import classNames from 'classnames';
import {Form} from 'react-final-form';

import CardVitalsSettingsFormBody from '../components/_card_vitals_settings_body';
import CardVitalsSettingsFormHeader from '../components/_card_vitals_settings_header';

import {profileUpdate} from '../modules/api/profiles';
import {rivalsList} from '../modules/api/rivals';
import {rivalGroupUpdate} from '../modules/api/rival_groups';
import {getGroupedRivalGroups, addNewRivalGroup} from '../modules/new_board_wizard_utils';
import {companyImagesFromRivalAndSrc} from '../modules/company_settings_utils';
import {localRivalLogoUrl} from '../modules/local_storage_utils';
import {refreshDynamicContent} from '../modules/card_utils';
import {redirectToV2} from '../modules/route_utils';
import {AlertSubscriptionsContext} from '../contexts/_alertSubscriptions';
import QueryBuilder from './_alerts_query_builder/_alerts_query_builder';
import BoardAlertSettings from './_board_alert_settings';
import BoardPageMonitorSettings from './_board_page_monitors_settings';

class CardVitalsSettings extends React.Component {

  constructor(props) {
    super(props);

    const {showSettingsOpen} = props;

    this.state = {
      expanded: showSettingsOpen,
      rivals: [],
      rivalsLoaded: false,
      curatorsLoaded: false,
      groupsLoaded: false,
      loading: false,
      uploadingImage: false,
      updatingProfile: false,
      isQueryBuilderOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    const {
      profile: {id},
      curators,
      curatorIds
    } = this.props;
    const {
      profile: {id: prevId},
      curators: prevCurators,
      curatorIds: prevCuratorsIds
    } = prevProps;

    if(id !== prevId) {
      this.loadSettings();
      this.loadCuratorGroups();
    }
    else if(
      (curators && curators !== prevCurators) ||
      (curatorIds && curatorIds !== prevCuratorsIds)
    ) {
      this.loadCuratorGroups();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  UNSAFE_componentWillMount() {
    this.mounted = true;
    this.loadSettings();
    this.loadCuratorGroups();
  }

  mounted = false;
  rivalsSequenceNumber = 0;
  groupsSequenceNumber = 0;

  loadSettings = () => {
    this.setState(
      {
        rivalsLoaded: false,
        groupsLoaded: false,
        loading: true
      },
      () => {
        this.loadRivals();
        this.loadGroups();
        refreshDynamicContent();
      }
    );
  };

  loadRivals = () => {
    this.rivalsSequenceNumber += 1;

    const sn = this.rivalsSequenceNumber;
    const rivalOptions = {
      order: 'alpha'
    };

    rivalsList({rivalOptions, code: 'CardVitalsSettings.componentDidMount'})
      .then(rs => {
        if(!this.mounted || sn !== this.rivalsSequenceNumber) {
          return;
        }

        const {
          curatorsLoaded,
          groupsLoaded
        } = this.state;

        this.setState({
          rivals: rs,
          rivalsLoaded: true,
          loading: !curatorsLoaded || !groupsLoaded
        });
      })
      .catch(() => {
        this.handleRequestError({
          title: '🚨 Failed to fetch board names..',
          message: 'Please refresh your board and try again.'
        });
      });
  };

  loadCuratorGroups = () => {
    const {curators, curatorIds, allCurators} = this.props;

    if(!curators || !curatorIds || !allCurators) {
      return;
    }

    const assignedCurators = curators.filter(c =>
      (curatorIds || []).includes(c.id)
    );

    const curatorsGroup = [{title: 'curators', items: allCurators}];
    const {rivalsLoaded, groupsLoaded} = this.state;

    this.setState({
      curatorsGroup,
      curators: assignedCurators,
      curatorsLoaded: true,
      loading: !rivalsLoaded || !groupsLoaded
    });
  };

  loadGroups = () => {
    this.groupsSequenceNumber += 1;

    const sn = this.groupsSequenceNumber;
    const {
      utils: {rival}
    } = this.context;

    getGroupedRivalGroups(rival)
      .then(({rivalGroups, groups, groupsThatRivalBelongsTo}) => {
        if(!this.mounted || sn !== this.groupsSequenceNumber) {
          return;
        }

        const {
          curatorsLoaded,
          rivalsLoaded
        } = this.state;

        this.setState({
          rivalGroups,
          groupsLoaded: true,
          groupsThatRivalBelongsTo,
          dashboardGroups: groups,
          loading: !rivalsLoaded || !curatorsLoaded
        });
      })
      .catch(error => console.log(error));
  };

  handleRequestError = error => {
    const {onErrorMessage} = this.props;

    onErrorMessage(error);
  };

  handleProfileUpdateError = error => {
    const {onErrorMessage} = this.props;

    console.log(
      `CardVitalsSettings.handleProfileUpdateError: ${JSON.stringify(error)}`
    );
    this.setState({updatingProfile: false});
    this.loadSettings();

    onErrorMessage({
      title: '🚨 An error occurred while saving the settings.',
      message: 'Please refresh your board and try again.'
    });
  };

  handleToggleQueryBuilder = () =>
    this.setState(({isQueryBuilderOpen}) => ({
      isQueryBuilderOpen: !isQueryBuilderOpen
    }));

  handleTogglePageMonitors = () => {
    const {
      utils: {rival}
    } = this.context;
    const {id: rivalId} = rival || {};
    const {appData: {v2Host}} = this.context;

    redirectToV2({v2Host, v2Path: '/settings/monitors', v2Search: `?rivalId=${rivalId}`});
  };

  handleUploadingStatusChange = uploadingImage =>
    this.setState({uploadingImage});

  getCuratorUpdates = (originalCurators, pendingCurators) => {
    const add = [];
    const remove = [];
    const originalIds = (originalCurators || []).map(c => c.id);
    const pendingCuratorIds = (pendingCurators || []).map(c => c.id);

    originalIds.forEach(originalId => {
      if(!pendingCuratorIds.includes(originalId)) {
        remove.push(originalId);
      }
    });
    pendingCuratorIds.forEach(pendingId => {
      if(!originalIds.includes(pendingId)) {
        add.push(pendingId);
      }
    });

    if(!add.length && !remove.length) {
      return {};
    }

    const curatorUpdates = {};

    if(add.length) {
      curatorUpdates.add = add;
    }

    if(remove.length) {
      curatorUpdates.remove = remove;
    }

    return {curatorUpdates, pendingCuratorIds};
  };

  getDashboardGroupsUpdates = (originalGroups, pendingGroups) => {
    const groupsToUpdate = [];
    const originalIds = (originalGroups || []).map(g => g.id);
    const pendingGroupIds = (pendingGroups || []).map(g => g.id);
    const {
      utils: {rival}
    } = this.context;
    const {id: rivalId} = rival || {};

    if(!rivalId) {
      return null;
    }

    originalIds.forEach(id => {
      if(!pendingGroupIds.includes(id)) {
        groupsToUpdate.push({id, removeRivals: [rivalId]});
      }
    });

    pendingGroupIds.forEach(id => {
      if(!originalIds.includes(id)) {
        groupsToUpdate.push({id, addRivals: [rivalId]});
      }
    });

    return groupsToUpdate.length ? groupsToUpdate : null;
  };

  // no api support to update all this info at once so we need to update all the rival groups where
  // this rival has been added AND removed.
  saveGroups = (profile, groupUpdates) => {
    if(!groupUpdates) {
      return Promise.resolve(profile);
    }

    const {
      utils: {rival},
      api: {refreshGroupsForRival}
    } = this.context;
    const updates = groupUpdates.map(rivalGroupOptions => {
      const {id, addRivals} = rivalGroupOptions;
      const action = addRivals && addRivals.length ? 'add' : 'remove';

      return (
        rivalGroupUpdate({
          rivalGroupOptions,
          code: 'CardVitalSettings.saveGroups'
        })
          // need to refresh the groups to ensure the dashboard card is updated.
          .then(() => refreshGroupsForRival(rival, [{id, action}]))
      );
    });

    return Promise.all(updates)
      .then(() => Promise.resolve(profile))
      .catch(error => Promise.reject({error}));
  };

  saveProfile = (profile, profileOptions, dirty) => {
    return dirty
      ? profileUpdate({
        profileOptions,
        code: 'CardVitalsSettings.handleSubmitClicked'
      })
      : Promise.resolve(profile);
  };

  handleSubmitClicked = values => {
    const {expanded} = this.state;
    const {onSettingsExpanded, profile, onProfileUpdated} = this.props;

    if(!expanded) {
      return this.setState({expanded: true}, () => onSettingsExpanded(true));
    }

    const {
      utils: {rival}
    } = this.context;
    const {
      boardName,
      logoUrl,
      curators,
      groups,
      useAsTemplate,
      wideLanes
    } = values;

    const {
      boardName: originalBoardName,
      logoUrl: originalLogoUrl,
      curators: originalCurators,
      groups: originalGroups,
      useAsTemplate: originalUseAsTemplate,
      wideLanes: originalWideLanes
    } = this.getSettingValues(rival, profile);

    const trimedBoardName = (boardName || '').trim();
    const {id: profileId = 0} = profile || {};
    const profileOptions = {
      id: profileId
    };
    let dirty = false;

    if(trimedBoardName !== originalBoardName) {
      dirty = true;
      profileOptions.name = trimedBoardName;
    }

    if(logoUrl !== originalLogoUrl) {
      dirty = true;
      profileOptions.imageUrl = logoUrl;
    }

    if(wideLanes !== originalWideLanes) {
      dirty = true;
      profileOptions.wideMode = wideLanes;
    }

    if(useAsTemplate !== originalUseAsTemplate) {
      dirty = true;
      profileOptions.isTemplate = useAsTemplate;
    }

    const {curatorUpdates} = this.getCuratorUpdates(
      originalCurators,
      curators
    );

    if(curatorUpdates) {
      dirty = true;
      profileOptions.curators = curatorUpdates;
    }

    const dashboardGroupsUpdates = this.getDashboardGroupsUpdates(
      originalGroups,
      groups
    );
    const reallyDirty =
      dirty || dashboardGroupsUpdates;

    if(!reallyDirty) {
      return this.setState({expanded: false}, () =>
        onSettingsExpanded(false)
      );
    }

    this.setState({updatingProfile: true}, () => {
      this.saveProfile(profile, profileOptions, dirty)
        .then(p => this.saveGroups(p, dashboardGroupsUpdates))
        .then(p => onProfileUpdated(p, profileOptions))
        .then(() => {
          if(!this.mounted) {
            return;
          }

          this.setState(
            {
              expanded: false,
              updatingProfile: false,
              loading: true
            },
            () => {
              onSettingsExpanded(false);
              setTimeout(() => this.loadSettings(), 500); // delay for the collapse animation
            }
          );
        })
        .catch(error => this.handleProfileUpdateError(error));
    });
  };

  handleCloseClicked = () => {
    const {onSettingsExpanded} = this.props;

    this.setState({expanded: false}, () => onSettingsExpanded(false));
  };

  handleAddNewGroup = groupName => {
    return new Promise((resolve, reject) => {
      const {
        api: {rivalGroupCreate}
      } = this.context;
      const {rivalGroups} = this.state;

      addNewRivalGroup(groupName, rivalGroups, rivalGroupCreate)
        .then(({rivalGroups: updatedGroups, rivalGroup, groups}) => {
          if(!this.mounted) {
            return;
          }

          resolve({group: rivalGroup}); // updates the selected value
          this.setState({
            rivalGroups: updatedGroups,
            dashboardGroups: groups
          });
        })
        .catch(error => {
          if(!this.mounted) {
            return;
          }

          this.handleRequestError(error.error);
          reject({error});
        });
    });
  };

  buttonTitle = () => {
    const {expanded, uploadingImage, updatingProfile} = this.state;

    if(updatingProfile) {
      return 'Saving';
    }

    if(uploadingImage) {
      return 'Uploading';
    }

    return expanded ? 'Save' : 'Edit';
  };

  getSettingValues = (rival, profile) => {
    const settingValues = {};

    if(!rival || !profile) {
      return settingValues;
    }

    const {curators, groupsThatRivalBelongsTo} = this.state;
    const {name} = rival;
    const {wideMode = false, isTemplate = false} = profile;
    const localLogoUrl = localRivalLogoUrl(rival);
    const images = localLogoUrl
      ? [localLogoUrl]
      : companyImagesFromRivalAndSrc(rival) || [];

    settingValues.boardName = name;
    settingValues.logoUrl = images.length ? images[0] : null;
    settingValues.useAsTemplate = Boolean(isTemplate);
    settingValues.wideLanes = Boolean(wideMode);
    Object.assign(settingValues, {curators});
    Object.assign(settingValues, {groups: groupsThatRivalBelongsTo});

    return settingValues;
  };

  renderAlertsSection = rivalId => {
    const {onErrorMessage} = this.props;
    const {expanded, isQueryBuilderOpen} = this.state;

    return (
      <AlertSubscriptionsContext.Consumer>
        {({rivals, areAllAlertsQueriesDisabled, updateAlertsActive, refreshMetabaseSubscription}) => {
          const {
            utils: {rival: currentRival}
          } = this.context;
          // pull rival from subscription lookup or currentRival if not in look-up (e.g. when newly created)
          const rival = rivals[rivalId] || currentRival || {};

          const updateAlertsActiveStatus = async ({rival: r, alertsActive, updateProfile, code}) => {
            try {
              await updateAlertsActive({rival: r, alertsActive, updateProfile});
            }
            catch{
              console.error(`CardVitalsSettings: failed on ${code}`);
              onErrorMessage({
                title: '🚨 An error occurred while updating alerts settings.',
                message: 'Please refresh your board and try again.'
              });
            }
          };

          const handleHighVolumeAlertsToggle = () =>
            updateAlertsActiveStatus({rival, alertsActive: !rival.alertsActive, updateProfile: true, code: 'handleHighVolumeAlertsToggle'});

          const handleonAlertsActiveUpdate = newAlertsActive =>
            updateAlertsActiveStatus({rival, alertsActive: newAlertsActive, code: 'handleonAlertsActiveUpdate'});

          const handleToggleAlertsAndOpenQueryBuilderModal = async alertsActive => {
            try {
              if(rival.alertsActive !== alertsActive) {
                await updateAlertsActiveStatus({rival, alertsActive, updateProfile: true, code: 'handleonAlertsActiveUpdate'});
              }

              this.setState({isQueryBuilderOpen: alertsActive});
            }
            catch{
              console.error('CardVitalsSettings: failed on handleToggleAlertsAndOpenQueryBuilderModal');
              onErrorMessage({
                title: '🚨 An error occurred while updating alerts settings.',
                message: 'Please refresh your board and try again.'
              });
            }
          };

          const handleOnMetabaseSubscriptionDidUpdate = ({rivalId: id}) => refreshMetabaseSubscription(id);

          return (
            <>
              {expanded && (
                <>
                  <BoardAlertSettings
                    alertsActive={!areAllAlertsQueriesDisabled(rival)}
                    onToggleQueryBuilder={this.handleToggleQueryBuilder}
                    onToggleAlerts={handleToggleAlertsAndOpenQueryBuilderModal} />
                  <BoardPageMonitorSettings onTogglePageMonitors={this.handleTogglePageMonitors} />
                </>
              )}

              {isQueryBuilderOpen &&
              <QueryBuilder
                rivalId={rivalId}
                rival={rival}
                onClose={this.handleToggleQueryBuilder}
                alertsActive={rival?.alertsActive || false}
                onHighVolumeQueryStatusChange={handleHighVolumeAlertsToggle}
                onAlertsActiveUpdate={handleonAlertsActiveUpdate}
                onMetabaseSubscriptionDidUpdate={handleOnMetabaseSubscriptionDidUpdate} />
            }
            </>
          );
        }}
      </AlertSubscriptionsContext.Consumer>
    );
  };

  render() {
    const {profile} = this.props;
    const {
      expanded,
      uploadingImage,
      updatingProfile,
      rivals,
      loading,
      curatorsGroup,
      dashboardGroups
    } = this.state;

    const disableSubmit = uploadingImage || updatingProfile;
    const baseClassName = 'card-vitals-settings';
    const {
      utils: {rival}
    } = this.context;
    const {name, id: rivalId} = rival;

    if(loading) {
      return (
        <div
          className={classNames(baseClassName, {
            'card-vitals-settings--expanded': expanded,
            'card-vitals-settings--loading': loading
          })}>
          <CardVitalsSettingsFormHeader
            rival={rival}
            name={name}
            buttonTitle="Edit"
            // this will present the spinner if we're trying to expand the form.
            // Once the form is loaded, the expanded component below will be rendered.
            loading={expanded}
            // disable submit if we're expanding and loading.
            onSubmit={expanded ? null : this.handleSubmitClicked} />
        </div>
      );
    }

    const initialValues = this.getSettingValues(rival, profile);

    return (
      <div
        className={classNames(baseClassName, {
          'card-vitals-settings--expanded': expanded,
          'card-vitals-settings--loading': loading
        })}>
        <Form initialValues={initialValues} onSubmit={this.handleSubmitClicked}>
          {({handleSubmit, values}) => (
            <div className="input-settings">
              <form onSubmit={handleSubmit} className="form">
                <CardVitalsSettingsFormHeader
                  expanded={expanded}
                  rival={rival}
                  name={name}
                  disableSubmit={disableSubmit}
                  onSubmit={this.handleSubmitClicked}
                  values={values}
                  buttonTitle={this.buttonTitle()}
                  onClose={this.handleCloseClicked} />
                <CardVitalsSettingsFormBody
                  rival={rival}
                  rivals={rivals}
                  values={values}
                  curatorsGroup={curatorsGroup}
                  dashboardGroups={dashboardGroups}
                  expanded={expanded}
                  onAddNewGroup={this.handleAddNewGroup}
                  onUploadingStatusChange={this.handleUploadingStatusChange} />
              </form>
            </div>
          )}
        </Form>
        {this.renderAlertsSection(rivalId)}
      </div>
    );
  }

}

CardVitalsSettings.propTypes = {
  profile: PropTypes.object.isRequired,
  curatorIds: PropTypes.arrayOf(PropTypes.number),
  curators: PropTypes.arrayOf(PropTypes.object),
  allCurators: PropTypes.arrayOf(PropTypes.object),
  onProfileUpdated: PropTypes.func,
  onErrorMessage: PropTypes.func,
  onSettingsExpanded: PropTypes.func,
  showSettingsOpen: PropTypes.bool
};

CardVitalsSettings.defaultProps = {
  curatorIds: null,
  curators: null,
  allCurators: [],
  onProfileUpdated() {},
  onErrorMessage() {},
  onSettingsExpanded() {},
  showSettingsOpen: false
};

CardVitalsSettings.contextTypes = {
  utils: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  appData: PropTypes.object.isRequired
};

export default CardVitalsSettings;
