import {useContext, useMemo} from 'react';
import {TagErrorsContext} from '.';
import {TAG_NAME_NOT_UNIQUE} from '../../modules/api/tags';

export const testIds = {
  showMeLink: 'tag-error-show-me-link'
};

const TagError = ({error, showLinkToNameConflicts}) => {
  const {setHighlights} = useContext(TagErrorsContext);
  const isNameConflictError = useMemo(() => Boolean(error?.type === TAG_NAME_NOT_UNIQUE), [error]);
  const {message, id} = error || {};

  if(!message) {
    return null;
  }

  const handleClickShowMe = error => {
    const {id, tagName, conflictingName} = error || {};

    if(!id) {
      return;
    }

    setHighlights({[id]: {tagName, conflictingName}});
  };

  return (
    <div className="tag-error-container">
      <p data-testid="tag-error"><span className="error-msg">{message}</span>{showLinkToNameConflicts && isNameConflictError && (<span data-testid={testIds.showMeLink} className="tag-error-link" onClick={() => handleClickShowMe(error)}> Show me.</span>)}</p>
    </div>
  );
};

TagError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    id: PropTypes.number
  }),
  showLinkToNameConflicts: PropTypes.bool
};

TagError.defaultProps = {
  error: null,
  showLinkToNameConflicts: false
};

export default TagError;
