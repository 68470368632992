import {fetch, post, destroy, update} from '../api_utils';
import {isValidId} from '../utils';
import {isValidContainer} from '../comment_utils';

// comments management API

export function commentsGet({commentOptions = {}, code = 'ApiUtils.commentsGet'}) {
  return new Promise((resolve, reject) => {
    // options format:
    //   commentOptions: {
    //     containerId: int,              // required
    //     containerType: string,         // required
    //     extensionMode: bool,
    //     reverse: bool
    //   }
    const {containerId, containerType, reverse = false, extensionMode = false} = commentOptions;

    if(_.isEmpty(commentOptions) || !isValidId(containerId) || !containerType) {
      console.warn(`${code}: invalid options specified: %o`, commentOptions);

      return reject(commentOptions);
    }

    const type = containerType.toLowerCase();

    if(!isValidContainer(type)) {
      return reject(commentOptions);
    }

    const url = `/api/${type}s/${containerId}/comments.json${reverse ? `?reverse=${Number(reverse)}` : ''}${extensionMode ? '?ext=1' : ''}`;

    fetch(url, {code})
      .then(response => {
        const {data: comments} = response;
        const {date: retrievedTime} = response.headers;

        console.log(`${code}: loaded comments at %o for %o #%o: %o`, retrievedTime, containerType, containerId, comments);

        resolve({comments, retrievedTime});
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
}

export function commentCreateOrUpdate({commentOptions = {}, code = 'ApiUtils.commentCreateOrUpdate'}) {
  // options format:
  //   commentOptions: {
  //     commentData: object,     // required
  //     containerId: int,        // required
  //     containerType: string,   // required
  //     extensionMode: bool
  //   }
  return new Promise((resolve, reject) => {
    const {commentData, containerId, containerType, extensionMode = false} = commentOptions;

    if(_.isEmpty(commentOptions) || _.isEmpty(commentData) || !containerId || !containerType) {
      console.warn(`${code}: invalid options specified: %o`, commentOptions);

      return reject(commentOptions);
    }

    const type = containerType.toLowerCase();

    if(!isValidContainer(type)) {
      return reject(commentOptions);
    }

    const {id} = commentData.comment;
    const action = id ? update : post;
    const url = `/api/${type.toLowerCase()}s/${containerId}/comments${id ? `/${id}` : '.json'}${extensionMode ? '?ext=1' : ''}`;

    action(url, JSON.stringify(commentData), {code})
      .then(({data: comment}) => {
        console.log(`${code}: %o comment for %o id #%o: %o`, id ? 'updated' : 'created new', containerType, containerId, comment);

        resolve(comment);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
}

export function commentDelete({commentOptions = {}, code = 'ApiUtils.commentDelete'}) {
  // options format:
  //   commentOptions: {
  //     id: int,                 // required, commentId to toggle
  //     containerId: int,        // required
  //     containerType: string,   // required
  //     extensionMode: bool
  //   },
  return new Promise((resolve, reject) => {
    const {id, containerId, containerType, extensionMode = false} = commentOptions;

    if(_.isEmpty(commentOptions) || !isValidId(id) || !containerId || !containerType) {
      console.warn(`${code}: invalid options specified: %o`, commentOptions);

      return reject(commentOptions);
    }

    const type = containerType.toLowerCase();

    if(!isValidContainer(type)) {
      return reject(commentOptions);
    }

    const url = `/api/${type}s/${containerId}/comments`;

    // NOTE: ext flag is used for all methods: POST/PUT/DELETE/UPDATE
    destroy(`${url}${id ? '/' + id : ''}${extensionMode ? '?ext=1' : ''}`, {code})
      .then(({data: updatedComments}) => {
        console.log(`${code}: comment #%o deleted successfully, update comments: %o`, commentOptions.id, updatedComments);

        resolve(updatedComments);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
}
