import moment from 'moment';

class CraftTimePeriod {

  constructor(period) {
    this.period = period;
  }

  _getQuarter = ({displayEndDate, startDate, periodType}) => {
    if(displayEndDate) {
      return displayEndDate.replace(/,\s+\d\d/, ' \'');
    }

    const DEFAULT_QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4'];
    const startDateObj = moment(startDate);
    const getPeriodQuarter = () => {
      const isValidQuarter = DEFAULT_QUARTERS.includes(periodType.toUpperCase());
      const quarterNumber = isValidQuarter ? Number(periodType.slice(-1)) : startDateObj.quarter();

      return quarterNumber;
    };

    const periodQuarter = getPeriodQuarter();
    const quarterByStartDate = startDateObj.quarter();
    const dateFormat = `[Q${periodQuarter}] 'YY`;
    const defaultDate = startDateObj;
    /**
     * check if the fiscal year is aligned with the calendar year
     * which means that Q1 starts Jan 1 and Q4 starts Oct 1
     */
    const isQuarterAlignedWithCalendar = quarterByStartDate === periodQuarter;

    /**
     * if the fiscal year is aligned with the calendar quarters
     * return the default date
     */
    if(isQuarterAlignedWithCalendar) {
      return defaultDate.format(dateFormat);
    }

    /**
     * Check if the quarter in question is Q1 ou Q2
     * if so, check if one of them starts after Jul, which means
     * that there are more quarters ending next year or half quarters ending next year,
     * so that one year should be incremented to every quarter.
     */
    const quarterStartMonth = startDateObj.month();
    const isQ1orQ2 = periodQuarter === 1 || periodQuarter === 2;
    const currentQuarterStartsAfterJuly = quarterStartMonth > 7;
    const shouldIncrementYear = isQ1orQ2 && currentQuarterStartsAfterJuly;

    if(shouldIncrementYear) {
      defaultDate.add(1, 'year');
    }

    return defaultDate.format(dateFormat);
  };

  displayString() {
    const {displayEndDate, endDate, periodType} = this.period;

    const date = moment(endDate, 'YYYY-MM-DD');
    const yearFormat = date.format('YYYY');
    const quarterFormat = this._getQuarter(this.period);
    const fiscalYearFormat = displayEndDate ? displayEndDate.replace(/,\s+\d\d/, '') : date.format('[Y]YY');
    const monthFormat = date.format('MMM YYYY');

    const format = {
      year: yearFormat,
      // eslint-disable-next-line camelcase
      fiscal_year: fiscalYearFormat,
      fy: fiscalYearFormat,
      month: monthFormat,
      quarter: quarterFormat,
      q1: quarterFormat,
      q2: quarterFormat,
      q3: quarterFormat,
      q4: quarterFormat
    };

    return format[periodType] || date.format('D MMM YYYY');
  }

  type() {
    const {periodType} = this.period;

    switch(periodType) {
      case 'fiscal_year':
      case 'fy':
        return 'fiscal_year';
      case 'h1':
      case 'h2':
        return 'half';
      case 'q1':
      case 'q2':
      case 'q3':
      case 'q4':
        return 'quarter';
      default:
        return periodType;
    }
  }

  sortableString() {
    const {endDate, periodType} = this.period;

    const date = moment(endDate, 'YYYY-MM-DD');

    switch(periodType) {
      case 'year':
      case 'fiscal_year':
        return date.format('YYYY');
      case 'quarter':
      case 'q1':
      case 'q2':
      case 'q3':
      case 'q4':
        return date.format('YYYY-Q');
      case 'month':
        return date.format('YYYY-Q-MM');
      default:
        return null;
    }
  }

}

export default CraftTimePeriod;
