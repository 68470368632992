import {Link} from 'react-router-dom';

const Like = ({userId, userImage, userName, onLikesLoaded}) => {
  if(onLikesLoaded) {
    onLikesLoaded();
  }

  return (
    <Link to={`/users/${userId}`} data-tip={userName} data-offset="{'top': -2, 'left': -3}" className="like">
      <img
        src={userImage}
        alt={userName}
        loading="lazy" />
    </Link>
  );
};

Like.propTypes = {
  userId: PropTypes.number,
  userName: PropTypes.string,
  userImage: PropTypes.string,
  onLikesLoaded: PropTypes.func
};

Like.defaultProps = {
  userId: 0,
  userName: '',
  userImage: '',
  onLikesLoaded: null
};

export default Like;
