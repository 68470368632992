import {useEffect} from 'react';

import {useMicrosoftTeams} from '../hooks/useMicrosoftTeams';

const MsTeamsNotifier = () => {
  const {teams} = useMicrosoftTeams({
    onSuccess() {
      console.log('MS Teams SDK: initialization successful');
    },
    onFailure(error) {
      console.error('MS Teams SDK: initialization failed', error);
    }
  });

  useEffect(() => {
    if(teams?.isInitialized) {
      teams.authentication.notifySuccess();
    }
  }, [teams]);

  return null;
};

export default MsTeamsNotifier;
