import {Token} from './tokenizer';

class ASTNode {

  constructor(type, children = []) {
    this.type = type;
    this.children = children;
  }

  len() {
    let l = 0;

    this.children.forEach(c => {
      if(c instanceof ASTNode) {
        l += c.len();
      }
      else {
        l++;
      }
    });

    return l;
  }

  findChildren(nodeType) {
    return this.children.filter(c => {
      if(c instanceof ASTNode && c.type === nodeType) {
        return true;
      }
      else if(c instanceof Token && c.type === nodeType) {
        return true;
      }

      return false;
    });
  }

}

export default ASTNode;
