import {useRef, useCallback} from 'react';
import moment from 'moment';

import classNames from 'classnames';

import CardInfoTime from '../_card_info_time';
import Icon from '../_icon';

export const cardHealthDays = [
  {
    maxDays: 7,
    label: '7 days',
    health: 'health-100'
  },
  {
    maxDays: 30,
    label: '30 days',
    health: 'health-75'
  },
  {
    maxDays: 90,
    label: '90 days',
    health: 'health-50'
  },
  {
    maxDays: 183,
    label: '6 months',
    health: 'health-25'
  }
];

export const getCardHealth = cardLastReviewedAt => {
  const jsToday = moment().startOf('day');
  const jsOneDay = 24 * 60 * 60 * 1000;
  const lastNDays = (n = 0) => Number(new Date(jsToday - n * jsOneDay));

  const lastModifiedDate = cardLastReviewedAt ? Number(new Date(cardLastReviewedAt)) : null;

  if(!lastModifiedDate) {
    return 'health-0';
  }

  for(const {maxDays, health} of cardHealthDays) {
    if(lastModifiedDate > lastNDays(maxDays)) {
      return health;
    }
  }

  return 'health-0';
};

const FreshnessCard = ({
  card,
  user,
  name,
  onSetEditMode,
  onToggleFreshCardExpanded,
  onMarkCardAsReviewed
}, context) => {
  const {id, reviewer, isDraft: draft} = card;
  const {reviewedAt: cardLastReviewed} = reviewer || {};
  const {id: userId} = user || {};

  const refreshDialogDismissedCheckboxValue = useRef(false);

  const markCardAsReviewed = useCallback(() => onMarkCardAsReviewed(card),
    [card, onMarkCardAsReviewed]);

  const handleEditCard = useCallback(e => {
    e?.preventDefault();
    onSetEditMode(id);
  }, [id, onSetEditMode]);

  const handleExpandCard = useCallback(e => {
    e?.preventDefault();
    onToggleFreshCardExpanded(id);
  }, [id, onToggleFreshCardExpanded]);

  const handleMarkCardAsReviewed = useCallback(e => {
    e?.preventDefault();

    const {userData} = user || {};
    const {didDismissFreshnessConfirmation = false} = userData || {};

    if(didDismissFreshnessConfirmation) {
      return markCardAsReviewed();
    }

    const {utils: {dialog}} = context;

    dialog.confirm({
      message: 'Confirm - Mark this card as "Recently Updated?"',
      buttonOk: 'Yes',
      bodyContent: <div className="set-as-fresh-confirmation">
        <input id="confirm-set-freshness-checkbox" type="checkbox" ref={refreshDialogDismissedCheckboxValue} />
        <label htmlFor="confirm-set-freshness-checkbox">Do not show this confirmation again</label>
      </div>,
      okCallback() {
        const updateDismissedCheckbox = refreshDialogDismissedCheckboxValue.current?.checked;

        if(updateDismissedCheckbox) {
          const {api: {userUpdate}} = context;

          userUpdate({id: userId, featureFlag: ['didDismissFreshnessConfirmation', true]}).then(() => {
            markCardAsReviewed();
          });
        }
        else {
          markCardAsReviewed();
        }
      }
    });
  }, [context, markCardAsReviewed, user, userId]);

  const cardHealth = getCardHealth(cardLastReviewed);
  const fresh = cardHealth === 'health-100';

  return (
    <div className={classNames('card-hidden-by-filters', cardHealth)}>
      <div className="card-hidden-by-filters-name-row">
        <span>{name}</span>
        <span className="card-hidden-by-filters-name-row-buttons">
          <button
            className="card-freshness-button"
            onClick={handleEditCard}
            data-tracking-id="cardFresh-editLink"
            data-testid="cardFresh-editLink"
            data-tip="Edit Card"
            data-offset="{'top': 0}">
            <i className="fa fa-edit" />
          </button>
          <button
            type="button"
            className="card-freshness-button"
            onClick={handleExpandCard}
            data-tracking-id="cardFresh-expandLink"
            data-testid="cardFresh-expandLink"
            data-tip="Expand Card"
            data-offset="{'top': 0}">
            <i className="fa fa-expand" />
          </button>
          <button
            className={classNames('card-freshness-button', {fresh, draft})}
            onClick={handleMarkCardAsReviewed}
            data-tracking-id="cardFresh-markLink"
            data-testid="cardFresh-markLink"
            data-tip="Mark as Reviewed"
            data-offset="{'top': 0}">
            <Icon
              width="20"
              height="20"
              icon="verified"
              stroke={fresh ? '#2f8464' : '#716879'}
              fill={fresh ? 'rgb(255, 255, 255, 0.75)' : 'rgb(255, 255, 255, 0.75)'} />
          </button>
        </span>
      </div>
      <div className="card-hidden-by-filters-bottom-row">
        <CardInfoTime {...card} useReviewedAt={true} />
        {draft ? <span className="card-freshness-draft">Draft</span> : null}
      </div>
    </div>
  );
};

FreshnessCard.contextTypes = {
  utils: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired
};

FreshnessCard.propTypes = {
  card: PropTypes.object.isRequired,
  name: PropTypes.node,
  user: PropTypes.object.isRequired,
  onSetEditMode: PropTypes.func.isRequired,
  onToggleFreshCardExpanded: PropTypes.func,
  onMarkCardAsReviewed: PropTypes.func
};

FreshnessCard.defaultProps = {
  name: 'Untitled Card',
  onToggleFreshCardExpanded() {},
  onMarkCardAsReviewed() {}
};

export default FreshnessCard;
