import NotificationsGroupList from './_notifications_group_list';

import {notificationsFilters} from '../modules/constants/notifications';
import {notificationsGet, notificationDelete, notificationsCountsGet} from '../modules/api/notifications';
import {userCanCurate, userHasCuratedProfiles} from '../modules/roles_utils';

import ReactTooltip from 'react-tooltip';

class Notifications extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  // props added in app_base
  static propTypes = {
    user: PropTypes.object,
    rivals: PropTypes.arrayOf(PropTypes.object),
    notificationsCount: PropTypes.number,
    onRefreshNotificationsCount: PropTypes.func
  };

  static defaultProps = {
    user: null,
    rivals: null,                       // null = not finished loading; [] = no rivals found for company
    notificationsCount: null,
    onRefreshNotificationsCount() {}
  };

  state = {
    notifications: null,                // null = not finished loading; [] = no notifications found for user
    notificationsCount: null,
    filter: notificationsFilters.ALL    // options: ALL, OWN
  };

  componentDidMount() {
    console.log('Notifications.componentDidMount: props: %o', this.props);

    const {user, notificationsCount} = this.props;
    const shouldLoadCounts = notificationsCount !== null;

    if(userHasCuratedProfiles({user})) {
      this.setState({filter: notificationsFilters.OWN}, () => {
        // NOTE: on initial load we populate state.notificationsCount with the initial result passed down from AppBase
        this.loadNotifications(shouldLoadCounts);
      });
    }
    else {
      this.loadNotifications(shouldLoadCounts);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('Notifications.componentWillReceiveProps: props: %o', nextProps);

    if((this.state.notificationsCount === null) && nextProps.notificationsCount) {
      this.setState({notificationsCount: nextProps.notificationsCount}, () => {
        console.log('Notifications.componentWillReceiveProps: initialized notificationsCount: %o', this.state.notificationsCount);
      });
    }
  }

  loadNotifications = (shouldLoadCounts = true) => {
    // TODO: we should paginate notifications (needs new UI) instead of the "infinite" scroll with the 500 limit
    const notificationOptions = {
      dismissed: true,
      page: 1,
      limit: 500
    };
    const notificationsCountsOptions = {};

    // only pass ownProfiles option if *on* (at_notifications API only expects it if specifically requested, see #3977)
    if(this.state.filter === notificationsFilters.OWN) {
      notificationOptions.ownProfiles = true;
      notificationsCountsOptions.ownProfiles = true;
    }

    notificationsGet(notificationOptions, 'Notifications.loadNotifications').then(({notifications}) => {
      this.setState({notifications}, () => {
        console.log('Notifications.loadNotifications: loaded notifications: %o', this.state.notifications);
      });

      // request associated rivals as needed
      const profileIds = [];

      notifications.forEach(notification => {
        const {comment: {containers} = {containers: []}} = notification;

        if(containers.length) {
          for(let i = 0; i < containers.length; i++) {
            const container = containers[i];
            const type = container.containerType.toLowerCase();
            const profileId = (type === 'profile') ? container.containerId : container.parentId;

            if(profileId && !profileIds.includes(profileId)) {
              profileIds.push(profileId);

              this.context.utils.requestRival({profileId});
            }
          }
        }
      });
    });

    if(shouldLoadCounts) {
      notificationsCountsGet(notificationsCountsOptions, 'Notifications.loadNotifications')
        .then(({notificationsCount, staleBattlecardsCount}) => {
          this.setState({notificationsCount, staleBattlecardsCount}, () => {
            console.log('Notifications.loadNotifications: loaded notificationsCount: %o, staleBattlecardsCount: %o',
              notificationsCount, staleBattlecardsCount);
          });
        });
    }
  };

  handleNotificationDelete = notification => {
    const notifications = this.state.notifications ? this.state.notifications.slice() : [];
    const notificationIndex = notifications.findIndex(n => n.id === notification.id);
    const undo = Boolean(notification.dismissedAt);

    notificationDelete({notificationOptions: {id: notification.id}, undo})
      .then(updatedNotification => {
        this.setState(prevState => {
          return {
            notifications: ReactUpdate(this.state.notifications, {
              $splice: [
                [notificationIndex, 1, updatedNotification]
              ]
            }),
            notificationsCount: prevState.notificationsCount + (undo ? 1 : -1)
          };
        }, () => {
          console.log('Notifications.handleNotificationDelete: updated notifications: %o', this.state.notifications);

          // trigger notifications count update in AppBase
          this.props.onRefreshNotificationsCount();
        });
      });
  };

  handleFilterClick = filter => {
    if(filter === this.state.filter) {
      return;
    }

    this.setState({filter}, () => {
      console.log('Notifications.handleFilterClick: set notifications filter: %o', this.state.filter);

      this.loadNotifications();
    });
  };

  render() {
    const {user, rivals} = this.props;
    const {notifications, notificationsCount, filter} = this.state;

    return (
      <div className="notifications messages">
        <NotificationsGroupList
          user={user}
          rivals={rivals}
          filter={filter}
          notifications={notifications}
          unreadNotificationsCount={notificationsCount}
          onFilterClick={this.handleFilterClick}
          onNotificationDeleteClick={this.handleNotificationDelete} />
        <ReactTooltip
          class="tooltip"
          html={true}
          effect="solid" />
      </div>
    );
  }

}

export default Notifications;
