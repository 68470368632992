import {decodeCommonEntities, processLinks, sanitizeInput, allowlistedTags, wrapHtml} from '../modules/html_utils';
import {resizeExtension} from '../modules/ext_utils';
import {truncateLimits} from '../modules/constants/ui';
import {truncate, cleanupHtmlString, paragraphs} from '../modules/text_utils';

const CommentTruncate = ({text: commentText, isExtensionMode}) => {
  const [isExpanded = false, setExpanded] = React.useState(false);
  const _cleanup = s => sanitizeInput(s, {
    allowedTags: allowlistedTags.filter(t => (!['noscript', 'img'].includes(t))),
    exclusiveFilter: ({tag, text}) => (['p', 'div', 'span', 'li', 'a'].includes(tag) && !text.trim()), // strip empty tags TODO: add others?
    nonTextTags: ['noscript']
  }).trim()
    .replace(/^[ \t\r]+|[ \t\r]+$/gm, '')
    .replace(/([ \t\r]*\B\n){2,}/gm, '\n\n')
    .split('\n\n')
    .map(str => str.trim().replace(/\n/g, ' '))
    .join('\n\n');
  const commentTextCleaned = isExtensionMode ? _cleanup(commentText) : commentText;
  const limit = truncateLimits.commentBody;
  const buffer = Math.round(limit / 2);
  let bodyText = '';

  const _truncateCommentHtml = toTruncate => {
    const {body} = new DOMParser().parseFromString(toTruncate, 'text/html');
    const html = body.innerHTML;

    bodyText = body.textContent;

    return (
      isExpanded
        ? cleanupHtmlString(html)
        : paragraphs(truncate(html,
          {
            isHtml: true,
            useWordBoundary: true,
            limit,
            buffer
          }))
    );
  };

  const bodyContent = decodeCommonEntities(processLinks(_truncateCommentHtml(commentTextCleaned)));
  const handleExpandClick = e => {
    e.preventDefault();
    e.stopPropagation();

    setExpanded(!isExpanded);
  };

  React.useEffect(() => {
    resizeExtension();
  }, [isExpanded]);

  const viewMoreButton = (bodyText.length > (limit + buffer))
    ? (<button className="btn-more button button--alt button--small" onClick={handleExpandClick}>
      {isExpanded ? 'Less' : 'More'}
    </button>) : null;

  return (
    <>
      <div className="comment--content" dangerouslySetInnerHTML={wrapHtml(bodyContent)} />
      {viewMoreButton}
    </>
  );
};

CommentTruncate.propTypes = {
  text: PropTypes.string.isRequired,
  isExtensionMode: PropTypes.bool
};

CommentTruncate.defaultProps = {
  isExtensionMode: false
};

export default CommentTruncate;
