import AttachmentBox from './_attachment_box';
import Icon from './_icon';
import classNames from 'classnames';

import {analyticsTrack} from '../modules/analytics_utils';

const FeedPostBoxExpanded = ({postData, profile, profileEditMode, onToggleFeedItem, dim}) => {
  if(!profile || !postData) {
    return null;
  }

  const {commentTitle: postTitle, commentBody: postBody, postId, commentTimestamp, attachments: postAttachments = [], linkToFullPost} = postData;
  const attachmentBoxKey = `attachmentBox_${postId}`;
  const postAttachment = postAttachments.find(a => (a.id === postData.attachmentId));
  const attachments = [postAttachment].filter(Boolean);

  analyticsTrack({
    type: 'event',
    category: 'Post',
    action: 'view',
    label: `${postTitle || (postAttachment || {}).title || 'Untitled'} (${postId})`
  });

  const swimlaneClass = classNames('swimlane feed-item-expanded', {
    'swimlane--dim': dim
  });

  return (
    <div className={swimlaneClass}>
      <div className="board-header">
        <span className="feed-item-expanded_close"><Icon icon="close" onClick={() => onToggleFeedItem(null)} /></span>
      </div>
      <div className="board-body">
        <AttachmentBox
          key={attachmentBoxKey}
          data={attachments}
          postId={postId}
          postBody={postBody}
          postTitle={postTitle}
          timestamp={commentTimestamp}
          profileMode={true}
          profile={profile}
          profileEditMode={profileEditMode}
          linkToFullPost={linkToFullPost} />
      </div>
    </div>
  );
};

FeedPostBoxExpanded.propTypes = {
  postData: PropTypes.object,
  profile: PropTypes.object,
  profileEditMode: PropTypes.bool,
  onToggleFeedItem: PropTypes.func,
  dim: PropTypes.bool
};

FeedPostBoxExpanded.defaultProps = {
  postData: null,
  profile: null,
  profileEditMode: false,
  onToggleFeedItem() {},
  dim: false
};

export default FeedPostBoxExpanded;
