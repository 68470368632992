import Icon from './_icon';

import classNames from 'classnames';

class DashboardGroup extends React.Component {

  static propTypes = {
    children: PropTypes.node,
    group: PropTypes.object,
    isActive: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isHighlighted: PropTypes.bool.isRequired,
    isDragging: PropTypes.bool.isRequired,
    modifier: PropTypes.string,
    label: PropTypes.string,
    onToggle: PropTypes.func,
    onToggleEffect: PropTypes.func,
    onEdit: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    groupClasses: PropTypes.string,
    dragHandle: PropTypes.node,
    isLoaded: PropTypes.bool,
    filteredRivalIds: PropTypes.arrayOf(PropTypes.number),
    isGroupLoaded: PropTypes.bool,
    onGroupLoaded: PropTypes.func
  };

  static defaultProps = {
    children: null,
    group: null,
    isActive: false,
    isEditing: false,
    isHighlighted: false,
    isDragging: false,
    modifier: '',
    label: '',
    onToggle: null,             // primary toggle action (active state is managed by parent if this is defined)
    onToggleEffect() {},        // handles side-effect events when toggling (active state managed by child)
    onEdit: null,
    onSave: null,
    onDelete: null,
    onCancel: null,
    groupClasses: '',
    dragHandle: null,
    isLoaded: false,
    filteredRivalIds: [],
    isGroupLoaded: false,
    onGroupLoaded() {}
  };

  state = {
    isActive: this.props.isActive,
    containerHeight: 0
  };

  componentDidMount() {
    console.log('DashboardGroup.componentDidMount: props: %o', this.props);

    window.addEventListener('resize', this.setMaxHeight);

    this.setMaxHeight();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.isActive !== this.props.isActive) {
      this.setState({
        isActive: nextProps.isActive
      });
    }
  }

  componentDidUpdate() {
    const {isActive, isLoaded, filteredRivalIds, isGroupLoaded, onGroupLoaded, group} = this.props;

    // NOTE: due to the delay in the group pop-in animation, we need to re-fire this on every view update
    if(isLoaded) {
      this.setMaxHeight();
    }

    const groupEl = ReactDOM.findDOMNode(this);
    const cards = groupEl.querySelectorAll('.dashboard-card');

    if(
      isActive && !isGroupLoaded && onGroupLoaded &&
      (groupEl.scrollHeight > 0) &&
      (cards.length === filteredRivalIds.length)
    ) {
      // HACK: ok, I don't like this (setTimeout(fn, 0)) much at all, but it was the only way I could get the card/placeholder Observers
      // on DashboardView to stall long enough to wait until groups are fully populated before checking for card visibility, otherwise it
      // loads everything from all "visible" groups (where only headers were showing -- groups load up w/out any content, cards load after)
      // TODO: clean this up later and figure out a way to avoid needing to "yield" here (to fix in #4171)
      setTimeout(() => onGroupLoaded(group));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMaxHeight);
  }

  setMaxHeight = () => {
    const groupEl = this.refs[`dashboard-group-${this.props.group.id}`];

    if(!groupEl) {
      return;
    }

    const containerHeight = groupEl.scrollHeight;

    if(this.state.containerHeight !== containerHeight) {
      this.setState({containerHeight});
    }
  };

  positionCaret = event => {
    if(event && event.target) {
      event.target.setSelectionRange(0, 0);
    }
  };

  handleSave = event => {
    if(event) {
      event.preventDefault();
    }

    const groupName = this.refs.editGroupName.value.trim();

    this.props.onSave(groupName, event);
  };

  handleToggle = event => {
    if(event) {
      event.preventDefault();
    }

    const {group, onToggle, onToggleEffect} = this.props;

    if(_.isEmpty(group)) {
      return;
    }

    // check for override
    if(onToggle) {
      onToggle(group, () => {
        onToggleEffect(group, this.props.isActive);
      });
    }
    else {
      this.setState(prevState => {
        return {
          isActive: !prevState.isActive
        };
      }, () => {
        onToggleEffect(group, this.state.isActive);
      });
    }
  };

  render() {
    const {children, group, isEditing, isHighlighted, isDragging, dragHandle, ...props} = this.props;
    const {containerHeight} = this.state;
    const isActive = props.onToggle ? props.isActive : this.state.isActive;
    const inputClasses = classNames({
      [props.modifier]: Boolean(props.modifier)
    });
    let groupClasses = classNames(`dashboard-group dashboard-group-${group.id}`, props.groupClasses, {
      'dashboard-group--active': isActive
    });
    let titleRegion;
    let editRegion;
    let editButtonsRegion;

    if(isEditing) {
      titleRegion = (
        <input
          ref="editGroupName"
          type="text"
          className="dashboard-input dashboard-input--edit"
          placeholder="Enter a group name..."
          maxLength={255}
          defaultValue={props.label}
          autoFocus={true}
          onFocus={this.positionCaret} />
      );

      editButtonsRegion = (
        <div className="dashboard-group_edit">
          <button
            type="button"
            className="button button--small button--save"
            onClick={this.handleSave}>
            Save
          </button>
          <button
            type="button"
            className="button button--small button--alert"
            onClick={props.onDelete}>
            Delete
          </button>
          <button
            type="button"
            className="button button--small button--disabled"
            onClick={props.onCancel}>
            Cancel
          </button>
        </div>
      );
    }
    else {
      titleRegion = (
        <h4 className="dashboard-group_label">
          <span className="dashboard-group_label_text">{props.label}</span>
        </h4>
      );

      if(props.onEdit) {
        editRegion = (
          <div className="dashboard-group_icon dashboard-group_icon--edit" onClick={props.onEdit}>
            <Icon icon="settings" width="20" height="20" />
          </div>
        );
      }
    }

    const groupStyles = {
      maxHeight: `${containerHeight}px`
    };
    const toggleRegion = [
      (
        <input
          key={`group-input_${group.id}`}
          id={`group-${group.id}`}
          name="groups"
          type="checkbox"
          className={inputClasses}
          checked={isActive}
          readOnly={true}
          value={group.id}
          onClick={this.handleToggle} />
      ),
      (
        <label
          key={`group-label_${group.id}`}
          htmlFor={`group-${group.id}`}>
          {titleRegion}
          {!isEditing && dragHandle}
          {editRegion}
          {editButtonsRegion}
        </label>
      ),
      (
        <div className="dashboard-group_rivals" ref={`dashboard-group-${group.id}`} key={`dashboard-group-${group.id}`} style={groupStyles}>
          {children}
        </div>
      )
    ];

    if(props.onSave) {
      groupClasses = classNames(groupClasses, {
        'dashboard-group--highlighted': isHighlighted,
        'dashboard-group--editing': isEditing,
        'dashboard-group--drag': isDragging
      });

      return (
        <form className={groupClasses} onSubmit={e => this.handleSave(e)}>
          {toggleRegion}
        </form>
      );
    }

    return (
      <div className={groupClasses}>
        {toggleRegion}
      </div>
    );
  }

}

export default DashboardGroup;
