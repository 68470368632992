import onClickOutside from 'react-onclickoutside';

// NOTE: legacy component, use DropdownMenu instead for new content
// don't use this component directly: use dropdown instead (see that component for reasons)
class DropdownBox extends React.Component {

  static propTypes = {
    button: PropTypes.node,
    label: PropTypes.string,
    labelIcon: PropTypes.element,
    displayLabel: PropTypes.string,
    placeholderMode: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    selectedValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    keyPrefix: PropTypes.string,
    className: PropTypes.string,
    containerClass: PropTypes.string,
    iconClass: PropTypes.string,
    onOptionClick: PropTypes.func,
    onToggle: PropTypes.func,
    condensed: PropTypes.bool,
    alignMenuLeft: PropTypes.bool,
    multiSelect: PropTypes.bool,
    // from onClickOutside HoC
    enableOnClickOutside: PropTypes.func,
    disableOnClickOutside: PropTypes.func,
    disabled: PropTypes.bool,
    onLoadOptions: PropTypes.func,
    dataTestId: PropTypes.string,
    dataTrackingId: PropTypes.string,
    showFilter: PropTypes.bool
  };

  static defaultProps = {
    button: null,
    label: 'Select',
    labelIcon: null,
    displayLabel: '',
    placeholderMode: false,
    options: [],
    selectedValue: '',
    keyPrefix: 'dd',
    className: '',
    onOptionClick: null,
    onToggle() {},
    condensed: false,
    containerClass: '',
    iconClass: 'fa-ellipsis-v',
    alignMenuLeft: false,
    multiSelect: false,
    enableOnClickOutside() {},
    disableOnClickOutside() {},
    disabled: false,
    onLoadOptions: null,
    dataTestId: 'dropdown-box',
    dataTrackingId: '',
    showFilter: false
  };

  state = {
    open: false,
    loading: false,
    filterValue: ''
  };

  componentDidMount() {
    console.log('DropdownBox.componentDidMount: props: %o', this.props);
  }

  // onClickOutside HoC
  handleClickOutside = () => this.toggleDropdown();

  // pass `{open: true}` or `{open: false}` to force state
  toggleDropdown = (force = null) => {
    const {open} = this.state;

    this.setState({
      open: (force === null) ? !open : force.open,
      filterValue: ''
    }, () => {
      const {open: nowOpen} = this.state;

      if(nowOpen) {
        this.props.enableOnClickOutside();
      }
      else {
        this.props.disableOnClickOutside();
      }

      this.props.onToggle(nowOpen);
    });
  };

  handleDropdownClick = event => {
    const {disabled} = this.props;

    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if(disabled) {
      return;
    }

    this.toggleDropdown();
  };

  handleKeyDown = event => {
    if(event.key === 'Escape') {
      event.preventDefault();
      this.toggleDropdown({open: false});
    }

    if(event.key === 'Enter') {
      event.preventDefault();
      event.target.click();
    }
  };

  handleOptionClick = (option, event) => {
    if(event) {
      event.preventDefault();
    }

    this.props.onOptionClick && this.props.onOptionClick(option.value);
    this.toggleDropdown();
  };

  handleLoadOptions = () => {
    const {onLoadOptions} = this.props;
    const {loading} = this.state;

    if(loading) {
      return;
    }

    this.setState({loading: true}, () => {
      onLoadOptions().then(options => {
        this.setState({
          loading: false,
          options
        }, () => {
          this.handleDropdownClick();
        });
      });
    });
  };

  renderOptions = () => {
    const {selectedValue, condensed, multiSelect, alignMenuLeft, options: propOptions, showFilter} = this.props;
    const {options: stateOptions, filterValue} = this.state;
    const filterValueLowercase = filterValue.toLowerCase();
    const options = stateOptions || propOptions;
    const {open} = this.state;
    const dropdownOptions = [];
    const menuContainerStyles = {};
    let dividerCount = 0;

    for(let i = 0; i < options.length; i++) {
      const option = options[i];
      const key = `${this.props.keyPrefix}_${option.value ?? ''}`;
      const optionAttributes = {};

      if(open) {
        optionAttributes.tabIndex = 0;
      }

      let icon;
      let optionIcon;
      let optionIconRight;
      let onOptionClick = () => {};

      if(option.linkElement) {
        dropdownOptions.push(option.linkElement);
        continue;
      }

      if(option.hasOwnProperty('className')) {
        optionAttributes.className = option.className;
      }

      if(option.hasOwnProperty('dataTrackingId')) {
        optionAttributes['data-tracking-id'] = option.dataTrackingId;
      }

      if(option.hasOwnProperty('dataTestId')) {
        optionAttributes['data-testid'] = option.dataTestId;
      }

      if(option.hasOwnProperty('tooltip')) {
        optionAttributes.title = option.tooltip;
      }

      if(option.hasOwnProperty('onOptionClick')) {
        // TODO: bind individual handlers
        onOptionClick = option.onOptionClick;
      }
      else {
        onOptionClick = () => this.handleOptionClick(option);
      }

      if(option.hasOwnProperty('icon')) {
        if(React.isValidElement(option.icon)) {
          icon = (
            <span className="ui-dropdown-option_icon">
              {option.icon}
            </span>
          );
        }
        else {
          icon = (
            <i className={`${'ui-dropdown-option_icon fa'} ${option.icon}`} />
          );
        }

        if(condensed) {
          optionIcon = icon;
        }
        else {
          optionIconRight = icon;
        }
      }

      if(option.hasOwnProperty('activeIcon')) {
        if(option.value === selectedValue || option.active) {
          if(React.isValidElement(option.icon)) {
            icon = (
              <span className="ui-dropdown-option_icon">
                {option.icon}
              </span>
            );
          }
          else {
            icon = (
              <i className={`${'ui-dropdown-option_icon fa'} ${option.activeIcon}`} />
            );
          }

          if(condensed) {
            optionIcon = icon;
          }
          else {
            optionIconRight = icon;
          }
        }
      }

      if(option.hasOwnProperty('separator')) {
        if(optionAttributes.hasOwnProperty('className')) {
          optionAttributes.className += ' separator';
        }
        else {
          optionAttributes.className = 'dropdown-options-item separator';
        }
      }

      if(option.divider) {
        dropdownOptions.push(
          <div key={key + '-sep_' + dividerCount++} className={('divider ' + (optionAttributes.className || '')).trim()} />
        );

        continue;
      }

      if(option.value === selectedValue || option.active) {
        if(optionAttributes.hasOwnProperty('className')) {
          optionAttributes.className += ' active';
        }
        else {
          optionAttributes.className = 'active';
        }

        if(multiSelect) {
          optionAttributes.onClick = onOptionClick;
        }
      }
      else {
        // no-op on clicking currently selected element
        optionAttributes.onClick = onOptionClick;
      }

      if(showFilter && filterValueLowercase && !option.content) {
        if(typeof option.label === 'string' && !option.label?.toLowerCase().includes(filterValueLowercase)) {
          continue;
        }
      }

      const itemContent =  option.content
        || (
          <a
            data-option-value={option.value}
            key={key}
            className="dropdown-options-item"
            {...optionAttributes}>
            {optionIcon}
            <span className="ui-dropdown-option_label">
              {option.label}
            </span>
            {optionIconRight}
          </a>
        );

      dropdownOptions.push(itemContent);
    }

    if(alignMenuLeft) {
      menuContainerStyles.right = 'auto';
      menuContainerStyles.left = 0;
    }

    if(showFilter) {
      menuContainerStyles.paddingTop = 0;
    }

    return (
      <div className="ui-dropdown-menu dropdown-options" style={menuContainerStyles}>
        {showFilter ? <div className="dropdown-options-filter">
          <input type="text"
            tabIndex={-1}
            className="dropdown-options-filter-input"
            placeholder="Filter"
            value={filterValue}
            onClick={e => e.stopPropagation()}
            onChange={e => {
              e?.preventDefault();
              e?.stopPropagation();
              this.setState({filterValue: e.target.value});
            }} />
        </div> : null}
        {dropdownOptions}
      </div>
    );
  };

  render() {
    const {
      condensed,
      containerClass,
      button,
      iconClass,
      className,
      options,
      selectedValue,
      displayLabel,
      placeholderMode,
      label,
      onLoadOptions,
      dataTestId,
      dataTrackingId
    } = this.props;
    let dropdownClass = 'ui-dropdown' + (condensed ? ' condensed' : '') + (this.state.open ? ' on' : '');
    let dropdownLabel;
    let labelRegion;

    if(containerClass) {
      dropdownClass += ' ' + containerClass;
    }

    if(button) {
      labelRegion = this.props.button; // could be react element or html
    }
    else if(condensed) {
      let labelIcon;

      if(this.props.labelIcon && React.isValidElement(this.props.labelIcon)) {
        labelIcon = (
          <div className="dropdown-label_icon">
            {this.props.labelIcon}
          </div>
        );
      }
      else {
        labelIcon = (
          <i className={'ui-dropdown-option_icon fa ' + iconClass} />
        );
      }

      labelRegion = (
        <a href="#" className={className} data-action="dropdown">
          {labelIcon}
        </a>
      );
    }
    else {
      // select box format
      const option = _.find(options, i => i && i.value === selectedValue);
      const useDisplayLabel = (selectedValue !== null && option ? option.label : '') || displayLabel;
      const displayLabelClass = 'display-label' + (placeholderMode ? ' placeholder' : '');

      if(label) {
        dropdownLabel = (
          <span>{label}</span>
        );
      }

      labelRegion = (
        <a href="#" className={className} data-action="dropdown">
          <span className={displayLabelClass} data-testid="dropdown-display-label">{useDisplayLabel}</span><i className="fa fa-chevron-down" />
        </a>
      );
    }

    const extraAtrributes = {};

    if(dataTrackingId) {
      extraAtrributes['data-tracking-id'] = dataTrackingId;
    }

    return (
      <div
        data-testid={dataTestId}
        className={dropdownClass}
        onClick={onLoadOptions ? this.handleLoadOptions : this.handleDropdownClick}
        onKeyDown={this.handleKeyDown}
        {...extraAtrributes}>
        {dropdownLabel}
        {labelRegion}
        {this.renderOptions()}
      </div>
    );
  }

}

export default onClickOutside(DropdownBox);
