import React from 'react';
import PropTypes from 'prop-types';

import CompanyBuilder from './_company_builder';
import OperatingMetricDropdownContainer from './inputs/_operating_metric_dropdown_container';
import {normalizedCompanyFormulaParam} from '../../../../../../modules/editor_utils';

class OperatingMetricBuilder extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    onChange() {}
  };

  state = {
    selectedCompanyId: this.context.utils.rival.id,
    selectedMetric: ''
  };

  componentDidMount = this._fireOnChange;
  componentDidUpdate = this._fireOnChange;

  _handleCompanyBuilderChanged = (formula, {companyId}) => {
    this.setState({
      selectedCompanyId: companyId,
      selectedMetric: ''
    }, () => {
      this._fireOnChange();
    });
  };

  _handleOperatingMetricChanged = metricLabel => {
    this.setState({selectedMetric: metricLabel}, () => {
      this._fireOnChange();
    });
  };

  _fireOnChange = () => {
    const {selectedCompanyId, selectedMetric} = this.state;

    if(selectedMetric === '') {
      this.props.onChange(null);

      return;
    }

    const {utils: {rival: {id: currentRivalId} = {}}} = this.context;

    this.props.onChange(`OPERATING_METRIC(COMPANY("${normalizedCompanyFormulaParam(selectedCompanyId, currentRivalId)}"), "${selectedMetric}")`);
  };

  render() {
    const {selectedCompanyId, selectedMetric} = this.state;

    return (
      <div>
        <CompanyBuilder onChange={this._handleCompanyBuilderChanged} />
        <div className="klue-formula-wizard_control-group">
          <label className="klue-formula-wizard_control-label">Metric</label>
          <OperatingMetricDropdownContainer
            companyId={selectedCompanyId}
            selectedMetric={selectedMetric}
            onChange={this._handleOperatingMetricChanged} />
        </div>
      </div>
    );
  }

}

export default OperatingMetricBuilder;
