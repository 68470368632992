import React from 'react';
import PropTypes from 'prop-types';

function Table(props) {
  let headerSection = null;

  if(props.headers) {
    let i = 0;
    const headers = props.headers.map(v => {
      const key = 'h' + i;

      i += 1;

      if(v === null || v === undefined || v === '') {
        v = null;
      }

      return (
        <th
          className="klue-table_cell klue-table_cell--header"
          key={key}>
          {v}
        </th>
      );
    });

    headerSection = (
      <thead>
        <tr
          className="klue-table_row klue-table_row--header">
          {headers}
        </tr>
      </thead>
    );
  }

  let r = 0;
  const rows = props.rows.map(row => {
    let c = 0;
    const cells = row.map(cell => {
      const key = 'r' + r + 'c' + c;

      c += 1;

      if(cell === undefined || cell === null || cell === '') {
        cell = null;
      }

      return (
        <td
          className="klue-table_cell"
          key={key}>
          {cell}
        </td>
      );
    });

    const key = 'r' + r;

    r += 1;

    return (
      <tr
        className="klue-table_row"
        key={key}>
        {cells}
      </tr>
    );
  });

  return (
    <table className="klue-table">
      {headerSection}
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    )
  ).isRequired
};

Table.defaultProps = {
  headers: null,
  rows: []
};

export default Table;
