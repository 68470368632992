import ChartLegend from './_chart_legend';
import ChartistLineGraph from './_chartist_line_graph';

import {abbreviate} from '../../../modules/number_utils';

import moment from 'moment';
import Chartist from 'chartist';

// monkeypatch for missing last label on X axis from:
// https://github.com/gionkunz/chartist-js/issues/1022#issuecomment-505417421
// https://jsbin.com/hanecozamu/edit?js,output
(function(window, document, _Chartist) {
  'use strict';

  function FixedScaleAxis(axisUnit, data, chartRect, options) {
    const highLow = options.highLow || _Chartist.getHighLow(data, options, axisUnit.pos);

    this.divisor = options.divisor || 1;
    this.ticks = options.ticks || _Chartist.times(this.divisor + 1).map(function(value, index) {
      return highLow.low + (highLow.high - highLow.low) / this.divisor * index;
    }.bind(this));
    this.ticks.sort(function(a, b) {
      return a - b;
    });
    this.range = {
      min: highLow.low,
      max: highLow.high
    };

    _Chartist.FixedScaleAxis.super.constructor.call(this,
      axisUnit,
      chartRect,
      this.ticks,
      options);

    this.stepLength = this.axisLength / this.divisor;
  }

  function projectValue(value) {
    return this.axisLength * (Number(_Chartist.getMultiValue(value, this.units.pos)) - this.range.min) / (this.range.max - this.range.min);
  }

  _Chartist.FixedScaleAxis = _Chartist.Axis.extend({
    constructor: FixedScaleAxis,
    projectValue
  });
}(window, document, Chartist));

const MetricTimeseriesGraph = ({timeseries, labelInterpolationFnc, legend}) => {
  const xValues = [];

  const series = timeseries.map(ts => {
    const points = {data: ts.map(e => ({x: moment(e.date).valueOf(), y: e.value}))};

    // Chartest needs to be passed events in-order so sort by date.
    points.data.sort((a, b) => a.x - b.x);

    // Collect all the xValues so we can find the range for the x-axis
    xValues.push(...points.data.map(e => e.x));

    return points;
  });

  const chartData = {
    series
  };

  const chartOptions = {
    axisY: {
      labelInterpolationFnc
    },
    axisX: {
      labelInterpolationFnc: value => moment(value).format('MMM YY'),
      type: Chartist.FixedScaleAxis,
      high: Math.max(...xValues),
      low: Math.min(...xValues),
      divisor: 5
    }
  };

  const legendComp = legend ? (<ChartLegend entries={legend} />) : null;

  return (
    <div className="metric-timeseries-graph">
      <ChartistLineGraph data={chartData} options={chartOptions} type="Line" />
      {legendComp}
    </div>
  );
};

MetricTimeseriesGraph.propTypes = {
  timeseries: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
      })
    )
  ).isRequired,
  legend: PropTypes.arrayOf(PropTypes.string),
  labelInterpolationFnc: PropTypes.func
};

MetricTimeseriesGraph.defaultProps = {
  timeseries: [],
  legend: null,
  labelInterpolationFnc: label => abbreviate(label, {integer: false})
};

export default MetricTimeseriesGraph;
