import Icon from './_icon';

// use this tag for beta features
const LabsTag = () => {
  return (
    <span className="labs-tag">
      LABS <Icon icon="beaker" width="14px" height="14px" />
    </span>
  );
};

export default LabsTag;
