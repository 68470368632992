import {Container, Progress} from './_styles';

const SurveyProgressBar = ({currentStep, totalSteps}) => (
  <Container>
    <Progress percentage={currentStep / totalSteps * 100} />
  </Container>
);

SurveyProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired
};

export default SurveyProgressBar;
