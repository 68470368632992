import styled from 'styled-components/macro';

const CLIP_ABOVE_HEIGHT_PX = 400;
const green = '#44bc8f';
const ExpandLink = styled.a`
  color: ${green};
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  padding: 5px 11px;
  border: 1px solid ${green};
  margin: 11px 0;

  &:hover, &:active, &:focus {
    text-decoration: none;
  }

  &:hover {
    color: #fff !important;
    background-color: ${green};
  }

  &:after {
    display: none !important;
  }
`;
const CollapsedContentArea = styled.div`
  max-height: 340px;
  overflow: hidden;
  position : relative;

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%);
    width: 100%;
    height: 4em;
  }
`;

const ExpandedContentArea = styled.div``;

class PotentiallyLongContent extends React.Component {

  static propTypes = {
    children: PropTypes.any
  };

  static defaultProps = {
    children: []
  };

  state = {
    clippingActive: false,
    collapsed: true
  };

  _handleToggleClicked = e => {
    e && e.preventDefault();

    this.setState(prevState => ({collapsed: !prevState.collapsed}));
  };

  _handleRef = ref => {
    if(ref === null) {
      return;
    }

    const {clientHeight} = ref;

    if(clientHeight > CLIP_ABOVE_HEIGHT_PX) {
      this.setState({clippingActive: true});
    }
  };

  render() {
    const {clippingActive, collapsed} = this.state;
    const isClippedAndCollapsed = clippingActive && collapsed;
    const ContentArea = isClippedAndCollapsed ? CollapsedContentArea : ExpandedContentArea;
    let expandMenu = (
      <ExpandLink className="potentially-long-content_toggle" href="#" onClick={this._handleToggleClicked}>
        {`Show ${collapsed ? 'all' : 'fewer'}`}
      </ExpandLink>
    );

    if(!clippingActive) {
      expandMenu = null;
    }

    return (
      <div className="potentially-long-content">
        <ContentArea className={isClippedAndCollapsed
          ? 'collapsed-content-area'
          : 'expanded-content-area'}>
          <div ref={this._handleRef}>
            {this.props.children}
          </div>
        </ContentArea>
        {expandMenu}
      </div>
    );
  }

}

export default PotentiallyLongContent;
