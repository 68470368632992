import {useState, useEffect, useRef, useMemo} from 'react';
import Dropdown from '../_dropdown';
import ListItemsManage from '../_list_items_manage';

const maxNameLength = 30;

const DigestTemplateSettings = ({
  templates,
  emailDigestTemplate,
  onSetDigestTemplate,
  onDidDeleteTemplate,
  onDidUpdateTemplate,
  onWillEditTemplateItem,
  onDidEditTemplateItem
}) => {
  const [templateOptions, setTemplateOptions] = useState([]);
  const [workingTemplates, setWorkingTemplates] = useState(templates);
  const [selectedValue, setSelectedValue] = useState(0);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setSelectedValue(workingTemplates.find(({id}) => id === emailDigestTemplate) ? emailDigestTemplate : 0);
  }, [emailDigestTemplate, workingTemplates]);

  useEffect(() => {
    setWorkingTemplates(templates);
  }, [templates]);

  useEffect(() => {
    const options =
    [{value: 0, label: 'none'}].concat((workingTemplates || []).map(({id, name}) => ({value: id, label: name, dataTestId: `digest-template-${name}`})));

    setTemplateOptions(options);
  }, [workingTemplates]);

  const sortedDigestTypes = useMemo(() => [...workingTemplates].sort((a, b) => a.name.localeCompare(b.name)), [workingTemplates]);

  const handleDeleteTemplate = id => {
    const template = workingTemplates.find(({id: tId}) => tId === id);

    if(!template) {
      return Promise.reject({message: 'Deleting the digest template failed. Template not found.'});
    }

    if(emailDigestTemplate === id) {
      onSetDigestTemplate(0);
    }

    const updatedTemplates = workingTemplates.filter(({id: tId}) => tId !== id);

    setWorkingTemplates(updatedTemplates);
    onDidDeleteTemplate(id);

    return Promise.resolve(updatedTemplates);
  };

  const handlTemplateNameChange = async (id, name) => {
    const trimmedName = name.trim();
    const lowerCaseName = trimmedName.toLowerCase();

    if(trimmedName.length > maxNameLength) {
      return Promise.reject({message: 'The name must be 30 characters or less.'});
    }

    const nameExists = templates.some(({name: tName}) => tName.toLowerCase() === lowerCaseName);

    if(nameExists) {
      return Promise.reject({message: 'That name already exists.'});
    }

    const template = workingTemplates.find(({id: tId}) => tId === id);
    const updatedTemplate = {...template, name: trimmedName};
    const updatedTemplages = workingTemplates.map(t => (t.id === id ? updatedTemplate : t));

    setWorkingTemplates(updatedTemplages);
    onDidUpdateTemplate(updatedTemplate);

    return Promise.resolve(updatedTemplages);
  };

  return (
    <div id="digest-template-settings" className="digest-template-settings">
      <div className="digest-setting-header-with-info" data-tracking-id="digest-setting-articles-label">
        <h4 className="u-pt-m"><strong>Digest Templates</strong></h4>
      </div>
      <div className="u-mb-m">
        <div className="ui-dropdown-panel ui-dropdown-panel--left u-pt-xxs u-mb-m" data-testid="company-settings-digest-articles">
          <h6>Select the Default Template for this Digest</h6>
          <Dropdown
            label=""
            dataTestId="digest-schedule_template-dropdown"
            displayLabel="Select..."
            options={templateOptions}
            selectedValue={selectedValue}
            keyPrefix="group"
            className="btn secondary"
            containerClass="ui-dropdown--left"
            alignMenuLeft={true}
            onOptionClick={onSetDigestTemplate} />
        </div>
      </div>

      <ListItemsManage
        title=""
        listItems={sortedDigestTypes}
        onUpdateName={handlTemplateNameChange}
        onDelete={handleDeleteTemplate}
        hideCreate={true}
        onWillEditItem={onWillEditTemplateItem}
        onDidEditItem={onDidEditTemplateItem} />

    </div>
  );
};

DigestTemplateSettings.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object),
  emailDigestTemplate: PropTypes.number,
  onSetDigestTemplate: PropTypes.func,
  onDidDeleteTemplate: PropTypes.func,
  onDidUpdateTemplate: PropTypes.func,
  onWillEditTemplateItem: PropTypes.func,
  onDidEditTemplateItem: PropTypes.func
};

DigestTemplateSettings.defaultProps = {
  templates: [],
  emailDigestTemplate: null,
  onSetDigestTemplate() {},
  onDidDeleteTemplate() {},
  onDidUpdateTemplate() {},
  onWillEditTemplateItem() {},
  onDidEditTemplateItem() {}
};

export default DigestTemplateSettings;
