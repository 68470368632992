const DigestSettingError = ({message}) => {
  return (
    <div className="digest-settings-error">{message}</div>
  );
};

DigestSettingError.propTypes = {
  message: PropTypes.string
};

DigestSettingError.defaultProps = {
  message: ''
};

export default DigestSettingError;
