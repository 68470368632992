import {DropTarget, DragSource} from 'react-dnd';
import {DragTypes} from '../modules/constants/dnd';
import {withCardDragging} from '../contexts/_cardDragging';
import classNames from 'classnames';
import {handleScrollToLane} from '../modules/lane_utils';
import Icon from './_icon';
import {flow, debounce} from 'lodash';

const turnOffDropInsertHoverCheck = debounce(({cardDraggingContext}) => cardDraggingContext.hoverCardOverBoard({}), 100);

const itemSource = {
  beginDrag({id, name}) {
    return {
      id,
      name
    };
  }
};

const itemTarget = {
  drop(props, monitor) {
    const itemType = monitor.getItemType();

    const {cardDraggingContext, onDropSuccess} = props;

    if(monitor.didDrop()) {
      return;
    }

    const {id: targetBoardId} = props;

    if(cardDraggingContext) {
      if(itemType === DragTypes.BOARD_LANE) {
        const {id: sourceBoardId} = monitor.getItem();

        return cardDraggingContext.onBoardOnBoardDrop({sourceBoardId, targetBoardId});
      }
      else if(itemType === DragTypes.CARD) {
        cardDraggingContext.onDrop({targetCardIndex: Number.MAX_SAFE_INTEGER, targetBoardId, onDropSuccess});
      }
    }
  },

  hover(props, monitor) {
    const {cardDraggingContext, id} = props;

    const itemType = monitor.getItemType();

    if(cardDraggingContext) {
      if(itemType !== DragTypes.CARD) {
        return;
      }

      cardDraggingContext?.hoverCardOverBoard({targetBoardId: id});
      turnOffDropInsertHoverCheck({cardDraggingContext});
    }
  },

  canDrop() {
    return true;
  }
};

function collectItemTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({shallow: true}),
    itemType: monitor.getItemType(),
    item: monitor.getItem()
  };
}

const collectItemSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};

const LaneItem = ({
  id,
  name,
  count,
  connectDropTarget,
  isOver,
  itemType,
  item,
  onToggleLaneVisibility,
  connectDragSource,
  connectDragPreview
}) => {
  const makeLaneVisible = () => {
    handleScrollToLane({id}, false, onToggleLaneVisibility, {behavior: 'smooth', inline: 'end', block: 'end'});
  };

  const reorderHandle = connectDragSource(
    <div
      className="card-dragging_reorder-handle"
      onClick={() => {}}>
      <Icon icon="reorder" />
    </div>
  );

  const countString = count >= 0 ? ` (${count})` : '';

  return connectDropTarget(connectDragPreview(
    <div key={id}
      className={classNames('board-lanes-list-item', {
        'is-over': isOver,
        'is-board-drag': itemType === DragTypes.BOARD_LANE,
        'is-dragging': item?.id === id
      })}
      onClick={makeLaneVisible}>
      <div className="board-lanes-list-item-name">
        {`${name}${countString}`}
      </div>
      {reorderHandle}
    </div>
  ));
};

LaneItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  count: PropTypes.number,
  isOver: PropTypes.bool,
  itemType: PropTypes.string,
  item: PropTypes.object,
  connectDropTarget: PropTypes.func,
  connectDragSource: PropTypes.func,
  onToggleLaneVisibility: PropTypes.func,
  onDropSuccess: PropTypes.func,
  cardDraggingContext: PropTypes.object
};

LaneItem.defaultProps = {
  id: 0,
  name: 'untitled',
  count: null,
  isOver: false,
  itemType: '',
  item: {},
  connectDropTarget() {},
  onToggleLaneVisibility() {},
  onDropSuccess() {},
  cardDraggingContext: null
};

export default withCardDragging(flow(
  DragSource(DragTypes.BOARD_LANE, itemSource, collectItemSource),
  DropTarget([DragTypes.CARD, DragTypes.BOARD_LANE], itemTarget, collectItemTarget)
)(LaneItem));
