import {fetch, post, update, destroy} from '../api_utils';
import {isValidId, serializeQueryString} from '../utils';

// lane management API

export const lanesGet = ({laneOptions = {}, code = 'ApiUtils.lanesGet'}) => {
  // options format:
  //   laneOptions: {
  //     profileId: int,
  //   }
  const {profileId = 0, visibilityGroupId} = laneOptions;

  return new Promise((resolve, reject) => {
    if(_.isEmpty(laneOptions) || !isValidId(profileId)) {
      console.warn(`${code}: invalid options specified: %o`, laneOptions);

      return reject(laneOptions);
    }

    const urlParams = serializeQueryString({visibilityGroupId});

    fetch(`/api/profiles/${profileId}/boards.json${urlParams ? `?${urlParams}` : ''}`, {code})
      .then(({data: lanes}) => {
        console.log(`${code}: lanes loaded for profileId #%o: %o`, profileId, lanes);

        resolve(lanes);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

export const lanesGetByRival = ({laneOptions = {}, code = 'ApiUtils.lanesGet'}) => {
  // options format:
  //   laneOptions: {
  //     rivalId: int,
  //   }
  const {rivalId = 0, visibilityGroupId} = laneOptions;

  return new Promise((resolve, reject) => {
    if(_.isEmpty(laneOptions) || !isValidId(rivalId)) {
      console.warn(`${code}: invalid options specified: %o`, laneOptions);

      return reject(laneOptions);
    }

    const urlParams = serializeQueryString({visibilityGroupId});

    fetch(`/api/rivals/${rivalId}/boards.json${urlParams ? `?${urlParams}` : ''}`, {code})
      .then(({data: lanes}) => {
        console.log(`${code}: lanes loaded for rivalId #%o: %o`, rivalId, lanes);

        resolve(lanes);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

export const laneGetCards = ({laneOptions = {}, code = 'ApiUtils.laneGet'}) => {
  const {laneId = 0, visibilityGroupId} = laneOptions;

  return new Promise((resolve, reject) => {
    if(_.isEmpty(laneOptions) || !isValidId(laneId)) {
      console.warn(`${code}: invalid options specified: %o`, laneOptions);

      return reject(laneOptions);
    }

    const urlParams = serializeQueryString({visibilityGroupId});

    fetch(`/api/boards/${laneId}/cards.json?v=3${urlParams ? `&${urlParams}` : ''}`, {code})
      .then(({data: cards}) => {
        console.log(`${code}: loaded cards for lane: id #%o: %o`, laneId, cards);

        resolve(cards);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

export const laneGet = ({laneOptions = {}, code = 'ApiUtils.laneGet'}) => {
  const {laneId = 0, visibilityGroupId} = laneOptions;

  return new Promise((resolve, reject) => {
    if(_.isEmpty(laneOptions) || !isValidId(laneId)) {
      console.warn(`${code}: invalid options specified: %o`, laneOptions);

      return reject(laneOptions);
    }

    const urlParams = serializeQueryString({visibilityGroupId});

    fetch(`/api/boards/${laneId}.json${urlParams ? `?${urlParams}` : ''}`, {
      code
    }).then(({data: lane}) => {
      console.log(`${code}: loaded lane: id #%o: %o`, laneId, lane);

      resolve(lane);
    }).catch(error => {
      console.error(code, error);

      reject(error);
    });
  });
};

export const laneCreate = ({laneOptions = {}, code = 'ApiUtils.laneCreate'}) => {
  // options format:
  //   laneOptions: {
  //     name: string,
  //     profileId: int,          // optional if rivalId is specified
  //     rivalId: int,            // optional if profileId is specified
  //     comments: {              // optional; array of commentIds for this board
  //       add: int[],
  //       remove: int[]
  //     }
  //     viewOrder: float         // optional
  //   }
  return new Promise((resolve, reject) => {
    if(_.isEmpty(laneOptions) || !laneOptions.name || !(isValidId(laneOptions.profileId) || isValidId(laneOptions.rivalId))) {
      console.warn(`${code}: invalid options specified: %o`, laneOptions);

      return reject(laneOptions);
    }

    post('/api/boards.json', JSON.stringify(laneOptions), {code})
      .then(({data: lane}) => {
        console.log(`${code}: lane with id #%o created: %o`, laneOptions.profileId ? 'profile' : 'rival', lane);

        resolve(lane);
      })
      .catch(error => {
        console.error(`${code}: error: %o, response: %o`, error, error.response);

        reject(error.response.data || {});
      });
  });
};

export const laneUpdate = ({laneOptions = {}, code = 'ApiUtils.laneUpdate'}) => {
  // options format:
  //   laneOptions {
  //     id: int,                 // required
  //     name: string,
  //     rivalId: int,
  //     profileId: int,
  //     comments: {              // optional; array of commentIds for this board
  //       add: int[],
  //       remove: int[]
  //     }
  //     viewOrder: float
  //   }
  return new Promise((resolve, reject) => {
    if(_.isEmpty(laneOptions) || !isValidId(laneOptions.id)) {
      console.warn(`${code}: invalid options specified: %o`, laneOptions);

      return reject(laneOptions);
    }

    update(`/api/boards/${laneOptions.id}.json`, JSON.stringify(laneOptions), {code})
      .then(({data: lane}) => {
        console.log(`${code}: lane with id #%o updated: %o`, lane.id, lane);

        resolve(lane);
      })
      .catch(error => {
        console.error(`${code}: error: %o, response: %o`, error, error.response);

        reject(error.response.data || {});
      });
  });
};

export const laneDelete = ({laneOptions = {}, code = 'ApiUtils.laneDelete'}) => {
  // options format:
  //   laneOptions: {
  //     id: int                  // required
  //   }
  return new Promise((resolve, reject) => {
    if(_.isEmpty(laneOptions) || !isValidId(laneOptions.id)) {
      console.warn(`${code}: invalid options specified: %o`, laneOptions);

      return reject(laneOptions);
    }

    destroy(`/api/boards/${laneOptions.id}.json`, {code})
      .then(({data}) => {
        console.log(`${code}: lane with id #%o deleted successfully`, laneOptions.id);

        resolve(data || {});
      })
      .catch(error => {
        console.error(`${code}: error: %o, response: %o`, error, error.response);

        reject(error.response.data || {});
      });
  });
};
