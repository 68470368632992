import sanitizeHtml from 'sanitize-html';
import classNames from 'classnames';

import {wrapHtml} from '../modules/html_utils';

class Dialogs extends React.Component {

  static propTypes = {
    callbackForMethods: PropTypes.func
  };

  static defaultProps = {
    callbackForMethods() {}
  };

  state = {
    dialogs: []
  };

  componentDidMount() {
    // keep state on a variable to avoid race conditions + callback hell using setState(fn)
    // is there a better way?
    this.dialogStateDialogs = [];

    // set state will render the dialogs
    this.setState({
      dialogs: this.dialogStateDialogs
    });

    this.exportCallableMethodsToParent();
  }

  getNewId = () => {
    if(!this.dialogStateSpawnCount) {
      this.dialogStateSpawnCount = 0;
    }

    return ++this.dialogStateSpawnCount;
  };

  _sanitizeMessage = message => {
    const allowlistedDialogTags = ['b', 'br', 'em', 'i', 'strong', 'u'];
    const allowlistedDialogAttributes = {
      '*': ['data-klue-controlled-markup', 'class', 'id', 'style']
    };

    return sanitizeHtml(message, {
      allowedTags: allowlistedDialogTags,
      allowedAttributes: allowlistedDialogAttributes
    });
  };

  alert = (message = 'Alert', callback, button) => {
    const dialogId = this.getNewId();

    const callbackOkWrap = () => {
      this.remove(dialogId);

      typeof callback === 'function' && callback();
    };

    const content = (
      <div>
        <h3 className="heading-dialog" dangerouslySetInnerHTML={wrapHtml(this._sanitizeMessage(message), false)} />
        <div className="dialog-toolbar">
          <div className="button" onClick={callbackOkWrap} style={{width: '100%'}}>{button || 'OK'}</div>
        </div>
      </div>
    );

    this.create({
      id: dialogId,
      content
    });

    return dialogId;
  };

  _confirm = (
    message = 'Are you sure?',
    okCallback,
    cancelCallback,
    buttonOk,
    buttonCancel,
    bodyContent,
    isRestrictive,
    okButtonDataTrackingId,
    hideCancel
  ) => {
    const dialogId = this.getNewId();

    const okCallbackWrap = () => {
      this.remove(dialogId);

      typeof okCallback === 'function' && okCallback();
    };

    const cancelCallbackWrap = () => {
      this.remove(dialogId);

      typeof cancelCallback === 'function' && cancelCallback();
    };

    const okButtonClassNames = classNames('button', {
      'button--alert': isRestrictive
    });

    const okAttributes = {};

    if(okButtonDataTrackingId) {
      okAttributes['data-tracking-id'] = okButtonDataTrackingId;
    }

    const content = (
      <div>
        <h4 className="heading-dialog" dangerouslySetInnerHTML={wrapHtml(this._sanitizeMessage(message), false)} />
        {bodyContent}
        <div className="row dialog-toolbar">
          {!hideCancel && <div className="col-xs-5">
            <div className="button button--disabled" onClick={cancelCallbackWrap} style={{width: '100%'}}>{buttonCancel || 'Cancel'}</div>
          </div>}
          <div className={classNames('', {'col-xs-7': !hideCancel, 'col-xs-12': hideCancel})}>
            <div className={okButtonClassNames} onClick={okCallbackWrap} style={{width: '100%'}} {...okAttributes}>{buttonOk || 'OK'}</div>
          </div>
        </div>
      </div>
    );

    this.create({
      id: dialogId,
      content
    });

    return dialogId;
  };

  confirm = dialogData => {
    const {message, okCallback, cancelCallback, buttonOk, buttonCancel, bodyContent, okButtonDataTrackingId, hideCancel = false} = dialogData;

    return this._confirm(message, okCallback, cancelCallback, buttonOk, buttonCancel, bodyContent, false, okButtonDataTrackingId, hideCancel);
  };

  confirmRestrictive = dialogData => {
    const {message, okCallback, cancelCallback, buttonOk, buttonCancel, bodyContent, okButtonDataTrackingId, hideCancel = false} = dialogData;

    return this._confirm(message, okCallback, cancelCallback, buttonOk, buttonCancel, bodyContent, true, okButtonDataTrackingId, hideCancel);
  };

  create = dialog => {
    if(!this.dialogStateDialogs) {
      this.dialogStateDialogs = [];
    }

    const existingIndex = this.dialogStateDialogs.findIndex(d => d.id === dialog.id);

    if(existingIndex > -1) {
      this.dialogStateDialogs[existingIndex] = dialog;
    }
    else {
      // blur any focused elements (+ extra blur check for IE11)
      if(('activeElement' in document) && document.activeElement && (typeof document.activeElement.blur === 'function')) {
        document.activeElement.blur();
      }

      this.dialogStateDialogs.push(dialog);
    }

    this.setState({
      dialogs: this.dialogStateDialogs
    }, () => {
      // DEBUG
      console.log('Dialogs.create: created dialog with id: %o', dialog.id);
    });
  };

  remove = (item = null, callback = null) => {
    if(!item) {
      return;
    }

    const id = item.id || item;

    this.dialogStateDialogs = this.dialogStateDialogs.filter(d => id !== d.id);

    this.setState({
      dialogs: this.dialogStateDialogs
    }, () => {
      // DEBUG
      console.log('Dialogs.remove: removed dialog with id: %o', id);

      return typeof callback === 'function' && callback();
    });
  };

  exportCallableMethodsToParent = () => {
    this.props.callbackForMethods({
      getNewId: this.getNewId,
      alert: this.alert,
      confirm: this.confirm,
      confirmRestrictive: this.confirmRestrictive,
      create: this.create,
      remove: this.remove
    });
  };

  renderDialogs = dialogs => {
    if(!dialogs || !dialogs.length) {
      return;
    }

    return dialogs.map(item => {
      const dialogClass = classNames('app-dialog', {
        'app-dialog--wide': item._wideMode,
        'app-dialog--wider': item._widerMode,
        'app-dialog-diff--card-wide app-dialog--grey': item._diffModeWide,
        'app-dialog-diff--card-wider app-dialog--grey': item._diffModeWider,
        [item.extraClass]: item.extraClass !== undefined
      });
      const onBgClickAction = event => {
        if(!event || (event && event.target && (event.target.classList.contains('app-dialog') || event.target.classList.contains('app-dialog_position')))) {
          this.remove(item);
        }
      };

      const extraAttributes = {};

      if(item.closeOnBgClick) {
        extraAttributes.onClick = onBgClickAction;
      }

      return (
        <div className={dialogClass} key={item.id} {...extraAttributes}>
          <div className="app-dialog_position">
            <div className="app-dialog_position_frame">
              {item.content || (<a href="#" onClick={() => this.remove(item)}>No content - Close</a>)}
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    if(!this.state.dialogs.length) {
      return null;
    }

    const dialogs = this.state.dialogs.slice();

    return (
      <div className="app-dialogs">
        {this.renderDialogs(dialogs)}
      </div>
    );
  }

}

export default Dialogs;
