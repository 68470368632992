import {fetch, update} from '../api_utils';
import {isValidId, serializeQueryString} from '../utils';

// profile management API

export const profileGet = (profileOptions = {profileId: 0, name: '', visibilityGroupId: null}, code = 'ApiUtils.profileGet') => {
  // options format:
  //   profileOptions: {
  //     profileId: int,
  //     name: string        // optional; required if profileId not specified
  //     version: int        // optional api version;
  //   }
  const {profileId, name, visibilityGroupId, version} = profileOptions;

  return new Promise((resolve, reject) => {
    if(_.isEmpty(profileOptions) || (!isValidId(profileId) && !name)) {
      console.warn(`${code}: invalid profileId or name specified: profileId #%o, name: %o`, profileId, name);

      return reject(profileId);
    }

    const urlParams = serializeQueryString({visibilityGroupId, v: version});

    fetch(`/api/profiles/${isValidId(profileId, true) ? profileId : name}.json${urlParams ? `?${urlParams}` : ''}`, {code})
      .then(({data: profile}) => {
        if(_.isEmpty(profile)) {
          console.error(`${code}: no profile found for profileId #%o, name: %o`, profileId, name);

          return reject(null);
        }

        console.log(`${code}: loaded profileId #%o: %o`, profile.id, profile);

        resolve(profile);
      })
      .catch(error => {
        console.error(code, error);
        reject(error);
      });
  });
};

export const profileUpdate = ({profileOptions = {}, code = 'ApiUtils.profileUpdate'}) => {
  // options format:
  //   profileOptions: {
  //     id: int,
  //     wideMode: bool,            // optional, toggles wide/narrow board format
  //     isDraft: bool,             // optional, toggles published status for profile (+ rival)
  //     comments: {                // optional, hash with add/remove arrays of commentIds
  //       add: int[],
  //       remove: int[]
  //     }
  //     curators: {                // optional, hash with add/remove arrays of curator userIds
  //       add: int[],
  //       remove: int[]
  //     }
  //    name:                       // optional, new name for the profile
  //    imageUrl:                   // optional, new image url
  //   }
  return new Promise((resolve, reject) => {
    if(_.isEmpty(profileOptions) || !isValidId(profileOptions.id)) {
      console.warn(`${code}: invalid options specified: %o`, profileOptions);

      return reject(profileOptions);
    }

    update(`/api/profiles/${profileOptions.id}.json`, JSON.stringify(profileOptions), {code})
      .then(({data: profile}) => {
        console.log(`${code}: profile #%o: %o`, profile.id, profile);
        resolve(profile);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};
