import {useState} from 'react';
import classNames from 'classnames';
import {isValidUrl} from '../modules/url_utils';

const DigestCustomURL = ({id, initialValue, onDidChangeURL}) => {
  const [url, setURL] = useState(initialValue || '');
  const [isValid, setIsValid] = useState(url === '' || isValidUrl(url));

  const handleURLChange = e => {
    const newURL = e.target.value;

    setURL(newURL);
    onDidChangeURL(newURL);
  };

  const resetToValidURL = () => {
    setIsValid(true);
  };

  const handleValidateURL = () => {
    setIsValid(url === '' || isValidUrl(url));
  };

  const renderInvalidURLMessage = url => {
    if(!url?.trim()?.startsWith('https://')) {
      return 'Please enter a valid URL starting with https://';
    }

    return 'Please enter a valid URL';
  };

  return (
    <div className="digest-custom-url">
      <div className="digest-custom-url-input-section">
        <label className="digest-custom-url-label" htmlFor={`custom_url_${id}`}>Link to:</label>
        <input
          value={url}
          id={`custom_url_${id}`}
          onChange={handleURLChange}
          onFocus={resetToValidURL}
          onBlur={handleValidateURL}
          className={classNames('digest-custom-url-input', {invalid: !isValid})}
          placeholder="e.g. https://www.youtube.com"
          type="text" />
      </div>
      {!isValid && (
        <div className={classNames('custom-url-info', {invalid: !isValid})}>
          {renderInvalidURLMessage(url)}
        </div>
      )}
      {isValidUrl(url) && (
        <div className="custom-url-info">
          <a href={url} target="_blank">Check URL</a>
        </div>
      )}
    </div>
  );
};

DigestCustomURL.propTypes = {
  id: PropTypes.number,
  initialValue: PropTypes.string,
  onDidChangeURL: PropTypes.func
};

DigestCustomURL.defaultProps = {
  id: 0,
  initialValue: '',
  onDidChangeURL() {}
};

export default DigestCustomURL;
