import VisibilityGroup from './_visibility_group';
import Icon from './_icon';
import DraggableItem from './_draggable_item';

const VisibilityGroupDraggable = DraggableItem('VISIBILITY-GROUP-DD');

const VisibilityGroupsEditList = props => {
  const {
    className, visibilityGroups,
    activeEditGroupId, editGroupId,
    onEditGroupClick, onSaveGroupClick, onDeleteGroupClick, onToggleGroupClick,
    onToggleUserDefaultGroupClick, onToggleCardDefaultGroupClick,
    onReorderGroupDrag, onReorderGroupDrop, onReorderGroupMove,
    groupPositions = [], errorText
  } = props;
  const orderedGroups = [];

  if(!visibilityGroups) {
    return null;
  }

  groupPositions.forEach(groupId => {
    orderedGroups.push(visibilityGroups.find(g => g.id === groupId));
  });

  const groupNodes = orderedGroups.map((group, index) => {
    const {id: groupId} = group;
    const isActive = (groupId === activeEditGroupId);
    const isEditing = (groupId === editGroupId);
    const dragHandle = (
      <div className="visibility-group_icon visibility-group_icon--reorder">
        <Icon icon="reorder" width="20" height="20" />
      </div>
    );

    return (
      <VisibilityGroupDraggable
        key={`group_${groupId}`}
        id={groupId}
        canDrag={true}
        canDrop={true}
        onBeginDrag={onReorderGroupDrag}
        onEndDrag={() => onReorderGroupDrag(false)}
        onHandleHover={onReorderGroupMove}
        onHandleDrop={onReorderGroupDrop}
        dragHandle={dragHandle}
        droppableCardIndex={index}>
        <VisibilityGroup
          group={group}
          isActive={isActive}
          isEditing={isEditing}
          onToggle={onToggleGroupClick}
          onToggleUserDefault={onToggleUserDefaultGroupClick}
          onToggleCardDefault={onToggleCardDefaultGroupClick}
          onEdit={e => onEditGroupClick(group, e)}
          onSave={(name, e) => onSaveGroupClick(group, name, e)}
          onDelete={e => onDeleteGroupClick(group, e)}
          onCancel={e => onEditGroupClick(null, e)}
          modifier={'title reorder'}
          label={group.name || '(Untitled)'}
          errorText={isEditing ? errorText : ''} />
      </VisibilityGroupDraggable>
    );
  });

  if(groupNodes.length) {
    return (
      <div className={className}>
        {groupNodes}
      </div>
    );
  }

  return (
    <p className="no-visibility-groups-message">
      You don&apos;t have any visibility groups. 😢
    </p>
  );
};

VisibilityGroupsEditList.propTypes = {
  className: PropTypes.string,
  errorText: PropTypes.string,
  visibilityGroups: PropTypes.arrayOf(PropTypes.object),
  activeEditGroupId: PropTypes.number,
  editGroupId: PropTypes.number,
  groupPositions: PropTypes.arrayOf(PropTypes.number),
  onEditGroupClick: PropTypes.func.isRequired,
  onSaveGroupClick: PropTypes.func.isRequired,
  onDeleteGroupClick: PropTypes.func.isRequired,
  onReorderGroupDrag: PropTypes.func.isRequired,
  onReorderGroupDrop: PropTypes.func.isRequired,
  onReorderGroupMove: PropTypes.func.isRequired,
  onToggleGroupClick: PropTypes.func.isRequired,
  onToggleUserDefaultGroupClick: PropTypes.func.isRequired,
  onToggleCardDefaultGroupClick: PropTypes.func.isRequired
};

VisibilityGroupsEditList.defaultProps = {
  className: '',
  errorText: '',
  visibilityGroups: null,
  activeEditGroupId: 0,
  editGroupId: 0,
  groupPositions: [],
  onEditGroupClick() {},
  onSaveGroupClick() {},
  onDeleteGroupClick() {},
  onReorderGroupDrag() {},
  onReorderGroupDrop() {},
  onReorderGroupMove() {},
  onToggleGroupClick() {},
  onToggleUserDefaultGroupClick() {},
  onToggleCardDefaultGroupClick() {}
};

export default VisibilityGroupsEditList;
