import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {EXTERNAL_LINK_CLASSNAME} from '../../../modules/constants/editor';

function clipContent(original, maxLength) {
  if(original.length <= maxLength) {
    return (
      <span>{original}</span>
    );
  }

  return (
    <span>{`${original.substring(0, maxLength - 1).trim()}`}&hellip;</span>
  );
}

const OnlineArticle = ({lead, source, url, headline, publishedDate}) => { // eslint-disable-line react/no-multi-comp
  let leadComponent = null;
  let sourceComponent = null;

  if(lead !== null) {
    leadComponent = (
      <p className="online-article_lead">{clipContent(lead, 130)}</p>
    );
  }

  if(source !== null) {
    sourceComponent = (
      <span className="online-article_source">
        {source}
      </span>
    );
  }

  return (
    <div className="online-article">
      <p className="online-article_headline">
        <a href={url} className={EXTERNAL_LINK_CLASSNAME}>
          {clipContent(headline, 75)}
        </a>
      </p>
      {leadComponent}
      <p className="online-article_meta-line">
        <span className="online-article_published-date">
          {`Published ${moment(publishedDate).fromNow()}`}
        </span>
        {sourceComponent}
      </p>
    </div>
  );
};

OnlineArticle.propTypes = {
  headline: PropTypes.string.isRequired,
  lead: PropTypes.string,
  url: PropTypes.string.isRequired,
  publishedDate: PropTypes.string.isRequired,
  source: PropTypes.string
};

OnlineArticle.defaultProps = {
  headline: '',
  lead: null,
  url: '#',
  publishedDate: '',
  source: null
};

export default OnlineArticle;
