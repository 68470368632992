import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {canEditDigest} from '../modules/digest_utils';
import classNames from 'classnames';

function isFavorite(favoriteList = [], postId) {
  return Boolean(favoriteList.find(favorite => favorite.postId === postId));
}

function digestTypeSerializer(postId, data = [], user = {}, company = {}) {
  return data.map(({digestType, draftDigest}) => {
    const value = isFavorite(draftDigest.favorites, postId);
    const isLocked = !canEditDigest(draftDigest, user, company);

    return {
      digestTypeId: digestType.id,
      digestId: draftDigest.id,
      postId,
      draftDigest,
      title: digestType.name,
      isFavorite: value,
      isLocked,
      value
    };
  });
}

function digestTypesUpdateSerializer(digestTypes = []) {
  return digestTypes.filter(item => item.isFavorite !== item.value);
}

function PostDigestTypes({
  postId,
  digestTypesData,
  user,
  company,
  onConfirm,
  onCancel
}) {
  const [digestTypes, setDigestTypes] = useState([]);

  useEffect(() => {
    setDigestTypes(digestTypeSerializer(postId, digestTypesData, user, company));
  }, [postId, digestTypesData, user, company]);

  useEffect(() => {
    const keyCheck = event => {
      if(event?.key === 'Escape') {
        return onCancel();
      }
    };

    window.addEventListener('keydown', keyCheck);

    return () => window.removeEventListener('keydown', keyCheck);
  }, [onCancel]);

  const handleCheck = useCallback(digestType => {
    const digestTypeIndex = digestTypes.findIndex(item => (
      item.digestTypeId === digestType.digestTypeId)
    );

    if(digestTypeIndex === -1) {return;}

    const newDigestTypes = [...digestTypes];

    newDigestTypes[digestTypeIndex].value = !newDigestTypes[digestTypeIndex].value;

    return setDigestTypes(newDigestTypes);
  }, [digestTypes]);

  const handleConfirm = useCallback(async () => {
    const digestTypesUpdateList = digestTypesUpdateSerializer(digestTypes);

    return onConfirm(digestTypesUpdateList);
  }, [onConfirm, digestTypes]);

  const digestTypesList = useMemo(() => (
    digestTypes.map(item => {
      const {isLocked, digestTypeId, title, value} = item;

      const digestTypeItemClass = classNames({
        'post-digest-types-item-disabled': isLocked
      });
      const digestTypeItemHandle = () => (isLocked ? null : handleCheck(item));

      return (
        <li key={`digestType-${digestTypeId}`} className={digestTypeItemClass}>
          <label htmlFor={`digestType-checkbox-${digestTypeId}`}>
            <input
              id={`digestType-checkbox-${digestTypeId}`}
              title={title}
              name={title}
              type="checkbox"
              checked={value}
              disabled={isLocked}
              onChange={digestTypeItemHandle} />

            <span>{title}</span>

            {isLocked ? (
              <i className="fa fa-lock" />
            ) : null}
          </label>
        </li>
      );
    })
  ), [digestTypes, handleCheck]);

  return (
    <div className="post-digest-types">
      <h3>Assign to Digests</h3>

      <ul className="post-digest-types-list">
        {digestTypesList}
      </ul>

      <footer className="post-digest-types-footer">
        <button className="button button--medium button--alt" onClick={onCancel}>Cancel</button>
        <button className="button button--medium button--green" onClick={handleConfirm}>Save</button>
      </footer>
    </div>
  );
}

PostDigestTypes.propTypes = {
  postId: PropTypes.number,
  digestTypesData: PropTypes.arrayOf(
    PropTypes.shape({
      digestType: PropTypes.object,
      draftDigest: PropTypes.object,
      favorites: PropTypes.arrayOf(PropTypes.object)
    })
  ),
  user: PropTypes.object,
  company: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

PostDigestTypes.defaultProps = {
  postId: null,
  digestTypesData: [],
  user: {},
  company: {}
};

export default PostDigestTypes;
