import Board from './_board';
import BoardVitals from './_board_vitals';
import BoardEditor from './_board_editor';
import BoardAddPanel from './_board_add_panel';
import Snapshots from './_snapshots';
import Lanes from './_lanes';
import DropTargetScroller from './_drop_target_scroller';

import {userCanCurate} from '../modules/roles_utils';
import {wait, isValidId, whichTransitionEvent} from '../modules/utils';
import {maybeLaunchModalCardView} from '../modules/card_utils';
import {uiDelays} from '../modules/constants/ui';

import classNames from 'classnames';
import {Link, withRouter} from 'react-router-dom';
import {withCardDragging} from '../contexts/_cardDragging';

const BoardWithRouter = withRouter(Board);

class BoardList extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    profileFilters: PropTypes.object
  };

  static propTypes = {
    swimlanesRef: PropTypes.object,
    boards: PropTypes.arrayOf(PropTypes.object).isRequired,
    lanesLoading: PropTypes.bool,
    boardsRefreshCount: PropTypes.object,
    activeBoardId: PropTypes.number,
    wideMode: PropTypes.bool,
    battlecardCardsOnly: PropTypes.bool,
    battlecardCardIds: PropTypes.object,
    onBoardCreate: PropTypes.func,
    onBoardUpdate: PropTypes.func,
    onBoardDelete: PropTypes.func.isRequired,
    onInsertLaneAfter: PropTypes.func.isRequired,
    onToggleFreshCardExpanded: PropTypes.func,
    rivals: PropTypes.arrayOf(PropTypes.object),
    profileEditMode: PropTypes.bool,
    freshnessMode: PropTypes.bool,
    freshCardExpanded: PropTypes.object,
    user: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    builderMode: PropTypes.bool,
    onToggleCardOnBattlecard: PropTypes.func,
    onShowCardMeta: PropTypes.func,
    maxBattlecardCards: PropTypes.number,
    onGetCommentSourceLink: PropTypes.func,
    emphasizeVitalSettings: PropTypes.bool,
    boardsCount: PropTypes.number,
    onScratchpadDismiss: PropTypes.func,
    onBoardRefresh: PropTypes.func,
    onCardRefresh: PropTypes.func,
    onBoardsLoad: PropTypes.func,
    onToggleLane: PropTypes.func,
    onToggleCuratorTools: PropTypes.func,
    onErrorMessage: PropTypes.func,
    onSettingsExpanded: PropTypes.func,
    moveCardToLane: PropTypes.func,
    onProfileUpdated: PropTypes.func,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    lastCardUpdated: PropTypes.object,
    previewingId: PropTypes.number,
    onClickOutsideOfVitals: PropTypes.func,
    onProfileRefresh: PropTypes.func,
    isCuratorToolsCollapsed: PropTypes.bool,
    feedCollapsed: PropTypes.bool,
    cardDraggingContext: PropTypes.object,
    updatingBoardDrags: PropTypes.bool,
    onLanesDropHover: PropTypes.func
  };

  static defaultProps = {
    swimlanesRef: null,
    boards: [],
    lanesLoading: false,
    boardsRefreshCount: {},
    activeBoardId: null,
    wideMode: false,
    battlecardCardsOnly: false,
    battlecardCardIds: null,
    onBoardCreate: null,
    onBoardUpdate: null,
    onBoardDelete: null,
    onToggleFreshCardExpanded() {},
    rivals: [],
    profileEditMode: false,
    freshnessMode: false,
    freshCardExpanded: {},
    user: null,
    users: {},
    builderMode: false,
    onToggleCardOnBattlecard: null,
    onShowCardMeta: null,
    maxBattlecardCards: 8,
    onGetCommentSourceLink: null,
    emphasizeVitalSettings: false,
    boardsCount: null,
    lastCardUpdated: {},
    onScratchpadDismiss() {},
    onBoardRefresh() {},
    onCardRefresh() {},
    onBoardsLoad() {},
    onToggleLane() {},
    onToggleCuratorTools() {},
    onErrorMessage() {},
    onSettingsExpanded() {},
    moveCardToLane() {},
    onProfileUpdated() {},
    history: {},
    location: {},
    previewingId: null,
    onClickOutsideOfVitals() {},
    onProfileRefresh() {},
    isCuratorToolsCollapsed: false,
    feedCollapsed: false,
    cardDraggingContext: null,
    updatingBoardDrags: false,
    onLanesDropHover() {}
  };

  state = {
    editMode: false,
    activeBoardId: 0, // 0: no board; -1: add new board; >0: specific boardId
    showAddCardForm: false,
    cardTemplates: [],
    cardSnapshotsId: 0,
    laneCountRefresh: 0
  };

  componentDidMount() {
    console.log('BoardList.componentDidMount: props: %o', this.props);

    this._isMounted = true;
    this._scrollingToCardFlag = false;
    this._scrollToCardTimeoutStart = -1;
    this._boardsLoaded = false;
    this._gutterWidth = 12;
    this._collapsedGutterWidth = 3;
    this._collapsedBoardWidth = 30;
    this._baseBoardWidth = 320;
    this._wideBoardWidth = 500;
    this._scrollTimer = null;

    if(userCanCurate({user: this.props.user})) {
      klueMediator.subscribe(
        'klue:profile:scrollProfileToNewLane',
        this.scrollProfileToNewLane
      ); // triggered by ProfileToolbar
      klueMediator.subscribe(
        'klue:profile:forceRefreshBoardCounts',
        this.forceRefreshBoardCounts
      ); // triggered by ProfileToolbar
    }

    this._checkForScrollBoardToCard();
    this.registerLinksListenerEvent();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!userCanCurate({user: nextProps.user})) {
      return;
    }

    if(
      this._boardsLoaded &&
      nextProps.profileEditMode !== this.props.profileEditMode
    ) {
      if(nextProps.profileEditMode) {
        // toggling into edit mode, unbind click handlers
        this.toggleLinkClickHandlers(false);
      }
      else {
        // toggling out, rebind
        this.toggleLinkClickHandlers(true, true);
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.registerLinksListenerEvent();

    if(this.props.location !== prevProps.location) {
      this._checkForScrollBoardToCard();
    }
  }

  componentWillUnmount() {
    this.toggleLinkClickHandlers(false);

    klueMediator.remove('klue:profile:filterUpdatedSince');
    klueMediator.remove('klue:profile:filterSearch');

    if(userCanCurate({user: this.props.user})) {
      klueMediator.remove('klue:profile:scrollProfileToNewLane');
      klueMediator.remove('klue:profile:forceRefreshBoardCounts');
    }

    const {boardListRef: element} = this;

    element &&
      element.removeEventListener('click', this.handleCardLinksListener, true);

    this._isMounted = false;
  }

  boards = {};

  registerLinksListenerEvent = () => {
    const {boardListRef: element} = this;

    element &&
      element.addEventListener('click', this.handleCardLinksListener, {
        capture: true
      });
  };

  /**
   * Detect internal card links
   * If the target card id is in the current board, it triggers the scrollBoardToCard function
   * If the target card id is NOT in the current board, it should push the router /card/:id
   *
   * @param {object} event Mouse Event
   * @memberof BoardList
   * @returns {undefined}
   */
  handleCardLinksListener = event => {
    const {target} = event;
    const isDropDownMenuClick = target.closest('.ui-dropdown-menu');

    if(isDropDownMenuClick) {
      return;
    }

    const {history} = this.props;

    maybeLaunchModalCardView(event, history);
  };

  _getActiveCardIdFromPath = () => {
    const {location: {pathname = ''} = {}} = window || {};
    const path = pathname.toLowerCase().replace(/\/$/, ''); // strip trailing slash if present
    const [, activeCardId] =
      /\/profile\/\d+\/(?:edit|view)\/card\/(\d+)\/?(?!edit)/i.exec(path) || [];

    return Number(activeCardId || -1);
  };

  _checkForScrollBoardToCard = () => {
    const activeCardId = this._getActiveCardIdFromPath();
    const {activeBoardId} = this.props;

    if(activeBoardId && activeCardId) {
      // navigate to specified card (if any)
      this.scrollBoardToCard(activeBoardId, activeCardId);
    }
    else {
      this.checkForScrollToBoardAndCard();
    }
  };

  _parseTarget = target => {
    if(!target || target.indexOf('/') < 0) {
      console.warn(
        'BoardList._parseTarget: invalid board/card target detected: %o',
        target
      );

      return [];
    }

    // split requested board/card target into its respective chunks
    return target ? target.split('/').map(i => parseInt(i, 10)) : [];
  };

  _isCollapsedLane = laneId => {
    const profile = this._getProfile();

    if(_.isEmpty(profile)) {
      return false;
    }

    const collapsedLanes =
      this.context.utils.getCollapsedLanes(profile.id) || [];

    return collapsedLanes.includes(laneId) && !this._isFilteringProfile();
  };

  _isFilteringProfile = () => {
    const {profileEditMode} = this.props;
    const {profileFilters} = this.context;

    return profileEditMode
      ? false
      : Boolean(profileFilters.q);
  };

  _getProfile = () => {
    const {profile = {}} = this.context.utils.rival || {};

    return profile;
  };

  // TODO: remove this client-side filtering, instead call api with appropriate params to get just
  // consumer visible boards
  _getConsumerVisibleBoards = (boards = this.props.boards) => {
    const {user, profileEditMode} = this.props;

    // NOTE: scratchpad should already be excluded (filtered by parent Profile compnent)
    return boards.filter(board => {
      if(
        (!userCanCurate({user}) || !profileEditMode) &&
        !(board.cards && board.cards.length)
      ) {
        // hide empty boards or boards with no published cards (#1237) from consumer role
        return false;
      }

      return true;
    });
  };

  refreshBoardsIfNeeded = boards => {
    const {cardDraggingContext, onProfileRefresh} = this.props;
    const {getAffectedBoards} = cardDraggingContext;

    const affectedBoards = getAffectedBoards();
    const boardsToRefresh = boards.filter(b => affectedBoards.includes(b.id)).map(b => b.id);

    if(boardsToRefresh.length) {
      onProfileRefresh(boardsToRefresh);
    }
  };

  handleLaneCardCountsChanged = () => {
    this.setState(prev => ({laneCountRefresh: prev.laneCountRefresh + 1}));
  };

  toggleLaneVisibility = (board = null, toggleAll = false) => {
    return new Promise((resolve, reject) => {
      const profile = this._getProfile();

      if(!profile || !board) {
        return reject([]);
      }

      const isCollapsing = !this._isCollapsedLane(board.id);
      const {profileEditMode, onToggleLane, boards = []} = this.props;
      const delay =
        profileEditMode && isCollapsing ? uiDelays.profileLaneToggle : 0;

      if(toggleAll) {
        this.refreshBoardsIfNeeded(boards);

        const boardsIds = boards.map(b => b.id);

        onToggleLane({
          profile,
          boards: boardsIds,
          delay,
          force: isCollapsing ? 'close' : 'open'
        }).then(() => resolve());
      }
      else {
        this.refreshBoardsIfNeeded([board]);

        onToggleLane({
          profile,
          boards: [board.id],
          delay
        }).then(() => resolve());
      }
    });
  };

  forceRefreshBoardCounts = () => this.props.onBoardsLoad().then(this.handleBoardsLoaded);

  swapBoards = (index, dir) => {
    const boards = this._getConsumerVisibleBoards();
    const selected = boards[index];
    const target = boards[index + dir];
    const selectedOrder = selected.viewOrder;

    console.log(
      'BoardList.swapBoards: swapping boards: selected: %o, target: %o',
      selected,
      target
    );

    selected.viewOrder = target.viewOrder;
    target.viewOrder = selectedOrder;

    [selected, target].map(b =>
      this.props.onBoardUpdate(b, () => {
        const movedBoard = ReactDOM.findDOMNode(this.boards[selected.id]);

        if(movedBoard) {
          movedBoard.scrollIntoView({behavior: 'smooth'});
        }
      })
    );
  };

  handleBoardMove = (board, index, dir) => {
    console.log(
      'BoardList.handleBoardMove: board: %o, index: %o, dir: %o',
      board,
      index,
      dir < 0 ? 'left' : 'right'
    );

    const boards = this._getConsumerVisibleBoards();
    let canMove = false;

    // No moving possible if it's the only board
    if(boards.length === 1) {
      return;
    }

    if(index > 0 && index < boards.length - 1) {
      canMove = true;
    }
    else if(index === boards.length - 1 && dir < 0) {
      // last board moving left
      canMove = true;
    }
    else if(index === 0 && dir > 0) {
      // first board moving right
      canMove = true;
    }

    if(canMove) {
      // TODO: would be nice to eventually remove this...
      if(boards[index].viewOrder === boards[index + dir].viewOrder) {
        // handle bad/outdated viewOrder data from older profiles; do a full board reorder
        // NOTE: scratchpad lives at index 0
        const deferreds = [];
        const {onBoardUpdate} = this.props;

        console.warn(
          'BoardList.handleBoardMove: found invalid board viewOrders, cleaning up...'
        );

        for(let i = 0; i < boards.length; i++) {
          const boardToUpdate = boards[i];

          boardToUpdate.viewOrder = i + 1.0; // increment viewOrder by 1 to account for scratchpad

          deferreds.push(onBoardUpdate(boardToUpdate));
        }

        Promise.all(deferreds).then(() => this.swapBoards(index, dir));
      }
      else {
        this.swapBoards(index, dir);
      }
    }
  };

  toggleLinkClickHandlers = (bindMode, unbindFirst) => {
    if(!this._boardsLoaded) {
      return;
    }

    const boards = document.querySelectorAll(
      'div.board-list div.board-body ul.card-list'
    );
    const action = bindMode ? 'addEventListener' : 'removeEventListener';

    for(let i = 0; i < boards.length; i++) {
      const board = boards[i];

      if(unbindFirst) {
        board.removeEventListener('click', this.handleLinkClick);
      }

      board[action]('click', this.handleLinkClick);
    }
  };

  handleLinkClick = event => {
    // make all links in rich text card content (non-edit-mode) open in a new window (kluein/klue#741)
    let target = event ? event.target : null;

    if(target?.classList.contains('card-tag')) {
      return;
    }

    const parentLink = target && target.closest('a');

    if(parentLink) {
      target = parentLink;
    }

    // required so the tabs can't navigate us via `window.opener`
    // security issue: https://developers.google.com/web/tools/lighthouse/audits/noopener
    this.rel = 'noreferrer';

    if(
      target &&
      target.nodeName === 'A' &&
      target.hasAttribute('href') &&
      !target.hasAttribute('data-action')
    ) {
      // suppress normal link opening behaviour
      event.preventDefault();

      const targetUrl = target.getAttribute('href');

      if(targetUrl === '#') {
        return;
      }

      try {
        const win = window.open(targetUrl, '_blank');

        // some popup blockers appear to return null for window.open
        if(win) {
          // try to nullify `window.opener` so the new tab can't navigate us
          // security issue: https://developers.google.com/web/tools/lighthouse/audits/noopener
          win.opener = null;
        }
      }
      catch(error) {
        console.warn(
          'BoardList.handleLinkClick: error opening link: %o',
          error
        );
      }
    }
  };

  checkForScrollToBoardAndCard = () => {
    if(
      !this._scrollingToCardFlag &&
      window.location.hash &&
      window.location.hash.length > 1
    ) {
      const target = window
        .decodeURIComponent(window.location.hash)
        .substring(1);
      const [boardId, cardId] = this._parseTarget(target);

      this.scrollBoardToCard(boardId, cardId);
    }
  };

  handleBoardsLoaded = () => {
    this._boardsLoaded = true;

    const {user} = this.props;
    const visibleBoards = this._getConsumerVisibleBoards();

    console.log(
      'BoardList.handleBoardsLoaded: visibleBoards: %o',
      visibleBoards
    );

    if(!userCanCurate({user})) {
      klueMediator.publish(
        'klue:profile:boards:updateCount',
        visibleBoards.length
      );
    }

    this.toggleLinkClickHandlers(true);
    this.checkForScrollToBoardAndCard();
  };

  scrollProfileToNewLane = () => {
    const newLaneEl = document.querySelector('div.board--new');

    if(newLaneEl) {
      newLaneEl.scrollIntoView({behavior: 'smooth', inline: 'center'});
    }
  };

  scrollToCardRetry = (
    boardId = 0,
    cardId = 0,
    container = null,
    onlyInLane = false
  ) => {
    if(!boardId || !cardId || (onlyInLane && !container)) {
      return;
    }

    const timeOutMs = 100;
    const thresholdMs = 20000;
    const dateNow = new Date();

    // retry for no longer than thresholdMs
    if(dateNow - this._scrollToCardTimeoutStart < thresholdMs) {
      // most likely still loading, wait 100ms & attempt to re-scroll to card
      this._scrollTimer = setTimeout(() => {
        return onlyInLane
          ? this.scrollToCardInLane(boardId, cardId, container)
          : this.scrollBoardToCard(boardId, cardId);
      }, timeOutMs);
    }
    else {
      this._scrollToCardTimeoutStart = -1;
    }
  };

  // scroll the selected lane vertically to the requested card + highlight
  scrollToCardInLane = (boardId = 0, cardId = 0, boardBody = null) => {
    if(!boardId || !cardId) {
      return;
    }

    // refresh card DOM element here in case parent lane was collapsed initially (and replaced)
    const card = document.querySelector(`#c${cardId}`);

    // scroll board vertically to card
    if(!card || (card && card.classList.contains('placeholder'))) {
      return this.scrollToCardRetry(boardId, cardId, boardBody, true);
    }

    // card loaded, clear timer
    clearTimeout(this._scrollTimer);

    const duration = 800;

    // detect if we've completed scrolling to card, then trigger card source animation
    // NOTE: using raw IntersectionObserver (or polyfill); doesn't need to use our wrapper Observer component (overkill)
    const intersectionObserver = new IntersectionObserver(async ([entry]) => {
      if(entry.isIntersecting) {
        card.addEventListener(whichTransitionEvent(), async () => {
          await wait(duration);

          card.classList.remove('spotlight');

          const profileUrlChunks = (window.location.href || '').split('#');

          if(profileUrlChunks.length > 1) {
            window.history.replaceState({}, '', profileUrlChunks[0]);
          }
        });

        card.classList.add('spotlight');

        this._scrollingToCardFlag = false;
        this._scrollToCardTimeoutStart = -1;

        intersectionObserver.disconnect();
      }
    });

    intersectionObserver.observe(card);

    if(card) {
      // scroll swimlane vertically to card
      const cardItem = document.querySelector(`[data-card-id="${cardId}"]`);

      cardItem && cardItem.scrollIntoView({behavior: 'smooth'});
    }
  };

  // scroll the entire board horizontally to the requested lane/card (expands collapsed lane if necessary)
  scrollBoardToCard = async (boardId = 0, cardId = 0) => {
    if(!isValidId(boardId) || !isValidId(cardId)) {
      return;
    }

    const {swimlanesRef, boards, isCuratorToolsCollapsed} = this.props;

    if(this._scrollToCardTimeoutStart < 0) {
      this._scrollToCardTimeoutStart = new Date();
    }

    this._scrollingToCardFlag = true;

    if(!swimlanesRef) {
      // if props.swimlanesRef hasn't propagated down the tree yet, retry
      return this.scrollToCardRetry(boardId, cardId);
    }

    // navigate to specified card (if any)
    const isCollapsed = this._isCollapsedLane(boardId);
    const scrollToLaneMs = 1200;
    let board = swimlanesRef.querySelector(`#b${boardId}`);

    if(!board) {
      // board not loaded yet, retry after delay
      return this.scrollToCardRetry(boardId, cardId);
    }

    if(isCollapsed) {
      // toggle lane to expanded state if collapsed (also introduces a slight delay for animation to complete)
      this.toggleLaneVisibility(boards.find(b => b.id === boardId));

      await wait(uiDelays.profileLaneToggle);

      // refresh lane DOM element since it was collapsed initially
      board = swimlanesRef.querySelector(`#b${boardId}`);
    }

    const boardBody = board.querySelector('div.board-body');
    const swimlane = board.parentNode;

    if(!boardBody) {
      this._scrollingToCardFlag = false;
      this._scrollToCardTimeoutStart = -1;

      // at this point the board has been found; if boardBody is not here for some reason - terminate now
      return;
    }

    // detect if we've completed scrolling to card, then trigger card source animation
    // NOTE: using raw IntersectionObserver (or polyfill); doesn't need to use our wrapper Observer component (overkill)
    const intersectionObserver = new IntersectionObserver(async ([entry]) => {
      if(entry.isIntersecting) {
        // card scroll delay prior to displaying sources popup (otherwise in-lane vertical alignment goes sideways)
        await wait(scrollToLaneMs);

        this.scrollToCardInLane(boardId, cardId, boardBody);
        swimlane.classList.remove('swimlane--scrollMargin');

        intersectionObserver.disconnect();
      }
    });

    intersectionObserver.observe(board);

    if(board) {
      // scroll board horizontally to requested lane
      if(isCuratorToolsCollapsed) {
        board.scrollIntoView({behavior: 'smooth', inline: 'center'});

        return;
      }

      // workaround to keep the fouced lane centered when the curator tools is open
      swimlane.classList.add('swimlane--scrollMargin');
      swimlane.scrollIntoView({behavior: 'smooth', inline: 'center'});
    }
  };

  handleBoardSave = (board, boardName, onComplete) => {
    if(board) {
      Object.assign(board, {
        prevName: board.name,
        name: boardName
      });

      this.props.onBoardUpdate(board, onComplete);
    }
    else {
      this.props.onBoardCreate(boardName, onComplete);
    }
  };

  setEditMode = (editMode, boardId = null) => {
    const updatedState = {editMode};

    if(boardId !== null) {
      updatedState.activeBoardId = boardId;
    }

    const toggleEditMode = () => this.setState(updatedState);

    if(
      boardId !== null &&
      boardId !== this.state.activeBoardId &&
      this.state.showAddCardForm
    ) {
      // creating a new board or editing another board title, warn user if they're editing a new card (#1852)
      this.context.utils.dialog.confirm({
        message: 'You have an unsaved new card. Do you want to discard it?',
        okCallback() {
          updatedState.showAddCardForm = false;

          toggleEditMode();
        }
      });
    }
    else {
      toggleEditMode();
    }
  };

  isEditingBoard = boardId => {
    return this.state.editMode && this.state.activeBoardId === boardId;
  };

  showAddCard = boardId => {
    return this.state.showAddCardForm && this.state.activeBoardId === boardId;
  };

  toggleSnapshotsClick = (cardSnapshotsId = 0) => {
    this.setState({cardSnapshotsId}, () => {
      if(cardSnapshotsId) {
        const selectedCard = document.getElementById(`c${cardSnapshotsId}`);

        if(selectedCard) {
          selectedCard.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
      }
    });
  };

  renderBoard = (board, index, boardPos, editorProps, boardsLoadedHandler) => {
    const profile = this._getProfile();

    if(!profile) {
      return;
    }

    const {
      swimlanesRef,
      user,
      users,
      rivals,
      profileEditMode,
      wideMode,
      battlecardCardsOnly,
      battlecardCardIds,
      builderMode,
      maxBattlecardCards,
      lastCardUpdated,
      onBoardRefresh,
      onCardRefresh,
      onBoardDelete,
      onScratchpadDismiss,
      onToggleCardOnBattlecard,
      onShowCardMeta,
      onGetCommentSourceLink,
      moveCardToLane,
      boards,
      previewingId,
      emphasizeVitalSettings,
      cardDraggingContext,
      boardsRefreshCount,
      onInsertLaneAfter,
      onToggleFreshCardExpanded,
      freshCardExpanded
    } = this.props;
    const {cardTemplates, cardSnapshotsId} = this.state;
    const isEditingBoard = this.isEditingBoard(board.id);
    const boardsLoaded = Boolean(boardsLoadedHandler);
    const boardClass = classNames('swimlane ui-boards', {
      'board--editing': isEditingBoard,
      'board--empty': boardsLoaded && !(board.cards && board.cards.length),
      'swimlane--dim': emphasizeVitalSettings
    });
    const collapsed = this._isCollapsedLane(board.id);
    const refreshCount = boardsRefreshCount[board.id] ?? 0;
    const useBoardWidth = wideMode ? this._wideBoardWidth : this._baseBoardWidth;
    const viewportLoadMargin = useBoardWidth + this._gutterWidth;
    const focusedCardId = this._getActiveCardIdFromPath();
    const BoardComponent = collapsed ? Board : BoardWithRouter;
    let snapshotsRegion;

    if(cardSnapshotsId && board.cards.find(c => c.id === cardSnapshotsId)) {
      const {utils: {rival}} = this.context;

      snapshotsRegion = (
        <Snapshots
          cardId={cardSnapshotsId}
          rival={rival}
          user={user}
          users={users}
          leftAdjustPx={useBoardWidth + 1}
          onToggleClick={this.toggleSnapshotsClick} />
      );
    }

    const handleScrollUp = () => {
      document.getElementById(`board_body_${board.id}`)?.scrollBy(0, cardDraggingContext?.isCompressedCardDraggingOn ? 12 : 25);
    };

    return (
      <div key={board.id} className={boardClass} style={boardPos}>
        <BoardComponent
          key={`board-${board.id}`}
          cardDraggingContext={cardDraggingContext}
          swimlanesRef={swimlanesRef}
          index={index >= 0 ? index : -1}
          isLastBoard={Boolean(boardsLoadedHandler)}
          rivals={rivals}
          board={board}
          refreshCount={refreshCount}
          user={user}
          onBoardMove={this.handleBoardMove}
          onBoardDelete={onBoardDelete}
          onBoardsLoaded={boardsLoadedHandler}
          onLaneCountRefresh={this.handleLaneCardCountsChanged}
          onInsertLaneAfter={onInsertLaneAfter}
          showAddCardForm={this.showAddCard(board.id)}
          editMode={isEditingBoard}
          battlecardCardsOnly={battlecardCardsOnly}
          battlecardCardIds={battlecardCardIds}
          cardTemplates={cardTemplates}
          profileEditMode={profileEditMode}
          builderMode={builderMode}
          onToggleCardOnBattlecard={onToggleCardOnBattlecard}
          maxBattlecardCards={maxBattlecardCards}
          onShowCardMeta={onShowCardMeta}
          onToggleLaneVisibility={this.toggleLaneVisibility}
          collapsed={collapsed}
          onGetCommentSourceLink={onGetCommentSourceLink}
          onToggleSnapshots={this.toggleSnapshotsClick}
          cardSnapshotsId={cardSnapshotsId}
          onScratchpadDismiss={onScratchpadDismiss}
          onBoardRefresh={onBoardRefresh}
          onCardRefresh={onCardRefresh}
          onToggleFreshCardExpanded={onToggleFreshCardExpanded}
          freshCardExpanded={freshCardExpanded}
          viewportLoadMargin={viewportLoadMargin}
          focusedCardId={focusedCardId}
          moveCardToLane={moveCardToLane}
          boards={boards}
          lastCardUpdated={lastCardUpdated}
          previewingId={previewingId}
          {...editorProps}
          wrappedComponentRef={b => (this.boards[board.id] = b)} />
        {snapshotsRegion}
        <DropTargetScroller position="bottom" narrow={true} onDropTargetScrollerHover={handleScrollUp} />
      </div>

    );
  };

  getBoardWidths = boards => {
    const profile = this._getProfile();

    if(!boards || !profile) {
      return [];
    }

    // filter lanes to only get "real" boards for display checks; ignore vitals/curator tools/feed collapsed state
    const collapsedLanes = (
      this.context.utils.getCollapsedLanes(profile.id) || []
    ).filter(l => l && Number.isInteger(l));
    const useBoardWidth = this.props.wideMode
      ? this._wideBoardWidth
      : this._baseBoardWidth;

    return boards.map(board => {
      const laneIndex = collapsedLanes.indexOf(board.id);
      let boardCollapsed = laneIndex >= 0;
      let nextCollapsed = false;

      if(boardCollapsed) {
        // find index of next (right side) board within sorted boards
        const nextLaneIndex = boards.findIndex(b => b.id === board.id) + 1;

        if(nextLaneIndex < boards.length) {
          nextCollapsed = collapsedLanes.includes(boards[nextLaneIndex].id);
        }
      }

      if(this._isFilteringProfile()) {
        // while filtering profile, temporarily un-collapse all user-created boards
        // (excludes un-searchable regions like curator tools, feed, vitals)
        boardCollapsed = nextCollapsed = false;
      }

      let currentBoardWidth = boardCollapsed
        ? this._collapsedBoardWidth
        : useBoardWidth;

      if(!nextCollapsed) {
        currentBoardWidth += this._gutterWidth;
      }
      else if(boardCollapsed && !nextCollapsed) {
        currentBoardWidth += this._gutterWidth;
      }
      else if(boardCollapsed && nextCollapsed) {
        currentBoardWidth += this._collapsedGutterWidth;
      }
      else if(!boardCollapsed && nextCollapsed) {
        currentBoardWidth += this._gutterWidth;
      }

      return currentBoardWidth;
    });
  };

  setRef = el => (this.boardListRef = el);

  render() {
    const profile = this._getProfile();

    if(_.isEmpty(profile)) {
      return null;
    }

    const {
      user,
      boards,
      profileEditMode,
      builderMode,
      boardsCount = 0,
      maxBattlecardCards,
      onToggleCuratorTools,
      wideMode,
      onToggleCardOnBattlecard,
      onErrorMessage,
      previewingId,
      onProfileUpdated,
      onBoardUpdate,
      onSettingsExpanded,
      emphasizeVitalSettings,
      onClickOutsideOfVitals,
      cardDraggingContext,
      updatingBoardDrags,
      onLanesDropHover,
      freshnessMode,
      feedCollapsed: collapsedFeed,
      lanesLoading
    } = this.props;
    const previewing = previewingId !== null;
    const {cardSnapshotsId} = this.state;
    const sumWidths = (acc, cur) => acc + cur;
    const collapsedLanes = this.context.utils.getCollapsedLanes(profile.id);
    const boardEditor = (
      <BoardEditor
        ref="boardEditor"
        user={user}
        onBoardSave={this.handleBoardSave}
        onErrorMessage={onErrorMessage} />
    );
    let vitalsBoard;
    let vitalsBoardWidth = 0;
    let vitalsCollapsed = false;
    let vitalsAdjustPosLeft = 0;
    let boardNodes = boards.length ? this._getConsumerVisibleBoards() : [];
    let boardAddRegion;
    let uiEmptyBoardMsg;
    let boardWidths = [];
    let boardPosLeft = 0;
    let boardsAdjustLeft = 0;
    let lanesDragTargetsList;

    const uiShowEmptyBoardMsg = boards && !boardsCount && !profileEditMode;
    const editorProps = {
      boardEditor,
      onSetEditMode: this.setEditMode
    };

    if(boards) {
      const feedCollapsed = collapsedLanes.includes('feed');

      // hide automated company vitals board (clearbit) if user has created a profile board named "vitals" (#1136)
      if(!boards.filter(b => b.name.toLowerCase() === 'vitals').length) {
        const vitalsMatchIndex = collapsedLanes.indexOf(0);
        const nextLane = boardNodes[boardNodes.findIndex(b => !b.id) + 1];
        const nextCollapsed = nextLane
          ? collapsedLanes.includes(nextLane.id)
          : false;

        // remove vitals board from collapsed lanes if present
        if(vitalsMatchIndex >= 0) {
          collapsedLanes.splice(vitalsMatchIndex, 1);
        }

        // vitals board is always narrow/base width (if not collapsed)
        vitalsCollapsed = vitalsMatchIndex >= 0 || cardDraggingContext?.isCompressedCardDraggingOn || freshnessMode;
        vitalsBoardWidth =
          (vitalsCollapsed ? this._collapsedBoardWidth : this._baseBoardWidth) +
          (nextCollapsed && vitalsCollapsed
            ? this._collapsedGutterWidth
            : this._gutterWidth);

        if(feedCollapsed && vitalsCollapsed) {
          // subtract diff between column gap & collapsed gap
          vitalsAdjustPosLeft = -(
            this._gutterWidth - this._collapsedGutterWidth
          );
        }

        vitalsBoard = !freshnessMode && !previewing && (
          <BoardVitals
            user={user}
            boards={boards}
            profileEditMode={profileEditMode}
            builderMode={builderMode}
            showSettingsOpen={emphasizeVitalSettings}
            onToggleCardOnBattlecard={onToggleCardOnBattlecard}
            maxBattlecardCards={maxBattlecardCards}
            onToggleLaneVisibility={this.toggleLaneVisibility}
            onToggleCuratorTools={onToggleCuratorTools}
            onBoardUpdate={onBoardUpdate}
            onProfileUpdated={onProfileUpdated}
            collapsed={vitalsCollapsed}
            onErrorMessage={onErrorMessage}
            onSettingsExpanded={onSettingsExpanded}
            adjustLeftPos={vitalsAdjustPosLeft}
            onClickOutside={onClickOutsideOfVitals} />
        );
      }

      boardWidths = this.getBoardWidths(boardNodes);
      boardsAdjustLeft = this._isFilteringProfile() ? 0 : vitalsAdjustPosLeft; // ignore collapsed vitals adjustment if filtering profile

      const useBoardWidth = wideMode ? this._wideBoardWidth : this._baseBoardWidth;
      let snapshotsOpenToLeft = false;

      boardNodes = boardNodes.map((board, index) => {
        const boardsLoadedHandler = (index === boardNodes.length - 1) ? this.handleBoardsLoaded : null;
        const boardIndex = boardNodes.findIndex(b => b.id === board.id);

        // note: vitals board (if shown) & add new board are always single-col (320px)
        boardPosLeft =
          (previewing || freshnessMode
            ? this._gutterWidth
            : vitalsBoardWidth + boardsAdjustLeft) +
          boardWidths.slice(0, boardIndex).reduce(sumWidths, 0);

        // if previous column has snapshots lane open, shift over
        if(index && boards[index - 1].cards.find(c => c.id === cardSnapshotsId)) {
          snapshotsOpenToLeft = true;
        }

        if(snapshotsOpenToLeft) {
          boardPosLeft += useBoardWidth + 1;
        }

        const boardPos = {
          left: `${boardPosLeft}px`,
          zIndex: collapsedLanes.includes(board.id) ? 0 : boardNodes.length - index
        };

        return this.renderBoard(
          board,
          index,
          boardPos,
          editorProps,
          boardsLoadedHandler
        );
      });

      lanesDragTargetsList = cardDraggingContext?.isCompressedCardDraggingOn
        ? (<Lanes
            boards={boards}
            updatingBoardDrags={updatingBoardDrags}
            onToggleLaneVisibility={this.toggleLaneVisibility}
            onLaneCardCountsChanged={this.handleLaneCardCountsChanged}
            onLanesDropHover={onLanesDropHover} />)
        : null;
    }

    if(profileEditMode) {
      const addBoardPanel = (
        <BoardAddPanel
          user={user}
          key={boardNodes ? boardNodes.length : 0}
          editMode={this.isEditingBoard(-1)}
          {...editorProps} />
      );

      const addBoardPosLeft = previewing || freshnessMode
        ? 0
        : vitalsBoardWidth +
          boardsAdjustLeft +
          boardWidths.reduce(sumWidths, 0);
      const addBoardPos = {
        left: `${addBoardPosLeft}px`,
        background: 'none',
        opacity:
          !boardsCount || (boardNodes && boardsCount === boardNodes.length)
            ? '1'
            : '0'
      };

      boardAddRegion = !previewing && !freshnessMode && (
        <div className="swimlane ui-boards board--new" style={addBoardPos}>
          {addBoardPanel}
        </div>
      );
    }

    if(uiShowEmptyBoardMsg) {
      const uiEmptyBoardStyles = {
        position: 'absolute',
        left: `${vitalsBoardWidth}px`,
        top: 0,
        bottom: 0,
        minWidth: '600px'
      };
      let uiAddInsightsRegion;

      if(userCanCurate({user})) {
        uiAddInsightsRegion = (
          <div>
            You can add manually add your own insight cards by clicking{' '}
            <Link to={`/profile/${profile.id}/battlecard/edit`}>
              <strong>Edit Profile</strong>
            </Link>{' '}
            above.
          </div>
        );
      }

      uiEmptyBoardMsg = (
        <div className="empty-state-user-message" style={uiEmptyBoardStyles}>
          <div className="empty-state-user-message_heading">
            Company Insights Appear Here.
          </div>
          <div className="empty-state-user-message_text">
            <div>
              We&apos;re automatically collecting insights across the web for{' '}
              {profile.name} now.
            </div>
            {uiAddInsightsRegion}
          </div>
        </div>
      );
    }

    return (
      <div
        ref={this.setRef}
        id="board-list"
        className={classNames('board-list', {
          'board-list--previewing': previewing || freshnessMode,
          'board-list--collapsed': collapsedFeed,
          'board-list--loading': lanesLoading,
          'board-list--reordering': cardDraggingContext?.isCompressedCardDraggingOn
        })}>
        {lanesDragTargetsList}
        {vitalsBoard}
        {uiEmptyBoardMsg}
        {boardNodes}
        {boardAddRegion}
      </div>
    );
  }

}

export {BoardList as WrappedBoardList};
export default withCardDragging(withRouter(BoardList));
