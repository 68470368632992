/* eslint-disable react/no-multi-comp */
import onClickOutside from 'react-onclickoutside';

const Option = ({option, onChange}) => {
  const {key, value, description} = option;

  const handleLabelClick = e => {
    e.stopPropagation();
  };

  const handleValueChange  = () => {
    const changedValue = {
      [key]: !value
    };

    onChange(changedValue);
  };

  return (
    <label htmlFor={`QueryBuilderOptions${key}`} className="option" onClick={handleLabelClick}>
      <input className="hidden"
        id={`QueryBuilderOptions${key}`}
        type="checkbox"
        readOnly={true}
        value={key}
        checked={value}
        onChange={handleValueChange} />
      <span className="checkmark" />
      {description}
    </label>
  );
};

Option.propTypes = {
  option: PropTypes.object,
  onChange: PropTypes.func
};

Option.defaultProps = {
  option: {},
  onChange() {}
};

/**
 * renders Checkbox Options
 *
 * @param {Array} options, list of options. each should have "key", "description" and "value"
 * @returns {Element} the option dropdown
 */
const OptionsDropdown = ({options, onClose, onChange}) => {
  OptionsDropdown.handleClickOutside = onClose;

  return (
    <ul className="options-dropdown" data-testid="query-options-dropdown">
      {options.map(option => (
        <li key={option.key}>
          <Option option={option} onChange={onChange} />
        </li>
      ))}
    </ul>
  );
};

OptionsDropdown.propTypes = {
  options: PropTypes.array,
  onClose: PropTypes.func,
  onChange: PropTypes.func
};

OptionsDropdown.defaultProps = {
  options: [],
  onClose() {},
  onChange() {}
};

const clickOutsideConfig = {
  handleClickOutside: () => OptionsDropdown.handleClickOutside
};

export default onClickOutside(OptionsDropdown, clickOutsideConfig);
