import DropdownMenu from './_dropdown_menu';
import FlashMessage from './_flash_message';
import Icon from './_icon';
import ListItemsManage from './_list_items_manage';
import ReactUpdate from 'immutability-helper';

import {pluralize, capitalize} from '../modules/text_utils';
import {isEqual} from 'lodash';
import moment from 'moment';

import {Link} from 'react-router-dom';
import {getNextDigestUTCDateTime} from '../modules/digest_utils';
import {withDigests} from '../contexts/_digests';
import {withDigestTemplates} from '../contexts/_digestTemplates';
import {
  defaultDigestFrequency,
  defaultDigestDay,
  defaultDigestHour,
  defaultDigestSendOnceHours
} from '../modules/constants/digest';
import {RIVALS_LIMIT_UNLIMITED} from '../modules/constants/api';
import DigestFrequencySettings from './digest_settings/_digest_frequency_settings';
import DigestAudienceSettings from './digest_settings/_digest_audience_settings';
import DigestArticleSettings from './digest_settings/_digest_articles_settings';
import DigestTemplateSettings from './digest_settings/_digest_template_settings';

const digestTypeManageDialogId = 'manage_digest_type_modal';

class CompanySettingsDigest extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    digestsContext: PropTypes.shape({
      reloadDigests: PropTypes.func
    }).isRequired,
    digestTemplatesContext: PropTypes.shape({
      templates: PropTypes.arrayOf(PropTypes.object),
      updateTemplate: PropTypes.func,
      deleteTemplate: PropTypes.func
    }).isRequired,
    rivalGroups: PropTypes.array
  };

  static defaultProps = {
    rivalGroups: []
  };

  state = {
    actionMessageType: CompanySettingsDigest.reviewActionTypes.NONE,
    digestTypes: [],
    sendOnceHours: defaultDigestSendOnceHours,
    visibilityGroups: [],
    rivals: {}
  };

  componentDidMount() {
    this.mounted = true;
    this.loadDigestTypes();
    this.loadVisibilityGroups();
    this.refreshDigestSendTime();
    this.loadRivals();
  }

  componentWillUnmount() {
    this.mounted = false;

    const {digestsContext: {reloadDigests}} = this.props;

    reloadDigests && reloadDigests();
  }

  static reviewActionTypes = {
    NONE: 0,
    UPDATED_ROLE: 1,
    UPDATED_FREQUENCY: 2,
    UPDATED_ITEMS_PER_RIVAL: 3,
    UPDATED_DAY_OFFSET: 4,
    UPDATED_HOUR_OFFSET: 5,
    UPDATED_DELETE_AFTER_SEND: 6
  };

  loadVisibilityGroups = () => {
    const {api: {visibilityGroupsGet}} = this.context;

    visibilityGroupsGet({}).then(visibilityGroups => this.setState({
      visibilityGroups: [...visibilityGroups].concat({id: 0, name: 'Full Access Users'})
    }));
  };

  loadRivals = () => {
    const {api: {rivalsGet}} = this.context;

    rivalsGet({page: 1, limit: RIVALS_LIMIT_UNLIMITED}).then(({rivals}) => this.setState({rivals}));
  };

  loadDigestTypes = selected => {
    const digestTypeOptions = {
    };

    return new Promise((resolve, reject) => {
      const {api: {digestTypesGet}} = this.context;

      digestTypesGet({digestTypeOptions, code: 'Digest.loadDigestTypes', callback: ({data}) => {
        const defaultDigest = data.find(dt => dt.default);
        const selectedDigestType = selected ? (data.find(dt => dt.id === selected.id) ?? defaultDigest) : (defaultDigest || data[0]);

        this.setState({
          digestTypes: data,
          selectedDigestType
        }, () => {
          if(data) {
            console.log('Digest.loadDigestTypes: loaded digestTypes: %o', data);

            return resolve(data);
          }

          return reject('Digest.loadDigestTypes: failed to load digestTypes');
        });
      }});
    });
  };

  updateDigestType = (digestTypeOptions, callback) => {
    if(!digestTypeOptions || _.isEmpty(digestTypeOptions)) {
      return;
    }

    const {api: {digestTypeUpdate}} = this.context;

    digestTypeUpdate(digestTypeOptions, updatedDigestType => {
      if(!this.mounted) {
        return;
      }

      if(!updatedDigestType) {
        return typeof callback === 'function' && callback(null);
      }

      const {digestTypes} = this.state;
      let {selectedDigestType} = this.state;
      const index = digestTypes.findIndex(dt => dt.id === updatedDigestType.id);

      if(index >= 0) {
        if(selectedDigestType?.id === updatedDigestType.id) {
          selectedDigestType = {...updatedDigestType};
        }

        const updated = {};

        updated[index] = {$set: updatedDigestType};
        this.setState({
          digestTypes: ReactUpdate(digestTypes, updated),
          selectedDigestType
        });
      }

      typeof callback === 'function' && callback(updatedDigestType);
    });
  };

  handleToggleReviewRole = (digestType, role) => {
    const currentReviewRole = digestType?.reviewRole || 'consumer';

    // Don't trigger update if we are clicking an already-selected role
    if(!role || (role === currentReviewRole)) {
      return;
    }

    // When picking `visibilityGroups` as role, default to Full Access Users
    const reviewRole = (role === 'visibilityGroups') ? [0] : (role || 'consumer');

    // DEBUG
    console.log('CompanySettingsDigest.handleToggleReviewRole: role: %o', role);

    const digestTypeOptions = {
      id: digestType.id,
      reviewRole
    };

    this.updateDigestType(digestTypeOptions, updated => {
      if(updated) {
        this.setState({
          actionMessageType: CompanySettingsDigest.reviewActionTypes.UPDATED_ROLE
        }, () => {
          // DEBUG
          console.log('CompanySettingsDigest.handleToggleReviewRole: updated role: %o', this.state.actionMessageType);
        });
      }
    });
  };

  sequenceNumber = 0;

  getNextSequenceNumber = () => ++this.sequenceNumber;

  refreshDigestSendTime = (digestTypeId = '', callback) => {
    const {api: {digestGet}} = this.context;

    const sn = this.getNextSequenceNumber();

    digestGet(0, digestTypeId, digest => {
      if(!this.mounted || (sn !== this.sequenceNumber)) {
        return;
      }

      const {digestType = {}} = digest;
      const sendOnceAt = digestType.reviewSendOnceAt;
      const sendOnceHours = sendOnceAt ? Math.ceil(moment.duration(moment(sendOnceAt).diff(moment())).asHours()) : defaultDigestSendOnceHours;

      this.setState({
        sendTime: getNextDigestUTCDateTime(digest),
        sendOnceHours
      }, () => {
        callback && callback();
      });
    });
  };

  handleSetReviewSchedule = (digestType, key, value, actionMessageType, callback) => {
    const currentValue = digestType[key];

    if(value === currentValue) {
      return;
    }

    const digestTypeOptions = {
      id: digestType.id,
      [key]: value
    };

    if(key === 'reviewSendOnceAt') {
      digestTypeOptions.reviewFrequency = 'once';
    }
    else if(key === 'reviewFrequency' && value === 'once' && !digestType.reviewSendOnceAt) {
      digestTypeOptions.reviewSendOnceAt = moment().add(defaultDigestSendOnceHours, 'h').toISOString();
    }

    this.updateDigestType(digestTypeOptions, updated => {
      if(updated) {
        this.setState({
          actionMessageType
        }, () => this.refreshDigestSendTime(updated.id, () => callback && callback(updated)));
      }
      else {
        callback && callback();
      }
    });
  };

  handleSetReviewFrequency = (digestType, frequency, callback) => this.handleSetReviewSchedule(
    digestType, 'reviewFrequency', frequency, CompanySettingsDigest.reviewActionTypes.UPDATED_FREQUENCY, callback
  );

  handleSetReviewDay = (digestType, day) => this.handleSetReviewSchedule(
    digestType, 'reviewDay', day, CompanySettingsDigest.reviewActionTypes.UPDATED_DAY_OFFSET
  );

  handleSetReviewHour = (digestType, hour) => this.handleSetReviewSchedule(
    digestType, 'reviewHour', hour, CompanySettingsDigest.reviewActionTypes.UPDATED_HOUR_OFFSET
  );

  handleSetDeleteAfterSend = (digestType, deleteAfterSend) => this.handleSetReviewSchedule(
    digestType, 'deleteAfterSend', deleteAfterSend, CompanySettingsDigest.reviewActionTypes.UPDATED_DELETE_AFTER_SEND
  );

  handleSetSendOnceAtClick = (hours, digestType) => {
    const sendOnceAt = moment().add(hours, 'h').toISOString();

    this.handleSetReviewSchedule(
      digestType, 'reviewSendOnceAt', sendOnceAt, CompanySettingsDigest.reviewActionTypes.UPDATED_FREQUENCY,
      () => this.setState({sendOnceHours: hours})
    );
  };

  handleSetReviewItemsPerRival = (digestType, itemsPerRival) => {
    const currentItemsPerRival = digestType.reviewItemsPerRival ?? 1;

    if(itemsPerRival < 0 || itemsPerRival > 10 || itemsPerRival === currentItemsPerRival) {
      return;
    }

    // DEBUG
    console.log('CompanySettingsDigest.handleSetReviewItemsPerRival: items per rival: %o', itemsPerRival);

    const digestTypeOptions = {
      id: digestType.id,
      reviewItemsPerRival: itemsPerRival
    };

    this.updateDigestType(digestTypeOptions, updated => {
      if(updated) {
        this.setState({
          actionMessageType: CompanySettingsDigest.reviewActionTypes.UPDATED_ITEMS_PER_RIVAL
        }, () => {
          // DEBUG
          console.log('CompanySettingsDigest.handleSetReviewItemsPerRival: updated items per rival: %o', this.state.actionMessageType);
        });
      }
    });
  };

  handleDidCheckTopics = (digestType, checkedTopics) => {
    const updatedTopics = new Set(digestType.reviewTopics || []);

    Object.entries(checkedTopics).forEach(([topic, checked]) => {
      if(checked) {
        updatedTopics.add(topic);
      }
      else {
        updatedTopics.delete(topic);
      }
    });

    const digestTypeOptions = {
      id: digestType.id,
      reviewTopics: [...updatedTopics]
    };

    this.setState({processingTopics: true}, () => {
      this.updateDigestType(digestTypeOptions, () => {
        this.setState({processingTopics: false});
      });
    });
  };

  handleDidCheckRivals = (digestType, checkedRivals) => {
    const updatedRivals = new Set(digestType.reviewRivalIds || []);

    Object.entries(checkedRivals || {}).forEach(([rivalId, checked]) => {
      const rId = parseInt(rivalId, 10);

      if(checked) {
        updatedRivals.add(rId);
      }
      else {
        updatedRivals.delete(rId);
      }
    });

    const digestTypeOptions = {
      id: digestType.id,
      reviewRivalIds: [...updatedRivals]
    };

    this.setState({processingRivals: true}, () => {
      this.updateDigestType(digestTypeOptions, () => {
        this.setState({processingRivals: false});
      });
    });
  };

  handleEnableDigestSuggestions = (digestType, enableAutoSuggestion) => {
    const digestTypeOptions = {
      id: digestType.id,
      enableAutoSuggestion
    };

    this.updateDigestType(digestTypeOptions);
  };

  handleClearAllTopics = digestType => {
    const digestTypeOptions = {
      id: digestType.id,
      reviewTopics: null
    };

    this.setState({processingTopics: true}, () => {
      this.updateDigestType(digestTypeOptions, () => {
        this.setState({processingTopics: false});
      });
    });
  };

  handleDismissFlashMessageClick = () => {
    this.setState({
      actionMessageType: CompanySettingsDigest.reviewActionTypes.NONE
    });
  };

  getDigestName = () => {
    const {selectedDigestType} = this.state;
    const defaultName = 'Intel Digest';

    if(selectedDigestType?.name) {
      return `${capitalize(selectedDigestType.name)} ${defaultName}`;
    }

    return defaultName;
  };

  renderFlashMessageRegion = digestType => {
    const {actionMessageType} = this.state;
    const intelDigestName = this.getDigestName();

    if(!actionMessageType) {
      return;
    }

    let flashMessage;

    switch(actionMessageType) {
      case CompanySettingsDigest.reviewActionTypes.UPDATED_ROLE:
        const visibilityGroupsSelected = Array.isArray(digestType.reviewRole);
        const currentReviewRole = digestType.reviewRole || 'consumer';
        const activeRoleLabel = visibilityGroupsSelected
          ? 'selected visibility groups'
          : ((currentReviewRole === 'admin') ? 'admins only' : ((currentReviewRole === 'curator') ? 'curators & admins' : 'all users'));

        flashMessage = (
          <span>{`The ${intelDigestName} is now enabled for`} <strong>{activeRoleLabel}</strong>.</span>
        );

        break;
      case CompanySettingsDigest.reviewActionTypes.UPDATED_DELETE_AFTER_SEND:
        const {deleteAfterSend} = digestType;

        flashMessage = (
          <span>
            This Digest {deleteAfterSend ? 'will be discontinued' : 'can be sent again'} after the next send.
          </span>
        );
        break;
      case CompanySettingsDigest.reviewActionTypes.UPDATED_FREQUENCY:
      case CompanySettingsDigest.reviewActionTypes.UPDATED_DAY_OFFSET:
      case CompanySettingsDigest.reviewActionTypes.UPDATED_HOUR_OFFSET:
        const currentReviewFrequency = digestType.reviewFrequency;
        const activeFrequencyLabel = (currentReviewFrequency === 'biweekly') ? 'bi-weekly' : currentReviewFrequency;
        const currentDayLabel = capitalize(digestType.reviewDay);
        const currentHourLabel = digestType.reviewHour;
        let maybeDayLabel = '';

        if(activeFrequencyLabel === 'never') {
          flashMessage = (
            <span>
              Scheduled sending disabled, hit <strong>Send Now</strong> in Digest Editor when ready to send.
            </span>
          );
        }
        else if(activeFrequencyLabel === 'once') {
          const sendOnceAt = moment(digestType.reviewSendOnceAt);

          flashMessage = (
            <span>
              The {`${intelDigestName}`} will be sent <strong>once</strong>, at
              <strong> {`${sendOnceAt.format('LLLL')} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`}</strong>.
            </span>
          );
        }
        else {
          if(activeFrequencyLabel === 'monthly') {
            maybeDayLabel = 'on the first day of the month, ';
          }
          else if(activeFrequencyLabel !== 'daily') {
            maybeDayLabel = (<span>on <strong>{`${currentDayLabel}s`}</strong> </span>);
          }

          flashMessage = (
            <span>
              The {`${intelDigestName}`} will be sent on a <strong>{activeFrequencyLabel}</strong> basis, {maybeDayLabel}
              at <strong>{`${currentHourLabel}:00 UTC`}</strong>.
            </span>
          );
        }

        break;
      case CompanySettingsDigest.reviewActionTypes.UPDATED_ITEMS_PER_RIVAL:
        const itemsPerRival = digestType.reviewItemsPerRival ?? 1;

        flashMessage = (itemsPerRival ?
          (<span>The {`${intelDigestName}`} will now include up to <strong>
            {itemsPerRival} {pluralize('article', itemsPerRival)}</strong> per company.</span>) :
          <span>Automatic inclusion of articles in the {`${intelDigestName}`} has been disabled.</span>
        );

        break;
      default:
        return;
    }

    return (
      <FlashMessage
        message={flashMessage}
        onDismissClick={this.handleDismissFlashMessageClick} />
    );
  };

  handleVisibilityGroupSelected = (ids, addingRole, {id, reviewRole = []}) => {
    if(!id) {
      return;
    }

    const digestTypeOptions = {
      id
    };

    if(addingRole) {
      digestTypeOptions.reviewRole = [...new Set((reviewRole).concat(ids))];
    }
    else {
      // Don't run update if we are trying to remove Full Access Users and it's the only role left
      if(ids.length === 1 && ids[0] === 0 && isEqual(ids, reviewRole)) {
        return;
      }

      digestTypeOptions.reviewRole = reviewRole.filter(vgId => !ids.includes(vgId));

      // If we've removed all roles, add Full Access Users back
      if(!digestTypeOptions.reviewRole.length) {
        digestTypeOptions.reviewRole = [0];
      }
    }

    this.updateDigestType(digestTypeOptions);
  };

  resetToDefaults = () => {
    const {selectedDigestType} = this.state;
    const {id} = selectedDigestType || {};

    if(!id) {
      return;
    }

    const digestTypeOptions = {
      id,
      reviewFrequency: defaultDigestFrequency,
      reviewDay: defaultDigestDay,
      reviewHour: defaultDigestHour,
      reviewSendOnceAt: null
    };

    this.updateDigestType(digestTypeOptions, updated => {
      if(updated) {
        this.setState({
          actionMessageType: CompanySettingsDigest.reviewActionTypes.UPDATED_FREQUENCY,
          sendOnceHours: defaultDigestSendOnceHours
        }, () => this.refreshDigestSendTime(updated.id));
      }
    });
  };

  handleResetToDefaults = () => {
    const {utils: {dialog} = {}} = this.context;

    dialog.confirm({
      message: 'Do you want to reset this Digest Schedule to the default schedule?',
      okCallback: this.resetToDefaults,
      buttonOk: 'Reset to Defaults'
    });
  };

  handleDigestSelect = digestTypeId => {
    const {digestTypes} = this.state;
    const selectedDigestType = digestTypes.find(dt => dt.id === digestTypeId);

    if(!selectedDigestType) {
      return;
    }

    this.setState({selectedDigestType, actionMessageType: CompanySettingsDigest.reviewActionTypes.NONE}, () => {
      this.refreshDigestSendTime(digestTypeId);
    });
  };

  handleAddDigest = name => {
    const {api: {digestTypeCreate}} = this.context;

    return new Promise((resolve, reject) => {
      digestTypeCreate({name}, created => {
        if(created) {
          const {digestTypes} = this.state;
          const updatedTypes = [...digestTypes].concat(created);

          return this.setState({digestTypes: updatedTypes}, () => resolve(this.getDigestTypesSorted(updatedTypes)));
        }

        reject({message: 'Creating the new digest name failed. Please try again.'});
      });
    });
  };

  handleUpdateDigestName = (id, name) => {
    return new Promise((resolve, reject) => {
      this.updateDigestType({id, name}, updated => {
        if(updated) {
          const {digestTypes} = this.state;
          const updatedTypes = [...digestTypes].map(dt => ((dt.id === id) ? {...updated} : dt));

          return this.setState({digestTypes: updatedTypes}, () => resolve(this.getDigestTypesSorted(updatedTypes)));
        }

        reject({message: 'Updating the digest name failed. Please try again.'});
      });
    });
  };

  handleDeleteDigest = id => {
    const {api: {digestTypeDelete}} = this.context;

    return new Promise((resolve, reject) => {
      digestTypeDelete({id}, success => {
        if(success) {
          const {digestTypes} = this.state;
          const updatedTypes = [...digestTypes].filter(dt => dt.id !== id);

          return this.setState({digestTypes: updatedTypes}, () => resolve(this.getDigestTypesSorted(updatedTypes)));
        }

        reject({message: 'Deleting the digest failed. Please try again.'});
      });
    });
  };

  handleChangeDigestDefault = id => {
    return new Promise((resolve, reject) => {
      const {digestTypes} = this.state;
      const {deleteAfterSend} = digestTypes.find(dt => dt.id === id) || {};
      const makeDefaultAction = () => this.updateDigestType({id, default: true}, defaultDigest => {
        if(defaultDigest) {
          const updatedTypes = [...digestTypes].map(dt => ((dt.id === id) ? {...defaultDigest} : {...dt, default: false}));

          return this.setState({
            digestTypes: updatedTypes
          }, () => resolve(this.getDigestTypesSorted(updatedTypes)));
        }

        reject({message: 'Updating the digest default failed. Please try again.'});
      });

      if(deleteAfterSend) {
        const {utils: {dialog}} = this.context;

        dialog.confirm({
          message: 'Convert to Recurring Digest?',
          bodyContent: 'Default Digest cannot be a One-Time Digest.',
          okCallback: makeDefaultAction,
          cancelCallback: () => resolve(null)
        });
      }
      else {
        makeDefaultAction();
      }
    });
  };

  renderManageDigestsModal = () => {
    const {utils: {dialog}} = this.context;
    const {digestTypes} = this.state;
    const sortedDigestTypes = this.getDigestTypesSorted(digestTypes);

    const dialogBodyContent = (
      <ListItemsManage
        modalId={digestTypeManageDialogId}
        title="Manage Digests"
        createButton="Create New Digest"
        placeholder="New digest name"
        listItems={sortedDigestTypes}
        infoContent={(<div className="list-manage-info-content">
          <Icon
            icon="star"
            width="18"
            height="18"
            className="visibility-group_icon visibility-group_icon--default" />
          Your default digest.
        </div>)}
        onAdd={this.handleAddDigest}
        onUpdateName={this.handleUpdateDigestName}
        onDelete={this.handleDeleteDigest}
        onChangedDefault={this.handleChangeDigestDefault} />
    );

    dialog.create({
      id: digestTypeManageDialogId,
      content: dialogBodyContent,
      _widerMode: true
    });
  };

  getDigestTypesSorted = digestTypes => [...(digestTypes || [])]
    .sort((dt1, dt2) => (dt1.name || '').localeCompare(dt2.name || ''));

  renderMultiDigestSelector = selectedDigestType => {
    const {digestTypes = []} = this.state;

    if(!digestTypes || !digestTypes.length) {
      return null;
    }

    const dropDownValues = [];

    this.getDigestTypesSorted(digestTypes).forEach(({id, name}, index) => {
      dropDownValues.push([id, name, false, '', {dataTestId: `drop-down-option-${index}`}]);
    });

    return (
      <div className="settings-digest_multi-options" data-testid="company-settings-digest-multi">
        <div className="title-and-dropdown">
          <h4><strong>Digest:</strong></h4>
          {digestTypes.length > 1
            ? (
              <DropdownMenu
                id="multiple-digests-selector"
                dataTrackingId="digest-settings-multiple-digests-dropdown"
                justification="left"
                values={dropDownValues}
                selectedValue={selectedDigestType?.id ?? ''}
                selectCallback={this.handleDigestSelect} />
            )
            : (
              <h4><strong>{digestTypes[0].name}</strong></h4>
            )
          }

        </div>
        <button
          className="button button--xsmall button--alt visibility-group-header--button"
          data-tracking-id="mange-digests-button"
          onClick={this.renderManageDigestsModal}>Manage</button>
      </div>
    );
  };

  render() {
    const {utils: {isDigestTemplatesEnabled, isImprovedDigestSuggestionsEnabled}} = this.context;
    const showDigestTemplates = isDigestTemplatesEnabled();
    const improvedDigestSuggestionsEnabled = isImprovedDigestSuggestionsEnabled();
    const {selectedDigestType: stateDigestType, digestTypes, sendTime, sendOnceHours, visibilityGroups, rivals,
      processingRivals, processingTopics} = this.state;
    const selectedDigestType = stateDigestType ?? digestTypes.find(({default: isDefault}) => isDefault);
    const {deleteAfterSend} = selectedDigestType || {};
    const currentReviewFrequency = stateDigestType?.reviewFrequency || defaultDigestFrequency;
    const currentReviewDay = stateDigestType?.reviewDay || defaultDigestDay;
    const currentReviewHour = stateDigestType?.reviewHour ?? defaultDigestHour;
    const {digestTemplatesContext: {templates = [], updateTemplate, deleteTemplate} = {}, rivalGroups} = this.props;

    const handleSetReviewFrequency = (frequency, callback) => this.handleSetReviewFrequency(selectedDigestType, frequency, callback);
    const handleSetReviewDay = day => this.handleSetReviewDay(selectedDigestType, day);
    const handleSetReviewHour = hour => this.handleSetReviewHour(selectedDigestType, hour);
    const handleSetSendOnceHours = hours => this.handleSetSendOnceAtClick(hours, selectedDigestType);
    const handleToggleReviewRole = role => this.handleToggleReviewRole(selectedDigestType, role);
    const handleSetReviewItemsPerRival = itemsPerRival => this.handleSetReviewItemsPerRival(selectedDigestType, itemsPerRival);
    const handleDidCheckTopics = checkedTopics => this.handleDidCheckTopics(selectedDigestType, checkedTopics);
    const handleDidCheckRivals = checkedRivals => this.handleDidCheckRivals(selectedDigestType, checkedRivals);
    const handleEnableDigestSuggestions = enabled => this.handleEnableDigestSuggestions(selectedDigestType, enabled);
    const handleClearAllTopics = () => this.handleClearAllTopics(selectedDigestType);
    const handleSetDeleteAfterSend = delAfterSend => this.handleSetDeleteAfterSend(selectedDigestType, delAfterSend);
    const handleSetDigestTempate = templateId => {
      const id = selectedDigestType?.id;

      if(!id) {
        return;
      }

      this.updateDigestType({
        id,
        emailDigestTemplateId: templateId || null
      });
    };

    const handleDidDeleteTemplate = templateId => deleteTemplate(templateId);
    const handleDidUpdateTemplate = template => updateTemplate(template);
    const handleVisibilityGroupSelected = (ids, addingRole) => this.handleVisibilityGroupSelected(ids, addingRole, selectedDigestType);

    return (
      <div className="company-settings company-settings-review">
        <h3 className="u-m0">Intel Digests Settings</h3>
        <div className="help-block u-mb-m">
          Configure the <Link to="/digest"><strong>Intel Digest</strong></Link>&apos;s audience, delivery schedule and status.
          <br />
          <br />
          Users are able to disable the <strong>Intel Digest</strong> on their individual accounts.
        </div>
        {this.renderMultiDigestSelector(selectedDigestType)}
        {this.renderFlashMessageRegion(selectedDigestType)}
        <DigestAudienceSettings
          reviewRole={selectedDigestType?.reviewRole}
          visibilityGroups={visibilityGroups}
          onToggleReviewRole={handleToggleReviewRole}
          onVisibilityGroupSelected={handleVisibilityGroupSelected}
          alwaysShowInfo={true} />
        <DigestFrequencySettings
          reviewFrequency={currentReviewFrequency}
          reviewDay={currentReviewDay}
          reviewHour={currentReviewHour}
          sendOnceHours={sendOnceHours}
          sendTime={sendTime}
          deleteAfterSend={deleteAfterSend}
          showDeleteAfterSend={!selectedDigestType?.default}
          onSetSendOnceHours={handleSetSendOnceHours}
          onSetReviewFrequency={handleSetReviewFrequency}
          onSetReviewDay={handleSetReviewDay}
          onSetReviewHour={handleSetReviewHour}
          onResetToDefaults={this.handleResetToDefaults}
          onSetDeleteAfterSend={handleSetDeleteAfterSend}
          alwaysShowInfo={true} />
        <DigestArticleSettings
          improvedDigestSuggestionsEnabled={improvedDigestSuggestionsEnabled}
          reviewItemsPerRival={selectedDigestType?.reviewItemsPerRival}
          reviewTopics={selectedDigestType?.reviewTopics}
          reviewRivalIds={selectedDigestType?.reviewRivalIds}
          enableAutoSuggestion={selectedDigestType?.enableAutoSuggestion}
          rivalGroups={rivalGroups || []}
          rivals={rivals || {}}
          onSetReviewItemsPerRival={handleSetReviewItemsPerRival}
          onDidCheckTopics={handleDidCheckTopics}
          onDidCheckRivals={handleDidCheckRivals}
          processingTopics={processingTopics}
          processingRivals={processingRivals}
          onToggleDigestSuggestionsEnabled={handleEnableDigestSuggestions}
          onClearAllTopics={handleClearAllTopics}
          alwaysShowInfo={true} />
        {showDigestTemplates && <DigestTemplateSettings
          templates={templates}
          emailDigestTemplate={selectedDigestType?.emailDigestTemplate}
          onSetDigestTemplate={handleSetDigestTempate}
          onDidDeleteTemplate={handleDidDeleteTemplate}
          onDidUpdateTemplate={handleDidUpdateTemplate} />}
      </div>
    );
  }

}

export default withDigestTemplates(withDigests(CompanySettingsDigest));
