import {usersGet} from '../modules/api/users';
import {sortUsersByMatchIndex} from '../modules/user_utils';
import InlineEditor from './editorInline/InlineEditor';
import FroalaEditor from 'froala-editor';

import classNames from 'classnames';
import {MentionsInput, Mention} from 'react-mentions';
import Tribute from 'tributejs';
import Modal from './_modal';
import EditorToolbarCardLinkSelector from './editor/_editor_toolbar_card_link_selector';
import {editorInsertKlueLink, handleInsertTargetImageURL} from '../modules/editor_utils';

class CommentTextInput extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired
  };

  static propTypes = {
    editMode: PropTypes.bool,
    commentCount: PropTypes.number,
    onChange: PropTypes.func,
    canUseForm: PropTypes.bool,
    commentText: PropTypes.string,
    highlightUnsaved: PropTypes.bool,
    handleResize: PropTypes.func,
    containerType: PropTypes.string,
    isExtensionMode: PropTypes.bool,
    containerId: PropTypes.number.isRequired,
    rivals: PropTypes.object
  };

  static defaultProps = {
    editMode: false,
    commentCount: 0,
    onChange: null,
    canUseForm: true,
    commentText: '',
    highlightUnsaved: false,
    handleResize() {},
    containerType: '',
    isExtensionMode: false,
    containerId: 0,
    rivals: null
  };

  state = {
    value: '',
    highlightUnsaved: false,
    atNames: [],
    query: '',
    inputHeight: 0,
    cardLinkSelectorVisible: false
  };

  componentDidMount() {
    const {commentText, highlightUnsaved, editMode} = this.props;

    if(this.props.isExtensionMode) {
      const textArea = this.findTextareaElem();
      const textareaContainerEl = ReactDOM.findDOMNode(this.refs.textareaContainer);
      let mentionsEl;

      this.setState({
        value: commentText,
        highlightUnsaved,
        inputHeight: textArea ? textArea.getBoundingClientRect().height : 0
      });

      if(textareaContainerEl) {
        mentionsEl = textareaContainerEl.getElementsByClassName('has-highlight__highlighter')[0];
      }

      // synch textarea scrolling with mentions highlighting div
      if(textArea && mentionsEl) {
        textArea.addEventListener('scroll', () => {
          mentionsEl.scrollTop = textArea.scrollTop;
        });
      }

      // extension only: allows tabbing from search to comment box
      if(textArea && textArea.matches('.extension-box > .comment-box > .comment-form textarea')) {
        textArea.tabIndex = 3;
      }

      if(editMode) {
        this.focusTextArea();
      }
    }
    else {
      this.setState({
        value: commentText
      });
    }
  }

  commentRef = React.createRef(null);

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {commentText, highlightUnsaved, editMode} = nextProps;

    if(this.props.isExtensionMode) {
      if(commentText !== this.getValue()) {
        this.setState({value: commentText}, this.focusTextArea);
      }

      if(highlightUnsaved !== this.props.highlightUnsaved) {
        this.setState({highlightUnsaved});
      }

      if(editMode) {
        this.focusTextArea();
      }
    }
    else if(commentText !== this.getValue()) {
      this.setState({value: commentText});

      if(this.commentRef) {
        this.commentRef.current.setText(commentText);
      }
    }
  }

  fetchAtNames = (query = '') => {
    const {containerType} = this.props;

    // containerPost === 'post' -> Feed
    const typeFilter = containerType === 'post' ? 'active' : 'curators';

    return new Promise((resolve, reject) => {
      const userOptions = {
        kluebot: true,
        page: 1,
        limit: 10,
        typeFilter,
        query: _.escapeRegExp(query.trim())
      };

      usersGet({userOptions, code: 'CommentTextInput.fetchAtNames'}).then(users => {
        const sortedUsers = sortUsersByMatchIndex(users, query.trim());
        const usersMap = sortedUsers.map(({id, name, username: display, image}) => ({id: id.toString(), name, display, image}));

        // each array item must have display and id fields to work with react-mentions lib
        resolve(usersMap);
      });
    });
  };

  prepareArray = async (query = '', callback = null) => {
    // NOTE: logic notes...
    // - fetchAtNames should short-circuit if previous query produced 0 results + new query is the same or longer
    // - fetchAtNames should reset (fire again) if new query is shorter or previous query produced > 0 results
    const {atNames: prevAtNames = [], query: prevQuery} = this.state;

    if(!prevAtNames.length && prevQuery && (query.trim().length >= prevQuery.trim().length)) {
      return typeof callback === 'function' && callback([]);
    }

    const {commentCount} = this.props;
    const {Klue = {}} = window;
    const atNames = (query.trim() === prevQuery.trim()) ? prevAtNames : await this.fetchAtNames(query);
    let sliceLimit = 10;
    let atNamesTruncated;

    if(Klue.ext && !commentCount) {
      sliceLimit = 6;
    }

    // return a list of top 10 users if no query is specified
    if(!query.trim()) {
      this.setState({
        query,
        atNames
      }, () => {
        return typeof callback === 'function' && callback(atNames.slice(0, sliceLimit));
      });
    }

    if(atNames.length >= sliceLimit) {
      atNamesTruncated = atNames.slice(0, sliceLimit);
    }

    this.setState({
      query,
      atNames: atNamesTruncated || atNames
    }, () => {
      return typeof callback === 'function' && callback(atNamesTruncated || atNames);
    });
  };

  suggestionRender = (entry, search, highlightedDisplay) => {
    return (
      <span>
        <img className="comment-mentions__suggestions__item_thumbnail" src={entry.image} />
        <span className="comment-mentions__suggestions__item__at">@</span>
        <span className="comment-mentions__suggestions__item__at-name">{highlightedDisplay}</span>
        <span className="comment-mentions__suggestions__item__separator">-</span>
        <span className="comment-mentions__suggestions__item__name">{this.renderHighlightedName(search, entry.name)}</span>
      </span>
    );
  };

  // This is a refactored copy of a function that react-mentions lib is using. The styles (mentionsInputStyle array, see below)
  // are omitted here, as they are not specified for these labels anyways.
  // https://github.com/effektif/react-mentions/blob/72faab14b2809f05eba2458c8d7f7d1349ab8127/src/Suggestion.js#L69
  renderHighlightedName(query = '', name = '') {
    const i = name.toLowerCase().indexOf(query.toLowerCase());

    if(i === -1) {
      return <span>{name}</span>;
    }

    return (
      <span>
        {name.substring(0, i)}
        <b>
          {name.substring(i, i + query.length)}
        </b>
        {name.substring(i + query.length)}
      </span>
    );
  }

  tribute = new Tribute({
    values: (text, cb) => {
      this.prepareArray(text, users => cb(users));
    },
    lookup: 'name',
    fillAttr: 'name',
    menuItemTemplate(item) {
      return (
        `<span>
        <img className="comment-mentions__suggestions__item_thumbnail" src=${item.original.image} />
        <span className="comment-mentions__suggestions__item__at">@</span>
        <span className="comment-mentions__suggestions__item__at-name">${item.original.display}</span>
        <span className="comment-mentions__suggestions__item__separator">-</span>
        <span className="comment-mentions__suggestions__item__name">${item.original.name}</span>
      </span>`
      );
    },
    selectTemplate(item) {
      return (
        `<span contenteditable="false" class="fr-deletable fr-tribute"><a href=${window.location.origin}/users/${item.original.display} target="_blank">@${item.original.display}</a></span>`
      );
    }
  });

  handleChange = event => {
    this.props && this.props.onChange(event);

    if(event.target && this.props.isExtensionMode) {
      const textArea = this.findTextareaElem();
      const inputHeight = textArea ? textArea.getBoundingClientRect().height : 0;

      this.setState(prevState => {
        if(prevState.inputHeight !== inputHeight) {
          this.props.handleResize();
        }

        return {
          value: event.target.value,
          inputHeight
        };
      });
    }
  };

  getValue = () => this.state.value;

  findTextareaElem = () => {
    const textAreaContainerElement = ReactDOM.findDOMNode(this.refs.textareaContainer);

    // find the textarea element inside the 3rd party component
    return textAreaContainerElement && textAreaContainerElement.querySelector('textarea');
  };

  focusTextArea = event => {
    if(event) {
      event.preventDefault();
    }

    const textArea = this.findTextareaElem();

    textArea && textArea.focus();
  };

  handleInitialized = () => {
    const {editor} = this.commentRef ? this.commentRef.current.getEditorRef() : null;

    if(!editor) {
      return;
    }

    this.tribute.attach(editor.el);
    editor.events.on('keydown', function(e) {
      if(e.which === FroalaEditor.KEYCODE.ENTER && this.tribute?.isActive) {
        return false;
      }
    }, true);
  };

  handleUpdateComment = event => {
    const {onChange} = this.props;

    onChange(event);
  };

  handleShowInsertLink = ({urlHRef, urlText, editor, targetImage}) => {
    editor?.selection.save();
    editor?.$el?.blur && editor?.$el?.blur();
    editor?.toolbar?.hide();

    this.setState({cardLinkSelectorVisible: true, urlHRef, urlText, editor, targetImage});
  };

  handleCloseKlueCardLinkSelector = args => {
    this.setState({cardLinkSelectorVisible: false});

    const {appData: {rootUrl}} = this.context;

    editorInsertKlueLink({...args, rootUrl});
  };

  handleInsertLink = ({linkText, urlText, editor, targetImage}) => {
    this.setState({cardLinkSelectorVisible: false});

    editor?.selection?.restore();

    if(targetImage) {
      return handleInsertTargetImageURL({editor, targetImage, urlText});
    }

    editor?.link.insert(urlText, linkText, {target: '_blank', rel: 'nofollow'});
  };

  handleImageUploadStatus = info => {
    const {status} = info;
    const {utils: {dialog: {alert}}} = this.context;

    switch(status) {
      case 'maxFileSizeExceeded':
        alert('Maximum image file size is 3MB. Please try again with a smaller file.');
        break;
      case 'error':
        alert('An image upload failed. Please try again.');
        break;
      default:
        break;
    }
  };

  render() {
    const {canUseForm, isExtensionMode, containerId, commentText, rivals} = this.props;
    const {highlightUnsaved, value, cardLinkSelectorVisible, urlHRef, urlText, editor, targetImage} = this.state;
    const renderDisabled = !canUseForm ? 'disabled' : null;
    const mentionsClass = classNames('layout-mentions', {
      'has-unsaved-highlight': highlightUnsaved
    });

    return (
      <div className={mentionsClass}>
        {isExtensionMode ? <MentionsInput
          ref="textareaContainer"
          placeholder="Your comments..."
          className="comment-mentions"
          disabled={renderDisabled}
          value={value}
          onChange={this.handleChange}
          allowSpaceInQuery={true}
          style={{}}>
          <Mention
            className="comment-mentions"
            renderSuggestion={this.suggestionRender}
            markup="@#__display__#"
            displayTransform={(id, display) => `@${display}`}
            trigger="@"
            data={this.prepareArray}
            style={{}} />
        </MentionsInput> : <>{cardLinkSelectorVisible && (
        <Modal
          header={false}
          padded={false}
          extraBodyClass="with-overflow-scroll"
          extraModalClass="comment-text-input"
          basic={true}
          hideCloseButton={true}
          closeOnOutsideClick={true}>
          <EditorToolbarCardLinkSelector
            url={urlHRef}
            text={urlText}
            targetImage={targetImage}
            editor={editor}
            rivals={rivals}
            onCloseKlueLink={this.handleCloseKlueCardLinkSelector}
            onInsertLink={this.handleInsertLink} />
        </Modal>
        )}<InlineEditor
          identifier={`comment-${containerId}`}
          ref={this.commentRef}
          placeholder="Your comments..."
          text={commentText}
          onUpdate={this.handleUpdateComment}
          onShowInsertLink={this.handleShowInsertLink}
          onInitialized={this.handleInitialized}
          onImageUploadStatus={this.handleImageUploadStatus}
          context={this.context} /></>}
      </div>
    );
  }

}

export default CommentTextInput;
