import {cardGet} from '../modules/api/cards';

import _ from 'lodash';

const CardRedirector = ({match: {params = {}}, history, appData}) => {
  console.log('CardRedirector: params: %o', params);

  if(_.isEmpty(params)) {
    console.warn('CardRedirector: no cardId specified: %o');

    return history.push('/');
  }

  const cardOptions = {
    cardId: parseInt(params.cardId, 10) || 0
  };

  cardGet({cardOptions, code: 'CardRedirector'})
    .then(card => {
      const {board: {profileId, id: boardId} = {}, id} = card || {};

      if(!id || !profileId) {
        return history.push('/');
      }

      const {user: {roles = []} = {}} = appData || {};
      const isCurator = roles.includes('curator') || roles.includes('admin');
      const search = window.location.search ? `${window.location.search}&viewCard=${id}` : `?viewCard=${id}`;

      return history.push({
        pathname: `/profile/${profileId}/${isCurator ? 'edit' : 'view'}/card/${id}`,
        search,
        state: {boardId}
      });
    })
    .catch(error => {
      console.error('CardRedirector: error: %o', error);

      // requested card deleted or doesn't exist
      return history.push('/');
    });

  return null;
};

CardRedirector.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};

CardRedirector.defaultProps = {
  match: {},
  history: {}
};

export default CardRedirector;
