import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CodeWindow = styled.textarea`
  display: block;
  width: 100%;
  height: 55px;
  margin-bottom: 6px;
  padding: 6px;
  white-space: nowrap;
  overflow: auto;
`;

class FormulaEditor extends Component {

  static propTypes = {
    code: PropTypes.string.isRequired,
    onEditFinish: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    code: '',
    onEditFinish() {},
    onChange() {}
  };

  state = {
    code: this.props.code,
    input: null
  };

  componentDidMount() {
    console.log('FormulaEditor.componentDidMount: props: %o', this.props);
  }

  handleCodeChanged = code => this.setState({code});

  finishEditing = () => {
    const {onEditFinish, onChange} = this.props;

    onChange(this.state.code);
    onEditFinish();
  };

  handleKeyDown = event => {
    switch(event.key) {
      case 'Escape':
      case 'Enter':
        event.preventDefault();
        this.finishEditing();
        break;
      default:
        break;
    }
  };

  render() {
    const {code} = this.state;

    return (
      <CodeWindow
        type="text"
        className="formula-editor_code-window"
        defaultValue={code}
        onKeyDown={this.handleKeyDown}
        onChange={e => this.handleCodeChanged(e.target.value)} />
    );
  }

}

export default FormulaEditor;
