
const ModalOverlay = ({visible}) => {
  if(!visible) {
    return null;
  }

  return (
    <div className="modal-overlay" />
  );
};

ModalOverlay.propTypes = {
  visible: PropTypes.bool
};

ModalOverlay.defaultProps = {
  visible: false
};

export default ModalOverlay;
