import Icon from '../_icon';
import ManagedTagItem from './_managed_tag_item';

const CompanySettingsManageTags = ({tags, onUpdateTag, onDeleteTag}) => {
  const renderTagsList = () => {
    if(!tags) {
      return (
        <p className="empty-message">
          <em>Loading...</em>
        </p>
      );
    }

    if(!tags.length) {
      return (
        <p className="empty-message">
          <em>Your tags list is empty</em>
        </p>
      );
    }

    return (
      <ul className="tags_list-container">
        {tags.map(tag => <ManagedTagItem key={tag.id} tag={tag} onUpdateTag={onUpdateTag} onDeleteTag={onDeleteTag} />)}
      </ul>
    );
  };

  return (
    <div className="tags_container">
      <h4 className="u-pt-s"><strong>Manage Tags</strong></h4>
      <p className="help-block u-mb-m">Edits to a tag will be reflected across all Cards with that tag applied.
        <Icon icon="caution" />
      </p>
      {renderTagsList()}
    </div>);
};

CompanySettingsManageTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),
  onUpdateTag: PropTypes.func,
  onDeleteTag: PropTypes.func
};

CompanySettingsManageTags.defaultProps = {
  tags: null,
  onUpdateTag() {},
  onDeleteTag() {}
};

export default CompanySettingsManageTags;
