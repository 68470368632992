import {update} from '../api_utils';
import {isValidId} from '../utils';

// board (lane) management API

export const boardUpdate = ({boardOptions = {}, code = 'ApiUtils.boardUpdate'}) => {
  // options format:
  //   boardOptions: {
  //     id: int,
  //     name: string,              // optional
  //     rivalId: int,              // optional, id of parent rival
  //     profileId: int,            // optional, id of parent profile
  //     comments: {                // optional, hash with add/remove arrays of commentIds
  //       add: int[],
  //       remove: int[]
  //     }
  //     viewOrder: float           // optional
  //   }
  return new Promise((resolve, reject) => {
    if(_.isEmpty(boardOptions) || !isValidId(boardOptions.id)) {
      console.warn(`${code}: invalid options specified: %o`, boardOptions);

      return reject(boardOptions);
    }

    update(`/api/boards/${boardOptions.id}.json`, JSON.stringify(boardOptions), {code})
      .then(({data: board}) => {
        console.log(`${code}: board with id #%o updated: %o`, board.id, board);

        resolve(board);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};
