import attrAccept from 'attr-accept';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import {dataUrlToBlob} from '../modules/attachment_utils';
import {getSignedUrl} from '../modules/s3_utils';
import {handleMessageFromExtension} from '../modules/ext_utils';
import {DOCUMENT_CONTENT_TYPES, IMAGE_CONTENT_TYPES} from '../modules/constants/content_types';

class AttachmentButton extends React.Component {

  static displayName = 'AttachmentButton';

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    preview: PropTypes.string, // image url or data url
    onWillUpload: PropTypes.func,
    onDidUpload: PropTypes.func,
    canTakeScreenshot: PropTypes.bool
  };

  static defaultProps = {
    preview: '',
    onWillUpload() {},
    onDidUpload() {},
    canTakeScreenshot: false
  };

  state = {
    preview: this.props.preview,
    showMenu: false,
    uploading: false
  };

  componentDidMount() {
    if(typeof ReactTooltip !== 'undefined') {
      // only load in mainline app, not extension
      ReactTooltip.rebuild();
    }
  }

  resetState = () => {
    this.setState({
      preview: this.props.preview,
      showMenu: false,
      uploading: false
    });
  };

  onClick = event => {
    event.preventDefault();
    event.stopPropagation();

    if(this.props.canTakeScreenshot) {
      this.listenForDismiss();

      this.setState({
        showMenu: true
      });
    }
    else {
      this.onUploadImage(event);
    }
  };

  onUploadImage = event => {
    if(event) {
      event.preventDefault();
    }

    this.setState({
      showMenu: false
    });

    const target = ReactDOM.findDOMNode(this.refs.file);

    if(target) {
      target.click();
    }
  };

  onUploadError = () => {
    this.setState({
      uploading: false
    });

    this.context.utils.dialog.alert('Oops, something went wrong uploading this file. Please try again');

    // DEBUG
    console.error('AttachmentButton.onUploadError: error uploading');
  };

  onUploadFinish = (signingObject, file) => {
    const upload = _.extend(signingObject, {
      mimeType: file.type,
      fileName: file.name,
      type: this.getFileType(file.type)
    });

    this.setState({
      uploading: false
    });

    if(attrAccept(file, this.getAcceptableTypes().join(', '))) {
      this.props.onDidUpload(upload);
    }
    else {
      this.context.utils.dialog.alert('Sorry, we don\'t currently support files of this type (' + file.type + ').');
    }
  };

  onUploadProgress = file => {
    if(!this.state.uploading) {
      this.props.onWillUpload(file);
      this.setState({uploading: true, preview: null});
    }
  };

  onTakeScreenshot = event => {
    if(event) {
      event.preventDefault();
    }

    this.setState({
      showMenu: false
    });

    // DEBUG
    console.log('[klue] sending message from iframe to extension klue:ext:capture:start');

    window.parent.postMessage({type: 'klue:ext:capture:start'}, '*');

    handleMessageFromExtension(msg => {
      if(msg.type === 'klue:ext:capture:finished') {
        const blob = dataUrlToBlob(msg.body);

        blob.name = 'screenshot.png';

        this.uploadFile(blob);
      }
    });
  };

  uploadFile = file => {
    this.props.onWillUpload(file);

    return new S3Upload({
      fileElement: {files: [file]},
      getSignedUrl: (f, cb) => getSignedUrl(f, cb, this.onUploadError),
      usePostForm: true,
      onProgress: this.onUploadProgress,
      onFinishS3Put: this.onUploadFinish,
      onError: this.onUploadError,
      signingUrlHeaders: {},
      signingUrlQueryParams: {},
      uploadRequestHeaders: {'x-amz-acl': 'public-read'},
      server: ''
    });
  };

  listenForDismiss = () => window.addEventListener('click', () => {
    this.setState({
      showMenu: false
    });
  }, {once: true});

  getFileType = mimeType => {
    if(DOCUMENT_CONTENT_TYPES.indexOf(mimeType) >= 0) {
      return 'document';
    }
    else if(_.find(IMAGE_CONTENT_TYPES, function(type) { return mimeType.match(new RegExp(type.replace('*', '.+'))); })) {
      return 'image';
    }

    return 'other';
  };

  getAcceptableTypes = () => {
    return IMAGE_CONTENT_TYPES.concat(DOCUMENT_CONTENT_TYPES);
  };

  renderMenu = () => {
    if(!this.state.showMenu) {
      return;
    }

    return (
      <ul className="photo-attachment-menu">
        <li><a href="#" onClick={this.onTakeScreenshot}><i className="fa fa-camera" />Take a screenshot</a></li>
        <li><a href="#" onClick={this.onUploadImage}><i className="fa fa-cloud-upload" />Upload file</a></li>
      </ul>
    );
  };

  renderUploader = () => {
    if(this.state.uploading) {
      return (
        <div className="upload-indicator" />
      );
    }

    const {canTakeScreenshot} = this.props;
    const fileUploadIcon = classNames('fa', {
      'fa-camera': canTakeScreenshot,
      'fa-cloud-upload': !canTakeScreenshot
    });
    const fileUploadTooltip = canTakeScreenshot ? 'Take a Screenshot' : 'Upload an Attachment';

    return (
      <div className="file-uploader-control">
        <i onClick={this.onClick} className={fileUploadIcon} data-tip={fileUploadTooltip} data-place="top" data-offset="{'top': 0}" aria-hidden="true" />
        {this.renderMenu()}
        <ReactS3Uploader
          ref="file"
          getSignedUrl={(f, cb) => getSignedUrl(f, cb, this.onUploadError)}
          usePostForm={true}
          accept={this.getAcceptableTypes()}
          onProgress={this.onUploadProgress}
          onError={this.onUploadError}
          onFinish={this.onUploadFinish}
          uploadRequestHeaders={{'x-amz-acl': 'public-read'}} />
      </div>
    );
  };

  render() {
    return (
      <div className="photo-attachment-button">
        {this.renderUploader()}
      </div>
    );
  }

}

export default AttachmentButton;
