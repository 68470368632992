import PostList from './_post_list';
import BoardCollapsed from './_board_collapsed';
import Icon from './_icon';
import URI from 'urijs';

import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';

import {throttle} from 'lodash';

import {fetch} from '../modules/api_utils';
import {withPosts} from '../contexts/_posts';

const INITIAL_STATE =
  {
    data: [],
    likes: {},
    hasLoadedFromServer: false,
    hasMore: true
  };

function LoadPostsQueue() {
  this.cancelled = false;
  this.loads = [];
}

LoadPostsQueue.prototype.cancel = function() {
  this.cancelled = true;
  this.loads = [];
};

LoadPostsQueue.prototype.startLoading = function({postURL, postBox}) {
  this.currentlyLoading = fetch(postURL);
  this.currentlyLoading
    .then(({data: posts}) => {
      if(this.cancelled || !postBox._isMounted) {
        return;
      }

      postBox.props.updatePosts(posts);
      postBox.setState({
        data: [...postBox.state.data, ...posts],
        hasMore: posts.length > 0,
        hasLoadedFromServer: true
      }, () => {
        if(this.cancelled || !postBox._isMounted) {
          return;
        }

        const postIds = posts.map(p => p.postId);

        postBox.props.asyncLoadLikes(postIds);

        this.currentlyLoading = null;

        if(this.loads.length) {
          const nextLoad = this.loads.shift();

          if(nextLoad) {
            this.startLoading(nextLoad);
          }
        }
      });
    })
    .catch(error => {
      if(this.cancelled || !postBox._isMounted) {
        return;
      }

      postBox.setState({postLoadingError: error});
    });
};

LoadPostsQueue.prototype.push = function(postURL, postBox) {
  if(this.cancelled || !postBox._isMounted) {
    return;
  }

  if(this.currentlyLoading) {
    return this.loads.push({postURL, postBox});
  }

  this.startLoading({postURL, postBox});
};

class PostBox extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    rivals: PropTypes.object,
    digest: PropTypes.object,
    onPinnedCommentUpdatedForFavorite: PropTypes.func,
    onUpdateFavorite: PropTypes.func,
    onDeleteFavorite: PropTypes.func,
    digestMode: PropTypes.bool,
    query: PropTypes.string,
    findPostId: PropTypes.number,
    showComments: PropTypes.bool,
    selectedPostData: PropTypes.object,
    onToggleFeedFilters: PropTypes.func,
    showFeedFilters: PropTypes.bool,
    onFindPost: PropTypes.func,
    onToggleFeedItem: PropTypes.func,
    /* eslint-disable react/no-unused-prop-types */
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    postUserId: PropTypes.number,
    postSource: PropTypes.string,
    postDateStart: PropTypes.string,
    postDateEnd: PropTypes.string,
    postRivals: PropTypes.array,
    /* eslint-enable react/no-unused-prop-types */
    dim: PropTypes.bool,
    isFeedCollapsed: PropTypes.bool,
    onToggleFeed: PropTypes.func,
    updatePosts: PropTypes.func,
    digestTypesData: PropTypes.arrayOf(PropTypes.object),
    onDigestTypeFavoriteUpdate: PropTypes.func,
    reorderingCards: PropTypes.bool,
    postsContext: PropTypes.shape({
      posts: PropTypes.object
    }).isRequired
  };

  static defaultProps = {
    user: null,
    users: {},
    rivals: {},
    digest: null,
    onPinnedCommentUpdatedForFavorite: null,
    onUpdateFavorite: null,
    onDeleteFavorite: null,
    digestMode: false,
    query: '',
    findPostId: null,
    showComments: false,
    selectedPostData: null,
    onToggleFeedFilters() {},
    showFeedFilters: false,
    onFindPost() {},
    onToggleFeedItem() {},
    sortBy: '',
    sortDirection: '',
    postUserId: 0,
    postSource: '',
    postDateStart: '',
    postDateEnd: '',
    postRivals: [],
    dim: false,
    isFeedCollapsed: false,
    onToggleFeed() {},
    updatePosts() {},
    digestTypesData: [],
    onDigestTypeFavoriteUpdate() {},
    reorderingCards: false
  };

  state = {...INITIAL_STATE};

  componentDidMount() {
    const {isFeedCollapsed} = this.props;

    this._isMounted = true;

    // NOTE: this should only fire on secondary loads (i.e. navigating *back* to Feed from other non-Feed component routes)
    if(!isFeedCollapsed) {
      // first load
      this.loadNextPosts();
    }

    this.attachScrollListener();
  }

  componentDidUpdate(prevProps, prevState) {
    const feedGotOpened = prevProps.isFeedCollapsed && !this.props.isFeedCollapsed;

    if(
      (!isEmpty(prevState.data) &&
      !isEmpty(this.state.data) &&
      prevState.data.length !== this.state.data.length) || feedGotOpened
    ) {
      this.attachScrollListener();
    }
  }

  componentWillUnmount() {
    if(!this.context.utils.userCanCurate()) {
      klueMediator.remove('klue:profile:boards:updateCount');
    }

    this.detachScrollListener();
    this._loadPostsQueue.cancel();

    this._isMounted = false;
  }

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {
      props: {
        isFeedCollapsed,
        digestMode,
        findPostId
      }
    } = this;
    const {isFeedCollapsed: nextIsFeedCollapsed, digestMode: nextDigestMode, findPostId: nextFindPostId} = nextProps;
    const {data} = this.state;
    const willExpandFeed = isFeedCollapsed && !nextIsFeedCollapsed;
    const hasData = Boolean(data.length);
    const filterHasChanged = this._filterHasChanged(nextProps);
    const shouldResetFeed = (!hasData && willExpandFeed) || (!nextIsFeedCollapsed && filterHasChanged);
    const {rival} = this.context.utils;
    const {rival: nextRival} = nextContext.utils;
    const willChangeRival = (rival || {}).id !== (nextRival || {}).id;

    /**
     * Check by deep comparison using the activeFilters value to see if the feed is
     * being filtered or if the feed was being filtered and is no longer
     */
    if(shouldResetFeed || willChangeRival) {
      // reset state and request new results based on query
      this._page = 0;
      this._loadPostsQueue.cancel();
      this._loadPostsQueue = new LoadPostsQueue();
      this.setState(INITIAL_STATE, () => {
        if(!nextIsFeedCollapsed) {
          // request posts from search
          this.loadNextPosts(nextProps, nextContext);

          const profile = this._getProfile();

          if(!isEmpty(profile) && this.postBoxInner) {
            this.postBoxInner.scrollTop = 0;
          }
        }
      });
    }

    const location = this._getCurrentLocation();
    const nextLocation = this._getCurrentLocation(nextContext);

    if(location && nextLocation && location !== nextLocation || (digestMode && nextDigestMode && findPostId !== nextFindPostId)) {
      // add slight delay to account for transition/ender delay when switching between feed/profile/digest views
      setTimeout(() => {
        this.detachScrollListener(this.attachScrollListener);
      }, 500);
    }
  }

  SCROLL_DELAY_THROTTLE = 500;
  _page = 0;
  _loadPostsQueue = new LoadPostsQueue();

  static regionTypes = {
    NONE: 0,
    FEED: 'feed',
    CURATOR_TOOLS: 'tools'
  };

  _getFilterValuesFromProps = props => {
    const feedFilters = ['query',
      'sortBy',
      'sortDirection',
      'postUserId',
      'postSource',
      'postDateStart',
      'postDateEnd',
      'findPostId'
    ];

    return feedFilters.reduce((obj, filterName) => ({
      ...obj,
      [filterName]: props[filterName]
    }), {});
  };

  _filterHasChanged = nextProps => {
    const {props} = this;
    const currentFilters = this._getFilterValuesFromProps(props);
    const nextFilters = this._getFilterValuesFromProps(nextProps);

    return !_.isEqual(currentFilters, nextFilters);
  };

  toggleRegionVisibility = (region = PostBox.regionTypes.NONE, event) => {
    const {onToggleFeedItem, onToggleFeed} = this.props;

    if(event) {
      event.preventDefault();
    }

    const profile = this._getProfile();

    console.log(
      'PostBox.toggleRegionVisibility: profile: %o, region: %o',
      profile,
      region
    );

    if(!profile || region === PostBox.regionTypes.NONE) {
      return [];
    }

    const collapsedLanes = this.context.utils.getCollapsedLanes(profile.id);
    const laneIndex = collapsedLanes.findIndex(l => l === region);

    if(region === PostBox.regionTypes.FEED && laneIndex === -1) {
      // collapsing feed: if a feed item is fully expanded, cancel it
      onToggleFeedItem(null);
    }

    onToggleFeed();
  };

  _getCurrentLocation = (context = this.context) =>
    context.utils.getCurrentLocation(context.location);

  _getScrollableContainer = () => {
    const {digestMode} = this.props;

    return this._getCurrentLocation() === '/feed' || digestMode
      ? this.postBoxInner.parentNode.closest('.endless-container')
      : this.postBoxInner;
  };

  _getProfile = (context = this.context) => {
    const {profile = {}} = context.utils.rival || {};

    return profile;
  };

  _getPostsUrl = (page, props = this.props, context = this.context) => {
    // build uri from url, filters and page

    const terms = !_.isEmpty(props.postRivals) ? props.postRivals : context.utils.rival ? [context.utils.rival.name] : [];

    const query = props.query || '*';
    const uri = URI(props.url);

    const searchOptions = {
      t: terms,
      q: query,
      page
    };

    if(terms) {
      searchOptions.t = terms;
    }

    if(query) {
      searchOptions.q = query;
    }

    if(page) {
      searchOptions.page = page;
    }

    if(props.postUserId) {
      searchOptions.userId = props.postUserId;
    }

    if(props.postSource) {
      searchOptions.source = props.postSource;
    }

    if(props.sortBy) {
      searchOptions.sort = props.sortBy;
      searchOptions.dir = props.sortDirection;
    }

    if(props.postDateStart || props.postDateEnd) {
      searchOptions.dateStart = props.postDateStart;
      searchOptions.dateEnd = props.postDateEnd;
    }

    if(props.findPostId) {
      uri.filename(`posts/${props.findPostId}.json`);
    }

    uri.addSearch(searchOptions);

    return uri.toString();
  };

  // NOTE: news feed has three different scrollable states/containers (feed mode, digest mode, and profile mode)
  scrollListener = event => {
    const {target: el} = event;
    const {hasMore} = this.state;
    const {selectedPostData} = this.props;

    // if selectedPostData is set, we don't need to load more posts. This is a fix for #7398.
    // loadNextPage was unnecessarily called when viewing a single post since it was often the case
    // that posY (calculated below) was continually < offset.
    if(el && hasMore && !selectedPostData) {
      const offset = 333;
      const {scrollHeight, scrollTop, offsetHeight} = el;

      if(el && el.scrollTop > 0) {
        const posY = scrollHeight - scrollTop - offsetHeight;

        if(posY < offset) {
          this.detachScrollListener(this.loadNextPosts);
        }
      }
    }
  };

  startScrollListener = throttle(this.scrollListener, this.SCROLL_DELAY_THROTTLE);

  attachScrollListener = () => {
    const el = this._getScrollableContainer();

    if(el) {
      el.addEventListener('scroll', this.startScrollListener);
      el.addEventListener('resize', this.startScrollListener);

      this.scrollListener({target: el});
    }
  };

  detachScrollListener = callback => {
    const el = this._getScrollableContainer();

    if(el) {
      el.removeEventListener('scroll', this.startScrollListener);
      el.removeEventListener('resize', this.startScrollListener);
    }

    return typeof callback === 'function' && callback();
  };

  loadNextPosts = (props = this.props, context = this.context) => {
    const nextPage = this._page += 1;
    const {findPostId} = props;

    if(findPostId && nextPage > 1) {
      return;
    }

    const postURL = this._getPostsUrl(nextPage, props, context);

    this._loadPostsQueue.push(postURL, this);
  };

  pruneDeletedPost = postId => {
    let index;

    for(let i = 0; i < this.state.data.length; i++) {
      if(this.state.data[i].postId === postId) {
        index = i;
        break;
      }
    }

    if(index === undefined) {
      return;
    }

    this.setState({
      data: ReactUpdate(this.state.data, {$splice: [[index, 1]]})
    });
  };

  handleClearFindPostClick = event => {
    if(event) {
      event.preventDefault();
    }

    this.props.onFindPost(null);
  };

  setPostBoxRef = el => (this.postBoxInner = el);

  render() {
    const {v2Host} = this.context.appData;
    const {dim, query, selectedPostData, digestMode, findPostId, showFeedFilters, onToggleFeedFilters, reorderingCards} = this.props;
    const profile = this._getProfile();
    const isProfile = !isEmpty(profile);
    const feedItemToggled = Boolean(selectedPostData);
    const {postLoadingError, hasLoadedFromServer, data} = this.state;
    const showEmptyState = hasLoadedFromServer && data && !data.length;
    const showNoSearchResultsState = query && query.length && data && !data.length && hasLoadedFromServer;
    const showPostLoadingError = Boolean(postLoadingError);
    const postBoxClass = classNames('post-box', {
      'endless-container': Boolean(profile) || digestMode
    });
    let collapsedLanes = [];
    let feedHeader;
    let feedFiltersToggle;
    let feedFiltersToggleClass;
    let feedRegion;
    let clearFindPostFilterRegion;
    let profileMode = false;
    let feedTitle = 'Feed';
    let uiEmptyState;

    if(isProfile) {
      profileMode = true;

      if(profile.name) {
        feedTitle = `${profile.name} ${feedTitle}`;
      }

      collapsedLanes = this.context.utils.getCollapsedLanes(profile.id);
    }

    if(showPostLoadingError) {
      uiEmptyState = (
        <div className="layout-posts-box-empty-state-user-message">
          <div className="empty-state-user-message">
            <div className="empty-state-user-message_heading">
              <i className="fa fa-ban" aria-hidden="true" /> An error occurred while loading posts.
            </div>
            <div className="empty-state-user-message_text">
              Please try refreshing your page to fix the issue.
            </div>
          </div>
        </div>
      );
    }
    else if(showNoSearchResultsState) {
      uiEmptyState = (
        <div className="layout-posts-box-empty-state-user-message">
          <div className="empty-state-user-message">
            <div className="empty-state-user-message_heading">
              <i className="fa fa-ban" aria-hidden="true" /> No posts match your
              query:
            </div>
            <div className="empty-state-user-message_heading">
              <strong>&quot;{query}&quot;</strong>
            </div>
            <div className="empty-state-user-message_text">
              Unfortunately, we couldn&apos;t find any posts for that term.
              Please add more posts to your feed, or search for another term.
            </div>
          </div>
        </div>
      );
    }
    else if(showEmptyState) {
      let curatorFeedMessage;

      if(this.context.utils.userCanCurate()) {
        curatorFeedMessage = (
          <li>
            from automatically found content in{' '}
            <strong>
              <Link to="/alerts">Alerts</Link>
            </strong>
            .
          </li>
        );
      }

      uiEmptyState = (
        <div className="empty-state-user-message">
          <div className="empty-state-user-message_heading">
            Your Klue feed is empty,
            <br />
            but adding posts to your feed is easy.
          </div>
          <p className="empty-state-user-message_text">Add posts...</p>
          <ol className="empty-state-user-message_text u-p0">
            <li>
              from any website with the{' '}
              <strong>
                <a href={`//${v2Host}/integrations`}>
                  Klue Button
                </a>
              </strong>
              .
            </li>
            {curatorFeedMessage}
            <li>
              from your{' '}
              <strong>
                <Link to={`/users/${this.context.utils.user.id}`}>email</Link>
              </strong>
              .
            </li>
            <li>
              using the{' '}
              <strong>
                <a href="https://appsto.re/ca/k4Hcgb.i" target="_blank">
                  Klue iPhone app
                </a>
              </strong>
              .
            </li>
          </ol>
        </div>
      );
    }

    if(digestMode && findPostId) {
      clearFindPostFilterRegion = (
        <div className="post-box_buttons">
          <a
            href="#"
            className="button"
            onClick={this.handleClearFindPostClick}>
            <Icon
              icon="arrow-left"
              width="24"
              height="24"
              className="post-box_buttons_icon" />
            Back to Feed
          </a>
        </div>
      );
    }
    else if(isProfile) {
      feedFiltersToggleClass =
        'feed-filters-toggle' +
        (showFeedFilters ? ' feed-filters-toggle--active' : '');
      feedFiltersToggle = (
        <div
          className={feedFiltersToggleClass}
          data-tip={'Search Feed'}
          data-offset="{'top': 0}"
          onClick={onToggleFeedFilters}>
          <i className="fa fa-search feed-filters-toggle_icon" />
        </div>
      );
    }

    if(isProfile) {
      feedHeader = (
        <div className="board-header">
          <div className="board-header_title board-name">
            <i
              id="onboardBCStep4Target1"
              className="fa fa-newspaper-o board-header_title_icon" />{' '}
            {feedTitle}
          </div>
          <div className="board-header_actions">
            {feedFiltersToggle}
            <div
              className="btn btn-board-header btn-board-header--collapse btn-dropdown-size-38"
              data-tip={'Collapse Feed'}
              data-offset="{'top': 0}"
              onClick={e => {
                this.toggleRegionVisibility(PostBox.regionTypes.FEED, e);

                // hide filter box when lane collapses
                if(showFeedFilters) {
                  onToggleFeedFilters(e);
                }
              }
              }>
              <Icon icon="collapse" width="24" height="24" />
            </div>
          </div>
        </div>
      );
    }

    const swimlaneClass = classNames('swimlane ui-feed', {
      'feed--collapsed': this.props.isFeedCollapsed,
      'ui-feed--dim': dim
    });

    if(this.props.isFeedCollapsed) {
      feedRegion = (
        <div className={swimlaneClass}>
          <BoardCollapsed
            board={{id: 0, name: feedTitle}}
            disable={reorderingCards}
            onToggleClick={e =>
              this.toggleRegionVisibility(PostBox.regionTypes.FEED, e)
            } />
        </div>
      );
    }
    else {
      const {
        rivals,
        user,
        users,
        showComments,
        onToggleFeedItem,
        digest,
        onPinnedCommentUpdatedForFavorite,
        onUpdateFavorite,
        onDeleteFavorite,
        digestTypesData,
        onDigestTypeFavoriteUpdate,
        postsContext: {
          posts
        }
      } = this.props;

      feedRegion = (
        <div className={swimlaneClass}>
          {feedHeader}
          {clearFindPostFilterRegion}
          <div ref={this.setPostBoxRef} className={postBoxClass}>
            {uiEmptyState}
            <PostList
              data={data}
              posts={posts}
              rivals={rivals}
              user={user}
              users={users}
              showComments={showComments}
              onPostDelete={this.pruneDeletedPost}
              profileMode={profileMode}
              profile={profile}
              feedItemToggled={feedItemToggled}
              toggledPostId={
                      selectedPostData
                        ? selectedPostData.postId
                        : 0
                  }
              onToggleFeedItem={onToggleFeedItem}
              digest={digest}
              digestMode={digestMode}
              onPinnedCommentUpdatedForFavorite={onPinnedCommentUpdatedForFavorite}
              onUpdateFavorite={onUpdateFavorite}
              onDeleteFavorite={onDeleteFavorite}
              singlePostMode={Boolean(findPostId)}
              digestTypesData={digestTypesData}
              onDigestTypeFavoriteUpdate={onDigestTypeFavoriteUpdate} />
          </div>
        </div>
      );
    }

    return feedRegion;
  }

}

const mapStateToProps = ({rivals}) => ({rivals: rivals.items});

const mapDispatchToProps = dispatch => {
  return {
    asyncLoadLikes: (postIds = []) => dispatch.feed.asyncLoadLikes(postIds)
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(PostBox);

export {PostBox};

export default withPosts(connected);
