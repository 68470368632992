import Icon from './_icon';
import DigestSelector from './_digest_selector';
import DigestScheduleAndAudience from './_digest_schedule_and_audience';

const DigestHeaderSettings = ({
  isCurator,
  isAdmin,
  digest,
  activeDigestType,
  archivesMode,
  canSendDigest,
  canEditDigest,
  reorderMode,
  digestTypes,
  visibilityGroups,
  onSelectDigestType,
  onEdit,
  onAdd,
  onSendDigest
}, context) => {
  const {manual, archivedAt} = digest || {};
  const {archivedAt: digestTypeArchivedAt} = activeDigestType || {};
  const {utils: {isDigestTemplatesEnabled}} = context || {};
  const showDigestTemplates = isDigestTemplatesEnabled();

  const handleOnEdit = () => onEdit(activeDigestType);

  return (
    <div className="digest-settings-container">
      <div className="digest-settings">
        <DigestSelector
          isCurator={isCurator}
          isAdmin={isAdmin}
          archivesMode={archivesMode}
          manualDigest={manual}
          archivedAt={archivedAt}
          activeDigestType={activeDigestType}
          digestTypes={digestTypes}
          onSelectDigestType={onSelectDigestType}
          onAdd={archivesMode ? null : onAdd} />
        {isCurator && !archivesMode && <DigestScheduleAndAudience
          digest={digest}
          canSendDigest={canSendDigest}
          canEditDigest={canEditDigest}
          reorderMode={reorderMode}
          visibilityGroups={visibilityGroups}
          onSendDigest={onSendDigest} />}
      </div>
      {(isAdmin || (isCurator && showDigestTemplates)) && !manual && !digestTypeArchivedAt &&
      <div
        className="digest-settings-edit-button"
        onClick={handleOnEdit}
        data-tracking-id="digest-settings-edit-button"
        data-testid="digest-settings-edit-button">
        <Icon icon="settings_outline" width="24" height="24" />
      </div>}
    </div>
  );
};

DigestHeaderSettings.contextTypes = {
  utils: PropTypes.object.isRequired
};

DigestHeaderSettings.propTypes = {
  isCurator: PropTypes.bool,
  isAdmin: PropTypes.bool,
  digest: PropTypes.object,
  activeDigestType: PropTypes.object,
  archivesMode: PropTypes.bool,
  canSendDigest: PropTypes.bool,
  canEditDigest: PropTypes.bool,
  reorderMode: PropTypes.bool,
  digestTypes: PropTypes.arrayOf(PropTypes.object),
  visibilityGroups: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onSendDigest: PropTypes.func,
  onSelectDigestType: PropTypes.func
};

DigestHeaderSettings.defaultProps = {
  isCurator: false,
  isAdmin: false,
  digest: {},
  activeDigestType: {},
  archivesMode: false,
  canSendDigest: false,
  canEditDigest: false,
  reorderMode: false,
  digestTypes: [],
  visibilityGroups: [],
  onEdit() {},
  onAdd() {},
  onSendDigest() {},
  onSelectDigestType() {}
};

export default DigestHeaderSettings;
