import DashboardEditGroupsList from './_dashboard_edit_groups_list';

// layout components
import Grid from './layout/_grid';

import classNames from 'classnames';

/* eslint-disable react/no-multi-comp */
class DashboardEditGroups extends React.Component {

  static propTypes = {
    rivals: PropTypes.arrayOf(PropTypes.object),
    rivalGroups: PropTypes.arrayOf(PropTypes.object),
    unfilteredRivals: PropTypes.arrayOf(PropTypes.object),
    activeGroupId: PropTypes.number,
    editGroupId: PropTypes.number,
    showAddGroup: PropTypes.bool,
    groupDragging: PropTypes.bool,
    groupPositions: PropTypes.arrayOf(PropTypes.number),
    highlightedGroups: PropTypes.arrayOf(PropTypes.number),
    isLoaded: PropTypes.bool,
    onEditGroupClick: PropTypes.func.isRequired,
    onSaveGroupClick: PropTypes.func.isRequired,
    onDeleteGroupClick: PropTypes.func.isRequired,
    onReorderGroupDrag: PropTypes.func.isRequired,
    onReorderGroupDrop: PropTypes.func.isRequired,
    onReorderGroupMove: PropTypes.func.isRequired,
    onToggleGroupClick: PropTypes.func.isRequired,
    onToggleGroupMembership: PropTypes.func.isRequired,
    onToggleAddGroupForm: PropTypes.func.isRequired,
    onAddRivalGroupClick: PropTypes.func.isRequired
  };

  static defaultProps = {
    rivals: [],
    rivalGroups: [],
    unfilteredRivals: [],
    activeGroupId: 0,
    editGroupId: 0,
    showAddGroup: false,
    groupDragging: false,
    groupPositions: [],
    highlightedGroups: [],
    isLoaded: false,
    onEditGroupClick() {},
    onSaveGroupClick() {},
    onDeleteGroupClick() {},
    onReorderGroupDrag() {},
    onReorderGroupDrop() {},
    onReorderGroupMove() {},
    onToggleGroupClick() {},
    onToggleGroupMembership() {},
    onToggleAddGroupForm() {},
    onAddRivalGroupClick() {}
  };

  componentDidMount() {
    console.log('DashboardEditGroups.componentDidMount: props: %o', this.props);
  }

  handleAddRivalGroupClick = event => {
    if(event) {
      event.preventDefault();
    }

    const groupName = this.refs.newGroupName.value.trim();

    this.props.onAddRivalGroupClick(groupName, success => {
      if(success) {
        this.refs.newGroupName.value = '';
      }
    });
  };

  render() {
    const {rivals, rivalGroups, showAddGroup, groupDragging, onToggleAddGroupForm} = this.props;

    if(!rivals || !rivalGroups) {
      return (
        <div className="dashboard-edit" />
      );
    }

    const groupsClasses = classNames('dashboard-group-list', {
      'dashboard-group-list--dragging': groupDragging
    });
    const addGroupRegion = showAddGroup && (
      <form className="dashboard-form dashboard-form--add" onSubmit={this.handleAddRivalGroupClick}>
        <label>
          <input
            ref="newGroupName"
            type="text"
            className="dashboard-input dashboard-input--edit"
            placeholder="New group name..."
            maxLength={255}
            autoFocus={true} />
          <div className="dashboard-group_edit">
            <button
              type="button"
              className="button button--small"
              onClick={this.handleAddRivalGroupClick}>
              Save
            </button>
            <button
              type="button"
              className="button button--small button--disabled"
              onClick={e => onToggleAddGroupForm(false, e)}>
              Cancel
            </button>
          </div>
        </label>
      </form>
    );

    return (
      <div className="dashboard-edit">
        <Grid columns={2} classes="ui">
          <h4 className="dashboard-heading">Your Groups</h4>
          <div className="right">
            <button
              type="button"
              className="button button--small"
              onClick={e => onToggleAddGroupForm(true, e)}>
              New Group
            </button>
          </div>
        </Grid>
        {addGroupRegion}
        <DashboardEditGroupsList className={groupsClasses} {...this.props} />
      </div>
    );
  }

}

export default DashboardEditGroups;
