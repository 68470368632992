import DropdownMenu from './_dropdown_menu';
import Icon from './_icon';
import NewBoardWizard from './_new_board_wizard/_new_board_wizard';

import {dig} from '../modules/utils';
import {userCanCurate} from '../modules/roles_utils';

import classNames from 'classnames';
import {Link} from 'react-router-dom';
import ViewAsDropdown from './_view_as_dropdown';

class DashboardToolbar extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object,
    rivals: PropTypes.arrayOf(PropTypes.object),
    rivalGroups: PropTypes.arrayOf(PropTypes.object),
    activeGroup: PropTypes.object,
    showGroupings: PropTypes.bool.isRequired,
    editMode: PropTypes.bool.isRequired,
    sortBy: PropTypes.string.isRequired,
    companyAddOpen: PropTypes.bool.isRequired,
    onGroupClick: PropTypes.func.isRequired,
    onSortClick: PropTypes.func.isRequired,
    companyFilter: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onFilterCancel: PropTypes.func.isRequired,
    onCreateBoard: PropTypes.func.isRequired,
    onCompanyAddClick: PropTypes.func.isRequired,
    onGetCompanyData: PropTypes.func.isRequired,
    onSaveCompanyDefaults: PropTypes.func.isRequired,
    onToggleCompanyAddClick: PropTypes.func.isRequired,
    onViewAs: PropTypes.func,
    isBoardReady: PropTypes.bool.isRequired,
    noDataAutomatedCards: PropTypes.array.isRequired,
    newBoardData: PropTypes.object,
    onCreateAutomatedCards: PropTypes.func.isRequired
  };

  static defaultProps = {
    user: null,
    rivals: null,   // null = not finished loading; [] = no rivals found for company
    rivalGroups: [],
    activeGroup: null,
    showGroupings: false,
    editMode: false,
    sortBy: 'cards',
    companyAddOpen: false,
    onGroupClick() {},
    onSortClick() {},
    companyFilter: '',
    onFilterChange() {},
    onFilterCancel() {},
    onCreateBoard() {},
    onCompanyAddClick() {},
    onGetCompanyData() {},
    onSaveCompanyDefaults() {},
    onToggleCompanyAddClick() {},
    onViewAs() {},
    newBoardData: {},
    noDataAutomatedCards: []
  };

  state = {};

  componentDidMount() {
    console.log('DashboardToolbar.componentDidMount: props: %o', this.props);

    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleFilterClick = event => {
    if(this.props.companyFilter) {
      this.refs.filterCompanies.value = '';

      this.props.onFilterCancel(event);
    }
    else {
      this.props.onFilterChange(event, this.refs.filterCompanies.value);
    }
  };

  handleFilterKeyDown = event => {
    event = event || window.event;

    if(event.which === 27) {
      this.props.onFilterCancel(event);
    }
    else if(event.which === 13) {
      this.props.onFilterChange(event);
    }
  };

  renderCuratorTools = () => {
    const {editMode, onCompanyAddClick, onSaveCompanyDefaults, onViewAs, companyAddOpen} = this.props;
    const {user} = this.context.utils;

    if(!userCanCurate({user})) {
      return;
    }

    const buttonEditGroupsClasses = classNames('toolbar-button', {
      'toolbar-button--focused': editMode && !companyAddOpen
    });
    const buttonNewBoardClasses = classNames('toolbar-button', {
      'toolbar-button--focused': companyAddOpen
    });
    let curatorToolsNodes = [];

    if(editMode) {
      curatorToolsNodes.push(
        <Link key="dashboard-back" to="/" className="toolbar-button">
          <div className="toolbar-button_icon toolbar-button_icon--left"><Icon icon="arrow-left" width="24" height="24" /></div>
          <div className="toolbar-button_label">Back</div>
        </Link>
      );
    }

    curatorToolsNodes = curatorToolsNodes.concat([
      (
        <div
          id="onboardBCStep6Target1"
          key="dashboard-new-company"
          className={buttonNewBoardClasses}
          data-tracking-id="dashboard-toolbar-add-new-board"
          title="Create a new Board"
          onClick={onCompanyAddClick}>
          <div className="toolbar-button_icon toolbar-button_icon--left"><Icon icon="add_circle" width="24" height="24" /></div>
          <div className="toolbar-button_label">New Board</div>
        </div>
      ), (
        <Link
          key="dashboard-edit-groups"
          to={editMode ? '/' : '/dashboard/edit'}
          className={buttonEditGroupsClasses}
          title="Edit Groups">
          <div className="toolbar-button_icon toolbar-button_icon--left"><Icon icon="list" width="24" height="24" /></div>
          <div className="toolbar-button_label">Edit Groups</div>
        </Link>
      )
    ]);

    if(!editMode) {
      // keep "save company defaults" option on dashboard view only (groups toggle not visible on edit view)
      curatorToolsNodes.push(
        <div
          href="#"
          key="dashboard-save-settings"
          className="toolbar-button"
          onClick={onSaveCompanyDefaults}
          title="Save Company Defaults">
          <div className="toolbar-button_icon toolbar-button_icon--left"><Icon icon="cloud-upload" width="24" height="24" /></div>
          <div className="toolbar-button_label">Save Company Defaults</div>
        </div>
      );
    }

    if(!editMode) {
      curatorToolsNodes.push(<ViewAsDropdown key="dashboard-view-as-dropdown" trackingId="dashboard-toolbar" onViewAs={onViewAs} />);
    }

    return curatorToolsNodes;
  };

  renderCompanyAddRegion = () => {
    const {user, onCreateBoard, companyAddOpen, onToggleCompanyAddClick, activeGroup, isBoardReady, noDataAutomatedCards,
      newBoardData, onCreateAutomatedCards} = this.props;

    if(!companyAddOpen || !userCanCurate({user})) {
      return;
    }

    return (
      <NewBoardWizard
        onCreateBoard={onCreateBoard}
        onCreateAutomatedCards={onCreateAutomatedCards}
        activeGroup={activeGroup}
        isBoardReady={isBoardReady}
        noDataAutomatedCards={noDataAutomatedCards}
        newBoardData={newBoardData}
        onClose={onToggleCompanyAddClick} />
    );
  };

  renderGroupsDropdown = () => {
    const {editMode, activeGroup, showGroupings, rivalGroups, onGetCompanyData, onGroupClick} = this.props;
    const groups = (rivalGroups || []).slice();

    if(_.isEmpty(groups) || editMode) {
      return;
    }

    const groupOptions = [
      [0, 'All Companies'],
      [-1, 'All Groups']
    ];

    for(let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const showSeparator = (i === 0) || (group.id === 0);
      const option = [group.id, group.name, showSeparator];

      // "ungrouped companies" item
      if(group.id === 0) {
        // TODO: re-enable this when ungrouped companies filter works properly with activeGroupId=0 on dashboard
        // option.push('toolbar-dropdown-button--alt');
        continue;
      }

      groupOptions.push(option);
    }

    const companyData = onGetCompanyData();
    const defaultGroupId = dig(companyData, 'defaults.dashboard.activeGroupId', null);

    if((activeGroup && (defaultGroupId !== activeGroup.id))
      || (!activeGroup && showGroupings && defaultGroupId !== -1)
      || (!activeGroup && !showGroupings && defaultGroupId !== 0)) {
      groupOptions.push(['default', '(Use Company Default)', true]);
    }

    return (
      <DropdownMenu
        mobileLabel={<Icon icon="filter" height="18" width="18" />}
        id="groupFilterDropdown"
        values={groupOptions}
        label="View:"
        title="Filter Groups"
        selectedValue={showGroupings ? -1 : (activeGroup ? activeGroup.id : 0)}
        defaultValue="default"
        selectCallback={onGroupClick} />
    );
  };

  renderSortDropdown = () => {
    const {sortBy, onGetCompanyData, onSortClick} = this.props;

    if(sortBy === '') {
      return;
    }

    const sortOptions = [
      ['cards', '# of Cards'],
      ['battlecards', 'Has Battlecard'],
      ['created', 'Newest'],
      ['created-rev', 'Oldest'],
      ['alpha', 'A-Z'],
      ['alpha-rev', 'Z-A']
    ];
    const companyData = onGetCompanyData();
    const defaultSortBy = dig(companyData, 'defaults.dashboard.sortBy', null);

    // show "use company default" option if user has a different sort option selected
    if(defaultSortBy && (defaultSortBy !== sortBy)) {
      sortOptions.push(['default', '(Use Company Default)', true]);
    }

    return (
      <DropdownMenu
        mobileLabel={<Icon fill="currentColor" icon="sortArrows" height="16" width="16" />}
        id="sortDropdown"
        values={sortOptions}
        label="Sort By:"
        title="Sort By"
        selectedValue={sortBy}
        defaultValue={'default'}
        selectCallback={onSortClick} />
    );
  };

  renderCompaniesFilter = () => {
    const {editMode, rivals, companyFilter, onFilterChange} = this.props;

    if((!companyFilter && _.isEmpty(rivals)) || editMode) {
      return;
    }

    let companyFilterPlaceholder = 'Filter companies';

    if(window.matchMedia('(max-width: 980px)').matches) {
      companyFilterPlaceholder = 'Filter';
    }

    return (
      <form
        key="filter-search"
        className="toolbar-input"
        onChange={onFilterChange}
        onKeyDown={this.handleFilterKeyDown}
        onSubmit={e => e.preventDefault()}>
        <input ref="filterCompanies" className="toolbar-input_input" type="search" placeholder={`${companyFilterPlaceholder}\u2026`} />
        <input type="submit" className="toolbar-input_faux-submit" />
        <div className="toolbar-input_icon" onClick={this.handleFilterClick}>
          <Icon icon={companyFilter ? 'close' : 'search'} width="16" height="16" />
        </div>
      </form>
    );
  };

  render() {
    return (
      <div className="dashboard-toolbar">
        <div className="toolbar">
          <div className="toolbar_left toolbar-button-group--wide">
            {this.renderCuratorTools()}
          </div>
          <div className="toolbar_right">
            {this.renderGroupsDropdown()}
            {this.renderSortDropdown()}
            {this.renderCompaniesFilter()}
          </div>
        </div>
        {this.renderCompanyAddRegion()}
      </div>
    );
  }

}

export default DashboardToolbar;
