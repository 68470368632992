import Spinner from './_spinner';
import {rivalsList} from '../modules/api/rivals';

import classNames from 'classnames';
import {withRouter} from 'react-router-dom';

class ProfileOptionsModal extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    profile: PropTypes.object,
    onToggleDraftProfileClick: PropTypes.func,
    onCloneProfileClick: PropTypes.func,
    onDeleteRivalClick: PropTypes.func,
    onBulkEditClick: PropTypes.func
  };

  static defaultProps = {
    profile: null,
    onToggleDraftProfileClick: null,
    onCloneProfileClick: null,
    onDeleteRivalClick: null,
    onBulkEditClick: null
  };

  state = {
    rivals: null,
    cloneMode: false,
    boardsExpanded: false
  };

  componentDidMount() {
    this._isMounted = true;

    this.loadRivalsList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // load full rivals listing for profile cloning (w/minimal additional info and no nested profile data)
  loadRivalsList = () => {
    if(this._isMounted) {
      rivalsList({code: 'ProfileToolbar.loadRivalsList'})
        .then(rivals => this.setState({rivals}));
    }
  };

  handleToggleDraftProfileClick = (isDraft, event) => {
    if(event) {
      event.preventDefault();
    }

    const {onToggleDraftProfileClick} = this.props;

    onToggleDraftProfileClick(isDraft);
  };

  handleToggleCloneProfileClick = event => {
    if(event) {
      event.preventDefault();
    }

    const {target: {classList = []} = {}} = event;

    if(classList.contains('profile-options_clone-link') || classList.contains('profile-options_show-boards-link')) {
      return;
    }

    this.setState(prevState => ({cloneMode: !prevState.cloneMode}));
  };

  handleExpandBoardsClick = event => {
    if(event) {
      event.preventDefault();
    }

    if(event && event.target && event.target.classList.contains('profile-options_clone-link')) {
      return;
    }

    this.setState({boardsExpanded: true});
  };

  handleCloneProfileClick = (rival, event, isTemplate) => {
    if(event) {
      event.preventDefault();
    }

    const {onCloneProfileClick} = this.props;

    onCloneProfileClick(rival, isTemplate);
  };

  // TODO: some duplication here, next two methods could use merging/refactoring
  renderToggleDraftProfileRegion = () => {
    const {profile} = this.props;

    if(!profile) {
      return;
    }

    const {cloneMode} = this.state;
    const dialogButtonBlockClass = 'profile-settings-button-block' + (cloneMode ? ' profile-settings-button-block--inactive' : '');
    let toggleDraftProfileLabel;
    let toggleDraftProfileIcon;

    if(!profile.isDraft) {
      toggleDraftProfileLabel = 'Unpublish this board';
      toggleDraftProfileIcon = 'eye-slash';
    }
    else {
      toggleDraftProfileLabel = 'Publish this board';
      toggleDraftProfileIcon = 'eye';
    }

    return (
      <div className={dialogButtonBlockClass} onClick={e => this.handleToggleDraftProfileClick(!profile.isDraft, e)} data-help-id="publish-board-button">
        <h5 className="profile-settings-block-heading">
          <i className={'fa fa-' + toggleDraftProfileIcon} />{toggleDraftProfileLabel}
        </h5>
        <div className="profile-settings-block-body">
          <p>Publish or unpublish this board. Only curators can see draft boards. You can change this at any time.</p>
        </div>
      </div>
    );
  };

  // profile isTemplate value may be more recent if template toggle button has been hit
  isTemplate = (rival, profile) => ((rival.profileId === profile.id) ? profile.isTemplate : rival.isTemplate);

  renderCloneProfileRegion = () => {
    const {profile} = this.props;
    const {boardsExpanded, rivals} = this.state;
    const {cloneMode} = this.state;
    let cloneProfileRegion;

    if(profile && rivals && rivals.length) {
      const dialogButtonBlockClass = classNames('profile-settings-button-block', {'profile-settings-button-block--active': cloneMode});
      const rivalsListClass = classNames('profile-options_clone', {'profile-options_clone--expand': cloneMode});
      const expandBoardsClass = classNames('profile-options_show-boards-link', {'profile-options_show-boards-link--expand': cloneMode});
      const {templateNodes, boardNodes} = rivals.reduce((acc, rival) => {
        const {id, name, isTemplate = false} = rival;
        let nodes;
        let dataTrackingId;

        if(this.isTemplate(rival, profile)) {
          nodes = 'templateNodes';
          dataTrackingId = 'profile-settings-clone-template-link';
        }
        else {
          nodes = 'boardNodes';
          dataTrackingId = 'profile-settings-clone-board-link';
        }

        acc[nodes].push((
          <li key={id} className="profile-options_clone-item">
            <a
              href="#"
              className="profile-options_clone-link"
              onClick={e => this.handleCloneProfileClick(rival, e, isTemplate)}
              data-tracking-id={dataTrackingId}>{name}</a>
          </li>
        ));

        return acc;
      }, {templateNodes: [], boardNodes: []});
      const showBoards = (!templateNodes.length || boardsExpanded) && boardNodes.length;

      let title;
      let subtitleSubtext;

      if(templateNodes.length && boardNodes.length) {
        title = 'Clone from a template or board';
        subtitleSubtext = 'a template or another competitor\'s board';
      }
      else if(templateNodes.length) {
        title = 'Clone from a template';
        subtitleSubtext = 'a template';
      }
      else if(boardNodes.length) {
        title = 'Clone from a board';
        subtitleSubtext = 'another competitor\'s board';
      }
      else {
        return cloneProfileRegion;
      }

      cloneProfileRegion = (
        <div className={dialogButtonBlockClass} onClick={this.handleToggleCloneProfileClick} data-help-id="clone-board-button">
          <h5 className="profile-settings-block-heading">
            <i className="fa fa-clone" />{title}
          </h5>
          <div className="profile-settings-block-body">
            <p>{`Allows you to clone (copy) all of the lanes and cards from ${subtitleSubtext} into this one.`}</p>
            <ul id="selectCompetitor" className={rivalsListClass}>
              {templateNodes}
              {boardsExpanded && (<hr />)}
              {showBoards && boardNodes}
            </ul>
            {!showBoards && boardNodes.length && (
              <a href="#" className={expandBoardsClass} onClick={this.handleExpandBoardsClick}>show boards</a>
            )}
          </div>
        </div>
      );
    }

    return cloneProfileRegion;
  };

  renderDeleteRivalRegion = () => {
    const {cloneMode} = this.state;
    const {onDeleteRivalClick} = this.props;
    const dialogButtonBlockClass = 'profile-settings-button-block' + (cloneMode ? ' profile-settings-button-block--inactive' : '');

    return (
      <div className={dialogButtonBlockClass} onClick={onDeleteRivalClick} data-help-id="delete-profile-button">
        <h5 className="profile-settings-block-heading">
          <i className="fa fa-trash" />Delete this board
        </h5>
        <div className="profile-settings-block-body">
          <p>
            This will <strong>permanently delete</strong> this entire competitor board, including all associated battlecards, lanes and cards.<br />
            <i><strong>Warning:</strong> This action can&apos;t be undone. Consider unpublishing the board instead.</i>
          </p>
        </div>
      </div>
    );
  };

  renderBulkCardEditRegion = () => {
    const {cloneMode} = this.state;
    const {onBulkEditClick} = this.props;
    const dialogButtonBlockClass = 'profile-settings-button-block' + (cloneMode ? ' profile-settings-button-block--inactive' : '');

    return (
      <div
        className={dialogButtonBlockClass}
        onClick={onBulkEditClick}
        data-testid="bulk-edit-cards-button"
        data-tracking-id="profile-settings-bulk-card-actions">
        <h5 className="profile-settings-block-heading">
          <i className="fa fa-check-square" />Bulk Card Actions
        </h5>
        <div className="profile-settings-block-body">
          <p>Allows you to perform bulk actions on cards within a competitor board.</p>
        </div>
      </div>
    );
  };

  render() {
    const {profile: {name = ''} = {}} = this.props;
    const {rivals} = this.state;

    if(!rivals) {
      return (
        <div className="profile-options-modal">
          <h4><strong>{name}</strong> Board&nbsp;Actions</h4>
          <div className="profile-options-modal_loading-container">
            <Spinner />loading...
          </div>
        </div>
      );
    }

    return (
      <div className="profile-options-modal">
        <h4><strong>{name}</strong> Board&nbsp;Actions</h4>
        <p>As a curator, you can customize this company&apos;s board using the options below.</p>
        {this.renderToggleDraftProfileRegion()}
        {this.renderCloneProfileRegion()}
        {this.renderDeleteRivalRegion()}
        {this.renderBulkCardEditRegion()}

      </div>
    );
  }

}

export default withRouter(ProfileOptionsModal);
