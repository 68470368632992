import FroalaEditor from 'froala-editor';

FroalaEditor.POPUP_TEMPLATES['listTypes.popup'] = '[_BUTTONS_]';

Object.assign(FroalaEditor.DEFAULTS, {
  popupButtons: ['formatUL', 'formatOLSimple']
});

// custom popup plugin to display list type selection
// for reference, see:
// - https://www.froala.com/wysiwyg-editor/examples/custom-popup
// - https://github.com/froala/wysiwyg-editor/issues/2999#issuecomment-410018914
// - https://jsfiddle.net/froala/pzt4adxb/46/
FroalaEditor.PLUGINS.listTypes = function(editor) {
  const initPopup = () => {
    let buttons = '';

    if(editor.opts.popupButtons.length) {
      // define popup template
      buttons = `<div class="fr-buttons">${editor.button.buildList(editor.opts.popupButtons)}</div>`;
    }

    // create popup with specified template
    return editor.popups.create('listTypes.popup', {buttons});
  };

  const showPopup = () => {
    // conditionally initialize popup (perf)
    editor.popups.get('listTypes.popup') || initPopup();

    // set the editor toolbar as the popup container
    editor.popups.setContainer('listTypes.popup', editor.$tb);

    // grab jquery 😢 button from editor toolbar in order to place the popup relative to it
    const btn = editor.$tb.find('.fr-command[data-cmd="listTypesButton"]');

    const left = btn.offset().left;
    const top = btn.offset().top + (editor.opts.toolbarBottom ? 10 : btn.outerHeight() - 10);

    editor.popups.show('listTypes.popup', left, top, btn.outerHeight());
  };

  const hidePopup = () => editor.popups.hide('listTypes.popup');

  // externally exposed plugin methods
  return {
    showPopup,
    hidePopup
  };
};
