import React from 'react';
import PropTypes from 'prop-types';

import KlueTypeComponentFactory from './_klue_type_component_factory';
import PotentiallyLongContent from './_potentially_long_content';
import {formatLocation} from '../../../modules/card_utils';

const RegionalEntities = ({entityMap, cardId, cardCreatedAt, isDraft, analyticsEventContext}) => {
  const entities = entityMap.map(({key: region, value}) => {
    const regionTitle = formatLocation(region);

    return (
      <li
        className="job-postings-by-region_region"
        key={regionTitle}>
        <p className="job-postings-by-region_region-title">
          <i className="fa fa-map-marker" /> {regionTitle} {Array.isArray(value) ? `(${value.length})` : ''}
        </p>
        <KlueTypeComponentFactory
          val={value}
          cardId={cardId}
          cardCreatedAt={cardCreatedAt}
          isDraft={isDraft}
          analyticsEventContext={analyticsEventContext} />
      </li>
    );
  });

  return (
    <PotentiallyLongContent>
      <ul className="job-postings-by-region">
        {entities}
      </ul>
    </PotentiallyLongContent>
  );
};

RegionalEntities.propTypes = {
  entityMap: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.shape({
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired
    }).isRequired,
    value: PropTypes.any
  })).isRequired,
  cardId: PropTypes.number.isRequired,
  cardCreatedAt: PropTypes.string,
  isDraft: PropTypes.bool,
  analyticsEventContext: PropTypes.object
};

RegionalEntities.defaultProps = {
  entityMap: [],
  cardCreatedAt: '',
  isDraft: false,
  analyticsEventContext: {}
};

export default RegionalEntities;
