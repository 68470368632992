import moment from 'moment';

const AttachmentSource = ({profileMode, timestamp, name, url, onAttachmentClick}) => {
  let timestampRegion;
  const nameRegion = (<><a href={url} className="attachment-source_site" target="_blank" onClick={onAttachmentClick}>
    {name}
  </a>{profileMode ? ':' : null}&nbsp;</>);

  if(profileMode) {
    timestampRegion = (
      <div className="attachment-source_timestamp">
        <time dateTime={timestamp} title={new Date(timestamp)}>
          {moment(timestamp).fromNow()}
        </time>
      </div>
    );
  }

  return (
    <div className="attachment-source">
      {name ? nameRegion : null}
      {timestampRegion}
    </div>
  );
};

AttachmentSource.propTypes = {
  profileMode: PropTypes.bool,
  timestamp: PropTypes.string,     // ISO-8601 UTC date
  name: PropTypes.string,
  url: PropTypes.string,
  onAttachmentClick: PropTypes.func
};

AttachmentSource.defaultProps = {
  profileMode: false,
  timestamp: '',
  name: '',
  url: '',
  onAttachmentClick() {}
};

export default AttachmentSource;
