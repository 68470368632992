import {useEffect, useState} from 'react';
import {appInitialization, authentication, initialize} from '@microsoft/teams-js';

export const INIT_TIMEOUT = 1000;
export const SRC_PARAM = 'msteams';

export const klueIsInTeams = (src, win = window) => {
  const currentURL = new URL(win?.location.href);
  const redirectURL = new URL(`${currentURL?.origin}${currentURL?.searchParams.get('redirectTo') || '/'}`);
  const currentSrc = src
    ?? currentURL?.searchParams.get('src')
    ?? (redirectURL?.searchParams.get('src') || '');

  return Boolean((win?.parent === win?.self && win?.nativeInterface)
  || win?.name === 'embedded-page-container'
  || win?.name === 'extension-tab-frame'
  || win?.name?.startsWith('appAuthWindow_')
  || currentSrc.startsWith(SRC_PARAM));
};

export const useMicrosoftTeams = (opts = {}) => {
  const {onSuccess, onFailure} = opts;
  const [teamsInitialized, setTeamsInitialized] = useState(undefined);
  const inTeams = klueIsInTeams();

  useEffect(() => {
    if(inTeams) {
      const initTimeout = window.setTimeout(() => {
        const error = 'useMicrosoftTeams failed to initialize teams-js';

        window.clearTimeout(initTimeout);
        setTeamsInitialized(false);
        typeof onFailure === 'function' && onFailure(error);
        appInitialization?.notifyFailure({reason: error});
      }, INIT_TIMEOUT);

      if(!teamsInitialized) {
        initialize(() => {
          window.clearTimeout(initTimeout);
          setTeamsInitialized(true);
          typeof onSuccess === 'function' && onSuccess();
          appInitialization?.notifyAppLoaded();
          appInitialization?.notifySuccess();
        });
      }

      return () => {
        window.clearTimeout(initTimeout);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inTeams, onFailure, onSuccess]);

  return {teams: {isInitialized: teamsInitialized, authentication}};
};
