import DashboardCard from './_dashboard_card';
import CompanyLogo from './_company_logo';
import Icon from './_icon';

// layout components
import Grid from './layout/_grid';

const DashboardEditGroupRivals = ({group, rivals, onToggleGroupMembership}) => {
  if(_.isEmpty(group)) {
    return null;
  }

  const groupRivalIds = (group.rivals || []).map(r => r.id);
  const groupRivals = (rivals ? rivals.slice() : []).filter(rival => groupRivalIds.includes(rival.id));

  if(!groupRivals || !groupRivals.length) {
    if(rivals && rivals.length) {
      return (
        <div className="dashboard_empty-message">
          Add a company to this group from the listing on the left.
        </div>
      );
    }

    return (
      <div className="dashboard_empty-message">
        Click the <strong>New Company</strong> button in the toolbar to add a new company to this group.
      </div>
    );
  }

  const rivalNodes = groupRivals.map(rival => {
    return (
      <DashboardCard
        id={`rival_${rival.id}_group_${group.id}`}
        key={`rival_${rival.id}`}
        className="dashboard">
        <div
          className="dashboard-card_icon dashboard-card_icon--remove"
          onClick={() => onToggleGroupMembership({rivalId: rival.id, groupId: group.id, remove: true})}>
          <Icon icon="close" width="20" height="20" />
        </div>
        <div className="content">
          <div className="logo">
            <CompanyLogo rival={rival} />
          </div>
          <h4>{rival.name}</h4>
        </div>
        <footer className="dashboard-card_footer input--filter">
          <input
            readOnly={true}
            checked={true}
            type="checkbox"
            id={`input_rival_${rival.id}_group_${group.id}`}
            value="" />
          <label htmlFor={`input_rival_${rival.id}_group_${group.id}`} />
        </footer>
      </DashboardCard>
    );
  });

  return (
    <Grid>
      {rivalNodes}
    </Grid>
  );
};

DashboardEditGroupRivals.propTypes = {
  group: PropTypes.object,
  rivals: PropTypes.arrayOf(PropTypes.object),
  onToggleGroupMembership: PropTypes.func.isRequired
};

DashboardEditGroupRivals.defaultProps = {
  group: null,
  rivals: [],
  onToggleGroupMembership() {}
};

export default DashboardEditGroupRivals;
