import {useState, useEffect} from 'react';
import Modal from '../_modal';
import {rivalsList} from '../../modules/api/rivals';
import {lanesGetByRival} from '../../modules/api/lanes';
import {cardCreate} from '../../modules/api/cards';
import Dropdown from '../_dropdown';
import Icon from '../_icon';

const CopyToBoardModal = ({card, onClose, onBoardRefresh}) => {
  const [rivals, setRivals] = useState([]);
  const [lanes, setLanes] = useState([]);
  const [selectedRivalId, setSelectedRivalId] = useState('');
  const [selectedLaneId, setSelectedLaneId] = useState('');
  const [jumpToBoard, setJumpToBoard] = useState(false);

  const handleRivalSelected = async value => {
    setLanes([]);
    setSelectedRivalId(value);

    const laneOptions = {rivalId: value};

    try {
      const boardLanes = await lanesGetByRival({laneOptions});

      const filteredLanes = boardLanes.filter(lane => lane.name !== 'Scratchpad');

      const lanesDropdown = filteredLanes.map(lane => {
        return {
          value: lane.id,
          label: lane.name
        };
      });

      setLanes(lanesDropdown);
    }
    catch(error) {
      console.error('Error getting lanes: ', error);
    }
  };

  useEffect(() => {
    const rivalOptions = {
      order: 'alpha'
    };

    rivalsList({rivalOptions})
      .then(rs => {
        const rivalsDropdown = rs.map(rival => {
          return {
            value: rival.id,
            label: rival.name,
            profileId: rival.profileId
          };
        });

        setRivals(rivalsDropdown);

        const defaultRival = rivalsDropdown.find(rival => rival.profileId === card.board.profileId);

        handleRivalSelected(defaultRival.value);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLaneSelected = value => {
    setSelectedLaneId(value);
  };

  const handleCreateCard = async () => {
    const cardOptions = {
      boardId: selectedLaneId,
      templateName: card.templateName,
      data: card.data
    };

    try {
      const newCard = await cardCreate(cardOptions);

      if(jumpToBoard) {
        window.open(
        `${window.location.origin}/profile/${newCard.data.board.profileId}/edit/card/${newCard.data.id}/edit?viewCard=${newCard.data.id}`, '_blank');
      }

      if(newCard.data.board.profileId === card.board.profileId) {
        onBoardRefresh(selectedLaneId);
      }
    }
    catch(error) {
      console.error('Error creating card: ', error);
    }
    finally{
      onClose();
    }
  };

  const onToggleJumpToBoard = event => {
    setJumpToBoard(event.target.checked);
  };

  return (
    <Modal
      header={false}
      padded={false}
      extraBodyClass="with-overflow-scroll"
      extraContentClass="card-copy-modal_container"
      basic={true}
      hideCloseButton={true}
      closeOnOutsideClick={true}
      onClose={onClose}>
      <>
        <div className="card-copy-modal_header">
          <div><Icon icon="content_copy" className="card-copy-icon" />
            <h2>Duplicate to Other Board</h2></div>
          <Icon icon="close" className="close-icon" onClick={onClose} />
        </div>
        <div className="card-copy-modal_content">
          <div className="card-copy-modal_warning">
            <Icon icon="warning" />
            <div>
              Card tags are not duplicated
            </div></div>
          <p>Select the related competitor, product, or industry board</p>
          <Dropdown
            label=""
            data-test-id="copy-to-board-rival-dropdown"
            displayLabel="Select..."
            options={rivals}
            selectedValue={selectedRivalId}
            keyPrefix="group"
            className="btn secondary"
            alignMenuLeft={true}
            onOptionClick={handleRivalSelected} />
          <p>Select Board Lane</p>
          <Dropdown
            label=""
            data-test-id="copy-to-board-lane-dropdown"
            displayLabel="Select..."
            options={lanes}
            disabled={!selectedRivalId}
            selectedValue={selectedLaneId}
            keyPrefix="group"
            className="btn secondary"
            alignMenuLeft={true}
            onOptionClick={handleLaneSelected} />
          <div className="card-copy-modal_footer">
            <div className="card-copy-modal_footer_checkbox"><input
              type="checkbox"
              id="jump-to-board-checkbox"
              checked={jumpToBoard}
              onChange={onToggleJumpToBoard} />
              <label
                htmlFor="jump-to-board-checkbox">
                Jump to Board after Copy
              </label></div>
            <div><button className="btn primary" onClick={onClose}>Cancel</button>
              <button data-tracking-id="duplicate-card-to-board"
                className="btn primary btn-create"
                disabled={!selectedLaneId}
                onClick={handleCreateCard}>Create Card</button></div>
          </div>
        </div>
      </>
    </Modal>
  );
};

CopyToBoardModal.propTypes = {
  card: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onBoardRefresh: PropTypes.func.isRequired
};

export {CopyToBoardModal as WrappedCopyToBoardModal};
export default CopyToBoardModal;
