import {pluralize} from '../modules/text_utils';

import CardInfoTime from './_card_info_time';

const CardInfo = ({author, createdAt, updatedAt, cardHistoryCount, onToggleCardHistory}, context = {}) => {
  const {utils: {user: {id: userId} = {}} = {}} = context;
  const freshnessAction = (new Date(createdAt)) < (new Date(updatedAt)) ? 'Updated' : 'Created';
  const freshnessAuthor = author ? (
    <i>
      {`${freshnessAction} by `}
      <a href={`/users/${author.id}`}>
        {userId === author.id ? 'You' : author.name}
      </a>
    </i>
  ) : null;
  const freshnessTime = <CardInfoTime createdAt={createdAt} updatedAt={updatedAt} author={author} />;

  const cardHistoryLink = (cardHistoryCount > 0 && onToggleCardHistory) ? (
    <a
      href="#"
      data-tip="View Card History"
      className="card-history-link"
      data-tracking-id="card-history-link"
      data-testid="card-history-link"
      onClick={onToggleCardHistory}>
      {`${cardHistoryCount} Past ${pluralize('Version', cardHistoryCount)}`}
    </a>
  ) : null;

  return (
    <small className="card-info">
      {freshnessAuthor}
      {freshnessTime || cardHistoryLink ? <div className="card-info-time-and-history">
        {freshnessTime}
        {cardHistoryLink}
      </div> : null}
    </small>
  );
};

CardInfo.propTypes = {
  author: PropTypes.object,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  cardHistoryCount: PropTypes.number,
  onToggleCardHistory: PropTypes.func
};

CardInfo.defaultProps = {
  author: null,
  createdAt: '',
  updatedAt: '',
  cardHistoryCount: 0,
  onToggleCardHistory: null
};

CardInfo.contextTypes = {
  utils: PropTypes.object
};

export default CardInfo;
