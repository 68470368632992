import Types from '../../types';
import * as KlueTypes from '../../klue_types';

export function normalizeTimeseries(timeseries) {
  if(!KlueTypes.Timeseries.validate(timeseries)) {
    console.warn('Unexpected parameter type.', timeseries);
    throw new Error('Parameter was unexpected type.');
  }

  if(timeseries.events.length < 1) {
    return timeseries;
  }

  const indexValue = timeseries.events[0].value;
  const normalizedEvents = timeseries.events.map(event => {
    return {...event, value: event.value / indexValue};
  });

  return KlueTypes.Timeseries.create({
    label: timeseries.label,
    events: Types.arrayOf(KlueTypes.TimeseriesEvent).create(normalizedEvents)
  });
}
