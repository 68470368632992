const initialState = {
  onboardBC: {
    active: false
  },
  onboardAction: '',
  onboardSteps: {},
  shouldOnboard: false,
  onboardOptions: {
    className: '',
    run: true
  }
};

/**
 * Onboard model
 *
 * @type Redux model
 * See: https://github.com/rematch/rematch/
 *
 */
export default {
  state: initialState,
  reducers: {
    onboardSetAction(state, payload) {
      const {onboardAction} = payload;

      return {...state, onboardAction};
    },
    onboardSetOptions(state, payload) {
      const {onboardOptions} = payload;

      return {...state, onboardOptions};
    },
    onboardSetSteps(state, payload) {
      const {onboardSteps} = payload;

      return {...state, onboardSteps};
    },
    onboardHalt(state) {
      return {...state, shouldOnboard: false};
    },
    onboardResume(state) {
      return {...state, shouldOnboard: true};
    }
  },
  effects: () => ({
    async onboardResetAll() {
      this.onboardSetOptions({});
      this.onboardSetSteps({});
      this.onboardSetAction({});
    }
  })
};

