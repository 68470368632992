import {getTextFace} from '../modules/text_utils';

class ErrorPage extends React.PureComponent {

  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.string
  };

  static defaultProps = {
    title: '',
    body: ''
  };

  render() {
    const {title, body} = this.props;
    const titleText = title || 'Sorry. We\u0027re experiencing technical difficulties.';
    const bodyText = body || 'Our Engineers have been notified and service should resume shortly.';

    // styles from .error-page assume a (mostly) empty layout / no app chrome
    return (
      <div className="error-page">
        <div className="wrapper">
          <div>
            {getTextFace()}
            <h1 className="error-page_heading">{titleText}</h1>
            <p className="error-page_body">{bodyText}</p>
            <p className="error-page_footer"><small><a href="https://klue.com" title="Back to klue.com">klue.com</a></small></p>
          </div>
        </div>
      </div>
    );
  }

}

export default ErrorPage;
