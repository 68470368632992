import {useState} from 'react';

const ProductRoadMap = ({}, context) => {
  const [showSpinner, setShowSpinner] = useState(true);
  const {appData: {rootUrl}} = context;

  const hideSpinner = () => {
    setShowSpinner(false);
  };

  const spinner = () => (
    <div className="product-board-loading-spinner">
      <i className="fa fa-spin fa-spinner" />
    </div>
  );

  return (
    <div className="product-board">
      {showSpinner && spinner()}
      <iframe onLoad={hideSpinner} src={`${rootUrl}product_portal/tabs/3-launched`} className="product-board-iframe" />
    </div>
  );
};

ProductRoadMap.contextTypes = {
  appData: PropTypes.object.isRequired
};

export default ProductRoadMap;
