import moment from 'moment';

let sessionData = {};
let handlers = {};

const log = message => {
  // log only in development mode
  if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log('[SESSION MANAGER]:', message);
  }
};

/**
 * Session Manager - A separated layer to manage things related to the session expiration
 *
 * How it works:
 * The top-level of application _app_base.js is responsible for initializing the session manager
 * configuration by passing the needed values to the initSessionConfig method from the Session Manager.
 * This service currently uses the Notification API requests /api/notifications/counts.json that returns
 * the expiration date for idle and absolute session: sessionAbsoluteTimeoutAt and sessionIdleTimeoutAt.
 * Once the request is done, it calls the setSessionExpirationDate() method to update the dates and check
 * if the current time is <= 10 minutes of difference between the expiration time.
 * If so, a silent timer (setInterval) will start, checking the current time at every minute and it will
 * display a toast if the current time is less than 5 minutes.
 *
 * @returns {{initSessionConfig: Function, startSessionTimer: Function, setSessionExpirationDate: Function}} - It returns an object of its API
 */
const sessionManager = () => {
  const CHECK_SESSION_STATUS_DELAY = 1000 * 60 * 1; // check at every minute
  const TIME_REMAINING_BEFORE_ALERT = 5 * 60; // 5 minutes (in seconds format) before session gets expired it will display a toast message
  const TIME_REMAINING_TO_START_COUNTER = 10 * 60; // 10 minutes (in seconds format) before session gets expired it will start a silent countdown timer

  const timeoutData = {
    idle: null,
    absolute: null
  };

  const _clearToast = type => Boolean(handlers.clearToastMessages) && handlers.clearToastMessages(`AppBase.${type}`);

  const _clearTimeout = type => {
    if(timeoutData[type] !== null) {
      clearInterval(timeoutData[type]);
    }
  };

  const _resetIdleTimeoutDate = () => {
    handlers.onResetSessionExpirationDate();
    _clearTimeout('idle');
    _clearToast('idle');
  };

  const _forceAbsoluteSignOut = () => {
    _clearTimeout('absolute');
    _clearToast('absolute');
    handlers.onSignOut();
  };

  const _generateMessageByTimeoutType = ({timeoutType, minutes}) => {
    const messages = {
      idle: (
        <div>
          <p>Your session will expire in <strong>{minutes} {`minute${minutes === 1 ? '' : 's'}`}</strong> due to inactivity.</p>
          <button className="button button--small" onClick={_resetIdleTimeoutDate}>Stay connected!</button>
        </div>
      ),
      absolute: (
        <div>
          <p>You will be logged out in <strong>{minutes} {`minute${minutes === 1 ? '' : 's'}`}</strong>, per your company's security policy. Sorry!</p>
          <button className="button button--small" onClick={_forceAbsoluteSignOut}>Sign in again now</button>
        </div>)
    };

    return messages[timeoutType];
  };

  const _getDiffFromNow = sessionExpirationDate => {
    const now = moment();
    const seconds =  moment(sessionExpirationDate).diff(now, 'seconds');
    const minutes = Math.ceil(seconds / 60);

    return {
      seconds,
      minutes
    };
  };

  const _getWhichTimeoutExpiresFirst = () => {
    let expiresFirst;

    Object.keys(sessionData).reduce((lowestTimeRemaining, timeoutItem) => {
      const {seconds: timeRemaining} = _getDiffFromNow(sessionData[timeoutItem]);

      if(timeRemaining < lowestTimeRemaining) {
        expiresFirst = timeoutItem;

        return timeRemaining;
      }

      return lowestTimeRemaining;
    }, Number.MAX_SAFE_INTEGER);

    return expiresFirst;
  };

  const _alertHandler = ({sessionExpirationDate, timeoutType, toastMessage}) => {
    const {seconds, minutes} = _getDiffFromNow(sessionExpirationDate);
    const isExpired = seconds <= 0;

    // clear toast to update remaining time
    _clearToast(timeoutType);

    if(isExpired) {
      // clear itself if session gets expired
      _clearTimeout(timeoutType);

      return;
    }

    log(`${timeoutType} Timeout expires in ${minutes} minutes (${seconds} seconds) at ${sessionExpirationDate.format()}`);

    if(seconds <= TIME_REMAINING_BEFORE_ALERT) {
    // display toast message
      handlers.enqueueToastMessage({
        ...toastMessage,
        isInfo: true,
        duration: 0,    // infinite
        source: `AppBase.${timeoutType}`
      });
    }
  };

  const _getDismiss = timeoutType => {
    const onDismiss = {
      idle: _resetIdleTimeoutDate,
      absolute: _forceAbsoluteSignOut
    };

    return onDismiss[timeoutType]();
  };

  const _startTimeout = timeoutType => {
    const sessionExpiresAt = sessionData[timeoutType];
    const sessionExpirationDate = moment(sessionExpiresAt);
    const {seconds: secondsLeft} = _getDiffFromNow(sessionExpirationDate);

    if(secondsLeft <= TIME_REMAINING_TO_START_COUNTER) {
      const alertData = () => {
        const {minutes} =  _getDiffFromNow(sessionExpirationDate);

        return {
          timeoutType,
          sessionExpirationDate,
          toastMessage: {
            title: 'Your session is about to expire',
            message: _generateMessageByTimeoutType({timeoutType, minutes}),
            onDismissClick: () => _getDismiss(timeoutType)
          }
        };
      };

      // immediately run
      _alertHandler(alertData());

      // prevent creating duplicated instances of setInterval
      _clearTimeout(timeoutType);

      // initialize interval timer to check again and update remaining time
      timeoutData[timeoutType] = setInterval(() => {
        _alertHandler(alertData());
      }, CHECK_SESSION_STATUS_DELAY);

      return;
    }

    // clear everything if expiration date is refreshed
    _clearToast(timeoutType);
    _clearTimeout(timeoutType);
  };

  const initSessionConfig = ({
    onResetSessionExpirationDate,
    clearToastMessages,
    enqueueToastMessage,
    onSignOut
  }) => {
    handlers = {
      onResetSessionExpirationDate,
      clearToastMessages,
      enqueueToastMessage,
      onSignOut
    };
  };

  const startSessionTimer = () => {
    const shouldStartIdleTimer = Boolean(sessionData.idle);
    const shouldStartAbsoluteTimer = Boolean(sessionData.absolute);
    const shouldSkipAll = !shouldStartIdleTimer && !shouldStartAbsoluteTimer;

    if(shouldSkipAll) {
      return null;
    }

    const whichTimeoutExpiresFirst = _getWhichTimeoutExpiresFirst();

    _startTimeout(whichTimeoutExpiresFirst);
  };

  const setSessionExpirationDate = ({sessionAbsoluteTimeoutAt = null, sessionIdleTimeoutAt = null}) => {
    sessionData = {absolute: sessionAbsoluteTimeoutAt, idle: sessionIdleTimeoutAt};

    _clearTimeout('idle');
    _clearTimeout('absolute');

    startSessionTimer();
  };

  return {
    initSessionConfig,
    startSessionTimer,
    setSessionExpirationDate
  };
};

export default sessionManager;
