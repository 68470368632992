const Guides = ({width, height, labels}) => {
  const _xOffset = Math.floor(width / labels.length / 2);

  return (
    <g>
      {labels.map((item, key) => {
        const _x = Math.floor(width / labels.length * key) + _xOffset;
        const dx = item.length === 1 ? '-4' : '-7';

        return (
          <g key={key}>
            <line x1={_x} y1="10%" x2={_x} y2={height} />
            <text x={_x} y="8%" dx={dx} className="label">
              {item}
            </text>
          </g>
        );
      })}
    </g>
  );
};

const Plot = ({width, height, label, index, coords, max}) => {
  let _pts = '';

  let _ptsFill;

  const _xOffset = Math.floor(width / coords.length / 2);
  const yScale = () => {
    const sum = 0;

    const scaler = (10 / (max * 0.1)) * 0.80; // between 10 and 0.85

    return (scaler !== Infinity) ? scaler : 1;
  };

  return (
    <g className="graph">
      {coords.map((item, key) => {
        const _x = Math.floor(width / coords.length * key) + _xOffset;

        const _y = Math.floor(height - height * ((item * yScale()) / 100));

        // solid line pts
        _pts += _x + ',' + _y + ' ';
        // fill pts
        _ptsFill =
          _xOffset +
          ',' +
          height +
          ' ' +
          _pts +
          (width - _xOffset) +
          ',' +
          height;

        return (
          <text key={key} x={_x} y={_y} dx="-7" dy="-8">
            {item}
          </text>
        );
      })}
      <polyline points={_pts} />
      <polyline points={_ptsFill} />
      <rect width="10" height="10" x="8%" y={height + index * 15 + 10} />
      <text x="13%" y={height} dy={18 + index * 15} className="legend">
        {label}
      </text>
    </g>
  );
};

class LineChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      spacing: 0,
      ratio: 0.9
    };
  }
  componentDidMount() {
    this.setState({
      width: this.container.offsetWidth - 2,
      spacing:
        this.props.containerWidth.replace('px', '') -
        (this.container.offsetWidth + 4),
      height: Math.floor(this.container.offsetWidth / this.state.ratio)
    });

    document.addEventListener('refreshDynamicContent', this.handleRefreshDynamicContent);
    window.addEventListener('resize', this.handleRefreshDynamicContent);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      width:
        nextProps.containerWidth.replace('px', '') - this.state.spacing - 2,
      height: Math.floor(
        (nextProps.containerWidth.replace('px', '') - this.state.spacing) /
          this.state.ratio
      )
    });
  }

  componentWillUnmount() {
    document.removeEventListener('refreshDynamicContent');
    window.removeEventListener('resize');
  }

  handleRefreshDynamicContent = event => {
    if(!this.container) {
      return;
    }

    this.setState({
      width: this.container.offsetWidth - 2,
      spacing:
        this.props.containerWidth.replace('px', '') -
        (this.container.offsetWidth + 4),
      height: Math.floor(this.container.offsetWidth / this.state.ratio)
    });
  };

  render() {
    const {data, labels, max} = this.props;
    const style = {
      height: this.state.height
    };

    return (
      <div className={'jk--chart'}>
        <div
          ref={container => {
            this.container = container;
          }}>
          <svg style={style}>
            {data.map((item, key) => (
              <Plot
                key={key}
                index={key}
                width={this.state.width}
                height={this.state.height - 44}
                coords={item.set}
                label={item.label}
                max={max} />
            ))}
            <Guides
              width={this.state.width}
              height={this.state.height - 44}
              labels={labels} />
          </svg>
        </div>
      </div>
    );
  }

}

export default LineChart;
