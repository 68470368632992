
import BattlecardToolbar from './_battlecard_toolbar';
import BattlecardView from './_battlecard_view';

import {userCanCurate} from '../modules/roles_utils';
import {refreshDynamicContent} from '../modules/card_utils';
import {analyticsTrack, SNOWPLOW_SCHEMAS, pageTrackingTypes} from '../modules/analytics_utils';
import {dig} from '../modules/utils';
import {uiViewports, BATTLE_CARD_LAYOUT} from '../modules/constants/ui';
import {redirectToV2} from '../modules/route_utils';

import Fullscreen from 'react-full-screen';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'redux';
import ReactTooltip from 'react-tooltip';

class Battlecard extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired
  };

  static propTypes = {
    match: PropTypes.object,
    company: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    maxBattlecardCards: PropTypes.number,
    embedMode: PropTypes.bool,
    onTrackRecentProfileView: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object
  };

  static defaultProps = {
    match: {},
    company: null,
    users: {},
    maxBattlecardCards: 0,
    embedMode: false,
    onTrackRecentProfileView() {},
    history: {},
    location: {}
  };

  state = {
    fullscreen: false,
    layout: BATTLE_CARD_LAYOUT.single,
    focusedCard: null,
    battlecardCards: []
  };

  componentDidMount() {
    console.log('Battlecard.componentDidMount: props: %o', this.props);

    const {onTrackRecentProfileView, history, match: {params = {}}} = this.props;

    const handleBattlecardView = rival => {
      const {profile = {}} = rival;
      // eslint-disable-next-line prefer-destructuring, react/destructuring-assignment
      let battlecard = this.context?.utils?.battlecard;

      if(!_.isEmpty(profile)) {
        const {id: profileId, curatorsCount, curators} = profile;

        // track recent profile view
        onTrackRecentProfileView(profileId);

        if(curatorsCount) {
          // queue up requests for profile curators
          this.requestProfileCurators(curators);
        }

        if(!battlecard) {
          battlecard = profile.battlecards[0];
        }

        this.analyticsTrack(rival, battlecard);
      }
    };

    if(params.profileId) {
      // eslint-disable-next-line react/destructuring-assignment
      this.context.api.rivalGet({profileId: params.profileId}).then(rival => {
        handleBattlecardView(rival);
      }).catch(() => {
        return history.replace({
          pathname: '/',
          state: {
            redirectCode: 'battlecardNotFound'
          }
        });
      });
    }
  }

  componentDidUpdate() {
    refreshDynamicContent();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {rival: nextRival, battlecard: nextBattlecard} = nextContext.utils;
    const {profile = {}} = nextRival || {};
    const {battlecard} = this.context.utils;
    let battlecardId;
    let battlecardLayout;

    if(!_.isEmpty(profile)) {
      const {id, curators, curatorsCount} = profile;

      if(id) {
        // track recent profile view
        this.props.onTrackRecentProfileView(id);
      }

      if(nextBattlecard && (!battlecard || (battlecard.id !== nextBattlecard.id))) {
        if(curatorsCount) {
          // queue up requests for profile curators
          this.requestProfileCurators(curators);
        }

        battlecardId = nextBattlecard.id;
        battlecardLayout = nextBattlecard.layout;

        this.setState({focusedCard: null});
      }
      else if(nextBattlecard) {
        battlecardId = battlecard.id;
        battlecardLayout = battlecard.layout;
      }

      const userLayoutPrefs = dig(this.context.utils, 'user.userData.battlecardLayouts', {});
      let layout;

      if(userLayoutPrefs[battlecardId]) {
        layout = userLayoutPrefs[battlecardId];
      }
      else {
        layout = battlecardLayout || BATTLE_CARD_LAYOUT.single;
      }

      this.setState({
        layout
      });
    }
  }

  _getProfile = () => {
    const {profile = {}} = this.context.utils.rival || {};

    return profile;
  };

  analyticsTrack = (rival = {}, battlecard = {}) => {
    if(_.isEmpty(rival) || _.isEmpty(battlecard)) {
      return;
    }

    const {id: battlecardId = null} = battlecard;
    const trackingData = {};
    const battlecardTitle = battlecard.title || '(Untitled Battlecard)';
    const view = uiViewports.DESKTOP;
    const cardCount = (battlecard.cards && battlecard.cards[view].filter(card => card !== '').length) || 0;

    if(battlecardId) {
      const {id: rivalId, profile: {isDraft}} = rival;

      trackingData.context = [
        {
          schema: SNOWPLOW_SCHEMAS.battleCard,
          data: {id: battlecardId}
        },
        {
          schema: SNOWPLOW_SCHEMAS.board,
          data: {
            id: rivalId,
            status: isDraft ? 'draft' : 'published'
          }
        }
      ];
    }

    const {href: currentLocation} = location;
    let event;

    pageTrackingTypes.forEach(type => {
      if(type === 'event') {
        event = {
          type,
          category: 'Battlecard',
          action: 'view',
          label: `${rival.name}: ${battlecardTitle}`,
          value: cardCount
        };
      }
      else {
        event = {
          path: `${currentLocation}`,
          type
        };
      }

      analyticsTrack(event, trackingData);
    });
  };

  requestProfileCurators = (curators = []) => {
    if(_.isEmpty(curators)) {
      return;
    }

    curators.forEach(userId => this.context.utils.requestUser({userId}));
  };

  handleBattlecardViewAs = (previewVisibilityGroup = null) => {
    const {appData: {v2Host}} = this.context;

    if(previewVisibilityGroup !== null) {
      const v2Search = `?previewing=${previewVisibilityGroup}`;

      return redirectToV2({v2Host, v2Path: window.location.pathname, v2Search, newTab: true});
    }
  };

  handleFocusCard = (card = null) => this.setState({focusedCard: card}, refreshDynamicContent);

  updateBattlecardCards = cards => this.setState({battlecardCards: cards});

  handleFullscreenClick = () => this.setState(prevState => ({fullscreen: !prevState.fullscreen}));

  handleLayoutSelect = val => {
    this.setState({
      layout: val
    });

    const {user = {}, battlecard = {id: -1}} = this.context.utils;
    const battlecardId = battlecard.id;

    const newUserData = {
      id: user.id,
      featureFlag: [['battlecardLayouts', `${battlecardId}`], val]
    };

    this.context.api.userUpdate(newUserData).then(() => {
      refreshDynamicContent();
    });
  };

  handleSetDefaultLayout = layout => {
    const {utils: {battlecard: {id, profile: {id: profileId}}}, api: {battlecardCreateOrUpdate}} = this.context;

    battlecardCreateOrUpdate({
      id,
      profileId,
      layout
    });
  };

  handleSetDefaultLayoutWithStateLayout = () => {
    const {layout} = this.state;

    this.handleSetDefaultLayout(layout);
  };

  render() {
    const profile = this._getProfile() || {};
    const {embedMode, users, company, maxBattlecardCards} = this.props;
    const {fullscreen, layout, focusedCard, battlecardCards} = this.state;
    const {user} = this.context.utils;
    let battlecardToolbarRegion;

    if(_.isEmpty(profile)) {
      return null;
    }

    if(!embedMode && !fullscreen && (profile.battlecardsCount || userCanCurate({user}))) {
      battlecardToolbarRegion = (
        <div className="battlecard-section_toolbar">
          <BattlecardToolbar
            users={users}
            onFullscreenClick={this.handleFullscreenClick}
            onSelectLayout={this.handleLayoutSelect}
            onSetDefaultLayout={this.handleSetDefaultLayoutWithStateLayout}
            onViewAs={this.handleBattlecardViewAs}
            battlecardCards={battlecardCards} />
        </div>
      );
    }

    const battlecardSectionClasses = classNames('battlecard-section', {
      'battlecard-section--fullscreen': fullscreen
    });

    return (
      <div className={battlecardSectionClasses} data-print-heading={profile.name}>
        {battlecardToolbarRegion}
        <Fullscreen
          enabled={fullscreen}
          onChange={fs => this.setState({fullscreen: fs})}>
          <div className="battlecard-section_cards">
            <BattlecardView
              companyLastUpdated={company && company.updatedAt}
              maxBattlecardCards={maxBattlecardCards}
              showPreviewLinkOnCards={true}
              layout={layout}
              onFocusCard={this.handleFocusCard}
              updateBattlecardCards={this.updateBattlecardCards}
              focusedCard={focusedCard} />
          </div>
        </Fullscreen>
        <ReactTooltip
          class="tooltip"
          html={true}
          effect="solid" />
      </div>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  loadOrFetchRivals: () => dispatch.rivals.loadOrFetchRivals()
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  withRouter
);

export default enhance(Battlecard);
