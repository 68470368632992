import {useEffect, useState, useRef} from 'react';
import classNames from 'classnames';

import Icon from '../_icon';
import Spinner from '../_spinner';
import {getSignedUrl} from '../../modules/s3_utils';

const DigestBanner = ({bannerStyle, updateUploadedBanner, banner, archive}) => {
  const [uploadState, setUploadState] = useState('noUpload');
  const [buttonText, setButtonText] = useState('Upload');
  const [bannerContent, setBannerContent] = useState('no banner displayed');
  const [bannerImageUrl, setBannerImageUrl] = useState(null);
  const bannerImageInput = useRef(null);

  useEffect(() => {
    setBannerImageUrl(banner);
  }, [banner]);

  useEffect(() => {
    if(bannerImageUrl) {
      setButtonText('change');
    }
    else {
      setButtonText('upload');
    }
  }, [bannerImageUrl]);

  useEffect(() => {
    switch(uploadState) {
      case 'noUpload':
        setButtonText('upload');
        setBannerContent('no banner displayed');
        break;
      case 'failedUpload':
        setButtonText('try again');
        setBannerContent('upload failed');
        break;
      case 'uploading':
        setButtonText('loading...');
        break;
      case 'uploaded':
        setButtonText('change');
        break;
      default:
        break;
    }
  }, [uploadState]);

  const removeBanner = () => {
    setBannerImageUrl('');
    setUploadState('noUpload');
    updateUploadedBanner('');
  };

  const onUploadImage = () => {
    const target = ReactDOM.findDOMNode(bannerImageInput.current);

    if(target) {
      target.click();
    }
  };

  const triggerUpload = event => {
    onUploadImage(event);
  };

  const onBannerUploadStart = (file, next) => {
    setUploadState('uploading');

    // Needs this to progress
    next(file);
  };

  const bannerOnUploadProgress = () => {
    if(uploadState !== 'uploading') {
      setUploadState('uploading');
    }
  };

  const onUploadError = () => {
    setUploadState('failedUpload');

    // DEBUG
    console.error('Banner.onUploadError: error uploading');
  };

  const bannerOnUploadFinish = signResult => {
    setUploadState('uploaded');

    const {assetUrl} = signResult;

    setBannerImageUrl(assetUrl);
    updateUploadedBanner(assetUrl);
  };

  return (
    <div data-testid="banner-wrapper" className={classNames(bannerStyle, {archive})}>
      {bannerImageUrl && <div data-testid="banner-image" className="banner-container"><img src={bannerImageUrl} /></div>}
      {
        !bannerImageUrl && <div className={classNames('banner-content', {
          'banner-content-failed': uploadState === 'failedUpload'
        })}>
          {uploadState === 'uploading' && <Spinner className="spinner--green" />}
          {uploadState === 'noUpload' && <strong>{bannerContent}</strong>}
          {uploadState === 'failedUpload'
            && <>
              <Icon icon="warning" fill="#ff5c6f" />
              <strong className="banner-content--upload-fail">{bannerContent}</strong>
            </>
          }
        </div>
      }
      {!archive && <div data-testid="banner-action" className="banner-meta">
        <div>
          <strong>Add a graphic banner to your Intel Digests.</strong>
          <div className="banner-meta--footnote">Recommended - 580 X 100px</div>
        </div>
        <div className="banner-meta-cta">
          {bannerImageUrl && <strong data-testid="remove-banner-action" onClick={removeBanner}>remove</strong>}
          <button
            className={classNames('button button--small button--alt', {
              'button--disabled': uploadState === 'uploading'
            })}
            type="button"
            onClick={triggerUpload}
            disabled={uploadState === 'uploading'}>
            {buttonText}
            <ReactS3Uploader
              ref={bannerImageInput}
              getSignedUrl={(f, cb) => getSignedUrl(f, cb, onUploadError)}
              usePostForm={true}
              accept="image/*"
              preprocess={onBannerUploadStart}
              onProgress={bannerOnUploadProgress}
              onError={onUploadError}
              onFinish={bannerOnUploadFinish}
              uploadRequestHeaders={{'x-amz-acl': 'public-read'}} />
          </button>
        </div>
      </div>}

    </div>
  );
};

DigestBanner.propTypes = {
  bannerStyle: PropTypes.string,
  updateUploadedBanner: PropTypes.func.isRequired,
  banner: PropTypes.string,
  archive: PropTypes.bool
};

DigestBanner.defaultProps = {
  bannerStyle: '',
  banner: '',
  archive: false
};

export default DigestBanner;
