const WaitingForUploads = ({message, cancelTitle, onUserCancel}) => {
  return (
    <div className="waiting-for-uploads-container">
      <div className="box">
        <div className="feedback">
          <div className="message">{message}</div>
          <div className="spinner" />
        </div>
        <div className="controls">
          {onUserCancel ? <button type="button" onClick={onUserCancel}>{cancelTitle}</button> : null}
        </div>
      </div>
    </div>
  );
};

WaitingForUploads.propTypes = {
  message: PropTypes.string,
  cancelTitle: PropTypes.string,
  onUserCancel: PropTypes.func
};

WaitingForUploads.defaultProps = {
  message: 'Waiting for uploads...',
  cancelTitle: 'Cancel',
  onUserCancel: null
};

export default WaitingForUploads;
