import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {tagsGet} from '../modules/api/tags';

export const TagsContext = createContext();

export const useTags = () => {
  const tagsContext = useContext(TagsContext);

  if(tagsContext === undefined) {
    throw new Error('useTags must be used within <TagsProvider />');
  }

  return tagsContext;
};

const useTagsInternal = ({utils: {cardTagIsVisible, userCanCurate}}) => {
  const isCurator = userCanCurate();
  const [isLoading, setIsLoading] = useState(cardTagIsVisible());
  const [tags, setTags] = useState();
  const [error, setError] = useState();
  const userCanViewTags = Boolean(cardTagIsVisible() && tags?.length);
  const userCanEditTags = userCanViewTags && isCurator;

  async function loadTags() {
    setIsLoading(true);

    try {
      const {tags: loadedTags} = await tagsGet();

      setTags(loadedTags);
    }
    catch(e) {
      setError(e);
    }
    finally{
      setIsLoading(false);
    }
  }

  const reloadTags = useCallback(() => {
    if(cardTagIsVisible()) {
      loadTags();
    }
  }, [cardTagIsVisible]);

  useEffect(() => {
    reloadTags();
  }, [reloadTags]);

  const value = useMemo(() => ({
    userCanEditTags,
    userCanViewTags,
    tags,
    isLoading,
    error,
    reloadTags
  }), [userCanEditTags, userCanViewTags, tags, isLoading, error, reloadTags]);

  return value;
};

export const TagsProvider = ({children}, context) => {
  const value = useTagsInternal(context);

  return (
    <TagsContext.Provider value={value}>
      {children}
    </TagsContext.Provider>
  );
};

TagsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

TagsProvider.contextTypes = {
  utils: PropTypes.shape({
    cardTagIsVisible: PropTypes.func.isRequired,
    userCanCurate: PropTypes.func.isRequired
  }).isRequired
};
