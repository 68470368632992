import {useState, useEffect, useRef, useContext} from 'react';
import classNames from 'classnames';

import Icon from '../_icon';
import Spinner from '../_spinner';
import TagItemHeader from './tag_item_header';
import TagError from './_tag_error';
import EditInput from './_edit_input';
import EditSynonyms from './_edit_synonyms';
import TagOnItemsLink from './_tag_on_item_link';
import {tagUpdate, tagDelete} from '../../modules/api/tags';
import {TagErrorsContext} from '.';

export const testIds = {
  manageTagListItem: 'manage-tag-list-item',
  manageTagEditContainer: 'manage-tag-edit-container',
  manageTagViewContainer: 'manage-tag-view-container',
  manageTagItemDoneButton: 'manage-tag-item-done-button',
  manageTagItemEditButton: 'manage-tag-item-edit-button',
  manageTagItemDeleteButton: 'manage-tag-item-delete-button'
};

const ManagedTagItem = ({tag, onUpdateTag, onDeleteTag}, context) => {
  const listItemRef = useRef();
  const {highlights, setHighlights} = useContext(TagErrorsContext);
  const {name, id, taggingsCount, synonyms} = tag;
  const [highlightTitle, setHightlightTitle] = useState(false);
  const [editName, setEditName] = useState(name);
  const [editSynonymName, setEditSynonymName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAddingSynonym, setIsAddingSynonym] = useState(false);
  const [isDeletingSynonym, setIsDeletingSynonym] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState();
  const hasTags = Boolean(taggingsCount);

  const handleToggleEditTag = () => {
    if(!isEditing) {
      setEditName(name);
    }

    setHighlights({});
    setIsEditing(!isEditing);
  };

  const handleUpdateTag = async tagName => {
    try {
      setIsUpdating(true);

      const {tag: updatedTag} = await tagUpdate({tag: {id: tag.id, name: tagName.trim()}});

      setEditName(tagName);
      onUpdateTag(updatedTag);
    }
    catch(err) {
      setError(err?.error);
    }
    finally{
      setIsUpdating(false);
    }
  };

  const handleTagNameDidChange = () => setError(null);

  const handleDone = () => {
    setIsEditing(false);
    setEditName('');
    setEditSynonymName('');
    setError(null);
  };

  const confirmDeleteTag = () => {
    const deleteAction = async () => {
      try {
        setIsDeleting(true);

        await tagDelete({tag});
        onDeleteTag(tag);
      }
      catch(err) {
        setError(err?.error);
        setIsDeleting(false);
      }
    };

    const message = `Are you sure you want to delete the "${name}" tag? It will be permanently removed.`;

    context.utils.dialog.confirm({
      message,
      buttonOk: 'Delete Tag',
      okCallback: deleteAction
    });
  };

  const handleDeleteTag = () => {
    if(isDeleting) {
      return;
    }

    confirmDeleteTag();
  };

  const handleAddSynonym = async synonymName => {
    try {
      setIsAddingSynonym(true);
      setEditSynonymName(synonymName);

      const {tag: updatedTag} = await tagUpdate({tag: {id: tag.id, addSynonym: synonymName}});

      onUpdateTag(updatedTag);
      setEditSynonymName('');
    }
    catch(err) {
      setError(err?.error);
    }
    finally{
      setIsAddingSynonym(false);
    }
  };

  const handleDeleteSynonym = async synonymName => {
    try {
      setIsDeletingSynonym(true);

      const {tag: updatedTag} = await tagUpdate({tag: {id: tag.id, removeSynonym: synonymName}});

      onUpdateTag(updatedTag);
    }
    catch(err) {
      setError(err?.error);
    }
    finally{
      setIsDeletingSynonym(false);
    }
  };

  const handleSynonymNameDidChange = () => setError(null);

  useEffect(() => {
    const conflictingName = highlights && highlights[id]?.conflictingName?.toLowerCase();

    if(!conflictingName) {
      return setHightlightTitle(false);
    }

    let synonymHighlighted = false;
    const isTitleHighlighted = conflictingName.toLowerCase() === name.toLowerCase();

    if(isTitleHighlighted && !highlightTitle) {
      setIsEditing(false);
      setHightlightTitle(true);
    }
    else if(synonyms.map(s => s.toLowerCase()).includes(conflictingName)) {
      synonymHighlighted = true;

      if(!isEditing) {
        setEditName(name);
      }

      setIsEditing(true);
    }

    if(isTitleHighlighted || synonymHighlighted) {
      setError(null);
      listItemRef?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [highlights, name, synonyms, id, highlightTitle, isEditing]);

  return (
    <li ref={listItemRef} data-testid={testIds.manageTagListItem} key={id}>
      <>
        {isEditing
          ? <div data-testid={testIds.manageTagEditContainer} className="manage-tag-container--edit">
            <TagItemHeader title="EDIT TAG" />
            <div className="manage-tag_edit-controls-container">
              <EditInput
                title="Tag Name:"
                value={editName}
                buttonTitle="UPDATE"
                onSubmit={handleUpdateTag}
                onTextDidChange={handleTagNameDidChange}
                processing={isUpdating} />
              <EditSynonyms
                synonyms={synonyms}
                value={editSynonymName}
                onAdd={handleAddSynonym}
                onDelete={handleDeleteSynonym}
                onSynonymDidChange={handleSynonymNameDidChange}
                addingSynonym={isAddingSynonym}
                deletingSynonym={isDeletingSynonym} />
            </div>
            <div className="managed-tag-editing-controls">
              <div className="managed-tag-editing_button-container">
                <button
                  className="button button--medium done-button"
                  onClick={handleDone}
                  data-testid={testIds.manageTagItemDoneButton}>
                  <span>Done</span>{isUpdating && <Spinner className="spinner--small" />}
                </button>
              </div>
            </div>
          </div>
          : <div data-testid={testIds.manageTagViewContainer} className="tag-container--view">
            <TagItemHeader title={name} isConflicted={highlightTitle} />
            <div className="tag-viewing-controls">
              <TagOnItemsLink count={taggingsCount} id={id} />
              <a title="Edit" className="edit-tag-button" data-testid={testIds.manageTagItemEditButton} onClick={handleToggleEditTag}>Edit</a>
              <a
                className={classNames({'link--disabled': hasTags})}
                title={hasTags ? 'Tags Must be Removed to Enable Deletions' : 'Delete'}
                data-testid={testIds.manageTagItemDeleteButton}
                onClick={hasTags ? null : handleDeleteTag}>
                <Icon icon="close" width="16" height="16" />
              </a>
            </div>
          </div>
        }
        {error && <TagError error={error} showLinkToNameConflicts={true} />}
      </>
    </li>
  );
};

ManagedTagItem.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    taggingsCount: PropTypes.number,
    synonyms: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onUpdateTag: PropTypes.func,
  onDeleteTag: PropTypes.func
};

ManagedTagItem.defaultProps = {
  onUpdateTag() {},
  onDeleteTag() {}
};

ManagedTagItem.contextTypes = {
  utils: PropTypes.object.isRequired
};

export default ManagedTagItem;
