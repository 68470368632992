import KlueTagItem from './_klue_tag_item';

const KlueTags = ({tags, onUpdateTag}) => {
  if(!tags?.length) {
    return null;
  }

  return (
    <div className="tags_container">
      <h4 className="u-pt-s"><strong>Klue Tags</strong></h4>
      <p className="help-block u-mb-m">
        These are Klue suggested tags.
        Turn on the ones you want to make available to your Curators.
      </p>
      <ul className="tags_list-container">
        {tags.map(tag => <KlueTagItem key={tag.id} tag={tag} onUpdateTag={onUpdateTag} />)}
      </ul>
    </div>);
};

KlueTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool
  })),
  onUpdateTag: PropTypes.func
};

KlueTags.defaultProps = {
  tags: null,
  onUpdateTag() {}
};

export default KlueTags;
