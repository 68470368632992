import LikeList from './_like_list';

import {truncateLimits} from '../modules/constants/ui';

const LikeBox = ({likes, children: likeButton}) => {
  let list = (<>
    <div className="like-box">
      {likes === null ? (<div className="like-list--loading">&nbsp;</div>) : (<div>&nbsp;</div>)}
      {likeButton}
    </div>
    <p className="like-count like-count--no-likes">Interested in this intel?</p>
  </>);

  if(likes && likes.length) {
    const likesCount = likes.length;
    const diff = truncateLimits.postLikes - likesCount;
    const count = (diff < 0) ? Math.abs(diff) : likesCount;
    const human = (count === 1) ? 'person' : 'people';

    list = (<>
      <div className="like-box">
        <LikeList likes={likes} />
        {likeButton}
      </div>
      <p className="like-count">
        {(diff < 0) ? `...and ${Math.abs(diff)} other ${human}` : `${likesCount} ${human}`} found this relevant
      </p>
    </>);
  }

  return (<div className="like-box-wrap">{list}</div>);
};

LikeBox.propTypes = {
  likes: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node
};

LikeBox.defaultProps = {
  likes: null,
  children: null
};

export default LikeBox;
