import moment from 'moment';

import {BeginningOfTime} from '../modules/constants/dropdown_menu';

const jsToday = moment().startOf('day');
const jsOneDay = 24 * 60 * 60 * 1000;
const dayValue = day => jsToday - day * jsOneDay;

const defaultCardFilterDropdownValues = [
  ['last 7 days', dayValue(7)],
  ['last 30 days', dayValue(30)],
  ['last 3 months', dayValue(90)],
  ['last 6 months', dayValue(183)],
  ['last year', dayValue(365)],
  ['any time', null]
];

export const freshnessDropdownValue = ['freshness', BeginningOfTime, 'filter-by-freshness'];

export const getCardFilterDropdownValues = (options = {freshnessEnabled: true}) => {
  const dropdownValues = defaultCardFilterDropdownValues.slice();
  const {freshnessEnabled} = options;

  if(freshnessEnabled) {
    dropdownValues.unshift(freshnessDropdownValue);
  }

  return dropdownValues;
};

export const getCardFilterParamFromLabel = label => (label || '').replaceAll(' ', '_');

export const getCardFilterTimestamp = (cardFilter, options = {freshnessEnabled: true}) => {
  const filter = (cardFilter || '').replaceAll('_', ' ');
  const {freshnessEnabled} = options;

  let timestamp = null;

  if(cardFilter === 'freshness') {
    if(!freshnessEnabled) {
      return timestamp;
    }

    return BeginningOfTime;
  }

  for(let i = 0; i < defaultCardFilterDropdownValues.length; i++) {
    const [label, value] = defaultCardFilterDropdownValues[i];

    if(label === filter) {
      timestamp = value;
      break;
    }
  }

  return timestamp;
};

