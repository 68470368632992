import InputPassword from './_input_password';

import Spinner from './_spinner';

import {setInputCursorToEnd} from '../modules/html_utils';
import {showIntercomMessage} from '../modules/utils';
import iconSuccess from '../../images/icon-success.svg';
import iconWarning from '../../images/icon-warning.svg';

class LoginBox extends React.Component {

  static propTypes = {
    signInPostUrl: PropTypes.string.isRequired,
    forgotPassPostUrl: PropTypes.string.isRequired,
    csrfToken: PropTypes.string.isRequired,
    onElementResize: PropTypes.func,
    flashMessages: PropTypes.array,
    email: PropTypes.string,
    klueUtk: PropTypes.string,
    inTeams: PropTypes.bool
  };

  static defaultProps = {
    onElementResize: null,
    flashMessages: [],
    email: '',
    klueUtk: null,
    inTeams: false
  };

  state = {
    tryingAutomaticLogin: Boolean(this.props.email) && this.props.flashMessages.length === 0,
    emailIdentities: null,
    emailIdentity: null,
    uiInputKeyValues: {
      email: this.props.email || ''
    },
    uiShowAccPassForm: false,
    uiShowForgotPassword: false,
    flashMessages: this.props.flashMessages // stored in the ruby array format

  };

  componentDidMount() {
    // DEBUG
    console.log('LoginBox.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    if(this.state.tryingAutomaticLogin) {
      this.handleIdentifyEmailClick();
    }
    else if(this.props.email) {
      setInputCursorToEnd(this.emailInputElement);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // trigger a resize update for non input state changes (resize is ment for the extension)
    if(this.props.onElementResize) {
      const shouldTriggerResize = _.isEqual(prevState.uiInputKeyValues, this.state.uiInputKeyValues);

      shouldTriggerResize && this.props.onElementResize();
    }

    if(prevState.emailIdentities && !this.state.emailIdentities) {
      setInputCursorToEnd(this.emailInputElement);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static viewTypes = {
    FORGOT_PASSWORD: 1,
    REQUEST_ACCESS_SUCCESS: 2,
    NEW_USER_NEW_COMPANY: 3,
    EXISTING_USER_EXISTING_COMPANY_HAS_SSO: 4,
    EXISTING_USER_EXISTING_COMPANY_NO_SSO: 5,
    EXISTING_USER_EXISTING_COMPANY_NO_LOGIN_METHODS: 6,
    CREATE_ACCOUNT_SUCCESS: 7,
    NEW_USER_EXISTING_COMPANY_HAS_SSO: 8,
    NEW_USER_EXISTING_COMPANY_NO_SSO: 9,
    NEW_USER_EXISTING_COMPANY_NO_LOGIN_METHODS: 10,
    INITIAL_ENTER_EMAIL: 11,
    INITIAL_SELECT_COMPANY: 12,
    VALIDATE_EMAIL_CONFIRM: 13,
    VALIDATE_EMAIL_SENT: 14,
    EXISTING_USER_EXISTING_COMPANY_DEACTIVATED: 15,
    TRYING_AUTOMATIC_LOGIN: 16
  };

  setInputValue = (inputKey, value) => {
    const values = _.clone(this.state.uiInputKeyValues);

    values[inputKey] = (value || '').replace(/^\s+|\s+$/g, '');

    this.setState({
      uiInputKeyValues: values
    });
  };

  getInputValue = inputKey => {
    return this.state.uiInputKeyValues[inputKey] || '';
  };

  handleInputChange = (inputKey, event) => {
    this.setInputValue(inputKey, event.target.value);
  };

  handleEnterKey = (callback, event) => {
    if(event.which === 13) {
      event.preventDefault();

      return typeof callback === 'function' && callback();
    }
  };

  handleIdentifyEmailClick = () => {
    const email = this.getInputValue('email');

    if(!email) {
      return;
    }

    // regexp used in email_parser.rb
    if(!/[\w.!#$%&*+/=?^`{|}~-]+@[\w.-]+/.test(email)) {
      this.setState({
        flashMessages: [
          ['alert', 'Double check your email, it looks invalid']
        ],
        tryingAutomaticLogin: false
      });

      return;
    }

    const handleIdentityResponse = data => {
      this.setState({
        flashMessages: [],
        emailIdentities: data,
        emailIdentity: (data && data.length === 1 ? data[0] : null), // user must select if more than one possibility
        tryingAutomaticLogin: false
      });
    };

    this.apiGetEmailIdentities(email, false, null, handleIdentityResponse);
  };

  handleSelectCompanyClick = emailIdentity => {
    this.setState({
      emailIdentity
    });
  };

  handleCreateAccountClick = companyId => {
    const firstName = this.getInputValue('firstName');
    const lastName = this.getInputValue('lastName');

    if(!firstName || !lastName) {
      const message = 'Both first and last names are required';

      this.setState({
        flashMessages: [['alert', message]]
      });

      return;
    }

    const newUser = {
      firstName,
      lastName,
      companyId,
      email: this.getInputValue('email')
    };

    this.apiCreateUser(newUser);

    // timeout to simulate loading to user
    // might feel like nothing has happened otherwise ..?
    setTimeout(() => this.setState({
      formSubmitted: true
    }), 200);
  };

  handleRequestAccessClick = () => {
    const email = this.getInputValue('email');

    this.apiRequestAccess(email);

    if(window.clearbitSlack) {
      window.clearbitSlack.notify({email});
    }

    // timeout to simulate loading to user
    // might feel like nothing has happened otherwise ..?
    setTimeout(() => this.setState({
      formSubmitted: true
    }), 200);
  };

  handleValidateEmailClick = () => {
    const email = this.getInputValue('email');

    this.apiGetEmailIdentities(email, true, null, null);

    // timeout to simulate loading to user
    // might feel like nothing has happened otherwise ..?
    setTimeout(() => this.setState({
      formSubmitted: true
    }), 200);
  };

  handleResetViewClick = event => {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({
      uiShowAccPassForm: false,
      uiShowForgotPassword: false,
      uiInputKeyValues: {
        email: this.getInputValue('email')
      },
      emailUnconfirmed: false,
      emailIdentities: null,
      emailIdentity: null
    }, () => {
      this.props.onElementResize && this.props.onElementResize();
    });
  };

  handleShowForgotPasswordClick = event => {
    if(event) {
      event.preventDefault();
    }

    this.setState({
      uiShowForgotPassword: true
    });
  };

  handleAccPassFormToggleClick = event => {
    if(event) {
      event.preventDefault();
    }

    this.setState({
      uiShowAccPassForm: !this.state.uiShowAccPassForm
    });
  };

  handleCantAuthorizeSupportRequest = () => showIntercomMessage('Hey Klue! I\'m unable to login with my credentials.');

  handleValidationInput = event => {
    if(event) {
      event.preventDefault();
    }

    if(event.target.value.length > 0) {
      document.querySelector('.button--next').classList.remove('button--disabled');
    }
    else {
      document.querySelector('.button--next').classList.add('button--disabled');
    }
  };

  handleValidationCode = event => {
    if(event) {
      event.preventDefault();
    }

    const utk = document.getElementById('validationCode').value;

    const handleIdentityResponse = data => {
      this.setState({
        flashMessages: [],
        emailIdentities: data,
        emailIdentity: (data && data.length === 1 ? data[0] : null), // user must select if more than one possibility
        tryingAutomaticLogin: false
      });
    };

    const email = this.getInputValue('email');

    this.apiGetEmailIdentities(email, false, utk, handleIdentityResponse);
  };

  apiGetEmailIdentities = (email, validate, utk, callback) => {
    const {klueUtk, inTeams} = this.props;
    const utkValue = utk || klueUtk;
    const utkToSend = utkValue ? `&klueUtk=${encodeURIComponent(utkValue)}` : '';
    const embedded = inTeams ? '&embedded=1' : '';
    const url = `/api/identify.json?v=3${validate ? '&validate=1' : ''}${utkToSend}${embedded}`;

    jQuery.ajax({
      type: 'GET',
      url,
      headers: {
        'X-Klue-Email': email
      },
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      success: data => {
        if(this._isMounted) {
          return typeof callback === 'function' && callback(data);
        }
      },
      error: (xhr, type) => {
        if(this._isMounted) {
          // TODO: remove this error logic once api is updated to send back valid error JSON #1427
          if(!xhr.status || xhr.status === 404) {
            return typeof callback === 'function' && callback([]);
          }

          if(xhr.status === 440) {
            this.setState({
              tryingAutomaticLogin: false,
              emailUnconfirmed: true
            });

            return;
          }

          this.setState({
            tryingAutomaticLogin: false,
            flashMessages: [['info', 'We couldn\'t check your account at this time. Please try again, by clicking "next"']]
          });
        }
      }
    });
  };

  apiCreateUser = (params, callback) => {
    jQuery.ajax({
      type: 'POST',
      url: '/api/identify.json',
      data: JSON.stringify(params),
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      success: data => {
        if(this._isMounted) {
          return typeof callback === 'function' && callback(data);
        }
      },
      error: (xhr, type) => {
        if(this._isMounted) {
          //callback && callback(false);
        }
      }
    });
  };

  apiRequestAccess = (email, callback) => {
    jQuery.ajax({
      url: '/api/email/inbound.json',
      method: 'POST',
      data: {
        to: 'support',
        subject: `Request Access from page ${window.location}`,
        formName: 'Login',
        replyTo: email,
        body: `early access request from ${email}`
      },
      dataType: 'json',
      success: data => {
        if(this._isMounted) {
          return typeof callback === 'function' && callback(data);
        }
      },
      error: (xhr, type) => {
        if(this._isMounted) {
          return typeof callback === 'function' && callback(false);
        }
      }
    });
  };

  renderFlashMessages = () => {
    const {flashMessages} = this.state;

    if(!flashMessages || !flashMessages.length) {
      return null;
    }

    const classNamesMap = {
      notice: 'alert-success',
      alert: 'alert-warning',
      info: 'alert-info'
    };
    const alertIconMap = {
      notice: iconSuccess,
      alert: iconWarning,
      info: null
    };

    return flashMessages.slice().map((message, index) => {
      const alertClassName = classNamesMap[message[0]] || classNamesMap.info;
      const alertIconURL = alertIconMap[message[0]] || alertIconMap.info;
      const alertIcon = alertIconURL ? (<img className="alert-icon" src={alertIconURL} />) : null;

      return (
        <div
          key={index}
          className={'alert ' + alertClassName}>
          {alertIcon}
          {message[1]}
        </div>
      );
    });
  };

  renderSSOLoginButtons = () => {
    const {csrfToken} = this.props;
    const {emailIdentity} = this.state;
    const {idProviders = []} = emailIdentity || {};

    if(!idProviders.length) {
      return null;
    }

    const providers = idProviders.slice().map(provider => {
      const {authorizeUrl, iconUrl, vendorName} = provider;
      const uiImage = iconUrl ? (<img src={iconUrl} />) : null;
      const hasMoreThanOneTypeOfLoginPerProvider = idProviders.filter(p => p.vendorName === provider.vendorName).length > 1;
      const uiAuthType = hasMoreThanOneTypeOfLoginPerProvider ? provider.authType.replace(/[^a-zA-Z]/g, '') : null;

      return (
        <form action={authorizeUrl} method="post" key={authorizeUrl}>
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <button type="submit"
            className="sso-button"
            data-vendor={vendorName}
            data-auth-type={uiAuthType}>
            {uiImage}
            <span><b>{vendorName}</b> <i>{uiAuthType}</i></span>
          </button>
        </form>
      );
    });

    return (
      <div className="layout-sso-buttons">
        {providers}
      </div>
    );
  };

  renderCommonSSOFields = () => {
    return (
      <div className="u-pt-m">
        <h3>Your company sign in options</h3>
        {this.renderSSOLoginButtons()}
      </div>
    );
  };

  renderCommonTagline = () => {
    return (
      <div className="layout-login-contain layout-login-contain--tagline">
        <h1 className="heading-login heading-login--main text-center">
          We obsess about your competition<br />
          so you don&apos;t have to.
        </h1>
      </div>
    );
  };

  renderCommonCreateAccountFields = companyId => {
    return (
      <div>
        <input
          type="text"
          autoFocus={true}
          placeholder="First Name"
          data-test-id="signin-user-firstname"
          value={this.getInputValue.call(this, 'firstName')}
          onChange={e => this.handleInputChange('firstName', e)}
          className="external-input external-input--bottom" />
        <input
          type="text"
          placeholder="Last Name"
          data-test-id="signin-user-lastname"
          value={this.getInputValue.call(this, 'lastName')}
          onChange={e => this.handleInputChange('lastName', e)}
          onKeyPress={e => this.handleEnterKey(() => this.handleCreateAccountClick(companyId), e)}
          className="external-input external-input--bottom" />
        <button className="btn btn-default btn-lg btn-success btn-block btn--external button--next" onClick={() => this.handleCreateAccountClick(companyId)}>
          Create Account
        </button>
      </div>
    );
  };

  renderCommonReadonlyEmailField = () => {
    return (
      <footer className="text-center" onClick={this.handleResetViewClick}>
        <div>
          {this.getInputValue('email')} &mdash; <a href="#" data-test-id="signin-change-email-link" onClick={this.handleResetViewClick}>Change</a>
        </div>
      </footer>
    );
  };

  renderCommonEnterPasswordForm = companyId => (
    <form action={this.props.signInPostUrl} method="post">
      <input type="hidden" name="user[email]" data-test-id="signin-user-email" value={this.getInputValue('email')} />
      <input type="hidden" name="user[company_id]" value={companyId} />
      <input type="hidden" name="authenticity_token" value={this.props.csrfToken} />
      <InputPassword
        autoFocus={true}
        placeholder="Enter your password"
        data-test-id="user-password"
        id="user[password]"
        className="external-input external-input--bottom" />
      <input type="submit" value="Sign In" data-test-id="signin-submit" className="button--next btn btn-default btn-lg btn-success btn-block btn--external" />
      <div className="u-pt-s login-secondary-action">
        <a href="#" className="login-secondary-action-link" data-test-id="signin-forgot-password-link" onClick={this.handleShowForgotPasswordClick}>Forgot Password</a>
      </div>
    </form>
  );

  renderLoginView = () => {
    const {emailIdentities, emailIdentity, uiShowAccPassForm, uiShowForgotPassword, tryingAutomaticLogin,
      formSubmitted, emailUnconfirmed} = this.state;
    const {forgotPassPostUrl, csrfToken, inTeams} = this.props;
    const unknownCompanyEmail = emailIdentities && emailIdentities.length === 0;
    const isExistingUser = emailIdentity && emailIdentity.knownUser;
    const isDeactivatedUser = emailIdentity && emailIdentity.knownUser && emailIdentity.deactivatedUser;
    const isNewUser = emailIdentity && !emailIdentity.knownUser;
    const hasSSOEnabled = emailIdentity && emailIdentity.idProviders && emailIdentity.idProviders.length > 0;
    const hasPassEnabled = emailIdentity && emailIdentity.passwordAuthEnabled;
    const showAccPassForm = uiShowAccPassForm;
    const mustSelectCompany = emailIdentities && emailIdentities.length > 0 && emailIdentity === null;
    const uiCompanyId = emailIdentity && emailIdentity.companyId || '';
    let showView;
    let uiView;
    let uiAccPassForm;
    let uiCompanyName = emailIdentity && emailIdentity.companyName || '';

    // Calculate which view to show
    if(tryingAutomaticLogin) {
      showView = LoginBox.viewTypes.TRYING_AUTOMATIC_LOGIN;
    }
    else if(uiShowForgotPassword) {
      showView = LoginBox.viewTypes.FORGOT_PASSWORD;
    }
    else if(unknownCompanyEmail) {
      if(formSubmitted) {
        showView = LoginBox.viewTypes.REQUEST_ACCESS_SUCCESS;
      }
      else {
        showView = LoginBox.viewTypes.NEW_USER_NEW_COMPANY;
      }
    }
    else if(isExistingUser) {
      if(isDeactivatedUser) {
        showView = LoginBox.viewTypes.EXISTING_USER_EXISTING_COMPANY_DEACTIVATED;
      }
      else if(hasSSOEnabled) {
        showView = LoginBox.viewTypes.EXISTING_USER_EXISTING_COMPANY_HAS_SSO;
      }
      else if(hasPassEnabled) {
        showView = LoginBox.viewTypes.EXISTING_USER_EXISTING_COMPANY_NO_SSO;
      }
      else {
        showView = LoginBox.viewTypes.EXISTING_USER_EXISTING_COMPANY_NO_LOGIN_METHODS;
      }
    }
    else if(isNewUser) {
      if(!formSubmitted) {
        if(hasSSOEnabled) {
          showView = LoginBox.viewTypes.NEW_USER_EXISTING_COMPANY_HAS_SSO;
        }
        else if(hasPassEnabled) {
          showView = LoginBox.viewTypes.NEW_USER_EXISTING_COMPANY_NO_SSO;
        }
        else {
          showView = LoginBox.viewTypes.NEW_USER_EXISTING_COMPANY_NO_LOGIN_METHODS;
        }
      }
      else {
        showView = LoginBox.viewTypes.CREATE_ACCOUNT_SUCCESS;
      }
    }
    else if(mustSelectCompany) {
      showView = LoginBox.viewTypes.INITIAL_SELECT_COMPANY;
    }
    else if(emailUnconfirmed) {
      if(formSubmitted) {
        showView = LoginBox.viewTypes.VALIDATE_EMAIL_SENT;
      }
      else {
        showView = LoginBox.viewTypes.VALIDATE_EMAIL_CONFIRM;
      }
    }
    else {
      showView = LoginBox.viewTypes.INITIAL_ENTER_EMAIL;
    }

    // ( ͡° ͜ʖ ͡°)
    if(uiCompanyName === 'Klue' && isNewUser) {
      uiCompanyName = (<span className="fa fa-spin">
        <i className="fa fa-hashtag fa-pulse" />
        <span className="fa fa-pulse">Klue</span>
        <i className="fa fa-hashtag fa-pulse" />
      </span>);
    }

    // create correct view
    if(showView === LoginBox.viewTypes.TRYING_AUTOMATIC_LOGIN) {
      uiView = (
        <div>
          <div className="layout-login-contain layout-login-contain--form">
            <div className="u-pt-m text-center">
              <Spinner />
              <h3 className="reconnect-message">
                Reconnecting you to Klue...
              </h3>
            </div>
          </div>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.INITIAL_ENTER_EMAIL) {
      uiView = (
        <div>
          <div className="layout-login-contain layout-login-contain--form">
            <div>
              {this.renderCommonTagline()}
              <div>
                <label>
                  <input
                    type="email"
                    ref={ref => this.emailInputElement = ref}
                    autoFocus={true}
                    value={this.getInputValue('email')}
                    data-test-id="signin-user-email"
                    placeholder="Your Work Email Address"
                    onKeyPress={e => this.handleEnterKey(this.handleIdentifyEmailClick, e)}
                    onChange={e => this.handleInputChange('email', e)}
                    className="external-input external-input--bottom external-input-email" />
                </label>
                <button
                  className="button button--full button--next"
                  data-test-id="signin-next-button"
                  onClick={() => this.handleIdentifyEmailClick('')}>Next</button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.INITIAL_SELECT_COMPANY) {
      const knownIdentities = emailIdentities.filter(i => i.knownUser === true).map((identity, index) => {
        return (<button key={index}
          className="button button--full button--purple-outline"
          data-test-id="signin-select-team-button"
          onClick={() => this.handleSelectCompanyClick(identity)}>
          Team {identity.companyName}
        </button>);
      });
      const joinIdentities = emailIdentities.filter(i => i.knownUser === false).map((identity, index) => {
        return (<button
          key={index}
          className="button button--full button--grey-outline"
          data-test-id="signin-select-team-button"
          onClick={() => this.handleSelectCompanyClick(identity)}>
          Team {identity.companyName}
        </button>);
      });

      uiView = (
        <div>
          <div className="layout-login-contain layout-login-contain--form">
            <div>
              {knownIdentities.length > 0 && <div><h3 className="purple">Hello!</h3><h4>Your Klue Teams</h4></div>}
              {knownIdentities}
              {joinIdentities.length > 0 && <div><hr /><h4>Other teams <small>(you <em>may</em> be able to join)</small></h4></div>}
              {joinIdentities}
            </div>
            {this.renderCommonReadonlyEmailField()}
          </div>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.NEW_USER_NEW_COMPANY) {
      uiView = (
        <div>
          <div className="layout-login-contain layout-login-contain--form">
            <div>
              <div className="heading-login heading-login--s text-center u-pt-m u-mb-m">Hi! It doesn’t look like you<br />or your company are on Klue yet.</div>
              <div className="heading-login heading-login--s text-center u-mb-m">Register your interest and<br />we&apos;ll be in touch soon!</div>
              <button className="btn btn-default btn-lg btn-success btn-block btn--external button--next u-mb-s"
                onClick={this.handleRequestAccessClick}>
                Request Access
              </button>
            </div>
            {this.renderCommonReadonlyEmailField()}
          </div>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.NEW_USER_EXISTING_COMPANY_NO_SSO) {
      uiView = (
        <div>
          <div className="layout-login-contain layout-login-contain--form">
            <div>
              <h3>{uiCompanyName} welcomes you to Klue!</h3>
              <h4>Create your account to gain access.</h4>
              {this.renderCommonCreateAccountFields(uiCompanyId)}
            </div>
            {this.renderCommonReadonlyEmailField()}
          </div>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.EXISTING_USER_EXISTING_COMPANY_DEACTIVATED) {
      uiView = (
        <div className="text-center">
          <h3>Your account is deactivated.</h3>
          <h4>Please contact your Klue administrator to reactivate it.</h4>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.EXISTING_USER_EXISTING_COMPANY_NO_SSO) {
      uiView = (
        <div>
          <div className="layout-login-contain layout-login-contain--form">
            <div>
              {this.renderCommonEnterPasswordForm(uiCompanyId)}
            </div>
            {this.renderCommonReadonlyEmailField()}
          </div>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.NEW_USER_EXISTING_COMPANY_HAS_SSO) {
      if(!hasPassEnabled) {
        uiAccPassForm = null;
      }
      else if(showAccPassForm) {
        uiAccPassForm = (
          <div className="u-pt-m">
            <div className="heading-login heading-login--s text-center u-mb-s">Create a Klue Account and password</div>
            {this.renderCommonCreateAccountFields(uiCompanyId)}
          </div>
        );
      }
      else {
        uiAccPassForm = (
          <div className="u-pt-m login-secondary-action">
            <div className="text-center">
              or, <a href="#" className="login-secondary-action-link" onClick={this.handleAccPassFormToggleClick}>Create a Klue Password</a>
            </div>
            <div className="text-center">(less secure and not recommended)</div>
          </div>
        );
      }

      uiView = (
        <div>
          <div className="layout-login-contain layout-login-contain--form">
            <div>
              <h3>Welcome! {uiCompanyName} is already on Klue!</h3>
              {this.renderCommonSSOFields()}
            </div>
            {uiAccPassForm}
            {this.renderCommonReadonlyEmailField()}
          </div>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.EXISTING_USER_EXISTING_COMPANY_HAS_SSO) {
      if(!hasPassEnabled) {
        uiAccPassForm = null;
      }
      else if(showAccPassForm) {
        uiAccPassForm = (
          <div className="layout-pass-form">
            <hr />
            <h4>Sign in with your Klue Password</h4>
            {this.renderCommonEnterPasswordForm(uiCompanyId)}
          </div>
        );
      }
      else {
        uiAccPassForm = (
          <div className="login-secondary-action">
            or, <a href="#" className="login-secondary-action-link" data-test-id="signin-use-password-link" onClick={this.handleAccPassFormToggleClick}>
              Sign in with a Klue Password</a>
          </div>
        );
      }

      uiView = (
        <div>
          <div className="layout-login-contain layout-login-contain--form">
            <div>
              {this.renderCommonSSOFields()}
              {uiAccPassForm}
            </div>
            {this.renderCommonReadonlyEmailField()}
          </div>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.EXISTING_USER_EXISTING_COMPANY_NO_LOGIN_METHODS ||
      showView === LoginBox.viewTypes.NEW_USER_EXISTING_COMPANY_NO_LOGIN_METHODS) {
      uiView = (
        <div className="text-center">
          <h3>Oh no 😬 – Klue is unable to authorize your access.</h3>
          <h4>Please contact your Klue administrator or {
            window.Intercom
              ? <a href="#" onClick={this.handleCantAuthorizeSupportRequest}>Klue Support</a>
              : 'Klue Support'} for help.</h4>

          <div className="layout-login-reset--email">
            {this.renderCommonReadonlyEmailField()}
          </div>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.VALIDATE_EMAIL_CONFIRM) {
      if(inTeams) {
        uiView = (
          <div>
            <div className="layout-login-contain layout-login-contain--form">
              <div>
                <div className="layout-login-contain layout-login-contain--tagline">
                  <h1
                    style={{marginBottom: 0}}
                    className="heading-login heading-login--main text-center">
                    Confirm your email address.
                  </h1>
                  <p style={{textAlign: 'center', marginBottom: '24px', marginTop: '6px'}}>We will send you an email with instructions.</p>
                </div>
                <button
                  className="button button--full button--next"
                  onClick={this.handleValidateEmailClick}>
                  Send Instructions
                </button>
              </div>
              {this.renderCommonReadonlyEmailField()}
            </div>
          </div>
        );
      }
      else {
        uiView = (
          <div>
            <div className="layout-login-contain layout-login-contain--form">
              <div>
                <h3>Confirm your email address</h3>
                <h5>We will send you an email to confirm its validity.</h5>
                <br />
                <button className="btn btn-default btn-lg btn-success btn-block btn--external u-mb-s" onClick={this.handleValidateEmailClick}>
                  Send Confirmation
                </button>
              </div>
              {this.renderCommonReadonlyEmailField()}
            </div>
          </div>
        );
      }
    }
    else if(showView === LoginBox.viewTypes.VALIDATE_EMAIL_SENT) {
      if(inTeams) {
        uiView = (
          <div>
            <div className="layout-login-contain layout-login-contain--form">
              <div>
                <div className="layout-login-contain layout-login-contain--tagline">
                  <h1
                    style={{marginBottom: 0}}
                    className="heading-login heading-login--main text-center">
                    We&apos;ve sent a code to your email.
                  </h1>
                  <p style={{textAlign: 'center', marginBottom: '24px', marginTop: '6px'}}>Enter the code below to continue.</p>
                <input
                  type="text"
                  id="validationCode"
                  style={{marginBottom: '24px'}}
                  onInput={this.handleValidationInput}
                  className="external-input external-input--bottom" />
                <button
                  className="button button--full button--next button--disabled"
                  onClick={this.handleValidationCode}>Continue</button>
                </div>
              </div>
            </div>
          </div>
        );
      }
      else {
        uiView = (
          <div className="text-center">
            <h3>Thanks!</h3>
            <h4>Email has been sent.<br />Click the link in the email to continue logging in.</h4>
          </div>
        );
      }
    }
    else if(showView === LoginBox.viewTypes.CREATE_ACCOUNT_SUCCESS) {
      uiView = (
        <div className="text-center">
          <h3>Great!</h3>
          <h4>You have now  been emailed<br />your login instructions.</h4>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.REQUEST_ACCESS_SUCCESS) {
      uiView = (
        <div className="text-center">
          <h3>Thanks for your request!</h3>
          <h4>We&apos;ll be in touch soon.</h4>
        </div>
      );
    }
    else if(showView === LoginBox.viewTypes.FORGOT_PASSWORD) {
      uiView = (
        <div>
          <div className="layout-login-contain layout-login-contain--form">
            <div>
              <h3>Forgot Your Password?</h3>
              <h4 className="light">We&apos;ll send you an email with instructions.</h4>
              <form action={forgotPassPostUrl} method="post">
                <input type="hidden" name="authenticity_token" value={csrfToken} />
                <input type="hidden" name="user[email]" value={this.getInputValue('email')} />
                <input type="hidden" name="user[company_id]" value={uiCompanyId} />
                <input type="submit"
                  value="Email me reset instructions"
                  data-test-id="signin-instructions-submit"
                  className="button--next btn btn-default btn-lg btn-success btn-block btn--external" />
              </form>
            </div>
            {this.renderCommonReadonlyEmailField()}
          </div>
        </div>
      );
    }

    return uiView;
  };

  render() {
    if(this.state.flashMessages && this.state.flashMessages.length) {
      return (
        <div>
          <div className="layout-login-contain">
            {this.renderFlashMessages()}
          </div>
          {this.renderLoginView()}
        </div>
      );
    }

    return this.renderLoginView();
  }

}

export default LoginBox;
