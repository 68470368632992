import Icon from './_icon';
import ReactPasswordStrength from 'react-password-strength';

import classNames from 'classnames';

class InputPassword extends React.PureComponent {

  static propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    errors: PropTypes.array,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    showMeter: PropTypes.bool
  };

  static defaultProps = {
    autoFocus: true,
    className: '',
    errors: [],
    id: 'password',
    placeholder: 'Enter your password',
    showMeter: false
  };

  state = {
    password: '',
    passwordIsVisible: false
  };

  handlePasswordVisibilityToggle = () => {
    this.setState(prevState => ({passwordIsVisible: !prevState.passwordIsVisible}), () => this.input && this.input.focus());
  };

  handlePasswordChange = password => {
    this.setState({password});
  };

  render() {
    const {autoFocus, className, id, placeholder, errors, showMeter} = this.props;
    const {passwordIsVisible} = this.state;
    const toggleClass = classNames('input-password-wrapper-toggle', {
      'input-password-wrapper-toggle--on': passwordIsVisible
    });
    const passwordInputRegion = (showMeter ?
      (<ReactPasswordStrength
        minLength={12}
        scoreWords={['weak', 'so-so', 'better', 'good', 'great!']}
        changeCallback={state => this.handlePasswordChange(state.password)}
        namespaceClassName="input-password"
        inputProps={{
          type: passwordIsVisible ? 'text' : 'password',
          autoComplete: 'off',
          autoFocus,
          placeholder,
          name: id,
          id,
          className
        }} />) :
      (<input
        ref={el => this.input = el}
        type={passwordIsVisible ? 'text' : 'password'}
        autoComplete="off"
        autoFocus={autoFocus}
        placeholder={placeholder}
        name={id}
        id={id}
        onChange={event => this.handlePasswordChange((event && event.target.value) || '')}
        className={className} />));

    const passwordErrorRegion = errors[0] ? <span className="help-inline">{errors[0]}</span> : '';

    return (
      <div>
        <div className="input-password-wrapper">
          {passwordInputRegion}
          <Icon
            className={toggleClass}
            icon={`visibility-${passwordIsVisible ? 'on' : 'off'}`}
            onClick={this.handlePasswordVisibilityToggle} />
        </div>
        {passwordErrorRegion}
      </div>
    );
  }

}

export default InputPassword;
