import {useRef, useState, useEffect, useCallback} from 'react';

const ListItemCreate = ({
  manageMode,
  onCancel,
  listItemCreate,
  onListItemAdded,
  listItems,
  title,
  createButton,
  placeholder,
  infoContent
}) => {
  const [listItemName, setListItemName] = useState('');
  const [errorText, setErrorText] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const createNewListItem = useCallback(event => {
    event?.preventDefault();

    const newName = listItemName?.trim();

    if(!newName) {
      return;
    }

    if((listItems || []).some(({name}) => {
      return name?.toLowerCase() === newName.toLowerCase();
    })) {
      inputRef?.current?.select();

      return setErrorText('Please enter a unique name.');
    }

    const createItemOptions = {
      name: newName
    };

    listItemCreate(createItemOptions, result => {
      if(!result) {
        return setErrorText('An error occurred while updating the database. Please try again.');
      }

      onListItemAdded(result);
      setListItemName('');
    });
  }, [listItemCreate, listItems, onListItemAdded, listItemName]);

  useEffect(() => {
    const keyCheck = event => {
      if(event?.key === 'Escape') {
        return onCancel();
      }

      if(event?.key === 'Enter' && document.activeElement === inputRef.current) {
        return createNewListItem();
      }
    };

    window.addEventListener('keydown', keyCheck);

    return () => window.removeEventListener('keydown', keyCheck);
  }, [createNewListItem, onCancel]);

  const handleNameChange = event => {
    setErrorText('');
    setListItemName(event?.target?.value ?? '');
  };

  return (
    <div className="create-list-item-modal">
      <h4 className="heading-dialog">{title}</h4>
      <div className="input-block">
        <input
          type="text"
          ref={inputRef}
          placeholder={placeholder}
          className="klue-input create-visibility-group-input"
          value={listItemName}
          onChange={handleNameChange} />
        {manageMode
          ? (<button className="button save" onClick={createNewListItem}>Save</button>)
          : null}
      </div>
      {errorText ? (<div className="error">{errorText}</div>) : null}
      {infoContent}
      {!manageMode
        ? (<div className="footer">
          <button className="button button--disabled" onClick={onCancel}>Cancel</button>
          <button className="button save" onClick={createNewListItem}>{createButton}</button>
        </div>)
        : null}
    </div>
  );
};

ListItemCreate.propTypes = {
  listItemCreate: PropTypes.func,
  onCancel: PropTypes.func,
  onListItemAdded: PropTypes.func,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  title: PropTypes.string,
  createButton: PropTypes.string,
  placeholder: PropTypes.string,
  manageMode: PropTypes.bool,
  infoContent: PropTypes.node
};

ListItemCreate.defaultProps = {
  listItemCreate() {},
  onCancel() {},
  onListItemAdded() {},
  listItems: [],
  title: 'Create List Item',
  createButton: 'Create',
  placeholder: 'New item name',
  manageMode: false,
  infoContent: null
};

export default ListItemCreate;
