/* eslint-disable react/self-closing-comp */

import classNames from 'classnames';

export const testId = ({
  label,
  checked
}) => `toggle-switch-${label}${checked ? '-on' : checked === false ? '-off' : ''}`;

const ToggleSwitch = ({label, checked, defaultChecked, onClick, onChange, disabled, emphasizeUnchecked}) => (
  <div className={classNames('toggle-switch', {emphasized: emphasizeUnchecked && !checked})}>
    <>
      <input type="checkbox" id={`ToggleSwitch__${label}`} disabled={disabled} onChange={onChange} checked={checked} defaultChecked={defaultChecked} />
      <label
        htmlFor={`ToggleSwitch__${label}`}
        onClick={onClick}
        data-testid={testId({label, checked})} />
    </>
  </div>);

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  emphasizeUnchecked: PropTypes.bool
};

ToggleSwitch.defaultProps = {
  onClick() {},
  defaultChecked: undefined,
  checked: undefined,
  onChange: undefined,
  disabled: false,
  emphasizeUnchecked: false
};

export default ToggleSwitch;
