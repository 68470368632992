import styled from 'styled-components';
import tokens from '@kluein/design-tokens';

const ArrowContainer = styled.div`
opacity: 80%;
box-sizing: unset;
margin: 0;
padding: 0;
width: 24px !important;
height: 24px !important;
border-radius: 4px;
line-height: 1;
background-color: ${tokens.color.primitives.purple[200]};

[disabled]:hover {
  background-color: ${tokens.color.primitives.purple[200]};
  opacity: 80%;
}
&:hover {
  background-color: ${tokens.color.primitives.purple[400]};
  opacity: 100%;
}
`;

const Arrow = styled.svg`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transform: ${props => (props.direction === 'up' ? 'none' : 'rotateX(180deg)')};
  overflow: visible;
  box-sizing: unset;
`;

const ArrowIcon = ({size, direction, disabled, onClick, className, trackingId}) => {
  const color = disabled ? tokens.color.primitives.purple[500] : tokens.color.primitives.purple[800];

  return (
    <ArrowContainer id="ac" width={size} height={size}>
      <Arrow
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        direction={direction}
        disabled={disabled}
        data-tracking-id={trackingId}
        onClick={disabled ? null : onClick}
        xmlns="http://www.w3.org/2000/svg">
        <path rx="4" d="M3.98877 24.1274L20.1079 24.1274C22.7637 24.1274 24.1069 22.7944 24.1069 20.1797L24.1069 3.97852C24.1069 1.36377 22.7637 0.0307617 20.1079 0.0307617L3.98877 0.0307617C1.34326 0.0307617 0 1.35352 0 3.97852L0 20.1797C0 22.8047 1.34326 24.1274 3.98877 24.1274ZM4.02979 22.3125C2.59424 22.3125 1.81494 21.5537 1.81494 20.0874L1.81494 4.0708C1.81494 2.60449 2.59424 1.8457 4.02979 1.8457L20.0771 1.8457C21.4819 1.8457 22.292 2.60449 22.292 4.0708L22.292 20.0874C22.292 21.5537 21.4819 22.3125 20.0771 22.3125Z" fill={color} />
        <path d="M12.0688 18.6416C12.5713 18.6416 12.9199 18.2827 12.9199 17.7803L12.9199 10.5L12.8379 7.91602L14.2632 9.54639L16.0371 11.3306C16.1909 11.5049 16.396 11.5869 16.6421 11.5869C17.1138 11.5869 17.4727 11.2383 17.4727 10.7769C17.4727 10.541 17.3906 10.3359 17.2368 10.1821L12.7354 5.69092C12.52 5.47559 12.3252 5.37305 12.0688 5.37305C11.833 5.37305 11.6382 5.46533 11.4229 5.69092L6.91113 10.1821C6.75732 10.3359 6.68555 10.541 6.68555 10.7769C6.68555 11.2383 7.03418 11.5869 7.50586 11.5869C7.75195 11.5869 7.95703 11.4946 8.11084 11.3306L9.89502 9.54639L11.2998 7.92627L11.2178 10.5L11.2178 17.7803C11.2178 18.2827 11.5767 18.6416 12.0688 18.6416Z" fill={color} />
      </Arrow>
    </ArrowContainer>
  );
};

ArrowIcon.propTypes = {
  size: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down']),
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  trackingId: PropTypes.string
};

ArrowIcon.defaultProps = {
  size: '24',
  direction: 'up',
  className: null,
  trackingId: null
};

export default ArrowIcon;
