import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchCraftData} from './data_providers';
import CraftTimePeriod from './craft/craft_time_period';

export async function operatingMetricsSummary(company) {
  if(!KlueTypes.Company.validate(company)) {
    throw new Error('Parameter to operatingMetrics was unexpected type.');
  }

  const data = await fetchCraftData(company.info);

  const metricTimeseries = _.chain(data.operatingMetrics)
    .map(metric => {
      return {
        label: metric.companySpecificKpi,
        value: metric.value.amount,
        timePeriod: metric.timePeriod
      };
    })
    .groupBy('label')
    .value();

  const metrics = _.chain(metricTimeseries)
    .map(group => {
      return _.reduce(group, (memo, metric) => {
        if(memo === null || memo.timePeriod.startDate < metric.timePeriod.startDate) {
          return metric;
        }

        return memo;
      }, null);
    })
    .map(metric => {
      const timePeriod = new CraftTimePeriod(metric.timePeriod);

      return Object.assign(metric, {asOf: timePeriod.displayString()});
    })
    .value();

  const summaryFacts = [];

  if(metrics.length === 0) {
    throw new Error('operatingMetricsSummary: data unavailable');
  }

  metrics.forEach(({label, value}) => {
    summaryFacts.push(KlueTypes.SummaryFact(Types.number).create({
      label,
      value
    }));
  });

  return Types.arrayOf(KlueTypes.SummaryFact()).create(summaryFacts);
}
