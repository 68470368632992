export default function SFDCSvg(props) {
  return (
    <svg width={66} height={66} className="sfdc-icon" viewBox="0 0 800 800" {...props}>
      <g>
        <g><path fill="#00a1e0" d="M346.94,212.78a129.67,129.67,0,0,1,93.84-40.29c48.85,0,91.47,27.24,114.16,67.68a157.75,157.75,0,0,1,64.52-13.71c88.1,0,159.53,72,159.53,160.92S707.57,548.31,619.47,548.31A158.38,158.38,0,0,1,588,545.18a116.62,116.62,0,0,1-152.81,48,133.05,133.05,0,0,1-247.34-6,122.05,122.05,0,0,1-25.38,2.65C94.3,589.8,39,533.93,39,465a125,125,0,0,1,61.76-108.1,142.86,142.86,0,0,1-11.83-57.08c0-79.27,64.35-143.53,143.73-143.53a143.55,143.55,0,0,1,114.28,56.49" /></g>
        <g><path fill="#ffffff" d="M146.18,424.89c-.46,1.21.17,1.47.32,1.68a43.29,43.29,0,0,0,4.23,2.55,45.05,45.05,0,0,0,22.13,5.18c15.18,0,24.6-8.07,24.6-21.07V413c0-12-10.64-16.38-20.62-19.53l-1.3-.42c-7.53-2.45-14-4.55-14-9.51v-.26c0-4.24,3.79-7.36,9.68-7.36a43.76,43.76,0,0,1,19.29,4.93s1.47.95,2-.47c.3-.76,2.83-7.57,3.09-8.31a1.41,1.41,0,0,0-.74-1.71,43,43,0,0,0-21.75-5.84h-1.52c-13.89,0-23.59,8.39-23.59,20.42v.25c0,12.68,10.7,16.79,20.72,19.66l1.61.5c7.31,2.25,13.6,4.17,13.6,9.32v.25c0,4.7-4.09,8.2-10.69,8.2a35.89,35.89,0,0,1-19.55-5.63c-1.06-.62-1.69-1.08-2.51-1.57a1.2,1.2,0,0,0-2,.69Z" /></g>
        <g><path fill="#ffffff" d="M368.4,424.89c-.46,1.21.17,1.47.32,1.68a43.29,43.29,0,0,0,4.23,2.55,45.05,45.05,0,0,0,22.13,5.18c15.18,0,24.6-8.07,24.6-21.07V413c0-12-10.64-16.38-20.62-19.53l-1.3-.42c-7.53-2.45-14-4.55-14-9.51v-.26c0-4.24,3.79-7.36,9.68-7.36a43.76,43.76,0,0,1,19.29,4.93s1.47.95,2-.47c.3-.76,2.83-7.57,3.09-8.31a1.41,1.41,0,0,0-.74-1.71,43,43,0,0,0-21.75-5.84H393.8c-13.89,0-23.59,8.39-23.59,20.42v.25c0,12.68,10.7,16.79,20.72,19.66l1.61.5c7.31,2.25,13.61,4.17,13.61,9.32v.25c0,4.7-4.1,8.2-10.7,8.2a35.89,35.89,0,0,1-19.55-5.63c-1.06-.62-1.7-1.05-2.5-1.57a1.29,1.29,0,0,0-2,.69Z" /></g>
        <g><path fill="#ffffff" d="M520.11,399.43c0,7.35-1.37,13.13-4.07,17.22s-6.7,6-12.33,6-9.66-2-12.28-6-4-9.88-4-17.22,1.35-13.11,4-17.16,6.64-6,12.28-6,9.67,2,12.34,6,4.06,9.82,4.06,17.16m12.67-13.62a30.49,30.49,0,0,0-5.77-11,27.87,27.87,0,0,0-9.73-7.4,35.28,35.28,0,0,0-27.16,0,28,28,0,0,0-9.74,7.4,31,31,0,0,0-5.77,11,50.92,50.92,0,0,0,0,27.24,30.69,30.69,0,0,0,5.78,11,27.2,27.2,0,0,0,9.73,7.34,36.05,36.05,0,0,0,27.16,0,27.2,27.2,0,0,0,9.73-7.34,30.49,30.49,0,0,0,5.77-11,50.64,50.64,0,0,0,0-27.24" /></g>
        <g><path fill="#ffffff" d="M636.83,420.71a1.2,1.2,0,0,0-1.61-.77,29.09,29.09,0,0,1-5.89,1.69,45.81,45.81,0,0,1-6.95.5c-6.14,0-11-1.82-14.49-5.43s-5.46-9.43-5.44-17.32c0-7.18,1.75-12.58,4.86-16.69s7.79-6.19,14.06-6.19a42.11,42.11,0,0,1,13.39,1.92s1,.43,1.48-.87c1.11-3.08,1.93-5.28,3.11-8.66a1.14,1.14,0,0,0-.78-1.49,49.41,49.41,0,0,0-8.45-2.13,63.42,63.42,0,0,0-9.51-.64,34.46,34.46,0,0,0-14.13,2.72,29.26,29.26,0,0,0-10.2,7.39,31.44,31.44,0,0,0-6.17,11A43.82,43.82,0,0,0,588,399.37c0,10.51,2.84,19,8.43,25.23s14,9.41,25,9.41a51.56,51.56,0,0,0,17.94-3.2,1.15,1.15,0,0,0,.52-1.51Z" /></g>
        <g><path fill="#ffffff" d="M659,392.39a24.91,24.91,0,0,1,3.47-10.12c2.62-4,6.63-6.22,12.26-6.22s9.35,2.21,12,6.22c1.77,2.65,2.54,6.19,2.85,10.12Zm42.66-9a26.63,26.63,0,0,0-5.49-10,25.2,25.2,0,0,0-8.14-6.21,31.37,31.37,0,0,0-12.29-2.49,33.46,33.46,0,0,0-14.16,2.75A28.16,28.16,0,0,0,651.7,375a30.9,30.9,0,0,0-5.81,11.13A48.14,48.14,0,0,0,644,399.8a45.87,45.87,0,0,0,2,13.69,27.54,27.54,0,0,0,17,18,43.19,43.19,0,0,0,15.57,2.52c12.39,0,18.91-2.8,21.6-4.29.47-.26.93-.73.36-2.06l-2.8-7.85a1.23,1.23,0,0,0-1.61-.74c-3.07,1.14-7.43,3.18-17.6,3.16-6.65,0-11.58-2-14.67-5s-4.72-7.76-5-14.27l42.89,0a1.27,1.27,0,0,0,1.24-1.12,46,46,0,0,0-1.28-18.48" /></g>
        <g><path fill="#ffffff" d="M315.49,392.39A25.07,25.07,0,0,1,319,382.27c2.62-4,6.63-6.22,12.26-6.22s9.35,2.21,12,6.22c1.76,2.65,2.53,6.19,2.84,10.12Zm42.65-9a26.63,26.63,0,0,0-5.48-10,25.2,25.2,0,0,0-8.14-6.21,31.37,31.37,0,0,0-12.29-2.49,33.49,33.49,0,0,0-14.16,2.75A28.16,28.16,0,0,0,308.2,375a30.9,30.9,0,0,0-5.81,11.13,48.52,48.52,0,0,0-1.89,13.69,45.87,45.87,0,0,0,2,13.69,27.54,27.54,0,0,0,17,18A43.19,43.19,0,0,0,335,434.06c12.39,0,18.91-2.8,21.6-4.29.47-.26.93-.73.36-2.06l-2.79-7.85a1.24,1.24,0,0,0-1.62-.74c-3.07,1.14-7.42,3.18-17.61,3.16-6.64,0-11.57-2-14.66-5s-4.72-7.76-5-14.27l42.89,0a1.27,1.27,0,0,0,1.24-1.12,45.9,45.9,0,0,0-1.29-18.48" /></g>
        <g><path fill="#ffffff" d="M222.78,420.47a8.69,8.69,0,0,1-2.48-2.54,10.37,10.37,0,0,1-1.28-5.58c0-3.77,1.24-6.48,3.83-8.31,0,0,3.69-3.22,12.44-3.1a88,88,0,0,1,11.64,1v19.5h0A87.13,87.13,0,0,1,235.35,423c-8.73.53-12.61-2.52-12.58-2.51m17.07-30.14c-1.74-.13-4-.2-6.69-.2a40.68,40.68,0,0,0-10.56,1.36,27.54,27.54,0,0,0-9,4.15,20,20,0,0,0-8.51,17,22.41,22.41,0,0,0,2,9.79,17.65,17.65,0,0,0,5.6,6.7,23.44,23.44,0,0,0,8.74,3.78A51.23,51.23,0,0,0,232.77,434a77.44,77.44,0,0,0,13-1.09c4.21-.72,9.37-1.76,10.8-2.09s3-.77,3-.77a1.35,1.35,0,0,0,1-1.4l0-39.22c0-8.6-2.3-15-6.82-18.93s-11.13-5.93-19.7-5.93a70.57,70.57,0,0,0-11.49,1.06s-9.37,1.81-13.23,4.83a1.34,1.34,0,0,0-.38,1.71l3,8.16a1.08,1.08,0,0,0,1.4.7,5.53,5.53,0,0,0,.71-.35c8.25-4.49,18.69-4.35,18.69-4.35,4.64,0,8.2.93,10.6,2.77s3.53,4.5,3.53,10.21v1.81c-3.69-.53-7.07-.83-7.07-.83" /></g>
        <g><path fill="#ffffff" d="M585.79,368.23a1.16,1.16,0,0,0-.64-1.54,37.21,37.21,0,0,0-7.19-1.23c-5.39-.33-8.38.58-11.06,1.78a19.49,19.49,0,0,0-7.25,5.34v-5.22a1.25,1.25,0,0,0-1.23-1.31h-11a1.25,1.25,0,0,0-1.23,1.31v64a1.31,1.31,0,0,0,1.31,1.31h11.27a1.3,1.3,0,0,0,1.3-1.31v-32a37.44,37.44,0,0,1,1.42-11.26,16.2,16.2,0,0,1,3.75-6.31,13.17,13.17,0,0,1,5.3-3.18,20.67,20.67,0,0,1,5.76-.81,24.07,24.07,0,0,1,4.71.58c.82.09,1.29-.41,1.56-1.16.74-2,2.83-7.83,3.23-9" /></g>
        <g><path fill="#ffffff" d="M480,338.58a36.86,36.86,0,0,0-4.24-1,33.58,33.58,0,0,0-5.83-.44c-7.76,0-13.87,2.19-18.16,6.51s-7.17,10.85-8.62,19.46l-.53,2.9H432.9a1.45,1.45,0,0,0-1.43,1.24l-1.59,8.93c-.12.84.25,1.38,1.39,1.38h9.48l-9.61,53.68a66.43,66.43,0,0,1-2.57,10.57,21.6,21.6,0,0,1-3,6.1,8,8,0,0,1-3.92,3,16,16,0,0,1-5,.73,19,19,0,0,1-3.44-.38,10.16,10.16,0,0,1-2.32-.75,1.19,1.19,0,0,0-1.55.69c-.35.92-2.88,7.86-3.18,8.72a1.29,1.29,0,0,0,.66,1.72,35.09,35.09,0,0,0,3.92,1.15,26,26,0,0,0,6.28.59,31.43,31.43,0,0,0,10.53-1.63,19.88,19.88,0,0,0,7.93-5.52,28.3,28.3,0,0,0,5.57-9.6,81.5,81.5,0,0,0,3.77-14.4l9.67-54.68h14.13A1.44,1.44,0,0,0,470,376.3l1.6-8.92c.11-.85-.25-1.38-1.4-1.38H456.48a59.54,59.54,0,0,1,2.27-9.68,12.66,12.66,0,0,1,3-4.57,9,9,0,0,1,3.59-2.23,14.92,14.92,0,0,1,4.61-.65,18,18,0,0,1,3.52.35c1.33.28,1.84.43,2.19.54,1.4.42,1.59,0,1.87-.66l3.28-9a1.15,1.15,0,0,0-.79-1.5" /></g>
        <g><path fill="#ffffff" d="M288.4,431.35a1.24,1.24,0,0,1-1.23,1.3H275.8a1.23,1.23,0,0,1-1.22-1.3V339.81a1.23,1.23,0,0,1,1.22-1.3h11.37a1.24,1.24,0,0,1,1.23,1.3Z" /></g>
      </g>
    </svg>
  );
}
