export const preferenceTypes = Object.freeze({
  BLOCKLIST: 'blocklist',
  FAVORITE: 'favorite',
  RSS: 'rss'
});

export const preferenceErrors = Object.freeze({
  INVALID_RSS: 'not a valid RSS feed',
  INVALID_URL: 'invalid URL',
  NO_RESPONSE: 'no response from host',
  URL_EXISTS: 'feed already added'
});

export const placeholders = Object.freeze({
  rss: 'e.g. https://feeds.companyname.com/feed',
  blocklist: 'e.g. companyname.com',
  favorite: 'e.g. companyname.com'
});
