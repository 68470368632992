/* eslint-disable camelcase */
export const allCompaniesID = -1;

export const reviewFrequencyOptions = [
  {value: 'daily', label: 'Daily', dataTestId: 'review-frequency-daily'},
  {value: 'weekly', label: 'Weekly', dataTestId: 'review-frequency-weekly'},
  {value: 'biweekly', label: 'Bi-Weekly', dataTestId: 'review-frequency-bi-weekly'},
  {value: 'monthly', label: 'Monthly', dataTestId: 'review-frequency-monthly'},
  {value: 'once', label: 'Once', dataTestId: 'review-frequency-once'},
  {value: 'never', label: 'Send Manually', dataTestId: 'review-frequency-manually'}
];

export const reviewDayOptions = [
  {value: 'monday', label: 'Monday'},
  {value: 'tuesday', label: 'Tuesday'},
  {value: 'wednesday', label: 'Wednesday'},
  {value: 'thursday', label: 'Thursday'},
  {value: 'friday', label: 'Friday'},
  {value: 'saturday', label: 'Saturday'},
  {value: 'sunday', label: 'Sunday'}
];

export const reviewHourOptions = [
  {value: 0, label: '00:00 UTC'},
  {value: 1, label: '01:00 UTC'},
  {value: 2, label: '02:00 UTC'},
  {value: 3, label: '03:00 UTC'},
  {value: 4, label: '04:00 UTC'},
  {value: 5, label: '05:00 UTC'},
  {value: 6, label: '06:00 UTC'},
  {value: 7, label: '07:00 UTC'},
  {value: 8, label: '08:00 UTC'},
  {value: 9, label: '09:00 UTC'},
  {value: 10, label: '10:00 UTC'},
  {value: 11, label: '11:00 UTC'},
  {value: 12, label: '12:00 UTC'},
  {value: 13, label: '13:00 UTC'},
  {value: 14, label: '14:00 UTC'},
  {value: 15, label: '15:00 UTC'},
  {value: 16, label: '16:00 UTC'},
  {value: 17, label: '17:00 UTC'},
  {value: 18, label: '18:00 UTC'},
  {value: 19, label: '19:00 UTC'},
  {value: 20, label: '20:00 UTC'},
  {value: 21, label: '21:00 UTC'},
  {value: 22, label: '22:00 UTC'},
  {value: 23, label: '23:00 UTC'}
];

export const defaultDigestFrequency = 'weekly';
export const defaultDigestDay = 'thursday';
export const defaultDigestHour = 15;
export const defaultDigestSendOnceHours = 6;

export const DigestSettingsLabels = {
  numberOfArticles: 'Number of Articles Included Automatically',
  audience: 'Audience',
  schedule: 'Delivery Schedule',
  templates: 'Digest Templates',
  autoArticleSettings: 'Digest Suggestions'
};

export const alertTopics = {
  contract: 'New Customer',
  corporate_challenges: 'Corporate Challenges',
  corporate_challenges__lawsuit: 'Lawsuit',
  corporate_challenges__security_breach: 'Security Breach',
  event: 'Event',
  event__conference: 'Conference',
  event__podcast: 'Podcast',
  event__webinar: 'Webinar',
  financials: 'Financials',
  financials__earnings_report: 'Earnings Report',
  financials__ipo: 'IPO',
  financials__receives_funding: 'Funding Announcement',
  financials__stock_announcement: 'Stock Announcement',
  growth_contraction: 'Growth / Contraction',
  growth_contraction__closes_office: 'Office Closure',
  growth_contraction__expand_grow: 'Expansion / Growth',
  growth_contraction__layoffs: 'Layoffs',
  growth_contraction__restructure: 'Restructure',
  leadership_change: 'Leadership Change',
  leadership_change__joins: 'Joins',
  leadership_change__leaves: 'Leaves',
  mergers_acquisitions: 'M&A',
  mergers_acquisitions__acquires: 'Acquisition',
  mergers_acquisitions__divests: 'Divestiture',
  mergers_acquisitions__merges: 'Merger',
  partnership: 'Partnership',
  product: 'Product',
  product__integration: 'Integration',
  product__new_product: 'New Product',
  product__pricing_change: 'Pricing Change',
  product__product_comparison: 'Product Comparison',
  product__product_shutdown: 'Shutdown',
  product__product_update: 'Product Update',
  recognition_award: 'Recognition'
};

export const alertTopicHasChildren = topic => {
  return Object.keys(alertTopics).some(key => key.startsWith(`${topic}__`));
};
