import {matchPath} from 'react-router-dom';
import {userCanCurate, userIsAdmin} from './roles_utils';
import {BULK_EDIT_QUERY_PARAMS} from '../modules/constants/bulk_edit';
import {dig} from './utils';

export const getRouteParams = (props = {}, routePath = null) => {
  if(_.isEmpty(props) || _.isEmpty(routePath)) {
    return {};
  }

  const match = matchPath(props.history.location.pathname, {
    path: routePath,
    strict: false,
    exact: false
  });

  return !_.isEmpty(match) ? match.params : {};
};

export const redirectToV2 = ({v2Host, v2Path, v2Search = '', newTab = false}) => {
  const dest = new URL(window.location.href);

  dest.host = v2Host;

  if(v2Path) {
    dest.pathname = v2Path;
  }

  if(v2Search) {
    dest.search = v2Search;
  }

  if(newTab) {
    return window.open(dest.toString(), '_blank');
  }

  return window.location[v2Path ? 'assign' : 'replace'](dest.toString());
};

export const redirectConsumersToV2  = ({user, v2Host, v2Path, v2Search}) => {
  const isCurator = userCanCurate({user});
  const isAdmin = userIsAdmin({user});

  if(!isCurator && !isAdmin && v2Host) {
    redirectToV2({v2Host, v2Path, v2Search});

    return true;
  }

  return false;
};

export const redirectCuratorsToV2Dashboard = ({v2Host, company}) => {
  const isV2CuratorDashboardEnabled = !dig(company, 'companyData.isV2CuratorDashboardDisabled', false);

  if(isV2CuratorDashboardEnabled && v2Host) {
    redirectToV2({v2Host, v2Path: '/dashboard'});

    return true;
  }

  return false;
};

const isEditCardPathname = pathname => /\/profile\/\d*\/edit\/card\/\d*\/edit/i.test(pathname);

const isCreateNewCardPathname = pathname => /\/profile\/\d*\/edit\/card\/new/i.test(pathname);

export const redirectCuratorsToV2  = ({user, v2Host, isNewCardEditorEnabled}) => {
  const isCurator = userCanCurate({user});

  if(isCurator) {
    const {pathname, search} = window.location;

    if(/\/profile\/\d*/i.test(pathname) && search?.includes(BULK_EDIT_QUERY_PARAMS)) {
      redirectToV2({v2Host});

      return true;
    }

    if(isNewCardEditorEnabled) {
      if(isEditCardPathname(pathname)) {
        redirectToV2({v2Host});

        return true;
      }

      if(isCreateNewCardPathname(pathname)) {
        redirectToV2({v2Host});

        return true;
      }
    }
  }

  return false;
};

const lastKnownLocationKey = src => src && `klue.lastKnownLocation_${src}`;

export const setLastKnownLocation = src => {
  const currentURL = new URL(window.location.href);
  const storageKey = lastKnownLocationKey(src);

  if(storageKey) {
    const storedLocation = window.localStorage.getItem(storageKey);

    currentURL.searchParams.set('src', src);

    const currentLocation = currentURL.toString();

    if(storedLocation !== currentLocation) {
      window.localStorage.setItem(storageKey, currentLocation);
    }
  }
};

export const getLastKnownLocation = src => {
  const storageKey = lastKnownLocationKey(src);

  return storageKey && window.localStorage.getItem(storageKey);
};

export const klueIsInIframe = (win = window) => win?.location !== win?.parent?.location;
