import {ACTION_TYPE_CACHE_SAVE} from '../actions';

const cache = (state = new Map(), action) => {
  switch(action.type) {
    case ACTION_TYPE_CACHE_SAVE:
      const {key, value} = action.payload;

      return state.set(key, value);
    default:
      return state;
  }
};

export default cache;

export const getCacheValue = (state, key) => {
  return state.get(key);
};
