import 'react-dates/initialize';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'javascripts/kluelibs';
import 'javascripts/polyfills';
import 'javascripts/custom.modernizr';
import 'javascripts/asset-imports';
// NB: jquery-ujs is needed for rails forms, eg. devise invite/signup/signin/forgot flows
// TODO: migrate to rails-ujs, see: https://github.com/kluein/klue/issues/6040
import {} from 'jquery-ujs';

// Import styles
import 'stylesheets/application.scss';

// Mounted Components
import {mountComponents} from '../../assets/javascripts/modules/react-rails-ujs';
import AppRouter from 'javascripts/components/_app_router';
import Login from 'javascripts/components/_login';
import CopyButton from 'javascripts/components/_copy_button';
import InputPassword from 'javascripts/components/_input_password';
import WinLossSurvey from 'javascripts/components/_win_loss_survey';
import WinLossSurveySuccess from 'javascripts/components/_win_loss_survey_success';

mountComponents(
  {
    AppRouter,
    Login,
    CopyButton,
    InputPassword,
    WinLossSurvey,
    WinLossSurveySuccess
  }
);
