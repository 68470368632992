/* eslint-disable camelcase */
import React, {useState, useEffect, useRef} from 'react';
import moment from 'moment';
import classNames from 'classnames';

import Icon from '../_icon';
import Spinner from '../_spinner';
import {isValidUrl} from '../../modules/url_utils';
import {createCardSources, deleteCardSource} from '../../modules/api/sources';
import {stripHtml, decodeCommonEntities, sanitizeInput} from '../../modules/html_utils';
import {truncate} from '../../modules/text_utils';

import {Link} from 'react-router-dom';

const ERROR = {
  invalidUrl: 'Invalid URL format.',
  alreadyExists: 'This source URL was already added.'
};

const CardMeta = ({cardId, isCurator, previewingId, onDismiss, updateSourceList, sources}, context) => {
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(null);
  const isPreviewing = previewingId !== null;
  const hasSources = Boolean(sources.length);
  const canAddSources = isCurator && !isPreviewing;
  const inputRef = useRef(null);

  const handleDismiss = () => {
    if(isAdding || isDeleting) {return;}

    onDismiss();
  };

  const handleKeydown = event => {
    const {key} = event;

    if(key === 'Escape') {
      handleDismiss();
    }
  };

  const handleDismissClick = event => {
    const {target} = event;

    if(target) {
      if(target.classList.contains('card-meta-overlay') || target.classList.contains('card-meta-hole')) {
        event.preventDefault();

        handleDismiss();
      }
      else if(event && (!(target.classList.contains('card-meta-link') || target.classList.contains('card-meta-title-link')))) {
        event.preventDefault();
      }
    }
  };

  const isValidSourceUrl = () => {
    const {invalidUrl, alreadyExists} = ERROR;

    if(!isValidUrl(inputValue)) {
      setError(invalidUrl);

      return false;
    }

    const alreadyAdded = sources.some(source => inputValue === source.permalink);

    if(alreadyAdded) {
      setError(alreadyExists);

      return false;
    }

    return true;
  };

  const handleAddSource = async () => {
    const hasErrors = !isValidSourceUrl();

    if(hasErrors) {return;}

    setIsAdding(true);

    createCardSources({sourceOptions: {cardId, url: inputValue}}).then(async newSource => {
      const updatedSources = hasSources ? [...sources] : [];

      updatedSources.unshift(newSource);
      await updateSourceList(updatedSources);
    }).finally(() => {
      setInputValue('');
      setIsAdding(false);
      inputRef?.current?.focus();
    });
  };

  const handleDeleteSource = async sourceItem => {
    const {id} = sourceItem;
    const {utils} = context;

    return utils?.dialog?.confirm({
      message: 'Are you sure you want to delete this source?',
      okCallback() {
        setIsDeleting(id);
        deleteCardSource({sourceOptions: {id}})
          .then(async () => {
            if(hasSources) {
              const updatedSources = [...sources];
              const index = updatedSources.findIndex(source => source.id === id);

              if(index > -1) {
                updatedSources.splice(index, 1);
                await updateSourceList(updatedSources);
              }
            }
          }).finally(() => {
            setIsDeleting(null);
          });
      }
    });
  };

  const renderSourcesList = () => {
    const sourcesItems = [...sources].reduce((acc, sourceItem) => {
      const {
        id: sourceId,
        userId,
        createdAt,
        userAtName,
        originalAuthor,
        prettyName: sourceUser,
        permalink: sourceUrl,
        quote: sourceQuote,
        metadata,
        referableType
      } = sourceItem;
      const sourceDate = moment(createdAt).fromNow();
      const userIsUnknown = userAtName === 'unknownuser';
      const linkToUser = userId && !userIsUnknown;
      const sourceCreatedBy = linkToUser
        ? (<Link
            to={`/users/${userId}`}
            className="card-meta-source-poster"
            title={'View user profile'}>{sourceUser}</Link>)
        : (userIsUnknown ? originalAuthor : sourceUser);
      const canDeleteSources = isCurator && !isPreviewing;
      const isDeletingSource = isDeleting === sourceId;
      const deleteIcon = isDeletingSource ? <i className="fa fa-spin fa-spinner" /> : <Icon icon={'deleteX'} height="22" width="22" />;
      const titleTruncateOptions = {limit: 150, useWordBoundary: false};
      const quoteTruncateOptions = {limit: 300, useWordBoundary: false};
      const sanitizedQuote = sourceQuote ? sanitizeInput(sourceQuote) : null;
      const truncatedSanitizedQuote = sanitizedQuote ? truncate(sanitizedQuote, quoteTruncateOptions) : null;
      const truncatedSourceUrl = sourceUrl ? truncate(sourceUrl, titleTruncateOptions) : null;
      const {container, referableMetadata: {name: referableName, title: referableTitle} = {}} = metadata || {};
      let title;
      let fullURL;
      let tooltip;
      let isSlack = false;
      let isTeams = false;

      switch(referableType.toLowerCase()) {
        case 'link':
          if(sourceUrl) {
            title = referableTitle || truncatedSourceUrl;
            fullURL = sourceUrl;
            tooltip = `View Source: ${truncate(sourceUrl, {limit: 200, useWordBoundary: false})}`;
          }
          break;
        case 'comment':
          const {profileId, profileName, battlecardId, battlecardTitle, postId, postTitle} = container || {};

          isSlack = (referableName || '').toLowerCase() === 'slack';
          isTeams = (referableName || '').toLowerCase() === 'teams';

          if(postId) {
            title = postTitle ? truncate(stripHtml(postTitle), titleTruncateOptions) : truncatedSourceUrl;

            if(sourceUrl) {
              fullURL = sourceUrl;
            }
            else {
              fullURL = `/posts/${postId}`;
            }
          }
          else if(profileId) {
            if(battlecardId) {
              title = `${profileName} \u0022${battlecardTitle}\u0022 Battlecard Comments`;
              fullURL = `/profile/${profileId}/battlecard/${battlecardId}#comments`;
            }
            else {
              title = `${profileName} Board Comments`;
              fullURL = `/profile/${profileId}/${isCurator ? 'edit' : 'view'}#comments`;
            }
          }
          break;
        default:
          break;
      }

      if(fullURL) {
        acc.push(
          (
            <div key={sourceId} className="card-meta-item" data-testid="cardSource-sourcesItem">
              <div className="card-meta-data">
                <h5 className="card-meta-title" title={decodeCommonEntities(stripHtml(title))}>
                  <a href={fullURL}
                    target="_blank"
                    className="card-meta-link"
                    title={tooltip || 'View source'}>
                    {title}
                  </a>
                </h5>

                {truncatedSanitizedQuote &&
                  (<div className={classNames('card-meta-quote', {post_slack: isSlack, post_teams: isTeams})} dangerouslySetInnerHTML={{__html: truncatedSanitizedQuote}} />)
                }
                <div className="card-meta-bottom">
                  <small>{sourceCreatedBy} {sourceDate}</small>
                </div>
              </div>
              {canDeleteSources &&
              <div className="card-meta-actions">
                <button disabled={isDeletingSource} onClick={() => handleDeleteSource(sourceItem)} data-testid="cardSource-deleteSource">
                  {deleteIcon}
                </button>
              </div>
            }
            </div>
          )
        );
      }

      return acc;
    }, []);

    if(!sourcesItems.length) {
      return null;
    }

    return (
      <>
        <div className="card-meta-list" data-testid="cardSource-sourcesList">
          {sourcesItems}
        </div>
      </>
    );
  };

  const handleInputValue = e => {
    const {target: {value}} = e;

    setInputValue(value);
    setError(null);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, false);

    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, []);

  return (
    <div className="card-meta-overlay" onClick={handleDismissClick}>
      <div className="card-meta-hole" />
      <div className="card-meta card-meta--animate card-meta-default">
        <Icon icon="close" width="18" height="18" className="card-meta--close-icon" onClick={handleDismiss} />

        <div className="card-meta-header">
          <h4><strong>Card Sources</strong></h4>

          {canAddSources &&
          <div className="card-meta-form">
            <form onSubmit={handleAddSource}>
              <div className="form-group">
                <div className="input-group">
                  <input
                    ref={inputRef}
                    autoFocus={true}
                    data-testid="cardSource-urlInput"
                    disabled={isAdding}
                    className="form-control"
                    type="text"
                    placeholder="Paste URL (https://domain.com)"
                    value={inputValue}
                    onChange={handleInputValue} />
                  <div className="input-group-btn">
                    <button
                      data-testid="cardSource-addSource"
                      disabled={isAdding}
                      style={{height: 38}}
                      title="Add new source"
                      className="btn btn-success"
                      onClick={handleAddSource}>{isAdding ? <Spinner className="spinner--light spinner--small" /> : 'ADD'}</button>
                  </div>
                </div>
                {Boolean(error) && <small data-testid="cardSource-urlError" className="form-text text-muted text-warning">{error}</small>}
              </div>
            </form>
          </div>
        }
        </div>
        {renderSourcesList()}
      </div>
    </div>
  );
};

CardMeta.propTypes = {
  cardId: PropTypes.number.isRequired,
  sources: PropTypes.array,
  onDismiss: PropTypes.func,
  isCurator: PropTypes.bool.isRequired,
  previewingId: PropTypes.number,
  updateSourceList: PropTypes.func
};

CardMeta.defaultProps = {
  onDismiss: null,
  previewingId: null,
  updateSourceList: null,
  sources: []
};

CardMeta.contextTypes = {
  utils: PropTypes.object.isRequired
};

export default CardMeta;
