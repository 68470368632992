/* eslint-disable max-len */
const coordinates = {
  // KlueBot
  bot: {
    to: {o: 1, x: 0, y: 0},
    from: {o: 0, x: 0, y: -5}
  },
  // Browser Frame
  frame: {
    to: -65,
    from: 0
  }
};

const initialOnboardingPost = [{
  body: '',
  bodyPlain: 'https://klue.com/blog/klue-for-smart-competitive-intelligence-management/',
  title: 'Klue Blog',
  editedAt: null,
  viaUserId: null,
  viaUserName: null,
  viaUserEmail: null,
  pageTitle: 'Klue Blog',
  highlight: null,
  attachments: []
}];

const newsContent = {
  header: [
    {
      logo: '/onboarding/kc-logo.png'
    }
  ],
  feature: {
    big: [
      {
        headerText: 'TJ Miller Shares 3 Secrets About the Next Season of Silicon Valley',
        headerImg: '/onboarding/erlich.jpg',
        bodyText: 'We caught up with TJ Miller after the premiere of the new season of Silicon Valley, and he had a few secrets to share.',
        author: 'DW Reed'
      },
      {
        headerText: 'Telcos Now With an Axe to Grind After Amazon Court Ruling',
        headerImg: '/onboarding/trump.jpg',
        bodyText: 'The fallout from the recent Amazon vs. Verizon continues as several ISPs are now reportedly looking to countersue.',
        author: 'Xiomara Villanueva'
      }
    ],
    main: [
      {
        // title: 'This is the main title to write.',
        img: '/onboarding/satya-nadella.jpg'
        // intro: 'The tech world is abuzz with speculation about joining forces with Klue..'
      }
    ],
    vertical: [
      {
        title: 'Open, Closed Offices',
        img: '/onboarding/glassdoor.jpg',
        author: 'Witt Genstein'
      },
      {
        title: 'Hot New Pebble News',
        img: '/onboarding/pebble.jpg',
        author: 'Simone de Beauvoir'
      },
      {
        title: 'All Eyes on Slack Fund',
        img: '/onboarding/slack.png',
        author: 'Auguste Comte'
      }
    ]
  },
  small: [
    {
      headerText: 'This USB Will Blow Your Mind - And Your Budget at the Same Time',
      headerImg: '/onboarding/usb-drive.jpg',
      bodyText: 'Klue Crunch devise reporter Tim Dorr recently got his hands on the new Seagate Infinitus. With a price tag approaching infinity, we were curious to see what all the fuss is about.',
      author: 'Tim Dorr'
    },
    {
      headerText: 'Zuck Wears a Gray Shirt That is Same-Same But Different',
      headerImg: '/onboarding/zuck.jpg',
      bodyText: 'This past weekend, Mark Zuckerberg donned a gray t-shirt that was a little bit different than his usual attire — it was made using machine learning. Zuck explained how for the past month, he\'s been feeding a Facebook-created algorithm the data required to pull this off.',
      author: 'Anja Koslov'
    },
    {
      headerText: 'Kayako CEO On 2017 Plans: to Infinity and Beyond',
      headerImg: '/onboarding/kayako.png',
      bodyText: 'What is Kayako anyway? In the new year, their first order of business is answering that question for the general masses. To most, they are known as a customer service company similar to Intercom. But there is one vastly different feature that separates them.',
      author: 'Roman Solanski'
    }
  ]
};

const onboardingData = {
  initial: initialOnboardingPost,
  news: newsContent,
  xy: coordinates
};

export default onboardingData;
