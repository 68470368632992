import React from 'react';
import {Container, Select} from '../../../SFDC/button/_sfdc_button_styles';
import {parseHostname} from '../../../modules/url_utils';

const SFDCOptions = ({rival, sfdc, onOptionChanged}) => {
  const {
    options: {
      options,
      selectedOption,
      setSelectedOption,
      defaultOptionName
    }
  } = sfdc;

  if(!options.length) {
    return null;
  }

  const handleChangeCardType = e => {
    const {target} = e;
    const {value: name} = target || {};

    if(!name) {
      return;
    }

    const {formulaKey} = options.find(({name: n}) => n === name) ?? {};

    if(!formulaKey) {
      return;
    }

    setSelectedOption(name);
    onOptionChanged(name === defaultOptionName ? 'COMPANY("currentRival")' : `${formulaKey}(COMPANY("currentRival"))`);
  };

  return (
    <div className="latest-opportunity-options">
      <h6>Select Opportunity Type:</h6>
      <Container>
        <Select
          onChange={handleChangeCardType}
          value={selectedOption}
          data-testid="sfdc-select">
          {options.reduce((acc, option) => {
            const {name} = option;

            acc.push(<option
              value={name}
              key={name}>
              {name}
            </option>);

            return acc;
          }, [])}
        </Select>
      </Container>
    </div>
  );
};

SFDCOptions.propTypes = {
  rival: PropTypes.object,
  sfdc: PropTypes.object,
  onOptionChanged: PropTypes.func
};

SFDCOptions.defaultProps = {
  rival: {},
  sfdc: {},
  onOptionChanged() {}
};

export default SFDCOptions;
