import styled from 'styled-components';
import tokens from '@kluein/design-tokens';

export const Container = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${tokens.color.neutral[600]};
`;

export const Progress = styled.div`
  width: ${props => props.percentage}%;
  height: 100%;
  background-color: ${tokens.color.secondary[600]};
  transition: width 0.5s ease-out;
`;
