import {Fragment} from 'react';
import BoardLogoFormField from '../components/_board_logo_form_field';
import TextField from './_form_text_field';
import BoardTypeInfo from '../components/_board_type_info';
import {validateBoardName} from '../modules/new_board_wizard_utils';

const BoardNameAndLogoFormFields = ({type, boardName, logoUrl, rival, rivals, onUploadingStatusChange, visible, showBoardTypeInfo}) => {
  const {name, url} = rival || {};
  const allowlistedRivalNames = (name || '').length ? [name.toLowerCase()] : [];

  if(!visible) {
    return null;
  }

  return (
    <Fragment>
      <TextField
        name="boardName"
        displayName="Board Name"
        validate={value => validateBoardName(value, rivals || [], allowlistedRivalNames)} />
      {showBoardTypeInfo && (
        <BoardTypeInfo
          boardType={type}
          name={name}
          url={url} />
      )}
      <BoardLogoFormField
        url={logoUrl}
        boardName={boardName}
        onUploadingStatusChange={onUploadingStatusChange} />
    </Fragment>
  );
};

BoardNameAndLogoFormFields.propTypes = {
  type: PropTypes.string,
  boardName: PropTypes.string,
  logoUrl: PropTypes.string,
  rival: PropTypes.object,
  rivals: PropTypes.arrayOf(PropTypes.object),
  onUploadingStatusChange: PropTypes.func,
  visible: PropTypes.bool,
  showBoardTypeInfo: PropTypes.bool
};

BoardNameAndLogoFormFields.defaultProps = {
  type: 'company',
  boardName: '',
  logoUrl: null,
  rival: null,
  rivals: null,
  onUploadingStatusChange() {},
  visible: true,
  showBoardTypeInfo: false
};

export default BoardNameAndLogoFormFields;
