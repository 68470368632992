import classNames from 'classnames';
import {useState} from 'react';
import {truncateLimits} from '../modules/constants/ui';
import {wrapHtml, cleanedAndMaybeTruncated} from '../modules/html_utils';

const PostSummary = ({commentTitle, commentBody, profileMode, singlePostMode, redirectToUrl, linkToFullPost}) => {
  const [expanded, setExpanded] = useState(singlePostMode);
  const {attachmentSummary: limit} = truncateLimits;
  const buffer = Math.round(limit / 2);
  const {html, truncatable} = cleanedAndMaybeTruncated({dirtyHtml: commentBody, limit, buffer, isExpanded: expanded});

  const handleViewMoreClick = () => setExpanded(!expanded);

  const body = commentBody && (
    <>
      <div
        data-testid="feed-post-summary"
        className="summary"
        dangerouslySetInnerHTML={wrapHtml(html)} />
      {
        truncatable
          && (<button className="btn-more post-btn-more button button--alt button--small" onClick={handleViewMoreClick}>{
            expanded ? 'Less' : 'More'
          }</button>)}
    </>
  );

  return (commentTitle || commentBody) && (
    <div onClick={redirectToUrl} className={classNames('attachment-main', {'zoom-cursor': !(profileMode || (singlePostMode && linkToFullPost))})}>
      <div className="attachment-body">
        <div className="attachment-body-summary">
          {commentTitle && (<h5 className="attachment-title">
            {commentTitle}
          </h5>)}
          {body && (<div className="summary">
            {body}
          </div>)}
        </div>
      </div>
    </div>
  );
};

PostSummary.propTypes = {
  commentTitle: PropTypes.string,
  commentBody: PropTypes.string,
  profileMode: PropTypes.bool,
  singlePostMode: PropTypes.bool,
  redirectToUrl: PropTypes.func,
  linkToFullPost: PropTypes.bool
};

PostSummary.defaultProps = {
  commentTitle: '',
  commentBody: '',
  profileMode: false,
  singlePostMode: false,
  redirectToUrl: null,
  linkToFullPost: false
};

export default PostSummary;
