import className from 'classnames';

import {useState, useRef, Fragment} from 'react';
import {Field} from 'react-final-form';

import CompanyLogo from './_company_logo';
import {getSignedUrl} from '../modules/s3_utils';

const BoardLogoFormField = ({boardName, url, rival, onUploadingStatusChange}) => {
  const [uploading, setUploading] = useState(false);
  const fieldRef = useRef(null);

  const handlePreprocess = (file, next, onChange) => {
    const reader = new FileReader();

    if(reader) {
      reader.onload = ({target: {result}}) => {
        if(result) {
          onChange(result);
        }
      };

      reader.readAsDataURL(file);
    }

    setUploading(true);
    onUploadingStatusChange(true);
    next(file);
  };

  const onUploadError = () => {
    // TODO: handle error with some feedback once we've establish what that will look like design-wise.
    setUploading(false);
    onUploadingStatusChange(false);
  };

  const onUploadFinish = (signingObject, file, onChange) => {
    const {assetUrl} = signingObject;

    setUploading(false);
    onUploadingStatusChange(false);
    onChange(assetUrl);
  };

  const onUploadClick = event => {
    event.preventDefault();
    event.stopPropagation();

    if(uploading) {
      return;
    }

    const node = ReactDOM.findDOMNode(fieldRef.current);

    if(node && node.click) {
      node.click();
    }

    setUploading(false);
    onUploadingStatusChange(false);
  };

  const logo = (!url && !rival)
    ? (<div className="initials">{(boardName || '').slice(0, 2) || '??'}</div>)
    : (<CompanyLogo src={url} rival={rival} />);

  return (
    <div className="board_logo_form_field">
      <div className="board_logo_form_field_container" onClick={onUploadClick}>
        {logo}
      </div>
      <div className="board_logo_form_field_upload_controls">
        {!uploading && (
          <Fragment>
            <label>Board Image (Logo)</label>
            <div className="board_logo_form_field_upload_instructions">512px x 512px is recommended</div>
            <button
              type="button"
              className={className('button button--small', {'button--disabled': uploading})}
              disabled={uploading}
              onClick={onUploadClick}>upload new</button>

          </Fragment>
        )}
        {uploading && <div className="upload-indicator" />}
      </div>
      <Field
        name="logoUrl">
        {({input: {onChange}}) => (
          <ReactS3Uploader
            ref={fieldRef}
            getSignedUrl={(f, cb) => getSignedUrl(f, cb, onUploadError)}
            usePostForm={true}
            accept={['image/*']}
            disabled={uploading}
            preprocess={(file, next) => handlePreprocess(file, next, onChange)}
            onError={onUploadError}
            onFinish={(signingObject, file) => onUploadFinish(signingObject, file, onChange)}
            uploadRequestHeaders={{'x-amz-acl': 'public-read'}} />
        )}
      </Field>
    </div>
  );
};

BoardLogoFormField.propTypes = {
  boardName: PropTypes.string,
  url: PropTypes.string,
  rival: PropTypes.object,
  onUploadingStatusChange: PropTypes.func
};

BoardLogoFormField.defaultProps = {
  boardName: '',
  url: '',
  rival: null,
  onUploadingStatusChange() {}
};

export default BoardLogoFormField;
