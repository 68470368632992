import moment from 'moment';

const CardInfoTime = ({createdAt, updatedAt, author, reviewer, useReviewedAt}) => {
  if(useReviewedAt) {
    if(!reviewer) {
      return 'Never Reviewed';
    }

    const {reviewedAt} = reviewer;

    if(!reviewedAt) {
      return 'Never Reviewed';
    }

    return (
      <time>
        {`Reviewed ${moment(reviewedAt).fromNow()}`}
      </time>
    );
  }

  const haveDates = createdAt && updatedAt;
  const freshnessAction = (new Date(createdAt)) < (new Date(updatedAt)) ? 'Updated' : 'Created';
  const momentDate = moment(freshnessAction === 'Updated' ? updatedAt : createdAt);
  const freshnessDate = momentDate.format('llll');
  const dateString = momentDate.fromNow();
  const freshnessTime = haveDates ? (
    <time title={freshnessDate}>
      {`${author ? '' : freshnessAction}
        ${dateString}`}
    </time>
  ) : null;

  return freshnessTime;
};

CardInfoTime.propTypes = {
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  author: PropTypes.object,
  reviewer: PropTypes.object,
  useReviewedAt: PropTypes.bool
};

CardInfoTime.defaultProps = {
  createdAt: '',
  updatedAt: '',
  author: null,
  reviewer: null,
  useReviewedAt: false
};

export default CardInfoTime;

