import {fetch, post, update, destroy} from '../api_utils';
import {isValidUrl} from '../url_utils';
import {isValidId} from '../utils';

const normalizeData = (data = []) => data.map((item = {}) => {
  const {id, title = '', broken = false, promoteByDefault = false, prioritizeRssContent = false, promoteToAlert = false} = item;

  return {
    id: id || item.domain, // source preference api does not return an id
    title,
    broken,
    promoteByDefault,
    prioritizeRssContent,
    promoteToAlert,
    value: item.domain || item.url || '' // data massage
  };
});

const isValidDomain = domain => isValidUrl(`https://${domain}`);

export const alertsPreferenceGet = ({type = '', code = 'modules.api.alertsPreferenceGet'}) => {
  // args:
  //   {
  //     type: string,      // 'blocklist'|'favorite' REQUIRED
  //     code: string
  //   }
  return new Promise((resolve, reject) => {
    fetch(`/api/source_preference/${type}.json`, {code})
      .then(({data: {items = []}}) => resolve(normalizeData(items)))
      .catch(({response: {data: {errors = {}}}}) => {
        console.error(code, errors);
        reject(errors);
      });
  });
};

export const alertsPreferenceDelete = ({type = '', id: domain = '', code = 'modules.api.alertsPreferenceDelete'}) => {
  // args:
  //   {
  //     type: string,      // 'blocklist'|'favorite' REQUIRED
  //     id:   string,      // REQUIRED
  //     code: string
  //   }
  return new Promise((resolve, reject) => {
    if(!isValidDomain(domain)) {
      const error = `invalid domain: ${domain} (${typeof domain})`;

      console.error(code, error);

      return reject(`${code}: ${error}`);
    }

    destroy(`/api/source_preference/${type}.json?domain=${domain}`, {code})
      .then(() => resolve(domain))
      .catch(({response: {data: {errors = {}}}}) => {
        console.error(code, errors);

        reject(errors);
      });
  });
};

export const alertsPreferenceUpdate = ({
  type = '',
  value: domain = '',
  code = 'modules.api.alertsPreferenceUpdate'
}) => {
  // args:
  //   {
  //     type: string,     // 'blocklist'|'favorite' REQUIRED
  //     value:string,     // REQUIRED
  //     code: string
  //   }
  return new Promise((resolve, reject) => {
    const params = {
      domain
    };

    if(!isValidDomain(domain)) {
      const error = `invalid domain: ${domain}`;

      console.error(code, error);

      return reject(`${code}: ${error}`);
    }

    post(`/api/source_preference/${type}.json`, params, {code})
      .then(() => resolve(normalizeData([{domain}]).shift()))
      .catch(({response: {data: {errors = {}}}}) => {
        console.error(code, errors);

        reject(errors);
      });
  });
};

export const alertsRssGet = ({code = 'modules.api.alertsRssGet'}) => {
  // args:
  //   {
  //     code: string
  //   }
  return new Promise((resolve, reject) => {
    fetch('/api/feeds.json', {code})
      .then(({data}) => resolve(normalizeData(data)))
      .catch(({response: {data: {errors = {}}}}) => {
        console.error(code, errors);

        reject(errors);
      });
  });
};

export const alertsRssAdd = ({value: url = '', promoteByDefault = false, code = 'modules.api.alertsRssAdd'}) => {
  // args:
  //   {
  //     value: string,     // REQUIRED
  //     code: string
  //   }
  return new Promise((resolve, reject) => {
    if(!url) {
      const error = 'invalid value specified';

      console.error(code, error);

      return reject(`${code}: ${error}`);
    }

    post('/api/feeds.json', {url, promoteByDefault}, {code})
      .then(({data}) => resolve(normalizeData([data]).shift()))
      .catch(({response: {data: {errors = {}}}}) => {
        console.error(code, errors);

        reject(errors);
      });
  });
};

export const alertsRssDelete = ({id: feedId = 0, code = 'modules.api.alertsRssDelete'}) => {
  // args:
  //   {
  //     id: number,        // REQUIRED
  //     code: string
  //   }
  return new Promise((resolve, reject) => {
    if(!isValidId(feedId)) {
      const error = 'invalid feed ID specified';

      console.error(code, error);

      return reject(`${code}: ${error}`);
    }

    destroy(`/api/feeds/${feedId}.json`, {code})
      .then(() => resolve(feedId))
      .catch(({response: {data: {errors = {}}}}) => {
        console.error(code, errors);

        reject(errors);
      });
  });
};

export const alertsRssUpdate = ({
  id: feedId = '',
  value: url = '',
  promoteByDefault = false,
  prioritizeRssContent = false,
  promoteToAlert = false,
  code = 'modules.api.alertsRssUpdate'
}) => {
  // args:
  //   {
  //     id: number,        // REQUIRED
  //     value: string,     // REQUIRED
  //     code: string
  //   }
  return new Promise((resolve, reject) => {
    if(!isValidId(feedId)) {
      const error = 'invalid feed ID specified';

      console.error(code, error);

      return reject(`${code}: ${error}`);
    }

    update(`/api/feeds/${feedId}.json`, {url, promoteByDefault, prioritizeRssContent, promoteToAlert}, {code})
      .then(({data}) => resolve(normalizeData([data]).shift()))
      .catch(({response: {data: {errors = {}}}}) => {
        console.error(code, errors);

        reject(errors);
      });
  });
};

export const alertsReviewGet = () => {
  return new Promise((resolve, reject) => {
    fetch('/api/review_sources.json')
      .then(({data}) => resolve(data))
      .catch(({response: {data: {errors = {}}}}) => {
        console.error(errors);
        reject(errors);
      });
  });
};

export const alertsReviewCreate = ({rivalId, url}) => {
  // args:
  //   {
  //     rivalId: string,     // REQUIRED
  //     url: string
  //     code: string
  //   }
  return new Promise((resolve, reject) => {
    if(!rivalId) {
      return reject('invalid rivalId specified');
    }

    if(!url) {
      return reject('invalid url specified');
    }

    post(`/api/review_sources/${rivalId}.json`, {url})
      .then(({data}) => resolve(normalizeData([data]).shift()))
      .catch(({response: {data: {error = ''}}}) => {
        console.error(error);

        reject(error);
      });
  });
};

export const alertsReviewDelete = ({url, rivalId}) => {
  // args:
  //   {
  //     url: string,        // REQUIRED
  //     rivalId: string
  //   }
  return new Promise((resolve, reject) => {
    if(!rivalId) {
      return reject('invalid rivalId specified');
    }

    if(!url) {
      return reject('invalid url specified');
    }

    destroy(`/api/review_sources/${rivalId}.json`, {data: {url}})
      .then(resolve)
      .catch(errors => {
        console.error(errors);

        reject(errors);
      });
  });
};

