import CraftTimePeriod from './craft_time_period';

class CraftPeriodStatement {

  constructor(periodStatement) {
    this.periodStatement = periodStatement;
  }

  timePeriod() {
    return new CraftTimePeriod(this.periodStatement.period);
  }

  get(property) {
    return this.periodStatement[property];
  }

}

export default CraftPeriodStatement;
