import {truncateLimits} from './constants/ui';
import {decodeCommonEntities, processLinks, sanitizeInput, stripHtml, allowlistedTags, wrapHtml} from './html_utils';
import {truncate, pluralize} from './text_utils';
import {isDraftRival} from './rival_utils';
import {getPinnedComment} from './post_utils';
import {capitalize} from '../modules/text_utils';

import {userCanCurate, userIsAdmin, userIsKluebot} from './roles_utils';
import {defaultDigestDay, defaultDigestFrequency, defaultDigestHour} from './constants/digest';

import moment from 'moment';
import {Link} from 'react-router-dom';

export const DIGEST_REMINDER_TIME = 24;

export const digestHeaderInputs = Object.freeze({
  SUBJECT: 'subject',
  SUMMARY: 'summary'
});

export const sanitizeDigestHtml = text => {
  const sanitizeOptions = {
    allowedTags: ['b', 'i', 'em', 'strong', 'u', 'a', 'img', 'br', 'p', 'div', 'span', 'ol', 'ul', 'li', 's', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    allowedAttributes: {
      '*': ['style'],
      a: ['href'],
      img: ['src']
    }
  };

  return sanitizeInput(text, sanitizeOptions);
};

export const canEditDigest = (digest = {}, user = {}, company = {}) => {
  if(_.isEmpty(digest) || _.isEmpty(user) || _.isEmpty(company)) {
    return false;
  }

  if(!digest.locked) {
    return userCanCurate(user);
  }

  // instance admins and kluebot can override/edit locked state and delete locked digests
  if(userIsAdmin(user) || userIsKluebot({userId: user.id, company}) ||
    (!digest.locked || (digest.locked && [digest.lastCuratorId, digest.userId].includes(user.id)))) {
    return true;
  }

  return false;
};

export const canEditDigests = (digests = [], user = {}, company = {}) => (
  digests.map(digest => ({
    digest,
    value: canEditDigest(digest, user, company)
  }))
);

export const isFavoriteUpdated = (favorite = {}, updatedFavorite = {}) => {
  return Object.entries(updatedFavorite).some(([key, val]) => {
    return favorite[key] !== val;
  });
};

export const getCompetitorTags = digest => digest.competitors.map(c => c[0]);

export const filterPublishedRivals = (rivals = {}) => (Object.values(rivals) || []).filter(rival => !isDraftRival(rival));

export const getRivalsForTags = (tags = [], rivals = {}, options = {publishedOnly: true}) => {
  if(_.isEmpty(tags) || _.isEmpty(rivals)) {
    return [];
  }

  // map to rivals + filter out any deleted/unmatched/draft competitor tags
  // rivals can be object or an array.
  const rivalArray = Object.values(rivals);
  const allRivals = tags.reduce((acc, c) => {
    const foundRival = rivalArray.find(r => r.name.toLowerCase() === c.toLowerCase());

    if(foundRival) {
      acc.push(foundRival);
    }

    return acc;
  }, []);
  const {publishedOnly} = options;

  return publishedOnly
    ? filterPublishedRivals(allRivals)
    : allRivals;
};

export const getRivalsNamesForTags = (tags = [], rivals = {}) => {
  if(_.isEmpty(tags) || _.isEmpty(rivals)) {
    return [];
  }

  // map to rivals + filter out any deleted/unmatched competitor tags
  return getRivalsForTags(tags, rivals).map(r => r.name);
};

export const getLogoRivalWithRivalId = (favorite = {}, rivalId, rivals = {}, publishedOnly = true) => {
  const {competitors} = favorite;
  let favoriteRivals = (getRivalsForTags(competitors, rivals, {publishedOnly}) || []).sort((r1, r2) => r1.id - r2.id);

  if(publishedOnly) {
    // w.r.t. the logo... consider rivals with no cards to be unpublished too.
    // This maps the FE to the BE behaviour
    favoriteRivals = [...favoriteRivals].filter(fr => fr.profile?.cardsCount);
  }

  return favoriteRivals.find(r => r.id === rivalId) || favoriteRivals[0];
};

export const formatCompetitorsList = (competitors = []) => {
  if(_.isEmpty(competitors)) {
    return '';
  }

  let firstCompetitors = [];

  if(competitors.length > 3) {
    firstCompetitors = competitors.slice(0, 3);
  }
  else {
    firstCompetitors = competitors.slice(0, competitors.length - 1);
  }

  const numRemaining = competitors.length - firstCompetitors.length;
  const firstCompStr = `${firstCompetitors.length ?
    `${firstCompetitors.join(', ')}${((firstCompetitors.length > 1) && numRemaining) ? ',' : ''} and` : ''}`;
  const remainingCompStr = `${(numRemaining > 1) ? `${numRemaining} others` :
    `${competitors[competitors.length - 1]}`}`;

  return `${firstCompStr} ${remainingCompStr}`;
};

export const getDefaultOverviewCopy = ({digest = null, rivals = [], inputType = null, plainText = false}) => {
  if(_.isEmpty(digest) || !inputType) {
    return;
  }

  const competitorsList = formatCompetitorsList(getRivalsNamesForTags(getCompetitorTags(digest), rivals));
  let content = '';

  switch(inputType) {
    case digestHeaderInputs.SUBJECT:
      content = `Intel updates ${competitorsList ? `on ${competitorsList}` : 'from Klue'}`;

      break;
    case digestHeaderInputs.SUMMARY:
      content = `Here's what the market has been up to since our last digest.<br /><br />
          <small>Find something worth sharing? Hit the Klue Button.
          Top articles and commentary will be selected for the next digest.</small>`;
      break;
    default:
      break;
  }

  if(!plainText) {
    return (
      <span dangerouslySetInnerHTML={wrapHtml(content)} />
    );
  }

  return (stripHtml(content) || '').replace(/\s+/g, ' ');
};

export const getNextDigestUTCDateTime = digest => {
  const {willSendAt = ''} = digest || {};

  return (willSendAt ? moment(willSendAt).utc().local() : null);
};

export const getFavoriteCommentary = (favorite = {}, post = {}, options = {}) => {
  const {curatedCommentary} = favorite;
  const pinnedComment = getPinnedComment(post, options);

  let favoriteCommentary = null;

  if(pinnedComment) {
    favoriteCommentary = pinnedComment;
  }

  if(curatedCommentary !== null) {
    // curatedCommentary wins
    favoriteCommentary = curatedCommentary;
  }

  return favoriteCommentary;
};

export const getFavoriteContent = (favorite = {}, post = {}, options = {}) => {
  const {shouldProcessSummary = true, htmlEditorEnabled = false} = options;
  const placeholderAttachment = {title: '(Untitled article)', body: ''};
  const firstAttachment = post.attachments ? post.attachments.find(a => !_.isEmpty(a.body)) || placeholderAttachment : placeholderAttachment;
  const favoriteTitle = favorite.curatedTitle || post.commentTitle || firstAttachment.title;
  const _cleanup = s => sanitizeInput(s, {
    allowedTags: allowlistedTags.filter(t => (!['noscript'].includes(t))),
    allowedSchemesByTag: {
      img: ['https']
    },
    exclusiveFilter: ({tag, text, mediaChildren}) =>
      (['p', 'div', 'span', 'li', 'a'].includes(tag) && !text.trim() && !(mediaChildren && mediaChildren.length)),
    nonTextTags: ['noscript']
  }).trim()
    .replace(/^[ \t\r]+|[ \t\r]+$/gm, '')
    .replace(/([ \t\r]*\B\n){2,}/gm, '\n\n')
    .split('\n\n')
    .map(str => str.trim().replace(/\n/g, ' '))
    .join('\n\n');

  const limit = truncateLimits.digestSummary;
  const buffer = Math.round(limit / 2);
  const _truncate = toTruncate => {
    const {body} = new DOMParser().parseFromString(toTruncate, 'text/html');
    const html = body.innerHTML;

    return truncate(html,
      {
        isHtml: true,
        useWordBoundary: true,
        limit,
        buffer
      });
  };

  const formatSummary = ({summary, shouldTruncate = true, shouldProcessLinks = true}) => {
    if(!shouldProcessSummary && !htmlEditorEnabled) {
      return shouldTruncate
        ? _truncate(summary)
        : summary;
    }

    const flow = [];

    if(htmlEditorEnabled) {
      if(shouldProcessLinks) {
        flow.push(processLinks);
      }

      if(shouldTruncate) {
        flow.push(_truncate);
      }
    }
    else {
      flow.push(decodeCommonEntities);

      if(shouldProcessLinks) {
        flow.push(processLinks);
      }

      if(shouldTruncate) {
        flow.push(_truncate);
      }

      flow.push(_cleanup);
    }

    if(flow.length) {
      return _.flowRight(flow)(summary);
    }

    return summary;
  };

  // Various fallbacks here...
  // favorite.curatedSummary -> post.commentBodyHtml -> post.commentBodySummary -> firstAttachment.body
  const {curatedSummary} = favorite;
  const {commentBodySummary, commentBodyHtml, isGeneratedBody} = post;
  const {body, url} = firstAttachment;
  let rawSummary; let shouldTruncate; let shouldProcessLinks;

  if(isGeneratedBody && _.isNull(curatedSummary) && !_.isEmpty(commentBodyHtml)) {
    rawSummary = commentBodyHtml;
    shouldTruncate = false;
    shouldProcessLinks = false;
  }
  else {
    const summaryValues = [
      curatedSummary,
      commentBodySummary,
      body
    ];

    const haveCommentBodySummary = !_.isEmpty(commentBodySummary);
    const hasUserSavedSummary = !_.isNull(curatedSummary) && curatedSummary !== url;

    const valueIndex = [
      () => hasUserSavedSummary,
      () => haveCommentBodySummary,
      () => true
    ].findIndex(shouldUseValue => shouldUseValue());

    rawSummary = summaryValues[valueIndex];
    shouldTruncate = !hasUserSavedSummary;

    // only processLinks if old digest data or the data is not html
    shouldProcessLinks = htmlEditorEnabled ? !hasUserSavedSummary && !haveCommentBodySummary : true;
  }

  const favoriteSummary = formatSummary({
    summary: rawSummary,
    shouldTruncate,
    shouldProcessLinks
  });

  const favoriteCommentary = getFavoriteCommentary(favorite, post, options);

  return {
    favoriteTitle,
    favoriteCommentary,
    favoriteSummary
  };
};

export const filterRemindableDigests = (digests = []) => {
  if(!digests.length) {
    console.warn('DigestUtils.filterRemindableDigests: no digests');

    return [];
  }

  const filteredDigests = _.filter(digests, function(digest) {
    const {archivedAt, reviewFrequency} = digest;
    const sendTime = getNextDigestUTCDateTime(digest);

    if(!sendTime || archivedAt || reviewFrequency === 'never' || reviewFrequency === 'daily') {
      return false;
    }

    const timeToDigestSend = Math.round(sendTime.diff(moment(), 'hours', true));

    return timeToDigestSend <= DIGEST_REMINDER_TIME;
  });

  return filteredDigests;
};

export const getDigestReminderMessage = (digests = [], onClick = () => {}) => {
  if(!digests.length) {
    console.warn('DigestUtils.getDigestReminderMessage: no digests');

    return null;
  }

  const digestCount = digests.length;
  const children = (
    <>
      <p>You have <b>{digestCount} {pluralize('digest', digestCount)}</b> scheduled in the next
        <b> 24 hours </b> Make your Digests the best ever by <Link to="/digest" onClick={onClick}>editing them now</Link></p>
      <div className="digest-reminder-table">
        <div className="digest-reminder-table-header-row">
          <div>Digest</div>
          <div>People</div>
          <div>Time Left</div>
        </div>
        {digests.map(digest => {
          const {digestType: {digestRecipientsCount = 0, name = ''}, id} = digest;
          const sendTime = getNextDigestUTCDateTime(digest);
          const timeToDigestSend = Math.round(sendTime.diff(moment(), 'hours', true));

          return (
            <div className="digest-reminder-table-row" key={id}>
              <div>{name}</div>
              <div>{digestRecipientsCount}</div>
              <div>{timeToDigestSend} {pluralize('hour', timeToDigestSend)}</div>
            </div>
          );
        })}
      </div>
    </>
  );

  const msg = (<span>{children}<Link to="/digest" onClick={onClick}>Curate your Intel Digest now</Link>.</span>);

  return msg;
};

export const renderDigestScheduleString = digest => {
  if(!digest) {
    return null;
  }

  const {digestType} = digest;

  if(!digestType) {
    return null;
  }

  const {reviewFrequency, reviewDay, reviewHour, deleteAfterSend} = digestType;
  const currentDayLabel = capitalize(reviewDay ?? '');

  if(deleteAfterSend) {
    return 'One-Time Digest';
  }

  switch(reviewFrequency) {
    case 'daily':
      return `Repeats Daily @ ${reviewHour}:00 UTC`;
    case 'weekly':
      return `Repeats Weekly - ${currentDayLabel} @ ${reviewHour}:00 UTC`;
    case 'biweekly':
      return `Repeats Bi-Weekly - ${currentDayLabel} @ ${reviewHour}:00 UTC`;
    case 'monthly':
      return `Repeats Monthly - Day 1 @ ${reviewHour}:00 UTC`;
    case 'once':
      return 'Must be sent manually thereafter';
    case 'never':
      return 'Scheduled sending is disabled';
    default: return '';
  }
};

export const getDigestAudienceStrings = digest => {
  if(!digest) {
    return null;
  }

  const {digestType} = digest;

  if(!digestType) {
    return null;
  }

  const {reviewRole} = digestType;
  const visibilityGroupsSelected = Array.isArray(reviewRole);

  if(visibilityGroupsSelected) {
    return {
      audience: 'Selected Visibility Groups',
      visibilityGroupIds: reviewRole
    };
  }

  switch(reviewRole) {
    case 'admin':
      return {audience: 'Admins'};
    case 'curator':
      return {audience: 'Admins and Curators'};
    default: return {audience: 'All Users'};
  }
};

export const renderNextDigestSendTime = (nextDigestUTCDateTime, showAlternate = false, sendTimeOnly = false) => {
  if(!nextDigestUTCDateTime) {
    return 'must be sent manually';
  }

  const now = moment();
  const dateText = now.to(nextDigestUTCDateTime, true);

  if(sendTimeOnly) {
    return dateText;
  }

  let datePrefix;
  let dateSuffix;
  let dateTextRegion;

  if(!showAlternate) {
    datePrefix = (now < nextDigestUTCDateTime) ? 'Sending in' : 'Sent';
    dateSuffix = (now >= nextDigestUTCDateTime) ? 'ago' : '';
    dateTextRegion = (
      <Link to="/digest">{dateText}</Link>
    );
  }
  else {
    datePrefix = (now < nextDigestUTCDateTime) ? 'auto-sends in' : 'was sent';
    dateSuffix = (now >= nextDigestUTCDateTime) ? ' ago' : '';
    dateTextRegion = dateText;
  }

  return (
    <time className="digest-time" dateTime={nextDigestUTCDateTime.format()} title={nextDigestUTCDateTime.format('LLLL')}>
      {datePrefix} {dateTextRegion}{dateSuffix}
    </time>
  );
};

export const getSettingsFromDigestType = digestType => {
  const {
    id,
    name,
    reviewFrequency,
    reviewDay,
    reviewHour,
    reviewRole,
    reviewSendOnceAt,
    reviewItemsPerRival,
    reviewTopics,
    reviewRivalIds,
    enableAutoSuggestion = false,
    deleteAfterSend = false,
    emailDigestTemplate
  } = digestType || {};

  return {
    name: name || '',
    id,
    reviewFrequency: reviewFrequency || defaultDigestFrequency,
    reviewDay: reviewDay || defaultDigestDay,
    reviewHour: reviewHour ?? defaultDigestHour,
    reviewRole: reviewRole || null,
    reviewSendOnceAt: reviewSendOnceAt || null,
    reviewItemsPerRival,
    reviewTopics,
    reviewRivalIds,
    enableAutoSuggestion,
    deleteAfterSend,
    emailDigestTemplate
  };
};

export const getSendDigestTooltip = (canSend, canEdit) => (canSend
  ? ''
  : !canEdit
    ? 'Digest locked. It can only be sent by the curator who locked it, or an admin.'
    : 'Digest empty. Please add a news item to this Digest in order to send it.');

export const findDigestTypesDataInsertionIndex = (digestTypesData, newName, id) => {
  const updateIndex = digestTypesData.findIndex(digestTypeData => digestTypeData.digestType.id === id);

  if(updateIndex >= 0) {
    return {updateIndex};
  }

  const newNameLower = newName.toLowerCase();
  const insertIndex = digestTypesData.findIndex(digestTypeData => digestTypeData.digestType.name.toLowerCase().localeCompare(newNameLower) > 0);

  if(insertIndex >= 0) {
    return {insertIndex};
  }

  return {insertIndex: digestTypesData.length};
};
