import {Field} from 'react-final-form';

import FormLabelWithField from './_form_label_with_field';
import ToggleSwitch from './_toggle_switch';

const FormToggleSwitch = ({name, label, initialValue, onSettingsClick, titleDataTrackingId}) => {
  return (
    <FormLabelWithField
      label={label}
      titleDataTrackingId={titleDataTrackingId}
      onSettingsClick={onSettingsClick}>
      <Field
        name={name}
        label={label}
        initialValue={initialValue}>
        {({input: {onChange, value}}) => (
          <ToggleSwitch
            label={label}
            defaultChecked={Boolean(value)}
            onClick={() => onChange(!value)} />
        )}
      </Field>
    </FormLabelWithField>
  );
};

FormToggleSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  titleDataTrackingId: PropTypes.string,
  initialValue: PropTypes.bool,
  onSettingsClick: PropTypes.func
};

FormToggleSwitch.defaultProps = {
  initialValue: false,
  titleDataTrackingId: null,
  onSettingsClick: null
};

export default FormToggleSwitch;
