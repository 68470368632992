import {useState, useCallback, useEffect} from 'react';
import classNames from 'classnames';

import {alertTopics, alertTopicHasChildren} from '../../modules/constants/digest';
import Icon from '../_icon';
import Dropdown from '../_dropdown';
import {DigestSettingsLabels} from '../../modules/constants/digest';
import DigestArticlesInfo from './_digest_articles_info';
import DigestTopicCheckboxes from './_digest_topic_checkboxes';
import DigestSectionFilter from './_digest_section_filter';
import DigestSectionToggle from './_digest_section_toggle';
import DigestSectionSelectAll from './_digest_section_select_all';
import CheckboxForObjects from './_object_checkboxes';
import ToggleSwitch from '../_toggle_switch';

const DigestArticleSettings = ({
  improvedDigestSuggestionsEnabled,
  reviewItemsPerRival,
  reviewTopics,
  reviewRivalIds,
  enableAutoSuggestion,
  rivals,
  rivalGroups,
  onSetReviewItemsPerRival,
  onDidCheckTopics,
  onClearAllTopics,
  onDidCheckRivals,
  onToggleDigestSuggestionsEnabled,
  alwaysShowInfo,
  processingTopics,
  processingRivals
}) => {
  const [showInfo, setShowInfo] = useState(alwaysShowInfo);
  const [showTopics, setShowTopics] = useState(false);
  const [showRivals, setShowRivals] = useState(false);
  const [topicsFilter, setTopicsFilter] = useState('');
  const [boardGroupsFilter, setBoardGroupsFilter] = useState('');
  const [boardsFilter, setBoardsFilter] = useState('');
  const [showBoardGroups, setShowBoardGroups] = useState(false);
  const [checkedRivalGroups, setCheckedRivalGroups] = useState([]);
  const [rivalGroupRivals, setRivalGroupRivals] = useState({});
  const toggleShowInfo = () => setShowInfo(!showInfo);
  const handleToggleShowTopics = () => setShowTopics(!showTopics);
  const handleToggleShowRivals = () => setShowRivals(!showRivals);
  const handleToggleShowBoardGroups = () => setShowBoardGroups(!showBoardGroups);
  const handleDidUpdateCheckedItems = useCallback(checkedItems => {
    onDidCheckTopics(checkedItems);
  }, [onDidCheckTopics]);
  const handleSetBoardGroupsFilter = value => setBoardGroupsFilter(value);
  const handleSetBoardsFilter = value => setBoardsFilter(value);
  const handleSetTopicFilter = value => setTopicsFilter(value);
  const handleRivalChecked = (id, checked) => onDidCheckRivals({[id]: checked});
  const handleRivalGroupChecked = (id, checked) => {
    const groupRivals = rivalGroupRivals[id];

    onDidCheckRivals([...groupRivals].reduce((acc, rivalId) => {
      acc[rivalId] = checked;

      return acc;
    }, {}));
  };

  const handleCheckAllBoards = checked => {
    onDidCheckRivals(Object.keys(rivals).reduce((acc, rivalId) => {
      acc[rivalId] = checked;

      return acc;
    }, {}));
  };

  const handleSelectAllBoards = () => handleCheckAllBoards(true);
  const handleClearAllBoards = () => handleCheckAllBoards(false);

  const handleCheckAllBoardGroups = checked => {
    onDidCheckRivals(Object.values(rivalGroupRivals).reduce((acc, groupRivals) => {
      [...groupRivals].forEach(rivalId => {
        acc[rivalId] = checked;
      });

      return acc;
    }, {}));
  };

  const handleSelectAllBoardGroups = () => handleCheckAllBoardGroups(true);
  const handleClearAllBoardGroups = () => handleCheckAllBoardGroups(false);

  const handleCheckAllTopics = checked => {
    const selectedTopics = Object.keys(alertTopics).reduce((acc, key) => {
      if(key.includes('__')) {
        acc[key] = checked;
      }
      else if(!alertTopicHasChildren(key)) {
        acc[key] = checked;
      }

      return acc;
    }, {});

    onDidCheckTopics(selectedTopics);
  };

  const handleSelectAllTopics = () => handleCheckAllTopics(true);
  const handleClearAllTopics = () => onClearAllTopics();
  const itemsPerRival = reviewItemsPerRival ?? 1;
  const frequencyOptions =
    [{value: 0, label: 'none'}]
      .concat([...Array(10).keys()].map(n => ({value: n + 1, label: `${n + 1} article${!n ? '' : 's'}`, dataTestId: `digest-articles-${n + 1}`})));
  const handleToggleDigestSuggestionsEnabled = () => {
    onToggleDigestSuggestionsEnabled(!enableAutoSuggestion);
  };

  useEffect(() => {
    const updatedCheckedRivalGroups = new Set();

    Object.entries(rivalGroupRivals).forEach(([groupId, groupRivals]) => {
      if(groupRivals.size && [...groupRivals].every(rivalId => reviewRivalIds?.includes(rivalId))) {
        updatedCheckedRivalGroups.add(parseInt(groupId, 10));
      }
    });

    setCheckedRivalGroups([...updatedCheckedRivalGroups]);
  }, [reviewRivalIds, rivalGroupRivals]);

  useEffect(() => {
    setRivalGroupRivals(rivalGroups.reduce((acc, group) => {
      acc[group.id] = new Set(group.rivals.map(rival => rival.id));

      return acc;
    }, {}));
  }, [rivalGroups]);

  return (
    <div className="digest-article-settings">
      <div className="digest-setting-header-with-info" data-tracking-id="digest-setting-articles-label">
        {improvedDigestSuggestionsEnabled
          ? (
            <div className="digest-suggestions-header-container">
              <h4><strong>{DigestSettingsLabels.autoArticleSettings}</strong></h4>
              <ToggleSwitch
                label="Enable Digest Suggestions"
                checked={enableAutoSuggestion}
                emphasizeUnchecked={true}
                onChange={handleToggleDigestSuggestionsEnabled} />
            </div>)
          : <h4 className="u-pt-m"><strong>{DigestSettingsLabels.numberOfArticles}</strong></h4>}
        {!alwaysShowInfo && <Icon icon="info-outline" width={18} height={18} onClick={toggleShowInfo} />}
      </div>
      {showInfo && <DigestArticlesInfo improvedDigestSuggestionsEnabled={improvedDigestSuggestionsEnabled} />}
      {improvedDigestSuggestionsEnabled ? (
        <div className={classNames('digest-suggestions-container', {disabled: !enableAutoSuggestion})}>
          <DigestSectionToggle
            title="Boards"
            expanded={showRivals}
            onToggleExpanded={handleToggleShowRivals}
            count={reviewRivalIds?.length ?? 0}
            showCount={enableAutoSuggestion}
            dataTrackingId="digest-articles-boards-section-toggle"
            dataTestId="digest-articles-boards-section-toggle" />
          <div className={classNames('digest-settings-section', {expanded: enableAutoSuggestion && showRivals})}>
            <DigestSectionFilter
              placeholder="Search Boards"
              filter={boardsFilter}
              onChange={handleSetBoardsFilter}
              visible={showRivals}
              dataTrackingId="" />
            <DigestSectionSelectAll
              processing={processingRivals}
              showSelectAll={!reviewRivalIds?.length}
              visible={showRivals}
              onSelectAll={handleSelectAllBoards}
              onClearAll={handleClearAllBoards} />
            {showRivals ? <CheckboxForObjects
              objects={Object.values(rivals)}
              checkedObjects={reviewRivalIds}
              filter={boardsFilter}
              onObjectChecked={handleRivalChecked} /> : null}
          </div>
          <DigestSectionToggle
            title="Board Groups"
            expanded={showBoardGroups}
            onToggleExpanded={handleToggleShowBoardGroups}
            count={checkedRivalGroups?.length ?? 0}
            showCount={enableAutoSuggestion}
            dataTrackingId="digest-articles-board-groups-section-toggle"
            dataTestId="digest-articles-board-groups-section-toggle" />
          <div className={classNames('digest-settings-section', {expanded: enableAutoSuggestion && showBoardGroups})}>
            <DigestSectionFilter
              placeholder="Search Board Groups"
              filter={boardGroupsFilter}
              onChange={handleSetBoardGroupsFilter}
              visible={showBoardGroups}
              dataTrackingId="" />
            <DigestSectionSelectAll
              processing={processingRivals}
              showSelectAll={!checkedRivalGroups?.length}
              visible={showBoardGroups}
              onSelectAll={handleSelectAllBoardGroups}
              onClearAll={handleClearAllBoardGroups} />
            {showBoardGroups ? <CheckboxForObjects
              objects={rivalGroups}
              checkedObjects={checkedRivalGroups}
              filter={boardGroupsFilter}
              onObjectChecked={handleRivalGroupChecked} /> : null}
          </div>
          <DigestSectionToggle
            title="Topics"
            expanded={showTopics}
            onToggleExpanded={handleToggleShowTopics}
            count={reviewTopics?.length ?? 0}
            showCount={enableAutoSuggestion}
            dataTrackingId="digest-articles-topics-section-toggle"
            dataTestId="digest-articles-topics-section-toggle" />
          <div className={classNames('digest-settings-section', {expanded: enableAutoSuggestion && showTopics})}>
            <DigestSectionFilter
              placeholder="Search Topics"
              filter={topicsFilter}
              onChange={handleSetTopicFilter}
              visible={showTopics}
              dataTrackingId="" />
            <DigestSectionSelectAll
              processing={processingTopics}
              showSelectAll={!reviewTopics?.length}
              visible={showTopics}
              onSelectAll={handleSelectAllTopics}
              onClearAll={handleClearAllTopics} />
            <DigestTopicCheckboxes
              checkboxItems={alertTopics}
              checkedItems={reviewTopics}
              filter={topicsFilter}
              visible={showTopics}
              onDidUpdateCheckedItems={handleDidUpdateCheckedItems} />
          </div>
        </div>
      ) : (
        <div className="settings-review_frequency-options u-mb-m">
          <div className="ui-dropdown-panel ui-dropdown-panel--left u-pt-xxs u-mb-m" data-testid="company-settings-digest-articles">
            <Dropdown
              label=""
              dataTestId="digest-schedule_articles-dropdown"
              displayLabel="Select..."
              options={frequencyOptions}
              selectedValue={itemsPerRival}
              keyPrefix="group"
              className="btn secondary"
              containerClass="ui-dropdown--left"
              alignMenuLeft={true}
              onOptionClick={onSetReviewItemsPerRival} />
          </div>
        </div>
      )}
    </div>
  );
};

DigestArticleSettings.propTypes = {
  improvedDigestSuggestionsEnabled: PropTypes.bool,
  reviewItemsPerRival: PropTypes.number,
  reviewTopics: PropTypes.array,
  reviewRivalIds: PropTypes.array,
  enableAutoSuggestion: PropTypes.bool,
  rivals: PropTypes.object,
  rivalGroups: PropTypes.array,
  onSetReviewItemsPerRival: PropTypes.func,
  onDidCheckTopics: PropTypes.func,
  onDidCheckRivals: PropTypes.func,
  onClearAllTopics: PropTypes.func,
  onToggleDigestSuggestionsEnabled: PropTypes.func,
  alwaysShowInfo: PropTypes.bool,
  processingTopics: PropTypes.bool,
  processingRivals: PropTypes.bool
};

DigestArticleSettings.defaultProps = {
  improvedDigestSuggestionsEnabled: false,
  reviewItemsPerRival: 1,
  reviewTopics: null,
  reviewRivalIds: null,
  enableAutoSuggestion: false,
  rivals: {},
  rivalGroups: [],
  onSetReviewItemsPerRival() {},
  onDidCheckTopics() {},
  onDidCheckRivals() {},
  onClearAllTopics() {},
  onToggleDigestSuggestionsEnabled() {},
  alwaysShowInfo: false,
  processingTopics: false,
  processingRivals: false
};

export default DigestArticleSettings;
