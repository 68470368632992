import {useState, useCallback, useEffect, useLayoutEffect, useRef} from 'react';

import classNames from 'classnames';

import {cardUpdate} from '../../modules/api/cards';
import {CURATORS_ONLY, EVERYONE, FULL_ACCESS_USER_LABEL, cardPermissions} from '../../modules/constants/permissions';
import Icon from '../_icon';
import ModalEditorPermissionManager from '../editor/_modal_editor_permission_manager';
import {isEmpty} from 'lodash';

const CardVisibilityGroups = ({
  card,
  previewingId,
  isOnBattlecard,
  onCardPermissionsUpdated,
  quickChangeEnabled,
  containerScroll,
  horizontalScroll
}) => {
  const {isDraft, allAccess, visibilityGroups, id: cardId} = card;
  const everyoneEnabled = !isDraft && allAccess;
  const groupsEnabled = !isDraft && !allAccess;
  const [showGroupEditor, setShowGroupEditor] = useState(false);
  const [style, setStyle] = useState({}); // eslint-disable-line no-unused-vars
  const permissionRef = useRef(null);
  const footerRef = useRef(null);

  const updatePosition = useCallback(() => {
    if(!footerRef.current || !permissionRef.current?.componentNode || !containerScroll) {
      return;
    }

    const vHeight = window.innerHeight || document.documentElement.clientHeight;

    const {top: containerTop} = containerScroll.getBoundingClientRect();
    const {height: permissionHeight} = permissionRef.current.componentNode.getBoundingClientRect();
    const {left, top, bottom, height} = footerRef.current.getBoundingClientRect();
    let bottomAdjusted = bottom;

    if((top + height - containerTop) < permissionHeight) {
      bottomAdjusted += permissionHeight - top + height;

      return setStyle({left: `${left}px`, top: `${containerTop}px`});
    }

    setStyle({left: `${left}px`, bottom: `${vHeight - bottomAdjusted}px`});
  }, [containerScroll]);

  useEffect(() => {
    if(!horizontalScroll) {
      return;
    }

    horizontalScroll.addEventListener('scroll', updatePosition);

    return () => {
      horizontalScroll.removeEventListener('scroll', updatePosition);
    };
  }, [horizontalScroll, updatePosition]);

  const closeOnResizse = useCallback(event => {
    event?.preventDefault();
    event?.stopPropagation();

    // a hack for a hack. See app/assets/javascripts/modules/card_utils.js/refreshDynamicContent
    // real reize events are not cancelable nor do they bubble
    if(event?.cancelable && event?.bubbles) {
      return;
    }

    setShowGroupEditor(false);
  }, []);

  useLayoutEffect(() => {
    if(!showGroupEditor) {
      return;
    }

    updatePosition();
    containerScroll?.addEventListener('scroll', updatePosition);

    return () => {
      containerScroll.removeEventListener('scroll', updatePosition);
    };
  }, [showGroupEditor, updatePosition, containerScroll]);

  useEffect(() => {
    if(!showGroupEditor) {
      return;
    }

    window.addEventListener('resize', closeOnResizse);

    return () => {
      window.removeEventListener('resize', closeOnResizse);
    };
  }, [closeOnResizse, showGroupEditor]);

  const getIcon = useCallback(() => {
    if(isDraft) {
      return 'visibility-off';
    }

    if(allAccess) {
      return 'visibility-on';
    }

    return 'people';
  }, [isDraft, allAccess]);

  // eslint-disable-next-line react/no-multi-comp
  const getListItems = useCallback(() => {
    if(isDraft) {
      return (<li data-test-id="card-visibility-groups--draft">{cardPermissions[CURATORS_ONLY].label}</li>);
    }

    if(allAccess) {
      return (<li data-test-id="card-visibility-groups--allAccess">{cardPermissions[EVERYONE].label}</li>);
    }

    if((visibilityGroups || []).length) {
      return visibilityGroups
        .reduce((acc, group) => {
          if(group.id === previewingId) {
            acc.unshift(group);
          }
          else {
            acc.push(group);
          }

          return acc;
        }, [])
        .map(({name, id}, i) => (
          <li data-test-id="`card-visibility-groups-list_${id}`" key={id}>
            {`${name}${(i < (visibilityGroups.length - 1)) ? ', ' : ''}`}
          </li>
        ));
    }

    return (<li>{FULL_ACCESS_USER_LABEL}</li>);
  }, [
    isDraft,
    allAccess,
    visibilityGroups,
    previewingId
  ]);

  const visibilityGroupsDidChange = ({isDraftFromPermissions, allAccessFromPermissions, visibilityGroupsFromPermissions}) => {
    if(isDraftFromPermissions !== null && isDraftFromPermissions !== isDraft) {
      return true;
    }

    if(allAccessFromPermissions !== null && allAccessFromPermissions !== allAccess) {
      return true;
    }

    if(visibilityGroupsFromPermissions !== null) {
      if(visibilityGroupsFromPermissions.length !== visibilityGroups.length) {
        return true;
      }

      if(visibilityGroupsFromPermissions.some(({id}) => !visibilityGroups.some(group => group.id === id))) {
        return true;
      }
    }

    return false;
  };

  const handleClosePermissions = async newPermissions => {
    try {
      if(!visibilityGroupsDidChange(newPermissions)) {
        return setShowGroupEditor(false);
      }

      const {isDraftFromPermissions, allAccessFromPermissions, visibilityGroupsFromPermissions} = newPermissions;

      const updatedCard = {
        id: cardId,
        data: {}
      };

      if(isDraftFromPermissions !== null) {
        updatedCard.data.isDraft = isDraftFromPermissions ? true : false;
      }

      if(allAccessFromPermissions !== null) {
        updatedCard.data.allAccess = allAccessFromPermissions ? true : false;
      }

      if(visibilityGroupsFromPermissions !== null) {
        updatedCard.data.visibilityGroups = visibilityGroupsFromPermissions.map(({id}) => id);
      }

      if(!isEmpty(updatedCard.data) && visibilityGroupsDidChange(updatedCard, card)) {
        const {data: updated} = await cardUpdate(updatedCard);

        onCardPermissionsUpdated && onCardPermissionsUpdated({card: updated});
      }

      setShowGroupEditor(false);
    }
    catch(e) {
      console.error(e);
    }
  };

  const handleCancelPermissions = () => {
    setShowGroupEditor(false);
  };

  const handleTogglePermissions = () => {
    if(!quickChangeEnabled) {
      return;
    }

    setShowGroupEditor(!showGroupEditor);
  };

  return (
    <div id={`card-visibility-groups-with-permissions_${cardId}`} className="card-visibility-groups-with-permissions" ref={footerRef}>
      {quickChangeEnabled ? <ModalEditorPermissionManager
        ref={permissionRef}
        cardId={showGroupEditor ? cardId : 0}
        style={style}
        assignedVisibilityGroups={visibilityGroups}
        visible={showGroupEditor}
        isDraftCard={isDraft}
        eventTypes={['mousedown', 'touchstart', 'keydown']}
        isEveryoneCard={allAccess}
        previewingId={previewingId}
        cancelOnClickOutside={true}
        onClose={handleClosePermissions}
        onCancel={handleCancelPermissions} /> : null}
      <div data-test-id="card-visibility-groups"
        className={classNames('card-visibility-groups', {
          'card-visibility-groups--draft': isDraft,
          'card-visibility-groups--allAccess': everyoneEnabled,
          'card-visibility-groups--groups': groupsEnabled
        })}>
        <div className={classNames('card-visibility-groups--meta-data', {
          'card-visibility-groups--meta-data--not-on-battlecard': !isOnBattlecard
        })}
          onClick={handleTogglePermissions}
          data-testid="card-visibility-groups-footer">
          <Icon data-test-id="card-visibility-groups-icon" icon={getIcon()} className="card-visibility-groups-icon" />
          <ul className={classNames('card-visibility-groups-list', {quick: quickChangeEnabled})} data-test-id="card-visibility-groups-list">
            {getListItems()}
          </ul>
        </div>
        {isOnBattlecard && <div className="card-visibility-groups--on-battlecard">Added to Battlecard</div>}
      </div>
    </div>
  );
};

CardVisibilityGroups.propTypes = {
  card: PropTypes.object,
  previewingId: PropTypes.number,
  isOnBattlecard: PropTypes.bool,
  quickChangeEnabled: PropTypes.bool,
  onCardPermissionsUpdated: PropTypes.func,
  containerScroll: PropTypes.object,
  horizontalScroll: PropTypes.object
};

CardVisibilityGroups.defaultProps = {
  card: {},
  previewingId: null,
  isOnBattlecard: false,
  quickChangeEnabled: false,
  onCardPermissionsUpdated() {},
  containerScroll: null,
  horizontalScroll: null
};

export default CardVisibilityGroups;
