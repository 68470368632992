import {isValidId} from './utils';
import {capitalize} from './text_utils';
import {userIsAdmin} from './roles_utils';

import Icon from '../components/_icon';

// api helpers
export const getImageFormat = (user, format) => {
  // relies on backend sending "-sml." image file name, see app/views/api/users/base.rabl
  return user.image ? user.image.replace('-sml.', `-${format}.`) : user.image;
};

export const mergeExtendedUserProperties = (user = {}) => {
  if(_.isEmpty(user) || !isValidId(user.id)) {
    console.warn('UserUtils.mergeExtendedUserProperties: invalid user specified: %o', user);

    return {};
  }

  const {username, firstName, lastName, deletedAt, roles, curatingProfiles} = user;

  return _.extend(user, {
    name: `${firstName} ${lastName}`,
    prettyName: (username === 'kluebot' ? capitalize(username) : `${firstName} ${lastName.charAt(0)}.`),
    imageSml: getImageFormat(user, 'sml'),      // an SSO user has been found with a null `.image` (test)
    imageMed: getImageFormat(user, 'med'),
    imageLrg: getImageFormat(user, 'lrg'),
    // re-map roles & curatingProfiles if user is deactivated (re: #2146, PR #2777)
    roles: (!deletedAt && roles) ? roles : [],
    curatingProfiles: (!deletedAt && curatingProfiles) ? curatingProfiles : []
  });
};

// user sorting
// alpha sort an array of users
export const sortUsers = ({users = [], userToShowFirst = null}) => {
  if(_.isEmpty(users)) {
    return [];
  }

  return users.sort((u1, u2) => {
    const u1name = u1.name || '';
    const u2name = u2.name || '';

    // sort specified user first
    if(userToShowFirst) {
      if(u1.id === userToShowFirst.id) {
        return -1;
      }
      else if(u2.id === userToShowFirst.id) {
        return 1;
      }
    }

    if(u1name.toLowerCase() === u2name.toLowerCase()) {
      return (u1.username || '').localeCompare(u2.username || '');
    }

    return u1name.localeCompare(u2name);
  });
};

// sort an array of users by text match position (used in user autosuggest/@mentions)
export const sortUsersByMatchIndex = (users = [], match = '') => {
  if(_.isEmpty(users)) {
    return [];
  }

  return users.sort((u1, u2) => {
    const u1usernameIndex = u1.username.indexOf(match);
    const u2usernameIndex = u2.username.indexOf(match);

    // compare match position in username, fall back to full names, fall back to alpha sort
    if((u1usernameIndex >= 0) && (u2usernameIndex >= 0)) {
      if(u1usernameIndex === u2usernameIndex) {
        return u1.username.localeCompare(u2.username);
      }

      return u1usernameIndex < u2usernameIndex ? -1 : 1;
    }
    else if((u1usernameIndex >= 0) && (u2usernameIndex === -1)) {
      return -1;
    }
    else if((u1usernameIndex === -1) && (u2usernameIndex >= 0)) {
      return 1;
    }

    const u1nameIndex = u1.name.indexOf(match);
    const u2nameIndex = u2.name.indexOf(match);

    if(u1nameIndex === u2nameIndex) {
      return u1.name.localeCompare(u2.name);
    }
    else if((u1nameIndex >= 0) && (u2nameIndex === -1)) {
      return -1;
    }
    else if((u1nameIndex === -1) && (u2nameIndex >= 0)) {
      return 1;
    }

    return 0;
  });
};

export const getCollapsedLanes = (user = {}, profileId = 0) => {
  if(_.isEmpty(user) || !isValidId(profileId)) {
    console.warn('UserUtils.getCollapsedLanes: invalid options specified: user: %o, profileId: %o', user, profileId);

    return [];
  }

  const userData = user.userData || {};
  const {collapsedProfileLanes} = userData;

  if(_.isEmpty(collapsedProfileLanes)) {
    return [];
  }

  return collapsedProfileLanes[profileId] ? collapsedProfileLanes[profileId].slice() : [];
};

export const renderSuppressionMessage = (user, dialog, unsuppressCallback = null) => {
  const {suppression: {reportedAt, error, event} = {}} = user;

  if(!reportedAt) {
    return null;
  }

  const formattedReportedAt = moment(reportedAt).format('YYYY-MM-DD');
  const reason = error || event;

  let unsuppressButton = null;

  if(unsuppressCallback) {
    const unsuppressAction = () => {
      unsuppressCallback();
      dialog.remove('modal-email-review');
    };

    unsuppressButton = (<div className="col-sm-7">
      <div className="button button--disabled" style={{width: '100%'}} onClick={unsuppressAction}>Unblock User</div>
    </div>);
  }

  return (
    <div className="suppression-dialog">
      <div className="suppression-dialog--header">
        <Icon icon="caution" width={22} height={22} />
        <h4 className="heading-dialog">Email access is restricted</h4>
      </div>
      <p className="help-block">Klue emails for <strong>{user.name} @{user.username}</strong> have been
        blocked as of {formattedReportedAt}. Future emails from Klue will not be sent to this user.</p>
      <p className="help-block">The error encountered was <strong>{reason}</strong>.
        {unsuppressCallback ?
          ' Please make sure to resolve this error in order to unblock this user' :
          ' Contact your Klue Admin to unblock and resume emails'}.</p>
      <div className="row dialog-toolbar">
        {unsuppressButton}
        <div className="col-sm-5 pull-right">
          <div className="button button--primary" style={{width: '100%'}} onClick={() => dialog.remove('modal-email-review')}>OK</div>
        </div>
      </div>
    </div>
  );
};

/**
 * See if auth has been successful or not for the given domain.
 * For use in iframe auth code, to determine if a window reload is necessary.
 *
 * @returns {boolean} True if auth has been successful, false if not
 */
export const isAuthSuccessful = () => {
  return Boolean(window.localStorage.getItem('authComplete'));
};

export const setAuthSuccessful = () => {
  window.localStorage.setItem('authComplete', true);
};

export const clearAuthSuccessful = () => {
  window.localStorage.removeItem('authComplete');
};

export const clearKluebeeCookie = () => {
  document.cookie = 'kluebee=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

export const setIsKluebeeCookie = email => {
  if((/@klue\.com$/i).test(email)) {
    document.cookie = 'kluebee=true;domain=klue.com';
  }
  else {
    clearKluebeeCookie();
  }
};
