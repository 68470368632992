export const T_SYMBOL = 'T_SYMBOL';
export const T_IDENTIFIER = 'T_IDENTIFIER';
export const T_STRING = 'T_STRING';
export const T_NUMBER = 'T_NUMBER';

export class Token {

  constructor(type, value) {
    this.type = type;
    this.value = value;
  }

}

function readIdentifier(s) {
  const res = /[a-zA-Z][a-zA-Z0-9_-]*/.exec(s);

  if(res !== null && res.index === 0) {
    return res[0];
  }

  return null;
}

function readSymbol(s) {
  const res = /[();,]/.exec(s);

  if(res !== null && res.index === 0) {
    return res[0];
  }

  return null;
}

function readString(s) {
  const res = /"(?:[^"\\]|\\.)*"/.exec(s);

  if(res !== null && res.index === 0) {
    return res[0];
  }

  return null;
}

function readNumber(s) {
  const res = /-?[0-9.]+/.exec(s);

  if(res !== null && res.index === 0) {
    return res[0];
  }

  return null;
}

const detectionMap = {
  T_IDENTIFIER: readIdentifier,
  T_SYMBOL: readSymbol,
  T_STRING: readString,
  T_NUMBER: readNumber
};

class Tokenizer {

  constructor(input) {
    this.input = input.trim();
    this.pos = 0;
  }

  hasMore() {
    return this.pos < this.input.length;
  }

  nextToken() {
    if(!this.hasMore()) {
      return null;
    }

    // Check for whitespace and move past any
    const res = /\s+/g.exec(this.input.substr(this.pos));

    if(res !== null && res.index === 0) {
      this.pos += res[0].length;
    }

    const detectors = Object.keys(detectionMap);

    for(let i = 0; i < detectors.length; i++) {
      const detectorLabel = detectors[i];
      const detectorFunc = detectionMap[detectorLabel];

      const t = detectorFunc(this.input.substr(this.pos));

      if(t !== null) {
        this.pos += t.length;

        return new Token(detectorLabel, t);
      }
    }

    throw new Error('Unknown characters at position ' + this.pos + '(' + this.input + ')');
  }

  tokenize() {
    const tokens = [];

    while(this.hasMore()) {
      const t = this.nextToken();

      tokens.push(t);
    }

    return tokens;
  }

}

export default Tokenizer;
