import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {OrderedMap, List} from 'immutable';

import GoogleMap from '../../_google_map';
import PotentiallyLongContent from '../charts/_potentially_long_content';

const MapOfPlaces = ({places}) => {
  const listLocations = useMemo(() => {
    const placeCounts = places.filter(loc => loc.label && !loc.label.includes('null')).reduce((map, loc) => {
      return map.update(loc.label, 0, count => count + 1);
    }, OrderedMap());

    return List(placeCounts).map(([label, count]) => {
      return (
        <tr key={label}>
          <td>{`${label}${count > 1 ? ` (${count})` : ''}`}</td>
        </tr>
      );
    });
  }, [places]);

  const mapMakers = useMemo(() => {
    return places
      .filter(loc => loc.latitude && loc.longitude)
      .map(({latitude: lat, longitude: lng}) => ({lat, lng}));
  }, [places]);

  return (
    <PotentiallyLongContent>
      <GoogleMap markers={mapMakers} />
      <table className="table">
        <tbody>{listLocations}</tbody>
      </table>
    </PotentiallyLongContent>
  );
};

MapOfPlaces.propTypes = {
  places: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number
  })).isRequired
};

MapOfPlaces.defaultProps = {
  places: []
};

export default MapOfPlaces;
