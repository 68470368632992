import classNames from 'classnames';

const Page = ({columns = 0, type = '', isConsumer = false, children, header}) => {
  const pageClasses = classNames('layout-page', {
    'layout-page--flush': isConsumer,
    [`columns__${columns}`]: Boolean(columns),  // TODO: class naming needs cleanup...
    [`columns__${type}`]: Boolean(type)
  });
  let container;

  if(!type && (columns <= 1)) {
    container = (
      <div className={pageClasses}>
        <div>{children}</div>
      </div>
    );
  }
  else {
    container = (
      <>
        <div className="digest-page-header-container">
          {header}
        </div>
        <div className={pageClasses}>
          {children}
        </div>
      </>
    );
  }

  return container;
};

Page.propTypes = {
  columns: PropTypes.number.isRequired,
  type: PropTypes.string,
  isConsumer: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.node
};

Page.defaultProps = {
  columns: 0,
  type: '',
  isConsumer: false,
  children: null,
  header: null
};

export default Page;
