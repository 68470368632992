import {useState} from 'react';
import RadioCheckboxList from '../_radio_checkbox_list';
import DigestVisibilityGroups from '../_digest_visibility_groups';
import Icon from '../_icon';
import {DigestSettingsLabels} from '../../modules/constants/digest';
import DigestAudienceInfo from './_digest_audience_info';

const DigestAudienceSettings = ({
  reviewRole,
  visibilityGroups,
  onToggleReviewRole,
  onVisibilityGroupSelected,
  alwaysShowInfo
}) => {
  const [showInfo, setShowInfo] = useState(alwaysShowInfo);
  const toggleShowInfo = () => setShowInfo(!showInfo);

  let selectedGroups;
  let isVisibilityGroupsSelected = false;
  let selectedRole = reviewRole;

  if(Array.isArray(reviewRole)) {
    isVisibilityGroupsSelected = true;
    selectedGroups = reviewRole;
    selectedRole = 'visibilityGroups';
  }
  else if(reviewRole === null) {
    selectedRole = 'consumer';
  }

  const reviewRoleOptions = [
    {value: 'admin', label: 'Admins', dataTestId: 'digest-audience-admin'},
    {value: 'curator', label: 'Admins & Curators', dataTestId: 'digest-audience-curator'},
    {value: 'consumer', label: 'All users', dataTestId: 'digest-audience-consumer'}
  ];

  reviewRoleOptions.splice(2, 0, {value: 'visibilityGroups', label: 'Admins, Curators & Visibility Groups', dataTestId: 'digest-audience-visibility-groups'});

  return (
    <div>
      <div className="digest-setting-header-with-info" data-tracking-id="digest-setting-audience-label">
        <h4 className="u-pt-m"><strong>{DigestSettingsLabels.audience}</strong></h4>
        {!alwaysShowInfo && <Icon icon="info-outline" width={18} height={18} onClick={toggleShowInfo} />}
      </div>
      {showInfo && <DigestAudienceInfo />}
      <div className="settings-review_review-options u-mb-m" data-testid="company-settings-digest-role">
        <RadioCheckboxList
          options={reviewRoleOptions}
          selectedValue={selectedRole}
          onOptionClick={onToggleReviewRole}
          keyPrefix="review-role" />
        {isVisibilityGroupsSelected
          ? <DigestVisibilityGroups
              visibilityGroups={visibilityGroups}
              selectedGroups={selectedGroups}
              onGroupsChanged={onVisibilityGroupSelected} />
          : null
        }
      </div>
    </div>
  );
};

DigestAudienceSettings.propTypes = {
  reviewRole: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  visibilityGroups: PropTypes.array,
  onToggleReviewRole: PropTypes.func,
  onVisibilityGroupSelected: PropTypes.func,
  alwaysShowInfo: PropTypes.bool
};

DigestAudienceSettings.defaultProps = {
  reviewRole: null,
  visibilityGroups: [],
  onToggleReviewRole() {},
  onVisibilityGroupSelected() {},
  alwaysShowInfo: false
};

export default DigestAudienceSettings;
