import Icon from './_icon';

import classNames from 'classnames';

class VisibilityGroup extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    group: PropTypes.object,
    isEditing: PropTypes.bool.isRequired,
    isDragging: PropTypes.bool.isRequired,
    modifier: PropTypes.string,
    label: PropTypes.string,
    errorText: PropTypes.string,
    onToggle: PropTypes.func,
    onToggleUserDefault: PropTypes.func,
    onToggleCardDefault: PropTypes.func,
    onToggleEffect: PropTypes.func,
    onEdit: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    groupClasses: PropTypes.string,
    dragHandle: PropTypes.node
  };

  static defaultProps = {
    group: null,
    isEditing: false,
    isDragging: false,
    modifier: '',
    label: '',
    errorText: '',
    onToggle: null,             // primary toggle action (active state is managed by parent if this is defined)
    onToggleUserDefault: null,
    onToggleCardDefault: null,
    onToggleEffect() {},        // handles side-effect events when toggling (active state managed by child)
    onEdit: null,
    onSave: null,
    onDelete: null,
    onCancel: null,
    groupClasses: '',
    dragHandle: null
  };

  state = {
    containerHeight: 0,
    battlecardsData: null
  };

  componentDidMount() {
    console.log('VisibilityGroup.componentDidMount: props: %o', this.props);

    window.addEventListener('resize', this.setMaxHeight);

    this.setMaxHeight();
  }

  componentDidUpdate() {
    this.setMaxHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMaxHeight);
  }

  setMaxHeight = () => {
    const groupEl = this.refs[`visibility-group_${this.props.group.id}`];

    if(!groupEl) {
      return;
    }

    const height = groupEl.scrollHeight;

    if(this.state.containerHeight !== height) {
      this.setState({
        containerHeight: height
      });
    }
  };

  positionCaret = event => {
    if(event && event.target) {
      event.target.setSelectionRange(0, 0);
    }
  };

  handleSave = event => {
    if(event) {
      event.preventDefault();
    }

    const groupName = this.refs.editGroupName.value.trim();

    this.props.onSave(groupName, event);
  };

  handleUserDefaultGroupToggle = event => {
    if(event) {
      event.preventDefault();
    }

    const {group, onToggleUserDefault} = this.props;

    if(_.isEmpty(group)) {
      return;
    }

    const {api: {visibilityGroupUpdate}, utils: {dialog: {confirm}}} = this.context;
    const isUserDefault = !group.isUserDefault;

    onToggleUserDefault && onToggleUserDefault({...group, isUserDefault});

    visibilityGroupUpdate({
      visibilityGroupOptions: {
        id: group.id,
        isUserDefault
      }
    }).catch(() => {
      // restore the value on error
      onToggleUserDefault && onToggleUserDefault(group);

      const tryAgain = () => this.handleUserDefaultGroupToggle();

      confirm({
        message: 'Whoops! 😬 Visibility Group Error',
        bodyContent: 'An error occurred while trying to update the User Default Group.',
        buttonOk: 'Try Again',
        okCallback: tryAgain,
        buttonCancel: 'Cancel'
      });
    });
  };

  handleCardDefaultGroupToggle = event => {
    if(event) {
      event.preventDefault();
    }

    const {group, onToggleCardDefault} = this.props;

    if(_.isEmpty(group)) {
      return;
    }

    const {api: {visibilityGroupUpdate}, utils: {dialog: {confirm}}} = this.context;
    const isCardDefault = !group.isCardDefault;

    onToggleCardDefault && onToggleCardDefault({...group, isCardDefault});

    visibilityGroupUpdate({
      visibilityGroupOptions: {
        id: group.id,
        isCardDefault
      }
    }).catch(() => {
      // restore the value on error
      onToggleCardDefault && onToggleCardDefault(group);

      const tryAgain = () => this.handleCardDefaultGroupToggle();

      confirm({
        message: 'Whoops! 😬 Visibility Group Error',
        bodyContent: 'An error occurred while trying to update the Card Default Group.',
        buttonOk: 'Try Again',
        okCallback: tryAgain,
        buttonCancel: 'Cancel'
      });
    });
  };

  render() {
    const {group: {id, isUserDefault = false, isCardDefault = false}, isEditing, isDragging, dragHandle, ...props} = this.props;
    const inputClasses = classNames({
      [props.modifier]: Boolean(props.modifier)
    });
    const userDefaultGroupRegion = (
      <i title="Toggle User default group">
        <Icon
          key={`user-default-group_${id}`}
          icon={isUserDefault ? 'star' : 'star_border'}
          width="20"
          height="20"
          className="visibility-group_icon visibility-group_icon--user-default"
          onClick={this.handleUserDefaultGroupToggle} />
      </i>
    );
    const cardDefaultGroupRegion = (
      <i title="Toggle Card default group">
        <Icon
          key={`card-default-group_${id}`}
          icon={isCardDefault ? 'tick' : 'add_circle'}
          width="20"
          height="20"
          className="visibility-group_icon visibility-group_icon--card-default"
          onClick={this.handleCardDefaultGroupToggle} />
      </i>
    );

    let groupClasses = classNames('visibility-group', props.groupClasses);
    let titleRegion;
    let editRegion;
    let editButtonsRegion;

    if(isEditing) {
      titleRegion = (
        <input
          ref="editGroupName"
          type="text"
          className="visibility-input visibility-input--edit"
          placeholder="Enter a group name..."
          maxLength={255}
          defaultValue={props.label}
          autoFocus={true}
          onFocus={this.positionCaret} />
      );

      editButtonsRegion = (
        <div className="visibility-group_edit">
          <button
            type="button"
            className="button button--small button--save"
            onClick={this.handleSave}>
            Save
          </button>
          <button
            type="button"
            className="button button--small button--alert"
            onClick={props.onDelete}>
            Delete
          </button>
          <button
            type="button"
            className="button button--small button--disabled"
            onClick={props.onCancel}>
            Cancel
          </button>
        </div>
      );
    }
    else {
      titleRegion = (
        <h4 className="visibility-group_label">
          <span className="visibility-group_label_text">{props.label}</span>
        </h4>
      );

      if(props.onEdit) {
        editRegion = (
          <div className="visibility-group_icon visibility-group_icon--edit" onClick={props.onEdit}>
            <Icon icon="settings" width="20" height="20" />
          </div>
        );
      }
    }

    const toggleRegion = [
      (
        <input
          key={`group-input_${id}`}
          id={`group_${id}`}
          name="groups"
          type="checkbox"
          className={inputClasses}
          readOnly={true}
          value={id} />
      ),
      (
        <label
          key={`group-label_${id}`}
          htmlFor={`group_${id}`}>
          {titleRegion}
          {!isEditing && userDefaultGroupRegion}
          {!isEditing && cardDefaultGroupRegion}
          {!isEditing && dragHandle}
          {editRegion}
          {editButtonsRegion}
        </label>
      )
    ];
    const errors = props.errorText ? (<div className="manage-visibility-group-error">{props.errorText}</div>) : null;

    if(props.onSave) {
      groupClasses = classNames(groupClasses, {
        'visibility-group--editing': isEditing,
        'visibility-group--drag': isDragging
      });

      return (
        <form className={groupClasses} onSubmit={this.handleSave}>
          {toggleRegion}
          {errors}
        </form>
      );
    }

    return (
      <div className={groupClasses}>
        {toggleRegion}
      </div>
    );
  }

}

export default VisibilityGroup;
