import FroalaEditor from 'froala-editor';
import {generateEditorIframeCode} from '../../../modules/html_utils';

FroalaEditor.POPUP_TEMPLATES['iframe.popup'] = '[_CUSTOM_LAYER_][_ERROR_LAYER_]';
FroalaEditor.POPUP_TEMPLATES['iframe.edit'] = '[_BUTTONS_]';

FroalaEditor.PLUGINS.iframe = function(editor) {
  let currentIframe;

  const hidePopup = () => editor.popups.hide('iframe.popup');

  const initEditPopup = () => {
    const template = {
      buttons: `
      <div class="fr-buttons">${editor.button.buildList(editor.opts.iframeEditButtons)}</div>`
    };

    return editor.popups.create('iframe.edit', template);
  };

  const showEditPopup = clickedElement => {
    const {popups, $el, $sc} = editor;
    let popup = popups.get('iframe.edit');

    currentIframe = clickedElement;
    if(!popup) {popup = initEditPopup();}

    if(popup) {
      popups.setContainer('iframe.edit', $sc);
      popups.refresh('iframe.edit');

      const iframeObject = currentIframe.find('iframe, embed');
      const left = iframeObject.offset().left + iframeObject.outerWidth() / 2;
      const top = iframeObject.offset().top + iframeObject.outerHeight();

      popups.show('iframe.edit', left, top, iframeObject.outerHeight(), true);
      popups.get('iframe.edit').addClass('fr-active'); // Should happen automatically with popups.show, but doesnt. Froala bug?
    }
  };

  const remove = () => {
    const {popups, selection, html} = editor;

    if(currentIframe) {
      const iframe = currentIframe;

      popups.hideAll();

      if(iframe.get(0)) {
        selection.setBefore(iframe.get(0)) || selection.setAfter(iframe.get(0));
      }

      iframe.remove();
      selection.restore();
      html.fillEmptyBlocks();
    }
  };

  const hideErrorMessage = () => {
    const {popups, $el, undo} = editor;
    const popup = popups.get('iframe.popup');

    if(popup) {
      popup.find('.fr-layer.fr-pactive').addClass('fr-active').removeClass('fr-pactive');
      popup.find('.fr-iframe-error-layer').removeClass('fr-active');
      popup.find('.fr-buttons').show(); // Dismiss error message.
      editor.events.focus();

      if($el.find('iframe.fr-error').length) {
        $el.find('iframe.fr-error').parent().remove();
        undo.saveStep();
        undo.run();
        undo.dropRedo();
      }

      editor.popups.hide('iframe.popup');
    }
  };

  const initListeners = () => editor.events.$on(editor.$el, 'click touchend', '.klue-embed', event => {
    const clickedElement = $(event.currentTarget);

    showEditPopup(clickedElement);
  });

  const initPopup = () => {
    const {id} = editor;
    const template = {
      custom_layer: `
    <div class="fr-iframe-embed-layer fr-layer fr-active " id="fr-iframe-embed-layer-${id}">
      <div class="fr-input-line">
        <textarea id="fr-iframe-embed-layer-text${id}" type="text" placeholder="Embedded Code" tabIndex="1" aria-required="true" rows="5"></textarea>
      </div>
      <div class="fr-action-buttons">
        <button type="button" class="fr-command fr-submit" data-cmd="iframeInsertEmbed" tabIndex="2" role="button"> Insert </button>
      </div>
    </div>`,
      error_layer: `
    <div class="fr-iframe-error-layer fr-error fr-layer " id="fr-iframe-error-layer-${id}">
      <p tabIndex="-1" class="fr-message">Something went wrong. Please try again.</p>
    <div class="fr-action-buttons">
      <button type="button" class="fr-command fr-dismiss" data-cmd="dismissError" tabIndex="2" role="button"> OK </button>
    </div>
  </div>`
    };

    initListeners();

    return editor.popups.create('iframe.popup', template);
  };

  const showErrorMessage = errorMessage => {
    const {popups, $sc} = editor;
    let popup = popups.get('iframe.popup');

    if(!popup) {popup = initPopup();}

    popup.find('.fr-layer.fr-active').removeClass('fr-active').addClass('fr-pactive');
    popup.find('.fr-iframe-error-layer').addClass('fr-active');
    popup.find('.fr-buttons').hide();

    if(currentIframe) {
      const iframeObject = currentIframe.find('iframe');

      popups.setContainer('iframe.popup', $sc);

      const {left} = iframeObject.offset();
      const top = iframeObject.offset().top + iframeObject.height();

      popups.show('iframe.popup', left, top, iframeObject.outerHeight());

      const layer = popup.find('.fr-iframe-error-layer');

      layer.addClass('fr-error');

      const messageHeader = layer.find('h3');

      messageHeader.text(errorMessage);
      editor.events.disableBlur();
      messageHeader.focus();
    }
  };

  const handleInsertEmbed = () => {
    const {popups, selection, html} = editor;

    selection.restore(); // Flag to tell if the iframe is replaced.

    if(currentIframe) {
      remove();
    }

    const embedField = popups.get('iframe.popup').find('.fr-iframe-embed-layer textarea');
    const code = embedField.val().trim();

    embedField.val('');

    const doc = new DOMParser().parseFromString(code, 'text/html');
    // Only process the first iframe found
    const firstIframe = doc.querySelector('iframe[src]');

    if(firstIframe) {
      const iframeCode = generateEditorIframeCode(firstIframe);

      html.insert(iframeCode);

      popups.hide('iframe.popup');
    }
    else {
      showErrorMessage('Iframe is not valid.');
    }
  };

  const replace = () => {
    const {popups, $sc} = editor;
    let iframePopup = popups.get('iframe.popup');

    if(!iframePopup) {iframePopup = initPopup();}

    if(!popups.isVisible('iframe.popup')) {
      popups.refresh('iframe.popup');
      popups.setContainer('iframe.popup', $sc);
    }

    const left = currentIframe.offset().left + currentIframe.outerWidth() / 2;
    const top = currentIframe.offset().top + currentIframe.height();

    popups.show('iframe.popup', left, top, currentIframe.outerHeight(), true);
    iframePopup.addClass('fr-active'); // Should happen automatically with popups.show, but doesn't. Froala bug?

    if(currentIframe) {
      iframePopup.find('.fr-iframe-embed-layer textarea').val(currentIframe.html()).focus();
    }
  };

  const showPopup = () => {
    const {popups, opts} = editor;

    popups.get('iframe.popup') || initPopup();
    hideErrorMessage();
    popups.setContainer('iframe.popup', editor.$tb);

    // grab jquery 😢 button from editor toolbar in order to place the popup relative to it
    const btn = editor.$tb.find('.fr-command[data-cmd="iframeButton"]');

    const {left} = btn.offset();
    const top = btn.offset().top + (opts.toolbarBottom ? 10 : btn.outerHeight() - 10);

    popups.show('iframe.popup', left, top, btn.outerHeight());
  };

  return {
    showPopup,
    hidePopup,
    replace,
    handleInsertEmbed,
    remove,
    initListeners,
    hideErrorMessage
  };
};

FroalaEditor.DefineIcon('iframeReplace', {
  NAME: 'exchange',
  FA5NAME: 'exchange-alt',
  SVG_KEY: 'replaceImage'
});
FroalaEditor.RegisterCommand('iframeReplace', {
  title: 'Replace',
  undo: false,
  focus: false,
  popup: true,
  refreshAfterCallback: false,
  callback: function callback() {
    this.iframe.replace();
  }
});

FroalaEditor.DefineIcon('iframeRemove', {
  NAME: 'trash',
  SVG_KEY: 'remove'
});
FroalaEditor.RegisterCommand('iframeRemove', {
  title: 'Remove',
  callback: function callback() {
    this.iframe.remove();
  }
});
FroalaEditor.RegisterCommand('iframeInsertEmbed', {
  undo: true,
  focus: true,
  callback: function callback() {
    this.iframe.handleInsertEmbed();
  }
});
FroalaEditor.RegisterCommand('dismissError', {
  undo: false,
  focus: true,
  callback: function callback() {
    this.iframe.hideErrorMessage();
  }
});
