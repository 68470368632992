import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CheckboxForObjects = ({objects, checkedObjects, filter, onObjectChecked}) => {
  // State for maintaining sorted objects
  const [sortedObjects, setSortedObjects] = useState([]);
  const [filteredObjects, setFilteredObjects] = useState([]);

  // Effect for sorting objects initially and on objects prop changes
  useEffect(() => {
    // Sort objects alphabetically by name
    const sorted = [...objects].sort((a, b) => a.name.localeCompare(b.name));

    setSortedObjects(sorted);
  }, [objects]);

  useEffect(() => {
    if(filter) {
      const filtered = sortedObjects.filter(object => object.name.toLowerCase().includes(filter.toLowerCase()));

      setFilteredObjects(filtered);
    }
    else {
      setFilteredObjects(sortedObjects);
    }
  }, [filter, sortedObjects]);

  return (
    <div className="digest-checkboxes-container">
      {filteredObjects.map(object => {
        const {id} = object;
        const checked = checkedObjects?.includes(id);

        return (
          <div className="digest-checkbox" key={id} title={object.name}>
            <i className={
              classNames('fa digest-checkboxes-checkbox', {'fa-check-square': checked, 'fa-square-o': !checked})
            }
              onClick={() => onObjectChecked(id, !checked)} />
            <label htmlFor={id} onClick={() => onObjectChecked(id, !checked)}>{object.name}</label>
          </div>
        );
      })}
    </div>
  );
};

CheckboxForObjects.propTypes = {
  objects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  checkedObjects: PropTypes.arrayOf(PropTypes.number),
  filter: PropTypes.string,
  onObjectChecked: PropTypes.func
};

CheckboxForObjects.defaultProps = {
  checkedObjects: [],
  filter: '',
  onObjectChecked() {}
};

export default CheckboxForObjects;
