import {pluralize} from '../../modules/text_utils';
import CardTagSearchLink from '../card_templates/_card_tag_search_link';

const TagOnItemsLink = ({count, id}) => {
  const tagsOnItemsString = `${count} ${pluralize('item', count)}`;

  const countComponent = count
    ? (
      <CardTagSearchLink id={id}>
        {tagsOnItemsString}
      </CardTagSearchLink>
    )
    : tagsOnItemsString;

  return (<span data-testid="tag-on-items-link" className="sub-text">Found on&nbsp;{countComponent}</span>);
};

TagOnItemsLink.propTypes = {
  count: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired
};

export default TagOnItemsLink;

