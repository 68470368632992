import {List} from 'immutable';

import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchPredictLeadsData} from './data_providers';

export async function jobPostings(company) {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);
    throw new Error('Parameter was unexpected type.');
  }

  const results = await fetchPredictLeadsData(company.info);

  const postings = results.jobs.map(postingData => {
    const {title, first_seen_at: firstSeenAt, location: region, url} = postingData.attributes;

    return KlueTypes.JobPosting.create({
      position: title,
      posted: KlueTypes.Date.create(moment(firstSeenAt)),
      city: KlueTypes.City.create({
        city: region || '',
        state: '',
        country: ''
      }),
      source: KlueTypes.Link.create({
        url,
        title: ''
      })
    });
  });

  const sortedPostings = List(postings).sort((posting0, posting1) => {
    const {posted: {isoDate: iso0}} = posting0;
    const {posted: {isoDate: iso1}} = posting1;

    if(iso0 < iso1) { return 1; }
    else if(iso0 > iso1) { return -1; }

    return 0;
  });

  return Types.arrayOf(KlueTypes.JobPosting).create(sortedPostings.toArray());
}
