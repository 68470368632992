import classNames from 'classnames';

const Grid = ({columns, classes, children}) => {
  const gridClasses = classNames({
    'layout-grid': true,
    [`col-${columns}`]: Boolean(columns),
    [classes]: Boolean(classes)
  });

  return (
    <div className={gridClasses}>
      {children}
    </div>
  );
};

Grid.propTypes = {
  columns: PropTypes.number,
  classes: PropTypes.string,
  children: PropTypes.node
};

Grid.defaultProps = {
  columns: 0,
  classes: '',
  children: null
};

export default Grid;
