import React from 'react';
import PropTypes from 'prop-types';

const InputPlaceholder = ({text}) => {
  return (<input className="klue-formula-wizard_control-input" disabled={true} value={text} />);
};

InputPlaceholder.propTypes = {
  text: PropTypes.string
};

InputPlaceholder.defaultProps = {
  text: 'Loading...'
};

export default InputPlaceholder;
