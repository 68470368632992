import {EXTERNAL_LINK_CLASSNAME} from '../../../modules/constants/editor';

const Person = ({name, position, imageUrl, linkedinUrl, twitterUrl}) => {
  const socialLinks = [];
  let image = (
    <img className="person_photo" src="/user-profile-image-backup-circle.svg" />
  );

  if(imageUrl) {
    image = (
      <img className="person_photo" src={imageUrl} loading="lazy" />
    );
  }

  if(linkedinUrl) {
    socialLinks.push(
      <a key="linkedin" className={`person_social-link person_social-link--linkedin ${EXTERNAL_LINK_CLASSNAME}`} href={linkedinUrl}>
        LinkedIn
      </a>
    );
  }

  if(twitterUrl) {
    socialLinks.push(
      <a key="twitter" className={`person_social-link person_social-link--twitter ${EXTERNAL_LINK_CLASSNAME}`} href={twitterUrl}>
        Twitter
      </a>
    );
  }

  return (
    <div className="person" key={name}>
      {image}
      <div className="person_text-block">
        <p className="person_name">{name}</p>
        <p className="person_title">{position}</p>
        <p className="person_social">
          {socialLinks}
        </p>
      </div>
    </div>
  );
};

Person.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
  twitterUrl: PropTypes.string
};

Person.defaultProps = {
  name: '',
  position: '',
  imageUrl: null,
  linkedinUrl: null,
  twitterUrl: null
};

export default Person;
