import FroalaEditor from 'froala-editor';
import {cardLinkSelectorWidth, cardLinkSelectorPadding} from '../../../modules/constants/editor';

FroalaEditor.POPUP_TEMPLATES['cardLinks.popup'] = '[_CUSTOM_LAYER_]';

// custom popup plugin to display list type selection
// for reference, see:
// - https://www.froala.com/wysiwyg-editor/examples/custom-popup
// - https://github.com/froala/wysiwyg-editor/issues/2999#issuecomment-410018914
// - https://jsfiddle.net/froala/pzt4adxb/46/
FroalaEditor.PLUGINS.cardLinks = function(editor) {
  const getPosition = () => {
    const toolbar = editor.$tb;
    const btn = toolbar.find('.fr-command[data-cmd="klue-card-link-selector"]');
    const diff = toolbar.width() - cardLinkSelectorWidth;
    let left = btn.offset().left - toolbar.offset().left;

    if(diff < left) {
      left = diff - cardLinkSelectorPadding;
    }

    return {
      top: btn.outerHeight(),
      left,
      toolbarPosition: toolbar.position(),
      toolbarWidth: toolbar.width(),
      toolbarHeight: toolbar.height()
    };
  };

  const getImageEditPopupPosition = () => {
    const imageEditPopup = editor.popups.get('image.edit');
    const imageLinkBtn = imageEditPopup?.find('.fr-command[data-cmd="klue-card-image-link-selector"]');
    const diff = imageEditPopup.width() - cardLinkSelectorWidth;

    let left = imageLinkBtn.offset().left - imageEditPopup.offset().left;

    if(diff < left) {
      left = diff - cardLinkSelectorPadding;
    }

    return {top: imageLinkBtn.outerHeight(), left, popupPosition: imageEditPopup.position()};
  };

  // externally exposed plugin methods
  return {
    getPosition,
    getImageEditPopupPosition
  };
};
