export const RIVALS_PER_PAGE = 10;
export const RIVALS_GLOBAL_PER_PAGE = 100;
export const RIVALS_LIMIT_UNLIMITED = 10000;
export const USERS_PER_PAGE = 10;
export const DIGESTS_PER_PAGE = 10;
export const MONITORS_PER_PAGE = 20;
export const CARD_SNAPSHOTS_PER_PAGE = 20;
export const NOTIFICATIONS_FETCH_INTERVAL = 1000 * 60 * 5;    // 5 minutes
export const INTEL_API_PATH = '/api/intel/v1';

export const MONITOR_FETCH = {
  HIGHLIGHTS: 1,
  FULLPAGE: 2,
  ALL: 3
};
