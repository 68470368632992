import Icon from './_icon';
import Dropdown from './_dropdown';

import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import {
  preferenceErrors as ERRORS,
  placeholders as PLACEHOLDERS
} from '../modules/constants/alerts';

class ItemList extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    onLoad: PropTypes.func.isRequired,
    onAdd: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    moreOptions: PropTypes.bool
  };

  static defaultProps = {
    type: '',
    label: 'list',
    onLoad: null,
    onAdd: null,
    onUpdate: null,
    onDelete: null,
    moreOptions: false
  };

  state = {
    addValue: '',
    currentEdit: -1,
    editValue: '',
    errorMsgAdd: '',
    errorMsgEdit: '',
    isListFetched: false,
    isPendingRemoval: [],
    items: [],
    filterTerms: ''
  };

  componentDidMount() {
    this.loadItems();
  }

  loadItems = () => {
    this.props.onLoad({
      type: this.props.type,
      company: this.context.utils.company
    }).then((data = []) => this.setState({items: data, isListFetched: true}))
      .catch(() => {
        this.setState({items: null, isListFetched: true});
      });
  };

  handleAdd = () => {
    const {type, onAdd} = this.props;
    const {items: currentItems} = this.state;
    const input = this.refs.addItemField;
    const {value = ''} = input;

    this.handleCancelUpdate();
    this.setState({
      isListFetched: false
    }, () => {
      onAdd({type, value, currentItems, input}).then(item => {
        const items = currentItems.filter(d => d.id);

        items.unshift(item);
        this.setState({
          addValue: '',
          items,
          errorMsgAdd: '',
          isListFetched: true
        });
      }).catch(errors => {
        const {showError = true, focusInput = true} = errors || {};
        const items = currentItems.filter(d => d.id);
        let errorMsgAdd;

        if(showError) {
          errorMsgAdd = (
            <p className="help-block item-list-error">
              Failed to add item {this.renderErrors(errors)}
            </p>
          );
        }

        this.setState({
          items,
          errorMsgAdd,
          isListFetched: true
        });

        if(focusInput) {
          input.focus();
        }
      });
    });
  };

  handleAddChange = event => {
    this.setState({addValue: event.target.value});
  };

  handleAddKeyPress = e => {
    if(e && e.which === 13) {
      this.handleAdd();
    }

    this.resetErrors();
  };

  handleCancelAdd = () => {
    this.setState({
      addValue: '',
      errorMsgAdd: ''
    });
  };

  getDefaultValue = id => {
    const {items} = this.state;
    const {value} = items.find(item => item.id === id);

    return value;
  };

  updateItem = (id, promoteByDefault, prioritizeRssContent, promoteToAlert) => {
    const {company} = this.context.utils;
    const {type, onUpdate} = this.props;
    const {items: currentItems} = this.state;
    const editInput = this.refs[`editItem_${id}`];
    const editValue = editInput && editInput.value || this.getDefaultValue(id);
    const curr = currentItems.find(d => d.id === id) || {};
    const {value = ''} = curr;

    const shouldSkipUpdate = editInput && value === editValue;

    if(shouldSkipUpdate) {
      this.handleCancelUpdate();

      return;
    }

    this.setState({isListFetched: false});
    onUpdate({
      company,
      id,
      type,
      value: editValue,
      promoteByDefault,
      prioritizeRssContent,
      promoteToAlert
    }).then(pref => {
      const items = currentItems.map(d => {
        if(d.id === id) {
          Object.assign(d, pref);
        }

        return d;
      });

      this.handleCancelUpdate();
      this.setState({
        items,
        errorMsgEdit: '',
        isListFetched: true
      });
    }).catch(errors => {
      const errorMsgEdit = (
        <p className="help-block item-list-error">
          Failed to update item {this.renderErrors(errors)}
        </p>
      );
      const {currentEdit} = this.state;
      const editMode = currentEdit === id;

      this.setState({
        errorMsgEdit,
        isListFetched: true
      });

      if(editMode) {editInput.focus();}
    }).finally(() => {
      ReactTooltip.hide();
    });
  };

  handleUpdate = id => {
    this.resetErrors();
    this.resetValues();

    this.setState({
      currentEdit: id,
      editValue: this.state.items.find(d => d.id === id).value || ''
    });
  };

  handleUpdateChange = event => {
    this.setState({editValue: event.target.value});
  };

  handleUpdateKeyPress = (e, id) => {
    if(e && e.which === 13) {
      this.handleUpdate(id);
    }

    this.resetErrors();
  };

  handleCancelUpdate = () => {
    this.setState({
      currentEdit: -1,
      errorMsgEdit: ''
    });
  };

  deleteItem = id => {
    this.props.onDelete({
      company: this.context.utils.company,
      id,
      type: this.props.type
    }).then(() => {
      const items = this.state.items.filter(item => item.id !== id);
      const isPendingRemoval = this.state.isPendingRemoval.filter(itemId => itemId !== id);

      this.setState({
        items,
        isPendingRemoval
      });
    });
  };

  handleDelete = id => {
    this.setState({
      isPendingRemoval: [...this.state.isPendingRemoval, id]
    }, this.deleteItem(id));
  };

  handleFilterChange = e => {
    if(e.target) {
      this.setState({
        filterTerms: e.target.value.replace(/[^\w\-.]/gi, '').toLowerCase() || ''
      });
    }
  };

  resetErrors = () => this.setState({errorMsgAdd: '', errorMsgEdit: ''});

  resetValues = () => this.setState({addValue: '', editValue: ''});

  renderErrors = (errors = {}) => {
    // TODO: see notes @ _post_box.js:349 re: Array.flat() missing polyfill
    // const errorMsgs = Object.values(errors).flat().map(errorCode => ERRORS[errorCode] || '');
    const errorMsgs = Object.values(errors).reduce((acc, val) => acc.concat(val), []).map(errorCode => ERRORS[errorCode] || '');

    return errorMsgs.length ? (<em>&mdash; {errorMsgs.join(', ')}</em>) : null;
  };

  alertConfig = (id, promoteByDefault, prioritizeRssContent, promoteToAlert, updatedConfig) => {
    switch(updatedConfig) {
      case 'promoteByDefault':
        this.updateItem(id, !promoteByDefault, prioritizeRssContent, false);
        break;
      case 'prioritizeRssContent':
        this.updateItem(id, promoteByDefault, !prioritizeRssContent, promoteToAlert);
        break;
      case 'promoteToAlert':
        this.updateItem(id, false, prioritizeRssContent, !promoteToAlert);
        break;
      default:
        console.warn('Alert is being updated with with incorrect params');
    }
  };

  renderAdd = () => {
    const {label = '', type} = this.props;
    const {isListFetched = false, errorMsgAdd, addValue} = this.state;
    const addButton = (
      <button
        className={`button button--medium ${isListFetched ? '' : 'button--loading'}`}
        onClick={isListFetched && !errorMsgAdd ? this.handleAdd : null}>
        Add <span className="xtra">to {label}</span>
      </button>
    );
    const cancelButton = (
      <button
        className="button button--medium button--disabled button--cancel"
        onClick={addValue ? this.handleCancelAdd : null}>
        Cancel
      </button>
    );

    return (<div className={`item-list-add-item${addValue && ' item-list-add-item--editing'}`}>
      <div className="item-list-add-item-fields">
        <input
          className="form-control"
          onChange={this.handleAddChange}
          onKeyDown={this.handleAddKeyPress}
          placeholder={PLACEHOLDERS[type] || ''}
          ref="addItemField"
          type="url"
          value={addValue} />
        {addButton}
        {cancelButton}
      </div>
      {errorMsgAdd}
    </div>);
  };

  renderFilter = () => {
    return (
      <div className="control-float-submit u-mb-s">
        <input type="text" onChange={this.handleFilterChange} className="form-control" placeholder="Type to filter..." />
        <div className="control-float-submit_item">
          <i className="fa fa-search" />
        </div>
      </div>
    );
  };

  renderItem = item => {
    const {type, onUpdate, moreOptions} = this.props;
    const canEdit = Boolean(onUpdate);
    const {currentEdit, isListFetched, isPendingRemoval, errorMsgEdit, editValue} = this.state;
    const {id, value = '', broken = false, promoteByDefault = false, prioritizeRssContent = false, promoteToAlert = false} = item;
    const editMode = currentEdit === id;
    const itemIsPendingRemoval = _.includes(isPendingRemoval, id);
    const buttonClasses = 'button button--small';
    const labelClasses = 'item-list_label';

    const dropdownOptions = [
      {
        value: 'promote_to_feed',
        label: 'Auto-promote all items to Feed',
        active: promoteByDefault,
        activeIcon: 'fa-check fa-1',
        onOptionClick: () => this.alertConfig(id, promoteByDefault, prioritizeRssContent, promoteToAlert, 'promoteByDefault')
      },
      {
        value: 'promote_to_alert',
        label: 'Show all items in Alerts',
        active: promoteToAlert,
        activeIcon: 'fa-check fa-1',
        onOptionClick: () => this.alertConfig(id, promoteByDefault, prioritizeRssContent, promoteToAlert, 'promoteToAlert')
      },
      {
        value: 'prioritize_rss_content',
        label: 'Show RSS description in posts',
        active: prioritizeRssContent,
        activeIcon: 'fa-check fa-1',
        onOptionClick: () => this.alertConfig(id, promoteByDefault, prioritizeRssContent, promoteToAlert, 'prioritizeRssContent')
      }
    ];
    const label = (
      <strong title="Edit" className={labelClasses}>{value}</strong>
    );

    const interactableItem = !itemIsPendingRemoval && !editMode;
    const shouldShowConfigMenu = type === 'rss' && interactableItem;

    const editFields = !itemIsPendingRemoval && editMode ? (
      <div className={labelClasses}>
        <input type="text"
          autoFocus={true}
          className="form-control"
          onChange={this.handleUpdateChange}
          onKeyDown={e => this.handleUpdateKeyPress(e, id)}
          ref={`editItem_${id}`}
          value={editValue} />
        <button
          className={`${buttonClasses} ${!isListFetched && 'button--loading'}`}
          onClick={isListFetched && !errorMsgEdit ? () => this.updateItem(id, promoteByDefault, prioritizeRssContent, promoteToAlert) : null}>
          Save
        </button>
        <button
          className={`${buttonClasses} button--disabled`}
          onClick={this.handleCancelUpdate}>
          Cancel
        </button>
      </div>
    ) : null;
    const deleteButton = !itemIsPendingRemoval && !editMode ? (
      <a title="Remove"
        onClick={event => {
          if(event) {
            event.stopPropagation();
          }

          this.handleDelete(id);
        }}>
        <Icon icon="close" width="16" height="16" />
      </a>
    ) : null;

    const classes = classNames('item-list_row', {
      'item-list_row--removal-pending': itemIsPendingRemoval,
      'item-list_row--editable': canEdit,
      'item-list_row--editing': editMode,
      'is-invalid': broken
    });

    return (
      <tr className={classes} key={id}>
        <td>
          <div className="item-list_cell">
            <div className="item-list_cell--label" onClick={canEdit && interactableItem ? () => this.handleUpdate(id) : null}>
              {editMode ? editFields : label}
              {shouldShowConfigMenu && <Dropdown
                options={dropdownOptions}
                condensed={true}
                multiSelect={true}
                button={<Icon icon={'menu'} />} />}
              {broken && (<div className="item-list_label-error">Sorry, we couldn't process this URL</div>)}
              {deleteButton}
            </div>
          </div>
          {editMode && errorMsgEdit}
        </td>
      </tr>
    );
  };

  render() {
    const {label, onAdd} = this.props;
    const {items = [], isListFetched, filterTerms = ''} = this.state;
    const addItem = onAdd ? this.renderAdd() : null;
    let itemFilter;
    let filteredItems;

    if(items) {
      itemFilter = (items.length > 20 || filterTerms !== '') ? this.renderFilter() : null;
      filteredItems = items
        .filter(item => !(filterTerms.length > 2 ? item.value.indexOf(filterTerms) < 0 : item.value.indexOf(filterTerms) !== 0))
        .map(item => this.renderItem(item));
    }

    if(items === null) {
      return (
        <div className="item-list_list">
          <p className="item-list-error">We couldn&apos;t get your {label}. 😢</p>
        </div>
      );
    }

    if(!isListFetched && !items.length) {
      return (
        <div className="item-list_list item-list_list--empty">
          <p className="help-block loading-dots">Loading</p>
        </div>
      );
    }

    if(filteredItems.length && items.length) {
      return (
        <div className="item-list_list">
          {itemFilter}
          <table className="table table-bordered table-fixed">
            <tbody className="table-grey-background">
              {filteredItems}
            </tbody>
          </table>
          {addItem}
        </div>
      );
    }

    if(!filteredItems.length && items.length) {
      return (
        <div className="item-list_list">
          {itemFilter}
          <p className="help-block"><em>No results</em> 😢</p>
          {addItem}
        </div>
      );
    }

    return (
      <div className="item-list_list item-list_list--empty">
        <p className="help-block"><em>Your {label} is empty</em></p>
        {addItem}
      </div>
    );
  }

}

export default ItemList;
