import {matchPath} from 'react-router';

// route components
import Dashboard from '../../components/_dashboard';
import Notifications from '../../components/_notifications';
import Feed from '../../components/_feed';
import Profile from '../../components/_profile';
import Battlecard from '../../components/_battlecard';
import Digest from '../../components/_digest';
import CompanySettings from '../../components/_company_settings';
import ManageUsers from '../../components/_manage_users';
import User from '../../components/_user';
import CardEmbed from '../../components/_card_embed';
import ProductRoadMap from '../../components/_product_board';

// global search routes
import SearchQuery from '../../components/_search_query.js';
import SearchAnswer from '../../components/_search_answer.js';

// onboarding route
import ExtensionOnboarding from '../../components/onboarding/_onboarding_extension';

/* eslint-disable no-multi-spaces */
const defaultRoutes = Object.freeze([
  {path: '/dashboard/edit',                                     component: Dashboard},
  {path: '/dashboard*',                                          component: Dashboard},
  {path: '/notifications',                                      component: Notifications},
  {path: '/feed/posts/:postId',                                 component: Feed},
  {path: '/feed',                                               component: Feed},
  {path: '/digest/overview',                                    component: Digest},
  {path: '/digest/archives',                                    component: Digest},
  {path: '/digest/:digestId',                                   component: Digest},
  {path: '/digest',                                             component: Digest},
  // NB: profile, card and battlecard routes are used when cloning profiles; any changes
  // to the route path format will also need corresponding changes in backend code.
  {path: '/profile/:profileId/battlecard/embed/:battlecardId',  component: Battlecard},
  {path: '/profile/:profileId/battlecard/embed',                component: Battlecard},
  {path: '/profile/:profileId/battlecard/view/:battlecardId',   component: Battlecard},
  {path: '/profile/:profileId/battlecard/view',                 component: Battlecard},
  {path: '/profile/:profileId/view/card/:cardId/view',          component: Profile},
  {path: '/card/embed/:cardId',                                 component: CardEmbed},
  {path: '/profile/:profileId/view/card/:cardId',               component: Profile},
  {path: '/profile/:profileId/view',                            component: Profile},
  {path: '/users/:userId',                                      component: User},
  {path: '/users',                                              component: ManageUsers},
  {path: '/settings/:pageSlug/:pageSection',                    component: CompanySettings},
  {path: '/settings/:pageSlug',                                 component: CompanySettings},
  {path: '/settings',                                           component: CompanySettings},
  {path: '/search/:query',                                      component: SearchQuery},
  {path: '/search',                                             component: SearchQuery},
  {path: '/answer/:id/:rival',                                  component: SearchAnswer},
  // onboarding:
  // - linked to from the extension (first time use)
  {path: '/guides/button',                                      component: ExtensionOnboarding},
  // Product portal
  {path: '/product_roadmap',                                    component: ProductRoadMap}
]);

const unpriveledgedRoutes = Object.freeze([]);

const priveledgedRoutes = Object.freeze([
  {path: '/profile/:profileId/battlecard/edit/new',             component: Profile},
  {path: '/profile/:profileId/battlecard/edit/:battlecardId',   component: Profile},
  {path: '/profile/:profileId/battlecard/edit',                 component: Profile},
  {path: '/profile/:profileId/edit/card/:cardId/edit',          component: Profile},
  {path: '/profile/:profileId/edit/card/new',                   component: Profile},
  {path: '/profile/:profileId/edit/card/:cardId',               component: Profile},
  {path: '/profile/:profileId/edit',                            component: Profile}
]);

const defaultRedirects = [
  {from: '/posts/:postId',                                      to: {pathname: '/feed/posts/:postId'}},
  {from: '/profile/:profileId/battlecard/:battlecardId',        to: {pathname: '/profile/:profileId/battlecard/view/:battlecardId'}},
  {from: '/profile/:profileId/battlecard',                      to: {pathname: '/profile/:profileId/battlecard/view'}}
];

const unpriveledgedRedirects = [
  {from: '/profile/:profileId/card/:cardId',                    to: {pathname: '/profile/:profileId/view/card/:cardId'}},
  {from: '/profile/:profileId',                                 to: {pathname: '/profile/:profileId/view'}},
  {from: '/profile/:profileId/edit',                            to: {pathname: '/profile/:profileId/view'}},
  {from: '/profile/:profileId/battlecard/edit',                 to: {pathname: '/profile/:profileId/view'}}
];

const priveledgedRedirects = [
  {from: '/profile/:profileId/card/:cardId',                    to: {pathname: '/profile/:profileId/edit/card/:cardId'}},
  {from: '/profile/:profileId',                                 to: {pathname: '/profile/:profileId/battlecard/edit'}}
];

export const routes = ({isCurator = false}) => Object.freeze([
  ...(isCurator ? priveledgedRoutes : unpriveledgedRoutes), ...defaultRoutes
].filter(Boolean));

export const redirects = ({isCurator = false, location = {}}) => {
  const {search, hash} = location;
  const insertSearch = defs => defs.map(def => ({...def, to: {...def.to, search, hash}}));

  return Object.freeze([
    ...(isCurator ? insertSearch(priveledgedRedirects) : insertSearch(unpriveledgedRedirects)), ...insertSearch(defaultRedirects)
  ].filter(Boolean));
};

export const redirectPaths = ({isCurator = false}) => Object.freeze([
  ...(isCurator
    ? priveledgedRedirects.map(pr => pr.from)
    : unpriveledgedRedirects.map(upr => upr.from)),
  ...defaultRedirects.map(dr => dr.from)
]);

export const pathIsRedirectPath = (path, isCurator) =>
  Boolean(redirectPaths({isCurator}).find(from => matchPath(path, {path: from, strict: false, exact: true})));

