
import {Map} from 'immutable';

import KlueFunctionLibrary from '../../modules/klue_function_library';
import FormulaEngine from '../../modules/formula_engine';

const klueLib = new KlueFunctionLibrary();
const kle = new FormulaEngine(klueLib);

const initialState = {
  craft: Map(),
  codeResults: Map()
};

const computeCodeHash = code => code.trim();

/**
 * @name Cards model
 *
 * @type Redux model
 * @todo This code logic has more than 4 years, probably needs optimization
 */
export default {
  state: initialState,
  reducers: {
    handleFailCode(state, payload) {
      const {codeHash: code, error} = payload;
      const codeResults = state.codeResults.set(code, {error});

      return {...state, codeResults};
    },
    handleResolveCode(state, payload) {
      const {codeHash: code, result} = payload;
      const codeResults = state.codeResults.set(code, {result});

      return {...state, codeResults};
    }

  },
  effects: () => ({
    async cardsExecuteCode(code, rootState) {
      const {cards: {codeResults}} = rootState;
      const codeHash = computeCodeHash(code);
      const res = codeResults.get(codeHash);

      if(res) {
        return;
      }

      new Promise(resolve => {
        // We need to execute the code if we don't have results yet
        resolve(kle.execute(code));
      }).then(
        result => {
          this.handleResolveCode({codeHash, result});
        },
        error => {
          console.warn('Error encountered while executing Klue Function.', code, error);

          this.handleFailCode({codeHash, error});
        }
      );
    }
  })
};

