import Comment from './_comment';

import {boardUpdate} from '../modules/api/boards';
import {isValidId, redirectToFeed} from '../modules/utils';
import {userCanDeleteComment} from '../modules/roles_utils';
import {profileGet} from '../modules/api/profiles';
import {containerTypes} from '../modules/constants/comments';
import {paragraphs} from '../modules/text_utils';
import {resizeExtension} from '../modules/ext_utils';
import {redirectToKlueUrl} from '../modules/post_utils';

class CommentList extends React.Component {

  static contextTypes = {
    onScratchpadRefresh: PropTypes.func,   // not required, conditionally used inside the app (not in extension)
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    containerType: PropTypes.oneOf(containerTypes),
    containerId: PropTypes.number,
    onboardingId: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    showNewestFirst: PropTypes.bool,
    showFeedLink: PropTypes.bool,
    favorites: PropTypes.arrayOf(PropTypes.object),
    rivals: PropTypes.object,
    pinnedComment: PropTypes.object,
    rivalMatches: PropTypes.arrayOf(PropTypes.object),
    canTakeScreenshot: PropTypes.bool,
    suggestion: PropTypes.string,
    onRivalClick: PropTypes.func,
    onRivalSelect: PropTypes.func,
    onRivalCreate: PropTypes.func,
    onCommentSubmit: PropTypes.func,
    onCommentUpdate: PropTypes.func,
    onCommentDelete: PropTypes.func,
    handlePinClick: PropTypes.func,
    onHighlightScroll: PropTypes.func,
    digestMode: PropTypes.bool,
    listStyle: PropTypes.object,
    extensionMode: PropTypes.bool,
    isMakingPinRequest: PropTypes.bool
  };

  static defaultProps = {
    containerType: 'post',
    containerId: 0,
    onboardingId: 0,
    data: [],
    user: null,
    users: {},
    showNewestFirst: false,
    showFeedLink: false,
    favorites: [],
    rivals: {},
    rivalMatches: [],
    canTakeScreenshot: false,
    suggestion: '',
    onRivalClick: null,
    onRivalSelect: null,
    onRivalCreate: null,
    onCommentSubmit() {},
    onCommentUpdate() {},
    onCommentDelete() {},
    onHighlightScroll() {},
    handlePinClick() {},
    pinnedComment: null,
    digestMode: false,
    listStyle: null,
    extensionMode: false,
    isMakingPinRequest: false
  };

  state = {
    selectedHighlightId: 0
  };

  componentDidMount() {
    console.log('CommentList.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    // load comment posters into users collection
    (this.props.data || []).forEach(({userId}) => this.context.utils.requestUser({userId}));

    klueMediator.subscribe('klue:commentlist:select', highlightId => {
      console.log('klue:commentlist:select(%o)', highlightId);

      const selectedComment = ReactDOM.findDOMNode(this.refs[`highlight-${highlightId}`]);

      if(selectedComment) {
        // scroll CommentBox to associated comment's position
        this.props.onHighlightScroll(selectedComment.offsetTop);

        // focus comment via highlight click
        this.setState({
          selectedHighlightId: highlightId
        });

        setTimeout(() => {
          this.setState({
            selectedHighlightId: 0
          });
        }, 800);
      }
    });

    resizeExtension();
  }

  UNSAFE_componentWillUpdate() {
    const node = ReactDOM.findDOMNode(this);

    this.shouldScrollBottom = node
      && this._shouldSortDescending()
      && (((node.scrollTop + node.offsetHeight) === node.scrollHeight) || this.props.extensionMode);
  }

  componentDidUpdate() {
    if(this.shouldScrollBottom) {
      this.triggerScroll();
    }

    resizeExtension();
  }

  componentWillUnmount() {
    klueMediator.remove('klue:commentlist:select');

    this._isMounted = false;
  }

  _highlightHasRanges = highlight => {
    if(!highlight || !highlight.ranges || !highlight.ranges.length) {
      return false;
    }

    for(let i = 0; i < highlight.ranges.length; i++) {
      const range = highlight.ranges[i];

      // ignore full-page highlights (no valid range to find on page)
      if(range.start !== '/html') {
        return true;
      }
    }

    return false;
  };

  _shouldSortDescending = () => this.props.showNewestFirst || (this.props.containerType === 'post');

  // return to calling window (feed) if present (triggers feed refresh)
  handleReturnToFeedLink = event => redirectToFeed(event, true, (event && event.target) ? event.target.href : '');

  handleHighlightClick = highlight => {
    const {id, url, postId} = highlight;
    const {extensionMode} = this.props;

    klueMediator.publish('klue:highlight:select', id);

    if(!extensionMode && url && postId) {
      redirectToKlueUrl(url, postId);
    }
  };

  handleRivalClick = (commentId, rivalOptions = {}) => {
    if(!isValidId(commentId)) {
      return;
    }

    const comment = this.props.data.find(c => c.id === commentId);
    const {profile: {scratchpadBoardId, id} = {}, profileId} = rivalOptions || {};

    // scratchpadBoardId = 0 will disassociate the rival from this comment
    if(!scratchpadBoardId && (rivalOptions !== null)) {
      return profileGet({profileId: profileId || id, version: 3}).then(profile => {
        this.toggleOnScratchpad(comment, {
          ...rivalOptions,
          profile
        });
      });
    }

    this.toggleOnScratchpad(comment, rivalOptions);
  };

  toggleOnScratchpad = (comment = null, rival = null) => {
    if(_.isEmpty(comment)) {
      return console.warn('CommentList.toggleOnScratchpad: invalid comment specified: comment: %o', comment);
    }

    const {id = 0, name = ''} = (rival || {});
    const {onRivalSelect, onCommentUpdate} = this.props;
    const {onScratchpadRefresh} = this.context;
    let {scratchpadBoardId = 0} = rival ? ((_.isEmpty(rival.profile) ? rival : rival.profile) || {}) : {};
    let comments = {};

    console.log('CommentList.toggleOnScratchpad: comment: %o, rival: %o, scratchpadBoardId: %o', comment, rival, scratchpadBoardId);

    // we only allow single board associations for now (scratchpad)
    if(scratchpadBoardId) {
      comments = {
        add: [comment.id]
      };
    }
    else if(comment.boards) {
      scratchpadBoardId = comment.boards[0].id;

      comments = {
        remove: [comment.id]
      };
    }

    const boardOptions = {
      id: scratchpadBoardId,
      comments
    };

    boardUpdate({boardOptions, code: 'CommentList.toggleOnScratchpad'})
      .then(board => {
        if(this._isMounted) {
          console.log('CommentList.toggleOnScratchpad: highlight updated, board: %o', board);

          if(id) {
            // update highlight fields in comment
            comment.boards = [{
              id: scratchpadBoardId,
              name: 'Scratchpad',
              rival: {id, name}
            }];

            // update potential rival matches for other highlights
            onRivalSelect && onRivalSelect(rival);
            onScratchpadRefresh && onScratchpadRefresh({profileId: rival.profile.id});
          }
          else {
            // remove board association
            comment.boards = [];

            onScratchpadRefresh && onScratchpadRefresh({boardId: scratchpadBoardId});
          }

          onCommentUpdate(comment);
        }
      })
      .catch(error => this._isMounted && console.error('CommentList.toggleOnScratchpad: error: %o', error));
  };

  handleDelete = commentId => this.props.onCommentDelete(commentId);

  triggerScroll = () => {
    const node = ReactDOM.findDOMNode(this);

    if(node) {
      node.scrollTop = node.scrollHeight;
    }
  };

  isFavorite = commentId => Boolean((this.props.favorites || []).find(f => f.commentId === commentId));

  render() {
    const {
      user, data, extensionMode, digestMode, showFeedLink, containerId, containerType, onboardingId, pinnedComment, handlePinClick,
      listStyle, suggestion, rivals, rivalMatches, canTakeScreenshot, onRivalCreate, onRivalSelect, onCommentSubmit, isMakingPinRequest
    } = this.props;
    let comments = [];
    let feedLinkRegion;
    let primeComment = {};

    if(showFeedLink) {
      // don't show the link if user is inside extension
      const feedLink = !extensionMode && (
        <a href="/" target="_parent" onClick={this.handleReturnToFeedLink}>Return to feed</a>
      );

      feedLinkRegion = (
        <li className="comment comment-prime no-comments">
          <div className="comment-body">
            <p>
              This post has been removed.<br />
              <br />
              {feedLink}
            </p>
          </div>
        </li>
      );
    }

    if(data.length) {
      const isContainerPost = containerType === 'post';
      // prime comment is not necessarily the first comment created
      // as is the case with the threads sent from private channels in Slack
      const primeIndex = isContainerPost ? data.findIndex(comment => comment.isPrimeComment) : 0;

      // save prime commentId in case prime comment is spliced out below (used for deleting entire post)
      primeComment = data[primeIndex];
      comments = isContainerPost ? data.slice(0, primeIndex).concat(data.slice(primeIndex + 1)) : data;
    }

    const commentNodes = comments.map((comment, index) => {
      let {id, userImage} = comment;
      const {userId, userName, title, bodyHtml, bodyPlain, attachments, boards,
        highlight, createdAt, lastEdit, pageUrl: url, sourceData, isSlackComment} = comment;
      const commentProps = {};
      let commentBody;
      let commentBodyHtml;
      const isPinnedComment = id === pinnedComment?.id;

      if(!_.isEmpty(highlight)) {
        const {body: highlightBody} = highlight;

        if(highlightBody) {
          commentBody = bodyPlain || highlightBody;
          commentBodyHtml = highlightBody ? `${paragraphs(`<blockquote>${bodyHtml || highlightBody}</blockquote>`)}` : bodyHtml;

          const {id: highlightId} = highlight;

          Object.assign(commentProps, {
            ref: `highlight-${highlightId}`,
            highlightId,
            highlightSelected: (this.state.selectedHighlightId === highlightId)
          });
        }
        else {
          // probably onboarding; optimistically apply highlight styles
          commentBody = bodyPlain || highlight.body;
          commentBodyHtml = bodyHtml || paragraphs(highlight.body);
          commentProps.highlightId = 999999; // ugly 😒
        }
      }
      else {
        commentBody = bodyPlain;
        commentBodyHtml = bodyHtml;
      }

      if((!commentBodyHtml || !commentBodyHtml.trim()) && (!commentBody || !commentBody.trim()) && !attachments.length) {
        return;
      }

      if(onboardingId && !isValidId(id)) {
        // onboarding, add map index to let React differentiate the keys
        id = `${onboardingId}${index}`;
      }

      // if an authorized user is talking to kluebot and his image data is not provided via the comment object - use user image passed from props
      if(!userImage && (userId === user.id)) {
        userImage = user.image;
      }

      return (
        <Comment
          key={`comment_${id}`}
          primeCommentId={primeComment.id}
          commentId={id}
          containerId={containerId}
          containerType={containerType}
          createdAt={createdAt}
          lastEdit={lastEdit}
          isSlackComment={isSlackComment}
          userId={userId}
          userName={userName}
          userImage={userImage}
          currentUser={user}
          canDelete={userCanDeleteComment({
            comment,
            loggedInUser: user,
            company: this.context.utils.company
          })}
          title={title}
          attachments={attachments}
          isFavorite={this.isFavorite(id)}
          onDelete={this.handleDelete}
          onCommentSubmit={onCommentSubmit}
          onHighlightClick={this._highlightHasRanges(highlight) ? () => this.handleHighlightClick(highlight) : null}
          bodyPlain={commentBody}
          bodyHtml={commentBodyHtml}
          sourceData={{url, ...(sourceData || {})}}
          suggestion={suggestion}
          boards={boards || []}
          rivals={rivals}
          rivalMatches={rivalMatches}
          onRivalClick={this.handleRivalClick}
          onRivalCreate={onRivalCreate}
          onRivalSelect={onRivalSelect}
          canTakeScreenshot={canTakeScreenshot}
          extensionMode={extensionMode}
          digestMode={digestMode}
          renderAssociated={!onboardingId}
          handlePinClick={handlePinClick}
          isPinnedComment={isPinnedComment}
          isMakingPinRequest={isMakingPinRequest}
          {...commentProps} />
      );
    });

    return commentNodes.length ? (
      <ul className="comment-list" style={listStyle}>
        {feedLinkRegion}
        {commentNodes}
      </ul>
    ) : null;
  }

}

export default CommentList;
