import {useState} from 'react';
import {cardAddTag, cardRemoveTag} from '../../modules/api/cards';
import AddButton from './_card_tags_add_button';
import CardTag from './_card_tag';
import {useTags} from '../../contexts/_tags';

function isHighlighted({id, name}, searchQuery, filteredTags) {
  return Boolean(filteredTags?.has(id) || searchQuery?.toLowerCase().includes(name));
}

const CardTags = ({
  card,
  editable,
  searchQuery,
  filteredTags,
  disableTags,
  onTagsUpdated,
  onTagsRefresh
}) => {
  const {userCanViewTags, userCanEditTags} = useTags();
  const {tags: cardTags = []} = card;
  const [tags, setTags] = useState(cardTags);
  const canEdit = editable && userCanEditTags;

  const handleAddTag = async tag => {
    if(onTagsUpdated) {
      const updatedTags = [...tags].concat([tag]);

      setTags(updatedTags);
      onTagsUpdated(updatedTags);
    }
    else {
      try {
        const {data: {tags: updatedTags}} = await cardAddTag(card, tag.id);

        setTags(updatedTags);
        onTagsRefresh && onTagsRefresh(updatedTags);
      }
      catch(error) {
        // todo Handle error
        console.error(error);
      }
    }
  };

  const handleRemoveTag = async tagId => {
    if(onTagsUpdated) {
      const remainingTags = tags.filter(t => t.id !== tagId);

      setTags(remainingTags);
      onTagsUpdated(remainingTags);
    }
    else {
      try {
        const {data: {tags: updatedTags}} = await cardRemoveTag(card, parseInt(tagId, 10));

        setTags(updatedTags);
        onTagsRefresh && onTagsRefresh(updatedTags);
      }
      catch(error) {
        // todo Handle error
        console.error(error);
      }
    }
  };

  if(!userCanViewTags || (!Object.keys(tags).length && !(editable && userCanEditTags))) {
    return null;
  }

  return (
    <div className="card-tags">
      {tags.map(({id: tagId, name: tagName}) => (
        <CardTag
          key={tagId}
          id={tagId}
          name={tagName}
          isHighlighted={!disableTags && isHighlighted({id: tagId, name: tagName.toLowerCase()}, searchQuery, filteredTags)}
          disabled={disableTags}
          {...canEdit ? {onRemoveTag: handleRemoveTag} : {}} />
      ))}
      {canEdit && <AddButton tags={tags} onAddTag={handleAddTag} />}
    </div>
  );
};

CardTags.contextTypes = {
  utils: PropTypes.shape({
    cardTagIsVisible: PropTypes.func.isRequired
  }).isRequired
};

CardTags.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  editable: PropTypes.bool,
  disableTags: PropTypes.bool,
  searchQuery: PropTypes.string,
  filteredTags: PropTypes.object,
  onTagsUpdated: PropTypes.func,
  onTagsRefresh: PropTypes.func
};

CardTags.defaultProps = {
  editable: false,
  disableTags: false,
  searchQuery: undefined,
  filteredTags: undefined,
  onTagsUpdated: undefined,
  onTagsRefresh: undefined
};

export default CardTags;
