import Icon from './_icon';

import {rivalsGet} from '../modules/api/rivals';
import {isValidUrl} from '../modules/url_utils';
import {availableSources} from '../modules/constants/company_settings';
import {extractValidSourceFromUrl, getUrlSourceName} from '../modules/company_settings_utils';
import {alertsReviewGet, alertsReviewCreate, alertsReviewDelete} from '../modules/api/settings';

import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import ReactUpdate from 'immutability-helper';

class CompanySettingsAlertsReviews extends React.PureComponent {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  state = {
    isLoading: true,
    hasError: false,
    errorMessage: '',
    reviewSources: {},
    reviewUrl: '',
    sourceValue: null,
    isInvalidSource: false,
    reviewUrlDuplicated: false,
    suggestions: [],
    boardName: '',
    boardId: ''
  };

  componentDidMount() {
    const {
      company: {
        companyData: {reviewDataEnabled = true}
      }
    } = this.context.utils;

    if(reviewDataEnabled) {
      this.loadReviews();
    }
  }

  _preventDefault = e => e && e.preventDefault();

  loadReviews = () => {
    alertsReviewGet()
      .then(result => {
        const sources = result.reduce((map, {rivals, url, is_invalid: isInvalid}) => {
          rivals.forEach(rival => {
            map[`${url}_${rival.id}`] = {url, rivalId: rival.id, rivalName: rival.name, isInvalid};
          });

          return map;
        }, {});
        // sort sources by [rivalName, url]
        const keys = Object.keys(sources).sort((a, b) => {
          const src1 = sources[a];
          const src2 = sources[b];
          let first = src1.rivalName || '';
          let second = src2.rivalName || '';

          if(first === second) {
            first = src1.url || '';
            second = src2.url || '';
          }

          return first.localeCompare(second);
        });
        const reviewSources = keys.reduce((obj, k) => {
          obj[k] = sources[k];

          return obj;
        }, {});

        this.setState({reviewSources, isLoading: false});
      })
      .catch(error => {
        this.setState({
          hasError: true,
          errorMessage: error
        });
      });
  };

  handleReviewURLInputChange = event => {
    this._preventDefault(event);

    const {
      target: {value}
    } = event;
    const currentSource = extractValidSourceFromUrl(value);

    this.setState(
      {
        reviewUrl: value,
        // Automatically select the source when the review URL changes
        sourceValue: currentSource || ''
      },
      this.validateReview
    );
  };

  handleBoardSelect = (event, {suggestion: {id, name}}) => {
    this._preventDefault(event);

    this.setState(
      {
        boardName: name,
        boardId: id,
        suggestions: []
      },
      this.validateReview
    );
  };

  validateReview = () => {
    const {reviewUrl, reviewSources, boardId, sourceValue} = this.state;

    const reviewUrlDuplicated = `${reviewUrl}_${boardId}` in reviewSources;

    this.setState({
      isInvalidSource: !sourceValue || !isValidUrl(reviewUrl),
      reviewUrlDuplicated
    });
  };

  handleNewReviewSource = event => {
    this._preventDefault(event);

    const {reviewSources, boardName, reviewUrl, boardId} = this.state;

    this.setState({
      reviewSources: {
        ...reviewSources,
        [`${reviewUrl}_${boardName}`]: {
          url: reviewUrl,
          rivalName: boardName,
          rivalId: boardId
        }
      },
      reviewUrl: '',
      sourceValue: null,
      isInvalidSource: false,
      reviewUrlDuplicated: false,
      suggestions: [],
      boardName: '',
      hasError: false,
      errorMessage: ''
    }, () =>
      alertsReviewCreate({url: reviewUrl, rivalId: boardId})
        .then(this.loadReviews)
        .catch(error => {
          this.setState({
            hasError: true,
            errorMessage: error
          });
        })
    );
  };

  handleRemoveReview = ({rivalId, url}) => {
    this.setState(prevState => ({
      reviewSources: ReactUpdate(prevState.reviewSources, {$unset: [`${url}_${rivalId}`]})
    }), () => {
      this.validateReview();

      alertsReviewDelete({rivalId, url})
        .then(this.loadReviews)
        .catch(error => {
          this.setState({
            hasError: true,
            errorMessage: error
          });
        });
    });
  };

  onSuggestionsClearRequest = () => this.setState({suggestions: []});

  onSuggestionsFetchRequested = _.debounce(args => this.getSuggestions(args.value), 400);

  getSuggestions = input => {
    if(!input) {
      return;
    }

    const escapedInput = encodeURIComponent(input.trim());
    const rivalOptions = {
      query: escapedInput,
      page: 1,
      limit: 20
    };

    rivalsGet({rivalOptions}).then(({rivals}) => {
      this.setState({suggestions: rivals});
    });
  };

  renderSuggestion = ({name}) => (
    <span className="suggestion-label">
      <strong>{name}</strong>
    </span>
  );

  getSuggestionValue = ({competitorName = ''} = {}) => competitorName;

  handleAutocompleteChange = (event, {newValue}) => {
    this._preventDefault(event);
    this.setState(prevState => ({
      boardName: newValue,
      suggestions: prevState.boardName.length && (!newValue || !newValue.length) ? [] : prevState.suggestions
    }));
  };

  render() {
    const {
      company: {
        companyData: {reviewDataEnabled = true}
      }
    } = this.context.utils;

    if(!reviewDataEnabled) {
      return null;
    }

    const {reviewSources, reviewUrl, isInvalidSource, reviewUrlDuplicated, sourceValue, boardName, suggestions, hasError, errorMessage} = this.state;
    const isFormValid = reviewUrl && sourceValue && boardName && !suggestions.length && !reviewUrlDuplicated && !isInvalidSource;
    const hasReviewSources = Boolean(Object.keys(reviewSources).length);

    return (
      <div className="company-settings-alert-reviews">
        <h4>
          <strong>Review sources</strong>
        </h4>
        {hasReviewSources ? (
          <div className="settings-alert-reviews-list">
            <div className="item-list_list">
              <table className="table table-bordered table-grey-background">
                <tbody>
                  {Object.keys(reviewSources).map(id => {
                    const item = reviewSources[id];

                    return (
                      <tr data-test-id="review-source-item" className={classNames('item-list_row', {'is-invalid': item.isInvalid})} key={id}>
                        <td>
                          <div className="item-list_cell">
                            <span className="board-name">{item.rivalName}</span>
                            <strong title="Edit" className="item-list_label">
                              {getUrlSourceName(item.url)}
                            </strong>
                            {item.isInvalid && <span data-test-id="review-source-error-message" className="settings-alert-reviews-list--error-msg">Sorry, we couldn't process the URL you entered.</span>}
                            <a title="Remove" data-test-id="review-source-item-delete" className="item-list-icon" onClick={() => this.handleRemoveReview(reviewSources[id])}>
                              <Icon icon="close" width="16" height="16" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p className="empty-message">
            <em>Your list is empty</em>
          </p>
        )}
        <div className="settings-alert-reviews-search">
          <div className="input-search-group">
            <input id="input-select-review" className="form-control" type="url" onChange={this.handleReviewURLInputChange} value={reviewUrl} placeholder="Paste Review URL..." />
            <button
              disabled={!isFormValid}
              className={classNames('button button--medium', {'button--disabled': !isFormValid})}
              onClick={this.handleNewReviewSource}>
              Add
            </button>
          </div>
          {hasError && errorMessage && <p className="error-msg">{errorMessage}</p>}
          {isInvalidSource && Boolean(reviewUrl.length) && (
            <p className="error-msg">
              <strong>Invalid source URL.</strong> Please enter a URL from one of the allowed sources below.
            </p>
          )}
          {reviewUrlDuplicated && <p className="error-msg">Review url already exists, try with a different board.</p>}
        </div>
        <div className="settings-alert-reviews-details">
          <div className="reviews-details-column sources-column">
            <label>Allowed Sources:</label>
            <ul className="allowed-sources-list">
              {Object.keys(availableSources).map((source, index) => {
                const sourceKey = `source_${index}`;

                return (
                  <li key={sourceKey} className={classNames('allowed-sources-list-item', {active: sourceValue === source})}>
                    {source}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="reviews-details-column board-column">
            <label>Board (Competitor Name):</label>
            <Autosuggest
              alwaysRenderSuggestions={true}
              getSuggestionValue={this.getSuggestionValue}
              inputProps={{
                placeholder: 'Which competitor is this review about?',
                value: boardName,
                onChange: this.handleAutocompleteChange,
                className: 'form-control google-search-autosuggest'
              }}
              onSuggestionsClearRequest={this.onSuggestionsClearRequest}
              onSuggestionSelected={this.handleBoardSelect}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              ref="companyAutoSuggest"
              renderSuggestion={this.renderSuggestion}
              suggestions={suggestions} />

            {!isInvalidSource && reviewUrl && !boardName && <p className="error-msg">Please select a board.</p>}
          </div>
        </div>
      </div>
    );
  }

}

export default CompanySettingsAlertsReviews;
