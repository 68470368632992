import styled, {css, keyframes} from 'styled-components';
import tokens from '@kluein/design-tokens';
import {desktopLayout, mediaQuery, tabletLayout} from './_base_styles';
import {breakpoints} from './_constants';

const typingAnimation = keyframes`
  from { max-width: 0; }
  to { max-width: 100%; }
`;

const blinkCursorAnimation = keyframes`
  50% {
    border-color: transparent;
  }
`;

export const QuestionBlock = styled.div`
  min-height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  gap: ${tokens.spacing.xxlarge};
`;

export const SurveyPage = styled.div`
  width: 100%;
  height: 100vh;
  padding: ${tokens.spacing.large};
  display: flex;
  direction: row;
  justify-content: center;
  align-items: center;
  gap: ${tokens.spacing.xxlarge};
  background-color: ${tokens.color.neutral[100]};
`;

export const SurveyPageContainer = styled.div`
  width: 100%;
  padding-top: ${tokens.spacing.large};
  padding-left: ${tokens.spacing.medium};
  padding-right: ${tokens.spacing.medium};
  padding-bottom: ${tokens.spacing.large};

  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacing.xxlarge};

  ${tabletLayout`
    padding-left: ${tokens.spacing.xlarge};
    padding-right: ${tokens.spacing.xlarge};
  `}

  ${desktopLayout`
    max-width: 880px;
    padding-left: 0;
    padding-right: 0;
  `}
`;

export const SurveyTitle = styled.h2`
  margin: 0px;
  color: ${tokens.color.neutral[1000]};
  display: inline-block;
  padding-right: ${tokens.spacing.xxsmall};
  border-right: ${tokens.borderWidth.medium} solid ${tokens.color.neutral.white.main};
  white-space: nowrap;
  overflow: hidden;
  width: fit-content;
  max-width: 100%;
  font-family: 'Barlow',system-ui,sans-serif;
  font-weight: ${tokens.fontWeight.medium};
  font-size: ${tokens.fontSize.headingFont.large};
  animation: ${typingAnimation} ${props => `${props.textLength * 0.25}s`} forwards,
             ${blinkCursorAnimation} 1s step-end infinite;
`;

export const SurveyHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacing.medium};
  margin-left: ${tokens.spacing.medium};
  margin-right: ${tokens.spacing.medium};
`;

export const SurveyForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacing.xsmall};
  flex-grow: 1;
  overflow: hidden;
`;

export const SurveyDescriptionContainer = styled.div`
  max-width: 480px;
`;

export const SurveyDescription = styled.span`
  font-size: ${tokens.fontSize.bodyFont.large};
  font-weight: ${tokens.fontWeight.regular};
  line-height: normal;
  letter-spacing: normal;
  color: ${tokens.color.neutral.black.main};
`;

const surveyQuestionAttributes = css`
  font-family: 'Barlow',system-ui,sans-serif;
  overflow-wrap: break-word;
  margin-top: 0em;
  max-width: unset;
`;

export const SurveyQuestionHeader = styled.span`
  ${surveyQuestionAttributes};
  color: ${tokens.color.neutral[1000]};
  margin-bottom: 0;
  font-size: 32px !important;
  line-height: 40px !important;
  font-weight: ${tokens.fontWeight.medium};

  ${tabletLayout`
    font-size: 24px !important;
    line-height: 28px !important;
  `}

  ${desktopLayout`
    font-size: 26px !important;
    line-height: 28px !important;
  `}
`;

export const SurveyQuestionDescription = styled(SurveyQuestionHeader)`
  color: ${tokens.color.neutral[1000]};
  font-size: 24px !important;
  line-height: 32px !important;
  font-weight: ${tokens.fontWeight.regular};

  ${tabletLayout`
    font-size: 20px !important;
    line-height: 28px !important;
  `}

  ${desktopLayout`
    font-size: 24px !important;
    line-height: 32px !important;
  `}
`;

export const RespondedSurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const SurveyQuestionInput = styled.textarea`
  width: 100%;
  margin: 0;
  border: 0;
  border-bottom: ${tokens.borderWidth.small} solid ${tokens.color.neutral[600]};
  background-color: transparent;
  color: ${tokens.color.neutral[1000]};
  font-family: 'Barlow',system-ui,sans-serif;

  min-height: 44px !important;
  height: auto;
  outline: none !important;
  overflow: hidden;
  resize: none;
  padding: 0 !important;
  font-weight: ${tokens.fontWeight.semibold};

  &::placeholder {
    color: ${tokens.color.neutral[600]};
  }

  &:active,
  &:focus {
    border-bottom-color: ${tokens.color.neutral[1000]};
  }

  ${mediaQuery(breakpoints.tablet.min)`
    font-size: 28px !important;
    line-height: 30px !important;
  `}
`;

export const SurveyQuestionInputHelperText = styled.div`
  font-size: ${tokens.fontSize.bodyFont.xsmall};
`;

export const ProgressButtonContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.spacing.small};
`;

function getProgressButtonColor(props) {
  if(props.isSubmit) {
    if(props.disabled && !props.isLoading) {
      return tokens.color.status.disabled.main;
    }
  }

  return tokens.color.primitives.purple[700];
}

export const ProgressButton = styled.button`
  cursor: pointer;
  background-color: ${props => getProgressButtonColor(props)};
  color: ${tokens.color.neutral.white.main};
  padding: ${props => (props.isSubmit && props.isLoading ? '0' : '10px 20px')};
  font-size: ${tokens.fontSize.bodyFont.large};
  font-weight: ${tokens.fontWeight.bold};
  border: none;
  border-radius: ${tokens.borderRadius.medium};
  transition: transform 0.25s, box-shadow 0.25s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0);

  &:hover {
    background-color: ${tokens.color.primitives.purple[800]};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      background-color: ${ tokens.color.status.disabled.main};
      transform: none;
      box-shadow: none;
    }
  }
`;
