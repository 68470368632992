import sanitizeHtml from 'sanitize-html';
import linkifyHtml from 'linkifyjs/html';
import {truncate as htmlTruncate, paragraphs as paras} from '../modules/text_utils';

import {cleanupHtmlString} from './text_utils';
import {isValidUrl} from './url_utils';

export const commonEntities = Object.freeze({
  '&amp;': '&',
  '&apos;': '\'',
  '&copy;': '©',
  '&gt;': '>',
  '&lt;': '<',
  '&nbsp;': ' ',
  '&quot;': '"',
  '&reg;': '®',
  '&trade;': '™'
});

export const commonEntitiesRegExp = new RegExp(`(${Object.keys(commonEntities).join('|')})`, 'gi');

export const allowlistedTags = Object.freeze(['a', 'abbr', 'address', 'article', 'aside', 'b', 'bdi', 'bdo', 'blockquote', 'br', 'caption', 'cite', 'code',
  'data', 'del', 'details', 'dfn', 'div', 'dl', 'em', 'fieldset', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr',
  'i', 'img', 'ins', 'kbd', 'label', 'li', 'main', 'map', 'mark', 'math', 'meter', 'nav', 'nl', 'noscript', 'ol', 'output', 'p', 'picture', 'pre', 'progress',
  'q', 'ruby', 's', 'samp', 'section', 'small', 'span', 'strike', 'strong', 'sub', 'sup', 'svg', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'time', 'tr',
  'u', 'ul', 'var', 'video', 'wbr']);

export const allowedDigestTags = Object.freeze(['b', 'i', 'em', 'strong', 'u', 'a', 'img', 'br', 'p', 'div',
  'span', 'ol', 'ul', 'li', 's', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']);

export const disallowedDigestTags = Object.freeze(allowlistedTags.filter(tag => !allowedDigestTags.includes(tag)).concat(['iframe']));

export const allowlistedAttributes = Object.freeze({
  '*': Object.freeze(['aria-*', 'class', 'data-*', 'id', 'style']),
  a: Object.freeze(['download', 'href', 'hreflang', 'rel', 'target', 'title']),
  img: Object.freeze(['align', 'alt', 'height', 'loading', 'src', 'srcset', 'title', 'width']),
  ol: Object.freeze(['reverse', 'start', 'type']),
  td: Object.freeze(['align', 'colspan', 'height', 'rowspan', 'valign', 'width']),
  th: Object.freeze(['align', 'colspan', 'height', 'rowspan', 'valign', 'width'])
});

export const allowlistedSchemes = Object.freeze(['data', 'ftp', 'http', 'https', 'mailto']);

export const blockElements = Object.freeze([
  'address', 'article', 'aside', 'blockquote', 'dd', 'details', 'dialog', 'div', 'dl', 'dt',
  'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
  'header', 'hgroup', 'hr', 'li', 'main', 'nav', 'ol', 'p', 'pre', 'section', 'table', 'ul']);

export const selfClosingElements = Object.freeze([
  'area', 'base', 'br', 'col', 'embed', 'hr', 'img',
  'input', 'link', 'meta', 'param', 'source', 'track', 'wbr'
]);

/**
 * Wrapper for `sanitize-html` NPM pkg. See: https://github.com/apostrophecms/sanitize-html#readme
 *
 * @param {string} html - Input string.
 * @param {object} [options] - Options object.
 * @param {Array} [options.allowedTags=allowlistedTags] - Array of allowed tag names (String).
 * @param {object} [options.allowedAttributes=allowlistedAttributes] - Hash of allowed attributes.
 * @param {Array} [options.allowedSchemes=allowlistedSchemes] - Array of allowed schemas.
 * @returns {string} - Sanitized HTML string.
 *
 */
export const sanitizeInput = (html, options = {}) => (html ? sanitizeHtml(html, {
  allowedTags: allowlistedTags,
  allowedAttributes: allowlistedAttributes,
  allowedSchemes: allowlistedSchemes,
  ...options
}) : '');

export const isNode = maybeNode => (maybeNode instanceof Node || maybeNode instanceof NodeList);

export const isTextNode = node => (node.nodeType === Node.TEXT_NODE);

export const isElementNode = node => (node.nodeType === Node.ELEMENT_NODE);

export const isBlockElement = node => (isElementNode(node) && blockElements.includes(node.nodeName.toLowerCase()));

export const isSelfClosingElement = node => (isElementNode(node) && selfClosingElements.includes(node.nodeName.toLowerCase()));

export const isEmptyTextNode = node => (isTextNode(node) && (/^\s*$/g).test(node.nodeValue || ''));

export const isEmptyElementNode = (node, treatSelfClosingAsNonEmpty = true, treatWhitespaceAsEmpty = true) => {
  const {childNodes = []} = node;

  if(!isElementNode(node) || (treatSelfClosingAsNonEmpty && isSelfClosingElement(node))) {
    return false;
  }

  const childNodesFiltered = treatWhitespaceAsEmpty
    ? [...childNodes].filter(n => !isEmptyTextNode(n))
    : childNodes;

  return Boolean(childNodesFiltered.length === 0);
};

/**
 * Strips empty (non-self-closing) HTML elements (e.g., `<div></div><div>foo</div><img src="foo.jpg" />` -> `<div>foo</div><img src="foo.jpg" />`)
 *
 * @param {(Array|NodeList)}  nodes - Array or NodeList of HTML elements.
 * @returns {Array} - Array of HTML elements.
 */
export const stripEmptyElementNodes = nodes => {
  if((nodes || []).length) {
    const frag = new DocumentFragment();

    frag.append(...nodes);

    const els = frag.querySelectorAll(`*:empty${selfClosingElements.map(el => (`:not(${el})`)).join('')}`);

    els.forEach(n => {
      const {parentNode} = n;

      parentNode.removeChild(n);
    });

    return [...frag.childNodes];
  }

  return [...nodes];
};

/**
 * Strips extra/dupped HTML elements (e.g., `<div><div>foo</div></div>` -> `<div>foo</div>`)
 *
 * @param {(Array|NodeList)}  nodes - Array or NodeList of HTML elements.
 * @returns {Array} - Array of HTML elements.
 */
export const stripRedundantElementNodes = nodes => {
  if((nodes || []).length) {
    const frag = new DocumentFragment();

    frag.append(...nodes);

    const _processChildNodes = childNodes => {
      childNodes.forEach(childNode => {
        let {nodeName} = childNode;

        nodeName = nodeName.toLowerCase();

        const els = frag.querySelectorAll(`${nodeName} > ${nodeName}:first-child:last-child`);

        els.forEach(node => {
          const childs = node.cloneNode(true).childNodes;
          const {parentNode} = node;

          if(parentNode) {
            parentNode.removeChild(node);
            parentNode.append(...childs);

            _processChildNodes(parentNode.childNodes);
          }
        });
      });
    };

    _processChildNodes(frag.childNodes);

    return [...stripEmptyElementNodes(frag.childNodes)];
  }

  return [...nodes];
};

export const stripHtml = (str = '') => sanitizeInput(str, {
  allowedTags: [],
  allowedAttributes: [],
  allowedSchemes: []
});

/**
 * Decodes (allowlisted) HTML entities (`&copy;` => `©`)
 * See `commonEntities` declaration
 *
 * @param {string} text - Input string.
 * @returns {string} - Decoded string.
 *
 */
export const decodeCommonEntities = text => (text || '').replace(commonEntitiesRegExp, (...matchArgs) => commonEntities[matchArgs[1].toLowerCase()]);

/**
 * Converts new lines in Strings to `<br />`
 *
 * @param {string}  str - String to process/convert.
 * @param {boolean} [doubleSpace=false] - If `true`, new lines are replaced with `<br /><br />` (vs `<br />`)
 * @returns {string} - Processed string.
 */
export const nl2br = (str = '', doubleSpace = false) => {
  const br = doubleSpace ? '<br /><br />' : '<br />';

  return String(str).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/gm, `$1${br}$2`);
};

const _parseURL = urlInput => {
  const SCHEME = /^[a-z\d+-.]+?:(\/\/)?/i;
  const HOST = /^[^/#:?\s]*/;
  const PORT = /^:\d*/;
  const PATH = /^\/[^?#,\s]*/;
  const QUERY = /^\?[^#\s]*/;
  const FRAGMENT = /^#[^\s]*/;

  let input = urlInput;

  const getSegment = pattern => {
    const gotten = input.match(pattern);

    if(gotten?.length) {
      input = input.slice(gotten[0].length);

      return gotten[0];
    }

    return '';
  };

  const info = {};

  info.scheme = getSegment(SCHEME);
  info.host = getSegment(HOST);

  const hostMatch = info.host.match(/.\../);

  if(!hostMatch || (hostMatch && !hostMatch[0].length)) {
    return {isInvalid: true};
  }

  info.host = info.host.replace(/\)$/, '');
  info.port = getSegment(PORT);
  info.path = getSegment(PATH);
  info.query = getSegment(QUERY);
  info.fragment = getSegment(FRAGMENT);

  info.url = Object.values(info).join('');

  return info;
};

function chunkStringForMarkdownLinks(str) {
  const chunks = [];
  let openBrackets = 0;
  let chunk = '';
  let rest = str;

  while(rest.includes('[')) {
    const nextBracket = rest.indexOf('[');

    chunks.push(rest.slice(0, nextBracket));
    rest = rest.slice(nextBracket);

    for(let i = 0; i < rest.length; i++) {
      const char = rest[i];
      const next = i + 1;

      chunk += char;

      if(char === '[') {
        openBrackets++;
      }
      else if(char === ']') {
        openBrackets--;

        if(!openBrackets && rest[next] !== '(') {
          chunks.push(chunk);
          chunk = '';
          rest = rest.slice(next);
          i = -1;
        }
      }
      else if(char === ')' && !openBrackets) {
        chunks.push(chunk);
        chunk = '';
        rest = rest.slice(next);
        i = -1;
      }
    }

    if(chunk.length === rest.length) {
      chunks.push(chunk);
      chunk = '';
      rest = '';
    }
  }

  if(rest) {
    chunks.push(rest);
  }

  return chunks;
}

const _processMarkdownLinks = toProcess => {
  const MARKDOWN_LINK_PATTERN = /\[((?:[^[]|\[.*?\])*?)\]\s*\(\s*(\S+?)\s*\)/g;

  const chunks = chunkStringForMarkdownLinks(toProcess);

  const processedText = chunks.reduce((acc, chunk) => {
    return acc + chunk.replace(MARKDOWN_LINK_PATTERN, (match, text, url) => {
      const urlInfo = _parseURL(url);

      if(urlInfo.isInvalid) {
        return match;
      }

      return `<a href="${urlInfo.url}" target="_blank" rel="noopener noreferrer">${text || urlInfo.host}</a>`;
    });
  }, '');

  return processedText;
};

/**
 * Links URL's, mailto's, and `@mentions` in text content
 *
 * @param {string} toProcess input content
 * @param {object} options config params
 * @param {boolean} [options.processAtMentions=false] - Flag for processing `@mentions`.
 * @returns {string} output with converted links
 */
export const processLinks = (toProcess, options = {}) => {
  if(!toProcess) {
    return toProcess;
  }

  const {processAtMentions = false} = options;

  const processHyperlinks = (str, label, href) => (href.startsWith('/') ?
    `<a href="${href}">${label.trim()}</a>`
    : `<a href="${href}" target="_blank" rel="noopener noreferrer">${label.trim()}</a>`);

  //replace @user by <a href="users/user">@user</a>
  const replaceUserMention = user =>
    processHyperlinks(_, user, `/users/${user.replace('@', '')}`);

  let normalized = toProcess
    // normalize whitespace
    .replace(/\t/g, ' ')
    .replace(/&gt;/gmi, '>')
    .replace(/&lt;/gmi, '<');
    // convert markdown hyperlinks. [label](http://)

  normalized = _processMarkdownLinks(normalized)
    // convert legacy hyperlink format. label(http://)
    .replace(/\b([-.\w]{1,180}?)\s*\((https?:\/\/[^)\s]+)\s*\)/gi, processHyperlinks)
    // convert mailto links
    .replace(/<mailto:(.+?)>/gi, '$1')
    // convert emails
    .replace(/\b(\w[-\w.]{0,63}?)@([-\w]{1,63}(?:\.[-\w]{1,63}){1,4})\b/gi, '<a href="mailto:$1@$2" target="_blank" rel="noopener noreferrer">$1@$2</a>');

  const linkifyOptions = {
    className: null,
    attributes: {
      rel: 'noopener noreferrer'
    },
    validate: {
      url: value => isValidUrl(value),
      email: false
    },
    format(match) {
      return isValidUrl(match) ? new URL(match).hostname : match;
    }
  };

  try {
    if(!processAtMentions) {
      return linkifyHtml(normalized, linkifyOptions);
    }

    //find all @user patterns
    return linkifyHtml(
      normalized.replace(/\B\@([\w\-]+)/gim, replaceUserMention),
      linkifyOptions
    );
  }
  catch(e) {
    console.warn('textUtils:paragraphs:linkifyHtml is not able to linify: error - #%o', e);

    return normalized;
  }
};

/**
 * Links URL's, mailto's, and `@mentions` in text content
 * Truncates HTML|text
 *
 * @param {(string|NodeList)} toTruncate - String or NodeList to truncate.
 * @param {object}            [options] - Options object.
 * @param {number}            [options.limit=0] - Character limit/truncation point.
 * @param {number}            [options.buffer=0] - A (non zero) value enables "fuzzy" limits/avoids truncating text that is only slightly beyond the limit.
 * @param {boolean}           [options.stripTags=false] - If `true` all HTML tags are stripped from the output.
 * @param {boolean}           [options.useWordBoundary=false] - If `true`, truncation limits are "fuzzy" to avoid word-breaks.
 * @returns {(string|NodeList)} - String or NodeList with links
 */
export const truncate = (toTruncate, options = {}) => {
  const isDomNodes = isNode(toTruncate);
  const {
    stripTags = false,
    limit = 0,
    useWordBoundary = false,
    buffer = 0
  } = options;

  if(!limit) {
    return toTruncate;
  }

  const fragment = new DOMParser().parseFromString(isDomNodes
    ? Array.from(toTruncate).map(n => (n.nodeValue || n.outerHTML)).join('')
    : toTruncate, 'text/html');
  const rootNode = fragment.body;
  const allText = rootNode.textContent;

  if(cleanupHtmlString(allText).length <= (limit + buffer)) {
    return stripTags
      ? allText
      : !isDomNodes
        ? cleanupHtmlString(toTruncate)
        : toTruncate;
  }

  const _getChildTextNodes = node => {
    const textNodes = [];

    node.childNodes.forEach(child => {
      const {nodeType, nodeValue, nextSibling} = child;

      if(nodeType === Node.ELEMENT_NODE) {
        const childNodes = _getChildTextNodes(child);

        textNodes.push(...childNodes);
      }

      if((nodeType === Node.TEXT_NODE) && !/^\s*$/.test(nodeValue)) {
        if(nextSibling && (nextSibling.nodeType === Node.TEXT_NODE) && !/^\s*$/.test(nodeValue)) {
          nextSibling.nodeValue = nodeValue + nextSibling.nodeValue;
        }
        else {
          textNodes.push(child);
        }
      }
    });

    return textNodes;
  };

  const childTextNodes = _getChildTextNodes(rootNode);
  const textNodes = (childTextNodes || []);
  const textNodesCount = textNodes.length;
  const range = fragment.createRange();
  let limitRemaining = limit;
  let out;

  range.setStart(textNodes[0] || fragment.firstChild, 0);

  for(let i = 0; i < textNodesCount; i++) {
    const textNode = textNodes[i];
    const {length} = textNode;
    const _process = ({truncationIndex = 0}) => {
      const clone = (textNode.cloneNode().nodeValue || '').replace(/(\s){2,}/g, '$1');
      const charAtTruncationPoint = clone.charAt(Math.max(truncationIndex - 1, 0));
      const isWordBoundary = /\W/.test(charAtTruncationPoint);
      const nextWordBoundaryIndex = [...clone.substring(truncationIndex)].findIndex(c => /\W/.test(c));
      const charsToNextBoundary = (useWordBoundary && !isWordBoundary)
        ? Math.max(nextWordBoundaryIndex, 0)
        : 0;
      const truncated = clone.substring(0, truncationIndex + charsToNextBoundary);

      textNode.nodeValue = truncated.replace(/((?:\b\W+$)|\b$)/, '…'); // replace any trailing chars that will 'clash' with '…'
      range.setEnd(textNode, textNode.nodeValue.length);

      const newNode = fragment.createElement('div');
      const {commonAncestorContainer} = range;

      let contents;

      if((commonAncestorContainer.nodeType === Node.TEXT_NODE) && (commonAncestorContainer.parentElement.nodeName.toLowerCase() !== 'body')) {
        const tmpNode = commonAncestorContainer.parentElement.cloneNode();

        tmpNode.childNodes.forEach(n => n.remove());
        tmpNode.append(range.extractContents());
        contents = tmpNode;
      }
      else {
        contents = range.extractContents();
      }

      newNode.append(contents);

      return newNode.childNodes || contents;
    };

    if((limitRemaining - length) > 0) {
      if(i === textNodesCount - 1) {
        out = _process({truncationIndex: length});
        break;
      }

      limitRemaining = limitRemaining - length;
      continue;
    }
    else {
      limitRemaining = (((length - limitRemaining) <= buffer) && (i === textNodesCount - 1))
        ? length
        : limitRemaining;
      out = _process({truncationIndex: limitRemaining});
      break;
    }
  }

  if(!isDomNodes) {
    out = (
      Array.from(out).map(n => (stripTags ? n.textContent : n.outerHTML) || n.nodeValue).join('')
    );
  }

  return out;
};

/**
 * Adds/creates `<p>paragraphs</p>` from text content
 *
 * @param {(string|NodeList)} toParagraphs - String or NodeList to process.
 * @param {object}            [options] - Options object.
 * @param {boolean}           [options.returnNodes=false] - If `true` (the default if `toParagraphs` is a `NodeList`), an HTML `NodeList` will be returned.
 * @param {boolean}           [options.stripEmptyTags=true] - If `false`, empty tags (e.g., `<p></p>`) will be retained.
 * @param {boolean}           [options.forceValidHtml=false] - When `true`, attempt to "fix" some invalid markup (e.g., wrap `<li>` with `<ul>`).
 * @returns {(string|NodeList)} - String or NodeList with links
 */
export const paragraphs = (toParagraphs, options = {}) => {
  if(!toParagraphs) {
    return;
  }

  const isDomNodes = isNode(toParagraphs);

  const {returnNodes = isDomNodes, stripEmptyTags = true, forceValidHtml = false} = options;
  let parsedChildren = toParagraphs;

  if(!isDomNodes) {
    const filteredString = toParagraphs
      .replace(/\r/g, '\n') // LF/CR to new line
      .replace(/<(\/?)([\w\W][^>\s]*)([^>]*)>/gi, // <illegal> -> [illegal]
        (match, p1, p2, p3) => (allowlistedTags.includes(p2) ? match : `[${p1}${p2}${p3}]`));

    parsedChildren = new DOMParser().parseFromString(filteredString, 'text/html').body.childNodes;
  }

  if(parsedChildren.length === 0) {
    return toParagraphs;
  }

  const handleTextNode = node => {
    if(!isTextNode(node)) {
      console.warn(`textUtils:paragraphs:handleElementNode received non-element node: ${node}`);

      return [node];
    }

    const nodeValueCleaned = node.nodeValue.replace(/([ \t\r]){2,}/g, '$1');

    return (nodeValueCleaned.split(/[\n\r]\s*[\n\r]/) || [])
      .map(p => {
        if(!p.trim()) {
          return null;
        }

        const pTag = document.createElement('p');
        const lines = p.split(/\s*\n\s*/);

        lines.forEach((line, idx) => {
          pTag.appendChild(document.createTextNode(line));

          if(idx < lines.length - 1) {
            pTag.appendChild(document.createElement('br'));
            pTag.appendChild(document.createTextNode('\n'));
          }
        });

        return pTag;
      });
  };

  const handleElementNode = node => {
    if(!isElementNode(node)) {
      console.warn(`textUtils:paragraphs:handleElementNode received non-element node: ${node}`);

      return [node];
    }

    const {nodeName: NODENAME, childNodes} = node || {};
    const nodeName = NODENAME.toLowerCase();
    const isParagraph = n => Boolean(n && ((n.nodeName || '').toLowerCase() === 'p'));

    let p = isParagraph(node) ? node : document.createElement('p');

    if(isEmptyElementNode(node) && stripEmptyTags) {
      return [];
    }

    if(selfClosingElements.includes(nodeName)) {
      return [node];
    }

    if(forceValidHtml) {
      if(nodeName === 'li') {
        p = document.createElement('ul');
      }

      if(nodeName === 'dt') {
        p = document.createElement('dl');
      }
    }

    if((childNodes || []).length && isBlockElement(node)) {
      if((childNodes.length === 1) && !isBlockElement(childNodes[0])) {
        return [node];
      }

      const processedChildren = processNodes(childNodes);

      if(processedChildren.length) {
        const hasParagraphs = [...processedChildren].map(c => (c && (c.nodeName || '').toLowerCase())).includes('p');

        if(hasParagraphs) {
          if(!isParagraph(node)) {
            const clone = node.cloneNode();

            clone.append(...processedChildren);

            return [clone];
          }

          return processedChildren;
        }
      }
    }

    p.append(node);

    return [p];
  };

  // Named function here for hoisting/recursive use in `handleElementNode`
  function processNodes(nodes) {
    if((nodes || []).length === 0) {
      return nodes;
    }

    const allNodes = stripEmptyTags ? stripRedundantElementNodes(stripEmptyElementNodes(nodes)) : [...nodes];
    const frag = document.createDocumentFragment();
    let processed = [];
    let childs = [];
    let tmpNode;

    allNodes.forEach(node => {
      const {nodeValue} = node;
      const _processNode = () => {
        if(isTextNode(node)) {
          const childNodes = handleTextNode(node);
          const childNodesCount = (childNodes || []).length;

          if(childNodesCount) {
            childs = [...childs, ...childNodes].filter(Boolean);
            tmpNode = childNodes[childNodesCount - 1];
          }
        }
        else if(isElementNode(node)) {
          if(isBlockElement(node)) {
            (node.childNodes || []).forEach(n => (isEmptyTextNode(n) && n.remove()));
            childs = [...childs, node].filter(Boolean);
            tmpNode = null;
          }
          else {
            const childNodes = handleElementNode(node);
            const childNodesCount = (childNodes || []).length;

            if(childNodesCount) {
              childs = [...childs, ...childNodes].filter(Boolean);
              tmpNode = childNodes[childNodesCount - 1];
            }
          }
        }
      };

      if(tmpNode) {
        if(isBlockElement(node)) {
          childs = [...childs, tmpNode, node];
          tmpNode = null;
        }
        else {
          if(isElementNode(node)) {
            tmpNode.appendChild(node);
          }

          if(isTextNode(node)) {
            const parts = nodeValue.split(/\n\s*\n/g);
            const txt = parts.shift();

            tmpNode.appendChild(document.createTextNode(txt));

            parts.forEach(pText => {
              if((pText || '').trim()) {
                const childNodes = handleTextNode(document.createTextNode(pText));
                const childNodesCount = (childNodes || []).length;

                if(childNodesCount) {
                  childs = [...childs, ...childNodes];
                  tmpNode = childNodes[childNodesCount - 1];
                }
              }
            });
          }
        }
      }
      else {
        _processNode();
      }
    });

    [...(childs || [])].forEach(node => (node && frag.append(node)));
    processed = frag.childNodes;

    return processed;
  }

  const processed = processNodes(parsedChildren);

  return returnNodes
    ? processed
    : processed && [...processed].map(n => n.nodeValue || n.outerHTML).join('\n\n');
};

/**
 * Focuses the given input element and sets the cursor to the end.
 * If inputElement is not an instance of HTMLInputElement, it does nothing.
 *
 * @param {HTMLInputElement} inputElement - HTML `<input>` element.
 *
 */
export const setInputCursorToEnd = inputElement => {
  if(!(inputElement instanceof HTMLInputElement)) {
    return;
  }

  inputElement.focus();

  const {value} = inputElement;

  inputElement.value = '';
  inputElement.value = value;
};

/**
 * Helper for `dangerouslySetInnerHtml`
 *
 * @param {string} input - Input string.
 * @param {boolean} [sanitize=true] - CAUTION: If `false`, input sanitization is supressed.
 * @returns {object} - `{__html: String}`
 *
 */
export const wrapHtml = (input, sanitize = true) => {
  let str = input || '';

  if(sanitize) {
    str = sanitizeInput(str);
  }

  return {__html: str};
};

const cleanup = s => sanitizeInput(s, {
  allowedTags: allowlistedTags.filter(t => (![
    'img', 'figure', 'svg', 'picture', 'object', 'embed',
    'form', 'button', 'datalist', 'fieldset', 'input', 'keygen',
    'label', 'legend', 'meter', 'optgroup', 'option', 'output',
    'progress', 'select', 'textarea', 'noscript', 'script'].includes(t))), // strip a few things
  exclusiveFilter: ({tag, text}) => (['p', 'div', 'span', 'li', 'a'].includes(tag) && !text.trim()), // strip empty tags TODO: add others?
  nonTextTags: ['img', 'figure', 'svg', 'picture', 'object', 'embed',
    'form', 'button', 'datalist', 'fieldset', 'input', 'keygen',
    'label', 'legend', 'meter', 'optgroup', 'option', 'output',
    'progress', 'select', 'textarea', 'noscript', 'script']
}).trim()
  .replace(/^[ \t\r]+|[ \t\r]+$/gm, '')
  .replace(/([ \t\r]*\B\n){2,}/gm, '\n\n')
  .split('\n\n')
  .map(str => str.trim().replace(/\n/g, ' '))
  .join('\n\n');

const truncateBodyHtml = ({toTruncate, limit, buffer, isExpanded}) => {
  const {body} = new DOMParser().parseFromString(toTruncate, 'text/html');
  const html = body.innerHTML;

  return {
    html: paras(isExpanded ? html : htmlTruncate(html,
      {
        isHtml: true,
        useWordBoundary: true,
        limit,
        buffer
      })),
    bodyText: body.textContent || ''};
};

export const cleanedAndMaybeTruncated = ({dirtyHtml, limit, buffer, isExpanded, processAtMentions = false}) => {
  const {html, bodyText} = truncateBodyHtml({toTruncate: cleanup(dirtyHtml), limit, buffer, isExpanded});

  return {
    html: decodeCommonEntities(processLinks(html, {processAtMentions})),
    truncatable: bodyText.length > (limit + buffer)};
};

export const generateEditorIframeCode = iframeElement => {
  // Check if iframe has `height` or `minHeight` property in its `style` attribute
  const hasHeightOverride = iframeElement.style.height.includes('px') || iframeElement.style.minHeight.includes('px');
  const heightOverrideClass = hasHeightOverride ? 'klue-embed--height-override ' : '';

  // Don't modify the whitespace in the return value, it will render additional unwanted markup.
  // eslint-disable-next-line max-len
  return `<span class="${heightOverrideClass}klue-embed klue-content--fullwidth fr-deletable" contenteditable="false">${iframeElement.outerHTML}</span>`;
};
