import {Record, Map, List} from 'immutable';

import Types from '../../types';
import * as KlueTypes from '../../klue_types';

import {jobPostings} from './job_postings';

const City = new Record({
  city: '',
  state: '',
  country: ''
});

export function jobPostingsByRegion(company) {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);
    throw new Error('Parameter was unexpected type.');
  }

  return jobPostings(company).then(postings => {
    const postingsByCity = postings.reduce((acc, curPosting) => {
      // Get the City information for the listing
      const {city, state, country} = curPosting.city;
      const region = City({
        city,
        state,
        country
      });

      // Add a new List for this city if one doesn't exist yet
      const withCity = acc.has(region) ? acc : acc.set(region, List());

      // Add the new posting to the list of postings for the city
      return withCity.set(region, withCity.get(region).push(curPosting));
    }, Map());

    // Convert Map to an Array of {key, value} object pairs with a KlueTypes.City as key.
    const pairs = [];

    postingsByCity.forEach((value, key) => pairs.push({
      key: KlueTypes.City.create({city: key.city, state: key.state, country: key.country}),
      value: Types.arrayOf(KlueTypes.JobPosting).create(value.toArray())
    }));

    // Create the output map
    return Types.mapOf(KlueTypes.City, Types.arrayOf(KlueTypes.JobPosting)).create(pairs);
  });
}
