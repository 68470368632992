import Spinner from '../_spinner';

const DigestSectionSelectAll = ({showSelectAll, visible, onSelectAll, onClearAll, processing}) => {
  if(!visible) {
    return null;
  }

  const renderButton = () => {
    if(processing) {
      return <div className="digest-section-select-all spinner"><Spinner className="spinner--small" /></div>;
    }

    if(showSelectAll) {
      return <div><button className="digest-section-select-all" onClick={onSelectAll}>Select All</button></div>;
    }

    return <div><button className="digest-section-select-all" onClick={onClearAll}>Clear</button></div>;
  };

  return (
    <>
      {renderButton()}
    </>
  );
};

DigestSectionSelectAll.propTypes = {
  showSelectAll: PropTypes.bool,
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onClearAll: PropTypes.func
};

DigestSectionSelectAll.defaultProps = {
  showSelectAll: 0,
  visible: false,
  processing: false,
  onSelectAll() {},
  onClearAll() {}
};

export default DigestSectionSelectAll;
