import DropdownBox from './_dropdown_box';

// Dropdown is just a wrapper for DropdownBox due to the way the onClickOutside HoC works
const Dropdown = props => (
  <DropdownBox
    disableOnClickOutside={true}
    {...props} />
);

export default Dropdown;
