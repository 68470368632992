import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ChartistGraph from 'react-chartist';

import ChartLegend from './_chart_legend';

class GenericSeries extends Component {

  /* eslint-disable */
  static CHARTTYPES = {
    PIE: 'Pie',
    BAR: 'Bar'
  };
  static MODIFIERS = {
    DISTRIBUTION: 'Distribution'
  };
  /* eslint-enable */
  
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    })).isRequired,
    type: PropTypes.string
  };

  static defaultProps = {
    data: [],
    type: GenericSeries.CHARTTYPES.BAR
  };

  render() {
    const sorted = [...this.props.data];
    const chartType = this.props.type.replace(/(\w+)-/, '');
    const chartTypeModifier = RegExp(/-(\w+)/).test(this.props.type) ? this.props.type.replace(/-(\w+)/, '') : null;
    const isPercentage = (chartTypeModifier === GenericSeries.MODIFIERS.DISTRIBUTION) && (chartType === GenericSeries.CHARTTYPES.BAR);

    sorted.sort((a, b) => a.amount - b.amount);

    const chartData = {
      series: sorted.map(p => (isPercentage ? p.amount * 100 : p.amount))
    };

    const chartOptions = {
      showLabel: false,
      height: '260px',
      distributeSeries: chartTypeModifier === GenericSeries.MODIFIERS.DISTRIBUTION,
      chartPadding: 0
    };

    if(chartType === GenericSeries.CHARTTYPES.BAR) {
      const barChartOptions = {
        height: '100%',
        axisY: {
          showGrid: false,
          showLabel: false,
          onlyInteger: false,
          offset: 0
        },
        axisX: {
          labelInterpolationFnc(value) {
            return isPercentage ? `${value}%` : value;
          }
        },
        horizontalBars: true
      };

      Object.assign(chartOptions, barChartOptions);
    }

    return (
      <div className="distribution-graph">
        <ChartistGraph data={chartData} type={chartType} options={chartOptions} />
        <ChartLegend entries={sorted.map(p => p.label)} />
      </div>
    );
  }

}

export default GenericSeries;
