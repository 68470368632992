/* eslint-disable react/no-multi-comp */
import {createContext, useContext, useMemo, useState} from 'react';
import {postGet} from '../modules/api/posts';

export const PostsContext = createContext();

export const usePosts = () => {
  const postsContext = useContext(PostsContext);

  if(postsContext === undefined) {
    throw new Error('usePosts must be used within <PostsProvider />');
  }

  return postsContext;
};

const usePostsInternal = () => {
  const [posts, setPosts] = useState({});

  const value = useMemo(() => {
    const updatePosts = somePosts => {
      const updatedPosts = somePosts.reduce((acc, p) => {
        // only storing usedInDigest since that's all that's being tracked at this point... smaller footprint.
        acc[p.postId] = {usedInDigest: Boolean(p.usedInDigest)};

        return acc;
      }, {});

      setPosts({...posts, ...updatedPosts});
    };

    const refreshPost = async postId => {
      const postArray = await postGet(postId);

      updatePosts(postArray);
    };

    return {
      refreshPost,
      updatePosts,
      posts
    };
  }, [posts]);

  return value;
};

export const PostsProvider = ({children}) => {
  const value = usePostsInternal();

  return (
    <PostsContext.Provider value={value}>
      {children}
    </PostsContext.Provider>
  );
};

PostsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const withPosts = Component => {
  return props => (
    <PostsContext.Consumer>
      {postsContext => (
        <Component {...props} postsContext={{...postsContext}} />
      )}
    </PostsContext.Consumer>
  );
};

