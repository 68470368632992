import Types from '../../types';
import * as KlueTypes from '../../klue_types';

export function embedHtml(htmlContent) {
  if(!Types.string.validate(htmlContent)) {
    throw new Error('Parameter was unexpected type.');
  }

  return KlueTypes.HtmlContent.create({
    rawHtml: htmlContent
  });
}
