class PlaceholdersHorizontal extends React.Component {

  render() {
    return (
      <div className="layout-news-grid layout-news-grid--small">
        <div className="layout-news-grid_item">
          <PlaceHolderHeader />
          <PlaceHolderBody />
        </div>
        <div className="layout-news-grid_item">
          <PlaceHolderHeader />
          <PlaceHolderBody />
        </div>
        <div className="layout-news-grid_item">
          <PlaceHolderHeader />
          <PlaceHolderBody />
        </div>
      </div>
    );
  }

}

class PlaceholdersHorizontalSingle extends React.Component {

  render() {
    return (
      <div className="layout-news-grid layout-news-grid--small">
        <div className="layout-news-grid_item">
          <PlaceHolderHeader />
          <PlaceHolderBody />
        </div>
      </div>
    );
  }

}

class PlaceHolderBody extends React.Component {

  render() {
    return (
      <div className="u-x-fill">
        <div className="placeholder-line placeholder-line--95" />
        <div className="placeholder-line placeholder-line--85" />
        <div className="placeholder-line placeholder-line--70" />
        <div className="placeholder-line placeholder-line--90" />
        <div className="placeholder-line placeholder-line--65" />
        <div className="placeholder-line placeholder-line--75 mb0" />
      </div>
    );
  }

}

class PlaceHolderHeader extends React.Component {

  render() {
    return (
      <div className="placeholder-header">
        <div className="placeholder-square u-mb-s" />
        <div className="u-fill-x">
          <div className="placeholder-line placeholder-line--80" />
          <div className="placeholder-line placeholder-line--70 mb0" />
        </div>
      </div>
    );
  }

}

const placeholders = {
  _PlaceholdersHorizontal: PlaceholdersHorizontal,
  _PlaceholdersHorizontalSingle: PlaceholdersHorizontalSingle
};

export default placeholders;
