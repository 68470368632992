import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {abbreviate} from '../../../modules/number_utils';
import {fetchCraftData} from './data_providers';

import CraftFinancialStatement from './craft/craft_financial_statement';

const financialNumberFormatter = value => abbreviate(value, {accounting: true});

const incomeStatementEntries = [
  {label: 'Currency', craftIndex: 'currency', formatter: c => (c ? c.toUpperCase() : '')},
  {label: 'Revenue', craftIndex: 'revenue', formatter: financialNumberFormatter},
  {label: 'Cost of Goods Sold', craftIndex: 'costOfGoodsSold', formatter: financialNumberFormatter},
  {label: 'Gross Profit', craftIndex: 'grossProfit', formatter: financialNumberFormatter},
  {label: 'Sales & Marketing Expense', craftIndex: 'salesMarketingExpense', formatter: financialNumberFormatter},
  {label: 'General Administrative Expense', craftIndex: 'generalAdministrativeExpense', formatter: financialNumberFormatter},
  {label: 'R&D Expense', craftIndex: 'researchDevelopmentExpense', formatter: financialNumberFormatter},
  {label: 'Total Operating Expense', craftIndex: 'totalOperatingExpense', formatter: financialNumberFormatter},
  {label: 'EBITDA', craftIndex: 'ebitda', formatter: financialNumberFormatter},
  {label: 'EBIT', craftIndex: 'ebit', formatter: financialNumberFormatter},
  {label: 'Net Income', craftIndex: 'netIncome', formatter: financialNumberFormatter}
];

const balanceSheetEntries = [
  {label: 'Currency', craftIndex: 'currency', formatter: c => (c ? c.toUpperCase() : '')},
  {label: 'Cash', craftIndex: 'cash', formatter: financialNumberFormatter},
  {label: 'Accounts Receivable', craftIndex: 'accountsReceivable', formatter: financialNumberFormatter},
  {label: 'Inventories', craftIndex: 'inventories', formatter: financialNumberFormatter},
  {label: 'Current Assets', craftIndex: 'currentAssets', formatter: financialNumberFormatter},
  {label: 'PP&E', craftIndex: 'ppE', formatter: financialNumberFormatter},
  {label: 'Goodwill', craftIndex: 'goodwill', formatter: financialNumberFormatter},
  {label: 'Total Assets', craftIndex: 'totalAssets', formatter: financialNumberFormatter},
  {label: 'Accounts Payable', craftIndex: 'accountsPayable', formatter: financialNumberFormatter},
  {label: 'Dividends Payable', craftIndex: 'dividendsPayable', formatter: financialNumberFormatter},
  {label: 'Current Liabilities', craftIndex: 'currentLiabilities', formatter: financialNumberFormatter},
  {label: 'Non-Current Liabilities', craftIndex: 'nonCurrentLiabilities', formatter: financialNumberFormatter},
  {label: 'Total Liabilities', craftIndex: 'totalLiabilities', formatter: financialNumberFormatter},
  {label: 'Preferred Stock', craftIndex: 'preferredStock', formatter: financialNumberFormatter},
  {label: 'Common Stock', craftIndex: 'commonStock', formatter: financialNumberFormatter},
  {label: 'Additional Paid-In Capital', craftIndex: 'additionalPaidInCapital', formatter: financialNumberFormatter},
  {label: 'Retained Earnings', craftIndex: 'retainedEarnings', formatter: financialNumberFormatter},
  {label: 'Total Equity', craftIndex: 'totalEquity', formatter: financialNumberFormatter}
];

const cashFlowEntries = [
  {label: 'Currency', craftIndex: 'currency', formatter: c => (c ? c.toUpperCase() : '')},
  {label: 'Net Income', craftIndex: 'netIncome', formatter: financialNumberFormatter},
  {label: 'Accounts Receivable', craftIndex: 'accountsReceivable', formatter: financialNumberFormatter},
  {label: 'Accounts Payable', craftIndex: 'accountsPayable', formatter: financialNumberFormatter},
  {label: 'Inventories', craftIndex: 'inventories', formatter: financialNumberFormatter},
  {label: 'Income Taxes Paid', craftIndex: 'incomeTaxesPaid', formatter: financialNumberFormatter},
  {label: 'Interest Paid', craftIndex: 'interestPaid', formatter: financialNumberFormatter},
  {label: 'Cash from Operating Activities', craftIndex: 'cashFromOperatingActivities', formatter: financialNumberFormatter},
  {label: 'Capital Expenditure', craftIndex: 'capex', formatter: financialNumberFormatter},
  {label: 'Cash from Investing Activities', craftIndex: 'cashFromInvestingActivities', formatter: financialNumberFormatter},
  {label: 'Dividends Paid', craftIndex: 'dividendsPaid', formatter: financialNumberFormatter},
  {label: 'Cash from Financing Activities', craftIndex: 'cashFromFinancingActivities', formatter: financialNumberFormatter},
  {label: 'Free Cash Flow', craftIndex: 'freeCashFlow', formatter: financialNumberFormatter}
];

const formatSeries = (periods, statement, property, formatter) => {
  return periods.map(p => {
    const perStatement = statement.statementForPeriod(p);

    if(!perStatement) {
      return null;
    }

    const value = perStatement.get(property);

    if(!formatter || typeof formatter !== 'function') {
      return value;
    }

    return formatter(value);
  });
};

const currentPeriods = (statement, range = null) => {
  const typesAvailable = statement.periodTypesAvailable();

  if(!typesAvailable.includes(range)) {
    console.warn('currentPeriods: range is undefined: %o', range);

    throw new Error('Parameter to currentPeriods was unexpected type.');
  }

  // Get up to four periods
  const periods = statement.periods(range).slice(0, 4);

  periods.reverse();

  return periods;
};

const allEntries = (statement, entries, periods) => {
  entries = entries.map(e => {
    e.series = formatSeries(periods, statement, e.craftIndex, e.formatter);

    return e;
  });

  // filter out any entries which don't have a single value
  return entries.filter(e => e.series.some(v => v));
};

const buildFinancialStatement = (statementData, entriesFilter, range) => {
  const statement = new CraftFinancialStatement(statementData);
  const periods = currentPeriods(statement, range);
  const periodHeaders = periods.map(p => p.displayString());

  periodHeaders.unshift('');

  const entries = allEntries(statement, entriesFilter, periods);
  const rows = entries.map(entry => {
    const row = entry.series;

    row.unshift(entry.label);

    return row;
  });

  return KlueTypes.Table.create({
    headers: Types.arrayOf(Types.string).create(periodHeaders),
    records: KlueTypes.Matrix.create(rows)
  });
};

export const balanceSheetStatements = async (company, craftDataFetcher = fetchCraftData) => {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);

    throw new Error('Parameter to financialStatement was unexpected type.');
  }

  const data = await craftDataFetcher(company.info);

  return buildFinancialStatement(data.balanceSheets, balanceSheetEntries, 'fiscal_year');
};

export const balanceSheetStatementsQuarterly = async (company, craftDataFetcher = fetchCraftData) => {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);

    throw new Error('Parameter to financialStatement was unexpected type.');
  }

  const data = await craftDataFetcher(company.info);

  return buildFinancialStatement(data.balanceSheets, balanceSheetEntries, 'quarter');
};

export const cashFlowStatements = async (company, craftDataFetcher = fetchCraftData) => {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);

    throw new Error('Parameter to financialStatement was unexpected type.');
  }

  const data = await craftDataFetcher(company.info);

  return buildFinancialStatement(data.cashFlowStatements, cashFlowEntries, 'fiscal_year');
};

export const cashFlowStatementsQuarterly = async (company, craftDataFetcher = fetchCraftData) => {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);

    throw new Error('Parameter to financialStatement was unexpected type.');
  }

  const data = await craftDataFetcher(company.info);

  return buildFinancialStatement(data.cashFlowStatements, cashFlowEntries, 'quarter');
};

export const incomeStatements = async (company, craftDataFetcher = fetchCraftData) => {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);

    throw new Error('Parameter to financialStatement was unexpected type.');
  }

  const data = await craftDataFetcher(company.info);

  return buildFinancialStatement(data.incomeStatements, incomeStatementEntries, 'fiscal_year');
};

export const incomeStatementsQuarterly = async (company, craftDataFetcher = fetchCraftData) => {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);

    throw new Error('Parameter to financialStatement was unexpected type.');
  }

  const data = await craftDataFetcher(company.info);

  return buildFinancialStatement(data.incomeStatements, incomeStatementEntries, 'quarter');
};
