import classNames from 'classnames';
import {useMemo, useState, useCallback, useEffect, useRef} from 'react';

import DigestMenuTextButton from './_digest_menu_text_button';
import DigestMenuGroup from './_digest_menu_group';
import DigestTypeCheckbox from './_digest_type_checkbox';
import {getSendDigestTooltip} from '../modules/digest_utils';
import DigestMenuIconButton from './_digest_menu_icon_button';
import DigestPreviewsStatus from './_digest_previews_status';
import Spinner from './_spinner';

import {allCompaniesID} from '../modules/constants/digest';
import Icon from './_icon';
import {rivalGroupsGet} from '../modules/api/rival_groups';

const DigestMenuEmail = ({
  editMode,
  isCurator,
  digest,
  user,
  archiveMode,
  reorderMode,
  hasUnsavedFields,
  editorBusy,
  sendingPreviews,
  rivalGroups,
  sentPreviewFailures,
  sentRivalGroupIds,
  previewsSentAt,
  previewExpanded,
  selectedGroups,
  canSendDigest,
  canEditDigest,
  onTogglePreview,
  onSendPreviews,
  onSetGroupSelected
}) => {
  const mounted = useRef(false);
  const [canSendPreview, setCanSendPreview] = useState(false);
  const [loadingCounts, setLoadingCount] = useState(false);
  const [groupCounts, setGroupCounts] = useState({});
  const [countError, setCountError] = useState(false);
  const sequenceNumber = useRef(0);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setGroupCounts({});
    setCountError(false);

    if(!digest) {
      return;
    }

    const {id: emailDigestId, favoritesLiveCount = 0} = digest;

    if(!emailDigestId) {
      return;
    }

    const params = {
      emailDigestId
    };

    setLoadingCount(true);

    const sn = ++sequenceNumber.current;

    rivalGroupsGet({params}).then(rivGroups => {
      if(!mounted.current) {
        return;
      }

      if(sn !== sequenceNumber.current) {
        return;
      }

      const counts = {};

      (rivGroups || []).forEach(({id, favoritesCount}) => {
        counts[id] = favoritesCount;
      });

      counts[allCompaniesID] = favoritesLiveCount;
      setGroupCounts(counts);
    }).catch(() => {
      if(sn !== sequenceNumber.current) {
        return;
      }

      setCountError(true);
    }).finally(() => {
      setLoadingCount(false);
    });
  }, [digest]);

  useEffect(() => {
    setCanSendPreview(Object.values(selectedGroups).some(Boolean));
  }, [selectedGroups]);

  const handleSendPreviews = useCallback(() => {
    const rivalGroupIds = Object.entries(selectedGroups).filter(([id, checked]) => checked && groupCounts[id]).map(([id]) => parseInt(id, 10));

    onSendPreviews(rivalGroupIds);
  }, [selectedGroups, onSendPreviews, groupCounts]);

  const previewOptions = useMemo(() => {
    if(loadingCounts) {
      return <Spinner />;
    }

    if(countError) {
      return null;
    }

    const allCompaniesOptionDisabled = !groupCounts[allCompaniesID];

    return [
      <DigestTypeCheckbox
        key={allCompaniesID}
        id={allCompaniesID}
        dataTestId={'all-companies-checkbox'}
        name="All Companies"
        checked={!allCompaniesOptionDisabled && selectedGroups[allCompaniesID]}
        disabled={allCompaniesOptionDisabled}
        onClick={allCompaniesOptionDisabled ? null : onSetGroupSelected} />
    ].concat((rivalGroups || []).filter(g => !_.isEmpty(g.rivals)).sort((g1, g2) => g1.name.localeCompare(g2.name)).map(g => {
      const {id, name} = g;
      const checked = selectedGroups[id] || false;
      const disabled = !groupCounts[id] || false;

      return (<DigestTypeCheckbox
        key={id}
        id={id}
        name={name}
        dataTestId={`rival-group-${id}-checkbox`}
        checked={!disabled && checked}
        disabled={disabled}
        tooltip={disabled ? 'No items in this Digest, it can\'t be previewed.' : ''}
        onClick={disabled ? null : onSetGroupSelected} />);
    }));
  }, [selectedGroups, rivalGroups, onSetGroupSelected, groupCounts, loadingCounts, countError]);

  const checkedCount = useMemo(() => {
    return Object.keys(selectedGroups).filter(id => selectedGroups[id] && groupCounts[id]).length;
  }, [selectedGroups, groupCounts]);

  const {archivedAt} = digest || {};

  if(!editMode || !isCurator || archivedAt || archiveMode) {
    return null;
  }

  const sendToolTip = getSendDigestTooltip(canSendDigest, canEditDigest);
  const disablePreviewButton = !previewExpanded && (!canSendDigest || reorderMode || editorBusy || hasUnsavedFields);
  const disableSendButton =
    !checkedCount || countError || !canSendDigest || !canSendPreview || reorderMode || editorBusy || hasUnsavedFields || sendingPreviews;

  return (
    <DigestMenuGroup>
      <div className={classNames('digest-preview-control', {disabled: disablePreviewButton})}>
        <DigestMenuIconButton
          icon="send_outline"
          title="preview"
          dataTrackingId="preview-digest-button"
          onClick={onTogglePreview}
          className={classNames({disabled: disablePreviewButton})}
          tooltip={!previewExpanded ? sendToolTip : ''} />
        <Icon icon={previewExpanded ? 'arrow-up' : 'arrow-down'} width={20} height={20} onClick={onTogglePreview} />
      </div>
      {previewExpanded && (
        <div className="digest-test-preview-options">
          <div className={classNames('show-checkbox-options', {disabled: !canSendDigest || reorderMode || editorBusy || hasUnsavedFields})}>
            <p>Send a preview email to <span className="email"><strong>{user.email}</strong></span> for each of the following selected user subscriptions:</p>
            <DigestMenuTextButton
              title={sendingPreviews ? 'sending previews...' : 'send preview'}
              dataTrackingId="send-digest-preview-button"
              onClick={handleSendPreviews}
              className={classNames('primary', {
                disabled: disableSendButton,
                'no-bottom-margin': true
              })} />
            <DigestPreviewsStatus
              previewsSentAt={previewsSentAt}
              sentPreviewFailures={sentPreviewFailures}
              sentRivalGroupIds={sentRivalGroupIds}
              countError={countError} />
            <div className="digest-test-preview-options-list">
              {previewOptions}
            </div>
            {!countError &&
              <p className="digest-preview-disable-checkbox-reason">If you cannot select a board group, it means no digest articles have been assigned</p>}
          </div>
        </div>
      )}
    </DigestMenuGroup>
  );
};

DigestMenuEmail.propTypes = {
  editMode: PropTypes.bool,
  isCurator: PropTypes.bool,
  digest: PropTypes.object,
  user: PropTypes.object,
  archiveMode: PropTypes.bool,
  reorderMode: PropTypes.bool,
  hasUnsavedFields: PropTypes.bool,
  editorBusy: PropTypes.bool,
  sentPreviewFailures: PropTypes.arrayOf(PropTypes.string),
  sentRivalGroupIds: PropTypes.arrayOf(PropTypes.number),
  previewsSentAt: PropTypes.object,
  sendingPreviews: PropTypes.bool,
  rivalGroups: PropTypes.arrayOf(PropTypes.object),
  previewExpanded: PropTypes.bool,
  selectedGroups: PropTypes.object,
  canSendDigest: PropTypes.bool,
  canEditDigest: PropTypes.bool,
  onTogglePreview: PropTypes.func,
  onSendPreviews: PropTypes.func,
  onSetGroupSelected: PropTypes.func
};

DigestMenuEmail.defaultProps = {
  editMode: false,
  isCurator: false,
  digest: null,
  user: {},
  archiveMode: false,
  reorderMode: false,
  hasUnsavedFields: false,
  editorBusy: false,
  sentPreviewFailures: null,
  sentRivalGroupIds: null,
  previewsSentAt: null,
  sendingPreviews: false,
  rivalGroups: [],
  previewExpanded: false,
  selectedGroups: {},
  canSendDigest: false,
  canEditDigest: false,
  onTogglePreview() {},
  onSendPreviews() {},
  onSetGroupSelected() {}
};

export default DigestMenuEmail;
