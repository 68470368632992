/* eslint-disable no-inner-declarations */
import {
  CARD_BODY_CLASSNAME,
  EDITOR_DEFAULT_CONFIG,
  COMPLETE_FORMULA_REGEX,
  EDITOR_PLACEHOLDER_CLASS,
  formulaKeyMappings,
  videoProviders,
  toolbarButtons,
  imageEditButtons,
  shortcutsEnabled,
  cardLinkSelectorWidth
} from '../../modules/constants/editor';
import {
  getFormulaKey,
  removeDynamicBlock,
  reindexDynamicBlocks,
  linkify,
  makeTableScrollable,
  clearFormatting as clearFormattingUtil,
  removeExtraSpacesUtil,
  getEditorInstance,
  handleImageUpload,
  updateToolbarButtonsBasedOnFeatureFlags,
  updateToolbarShortcutsBasedOnFeatureFlags,
  editorInsertKlueLink,
  getEditorColors,
  fontSizeHtmlString,
  handleFontSizeChange,
  handleFontSizeMenuRefresh,
  handleBeforeCleanup,
  handleUrlLinked,
  updateButtonGroupBasedOnFeatureFlags,
  handleInsertTargetImageURL,
  handleCardImageLinkButtonState,
  getEditorLinkInfo,
  getPasteAllowedStyle
} from '../../modules/editor_utils';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {camelToSnake} from '../../modules/text_utils';
import EditorAttachmentPlaceholder from './_editor_attachment_placeholder';
import EditorToolbarCardLinkSelector from './_editor_toolbar_card_link_selector';
import EditorToolbarAttachments from './_editor_toolbar_attachments';

import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import './plugins/_list_types';
import './plugins/_iframe';
import './plugins/_card_links';

const {
  FroalaEditorComponent,
  FroalaEditor
} = getEditorInstance();

FroalaEditor.VIDEO_PROVIDERS.push(...videoProviders);

class EditorComponent extends React.Component {

  static contextTypes = {
    appData: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    formulas: PropTypes.arrayOf(PropTypes.string),
    initialValue: PropTypes.string,
    editorConfig: PropTypes.object,
    editorRef: PropTypes.func.isRequired,
    onFormulaInsert: PropTypes.func,
    onFormulaUpdate: PropTypes.func,
    onFormulaDelete: PropTypes.func,
    onToolbarEnabled: PropTypes.func,
    onBusy: PropTypes.func,
    onHtmlSet: PropTypes.func,
    onImageUploadSuccess: PropTypes.func,
    profileId: PropTypes.number.isRequired,   // eslint-disable-line react/no-unused-prop-types
    titleRef: PropTypes.object,
    rivals: PropTypes.object,
    loadOrFetchRivals: PropTypes.func,
    handleToggleFullScreen: PropTypes.func
  };

  static defaultProps = {
    formulas: [],
    initialValue: '',
    editorConfig: {},
    editorRef: null,
    onFormulaInsert() {},
    onFormulaUpdate() {},
    onFormulaDelete() {},
    onToolbarEnabled() {},
    onBusy() {},
    onHtmlSet() {},
    onImageUploadSuccess() {},
    profileId: null,
    titleRef: null,
    rivals: {},
    loadOrFetchRivals() {},
    handleToggleFullScreen() {}
  };

  state = {
    model: '',
    isDirty: false,
    isAttachmentsMenuVisible: false,
    isCardLinkSelectorVisible: false,
    isImagePopupUpdated: false      // TODO: temporary! remove this after froala dnd is re-enabled
  };

  constructor(props) {
    super(props);

    this.editorComponent = {};
  }

  componentDidMount() {
    console.log('Editor.componentDidMount: props: %o', this.props);

    const {loadOrFetchRivals} = this.props;
    const {utils} = this.context;

    const isAutomatedCardButtonVisible = utils.company.companyData?.isAutomatedCardButtonVisible ?? true;
 
    loadOrFetchRivals();

    const toggleCardLinkSelector2 = this.toggleCardLinkSelector;

    FroalaEditor.RegisterCommand('klue-card-link-selector', {
      title: 'Insert Link',
      icon: 'insertLink',
      focus: false,
      undo: false,
      plugin: 'cardLinks',
      callback() {
        const position = this.cardLinks.getPosition();
        const {urlHRef, urlText} = getEditorLinkInfo(this);

        toggleCardLinkSelector2({...position, urlHRef, urlText});
      }
    });

    FroalaEditor.RegisterShortcut(75, 'klue-card-link-selector', null, 'K');

    FroalaEditor.RegisterCommand('klue-card-image-link-selector', {
      title: 'Insert Link',
      icon: 'insertLink',
      focus: false,
      undo: false,
      plugin: 'cardLinks',
      refreshAfterCallback: true,
      callback() {
        const position = this.cardLinks.getPosition();
        const {toolbarWidth, toolbarHeight} = position || {};

        this?.popups?.hide('image.edit');

        const top = toolbarHeight + 20;
        const left = (toolbarWidth - cardLinkSelectorWidth) / 2;
        const targetImage = this.image.get();

        toggleCardLinkSelector2({...position, top, left, targetImage});
      },
      refresh($btn) {
        handleCardImageLinkButtonState($btn, this);
      }
    });

    isAutomatedCardButtonVisible && FroalaEditor.RegisterCommand('klue-attachments', {
      title: 'Add Data',
      icon: 'klue-attachments',
      focus: false,
      undo: false,
      callback: this.toggleAttachmentsMenu,
      refresh: this.handleAttachmentsMenuState
    });

    FroalaEditor.RegisterCommand('contentZoomToggle', {
      title: 'Image Zoom',
      icon: 'image-zoom-toggle',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      callback: this.toggleContentZoom,
      refresh: this.handleContentZoomButtonState
    });

    FroalaEditor.RegisterCommand('imageDisplayInline', {
      title: 'Text Wrap',
      icon: 'image-display-inline-toggle',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      callback: this.toggleImageDisplayInline,
      refresh: this.handleImageDisplayInlineButtonState
    });

    FroalaEditor.RegisterCommand('fontSizeLabelled', {
      title: 'Font Size',
      type: 'dropdown',
      icon: 'font-size--labelled',
      undo: true,
      focus: true,
      refreshAfterCallback: true,
      // NOTE: needed for custom formatting of dropdown list options (instead of options property)
      html: fontSizeHtmlString,
      callback: this.handleFontSizeChange,
      refreshOnShow: this.handleFontSizeMenuRefresh
    });

    FroalaEditor.RegisterCommand('listTypesButton', {
      title: 'Insert List',
      icon: 'list-types',
      undo: true,
      focus: true,
      plugin: 'listTypes',
      callback() {
        this.listTypes.showPopup();
      }
    });

    FroalaEditor.RegisterCommand('iframeButton', {
      title: 'Insert iframe',
      icon: 'iframe',
      undo: true,
      focus: true,
      plugin: 'iframe',
      callback() {
        this.iframe.showPopup();
      }
    });

    FroalaEditor.RegisterCommand('klueClearFormatting', {
      title: 'Clear formatting',
      icon: 'clearFormatting',
      focus: false,
      undo: false,
      callback: this.clearFormatting
    });

    FroalaEditor.RegisterCommand('toggleTableWrapping', {
      title: 'Force column to expand',
      icon: 'expand-table',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      callback: this.toggleTableWrapping,
      refresh: this.handleTableWrappingRefresh
    });

    const {handleToggleFullScreen} = this.props;

    FroalaEditor.RegisterCommand('fullscreen', {
      title: 'Fullscreen',
      icon: 'fullScreen',
      focus: false,
      undo: false,
      callback: handleToggleFullScreen
    });
  }

  handleFontSizeChange = (cmd, val) => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    handleFontSizeChange(val, editor);
  };

  handleFontSizeMenuRefresh = (btn, dropdown) => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    handleFontSizeMenuRefresh(dropdown, editor);
  };

  /**
   * Toggles disabled state on the attachments menu button depending on selection/caret placement in the editor.
   * Currently, the button is only disabled if the editor is focused on a table or table-descendant node.
   *
   * @param {Array} btn Froala button representing the attachments menu (first item refers to the DOM element)
   * @returns {undefined}
   */
  handleAttachmentsMenuState = (btn = null) => {
    const btnEl = btn && btn.length ? btn[0] : null;

    if(!btnEl) {
      return;
    }

    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const selection = editor.selection.get();
    const parentEl = selection?.anchorNode?.parentElement;
    const hasOpenPlaceholder = Boolean(document.querySelectorAll('.attachment-component--editing').length);
    // dynamic attachments menu should be enabled only when the cursor is focused in an empty line, and not in a table
    const shouldDisable = parentEl
      ? Boolean(
        parentEl.closest('table') ||
          hasOpenPlaceholder ||
          ((selection.anchorNode.nodeName !== 'P') && !parentEl.classList.contains('fr-wrapper'))
      )
      : false;

    btnEl.classList.toggle('fr-disabled', shouldDisable);
  };

  handleContentZoomButtonState = () => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const image = (editor.image.get()).get(0); // editor.image.get() returns a jQuery object
    const popup = (editor.popups.get('image.edit')).get(0); // editor.popups.get() returns a jQuery object
    const button = popup.querySelector('.klue-editor-icon_image-zoom-toggle');

    if(!(button || image)) {
      return;
    }

    button.classList.toggle('klue-editor-icon_image-zoom-toggle--on', Boolean(image && image.classList.contains('no-zoom')));
  };

  handleImageDisplayInlineButtonState = () => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const image = (editor.image.get()).get(0); // editor.image.get() returns a jQuery object
    const popup = (editor.popups.get('image.edit')).get(0); // editor.popups.get() returns a jQuery object
    const button = popup.querySelector('.klue-editor-icon_image-display-inline-toggle');
    const alignCenter = popup.querySelector('[data-cmd="imageAlign"][data-param1="center"]');
    const imageAlignmentButtons = popup.querySelectorAll('[data-cmd="imageAlign"], [data-cmd="imageDisplayInline"]');

    if(!(button || image)) {
      return;
    }

    const isFullwidth = (image.classList.contains('klue-img--fullwidth') || image.classList.contains('klue-img--lg'));
    const isEnabled = image.classList.contains('fr-dii');

    button.classList.toggle('klue-editor-icon_image-display-inline-toggle--on', isEnabled);
    imageAlignmentButtons.forEach(btn => btn.classList.toggle('fr-disabled', isFullwidth));
    alignCenter && alignCenter.classList.toggle('fr-disabled', isEnabled);
  };

  handleModelChange = model => this.setState({model, isDirty: true});

  handleHtmlSet = () => {
    const {formulas, onHtmlSet} = this.props;

    if(formulas.length) {
      this.renderAttachments();

      onHtmlSet();
    }
  };

  handleBeforeImageUpload = images => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const {onBusy, onImageUploadSuccess} = this.props;

    return handleImageUpload({editor, images, onBusy, onImageUploadSuccess});
  };

  handleTableInserted = table => {
    makeTableScrollable(table);
  };

  handleVideoInserted = iframeWrapper => {
    if(isEmpty(iframeWrapper)) {
      return;
    }

    const iframe = iframeWrapper.length && iframeWrapper[0].querySelector('iframe');

    if(iframe && iframe.src) {
      iframe.src = iframe.src.replace(/youtube(?=\.)/i, 'youtube-nocookie');
    }
  };

  toggleCardLinkSelector = ({top: cardLinkTop, left: cardLinkLeft, urlHRef, urlText, targetImage}) => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const {isCardLinkSelectorVisible} = this.state;

    if(!isCardLinkSelectorVisible) {
      editor.selection.save();
      editor.events.trigger('blur');
    }

    this.setState(prevState => ({isCardLinkSelectorVisible: !prevState.isCardLinkSelectorVisible, cardLinkTop, cardLinkLeft, urlHRef, urlText, targetImage}));
  };

  handleCloseKlueCardLinkSelector = args => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const {appData: {rootUrl}} = this.context;

    this.setState({isCardLinkSelectorVisible: false});

    editorInsertKlueLink({...args, editor, rootUrl});
  };

  handleInsertLink = ({linkText, urlText, targetImage}) => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    this.setState({isCardLinkSelectorVisible: false});
    editor.selection.restore();

    if(targetImage) {
      return handleInsertTargetImageURL({editor, targetImage, urlText});
    }

    editor.link.insert(urlText, linkText, {target: '_blank', rel: 'nofollow'});
  };

  toggleAttachmentsMenu = () => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const {isAttachmentsMenuVisible} = this.state;

    if(!isAttachmentsMenuVisible) {
      editor.selection.save();
    }

    this.setState(prevState => ({isAttachmentsMenuVisible: !prevState.isAttachmentsMenuVisible}));
  };

  toggleContentZoom = () => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const image = (editor.image.get() || [])[0]; // editor.image.get() returns a jQuery object

    image && image.classList.toggle('no-zoom');
  };

  toggleTableWrapping = () => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const cursor = editor.selection.get(0);
    const parentNode = cursor?.focusNode?.parentElement;

    if(!parentNode || (parentNode.nodeName !== 'TD' && parentNode.nodeName !== 'TH')) {
      return;
    }

    const {whiteSpace} = parentNode.style;

    parentNode.style.whiteSpace = (whiteSpace === 'nowrap') ? 'normal' : 'nowrap';
  };

  handleTableWrappingRefresh = () => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const cursor = editor.selection.get(0);
    const parentNode = cursor?.focusNode?.parentElement;

    if(!parentNode || (parentNode.nodeName !== 'TD' && parentNode.nodeName !== 'TH')) {
      return;
    }

    const {whiteSpace} = parentNode.style;
    const tableEditPopup = editor.popups.get('table.edit')[0];
    const expandIcon = tableEditPopup.querySelector('.klue-editor-icon_expand-table');
    const button = expandIcon?.parentElement;

    if(!button) {
      return;
    }

    button.classList.toggle('fr-active', whiteSpace === 'nowrap');
  };

  toggleImageDisplayInline = () => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const image = (editor.image.get() || [])[0]; // editor.image.get() returns a jQuery object

    if(image) {
      const {classList} = image;

      classList.toggle('fr-dii');
      classList.toggle('fr-dib');

      if(classList.contains('fr-dii')) {
        classList.remove('fr-fic');

        if(!classList.contains('fr-fir')) {
          classList.add('fr-fil');
        }
      }
    }
  };

  closeAttachmentsMenu = () => this.state.isAttachmentsMenuVisible && this.setState({isAttachmentsMenuVisible: false});
  closePopups = () => this.setState({isAttachmentsMenuVisible: false, isCardLinkSelectorVisible: false});

  /**
   * Disables drag-and-drop in Froala on initialization.
   */
  handleEditorInit = async () => {
    // TODO: remove this after froala dnd is re-enabled
    // HACK: Workaround hackery due to react-froala-wysiwyg init event binding bug in > v3.0.4.
    // Can be removed when react v16 upgrade is merged (epic #3318) or the PR below is merged.
    // See also:
    // - https://github.com/froala/react-froala-wysiwyg/issues/198#issuecomment-545825800
    // - https://github.com/froala/react-froala-wysiwyg/pull/191
    //
    await new Promise(resolve => setTimeout(resolve, 0));

    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const {titleRef, initialValue} = this.props;
    const shouldFocusTitle = titleRef && !titleRef.value.trim() && !initialValue;

    editor.events.focus();
    editor.events.on('drop', dropEvent => dropEvent.preventDefault, true);

    if(shouldFocusTitle) {
      titleRef.focus();
    }
    else {
      // focus editor at end of current content
      // see: https://github.com/froala/wysiwyg-editor/issues/452#issuecomment-247993162
      editor.selection.setAtEnd(editor.$el.get(0));
      editor.selection.restore();
    }

    editor.iframe && editor.iframe.initListeners();
  };

  /**
   * Modifies the DOM of the Froala Insert Image popup to no longer reference drag-and-drop
   * in the popup copy.
   */
  handleInsertImagePopup = () => {
    const label = document.querySelector('.modal-editor .fr-image-by-url-layer')?.querySelector('.fr-input-line > label');

    label.textContent = 'https://';

    // TODO: remove this after froala dnd is re-enabled
    const imageUploadEl = document.querySelector('.modal-editor .fr-image-upload-layer');

    if(!imageUploadEl) {
      return;
    }

    imageUploadEl.querySelectorAll(':not([class^="fr-"])').forEach(el => el.remove());
    imageUploadEl.firstChild.textContent = 'Click to upload an image';

    this.setState({isImagePopupUpdated: true});
  };

  handleFormulaSelect = key => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    this.toggleAttachmentsMenu();
    editor.events.focus();
    editor.selection.restore();
    editor.markers.insert();
    editor.markers.split();

    const textHtml = editor.html.get();
    const splitMarkup = textHtml.match(/^(.*?)<span class="fr-marker">(.*?)$/im);
    const formulaToInsert = camelToSnake(key).toUpperCase();
    let formulaIndex = 0;

    const htmlOutput = [];
    let [htmlBefore = '', htmlAfter = ''] = !isEmpty(splitMarkup) ? splitMarkup.slice(1) : [];

    htmlBefore = (htmlBefore || '').trim();
    htmlAfter = (htmlAfter || '').trim();

    if(htmlBefore) {
      const fragmentBefore = new DOMParser().parseFromString(htmlBefore, 'text/html');
      const placeholders = fragmentBefore.querySelectorAll('.editor-formula[data-formula-index]');

      formulaIndex = placeholders.length;

      htmlOutput.push(fragmentBefore.body.innerHTML);
    }

    // NOTE: adding the .fr-deletable class lets the user backspace/del to get rid of the contenteditable="false" placeholder block
    const insertedPlaceholder = `<div
      class="editor-formula fr-inner fr-deletable ${EDITOR_PLACEHOLDER_CLASS}"
      data-formula-index="${formulaIndex}">
    </div>`;

    htmlOutput.push(insertedPlaceholder);

    if(htmlAfter) {
      htmlOutput.push(reindexDynamicBlocks(htmlAfter, formulaIndex + 1));
    }

    this.props.onFormulaInsert(formulaIndex, formulaToInsert).then(() => {
      const {html, undo} = editor;

      html.set(htmlOutput.join(''));
      html.cleanEmptyTags();
      undo.saveStep();
    });
  };

  handleFormulaUpdate = (formulaIndex = 0, formula = '') => {
    this.props.onFormulaUpdate(formulaIndex, formula).then(formulas => this.renderAttachments({formulas}));
  };

  /**
   * Delete a formula at the specified index.
   *
   * @param {number} [formulaIndex=0] the index of the formula to delete
   */
  handleFormulaDelete = (formulaIndex = 0) => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const textHtml = editor.html.get();

    if(!textHtml) {
      return;
    }

    this.props.onFormulaDelete(formulaIndex).then(() => {
      // convert the editor content to a DOM fragment, splice out the element with the id in question,
      // then update the editor with the resulting markup
      const updatedHtml = removeDynamicBlock(textHtml, formulaIndex);

      editor.html.set(updatedHtml);
      editor.html.cleanEmptyTags();
      editor.events.focus();
    });
  };

  handlePlaceholderClick = () => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    editor.$el.blur();
  };

  afterCleanup = clipboardHtml => {
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    const img = editor.image.get();
    let returnString = clipboardHtml;

    const fragment = document.createRange().createContextualFragment(clipboardHtml);

    // Should it be enough to cover all cases?
    // When pasting without format, the nodes come with #text type
    const isRawContentFormat = fragment?.firstChild && fragment.firstChild?.nodeType === Node.TEXT_NODE;

    if(isRawContentFormat) {
      const tempEl = document.createElement('div');

      tempEl.append(fragment);

      // Remove <br> elements and replace #text nodes with paraghaps
      Array.from(tempEl.childNodes).forEach(elem => {
        if(elem && elem.nodeName === 'BR') {
          elem.remove();
        }

        if(elem.nodeType === Node.TEXT_NODE) {
          const p = document.createElement('p');

          p.innerHTML = elem.textContent;

          elem.replaceWith(p);
        }
      });

      returnString = tempEl.innerHTML;
    }

    if(!img) {
      return linkify(returnString);
    }

    return returnString;
  };

  clearFormatting = () => clearFormattingUtil(this.editorComponent);

  commandsAfter = command => {
    const {isImagePopupUpdated} = this.state;
    const {editor} = this.editorComponent;

    if(!editor) {
      return;
    }

    if(command === 'insertImage' && !isImagePopupUpdated) {
      return this.handleInsertImagePopup();
    }

    if(command === 'paragraphFormat') {
      const selection = editor.selection.get();
      const range = selection && selection.getRangeAt(0);
      const selectedNode = range?.commonAncestorContainer;
      const {parentElement} = selectedNode || {};

      parentElement && parentElement.removeAttribute('style');
    }
  };

  commandsBefore = command => {
    if(command === 'formatUL' || command === 'formatOLSimple') {
      removeExtraSpacesUtil(this.editorComponent);
    }
  };

  setEditorRef = ref => {
    // exposes the ref locally and pass it to the parent
    this.editorComponent = ref;
    this.props.editorRef(this.editorComponent);
  };

  renderAttachments = ({formulas = [], profileId} = this.props) => {
    formulas.forEach((formula, index) => {
      // check for formulas that are incomplete (i.e. newly added w/out configuration) and map to correct key
      const isNew = !COMPLETE_FORMULA_REGEX.test(formula);
      const formulaKey = getFormulaKey(formula, isNew);
      const placeholderEl = document.querySelector(`#cardEditor .editor-formula[data-formula-index="${index}"]`);
      const {label = '', type = ''} = formulaKeyMappings[formulaKey] || {};

      if(placeholderEl) {
        ReactDOM.unstable_renderSubtreeIntoContainer(
          this,
          <EditorAttachmentPlaceholder
            title={label}
            type={type}
            profileId={profileId}
            formula={formula}
            formulaKey={formulaKey}
            editing={isNew}
            isNew={isNew}
            onFormulaUpdate={f => this.handleFormulaUpdate(index, f)}
            onFormulaDelete={() => this.handleFormulaDelete(index)}
            onPlaceholderClick={this.handlePlaceholderClick}
            onOpenPlaceholder={this.setToolbarActiveStatus}
            onClosePlaceholder={this.setToolbarActiveStatus} />,
          placeholderEl
        );
      }
    });
  };

  setToolbarActiveStatus = (active = false) => {
    const {onToolbarEnabled} = this.props;

    onToolbarEnabled(active);
  };

  render() {
    const {initialValue, editorConfig, rivals} = this.props;
    const {isAttachmentsMenuVisible, isDirty, model, isCardLinkSelectorVisible, cardLinkLeft, cardLinkTop, urlHRef, urlText, targetImage} = this.state;
    const {utils, appData: {editorClientKey = ''}} = this.context;
    const textHtml = isDirty ? model : initialValue;
    const {company} = utils;
    const colors = getEditorColors({company});
    const toolbarButtonsOverride = updateToolbarButtonsBasedOnFeatureFlags({buttons: toolbarButtons, context: this.context});
    const imageEditButtonsOverride = updateButtonGroupBasedOnFeatureFlags(imageEditButtons, this.context);
    const shortcutsEnabledOverride = updateToolbarShortcutsBasedOnFeatureFlags({shortcuts: shortcutsEnabled, context: this.context});
    const {pasteAllowedStyleProps, wordAllowedStyleProps} = getPasteAllowedStyle({
      defaultConfig: EDITOR_DEFAULT_CONFIG,
      context: this.context
    });
    const otherConfig = {};

    if(wordAllowedStyleProps) {
      otherConfig.wordAllowedStyleProps = wordAllowedStyleProps;
    }

    return (
      <div id="cardEditor" className={`card-editor-body ${CARD_BODY_CLASSNAME}`}>
        {isCardLinkSelectorVisible && (
          <EditorToolbarCardLinkSelector
            top={cardLinkTop}
            left={cardLinkLeft}
            url={urlHRef}
            targetImage={targetImage}
            text={urlText}
            rivals={rivals}
            onCloseKlueLink={this.handleCloseKlueCardLinkSelector}
            onInsertLink={this.handleInsertLink} />
        )}
        {isAttachmentsMenuVisible && (
          <EditorToolbarAttachments
            onOptionClick={this.handleFormulaSelect}
            onClose={this.closeAttachmentsMenu} />
        )}
        <FroalaEditorComponent
          ref={this.setEditorRef}
          config={{
            ...EDITOR_DEFAULT_CONFIG,
            toolbarButtons: toolbarButtonsOverride,
            imageEditButtons: imageEditButtonsOverride,
            shortcutsEnabled: shortcutsEnabledOverride,
            pasteAllowedStyleProps,
            ...colors,
            key: editorClientKey,
            ...editorConfig,
            events: {
              ...(editorConfig.events || {}),
              initialized: this.handleEditorInit,
              'commands.after': this.commandsAfter,
              'commands.before': this.commandsBefore,
              'commands.mousedown': this.closePopups,
              'image.beforeUpload': this.handleBeforeImageUpload,
              'paste.beforeCleanup': handleBeforeCleanup,
              'html.set': this.handleHtmlSet,
              'paste.afterCleanup': this.afterCleanup,
              'table.inserted': this.handleTableInserted,
              'video.inserted': this.handleVideoInserted,
              'video.replaced': this.handleVideoInserted,
              'url.linked': function(link) {
                handleUrlLinked(link, this);
              },
              focus: () => this.setToolbarActiveStatus(true),
              blur: () => this.setToolbarActiveStatus(false)
            },
            ...otherConfig
          }}
          model={textHtml}
          onModelChange={this.handleModelChange} />
      </div>
    );
  }

}

const mapStateToProps = ({rivals}) => ({rivals: rivals.items});

const mapDispatchToProps = dispatch => ({
  loadOrFetchRivals: () => dispatch.rivals.loadOrFetchRivals()
});

export {EditorComponent as EditorTestComponent};

export default connect(mapStateToProps, mapDispatchToProps)(EditorComponent);
