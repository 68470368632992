import {localRivalLogoUrl} from '../modules/local_storage_utils';

import {companyImagesFromRivalAndSrc} from '../modules/company_settings_utils';

const CompanyLogo = ({rival, src, ...props}) => {
  const imgSrc = [];

  // look for cached images 1st
  const logoUrl = localRivalLogoUrl(rival);

  if(logoUrl) {
    imgSrc.push(logoUrl);
  }
  else { // if there is no non-expired image in the useRivalLogo cache, fetch images the old way
    const images = companyImagesFromRivalAndSrc(rival, src);

    if(images) {
      imgSrc.push(...images);
    }
  }

  if(!imgSrc.length) {
    return null;
  }

  return (
    <img
      src={imgSrc[0]}
      srcSet={imgSrc.join(', ')}
      loading="lazy"
      {...props} />
  );
};

CompanyLogo.contextTypes = {
  utils: PropTypes.object.isRequired
};

CompanyLogo.propTypes = {
  rival: PropTypes.object,
  src: PropTypes.string
};

CompanyLogo.defaultProps = {
  rival: null,
  src: null
};

export default CompanyLogo;
