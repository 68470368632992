import {Set} from 'immutable';

import CraftPeriodStatement from './craft_period_statement';

class CraftFinancialStatement {

  constructor(incomeStatements) {
    this.incomeStatements = incomeStatements.map(data => { return new CraftPeriodStatement(data); });
  }

  periodTypesAvailable() {
    return Set(this.periods().map(period => period.type())).toArray();
  }

  periods(typeFilter = null) {
    const periods = this.incomeStatements.map(statement => {
      return statement.timePeriod();
    }).filter(p => p.sortableString() !== null);

    const filteredPeriods = typeFilter === null ? periods : periods.filter(period => period.type() === typeFilter);

    filteredPeriods.sort((a, b) => {
      if(a.sortableString() < b.sortableString()) { return 1; }
      if(a.sortableString() > b.sortableString()) { return -1; }

      return 0;
    });

    return filteredPeriods;
  }

  statementForPeriod(period) {
    return this.incomeStatements.find(statement => { return statement.timePeriod().sortableString() === period.sortableString(); });
  }

}

export default CraftFinancialStatement;
