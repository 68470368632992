import styled from 'styled-components';
import KlueButton from 'images/klue-salesforce-button.svg';

// This is the scale Jesse is using for mocks
const scale = [3, 6, 12, 24, 44, 88];
const colors = {
  primary: '#40BB8E',
  white: 'white',
  sfdcgrey: '#DDDBDA'
};

export const ErrorText = styled.span`
  color: red;
  font-size: 12px;
`;

export const HintText = styled.span`
  color: ${colors.primary};
  font-size: 12px;
  padding-left: 4px;
  margin-top: 2px;
`;

export const HintContainer = `
  display: 'flex',
  flexDirection: 'column',
  float: left,
  margin: 0
  `;

export const BattlecardsContainer = `
  display: 'flex',
  flexDirection: 'column'
  `;

export const showsComponent = ({showComponent = false}) => ({
  opacity: showComponent ? 1 : 0,
  height: '34px',
  width: '100%',
  transition: 'opacity 0.18s ease-in',
  willChange: 'opacity',
  padding: '0px'
});

export const Container = styled.div`
  display: flex;
`;

/* eslint-disable max-len */
export const Select = styled.select`
  height: 34px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px !important;
  border: 1px solid ${colors.sfdcgrey};
  background-color: ${colors.white};
  color: #636168;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAwIDEwMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGZpbGw9JyM2MzYxNjgnIHN0cm9rZT0iIzYzNjE2OCIgc3Ryb2tlLXdpZHRoPSI1IiBkPSJNNTAsNjQuNWMwLjcsMCwxLjUtMC4zLDIuMS0wLjhsMjQuNS0yM2MxLjItMS4xLDEuMy0zLDAuMS00LjJjLTEuMS0xLjItMy0xLjMtNC4yLTAuMUw1MCw1Ny40TDI3LjUsMzYuMyAgIGMtMS4yLTEuMS0zLjEtMS4xLTQuMiwwLjFzLTEuMSwzLjEsMC4xLDQuMmwyNC41LDIzQzQ4LjUsNjQuMiw0OS4zLDY0LjUsNTAsNjQuNXoiPjwvcGF0aD48L2c+PC9zdmc+) no-repeat white;
  background-size: ${scale[3]}px;
  background-position: right ${scale[1]}px center;
  appearance: none;
  outline: 0;
  padding-left: 6px;
  padding-right: 30px;
  margin-right: 0 !important;
  margin-left: 0 !important;
`;
/* eslint-enable max-len */

export const Button = styled.button`
  height: 34px;
  width: 120px;
  display: flex;
  justify-content: center;
  margin-left: ${scale[0]}px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${colors.white};
  background-size: contain;
  border-radius: 4px !important;
  border: 1px solid #00874A;
  padding: 0 20 0 20;
  cursor: pointer;
  outline: 0;
  text: #362E77;
  padding-top: 5px;
  font-size: inherit;
  white-space: nowrap;
`;
