import {removeOuterPTags} from './comment_utils';
import {processLinks} from './html_utils';

export const getRivalMatches = (rivals = {}, competitors = []) => {
  if(_.isEmpty(rivals) || _.isEmpty(competitors)) {
    return [];
  }

  const normalizedCompetitors = competitors.map(c => c.toLowerCase());

  return Object.keys(rivals)
    .filter(rivalId => {
      const rivalObj = rivals[rivalId];

      return normalizedCompetitors.includes(rivalObj.name.toLowerCase());
    })
    .map(rivalId => rivals[rivalId]);
};

// add "magic" klue-view hash (to trigger extension open)
export const getKlueUrl = (urlIn, postId) => {
  let urlOut = urlIn;

  // try...catch to handle invalid URL strings
  try {
    const url = new URL(urlIn);
    const hash = (url.hash || '').replace(/(?:#|~)klue-view-\d+/gi, '');

    // if (non-klue) hash exists, merge with ours
    url.hash = `${hash ? `${hash}~` : ''}klue-view-${postId}`;
    urlOut = url.toString();
  }
  finally{
    return urlOut;
  }
};

export const redirectToKlueUrl = (url, postId) => {
  const targetUrl = getKlueUrl(url, postId);

  // note name can be changed by the host window, this is what Facebook does.
  // There is no good way around it
  window.open(targetUrl, `klue-post-details-${postId}`);
};

export const getPinnedComment = (post = {}, options = {}) => {
  const {htmlEditorEnabled = false} = options;

  const {bodyPlain = null, bodyHtml = null} = post.pinnedComment || {};

  if(htmlEditorEnabled) {
    if(bodyHtml) {
      return bodyHtml;
    }

    if(bodyPlain) {
      return processLinks(bodyPlain);
    }
  }
  else {
    if(bodyPlain) {
      return bodyPlain;
    }

    if(bodyHtml) {
      return removeOuterPTags(bodyHtml);
    }
  }

  return null;
};
