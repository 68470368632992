import {useState, useEffect} from 'react';

import {BATTLE_CARD_LAYOUT} from '../../modules/constants/ui';

import Dropdown from '../_dropdown';
import classNames from 'classnames';
import DigestTypeCheckbox from '../_digest_type_checkbox';

const layoutOptions = Object.keys(BATTLE_CARD_LAYOUT).map(layoutKey => {
  const layoutValue = BATTLE_CARD_LAYOUT[layoutKey];

  return {
    value: layoutValue,
    label: layoutValue === 'single' ? 'single page' : layoutValue,
    dataTestingId: layoutValue
  };
});

const BattlecardsEditorLayouts = ({battlecard, handleLayoutSelect, canSetDefault, companyLayoutPrefs}) => {
  const [layout, setLayout] = useState(battlecard?.layout || BATTLE_CARD_LAYOUT.single);
  const [instanceLayout, setInstanceLayout] = useState(companyLayoutPrefs || BATTLE_CARD_LAYOUT.single);
  const [checkboxChecked, setCheckboxChecked] = useState(instanceLayout === layout);
  const [checkboxDisabled, setCheckboxDisabled] = useState(checkboxChecked && (layout === BATTLE_CARD_LAYOUT.single));
  const [updateEnabled, setUpdateEnabled] = useState(false);

  useEffect(() => {
    setCheckboxChecked(instanceLayout ? instanceLayout === layout : layout === BATTLE_CARD_LAYOUT.single);
    setCheckboxDisabled(checkboxChecked && (layout === BATTLE_CARD_LAYOUT.single));
    setUpdateEnabled((battlecard?.layout ? layout !== battlecard?.layout : layout !== BATTLE_CARD_LAYOUT.single) ||
    (companyLayoutPrefs ? instanceLayout !== companyLayoutPrefs : instanceLayout && instanceLayout !== BATTLE_CARD_LAYOUT.single));
  }, [layout, instanceLayout, checkboxChecked, battlecard?.layout, companyLayoutPrefs]);

  useEffect(() => {
    setLayout(battlecard?.layout || BATTLE_CARD_LAYOUT.single);
  }, [battlecard]);

  useEffect(() => {
    setInstanceLayout(companyLayoutPrefs || BATTLE_CARD_LAYOUT.single);
  }, [companyLayoutPrefs]);

  const handleToggleCheckbox = ({checked}) => {
    setInstanceLayout(checked ? (layout !== BATTLE_CARD_LAYOUT.single ? layout : null) : null);
  };

  const handleNewLayoutSelection = newLayout => setLayout(newLayout);

  const handleChangeLayoutClick = () => handleLayoutSelect(layout, instanceLayout);

  return (
    <div className="battlecards-editor-layouts">
      <h2>Default Consumer Layout</h2>
      <div className="battlecards-editor-layouts--selection">
        <div className="battlecards-editor-layouts--select ui-dropdown-panel ui-dropdown-panel--left u-pt-xxs u-mb-m">
          <Dropdown
            label=""
            dataTestId="battlecards_editor_layouts-dropdown"
            displayLabel="Select..."
            options={layoutOptions}
            selectedValue={layout}
            keyPrefix="group"
            className="btn secondary"
            containerClass="ui-dropdown--left"
            alignMenuLeft={true}
            onOptionClick={handleNewLayoutSelection} />
        </div>
        <div
          data-testid="change-layout-button"
          className={classNames('button', {disabled: !updateEnabled})}
          data-tracking-id="change-layout-button-from-profile"
          onClick={handleChangeLayoutClick}>Update</div>
      </div>
      {canSetDefault && <div className="set-default-wrapper">
        <DigestTypeCheckbox
          dataTestId={'set-default-checkbox'}
          name="Set as default layout when adding new Battlecards"
          checked={checkboxChecked}
          disabled={checkboxDisabled}
          transparentOnChecked={true}
          onClick={handleToggleCheckbox} />
      </div>}
    </div>
  );
};

BattlecardsEditorLayouts.propTypes = {
  battlecard: PropTypes.object,
  handleLayoutSelect: PropTypes.func,
  canSetDefault: PropTypes.bool,
  companyLayoutPrefs: PropTypes.string
};

BattlecardsEditorLayouts.defaultProps = {
  battlecard: {},
  handleLayoutSelect() {},
  canSetDefault: false,
  companyLayoutPrefs: ''
};

export default BattlecardsEditorLayouts;
