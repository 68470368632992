// Motion
import {spring, Motion, presets} from 'react-motion';
import cx from 'classnames';
const smooth = presets.noWobble;

class KlueBot extends React.Component {

  static propTypes = {
    cb: PropTypes.func.isRequired,
    motion: PropTypes.object.isRequired,
    toggle: PropTypes.bool.isRequired
  };

  static defaultProps = {
    cb() {},
    motion: null,
    toggle: false
  };

  cb = () => {
    const {toggle, cb} = this.props;

    if(!toggle) { return; }

    cb();
  };

  render() {
    const {toggle, motion} = this.props;

    const styles = {
      o: spring(toggle ? motion.to.o : motion.from.o, smooth),
      x: spring(toggle ? motion.to.x : motion.from.x, smooth),
      y: spring(toggle ? motion.to.y : motion.from.y, smooth)
    };

    return (
      <Motion style={styles} onRest={this.cb}>
        {s => {
          const moveTo = {
            opacity: s.o,
            transform: 'translate3d(' + s.x + 'em,' + s.y + 'em, 0)'
          };

          return (
            <img style={moveTo} className={cx('onboarding_kluebot onboarding_kluebot--one', motion.cl)} src="/kluebot.png" />
          );
        }}
      </Motion>
    );
  }

}

export default KlueBot;
