import {useState} from 'react';
import Icon from '../_icon';
import CommentTruncate from '../_comment_truncate';
import {allowlistedAttributes, processLinks, sanitizeInput} from '../../modules/html_utils';
import {isViaSlack, isViaTeams} from '../../modules/comment_utils';

import {Link} from 'react-router-dom';
import moment from 'moment';

const PinnedFeedComment = ({onUnpinClick, pinnedComment}, context) => {
  const {utils: {userCanCurate, company}} = context;
  const {bodyHtml, timestamp, userName, userId} = pinnedComment;
  const [unPinning, setUnpinning] = useState(false);
  const viaSlack = isViaSlack(pinnedComment);
  const viaTeams = isViaTeams(pinnedComment);
  const isCurator = userCanCurate();
  // Disallow in-line (pasted) styles
  const allowedAttributes = {...allowlistedAttributes, '*': allowlistedAttributes['*'].filter(attr => attr !== 'style')};
  const text = processLinks(
    company?.companyData?.enableStripPinnedFeedCommentStyles ? sanitizeInput(bodyHtml, {allowedAttributes}) : bodyHtml,
    {processAtMentions: !viaSlack && !viaTeams}
  );
  const handleUnpinClick = () => {
    setUnpinning(true);
    onUnpinClick();
  };

  return (
    <div className="pinned-comment">
      <div className="pinned-comment-header">
        <div className="pinned-comment-header--title">
          <Icon icon="pin" width="22" height="22" />
          <span>Why it matters</span>
        </div>
        {isCurator ?
          !unPinning ?
            <span className="pinned-comment-header--unpin" onClick={handleUnpinClick}>
              UNPIN COMMENT
            </span>
            :
            <span className="pinned-comment-header--unpin">UNPINNING COMMENT...</span>
          : null}
      </div>
      <div className="pinned-comment-body">
        <div className="pinned-comment-body--meta">
          <span>
            <time dateTime={timestamp} title={new Date(timestamp)}>{moment(timestamp).fromNow()}</time>
            {' '}by:
          </span>
          <Link to={`/users/${userId}`} title={userName}>{`${userName} `} </Link>
        </div>
        <CommentTruncate text={text} />
      </div>
    </div>
  );
};

PinnedFeedComment.propTypes = {
  onUnpinClick: PropTypes.func,
  pinnedComment: PropTypes.object
};

PinnedFeedComment.defaultProps = {
  onUnpinClick() {},
  pinnedComment: null
};

PinnedFeedComment.contextTypes = {
  utils: PropTypes.object.isRequired
};

export default PinnedFeedComment;
