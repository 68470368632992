import Icon from '../_icon';
import Spinner from '../_spinner';
import classNames from 'classnames';

export const testIds = {
  tagItemHeader: 'tag-item-header',
  tagItemHeaderName: 'tag-item-header-name'
};

const TagItemHeader = ({title, isConflicted, isLoading}) => {
  return (
    <div className="tag-header" data-testid={testIds.tagItemHeader}>
      <span>
        <Icon icon="tag" />
      </span>
      <span className={classNames('tag-header-name', {conflicted: isConflicted})} data-testid={testIds.tagItemHeaderName}>{title}</span>
      {isLoading && <Spinner />}
    </div>
  );
};

TagItemHeader.propTypes = {
  title: PropTypes.string,
  isConflicted: PropTypes.bool,
  isLoading: PropTypes.bool
};

TagItemHeader.defaultProps = {
  title: '',
  isConflicted: false,
  isLoading: false
};

export default TagItemHeader;
