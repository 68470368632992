import {update, post, destroy} from '../api_utils';
import {isValidId} from '../utils';

// # POST /api/rival/:rivalId/metabase_subscription.json
// # params:
// #  rivalId: required, id of rival to associate with
// #  userId:  optional, id of user (set to kluebot user if not set)
// #  query:   optional, must be a well-formed query string in Klue query format,
// #           call will fail if query does not pass parsing and validation
// #           (see query_to_regex.rb for allowed syntax). If not specified,
// #           a default query will be created.
// #  userQuery: optional, must be a well-formed query string in user query format,
// #             call will fail if query does not pass parsing and validation
// #             (see user_query_to_query.rb for allowed syntax),
// #             only takes effect if query is not passed
// #  state:   optional, one of active|inactive, defaults to active
export const metabaseQueryCreate = ({metabaseQueryOptions = {}, code = 'ApiUtils.metabaseQueryCreate'}) => {
  return new Promise((resolve, reject) => {
    const {rivalId, ...params} = metabaseQueryOptions;

    if(!isValidId(rivalId)) {
      console.warn(`${code}: invalid options specified: metabaseQueryOptions: %o`, metabaseQueryOptions);

      return reject(metabaseQueryOptions);
    }

    post(`/api/rival/${rivalId}/metabase_subscription.json`, JSON.stringify(params), {code})
      .then(({data}) => {
        console.log(`${code}: data: %o`, data);

        resolve(data);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

// # PUT /api/metabase_subscriptions/123.json
// # params:
// #  userId:  optional, pass id of user to set editorship
// #  query:   optional, must be a well-formed query string in Klue query format,
// #           call will fail if query does not pass parsing and validation
// #           (see query_to_regex.rb for allowed syntax)
// #  userQuery: optional, must be a well-formed query string in user query format,
// #             call will fail if query does not pass parsing and validation
// #             (see user_query_to_query.rb for allowed syntax),
// #             only takes effect if query is not passed
// #  state:   optional, one of active|inactive
export const metabaseQueryUpdate = ({metabaseQueryOptions = {}, code = 'ApiUtils.metabaseQueryUpdate'}) => {
  return new Promise((resolve, reject) => {
    const {id, ...params} = metabaseQueryOptions;

    if(_.isEmpty(metabaseQueryOptions) || _.isEmpty(params) || !isValidId(id)) {
      console.warn(`${code}: invalid options specified: metabaseQueryOptions: %o`, metabaseQueryOptions);

      return reject(metabaseQueryOptions);
    }

    update(`/api/metabase_subscriptions/${id}.json`, JSON.stringify(params), {code})
      .then(({data}) => {
        console.log(`${code}: data: %o`, data);

        resolve(data);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

// # DELETE /api/metabase_subscriptions/123.json
export const metabaseQueryDelete = ({metabaseQueryOptions = {}, code = 'ApiUtils.metabaseQueryDelete'}) => {
  // options format:
  //   metabaseQueryOptions: {
  //     id: int                  // required
  //   }
  return new Promise((resolve, reject) => {
    const {id} = metabaseQueryOptions;

    if(_.isEmpty(metabaseQueryOptions) || !isValidId(id)) {
      console.warn(`${code}: invalid options specified: metabaseQueryOptions: %o`, metabaseQueryOptions);

      return reject(metabaseQueryOptions);
    }

    destroy(`/api/metabase_subscriptions/${id}.json`, {code})
      .then(({data}) => {
        console.log(`${code}: data: %o`, data);

        resolve(data);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};
