import {useRef} from 'react';
import DigestsUpcoming from './_digests_upcoming';
import DigestsSent from './_digests_sent';
import ReactPaginate from 'react-paginate';
import Icon from './_icon';
import Spinner from './_spinner';
import {userCanCurate, userIsAdmin} from '../modules/roles_utils';
import {useDigests} from '../contexts/_digests';
import {Link} from 'react-router-dom';

const DigestsTimeline = ({
  rivals,
  user,
  onEditDigest,
  onEditDigestSettings,
  onViewSentDigest,
  onDeleteDigestType,
  onMakeDefaultDigest,
  onToggleDeleteAfterSend,
  onDeleteSentDigest,
  canEditDigest,
  onAddDigestType,
  onAddOneTimeDigest
}) => {
  const isAdmin = userIsAdmin({user});
  const isCurator = userCanCurate({user});
  const {
    upcomingDigests,
    sentDigests,
    overviewPage,
    setOverviewPage,
    overviewPageCount,
    loadingSentDigests,
    loadingUpcomingDigests
  } = useDigests();
  const iconPrevious = (
    <Icon icon="arrow-left" />
  );
  const iconNext = (
    <Icon icon="arrow-right" />
  );
  const scrollRef = useRef(null);
  const showLoading = (loadingUpcomingDigests && overviewPage === 0) || loadingSentDigests;

  const handlePaginationClick = pageData => {
    scrollRef.current.scrollTop = 0;
    setOverviewPage(pageData.selected);
  };

  return (
    <div className="ui-wrapper">
      <div ref={scrollRef} className="digests-timeline">
        <div className="digest-timeline-container">
          <div className="timeline-header-big">
            <div className="timeline-header-title">The Intel Digest</div>
            <div className="timeline-header-buttons">
              {isAdmin && <button className="primary" onClick={onAddDigestType} data-tracking-id="new-recurring-digest-button">New Recurring Digest </button>}
              {isAdmin && <button onClick={onAddOneTimeDigest} data-tracking-id="new-one-time-digest-button">New One-Time Digest </button>}
              {!isCurator && <Link to={`/users/${user.id}#subs`}><button data-tracking-id="update-digest-settings-button">Update Settings</button></Link>}
            </div>
          </div>
          {showLoading && <div className="digests-timeline-loading"><Spinner /></div>}
          {!showLoading && overviewPage === 0 && <DigestsUpcoming
            digests={upcomingDigests}
            rivals={rivals}
            user={user}
            canEditDigest={canEditDigest}
            onEditDigest={onEditDigest}
            onEditDigestSettings={onEditDigestSettings}
            onDeleteDigestType={onDeleteDigestType}
            onMakeDefaultDigest={onMakeDefaultDigest}
            onToggleDeleteAfterSend={onToggleDeleteAfterSend} />}
          {!showLoading && <DigestsSent
            digests={sentDigests}
            rivals={rivals}
            user={user}
            canEditDigest={canEditDigest}
            onViewSentDigest={onViewSentDigest}
            onEditDigestSettings={onEditDigestSettings}
            onDeleteSentDigest={onDeleteSentDigest} />}
          {!showLoading && sentDigests?.length ? <div className="digest-pagination">
            <ReactPaginate
              previousLabel={(overviewPage === 0) ? null : iconPrevious}
              nextLabel={(overviewPage === overviewPageCount) ? null : iconNext}
              breakLabel={<span>...</span>}
              breakClassName={'break-me'}
              forcePage={overviewPage}
              pageCount={overviewPageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePaginationClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'paginator_active'} />
          </div> : null}
        </div>
      </div>
    </div>
  );
};

DigestsTimeline.propTypes = {
  rivals: PropTypes.object,
  user: PropTypes.object,
  onEditDigest: PropTypes.func.isRequired,
  onEditDigestSettings: PropTypes.func.isRequired,
  onViewSentDigest: PropTypes.func.isRequired,
  canEditDigest: PropTypes.func.isRequired,
  onDeleteDigestType: PropTypes.func.isRequired,
  onDeleteSentDigest: PropTypes.func.isRequired,
  onAddDigestType: PropTypes.func.isRequired,
  onAddOneTimeDigest: PropTypes.func.isRequired,
  onMakeDefaultDigest: PropTypes.func,
  onToggleDeleteAfterSend: PropTypes.func
};

DigestsTimeline.defaultProps = {
  rivals: {},
  user: {},
  onMakeDefaultDigest: null,
  onToggleDeleteAfterSend: null
};

export default DigestsTimeline;
