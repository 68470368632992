import React, {Component} from 'react';
import PropTypes from 'prop-types';

class EmbedHtmlBuilder extends Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    onChange() {}
  };

  state = {
    htmlContent: ''
  };

  componentDidMount() {
    this._fireOnChange();
  }

  _generateFunction = () => {
    return `EMBED_HTML("${this.state.htmlContent}")`;
  };

  _fireOnChange = () => this.props.onChange(this._generateFunction());

  _handleTextareaChanged = event => {
    const value = event.target.value.replace(/"/g, '\'').replace(/\r?\n|\r/g, '');

    this.setState({htmlContent: value}, () => this._fireOnChange());
  };

  render() {
    return (
      <div data-cy="embedHtmlFormTextareaWrapper">
        <textarea className="klue-formula-wizard_control-textarea" onChange={this._handleTextareaChanged} />
      </div>
    );
  }

}

export default EmbedHtmlBuilder;
