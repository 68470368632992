const ModalEditorPermissionsHeader = ({loading}) => {
  const loader = loading && (
    <div className="permission-manager-activity">
      <div className="permission-manager-activity_loader permission-manager-activity_loader--loading" />
    </div>
  );

  return (
    <div data-test-id="permission-manager-header" className="permission-manager-intro">
      <h3 className="permission-manager-intro_title">
        Who can see this card?
      </h3>
      <div className="permission-manager-intro_message">
        <em>Select an option to restrict access to this card</em>
      </div>
      {loader}
    </div>
  );
};

ModalEditorPermissionsHeader.propTypes = {
  loading: PropTypes.bool
};

ModalEditorPermissionsHeader.defaultProps = {
  loading: false
};

export default ModalEditorPermissionsHeader;
