import classNames from 'classnames';
import {notificationTypes} from '../../modules/constants/editor';

const ModalEditorMessage = ({visible, content, type}) =>
  <div className={classNames('modal-editor-message', type, {visible})}>{content}</div>;

ModalEditorMessage.propTypes = {
  visible: PropTypes.bool,
  content: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['', ...Object.keys(notificationTypes).map(i => notificationTypes[i])])
};

ModalEditorMessage.defaultProps = {
  visible: false,
  content: '',
  type: notificationTypes.SUCCESS
};

export default ModalEditorMessage;
