import DropdownMenu from './_dropdown_menu';
import Icon from './_icon';

import {userCanCurate} from '../modules/roles_utils';
import {alertMessageForPublishedBattlecard, battlecardReviewedAlert, battlecardReviewedAlertCardErrorMessage} from '../modules/battlecard_utils';
import {isValidId} from '../modules/utils';

import {BATTLE_CARD_LAYOUT} from '../modules/constants/ui';

import classNames from 'classnames';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import ViewAsDropdown from './_view_as_dropdown';

class BattlecardToolbar extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    onFullscreenClick: PropTypes.func.isRequired,
    onSelectLayout: PropTypes.func.isRequired,
    onSetDefaultLayout: PropTypes.func.isRequired,
    onViewAs: PropTypes.func,
    battlecardCards: PropTypes.array
  };

  static defaultProps = {
    history: {},
    location: {},
    users: {},
    onFullscreenClick() {},
    onSelectLayout() {},
    onSetDefaultLayout() {},
    onViewAs() {},
    battlecardCards: []
  };

  state = {};

  componentDidMount() {
    // DEBUG
    console.log('BattlecardToolbar.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    const {battlecard, requestUser} = this.context.utils;
    const {review} = battlecard || {};

    if(review && review.byUserId) {
      requestUser({userId: review.byUserId});
    }
  }

  _copyPlainTextToClipboard = text => {
    const input = document.createElement('textarea');
    let copiedStatus = false;

    document.body.appendChild(input);
    // Taken from: http://stackoverflow.com/questions/25099409/copy-to-clipboard-as-plain-text/25275151#25275151
    input.value = _.escape(text || ''); // Escape to avoid XSS when providing unknown input (see stackoverflow answer);
    input.focus();
    input.select();

    // Try to copy
    try {
      copiedStatus = document.execCommand('copy'); // FF 41+, Chrome, IE 9+
    }
    catch(e) {
      copiedStatus = false;
    }

    input.remove();

    return copiedStatus;
  };

  isPreviewing = () => {
    const {location} = this.props;
    const queryParams = new URLSearchParams(location.search);
    const previewGroup = queryParams.get('previewing');
    const visibilityGroup = parseInt(previewGroup, 10);

    return isValidId(visibilityGroup, true);
  };

  handleShareClick = () => {
    this.renderShareModal();
  };

  handleShareModalCloseClick = () => {
    this.context.utils.dialog.remove('modal-battlecard-share');
  };

  handlePrintClick = event => {
    if(event) {
      event.preventDefault();
    }

    this.context.utils.dialog.remove('modal-battlecard-share');

    // Delay to hide modal before opening print dialog
    setTimeout(() => {
      window.print();
    }, 400);
  };

  handleCopyURLClick = text => {
    const copied = this._copyPlainTextToClipboard(text);
    let alertMessage;

    if(copied) {
      alertMessage = '<strong>Copied URL to clipboard!</strong><br /><br />You can now share with team members.';
    }
    else {
      alertMessage = 'To copy, select the URL then copy &amp; paste to share with your team members.';
    }

    this.context.utils.dialog.alert(alertMessage);
  };

  handleCreateCommentClick = () => {
    this.context.utils.handleCreateProfileCommentClick(this.context.utils.battlecard.id, 'battlecard');
  };

  handlePublishClick = () => {
    const {battlecard, dialog} = this.context.utils;
    const isDraft = !battlecard.isDraft;
    const title = battlecard.title || '(Untitled Battlecard)';
    const battlecardOptions = {
      id: battlecard.id,
      isDraft
    };

    dialog.remove('modal-battlecard-publish');

    this.context.api.battlecardCreateOrUpdate(battlecardOptions, () => {
      // force update .context data
      this.forceUpdate();

      const message = alertMessageForPublishedBattlecard(title, !isDraft);

      dialog.alert(message);
    });
  };

  handleToggleDraftClick = () => {
    const {battlecard, dialog} = this.context.utils;

    if(_.isEmpty(battlecard)) {
      return;
    }

    const {battlecardCards} = this.props;
    const draftCards = battlecardCards.filter(card => card.isDraft);

    if(battlecard.isDraft && draftCards.length > 0) {
      const uiModalContent = (
        <div>
          <h3 className="heading-dialog">This battlecard has cards set to &apos;Curator Only&apos;.
            We suggest updating visibility permissions on the following:</h3>
          <ul>
            {draftCards.map((card, index) => (
              <li key={card.id}>
                <a href={
                `${window.location.origin}/profile/${card.board?.profileId}/edit/card/${card.id}/edit`}
                  target="_blank"
                  className="external-link">{card.data?.name || `Card #${index + 1}`}</a></li>
            ))}
          </ul>
          <div className="button-wrapper">
            <div className="button-legacy button-legacy--size-normal button-legacy--secondary button-legacy--caps"
              onClick={() => dialog.remove('modal-battlecard-publish')}><strong>Cancel</strong></div>
            <div className="button-legacy button-legacy--size-normal button-legacy--primary button-legacy--caps"
              onClick={this.handlePublishClick}><strong>Publish Anyway</strong></div>
          </div>
        </div>
      );

      dialog.create({
        id: 'modal-battlecard-publish',
        _wideMode: true,
        content: uiModalContent
      });
    }
    else {
      this.handlePublishClick();
    }
  };

  handleOnDidUpdateBattlecardCards = ({error, userUpdateError}) => {
    if(userUpdateError) {
      console.log('BattlecardToolbar.handleOnDidUpdateBattlecardCards: failed to update user default');
    }

    if(error) {
      const {utils: {dialog}} = this.context;

      dialog.alert(battlecardReviewedAlertCardErrorMessage);
    }
  };

  handleMarkAsReviewedClick = () => {
    const {utils: {battlecard}, api: {battlecardCreateOrUpdate}} = this.context;

    if(_.isEmpty(battlecard)) {
      return;
    }

    // DEBUG
    console.log('BattlecardToolbar.handleMarkAsReviewedClick: battlecard: %o', battlecard);

    const battlecardOptions = {
      id: battlecard.id,
      profileId: battlecard.profile.id,
      markAsReviewed: true
    };

    battlecardCreateOrUpdate(battlecardOptions, () => {
      // force update .context data (in this case it's the battlecard.reviewedAt timestamp)
      this.forceUpdate();

      return battlecardReviewedAlert({
        battlecards: [battlecard],
        context: this.context,
        onDidUpdateBattlecardCards: this.handleOnDidUpdateBattlecardCards
      });
    });
  };

  handleReviewUserClick = userId => {
    const {history} = this.props;

    if(!userId) {
      return;
    }

    history.push(`/users/${userId}`);
  };

  handleDonePreviewingClick = () => {
    const {history, location} = this.props;
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('previewing');

    history.push({
      pathname: location.pathname.replace(/(\/view\b)(?!.*\1)/, '/edit'),
      search: queryParams.toString()
    });
  };

  renderShareModal = () => {
    const uiModalContent = (
      <div>
        <div className="u-mb-xxs"><i className="fa fa-lock" /> <strong>Share Private Link</strong></div>
        <div className="u-mb-xs">Only team members in Klue can access this battlecard</div>
        <div className="grid-row">
          <div className="grid-row_child grid-row_child--grow">
            <input type="text" className="form-control u-w100 disabled" readOnly={true} value={window.location} />
          </div>
          <div className="grid-row_child">
            <div className="button-legacy button-legacy--size-normal button-legacy--primary" onClick={() => this.handleCopyURLClick(window.location)}>Copy</div>
          </div>
        </div>
        <div className="u-pt-l text-right">
          <div className="button-legacy button-legacy--size-normal button-legacy--secondary button-legacy--caps"
            onClick={this.handleShareModalCloseClick}><strong>Close</strong></div>
        </div>
      </div>
    );

    this.context.utils.dialog.create({
      id: 'modal-battlecard-share', // calling with same id with just update the contents
      _wideMode: true,
      content: uiModalContent
    });
  };

  getCurrentBattlecardCardIds = () => {
    const {battlecard} = this.context.utils;
    let currentCards = [];

    if(battlecard && battlecard.cards) {
      currentCards = (battlecard.cards.desktop || []).filter(cardId => parseInt(cardId, 10) > -1);
    }

    return currentCards;
  };

  redirectToCuratorTools = () => {
    const {utils: {battlecard: {profile: {id: profileId} = {}, id: battleCardId} = {}}} = this.context;

    window.open(`/profile/${profileId}/battlecard/edit/${battleCardId}`, '_self');
  };

  renderReview = () => {
    const {users} = this.props;
    const {user, battlecard, battlecard: {review = {}}} = this.context.utils;
    const {isDraft} = battlecard;
    const uiReviewedAtTime = (review && review.reviewedAt) ? `${moment(review.reviewedAt).fromNow(true)} ago` : '(Never)';
    const reviewedByUser = (review && review.byUserId) ? (users || {})[review.byUserId] : null;
    const uiReview = [];
    let reviewedByRegion;

    if(reviewedByUser) {
      reviewedByRegion = (
        <div className="toolbar-item_text toolbar-item_text--link" onClick={() => this.handleReviewUserClick(reviewedByUser.id)}>
          @{reviewedByUser.username || 'team_member'}
        </div>
      );
    }

    const currentTitle = battlecard.title !== null && battlecard.title.length > 0 ? battlecard.title : '(Untitled battlecard)';

    uiReview.push(
      <div key={'review-msg'} className="toolbar-item toolbar-item--info">
        <div className="toolbar-item_text toolbar-item_text--bold toolbar-item_text--battlecard-name">{currentTitle}</div>
        <div
          className="toolbar-item_text toolbar-item_text--reviewed-by"
          data-tracking-id="battlecard-reviewed-label">Reviewed: {uiReviewedAtTime}{reviewedByUser ? ' by' : ''}</div>
        {reviewedByRegion}
      </div>
    );

    if(userCanCurate({user})) {
      uiReview.push(
        <div className="toolbar-action-buttons" key="toolbar-action-buttons">
          <div
            key={'review-btn'}
            className="toolbar-item toolbar-action-button"
            onClick={this.handleMarkAsReviewedClick}
            data-tracking-id="battlecard-mark-as-reviewed">Mark as reviewed</div>
          <div key={'publish-btn'} className="toolbar-item toolbar-action-button" onClick={this.handleToggleDraftClick}>
            {isDraft ? 'Publish' : 'Unpublish'}
          </div>
        </div>
      );
    }

    return uiReview;
  };

  render() {
    const {onSetDefaultLayout, onSelectLayout, onFullscreenClick, onViewAs} = this.props;
    const previewing = this.isPreviewing();
    const {utils} = this.context;

    const currentCards = this.getCurrentBattlecardCardIds();
    const battlecardHasContent = currentCards.length > 0;
    const {user, battlecard, printBattlecardDisabled = () => false, rival: {profile = {}}} = utils;
    const {layout: defaultLayout = BATTLE_CARD_LAYOUT.single, isDraft = false} = battlecard || {};

    let uiActionButtons = null;
    let donePreviewingButton = null;

    if(previewing) {
      donePreviewingButton = (
        <div key="done-previewing" className="toolbar-button toolbar-button--green" onClick={this.handleDonePreviewingClick}>DONE PREVIEWING</div>
      );
    }

    // Only render the action buttons if we have cards
    if(battlecardHasContent) {
      const userLayoutPrefs = user.userData.battlecardLayouts || {};
      const layout = userLayoutPrefs[battlecard.id]
        ? userLayoutPrefs[battlecard.id]
        : battlecard.layout !== 'unset'
          ? battlecard.layout
          : BATTLE_CARD_LAYOUT.single;
      let commentsCountBadge = null;

      const layoutOptions = [
        [BATTLE_CARD_LAYOUT.single, (<div key="view-icon1" className="dropdown-item"><Icon icon="battlecard-single" width="100" height="100" /></div>)],
        [BATTLE_CARD_LAYOUT.masonry, (<div key="view-icon2" className="dropdown-item"><Icon icon="battlecard-masonry" width="100" height="100" /></div>)],
        [BATTLE_CARD_LAYOUT.hero, (<div key="view-icon3" className="dropdown-item"><Icon icon="battlecard-hero" width="100" height="100" /></div>)],
        [BATTLE_CARD_LAYOUT.stacker, (<div key="view-icon4" className="dropdown-item"><Icon icon="battlecard-stacker" width="100" height="100" /></div>)]
      ];
      const isDefaultLayout = layout === defaultLayout;
      const getDefaultLayoutText = activeLayout => {
        return isDefaultLayout
          ? `${activeLayout === 'single' ? 'Single Page' : activeLayout} is default for everyone`
          : 'Set as default for everyone?';
      };

      const canSetDefault = userCanCurate({user});

      const setAsDefault = (
        <div className="battlecard-setDefault">
          <span>{getDefaultLayoutText(layout)}</span>
          {isDefaultLayout ?
            <i className={classNames('battlecard-setDefault-status', {
              'battlecard-setDefault-status--success': true
            })} /> :
            <button
              type="button"
              className="button button--small button-setDefault"
              data-action="setDefault"
              title="Set as default layout for all users"
              onClick={onSetDefaultLayout}>Set as default</button>}
        </div>
      );

      const setDefaultsRedirect = (
        <div className="battlecard-setDefault">
          <span>This will only impact your view. Set Default Consumer Layout
            <span className="battlecard-set-consumer-layout-link" onClick={this.redirectToCuratorTools}> here</span>
          </span>
        </div>
      );

      if(battlecard.commentsCount > 0) {
        commentsCountBadge = (
          <div className="toolbar-button_indicator">{battlecard.commentsCount}</div>
        );
      }

      let printButton = null;

      if(!printBattlecardDisabled()) {
        printButton = (
          <div className="toolbar-button toolbar-button--print" onClick={this.handlePrintClick} title="Print">
            <div className="toolbar-button_icon"><Icon icon="print" width="24" height="24" /></div>
          </div>
        );
      }

      uiActionButtons = (
        <div className="toolbar_right">
          <div className="toolbar-button toolbar-button--fullscreen" onClick={onFullscreenClick} title="Fullscreen">
            <div className="toolbar-button_icon"><Icon icon="fullscreen" width="24" height="24" /></div>
          </div>
          <div className="toolbar-button" onClick={this.handleCreateCommentClick} title="Comment" data-tracking-id="battlecard-title-commment">
            <div className="toolbar-button_icon"><Icon icon="comment" width="24" height="24" /></div>
            {commentsCountBadge}
          </div>
          <div className="toolbar-button" onClick={this.handleShareClick} title="Share" data-tracking-id="battlecard-title-share">
            <div className="toolbar-button_icon"><Icon icon="share" width="24" height="24" /></div>
          </div>
          {printButton}
          <DropdownMenu
            mobileLabel={<Icon icon="settings" width="24" height="24" />}
            id="battlecardLayoutDropdown"
            values={layoutOptions}
            label="Layouts"
            dataTrackingId="battlecard-layout-button"
            selectCallback={onSelectLayout}
            showSelectedValueWhenCollapsed={false}
            className="battlecard-layout-dropdown"
            selectedValue={layout}>
            {canSetDefault ? setDefaultsRedirect : null}
          </DropdownMenu>
        </div>
      );
    }

    return (
      <div className="toolbar">
        <div className="toolbar_left" data-tracking-id="battlecard-toolbar-left">
          <div className="battlecard-section_toolbar-reviewing">
            {battlecardHasContent && this.renderReview()}
          </div>
          {donePreviewingButton}
          {
            (isDraft || profile?.isDraft)
              ? (<div data-tip="Publish to preview" data-offset="{'top': 0}" data-testid="battlecard-view-as-dropdown_disabled">
                <ViewAsDropdown key="battlecard-view-as-dropdown" disabled={true} trackingId="battlecard-toolbar" onViewAs={onViewAs} />
              </div>)
              : (<ViewAsDropdown key="battlecard-view-as-dropdown" trackingId="battlecard-toolbar" onViewAs={onViewAs} />)
            }
        </div>
        {uiActionButtons}
      </div>
    );
  }

}

export default withRouter(BattlecardToolbar);
