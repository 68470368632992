import {DragSource, DropTarget} from 'react-dnd';
import {flow} from 'lodash';

class DraggableItem extends React.Component {

  static propTypes = {
    children: PropTypes.node,

    // props passed to nested child div
    className: PropTypes.string,
    dragHandle: PropTypes.element,

    // drag-related props
    canDrag: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    onBeginDrag: PropTypes.func,
    onEndDrag: PropTypes.func,
    onHandleHover: PropTypes.func,
    onHandleDrop: PropTypes.func,

    isDragging: PropTypes.bool.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDropOnTarget: PropTypes.bool.isRequired,
    itemType: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string
    ]),
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired
  };

  static defaultProps = {
    children: null,
    className: '',
    dragHandle: null,

    canDrag: null,
    canDrop: null,
    onBeginDrag: null,
    onEndDrag: null,
    onHandleHover: null,
    onHandleDrop: null,

    // react-dnd props
    isDragging: false,
    isOver: false,
    canDropOnTarget: false,
    itemType: null,
    connectDragSource() {},
    connectDragPreview() {},
    connectDropTarget() {}
  };

  render() {
    // destructure className to pass to nested div
    const {className, dragHandle, isDragging, isOver, children, ...rest} = this.props;
    const connectDragSource = this.props.connectDragSource;
    const connectDragPreview = this.props.connectDragPreview;
    const connectDropTarget = this.props.connectDropTarget;
    let childrenWithProps;
    let dragMode;

    // NOTE: [data-drag-mode= ] is used for css styling
    // TODO: LEGACY: older components should pass styles through to children instead of using [data-] attributes
    if(isDragging) {
      dragMode = 'dragging';
    }
    else if(isOver) {
      dragMode = 'over';
    }

    if(dragHandle) {
      const dragProps = {
        isDragging,
        isOver: !isDragging && isOver,
        dragHandle: connectDragSource(dragHandle)
      };

      childrenWithProps = React.Children.map(children, child => React.cloneElement(child, dragProps));
    }

    // TODO: see note above re: legacy drag styling
    const element = (
      <div className={className} data-drag-mode={dragMode}>{childrenWithProps || children}</div>
    );

    if(dragHandle) {
      if(this.props.canDrag && this.props.canDrop) {
        return connectDropTarget(connectDragPreview(element));
      }
      else if(this.props.canDrag) {
        return connectDragPreview(element);
      }
    }
    else if(this.props.canDrag && this.props.canDrop) {
      return connectDropTarget(connectDragSource(element));
    }
    else if(this.props.canDrag) {
      return connectDragSource(element);
    }
    else if(this.props.canDrop) {
      return connectDropTarget(element);
    }

    return element;
  }

}

// React DND helpers

/**
 * Implements the drag source contract.
 */
const itemSource = {
  beginDrag(props) {
    props.onBeginDrag && props.onBeginDrag();

    return _.extend({}, props);
  },

  endDrag(props) {
    props.onEndDrag && props.onEndDrag();
  }
};

/**
 * Implements the drop source contract.
 */
const itemTarget = {
  hover(props, monitor) {
    const dragItemProps = monitor.getItem();
    const targetItemProps = props;

    if(!targetItemProps.canDrop) {
      return;
    }

    props.onHandleHover && props.onHandleHover(dragItemProps, targetItemProps);

    // Note - when reordering on hover, the hover handler must update the
    // dragged item index prop to reflect any ui changes, eg:
    // monitor.getItem().dragIndexProp = hoverIndexProp;

    // For better reordering presision based on cursor position, if needed, see:
    // https://react-dnd.github.io/react-dnd/examples-sortable-simple.html
    // https://github.com/react-dnd/react-dnd/blob/master/examples/04%20Sortable/Simple/Card.js
  },

  drop(props, monitor) {
    const dragItemProps = monitor.getItem();
    const targetItemProps = props;

    if(!targetItemProps.canDrop) {
      return;
    }

    props.onHandleDrop && props.onHandleDrop(dragItemProps, targetItemProps);
  }
};

// specifies the props to inject into your component
function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

// specifies the props to inject into your component
function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDropOnTarget: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

// Exported as a function, so use: require('./_common_dragdropitem')('DD-GROUP-NAME')
export default function(itemGroupName) {
  // NOTE: multiple dragdrop components will interact with each other
  // so giving them a different group name will mean less conflicts
  // for different drag drop features on the same page
  itemGroupName = itemGroupName || 'DDITEM-UNIQUE-NAME';

  // flow combines multiple HoC's into our component
  const ddWrappedComponent = flow(
    DragSource(itemGroupName, itemSource, collectSource),
    DropTarget(itemGroupName, itemTarget, collectTarget)
  )(DraggableItem);

  return ddWrappedComponent;
}
