import {useState} from 'react';

import QueryBuilder from '../_alerts_query_builder/_alerts_query_builder';
import {useAlertSubscriptions} from '../../contexts/_alertSubscriptions';

const AlertRuleComposerEdit = ({rival}) => {
  const {updateAlertsActive, refreshMetabaseSubscription} = useAlertSubscriptions();
  const [isQueryBuilderOpen, setIsQueryBuilderOpen] = useState(false);

  const toggleQueryBuilder = () => {
    setIsQueryBuilderOpen(!isQueryBuilderOpen);
  };

  const updateAlertsActiveStatus = async ({rival: r, alertsActive, updateProfile, code}) => {
    try {
      await updateAlertsActive({rival: r, alertsActive, updateProfile});
    }
    catch{
      console.error(`AlertRuleComposerEdit: failed on ${code}`);
    }
  };

  const handleOnHighVolumeQueryStatusChange = () =>
    updateAlertsActiveStatus({rival, alertsActive: !rival.alertsActive, updateProfile: true, code: 'handleOnHighVolumeQueryStatusChange'});

  const handleOnAlertsActiveUpdate = newAlertsActive => updateAlertsActiveStatus({rival, alertsActive: newAlertsActive, code: 'handleOnAlertsActiveUpdate'});

  const handleOnMetabaseSubscriptionDidUpdate = ({rivalId}) => refreshMetabaseSubscription(rivalId);

  const {id} = rival;

  return (
    <span className="rule-composer-item-meta" data-tracking-id="rule-composer-item-meta">
      <a onClick={() => toggleQueryBuilder()} data-testid="edit" data-tracking-id="rule-composer-item-meta-edit-button">Edit</a>
      {isQueryBuilderOpen && <QueryBuilder
        onClose={toggleQueryBuilder}
        rivalId={id}
        rival={rival || {}}
        alertsActive={rival.alertsActive}
        onHighVolumeQueryStatusChange={handleOnHighVolumeQueryStatusChange}
        onAlertsActiveUpdate={handleOnAlertsActiveUpdate}
        onMetabaseSubscriptionDidUpdate={handleOnMetabaseSubscriptionDidUpdate} />
      }

    </span>
  );
};

AlertRuleComposerEdit.propTypes = {
  rival: PropTypes.object.isRequired
};

export default AlertRuleComposerEdit;
