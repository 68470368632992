import {createContext, useContext} from 'react';
import {analyticsTrack, SNOWPLOW_SCHEMAS} from '../modules/analytics_utils';

export const AnalyticsEventContext = createContext();

export const useAnalyticsEvent = () => {
  const analyticsEventContext = useContext(AnalyticsEventContext);

  if(analyticsEventContext === undefined) {
    return {};
  }

  return analyticsEventContext;
};

const useAnalyticsEventsInternal = props => {
  const {tagClickEvent, viewContext} = props;
  const value = {
    trackTagClickEvent({name}, pathname, event) {
      const {category, action} = tagClickEvent;

      let trackingDataSP = {};

      if(pathname && event) {
        const isBattlecardView = pathname.includes('/battlecard') && !pathname.includes('/edit');
        const isProfileView = pathname.startsWith('/profile') && !isBattlecardView;
        const clickViewSource = isProfileView ? 'board' : isBattlecardView ? 'battlecard' : 'search';

        const cardWrapper = event
          .currentTarget
          ?.closest('.card-tags')
          ?.previousSibling
          ?.querySelector('.card-static-html_body');

        const cardId = parseInt(cardWrapper?.getAttribute('data-ga-card-id'), 10);
        const battlecardId = parseInt(cardWrapper?.getAttribute('data-ga-battlecard-id'), 10);
        const rivalId = parseInt(cardWrapper?.getAttribute('data-ga-rival-id'), 10);

        trackingDataSP = {
          schema: SNOWPLOW_SCHEMAS.cardInteraction,
          data: {
            label: null,
            view: clickViewSource,
            type: 'tag_click',
            cardType: 'klue_card'
          },
          context: []
        };

        if(cardId) {
          trackingDataSP.context.push({
            schema: SNOWPLOW_SCHEMAS.card,
            data: {id: cardId}
          });
        }

        if(rivalId) {
          trackingDataSP.context.push({
            schema: SNOWPLOW_SCHEMAS.board,
            data: {id: rivalId}
          });
        }

        if(battlecardId) {
          trackingDataSP.context.push({
            schema: SNOWPLOW_SCHEMAS.battleCard,
            data: {id: battlecardId}
          });
        }
      }

      if(category) {
        analyticsTrack({
          type: 'event',
          category,
          action: action || 'Tag Click',
          label: `${name}`
        }, trackingDataSP);
      }
    },
    viewContext
  };

  return value;
};

export const AnalyticsEventProvider = props => {
  const {tagClickEvent, children, viewContext} = props;

  const value = useAnalyticsEventsInternal({tagClickEvent, viewContext});

  return (
    <AnalyticsEventContext.Provider value={value}>
      {children}
    </AnalyticsEventContext.Provider>
  );
};

AnalyticsEventProvider.propTypes = {
  children: PropTypes.node,
  tagClickEvent: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string
  }).isRequired,
  viewContext: PropTypes.string
};

AnalyticsEventProvider.defaultProps = {
  children: null,
  tagClickEvent: null,
  viewContext: ''
};

export const withAnalyticsEvent = Component => {
  // eslint-disable-next-line react/no-multi-comp
  return props => (
    <AnalyticsEventContext.Consumer>
      {analyticsEventContext => (
        <Component {...props} analyticsEventContext={analyticsEventContext} />
      )}
    </AnalyticsEventContext.Consumer>
  );
};

