import DropdownMenu from './_dropdown_menu';

const pagingValues = [
  [10, '10'],
  [25, '25'],
  [50, '50'],
  [100, '100'],
  [250, '250'],
  [500, '500']
];

const ManageUsersPerPageDropdown = ({selectedValue, onChangeItemsPerPage, dataTrackingId}) => {
  return (
    <DropdownMenu
      id="itemsPerPage"
      dataTrackingId={dataTrackingId}
      values={pagingValues}
      selectedValue={selectedValue}
      label="Users per page:"
      title="Users per page"
      selectCallback={onChangeItemsPerPage} />
  );
};

ManageUsersPerPageDropdown.propTypes = {
  dataTrackingId: PropTypes.string,
  selectedValue: PropTypes.number,
  onChangeItemsPerPage: PropTypes.func
};

ManageUsersPerPageDropdown.defaultProps = {
  dataTrackingId: '',
  selectedValue: 10,
  onChangeItemsPerPage() {}
};

export default ManageUsersPerPageDropdown;
