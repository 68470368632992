import CompanyLogo from './_company_logo';
import DigestToolbar from './_digest_toolbar';
import DigestMenu from './_digest_menu';
import DigestArchives from './_digest_archives';
import DigestCompetitors from './_digest_competitors';
import DigestEditHeader from './_digest_edit_header';
import FeedPostBox from './_feed_post_box';
import Icon from './_icon';
import KlueLogo from '../../images/icons/favicon.svg';
import DigestBanner from './_digest_banner/_digest_banner';
import DigestNewInKlueToggle from './_digest_new_in_klue_toggle';
import DigestCustomURL from './_digest_custom_url';
import InlineEditor from './editorInline/InlineEditor';
import {editorInsertKlueLink, handleInsertTargetImageURL} from '../modules/editor_utils';
import EditorToolbarCardLinkSelector from './editor/_editor_toolbar_card_link_selector';
import DigestBodyFooter from './_digest_body_footer';
import DigestTemplateSelect from './_digest_template_select';
import Modal from './_modal';

import {
  sanitizeDigestHtml, digestHeaderInputs, canEditDigest, isFavoriteUpdated,
  getRivalsForTags, getDefaultOverviewCopy, getNextDigestUTCDateTime, getFavoriteContent,
  getFavoriteCommentary, getLogoRivalWithRivalId, renderNextDigestSendTime, findDigestTypesDataInsertionIndex
} from '../modules/digest_utils';
import {addTrackingParams, trackingParamSources} from '../modules/analytics_utils';
import {copyToClipboard} from '../modules/clipboard_utils';
import {userCanCurate, userIsAdmin, userIsKluebot} from '../modules/roles_utils';
import {wait, isValidId} from '../modules/utils';
import {processLinks, decodeCommonEntities, wrapHtml} from '../modules/html_utils';
import {pluralize, stripBreaks, stripExtraWhitespace, truncate} from '../modules/text_utils';
import {truncateLimits} from '../modules/constants/ui';
import {DIGESTS_PER_PAGE} from '../modules/constants/api';
import {isDraftRival} from '../modules/rival_utils';
import {getPinnedComment} from '../modules/post_utils';

// dnd components
import DraggableItem from './_draggable_item';
const DigestFavoriteDraggable = DraggableItem('DIGEST-DD-GROUP');

// layout components
import Page from './layout/_page';

import ReactUpdate from 'immutability-helper';
import TextArea from 'react-textarea-autosize';
import moment from 'moment';
import classNames from 'classnames';
import {Link, Prompt, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {compose} from 'redux';
import {withPosts} from '../contexts/_posts';
import WaitingForUploads from './_waiting_for_uploads';
import DigestSettingsModal from './digest_settings/_digest_settings_modal';
import DigestsTimeline from './_digests_timeline';
import {withDigests} from '../contexts/_digests';

const defaultDigestTypeState = Object.freeze({
  editSettingsDigestType: null,
  editSettingsDigestTypeIsNew: false,
  currentActiveDigestType: null,
  editSettingsDigest: null,
  isManageDigestTemplates: false
});

class Digest extends React.Component {

  constructor(props) {
    super(props);

    this.bodyRef = React.createRef();
    this.feedRef = React.createRef();
    this.sideBarRef = React.createRef();
    this.digestArchiveRef = React.createRef();
    this.setupResizeObserver();

    this.state = {
      didSendNowAction: null,
      didSendPreviewAction: false,
      digests: [],
      digest: null,
      digestPosts: {},
      digestTypes: [],
      activeDigestType: {},
      totalDigests: -1,     // -1 value indicates digests are still loading
      page: 1,
      feedMode: false,
      reorderMode: false,
      editingInput: null,   // can be null (disabled), digestHeaderInputs.SUBJECT/SUMMARY, or a valid favoriteId
      hasUnsavedFields: false,
      savingDigest: false,
      imagesUploadingCount: 0,
      favoritesSorted: [],
      findPostId: null,
      subject: '',
      summary: '',
      banner: '',
      headerWidth: props.defaultHeaderWidth ?? '0',
      problemCustoms: []
    };
  }

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired
  };

  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    postsUrl: PropTypes.string,
    rivals: PropTypes.object.isRequired,
    loadOrFetchRivals: PropTypes.func.isRequired,
    rivalGroups: PropTypes.arrayOf(PropTypes.object),
    editMode: PropTypes.bool.isRequired,
    archivesMode: PropTypes.bool.isRequired,
    timelineMode: PropTypes.bool.isRequired,
    onEnqueueToastMessage: PropTypes.func.isRequired,
    onClearToastMessages: PropTypes.func.isRequired,
    toastMessageWithIdentifierExists: PropTypes.func.isRequired,
    digestsContext: PropTypes.shape({
      didUpdateDigestType: PropTypes.func.isRequired,
      didDeleteDigestType: PropTypes.func.isRequired,
      didDeleteSentDigest: PropTypes.func.isRequired,
      didUpdateDigest: PropTypes.func.isRequired,
      refreshDigest: PropTypes.func.isRequired,
      didSetAsDefault: PropTypes.func.isRequired,
      getDigestFromDigestType: PropTypes.func.isRequired,
      reloadDigests: PropTypes.func.isRequired
    }).isRequired,
    postsContext: PropTypes.shape({
      posts: PropTypes.object,
      updatePosts: PropTypes.func,
      refreshPost: PropTypes.func
    }).isRequired
  };

  static defaultProps = {
    match: {},
    history: {},
    users: {},
    postsUrl: '',
    rivals: {},
    rivalGroups: [],
    editMode: false,
    archivesMode: false,
    timelineMode: false,
    onEnqueueToastMessage() {},
    onClearToastMessages() {},
    toastMessageWithIdentifierExists() {}
  };

  componentDidMount() {
    console.log('Digest.componentDidMount: props: %o', this.props);
    this._isMounted = true;

    const {history, editMode, archivesMode, loadOrFetchRivals, timelineMode} = this.props;
    const {location: {state: {didSendNowAction} = {}} = {}} = history;

    loadOrFetchRivals();
    this.loadVisibilityGroups();

    if(archivesMode) {
      // archives view shows draft digest at top
      this.setState({didSendNowAction}, () => {
        this.loadDigestTypes().then(({activeDigestType, digestTypes}) => {
          const {id: typeId = null} = activeDigestType;

          this.loadDigestTypesData(digestTypes);
          this.loadActiveDigest({}, typeId);
          this.loadDigests({typeId});
        });
      });
    }
    else if(editMode || timelineMode) {
      this.loadDigestTypes().then(({activeDigestType, digestTypes}) => {
        const {id: typeId = null} = activeDigestType;

        this.loadDigestTypesData(digestTypes);
        this.loadActiveDigestAndPosts(typeId);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {problemCustoms: prevProblemCustoms} = prevState;
    const {problemCustoms} = this.state;

    if(problemCustoms.length !== prevProblemCustoms.length) {
      this.refreshProblemCustomMessages(problemCustoms);
    }
  }

  componentWillUnmount() {
    if(this._modalId) {
      this._closeActiveModal();
    }

    const {onClearToastMessages} = this.props;

    onClearToastMessages('Digest');
    onClearToastMessages('Digest.problems');
    this.unregisterNavConfirmation();
    this._isMounted = false;

    this.resizeObserver.disconnect();
  }

  refreshProblemCustomMessages = problems => {
    const {onEnqueueToastMessage, onClearToastMessages} = this.props;

    const problemStyles = {
      fontWeight: 'bold',
      cursor: 'pointer'
    };

    const title = `${problems.length} ${pluralize('Problem', problems.length)} Found`;

    const message = (<div>
      <p>Please fix these issues before sending your Digest.</p>
      <ul>
        {problems.map(problem => {
          const handleScrollToProblem = () => {
            document.getElementById(`f_${problem.id}`)?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
            
            const textInput = this._favoriteFields[problem?.postId].title;
            
            textInput?.focus();
            textInput?.select();
          };

          return (
            <li style={problemStyles} key={problem.id} onClick={handleScrollToProblem}>
              <strong>Untitled custom content</strong>
            </li>
          );
        })}
      </ul>
    </div>);

    onClearToastMessages('Digest.problems', () => {
      if(!problems?.length) {
        return;
      }

      onEnqueueToastMessage({
        title,
        message,
        duration: 0,
        source: 'Digest.problems',
        isError: true
      });    
    });
  };
  handleHeaderResize = () => {
    const {headerWidth} = this.state;
    const newWidth = this.getDigestHeaderWidth();

    if(headerWidth !== newWidth) {
      this.setState({headerWidth: newWidth});
    }
  };

  setupResizeObserver = () => {
    if(this.resizeObserver) {
      return;
    }

    this.resizeObserver = new ResizeObserver(() => {
      this.handleHeaderResize();
    });
  };

  loadActiveDigestAndPosts = (typeId = null, callback) => {
    this.loadActiveDigest({}, typeId, {willLoadPosts: true}).then(digest => {
      // NOTE: always fire loadDigestPosts as it will set the digest subject/summary whether or not it contains posts
      this.loadDigestPosts().catch(() => {
        console.warn('Digest.componentDidMount: unable to load digest posts');
      });

      this.registerNavConfirmation();

      typeof callback === 'function' && callback({digest});
    });
  };

  handleComponentWillReceiveProps = (nextProps, nextContext) => {
    const {editMode, archivesMode} = this.props;
    const {digest, feedMode, reorderMode, activeDigestType, digestTypes} = this.state;
    const {id: typeId = null, archivedAt: digestTypeArchivedAt} = activeDigestType || {};
    const nextDigestId = this._getActiveDigestId(nextProps);
    const {
      archivesMode: nextArchivesMode,
      history: {location: {state: {didSendNowAction} = {}} = {}}
    } = nextProps;

    if((feedMode || reorderMode) && (editMode && !nextProps.editMode)) {
      // clear feed display if enabled & switching out of edit mode
      this.setState({
        feedMode: false,
        reorderMode: false
      });
    }

    if(!editMode && nextProps.editMode) {
      this.registerNavConfirmation(nextProps, nextContext);
    }
    else if(editMode && !nextProps.editMode) {
      // clear "unsaved" fields warning
      this.setState({hasUnsavedFields: false});

      this.unregisterNavConfirmation();
    }

    if(archivesMode !== nextArchivesMode) {
      this._wimEditedByUser = {};
      this._summaryEditedByUser = {};
    }

    if(!archivesMode && nextArchivesMode) {
      // switching into archives listing
      this.setState({
        digests: [],
        digest: null,
        didSendNowAction,
        page: 1,
        totalDigests: -1
      }, () => {
        this.loadActiveDigest({}, typeId);
        this.loadDigests({typeId});
      });
    }

    const loadPosts = () => {
      this.loadDigestPosts({props: nextProps, context: nextContext})
        .catch(() => console.warn('Digest.componentWillReceiveProps: unable to load posts for digestId #%o', nextDigestId));
    };

    // NOTE: potential navigation paths + conditional actions:
    // - start on digest archives,            action: load digests p1
    // - upcoming digest => archives list,    action: load digests p1
    // - archived digest => archives list,    action: load digests p1
    // - start on upcoming digest,            action: load upcoming digest + posts
    // - archives list => archived digest,    action: load archived digest + posts
    // - archives list => upcoming digest,    action: load upcoming digest posts
    // - archived digest => upcoming digest,  action: load upcoming digest posts
    if(!editMode && nextProps.editMode) {
      // entering edit mode
      if(digestTypeArchivedAt && !nextDigestId) {
        // user was viewing an archived digest type... switch to the default.
        const defaultDigestType = digestTypes.find(({default: isDefault}) => isDefault);

        this.setActiveDigestType(defaultDigestType);
      }
      else if(this._getActiveDigestId() !== nextDigestId) {
        // different digest requested
        this.setState({
          digest: null,
          digestPosts: {},
          didSendNowAction
        }, () => {
          this.loadActiveDigest({props: nextProps}).then(loadPosts);
        });
      }
      else if(!_.isEmpty(digest)) {
        // still looking at same digest (likely draft)
        this.setState({
          digestPosts: {},
          didSendNowAction
        }, loadPosts);
      }
    }
    else if((editMode === nextProps.editMode) && (this._getActiveDigestId() !== nextDigestId)) {
      // still in edit mode, different digest requested (e.g. going from archived to upcoming digest)
      if(digestTypeArchivedAt && !nextDigestId) {
        // user was viewing an archived digest type... switch to the default.
        const defaultDigestType = digestTypes.find(({default: isDefault}) => isDefault);

        this.setActiveDigestType(defaultDigestType);
      }
      else {
        this.setState({
          digest: null,
          digestPosts: {},
          didSendNowAction
        }, () => {
          this.loadActiveDigest({props: nextProps}).then(loadPosts);
        });
      }
    }
    else if(editMode && !nextProps.editMode) {
      // leaving edit mode
      this.setState({
        digestPosts: {},
        didSendNowAction: null
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.handleComponentWillReceiveProps(nextProps, nextContext);
  }

  static DIGEST_EDIT_INTERVAL = 250;  // throttle interval for input/textarea onChange handler

  _postRefs = {};
  _favoriteFields = {};
  _editTimer = null;
  _modalId = null;
  _digestIntroRef = null;
  _wimRefs = {};
  _summaryRefs = {};
  _imageUploads = {};
  _wimEditedByUser = {};
  _summaryEditedByUser = {};

  _preventDefault = event => event && event.preventDefault();

  _closeActiveModal = () => {
    if(!this._modalId) {
      return;
    }

    this.context.utils.dialog.remove(this._modalId, () => {
      this._modalId = null;
    });
  };

  _setFavoriteInput = (postId = 0, key = '', ref = null) => {
    if(!postId) {
      console.warn('Digest._setFavoriteInput: invalid postId specified: %o, key: %o', postId, key);
    }

    if(ref) {
      if(!this._favoriteFields[postId]) {
        this._favoriteFields[postId] = {};
      }

      this._favoriteFields[postId][key] = ref;
    }
    else {
      // clear out related post refs
      this._favoriteFields[postId] = {};
    }
  };

  _triggerHasUnsavedFields = () => {
    const hasUnsavedFields = this._hasUnsavedDigestFields();

    if(this.state.hasUnsavedFields !== hasUnsavedFields) {
      this.setState({hasUnsavedFields});
    }

    if(this._editTimer) {
      clearTimeout(this._editTimer);

      this._editTimer = null;
    }
  };

  _handleInputChange = () => {
    if(this._editTimer) {
      return;
    }

    this._editTimer = setTimeout(this._triggerHasUnsavedFields, Digest.DIGEST_EDIT_INTERVAL);
  };

  isDigestHtmlEditorEnabled = () => {
    return Boolean(this._getDigest());
  };

  getUploadingImagesCount = () => Object.keys(this._imageUploads).length;

  refreshImageUploadingCount = () => {
    this.setState({imagesUploadingCount: this.getUploadingImagesCount()});
  };

  handleImageUploadStatus = info => {
    const {identifier, status} = info;
    const {utils: {dialog: {alert}}} = this.context;

    switch(status) {
      case 'uploading':
        this._imageUploads[identifier] = info;
        break;
      case 'uploaded':
      case 'deleted':
        delete this._imageUploads[identifier];
        break;
      case 'maxFileSizeExceeded':
        alert('Maximum image file size is 3MB. Please try again with a smaller file.');
        break;
      case 'error':
        alert('An image upload failed. Please try again.');
        delete this._imageUploads[identifier];
        break;
      default:
        break;
    }

    this.refreshImageUploadingCount();
  };

  handleShowInsertLink = ({urlHRef, urlText, editor, targetImage}) => {
    editor?.selection.save();
    editor?.$el?.blur && editor?.$el?.blur();
    editor?.toolbar?.hide();

    this.setState({cardLinkSelectorVisible: true, urlHRef, urlText, editor, targetImage});
  };

  handleOverviewInputChange = ({value, dirty}) => this.setState({
    [digestHeaderInputs.SUMMARY]: value
  }, () => {
    if(dirty) {
      this._handleInputChange();
    }
  });

  handleWhyItMattersInputChange = ({value, postId, dirty}) => {
    this._setFavoriteInput(postId, 'commentary', {value}); // pass in object with `value` to mimic TextArea object

    if(dirty) {
      this._wimEditedByUser[postId] = true;
      this._handleInputChange();
    }
  };

  handleWhyItMattersInitialized = ({postId}) => {this._wimEditedByUser[postId] = false;};

  handleSummaryInputChange = ({value, postId, dirty}) => {
    this._setFavoriteInput(postId, 'summary', {value}); // pass in object with `value` to mimic TextArea object

    if(dirty) {
      this._summaryEditedByUser[postId] = true;
      this._handleInputChange();
    }
  };

  handleSummaryInitialized = ({postId}) => {this._summaryEditedByUser[postId] = false;};

  _handleOverviewInputChangeWithValue = (inputType, value, options = {}) => {
    let sanitizedValue = value;

    if(options.stripBreaks) {
      sanitizedValue = stripExtraWhitespace(stripBreaks(sanitizedValue)).trimStart();
    }

    return this.setState({
      [inputType]: sanitizedValue
    }, this._handleInputChange);
  };

  _handleOverviewInputChange = (inputType, event, options = {}) => this._handleOverviewInputChangeWithValue(inputType, event.target.value, options);

  _canEditDigest = (digest = this._getDigest()) => {
    const {utils: {user = {}, company = {}}} = this.context;

    // instance admins and kluebot can override/edit locked state and delete locked digests
    return canEditDigest(digest, user, company);
  };

  _getActiveDigestId = (props = this.props, options) => {
    const {useDefaultDigestId} = options || {};
    const draftDigestId = 0;

    if(useDefaultDigestId) {
      return draftDigestId;
    }

    const {match: {params}} = props;

    // digestId = 0 fallback will trigger draft digest
    return (params && params.digestId) ? (parseInt(params.digestId, 10) || 0) : draftDigestId;
  };

  _getDigest = () => this.state.digest;

  _getDigestOverview = (digest = null) => {
    if(_.isEmpty(digest)) {
      return;
    }

    let {title: subject, summary} = digest;

    if(!digest.archivedAt) {
      const {subject: subjectInput, summary: summaryInput} = this.state;

      if(subjectInput && subjectInput.value) {
        subject = subjectInput.value.trim();
      }

      if(summaryInput && summaryInput.value) {
        summary = summaryInput.value.trim();
      }
    }

    return {subject, summary};
  };

  _hasUnsavedDigestFields = () => {
    const {subject, summary, digestPosts = {}, banner: currentBanner} = this.state;
    const digest = this._getDigest();

    if(_.isEmpty(digest) || digest.archivedAt) {
      // shouldn't be able to re-save/edit an archived digest
      return false;
    }

    const {favorites, title: digestSubject, summary: digestSummary, banner} = digest;

    if((subject !== digestSubject) || (summary !== digestSummary) || (currentBanner !== banner)) {
      return true;
    }

    for(let i = 0; i < favorites.length; i++) {
      const favorite = favorites[i];
      const post = (digestPosts || {})[favorite.postId];

      if(_.isEmpty(post)) {
        // associated post should always be present (if not, nothing to validate)
        continue;
      }

      let checkTitle;
      let checkCommentary;
      let checkSummary;
      let checkUrl;

      const {
        title: curatedTitle = null,
        commentary: curatedCommentary = null,
        summary: curatedSummary = null,
        curatedRivalId,
        curatedUrl
      } = this._favoriteFields[favorite.postId] || {};

      checkTitle = (curatedTitle && curatedTitle.value) ? curatedTitle.value.trim() : '';
      checkCommentary = (curatedCommentary && curatedCommentary.value) ? curatedCommentary.value.trim() : '';
      checkSummary = (curatedSummary && curatedSummary.value) ? curatedSummary.value.trim() : '';
      checkUrl = (curatedUrl && curatedUrl.value) ? curatedUrl.value.trim() : '';

      const firstAttachment = post.attachments ? post.attachments[0] : null;
      const favoriteTitle = favorite.curatedTitle || (post.commentTitle || (firstAttachment ? firstAttachment.title : '(Untitled article)'));
      const favoriteCommentary = getFavoriteCommentary(favorite, post, this.getFavoriteContentOptions()) || '';
      const favoriteSummary = favorite.curatedSummary ||
        truncate(
          stripExtraWhitespace(stripBreaks(firstAttachment ? firstAttachment.body : '')).trim(),
          {limit: truncateLimits.attachmentSummary, useWordBoundary: true, isHtml: true}
        );
      const favoriteCuratedRivalId = favorite.curatedRivalId;
      const favoriteUrl = favorite.curatedUrl;

      // don't save curated title/summary if they match related post fields, or are empty and original field was already null
      if((checkTitle === favoriteTitle) || (_.isEmpty(checkTitle) && _.isEmpty(favoriteTitle))) {
        checkTitle = null;
      }

      if((checkCommentary === favoriteCommentary) || (_.isEmpty(checkCommentary) && _.isEmpty(favoriteCommentary))) {
        checkCommentary = null;
      }

      if((checkSummary === favoriteSummary) || (_.isEmpty(checkSummary) && _.isEmpty(favoriteSummary))) {
        checkSummary = null;
      }

      if(
        (checkTitle !== null) ||
        (checkCommentary !== null) ||
        (checkSummary !== null) ||
        (checkUrl !== favoriteUrl) ||
        (favoriteCuratedRivalId !== curatedRivalId)) {
        return true;
      }
    }

    return false;
  };

  _getFavoritesToRender = favorites => {
    const {reorderMode, favoritesSorted: sortedFavs = []} = this.state;
    const favoritesSorted = [...sortedFavs];
    let favoritesToRender;

    if(_.isEmpty(favorites)) {
      return;
    }

    if(reorderMode && favoritesSorted && favoritesSorted.length) {
      favoritesToRender = favoritesSorted.reduce((matchedFavorites, favoriteId) => {
        const found = favorites.find(f => f.id === favoriteId);

        if(found) {
          matchedFavorites.push(found);
        }

        return matchedFavorites;
      }, []);
    }
    else {
      favoritesToRender = favorites;
    }

    return favoritesToRender;
  };

  confirmBeforeNav = event => {
    if(this.props.editMode && this.state.hasUnsavedFields) {
      const confirm = 'You have unsaved changes to your digest. Would you like to discard them?';

      event.returnValue = confirm;

      return confirm;
    }
  };

  registerNavConfirmation = () => {
    window.removeEventListener('beforeunload', this.confirmBeforeNav);  // remove any pre-existing hook
    window.addEventListener('beforeunload', this.confirmBeforeNav);
  };

  unregisterNavConfirmation = callback => {
    window.removeEventListener('beforeunload', this.confirmBeforeNav);

    return typeof callback === 'function' && callback();
  };

  sortFavorites = (favorites, callback) => {
    if(_.isEmpty(favorites)) {
      return;
    }

    this.setState({favoritesSorted: favorites.map(f => f.id)}, () => {
      console.log('Digest.sortFavorites: %o', this.state.favoritesSorted);

      return typeof callback === 'function' && callback();
    });
  };

  handlePaginationClick = pageData => {
    // paginator component uses 0-based page index
    const page = pageData.selected + 1;
    const {activeDigestType: {id: typeId = null}} = this.state;

    console.log('Digest.handlePaginationClick: loading digests page: %o', page);

    this.setState({
      page,
      digests: []     // reset digests array to wipe out current page
    }, () => this.loadDigests({page, typeId}));
  };

  setActiveDigestType = (activeDigestType, loadDigests = true, callback) => {
    const {archivesMode} = this.props;
    const {id: typeId = null} = activeDigestType;
    const recentDigestType = typeId;
    const {utils: {user: {id}}, api: {userUpdate}} = this.context;

    // Save user's prefered digest type
    Boolean(typeId) && userUpdate({id, featureFlag: ['recentDigestType', recentDigestType]});

    this.setState({
      activeDigestType: activeDigestType || {},
      hasUnsavedFields: false,
      didSendPreviewAction: false,
      didSendRivalGroupIds: null,
      didSendPreviewFailures: null
    }, () => {
      if(loadDigests) {
        this.loadActiveDigestAndPosts(typeId);
        archivesMode && this.loadDigests({typeId});
      }

      callback && callback();
    });
  };

  loadDigests = ({page = 1, typeId = null, filter} = {}) => {
    const {archivesMode} = this.props;
    const {api: {digestsGet}} = this.context;

    const digestOptions = {
      page,
      typeId,
      limit: DIGESTS_PER_PAGE,
      filter: filter || 'not_draft',
      includeChildren: archivesMode
    };

    return new Promise((resolve, reject) => {
      digestsGet({digestOptions, code: 'Digest.loadDigests', callback: ({digests, totalDigests}) => this.setState({
        digests,
        totalDigests
      }, () => {
        if(digests) {
          console.log('Digest.loadDigests: loaded digests: %o, total: %o, page: %o', digests, totalDigests, page);

          return resolve(digests);
        }

        return reject('Digest.loadDigests: failed to load digests');
      })});
    });
  };

  loadDigestUsers = digest => {
    const userIds = new Set([
      ...digest.favorites.map(f => f.userId),
      digest.lastCuratorId || digest.userId
    ]);

    userIds.forEach(userId => {
      if(!(this.props.users || {})[userId]) {
        this.context.utils.requestUser({userId});
      }
    });
  };

  resetStateForPossibleReloadOfPosts = ({willLoadPosts = false}, callback) => {
    if(!willLoadPosts) {
      return callback();
    }

    this._postRefs = {};
    this._favoriteFields = {};
    this._digestIntroRef = null;
    this._wimRefs = {};
    this._summaryRefs = {};
    this._imageUploads = {};
    this._wimEditedByUser = {};
    this._summaryEditedByUser = {};

    this.setState({digestPosts: {}}, () => callback());
  };

  loadVisibilityGroups = () => {
    const {api: {visibilityGroupsGet}} = this.context;

    visibilityGroupsGet({}).then(visibilityGroups => {
      if(!this._isMounted) {
        return;
      }

      this.setState({
        visibilityGroups: [...visibilityGroups].concat({id: 0, name: 'Full Access Users'})
      });
    });
  };

  digestTypeIsArchived = typeId => {
    const {digestTypes} = this.state;

    const digestType = digestTypes?.find(({id}) => id === typeId);

    if(!digestType) {
      // if it's not in the list, assume it's been archived
      return true;
    }

    return digestType?.archivedAt ? true : false;
  };

  loadActiveDigest = ({props = this.props} = {}, typeId = null, options = {}) => {
    const digestId = this._getActiveDigestId(props, options);

    return new Promise((resolve, reject) => {
      const handleDigestLoad = digest => {
        if(_.isEmpty(digest)) {
          console.warn('Digest.loadActiveDigest: unable to load digestId #%o', digestId);

          return reject(digest);
        }

        console.log('Digest.loadActiveDigest: loaded digestId #%o: %o', digestId, digest);

        this.resetStateForPossibleReloadOfPosts(options, () => {
          const {title: subject, summary, banner, digestType: {reviewBannerUrl: currentBanner = ''} = {}} = digest;
          const problemsState = this.refreshProblemStatus(digest);

          this.setState({
            digest,
            subject: subject || '',
            summary: summary || '',
            banner: banner || currentBanner,
            ...problemsState
          }, () => {
            this.loadDigestUsers(digest);
            this._digestIntroRef?.setText(digest.summary || '', false);

            resolve(digest);
          });
        });
      };

      const {api: {digestGet, digestCreate}} = this.context;

      if(isValidId(digestId)) {
        // fetch requested digest if digestId was passed
        digestGet(digestId, typeId, handleDigestLoad);
      }
      else if(!this.digestTypeIsArchived(typeId)) {
        // fetch & auto-populate draft digest
        digestCreate({typeId}, handleDigestLoad);
      }
    });
  };

  getDefaultDigestType = digestTypes => {
    return digestTypes.find(digestType => digestType.default);
  };

  loadDigestTypes = ({useDigestType} = {}) => {
    const {utils: {user: {userData: {recentDigestType = null} = {}}}, api: {digestTypesGet}} = this.context;
    const digestTypeOptions = {};

    return new Promise((resolve, reject) => {
      digestTypesGet({
        digestTypeOptions,
        code: 'Digest.loadDigestTypes',
        callback: data => {
          if(!data) {
            return reject('unable to get digest types');
          }

          console.log('Digest.loadDigestTypes: loaded digestTypes: %o', data);

          const {data: digestTypes = []} = data;
          let initialDigestType;

          if(useDigestType) {
            initialDigestType = digestTypes.find(digestType => digestType.id === useDigestType.id);
          }

          if(!initialDigestType) {
            if(recentDigestType) {
              initialDigestType = digestTypes.find(digestType => digestType.id === recentDigestType && !digestType?.archivedAt) ||
              this.getDefaultDigestType(digestTypes);
            }
            else {
              initialDigestType = this.getDefaultDigestType(digestTypes);
            }
          }

          this.setState({digestTypes, activeDigestType: initialDigestType || {}}, () => {
            resolve({activeDigestType: initialDigestType, digestTypes});
          });
        }
      });
    });
  };

  loadFavorites = digestId => {
    if(!digestId) {
      throw new Error('Digest.loadFavorites: digestId is required');
    }

    const {
      api: {
        digestFavoritesGet
      }
    } = this.context;

    return new Promise((resolve, reject) => {
      digestFavoritesGet(digestId, data => {
        if(data) {return resolve(data);}

        return reject('Digest.loadFavorites: failed to load digests');
      });
    });
  };

  loadDigestTypesData = async (digestTypes = []) => {
    const digestTypesData = [];

    const digestTypesReqs = digestTypes
      .filter(dt => !dt?.archivedAt)
      .sort((d1, d2) => d1?.name?.localeCompare(d2?.name ?? ''))
      .map(async digestType => {
        digestTypesData.push({
          digestType
        });

        const currentIndex = digestTypesData.length - 1;

        try {
          const digests = await this.loadDigests({typeId: digestType.id, filter: 'draft'});

          console.log('Digest.loadDigestTypesData: loaded digests: %o', digests);

          const draftDigest = digests?.[0];

          if(draftDigest) {
            digestTypesData[currentIndex].draftDigest = draftDigest;

            const favorites = await this.loadFavorites(draftDigest.id);

            digestTypesData[currentIndex].draftDigest.favorites = favorites;
          }
          else {
            digestTypesData.pop();
          }
        }
        catch(err) {
          console.error('Digest.loadDigestTypesData: failed to load digests', err);

          digestTypesData.pop();
        }
      });

    await Promise.all(digestTypesReqs);

    this.setState({
      digestTypesData
    });
  };

  handleUpdateFavorites = async (digestTypesUpdateList = [], options = {}) => {
    if(this._hasUnsavedDigestFields() && this.havePendingUploads()) {
      return this.waitForUploads({
        cancelTitle: 'Cancel update digest items'
      }).then(() => this.handleUpdateFavorites(digestTypesUpdateList, options));
    }

    const updateFavoritesReqs = digestTypesUpdateList.map(({postId, draftDigest, isFavorite}) => {
      if(isFavorite) {
        return this.handleDeleteFavorite(postId, undefined, {digest: draftDigest, isMultiDigests: true, ...options});
      }

      return this.handleUpdateFavorite({postId, digest: draftDigest, isMultiDigests: true, ...options});
    });

    try {
      await Promise.all(updateFavoritesReqs);

      return Promise.resolve();
    }
    catch(err) {
      console.error('Digest.handleUpdateFavorites: failed to update favorite', err);

      throw err;
    }
  };

  handleUpdateDigestTypesData = (draftDigestId, favorites, currentPostId) => {
    if(!draftDigestId || !favorites) {
      console.error('Digest.handleUpdateDigestTypesData: DraftDigestId and favorites are required');

      return;
    }

    const {digestTypesData} = this.state;

    const digestTypeIndex = digestTypesData.findIndex(({draftDigest}) => (
      draftDigest.id === draftDigestId)
    );

    if(digestTypeIndex === -1) {return false;}

    const newDigestTypesData = [...digestTypesData];

    newDigestTypesData[digestTypeIndex].draftDigest.favorites = favorites;

    return this.setState({digestTypesData: newDigestTypesData}, () => {
      this.handleUpdatePosts(currentPostId);
    });
  };

  handleUpdatePosts = currentPostId => {
    const {postsContext: {refreshPost}} = this.props;

    refreshPost(currentPostId);
  };

  loadDigestPosts = ({updatePostId = 0, props = this.props, context = this.context} = {}) => {
    if(!props.editMode) {
      return Promise.reject('Digest.loadDigestPosts: digest is not in edit mode');
    }

    const digest = this._getDigest();
    const {postsGet, postGet} = context.api;

    if(isValidId(updatePostId) && digest.favorites.length) {
      return new Promise((resolve, reject) => {
        postGet(updatePostId, posts => {
          if(!isValidId(updatePostId)) {
            console.warn('Digest.loadDigestPosts: invalid postId specified: %o, digest: %o', updatePostId, digest);

            return reject(updatePostId);
          }

          this.refreshDigestPosts(posts)
            .then(refreshedPost => resolve(refreshedPost))
            .catch(() => console.warn('Digest.loadDigestPosts: unable to refresh postId #%o for digestId #%o', updatePostId, digest.id));
        }, {includeCustomPosts: true});
      });
    }
    else if(digest) {
      // load all posts for specified digest
      const favorites = digest.favorites || [];
      const {title: subject, summary, banner: digestBanner, digestType: {reviewBannerUrl: currentBanner = ''} = {}} = digest;

      return new Promise(resolve => {
        this.setState({
          digestPosts: {},    // reset all digest posts in case we're switching between digests
          subject: subject || '',
          summary: summary || '',
          banner: digestBanner || currentBanner
        }, () => {
          // load related posts from JSON data
          const postOptions = {
            postIds: favorites.map(f => f.postId),
            includeCustomPosts: true
          };

          // NOTE: AppBase.apiPostsGet returns a promise
          postsGet(postOptions)
            .then((posts = []) => {
              console.log('Digest.loadDigestPosts: loaded all posts for digestId #%o: %o', digest.id, posts);

              // request users with digest posts
              const posterIds = [...new Set(posts.map(p => p.viaUserId || p.userId))];

              posterIds.forEach(userId => {
                if(!(props.users || {})[userId]) {
                  context.utils.requestUser({userId});
                }
              });

              this.refreshDigestPosts(posts)
                .then(refreshedPosts => resolve(refreshedPosts))
                .catch(() => console.warn('Digest.loadDigestPosts: unable to refresh digest posts for digestId #%o', digest.id));
            })
            .catch(() => console.warn('Digest.loadDigestPosts: unable to load digest posts for digestId #%o', digest.id));
        });
      });
    }
  };

  removeDigestPost = (postId = 0) => {
    return new Promise((resolve, reject) => {
      if(!isValidId(postId)) {
        console.warn('Digest.removeDigestPost: postId not specified: %o', postId);

        return reject(postId);
      }

      // remove a single post reference (removed favorite from digest)
      this.setState({
        digestPosts: ReactUpdate(this.state.digestPosts, {$unset: [postId]})
      }, () => {
        console.log('Digest.removeDigestPost: removed postId #%o from digest posts: %o', postId, this.state.digestPosts);

        resolve();
      });
    });
  };

  refreshDigestPosts = (posts = []) => {
    return new Promise((resolve, reject) => {
      if(_.isEmpty(posts)) {
        console.warn('Digest.refreshDigestPost: no posts to refresh specified', posts);

        return reject(posts);
      }

      const digestPostsState = {};

      for(let i = 0; i < posts.length; i++) {
        const post = posts[i];

        digestPostsState[post.postId] = {$set: post};
      }

      this.setState({
        digestPosts: ReactUpdate(this.state.digestPosts, digestPostsState)
      }, () => {
        console.log('Digest.refreshDigestPost: updated posts: %o', this.state.digestPosts);

        resolve(this.state.digestPosts);
      });
    });
  };

  handleFindPost = (postId, event) => {
    this._preventDefault(event);

    this.setState({
      feedMode: true,
      findPostId: postId
    });
  };

  getFavoriteContentOptions = () => ({htmlEditorEnabled: this.isDigestHtmlEditorEnabled()});

  updateFavoriteFromPost = ({favorite = null, post = null, overwrite = false}, callback = null) => {
    // NOTE: overwrite flag will force overwrite of favorite title/summary/commentary fields
    if(_.isEmpty(favorite) || _.isEmpty(post)) {
      return;
    }

    const {api: {favoriteUpdate, digestGet}} = this.context;
    const {postId} = post;

    const refreshFromPostAction = () => {
      const {favoriteSummary, favoriteTitle} =
        getFavoriteContent(overwrite ? {curatedSummary: null, curatedTitle: null} : favorite, post, this.getFavoriteContentOptions());
      const favoriteFields = this._favoriteFields[postId];
      const favoriteOptions = {
        id: favorite.id,
        curatedTitle: null,
        curatedSummary: null
      };

      if(favoriteFields && favoriteFields.title && favoriteFields.summary) {
        if(overwrite || (!overwrite && !favoriteFields.title.value)) {
          favoriteFields.title.value = favoriteTitle;
        }

        if(overwrite || (!overwrite && !favoriteFields.summary.value)) {
          favoriteFields.summary.value = favoriteSummary;
          this._summaryRefs[postId] && this._summaryRefs[postId].setText(favoriteSummary);
        }
      }

      let commentary;

      if(overwrite) {
        favoriteOptions.curatedCommentary = null;
        commentary = getFavoriteCommentary({curatedCommentary: null}, post, this.getFavoriteContentOptions());
      }
      else if(favoriteFields.commentary && favoriteFields.commentary.value) {
        commentary = favoriteFields.commentary.value;
      }
      else {
        commentary = getFavoriteCommentary(favorite, post, this.getFavoriteContentOptions());
      }

      if(favoriteFields && favoriteFields.commentary) {
        favoriteFields.commentary.value = commentary || '';
        this._wimRefs[postId] && this._wimRefs[postId].setText(commentary || '');
      }

      favoriteUpdate(favoriteOptions, false, updatedFavorite => {
        console.log('Digest.updateFavoriteFromPost: refreshed favoriteId #%o for postId #%o: %o', updatedFavorite.id, updatedFavorite.postId, updatedFavorite);

        if(favoriteFields && favoriteFields.title && favoriteFields.summary) {
          favoriteFields.summary.focus && favoriteFields.summary.focus();     // force textarea to resize if height is different from previous text
          favoriteFields.title.focus && favoriteFields.title.focus();
        }

        const {activeDigestType: {id: typeId = null}} = this.state;

        digestGet(updatedFavorite.emailDigestId, typeId,
          refreshedDigest => this.setState({digest: refreshedDigest},
            () => ((typeof callback === 'function') && callback(updatedFavorite))));
      });
    };

    refreshFromPostAction();
  };

  handleRefreshFavoriteFromPost = (favorite = null, event, overwrite = true, confirm = true) => {
    this._preventDefault(event);

    if(_.isEmpty(favorite)) {
      return;
    }

    const refreshFavoriteAction = () => {
      this.loadDigestPosts({updatePostId: favorite.postId}).then(posts => {
        this.updateFavoriteFromPost({
          favorite,
          post: (posts || {})[favorite.postId],
          overwrite
        });
      });
    };

    if(!confirm) {
      return refreshFavoriteAction();
    }

    const {utils: {dialog}} = this.context;

    dialog.confirm({
      message: 'Revert the content of this digest item?',
      bodyContent: 'This will revert your curated text for this item to the related post\'s original title, commentary, and summary.',
      okCallback: refreshFavoriteAction
    });
  };

  handleSaveDigestClickSync = digest => new Promise(resolve => (
    this.handleSaveDigestClick(null, () => {
      console.log('Digest.handleUpdateFavorite: saved unsaved fields for digestId #%o', digest.id);

      resolve();
    })
  ));

  handleUpdateFavorite = async (options = {}, event) => {
    this._preventDefault(event);

    if(_.isEmpty(options) || ((options.id <= 0) && (options.postId <= 0))) {
      return;
    }

    const digest = options.digest || this._getDigest();

    if(_.isEmpty(digest)) {
      return;
    }

    if(this._hasUnsavedDigestFields() && this.havePendingUploads()) {
      return this.waitForUploads({
        cancelTitle: 'Cancel add digest item'
      }).then(() => this.handleUpdateFavorite(options, event));
    }

    const {api: {favoriteUpdate, favoriteCreate, digestGet}} = this.context;
    const {digest: {id: currentDigestId}, digestTypesData} = this.state;
    const {favorites} = digest;
    const favoriteOptions = {
      emailDigestId: digest.id
    };
    const isMultiDigestRelatedToCurrentDigest = (
      options.digest && options.digest.id === currentDigestId
    );

    let favoriteAction = null;
    let hasCommentChanged = false;

    const {viewOrder = null} = options;

    if(viewOrder !== null) {
      favoriteOptions.viewOrder = viewOrder;
    }

    if(options.id) {
      // updating an existing favorite
      favoriteOptions.id = options.id;
      favoriteAction = (updateOptions = {}, callback = null) => favoriteUpdate(updateOptions, false, callback);

      const existingFavorite = favorites.find(f => f.id === options.id);

      if(existingFavorite) {
        hasCommentChanged = (existingFavorite.curatedCommentary !== options.curatedCommentary);
      }
    }
    else {
      // creating a new favorite
      favoriteOptions.postId = options.postId;
      favoriteAction = favoriteCreate;
    }

    if(this._hasUnsavedDigestFields()) {
      await this.handleSaveDigestClickSync(digest);
    }

    return new Promise(resolve => {
      favoriteAction(favoriteOptions, updatedFavorite => {
        console.log(
          'Digest.handleUpdateFavorite: %o favorite %o for postId #%o', options.id ? 'updated' : 'created', updatedFavorite, options.postId
        );

        let favorite = updatedFavorite;

        // NOTE: favorites#create API response will be an array; favorite#update will return only the updated item
        if(Array.isArray(updatedFavorite)) {
        // create new favorite
          favorite = updatedFavorite.find(f => f.postId === options.postId);

          this.handleUpdateDigestTypesData(digest.id, updatedFavorite, favoriteOptions.postId);
        }

        const refreshDigestPosts = () => {
          this.loadDigestPosts({updatePostId: options.postId}).then(posts => this.updateFavoriteFromPost({
            favorite,
            post: (posts || {})[favorite.postId]
          }, async (favoriteWithCommentary = null) => {
            const {postsContext: {refreshPost}} = this.props;

            refreshPost && refreshPost(options.postId);

            // scroll digest body to top of newly added favorite
            const updatedPost = ReactDOM.findDOMNode(this._postRefs[favorite.postId]);

            if(updatedPost) {
              const favoriteFields = this._favoriteFields[favorite.postId];
              let doCommentaryUpdate = false;

              if(hasCommentChanged || (favoriteFields.commentary && !favoriteFields.commentary.value)) {
                doCommentaryUpdate = true;
              }

              if(favoriteWithCommentary && favoriteWithCommentary.curatedCommentary && doCommentaryUpdate) {
                favoriteFields.commentary.value = favoriteWithCommentary.curatedCommentary;
              }

              updatedPost.scrollIntoView({behavior: 'smooth', block: 'end'});

              // NOTE: add slight delay to prevent input.focus() from breaking smooth scroll in chrome
              await wait(400);

              if(favoriteFields.title) {
                favoriteFields.title.focus && favoriteFields.title.focus();
              }
            }
          }));
        };

        const {activeDigestType: {id: typeId}} = this.state;
        const {digestsContext: {didUpdateDigest, refreshDigest}} = this.props;

        if(isMultiDigestRelatedToCurrentDigest || digestTypesData.length <= 1) {
          digestGet(digest.id, typeId, refreshedDigest => {
            this.setState({digest: refreshedDigest}, () => {
              didUpdateDigest && didUpdateDigest({digest: refreshedDigest});
              refreshDigestPosts();
              resolve(updatedFavorite);
            });
          });
        }
        else {
          refreshDigest && refreshDigest(digest?.id);
          resolve(updatedFavorite);
        }
      });
    });
  };

  handlePinnedCommentUpdatedForFavorite = favorite => {
    if(_.isEmpty(favorite) || favorite.curatedCommentary !== null) {
      return;
    }

    const commentaryField = this._favoriteFields[favorite.postId]?.commentary;

    if(!commentaryField) {
      return;
    }

    const {postId} = favorite;

    this.loadDigestPosts({updatePostId: postId}).then(posts => {
      const post = posts[favorite.postId];
      const commentary = getFavoriteCommentary(favorite, post, this.getFavoriteContentOptions());

      commentaryField.value = commentary;
      commentaryField.focus && commentaryField.focus();
      this._wimRefs[postId] && this._wimRefs[postId].focus();
    });
  };

  waitForUploads = ({cancelTitle}) => {
    const promise = new Promise((resolve, reject) => {
      const checkStatus = () => {
        setTimeout(() => {
          const {imagesUploadingCount, cancelledPromise} = this.state;

          if(cancelledPromise === promise) {
            return this.setState({
              waitingForUploads: false,
              waitForUploadPromise: null,
              cancelledPromise: null
            }, () => reject());
          }

          if(imagesUploadingCount) {
            checkStatus();
          }
          else {
            this.setState({
              waitingForUploads: false,
              waitForUploadPromise: null,
              cancelledPromise: null
            }, () => resolve());
          }
        }, 1000);
      };

      checkStatus();
    });

    this.setState({
      waitingForUploads: true,
      waitForUploadPromise: promise,
      waitingForUploadCancelTitle: cancelTitle,
      cancelledPromise: null
    });

    return promise;
  };

  havePendingUploads = () => {
    const {imagesUploadingCount} = this.state;

    return Boolean(imagesUploadingCount);
  };

  handleDeleteFavorite = (postId = 0, event, options = {}) => {
    this._preventDefault(event);

    if(!postId || (postId <= 0)) {
      return;
    }

    const digest = options.digest || this._getDigest();

    if(_.isEmpty(digest)) {
      return;
    }

    if(this._hasUnsavedDigestFields() && this.havePendingUploads()) {
      return this.waitForUploads({
        cancelTitle: 'Cancel delete digest item'
      }).then(() => this.handleDeleteFavorite(postId, event, options));
    }

    const {api: {favoriteDelete, digestGet}, utils: {dialog}} = this.context;
    const {digest: {id: currentDigestId}} = this.state;
    const favoriteOptions = {
      postId,
      emailDigestId: digest.id
    };

    const {isMultiDigests} = options;
    const isMultiDigestRelatedToCurrentDigest = (
      options.digest && options.digest.id === currentDigestId
    );

    const deleteFavoriteAction = () => {
      favoriteDelete(favoriteOptions, favorites => {
        const {activeDigestType: {id: typeId}} = this.state;

        this.handleUpdateDigestTypesData(digest.id, favorites, favoriteOptions.postId);

        const {digestsContext: {didUpdateDigest, refreshDigest}, postsContext: {refreshPost}} = this.props;

        if(!isMultiDigests || isMultiDigestRelatedToCurrentDigest) {
          digestGet(digest.id, typeId, refreshedDigest => {
            const problemsState = this.refreshProblemStatus(refreshedDigest);

            this.setState({
              digest: refreshedDigest,
              ...problemsState
            }, () => {
              this.removeDigestPost(postId).then(() => {
                delete this._wimEditedByUser[postId];
                delete this._summaryEditedByUser[postId];
                refreshPost && refreshPost(postId);
                didUpdateDigest && didUpdateDigest({digest: refreshedDigest});
                console.log('Digest.handleDeleteFavorite: deleted favorite for postId #%o, emailDigestId #%o: %o', postId, digest.id, favorites);
              });
            });
          });
        }
        else {
          refreshDigest && refreshDigest(digest?.id);
        }
      });
    };

    const saveAndDeleteFavoriteAction = () => {
      if(this._hasUnsavedDigestFields()) {
        this.handleSaveDigestClick(null, () => {
          console.log('Digest.handleDeleteFavorite: saved unsaved fields for digestId #%o', digest.id);

          deleteFavoriteAction();
        });
      }
      else {
        deleteFavoriteAction();
      }
    };

    if(isMultiDigests) {
      saveAndDeleteFavoriteAction();
    }
    else {
      const {favorites} = digest;
      const match = favorites.find(f => f.postId === postId);
      const {isCustom = false} = match || {};
      
      dialog.confirm({
        message: 'Delete this digest item?',
        bodyContent: isCustom ? '' : 'You can always add it back again from the feed at a later time.',
        okCallback: saveAndDeleteFavoriteAction
      });
    }
  };

  handleReorderFavoriteHover = (dragItemProps, targetItemProps) => {
    const {favoritesSorted = []} = this.state;

    if(!favoritesSorted.length) {
      return;
    }

    const fromIndex = dragItemProps.droppableCardIndex;
    const toIndex = targetItemProps.droppableCardIndex;
    const draggedFavorite = favoritesSorted[fromIndex];

    if(fromIndex === toIndex) {
      return;
    }

    this.setState({
      favoritesSorted: ReactUpdate(favoritesSorted, {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, draggedFavorite]
        ]
      }, () => console.log('Digest.handleReorderFavoriteHover: fromIndex: %o, toIndex: %o, moved group %o', fromIndex, toIndex, draggedFavorite))
    });

    // update dragged component with updated props
    dragItemProps.droppableCardIndex = targetItemProps.droppableCardIndex;
  };

  handleReorderFavoriteSave = event => {
    this._preventDefault(event);

    const {favoritesSorted = []} = this.state;

    if(!favoritesSorted.length) {
      return;
    }

    const digest = this._getDigest();

    if(_.isEmpty(digest) || digest.archivedAt) {
      // shouldn't be able to edit an archived (sent, not custom) digest
      return;
    }

    const {favorites} = digest;

    if(favorites && (favorites.length !== favoritesSorted.length)) {
      // may be mid-batch update
      return;
    }

    const {api: {digestUpdate, favoritesUpdateBatch}} = this.context;
    const favoritesToUpdate = [];

    // save favorites as needed based on new viewOrders
    favoritesSorted.forEach((favoriteId, index) => {
      const favorite = favorites.find(f => f.id === favoriteId);
      const newViewOrder = index + 1.0;

      if(parseFloat(favorite.viewOrder) !== newViewOrder) {
        const favoriteOptions = {
          id: favorite.id,
          emailDigestId: digest.id,
          viewOrder: newViewOrder
        };

        favoritesToUpdate.push(favoriteOptions);
      }
    });

    const favoritesUpdatedAction = (updatedFavorites = null) => {
      const {utils: {user: {id: lastCuratorId}}} = this.context;

      digestUpdate({id: digest.id, lastCuratorId},
        updatedDigest => this.setState({digest: updatedDigest},
          () => console.log('Digest.handleReorderFavoriteSave: updated last curator for digestId #%o: %o', updatedDigest.id, updatedDigest))
      );

      this.handleToggleReorder(updatedFavorites || undefined);
    };

    if(favoritesToUpdate.length) {
      favoritesUpdateBatch(favoritesToUpdate, updatedFavorites => {
        console.log('Digest.handleReorderFavoriteSave: updated all favorites%s: %o', digest ? ` for digestId #${digest.id}` : '', updatedFavorites);

        favoritesUpdatedAction(updatedFavorites);
      });
    }
    else {
      favoritesUpdatedAction();
    }
  };

  handleCreateDigestClick = event => {
    this._preventDefault(event);
    this._modalId = 'digest-create-modal';

    const {api: {digestCreate}, utils: {dialog}} = this.context;
    const {activeDigestType: {id: typeId = null}} = this.state;

    const createDigestAction = () => {
      // create new custom digest (blank, manual, unsent)
      digestCreate({manual: true, typeId}, digest => {
        if(_.isEmpty(digest)) {
          console.error('Digest.handleCreateDigestClick: unable to create manual digest');

          return;
        }

        const {history} = this.props;
        const {digestType: {reviewBannerUrl: currentBanner = ''} = {}} = digest;

        console.log('Digest.handleCreateDigestClick: created blank archived digest: created digest: %o', digest);

        // clear overview form fields when creating custom digest
        this.setState({
          didSendNowAction: null,
          subject: '',
          summary: '',
          banner: currentBanner
        }, () => {
          this._closeActiveModal();
          history.push(`/digest/${digest.id}`);
        });
      });
    };

    // TODO: extract new modal styles to Dialog component
    const modalContent = (
      <div className="digest-modal">
        <div className="digest-modal_close" onClick={this._closeActiveModal}>
          <Icon icon="close" width="24" height="24" className="digest-modal_close_icon" />
        </div>
        <h2>New Custom Digest?</h2>
        <h3>You are about to create a new, <em>custom</em> Intel Digest.</h3>
        <p>
          Once you&apos;ve selected intel to include, hit &quot;Copy to Clipboard&quot; and paste your digest into your email,
          Slack, or other messaging tool.
        </p>
        <p>
          <small><em><strong>Note</strong>: You can return to the current screen at any time.</em></small>
        </p>
        <footer className="digest-modal_footer digest-modal_footer--left">
          <div className="button button--disabled" onClick={this._closeActiveModal}>Cancel</div>
          <div className="button" onClick={createDigestAction}>Create Now</div>
        </footer>
      </div>
    );

    dialog.create({
      id: this._modalId,
      _wideMode: true,
      content: modalContent
    });
  };

  handleCopyDigestClick = event => {
    this._preventDefault(event);

    const digest = this._getDigest();

    if(_.isEmpty(digest)) {
      return;
    }

    const {onEnqueueToastMessage} = this.props;
    const htmlEnabled = this.isDigestHtmlEditorEnabled();
    const copyContent = (
      <div className={classNames('digest-body', {'html-enabled': htmlEnabled})}>
        {this.renderCopyContentsHeader(digest)}
        {this.renderFavorites(digest, true)}
        {this.renderCopyContentsFooter()}
      </div>
    );
    const isCopied = copyToClipboard(copyContent, 'digest-clipboard-copy');
    const toastMessage = isCopied
      ? {
        title: 'Copied digest to clipboard!',
        message: 'Now go paste into any email app, Slack channel, or anywhere your team hangs out!'
      }
      : {
        title: 'Unable to copy to clipboard',
        message: 'Sorry, your browser doesn\'t support this function.',
        isError: true
      };

    onEnqueueToastMessage({
      ...toastMessage,
      duration: 8000,
      source: 'Digest'
    });

    return {isCopied, copyContent};
  };

  refreshProblemStatus = digest => {
    const {editMode} = this.props;

    if(!digest?.favorites?.length || !editMode) {
      return {problemCustoms: []};
    }

    const {favorites} = digest;
    const {problemCustoms} = this.state || {};
    const problems = [];

    favorites.forEach(favorite => {
      if(favorite?.isCustom && !favorite?.curatedTitle || favorite.curatedTitle?.toLowerCase() === 'untitled') {
        problems.push(favorite);
      }
    });

    if(problemCustoms?.length !== problems.length) {
      return {problemCustoms: problems};
    }

    const didChange = problems.some((problem, index) => {
      return problemCustoms[index].id !== problem.id;
    });

    if(didChange) {
      return {problemCustoms: problems};
    }

    return {};
  };

  updateTheDigest = ({digestOptions, callback}) => {
    const {api: {digestUpdate, digestTypeUpdate}} = this.context;
    const {digestsContext: {didUpdateDigest}} = this.props;

    digestUpdate(digestOptions, updatedDigest => {
      const newProblemState = this.refreshProblemStatus(updatedDigest);

      if(updatedDigest) {
        const {banner: newBanner, digestType: {id: digestTypeId, reviewBannerUrl: currentBanner = ''} = {}} = updatedDigest;

        if(newBanner !== currentBanner) {
          digestTypeUpdate({id: digestTypeId, reviewBannerUrl: newBanner});
        }

        this.setState({
          digest: updatedDigest,
          savingDigest: false,
          hasUnsavedFields: false,
          ...newProblemState
        }, () => {
          didUpdateDigest && didUpdateDigest({digest: updatedDigest});
          console.log('Digest.updateTheDigest: updated digestId #%o: %o', updatedDigest.id, updatedDigest);
          (typeof callback === 'function') && callback();
        });
      }
      else {
        this.setState({
          savingDigest: false,
          hasUnsavedFields: false,
          ...newProblemState
        }, () => {
          const updateError = new Error('Digest.updateTheDigest: failed to update');

          console.error('Error:', updateError.message);

          (typeof callback === 'function') && callback(updateError);
        });
      }
    });
  };

  getCuratedCommentaryForUpdate = (inputField, originalValue, post) => {
    if(inputField) {
      const value = inputField.value.trim();

      if(!inputField.value.length && !originalValue) {
        return null;
      }

      if(value === getPinnedComment(post, this.getFavoriteContentOptions())) {
        return null;
      }

      return value;
    }

    return null;
  };

  handleSaveDigestClick = (event, callback = null) => {
    this._preventDefault(event);

    const digest = this._getDigest();

    if(_.isEmpty(digest)) {
      console.error('Digest.handleSaveDigestClick: no active digest found');

      return;
    }

    this.setState({
      savingDigest: true
    }, () => {
      if(!digest || digest.archivedAt) {
        if(digest.archivedAt) {
          // shouldn't be able to re-save/edit an archived digest
          console.warn('Digest.handleSaveDigestClick: active digest has already been archived: %o', digest);
        }

        return this.setState({savingDigest: false});
      }

      const {api: {favoritesUpdateBatch}, utils: {user: {id: lastCuratorId}}} = this.context;
      const {digestPosts = {}, subject, summary, banner} = this.state;

      // update favorites before updating the digest to ensure digest.favorites is update to date.
      // Previewing the digest + favorites can get out of sync otherwise.
      const favoritesToUpdate = [];
      const isDigestHtmlEditorEnabled = this.isDigestHtmlEditorEnabled();

      for(let i = 0; i < digest.favorites.length; i++) {
        const favorite = digest.favorites[i];
        const {postId} = favorite;
        const post = digestPosts[postId] || {};
        const favoriteFields = this._favoriteFields[postId];

        if(!favoriteFields) {
          // just in case post has been deleted but favorite hasn't
          console.warn('Digest.handleSaveDigestClick: related favorite fields for postId #%o not found', postId);

          continue;
        }

        const {title: curatedTitle, commentary: curatedCommentary, summary: curatedSummary, curatedRivalId, curatedUrl} = favoriteFields;

        // NOTE:
        // - commentary and summary can be left blank to override default values
        // - title is required and reverts to post title if blank
        const {favoriteSummary, favoriteCommentary} = getFavoriteContent(favorite, post, this.getFavoriteContentOptions());
        const {curatedRivalId: favoriteCuratedRivalId, curatedUrl: favoriteCuratedUrl} = favorite;
        const {value: curatedCommentaryValue} = curatedCommentary || {};
        const {value: curatedSummaryValue} = curatedSummary || {};
        const {value: curatedUrlValue} = curatedUrl || {};
        const newCuratedSummary = (curatedSummaryValue || '').trim();

        const favoriteOptions = {
          id: favorite.id,
          emailDigestId: digest.id,
          curatedRivalId: curatedRivalId || favoriteCuratedRivalId,
          curatedUrl: curatedUrlValue ? curatedUrlValue.trim() : favoriteCuratedUrl ? favoriteCuratedUrl : null,
          curatedTitle: (curatedTitle && curatedTitle.value) ? curatedTitle.value.trim() : null
        };

        if(!isDigestHtmlEditorEnabled) {
          Object.assign(favoriteOptions, {
            curatedCommentary: this.getCuratedCommentaryForUpdate(curatedCommentary, favoriteCommentary, post),
            curatedSummary: newCuratedSummary === favoriteSummary ? favorite.curatedSummary : newCuratedSummary
          });
        }

        const firstAttachment = post.attachments ? post.attachments[0] : null;
        const favoriteTitle = post.commentTitle || (firstAttachment ? firstAttachment.title : '(Untitled article)');

        // don't save curated title/summary if they match related post fields
        if(favoriteOptions.curatedTitle === favoriteTitle) {
          favoriteOptions.curatedTitle = null;
        }

        if(curatedTitle && !curatedTitle.value && favoriteFields.title) {
          // reset blank title input field to post title
          favoriteFields.title.value = favoriteTitle;
        }

        if(isDigestHtmlEditorEnabled) {
          // check if any of the non-editor fields have changed
          const favoriteIsDirty = isFavoriteUpdated({...favorite, emailDigestId: digest.id}, favoriteOptions) ||
            this._wimEditedByUser[postId] || this._summaryEditedByUser[postId];

          if(favoriteIsDirty) {
            if(this._wimEditedByUser[postId]) {
              favoriteOptions.curatedCommentary = (curatedCommentaryValue || '').trim();
            }

            if(this._summaryEditedByUser[postId]) {
              favoriteOptions.curatedSummary = (curatedSummaryValue || '').trim();
            }

            favoritesToUpdate.push(favoriteOptions);
          }
        }
        else if(isFavoriteUpdated({...favorite, curatedCommentary: favoriteCommentary, emailDigestId: digest.id}, favoriteOptions)) {
          // only update favorite if fields have changed
          favoritesToUpdate.push(favoriteOptions);
        }
      }

      this._wimEditedByUser = {};
      this._summaryEditedByUser = {};

      const digestOptions = {
        title: (subject || '').trim() || null,
        summary: (summary || '').trim() || null,
        banner: (banner || '').trim() || null,
        lastCuratorId,
        id: digest.id
      };

      if(favoritesToUpdate.length) {
        favoritesUpdateBatch(favoritesToUpdate, favoritesOrDigest => {
          console.log('Digest.handleSaveDigestClick: updated all favorites: %o', favoritesOrDigest);

          this.updateTheDigest({digestOptions, callback});
        });
      }
      else { // we know digest is set here so save it.
        this.updateTheDigest({digestOptions, callback});
      }
    });
  };

  handlePreviewsSentSuccess = rivalGroupIds => {
    this.setState({
      didSendPreviewAction: true,
      didSendRivalGroupIds: rivalGroupIds
    });
  };

  handlePreviewsSentFailures = failedRivalGroupIds => {
    const {rivalGroups} = this.props;

    this.setState({
      didSendPreviewAction: true,
      didSendPreviewFailures: (failedRivalGroupIds || []).reduce((acc, rivalGroupId) => {
        const match = (rivalGroupId === -1) ? {name: 'All Companies'} : rivalGroups.find(rivalGroup => rivalGroup.id === rivalGroupId);

        if(match) {
          acc.push(match.name);
        }

        return acc;
      }, []).sort((a, b) => a.localeCompare(b))
    });
  };

  handleSendPreviewsClick = (rivalGroupIds = []) => {
    const {api: {digestPreviewSend}} = this.context;
    const {activeDigestType: {id: typeId = null}, digest: {id}, sendingPreviews} = this.state;

    if(sendingPreviews) {
      return;
    }

    const promises = [];

    this.setState({
      sendingPreviews: true,
      previewsSentAt: null,
      didSendRivalGroupIds: null,
      didSendPreviewFailures: null

    }, () => {
      rivalGroupIds.forEach(rivalGroupId => {
        promises.push(new Promise((resolve, reject) => {
          digestPreviewSend({
            id,
            rivalGroupId: rivalGroupId < 0 ? null : rivalGroupId,
            typeId
          }, failure => {
            if(!failure) {
              resolve();
            }
            else {
              reject(rivalGroupId);
            }
          });
        }));
      });

      Promise.allSettled(promises).then(results => {
        const failedRivalGroupIds = [];

        results.forEach(result => {
          if(result.status === 'rejected') {
            failedRivalGroupIds.push(result.reason);
          }
        });

        this.setState({
          sendingPreviews: false,
          previewsSentAt: new Date()
        }, () => {
          if(failedRivalGroupIds.length) {
            this.handlePreviewsSentFailures(failedRivalGroupIds);
          }
          else {
            this.handlePreviewsSentSuccess(rivalGroupIds);
          }
        });
      });
    });
  };

  sendDigestPreview = (rivalGroupId = null) => {
    const {api: {digestPreviewSend}} = this.context;
    const {digest: {id}} = this.state;
    const {activeDigestType: {id: typeId = null}} = this.state;

    const params = {
      id,
      typeId,
      rivalGroupId
    };

    digestPreviewSend(params, () => this.setState({
      didSendPreviewAction: true
    }, () => {
      this._closeActiveModal();
    }));
  };

  // TODO: lots of chained actions here, should add some failure detection/user notifications
  handleSendDigestClick = event => {
    this._preventDefault(event);

    const {target: {id: action}} = event;

    if(action === 'send-preview') {
      return this.sendDigestPreview();
    }

    const {locked = false} = this._getDigest();
    const needsSave = this._hasUnsavedDigestFields() && !locked;

    if(needsSave && this.havePendingUploads()) {
      return this.waitForUploads({
        cancelTitle: 'Cancel Send Digest'
      }).then(() => this.handleSendDigestClick(event));
    }

    if(needsSave) {
      this.handleSaveDigestClick(event, this.renderSendDigestDialog);
    }
    else {
      this.renderSendDigestDialog();
    }
  };

  updateDigestTypeDataForSentDigest = typeId => {
    if(this.digestTypeIsArchived(typeId)) {
      return;
    }

    const {api: {digestCreate}} = this.context;

    digestCreate({typeId}, digest => {
      if(!digest) {
        return;
      }

      const {digestTypesData = []} = this.state;
      const {updateIndex} = findDigestTypesDataInsertionIndex(digestTypesData, '', typeId);

      if(updateIndex >= 0) {
        const updatedDigestTypesData = [...digestTypesData];
        const digestType = {...updatedDigestTypesData[updateIndex].digestType};

        updatedDigestTypesData.splice(updateIndex, 1, {digestType, draftDigest: {...digest, favorites: []}});

        this.setState({digestTypesData: updatedDigestTypesData});
      }
    });
  };

  handleSendDigest = async event => {
    this._preventDefault(event);
    event && event.stopPropagation();

    const {target} = event;
    const {api: {digestUpdate}} = this.context;
    const {history} = this.props;
    const {digest, activeDigestType: {id: typeId, deleteAfterSend = false}} = this.state;
    const button = target ? target.closest('.button') : null;
    const forceNextScheduled = (button && button.hasAttribute('data-force-next-scheduled'));
    const params = Object.assign({
      id: digest.id,
      sendNow: true
    }, forceNextScheduled && {forceNextScheduled});

    if(!button) {
      return this._closeActiveModal();
    }

    // disable buttons, add loading label, delay for readability
    button.closest('.digest-modal-buttongroup').querySelectorAll('.button').forEach(el => el.classList.add('button--disabled'));
    button.textContent = this._sendNowButtonLabels.sending;
    await wait(500);

    digestUpdate(params, sentDigest => this.setState({
      subject: null,
      summary: null,
      feedMode: false,
      digest: null,
      digestPosts: {},
      didSendNowAction: deleteAfterSend ? null : (forceNextScheduled ? 'sendNext' : 'skipNext')
    }, () => {
      console.log('Digest.handleSendDigest: digest sent successfully: %o', sentDigest);
      this.updateDigestTypeDataForSentDigest(typeId);
      this._closeActiveModal();
      this.unregisterNavConfirmation(() => {
        if(forceNextScheduled) {
          this.loadActiveDigest({}, typeId);
          this.loadDigests({typeId});
        }
        else {
          const state = {};

          if(!deleteAfterSend) {
            state.didSendNowAction = 'skipNext';
          }

          history.push({
            pathname: `/digest/${sentDigest.id}`,
            state
          });
        }

        const {digestsContext: {reloadDigests}} = this.props;

        reloadDigests && reloadDigests();
      });
    })
    );
  };

  handleDeleteDigestClick = (digest = {}, event) => {
    this._preventDefault(event);

    if(_.isEmpty(digest) || (digest.id <= 0)) {
      return;
    }

    const {api: {digestDelete}, utils: {dialog}} = this.context;
    const deleteDigestAction = () => {
      digestDelete({id: digest.id}, () => {
        console.log('Digest.handleDeleteDigestClick: deleted digestId #%o successfully', digest.id);

        const {page, activeDigestType: {id: typeId}} = this.state;
        let {totalDigests} = this.state;

        const totalPages = Math.ceil(--totalDigests / DIGESTS_PER_PAGE);
        const currentPage = page < totalPages ? page : totalPages;

        if(currentPage < page) {
          // in case we've deleted enough items to go down a page
          this.setState({
            page: currentPage,
            digests: []     // reset digests array to wipe out current page
          }, () => {
            this.loadDigests({currentPage, typeId});
          });
        }
        else {
          const {digests = []} = this.state;
          const digestIndex = digests.findIndex(d => d.id === digest.id);

          this.setState({
            digests: ReactUpdate(digests, {
              $splice: [[digestIndex, 1]]
            }),
            totalDigests
          });
        }
      });
    };

    dialog.confirm({
      message: 'Permanently delete this digest?',
      bodyContent: 'This action can\'t be undone.',
      okCallback: deleteDigestAction
    });
  };

  handleToggleFeed = event => {
    this._preventDefault(event);

    this.setState(prevState => ({
      feedMode: !prevState.feedMode,
      findPostId: null
    }));
  };

  handleAddCustomContent = async () => {
    const {api: {digestItemCreate, digestGet}} = this.context;
    const digest = this._getDigest();
    const {digestsContext: {didUpdateDigest}} = this.props;
    const {activeDigestType: {id: typeId}} = this.state;
    const {id: emailDigestId} = digest || {};

    if(!emailDigestId) {
      return;
    }

    if(this._hasUnsavedDigestFields()) {
      await this.handleSaveDigestClickSync(digest);
    }

    digestItemCreate({emailDigestId}, favorites => {
      if(!Array.isArray(favorites) || !favorites.length) {
        return;
      }

      const {postId} = favorites[0];

      if(!postId) {
        return;
      }

      digestGet(digest.id, typeId, refreshedDigest => {
        this.setState({digest: refreshedDigest}, () => {
          didUpdateDigest && didUpdateDigest({digest: refreshedDigest});

          const {postsContext: {refreshPost}} = this.props;

          refreshPost && refreshPost(postId);
          this.loadDigestPosts({updatePostId: postId}).then(() => {
            const textInput = this._favoriteFields[postId]?.title;
            
            textInput?.focus();
            textInput?.select();
          });
        });
      });
    });
  };

  handleToggleReorder = (favorites = [], event) => {
    this._preventDefault(event);

    const toggleReorderAction = () => {
      this.setState(prevState => ({
        reorderMode: !prevState.reorderMode,
        feedMode: false
      }));
    };

    if(!this.state.reorderMode) {
      // entering reorder mode
      this.sortFavorites(favorites, toggleReorderAction);
    }
    else {
      this.setState({favoritesSorted: []}, toggleReorderAction);
    }
  };

  handleToggleDigestLock = event => {
    this._preventDefault(event);

    const digest = this._getDigest();

    if(!digest || digest.archivedAt) {
      // shouldn't be able to update locked state on an archived digest
      return;
    }

    const toggleLockAction = () => {
      const {api: {digestUpdate}, utils: {user: {id: lastCuratorId}}} = this.context;
      const digestOptions = {
        id: digest.id,
        locked: !digest.locked,
        lastCuratorId
      };

      digestUpdate(digestOptions,
        updatedDigest => this.setState({
          digest: updatedDigest,
          savingDigest: false
        }, () => {
          const {digestsContext: {didUpdateDigest}} = this.props;

          didUpdateDigest && didUpdateDigest({digest: updatedDigest});
          console.log('Digest.handleToggleDigestLock: %o digestId #%o: %o', digestOptions.locked ? 'locked' : 'unlocked', updatedDigest.id, updatedDigest);
        }));
    };

    this.setState({savingDigest: true}, toggleLockAction);
  };

  handleEditingInput = (inputId = null) => ((inputId !== this.state.editingInput) && this.setState({editingInput: inputId}));

  handleChangeLogo = (e, rivalId, postId) => {
    e && e.preventDefault();
    this._setFavoriteInput(postId, 'curatedRivalId', rivalId);
    this.setState({hasUnsavedFields: true});
  };

  renderCopyContentsHeader = (digest = null) => {
    const {subject, summary} = this._getDigestOverview(digest);
    const isHtmlEditorEnabled = this.isDigestHtmlEditorEnabled();
    let emptySummaryPrefix;

    // NOTE: <br />s are used for proper spacing when copying from clipboard to plain text
    if(!subject && !summary) {
      emptySummaryPrefix = (
        <p>Team,<br /><br />Here is what the market has been up to since our last digest.<br /></p>
      );
    }

    return (
      <div className="digest-header">
        {subject && (
          <h1 className="digest-body_subject">
            <strong>Subject:</strong> {subject}
            <br />
          </h1>
        )}
        {summary && (
          <p className="digest-body_summary"
            dangerouslySetInnerHTML={{__html: isHtmlEditorEnabled
              ? sanitizeDigestHtml(summary)
              : processLinks(sanitizeDigestHtml(summary))}} />
        )}
        <div className="digest-body_share">
          {emptySummaryPrefix}
          <p>
            Full activity stream <a
              href={addTrackingParams('/feed', {
                source: trackingParamSources.digest,
                content: 'feed-summary'
              })}
              target="_blank">here.</a> Find something worth sharing?
            Hit the <a
              href={addTrackingParams(`//${this.context.appData.v2Host}/integrations#button`, {
                source: trackingParamSources.digest,
                content: 'kluebutton-summary'
              })}
              target="_blank">Klue Button</a>. Top articles
            and commentary will be selected for the next digest.
            <br />
          </p>
        </div>
      </div>
    );
  };

  renderCopyContentsFooter = () => {
    return (
      <table>
        <tbody>
          <tr><td height="12" /></tr>
          <tr>
            <td style={{borderTop: '1px solid #dfe2dd'}}>
              <p className="digest-footer">
                Digest created in <a
                  href={addTrackingParams('https://klue.com', {
                    source: trackingParamSources.digest,
                    content: 'klue-footer'
                  })}
                  target="_blank">Klue</a>,
                your competitive intelligence hub. Put your name in lights: contribute intel with
                the <a
                  href={addTrackingParams(`//${this.context.appData.v2Host}/integrations#button`, {
                    source: trackingParamSources.digest,
                    content: 'kluebutton-footer'
                  })}
                  target="_blank">Klue Button</a>.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  renderSendOneTimeDigestDialog = () => {
    const {utils: {dialog}} = this.context;
    const {digest} = this.state;
    const {digestType: {digestRecipientsCount = 0, reviewFrequency = 'weekly', deleteAfterSend = false} = {}} = digest;

    const sendButtonsRegion = [
      (<div key="send_button" className="digest-modal-buttongroup">
        <div className="button" data-action="send" onClick={this.handleSendDigest}>
          Send Now
        </div>
      </div>),
      (<div key="cancel_button" className="button button--disabled" data-action="cancel" onClick={this._closeActiveModal}>
        Cancel
      </div>)
    ];

    const modalContent = (
      <div className="digest-modal">
        <div className="digest-modal_close" onClick={this._closeActiveModal}>
          <Icon icon="close" width="24" height="24" className="digest-modal_close_icon" />
        </div>
        <h2>Send This {reviewFrequency === 'never' ? 'Manual' : (deleteAfterSend ? 'One-Time' : '')} Digest?</h2>
        <h3>
          <Icon icon="warning" className="digest-modal-icon" />
          You are about to email this Digest to {digestRecipientsCount.toLocaleString()} {(digestRecipientsCount === 1) ? 'person' : 'people'}.
        </h3>
        <div>
          {sendButtonsRegion}
          <div className="digest-modal-warn">
            <em>You cannot undo this.</em>
          </div>
        </div>
      </div>
    );

    this._modalId = 'digest-send-modal';

    dialog.create({
      id: this._modalId,
      _wideMode: true,
      content: modalContent
    });
  };

  _sendNowButtonLabels = {
    skipNext: (<span>Send Now <b>+</b> Skip Next</span>),
    forceNext: (<span>Send Now <b>+</b> Send Next</span>),
    sending: 'Sending Digest...',
    cancel: 'Cancel'
  };

  renderSendDigestDialog = () => {
    const {digests: archivedDigests = [], digest} = this.state;
    const {digestType: {digestRecipientsCount = 0, reviewFrequency = 'weekly', reviewItemsPerRival = 0, deleteAfterSend = false} = {}} = digest;

    if(deleteAfterSend || reviewFrequency === 'never' || reviewFrequency === 'once') {
      return this.renderSendOneTimeDigestDialog();
    }

    const sendTime = getNextDigestUTCDateTime(digest);
    const nextDigestTime = renderNextDigestSendTime(sendTime, false, true);
    const {_sendNowButtonLabels: labels} = this;
    const sendButtonsRegion = [
      (<div key="send_buttons" className="digest-modal-buttongroup">
        <div className="button" onClick={this.handleSendDigest} data-force-next-scheduled={true}>
          {labels.forceNext}
        </div>
        <div className="digest-modal-buttongroup_or">OR</div>
        <div className="button" onClick={this.handleSendDigest}>
          {labels.skipNext}
        </div>
      </div>),
      (<div key="cancel_button" className="button button--disabled" data-action="cancel" onClick={this._closeActiveModal}>
        {labels.cancel}
      </div>)
    ];
    let lastDigestRegion;

    if(archivedDigests.length) {
      // find first sent (archived) digest
      const lastDigest = archivedDigests.find(d => Boolean(d.archivedAt));
      const now = moment();

      if(!_.isEmpty(lastDigest)) {
        const lastDigestSentDate = moment(lastDigest.archivedAt || lastDigest.createdAt);

        lastDigestRegion = (
          <p>The last digest was sent <time dateTime={lastDigestSentDate.format()} title={lastDigestSentDate.format('LLLL')}>
            {now.to(lastDigestSentDate, true)}
          </time> ago.
            Are you sure?
          </p>
        );
      }
    }

    // TODO: extract new modal styles to Dialog component
    const modalContent = (
      <div className="digest-modal">
        <div className="digest-modal_close" onClick={this._closeActiveModal}>
          <Icon icon="close" width="24" height="24" className="digest-modal_close_icon" />
        </div>
        <h2>Start Sending Email?</h2>
        <h3>
          <Icon icon="warning" className="digest-modal-icon" />
          You are about to email this Digest to {digestRecipientsCount.toLocaleString()} {(digestRecipientsCount === 1) ? 'person' : 'people'}.
        </h3>
        {sendTime ? <h3><Icon className="digest-modal-icon" icon="alarm" /> Another Digest is set to auto-send in {nextDigestTime}.</h3> : null}
        {lastDigestRegion}
        <footer className="digest-modal_footer digest-modal_footer--left">
          {sendButtonsRegion}
          <div className="digest-modal-warn">
            <em>You cannot undo this. {reviewItemsPerRival > 0 ? 'Klue will automatically start building the next Digest.' : ''}</em>
          </div>
        </footer>
      </div>
    );

    this._modalId = 'digest-send-modal';
    this.context.utils.dialog.create({
      id: this._modalId,
      _wideMode: true,
      content: modalContent
    });
  };

  renderDigestByline = (dateUpdated = '', curatorId = 0) => {
    if(!dateUpdated || !curatorId) {
      return;
    }

    const curator = (this.props.users || {})[curatorId];
    const updated = moment(dateUpdated);
    let curatorRegion;

    if(curator) {
      curatorRegion = (
        <span>
          by <Link to={`/users/${curator.id}`}>@{curator.username}</Link>
        </span>
      );
    }

    return (
      <div className="digest-byline">
        Last edited <time dateTime={updated.format()} title={updated.format('LLLL')}>
          {updated.format('ll')}
        </time> {curatorRegion}
      </div>
    );
  };

  curatedRivalIdForFavorite = favorite => {
    const {postId = 0, curatedRivalId} = favorite;

    return this._favoriteFields[postId]?.curatedRivalId || curatedRivalId;
  };

  updateUploadedBanner = uploadUrl => {
    this.setState({
      banner: uploadUrl
    }, this._handleInputChange);
  };

  handlePostBoardsDidChange = favorite => this.handleRefreshFavoriteFromPost(favorite, null, false, false);

  handlePostBoardsChangeFailue = error => {
    console.error('Digest.handlePostBoardsChangeFailue: failed to update post boards: %o', error);
  };

  handlePostBoardsUpdate = (b, postId, favorite, action) => {
    const {name} = b;
    const {api: {postCompetitorTagUpdate}} = this.context;

    if(!name) {
      return;
    }

    const handlePostBoardsDidChange = () => this.handlePostBoardsDidChange(favorite);
    const handlePostBoardsChangeFailue = error => this.handlePostBoardsChangeFailue(error, postId, favorite);

    postCompetitorTagUpdate(postId, {[action]: [name.toLowerCase()]}, handlePostBoardsDidChange, handlePostBoardsChangeFailue);
  };

  handleOnAddBoard = (b, postId, favorite) => this.handlePostBoardsUpdate(b, postId, favorite, 'addTags');

  handleOnDeleteBoard = (b, postId, favorite) => this.handlePostBoardsUpdate(b, postId, favorite, 'deleteTags');

  renderFavorites = (digest = null, copyMode = false) => {
    if(_.isEmpty(digest)) {
      return;
    }

    const {favorites, archivedAt} = digest;
    const {utils: {company, user}} = this.context;
    const {users, rivals} = this.props;
    const {digestPosts = {}, editingInput, reorderMode} = this.state;

    if(!favorites || !favorites.length) {
      if(!copyMode && !digest.title && !digest.summary && archivedAt && !digest.manual) {
        // show empty state for curators on empty, sent, non-custom digests
        return (
          <div className="dialogue dialogue--notice dialogue--digest-empty">
            <h4>Empty digest 😢</h4>
            <p>This digest was empty, so we didn&apos;t send it out to your users. We&apos;ve kept a copy here for reference.</p>
          </div>
        );
      }

      return;
    }

    const favoritesToRender = this._getFavoritesToRender(favorites);

    return favoritesToRender.map((favorite, index) => {
      const {postId = 0, id: favoriteId, isCustom = false, curatedUrl} = favorite;
      const post = digestPosts[postId];
      let attachmentsRegion;

      const curatedRivalId = this.curatedRivalIdForFavorite(favorite);

      if(post && post.attachments) {
        const attachments = post.attachments.filter(a => a.type !== 'page').map(({id, url, sourceName, title}) => {
          return (
            <li className="digest-favorite_attachment" key={`attachment-${id}`}>
              <Icon icon="attachment" className="attachment-icon" />
              <a href={url} target="_blank" className="attachment-link">{sourceName || title || 'Attachment'}</a>
            </li>
          );
        });

        attachmentsRegion = attachments.length ? (
          <div className="digest-favorite_attachments">
            <ul>
              {attachments}
            </ul>
          </div>
        ) : null;
      }

      if(_.isEmpty(post)) {
        return;
      }

      const {favoriteSummary, favoriteCommentary, favoriteTitle} = getFavoriteContent(
        favorite,
        post,
        {
          shouldProcessSummary: false,
          htmlEditorEnabled: this.isDigestHtmlEditorEnabled()
        }
      );

      if(reorderMode && !copyMode && this._canEditDigest()) {
        // show condensed reorder view with drag handle
        return (
          <DigestFavoriteDraggable
            key={`f_${favoriteId}`}
            className="dialogue digest-favorite digest-favorite--collapsed"
            canDrag={true}
            canDrop={true}
            onHandleHover={this.handleReorderFavoriteHover}
            droppableCardIndex={index}>
            <h2 className="digest-favorite_headline">{decodeCommonEntities(favoriteTitle)}</h2>
            <div className="digest-favorite_icon digest-favorite_icon--reorder">
              <Icon icon="reorder" width="20" height="20" />
            </div>
          </DigestFavoriteDraggable>
        );
      }

      const curator = (users || {})[favorite.userId];
      const posterId = post.viaUserId || post.userId;
      const poster = users[posterId];
      const favoriteFormClass = classNames('dialogue digest-favorite digest-form', {
        'dialogue--edit': editingInput === favoriteId,
        'digest-favorite--kluebot': curator && userIsKluebot({userId: curator.id, company})
      });
      const curatorTime = moment(favorite.updatedAt || favorite.createdAt);
      const bylineTime = moment(post.commentTimestamp);
      const rivalId = this.curatedRivalIdForFavorite(favorite);
      const usePublishedOnlyRivals = Boolean(!rivalId);
      const logoRival = getLogoRivalWithRivalId(favorite, rivalId, rivals, usePublishedOnlyRivals);
      const companyLogo = (
        <CompanyLogo
          className="logo"
          rival={logoRival}
          src={company.iconUrl || KlueLogo} />
      );

      let favoriteToolbarRegion;
      let revertFavoriteRegion;
      let deleteFavoriteRegion;
      let customURLRegion;
      let headlineRegion;
      let summaryRegion;
      let contextualizeRegion;
      let posterLink;
      let curatorLink;

      if(archivedAt || copyMode || !this._canEditDigest()) {
        // NOTE: when copying to clipboard:
        // - URL targets of <Link> tags end up empty when pasting to HTML-enabled apps, so need to use regular <a> instead
        // - secondary headline URL is for copy-to-plain-text mode (hidden when copying to HTML)
        // - inline styles are for Outlook 2016 (etc.) compatibility
        const link = !isCustom ? `/posts/${postId}` : curatedUrl;
        const title = decodeCommonEntities(favoriteTitle);
        
        if(copyMode) {
          // GA custom dimensions: utm_dimension1 = Company ID, utm_dimension4 = isCurator, utm_dimension6 = UserId
          const gaContentParams = `post-body&utm_dimension1=${company.id}&utm_dimension4=false&utm_dimension6=0`;

          headlineRegion = (
            <table className="digest-favorite-copy_headline">
              <tbody>
                <tr>
                  <td style={{paddingTop: '10px', paddingBottom: '10px', verticalAlign: 'top'}}>
                    {!link 
                      ? title 
                      : !isCustom
                        ? (<><a
                            href={addTrackingParams(`/posts/${postId}`, {
                              source: trackingParamSources.digest,
                              content: gaContentParams
                            })}
                            target="_blank">
                          {title} <span className="digest-favorite-copy_headline_icon">&#10138;</span>
                        </a>
                          <span className="digest-favorite-copy_headline_url">
                            {addTrackingParams(`${this.context.appData.rootUrl}posts/${postId}`, {
                              source: trackingParamSources.digest,
                              content: gaContentParams
                            })}
                          </span></>)
                        : <a href={addTrackingParams(link)} target="_blank">{title}</a>}
                  </td>
                </tr>
              </tbody>
            </table>
          );
        }
        else {
          headlineRegion = (
            <div className="digest-favorite_headline-region">
              {companyLogo}
              <h2 className="digest-favorite_headline">
                {!link 
                  ? title 
                  : isCustom ? <a href={link} target="_blank">{title}</a> : <Link to={link}>{title}</Link>}
              </h2>
            </div>
          );
        }

        contextualizeRegion = favoriteCommentary && (
          <div dangerouslySetInnerHTML={wrapHtml(favoriteCommentary, false)} />
        );

        summaryRegion = favoriteSummary && (
          <div className="digest-quote digest-quote_item_body">
            <p
              dangerouslySetInnerHTML={wrapHtml(this.isDigestHtmlEditorEnabled()
                ? favoriteSummary
                : processLinks(sanitizeDigestHtml(favoriteSummary)), false)} />
          </div>
        );
      }
      else {
        // NOTE: callback refs split out (instead of inline) so as to not re-render with invalid/null values
        // see:
        // - https://itnext.io/dont-use-inline-functions-or-bind-in-react-ref-callbacks-5559e4342ead
        // - https://hackernoon.com/refs-in-react-all-you-need-to-know-fb9c9e2aeb81

        const setTitleInput = title => title && this._setFavoriteInput(postId, 'title', title);
        const setCommentaryInput = commentary => commentary && this._setFavoriteInput(postId, 'commentary', commentary);
        const setSummaryInput = summary => summary && this._setFavoriteInput(postId, 'summary', summary);
        const setWhyItMattersRef = ref => this._wimRefs[postId] = ref;
        const setSummaryRef = ref => this._summaryRefs[postId] = ref;
        const handleDidChangeCustomURL = url => {
          this._setFavoriteInput(postId, 'curatedUrl', {value: url});
          this._handleInputChange();
        };

        const showHtmlDigestEditor = this.isDigestHtmlEditorEnabled();

        revertFavoriteRegion = !showHtmlDigestEditor ? (
          <a
            href="#"
            className="digest-favorite_toolbar-link"
            onClick={e => this.handleRefreshFavoriteFromPost(favorite, e)}>
            <Icon icon="refresh" /><span className="digest-favorite_toolbar-label">Revert Text</span>
          </a>
        ) : null;

        deleteFavoriteRegion = (
          <a
            href="#"
            className="digest-favorite_toolbar-link digest-favorite_toolbar-link--alert digest-favorite_toolbar-link--delete"
            onClick={e => this.handleDeleteFavorite(postId, e)}>
            <Icon icon="close" />
          </a>
        );

        customURLRegion = isCustom ? (<DigestCustomURL
          id={favoriteId}
          initialValue={curatedUrl}
          onDidChangeURL={handleDidChangeCustomURL} />) : null;

        const titleClass = customURLRegion ? 'digest-favorite_headline-region-header' : 'digest-favorite_headline-region';
        const titleRegion = (
          <div className={titleClass}>
            {companyLogo}
            <TextArea
              ref={setTitleInput}
              className={classNames('digest-textarea digest-textarea--headline', {'html-enabled': this.isDigestHtmlEditorEnabled()})}
              minRows={1}
              placeholder="This is the article headline."
              onChange={this._handleInputChange}
              defaultValue={decodeCommonEntities(favoriteTitle) || ''} />
          </div>
        );

        if(customURLRegion) {
          headlineRegion = (
            <div className="digest-favorite_headline-region-with-link">
              {titleRegion}
              {customURLRegion}
            </div>
          );
        }
        else {
          headlineRegion = titleRegion;
        }

        contextualizeRegion = showHtmlDigestEditor ? (
          <InlineEditor
            identifier={`wim-${favoriteId}`}
            ref={setWhyItMattersRef}
            extraClass="wim-info"
            placeholder="Tell your co-workers why this matters. A one liner makes a big difference."
            text={favoriteCommentary}
            data={{postId}}
            rivals={rivals}
            onUpdate={this.handleWhyItMattersInputChange}
            onInitialized={this.handleWhyItMattersInitialized}
            onImageUploadStatus={this.handleImageUploadStatus}
            onShowInsertLink={this.handleShowInsertLink}
            context={this.context} />
        ) : (
          <TextArea
            ref={setCommentaryInput}
            className="digest-textarea digest-textarea--contextualize"
            minRows={1}
            placeholder="Tell your co-workers why this matters. A one liner makes a big difference."
            onChange={this._handleInputChange}
            defaultValue={decodeCommonEntities(favoriteCommentary) || ''} />
        );

        summaryRegion = showHtmlDigestEditor ? (
          <InlineEditor
            identifier={`summary-${favoriteId}`}
            ref={setSummaryRef}
            extraClass="summary-info"
            placeholder={isCustom ? 'Add your summary here.' : 'This is the article summary.'}
            text={favoriteSummary}
            data={{postId}}
            rivals={rivals}
            onUpdate={this.handleSummaryInputChange}
            onInitialized={this.handleSummaryInitialized}
            onImageUploadStatus={this.handleImageUploadStatus}
            onShowInsertLink={this.handleShowInsertLink}
            context={this.context} />
        ) : (
          <blockquote className="digest-quote">
            <TextArea
              ref={setSummaryInput}
              className="digest-textarea digest-textarea--quote"
              minRows={1}
              placeholder="This is the article summary."
              onChange={this._handleInputChange}
              defaultValue={favoriteSummary || ''} />
          </blockquote>
        );
      }

      if(!_.isEmpty(curator) && userCanCurate({user})) {
        curatorLink = (
          <span>Added to Digest by <Link to={`/users/${curator.id}`}>@{curator && curator.username}</Link></span>
        );
      }

      if(poster && !isCustom) {
        posterLink = (
          <span>Sent in by <Link to={`/users/${posterId}`}>@{poster?.username || '(unknown user)'}</Link></span>
        );
      }

      // output for clipboard-copied version
      if(copyMode) {
        if(poster && !isCustom) {
          posterLink = (
            <a
              href={addTrackingParams(`/users/${posterId}`, {
                source: trackingParamSources.digest,
                content: 'user-body'})}
              target="_blank">{`@${poster.username}`}</a>
          );
        }

        if(favoriteCommentary) {
          contextualizeRegion = (
            <table>
              <tbody>
                <tr>
                  <td className="digest-favorite-copy_commentary_headline">Why It Matters?</td>
                </tr>
                <tr>
                  <td
                    className="digest-favorite-copy_commentary"
                    dangerouslySetInnerHTML={wrapHtml(favoriteCommentary, false)} />
                </tr>
                <tr><td height="12" /></tr>
              </tbody>
            </table>
          );
        }

        if(favoriteSummary) {
          summaryRegion = (
            <table>
              <tbody>
                <tr>
                  <td width="12" style={{borderLeft: '2px solid #eceeeb'}} />
                  <td
                    className="digest-favorite-copy_summary"
                    dangerouslySetInnerHTML={wrapHtml(favoriteSummary, false)} />
                </tr>
                <tr><td colSpan="2" height="12" /></tr>
              </tbody>
            </table>
          );
        }

        // return clipboard output for favorite
        // NOTE: additional <br />s are used for proper spacing when copying from clipboard to plain text
        return (
          <table id={`favorite_${favoriteId}`} key={`f_${favoriteId}`} className="digest-favorite-copy" width="100%">
            <tbody>
              <tr>
                <td>
                  {headlineRegion}
                  {favoriteCommentary && contextualizeRegion}
                  {favoriteSummary && summaryRegion}
                  {!isCustom ? (<table>
                    <tbody>
                      <tr>
                        <td className="digest-favorite-copy_byline">
                          Sent in by {posterLink || '(unknown user)'} <time
                            className="digest-time"
                            dateTime={bylineTime.format()}
                            title={bylineTime.format('LLLL')}>{bylineTime.format('ll')}</time>
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>) : null}
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        );
      }

      if(userCanCurate({user})) {
        favoriteToolbarRegion = (
          <div className="ui--toolbar digest-favorite_toolbar">
            {!isCustom ? <a href="#" className="digest-favorite_toolbar-link" onClick={e => this.handleFindPost(postId, e)}>
              <Icon icon="search" /><span className="digest-favorite_toolbar-label">Find in feed</span>
            </a> : null}
            {revertFavoriteRegion}
            {deleteFavoriteRegion}
          </div>
        );
      }

      const filteredRivals = getRivalsForTags(favorite.competitors, rivals, {publishedOnly: Boolean(archivedAt)});
      const firstPublishedRivalWithSomeCards = [...filteredRivals]
        .sort((r1, r2) => r1.id - r2.id) // sort to ensure default rival is one with lowest id... to match what the BE does.
        .find(r => !isDraftRival(r) && r?.profile?.cardsCount);
      const handleOnAddBoard = b => this.handleOnAddBoard(b, postId, favorite);
      const handleOnDeleteBoard = b => this.handleOnDeleteBoard(b, postId, favorite);

      return (
        <form
          ref={ref => this._postRefs[postId] = ref}
          id={`f_${favoriteId}`}
          key={`f_${favoriteId}`}
          className={favoriteFormClass}
          onFocus={() => this.handleEditingInput(favoriteId)}
          onBlur={this.handleEditingInput}
          onSubmit={this._preventDefault}
          style={{zIndex: favoritesToRender.length - index}}>
          {favoriteToolbarRegion}
          <div className="digest-favorite_body">
            {headlineRegion}
            <DigestCompetitors
              id={favoriteId}
              rivals={filteredRivals}
              selectedRivalId={archivedAt ? null : curatedRivalId}
              defaultRival={archivedAt ? null : firstPublishedRivalWithSomeCards}
              className="digest-favorite_tags"
              onClickRival={archivedAt ? null : (e, rivId) => this.handleChangeLogo(e, rivId, postId)}
              showPrefix={false}
              allRivals={rivals}
              onDeleteBoard={handleOnDeleteBoard}
              onAddBoard={archivedAt ? null : handleOnAddBoard} />
            {contextualizeRegion && (
              <div className="digest-wim">
                <p className="digest-wim-label" data-tracking-id="digest-why-it-matters-label">Why It Matters</p>
                {contextualizeRegion}
              </div>
            )}
            {summaryRegion && (
              <div>
                <p><strong className="digest-form_label">Summary</strong></p>
                {summaryRegion}
                {attachmentsRegion}
              </div>
            )}
            <div className="digest-added-by-footer">
              <p className="digest-form_byline">
                {!isCustom ? posterLink : null}
                {!isCustom 
                  ? <time className="digest-time" dateTime={bylineTime.format()} title={bylineTime.format('LLLL')}>{bylineTime.format('ll')}</time> 
                  : null}
              </p>
              <p className="digest-form_byline">
                {curatorLink}
                <time className="digest-time" dateTime={curatorTime.format()} title={curatorTime.format('LLLL')}>{curatorTime.format('ll')}</time>
              </p>
            </div>
          </div>
        </form>
      );
    });
  };

  setDigestIntroRef = ref => this._digestIntroRef = ref;

  setBodyRef = ref => {
    if(ref) {
      this.resizeObserver.observe(ref);
    }
    else if(this.bodyRef.current) {
      this.resizeObserver.unobserve(this.bodyRef.current);
    }

    this.bodyRef.current = ref;
  };

  setFeedRef = ref => {
    if(ref) {
      this.resizeObserver.observe(ref);
    }
    else if(this.feedRef.current) {
      this.resizeObserver.unobserve(this.feedRef.current);
    }

    this.feedRef.current = ref;
  };

  setSideBarRef = ref => {
    if(ref) {
      this.resizeObserver.observe(ref);
    }
    else if(this.sideBarRef.current) {
      this.resizeObserver.unobserve(this.sideBarRef.current);
    }

    this.sideBarRef.current = ref;
  };

  setDigestArchiveRef = ref => {
    if(ref) {
      this.resizeObserver.observe(ref);
    }
    else if(this.digestArchiveRef.current) {
      this.resizeObserver.unobserve(this.digestArchiveRef.current);
    }

    this.digestArchiveRef.current = ref;
  };

  handleDigestTemplateSelected = ({title, summary}) => {
    this._digestIntroRef?.setText(summary || '', false);
    this.setState({
      [digestHeaderInputs.SUBJECT]: title
    }, this._handleInputChange);
  };

  handleManageDigestTemplates = () => {
    const {digest} = this.state;

    if(!digest) {
      return;
    }

    this.setState({isManageDigestTemplates: true}, () => this.handleEditDigestTypeSettings(digest));
  };

  handleDigestTypeUpdatedNewInKlueToggle = updatedDigestType => {
    const {id: typeId} = updatedDigestType || {};

    if(!typeId) {
      return;
    }

    const {digestTypes} = this.state;
    const updatedDigestTypes = digestTypes.map(dt => {
      if(dt.id === typeId) {
        return updatedDigestType;
      }

      return dt;
    });

    const {digestsContext: {didUpdateDigestType}} = this.props;

    didUpdateDigestType && didUpdateDigestType({digestType: updatedDigestType});
    this.setState({digestTypes: updatedDigestTypes, activeDigestType: updatedDigestType});
  };

  renderDigestBody = () => {
    const digest = this._getDigest();

    if(_.isEmpty(digest)) {
      return;
    }

    const {editMode, rivals, archivesMode} = this.props;
    const {imagesUploadingCount, hasUnsavedFields, activeDigestType} = this.state;
    const editorBusy = Boolean(imagesUploadingCount);
    const htmlEnabled = this.isDigestHtmlEditorEnabled();
    const {utils: {user, isDigestTemplatesEnabled, isTurnOffNewInKlueDigestEnabled}, api: {digestTypeUpdate}} = this.context;
    const showNewInKlueToggle = !digest.archivedAt && !digest.manual && isTurnOffNewInKlueDigestEnabled();
    const {reorderMode, editingInput, didSendNowAction, didSendPreviewAction, didSendPreviewFailures, didSendRivalGroupIds} = this.state;
    const isCurator = userCanCurate({user});
    const digestTemplatesEnabled = isCurator && isDigestTemplatesEnabled();
    const digestByline = isCurator && this.renderDigestByline(digest.archivedAt || digest.updatedAt, digest.lastCuratorId || digest.userId);
    const favoritesClass = classNames('digest-favorites', {
      'digest-favorites--compact': !isCurator
    });

    const digestBylineRegion = digestByline && (
      <footer className="digest-form_byline">
        {digestByline}
      </footer>
    );

    // TODO: split these into view/edit sub-components
    if(editMode) {
      // editing digest
      const overviewCopyArgs = {digest, rivals};
      const subjectPlaceholder = getDefaultOverviewCopy({...overviewCopyArgs, plainText: false, inputType: digestHeaderInputs.SUBJECT});
      const summaryPlaceholder = getDefaultOverviewCopy({...overviewCopyArgs, plainText: false, inputType: digestHeaderInputs.SUMMARY});
      const digestSummary = digest.summary
        ? (htmlEnabled
          ? sanitizeDigestHtml(digest.summary)
          : processLinks(sanitizeDigestHtml(digest.summary)))
        : summaryPlaceholder;
      const headerMessages = [];
      let headerRegion;
      let subjectRegion;
      let uploadRegion;
      let digestSummaryRegion;
      let summaryRegion;

      if(digest.summary) {
        digestSummaryRegion = (
          htmlEnabled
            ? <div className="digest-body_summary" dangerouslySetInnerHTML={wrapHtml(digestSummary, false)} />
            : <h3 className="digest-body_summary" dangerouslySetInnerHTML={wrapHtml(digestSummary, false)} />
        );
      }
      else {
        digestSummaryRegion = (
          htmlEnabled
            ? <div className="digest-body_summary">{digestSummary}</div>
            : <h3 className="digest-body_summary">{digestSummary}</h3>
        );
      }

      const {subject, summary, banner} = this.state;

      if(!digest.archivedAt || digest.manual) {
        // render draft digest or custom non-archived digest
        if(!reorderMode && this._canEditDigest()) {
          const alwaysShowEditIntro = true;
          const subjectClass = classNames('dialogue dialogue-subject', {
            'dialogue--edit': editingInput === digestHeaderInputs.SUBJECT,
            'dialogue--hidden': !alwaysShowEditIntro && !digest.manual
          });
          const summaryClass = classNames('dialogue dialogue-summary', {
            'dialogue--edit': editingInput === digestHeaderInputs.SUMMARY,
            'dialogue--hidden': !alwaysShowEditIntro && !digest.manual
          });
          const uploadClass = classNames('dialogue dialogue-banner', {
            'dialogue--hidden': !alwaysShowEditIntro && !digest.manual
          });
          const subjectInputPlaceholder = getDefaultOverviewCopy({
            ...overviewCopyArgs,
            inputType: digestHeaderInputs.SUBJECT,
            plainText: true
          });
          const summaryInputPlaceholder = getDefaultOverviewCopy({
            ...overviewCopyArgs,
            inputType: digestHeaderInputs.SUMMARY,
            plainText: true
          });

          subjectRegion = (
            <div className={subjectClass}>
              <form
                className="digest-form"
                onFocus={() => this.handleEditingInput(digestHeaderInputs.SUBJECT)}
                onBlur={this.handleEditingInput}
                onSubmit={this._preventDefault}>
                <strong className="digest-form_label subject">Subject:</strong>
                <TextArea
                  minRows={1}
                  className="digest-textarea digest-textarea--subject digest-body_overview_subject"
                  data-testid="digest-subject-input"
                  placeholder={subjectInputPlaceholder}
                  value={subject || ''}
                  onKeyDown={e => (e.key === 'Enter') && e.preventDefault()}
                  onChange={e => this._handleOverviewInputChange(digestHeaderInputs.SUBJECT, e, {stripBreaks: true})} />
              </form>
              {digestTemplatesEnabled && <DigestTemplateSelect
                title={(subject || '').trim()}
                summary={(summary || '').trim()}
                onSelectTemplate={this.handleDigestTemplateSelected}
                onManageTemplates={this.handleManageDigestTemplates} />}
            </div>
          );

          uploadRegion = (<DigestBanner bannerStyle={uploadClass} updateUploadedBanner={this.updateUploadedBanner} banner={banner} />);

          summaryRegion = this.isDigestHtmlEditorEnabled() ? (
            <div className={summaryClass}>
              <h3>
                <strong className="digest-form_label">Digest Intro:</strong>
              </h3>
              <InlineEditor
                ref={this.setDigestIntroRef}
                identifier="digest-info"
                extraClass="digest-info"
                placeholder={summaryInputPlaceholder}
                text={summary}
                rivals={rivals}
                onUpdate={this.handleOverviewInputChange}
                onImageUploadStatus={this.handleImageUploadStatus}
                onShowInsertLink={this.handleShowInsertLink}
                context={this.context} />
              {digestBylineRegion}
            </div>
          ) : (
            <div className={summaryClass}>
              <form
                className="digest-form"
                onFocus={() => this.handleEditingInput(digestHeaderInputs.SUMMARY)}
                onBlur={this.handleEditingInput}
                onSubmit={this._preventDefault}>
                <p>
                  <strong className="digest-form_label">Digest Intro:</strong>
                </p>
                <TextArea
                  className="digest-textarea digest-textarea--summary digest-body_overview_summary"
                  data-test-id="digest-summary"
                  minRows={1}
                  placeholder={summaryInputPlaceholder}
                  onChange={e => this._handleOverviewInputChange(digestHeaderInputs.SUMMARY, e)}
                  value={summary || ''} />
                {digestBylineRegion}
              </form>
            </div>
          );
        }
        else {
          // reorder mode or locked digest
          subjectRegion = (
            <div className="dialogue">
              <h1 className="digest-body_subject">{digest.title || subjectPlaceholder}</h1>
            </div>
          );

          summaryRegion = (
            <div className="dialogue">
              {digestSummaryRegion}
              {digestByline && !reorderMode && (
                <footer className="digest-form_byline">
                  {digestByline}
                </footer>
              )}
            </div>
          );
        }
      }
      else {
        // archived (sent, un-editable) digest
        subjectRegion = (
          <div className="dialogue">
            <h1 className="digest-body_subject">{digest.title || subjectPlaceholder}</h1>
          </div>
        );

        uploadRegion = (
          <DigestBanner
            bannerStyle={'dialogue dialogue-banner'}
            updateUploadedBanner={this.updateUploadedBanner}
            banner={digest.banner}
            archive={true} />
        );

        summaryRegion = (
          <div className="dialogue">
            {digestSummaryRegion}
            {digestByline && (
              <footer className="digest-form_byline">
                {digestByline}
              </footer>
            )}
          </div>
        );
      }

      if(didSendNowAction) {
        headerMessages.push((
          <span key="sentDigestMsg">
            <strong>Digest queued for sending</strong> &mdash; {({
              skipNext: (<em>Your next scheduled Intel Digest will be skipped.</em>),
              sendNext: (<em>Your next scheduled digest will be sent as scheduled.</em>)
            })[didSendNowAction]}
          </span>
        ));
      }

      if(didSendPreviewAction) {
        const {email = ''} = user;

        if(didSendPreviewFailures) {
          headerMessages.push((
            <span key="sentPreviewFailuresMsg" title={didSendPreviewFailures.join('\n')}>
              <strong className="failure">{`Digest ${pluralize('preview', didSendPreviewFailures.length)} NOT sent`}</strong> &mdash;&nbsp;
              <em>{didSendPreviewFailures.length > 1 ? 'Some of the sent previews failed.' : 'The sent preview failed.'}</em>
            </span>
          ));
        }
        else if(didSendRivalGroupIds) {
          headerMessages.push((
            <span key="sentPreviewMsg">
              <strong>{`Digest ${pluralize('preview', didSendRivalGroupIds.length)} sent`}</strong> &mdash;&nbsp;
              <em>
                {didSendRivalGroupIds.length > 1
                  ? 'Previews of the Digest have been sent to'
                  : 'A preview of the Digest has been sent to'} {email || 'your mail.'}
              </em>
            </span>
          ));
        }
      }

      const _handleDismissHeaderMsg = event => {
        event && event.stopPropagation();

        const {target} = event;

        if(didSendPreviewAction) {
          this.setState({didSendPreviewAction: false});
        }

        if(target) {
          const msg = target.closest('.dialogue--header_msg');

          return msg && msg.classList.add('dismissed');
        }
      };

      const _renderHeaderMsg = msg => (
        <header className="dialogue dialogue--header dialogue--header_msg" key={msg.key} onClick={_handleDismissHeaderMsg}>
          <p className="dialogue_warning">
            {msg}
          </p>
          <Icon icon="close" className="dialogue_warning_close" />
        </header>
      );

      // draft digest
      headerRegion = [...(headerMessages.map(_renderHeaderMsg))];//, (

      const {archivedAt, manual, emailsSentCount} = digest;

      if(archivedAt || manual) {
        if(manual) {
          headerMessages.push((
            <span key="customDigestMsg">
              <strong>Custom Digest</strong> &mdash;
              Please <a href="#" onClick={this.handleCopyDigestClick}>copy to clipboard</a> &amp; paste into your favorite email
              or messaging app to send.
            </span>
          ));
        }
        else if(archivedAt) {
          let sentAction = 'archived';
          let sentToRegion;

          if(isCurator && emailsSentCount) {
            sentAction = 'sent';
            sentToRegion = (
              <span>to {emailsSentCount.toLocaleString()} {emailsSentCount === 1 ? 'person' : 'people'}</span>
            );
          }

          headerMessages.push((
            <span key="archivedDigestMsg">
              <strong>Past Intel Digest</strong> &mdash;
              This digest was {sentAction} {sentToRegion} on {moment(archivedAt).format('LL')}.
            </span>
          ));
        }

        headerRegion = headerMessages.map(_renderHeaderMsg);
      }

      return (
        <div
          ref={this.setBodyRef}
          className={
          classNames('digest-body digest-body--edit', {
            'html-enabled': this.isDigestHtmlEditorEnabled(),
            'no-padding': archivedAt
          })}>
          <div className="digest-body_overview">
            {headerRegion}
            {subjectRegion}
            {uploadRegion}
            {summaryRegion}
          </div>
          {showNewInKlueToggle && <DigestNewInKlueToggle
            digestType={activeDigestType}
            digestTypeUpdate={digestTypeUpdate}
            onUpdatedDigestType={this.handleDigestTypeUpdatedNewInKlueToggle} />}
          <div className={favoritesClass}>
            {this.renderFavorites(digest, false)}
          </div>
          {!archivesMode &&
          <DigestBodyFooter
            onCopyToClipboard={this.handleCopyDigestClick}
            onCopyToClipboardDisabled={reorderMode || editorBusy || hasUnsavedFields} />}
        </div>
      );
    }
  };

  renderDigestArchives = nextDigestUTCDateTime => {
    // const digest = this._getDigest();
    const {utils: {user}} = this.context;
    const {archivesMode, editMode} = this.props;
    const {digests, totalDigests, page, digest, activeDigestType} = this.state;

    if((_.isEmpty(digest) && editMode) || !archivesMode) {
      return;
    }

    return (
      <div ref={this.setDigestArchiveRef}>
        <DigestArchives
          user={user}
          draftDigest={digest}
          activeDigestType={activeDigestType}
          digestByline={this.renderDigestByline()}
          archivedDigests={(digests || []).slice().filter(d => Boolean(d.archivedAt) || d.manual)}
          nextDigestSendTime={renderNextDigestSendTime(nextDigestUTCDateTime)}
          page={page - 1}
          totalDigests={totalDigests}
          onPaginationClick={this.handlePaginationClick}
          onDeleteDigestClick={this.handleDeleteDigestClick}
          canDeleteDigest={this._canEditDigest}
          {...this._getDigestOverview(digest)} />
      </div>
    );
  };

  renderFeedRegion = () => {
    const digest = this._getDigest();
    const {feedMode, findPostId, digestTypesData} = this.state;
    const {utils: {user, company}} = this.context;
    const {users, postsUrl, postsContext: {updatePosts}} = this.props;

    if(_.isEmpty(digest) || !feedMode) {
      return;
    }

    return (
      <FeedPostBox
        user={user}
        users={users}
        company={company}
        digest={digest}
        onPinnedCommentUpdatedForFavorite={this.handlePinnedCommentUpdatedForFavorite}
        onUpdateFavorite={this.handleUpdateFavorite}
        onDeleteFavorite={this.handleDeleteFavorite}
        postsUrl={postsUrl}
        digestMode={true}
        findPostId={findPostId}
        onFindPost={this.handleFindPost}
        updatePosts={updatePosts}
        digestTypesData={digestTypesData}
        onDigestTypeFavoriteUpdate={this.handleUpdateFavorites} />
    );
  };

  handleCloseKlueCardLinkSelector = args => {
    this.setState({cardLinkSelectorVisible: false});

    const {appData: {rootUrl}} = this.context;

    editorInsertKlueLink({...args, rootUrl});
  };

  handleInsertLink = ({linkText, urlText, editor, targetImage}) => {
    this.setState({cardLinkSelectorVisible: false});

    editor?.selection?.restore();

    if(targetImage) {
      return handleInsertTargetImageURL({editor, targetImage, urlText});
    }

    editor?.link.insert(urlText, linkText, {target: '_blank', rel: 'nofollow'});
  };

  handleEditDigestSettings = editSettingsDigestType => {
    this.setState({
      editSettingsDigestType
    });
  };

  handleCloseEditDigestSettings = digestType => {
    const {digestsContext: {didUpdateDigestType}} = this.props;
    const {editSettingsDigestTypeIsNew} = this.state;

    didUpdateDigestType && didUpdateDigestType({digestType, isNew: editSettingsDigestTypeIsNew});

    if(!digestType) {
      return this.setState({
        ...defaultDigestTypeState
      });
    }

    this.loadDigestTypes({useDigestType: digestType}).then(async () => {
      const digestTypesData = await this.getUpdatedDigestTypesDataWithNewDigestType(digestType);

      this.setState({
        digestTypesData,
        ...defaultDigestTypeState
      }, () => this.setActiveDigestType(digestType));
    });
  };

  handleCancelAddDigestSettings = digestType => {
    if(!digestType) {
      return this.setState({
        ...defaultDigestTypeState
      });
    }

    const {currentActiveDigestType} = this.state;
    const {api: {digestTypeDelete}} = this.context;

    digestTypeDelete({id: digestType.id}, () => {
      this.loadDigestTypes({useDigestType: currentActiveDigestType}).then(() => {
        this.setState({...defaultDigestTypeState}, () => {
          this.setActiveDigestType(currentActiveDigestType);
        });
      });
    });
  };

  handleCancelledEditDigestSettings = () => {
    this.setState({
      ...defaultDigestTypeState
    });
  };

  getNewDigestTypeName = () => {
    const defaultName = 'Untitled Digest';
    let currentName = defaultName;
    let lowercaseName = currentName.toLowerCase();
    let suffix = 0;

    const {digestTypes = []} = this.state;

    while(digestTypes.find(digestType => digestType.name.toLowerCase() === lowercaseName)) {
      currentName = `${defaultName} ${++suffix}`;
      lowercaseName = currentName.toLowerCase();
    }

    return currentName;
  };

  getUpdatedDigestTypesDataWithNewDigestType = async digestType => {
    const {name, id} = digestType;
    const {digestTypesData = []} = this.state;
    const {insertIndex, updateIndex} = findDigestTypesDataInsertionIndex(digestTypesData, name, id);
    const digests = await this.loadDigests({typeId: id, filter: 'draft'});
    const draftDigest = digests.find(digest => !digest.archivedAt);
    const updatedDigestTypesData = [...digestTypesData];

    if(draftDigest) {
      const index = updateIndex ?? insertIndex;
      const deleteCount = updateIndex !== undefined ? 1 : 0;

      updatedDigestTypesData.splice(index, deleteCount, {digestType, draftDigest: {...draftDigest, favorites: []}});
    }

    return updatedDigestTypesData;
  };

  handleAddDigestType = ({reviewFrequency, deleteAfterSend}) => {
    const {api: {digestTypeCreate}} = this.context;

    const createOptions = {name: this.getNewDigestTypeName()};
    const {activeDigestType: currentActiveDigestType} = this.state;

    if(reviewFrequency) {
      createOptions.reviewFrequency = reviewFrequency;
    }

    if(deleteAfterSend) {
      createOptions.deleteAfterSend = deleteAfterSend;
    }

    digestTypeCreate(createOptions, created => {
      if(created) {
        this.loadDigestTypes({useDigestType: created}).then(({activeDigestType}) => {
          const {id: typeId = null} = activeDigestType;

          this.loadActiveDigest({}, typeId, {useDefaultDigestId: true}).then(newDigest => {
            const {digestType} = newDigest;

            this.setState({
              editSettingsDigestType: digestType,
              editSettingsDigestTypeIsNew: true,
              editSettingsDigest: newDigest,
              currentActiveDigestType
            }, () => {
              this.loadDigests({typeId});
            });
          });
        });
      }
    });
  };

  handleAddOneTimeDigest = () => this.handleAddDigestType({
    reviewFrequency: 'never',
    deleteAfterSend: true
  });

  handleOnMakeDefaultDigest = digest => {
    const {id: digestId, digestType: {id, deleteAfterSend} = {}} = digest || {};
    const {api: {digestTypeUpdate}, utils: {dialog}} = this.context;
    const digestTypeOptions = {id, default: true};
    const {digestsContext: {didSetAsDefault}} = this.props;
    const makeDefaultAction = () => digestTypeUpdate(digestTypeOptions,
      () => { didSetAsDefault && didSetAsDefault(digestId); });

    if(deleteAfterSend) {
      dialog.confirm({
        message: 'Convert to Recurring Digest?',
        bodyContent: 'Default Digest cannot be a One-Time Digest.',
        okCallback: makeDefaultAction
      });
    }
    else {
      makeDefaultAction();
    }
  };

  handleToggleDeleteAfterSend = digest => {
    const {digestType: {id, deleteAfterSend: currentDeleteAfterSend} = {}} = digest || {};
    const {api: {digestTypeUpdate}} = this.context;
    const digestTypeOptions = {id, deleteAfterSend: !currentDeleteAfterSend};
    const {digestsContext: {didUpdateDigestType}} = this.props;

    digestTypeUpdate(digestTypeOptions, digestType => {
      didUpdateDigestType && didUpdateDigestType({digestType});
    });
  };

  getDigestHeaderWidth = () => {
    let width = 0;

    if(this.bodyRef?.current) {
      const {offsetWidth} = this.bodyRef.current;

      width += offsetWidth;
    }

    if(this.sideBarRef?.current) {
      const {offsetWidth} = this.sideBarRef.current;

      width += offsetWidth;
    }

    if(this.feedRef?.current) {
      const {offsetWidth} = this.feedRef.current;

      width += offsetWidth;
    }

    if(this.digestArchiveRef?.current) {
      const {offsetWidth} = this.digestArchiveRef.current;

      width += offsetWidth;
    }

    // subtract a margin inset to align with digest content
    return width >= 275 ? `${width - 44}px` : null;
  };

  getDigestEditHeaderSuperTitle = digest => {
    const {archivesMode} = this.props;
    const {archivedAt, manual} = digest || {};

    if(archivedAt || manual) {
      return null;
    }

    if(archivesMode) {
      return 'Sent Intel Digests';
    }

    return 'Upcoming Intel Digests';
  };

  handleEditDigest = digest => {
    const {history} = this.props;
    const {digestType} = digest || {};

    if(!digestType) {
      return;
    }

    this.setActiveDigestType(digestType, true, () => {
      history.push('/digest');
    });
  };

  handleViewSentDigest = digest => {
    const {history} = this.props;
    const {id, digestType} = digest || {};
    const {digestTypes = []} = this.state;

    if(!digestType || !id) {
      return;
    }

    const parentId = digestType?.parentId;
    const parentDigestType = digestTypes.find(({id: typeId}) => typeId === parentId) ?? digestType;

    this.setActiveDigestType(parentDigestType, false, () => {
      history.push(`/digest/${id}`);
    });
  };

  handleEditDigestTypeSettings = digest => {
    this.handleEditDigestSettings(digest?.digestType);
  };

  handleDeleteDigestType = digest => {
    const {digestsContext: {didDeleteDigestType}} = this.props;
    const {digestType} = digest || {};

    if(!digestType) {
      return;
    }

    const {id, name} = digestType;
    const {api: {digestTypeDelete}, utils: {dialog}} = this.context;
    const deleteDigestAction = () => {
      digestTypeDelete({id}, () => {
        didDeleteDigestType && didDeleteDigestType(digestType);

        this.loadDigestTypes().then(({activeDigestType, digestTypes}) => {
          const {id: typeId = null} = activeDigestType;

          this.loadDigestTypesData(digestTypes);

          return this.loadActiveDigestAndPosts(typeId);
        });
      });
    };

    dialog.confirm({
      message: `Permanently delete the "${name}" Digest draft and its Settings?`,
      bodyContent: 'This action can\'t be undone.',
      okCallback: deleteDigestAction
    });
  };

  handleDeleteSentDigest = digest => {
    if(_.isEmpty(digest) || (digest.id <= 0)) {
      return;
    }

    const {api: {digestDelete}, utils: {dialog}} = this.context;
    const deleteDigestAction = () => {
      const {id} = digest;

      digestDelete({id}, () => {
        const {digestsContext: {didDeleteSentDigest}} = this.props;

        didDeleteSentDigest && didDeleteSentDigest(id);
      });
    };

    dialog.confirm({
      message: 'Permanently delete this digest?',
      bodyContent: 'This action can\'t be undone.',
      okCallback: deleteDigestAction
    });
  };

  render() {
    const {
      utils: {user, company, dialog: {confirm, alert}, isAddCustomDigestItemsEnabled},
      api: {digestGet, digestTypeUpdate}} = this.context;

    const {
      archivesMode,
      editMode,
      rivalGroups,
      rivals,
      timelineMode,
      digestsContext: {getDigestFromDigestType}
    } = this.props;

    const {
      feedMode,
      reorderMode,
      hasUnsavedFields,
      savingDigest,
      imagesUploadingCount,
      cardLinkSelectorVisible,
      urlHRef,
      urlText,
      targetImage,
      editor,
      digestTypes,
      activeDigestType,
      waitingForUploads,
      waitForUploadPromise,
      waitingForUploadCancelTitle,
      editSettingsDigestType,
      editSettingsDigestTypeIsNew,
      editSettingsDigest,
      currentActiveDigestType,
      visibilityGroups,
      headerWidth,
      sendingPreviews,
      previewsSentAt,
      didSendPreviewFailures,
      didSendRivalGroupIds,
      isManageDigestTemplates
    } = this.state;
    const editorBusyReason = imagesUploadingCount === 1 ? 'Uploading 1 Image...' : `Uploading ${imagesUploadingCount} Images...`;
    const digest = (timelineMode && editSettingsDigestType) ? getDigestFromDigestType(editSettingsDigestType, editSettingsDigest) : this._getDigest();
    const {archivedAt} = digest || {};
    const nextDigestUTCDateTime = !_.isEmpty(digest) ? getNextDigestUTCDateTime(digest) : moment().add(1, 'weeks');
    const isCurator = userCanCurate({user});
    const isAdmin = userIsAdmin({user});
    const canAddCustomDigestItems = isAddCustomDigestItemsEnabled();

    let columnsType = 'digest';

    if(!feedMode) {
      if(isCurator || isAdmin) {
        if((archivesMode || archivedAt)) {
          columnsType = 'digest__simpler';
        }
        else {
          columnsType = 'digest__simple';
        }
      }
      else {
        columnsType = 'digest__simpler';
      }
    }

    if(timelineMode) {
      return (
        <div className="digest-page">
          {editSettingsDigestType &&
          <DigestSettingsModal
            digest={digest}
            rivals={rivals}
            rivalGroups={rivalGroups}
            activeDigestType={editSettingsDigestType}
            activeDigestTypeIsNew={editSettingsDigestTypeIsNew}
            isManageDigestTemplates={isManageDigestTemplates}
            digestTypes={digestTypes}
            visibilityGroups={visibilityGroups}
            onClose={this.handleCloseEditDigestSettings}
            onCancelAdd={currentActiveDigestType ? this.handleCancelAddDigestSettings : null}
            onCancelledEdit={this.handleCancelledEditDigestSettings}
            digestGet={digestGet}
            digestTypeUpdate={digestTypeUpdate}
            updateTheDigest={this.updateTheDigest}
            confirm={confirm} />}
          <DigestsTimeline
            rivals={rivals}
            user={user}
            canEditDigest={this._canEditDigest}
            onEditDigest={this.handleEditDigest}
            onViewSentDigest={this.handleViewSentDigest}
            onEditDigestSettings={this.handleEditDigestTypeSettings}
            onDeleteDigestType={this.handleDeleteDigestType}
            onDeleteSentDigest={this.handleDeleteSentDigest}
            onAddDigestType={this.handleAddDigestType}
            onAddOneTimeDigest={this.handleAddOneTimeDigest}
            onMakeDefaultDigest={this.handleOnMakeDefaultDigest}
            onToggleDeleteAfterSend={this.handleToggleDeleteAfterSend} />
        </div>
      );
    }

    let digestToolbarRegion;

    if(isCurator) {
      digestToolbarRegion = (
        <>
          <DigestToolbar
            user={user}
            reorderMode={reorderMode}
            activeDigestId={this._getActiveDigestId()}
            onToggleReorder={this.handleToggleReorder} />
        </>
      );
    }

    const handleCancelWaitingForUploads = () => {
      this.setState({cancelledPromise: waitForUploadPromise});
    };

    const canSendDigest = Boolean(digest?.favorites?.length) && this._canEditDigest();
    const canEdit = this._canEditDigest();
    const digestEditHeaderSuperTitle = this.getDigestEditHeaderSuperTitle(digest);

    const pageHeader = (isAdmin || isCurator) && (<DigestEditHeader
      isCurator={isCurator}
      isAdmin={isAdmin}
      width={headerWidth}
      digest={digest}
      activeDigestType={activeDigestType}
      dirty={hasUnsavedFields}
      archivesMode={archivesMode}
      canSendDigest={canSendDigest}
      canEditDigest={canEdit}
      reorderMode={reorderMode}
      digestTypes={digestTypes}
      visibilityGroups={visibilityGroups}
      title={digestEditHeaderSuperTitle}
      onEditDigestSettings={this.handleEditDigestSettings}
      onSelectDigestType={this.setActiveDigestType}
      onAddDigestType={this.handleAddDigestType}
      onSendDigest={this.handleSendDigestClick}
      confirm={confirm}
      alert={alert} />);

    return (
      <div className="digest">
        {waitingForUploads ? <WaitingForUploads
          cancelTitle={waitingForUploadCancelTitle}
          onUserCancel={handleCancelWaitingForUploads} /> : null}
        {cardLinkSelectorVisible && (
        <Modal
          header={false}
          padded={false}
          extraBodyClass="with-overflow-scroll"
          extraModalClass="digest"
          basic={true}
          hideCloseButton={true}
          closeOnOutsideClick={true}>
          <EditorToolbarCardLinkSelector
            url={urlHRef}
            text={urlText}
            targetImage={targetImage}
            editor={editor}
            rivals={rivals}
            onCloseKlueLink={this.handleCloseKlueCardLinkSelector}
            onInsertLink={this.handleInsertLink} />
        </Modal>
        )}
        {editSettingsDigestType &&
        <DigestSettingsModal
          digest={digest}
          rivals={rivals}
          rivalGroups={rivalGroups}
          activeDigestType={editSettingsDigestType}
          activeDigestTypeIsNew={editSettingsDigestTypeIsNew}
          isManageDigestTemplates={isManageDigestTemplates}
          digestTypes={digestTypes}
          visibilityGroups={visibilityGroups}
          onClose={this.handleCloseEditDigestSettings}
          onCancelAdd={currentActiveDigestType ? this.handleCancelAddDigestSettings : null}
          onCancelledEdit={this.handleCancelledEditDigestSettings}
          digestGet={digestGet}
          digestTypeUpdate={digestTypeUpdate}
          updateTheDigest={this.updateTheDigest}
          confirm={confirm} />}
        <Prompt
          when={editMode && hasUnsavedFields}
          message="You have unsaved changes to your digest. Are you sure you want to leave this page?" />
        {digestToolbarRegion}
        <div className={classNames('digest-page', {'full-header': Boolean(pageHeader)})}>
          <ReactTooltip
            class="tooltip"
            html={true}
            effect="solid"
            offset={{top: 0}} />
          <Page type={columnsType} isConsumer={!isCurator} header={pageHeader}>
            <>
              <div ref={this.setSideBarRef} className="digest-sidebar">
                <DigestMenu
                  user={user}
                  company={company}
                  digest={digest}
                  editMode={editMode}
                  feedMode={feedMode}
                  reorderMode={reorderMode}
                  archivesMode={archivesMode}
                  digestTypes={digestTypes}
                  activeDigestType={activeDigestType}
                  canEditDigest={canEdit}
                  canSendDigest={canSendDigest}
                  canAddCustomDigestItems={canAddCustomDigestItems}
                  hasUnsavedFields={hasUnsavedFields}
                  savingDigest={savingDigest}
                  editorBusy={Boolean(imagesUploadingCount)}
                  sendingPreviews={sendingPreviews}
                  previewsSentAt={previewsSentAt}
                  editorBusyReason={editorBusyReason}
                  rivalGroups={rivalGroups}
                  sentPreviewFailures={didSendPreviewFailures}
                  sentRivalGroupIds={didSendRivalGroupIds}
                  onSendPreviewsClick={this.handleSendPreviewsClick}
                  onCopyDigestClick={this.handleCopyDigestClick}
                  onCreateDigestClick={this.handleCreateDigestClick}
                  onSaveDigestClick={this.handleSaveDigestClick}
                  onReorderFavoriteSave={this.handleReorderFavoriteSave}
                  onToggleFeed={this.handleToggleFeed}
                  onAddCustomContent={this.handleAddCustomContent}
                  onToggleReorder={this.handleToggleReorder}
                  onToggleLock={this.handleToggleDigestLock} />
              </div>
              <div ref={this.setFeedRef} className="digest-feed endless-container">
                {this.renderFeedRegion()}
              </div>
              {this.renderDigestBody()}
              {this.renderDigestArchives(nextDigestUTCDateTime)}
            </>
          </Page>
        </div>
      </div>
    );
  }

}

const mapDispatchToProps = dispatch => ({loadOrFetchRivals: () =>
  dispatch.rivals.loadOrFetchRivals()
});

const mapStateToProps = ({rivals}) => ({rivals: rivals.items});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withDigests,
  withPosts
);

export {Digest as WrappedDigest};
export default enhance(Digest);
