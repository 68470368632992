import {rivalsList} from '../../../../modules/api/rivals';

class DisplayCompetitors extends React.Component {

  static propTypes = {
    competitors: PropTypes.string.isRequired
  };

  state = {
    rivals: [],
    rivalMatches: []
  };

  componentDidMount() {
    this.mounted = true;
    this.getRivals(this.getCompetitorMatches);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getRivals = callback =>
    rivalsList({code: 'DisplayCompetitors.getRivals'})
      .then(rivals => {
        if(!this.mounted) {
          return;
        }

        this.setState({rivals}, callback);
      })
      .catch(e => console.error(e));

  getCompetitorMatches = callback => {
    if(!this.mounted) {
      return;
    }

    const {rivals} = this.state;
    const {competitors = ''} = this.props;
    const rivalMatches = [];

    if(!competitors) {
      return;
    }

    rivals.find(r => {
      const regex = new RegExp(r.aliasesRegex, 'gi');
      const matches = competitors.match(regex);

      if(matches && (matches.length > 0)) {
        rivalMatches.push(r);
      }
    });

    this.setState({
      rivalMatches
    }, callback);
  };

  render() {
    const {rivalMatches} = this.state;

    return (
      <span>
        {rivalMatches.map((matched, i) => {
          return (
            <span key={matched.name.toString()}>
              <a target="_blank" rel="noopener noreferrer" href={`/profile/${matched.profileId}/view`}>{matched.name}</a>
              {rivalMatches[i + 1] ? ', ' : ''}
            </span>
          );
        })}
      </span>
    );
  }

}

export default DisplayCompetitors;
