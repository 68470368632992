import CommentForm from './_comment_form';
import CommentTruncate from './_comment_truncate';
import AttachmentPreview from './_attachment_preview';
import Icon from './_icon';
import CompanyLogo from './_company_logo';

import {userCanCurate} from '../modules/roles_utils';
import {fetch} from '../modules/api_utils';
import {rivalsGet} from '../modules/api/rivals';
import {getCommentFromLS} from '../modules/local_storage_utils';
import {getHostAndProtocol, removeHttp} from '../modules/url_utils';
import {serviceUrls} from '../modules/constants/services';
import {extensionDimensions} from '../modules/constants/extension';
import {resizeExtension} from '../modules/ext_utils';
import {processLinks} from '../modules/html_utils';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import {throttle} from 'lodash';
import {Link} from 'react-router-dom';
import {withPosts} from '../contexts/_posts';
import onClickOutside from 'react-onclickoutside';

class Comment extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    attachments: PropTypes.arrayOf(PropTypes.object),
    boards: PropTypes.arrayOf(PropTypes.object),
    bodyPlain: PropTypes.string,
    bodyHtml: PropTypes.string,
    canDelete: PropTypes.bool,
    canTakeScreenshot: PropTypes.bool,
    commentId: PropTypes.number,
    containerId: PropTypes.number,
    containerType: PropTypes.string,
    createdAt: PropTypes.string,
    currentUser: PropTypes.object,
    digestMode: PropTypes.bool,
    lastEdit: PropTypes.object,
    highlightId: PropTypes.number,
    highlightSelected: PropTypes.bool,
    extensionMode: PropTypes.bool,
    isFavorite: PropTypes.bool,
    maxAutoSuggestSize: PropTypes.number,
    onCommentSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    onHighlightClick: PropTypes.func,
    onRivalClick: PropTypes.func,
    onRivalCreate: PropTypes.func,
    handlePinClick: PropTypes.func,
    postHasAttachment: PropTypes.bool,
    prime: PropTypes.bool,
    primeCommentId: PropTypes.number,
    renderAssociated: PropTypes.bool,
    rivalMatches: PropTypes.arrayOf(PropTypes.object),
    sourceData: PropTypes.object,
    suggestion: PropTypes.string,
    title: PropTypes.string,
    user: PropTypes.object,
    userId: PropTypes.number,
    userImage: PropTypes.string,
    userName: PropTypes.string,
    isPinnedComment: PropTypes.bool,
    isMakingPinRequest: PropTypes.bool,
    isSlackComment: PropTypes.bool,
    postsContext: PropTypes.shape({
      posts: PropTypes.object
    }).isRequired,
    rivals: PropTypes.object
  };

  static defaultProps = {
    attachments: [],
    boards: [],
    bodyPlain: null,
    bodyHtml: null,
    canDelete: false,
    canTakeScreenshot: false,
    commentId: null,
    containerId: null,
    containerType: 'post',
    createdAt: null,
    currentUser: null,
    digestMode: false,
    lastEdit: null,
    highlightId: null,
    highlightSelected: false,
    extensionMode: false,
    isFavorite: false,
    maxAutoSuggestSize: 36,
    onCommentSubmit() {},
    onDelete() {},
    onHighlightClick: null,
    onRivalClick() {},
    onRivalCreate() {},
    handlePinClick() {},
    postHasAttachment: true,
    prime: false,
    primeCommentId: null,
    renderAssociated: true,
    rivalMatches: [],
    sourceData: null,
    suggestion: null,
    title: null,
    user: {},
    userId: null,
    userImage: null,
    userName: null,
    isPinnedComment: false,
    isMakingPinRequest: false,
    isSlackComment: false,
    rivals: null
  };

  state = {
    editMode: false,
    companyName: '',
    companyUrl: '',
    autoSuggestPlaceholder: 'a board',
    suggestions: []
  };

  UNSAFE_componentWillMount() {
    // auto-switch to edit mode (before rendering) if this comment has unsaved previous value kept in local storage.
    const {containerType, commentId: id, containerId: parentId} = this.props;
    const {editMode} = this.state;
    const data = {containerType, id, parentId};

    if(getCommentFromLS(data) && !editMode) {
      this.toggleMode();
    }
  }

  componentDidMount() {
    console.log('Comment.componentDidMount: props: %o', this.props);

    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _preventDefault = e => e && e.preventDefault();

  _resizeInput = inputValue => {
    const value = (typeof inputValue !== 'string') ? '' : inputValue;
    const autoSuggest = ReactDOM.findDOMNode(this).querySelector(`#companyUrl-${this.props.commentId}`);

    if(autoSuggest) {
      const size = ((value ? value.length : autoSuggest.value.length) || this.state.autoSuggestPlaceholder.length) + 1;

      autoSuggest.setAttribute('size', Math.min(size, this.props.maxAutoSuggestSize));
    }
  };

  handleClickOutside = () => {
    if(this.props.boards.length === 0) {
      this.resetFormFields();
    }

    this.onSuggestionsClearRequested();
  };

  handleHighlightClick = () => {
    const {onHighlightClick, highlightId} = this.props;

    onHighlightClick && onHighlightClick(highlightId);
  };

  handleRivalClick = (rival, event) => {
    const {onRivalClick, commentId} = this.props;

    this._preventDefault(event);
    onRivalClick && onRivalClick(commentId, rival);
  };

  handleDeleteClick = event => {
    this._preventDefault(event);

    const {prime, primeCommentId, commentId, highlightId, onDelete} = this.props;

    const confirmMessage = `Are you sure you want to delete this ${prime ? 'feed item' : 'comment'}?`;
    const confirmAction = () => {
      const deleteCommentId = prime ? primeCommentId : commentId;

      onDelete(deleteCommentId);

      klueMediator.publish('klue:highlight:delete', highlightId);
    };

    this.context.utils.dialog.confirm({
      message: confirmMessage,
      okCallback: confirmAction
    });
  };

  toggleMode = event => {
    const target = event && event.target;

    if(target && target.nodeName === 'A' && target.hasAttribute('href')) {
      return;
    }

    this.setState(prevState => ({editMode: !prevState.editMode}), () => {
      if(this.props.extensionMode) {
        const node = ReactDOM.findDOMNode(this);

        resizeExtension();
        node && node.scrollIntoView();
      }
    });
  };

  // TODO: extract to separate company autosuggest component (similar to DashboardAddCompany autosuggest)
  processCompaniesResult = (matches = [], rivals = []) => {
    const nameSort = (r1, r2) => (r1.name).localeCompare(r2.name);
    const sortedRivals = rivals.sort(nameSort);
    const sortedMatches = matches.sort(nameSort).filter(match => !sortedRivals.find(r => r.name?.toLowerCase() === match.name?.toLowerCase()));
    const results = [
      ...sortedRivals,
      ...sortedRivals.length && sortedMatches.length ? [{seperator: true}] : [],
      ...sortedMatches
    ];

    const suggestions = results.reduce((acc, curr) => {
      const {id, profile: {id: profileId, scratchpadBoardId} = {}, name: competitorName, url: resultUrl, iconUrl, logo, domain, seperator} = curr || {};
      const url = resultUrl || (domain && `https://${domain}`);

      return [...acc, {
        id,
        profileId,
        scratchpadBoardId,
        competitorName: competitorName || domain,
        domain: domain || removeHttp(url),
        displayLink: getHostAndProtocol(url),
        url,
        logo: iconUrl || logo,
        seperator
      }];
    }, []);

    this.setState({suggestions}, () => {
      // resize/scroll logic for extension...
      if(this.props.extensionMode) {
        const commentListItem = ReactDOM.findDOMNode(this);
        const commentList = commentListItem.parentElement;
        const {RIVALS_AUTOSUGGEST_PAD, RIVALS_AUTOSUGGEST_HEIGHT} = extensionDimensions;
        const autosuggestHeight = RIVALS_AUTOSUGGEST_HEIGHT + RIVALS_AUTOSUGGEST_PAD;
        const {bottom: listItemBottom} = commentListItem.getBoundingClientRect();
        const {bottom: listBottom, height: listHeight} = commentList.getBoundingClientRect();

        if(Math.abs(listItemBottom - listBottom) <= autosuggestHeight) {
          if(listHeight <= RIVALS_AUTOSUGGEST_HEIGHT + RIVALS_AUTOSUGGEST_PAD) {
            commentListItem.classList.add('autosuggest-expand');
          }
          else {
            commentList.scrollBy({top: autosuggestHeight, behavior: 'smooth'});
          }

          resizeExtension();
        }
      }
    });
  };

  onSuggestionsClearRequested = () => this.setState({suggestions: []}, () => {
    if(this.props.extensionMode) {
      const commentListItem = ReactDOM.findDOMNode(this);

      commentListItem.classList.remove('autosuggest-expand');
      resizeExtension();
    }
  });

  onSuggestionsFetchRequested = throttle(args => this.getSuggestions(args.value), 500);

  handleChange = (event, {newValue}) => {
    this.setState({companyName: newValue}, () => this._resizeInput(newValue));
  };

  getSuggestions = input => {
    const escapedInput = _.escapeRegExp(input.trim());
    const params = {
      query: escapedInput
    };

    if(this._isMounted) {
      rivalsGet({rivalOptions: {
        ...params,
        page: 1,
        limit: 5, // (limit to 5 so autosuggest list doesn't get too long)
        order: 'created' // since we are restricting to 5, grab the 5 most recent
      }})
        .then(({rivals = []}) => {
          if(rivals.length < 5) {
            return fetch(serviceUrls.CLEARBIT_COMPANY_URL, {params, code: 'Comment.getSuggestions'})
              .then(({data}) => {
                this.processCompaniesResult(data, rivals);
              }).catch(error => console.error('Comment.getSuggestions', error));
          }

          this.processCompaniesResult([], rivals);
        });
    }
  };

  renderSuggestion = ({competitorName = '', domain = '', logo = '', seperator}) => {
    if(seperator) {
      return (<div className="suggestion-seperator" />);
    }

    const escapedInput = _.escapeRegExp(this.state.companyName.trim());
    const escapedInputLength = escapedInput.length;
    const logoUrl = logo || (`${serviceUrls.GOOGLE_FAVICON_URL}${domain}`);
    const matcher = new RegExp(`\\b${escapedInput}`, 'i');
    const result = competitorName;
    const resultLink = domain;
    const firstMatchIndex = result ? result.search(matcher) : -1;
    let label;

    if(result.length > 0) {
      if(!escapedInputLength || (firstMatchIndex === -1)) {
        label = (
          <span className="suggestion-label">
            {result} <span className="suggestion-url pull-right">{resultLink}</span>
          </span>
        );
      }
      else {
        const beforeMatch = result.slice(0, firstMatchIndex);
        const match = result.slice(firstMatchIndex, firstMatchIndex + escapedInputLength);
        const afterMatch = result.slice(firstMatchIndex + escapedInputLength);

        label = (
          <span className="suggestion-label">
            {beforeMatch}<strong>{match}</strong>{afterMatch}
            <span className="suggestion-url pull-right">{resultLink}</span>
          </span>
        );
      }

      return (
        <span className="suggestion-body">
          <span className="suggestion-icon-wrap">
            <CompanyLogo src={logoUrl} className="suggestion-icon" />
          </span>
          {label}
        </span>
      );
    }
  };

  getSuggestionValue = suggestion => suggestion.competitorName;

  handleSelectSuggestion = (event, {suggestion}) => {
    this._preventDefault(event);

    const {id, profileId, scratchpadBoardId, competitorName: companyName, displayLink: companyUrl} = suggestion;

    if(!suggestion) {
      this.setState({
        companyName: '',
        companyUrl: '',
        suggestions: []
      });

      return false;
    }

    const rival = {
      companyName,
      companyUrl
    };

    this._resizeInput(companyName);
    this.setState(rival);

    delete window.googleSearchCallback;

    this.resetFormFields();

    if(scratchpadBoardId) {
      // use existing rival
      this.props.onRivalClick(this.props.commentId, {id, profileId, scratchpadBoardId, name: companyName});
    }
    else {
      this.createRival(rival);
    }
  };

  resetFormFields = () => {
    this.setState({
      companyName: '',
      companyUrl: ''
    }, () => {
      const autocompleteInput = ReactDOM.findDOMNode(this).querySelector(`#companyUrl-${this.props.commentId}`);

      if(autocompleteInput) {
        autocompleteInput.value = '';
      }
    });
  };

  createRival = ({companyName: name, companyUrl: url}) => {
    if(!name || !url) {
      // TODO: add some spiffy inline validation here
      return;
    }

    const {onRivalCreate, onRivalClick} = this.props;
    const rivalOptions = {
      name,
      url
    };

    console.log('Comment.createRival: creating new rival: %o %o', name, url);

    this.context.api.rivalCreate({rivalOptions}).then(rival => {
      console.log('Comment.createRival: created rival successfully: %o', rival);

      this.resetFormFields();

      // add rival to collection in ExtensionBox state
      onRivalCreate && onRivalCreate(rival);

      // update parent comment with new rival
      onRivalClick && onRivalClick(this.props.commentId, {
        id: rival.id,
        name: rival.name,
        profileId: rival.profile.id,
        scratchpadBoardId: rival.profile.scratchpadBoardId
      });
    });
  };

  blurAutoSuggest = () => {
    const {companyName, companyUrl} = this.state;

    if(!companyName && !companyUrl) {
      this.resetFormFields();
    }
  };

  normalizeAttachment = attachment => {
    /* eslint-disable camelcase */
    const {asset_url, assetUrl, file_name, fileName, type, mime_type, mimeType} = attachment || {};

    return {
      url: asset_url || assetUrl,
      thumbnailUrl: asset_url || assetUrl,
      fileName: file_name || fileName,
      type,
      mimeType: mime_type || mimeType
    };
    /* eslint-enable camelcase */
  };

  renderAttachment = () => {
    const {attachments} = this.props;
    const {editMode} = this.state;

    if(_.isEmpty(attachments) || editMode) {
      return;
    }

    const attachment = this.normalizeAttachment(attachments[0]);

    return (
      <AttachmentPreview attachment={attachment} />
    );
  };

  renderAssociatedBoards = ([board]) => (board ? (
    <div className="highlight-meta">
      <a href="#" className="highlight-rival" onClick={e => this.handleRivalClick(null, e)}>
        {board.rival.name}
      </a> &raquo; <span className="highlight-board">{board.name}</span>
    </div>
  ) : null);

  renderAssociateBoard = () => {
    const {rivalMatches} = this.props;
    const {autoSuggestPlaceholder, companyName, suggestions} = this.state;
    let rivalsMatched;

    if(rivalMatches.length) {
      rivalsMatched = rivalMatches.map((item, index) => {
        let separator = ', ';

        if(index === (rivalMatches.length - 1)) {
          separator = ', or ';
        }

        return (
          <span key={item.id}>
            <a href="#" className="highlight-rival" onClick={e => this.handleRivalClick(item, e)}>{item.name}</a>{separator}
          </span>
        );
      });
    }

    const inputProps = {
      id: `companyUrl-${this.props.commentId}`,
      type: 'search',
      className: 'google-search-autosuggest',
      size: autoSuggestPlaceholder.length,
      required: true,
      autoFocus: false,
      placeholder: autoSuggestPlaceholder,
      onChange: this.handleChange,
      onFocus: this._resizeInput,
      onBlur: this.blurAutoSuggest,
      value: companyName
    };

    return (
      <div className="highlight-meta no-rival-selected">
        Add this to {rivalsMatched}
        <Autosuggest
          getSuggestionValue={this.getSuggestionValue}
          inputProps={inputProps}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.handleSelectSuggestion}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          ref="companyAutoSuggest"
          renderSuggestion={this.renderSuggestion}
          suggestions={suggestions} />
      </div>
    );
  };

  renderRivalRegion = () => {
    const {currentUser, renderAssociated, boards, digestMode} = this.props;

    if(userCanCurate({user: currentUser}) && renderAssociated && !digestMode) {
      if(boards.length) {
        return this.renderAssociatedBoards(boards);
      }

      return this.renderAssociateBoard();
    }

    return;
  };

  setCommentFormRef = el => (this.commentForm = el);

  onPinCommentClick = action => {
    if(action === 'unpin') {
      const {handlePinClick} = this.props;

      return handlePinClick({id: 0});
    }

    const {commentId: id, bodyHtml: bodyHtml, createdAt: timestamp, userId, userName, handlePinClick} = this.props;

    handlePinClick({id, bodyHtml, timestamp, userId, userName});
  };

  renderPinCta = () => {
    const {isPinnedComment, isMakingPinRequest} = this.props;
    const wimAction = {
      PIN: 'pin',
      UNPIN: 'unpin'
    };
    const labelClass = classNames('comment-save-label', {
      'comment-save-label--disabled': isMakingPinRequest
    });

    return isPinnedComment
      ? <span className={labelClass} onClick={() => this.onPinCommentClick(wimAction.UNPIN)}>Unpin Comment</span>
      : <span className={labelClass} onClick={() => this.onPinCommentClick(wimAction.PIN)}> <Icon icon="pin" width="16" height="16" />Pin Comment</span>;
  };

  render() {
    const {
      attachments, bodyPlain, bodyHtml, canDelete, canTakeScreenshot,
      commentId, containerId, containerType, createdAt, currentUser,
      lastEdit = {}, highlightId, highlightSelected, isFavorite,
      onCommentSubmit, onDelete, onHighlightClick, postHasAttachment,
      prime, primeCommentId, sourceData = {}, suggestion, title,
      user, userId, userImage, userName, extensionMode, isPinnedComment, isSlackComment,
      postsContext: {posts}, rivals
    } = this.props;
    const {editMode} = this.state;
    let commentTitle = '';
    let canEdit = false;
    let commentActions;
    let commentBody = extensionMode ? bodyPlain : bodyHtml;
    let commentFormRegion;
    let highlightLink;
    let deleteLink;
    let toggleFavoriteRegion;
    let userNameDisplay;
    let attribution;
    let timestamp = (<time
      className="comment-timestamp_time"
      dateTime={createdAt}
      title={new Date(createdAt)}>
      {moment(createdAt).fromNow()}
    </time>);

    if(commentId && canDelete) {
      canEdit = Boolean((bodyPlain || bodyHtml) && !isSlackComment);
      commentTitle = bodyPlain || bodyHtml ? 'Click to edit' : '';

      const postId = containerType === 'post' ? containerId : null;
      const post = postId && posts[postId];
      // extPinnedCommentId is set to pinnedCommentId when coming from ext
      const {usedInDigest = false, extPinnedCommentId} = post || {};
      const disabled = usedInDigest && (isPinnedComment || (extPinnedCommentId === commentId));

      deleteLink = (
        <a
          onClick={disabled ? null : this.handleDeleteClick}
          className="comment-delete"
          title={disabled ? 'Pinned Comments of Posts attached to Intel Digests cannot be deleted.' : null}>
          <Icon icon="close" className={classNames('comment-delete-icon', {disabled})} />
        </a>
      );
    }

    if(prime) {
      const noAttachmentClass = !postHasAttachment ? ' no-attachment' : '';

      if(commentBody && extensionMode) {
        commentBody = '<div class="comment-text' + noAttachmentClass + '">' + commentBody.replace(/:\s*$/, '') + '</div>';
      }

      userNameDisplay = userName;
    }
    else {
      attribution = ' by ';
      userNameDisplay = userName.split(' ')[0];
    }

    let avatarRegion = (<Link to={`/users/${userId}`} target="_blank">
      <img title={userName} alt={userName} src={userImage} />
    </Link>);
    let authorLink = (<Link to={`/users/${userId}`} target="_blank" className="comment-author">{userNameDisplay}</Link>);

    const highlightClass = classNames('highlight-link', {
      active: highlightSelected
    });
    const commentClass = classNames('comment', {
      'comment-prime': prime,
      'has-highlight': Number.isInteger(highlightId) && (highlightId >= 0),
      'has-highlight--clickable': onHighlightClick,
      'has-attachment': !_.isEmpty(attachments),
      'is-editing': editMode
    });
    const commentBodyClass = classNames('comment-body', {
      'comment-editable-area': canEdit
    });

    if(lastEdit) {
      const {editorName, editorId, editorImage, editedAt} = lastEdit;

      timestamp = (<time
        className="comment-timestamp_time"
        dateTime={editedAt}
        title={new Date(editedAt)}>
        <span title={new Date(editedAt)}>Edited </span>{moment(editedAt).fromNow()}
      </time>);
      authorLink = (<Link to={`/users/${editorId}`} target="_blank" className="comment-author">{editorName.split(' ')[0]}</Link>);
      avatarRegion = (<Link to={`/users/${editorId}`} target="_blank"><img title={editorName} alt={editorName} src={editorImage} /></Link>);
    }

    if(editMode && (bodyPlain || bodyHtml)) {
      commentFormRegion = (
        <CommentForm
          ref={this.setCommentFormRef}
          user={user}
          containerId={containerId}
          containerType={containerType}
          suggestion={suggestion}
          onCommentSubmit={onCommentSubmit}
          onCommentCancel={this.toggleMode}
          toggleParentContainer={this.toggleMode}
          onCommentDelete={onDelete}
          editMode={true}
          attachments={attachments}
          primeCommentId={primeCommentId}
          commentId={commentId}
          commentTitle={title}
          commentBody={extensionMode ? bodyPlain : bodyHtml}
          commentTimestamp={createdAt}
          prime={prime}
          rivals={rivals}
          canTakeScreenshot={canTakeScreenshot}
          isExtensionMode={extensionMode}
          context={this.context} />
      );
    }
    else {
      commentActions = (
        <div className="comment-actions">{deleteLink}</div>
      );

      if(onHighlightClick) {
        highlightLink = (
          <i className={highlightClass} onClick={this.handleHighlightClick} title="Find highlight on page">
            <div className="highlight-link_icon">
              <Icon icon="highlight" width="25" height="25" />
            </div>
          </i>
        );
      }

      const viaSlack = /slack/i.test((sourceData || {}).name || '');
      const viaTeams = /teams/i.test((sourceData || {}).name || '');

      if(extensionMode) {
        commentFormRegion = (
          <div title={commentTitle} className={commentBodyClass}>
            {commentBody ? (<CommentTruncate text={processLinks(commentBody, {processAtMentions: !viaSlack && !viaTeams})} />) : (<p>&#8203;</p>)}
          </div>
        );
      }
      else {
        commentFormRegion = (
          <div title={commentTitle} className={commentBodyClass} dangerouslySetInnerHTML={{__html: commentBody}} />
        );
      }
    }

    if(userCanCurate({user: currentUser})) {
      if(containerType === 'post' && !extensionMode) {
        const regionClass = classNames('comment-save', {
          'comment-save--on': isFavorite
        });

        toggleFavoriteRegion = (
          <span className={regionClass}>
            {this.renderPinCta()}
          </span>
        );
      }
    }

    const timestampRegion = (
      <abbr className="comment-timestamp">
        {timestamp}
        {attribution}
        {authorLink}
        {toggleFavoriteRegion}
      </abbr>
    );

    return (
      <li id={`comment_${commentId}`} className={commentClass}>
        <div className="comment-wrapper">
          {commentActions}
          {highlightLink}
          <div className="comment-author-avatar">
            {avatarRegion}
          </div>
          <div className="comment-media">
            {timestampRegion}
            <div className="comment-body-wrapper" onClick={canEdit && !editMode ? this.toggleMode : null}>
              {commentFormRegion}
            </div>
            {this.renderAttachment()}
            {this.renderRivalRegion()}
          </div>
        </div>
      </li>
    );
  }

}

export default withPosts(onClickOutside(Comment));
