import NotificationsItem from './_notifications_item';
import CommentBox from './_comment_box';
import Icon from './_icon';

import {getItemTypes} from '../modules/notification_utils';

import classNames from 'classnames';
import {Link} from 'react-router-dom';

class NotificationsGroup extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object,
    notifications: PropTypes.arrayOf(PropTypes.object),
    groupName: PropTypes.string,
    rivals: PropTypes.arrayOf(PropTypes.object),
    showSingleGroupTitle: PropTypes.bool,
    showReplies: PropTypes.bool,
    toggled: PropTypes.bool,
    onToggleContainerClick: PropTypes.func,
    onNotificationDeleteClick: PropTypes.func
  };

  static defaultProps = {
    user: null,
    notifications: [],
    groupName: '',
    rivals: [],
    showSingleGroupTitle: true,
    showReplies: true,
    toggled: false,
    onToggleContainerClick() {},
    onNotificationDeleteClick() {}
  };

  componentDidMount() {
    // DEBUG
    console.log('NotificationsGroup.componentDidMount: props: %o', this.props);
  }

  handleToggleContainerClick = (notification, event) => {
    if(event) {
      event.preventDefault();
    }

    this.props.onToggleContainerClick(notification);
  };

  render() {
    const {user, notifications, rivals, groupName, showSingleGroupTitle, showReplies, toggled, onNotificationDeleteClick} = this.props;

    if(!notifications || !notifications.length) {
      return;
    }

    const firstNotification = notifications[0];
    const {comment, comment: {containers}} = firstNotification;
    const container = containers && containers.length ? containers[0] : {};
    const itemTypes = getItemTypes(firstNotification) || [];
    const {id: itemId, type: itemType, link: itemLink} = itemTypes.length ? itemTypes[0] : {};
    const itemSubTitle = (itemType === 'Battlecard') ?
      `"${this.context.utils.getBattlecardTitle(itemId) || '(Untitled Battlecard)'}"` :
      (itemType === 'Feed Post' ? comment.pageTitle : '');
    const itemTitle = (itemType !== 'Feed Post') ?
      `${groupName}${groupName ? ' \u2013 ' : ''}${itemType} Comments` :
      'Feed Mentions';
    const nestedGroupClass = classNames('notification-group_nested', {
      'notification-group_nested--toggled': toggled
    });
    let mainTitle;
    let groupTitle;
    let viewLink;
    let replyLink;
    let replyLabel = 'Quick Reply';
    let replyIcon = 'comment';
    let commentBox;

    if(itemTypes.length && ['Feed Post', 'Battlecard'].includes(itemType) && itemSubTitle) {
      // feed posts & battlecards are grouped together under same primary heading; item links show on subheadings
      mainTitle = showSingleGroupTitle && (
        <h5 className="notification-group_nested_title notification-group_nested_title--once">{itemTitle}</h5>
      );

      groupTitle = (
        <h6 className="notification-group_nested_subtitle">
          <Link to={itemLink}>{itemSubTitle}</Link>
        </h6>
      );
    }
    else {
      // all other item types
      groupTitle = (
        <h5 className="notification-group_nested_title">{itemTitle}</h5>
      );
    }

    const nestedGroupNodes = notifications.map(notification => {
      return (
        <NotificationsItem
          key={notification.id}
          user={user}
          notification={notification}
          itemTypes={getItemTypes(notification)}
          onNotificationDeleteClick={onNotificationDeleteClick} />
      );
    });

    if(toggled) {
      replyLabel = 'Collapse Comments';
      replyIcon = 'minus';

      commentBox = showReplies && (
        <div className="notification-group_nested_reply-box">
          <CommentBox
            user={user}
            containerType={container.containerType.toLowerCase()}
            containerId={container.containerId}
            showNewestFirst={true}
            allowCommentsToggle={false}
            postHasAttachment={false}
            hideStat={true}
            hideFavorites={true} />
        </div>
      );
    }

    if(container) {
      viewLink = (
        <Link to={itemLink} className="notification-group_nested_view-link">
          <span className="notification-group_nested_view-icon">
            <Icon icon="launch" />
          </span>View {itemType}
        </Link>
      );

      if(showReplies) {
        replyLink = (
          <a href="#" className="notification-group_nested_reply-link" onClick={e => this.handleToggleContainerClick(firstNotification, e)}>
            <span className="notification-group_nested_reply-icon">
              <Icon icon={replyIcon} />
            </span>{replyLabel}
          </a>
        );
      }
    }

    return (
      <div className={nestedGroupClass}>
        <hr className="notification-group_separator" />
        <header className="notification-group_nested_header">
          {mainTitle}
          {groupTitle}
          <div className="notification-group_nested_links">
            {viewLink}
            {replyLink}
          </div>
        </header>
        {nestedGroupNodes}
        {commentBox}
      </div>
    );
  }

}

export default NotificationsGroup;
