import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {pluralize} from '../modules/text_utils';

const DigestPreviewsStatus = ({previewsSentAt, sentPreviewFailures, sentRivalGroupIds, countError}) => {
  const [message, setMessage] = useState();
  const [tooltip, setTooltip] = useState();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let timer;

    setTooltip('');
    setMessage();
    setIsError(false);

    if(!previewsSentAt) {
      return;
    }

    if(sentPreviewFailures?.length) {
      setIsError(true);
      setMessage('Some previews failed to send.');

      return setTooltip(sentPreviewFailures.join('\n'));
    }

    if(sentRivalGroupIds?.length) {
      setMessage(`Digest ${pluralize('preview', sentRivalGroupIds.length)} sent`);
      timer = setTimeout(() => setMessage(), 5000);
    }

    return () => clearTimeout(timer);
  }, [previewsSentAt, sentPreviewFailures, sentRivalGroupIds]);

  if(countError) {
    return (<div className={classNames('digest-preview-status', {error: true})}>
      An error occurred fetching item counts.
    </div>);
  }

  if(!previewsSentAt) {
    return null;
  }

  return (
    <div className={classNames('digest-preview-status', {error: isError})} title={tooltip}>
      {message}
    </div>
  );
};

DigestPreviewsStatus.propTypes = {
  previewsSentAt: PropTypes.object,
  sentPreviewFailures: PropTypes.arrayOf(PropTypes.string),
  sentRivalGroupIds: PropTypes.arrayOf(PropTypes.number),
  countError: PropTypes.bool
};

DigestPreviewsStatus.defaultProps = {
  previewsSentAt: null,
  sentPreviewFailures: null,
  sentRivalGroupIds: null,
  countError: false
};

export default DigestPreviewsStatus;
