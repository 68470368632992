import {userCanCurate} from '../modules/roles_utils';
import {DragTypes} from '../modules/constants/dnd';
import {DropTarget} from 'react-dnd';

import classNames from 'classnames';

const itemTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();

    if(monitor.didDrop()) {
      return;
    }

    props.onDrop(item.comment, props.index, props.useViewOrder);

    return {
      moved: true
    };
  },

  canDrop(props, monitor) {
    const item = monitor.getItem();

    return (item.comment.id >= 0) || item.postData.id;
  }
};

function collectItemTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({shallow: true}),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

const CardAddButton = ({user, isOver, canDrop, connectDropTarget, onAddClick}) => {
  if(!userCanCurate({user})) {
    return null;
  }

  const containerClasses = classNames({
    hover: true,
    'is-over': isOver && canDrop
  });

  return (
    connectDropTarget(
      <div className={containerClasses} data-action="newCard" onClick={onAddClick} />
    )
  );
};

CardAddButton.propTypes = {
  user: PropTypes.object,
  index: PropTypes.number.isRequired,
  useViewOrder: PropTypes.number.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,

  // react-dnd
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired
};

CardAddButton.defaultProps = {
  user: {},
  index: 0,
  useViewOrder: 0,
  onAddClick() {},
  onDrop() {},

  // react-dnd
  isOver: false,
  canDrop: false
};

export default DropTarget(DragTypes.SCRATCHPAD_ITEM, itemTarget, collectItemTarget)(CardAddButton);
