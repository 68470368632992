/* eslint-disable react/no-multi-comp */
import {createContext, useCallback, useContext, useMemo, useRef, useState, useEffect} from 'react';

export const CardDraggingContext = createContext();

export const useCardDragging = () => {
  const cardDraggingContext = useContext(CardDraggingContext);

  if(cardDraggingContext === undefined) {
    throw new Error('useCardDragging must be used within <CardDraggingProvider />');
  }

  return cardDraggingContext;
};

const requiredObserverFunctions = Object.freeze([
  'removeCardFromBoard',
  'addCardToBoard',
  'moveCardOnBoard',
  'getCard',
  'commitReorderedCards',
  'moveCardToEmptyLane',
  'getCurrentCardCount',
  'showDropInsertAtIndex'
]);

const useCardDraggingInternal = ({onError, onBoardDragged}) => {
  const cardId = useRef(0);
  const originalCard = useRef();
  const boardId = useRef(0);
  const index = useRef(0);
  const originalIndex = useRef(0);
  const originalBoardId = useRef(0);
  const observers = useRef({});
  const affectedBoards = useRef(new Set());
  const [isCompressedCardDraggingOn, setIsCompressedCardDraggingOn] = useState(false);
  const [dragging, setIsDragging] = useState(false);

  const getObserver = id => {
    const theObservers = Object.values(observers.current);

    for(let i = 0; i < theObservers.length; i++) {
      const {observer, boardId: obsBoardId} = theObservers[i];

      if(obsBoardId === id) {
        return observer;
      }
    }
  };

  useEffect(() => {
    affectedBoards.current = new Set();
  }, [isCompressedCardDraggingOn]);

  const getCard = useCallback(({cardId: cId, boardId: bId}) => {
    const lookupCardId = cId;
    let lookupBoardId = bId;
    let foundCard;

    if(lookupCardId === cardId.current) {
      lookupBoardId = boardId.current;
    }

    if(lookupBoardId && lookupCardId) {
      foundCard = getObserver(lookupBoardId)?.getCard({cardId: lookupCardId});
    }

    return foundCard;
  }, []);

  const getAffectedBoards = () => [...affectedBoards.current];

  const getCardCountForBoard = useCallback(id => getObserver(id)?.getCurrentCardCount(), []);

  const onBeginDrag = useCallback(({cardId: cId, boardId: bId, index: i}) => {
    cardId.current = cId;
    boardId.current = bId;
    index.current = i;
    originalBoardId.current = bId;
    originalIndex.current = i;
    originalCard.current = getCard({cardId: cId, boardId: bId})?.card;
    document.getElementById(`c${cId}`)?.classList?.add('drag-fix');
    setIsDragging(true);
  }, [getCard]);

  const onEndDrag = useCallback(() => {
    getObserver(boardId.current)?.showDropInsertAtIndex();
    cardId.current = 0;
    boardId.current = 0;
    index.current = 0;
    originalBoardId.current = 0;
    originalIndex.current = 0;
    originalCard.current = null;
    document.getElementById(`c${cardId.current}`)?.classList?.remove('drag-fix');
    setIsDragging(false);
  }, []);

  const hoverCardOverBoard = useCallback(({targetBoardId}) => {
    getObserver(boardId.current)?.showDropInsertAtIndex();
    getObserver(targetBoardId)?.showDropInsertAtIndex(Number.MAX_SAFE_INTEGER);
    boardId.current = targetBoardId;
    index.current = targetBoardId ? Number.MAX_SAFE_INTEGER : null;
  }, []);

  const moveCardToBoard = useCallback(async ({cardId: movingCardId, card, boardId: fromBoardId, targetBoardId, targetCardId, targetCardIndex}) => {
    await getObserver(fromBoardId)?.removeCardFromBoard({cardId: movingCardId});
    await getObserver(targetBoardId)?.addCardToBoard({
      card,
      targetCardId,
      targetCardIndex
    });
  }, []);

  const moveCardOnBoard = useCallback(async ({cardId: cId, targetCardId, targetCardIndex}) => {
    await getObserver(boardId.current)?.moveCardOnBoard({
      cardId: cId,
      targetCardId,
      targetCardIndex
    });
  }, []);

  const onBoardOnBoardDrop = useCallback(({sourceBoardId, targetBoardId}) => {
    onBoardDragged && onBoardDragged({sourceBoardId, targetBoardId});
  }, [onBoardDragged]);

  const onDrop = useCallback(({targetCardId, targetBoardId, targetCardIndex, onDropSuccess}) => {
    const addToBoardId = originalBoardId.current !== targetBoardId ? targetBoardId : null;
    const saveBoards = [];

    saveBoards.push(targetBoardId);

    const handleOnDropError = error => {
      console.log(`onDrop failure: ${error?.message || 'unknown error'}`);
      onError && onError({error, affectedBoards: saveBoards});
    };

    let promisedMove;

    if(addToBoardId) {
      saveBoards.push(originalBoardId.current);
      promisedMove = moveCardToBoard({
        cardId: cardId.current,
        card: {...originalCard.current},
        boardId: originalBoardId.current,
        targetBoardId,
        targetCardId,
        targetCardIndex
      });
    }
    else {
      promisedMove = moveCardOnBoard({cardId: cardId.current, targetCardId, targetCardIndex});
    }

    promisedMove.then(() => {
      const promisedSaves = [];

      for(let i = 0; i < saveBoards.length; i++) {
        promisedSaves.push(getObserver(saveBoards[i]).commitReorderedCards({addToBoardId: targetBoardId}));
      }

      Promise.all(promisedSaves)
        .then(() => {
          affectedBoards.current = new Set([...affectedBoards.current, ...saveBoards]);
          onDropSuccess && onDropSuccess([...saveBoards]);
        }).catch(handleOnDropError);
    }).catch(handleOnDropError);

    // assuming success
    const card = getObserver(targetBoardId)?.getCard({cardId: cardId.current});
    const returnCard = card ? {
      card,
      moved: true
    } : null;

    return returnCard;
  }, [onError, moveCardToBoard, moveCardOnBoard]);

  const onMoveCardToLane = useCallback(async ({card, targetBoardId}) => {
    const {id: cId, board: {id: bId}, allAccess, createdAt, isDraft, updatedAt, viewOrder} = card || {};

    try {
      await moveCardToBoard({
        cardId: cId,
        card: {id: cId, allAccess, createdAt, isDraft, updatedAt, viewOrder, boardId: bId},
        boardId: bId,
        targetBoardId,
        targetCardIndex: 0
      });

      await getObserver(bId).commitReorderedCards({addToBoardId: targetBoardId});
      await getObserver(targetBoardId).commitReorderedCards({addToBoardId: targetBoardId});
      affectedBoards.current = new Set([...affectedBoards.current, bId, targetBoardId]);
    }
    catch{
      console.log('onMoveCardToLane move failure');
      onError && onError({
        affectedBoards: [targetBoardId, bId],
        message: 'Whoops 😬 an error occurred while updating the card lane. The changes were not saved.'});
    }
  }, [moveCardToBoard, onError]);

  const isCardDragging = useCallback(id => cardId.current === id, []);

  const toggleIsCardDraggingOn = useCallback(() => {
    setIsCompressedCardDraggingOn(on => !on);
  }, []);

  const addObserver = (observer, data, identifier) => {
    requiredObserverFunctions.forEach(fName => {
      if(!observer[fName] || typeof observer[fName] !== 'function') {
        throw new Error(`CardDraggingContext observers must implement ${fName}.`);
      }
    });
    observers.current[identifier] = {observer, ...data};
  };

  const removeObserver = identifier => {
    delete observers.current[identifier];
  };

  const onFindCard = useCallback(({cardId: cId, boardId: bId}) => getCard({cardId: cId, boardId: bId}), [getCard]);

  const value = useMemo(() => {
    return {
      onBeginDrag,
      onEndDrag,
      hoverCardOverBoard,
      onBoardOnBoardDrop,
      onDrop,
      onMoveCardToLane,
      isCardDragging,
      onFindCard,
      addObserver,
      removeObserver,
      setIsCompressedCardDraggingOn,
      isCompressedCardDraggingOn,
      toggleIsCardDraggingOn,
      getAffectedBoards,
      getCardCountForBoard,
      dragging
    };
  }, [
    onBeginDrag,
    onEndDrag,
    hoverCardOverBoard,
    onBoardOnBoardDrop,
    onDrop,
    onMoveCardToLane,
    isCardDragging,
    onFindCard,
    setIsCompressedCardDraggingOn,
    isCompressedCardDraggingOn,
    toggleIsCardDraggingOn,
    getCardCountForBoard,
    dragging
  ]);

  return value;
};

export const CardDraggingProvider = ({children, onError, onBoardDragged, onSetContextRef}) => {
  const value = useCardDraggingInternal({onError, onBoardDragged});

  onSetContextRef(value);

  return (
    <CardDraggingContext.Provider value={value}>
      {children}
    </CardDraggingContext.Provider>
  );
};

CardDraggingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onError: PropTypes.func,
  onBoardDragged: PropTypes.func,
  onSetContextRef: PropTypes.func
};

CardDraggingProvider.defaultProps = {
  onError() {},
  onBoardDragged() {},
  onSetContextRef() {}
};

export const withCardDragging = Component => {
  return props => (
    <CardDraggingContext.Consumer>
      {cardDraggingContext => (
        <Component {...props} cardDraggingContext={cardDraggingContext} />
      )}
    </CardDraggingContext.Consumer>
  );
};
