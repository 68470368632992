import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../_icon';

const DigestSectionFilter = ({placeholder, filter, onChange, visible, dataTrackingId}) => {
  if(!visible) {
    return null;
  }

  const attributes = {};

  if(dataTrackingId) {
    attributes['data-tracking-id'] = dataTrackingId;
  }

  return (
    <div className="digest-section-filter" {...attributes}>
      <Icon icon="search" width={20} height={20} />
      <input placeholder={placeholder} type="search" value={filter} onChange={e => onChange(e?.target?.value)} />
    </div>
  );
};

DigestSectionFilter.propTypes = {
  placeholder: PropTypes.string,
  filter: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  dataTrackingId: PropTypes.string
};

DigestSectionFilter.defaultProps = {
  placeholder: '',
  filter: '',
  visible: false,
  dataTrackingId: ''
};

export default DigestSectionFilter;
