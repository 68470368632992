import {matchPath} from 'react-router';
import {
  addGlobalContexts,
  enableActivityTracking,
  newTracker,
  setUserId,
  trackPageView,
  trackSelfDescribingEvent,
  trackStructEvent
} from '@snowplow/browser-tracker';

import {userCanCurate} from './roles_utils';

import ReactGA from 'react-ga';

export const cleanGoogleAnalyticsUrlParams = oldUrl => {
  // Clean up any tracking params in the url
  // https://gist.github.com/paulirish/626834
  return oldUrl.replace(/\?([^#]*)/, (location, search) => {
    search = search.split('&').reduce((acc, v) => {
      if(!/^utm_/.test(v)) {
        acc.push(v);
      }

      return acc;
    }, []).join('&');

    return search ? '?' + search : '';
  });
};

export const trackingParamSources = Object.freeze({
  digest: {label: 'digest', medium: 'email'},
  feed: {label: 'feed', medium: 'web'},
  card: {label: 'card', medium: 'web'}
});

export const addTrackingParams = (url, {source = {label: 'unknown', medium: 'unknown'}, content = 'unknown'}) => {
  const [nUrl, hash] = url.split('#', 2);
  const sep = nUrl.indexOf('?') >= 0 ? '&' : '?';

  return `${nUrl}${sep}utm_source=${source.label}&utm_medium=${source.medium}&utm_content=${content}${hash === undefined ? '' : `#${hash}`}`;
};

export const SNOWPLOW_SCHEMAS = {
  company: 'iglu:com.klue/company/jsonschema/1-0-0',
  user: 'iglu:com.klue/user/jsonschema/1-0-0',
  battleCard: 'iglu:com.klue/battlecard/jsonschema/1-0-0',
  board: 'iglu:com.klue/board/jsonschema/1-0-0',
  searchAction: 'iglu:com.klue/search_action/jsonschema/1-0-1',
  searchfilter: 'iglu:com.klue/searchfilter/jsonschema/1-0-0',
  cardInteraction: 'iglu:com.klue/card_interaction/jsonschema/1-0-8',
  card: 'iglu:com.klue/card/jsonschema/1-0-3',
  impression: 'iglu:com.klue/impression/jsonschema/1-0-5',
  salesforceAction: 'iglu:com.klue/salesforce_action/jsonschema/1-0-0'
};

export const pageTrackingTypes = ['event', 'pageping', 'pageview'];

export const defaultInstanceTRacker = 'sp-0';
const minimumVisitLength = 10;
const heartbeatDelay = 10;

const debugSnowplow = (msg, opts) => {
  (opts?.logger ?? console.log)(
    `%c[🌨  Snowplow Analytics]%c ${msg}`,
    'color: snow; font-weight: bold;',
    'color: unset; font-weight: normal;',
    opts?.data
  );
};

const getCampaignContentFromUrl = (url = '') => {
  if(matchPath(url, {path: '/profile/:profileId/battlecard/(view|embed)'})) {
    return 'Battlecard';
  }

  if(matchPath(url, {path: '/profile/:profileId/battlecard/edit'})) {
    return 'Board';
  }

  if(matchPath(url, {path: '/profile/:profileId/edit/card/:cardId'}) || matchPath(url, {path: '/card/embed/:cardId'})) {
    return 'Card';
  }

  if(matchPath(url, {path: '/dashboard'})) {
    return 'Dashboard';
  }

  return 'unknown';
};

const isGAEnabled = (() => {
  let featureFlagValue;

  return company => {
    if(featureFlagValue) {return featureFlagValue;}
    if(!company) {return false;}

    featureFlagValue = Boolean(company?.companyData?.isGAEnabled);

    return featureFlagValue;
  };
})();

const PROVIDERS = {
  ga: {
    id: 'ga',
    accounts: [],
    initialized: false,
    currentPath: '',
    spLegacyName: '',
    init(user, company) {
      if(!isGAEnabled(company)) {
        return true;
      }

      const {accounts} = this;

      if(accounts.length === 0) {
        return false;
      }

      if(this.initialized) {
        return true;
      }

      const accountNames = accounts.map(account => account.name);
      const userIdentifier = user.id.toString();
      const userDomainIndex = user.email.lastIndexOf('@');
      let userDomain = '';

      if(userDomainIndex >= 0) {
        userDomain = user.email.substring(userDomainIndex + 1);
      }

      const customDimensions = {
        dimension1: company.id.toString(),              // company id
        dimension2: company.name,                       // company name
        dimension3: userDomain,                         // email domain
        dimension4: userCanCurate({user}).toString(),   // is curator?
        // dimension5 was previously fullstory session URL (fullstory no longer used)
        dimension5: user.employeeId,
        dimension6: user.id.toString(),                 // redundant userId field, but required by dell for analytics reports
        dimension7: false                               // is user in v2
      };

      // add additional props
      accounts.forEach((account, idx) => {
        const {name, id: trackingId, debug} = account;

        const gaOptions = {
          name,
          userId: userIdentifier
        };

        Object.assign(accounts[idx], {
          trackingId,
          gaOptions,
          debug,
          titleCase: false
        });
      });
      ReactGA.initialize(accounts, {alwaysSendToDefaultTracker: false});
      accounts.forEach(({id}) => ReactGA.ga('create', id, 'auto', {cookieFlags: 'SameSite=None; Secure'}));
      ReactGA.set(customDimensions, accountNames);

      return true;
    },
    setTrackingParams(path = this.currentPath, isPageview = false) {
      if(!isGAEnabled()) {return false;}

      const isIframe = window.location !== window.parent.location;
      const defaultCompaignMedium = isIframe ? 'iframe' : null;
      const accountNames = this.accounts.map(account => account.name);
      const defaultCampaignContent = getCampaignContentFromUrl(path);
      const defaultCampaignParams = {campaignSource: 'unknown', campaignContent: defaultCampaignContent};

      if(!path && isPageview && isIframe) {
        return ReactGA.set({...defaultCampaignParams, campaignMedium: defaultCompaignMedium}, accountNames);
      }

      const queryString = path.substring(path.indexOf('?') + 1);

      this.currentPath = path;

      if(/utm_/.test(queryString)) {
        const oldUrl = location.href;
        const trackingParams = {};

        queryString.split('&').forEach(param => {
          const pair = param.split('=');

          trackingParams[pair[0]] = decodeURIComponent(pair[1]);
        });

        const {utm_source: campaignSource, utm_medium: campaignMedium, utm_content: campaignContent} = trackingParams;
        const campaignMediumValue = (isPageview && isIframe && !campaignMedium) ? defaultCompaignMedium : campaignMedium;
        const newUrl = cleanGoogleAnalyticsUrlParams(oldUrl);

        if(newUrl !== oldUrl) {
          ReactGA.set({campaignSource, campaignMedium: campaignMediumValue, campaignContent}, accountNames);
        }
      }
      else if(isIframe && isPageview) {
        ReactGA.set({...defaultCampaignParams, campaignMedium: defaultCompaignMedium}, accountNames);
      }
    },
    handlers: {
      pageview(event) {
        if(!isGAEnabled()) {return false;}

        const {path, title} = event;
        const accountNames = this.accounts.map(account => account.name);

        this.setTrackingParams(path, true);
        ReactGA.pageview(path, accountNames, title);
      },
      event(event) {
        if(!event.spOnly) {
          const accountNames = this.accounts.map(account => account.name);
          const {spLegacyName} = this;

          this.setTrackingParams();

          if(isGAEnabled()) {
            ReactGA.event(event, accountNames);
          }

          trackStructEvent(event, [spLegacyName]);
        }
      }
    }
  },
  sp: {
    id: 'sp',
    accounts: [],
    initialized: false,
    currentPath: '',
    spLegacyName: '',
    init(user, company) {
      const {accounts, spLegacyName} = this;

      if(accounts.length === 0) {
        return false;
      }

      if(this.initialized) {
        return true;
      }

      accounts.forEach((account, index) => {
        const {appId, collectorUrl, name, postPath} = account;
        const trackerName = name === 'LegacyInstanceAccount' ? spLegacyName : `sp-${index}`;

        const config = {
          appId,
          postPath,
          plugins: []
        };

        try {
          newTracker(trackerName, collectorUrl, config);
        }
        catch(error) {
          debugSnowplow('failed with\n%o', {
            data: error,
            logger: console.error
          });

          return false;
        }
      });

      try {
        const {id: userId} = user;
        const {id: companyId} = company;

        setUserId(`${userId}`);
        addGlobalContexts([
          {
            schema: SNOWPLOW_SCHEMAS.company,
            data: {
              id: parseInt(companyId ?? 0, 10)
            }
          },
          {
            schema: SNOWPLOW_SCHEMAS.user,
            data: {
              id: parseInt(userId ?? 0, 10)
            }
          }
        ]);
      }
      catch(error) {
        debugSnowplow('failed with\n%o', {
          data: error,
          logger: console.error
        });

        return false;
      }

      return true;
    },
    handlers: {
      pageping() {
        enableActivityTracking({minimumVisitLength, heartbeatDelay}, [defaultInstanceTRacker]);
      },
      pageview(event, context) {
        const isContextEmpty = Object.keys(context).length === 0;

        if(isContextEmpty) {
          return trackPageView({}, [defaultInstanceTRacker]);
        }

        trackPageView(context, [defaultInstanceTRacker]);
      },
      event(_, {schema, data, context = []}) {
        if(!schema) {return;}

        return trackSelfDescribingEvent({
          event: {
            schema,
            data
          },
          context
        }, [defaultInstanceTRacker]);
      }
    }
  }
};

export const analyticsInit = ({user = {}, company = {}, accounts = [], spAnalyticsLegacy = ''}) => {
  accounts.forEach(account => {
    if(PROVIDERS[account.provider]) {
      const provider = PROVIDERS[account.provider];

      if(provider.accounts.findIndex(acc => acc.name === account.name) === -1) {
        provider.accounts.push(account);
        provider.spLegacyName = spAnalyticsLegacy;
      }
    }
  });

  Object.values(PROVIDERS).forEach(provider => {
    if(provider.init && !provider.initialized) {
      // using call to keep 'this' reference to provider obj
      provider.initialized = provider.init.call(provider, user, company);
    }
  });
};

/**
 * Renders filters menu + cards for comparison view search
 *
 * @param {object} event - Analytics event details (see https://github.com/react-ga/react-ga)
 * @param {string} event.type - Event type (required) ['pageview','event']
 * @param {string} event.path - Page path (required for pageview events)
 * @param {string} event.title - Page title (optional)
 * @param {string} event.provider - Target specific provider (optional) single value or comma-separated list.
 * @param {string} event.category - Event category (required for generic events)
 * @param {string} event.action - Event action (required for generic events)
 * @param {string} event.label - Event label (required for generic events)
 * @param {string} event.value - Event value (optional)
 * @param {string} event.nonInteraction - Flag to specify non-interactive events (optional)
 * @param context
 */
export const analyticsTrack = (event = {
  type: 'pageview',
  path: '',
  title: '',
  provider: undefined,
  category: '',
  action: '',
  label: '',
  value: null,
  spOnly: false,
  nonInteraction: undefined
}, context = {}) => {
  if(_.isEmpty(event) || ((event.type === 'pageview') && !event.path)) {
    return;
  }

  const {type, provider} = event;
  let providers = [];

  if(provider) {
    providers = provider.split(',');
  }
  else {
    providers = Object.keys(PROVIDERS);
  }

  providers.forEach(p => {
    const providerObj = PROVIDERS[p];
    const handler = providerObj.handlers[type];

    if(providerObj.initialized) {
      if(typeof handler === 'function') {
        // using call to keep 'this' reference to provider obj
        handler.call(providerObj, event, context);
      }
    }
  });
};
