import DropdownMenu from './_dropdown_menu';
import ManageUsersPerPageDropdown from './_manage_users_per_page_dropdown';

import {userCanCurate} from '../modules/roles_utils';

class ManageUsersToolbar extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    sortBy: PropTypes.string.isRequired,
    viewBy: PropTypes.string.isRequired,
    viewGroup: PropTypes.number,
    onSortClick: PropTypes.func.isRequired,
    onViewClick: PropTypes.func.isRequired,
    onVisibilityGroupClick: PropTypes.func.isRequired,
    visibilityGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object.isRequired,
    counts: PropTypes.object.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    onChangeItemsPerPage: PropTypes.func.isRequired
  };

  static defaultProps = {
    sortBy: 'alpha',
    viewBy: 'active',
    viewGroup: null,
    onSortClick() {},
    onViewClick() {},
    onVisibilityGroupClick() {},
    visibilityGroups: [],
    user: {},
    counts: {},
    itemsPerPage: 10,
    onChangeItemsPerPage() {}
  };

  renderViewFiltersDropdown = () => {
    const {counts, viewBy, user} = this.props;

    let viewOptions;

    if(userCanCurate({user})) {
      viewOptions = [
        ['all', `All (${counts.all})`],
        ['active', `Active (${counts.active})`],
        ['pending', `Pending (${counts.pending})`],
        ['deactivated', `Deactivated (${counts.deactivated})`],
        ['admins', `Admins (${counts.admins})`],
        ['curators', `Curators (${counts.curators})`],
        ['consumers', `Consumers (${counts.consumers})`],
        ['suppressed', `Email Suppressed (${counts.suppressed})`]
      ];
    }
    else {
      viewOptions = [
        ['users', `All Users (${counts.users})`],
        ['curators', `Curators (${counts.curators})`],
        ['admins', `Admins (${counts.admins})`]
      ];
    }

    return (
      <DropdownMenu
        id="viewUsersDropdown"
        values={viewOptions}
        label="Show:"
        title="Show"
        selectedValue={viewBy}
        selectCallback={this.props.onViewClick} />
    );
  };

  renderVisibilityGroupFiltersDropdown = () => {
    const {viewGroup, visibilityGroups, onVisibilityGroupClick, user} = this.props;

    const values = [
      [null, 'All Groups'],
      ...visibilityGroups.map(group => [group.id, `${group.name} (${group.usersCount})`]),
      [0, 'Full Access', true]
    ];

    const dropDown = userCanCurate({user}) ? (
      <DropdownMenu
        id="viewVisibilityGroupDropdown"
        values={values}
        label="Show:"
        title="Visibility Groups"
        selectedValue={viewGroup}
        selectCallback={onVisibilityGroupClick} />
    ) : null;

    return dropDown;
  };

  renderSortDropdown = () => {
    const sortOptions = [
      ['alpha', 'A-Z'],
      ['alpha-rev', 'Z-A'],
      ['created', 'Newest'],
      ['created-rev', 'Oldest'],
      ['login', 'Most Recently Seen'],
      ['login-rev', 'Least Recently Seen']
    ];
    const {sortBy, onSortClick} = this.props;

    return (
      <DropdownMenu
        id="sortUsersDropdown"
        values={sortOptions}
        label="Sort By:"
        title="Sort By"
        selectedValue={sortBy}
        selectCallback={onSortClick} />
    );
  };

  render() {
    const {itemsPerPage, onChangeItemsPerPage} = this.props;

    return (
      <div className="manage-users-toolbar">
        <div className="toolbar">
          <div className="toolbar_right" data-cy="manage_users_toolbar_right">
            {this.renderViewFiltersDropdown()}
            {this.renderVisibilityGroupFiltersDropdown()}
            {this.renderSortDropdown()}
            <ManageUsersPerPageDropdown
              dataTrackingId="manage-users-toolbar-users-per-page-dropdown"
              selectedValue={itemsPerPage}
              onChangeItemsPerPage={onChangeItemsPerPage} />
          </div>
        </div>
      </div>
    );
  }

}

export default ManageUsersToolbar;
