export const copyToClipboard = (elements = null, wrapperClass = 'clipboard-copy') => {
  if(!elements) {
    // avoid error nastiness in Firefox (unsupported)
    console.error('ClipboardUtils.copyToClipboard: no content to copy specified');

    return false;
  }
  else if(!document.queryCommandSupported('copy')) {
    // avoid error nastiness in Firefox (unsupported)
    console.error('ClipboardUtils.copyToClipboard: copy command not supported');

    return false;
  }

  const divNode = document.createElement('div');
  let isCopied = false;

  // normalize display
  divNode.setAttribute('class', wrapperClass);
  ReactDOM.render(elements, divNode);

  const appendedNode = document.body.appendChild(divNode);

  // select contents to be copied
  // source: http://codebits.glennjones.net/copypaste/copydata.htm
  if(window.getSelection) { // FF, Safari, Chrome, Opera, IE 9+
    const sel = window.getSelection();
    const range = document.createRange();

    range.selectNodeContents(appendedNode);
    sel.removeAllRanges();
    sel.addRange(range);
  }

  // attempt copy
  try {
    isCopied = document.execCommand('copy');  // FF 41+, Chrome, (tested IE 10+)
  }
  catch(e) {
    // avoid error nastiness in Firefox (unsupported)
    console.error('ClipboardUtils.copyToClipboard: error: unable to copy text to clipboard: %o', e);
  }

  document.body.removeChild(divNode);

  return isCopied;
};
