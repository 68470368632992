import VisibilityGroupCreate from './_visibility_group_create';
import VisibilityGroupsEditList from './_visibility_groups_edit_list';
import Icon from './_icon';
import {pluralize} from '../modules/text_utils';

import classNames from 'classnames';

class VisibilityGroupsManage extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    modalId: PropTypes.string,
    onGroupAddedCallback: PropTypes.func,
    onGroupUpdatedCallback: PropTypes.func,
    onGroupDeletedCallback: PropTypes.func,
    onGroupMovedCallback: PropTypes.func,
    onGroupToggleUserDefault: PropTypes.func,
    onGroupToggleCardDefault: PropTypes.func,
    visibilityGroups: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    modalId: '',
    onGroupAddedCallback() {},
    onGroupUpdatedCallback() {},
    onGroupDeletedCallback() {},
    onGroupMovedCallback() {},
    onGroupToggleUserDefault() {},
    onGroupToggleCardDefault() {},
    visibilityGroups: []
  };

  state = {
    activeEditGroupId: 0,
    editGroupId: 0,
    groupDragging: false,
    groupPositions: [],
    errorText: ''
  };

  componentDidMount() {
    console.log('VisibilityGroupsManage.componentDidMount: props: %o', this.props);

    const {visibilityGroups} = this.props;

    this.setState({
      groupPositions: visibilityGroups.slice().map(g => g.id)
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const groups = this.props.visibilityGroups;
    const nextGroups = nextProps.visibilityGroups;

    if((!groups && nextGroups && nextGroups.length) || (groups && nextGroups && (groups.length !== nextGroups.length))) {
      this.setState({
        groupPositions: nextGroups.slice().map(g => g.id)
      }, () => console.log('VisibilityGroupsManage.componentWillReceiveProps: updated group positions: %o', this.state.groupPositions));
    }
  }

  _closeActiveModal = () => {
    this.context.utils.dialog.remove(this.props.modalId);
  };

  _stopPropagation = event => {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  handleEditGroupClick = (group, event) => {
    this._stopPropagation(event);

    if(_.isEmpty(group) || (group.id <= 0)) {
      this.setState({
        editGroupId: 0,
        errorText: ''
      });

      return;
    }

    this.setState({
      editGroupId: group.id,
      errorText: ''
    });
  };

  handleReorderGroupDrag = (isDragging = true) => {
    if(this.state.groupDragging !== isDragging) {
      this.setState({
        groupDragging: isDragging
      });
    }
  };

  handleReorderGroupMove = (dragItemProps, targetItemProps) => {
    const {groupPositions} = this.state;
    const fromIndex = dragItemProps.droppableCardIndex;
    const toIndex = targetItemProps.droppableCardIndex;
    const draggedGroupId = groupPositions[fromIndex];

    if(fromIndex === toIndex) {
      return;
    }

    this.setState({
      groupPositions: ReactUpdate(this.state.groupPositions, {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, draggedGroupId]
        ]
      }, () => {
        console.log('DashboardEdit.handleReorderGroupHover: fromIndex: %o, toIndex: %o, moved group %o', fromIndex, toIndex, draggedGroupId);
      })
    });
    dragItemProps.droppableCardIndex = targetItemProps.droppableCardIndex;
  };

  handleReorderGroupDrop = () => {
    const {groupPositions = []} = this.state;

    if(!groupPositions || !groupPositions.length) {
      return;
    }

    this.props.onGroupMovedCallback(groupPositions);
  };

  handleSaveGroupClick = (group = null, name = '', event) => {
    this._stopPropagation(event);

    const {visibilityGroups = []} = this.props;

    if(_.isEmpty(group) || (group.id <= 0) || (this.state.editGroupId !== group.id) || !name) {
      if(!name) {
        this.setState({
          errorText: 'Please enter a name for your group. 😉'
        });
      }

      return;
    }

    if(visibilityGroups.find(g => (g.id !== group.id) && (g.name.toLowerCase() === name.toLowerCase()))) {
      this.setState({
        errorText: 'It looks like a group with that name already exists. 😢'
      });

      return;
    }

    const updatedGroup = {
      id: group.id,
      name,
      viewOrder: group.viewOrder
    };

    this.context.api.visibilityGroupUpdate({
      visibilityGroupOptions: updatedGroup
    })
      .then(g => {
        this.setState({
          editGroupId: 0,
          errorText: ''
        }, () => {
          this.props.onGroupUpdatedCallback(g);
        });
      });
  };

  handleDeleteGroupClick = (group, event) => {
    this._stopPropagation(event);

    if(_.isEmpty(group) || (group.id <= 0)) {
      return;
    }

    const deleteAction = () => {
      // delete existing vis group
      this.context.api.visibilityGroupDelete({
        visibilityGroupOptions: group
      }).then(() => {
        console.log('DashboardEdit.handleDeleteGroupClick: groupId #%o deleted successfully: %o', group.id, group);

        if(group.id === this.state.activeEditGroupId) {
          this.setState({
            activeEditGroupId: 0,
            errorText: ''
          });
        }

        this.props.onGroupDeletedCallback(group.id);
      });
    };

    // TODO: address default group vs full access...
    const userMsg = group.usersCount ? `<br />(${group.usersCount} ${pluralize('user', group.usersCount)} will be reassigned)` : '';

    this.context.utils.dialog.confirm({
      message: `<strong>Delete group &quot;${group.name}&quot;</strong><br /><br />
        Are you sure you want to delete this group?${userMsg}`,
      okCallback: deleteAction
    });
  };

  handleToggleGroupClick = (group = null, callback = null) => {
    if(_.isEmpty(group) || (group.id <= 0)) {
      return;
    }

    const {activeEditGroupId} = this.state;
    const isActiveGroup = (group.id === activeEditGroupId);

    this.setState({
      activeEditGroupId: isActiveGroup ? 0 : group.id
    });

    return typeof callback === 'function' && callback();
  };

  renderGroups = () => {
    const {visibilityGroups, onGroupToggleUserDefault, onGroupToggleCardDefault} = this.props;
    const {activeEditGroupId, editGroupId, groupDragging, groupPositions, errorText} = this.state;
    const groupClasses = classNames('visibility-groups-list', {
      'visibility-groups-list--dragging': groupDragging
    });

    return (
      <VisibilityGroupsEditList
        visibilityGroups={visibilityGroups}
        className={groupClasses}
        errorText={errorText}
        activeEditGroupId={activeEditGroupId}
        editGroupId={editGroupId}
        groupPositions={groupPositions}
        onEditGroupClick={this.handleEditGroupClick}
        onSaveGroupClick={this.handleSaveGroupClick}
        onDeleteGroupClick={this.handleDeleteGroupClick}
        onReorderGroupDrag={this.handleReorderGroupDrag}
        onReorderGroupDrop={this.handleReorderGroupDrop}
        onReorderGroupMove={this.handleReorderGroupMove}
        onToggleGroupClick={this.handleToggleGroupClick}
        onToggleUserDefaultGroupClick={onGroupToggleUserDefault}
        onToggleCardDefaultGroupClick={onGroupToggleCardDefault} />
    );
  };

  renderUserDefaultGroupMsg = () => {
    const defaultGroup = this.props.visibilityGroups.find(vg => vg.isUserDefault === true);
    const icon = defaultGroup ? 'star' : 'star_border';
    const msg = defaultGroup ? `New users will be added to '${defaultGroup.name}'.` : 'New users will be granted \'Full Access\'';

    return (<div className="visibility-groups-default">
      <Icon
        icon={icon}
        width="18"
        height="18"
        className="visibility-group_icon visibility-group_icon--default" />
      {msg}
    </div>);
  };

  renderCardDefaultGroupMsg = () => {
    const defaultGroups = this.props.visibilityGroups.filter(vg => vg.isCardDefault === true).map(g => g.name);
    const icon = defaultGroups.length > 0 ? 'tick' : 'add_circle';
    const displayGroups = defaultGroups.concat('Full Access Users');
    const msg = `When published, new Cards will be visible to: '${displayGroups.join('\', \'')}'.`;

    return (<div className="visibility-groups-default">
      <Icon
        icon={icon}
        width="18"
        height="18"
        className="visibility-group_icon visibility-group_icon--default" />
      {msg}
    </div>);
  };

  renderCreateNewGroup = () => {
    return (
      <VisibilityGroupCreate
        modalId={this.props.modalId}
        onGroupAddedCallback={this.props.onGroupAddedCallback}
        visibilityGroups={this.props.visibilityGroups}
        manageMode={true} />
    );
  };

  render() {
    return (
      <div className="manage-visibility-groups-modal">
        <h3 className="heading-dialog">Manage Visibility Groups</h3>
        {this.renderGroups()}
        {this.renderCreateNewGroup()}
        {this.renderUserDefaultGroupMsg()}
        {this.renderCardDefaultGroupMsg()}
        <div className="dialog-toolbar">
          <div className="button button--disabled dialog-cancel-button" onClick={this._closeActiveModal}>Close</div>
        </div>
      </div>
    );
  }

}

export default VisibilityGroupsManage;
