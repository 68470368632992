import {useEffect, useState, useReducer, useMemo, createContext} from 'react';

import AddTag from './_add_tag';
import ManageTags from './_manage_tags';
import TagError from './_tag_error';
import {tagsGet} from '../../modules/api/tags';
import CompanySettingsTagVisibility from './_tag_visibility';
import KlueTags from './_klue_tags';
import {useTags} from '../../contexts/_tags';

export const TagErrorsContext = createContext();

const addTag = (tags, tag) => {
  const tagsAddedTo = tags.slice();

  tagsAddedTo.unshift({...tag});

  return tagsAddedTo;
};

const updateTag = (tags, tag) => {
  const {id} = tag;
  const index = tags.findIndex(t => t.id === id);

  if(index === -1) {
    return tags;
  }

  const tagsToUpdate = tags.slice();

  tagsToUpdate[index] = {...tag};

  return tagsToUpdate;
};

const deleteTag = (tags, tag) => {
  const {id: deleteId} = tag;
  const deletionIndex = tags.findIndex(t => t.id === deleteId);

  if(deletionIndex === -1) {
    return tags;
  }

  const tagsWithDelete = tags.slice();

  tagsWithDelete.splice(deletionIndex, 1);

  return tagsWithDelete;
};

const reducer = (state, {type, payload}) => {
  switch(type) {
    case 'addTag': {
      return {
        ...state,
        manageTags: addTag(state.manageTags, payload)
      };
    }

    case 'updateTag': {
      const {isKlueManaged} = payload;

      if(isKlueManaged) {
        return {
          ...state,
          klueTags: updateTag(state.klueTags, payload)
        };
      }

      return {
        ...state,
        manageTags: updateTag(state.manageTags, payload)
      };
    }

    case 'deleteTag': {
      return {
        ...state,
        manageTags: deleteTag(state.manageTags, payload)
      };
    }

    case 'setTags': {
      const [klueTags, manageTags] = payload.reduce((acc, tag) => {
        if(tag.isKlueManaged) {
          acc[0].push(tag);
        }
        else {
          acc[1].push(tag);
        }

        return acc;
      }, [[], []]);

      return {...state, klueTags, manageTags};
    }

    default: {
      return state;
    }
  }
};

const CompanySettingsTags = () => {
  const [error, setError] = useState();
  const [highlights, setHighlights] = useState({});
  const [{klueTags, manageTags}, dispatch] = useReducer(reducer, {});
  const {reloadTags} = useTags();

  useEffect(() => {
    const getTags = async () => {
      try {
        const {tags: fetchedTags} = await tagsGet({filter: 'all'});

        dispatch({type: 'setTags', payload: fetchedTags || []});
      }
      catch(err) {
        setError({error: err, message: 'An error occurred while fetching your tags. Please reload your browser and try again.'});
        dispatch({type: 'setTags', payload: []});
      }
    };

    getTags();
  }, [dispatch]);

  useEffect(() => {
    return () => reloadTags();
  }, [reloadTags]);

  const {
    handleAddTag,
    handleUpdateTag,
    handleDeleteTag
  } = useMemo(() => ({
    handleAddTag: tag => dispatch({type: 'addTag', payload: tag}),
    handleUpdateTag: tag => dispatch({type: 'updateTag', payload: tag}),
    handleDeleteTag: tag => dispatch({type: 'deleteTag', payload: tag})
  }), [dispatch]);

  const handleTagValueDidChange = () => setHighlights({});

  return (
    <TagErrorsContext.Provider value={{highlights, setHighlights}}>
      <div className="tags_settings-container">
        <h3 className="u-m0">Card Tags Settings</h3>
        <p className="help-block u-mb-m">Admins are able to add, edit and remove Tags. The resulting list is then available for Curators to add to Cards.</p>
        {error && <TagError error={error} />}
        <CompanySettingsTagVisibility />
        <h4 className="u-pt-s"><strong>Add a Tag</strong></h4>
        <AddTag onAddTag={handleAddTag} onTagValueDidChange={handleTagValueDidChange} />
        <ManageTags tags={manageTags} onUpdateTag={handleUpdateTag} onDeleteTag={handleDeleteTag} />
        <KlueTags tags={klueTags} onUpdateTag={handleUpdateTag} />
      </div>
    </TagErrorsContext.Provider>
  );
};

export default CompanySettingsTags;
