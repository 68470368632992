// A helper component that puts markup at root level of the dom
// useful for overlays and such. Could be turned into a global util type component too?
class BattlecardPreviewProxy extends React.Component {

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func
    ]).isRequired
  };
  constructor(props) {
    super(props);
    this.node = document.createElement('div');
  }
  componentDidMount() {
    document.body.appendChild(this.node);
  }
  componentWillUnmount() {
    if(this.node) {
      document.body.removeChild(this.node);
      this.node = null;
    }
  }

  render() {
    let {children} = this.props;

    if(typeof children.type === 'function') {
      children = React.cloneElement(children, this.props);
    }

    return ReactDOM.createPortal(
      children,
      this.node
    );
  }

}

export default BattlecardPreviewProxy;
