import SearchResultCard from './_search_result_card';

import {cardGet} from '../modules/api/cards';

class SearchAnswer extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    match: PropTypes.object.isRequired,
    rivals: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    match: {},
    rivals: []
  };

  state = {
    card: null,
    rival: {},
    board: {
      name: ''
    }
  };

  componentDidMount() {
    console.log('SearchAnswer.componentDidMount: props: %o', this.props);

    const {match: {params: {rival}}, rivals} = this.props;
    const name = decodeURIComponent(rival).toLowerCase();

    if(!_.isEmpty(rival) && (_.isEmpty(rivals) || !this.findRival(name, rivals))) {
      this.context.utils.requestRival({name}).then(r => {
        console.log(`SearchAnswer.componentDidMount: ${r ? 'fetched' : 'failed to fetch'} requested rival %o: %o`, name, r);
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {rivals, match: {params: {id: cardId, rival}}} = nextProps;

    if(!_.isEmpty(rivals) && this.findRival(decodeURIComponent(rival), rivals) && !this.state.card) {
      this.loadCard(rivals, parseInt(cardId, 10));
    }
  }

  findRival = (name = '', rivals = []) => rivals.find(r => r.name.toLowerCase() === name.toLowerCase());

  loadCard = (rivals, cardId = 0) => {
    const cardOptions = {cardId};

    // TODO: this should get card from ES instead of postgres to match expectations in SearchResultCard below
    cardGet({cardOptions, code: 'SearchAnswer.loadCard'}).then(card => {
      const {rival: rivalName} = this.props.match.params;
      const rival = this.findRival(decodeURIComponent(rivalName), rivals);

      this.setState({
        card,
        rival,
        board: {name: card.board.name}
      });
    });
  };

  render() {
    return (
      <div className="search-answer">
        {this.state.card &&
          <div className="search-answer-card">
            <SearchResultCard {...this.state} showHighlights={false} />
          </div>
        }
        {!this.state && (
          <div>No results found.</div>
        )}
      </div>
    );
  }

}

export default SearchAnswer;
