import Icon from './_icon';
import {userCanCurate} from '../modules/roles_utils';

import {Link} from 'react-router-dom';

const DigestToolbar = ({
  user,
  reorderMode,
  activeDigestId,
  onToggleReorder
}) => {
  if(!userCanCurate({user})) {
    return null;
  }

  const backButtonProps = {};
  let backButtonLink = '/digest';
  let backButtonRegion;
  let archivesButtonRegion;
  let settingsButtonRegion;
  let upcomingButtonRegion;

  if(reorderMode) {
    backButtonLink = `${backButtonLink}`;

    if(activeDigestId) {
      backButtonLink = `${backButtonLink}/${activeDigestId}`;
    }

    backButtonProps.onClick = onToggleReorder;
  }

  if(reorderMode) {
    backButtonRegion = (
      <Link to={backButtonLink} key="digest-back" className="toolbar-button" {...backButtonProps}>
        <div className="toolbar-button_icon toolbar-button_icon--left"><Icon icon="arrow-left" width="24" height="24" /></div>
        Back
      </Link>
    );
  }

  const timelineButtonRegion = (
    <Link to="/digest/overview" key="digest-timeline" className="toolbar-button digest-overview" data-tracking-id="return-to-digest-overview-link">
      <div className="toolbar-button_icon toolbar-button_icon--left"><Icon icon="list" width="24" height="24" /></div>
      Return to Digest Overview
    </Link>
  );

  return (
    <div className="digest-toolbar">
      <div className="toolbar">
        <div className="toolbar_left toolbar--hide-mobile">
          {backButtonRegion}
          {archivesButtonRegion}
          {settingsButtonRegion}
          {upcomingButtonRegion}
          {timelineButtonRegion}
        </div>
        <div className="toolbar_right" />
      </div>
    </div>
  );
};

DigestToolbar.propTypes = {
  user: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
  archivesMode: PropTypes.bool.isRequired,
  reorderMode: PropTypes.bool.isRequired,
  activeDigestId: PropTypes.number,
  onToggleReorder: PropTypes.func.isRequired
};

DigestToolbar.defaultProps = {
  user: null,
  editMode: false,
  archivesMode: false,
  reorderMode: false,
  activeDigestId: 0,
  onToggleReorder() {}
};

export default DigestToolbar;
