import {DragTypes} from '../modules/constants/dnd';
import {DropTarget} from 'react-dnd';
import {withCardDragging} from '../contexts/_cardDragging';
import classNames from 'classnames';

const itemTarget = {
  drop({cardDraggingContext, boardId, onLaneCountRefresh: onDropSuccess}, monitor) {
    if(monitor.didDrop()) {
      return;
    }

    if(cardDraggingContext) {
      return cardDraggingContext.onDrop({targetBoardId: boardId, targetCardIndex: Number.MAX_SAFE_INTEGER, onDropSuccess});
    }
  },

  canDrop() {
    return true;
  }
};

function collectItemTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

const EmptyLaneTargetCard = ({connectDropTarget, backgroundColor, isOver}) => {
  return connectDropTarget(
    <div className={classNames('empty-lane-target-card card-item', {
      'is-over': isOver
    })}
      style={{backgroundColor}} />
  );
};

EmptyLaneTargetCard.propTypes = {
  boardId: PropTypes.number,
  backgroundColor: PropTypes.string,
  isOver: PropTypes.bool,
  connectDropTarget: PropTypes.func.isRequired,
  cardDraggingContext: PropTypes.object,
  onLaneCountRefresh: PropTypes.func
};

EmptyLaneTargetCard.defaultProps = {
  boardId: 0,
  backgroundColor: 'transparent',
  isOver: false,
  cardDraggingContext: null,
  onLaneCountRefresh() {}
};

export default withCardDragging(DropTarget([DragTypes.CARD], itemTarget, collectItemTarget)(EmptyLaneTargetCard));
