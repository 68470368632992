import classNames from 'classnames';

const DigestMenuTextButton = ({title, onClick, className, tooltip, dataTestId, dataTrackingId}) => {
  const attributes = {};

  if(dataTestId) {
    attributes['data-testid'] = dataTestId;
  }

  if(dataTrackingId) {
    attributes['data-tracking-id'] = dataTrackingId;
  }

  if(tooltip) {
    attributes.title = tooltip;
  }

  return (
    <div className={classNames('digest-menu-text-button', className)} {...attributes}>
      <button onClick={onClick}>
        <span>{title}</span>
      </button>
    </div>
  );
};

DigestMenuTextButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  dataTestId: PropTypes.string,
  dataTrackingId: PropTypes.string
};

DigestMenuTextButton.defaultProps = {
  title: '',
  className: '',
  onClick() {},
  tooltip: '',
  dataTestId: '',
  dataTrackingId: ''
};

export default DigestMenuTextButton;
