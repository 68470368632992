import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../_icon';
import classNames from 'classnames';

const DigestSectionToggle = ({expanded, title, onToggleExpanded, count, showCount, dataTrackingId, dataTestId}) => {
  const attributes = {};

  if(dataTrackingId) {
    attributes['data-tracking-id'] = dataTrackingId;
  }

  if(dataTestId) {
    attributes['data-testid'] = dataTestId;
  }

  return (
    <div className={classNames('digest-section-toggle-container', {expanded})} onClick={onToggleExpanded} {...attributes}>
      <span>{title}</span>{showCount ? <><span>&nbsp;</span><span className="toggle-count">{` (${count} selected)`}</span></> : null}
      <Icon icon={expanded ? 'arrow-up' : 'arrow-down'} width={18} height={18} />
    </div>
  );
};

DigestSectionToggle.propTypes = {
  expanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onToggleExpanded: PropTypes.func.isRequired,
  count: PropTypes.number,
  showCount: PropTypes.bool,
  dataTrackingId: PropTypes.string,
  dataTestId: PropTypes.string
};

DigestSectionToggle.defaultProps = {
  count: 0,
  showCount: true,
  dataTrackingId: '',
  dataTestId: ''
};

export default DigestSectionToggle;
