import CardVitalsToc from './_card_vitals_toc';
import CardVitalsSettings from './_card_vitals_settings';
import BoardCurators from './_board_curators';

import {wrapHtml} from '../modules/html_utils';
import {sortUsers} from '../modules/user_utils';
import {EXTERNAL_LINK_CLASSNAME} from '../modules/constants/editor';
import {AlertSubscriptionsProvider} from '../contexts/_alertSubscriptions';
import {connect} from 'react-redux';
import URI from 'urijs';

class CardVitals extends React.Component {

  constructor(props) {
    super(props);

    const {user} = props;
    let curatorsListExpanded = true;

    if(user) {
      const userData = user.userData || {};

      if(!_.isEmpty(userData)) {
        const defaults = _.extend({}, userData.defaults);

        // user has personal defaults set already
        if(!_.isEmpty(defaults)) {
          const {curatorsListExpanded: expanded} = defaults;

          if(expanded !== undefined) {
            curatorsListExpanded = expanded;
          }
        }
      }
    }

    this.state = {
      curatorsListExpanded
    };
  }

  static contextTypes = {
    utils: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired
  };

  static propTypes = {
    children: PropTypes.node,
    user: PropTypes.object,
    showCurators: PropTypes.bool,
    showToc: PropTypes.bool,
    editMode: PropTypes.bool,
    lanes: PropTypes.arrayOf(PropTypes.object),
    onToggleLaneVisibility: PropTypes.func,
    onToggleCuratorTools: PropTypes.func,
    onBoardUpdate: PropTypes.func,
    onErrorMessage: PropTypes.func,
    onProfileUpdated: PropTypes.func,
    onSettingsExpanded: PropTypes.func,
    showSettingsOpen: PropTypes.bool,
    rivals: PropTypes.object.isRequired,
    dispatch: PropTypes.any.isRequired
  };

  static defaultProps = {
    children: null,
    user: {},
    showCurators: true,
    showToc: true,
    editMode: false,
    lanes: [],
    onToggleLaneVisibility() {},
    onToggleCuratorTools() {},
    onBoardUpdate() {},
    onErrorMessage() {},
    onProfileUpdated() {},
    onSettingsExpanded() {},
    showSettingsOpen: false
  };

  componentDidMount() {
    console.log('CardVitals.componentDidMount: props: %o', this.props);
    this.mounted = true;

    const {curators} = this.context.utils.rival?.profile || {};

    this.curatorIds = [];

    if(curators) {
      this.curatorIds = curators;
    }

    this.loadCurators();
  }
  componentDidUpdate() {
    const {curators} = this.context.utils.rival?.profile || {};

    if(this.curatorIds !== curators) {
      this.curatorIds = curators;
      this.loadCurators();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadCurators = () => {
    const {curators: profileCurators} = this.context.utils.rival?.profile || {};

    //if there is no curator for a profile backend will return empty array [], so we don't need to fetch until the profile data is available.
    if(_.isNil(profileCurators)) {return;}

    //here curators list and admins list are being fetched separately because for consumers we don't have access to the `roles` of users.
    this.context.api.usersGet({typeFilter: 'curators'}, (curators = {}) => {
      if(!this.mounted) {
        return;
      }

      // All curators are needed to show in curator assignment component
      const allCurators = sortUsers({users: Object.values(curators || {})});
      // in case no curators are assigned to a board we show all instance Admins as curators otherwise we show assigned curators
      const hasCurators = this.curatorIds && this.curatorIds.length > 0;

      if(hasCurators) {
        const boardCurators = allCurators.filter(u => (this.curatorIds).includes(u.id));

        this.setState({boardCurators, allCurators});
      }
      else {
        this.context.api.usersGet({typeFilter: 'admins'}, (admins = {}) => {
          if(!this.mounted) {
            return;
          }

          const allAdmins =  sortUsers({users: Object.values(admins || {})});

          this.setState({boardCurators: allAdmins, allCurators});
        });
      }
    });
  };

  handleToggleBoardCuratorsExpanded = () => {
    const {user} = this.props;
    const {api} = this.context;
    const {id} = user;

    this.setState(prev => {
      const toggled = !prev.curatorsListExpanded;

      const featureFlag = [['defaults', 'curatorsListExpanded'], toggled];

      const userOptions = {
        id,
        featureFlag
      };

      api.userUpdate(userOptions)
        .then(() => console.log(`CardVitals.handleToggleBoardCuratorsExpanded.userUpdate: curatorsListExpanded - ${toggled}`))
        .catch(error => console.error('CardVitals.handleToggleBoardCuratorsExpanded: error - #%o', error));

      return {curatorsListExpanded: toggled};
    });
  };

  render() {
    const {
      children,
      user,
      showToc,
      showCurators,
      editMode,
      lanes,
      onToggleLaneVisibility,
      onToggleCuratorTools,
      onProfileUpdated,
      onErrorMessage,
      onBoardUpdate,
      onSettingsExpanded,
      showSettingsOpen,
      rivals,
      dispatch
    } = this.props;
    const {boardCurators, allCurators, curatorsListExpanded} = this.state;
    const {utils} = this.context;
    const {rival} = utils;
    const {profile, category, url: rivalUrl, clearbitStat: rivalClearbitStat} = rival || {};
    let profileId;
    let profileName = '';
    let profileUrl;
    let curatorIds;
    let profileDescription;
    let profileSite;
    let summaryTagline;
    let subTitleRegion;
    let clearbitStat;
    let companyName;
    let companyDescription;
    let socialLinkTwitter;
    let socialLinkFacebook;
    let socialLinkLinkedIn;
    let socialLinkAngellist;

    if(profile) {
      profileId = profile.id;
      profileName = profile.name;
      profileDescription = profile.description || '';
      profileUrl = rivalUrl;
      curatorIds = profile.curators;

      if(category && (category.toLowerCase() === 'company')) {
        clearbitStat = rivalClearbitStat ? rivalClearbitStat.Company : null;
      }

      if(rivalUrl) {
        profileSite = URI(rivalUrl).hostname().toLowerCase();
      }

      if(clearbitStat) {
        companyName = (
          <h4>{clearbitStat.legalName || clearbitStat.name}</h4>
        );

        if(!profileDescription) {
          if(clearbitStat.site.metaDescription && (clearbitStat.site.metaDescription !== clearbitStat.description)) {
            profileDescription = clearbitStat.site.metaDescription;
          }
          else if(clearbitStat.twitter.bio) {
            profileDescription = clearbitStat.twitter.bio;
          }

          profileDescription = URI.withinString(
            profileDescription,
            url => `<a className="${EXTERNAL_LINK_CLASSNAME}" href="${url}" target="_blank">${url}</a>`);
        }

        if(clearbitStat.description) {
          const description = URI.withinString(
            clearbitStat.description,
            url => `<a className="${EXTERNAL_LINK_CLASSNAME}" href="${url}" target="_blank">${url}</a>`);

          companyDescription = (
            <p dangerouslySetInnerHTML={wrapHtml(description)} />
          );
        }

        if(clearbitStat.site.title) {
          const taglineCleaner = (tagline = '', separators = ['|', '·', '–', '&lsaquo;', ':']) => {
            for(let i = 0; i < separators.length; i++) {
              const sep = separators[i];

              if(tagline.indexOf(sep) >= 0) {
                tagline = tagline.slice(0, tagline.indexOf(sep)).trim();

                // if we matched the site name or a single word
                if(tagline === clearbitStat.name || (tagline.indexOf(' ') === -1)) {
                  tagline = clearbitStat.site.title.slice(clearbitStat.site.title.indexOf(sep) + 1).trim();
                }

                return tagline;
              }
            }

            return tagline;
          };

          summaryTagline = taglineCleaner(clearbitStat.site.title);

          if(summaryTagline !== profileName) {
            subTitleRegion = (
              <h5 className="card-subtitle">{summaryTagline}</h5>
            );
          }
        }

        // populate social links
        if(clearbitStat.twitter && clearbitStat.twitter.handle) {
          const twitterUrl = `https://twitter.com/${clearbitStat.twitter.handle}`;

          socialLinkTwitter = (
            <a className={EXTERNAL_LINK_CLASSNAME} href={twitterUrl} target="_blank"><i className="fa fa-twitter" /></a>
          );
        }

        if(clearbitStat.facebook && clearbitStat.facebook.handle) {
          const facebookUrl = `https://www.facebook.com/${clearbitStat.facebook.handle}`;

          socialLinkFacebook = (
            <a className={EXTERNAL_LINK_CLASSNAME} href={facebookUrl} target="_blank"><i className="fa fa-facebook" /></a>
          );
        }

        if(clearbitStat.linkedin && clearbitStat.linkedin.handle) {
          const linkedinUrl = `https://www.linkedin.com/${clearbitStat.linkedin.handle}`;

          socialLinkLinkedIn = (
            <a className={EXTERNAL_LINK_CLASSNAME} href={linkedinUrl} target="_blank"><i className="fa fa-linkedin" /></a>
          );
        }

        if(clearbitStat.angellist && clearbitStat.angellist.handle) {
          const angellistUrl = `https://angel.co/${clearbitStat.angellist.handle}`;

          socialLinkAngellist = (
            <a className={EXTERNAL_LINK_CLASSNAME} href={angellistUrl} target="_blank"><i className="fa fa-angellist" /></a>
          );
        }
      }
    }

    return (
      <div className="card">
        <AlertSubscriptionsProvider rivals={rivals} dispatch={dispatch}>
          <CardVitalsSettings
            profile={profile}
            curatorIds={curatorIds}
            curators={boardCurators}
            allCurators={allCurators}
            onErrorMessage={onErrorMessage}
            onProfileUpdated={onProfileUpdated}
            onSettingsExpanded={onSettingsExpanded}
            showSettingsOpen={showSettingsOpen} />
        </AlertSubscriptionsProvider>
        {showCurators && (<BoardCurators
          curators={boardCurators}
          expanded={curatorsListExpanded}
          onToggleExpanded={this.handleToggleBoardCuratorsExpanded} />
        )}
        {showToc && (
          <CardVitalsToc
            user={user}
            profileId={profileId}
            editMode={editMode}
            lanes={lanes}
            onBoardUpdate={onBoardUpdate}
            onToggleLaneVisibility={onToggleLaneVisibility}
            onToggleCuratorTools={onToggleCuratorTools} />
        )}
        <div className="vitals-body card-block">
          {children}
          {subTitleRegion}
          <a className={`profile-site ${EXTERNAL_LINK_CLASSNAME}`} href={profileUrl} target="_blank">{profileSite}</a>
          <hr />
          <div className="vitals-body_social-links">
            {socialLinkFacebook}
            {socialLinkTwitter}
            {socialLinkLinkedIn}
            {socialLinkAngellist}
          </div>
        </div>
        <div className="vitals-body card-block">
          {companyName}
          {companyDescription}
          <p><strong dangerouslySetInnerHTML={wrapHtml(profileDescription)} /></p>
        </div>
      </div>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  dispatch
});

const mapStateToProps = ({rivals}) => ({rivals: rivals.items});

export default connect(mapStateToProps, mapDispatchToProps)(CardVitals);
