import SearchResults from './_search_results';
import Dropdown from './_dropdown';

import {truncate, titleize} from '../modules/text_utils';
import {truncateLimits} from '../modules/constants/ui';

export default class SearchComparison extends React.Component {

  static propTypes = {
    rivals: PropTypes.objectOf(PropTypes.object),
    cards1: PropTypes.array,  // matching cards first query
    cards2: PropTypes.array,  // matching cards second query
    query1: PropTypes.string, // first query
    query2: PropTypes.string,  // second query
    user: PropTypes.object,
    renderCardMetaLink: PropTypes.func
  };

  static defaultProps = {
    rivals: {},
    cards1: [],
    cards2: [],
    query1: '',
    query2: '',
    user: null,
    renderCardMetaLink() {}
  };

  state = {
    selectedQuery: null // selected query (mobile view)
  };

  /**
   * Select query in mobile view
   *
   * @param queryNumber
   * @param {Integer} queryNumber, the number of the selected query (1 or 2)
   */
  selectQuery = queryNumber => {
    this.setState({
      selectedQuery: queryNumber
    });
  };

  /**
   * Renders query filter dropdown (mobile view)
   *
   * @returns {Element} the query filter dropdown
   */
  renderMobileSidebar = () => (
    <div className="global-search-mobile-filters compare">
      <Dropdown
        label="Queries"
        displayLabel="Select..."
        placeholderMode={false}
        options={[
          {value: 1, label: `1: ${truncate(this.props.query1, {limit: truncateLimits.searchResultsMobileMenu})}`},
          {value: 2, label: `2: ${truncate(this.props.query2, {limit: truncateLimits.searchResultsMobileMenu})}`},
          {value: null, label: 'SHOW BOTH'}
        ]}
        selectedValue={'QUERY 1'}
        className="global-search-mobile-filters-button"
        onOptionClick={this.selectQuery} />
    </div>
  );

  /**
   * Renders cards for comparison split column
   * (renders 'no results' message in case of no matching cards)
   *
   * @param {Array} cards, the matching cards
   * @param {string} query, the query
   * @param {number} col, column number to render
   * @returns {Element} the matching cards rendered (half-screen)
   */
  renderSplitView = ({cards = [], query = '', col = 0}) => {
    const {user, renderCardMetaLink, rivals} = this.props;
    const titleizedQuery = titleize(query);

    return (
      <div
        className={`global-search-split-side split-side${col}`}
        key={`split-side${col}`}>
        <div className="global-search-result-text" title={titleizedQuery}>
          {!_.isEmpty(cards) ? 'Results for: ' : 'No results were found for: '}
          <span className="global-search-query-text">{titleizedQuery}</span>
        </div>
        {!_.isEmpty(cards) && (
          <SearchResults
            renderCardMetaLink={renderCardMetaLink}
            rivals={rivals}
            cards={cards}
            query={query}
            cols={1}
            breakpointColumns={{default: 1}}
            user={user} />
        )}
      </div>
    );
  };

  render() {
    const {selectedQuery} = this.state;

    return (
      <div>
        {this.renderMobileSidebar()}
        <div className="global-search-comparison-container">
          {[1, 2].map(i => (!selectedQuery || selectedQuery === i) &&
            this.renderSplitView({cards: this.props[`cards${i}`], query: this.props[`query${i}`], col: selectedQuery === i ? 0 : i})
          )}
        </div>
      </div>
    );
  }

}
