import NotificationsGroup from './_notifications_group';
import Icon from './_icon';

import {getItemTypes} from '../modules/notification_utils';
import {notificationsFilters} from '../modules/constants/notifications';
import {userCanCurate, userHasCuratedProfiles} from '../modules/roles_utils';
import {battlecardReviewedAlert, battlecardReviewedAlertCardErrorMessage} from '../modules/battlecard_utils';

import classNames from 'classnames';
import {Link} from 'react-router-dom';

class NotificationsGroupList extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object,
    rivals: PropTypes.arrayOf(PropTypes.object),
    notifications: PropTypes.arrayOf(PropTypes.object),
    filter: PropTypes.string,
    unreadNotificationsCount: PropTypes.number,
    onFilterClick: PropTypes.func,
    onNotificationDeleteClick: PropTypes.func
  };

  static defaultProps = {
    user: null,
    rivals: null,
    notifications: null,
    filter: notificationsFilters.OWN,    // options: ALL, OWN
    unreadNotificationsCount: 0,
    onFilterClick() {},
    onNotificationDeleteClick() {}
  };

  state = {
    staleBattlecards: null,
    toggledContainers: []
  };

  componentDidMount() {
    const {user} = this.props;

    if(userCanCurate({user})) {
      const {api: {battlecardsGet}} = this.context;

      battlecardsGet({stale: true, callback: staleBattlecards => this.setState({staleBattlecards})});
    }
  }

  _getStaleBattlecards = () => {
    const {staleBattlecards} = this.state;

    if(staleBattlecards && staleBattlecards.length) {
      const {user, filter} = this.props;

      if(userHasCuratedProfiles({user}) && filter === notificationsFilters.OWN) {
        return staleBattlecards.filter(b => user.curatingProfiles.indexOf(b.profile.id) >= 0);
      }
    }

    return staleBattlecards;
  };

  _getToggledContainerIndex = container => {
    if(!container) {
      // handle comments with no container set (edge case; deleted/inactive parent, emailed-in reply)
      return -1;
    }

    return this.state.toggledContainers.findIndex(c => (c.containerType === container.containerType) && (c.containerId === container.containerId));
  };

  handleToggleContainerClick = ({comment: {containers}}) => {
    const {toggledContainers} = this.state;
    const container = (containers || [])[0];
    const containerIndex = this._getToggledContainerIndex(container);

    if(containerIndex >= 0) {
      toggledContainers.splice(containerIndex, 1);
    }
    else {
      toggledContainers.push(container);
    }

    this.setState({
      toggledContainers
    }, () => {
      // DEBUG
      console.log('NotificationsGroupList.handleToggleContainerClick: updated toggled containers: %o', this.state.toggledContainers);
    });
  };

  handleReviewAllBattlecards = (event, staleBattlecards) => {
    if(event) {
      event.preventDefault();
    }

    const {api: {battlecardCreateOrUpdate}, utils: {dialog}} = this.context;

    const reviewStaleBattlecards = () => {
      const {filter} = this.props;

      if(!staleBattlecards.length || filter === notificationsFilters.ALL) {
        // shouldn't be available in "all profiles" mode
        return;
      }

      for(let i = 0; i < staleBattlecards.length; i++) {
        const battlecard = staleBattlecards[i];
        const battlecardOptions = {
          id: battlecard.id,
          profileId: battlecard.profile.id,
          markAsReviewed: true
        };

        battlecardCreateOrUpdate(battlecardOptions, updatedBattlecard => {
          console.log('NotificationsGroupList.handleReviewAllBattlecards: reviewed battlecard: %o', updatedBattlecard);
        });
      }

      this.setState({staleBattlecards: []});
    };

    const handleOnDidUpdateBattlecardCards = ({error, userUpdateError}) => {
      if(userUpdateError) {
        console.log('NotificationsGroupList.handleOnDidUpdateBattlecardCards: failed to update user default');
      }

      if(error) {
        dialog.alert(battlecardReviewedAlertCardErrorMessage);
      }
    };

    return battlecardReviewedAlert({
      battlecards: staleBattlecards,
      context: this.context,
      isBulkEdit: true,
      onMarkBattlecardsAsReviewed: reviewStaleBattlecards,
      onDidUpdateBattlecardCards: handleOnDidUpdateBattlecardCards
    });
  };

  renderStaleBattlecards = battlecards => {
    battlecards = battlecards.slice().sort((b1, b2) => {
      const b1Reviewed = b1.review ? moment(b1.review.reviewedAt).valueOf() : null;
      const b2Reviewed = b2.review ? moment(b2.review.reviewedAt).valueOf() : null;

      if((b1Reviewed === b2Reviewed) || (!b1Reviewed && !b2Reviewed)) {
        if(b1.profile.name === b2.profile.name) {
          return (b1.title || '').localeCompare(b2.title || '');
        }

        return b1.profile.name.localeCompare(b2.profile.name);
      }

      if(!b1Reviewed) {
        return -1;
      }
      else if(!b2Reviewed) {
        return 1;
      }

      return b1Reviewed > b2Reviewed ? 1 : -1;
    });

    return battlecards.map(battlecard => {
      const {id, title, review: {reviewedAt} = {}, profile} = battlecard;
      const battlecardTitle = (title && (title !== '(Untitled Battlecard)')) ? title : 'Untitled';
      const battlecardUrl = `/profile/${profile.id}/battlecard/${id}`;
      let dateReviewed;

      if(reviewedAt) {
        const reviewedMoment = moment(reviewedAt);
        const reviewedTime = moment().diff(reviewedMoment, 'seconds') < 1 ? 'just now' : reviewedMoment.fromNow();

        dateReviewed = (
          <span className="reviewed">
            Reviewed <time dateTime={reviewedAt} title={reviewedAt}>{reviewedTime}</time>
          </span>
        );
      }
      else {
        // never reviewed
        dateReviewed = (
          <span className="reviewed">Never reviewed</span>
        );
      }

      return (
        <li key={`b_${id}`}>
          <Link to={battlecardUrl} title={`View ${profile.name}'s battlecard`}>
            {profile.name}
          </Link>
          <span className="battlecard-title">({battlecardTitle})</span>
          {dateReviewed}
        </li>
      );
    });
  };

  renderNotifications = staleBattlecards => {
    const {user, filter} = this.props;
    let notification;
    let battlecardMsg;

    if(filter === notificationsFilters.OWN) {
      battlecardMsg = 'The boards you curate have outdated battlecards:';
    }
    else {
      battlecardMsg = 'Your team has outdated battlecards:';
    }

    // TODO: build out other system notifications here
    if(staleBattlecards && userCanCurate({user})) {
      // curator view
      let notificationIcon;
      let notificationMsg;
      let markAsReviewedLink;

      if(userHasCuratedProfiles({user}) && filter === notificationsFilters.OWN) {
        markAsReviewedLink = (
          <div className="notification-group_nested_links">
            <a href="#" className="notification-group_nested_view-link" onClick={e => this.handleReviewAllBattlecards(e, staleBattlecards)}>
              <span className="notification-group_nested_view-icon"><Icon icon="check_circle" /></span>Mark all as reviewed
            </a>
          </div>
        );
      }

      if(staleBattlecards.length) {
        notificationIcon = 'warning';
        notificationMsg = (
          <div>
            <p style={{display: 'inline-block'}}>
              {battlecardMsg}
            </p>
            {markAsReviewedLink}
            <ul className="u-mb-s">
              {this.renderStaleBattlecards(staleBattlecards)}
            </ul>
            <p>Click the profile names above to review or update their battlecards.</p>
          </div>
        );
      }
      else {
        notificationIcon = 'check_circle';
        notificationMsg = (
          <div>
            <p>Your battlecards are all up-to-date! Nicely done.</p>
          </div>
        );
      }

      notification = (
        <div className="notification-body">
          <div>
            <Icon icon={notificationIcon} width="44" height="44" />
            Battlecards
          </div>
          <div>
            <div className="item-meta">
              {/* TODO: once direct messages (notifications, see #1140) are implemented, make this a link to contact @kluebot */}
              <Link to="/" className="item-type">@Klue</Link> Product Team {/* &mdash; 16 mins ago */}
            </div>
            {notificationMsg}
            {/*<p><a href="" data-action="reply"><i className="fa fa-comment-o" /> reply</a> | <a href="#">Go to Slack</a></p>*/}
            {/* <a href="#" className="btn">View</a> */}
          </div>
        </div>
      );
    }

    return (
      <div className="_notifications">
        <div className="notification-block system">
          {notification}
        </div>
      </div>
    );
  };

  render() {
    const {user, notifications, filter, rivals, unreadNotificationsCount, onNotificationDeleteClick, onFilterClick} = this.props;
    const staleBattlecards = this._getStaleBattlecards();
    const notificationsLoaded = Boolean(notifications);
    const mentionsHeading = 'Comment Notifications';
    const notificationsHeading = (userCanCurate({user}) ? 'Battlecard ' : '') + 'Notifications';
    const curatorFilterClass = classNames('notification-filters_button btn btn-default', {
      'notification-filters_button--active active': filter === notificationsFilters.OWN
    });
    const allFilterClass = classNames('notification-filters_button btn btn-default', {
      'notification-filters_button--active active': filter === notificationsFilters.ALL
    });
    let notificationsList = notifications ? notifications.slice() : [];
    let notificationNodes = [];
    let filtersRegion;
    let notificationsBadge;
    let unreadBadge;

    if(staleBattlecards && staleBattlecards.length) {
      notificationsBadge = (
        <div className="notification-badge badge">{staleBattlecards.length}</div>
      );
    }

    if(unreadNotificationsCount) {
      unreadBadge = (
        <div className="notification-badge badge">{unreadNotificationsCount}</div>
      );
    }

    notificationsList = rivals && notificationsLoaded && notificationsList.map(notification => {
      const {comment: {containers} = {containers: []}} = notification;

      if(containers.length) {
        for(let i = 0; i < containers.length; i++) {
          const container = containers[i];
          const type = container.containerType.toLowerCase();
          const profileId = (type === 'profile') ? container.containerId : container.parentId;

          if(profileId) {
            notification.rival = rivals.filter(r => r.profile.id === profileId)[0] || null;
          }
        }
      }

      return notification;
    });

    if(notificationsLoaded) {
      if(notificationsList) {
        // group by rival.name string (instead of rival.id integer) in order to properly preserve original insertion order
        // reference: https://github.com/jashkenas/underscore/issues/256#issuecomment-1682015
        const groupedNotifications = _.groupBy(notificationsList, m => { return m.rival ? m.rival.name : ''; });

        for(const groupKey in groupedNotifications) {
          if(groupedNotifications.hasOwnProperty(groupKey)) {
            const group = groupedNotifications[groupKey];
            const groupName = (groupKey || 'mentions').toLowerCase();
            const notificationGroupNodes = [];
            let showSingleGroupTitle = true;

            // group by notification's container type (profile, battlecard, board, card, post) within company grouping
            const nestedGroupNotifications = _.groupBy(group, m => {
              // TODO: arbitrarily picking first available container here, could be a little smarter

              // NOTE:
              // - comment should always be set, but containers might be empty; just playing it safe below
              // - containerId is included in grouping string so individual battlecards within a profile get split apart properly
              const containers = m.comment ? m.comment.containers : null;

              return containers ? (containers.length ? `${containers[0].containerType}_${containers[0].containerId}` : 'other') : '';
            });

            for(const nestedGroupKey in nestedGroupNotifications) {
              if(nestedGroupNotifications.hasOwnProperty(nestedGroupKey)) {
                const nestedGroup = nestedGroupNotifications[nestedGroupKey];
                const firstNotification = nestedGroup[0];
                const showReplies = (nestedGroup.length > 1) || !firstNotification.dismissedAt;
                const container = firstNotification.comment.containers[0];
                const itemTypes = getItemTypes(firstNotification);
                const containerIndex = this._getToggledContainerIndex(container);

                notificationGroupNodes.push(
                  <NotificationsGroup
                    key={`mg_${groupName}_${nestedGroupKey || 'mentions'}`.toLowerCase()}
                    user={this.props.user}
                    notifications={nestedGroup}
                    groupName={groupKey}
                    rivals={this.props.rivals}
                    onToggleContainerClick={this.handleToggleContainerClick}
                    showSingleGroupTitle={showSingleGroupTitle}
                    showReplies={showReplies}
                    toggled={showReplies && (containerIndex >= 0)}
                    onNotificationDeleteClick={onNotificationDeleteClick} />
                );

                if(itemTypes.length && ['Feed Post', 'Battlecard'].indexOf(itemTypes[0].type) >= 0) {
                  // only show "feed mentions" or "battlecard" heading once
                  showSingleGroupTitle = false;
                }
              }
            }

            notificationNodes.push(
              <div key={`g_${groupName}`} className="notification-group">
                {notificationGroupNodes}
              </div>
            );
          }
        }
      }

      if(notificationsLoaded && !notificationNodes.length) {
        notificationNodes = (
          <div className="notification-group">
            <div className="notification-block message empty">
              <div className="notification-body">
                <p>No unread comment notifications found. You&apos;re all caught up!</p>
              </div>
            </div>
          </div>
        );
      }
    }

    if(userHasCuratedProfiles({user})) {
      filtersRegion = (
        <div className="notification-filters">
          <div className={curatorFilterClass} onClick={() => onFilterClick(notificationsFilters.OWN)}>
            <span className="notification-filters_icon">
              <Icon icon="view_column" width="24" height="24" />
            </span>
            <span className="notification-filters_label">My Boards</span>
          </div>
          <div className={allFilterClass} onClick={() => onFilterClick(notificationsFilters.ALL)}>
            <span className="notification-filters_icon">
              <Icon icon="view_module" width="24" height="24" />
            </span>
            <span className="notification-filters_label">All Boards</span>
          </div>
        </div>
      );
    }

    return (
      <section className="layout-wrapper">
        <div className="layout-row">
          <div className="layout-col">
            <h3 className="notification-heading">
              <div>{notificationsHeading} {notificationsBadge}</div>
            </h3>
            {this.renderNotifications(staleBattlecards)}
          </div>
          <div className="layout-col">
            <h3 className="notification-heading">
              <div>{mentionsHeading} {unreadBadge}</div>
              {filtersRegion}
            </h3>
            {notificationNodes}
          </div>
        </div>
      </section>
    );
  }

}

export default NotificationsGroupList;
