import {formulaTypes} from '../../modules/constants/formulas';

import KlueFormulaWizard from '../card_templates/dynamic_cards/template_editor/formula_wizard/_klue_formula_wizard';
import Icon from '../_icon';

import classNames from 'classnames';
import {connect} from 'react-redux';

class EditorAttachmentPlaceholder extends React.PureComponent {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    title: PropTypes.string,
    formula: PropTypes.string,
    formulaKey: PropTypes.string,
    editing: PropTypes.bool,
    isNew: PropTypes.bool,
    type: PropTypes.string,
    profileId: PropTypes.number,
    onFormulaUpdate: PropTypes.func,
    onFormulaDelete: PropTypes.func,
    onPlaceholderClick: PropTypes.func,
    onOpenPlaceholder: PropTypes.func,
    onClosePlaceholder: PropTypes.func,
    rivals: PropTypes.object
  };

  static defaultProps = {
    title: 'Dynamic Component',
    formula: '',
    formulaKey: '',
    editing: true,
    isNew: true,
    type: formulaTypes.DYNAMIC,
    profileId: null,
    rivals: {},
    onFormulaUpdate() {},
    onFormulaDelete() {},
    onPlaceholderClick() {},
    onOpenPlaceholder() {},
    onClosePlaceholder() {}
  };

  state = {
    company: '',
    editing: true,
    isNew: true,
    isLoading: Boolean(!this.context.utils.rival),
    currentFormula: ''
  };

  componentDidMount() {
    console.log('EditorAttachmentPlaceholder.componentDidMount: props: %o', this.props);

    const {editing, isNew, onOpenPlaceholder} = this.props;

    this.setState({editing, isNew});

    this.state.isLoading && this.refreshRivals();

    editing && onOpenPlaceholder();
  }

  refreshRivals = () => {
    const {utils} = this.context;
    const {profileId, rivals} = this.props;

    if(profileId && Object.keys(rivals).length && rivals[profileId]) {
      return utils.rivalSet(rivals[profileId]);
    }

    utils.requestRival({profileId: this.props.profileId})
      .then(rival => {
        utils.rivalSet(rival);

        this.setState({isLoading: false});
      })
      .catch(error => {
        console.error('EditorAttachmentPlaceholder: error: %o', error);
        this.setState({isLoading: false});
      });
  };

  handleToggleEditing = () => this.setState(prevState => ({editing: !prevState.editing}));

  handleUpdateClick = () => {
    this.setState({isNew: false}, () => {
      this.props.onFormulaUpdate(this.state.currentFormula);
      this.handleToggleEditing();
    });
  };

  handleDeleteClick = () => {
    this.props.onFormulaDelete();
    this.handleToggleEditing();
  };

  handleSetCurrentFormula = currentFormula => this.setState({currentFormula});

  handlePlaceholderClick = () => {
    const {onPlaceholderClick, onClosePlaceholder} = this.props;

    onPlaceholderClick();

    if(!this.state.editing) {
      return this.handleToggleEditing();
    }

    onClosePlaceholder();
  };

  render() {
    const {title, formula, formulaKey, profileId, type} = this.props;
    const {editing, isNew, isLoading} = this.state;
    const attachmentBaseClass = 'attachment-component';
    const attachmentClass = classNames(attachmentBaseClass, {
      [`${attachmentBaseClass}--editing`]: editing
    });
    const extraProps = {};

    extraProps[isNew ? 'onCancelClick' : 'onDeleteClick'] = this.handleDeleteClick;

    return (
      <div data-test-id="editor-attachment-placeholder" className={attachmentClass} onClick={this.handlePlaceholderClick} contentEditable={false}>
        <div className={`${attachmentBaseClass}_title`}>{title}</div>
        <div className={`${attachmentBaseClass}_form`}>
          {isLoading
            ? (<div className="dynamic-data-loading">Loading data...</div>)
            : (<KlueFormulaWizard
                formula={formula}
                formulaKey={formulaKey}
                type={type}
                isNewBlock={isNew}
                profileId={profileId}
                onUpdateClick={this.handleUpdateClick}
                onSetCurrentFormula={this.handleSetCurrentFormula}
                {...extraProps} />)}
        </div>
        {!editing && (
          <Icon icon="settings" width="24" height="24" className="icon-settings" />
        )}
        {editing && (
          <Icon icon="arrow-down" width="24" height="24" className="icon-collapse" onClick={this.handleUpdateClick} />
        )}
      </div>
    );
  }

}

const mapStateToProps = ({rivals}) => ({rivals: rivals.items});

export default connect(mapStateToProps)(EditorAttachmentPlaceholder);
