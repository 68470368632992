import className from 'classnames';
import {useState, useRef} from 'react';

import TagError from './_tag_error';
import Spinner from '../_spinner';
import {tagCreate} from '../../modules/api/tags';

export const testIds = {
  addTagButton: 'add-tag-button',
  inputText: 'add-tag-input-text',
  tagError: 'tag-error',
  tagSpinner: 'tag-spinner'
};

const CompanySettingsAddTag = ({onAddTag, onTagValueDidChange}) => {
  const [newTagValue, setNewTagValue] = useState('');
  const [error, setError] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const inputRef = useRef();

  const handleNewTagValueDidChange = e => {
    const newValue = e?.target?.value || '';

    setNewTagValue(newValue);
    setError(null);
    onTagValueDidChange(newValue);
  };

  const handleAddTag = async () => {
    try {
      setIsCreating(true);
      setError(null);

      const {tag} = await tagCreate({name: newTagValue});

      setNewTagValue('');
      inputRef?.current?.focus();
      onAddTag(tag);
    }
    catch(err) {
      setError({...(err?.error || {}), conflictingName: newTagValue});
    }
    finally{
      setIsCreating(false);
    }
  };

  const disableAddButton = Boolean(!newTagValue || !newTagValue.trim().length || isCreating);

  const handleKeyUp = event => {
    event.preventDefault();
    event.stopPropagation();

    const {key} = event;

    if(key === 'Enter' && !disableAddButton) {
      handleAddTag(event);
    }
  };

  return (
    <>
      <div className="tags_add-container" onKeyUp={handleKeyUp}>
        <input
          data-testid={testIds.inputText}
          ref={inputRef}
          type="text"
          className="form-control"
          placeholder="eg. Product"
          value={newTagValue}
          onChange={handleNewTagValueDidChange} />
        <button
          disabled={disableAddButton}
          data-testid={testIds.addTagButton}
          className={className('button button--medium', {'button--disabled': disableAddButton, 'button--with-spinner': isCreating})}
          onClick={handleAddTag}><span>Add Tag</span>{isCreating && <Spinner />}</button>
      </div>
      {error && <TagError data-testid={testIds.tagError} error={error} showLinkToNameConflicts={true} />}
    </>
  );
};

CompanySettingsAddTag.propTypes = {
  onAddTag: PropTypes.func,
  onTagValueDidChange: PropTypes.func
};

CompanySettingsAddTag.defaultProps = {
  onAddTag() {},
  onTagValueDidChange() {}
};

export default CompanySettingsAddTag;
