import React from 'react';
import PropTypes from 'prop-types';

import CompareTimeseriesBuilder from './_compare_timeseries_builder';

const CompareEmployeeCountBuilder = ({onChange}) => {
  CompareEmployeeCountBuilder.propTypes = {
    onChange: PropTypes.func.isRequired
  };

  CompareEmployeeCountBuilder.defaultProps = {
    onChange() {}
  };

  return (<CompareTimeseriesBuilder onChange={onChange} funcName="EMPLOYEE_COUNT" />);
};

export default CompareEmployeeCountBuilder;
