import {Field} from 'react-final-form';
import className from 'classnames';

const FormTextField = ({name, defaultValue, displayName, validate, parse, placeholder, isFocused}) => {
  const labelText = error => (error
    ? error
    : displayName);

  return (
    <Field
      name={name}
      defaultValue={defaultValue}
      validate={validate}
      parse={parse}>
      {({input, meta}) => (
        <div className="form_text_field">
          <label className={className('form_text_field-label', {'form_text_field-label--error': meta.error, focus: isFocused})}
            data-test-id="form_text_field-label">{labelText(meta.error)}</label>
          <input className={className('form_text_field-text', {'form_text_field-text--error': meta.error})}
            {...input}
            autoComplete="off"
            type="text"
            placeholder={placeholder ? placeholder : `Enter ${displayName}...`} />

        </div>
      )}
    </Field>
  );
};

FormTextField.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  displayName: PropTypes.string,
  validate: PropTypes.func,
  parse: PropTypes.func,
  placeholder: PropTypes.string,
  isFocused: PropTypes.bool
};

FormTextField.defaultProps = {
  name: '',
  defaultValue: undefined,
  displayName: '',
  validate() {},
  parse: undefined,
  placeholder: undefined,
  isFocused: false
};

export default FormTextField;
