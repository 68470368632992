class DisplayNotes extends React.Component {

  static propTypes = {
    notes: PropTypes.string,
    title: PropTypes.string
  };

  static defaultProps = {
    notes: '',
    title: ''
  };

  state = {
    expanded: false
  };

  handleClick = event => {
    if(event) {
      event.preventDefault();
    }

    this.setState(prevState => ({expanded: !prevState.expanded}));
  };

  render() {
    const {notes, title} = this.props;
    const {expanded} = this.state;

    if(!notes) {
      return null;
    }

    const newNotes = notes.replace(/<br>/g, '\r\n'); // Support for the SFDC BR() formula function. Need to convert to CR/LF

    return (
      <div className="sfdc-notes">
        <h5>{title || 'Win/Loss Notes'}</h5>
        <div className="sfdc-notes-body">
          <div>{expanded ? newNotes : newNotes.substring(0, 150)}</div>
          {(newNotes.length > 150) && (
            <a href="#" className="expand sfdc-button" onClick={this.handleClick}>{expanded ? 'Less' : 'More'}</a>
          )}
        </div>
      </div>
    );
  }

}

export default DisplayNotes;
