import {redirectToV2} from '../modules/route_utils';

const BattlecardTryComposer = ({v2Host}) => {
  const handleTryComposer = () => {
    redirectToV2({v2Host, v2Path: '/composer'});
  };

  return (
    <div className="battlecard-try-composer">
      <h3>Build via Klue AI</h3>
      <p>Generate a Basic Battlecard in minutes</p>
      <button className="button button--primary klue-ui" onClick={handleTryComposer}>Try Compete Composer</button>
    </div>
  );
};

BattlecardTryComposer.propTypes = {
  v2Host: PropTypes.string.isRequired
};

export default BattlecardTryComposer;
