import {isChrome, isChromiumEdge, isTestBrowser} from './browser_utils';
import {parseDomain} from './url_utils';

const _isKlueMessage = message => (message && message.type && message.type.startsWith('klue'));

export const handleMessageFromExtension = (onMessage = () => {}) => {
  window.addEventListener('message', (event = {}) => {
    const {data: message} = event;

    if(!_isKlueMessage(message)) {
      return false;
    }

    console.log('ExtUtils.handleMessageFromExtension: got message: %o', message);

    onMessage(message, event);
  });
};

// NOTE: chrome web store (+ edge addons catalog) extension ids are 32 characters (alpha) long
// see: https://support.google.com/chrome/a/answer/7532015?hl=en#getid
export const isValidExtensionId = (id = '') => /^[a-zA-Z]{32}$/.test(id);

export const sendMessageToExtensionContent = message => {
  if(!_isKlueMessage(message)) {
    return false;
  }

  window.parent.postMessage(message, '*');
};

export const sendMessageToExtensionBackground = message => {
  const {ext: {ids = {}}} = window.Klue || {};
  const hostBrowser = [
    {edge: isChromiumEdge()},
    {chrome: isChrome()},
    {test: isTestBrowser()}
  ].reduce((acc, current) => {
    const [browserName, isHost] = Object.entries(current).shift();

    return isHost ? browserName : acc;
  }, '');
  const id = ids[hostBrowser];

  window.browser = window.browser || window.chrome || window.msBrowser || null;

  const {browser} = window;
  const {runtime: {sendMessage} = {}} = browser || {};

  if(!sendMessage || !hostBrowser) {
    // Unsupported browser
    return Promise.reject();
  }

  if(!_isKlueMessage(message)) {
    return Promise.reject('ExtUtils.sendMessageToExtensionBackground: no message provided');
  }

  if(!isValidExtensionId(id)) {
    return Promise.reject('ExtUtils.sendMessageToExtensionBackground: invalid or missing extension ID');
  }

  return Promise.resolve(sendMessage(id, message));
};

export const closeExtension = event => {
  if(event) {
    event.preventDefault();
  }

  sendMessageToExtensionContent({type: 'klue:ext:close'});
};

// resizeTo - 'max'|Number optional fixed size
export const resizeExtension = resizeTo => {
  const extensionBox = document.getElementById('klue-extension');

  if(extensionBox) {
    let height = 0;
    let width;

    if(resizeTo) {
      height = resizeTo.height;
      width = resizeTo.width;
    }
    else {
      extensionBox.childNodes.forEach(el => {
        if(el && el.nodeType === Node.ELEMENT_NODE) {
          height += Math.max(el.getBoundingClientRect().height, el.scrollHeight);
        }
      });
    }

    const {lastValue} = resizeExtension;
    const {height: lastHeight, width: lastWidth} = lastValue || {};

    // Given there are several components calling `resizeExtension`...
    // avoid SPAMming the extension with resize messages
    if(!lastValue || (lastHeight !== height) || (lastWidth !== width)) {
      sendMessageToExtensionContent({
        type: 'klue:ext:content:resize',
        body: {height, width}
      });

      resizeExtension.lastValue = {height, width};
    }
  }
};

resizeExtension.lastValue = null;

const stagingString = 'staging.klue';
const productionString = 'app.klue';
const localString = 'klue.d';

export const extensionOpenedInKlueApp = url => (url
    && (Boolean(url.includes(stagingString))
    || Boolean(url.includes(productionString))
    || Boolean(url.includes(localString))));

export const SRC_PARAM = 'extension';

export const klueIsInExtension = (src, win = window) => {
  const currentURL = new URL(win?.location.href);
  const redirectURL = new URL(`${currentURL?.origin}${currentURL?.searchParams.get('redirectTo') || '/'}`);
  const currentSrc = src ?? currentURL?.searchParams.get('src') ?? redirectURL?.searchParams.get('src');

  return currentSrc === SRC_PARAM;
};

export const utmSuffix = campaign => {
  const source = isChrome() ? 'Chrome' : (isChromiumEdge() ? 'Edge' : 'Other');

  return `utm_medium=Extension&utm_source=${source}&utm_campaign=${campaign}`;
};
