import TextareaAutoSize from './_textarea_auto_size';
import AttachmentPreview from './_attachment_preview';

import {putCommentToLS, deleteCommentFromLS, getCommentFromLS} from '../modules/local_storage_utils';
import {isValidId} from '../modules/utils';
import {wrapHtml} from '../modules/html_utils';
import {removeHttp} from '../modules/url_utils';
import {DragTypes} from '../modules/constants/dnd';
import {normalizeAttachment} from '../modules/attachment_utils';

import moment from 'moment';
import classNames from 'classnames';
import {DragSource} from 'react-dnd';
import {Link} from 'react-router-dom';

const itemSource = {
  beginDrag(props) {
    return {
      comment: props.comment
    };
  },

  endDrag(props, monitor) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();

    if(dropResult) {
      console.log('ScratchpadItem.itemSource.endDrag: dropped %o onto %o', item, dropResult);
    }
  }
};

function collectItemSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

class ScratchpadItem extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    comment: PropTypes.object,
    isNew: PropTypes.bool,
    onUpdateComment: PropTypes.func,
    onDismissComment: PropTypes.func,
    onGetCommentSourceLink: PropTypes.func,

    // react-dnd
    isDragging: PropTypes.bool.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired
  };

  static defaultProps = {
    comment: null,
    isNew: false,
    onUpdateComment() {},
    onDismissComment() {},
    onGetCommentSourceLink() {}
  };

  state = {
    editMode: false,
    commentText: this.props.comment.body
  };

  constructor(props) {
    super(props);

    this.containerType = 'Board';

    if(this.props.comment.containers) {
      const boardContainers = this.props.comment.containers.filter(container => container.containerType === this.containerType);

      if(boardContainers.length === 1) {
        this.containerId = boardContainers[0].parentId;
      }
    }
  }

  componentDidMount() {
    console.log('ScratchpadItem.componentDidMount: props: %o', this.props);

    this.possiblyRecoverUnsavedContent();
  }

  possiblyRecoverUnsavedContent = () => {
    const {id} = this.props.comment;

    if(isValidId(id) && this.containerId) {
      const data = {containerType: this.containerType.toLowerCase(), id, parentId: this.containerId};
      const recoveredComment = getCommentFromLS(data);

      if(recoveredComment) {
        this.toggleEditMode(null, recoveredComment.text);
      }
    }
  };

  removeCommentBitsFromStorage = () => {
    const {id} = this.props.comment;

    if(isValidId(id) && this.containerId) {
      const data = {containerType: this.containerType.toLowerCase(), id, parentId: this.containerId};

      deleteCommentFromLS(data);
    }
  };

  handleDismissComment = event => {
    if(event) {
      event.preventDefault();
    }

    this.removeCommentBitsFromStorage();
    this.props.onDismissComment(this.props.comment);
  };

  handleUpdateComment = event => {
    if(event) {
      event.preventDefault();
    }

    const {comment: {id}, onUpdateComment} = this.props;

    if(isValidId(id) && this.state.commentText.length) {
      // update related comment
      const commentObj = {
        id,
        body: this.state.commentText.trim()
      };

      onUpdateComment(commentObj, this.removeCommentBitsFromStorage);

      this.toggleEditMode();

      klueMediator.publish(`klue:comment:textarea:${id}:reset`);
    }
    else {
      // failed submit, refocus on textarea
      const textarea = ReactDOM.findDOMNode(this.textarea);

      textarea && textarea.focus();
    }
  };

  handleKeyPress = event => {
    event = event || window.event;

    if((event.which === 13) && !event.shiftKey) {
      this.handleUpdateComment();
    }
  };

  handleKeyDown = event => {
    event = event || window.event;

    if((event.which === 27) && this.state.editMode) {
      this.toggleEditMode();
    }
  };

  handleCommentTextChange = event => {
    if(event) {
      this.setState({
        commentText: event.target.value
      }, () => {
        if(this.containerId) {
          this.saveDraftTextAttachments();
        }
      });
    }
  };

  saveDraftTextAttachments = () => {
    const data = {
      containerType: this.containerType.toLowerCase(),
      id: this.props.comment.id,
      parentId: this.containerId,
      val1: this.state.commentText
    };

    // debounce
    if(this.timeOutHandle) {
      window.clearTimeout(this.timeOutHandle);
    }

    this.timeOutHandle = window.setTimeout(() => {
      putCommentToLS(data);
    }, 500);
  };

  handleCommentCancel = () => {
    this.removeCommentBitsFromStorage();
    this.toggleEditMode();
  };

  toggleEditMode = (event, updatedText) => {
    if(event) {
      event.preventDefault();
    }

    this.setState(prevState => ({
      editMode: !prevState.editMode,
      commentText: _.isEmpty(updatedText) ? prevState.commentText : updatedText
    }), () => {
      const textarea = ReactDOM.findDOMNode(this.textarea);
      const {editMode} = this.state;

      // entering edit mode
      (textarea && editMode) && textarea.focus();
    });
  };

  renderCommentBody = () => {
    const {editMode, commentText} = this.state;
    const {comment: {id: commentId, attachments = [], bodyHtml, bodyPlain, userName} = {}} = this.props;
    const hasAttachment = !_.isEmpty(attachments);
    const scratchpadItemClass = classNames('scratchpad-item_wrap', {
      'scratchpad-item_text-wrap': hasAttachment
    });
    let commentBodyRegion;

    if(editMode) {
      commentBodyRegion = (
        <form onKeyPress={this.handleKeyPress} onKeyDown={this.handleKeyDown} data-cy="scratchpadItemForm">
          <div className={scratchpadItemClass}>
            <div className="scratchpad-item_text-area">
              <TextareaAutoSize
                ref={el => (this.textarea = el)}
                commentId={commentId}
                commentText={commentText}
                canUseForm={true}
                onChange={this.handleCommentTextChange} />
              <div className="edit-buttons">
                <span className="pull-left">
                  <label>esc to</label>
                  <button type="button" className="btn btn-link btn-cancel" onClick={this.handleCommentCancel} title="Cancel" tabIndex="11">Cancel</button>
                </span>
                <span className="pull-right">
                  <label>enter to</label>
                  <button type="submit" className="btn btn-link" onClick={this.handleUpdateComment} tabIndex="10">Save</button>
                </span>
              </div>
            </div>
            {hasAttachment &&
            <div className="scratchpad-item_body">
              <span className="scratchpad-item_image">
                <AttachmentPreview attachment={normalizeAttachment(attachments[0])} />
              </span>
            </div>
            }
          </div>
        </form>
      );
    }
    else {
      commentBodyRegion = (
        <div className="scratchpad-item_body">
          {!_.isEmpty(attachments) && (
            <span className="scratchpad-item_image">
              <AttachmentPreview attachment={normalizeAttachment(attachments[0])} />
            </span>
          )}
          <div className={scratchpadItemClass}>
            <div dangerouslySetInnerHTML={wrapHtml((bodyHtml || bodyPlain), {processAtMentions: true, currentUsername: userName})} />
          </div>
        </div>
      );
    }

    return commentBodyRegion;
  };

  renderTargetLink = comment => {
    const {pageUrl, pageTitle, sourceData = {}} = comment || {};
    const {name: sourceName = ''} = sourceData || {};
    const targetDomain = pageUrl ? removeHttp(pageUrl) : '';
    const className = classNames('source', {
      [`${sourceName && sourceName.toLowerCase().replace(/\s/g, '_')}-source-link`]: sourceName
    });

    if(targetDomain) {
      return (
        <a href={pageUrl} className={className} target="klue_window" title={pageTitle || ''}>{targetDomain}</a>
      );
    }

    const {link, label} = this.props.onGetCommentSourceLink(comment);

    return link
      ? (<Link to={link} className={className} title={label}>{label}</Link>)
      : label
        ? (<span className={className} title={label}>{label}</span>)
        : null;
  };

  renderCommentMeta = () => {
    const {comment} = this.props;
    const targetLink = this.renderTargetLink(comment);
    const itemLabel = comment.highlight ? 'Highlight' : 'Comment';
    const separator = '\u2013';

    return (
      <div className="scratchpad-item_meta">
        {targetLink} {targetLink && separator} <time dateTime={comment.createdAt} title={new Date(comment.createdAt)}>
          {moment(comment.createdAt).format('ll')}</time> {separator} {itemLabel} posted by
        {' '}<Link to={`/users/${comment.userId}`} className="comment-creator" title={comment.userName}>{comment.prettyName}</Link>
      </div>
    );
  };

  renderCommentFooter = () => {
    const {connectDragSource, onDismissComment} = this.props;
    let commentDismissLink;

    if(onDismissComment) {
      commentDismissLink = (
        <a href="#" onClick={this.handleDismissComment} title="Remove"><i className="fa fa-trash" /></a>
      );
    }

    return (
      <div className="scratchpad-item_footer">
        {commentDismissLink}
        <div className="scratchpad-item_pull-right">
          <span>
            <a href="#" onClick={this.toggleEditMode} title="Edit" data-cy="editScratchPadNote"><i className="fa fa-pencil" /></a>
          </span>
          {connectDragSource(
            <i className="fa fa-arrows" />,
            {dropEffect: 'copy'}
          )}
        </div>
      </div>
    );
  };

  render() {
    const {comment, isDragging, connectDragPreview, isNew}  = this.props;
    const scratchpadItemClasses = classNames({
      'scratchpad-item': true,
      'scratchpad-item--comment': !comment.highlight,
      'scratchpad-item--editing': this.state.editMode,
      'scratchpad-item--animate': isNew,
      'scratchpad-item--dragging': isDragging
    });

    return (
      <div className={scratchpadItemClasses}>
        {connectDragPreview(
          <div className="scratchpad-item_inner">
            {this.renderCommentBody()}
            {this.renderCommentMeta()}
          </div>
        )}
        {this.renderCommentFooter()}
      </div>
    );
  }

}

export default DragSource(DragTypes.SCRATCHPAD_ITEM, itemSource, collectItemSource)(ScratchpadItem);
