import {useRef, useEffect, useState} from 'react';
import Dropdown from '../_dropdown';
import Icon from '../_icon';
import ToggleSwitch from '../_toggle_switch';

import {
  defaultDigestFrequency,
  defaultDigestDay,
  defaultDigestHour,
  reviewFrequencyOptions,
  reviewHourOptions,
  reviewDayOptions,
  defaultDigestSendOnceHours,
  DigestSettingsLabels
} from '../../modules/constants/digest';
import DigestScheduleInfo from './_digest_frequency_info';

const DigestFrequencySettings = ({
  reviewFrequency,
  reviewDay,
  reviewHour,
  sendTime,
  sendOnceHours,
  deleteAfterSend,
  showDeleteAfterSend,
  onSetSendOnceHours,
  onSetReviewFrequency,
  onSetReviewDay,
  onSetReviewHour,
  onResetToDefaults,
  onSetDeleteAfterSend,
  alwaysShowInfo
}) => {
  const onceInputRef = useRef(null);
  const [showInfo, setShowInfo] = useState(alwaysShowInfo);
  const [hours, setHours] = useState(sendOnceHours || defaultDigestSendOnceHours);
  const toggleShowInfo = () => setShowInfo(!showInfo);

  useEffect(() => {
    setHours(sendOnceHours || defaultDigestSendOnceHours);
  }, [sendOnceHours]);

  const handleOnChange = e => {
    setHours(e?.target?.value.match(/[0-9]*/) || '');
  };

  const handleSave = () => {
    onSetSendOnceHours(hours);
  };

  const handleSetReviewFrequency = value => {
    onSetReviewFrequency(value, () => {
      if(value === 'once') {
        onceInputRef?.current?.select();
      }
    });
  };

  const handleSetDeleteAfterSend = () => onSetDeleteAfterSend(!deleteAfterSend);

  const hideDayOptions = reviewFrequency === 'monthly' ||
  reviewFrequency === 'daily' ||
  reviewFrequency === 'once' ||
  reviewFrequency === 'never';
  const hideHourOptions = reviewFrequency === 'once' ||
  reviewFrequency === 'never';
  let hourInputRegion = null;

  if(reviewFrequency === 'once') {
    hourInputRegion = (<div className="hours-input">
      <h6>Hours from Now</h6>
      <div>
        <div className="digest-send-once-group">
          <input
            ref={onceInputRef}
            type="text"
            className="form-control"
            value={hours}
            onChange={handleOnChange} />
          <input className="faux-form_submit" type="submit" />
        </div>
        <div className="button" onClick={handleSave}>
          Save
        </div>
      </div>
    </div>);
  }

  return (
    <div>
      <div className="digest-setting-header-with-info" data-tracking-id="digest-setting-delivery-schedule-label">
        <h4 className="u-pt-m"><strong>{DigestSettingsLabels.schedule}</strong></h4>
        {!alwaysShowInfo && <Icon icon="info-outline" width={18} height={18} onClick={toggleShowInfo} />}
      </div>
      {showInfo && <DigestScheduleInfo />}
      <div className="settings-review_frequency-options u-mb-m">
        <div style={{margin: 0}}>
          <div className="ui-dropdown-panel ui-dropdown-panel--left digest-schedule u-pt-xxs" data-testid="company-settings-digest-frequency">
            <div data-testid="digest-schedule_frequency">
              <h6>Frequency</h6>
              <Dropdown
                label=""
                dataTestId="digest-schedule_frequency-dropdown"
                displayLabel="Select..."
                options={reviewFrequencyOptions}
                selectedValue={reviewFrequency}
                keyPrefix="group"
                className="btn secondary"
                containerClass="ui-dropdown--left"
                alignMenuLeft={true}
                onOptionClick={handleSetReviewFrequency} />
            </div>
            {!hideDayOptions
              ? <div>
                <h6>Day</h6>
                <Dropdown
                  label=""
                  dataTestId="digest-schedule_day-dropdown"
                  displayLabel="Select..."
                  options={reviewDayOptions}
                  selectedValue={reviewDay}
                  keyPrefix="group"
                  className="btn secondary"
                  containerClass="ui-dropdown--left"
                  alignMenuLeft={true}
                  onOptionClick={onSetReviewDay} />
              </div>
              : null}
            {!hideHourOptions
              ? <div>
                <h6>Hour</h6>
                <Dropdown
                  label=""
                  dataTestId="digest-schedule_hour-dropdown"
                  displayLabel="Select..."
                  options={reviewHourOptions}
                  selectedValue={reviewHour}
                  keyPrefix="group"
                  className="btn secondary"
                  containerClass="ui-dropdown--left"
                  alignMenuLeft={true}
                  onOptionClick={onSetReviewHour} />
              </div>
              : null}
            {hourInputRegion}
            <div className="digest-schedule-reset" title="Reset to defaults" onClick={onResetToDefaults}>
              <Icon icon="refresh" />
            </div>
          </div>
          {sendTime
            ? <div className="digest-schedule-time">
              <span className="head">Next scheduled send:</span>
              <span className="time">
                <time dateTime={sendTime.toDate()}>{`${sendTime.format('LLLL')} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`}</time>
              </span>
            </div>
            : <div className="digest-schedule-time emphasized">
              <span className="head">Scheduled sending disabled, hit <strong>Send Now</strong> in Digest Editor when ready to send.</span>
            </div>
          }
        </div>
      </div>
      {showDeleteAfterSend && <div className="settings-delete-after-send">
        <span>Discontinue this Digest after next send?</span>
        <ToggleSwitch
          label="Remove after next send"
          checked={deleteAfterSend}
          onChange={handleSetDeleteAfterSend}
          className="tags-visibility-toggle--button" />
        </div>}    </div>
  );
};

DigestFrequencySettings.propTypes = {
  reviewFrequency: PropTypes.string,
  reviewDay: PropTypes.string,
  reviewHour: PropTypes.number,
  sendTime: PropTypes.object,
  sendOnceHours: PropTypes.any,
  deleteAfterSend: PropTypes.bool,
  showDeleteAfterSend: PropTypes.bool,
  onSetSendOnceHours: PropTypes.func,
  onSetReviewFrequency: PropTypes.func,
  onSetReviewDay: PropTypes.func,
  onSetReviewHour: PropTypes.func,
  onResetToDefaults: PropTypes.func,
  onSetDeleteAfterSend: PropTypes.func,
  alwaysShowInfo: PropTypes.bool
};

DigestFrequencySettings.defaultProps = {
  reviewFrequency: defaultDigestFrequency,
  reviewDay: defaultDigestDay,
  reviewHour: defaultDigestHour,
  sendTime: null,
  sendOnceHours: null,
  deleteAfterSend: false,
  showDeleteAfterSend: false,
  onSetSendOnceHours() {},
  onSetReviewFrequency() {},
  onSetReviewDay() {},
  onSetReviewHour() {},
  onResetToDefaults() {},
  onSetDeleteAfterSend() {},
  alwaysShowInfo: false
};

export default DigestFrequencySettings;
