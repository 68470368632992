/* eslint-disable max-len */
const DigestArticlesInfo = ({improvedDigestSuggestionsEnabled}) => {
  if(improvedDigestSuggestionsEnabled) {
    return (
      <div className="help-block u-mb-xs">
        Enable this feature, and Klue will help you build your digest. The filters below can be used to control what type of articles should be suggested, up to a maximum of ten per digest.
        <br />
      </div>);
  }

  return (
    <div className="help-block u-mb-xs">
      Set the maximum number of articles to be automatically included per company in your <strong>Intel Digest</strong>.<br />
      <br />
      Select <strong>None</strong> to disable automatic inclusion of articles.<br />
    </div>
  );
};

DigestArticlesInfo.propTypes = {
  improvedDigestSuggestionsEnabled: PropTypes.bool
};

DigestArticlesInfo.defaultProps = {
  improvedDigestSuggestionsEnabled: false
};

export default DigestArticlesInfo;
