import Icon from './_icon';
import classNames from 'classnames';

const DigestMenuIconButton = ({icon, title, onClick, className, dataTestId, tooltip, dataTrackingId}) => {
  const attributes = {};

  if(dataTestId) {
    attributes['data-testid'] = dataTestId;
  }

  if(dataTrackingId) {
    attributes['data-tracking-id'] = dataTrackingId;
  }

  return (
    <div className={classNames('digest-menu-icon-button', className)} onClick={onClick} title={tooltip} {...attributes}>
      <Icon icon={icon} width={20} height={20} />
      <span>{title}</span>
    </div>
  );
};

DigestMenuIconButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  dataTrackingId: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func
};

DigestMenuIconButton.defaultProps = {
  icon: '',
  title: '',
  className: '',
  dataTestId: '',
  dataTrackingId: '',
  tooltip: '',
  onClick() {}
};

export default DigestMenuIconButton;
