import {fetch, post, update, destroy} from '../api_utils';
import {serializeQueryString} from '../utils';

const DEFAULT_LIMIT = 1000;
const DEFAULT_FILTER = 'active';

const UNKNOWN_TAG_ERROR_MESSAGE = 'Whoops 😬! Something went wrong. Please try again.';

export const TAG_NAME_NOT_UNIQUE = 'not_unique';

export const formattedTagError = (errorResult, conflictingName) => {
  const data = errorResult?.response?.data;

  if(!data) {
    return {message: UNKNOWN_TAG_ERROR_MESSAGE};
  }

  // The backend returns error information in the following format:
  /*
  {
    "error": "Validation failed: Name is already taken by KlueTag#8='Art' or its synonyms",
    "code": [
        "klue_tag.invalid_record",
        {
            "name": [
                {
                    "error": "invalid",
                    "type": "not_unique",
                    "id": 8,
                    "name": "Art"
                }
            ]
        }
    ]
  }
  */
  // A generic backend error message is provided in the "error" field. We try to avoid
  // displaying that unless we don't have specific error details to work with. Details, if
  // they exist, are provided in 2nd element of the "code" array. The details element is
  // a hash of attribute names pointing to an array of error descriptions. So no details,
  // they get the generic, somewhat unreadable, backend error message. If details are provided,
  // here we parse them to format are reasonable message... otherwise we fallback to the generic
  // message.

  const {code} = data;

  if(Array.isArray(code) && code.length > 1) {
    const attributeHash = code[1];
    const {name} = attributeHash;

    if(Array.isArray(name) && name.length) { // indicates we have an error related to the name
      const {name: tagName, type, id} = name[0]; /// there may be multiple errors but the 1st has priority

      if(type === TAG_NAME_NOT_UNIQUE) {
        return {
          message: 'The name you provided is already in use.',
          tagName,
          id,
          type,
          conflictingName
        };
      }
    }
  }

  return {message: UNKNOWN_TAG_ERROR_MESSAGE};
};

export const tagsGet = (params = {}) => new Promise((resolve, reject) => {
  if(params.limit === undefined) {
    params.limit = DEFAULT_LIMIT;
  }

  if(params.filter === undefined) {
    params.filter = DEFAULT_FILTER;
  }

  const urlParams = serializeQueryString(params);

  fetch(`/api/tags.json?${urlParams}`)
    .then(({data: {items: tags}}) => {
      resolve({tags});
    })
    .catch(error => {
      reject({error: formattedTagError(error)});
    });
});

export const tagCreate = tag => {
  const {name} = tag;

  if(!name) {
    return Promise.reject({error: {message: 'Invalid tag name'}});
  }

  return new Promise((resolve, reject) => {
    post('/api/tags.json', {name})
      .then(({data: tag}) => resolve({tag}))
      .catch(error => {
        reject({error: formattedTagError(error, name)});
      });
  });
};

export const tagUpdate = ({tag}) => {
  return new Promise((resolve, reject) => {
    update(`/api/tags/${tag.id}.json`, tag)
      .then(({data: updatedTag}) => resolve({tag: updatedTag}))
      .catch(error => {
        const name = tag.addSynonym ?? tag.name;

        reject({error: formattedTagError(error, name)});
      });
  });
};

export const tagDelete = ({tag}) => {
  const {id} = tag;

  if(!id) {
    return Promise.reject({error: {message: 'Invalid tag identifier'}});
  }

  return new Promise((resolve, reject) => {
    destroy(`/api/tags/${id}.json`, {id})
      .then(() => resolve())
      .catch(error => {
        reject({error: formattedTagError(error)});
      });
  });
};
