import DashboardCard from './_dashboard_card';
import CompanyLogo from './_company_logo';

import {filterRivals} from '../modules/rival_utils';

const DashboardEditRivals = props => {
  const {
    user, rivalsLoaded, companyFilter, activeRivalId, activeGroupId,
    checkIsInActiveGroup, onFilterChange, onFilterCancel, onCompanyHover, onCompanyClick
  } = props;
  let {rivals} = props;
  let sidebarHeadingClasses = 'dashboard-heading';
  let filterFormRegion;
  let rivalNodes;
  let noRivalsMessage;

  if(rivalsLoaded) {
    if(rivals.length || companyFilter) {
      filterFormRegion = (
        <form
          key="filter-search"
          className="dashboard-search"
          onChange={onFilterChange}
          onKeyDown={onFilterCancel}
          onSubmit={e => e.preventDefault()}>
          <input type="search" className="dashboard-search_input dashboard-input" placeholder="Find company..." />
          <button type="button" className="dashboard-search_icon" />
        </form>
      );
    }

    if(companyFilter) {
      rivals = filterRivals(rivals, companyFilter);
    }

    if(rivals.length) {
      rivalNodes = rivals.map(rival => {
        const isActiveRival = (rival.id === activeRivalId);
        const isInActiveGroup = checkIsInActiveGroup(rival.id);
        const isNew = moment(user.lastSeenAt).diff(rival.createdAt, 'seconds') <= 0;
        let cardFooter = null;

        if(isActiveRival && !activeGroupId) {
          cardFooter = (
            <footer className="dashboard-card_footer input--filter">
              <input readOnly={true} checked={false} type="checkbox" id={`groupBuilder_r${rival.id}`} value="" />
              <label htmlFor={`groupBuilder_r${rival.id}`}>Add to which group?</label>
            </footer>
          );
        }
        else if(activeGroupId) {
          cardFooter = (
            <footer className="dashboard-card_footer input--filter">
              <input readOnly={true} checked={isInActiveGroup} type="checkbox" id={`groupBuilder_r${rival.id}`} value="" />
              <label htmlFor={`groupBuilder_r${rival.id}`}>{isInActiveGroup ? 'Added' : 'Add to Group'}</label>
            </footer>
          );
        }

        return (
          <DashboardCard
            id={`rival_${rival.id}`}
            key={`r_${rival.id}`}
            isCompact={true}
            isNew={isNew}
            isDraft={rival.isDraft}
            isActive={isActiveRival}
            onClick={e => onCompanyClick(rival.id, e)}
            onMouseEnter={() => onCompanyHover(rival.id)}
            onMouseLeave={() => onCompanyHover(rival.id, false)}>
            <div className="content">
              <div className="logo">
                <CompanyLogo rival={rival} />
              </div>
              <h4>{rival.name}</h4>
            </div>
            {cardFooter}
          </DashboardCard>
        );
      });
    }
  }

  if(rivalsLoaded && (!rivalNodes || !rivalNodes.length)) {
    sidebarHeadingClasses += ' dashboard-heading--empty';
    noRivalsMessage = (
      <div className="dashboard_empty-message">
        No companies found.
      </div>
    );
  }

  return (
    <div className="dashboard-sidebar">
      <h4 className={sidebarHeadingClasses}>Your Companies</h4>
      {filterFormRegion}
      {rivalNodes}
      {noRivalsMessage}
    </div>
  );
};

DashboardEditRivals.propTypes = {
  user: PropTypes.object,
  rivals: PropTypes.arrayOf(PropTypes.object),
  rivalsLoaded: PropTypes.bool,
  companyFilter: PropTypes.string,
  activeRivalId: PropTypes.number,
  activeGroupId: PropTypes.number,
  checkIsInActiveGroup: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterCancel: PropTypes.func.isRequired,
  onCompanyHover: PropTypes.func.isRequired,
  onCompanyClick: PropTypes.func.isRequired
};

DashboardEditRivals.defaultProps = {
  user: null,
  rivals: [],
  rivalsLoaded: false,
  companyFilter: '',
  activeRivalId: 0,
  activeGroupId: 0,
  checkIsInActiveGroup: () => false,
  onFilterChange() {},
  onFilterCancel() {},
  onCompanyHover() {},
  onCompanyClick() {}
};

export default DashboardEditRivals;
