import {useState, useEffect} from 'react';
import Icon from './_icon';

import {dimensions} from '../modules/constants/ui';
import {pluralize, capitalize} from '../modules/text_utils';

import classNames from 'classnames';

import DraggableItem from './_draggable_item';
const TocItems = DraggableItem('TOC-DD-GROUP');

const CardVitalsTocList = ({items, title, icon, isCurating, isOpen, isRorderable, onItemClick, onAddClick, onLanesReorder, lanesLoading}) => {
  const [reordering, setReordering] = useState(false);
  const [edited, setEdited] = useState(false);
  const [reorderedItems, setReorderedItems] = useState(items || []);

  useEffect(() => {
    setReorderedItems(items);
  }, [items]);

  const handleToggleReorderMode = e => {
    e.preventDefault();
    e.stopPropagation();

    if(reordering && edited) {
      onLanesReorder(reorderedItems);
      setEdited(false);
    }

    setReordering(!reordering);
  };

  const handleLaneReorder = (item, target) => {
    if(item.droppableCardIndex === target.droppableCardIndex) {
      return;
    }

    setEdited(true);

    setReorderedItems(ReactUpdate(reorderedItems, {
      $splice: [
        [item.droppableCardIndex, 1],
        [target.droppableCardIndex, 0, reorderedItems[item.droppableCardIndex]]
      ]
    }
    ));

    item.droppableCardIndex = target.droppableCardIndex;
  };

  if(!items) {
    // empty array is allowed (displays "empty" mode header)
    return;
  }

  const canDrag = reordering && isRorderable && isCurating;
  const listItems = reorderedItems.map(({id = '', key = '', itemTitle = '', onClick = null, isNew = false, isDraft = false}, i) => (
    <TocItems
      key={key}
      className="vitals-toc_list_item vitals-toc_list_item--draggable"
      id={id}
      canDrag={canDrag}
      canDrop={canDrag}
      onHandleDrop={handleLaneReorder}
      droppableCardIndex={i}>
      <li key={key} onClick={onClick}>
        <div className="vitals-toc_list_item_text" title={itemTitle}>
          {itemTitle}
        </div>
        <div className="toc-flag_container">
          {(isDraft && <span className="toc-flag toc-flag--draft"><Icon icon="visibility-off" width="16" height="16" /></span>)}
          {(isNew && <span className="toc-flag toc-flag--new">New</span>)}
          {reordering && isCurating && <Icon className="toc-flag" icon="reorder" width="20" height="20" />}
        </div>
      </li>
    </TocItems>
  ));
  const reorderRegion = isOpen && isCurating && isRorderable && !lanesLoading &&
  (
    <button
      className={classNames('button button--xsmall', {
        'button--alt': !reordering,
        'button--disabled': lanesLoading
      })}
      disabled={lanesLoading}
      onClick={handleToggleReorderMode}>
      {!reordering ? 'reorder' : 'done'}
    </button>
  )
  ;
  const addNewRegion = isCurating && (
    <li className="vitals-toc_list_item">
      <div className="vitals-toc_list_item_text vitals-toc_list_item_text--new">
        <a
          href="#"
          title={`Add new ${title}`}
          className="vitals-toc_list_item_text_link"
          onClick={onAddClick}
          data-tracking-id={`vitals-toc-add-new-${title.toLowerCase()}`}>
          Add New {capitalize(title)}
        </a>
      </div>
    </li>
  );
  const tocContainerClass = classNames('vitals-toc_container', {
    'vitals-toc_container--open': isOpen,
    'vitals-toc_container--empty': !items.length
  });
  const listStyles = {};

  if(isOpen) {
    const maxHeight = dimensions.tocItemHeight * listItems.length + (isCurating ? dimensions.tocItemHeight : 0) + (isCurating && isRorderable ? dimensions.tocEditHeight : 0);

    listStyles.style = {
      maxHeight: `${maxHeight}px`
    };
  }

  return (
    <ul className={tocContainerClass}>
      <li>
        <div className="vitals-toc_container_header" onClick={onItemClick}>
          <Icon icon={icon} className="vitals-toc_container_header_icon" />
          <a href="#" className="vitals-toc_container_header_text">{pluralize(title)} ({items.length})</a>
          <div className="vitals-toc_container_header_right-container">
            {reorderRegion}
            <Icon icon={isOpen ? 'arrow-up' : 'arrow-down'} width="16" height="16" className="vitals-toc_container_header_toggle-icon" />
          </div>
        </div>
        <ul className={classNames('vitals-toc_list', {'vitals-toc_list--loading': lanesLoading})} {...listStyles}>
          {listItems}
          {addNewRegion}
        </ul>
      </li>
    </ul>
  );
};

CardVitalsTocList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  icon: PropTypes.string,
  isCurating: PropTypes.bool,
  isOpen: PropTypes.bool,
  isRorderable: PropTypes.bool,
  lanesLoading: PropTypes.bool,
  onItemClick: PropTypes.func,
  onAddClick: PropTypes.func,
  onLanesReorder: PropTypes.func
};

CardVitalsTocList.defaultProps = {
  items: [],
  title: '',
  icon: 'view_module',
  isCurating: false,
  isOpen: true,
  isRorderable: false,
  lanesLoading: false,
  onItemClick() {},
  onAddClick() {},
  onLanesReorder() {}
};

export default CardVitalsTocList;
