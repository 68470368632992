import {FormSpy} from 'react-final-form';
import {useEffect, useState} from 'react';
import InputSelectorGroup from '../../components/_input_selector_group';

import {allGroupItems} from '../../modules/new_board_wizard_utils';
import CompanyLogo from '../_company_logo';

const AddCards = ({automatedCardGroups, newBoardData, onAddCardsChange}) => {
  const [selectedAutomatedCards, setSelectedAutomatedCards] = useState([]);
  const [automatedCards, setAutomatedCards] = useState([]);

  const handleSelectAll = (groups, name, form) => {
    form.change(name, allGroupItems(groups));
    setSelectedAutomatedCards(allGroupItems(groups));
  };

  const handleClearAll = (name, form) => {
    form.change(name, []);
    setSelectedAutomatedCards([]);
  };

  useEffect(() => {
    onAddCardsChange(selectedAutomatedCards);
  }, [selectedAutomatedCards, onAddCardsChange]);

  useEffect(() => {
    setAutomatedCards(automatedCardGroups);
    setSelectedAutomatedCards(allGroupItems(automatedCardGroups));
  }, []);

  const profilePath = `${window.location.origin}/profile/${newBoardData?.profile?.id}/edit`;

  const logo = (!newBoardData || !newBoardData?.iconUrl)
    ? (<div className="initials">{(newBoardData?.profile?.name || '').slice(0, 2) || '??'}</div>)
    : (<CompanyLogo src={newBoardData?.iconUrl} rival={newBoardData} />);

  return (
    <div>
      <div className="board_logo_form_field_add_card_container">
        <div className="board_logo_form_field_container">
          {logo}
        </div>
        <div className="board_logo_form_field_metadata">{newBoardData?.profile?.name}
          <a href={profilePath}>View</a>
        </div>
      </div>
      <FormSpy subscription={{values: true}}>
        {({form}) => (
          <div className="add-cards">
          
            <InputSelectorGroup
              title="Add Automated Cards"
              titleDataTrackingId="new-board-add-automated-cards-label"
              name="automatedCards"
              label="Automated Cards"
              type="checkbox"
              groups={automatedCards}
              onSelectAll={() => handleSelectAll(automatedCardGroups, 'automatedCards', form)}
              onClearAll={() => handleClearAll('automatedCards', form)} />
          
          </div>
        )}
      </FormSpy>
      <div className="board_logo_form_field_success_message"><h3>Your new board is ready!</h3>
        <h4>You can add cards here or do it later from the board.</h4></div>
    </div>
  );
};

AddCards.propTypes = {
  automatedCardGroups: PropTypes.arrayOf(PropTypes.object),
  newBoardData: PropTypes.object,
  onAddCardsChange: PropTypes.func.isRequired
};

AddCards.defaultProps = {
  automatedCardGroups: [],
  newBoardData: {},
  onAddCardsChange() {}
};

export default AddCards;
