import {cardPermissions, CURATORS_ONLY, EVERYONE, FULL_ACCESS_USER_LABEL} from './constants/permissions';

export const getPermissionGroupButtonTitle = ({isDraft, allAccess, visibilityGroups}) => {
  if(isDraft) {
    return {title: cardPermissions[CURATORS_ONLY].label};
  }

  const groups = visibilityGroups || [];

  if(allAccess) {
    return {title: cardPermissions[EVERYONE].label};
  }

  let firstGroupName = '';
  let count = 0;

  const tooltip = groups.reduce((acc, group) => {
    const {name} = group;

    if(name) {
      if(!firstGroupName) {
        firstGroupName = name;
      }
      else {
        count += 1;
      }

      return `${acc}${acc ? ',\n' : ''}${name}`;
    }

    return acc;
  }, '');

  return {title: firstGroupName || FULL_ACCESS_USER_LABEL, count, tooltip};
};
