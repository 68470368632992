import DraggableItem from './_draggable_item';
import {uiViewports} from '../modules/constants/ui';

const BattlecardItemDraggable = DraggableItem('BCBUILDER-DD-GROUP');

class BattlecardsEditorDnDBuilder extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    cardTitles: PropTypes.arrayOf(PropTypes.object),
    maxBattlecardCards: PropTypes.number,
    activeViewport: PropTypes.string,
    onBattlecardTileClicked: PropTypes.func
  };

  static defaultProps = {
    cardTitles: [],
    maxBattlecardCards: 8,
    activeViewport: uiViewports.DESKTOP,
    onBattlecardTileClicked() {}
  };

  componentDidMount() {
    console.log('BattlecardsEditorDnDBuilder.componentDidMount: props: %o', this.props);
  }

  _getCardSlotsArrOfBattlecardView = view => {
    const cardsOfCurrentView = this.context.utils.battlecard.cards[view] || [];
    let paddedCardsArr;

    if(cardsOfCurrentView.length > this.props.maxBattlecardCards) {
      paddedCardsArr = cardsOfCurrentView.slice(0, this.props.maxBattlecardCards);
    }
    else {
      paddedCardsArr = cardsOfCurrentView.concat(new Array(this.props.maxBattlecardCards - cardsOfCurrentView.length));
    }

    return paddedCardsArr;
  };

  _getCardSlotsArrOfCurrentBattlecardView = () => {
    return this._getCardSlotsArrOfBattlecardView(this.props.activeViewport);
  };

  _getFilteredCardsForSave = updatedCards => {
    const {maxBattlecardCards} = this.props;
    const filteredCards = updatedCards.filter(value => Number.isInteger(value));
    const emptySlots = maxBattlecardCards - filteredCards.length;

    return filteredCards.fill('', filteredCards.length, emptySlots);
  };

  _setCardsOfCurrentBattlecardView = updatedCards => {
    const battlecard = _.extend({}, this.context.utils.battlecard);

    battlecard.cards[this.props.activeViewport] = this._getFilteredCardsForSave(updatedCards);

    const battlecardOptions = {
      id: battlecard.id,
      profileId: battlecard.profile.id,
      cardData: battlecard.cards
    };

    this.context.api.battlecardCreateOrUpdate(battlecardOptions);
  };

  _handleCardClick = (cardId, event) => {
    if(event) {
      event.preventDefault();
    }

    const {onBattlecardTileClicked} = this.props;

    onBattlecardTileClicked(cardId);
  };

  _getCardDisplayTitle = cardId => {
    const {cardTitles} = this.props;
    const cardObj = cardTitles.find(ct => ct.id === cardId);
    let name;

    if(cardObj) {
      name = cardObj.name; // eslint-disable-line prefer-destructuring
    }
    else if(cardId === 0) {
      name = 'Company Vitals';
    }

    if(name) {
      return (<a href="#" onClick={e => this._handleCardClick(cardId, e)}>{name}</a>);
    }

    return (
      <i className="fa fa-spinner fa-pulse" />
    );
  };

  handleCardReorderDrop = (dragItemProps, targetItemProps) => {
    // DEBUG
    console.log('BattlecardsEditorDnDBuilder.drop: dragItemProps: %o, targetItemProps: %o', dragItemProps, targetItemProps);

    const cards = this._getCardSlotsArrOfCurrentBattlecardView();
    const targetValue = cards[targetItemProps.droppableCardIndex];

    // swap elements
    cards[targetItemProps.droppableCardIndex] = cards[dragItemProps.droppableCardIndex];
    cards[dragItemProps.droppableCardIndex] = targetValue;

    this._setCardsOfCurrentBattlecardView(cards);
  };

  handleCardDeleteClick = (cardId, event) => {
    if(event) {
      event.preventDefault();
    }

    const battlecard = _.extend({}, this.context.utils.battlecard);
    const {cards} = battlecard;

    // remove a card from all battlecard views
    for(const key in cards) {
      if(cards.hasOwnProperty(key)) {
        if(!cards[key]) {
          cards[key] = [];
        }
        else if(cards[key].includes(cardId)) {
          const cardIndex = cards[key].indexOf(cardId);

          cards[key].splice(cardIndex, 1);
        }

        battlecard.cards[key] = this._getFilteredCardsForSave(cards[key]);
      }
    }

    const battlecardOptions = {
      id: battlecard.id,
      profileId: battlecard.profile.id,
      cardData: battlecard.cards
    };

    this.context.api.battlecardCreateOrUpdate(battlecardOptions);
  };

  render() {
    const {battlecard} = this.context.utils;
    const slotsToRender = [];
    const cardSlots = this._getCardSlotsArrOfCurrentBattlecardView();
    const activeCardSlots = cardSlots.filter(s => { return parseInt(s, 10) >= 0; });
    let uiEmptyMessage;

    if(!battlecard) {
      return;
    }

    for(let i = 0; i < cardSlots.length; i++) {
      const hasCardId = (parseInt(cardSlots[i], 10) >= 0);

      if(hasCardId) {
        slotsToRender.push(
          <BattlecardItemDraggable
            key={i}
            canDrag={true}
            canDrop={true}
            onHandleDrop={this.handleCardReorderDrop}
            className="ui-battlecard-legend_slot ui-battlecard-legend_slot--card"
            droppableCardIndex={i}>
            <div className="ui-battlecard-legend_slot_action" onClick={e => this.handleCardDeleteClick(cardSlots[i], e)}>
              <i className="fa fa-times-circle" />
            </div>
            <div className="ui-battlecard-legend_slot_label">
              {this._getCardDisplayTitle(cardSlots[i])}
            </div>
          </BattlecardItemDraggable>
        );
      }
      else {
        slotsToRender.push(
          <BattlecardItemDraggable
            key={i}
            canDrag={false}
            canDrop={true}
            onHandleDrop={this.handleCardReorderDrop}
            className="ui-battlecard-legend_slot ui-battlecard-legend_slot--empty"
            droppableCardIndex={i} />
        );
      }
    }

    if(!activeCardSlots.length) {
      uiEmptyMessage = (
        <div className="ui-battlecard-legend_empty-message">
          To populate your battlecard,<br />
          Select &quot;Add to battlecard&quot; from the dropdown
          menu on any card in your selected board.
        </div>
      );
    }

    return (
      <div className={'ui-battlecard-legend ui-battlecard-legend--view-' + this.props.activeViewport}>
        {uiEmptyMessage}
        {slotsToRender}
      </div>
    );
  }

}

export default BattlecardsEditorDnDBuilder;
