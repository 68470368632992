import classNames from 'classnames';
import FeedPostBox from './_feed_post_box';
import MessageBar from './_message_bar';

import {redirectToFeed} from '../modules/utils';
import {userCanCurate} from '../modules/roles_utils';

import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {withPosts} from '../contexts/_posts';

class Feed extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired
  };

  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    company: PropTypes.object,
    postsUrl: PropTypes.string,
    rivals: PropTypes.object,
    loadOrFetchRivals: PropTypes.func.isRequired,
    postsContext: PropTypes.shape({
      updatePosts: PropTypes.func,
      refreshPost: PropTypes.func
    }).isRequired
  };

  static defaultProps = {
    match: {},
    history: {},
    location: {},
    user: null,
    users: {},
    company: null,
    postsUrl: '',
    rivals: {}
  };

  state = {
    draftDigest: null,
    digestTypesData: []
  };

  componentDidMount() {
    const {loadOrFetchRivals, user} = this.props;

    this._isMounted = true;

    if(userCanCurate({user})) {
      this.loadDraftDigest();
    }

    loadOrFetchRivals();
    this.loadDigestTypesData();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _preventDefault = event => event && event.preventDefault();

  loadDraftDigest = () => {
    // for curators viewing the feed, load the current draft digest
    const draftDigestId = 0;

    this.context.api.digestGet(draftDigestId, '', draftDigest => {
      // DEBUG
      console.log('Feed.loadDraftDigest: loaded draft digest: %o', draftDigest);

      this.setState({draftDigest});
    });
  };

  // NOTE:
  // - some overlap with Digest.handleUpdateFavorite (hard to avoid without major restructuring)
  handleUpdateFavorite = (options = {}, event) => {
    this._preventDefault(event);

    if(_.isEmpty(options) || ((options.id <= 0) && (options.postId <= 0))) {
      return;
    }

    const draftDigest = options.draftDigest || this.state.draftDigest;

    if(_.isEmpty(draftDigest)) {
      return;
    }

    const {utils: {dialog: {alert}}, api: {favoriteUpdate, favoriteCreate, postCommentsGet, digestGet, alertPublish}} = this.context;

    const favoriteOptions = {
      emailDigestId: draftDigest.id
    };
    let favoriteAction = null;
    let hasCommentChanged = false;

    const {id, postId, needsPromote, alertId, curatedCommentary, commentId, viewOrder = null} = options;

    if(viewOrder !== null) {
      favoriteOptions.viewOrder = viewOrder;
    }

    if(id) {
      // updating an existing favorite
      favoriteOptions.id = id;
      favoriteAction = (updateOptions = {}, callback = null) => favoriteUpdate(updateOptions, false, callback);

      const existingFavorite = draftDigest.favorites.find(f => f.id === id);

      if(existingFavorite) {
        hasCommentChanged = (existingFavorite.curatedCommentary !== curatedCommentary);
      }
    }
    else {
      // creating a new favorite
      favoriteOptions.postId = postId;
      favoriteAction = favoriteCreate;
      hasCommentChanged = true;     // if not the prime comment, use selected comment text for "why it matters"
    }

    const currentPostId =  id || postId;

    const performFavoriteAction = (comments = []) => {
      if(!_.isEmpty(comments)) {
        const selectedCommentIndex = comments.findIndex(c => c.id === commentId);

        if(selectedCommentIndex > 0) {
          const selectedComment = comments[selectedCommentIndex];
          const {bodyPlain, bodyHtml} = selectedComment || {};

          favoriteOptions.curatedCommentary = bodyPlain || bodyHtml || null;
        }
      }

      favoriteAction(favoriteOptions, favorites => {
        if(options.isMultiDigests) {
          this.handleUpdateDigestTypesData(draftDigest.id, favorites, currentPostId);
        }

        if(!options.draftDigest || options.draftDigest.id === this.state.draftDigest.id) {
          // refresh related digest
          return digestGet(draftDigest.id, '', refreshedDigest => {
            this.setState({
              draftDigest: refreshedDigest
            }, () => {
              const {postsContext: {refreshPost}} = this.props;

              refreshPost && refreshPost(postId);
            });
          });
        }
      });
    };

    const publishFailedMessage = 'Whoops! 😬 An error occurred while trying to promote the associated alert. Please try again.';

    const maybeUpdateCommentAndPerformAction = (promoteCallbackStatus = {}) => {
      const {status} = promoteCallbackStatus;

      // having a status indicates an error occurred so alert user and abort.
      if(status && status >= 400) {
        return alert(publishFailedMessage);
      }

      if(hasCommentChanged) {
        // update "why it matters" commentary on associated favorite with selected comment text
        postCommentsGet(postId, comments => performFavoriteAction(comments));
      }
      else {
        performFavoriteAction();
      }
    };

    if(needsPromote) {
      if(!alertId) {
        return alert('Whoops! 😬 Something is not quite right. Data required to promote the alert is missing.');
      }

      alertPublish({id: decodeURIComponent(alertId)})
        .then(result => maybeUpdateCommentAndPerformAction(result))
        .catch(() => alert(publishFailedMessage));
    }
    else {
      maybeUpdateCommentAndPerformAction();
    }
  };

  handleDeleteFavorite = (postId = 0, options = {}) => {
    if(!postId || (postId <= 0)) {
      return;
    }

    const {api: {favoriteDelete}} = this.context;

    const draftDigest = options.draftDigest || this.state.draftDigest;

    if(_.isEmpty(draftDigest)) {
      return;
    }

    favoriteDelete({emailDigestId: draftDigest.id, postId}, favorites => {
      if(options.isMultiDigests) {
        this.handleUpdateDigestTypesData(draftDigest.id, favorites, postId);
      }

      if(!options.draftDigest || options.draftDigest.id === this.state.draftDigest.id) {
        return this.setState({
          draftDigest: {...draftDigest, favorites}
        }, () => {
          const {postsContext: {refreshPost}} = this.props;

          refreshPost && refreshPost(postId);
        });
      }
    });
  };

  loadDigestTypes = () => {
    const {
      api: {
        digestTypesGet
      }
    } = this.context;

    const options = {
      digestTypeOptions: {},
      code: 'Digest.loadDigestTypes'
    };

    return new Promise((resolve, reject) => {
      digestTypesGet({
        ...options,
        callback(res) {
          if(res) {return resolve(res.data);}

          return reject('Digest.loadDigestTypes: failed to load digestTypes');
        }
      });
    });
  };

  loadDigests = digestTypeId => {
    const {
      api: {
        digestsGet
      }
    } = this.context;

    const options = {
      digestOptions: {
        filter: 'draft'
      }
    };

    if(digestTypeId) {
      options.digestOptions.typeId = digestTypeId;
    }

    return new Promise((resolve, reject) => {
      digestsGet({
        ...options,
        callback(data) {
          if(data) {return resolve(data.digests);}

          return reject('Digest.loadDigests: failed to load digests');
        }
      });
    });
  };

  loadFavorites = digestId => {
    const {
      api: {
        digestFavoritesGet
      }
    } = this.context;

    return new Promise((resolve, reject) => {
      digestFavoritesGet(digestId, data => {
        if(data) {return resolve(data);}

        return reject('Digest.loadDigests: failed to load digests');
      });
    });
  };

  loadDigestTypesData = async () => {
    const digestTypesData = [];

    const digestTypes = await this.loadDigestTypes();

    const digestTypesReqs = digestTypes.map(async digestType => {
      digestTypesData.push({
        digestType
      });

      const currentIndex = digestTypesData.length - 1;

      const digests = await this.loadDigests(digestType.id);

      const draftDigest = digests.length ? digests[0] : null;

      if(draftDigest) {
        digestTypesData[currentIndex].draftDigest = draftDigest;

        const favorites = await this.loadFavorites(draftDigest.id);

        digestTypesData[currentIndex].draftDigest.favorites = favorites;
      }
    });

    await Promise.all(digestTypesReqs);

    this.setState({
      digestTypesData
    });
  };

  handleUpdateFavorites = async (digestTypesUpdateList = [], options = {}) => {
    const updateFavoritesReqs = digestTypesUpdateList.map(({postId, draftDigest, isFavorite}) => {
      if(isFavorite) {
        return this.handleDeleteFavorite(postId, {draftDigest, isMultiDigests: true, ...options});
      }

      return this.handleUpdateFavorite({postId, draftDigest, isMultiDigests: true, ...options});
    });

    try {
      await Promise.all(updateFavoritesReqs);

      return Promise.resolve();
    }
    catch(err) {
      console.error('Feed.handleUpdateFavorites: failed to update favorite', err);

      throw err;
    }
  };

  handleUpdateDigestTypesData = (draftDigestId, favorites, currentPostId) => {
    if(!draftDigestId || !favorites) {
      console.error('Feed.handleUpdateDigestTypesData: DrafDigestId and favorites are required');

      return;
    }

    const {digestTypesData} = this.state;

    const digestTypeIndex = digestTypesData.findIndex(({draftDigest}) => (
      draftDigest.id === draftDigestId)
    );

    if(digestTypeIndex === -1) {return;}

    const newDigestTypesData = [...digestTypesData];

    newDigestTypesData[digestTypeIndex].draftDigest.favorites = favorites;

    return this.setState({digestTypesData: newDigestTypesData}, () => {
      this.handleUpdatePosts(currentPostId);
    });
  };

  handleUpdatePosts = currentPostId => {
    const {postsContext: {refreshPost}} = this.props;

    refreshPost(currentPostId);
  };

  render() {
    const {user, users, company, postsUrl, match: {params = {}}, history, location: {search = ''}, rivals, postsContext: {updatePosts}} = this.props;
    const {appData: {v2Host}} = this.context;
    const {digestTypesData, draftDigest} = this.state;

    const postRivals = new URLSearchParams(search).getAll('rivals') ?? [];
    const v2RivalId = parseInt(new URLSearchParams(search).get('from_v2'), 10) ?? null;
    const v2ProfileInfo = !_.isNil(v2RivalId) ? rivals[v2RivalId] : undefined;
    const visibilityGroupId = new URLSearchParams(search).get('previewing') ?? null;

    const singlePostRequested = !_.isEmpty(params) && params.postId
      ? {
        findPostId: parseInt(params.postId, 10),
        onFindPost() {
          const {parent: {opener} = {}} = window || {};

          if(opener) {
            redirectToFeed();
          }
          else {
            history.push('/feed');
          }
        }
      }
      : {};

    return (
      <div className="container feed">
        {v2ProfileInfo &&
        <MessageBar dismissable={false} large={true}>
          <div className="feed-message-bar">
            <img
              className="feed-message-bar-logo"
              src={v2ProfileInfo.iconUrl}
              loading="lazy" />
            <span className="feed-message-bar-name">{v2ProfileInfo.name}</span>
            <a
              className="feed-message-bar-link"
              href={`//${v2Host}/profile/${v2ProfileInfo.profile?.id}${visibilityGroupId ? `?previewing=${visibilityGroupId}` : ''}`}>
              Back to Board
            </a>
          </div>
        </MessageBar>}

        <div className={classNames('ui-wrapper', {'with-large-message-bar': Boolean(v2ProfileInfo)})}>
          <div className="row">
            <ReactTooltip
              class="tooltip"
              html={true}
              effect="solid"
              offset={{top: 0}} />
            <FeedPostBox
              user={user}
              users={users}
              company={company}
              postsUrl={postsUrl}
              postRivals={postRivals}
              digest={draftDigest}
              onUpdateFavorite={this.handleUpdateFavorite}
              onDeleteFavorite={this.handleDeleteFavorite}
              {...singlePostRequested}
              updatePosts={updatePosts}
              digestTypesData={digestTypesData}
              onDigestTypeFavoriteUpdate={this.handleUpdateFavorites} />
          </div>
        </div>
      </div>
    );
  }

}
const mapStateToProps = ({rivals}) => ({rivals: rivals.items});
const mapDispatchToProps = dispatch => ({
  loadOrFetchRivals: () => dispatch.rivals.loadOrFetchRivals()
});

export default withPosts(connect(mapStateToProps, mapDispatchToProps)(Feed));

