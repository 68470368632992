class CompanySettingsDomains extends React.Component {

  static contextTypes = {
    utils: PropTypes.object
  };

  state = {
    initiallyLoaded: false,
    domains: [],
    uiShowBulkAdd: false,
    uiFilterTerms: false,
    uiSingleDomainToAdd: '',
    uiBulkDomainsToAdd: '',
    uiAddingProcessDomainTotal: 0,
    uiAddingProcessDomainErrors: [],
    uiAddingProcessDomainSucceeds: []
  };

  componentDidMount() {
    // DEBUG
    console.log('CompanySettingsDomains.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    this.apiGetDomains(domains => {
      this.setState({
        domains,
        initiallyLoaded: true
      });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSearchChange = e => {
    let searchValue = false;

    if(e.target) {
      searchValue = e.target.value.replace(/[^\w\-.]/gi, '').toLowerCase() || false;
    }

    this.setState({
      uiFilterTerms: searchValue
    });
  };

  handleBulkAddToggleClick = (toggle, e) => {
    if(e) {
      e.preventDefault();
    }

    this.setState({
      uiShowBulkAdd: toggle
    });
  };

  handleRemoveDomain = (domainToDelete, e) => {
    if(e) {
      e.preventDefault();
    }

    let domains = this.state.domains ? this.state.domains.slice() : [];
    const onRemoveDomain = () => {
      domains = domains.filter(function(domain) {
        return (domain !== domainToDelete);
      });

      // optimistic deletion
      this.setState({
        domains
      });

      this.apiDeleteDomain(domainToDelete);
    };

    if(domains.length === 1) {
      this.context.utils.dialog.confirm({
        message: `<h3>You are about to delete the last Login Domain.</h3>
          <p>Individual invites or Single Sign-On will be needed to sign up users. Proceed?</p>`,
        okCallback: onRemoveDomain
      });

      return;
    }

    onRemoveDomain();
  };

  handleNewSingleDomainChange = e => {
    this.setState({
      uiSingleDomainToAdd: e.target.value
    });
  };

  handleNewSingleDomainKeyPress = e => {
    if(e.which === 13) {
      this.handleNewSingleDomainSubmit();
    }
  };

  handleNewSingleDomainSubmit = () => {
    let newDomain = this.state.uiSingleDomainToAdd;

    newDomain = newDomain.replace(/\s+/g, '');

    if(!newDomain) {
      return;
    }

    this.setState({
      uiSingleDomainToAdd: '',
      uiAddingProcessDomainTotal: 1,
      uiAddingProcessDomainErrors: [],
      uiAddingProcessDomainSucceeds: []
    });

    this._addDomain(newDomain);
  };

  handleNewBulkDomainChange = e => {
    this.setState({
      uiBulkDomainsToAdd: e.target.value
    });
  };

  handleNewBulkDomainsSubmit = () => {
    let newDomains = this.state.uiBulkDomainsToAdd ? this.state.uiBulkDomainsToAdd.slice() : [];

    // convert to an array of new domains
    newDomains = newDomains.replace(/ /g, '').replace(/[\s+,]/g, '\n').split('\n');

    // remove empty values from array
    newDomains = _.compact(newDomains);

    if(!newDomains.length) {
      return;
    }

    this.setState({
      uiBulkDomainsToAdd: '',
      uiShowBulkAdd: false,
      uiAddingProcessDomainTotal: newDomains.length,
      uiAddingProcessDomainErrors: [],
      uiAddingProcessDomainSucceeds: []
    });

    newDomains.map(this._addDomain);
  };

  _addDomain = domain => {
    this.apiAddDomain(domain, updatedDomainList => {
      this.setState(currentState => {
        const successDomains = currentState.uiAddingProcessDomainSucceeds.slice();

        successDomains.push(domain);

        return {
          uiAddingProcessDomainSucceeds: successDomains,
          domains: updatedDomainList
        };
      });
    }, errorMessage => {
      const msg = errorMessage ? errorMessage.replace(/^Validation failed:\s+(Domain.*?)?:\s+/i, '') : 'invalid domain';

      this.setState(currentState => {
        const errorDomains = currentState.uiAddingProcessDomainErrors.slice();

        errorDomains.push({domain, message: msg});

        return {
          uiAddingProcessDomainErrors: errorDomains
        };
      });
    });
  };

  handleAddProcessResetClick = stateKey => {
    if(!this.state[stateKey] || !this.state[stateKey].length) {
      return;
    }

    const newStateObj = {};
    const countToRemove = this.state[stateKey].length;

    newStateObj[stateKey] = [];
    newStateObj.uiAddingProcessDomainTotal = Math.max(0, this.state.uiAddingProcessDomainTotal - countToRemove);

    this.setState(newStateObj);
  };

  apiValidateDomain = (domain, callback) => {
    jQuery.ajax({
      type: 'GET',
      url: `/api/identify.json?v=3&domain=${encodeURIComponent(domain)}`,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      success: data => {
        if(this._isMounted) {
          return typeof callback === 'function' && callback(data);
        }
      },
      error: (xhr, type) => {
        if(this._isMounted) {
          if(xhr.status === 404) {
            // 404 signifies domain is not found within klue, so it is not an error
            return typeof callback === 'function' && callback([]);
          }

          console.error('CompanySettingsDomains.apiValidateDomain: error: %o, type: %o', xhr, type);
        }
      }
    });
  };

  apiGetDomains = callback => {
    jQuery.ajax({
      type: 'GET',
      url: '/api/company.json',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      success: data => {
        if(this._isMounted) {
          if(data && data.emailDomains) {
            return typeof callback === 'function' && callback(data.emailDomains || []);
          }
        }
      },
      error: (xhr, type) => {
        if(this._isMounted) {
          console.error('CompanySettingsDomains.apiGetDomains: error: %o, type: %o', xhr, type);
        }
      }
    });
  };

  apiAddDomain = (domain, successCallback, errorCallback) => {
    const onAddDomain = () => {
      const domainData = {
        addEmailDomains: [domain]
      };

      jQuery.ajax({
        type: 'PUT',
        url: '/api/company.json',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(domainData),
        success: data => {
          if(this._isMounted) {
            if(data && data.emailDomains) {
              successCallback(data.emailDomains || []);
            }
          }
        },
        error: xhr => {
          if(this._isMounted) {
            if(xhr.status === 404 || xhr.status === 422) {
              const msg = xhr.responseJSON && xhr.responseJSON.error;

              errorCallback(msg || 'Invalid domain');
            }
            else {
              errorCallback(false);
            }
          }
        }
      });
    };

    this.apiValidateDomain(domain, companiesData => {
      const {utils: {company, dialog} = {}} = this.context;
      const companies = (companiesData || []).filter(c => c.companyId !== company.id).map(c => c.companyName);

      if(companies.length > 0) {
        const body = (<div>
          <h3>Domain is already associated with a Klue Team</h3>
          <p>Klue Teams using this domain:</p>
          <p>
            <strong>{companies.map(c => <span>{c}<br /></span>)}</strong>
          </p>
          <hr />
          <p><em>
            Assigning this domain name will require all team members
            to select a team each time they sign into Klue.
          </em></p>
        </div>);

        dialog.confirm({
          message: '',
          bodyContent: body,
          okCallback: onAddDomain,
          cancelCallback: () => errorCallback(`Skipped adding domain ${domain}`),
          buttonOk: 'OK \u2014 Proceed'
        });

        return;
      }

      onAddDomain();
    });
  };

  apiDeleteDomain = domain => {
    const domainData = {
      removeEmailDomains: [domain]
    };

    jQuery.ajax({
      type: 'PUT',
      url: '/api/company.json',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(domainData),
      success: () => {
        if(this._isMounted) {
          console.log('CompanySettingsDomains.apiDeleteDomain: deleted domain: %o', domain);
        }
      },
      error: (xhr, type) => {
        if(this._isMounted) {
          console.error('CompanySettingsDomains.apiDeleteDomain: error: %o, type: %o', xhr, type);
        }
      }
    });
  };

  renderMessagesWhenAdding = () => {
    if(!this.state.uiAddingProcessDomainTotal) {
      return null;
    }

    const succededDomainsStrArr = this.state.uiAddingProcessDomainSucceeds;
    const errorDomainsObjArr = this.state.uiAddingProcessDomainErrors;
    const totalProcessed = (errorDomainsObjArr.length + succededDomainsStrArr.length);
    const isComplete = (totalProcessed >= this.state.uiAddingProcessDomainTotal);
    let uiProgress;
    let uiSucceeded;
    let uiErrors;

    if(!isComplete) {
      uiProgress = (
        <div className="alert alert-info">
          <div><i className="fa fa-pulse fa-spinner" /> <em>Adding {totalProcessed + 1}/{this.state.uiAddingProcessDomainTotal} new domains&hellip;</em></div>
        </div>
      );
    }

    if(succededDomainsStrArr.length) {
      let uiClose;

      if(isComplete) {
        uiClose = (<div className="close" onClick={() => this.handleAddProcessResetClick('uiAddingProcessDomainSucceeds')}><span>&times;</span></div>);
      }

      uiSucceeded = (
        <div className="alert alert-success alert-dismissible">
          {uiClose}
          <i className="fa fa-check" /> {succededDomainsStrArr.length} New Domain{succededDomainsStrArr.length === 1 ? '' : 's'} added
        </div>
      );
    }

    if(errorDomainsObjArr.length) {
      let uiClose;

      if(isComplete) {
        uiClose = (<div className="close" onClick={() => this.handleAddProcessResetClick('uiAddingProcessDomainErrors')}><span>&times;</span></div>);
      }

      uiErrors = (
        <div className="alert alert-danger alert-dismissible">
          {uiClose}
          <div><i className="fa fa-times" /> {errorDomainsObjArr.length} domain{errorDomainsObjArr.length === 1 ? '' : 's'} was not added</div>
          {errorDomainsObjArr.map(function(domainErrObj) {
            return (
              <div key={domainErrObj.domain} className="text-danger">{domainErrObj.message || 'error'} &ndash; <strong>{domainErrObj.domain}</strong></div>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        {uiProgress}
        {uiSucceeded}
        {uiErrors}
      </div>
    );
  };

  render() {
    const uiDomainRows = [];
    const uiSearchTerm = this.state.uiFilterTerms;
    let uiBulkAdd;
    let uiBulkAddTrigger;
    let uiFilters;
    let uiResults;

    for(let i = 0; i < this.state.domains.length; i++) {
      const domain = this.state.domains[i];

      if(uiSearchTerm && (uiSearchTerm.length > 2 ? domain.indexOf(uiSearchTerm) < 0 : domain.indexOf(uiSearchTerm) !== 0)) {
        continue;
      }

      uiDomainRows.push(
        <tr key={domain}>
          <td>
            <strong>{domain}</strong>
            <a href="#" data-tip="Remove Domain" className="pull-right" onClick={e => this.handleRemoveDomain(domain, e)}>
              <i className="fa fa-remove" />
            </a>
          </td>
        </tr>
      );
    }

    if(uiDomainRows.length > 0) {
      let uiCounterMessage;

      if(this.state.domains.length > 13 && !uiSearchTerm) {
        uiCounterMessage = (
          <div className="u-pt-s help-block text-center"><small><em>{this.state.domains.length} Total Domains</em></small></div>
        );
      }

      uiResults = (
        <div className="max-height-scroll-container max-height-scroll-container--m u-mb-m">
          <table className="table table-bordered table-grey-background" style={{marginBottom: 0}}>
            <tbody>
              {uiDomainRows}
            </tbody>
          </table>
          {uiCounterMessage}
        </div>
      );
    }
    else if(this.state.initiallyLoaded === false) {
      uiResults = (
        <div className="help-block u-mb-m">
          <i className="fa fa-pulse fa-spinner" /> <em>Loading&hellip;</em>
        </div>
      );
    }
    else if(uiSearchTerm) {
      uiResults = (
        <p className="help-block u-mb-m">No domains found with the current search terms</p>
      );
    }
    else {
      uiResults = (
        <p className="help-block u-mb-m"><strong>No domains</strong> &ndash; Currently users can only receive access by invite only, or via Single Sign-On</p>
      );
    }

    if(this.state.domains.length > 13 || uiSearchTerm) {
      uiFilters = (
        <div className="control-float-submit u-mb-m">
          <input type="text" onChange={this.handleSearchChange} className="form-control" placeholder="Type to filter..." />
          <div className="control-float-submit_item">
            <i className="fa fa-search" />
          </div>
        </div>
      );
    }

    if(this.state.uiShowBulkAdd) {
      uiBulkAdd = (
        <div className="u-pt-xl">
          <label>Bulk Add Domains</label>
          <div className="help-block">Add one domain per line, or separate them with commas:</div>
          <textarea
            autoFocus={true}
            value={this.state.uiBulkDomainsToAdd}
            onChange={this.handleNewBulkDomainChange}
            className="form-control u-w100 u-mb-s"
            rows="6"
            style={{resize: 'vertical'}} />
          <div className="row">
            <div className="col-sm-4 pull-right">
              <button className="btn btn-success u-w100" onClick={this.handleNewBulkDomainsSubmit}> Add Domains</button>
            </div>
          </div>
        </div>
      );
    }
    else {
      uiBulkAddTrigger = (
        <div className="text-right u-pt-m">
          <a href="#" onClick={e => this.handleBulkAddToggleClick(true, e)}>Bulk Add Domains</a>
        </div>
      );
    }

    return (
      <div className="company-settings company-settings-domains">
        <h3 className="u-m0">Authorized Login Domains</h3>
        <div className="help-block u-mb-m">Allow automatic access to Klue for users with email addresses from the following domains:</div>
        {this.renderMessagesWhenAdding()}
        {uiFilters}
        {uiResults}
        <div className="row">
          <div className="col-sm-8">
            <input
              type="email"
              placeholder="e.g. companyname.com"
              className="form-control u-w100"
              value={this.state.uiSingleDomainToAdd}
              onChange={this.handleNewSingleDomainChange}
              onKeyDown={this.handleNewSingleDomainKeyPress} />
          </div>
          <div className="col-sm-4">
            <button className="button button--medium" onClick={this.handleNewSingleDomainSubmit}> Add Domain</button>
          </div>
        </div>
        {uiBulkAddTrigger}
        {uiBulkAdd}
      </div>
    );
  }

}

export default CompanySettingsDomains;
