import {postsLikesByIds} from '../../modules/api/posts';

const initialState = {
  posts: [], // TODO - Move posts data from postBox.js to redux
  likes: {}
};

/**
 * @name Feed model
 * See: https://github.com/rematch/rematch/
 *
 * @type Redux model
 * @description Stores all posts and likes and make its data accessible to any component
 * @todo Store feed posts
 */
export default {
  state: initialState,
  reducers: {
    populatePostsLikes(state, payload) {
      return {
        ...state,
        likes: {...state.likes, ...payload}
      };
    },
    updatePostLikes(state, payload) {
      const {postId = 0, likes = []} = payload;

      if(!postId) {
        return state;
      }

      return {
        ...state,
        likes: {...state.likes, [postId]: likes}
      };
    },
    reset() {
      return initialState;
    }
  },
  effects: () => ({
    /**
     *
     * @description Async function to load likes by list of post ids
     * @param {object} payload list of post Ids [123, 122, 133]
     * @returns {object} API response
     */
    async asyncLoadLikes(payload) {
      const response = await postsLikesByIds(payload);

      if(Object.keys(response).length) {
        this.populatePostsLikes(response);
      }

      return response;
    }
  })
};
