import AttachmentPreview from './_attachment_preview';
import Icon from './_icon';

import {wrapHtml} from '../modules/html_utils';

import classNames from 'classnames';
import {Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

class NotificationsItem extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object,
    notification: PropTypes.object,
    onNotificationDeleteClick: PropTypes.func
  };

  static defaultProps = {
    user: null,
    notification: null,
    onNotificationDeleteClick() {}
  };

  componentDidMount() {
    // DEBUG
    console.log('NotificationsItem.componentDidMount: props: %o', this.props);

    ReactTooltip.rebuild();
  }

  handleDismissClick = () => {
    const {notification, onNotificationDeleteClick} = this.props;

    onNotificationDeleteClick(notification);
  };

  // partially duped from Comment component
  normalizeAttachment = attachment => {
    return {
      url: attachment.asset_url || attachment.assetUrl,
      thumbnailUrl: attachment.asset_url || attachment.assetUrl,
      fileName: attachment.file_name || attachment.fileName,
      type: attachment.type,
      mimeType: attachment.mime_type || attachment.mimeType
    };
  };

  replaceAtNames = (match, p1, p2) => {
    const {user = {}} = this.props;
    const atName = p2.toLowerCase();
    const isUser = (atName === user.username.toLowerCase());

    return `${p1}<a href="/users/${atName}" class="ui-at ${(isUser ? ' _highlight' : '')}">@${p2}</a>`;
  };

  render() {
    const {notification, notification: {comment = {}}} = this.props;
    const dismissTitle = `Mark as ${notification.dismissedAt ? 'unread' : 'read'}`;
    const replaceRe = /(^|\W)@(\w+)(?![^<>]*>)/g;
    const commentBody = comment.body.replace(replaceRe, this.replaceAtNames);
    const hasAttachment = !_.isEmpty(comment.attachments);
    const userProfileUrl = `/users/${comment.userId}`;
    const notificationClass = classNames('notification-block notification-group_message', {
      'notification-group_message--dismissed': Boolean(notification.dismissedAt),
      'notification-group_message--has-attachment': hasAttachment
    });
    let attachmentPreview;

    if(hasAttachment) {
      attachmentPreview = (
        <AttachmentPreview attachment={this.normalizeAttachment(comment.attachments[0])} />
      );
    }

    return (
      <div className={notificationClass}>
        <div className="notification-body">
          <Link to={userProfileUrl} className="avatar-link">
            <img className="avatar" src={comment.userImage} loading="lazy" />
          </Link>
          <div className="item-body">
            <div className="item-meta u-mb-s">
              <Link to={userProfileUrl} className="avatar-user notification-body_user-link">
                @{comment.userAtName}
              </Link> &mdash; <time dateTime={notification.createdAt} title={new Date(notification.createdAt)}>{moment(notification.createdAt).fromNow()}</time>
            </div>
            <div dangerouslySetInnerHTML={wrapHtml(commentBody)} />
            {attachmentPreview}
          </div>
        </div>
        <div className="notification-toolbar">
          <div className="notification-toolbar_dismiss-icon" onClick={this.handleDismissClick}>
            <Icon icon={notification.dismissedAt ? 'undo' : 'close'} width="20" height="20" data-tip={dismissTitle} />
          </div>
        </div>
      </div>
    );
  }

}

export default NotificationsItem;
