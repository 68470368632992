import {useMemo} from 'react';
import classNames from 'classnames';
import moment from 'moment';

import Icon from './_icon';
import {
  getNextDigestUTCDateTime,
  renderNextDigestSendTime,
  renderDigestScheduleString,
  getDigestAudienceStrings,
  getRivalsForTags
} from '../modules/digest_utils';
import DigestTimelineRivals from './_digest_timeline_rivals';
import DigestStats from './_digest_stats';
import {userCanCurate, userIsAdmin} from '../modules/roles_utils';
import DigestItemMoreOptions from './_digest_item_more_options';

const DigestTimelineItem = ({
  digest,
  isDraft,
  onDigestClick,
  onEditDigestSettings,
  onDeleteDigestType,
  onDeleteSentDigest,
  onMakeDefaultDigest,
  onToggleDeleteAfterSend,
  rivals,
  user
}) => {
  const {
    title,
    competitors,
    archivedAt,
    createdAt,
    manual,
    locked,
    useNewStats,
    favoritesLiveCount = 0,
    emailsDeliverCount = 0,
    digestType: {
      name,
      default: isDefault
    }
  } = digest || {};
  const isAdmin = userIsAdmin({user});
  const isCurator = userCanCurate({user});
  const rivalsForItem = getRivalsForTags((competitors || []).map(c => c[0]), rivals, {publishedOnly: Boolean(archivedAt)});
  const {audience} = getDigestAudienceStrings(digest);
  const {
    favoritesCount,
    itemCountInfo
  } = useMemo(() => {
    const count = favoritesLiveCount ?? 0;

    return {
      favoritesCount: count,
      itemCountInfo: count === 1
        ? '1 Story featuring:'
        : `${count} Stories featuring:`
    };
  }, [favoritesLiveCount]);

  const {
    nextDigestUTCDateTime,
    digestDate
  } = useMemo(() => {
    const digestDateTime = getNextDigestUTCDateTime(digest);

    return {
      nextDigestUTCDateTime: digestDateTime,
      digestDate: isDraft
        ? digestDateTime
          ? digestDateTime
          : null
        : moment(archivedAt || createdAt)
    };
  }, [digest, archivedAt, createdAt, isDraft]);

  const deliveryNotice = useMemo(() => {
    if(!archivedAt && manual) {
      return (
        <div className="digest-delivered">Custom Digest</div>
      );
    }

    if(archivedAt) {
      const notice = favoritesLiveCount === 0 && emailsDeliverCount <= 0 ? 'Empty Digest, not sent' : 'Delivered';

      return (
        <div className="digest-delivered">
          {`${(isCurator && emailsDeliverCount > 0 && useNewStats) ? (emailsDeliverCount + ' ') : ''}${notice}`}
        </div>
      );
    }

    if(!nextDigestUTCDateTime) {
      return (
        <div className="digest-time">
          <span>{renderNextDigestSendTime(nextDigestUTCDateTime, true)}</span>
        </div>
      );
    }

    return renderNextDigestSendTime(nextDigestUTCDateTime, true);
  }, [archivedAt, manual, isCurator, favoritesLiveCount, emailsDeliverCount, useNewStats, nextDigestUTCDateTime]);

  return (
    <div
      className={
      classNames('digest-timeline-item', {
        'digest-timeline-item--draft': isDraft
      })

    }
      data-tracking-id="digest-timeline-item"
      onClick={() => onDigestClick(digest)}>
      <div className="digest-timeline-item-info">
        <div className="digest-type-name">{name}{(isDraft && isDefault) ? <span className="default-digest-badge"> default</span> : null}</div>
        <div className="digest-timeline-item-title">
          <div>
            <Icon icon="envelope" width={20} height={20} />
          </div>
          <div className="digest-timeline-item-title-link">{title || 'Intel Updates from Klue'}</div>
        </div>
        {isCurator && <div className="digest-type-audience">
          <Icon icon="group" width={20} height={20} />
          <div className="digest-audience">{audience}</div>
        </div>}
        {favoritesCount > 0 ? <div className="digest-type-rivals">
          <div className="article-count">{itemCountInfo}</div>
          <DigestTimelineRivals rivals={rivalsForItem} />
        </div> : null}
      </div>
      <div className="digest-timeline-item-stats">
        <div className="digest-timeline-item-stats-header">
          <div>
            <div className="digest-timeline-delivery-status-and-locked">
              {deliveryNotice}{locked ? <Icon icon="lock" width={20} height={20} /> : null}
            </div>
            <div className="digest-timeline-item-stats-header-date-and-more">
              {digestDate && <div className="digest-timeline-item-stats-date">
                {digestDate.format('llll')} {Intl.DateTimeFormat().resolvedOptions().timeZone}
              </div>}
            </div>
          </div>
          <DigestItemMoreOptions
            digest={digest}
            isDraft={isDraft}
            editSettings={isAdmin ? onEditDigestSettings : null}
            onDeleteDigestType={isAdmin ? onDeleteDigestType : null}
            onDeleteSentDigest={onDeleteSentDigest}
            onMakeDefaultDigest={isAdmin ? onMakeDefaultDigest : null}
            onToggleDeleteAfterSend={isAdmin ? onToggleDeleteAfterSend : null} />
        </div>
        {(!archivedAt && !manual) ? <div className="date-time">{renderDigestScheduleString(digest || {})}</div> : null}
        {archivedAt ? <DigestStats
          digest={digest}
          showStats={isCurator} /> : null}
      </div>
    </div>
  );
};

DigestTimelineItem.propTypes = {
  digest: PropTypes.object,
  isDraft: PropTypes.bool,
  rivals: PropTypes.object,
  user: PropTypes.object,
  onDigestClick: PropTypes.func,
  onEditDigestSettings: PropTypes.func,
  onDeleteDigestType: PropTypes.func,
  onDeleteSentDigest: PropTypes.func,
  onMakeDefaultDigest: PropTypes.func,
  onToggleDeleteAfterSend: PropTypes.func
};

DigestTimelineItem.defaultProps = {
  digest: {},
  isDraft: false,
  rivals: {},
  user: {},
  onDigestClick() {},
  onEditDigestSettings: null,
  onDeleteDigestType: null,
  onDeleteSentDigest: null,
  onMakeDefaultDigest: null,
  onToggleDeleteAfterSend: null
};

export default DigestTimelineItem;
