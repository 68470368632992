import CompareEmployeeCountBuilder from './formula_builders/_compare_employee_count_builder';
import CompareRevenueGrowthBuilder from './formula_builders/_compare_revenue_growth_builder';
import OperatingMetricBuilder from './formula_builders/_operating_metric_builder';
import CompanyBuilder from './formula_builders/_company_builder';
import SalesforceBuilder from './formula_builders/_salesforce_builder';
import {wrapBuilder} from './formula_builders/wrap_builder';

// TODO: can't freeze this object, since it is being modified in _klue_formula_wizard.js via the export
export const functionBuilder = {
  keyPeople: {
    displayName: 'Key People',
    builderComponent: wrapBuilder('KEY_PEOPLE')(CompanyBuilder)
  },
  balanceSheetStatements: {
    displayName: 'Balance Sheet - Fiscal Year',
    builderComponent: wrapBuilder('BALANCE_SHEET_STATEMENTS')(CompanyBuilder)
  },
  balanceSheetStatementsQuarterly: {
    displayName: 'Balance Sheet - Quarterly',
    builderComponent: wrapBuilder('BALANCE_SHEET_STATEMENTS_QUARTERLY')(CompanyBuilder)
  },
  cashFlowStatements: {
    displayName: 'Cash Flow - Fiscal Year',
    builderComponent: wrapBuilder('CASH_FLOW_STATEMENTS')(CompanyBuilder)
  },
  cashFlowStatementsQuarterly: {
    displayName: 'Cash Flow - Quarterly',
    builderComponent: wrapBuilder('CASH_FLOW_STATEMENTS_QUARTERLY')(CompanyBuilder)
  },
  employeeCount: {
    displayName: 'Employee Count',
    builderComponent: wrapBuilder('EMPLOYEE_COUNT')(CompanyBuilder)
  },
  homepageScreenshot: {
    displayName: 'Homepage Screenshot',
    builderComponent: wrapBuilder('HOMEPAGE_SCREENSHOT')(CompanyBuilder)
  },
  operatingMetric: {
    displayName: 'Operating Metric History',
    builderComponent: OperatingMetricBuilder
  },
  revenues: {
    displayName: 'Revenue',
    builderComponent: wrapBuilder('REVENUES')(CompanyBuilder)
  },
  compareRevenues: {
    displayName: 'Revenues Growth',
    builderComponent: CompareRevenueGrowthBuilder
  },
  incomeStatements: {
    displayName: 'Income Statements - Fiscal Year',
    builderComponent: wrapBuilder('INCOME_STATEMENTS')(CompanyBuilder)
  },
  incomeStatementsQuarterly: {
    displayName: 'Income Statements - Quarterly',
    builderComponent: wrapBuilder('INCOME_STATEMENTS_QUARTERLY')(CompanyBuilder)
  },
  jobCategories: {
    displayName: 'Job Categories',
    builderComponent: wrapBuilder('JOB_CATEGORIES')(CompanyBuilder)
  },
  jobPostings: {
    displayName: 'Job Postings',
    builderComponent: wrapBuilder('JOB_POSTINGS')(CompanyBuilder)
  },
  jobPostingsByRegion: {
    displayName: 'Job Postings by Region',
    builderComponent: wrapBuilder('JOB_POSTINGS_BY_REGION')(CompanyBuilder)
  },
  officeLocations: {
    displayName: 'Employee Locations',
    builderComponent: wrapBuilder('OFFICE_LOCATIONS')(CompanyBuilder)
  },
  operatingMetricsSummary: {
    displayName: 'Operating Metrics',
    builderComponent: wrapBuilder('OPERATING_METRICS_SUMMARY')(CompanyBuilder)
  },
  similarWebsites: {
    displayName: 'Similar Websites',
    builderComponent: wrapBuilder('SIMILAR_WEBSITES')(CompanyBuilder)
  },
  engagementSummary: {
    displayName: 'Website Engagement Summary',
    builderComponent: wrapBuilder('ENGAGEMENT_SUMMARY')(CompanyBuilder)
  },
  websiteTrafficOrganicKeywords: {
    displayName: 'Organic Search Keywords',
    builderComponent: wrapBuilder('WEBSITE_TRAFFIC_ORGANIC_KEYWORDS')(CompanyBuilder)
  },
  trafficPageViews: {
    displayName: 'Website Page Views per Visit',
    builderComponent: wrapBuilder('TRAFFIC_PAGE_VIEWS')(CompanyBuilder)
  },
  websiteTrafficPaidKeywords: {
    displayName: 'Paid Search Keywords',
    builderComponent: wrapBuilder('WEBSITE_TRAFFIC_PAID_KEYWORDS')(CompanyBuilder)
  },
  trafficSources: {
    displayName: 'Website Traffic Sources',
    builderComponent: wrapBuilder('TRAFFIC_SOURCES')(CompanyBuilder)
  },
  recentPressReleases: {
    displayName: 'Recent Press Releases',
    builderComponent: wrapBuilder('RECENT_PRESS_RELEASES')(CompanyBuilder)
  },
  recentBlogPosts: {
    displayName: 'Recent Blog Posts',
    builderComponent: wrapBuilder('RECENT_BLOG_POSTS')(CompanyBuilder)
  },
  recentSocialUpdates: {
    displayName: 'Recent Social Updates',
    builderComponent: wrapBuilder('RECENT_SOCIAL_UPDATES')(CompanyBuilder)
  },
  recentCaseStudies: {
    displayName: 'Recent Case Studies',
    builderComponent: wrapBuilder('RECENT_CASE_STUDIES')(CompanyBuilder)
  },
  recentPublications: {
    displayName: 'Recent Publications',
    builderComponent: wrapBuilder('RECENT_PUBLICATIONS')(CompanyBuilder)
  },
  compareEmployeeCount: {
    displayName: 'Employee Growth',
    builderComponent: CompareEmployeeCountBuilder
  },
  companyOverview: {
    displayName: 'Company Overview',
    builderComponent: wrapBuilder('COMPANY_SUMMARY')(CompanyBuilder)
  },
  sfdcWins: {
    displayName: 'Win Rate',
    builderComponent: wrapBuilder('SFDC_WINS')(SalesforceBuilder)
  },
  sfdcLatestOpportunities: {
    displayName: 'Latest Opportunities',
    builderComponent: wrapBuilder('SFDC_LATEST_OPPORTUNITIES')(SalesforceBuilder)
  }
};
