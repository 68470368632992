import {useMemo, useCallback} from 'react';
import classNames from 'classnames';

import DigestMenuTitle from './_digest_menu_title';
import DigestMenuIconButton from './_digest_menu_icon_button';
import DigestMenuGroup from './_digest_menu_group';
import DigestMenuTextButton from './_digest_menu_text_button';

import {pluralize} from '../modules/text_utils';

const DigestMenuBuildAndEdit = ({
  editMode,
  isCurator,
  digest,
  favorites,
  canEditDigest,
  canAddCustomDigestItems,
  feedMode,
  archiveMode,
  reorderMode,
  digestLocked,
  savingDigest,
  editorBusy,
  editorBusyReason,
  hasUnsavedFields,
  onAddContent,
  onAddCustomContent,
  onToggleReorder,
  onToggleLock,
  onSaveDigest
}) => {
  const {archivedAt} = digest || {};

  const {title: saveTitle, dataTrackingId: saveDataTrackingId = ''} = useMemo(
    () => {
      if(reorderMode) {
        return {title: 'Done Reordering', dataTrackingId: 'done-reordering-digest-button'};
      }

      if(savingDigest || editorBusy) {
        if(editorBusy && editorBusyReason) {
          return {title: editorBusyReason};
        }

        return {title: 'Saving Digest...'};
      }

      return {title: 'Save Changes', dataTrackingId: 'save-digest-button'};
    },
    [savingDigest, editorBusy, editorBusyReason, reorderMode]);
  const busy = savingDigest || editorBusy;

  const handleToggleLock = useCallback(event => {
    event?.preventDefault?.();

    if(!canEditDigest || archivedAt || archiveMode) {
      return;
    }

    onToggleLock(event);
  }, [canEditDigest, archivedAt, archiveMode, onToggleLock]);

  const lockDataTrackingId = useMemo(() => {
    if(canEditDigest && !archivedAt && !archiveMode) {
      return digestLocked ? 'unlock-digest-button' : 'lock-digest-button';
    }

    return '';
  }, [canEditDigest, archivedAt, archiveMode, digestLocked]);

  const lockTitle = useMemo(() => {
    if(canEditDigest && !archivedAt && !archiveMode) {
      return digestLocked ? 'unlock' : 'lock';
    }

    if(!canEditDigest && digestLocked) {
      return 'digest locked';
    }
  }, [canEditDigest, archivedAt, archiveMode, digestLocked]);

  const lockIcon = useMemo(() => {
    if(canEditDigest && !archivedAt && !archiveMode) {
      return digestLocked ? 'lock' : 'unlock';
    }

    if(!canEditDigest && digestLocked) {
      return 'lock';
    }
  }, [canEditDigest, archivedAt, archiveMode, digestLocked]);

  const numberOfFavouritesText = useMemo(() => {
    const count = favorites.length ?? 0;

    return `${count} ${pluralize('item', count)} in this digest`;
  }, [favorites]);

  if(!isCurator || archivedAt || archiveMode) {
    return null;
  }

  const addKlueContentTitle = feedMode ? 'hide feed' : canAddCustomDigestItems ? 'add klue content' : 'add content';
  const showAddNewCustomDigestItem = canAddCustomDigestItems && canEditDigest;

  return (
    <DigestMenuGroup>
      <DigestMenuTitle title="build & edit" />
      {editMode && canEditDigest && <DigestMenuIconButton
        className={classNames({disabled: reorderMode})}
        dataTestId="toggle-feed-button"
        dataTrackingId={feedMode ? 'hide-feed-button' : 'add-content-button'}
        icon={feedMode ? 'toggle_on' : 'toggle_off'}
        title={addKlueContentTitle}
        onClick={onAddContent} />}
      {showAddNewCustomDigestItem &&
        <DigestMenuIconButton
          className={classNames({disabled: reorderMode})}
          dataTestId="add-content-button"
          dataTrackingId="add-content-button"
          icon="add"
          title="add custom content"
          onClick={onAddCustomContent} />}
      {editMode && canEditDigest && onToggleReorder &&
      <DigestMenuIconButton
        className={classNames({disabled: busy || hasUnsavedFields || reorderMode})}
        dataTestId="reorder-button"
        dataTrackingId="reorder-button"
        icon="reorder"
        title="reorder content"
        onClick={onToggleReorder} />}
      {lockIcon && lockTitle && <DigestMenuIconButton
        className={classNames({disabled: busy || hasUnsavedFields || reorderMode})}
        dataTestId="lock-button"
        dataTrackingId={lockDataTrackingId}
        icon={lockIcon}
        title={lockTitle}
        onClick={handleToggleLock} />}
      {editMode && canEditDigest && <DigestMenuTextButton
        title={saveTitle}
        dataTrackingId={saveDataTrackingId}
        onClick={reorderMode ? onToggleReorder : onSaveDigest}
        dataTestId="save-digest-button"
        className={classNames('primary', 'save', {
          disabled: !reorderMode && (busy || !hasUnsavedFields)
        })} />}
      {editMode && canEditDigest &&
        <div className="digest-attached-items-section">
          <div className="digest-attached-items-count">{numberOfFavouritesText}</div>
          <div className="digest-attached-items-info">Klue recommends 5-8 items.</div>
        </div>}
    </DigestMenuGroup>
  );
};

DigestMenuBuildAndEdit.propTypes = {
  editMode: PropTypes.bool,
  isCurator: PropTypes.bool,
  digest: PropTypes.object,
  favorites: PropTypes.arrayOf(PropTypes.object),
  canEditDigest: PropTypes.bool,
  canAddCustomDigestItems: PropTypes.bool,
  feedMode: PropTypes.bool,
  archiveMode: PropTypes.bool,
  reorderMode: PropTypes.bool,
  savingDigest: PropTypes.bool,
  editorBusy: PropTypes.bool,
  hasUnsavedFields: PropTypes.bool,
  editorBusyReason: PropTypes.string,
  digestLocked: PropTypes.bool,
  onAddContent: PropTypes.func,
  onAddCustomContent: PropTypes.func,
  onToggleReorder: PropTypes.func,
  onToggleLock: PropTypes.func,
  onSaveDigest: PropTypes.func
};

DigestMenuBuildAndEdit.defaultProps = {
  editMode: false,
  isCurator: false,
  digest: null,
  favorites: [],
  canEditDigest: false,
  canAddCustomDigestItems: false,
  feedMode: false,
  archiveMode: false,
  reorderMode: false,
  savingDigest: false,
  editorBusy: false,
  hasUnsavedFields: false,
  editorBusyReason: 'Uploading Image...',
  digestLocked: false,
  onAddContent() {},
  onAddCustomContent() {},
  onToggleReorder() {},
  onToggleLock() {},
  onSaveDigest() {}
};

export default DigestMenuBuildAndEdit;
