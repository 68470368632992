import DigestTimelineItemCompetitor from './_digest_timeline_item_competitor';

const DigestTimelineRivals = ({rivals}) => {
  if(!rivals || !rivals.length) {
    return null;
  }

  const extraRivalsCount = rivals.length > 3
    ? <span className="plus-rivals" key="extra-rivals-count">{`+${rivals.length - 3}`}</span>
    : null;

  const rivalComps = rivals.map(rival => {
    const {id: rivalId} = rival || {};

    return (<DigestTimelineItemCompetitor
      data-testid="digest-timeline-item-competitor"
      key={`digest-timeline-item-competitor-${rivalId}`}
      rival={rival} />);
  });

  if(extraRivalsCount) {
    rivalComps.splice(3, rivals.length - 3, extraRivalsCount);
  }

  return rivalComps;
};

DigestTimelineRivals.propTypes = {
  rivals: PropTypes.arrayOf(PropTypes.object)
};

DigestTimelineRivals.defaultProps = {
  rivals: []
};

export default DigestTimelineRivals;
