class FeedAdder extends React.Component {

  static propTypes = {
    mode: PropTypes.string,
    top: PropTypes.number,
    left: PropTypes.number,
    onAdderClick: PropTypes.func
  };

  static defaultProps = {
    mode: 'DEFAULT',    // options: DEFAULT, SAVING, SAVED
    top: 0,
    left: 0,
    onAdderClick: null
  };

  componentDidMount() {
    // DEBUG
    console.log('FeedAdder.componentDidMount: props: %o', this.props);
  }

  render() {
    const adderStyles = {
      top: `${this.props.top}px`,
      left: `${this.props.left}px`
    };
    let adderIcon;
    let adderClass = 'feed-adder';

    switch(this.props.mode) {
      case 'SAVING':
        adderClass += ' feed-adder--saving';
        break;
      case 'SAVED':
        adderClass += ' feed-adder--saved';
        adderIcon = (
          <div className="feed-adder_icon"><i className="fa fa-check-circle feed-adder_icon_inner" /></div>
        );
        break;
      case 'DEFAULT':
      default:
        // default state, nothing to do here
        break;
    }

    return (
      <div className={adderClass} style={adderStyles} onClick={this.props.onAdderClick}>
        {adderIcon}
      </div>
    );
  }

}

export default FeedAdder;
