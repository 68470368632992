import {percentage} from '../../../modules/number_utils';

import classNames from 'classnames';

const COLOUR_THRESHOLD = 0.01;

const KeywordStatsTable = ({keywordStats}) => {
  const top10Keywords = keywordStats.slice(0, 10);
  const showChangeColumn = top10Keywords.some(({change}) => change !== null);

  const rows = top10Keywords.map(({keyword, share, change}) => {
    let changeColumnCell = null;

    if(showChangeColumn) {
      changeColumnCell = (
        <td
          className="klue-table_cell keyword-stats-table_cell--numeric">
          <span
            className={classNames({
              'keyword-stats-table_change--positive': change - COLOUR_THRESHOLD > 0.0,
              'keyword-stats-table_change--negative': change + COLOUR_THRESHOLD < 0.0
            })}>
            {change === null ? '' : percentage(change, true)}
          </span>
        </td>
      );
    }

    return (
      <tr
        className="klue-table_row"
        key={keyword}>
        <td className="klue-table_cell">{keyword}</td>
        <td
          className="klue-table_cell keyword-stats-table_cell--numeric">
          {percentage(share)}
        </td>
        {changeColumnCell}
      </tr>
    );
  });

  let changeColumnHeader = null;

  if(showChangeColumn) {
    changeColumnHeader = (<th className="klue-table_cell klue-table_cell--header keyword-stats-table_cell--numeric">Change</th>);
  }

  return (
    <table className="klue-table keyword-stats-table">
      <thead>
        <tr className="klue-table_row klue-table_row--header">
          <th className="klue-table_cell klue-table_cell--header">Keyword</th>
          <th className="klue-table_cell klue-table_cell--header keyword-stats-table_cell--numeric">Share</th>
          {changeColumnHeader}
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
};

KeywordStatsTable.propTypes = {
  keywordStats: PropTypes.arrayOf(PropTypes.shape({
    keyword: PropTypes.string.isRequired,
    share: PropTypes.number.isRequired,
    change: PropTypes.number
  })).isRequired
};

KeywordStatsTable.defaultProps = {
  keywordStats: []
};

export default KeywordStatsTable;
