const DigestHeaderSuperscript = ({text}) => {
  return (
    <div className="header-superscript">
      {text}
    </div>
  );
};

DigestHeaderSuperscript.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

DigestHeaderSuperscript.defaultProps = {
  text: 'Intel Digest'
};

export default DigestHeaderSuperscript;
