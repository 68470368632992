/* eslint-disable no-unused-vars */
import {fetch} from '../../modules/api_utils';
import {isValidId} from '../../modules/utils';
import {mergeProfileIntoRival} from '../../modules/rival_utils';
import {RIVALS_GLOBAL_PER_PAGE} from '../../modules/constants/api';
import {isEmpty} from 'lodash';
import ReactUpdate from 'immutability-helper';

export const initialState = {
  items: {},
  profileIdToRivalId: {},
  isLoaded: false
};

/**
 * @name Rivals model
 * See: https://github.com/rematch/rematch/
 *
 * @type Redux model
 * @description Stores all rivals and make it accessible to any component
 * https://rematch.netlify.app/#/plugins/immer?id=immer-plugin
 */
export default {
  state: initialState,
  reducers: {
    populate(state, payload) {
      const {rivalsById, profileIdToRivalId} = payload;

      return {...state, items: {...state.items, ...rivalsById}, profileIdToRivalId: {...state.profileIdToRivalId, ...profileIdToRivalId}};
    },
    reset() {
      return initialState;
    },
    complete(state) {
      return {
        ...state,
        isLoaded: true
      };
    },
    updateRivalWithRival(state, rival) {
      if(isEmpty(rival)) {
        return state;
      }

      const {id, profile} = rival;
      const {id: profileId} = profile || {};

      if(isValidId(id)) {
        const rivalsState = {};
        const profileState = {};
        const {items, profileIdToRivalId} = state;

        rivalsState[id] = {$set: rival};

        if(profileId) {
          profileState[profileId] = {$set: id};
        }

        return {...state, items: ReactUpdate(items, rivalsState), profileIdToRivalId: ReactUpdate(profileIdToRivalId, profileState)};
      }

      return state;
    },
    updateRivalWithProfile(state, profile) {
      if(isEmpty(profile)) {
        return state;
      }

      const {id} = profile;

      if(isValidId(id)) {
        const {items, profileIdToRivalId} = state;
        const rivalId = profileIdToRivalId[id];

        if(rivalId) {
          const riv = items[rivalId];

          if(riv) {
            const updatedRival = mergeProfileIntoRival(riv, profile);

            return {...state, items: {...items, [rivalId]: updatedRival}};
          }
        }
      }

      return state;
    }
  },
  effects: dispatch => ({
    /**
     * Recursively load all rivals and populate global store
     *
     * @name fetchAllRivals
     * @param {object} payload Any param passed to the function
     * @param {object} rootState global redux state
     *
     * @description see app/controllers/api/rivals_controller.rb for a list of all accepted params
     */

    async fetchAllRivals(payload = {}, rootState) {
      let totalLoaded = 0;

      const fetchAndPopulate = async query => {
        const search = new URLSearchParams({
          limit: RIVALS_GLOBAL_PER_PAGE,
          page: 1,
          ...query
        }).toString();

        return fetch(`/api/rivals.json?v=3&${search}`)
          .then(({data = {}}) => {
            const {totalItems, items = []} = data;

            if(!items || !items.length) {
              return;
            }

            // Map rivals to Object and profile ids to rival ids.
            const profileIdToRivalId = {};
            const rivalsById = items.reduce((ac, cur) => {
              const {id, profile: {id: profileId}} = cur;

              if(id) {
                ac[id] = cur;

                if(profileId) {
                  profileIdToRivalId[profileId] = id;
                }
              }

              return ac;
            }, {});

            dispatch.rivals.populate({rivalsById, profileIdToRivalId});

            totalLoaded = totalLoaded + items.length;

            // recursive load rivals
            if((totalLoaded < totalItems) && (items.length < totalItems)) {
              return fetchAndPopulate({...query, page: query.page + 1});
            }

            dispatch.rivals.complete();
          });
      };

      try {
        fetchAndPopulate({
          page: 1,
          ...payload
        });
      }
      catch(error) {
        console.warn('error', error);
        // TODO: what to do if rivals can't load
      }
    },

    /**
     * Don't fetch rivals from API if data is loaded
     *
     * @param {object} payload Rivals query params
     * @param {object} rootstate redux state
     * @returns {object} rivals collection
     */
    async loadOrFetchRivals(payload = {}, rootstate) {
      if(rootstate.rivals.isLoaded) {
        return rootstate.items;
      }

      dispatch.rivals.fetchAllRivals(payload);
    }
  })
};
