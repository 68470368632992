import FunctionCall from './function_call';
import Parser, {FUNC_CALL} from './parser';

function validateParamType(lib, givenParam, paramDefinition) {
  if(!paramDefinition || !paramDefinition.type) {
    throw new Error(`Invalid parameter definition: ${JSON.stringify(paramDefinition)}`);
  }

  if(givenParam instanceof FunctionCall) {
    const paramType = lib.find(givenParam.name).returns.type;

    if(paramType !== paramDefinition.type) {
      throw new Error('Function passed as parameter returns wrong type');
    }

    validateFunctionCall(lib, givenParam);   // eslint-disable-line no-use-before-define
  }
  else if(!(paramDefinition.type.validate(givenParam))) {
    throw new Error('Unexpected parameter type');
  }
}

function validateFunctionCall(lib, functionCall) {
  const mapped = lib.find(functionCall.name);

  if(!mapped) {
    throw new Error(`Function ${functionCall.name} is not defined.`);
  }

  // Validate params
  const libParams = mapped.parameters;

  if(libParams.length === 0) {
    if(functionCall.params.length > 0) {
      throw new Error('Too many parameters in call to ' + functionCall.name);
    }

    return;
  }

  if(libParams.length > functionCall.params.length) {
    throw new Error('Not enough parameters in call to ' + functionCall.name);
  }

  const lastParam = libParams[libParams.length - 1];
  const variadicParams = (lastParam.variadic);

  if(!variadicParams && libParams.length < functionCall.params.length) {
    throw new Error('Too many parameters in call to ' + functionCall.name);
  }

  // Number of parameters is okay so let's confirm types
  for(let i = 0; i < libParams.length; i++) {
    const libParam = libParams[i];
    const given = functionCall.params[i];

    validateParamType(lib, given, libParam);
  }

  if(variadicParams && functionCall.params.length > libParams.length) {
    // Validate types on variadic params
    for(let i = libParams.length; i < functionCall.params.length; i++) {
      const given = functionCall.params[i];

      validateParamType(lib, given, lastParam);
    }
  }

  // Looks good!
  return;
}

function execute(lib, functionCall) {
  const mappedFunc = lib.find(functionCall.name);

  if(!mappedFunc) {
    throw new Error('Unknown function: ' + functionCall.name);
  }

  if(!mappedFunc.handler) {
    throw new Error(`Invalid handler for function ${functionCall.name}. Found: ${mappedFunc.handler}`);
  }

  const params = functionCall.params.map(p => {
    if(p instanceof FunctionCall) {
      return execute(lib, p);
    }

    return Promise.resolve(p);
  });

  const fParams = Promise.all(params);

  const fResult = fParams.then(resolvedParams => {
    return mappedFunc.handler(...resolvedParams);
  }).then(retVal => {
    if(mappedFunc.returns && mappedFunc.returns.type && !mappedFunc.returns.type.validate(retVal)) {
      throw new Error(
        `Invalid return value from function "${functionCall.name}". Expected: ${mappedFunc.returns.type.name}; Received: ${JSON.stringify(retVal)}`
      );
    }

    return retVal;
  });

  return fResult;
}

class FormulaEngine {

  constructor(library) {
    this.library = library;
  }

  parse(code) {
    const tree = Parser.parse(code.trim());

    if(tree === null) {
      console.log('Invalid block.', code);
      throw new Error('Invalid code block. No valid function call found.');
    }

    return tree;
  }

  evaluate(astRoot) {
    const funcCallNodes = astRoot.findChildren(FUNC_CALL);

    if(funcCallNodes.length !== 1) {
      throw new Error('Expected FUNC_CALL in block');
    }

    const fc = FunctionCall.fromNode(funcCallNodes[0]);

    return fc;
  }

  // Parses and executes the code and returns a promise which contains the result of the execution.
  execute(code) {
    const ast = this.parse(code);
    const executable = this.evaluate(ast);

    validateFunctionCall(this.library, executable);

    return execute(this.library, executable);
  }

}

export default FormulaEngine;
