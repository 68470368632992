import React from 'react';
import PropTypes from 'prop-types';

import KlueTypeComponentFactory from './_klue_type_component_factory';
import PotentiallyLongContent from './_potentially_long_content';

const EntityList = ({elements, cardId, cardCreatedAt, isDraft, analyticsEventContext}) => {
  if(!elements || elements.length === 0) {
    return (<p>No results for now. We&#39;ll automatically add anything we find here.</p>);
  }

  let i = 0;
  const entities = elements.map(e => (
    <li className="entity-list_list-item" key={i++}>
      <KlueTypeComponentFactory
        val={e}
        cardId={cardId}
        cardCreatedAt={cardCreatedAt}
        isDraft={isDraft}
        analyticsEventContext={analyticsEventContext} />
    </li>
  ));

  return (
    <PotentiallyLongContent>
      <ul className="entity-list">
        {entities}
      </ul>
    </PotentiallyLongContent>
  );
};

EntityList.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.any).isRequired,
  cardId: PropTypes.number.isRequired,
  cardCreatedAt: PropTypes.string,
  isDraft: PropTypes.bool,
  analyticsEventContext: PropTypes.object
};

EntityList.defaultProps = {
  elements: [],
  cardCreatedAt: '',
  isDraft: false,
  analyticsEventContext: {}
};

export default EntityList;
