export const testId = 'spinner';

const Spinner = ({className}) => {
  return (
    <div data-testid={testId} className={`spinner ${className ?? ''}`} />
  );
};

Spinner.propTypes = {
  className: PropTypes.string
};

Spinner.defaultProps = {
  className: undefined
};

export default Spinner;
