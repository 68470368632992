import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {cardGet} from '../modules/api/cards';
import {rivalGet} from '../modules/api/rivals';

import CardDisplay from './_card_display';

const CardEmbed = () => {
  const [card, setCard] = useState({});
  const [rival, setRival] = useState({});
  const {cardId} = useParams();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        const code = 'CardEmbed';
        const cardResult = await cardGet({cardOptions: {cardId}, code});
        const rivalResult = await rivalGet({profileId: cardResult.board.profileId, code});

        setRival(rivalResult);
        setCard(cardResult);
      }
      catch(error) {
        console.error('Card: error: %o', error);

        // requested card deleted or doesn't exist
        return history.push('/');
      }
    }

    fetchData();
  }, [cardId, history]);

  return (
    <div className="card-embed">
      {!_.isEmpty(card) && <CardDisplay card={card} rival={rival} />}
    </div>
  );
};

export default CardEmbed;
