import SurveySuccessfullySubmitted from './win_loss/_responded_survey/_success';
import {
  SurveyPage,
  SurveyPageContainer,
  SurveyTitle
} from './win_loss/_styles';
import {labels} from './win_loss/_constants';

const WinLossSurveySuccess = () => {
  return (
    <SurveyPage>
      <SurveyPageContainer>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}>
          <img width="100px" src="/logo-klue-white.png" />
          <SurveyTitle aria-level={1} textLength={labels.title.length}>
            {labels.title}
          </SurveyTitle>
        </div>
        <SurveySuccessfullySubmitted />
      </SurveyPageContainer>
    </SurveyPage>
  );
};

export default WinLossSurveySuccess;
