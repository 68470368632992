import {isEmpty} from 'lodash';
import {isValidId} from '../utils';
import {fetch, post, destroy} from '../api_utils';

export const fetchCardSources = (card, code = 'ApiUtils.fetchCardSources') => {
  const {id: cardId} = card || {};

  if(!cardId) {
    return Promise.reject(`${code} invalid parameters`);
  }

  const url = `/api/cards/${cardId}/sources.json`;

  return new Promise((resolve, reject) => {
    fetch(url, {code})
      .then(response => {
        const {data: sources} = response;

        resolve({sources: (sources || []).sort(({createdAt: a}, {createdAt: b}) => Date.parse(b) - Date.parse(a))});
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

/**
 * Source Options
 *
 * @typedef {object} sourceOptions
 * @property {number} cardId required id of card that requires a new source
 * @property {number=} commentId Optional, Specifies the Comment we want to create a Source from
 * @property {string=} url Optional, If commentId is not provided, provide a URL
 * @property {string=} description Optional, a text to describe why this source is of value
 */
/**
 * Create Card Source
 *
 * @param {{sourceOptions: sourceOptions, code: string}} params options + caller identifer code
 * @returns {Promise} a promise that's resolved with the created source or rejected with invalid source options or an error.
 */
export const createCardSources = ({sourceOptions = {}, code = 'ApiUtils.createCardSources'}) => {
  if(isEmpty(sourceOptions)) {
    return Promise.reject(null);
  }

  const {cardId, url: sourceUrl, commentId} = sourceOptions;

  if(!isValidId(cardId) || (!sourceUrl && !commentId)) {
    return Promise.reject(null);
  }

  const url = `/api/cards/${cardId}/sources`;

  return new Promise((resolve, reject) => {
    post(url, JSON.stringify(sourceOptions), {code})
      .then(({data: source}) => {
        resolve(source);
      })
      .catch(() => {
        reject(null);
      });
  });
};

/**
 * Source Options
 *
 * @typedef {object} sourceOptions
 * @property {number} id required id of source we want to delete.
 */
/**
 * Delete Card Source
 *
 * @param {{sourceOptions: sourceOptions, code: string}} params options + caller identifer code
 * @returns {Promise} a promise that's resolved with nothing on success or rejected with invalid source options or an error.
 */
export const deleteCardSource = ({sourceOptions = {}, code = 'ApiUtils.deleteCardSource'}) => {
  //   sourceOptions: {
  //     id: int - required id of the source that we want to delete
  //   }
  if(isEmpty(sourceOptions)) {
    return Promise.reject(sourceOptions);
  }

  const {id} = sourceOptions;

  if(!isValidId(id)) {
    return Promise.reject(sourceOptions);
  }

  const url = `/api/sources/${id}`;

  return new Promise((resolve, reject) => {
    destroy(url, {code})
      .then(({data}) => {
        console.log(`${code}: source #%o deleted successfully, update comments: %o`, id, data);

        resolve();
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};
