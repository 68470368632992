export const abbreviate = (value, opts = {}) => {
  // Parse options
  const accounting = (opts && opts.accounting) || false;
  const integer = (opts && opts.integer) || false;

  if(typeof value !== 'number') {
    return value;
  }

  let newValue = value;
  const neg = value < 0;

  if(neg) {
    newValue = 0 - newValue;
  }

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;

  while(newValue >= 1000 && suffixNum < (suffixes.length - 1)) {
    newValue /= 1000;
    suffixNum++;
  }

  const fmtOptions = {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    minimumIntegerDigits: 1
  };

  if(suffixNum > 0 || (!integer && newValue >= 1)) {
    fmtOptions.maximumSignificantDigits = 3;
    fmtOptions.minimumSignificantDigits = 3;
  }
  else if(integer) {
    fmtOptions.maximumFractionDigits = 0;
    fmtOptions.minimumFractionDigits = 0;
  }

  newValue = newValue.toLocaleString('en-US', fmtOptions);

  if(neg && !accounting) {
    newValue = 0 - newValue;
  }

  newValue += suffixes[suffixNum];

  if(neg && accounting) {
    newValue = `(${newValue})`;
  }

  return newValue;
};

export const percentage = (value, change = false) => {
  const abs = Math.abs(value);
  const fmtOptions = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    minimumIntegerDigits: 1
  };

  if(abs >= 0.01) {
    fmtOptions.minimumSignificantDigits = 3;
    fmtOptions.maximumSignificantDigits = 3;
  }

  const changeIndicator = change && value > 0 ? '+' : '';

  return `${changeIndicator}${value.toLocaleString('en-US', fmtOptions)}`;
};
