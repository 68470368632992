import CardSFDCLatestOpp from './_card_sfdc_latest_opp';
import SFDCOptions from './_card_sfdc_options';
import CardSFDCWins6Months from './_card_sfdc_wins_six_months';

export const ComponentSFDC = Object.freeze({
  SFDC_LATEST_OPPORTUNITIES: {desc: 'Latest Opportunities', El: CardSFDCLatestOpp, Options: SFDCOptions},
  SFDC_WINS: {desc: 'Win Rate', El: CardSFDCWins6Months, Options: SFDCOptions}
});

export const SalesforceInfoContent = ({instance}) => (instance ? (
  <div className="klue-formula-sfdc--info">
    <h6>You are currently logged into: <strong>{instance}</strong></h6>
  </div>
) : null);

SalesforceInfoContent.propTypes = {
  instance: PropTypes.string.isRequired
};

SalesforceInfoContent.defaultProps = {
  instance: ''
};
