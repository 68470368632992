import Icon from '../_icon';

const EditorToolbarCardLinkDisclosure = ({id, open, onClick, title, dataTestId}) => {
  return (
    <div key={id} className="card-link-disclosure" data-testid={dataTestId} onClick={onClick}>
      <Icon
        icon={open ? 'arrow-down' : 'arrow-right'}
        width={18}
        height={18} />
      <label className="lane-name-label">
        {title}
      </label>
    </div>
  );
};

EditorToolbarCardLinkDisclosure.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
  dataTestId: PropTypes.string
};

EditorToolbarCardLinkDisclosure.defaultProps = {
  id: null,
  open: false,
  onClick() {},
  title: 'untitled',
  dataTestId: ''
};

export default EditorToolbarCardLinkDisclosure;
