import classNames from 'classnames';
import {useRef} from 'react';
import {useTags} from '../../contexts/_tags';
import Dropdown from '../_dropdown';
import Icon from '../_icon';
const ENTER_KEY = 'Enter';

const useAvailableTags = cardTags => {
  const {tags} = useTags();
  const availableTags = [];

  if(tags) {
    for(const tag of tags) {
      if(!cardTags.has(tag.id)) {
        availableTags.push(tag);
      }
    }
  }

  return {
    tags: availableTags
  };
};

export const AddButton = ({tags: cardTags, onAddTag}) => {
  const buttonRef = useRef();
  const {tags} = useAvailableTags(new Set(cardTags.map(t => t.id)));
  const disabled = !tags.length;
  const className = classNames(
    'card-tags-add-button',
    {
      'card-tags-add-button-disabled': disabled
    }
  );

  const handleOptionClick = tagId => {
    const addedTag = tags.find(({id}) => id === tagId);

    onAddTag(addedTag);
  };

  const handleKeyDown = event => {
    if(event.key === ENTER_KEY) {
      event.preventDefault();
      event.target.click();
    }
  };

  const handleToggle = open => {
    if(!open) {
      buttonRef.current.focus();
    }
  };

  return (
    <Dropdown
      options={tags.map(({id, name}) => ({value: id, label: name}))}
      onOptionClick={handleOptionClick}
      onToggle={handleToggle}
      disabled={disabled}
      showFilter={true}
      button={(
        <button ref={buttonRef} className={className} title="Add Tag" tabIndex={0} disabled={disabled} onKeyDown={handleKeyDown}>
          <Icon className="card-tags-add-button-icon" icon="add" width={18} height={18} />
          Add Tag
        </button>
      )} />
  );
};

AddButton.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddTag: PropTypes.func.isRequired
};

export default AddButton;
