import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from './_icon';

const MessageBar = ({children, dismissable, large, onDismiss}) => {
  const [visible, setVisible] = React.useState(true);

  if(!visible) {
    return null;
  }

  return (
    <div className={classNames('message-bar', {'message-bar-large': large})}>
      <div className="message-bar--content">{children}</div>
      {dismissable && <Icon className="message-bar--close"
        onClick={() => {
          setVisible(false);

          return typeof onDismiss === 'function' && onDismiss();
        }}
        icon="close"
        width="16"
        height="16" />}
    </div>
  );
};

MessageBar.propTypes = {
  children: PropTypes.any.isRequired,
  dismissable: PropTypes.bool,
  large: PropTypes.bool
};

MessageBar.defaultProps = {
  dismissable: true,
  large: false
};

export default MessageBar;
