import {useState} from 'react';
import {post} from '../../modules/api_utils';

const AlertsSubscriptionSupport = ({rival: {name, id}}, {utils}) => {
  const {dialog, user, company} = utils;
  const [requestText, setRequestText] = useState('');

  const handleRequestSubmit = () => {
    if(!user || !company || !requestText) {
      return;
    }

    const body = {
      to: 'support',
      subject: `QUERY ALERT REQUEST: ${company.name} (${name})`,
      formName: 'Metabase Query',
      replyTo: user.email,
      body: `
      userEmail: ${user.email}
      userName: ${user.name}
      instance: ${company.name}
      competitor: (rival) ${name}
      competitorId: (rival id) ${id}
      message: ${requestText}
      `.trim()
    };
    const code = 'AlertsSubscriptionSupport.postSupportMail';

    post('/api/email/inbound.json', JSON.stringify(body), {code})
      .then(() => {
        dialog.remove('modal-rival-settings');
        dialog.alert(
        `Thanks. Your request to change alert terms for "${name}" has been submitted.`
        );
      })
      .catch(error => console.log(error, code));
    setRequestText('');
  };

  return (
    <div className="subscription-support">
      <p>
        <strong>Request a change to the alert&nbsp;terms</strong> for<br />&quot;{name}&quot;:
      </p>
      <p className="help-block">
        Please describe what kind of articles you&apos;d like to see, using
        AND/OR&ndash;type logic if possible. (We&apos;ll reply to you at:{' '}
        <strong>{user.email}</strong>)
      </p>
      <textarea
        className="form-control u-mb-xs request-input"
        rows="5"
        style={{resize: 'vertical'}}
        defaultValue={requestText}
        onChange={e => setRequestText(e.target.value)} />
      <div className="text-right u-pt-xs">
        <div
          className="button button--disabled"
          onClick={() => dialog.remove('modal-rival-settings')}>
          Cancel
        </div>
        <div
          className="button button-send"
          onClick={handleRequestSubmit}>
          Send Request
        </div>
      </div>
    </div>
  );
};

AlertsSubscriptionSupport.propTypes = {
  rival: PropTypes.object.isRequired
};

AlertsSubscriptionSupport.defaultProps = {
  rival: {}
};
AlertsSubscriptionSupport.contextTypes = {
  utils: PropTypes.object.isRequired
};

export default AlertsSubscriptionSupport;
