import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaEditor, {DEFAULTS} from 'froala-editor';
import {useState, useRef, forwardRef, useImperativeHandle} from 'react';
import {
  handleImageUpload2,
  handleImageBeforeRemove,
  updateToolbarButtonsBasedOnFeatureFlags,
  updateButtonGroupBasedOnFeatureFlags,
  getEditorColors,
  fontSizeHtmlString,
  handleFontSizeChange,
  handleFontSizeMenuRefresh,
  handleBeforeCleanup,
  handleUrlLinked,
  handleCardImageLinkButtonState,
  getEditorLinkInfo,
  getPasteAllowedStyle
} from '../../../modules/editor_utils';
import {disallowedDigestTags} from '../../../modules/html_utils';

const toolbarButtons = [
  ['paragraphFormat', 'fontSizeLabelled', 'bold', 'italic', 'clearFormatting'],
  ['textColor', 'backgroundColor'],
  ['formatOL', 'formatUL'],
  ['align'],
  ['insertLink'],
  ['insertImage']
];

const imageEditButtons = ['imageRemove', 'imageReplace', '|', 'imageAlign', '|', 'imageLink', 'linkEdit', 'linkRemove'];

export const defaultConfig = {
  attribution: false,
  autofocus: false,
  charCounterCount: false,
  colorsButtons: [],
  colorsHEXInput: false,
  colorsStep: 4,
  htmlAllowedEmptyTags: [...DEFAULTS.htmlAllowedEmptyTags, 'i'],
  imageDefaultAlign: 'left',
  imageDefaultMargin: 0,
  imageDefaultWidth: 0,
  imageEditButtons,
  imageInsertButtons: ['imageBack', 'imageUpload'],
  imageMaxSize: 1024 * 1024 * 3, // a totally arbitrary 3MB image max size (froala default is 10MB)
  imageMultipleStyles: false,
  imageResize: false,
  imageUploadRemoteUrls: false,
  linkAlwaysBlank: true,
  linkAutoPrefix: 'https://',
  linkEditButtons: ['linkEdit', 'linkRemove'],
  linkInsertButtons: ['linkBack'],
  listAdvancedTypes: false,
  paragraphDefaultSelection: 'Subheading',
  paragraphFormat: {
    H3: 'Heading',
    H4: 'Subheading',
    N: 'Normal'
  },
  paragraphMultipleStyles: false,
  pasteAllowedStyleProps: ['font-size', 'color', 'text-align', 'font-weight', 'background-color'],
  pasteDeniedAttrs: ['class', 'id'],
  pasteDeniedTags: disallowedDigestTags,
  quickInsertEnabled: true,
  quickInsertButtons: ['ul', 'ol', 'image'],
  toolbarButtons,
  toolbarInline: true,
  toolbarVisibleWithoutSelection: false,
  useClasses: false,
  // If you change the folowing z-index, you may need to update the z-index for
  // .tag--add-board-dropdown .ui.dropdown-menu in digest.scss
  zIndex: 1000 // z-index for froala editor toolbar & popups. Although it appears to affect the z-index of the editor itself as well.
};

FroalaEditor.RegisterCommand('fontSizeLabelled', {
  title: 'Font Size',
  type: 'dropdown',
  icon: 'font-size--labelled',
  undo: true,
  focus: true,
  refreshAfterCallback: true,
  // NOTE: needed for custom formatting of dropdown list options (instead of options property)
  html: fontSizeHtmlString,
  callback(cmd, val) {
    handleFontSizeChange(val, this);
  },
  refreshOnShow(btn, dropdown) {
    handleFontSizeMenuRefresh(dropdown, this);
  }
});

const InlineEditor = forwardRef((
  {identifier: id, extraClass, placeholder, onUpdate, text, data, onShowInsertLink, context, onImageUploadStatus, onInitialized, events},
  ref
) => {
  const [model, setModel] = useState(text);
  const [initialized, setInitialized] = useState(false);
  const extraClassName = extraClass ? ' ' + extraClass : '';
  const editorRef = useRef();
  const resetAsCleanText = useRef(false);
  const toolbarButtonsOverride = updateToolbarButtonsBasedOnFeatureFlags({buttons: toolbarButtons, context});
  const imageEditButtonsOverride = updateButtonGroupBasedOnFeatureFlags(imageEditButtons, context);
  const {utils: {company}} = context;
  const colors = getEditorColors({company});

  useImperativeHandle(ref, () => ({
    getEditorRef() {
      return editorRef ? editorRef.current : null;
    },
    setText(value, dirty = true) {
      resetAsCleanText.current = initialized && !dirty;
      setModel(value);
    },
    focus() {
      const {editor} = editorRef ? editorRef.current : null;

      if(!editor) {
        return;
      }

      editor.events.focus();
    }
  }));

  const handleCardLinkSelector = editor => {
    const position = FroalaEditor.PLUGINS.cardLinks(editor).getPosition();
    const {toolbarPosition} = position;
    const {urlHRef, urlText} = getEditorLinkInfo(editor);

    onShowInsertLink({
      top: toolbarPosition.top,
      left: toolbarPosition.left + position.left,
      urlHRef,
      urlText,
      editor
    });
  };

  const handleCardImageLinkSelector = editor => {
    const position = FroalaEditor.PLUGINS.cardLinks(editor).getImageEditPopupPosition();
    const {popupPosition} = position;

    editor?.popups?.hide('image.edit');

    onShowInsertLink({
      top: popupPosition.top,
      left: popupPosition.left + position.left,
      editor,
      targetImage: editor?.image?.get()
    });
  };

  FroalaEditor.RegisterCommand('klue-card-link-selector', {
    title: 'Insert Link',
    icon: 'insertLink',
    focus: false,
    undo: false,
    plugin: 'cardLinks',
    callback() {
      handleCardLinkSelector(this);
    }
  });

  FroalaEditor.RegisterCommand('klue-card-image-link-selector', {
    title: 'Insert Link',
    icon: 'insertLink',
    focus: false,
    undo: false,
    plugin: 'cardLinks',
    refreshAfterCallback: true,
    callback() {
      handleCardImageLinkSelector(this);
    },
    refresh($btn) {
      handleCardImageLinkButtonState($btn, this);
    }
  });

  const {appData: {editorClientKey = ''}} = context;

  const handleBeforeImageUpload = images => {
    const {editor} = editorRef ? editorRef.current : null;

    if(!editor) {
      return;
    }

    return handleImageUpload2({
      editor,
      images,
      onImageUploadStatus,
      statusData: data
    });
  };

  const handleInitialized = () => {
    setInitialized(true);
    onInitialized({...data});
  };

  const commandsAfter = (command, value) => {
    if(command !== 'paragraphFormat') {
      return;
    }

    const {editor} = editorRef ? editorRef.current : null;

    if(!editor) {
      return;
    }

    const blocks = editor.selection.blocks() || [];

    blocks.forEach(block => {
      switch(value) {
        case 'H3':
          block.style.fontWeight = '700';
          block.style.fontSize = '16px';
          block.style.margin = '24px 0 10px';
          break;
        case 'H4':
          block.style.fontWeight = '700';
          block.style.fontSize = '14px';
          block.style.margin = '20px 0 10px';
          break;
        case 'N':
          block.style.fontWeight = 'normal';
          block.style.fontSize = '14px';
          block.style.margin = '0';
          block.style.marginTop = '8px';
          break;
        default: break;
      }
    });
  };

  const {pasteAllowedStyleProps, wordAllowedStyleProps} = getPasteAllowedStyle({
    defaultConfig,
    context
  });
  const otherConfig = {};

  if(wordAllowedStyleProps) {
    otherConfig.wordAllowedStyleProps = wordAllowedStyleProps;
  }

  const editorConfig = {
    ...defaultConfig,
    pasteAllowedStyleProps,
    toolbarButtons: toolbarButtonsOverride,
    imageEditButtons: imageEditButtonsOverride,
    key: editorClientKey,
    events: {
      'commands.after': commandsAfter,
      'image.beforeUpload': handleBeforeImageUpload,
      'image.beforeRemove': handleImageBeforeRemove,
      'paste.beforeCleanup': function(htmlString) {
        return handleBeforeCleanup(htmlString, {
          removeContentEditable: true,
          removeBackgroundColor: true
        });
      },
      'url.linked': function(link) {
        handleUrlLinked(link, this);
      },
      initialized: handleInitialized,
      ...events
    },
    placeholderText: placeholder,
    ...colors,
    ...otherConfig
  };

  const handleModelChange = mod => {
    setModel(mod);
    onUpdate({value: mod, ...data, dirty: initialized && !resetAsCleanText.current});

    resetAsCleanText.current = false;
  };

  return (
    <div id={`inline-editor-${id}`} className={`inline-editor${extraClassName}`} data-testid="inline-editor">
      <FroalaEditorComponent
        ref={editorRef}
        config={editorConfig}
        model={model}
        onModelChange={handleModelChange} />
    </div>
  );
});

InlineEditor.propTypes = {
  identifier: PropTypes.string,
  extraClass: PropTypes.string,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  onInitialized: PropTypes.func,
  onImageUploadStatus: PropTypes.func,
  onShowInsertLink: PropTypes.func,
  context: PropTypes.object,
  events: PropTypes.object
};

InlineEditor.defaultProps = {
  identifier: '',
  extraClass: '',
  placeholder: 'Enter text...',
  text: null,
  data: {},
  onUpdate() {},
  onInitialized() {},
  onImageUploadStatus: null,
  onShowInsertLink() {},
  context: {},
  events: {}
};

export default InlineEditor;
