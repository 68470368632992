import {useState, useEffect, useRef, useCallback} from 'react';
import classNames from 'classnames';

import {useDigestTemplates} from '../contexts/_digestTemplates';
import Icon from './_icon';
import DropdownMenu from './_dropdown_menu';
import Modal from './_modal';

const maxNameLength = 30;

const DigestTemplateSelect = ({onSelectTemplate, onManageTemplates, title, summary}, context) => {
  const [templateOptions, setTemplateOptions] = useState([]);
  const [creating, setCreating] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [error, setError] = useState('');
  const {
    templates,
    createTemplate
  } = useDigestTemplates();
  const inputRef = useRef();

  const updateTemplateOptions = useCallback(() => {
    const disabled = !title && !summary;

    const options =
    [[
      0,
      'Save As New Template',
      false,
      classNames('digest-template-select-item', {disabled}),
      {},
      <div key="add-icon" className={classNames('digest-template-select-item-icon', {disabled})}><Icon icon="add_circle" width={18} height={18} /></div>
    ], [
      -1,
      'Manage Templates',
      false,
      'digest-template-select-item',
      {},
      <div key="add-icon" className={classNames('digest-template-select-item-icon', {disabled})}><Icon icon="edit" width={18} height={18} /></div>
    ],
    [-2, 'Apply Template...', true, 'digest-template-select-item disabled']]
      .concat(templates.map(({id, name}) =>
        ([id, name, false, 'digest-template-select-item template-item'])));

    setTemplateOptions(options);
  }, [templates, summary, title]);

  useEffect(() => {
    updateTemplateOptions();
  }, [updateTemplateOptions, summary, title]);

  useEffect(() => {
    if(creating) {
      if(!inputRef?.current) {
        return;
      }

      inputRef.current.value = '';
      inputRef.current.focus();
    }
  }, [creating]);

  const handleSaveTemplate = useCallback(async () => {
    const {value = ''} = inputRef.current;
    const name = value.trim();

    try {
      const success = await createTemplate({name, title, summary});

      if(!success) {
        setError('An error occurred.');
      }
    }
    catch(err) {
      setError('An error occurred.');
    }
    finally{
      setCreating(false);
    }
  }, [summary, title, createTemplate]);

  const handleCancelCreateTemplate = () => setCreating(false);

  useEffect(() => {
    const keyCheck = event => {
      if(event?.key === 'Escape') {
        event?.preventDefault();

        return handleCancelCreateTemplate();
      }

      if(event?.key === 'Enter' && !disableSave) {
        event?.preventDefault();

        return handleSaveTemplate();
      }
    };

    window.addEventListener('keydown', keyCheck);

    return () => window.removeEventListener('keydown', keyCheck);
  }, [disableSave, handleSaveTemplate]);

  if(!templateOptions.length) {
    return null;
  }

  const handleCreateTemplate = () => {
    setCreating(true);
  };

  const handleTemplateSelected = templateId => {
    if(templateId === 0) {
      return handleCreateTemplate();
    }

    if(templateId === -1) {
      return onManageTemplates();
    }

    const template = templates.find(({id}) => id === templateId);
    const {title: tTitle, summary: tSummary, name} = template || {};
    const {utils: {dialog} = {}} = context;

    const handleTemplateSelectedConfirmed = () => {
      onSelectTemplate({id: templateId, title: tTitle, summary: tSummary});
    };

    dialog.confirmRestrictive({
      message: `Your Subject and Intro will be replaced by content from the “${name}” template.`,
      okCallback: handleTemplateSelectedConfirmed,
      buttonOk: 'Replace'
    });
  };

  const handleClearText = () => {
    if(!inputRef?.current) {
      return;
    }

    inputRef.current.value = '';
    inputRef.current.focus();

    setDisableSave(true);
  };

  const validName = name => {
    setError('');

    if(!name.length) {
      return false;
    }

    if(name.length > maxNameLength) {
      setError('The name must be 30 characters or less.');

      return false;
    }

    const lowerCaseName = name.toLowerCase();
    const nameExists = templates.some(({name: tName}) => tName.toLowerCase() === lowerCaseName);

    if(nameExists) {
      setError('That name already exists.');

      return false;
    }

    return true;
  };

  const handleTemplateNameDidChange = () => {
    setError('');

    if(!inputRef?.current) {
      return;
    }

    const {value = ''} = inputRef.current;
    const name = value.trim();

    setDisableSave(!validName(name));
  };

  return (
    <div className="digest-template-select-with-modal">
      {creating && <Modal
        header={false}
        padded={true}
        extraModalClass="digest-template-select-with-modal"
        basic={true}
        closeOnOutsideClick={false}
        hideCloseButton={true}>
        <div className="digest-template-select-create-modal">
          <div className="digest-template-select-create-modal-container">
            <h3>Create New Template</h3>
            <div className="digest-template-editor_input-text">
              <input ref={inputRef} type="text" placeholder="Name your template..." onChange={handleTemplateNameDidChange} />
              <Icon icon="close" height={16} width={16} fill="#BDBDBD" onClick={handleClearText} />
            </div>
            {<div className="digest-template-select-create-modal-error">{error}</div>}
            <div className="digest-template-select-create-modal-controls-buttons">
              <button className="button button--disabled dialog-cancel-button" onClick={handleCancelCreateTemplate}>
                Cancel
              </button>
              <button className={classNames('button save', {disabled: disableSave})} onClick={handleSaveTemplate}>
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>}
      <div className="digest-template-select">
        <DropdownMenu
          id="digest-template-selector"
          dataTestId="digest-template-selector"
          values={templateOptions}
          alwaysRenderLabel={true}
          label="Template"
          showSelectedValueWhenCollapsed={false}
          selectCallback={handleTemplateSelected} />
      </div>
    </div>
  );
};

DigestTemplateSelect.contextTypes = {
  utils: PropTypes.object
};

DigestTemplateSelect.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.string,
  onSelectTemplate: PropTypes.func.isRequired,
  onManageTemplates: PropTypes.func.isRequired
};

DigestTemplateSelect.defaultProps = {
  title: '',
  summary: ''
};

export default DigestTemplateSelect;
