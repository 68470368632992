import classNames from 'classnames';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {RemoveButton} from './_card_tag_remove_button';
import {useAnalyticsEvent} from '../../contexts/_analyticsEvent';
import {redirectToV2} from '../../modules/route_utils';

const SEARCH_PATHNAME = '/search';
const ENTER_KEY = 'Enter';

const CardTag = ({
  id,
  name,
  isHighlighted,
  disabled,
  isLinkToSearchResults,
  onRemoveTag,
  handleOnSelect
}, {
  appData: {v2Host}
}) => {
  const {trackTagClickEvent} = useAnalyticsEvent();
  const location = useLocation();

  const goToSearch = event => {
    handleOnSelect?.();
    trackTagClickEvent && trackTagClickEvent({id, name}, location.pathname, event);

    redirectToV2({v2Host, v2Path: SEARCH_PATHNAME, v2Search: `?tags=${id}`});
  };

  const handleClick = goToSearch;
  const handleKeyDown = event => {
    if(event.key === ENTER_KEY) {
      event.preventDefault();
      goToSearch();
    }
  };

  return (
    <div
      className={classNames(
        'card-tag',
        {
          'card-tag-display': !isLinkToSearchResults,
          'card-tag-highlighted': isHighlighted,
          'card-tag-disabled': isLinkToSearchResults && disabled,
          'card-tag-removable': Boolean(onRemoveTag)
        }
      )}
      data-testid="card-tag"
      {...isLinkToSearchResults && !disabled ? {tabIndex: 0, onClick: handleClick, onKeyDown: handleKeyDown} : {}}>
      <span
        className="card-tag-name"
        title={name}>
        {name}
      </span>
      {onRemoveTag && <RemoveButton tagId={id} onRemoveTag={onRemoveTag} />}
    </div>
  );
};

CardTag.contextTypes = {
  appData: PropTypes.object.isRequired,
  utils: PropTypes.object.isRequired
};

CardTag.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  isLinkToSearchResults: PropTypes.bool,
  onRemoveTag: PropTypes.func,
  handleOnSelect: PropTypes.func,
  origin: PropTypes.oneOf(['card', 'search_suggestion'])
};

CardTag.defaultProps = {
  isHighlighted: false,
  disabled: false,
  isLinkToSearchResults: true,
  onRemoveTag: undefined,
  handleOnSelect() {},
  origin: 'card'
};

export default CardTag;
