import classNames from 'classnames';

import Icons from './../_icon';

class CardSelectLanePopup extends React.Component {
  static propTypes = {
    boards: PropTypes.arrayOf(PropTypes.object),
    moveCardToLane: PropTypes.func,
    boardId: PropTypes.number
  };

  static defaultProps = {
    boards: [],
    moveCardToLane() {},
    boardId: 0
  };

  state = {
    isOpen: false
  };

  handleToggleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState(prevState => ({isOpen: !prevState.isOpen}));
  };

  handleSelectBoard = (event, boardId) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isOpen: false
    }, () => this.props.moveCardToLane(boardId));
  };

  render() {
    const {boards, boardId: currentBoardId} = this.props;
    const {isOpen} = this.state;
    const boardsList = boards.filter(board => board.id !== currentBoardId);

    return (
      <div className={classNames('move-card-popup-container', {active: isOpen})}>
        <a
          href="#"
          onClick={this.handleToggleClick}
          data-title="Copy shareable link"
          data-test-id="card-select-lane-link"
          className="copy-link dropdown-options-item">
          <Icons className="ui-dropdown-option_icon" icon="compareArrow" />
          <span className="ui-dropdown-option_label">Move card</span>
        </a>
        {isOpen && (
          <div className="move-card-popup">
            <span className="move-card-popup--title">Select a lane to move this card</span>

            <ul className="move-card-popup--select-list" data-test-id="card-select-lane-list">
              {boardsList.map(({id, name}) => (
                <li key={id} className="select-list-item">
                  <button onClick={e => this.handleSelectBoard(e, id)} className="select-list-item--button">
                    {name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

}

export default CardSelectLanePopup;
