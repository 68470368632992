import Types from './types';

const cleanString = s => {
  if(s === null || s === undefined) {
    return '';
  }

  if(typeof s === 'number') {
    return String(s);
  }

  return s;
};

export const Matrix = Types.shape({
  rows: Types.arrayOf(Types.arrayOf(Types.string))
});

Matrix.create = rows => {
  const cleanedRows = rows.map(row => {
    return Types.arrayOf(Types.string).create(row.map(cleanString));
  });

  cleanedRows.forEach(r => {
    if(!Types.arrayOf(Types.string).validate(r)) {
      throw new Error(`Invalid row passed to Matrix.create. Found: ${r}`);
    }
  });

  return {
    rows: Types.arrayOf(Types.arrayOf(Types.string)).create(cleanedRows)
  };
};

export const Table = Types.shape({
  headers: Types.arrayOf(Types.string),
  records: Matrix
});

export const KeywordStats = Types.shape({
  keyword: Types.string,
  share: Types.number,
  change: Types.optionOf(Types.number)
});

// A summary fact is simply some fact (in the Big Data™ sense) to be presented in a human readable way
// Example: Page Views per Visit = 3.5 pages
export const SummaryFact = (valueType = Types.any) => Types.shape({
  label: Types.string,
  value: valueType
});

export const Measurement = Types.shape({
  amount: Types.number,
  unit: Types.string
});

export const Percentage = Types.shape({
  percentage: Types.number
});

export const Company = Types.shape({
  info: Types.string
});

const _Date = Types.shape({
  isoDate: Types.string
});

_Date.create = moment => {
  return {isoDate: moment.format('YYYY-MM-DD')};
};

export const Date = _Date;

export const DistributionPortion = Types.shape({
  label: Types.string,
  amount: Types.number
});

export const GenericSeriesData = Types.shape({
  label: Types.string,
  amount: Types.number
});

export const Distribution = Types.shape({
  portions: Types.arrayOf(DistributionPortion)
});

export const GenericSeries = Types.shape({
  data: Types.arrayOf(GenericSeriesData),
  label: Types.string,
  type: Types.string
});

export const TimeseriesEvent = Types.shape({
  date: _Date,
  value: Types.number
});

export const Timeseries = Types.shape({
  label: Types.string,
  events: Types.arrayOf(TimeseriesEvent)
});

export const Person = Types.shape({
  name: Types.string,
  position: Types.string,
  imageUrl: Types.optionOf(Types.string),
  linkedinUrl: Types.optionOf(Types.string),
  twitterUrl: Types.optionOf(Types.string)
});

export const City = Types.shape({
  city: Types.string,
  state: Types.string,
  country: Types.string
});

export const GeoPlace = Types.shape({
  label: Types.string,
  latitude: Types.number,
  longitude: Types.number
});

export const Link = Types.shape({
  url: Types.string,
  title: Types.string
});

export const JobPosting = Types.shape({
  position: Types.string,
  posted: _Date,
  city: City,
  source: Link
});

export const Image = Types.shape({
  imageSrcUrl: Types.string,
  alt: Types.string
});

export const OnlineArticle = Types.shape({
  link: Link,
  headline: Types.string,
  lead: Types.string, // In publishing, the "lead" is the short overview of an article displayed alongside the headline to pique interest.
  published: _Date
});

// A Website represents an entire site (e.g., a collection of pages under some domain).
// Websites are different from Links in that the latter refers to a single page or resource.
// Examples of Websites would be facebook.com, walmart.com, or klue.com.
// In most cases, a Website maps 1:1 to a company and is that company's representation online.
export const Website = Types.shape({
  websiteDomain: Types.string
});

export const HtmlContent = Types.shape({
  rawHtml: Types.string
});
