import AttachmentList from './_attachment_list';

class AttachmentBox extends React.Component {

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    postTitle: PropTypes.string,
    postBody: PropTypes.string,
    profileMode: PropTypes.bool,
    profile: PropTypes.object,
    onActiveAttachmentUpdate: PropTypes.func,
    postId: PropTypes.number,
    redirectToUrl: PropTypes.func,
    timestamp: PropTypes.string,     // ISO-8601 UTC date
    singlePostMode: PropTypes.bool,
    viaEmail: PropTypes.bool,
    viaSlack: PropTypes.bool,
    viaTeams: PropTypes.bool,
    linkToFullPost: PropTypes.bool,
    handlePinClick: PropTypes.func,
    favorite: PropTypes.bool,
    pinnedComment: PropTypes.object
  };

  static defaultProps = {
    data: [],
    postTitle: '',
    postBody: '',
    profileMode: false,
    profile: null,
    onActiveAttachmentUpdate: null,
    postId: 0,
    redirectToUrl: null,
    timestamp: '',
    singlePostMode: false,
    viaEmail: false,
    viaSlack: false,
    viaTeams: false,
    linkToFullPost: false,
    favorite: false,
    pinnedComment: {},
    handlePinClick() {}
  };

  // 0 indexed array adjust for display only
  state = {
    position: 0
  };

  render() {
    const {
      data, postTitle, postBody, postId, redirectToUrl, onActiveAttachmentUpdate,
      timestamp, profileMode, profile, singlePostMode, linkToFullPost, favorite, viaEmail, viaSlack, viaTeams, handlePinClick, pinnedComment
    } = this.props;
    const postTitleLowercased = (postTitle || '').toLowerCase();
    const {position} = this.state;

    return (
      <div className="attachment-box">
        <AttachmentList
          pinnedComment={pinnedComment}
          handlePinClick={handlePinClick}
          data={data}
          postTitle={postTitle}
          postTitleLowercased={postTitleLowercased}
          postBody={postBody}
          postId={postId}
          currentPosition={position}
          redirectToUrl={redirectToUrl}
          onActiveAttachmentUpdate={onActiveAttachmentUpdate}
          timestamp={timestamp}
          profileMode={profileMode}
          profile={profile}
          singlePostMode={singlePostMode}
          linkToFullPost={linkToFullPost}
          viaEmail={viaEmail}
          viaSlack={viaSlack}
          viaTeams={viaTeams}
          favorite={favorite} />
      </div>
    );
  }

}

export default AttachmentBox;
