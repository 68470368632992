import moment from 'moment';
import {Map, OrderedMap, List} from 'immutable';

import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchSimilarWebData, fetchCraftData} from './data_providers';

function validateCompany(company) {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('validateCompany: Unexpected parameter type.', company);
    throw new Error('validateCompany: Parameter was unexpected type.');
  }
}

export async function websiteTrafficPageViews(company, similarWebDataFetcher = fetchSimilarWebData, craftDataFetcher = fetchCraftData) {
  validateCompany(company);

  const craftData = craftDataFetcher(company.info);
  const similarWebData = await similarWebDataFetcher(company.info);

  // Map series to proper Timeseries type
  const events = Object.keys(similarWebData.Traffic.PageViews).map(rawDate => {
    const date = moment(rawDate, 'YYYY-MM-DD');
    const value = similarWebData.Traffic.PageViews[rawDate];

    return KlueTypes.TimeseriesEvent.create({date: KlueTypes.Date.create(date), value});
  });

  const label = (await craftData).displayName;

  return KlueTypes.Timeseries.create({
    label,
    events: Types.arrayOf(KlueTypes.TimeseriesEvent).create(events)
  });
}

export async function websiteTrafficSources(company, similarWebDataFetcher = fetchSimilarWebData) {
  validateCompany(company);

  const similarWebData = await similarWebDataFetcher(company.info);

  const trafficSources = similarWebData.Sources.DesktopSources.reduce((sourceMap, {source_type: sourceType, share}) => {
    return sourceMap.update(sourceType, 0.0, prevVal => prevVal + share);
  }, Map());

  const data = List(trafficSources).map(([sourceType, share]) =>
    KlueTypes.GenericSeriesData.create({
      label: sourceType,
      amount: share
    })
  );

  return KlueTypes.GenericSeries.create({
    data: Types.arrayOf(KlueTypes.GenericSeriesData).create(data),
    label: 'Website Traffic Sources',
    type: 'Distribution-Bar'
  });
}

function mostRecent(dateKeyedMap) {
  const map = Map(dateKeyedMap);
  const sortedEntries = List(map).sort(([date0], [date1]) => {
    if(date0 > date1) { return 1; }
    else if(date0 < date1) { return -1; }

    return 0;
  });
  const values = sortedEntries.map(([, value]) => value);
  const mostRecentValue = values.last();

  return mostRecentValue;
}

export async function websiteEngagementSummary(company, similarWebDataFetcher = fetchSimilarWebData) {
  validateCompany(company);

  const similarWebData = await similarWebDataFetcher(company.info);

  if(!similarWebData.Traffic) {
    throw new Error('websiteEngagementSummary: website traffic data not available.');
  }

  const pageViews = mostRecent(similarWebData.Traffic.PageViews);
  const visitDuration = mostRecent(similarWebData.Traffic.VisitDuration);
  const bounceRate = mostRecent(similarWebData.Traffic.BounceRate);

  const summaryFacts = [];

  if(pageViews !== undefined && pageViews !== null) {
    const pageViewsFact = KlueTypes.SummaryFact(KlueTypes.Measurement).create({
      label: 'Page Views',
      value: KlueTypes.Measurement.create({
        amount: pageViews,
        unit: 'pages'
      })
    });

    summaryFacts.push(pageViewsFact);
  }

  if(visitDuration !== undefined && visitDuration !== null) {
    const visitDurationFact = KlueTypes.SummaryFact(KlueTypes.Measurement).create({
      label: 'Visit Duration',
      value: KlueTypes.Measurement.create({
        amount: visitDuration,
        unit: 'seconds'
      })
    });

    summaryFacts.push(visitDurationFact);
  }

  if(bounceRate !== undefined && bounceRate !== null) {
    const bounceRateFact = KlueTypes.SummaryFact(KlueTypes.Percentage).create({
      label: 'Bounce Rate',
      value: KlueTypes.Percentage.create({
        percentage: bounceRate
      })
    });

    summaryFacts.push(bounceRateFact);
  }

  return Types.arrayOf(KlueTypes.SummaryFact()).create(summaryFacts);
}

export async function similarWebsites(company, similarWebDataFetcher = fetchSimilarWebData) {
  validateCompany(company);

  const similarWebData = await similarWebDataFetcher(company.info);

  if(!similarWebData.Content || !similarWebData.Content.SimilarSites) {
    throw new Error('similarWebsites: similarweb data unavailable');
  }

  const sortedSites = OrderedMap(similarWebData.Content.SimilarSites).sort((score0, score1) => score1 - score0);
  const websites = sortedSites.map((score, domain) => KlueTypes.Website.create({websiteDomain: domain}));

  return Types.arrayOf(KlueTypes.Website).create(websites.valueSeq().toArray());
}
