export const dataUrlToBlob = (dataUrl = '') => {
  const BASE64_MARKER = ';base64,';
  let parts;
  let contentType;
  let raw;

  if(dataUrl.indexOf(BASE64_MARKER) === -1) {
    parts = dataUrl.split(',');
    contentType = parts[0].split(':')[1];
    raw = decodeURIComponent(parts[1]);

    return new Blob([raw], {type: contentType});
  }

  parts = dataUrl.split(BASE64_MARKER);
  contentType = parts[0].split(':')[1];
  raw = window.atob(parts[1]);

  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for(let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], {type: contentType});
};

export const normalizeAttachment = attachment => {
  return {
    thumbnailUrl: attachment.remote_url || attachment.assetUrl,
    url: attachment.asset_url || attachment.assetUrl,
    fileName: attachment.file_name || attachment.fileName,
    type: attachment.type,
    mimeType: attachment.mime_type || attachment.mimeType
  };
};

export const getFileType = mimeType => {
  if(mimeType === 'application/pdf') {
    return 'pdf';
  }

  if(mimeType === 'application/zip') {
    return 'zip';
  }

  if([
    'application/msword', // doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // docx
  ].indexOf(mimeType) >= 0) {
    return 'doc';
  }

  if([
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
  ].indexOf(mimeType) >= 0) {
    return 'xls';
  }

  return 'other';
};
