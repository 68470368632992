import FormulaEditor from './_formula_editor';
import FormulaDisplay from './_formula_display';

class FormulaAttachment extends React.Component {

  static propTypes = {
    code: PropTypes.string.isRequired,
    rival: PropTypes.object.isRequired,
    board: PropTypes.object,
    cardId: PropTypes.number,
    cardTitle: PropTypes.string,
    editing: PropTypes.bool,
    createdAt: PropTypes.string,
    isDraft: PropTypes.bool,
    onEditFinish: PropTypes.func,
    onChange: PropTypes.func,
    analyticsEventContext: PropTypes.object
  };

  static defaultProps = {
    code: '',
    rival: null,
    board: null,
    cardId: 0,
    cardTitle: '',
    editing: false,
    createdAt: '',
    isDraft: false,
    onEditFinish() {},
    onChange() {},
    analyticsEventContext: {}
  };

  componentDidMount() {
    console.log('FormulaAttachment.componentDidMount: props: %o', this.props);
  }

  finishEditing = this.props.onEditFinish;

  render() {
    const {code, editing, onChange, rival, cardId, cardTitle, board, createdAt: cardCreatedAt, analyticsEventContext, isDraft} = this.props;
    const resolvedCode = code.replace(/\bcurrentRival\b/g, rival.id)
      .replace(/\bcurrentCompany\b/g, 0)
      .replace(/\bWEBSITE\((COMPANY\(.+?\))\)/g, '$1'); // TODO: remove this last one after card data migration

    if(editing) {
      return (
        <FormulaEditor
          code={resolvedCode}
          rival={rival}
          onEditFinish={this.finishEditing}
          onChange={onChange} />
      );
    }

    return (
      <FormulaDisplay
        code={resolvedCode}
        rival={rival}
        lane={board}
        cardId={cardId}
        cardCreatedAt={cardCreatedAt}
        isDraft={isDraft}
        cardTitle={cardTitle}
        analyticsEventContext={analyticsEventContext} />
    );
  }

}

export default FormulaAttachment;
