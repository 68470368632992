export const containerTypes = Object.freeze([
  'post',
  'profile',
  'board',
  'card',
  'battlecard'
]);

export const validMediaTypes = Object.freeze([
  'page',
  'image',
  'document-pdf'
]);
