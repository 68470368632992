import {userCanCurate} from '../modules/roles_utils';

class BoardEditor extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    board: PropTypes.object,
    onBoardSave: PropTypes.func,
    onToggleEditMode: PropTypes.func,
    onErrorMessage: PropTypes.func
  };

  static defaultProps = {
    user: {},
    board: null,
    onBoardSave() {},
    onToggleEditMode() {},
    onErrorMessage() {}
  };

  state = {
    boardName: ''
  };

  componentDidMount() {
    this.resetBoardName();
  }

  componentDidUpdate() {
    if(this.didFocus) {
      return;
    }

    this.didFocus = true;
    this.inputRef.current?.focus();
    this.inputRef.current?.select();
  }

  inputRef = React.createRef();
  didFocus = false;

  resetBoardName = ({board = {}} = this.props) => this.setState({boardName: board ? board.name : ''});

  handleKeyDown = event => {
    event = event || window.event;

    if(event.which === 27) {
      this.props.onToggleEditMode();
      this.resetBoardName();
    }
  };

  handleBoardNameChange = event => this.setState({boardName: event.target.value});

  handleBoardSave = event => {
    if(event) {
      event.preventDefault();
    }

    const boardName = this.inputRef.current?.value.trim();
    const {board, onBoardSave, onToggleEditMode, onErrorMessage} = this.props;

    if(!boardName) {
      return onErrorMessage({
        title: '🚨 Unable to to save lane!',
        message: 'Please enter a name for your lane.'
      });
    }

    onBoardSave(board, boardName, () => board && onToggleEditMode());

    this.setState({
      boardName: (board || {}).name || ''
    });
  };

  render() {
    const {user, onToggleEditMode} = this.props;
    const {boardName} = this.state;

    if(!userCanCurate({user})) {
      return null;
    }

    return (
      <form className="board-editor" onKeyDown={this.handleKeyDown}>
        <div className="row">
          <div className="col-xs-7">
            <div className="form-group">
              <label className="sr-only" htmlFor="boardName">Name</label>
              <input ref={this.inputRef}
                data-testid="board-editor-input"
                id="boardName"
                className="form-control input-sm board-editor_input"
                type="text"
                autoFocus={true}
                onChange={this.handleBoardNameChange}
                value={boardName}
                placeholder="New lane title..."
                maxLength="255" />
            </div>
          </div>
          <div className="col-xs-5 text-right">
            <div className="form-group">
              <button type="submit" className="button button--small board-editor_save" onClick={this.handleBoardSave}>
                Save
              </button>
              <button type="button" className="btn btn-sm btn-link" onClick={onToggleEditMode}>
                <i className="fa fa-times board-editor_cancel" />
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }

}

export default BoardEditor;
