import CardDisplay from './_card_display';
import CompanyLogo from './_company_logo';

import {highlightHTMLWithSearchWords} from '../modules/card_utils';
import {stripHtml, wrapHtml} from '../modules/html_utils';
import {titleize} from '../modules/text_utils';
import {userCanCurate} from '../modules/roles_utils';
import CardTags from './card_templates/_card_tags';
import {AnalyticsEventProvider} from '../contexts/_analyticsEvent';

import {Link} from 'react-router-dom';

const SearchResultCard = props => {
  const {rival, board, card: originalCard, query, filteredTags, showHighlights, user, renderCardMetaLink} = props;
  const isCurator = userCanCurate({user});

  if(_.isEmpty(rival) || !board) {
    return null;
  }

  let rivalName = stripHtml(rival.name);
  let boardName = titleize(stripHtml(board.name));
  let {name: cardName = '', textHtml = ''} = originalCard.data;
  const nameWithoutHighlight = cardName ? cardName.slice() : '';
  const logoRegion = (
    <CompanyLogo rival={rival} className="search-result-card-icon" title={rivalName} />
  );

  if(showHighlights) {
    const {nameWithHighlight, textHtmlWithHighlight} = originalCard.data;

    rivalName = highlightHTMLWithSearchWords(query, rivalName);
    boardName = highlightHTMLWithSearchWords(query, boardName);
    // note CardDisplay sanitizes name and textHtml when displaying
    cardName = nameWithHighlight || cardName || '';
    textHtml = textHtmlWithHighlight || textHtml || '';
  }

  const card = {...originalCard, data: {...originalCard.data, nameWithoutHighlight, name: cardName || '', textHtml}};

  // TODO: structure of data coming from postgres is different from data coming from ES
  const profileId = (card.profile && card.profile.id) || card.board.profileId || 0;
  const profileAction = isCurator ? 'edit' : 'view';

  return (
    <div className="search-result-card">
      <div className="search-result-card-header card-header-block">
        <h4>
          {logoRegion}
          <Link className="search-result-card-header-a" to={`/profile/${profileId}/${profileAction}`} dangerouslySetInnerHTML={wrapHtml(rivalName, false)} />
          <i className="fa fa-angle-right search-result-card-header-i" aria-hidden="true" />
          <Link
            className="search-result-card-header-a"
            to={{
              pathname: `/profile/${profileId}/${profileAction}/card/${card.id}`,
              state: {boardId: board.id}
            }}
            dangerouslySetInnerHTML={wrapHtml(boardName, false)} />
        </h4>
        {renderCardMetaLink(card)}
      </div>
      <div className="search-result-card-content card-block">
        <CardDisplay card={card} rival={rival} showTitle={true} />
      </div>
      <AnalyticsEventProvider tagClickEvent={{category: 'Global Search'}}>
        <CardTags
          card={card}
          searchQuery={showHighlights ? query : undefined}
          filteredTags={showHighlights ? filteredTags : undefined}
          editable={true} />
      </AnalyticsEventProvider>
    </div>
  );
};

SearchResultCard.propTypes = {
  rival: PropTypes.object,
  board: PropTypes.object,
  card: PropTypes.object,
  query: PropTypes.string,
  filteredTags: PropTypes.object,
  showHighlights: PropTypes.bool,
  user: PropTypes.object,
  renderCardMetaLink: PropTypes.func
};

SearchResultCard.defaultProps = {
  rival: null,
  board: null,
  card: null,
  query: '',
  filteredTags: new Set(),
  showHighlights: true,
  user: null,
  renderCardMetaLink() {}
};

export default SearchResultCard;
