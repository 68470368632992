import Dropdown from './_dropdown';

import {usersGet} from '../modules/api/users';
import {sortUsersByMatchIndex} from '../modules/user_utils';
import {sanitizeInput, wrapHtml} from '../modules/html_utils';

import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';

class FeedFilters extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    onSearchFeed: PropTypes.func,
    onSortFeed: PropTypes.func,
    onFilterFeed: PropTypes.func,
    expanded: PropTypes.bool
  };

  static defaultProps = {
    onSearchFeed() {},
    onSortFeed() {},
    onFilterFeed() {},
    expanded: false
  };

  state = {
    focused: false,
    filterBy: 'query',
    sortBy: 'timestamp_desc',
    query: '',
    poster: '',
    source: '',
    posterId: 0,
    activeFilters: [],
    suggestions: [],

    // for DateRangePicker
    focusedInput: null,
    startDate: null,
    endDate: null
  };

  componentDidMount() {
    // DEBUG
    console.log('FeedFilters.componentDidMount: props: %o', this.props);

    // TODO: migrate filter state management up to Feed component & remove mediator dependency
    klueMediator.subscribe('klue:box:clearSearch', doFilter => {
      // reset search completely & switch back to default filter
      this.handleClearSearch(true, doFilter);
      this.handleFilterClick('query');
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // DEBUG
    console.log('FeedFilters.componentWillReceiveProps: nextProps: %o', nextProps);
  }

  componentWillUnmount() {
    klueMediator.remove('klue:box:clearSearch');
  }

  handleChange = event => {
    const {value} = event.target;
    const state = {};

    if(this.state.filterBy === 'query') {
      state.query = value;
    }
    else if(this.state.filterBy === 'source') {
      state.source = value;
    }

    this.setState(state);
  };

  handleChangePoster = (event, {newValue: poster}) => this.setState({poster});

  handleSortClick = (sortValue, event) => {
    if(event) {
      event.preventDefault();
    }

    const sort = sortValue ? sortValue.toLowerCase().split('_') : [];

    if(sort.length < 2) {
      return;
    }

    this.setState({
      // store locally as single value, no need for extra state
      sortBy: sortValue
    }, () => {
      this.props.onSortFeed({
        sortBy: sort[0],
        sortDirection: sort[1]
      });
    });
  };

  handleFilterClick = filterBy => {
    this.setState({
      filterBy
    });
  };

  handleFilterPosts = (options = {}) => {
    const {query, posterId, source} = this.state;

    // override existing filters (preserves any other previously set)
    const filterOptions = _.extend({
      query: query ? query.trim() : '',
      postUserId: posterId,
      postSource: source ? source.trim() : ''
    }, options);

    this.props.onFilterFeed({
      postsFilterOptions: filterOptions
    });
  };

  // required Autosuggest handlers
  getUserSuggestions = input => {
    const userOptions = {
      kluebot: true,
      page: 1,
      limit: 10,
      typeFilter: 'active',
      query: _.escapeRegExp(input.trim())
    };

    usersGet({userOptions, code: 'FeedFilters.getUserSuggestions'}).then(users => {
      const suggestions = users.map(({id, name, username, image}) => ({id, name, username, image}));
      const sortedUsers = sortUsersByMatchIndex(suggestions, input.trim());

      this.setState({suggestions: sortedUsers});
    });
  };

  getUserSuggestionValue = suggestion => {
    if(this.state.poster) {
      return suggestion.username;
    }
  };

  renderUserSuggestion = suggestion => {
    const escapedInput = _.escapeRegExp(this.state.poster.trim());
    const regex = new RegExp(escapedInput, 'gi');
    let {name, username: userName} = suggestion;

    name = sanitizeInput(name).replace(regex, str => `<span class="hl">${str}</span>`);
    userName = sanitizeInput(userName).replace(regex, str => `<span class="hl">${str}</span>`);

    return (
      <div className="suggestion-item">
        <img src={suggestion.image} alt={suggestion.name} />
        <strong dangerouslySetInnerHTML={wrapHtml('@' + userName, false)} />
        <span dangerouslySetInnerHTML={wrapHtml(name, false)} />
      </div>
    );
  };

  handleSelectUserSuggestion = (event, {suggestion: userSuggestion}) => {
    if(event) {
      event.preventDefault();
    }

    this.setState({
      posterId: userSuggestion ? userSuggestion.id : 0
    }, () => {
      if(!this.state.posterId) {
        this.setState({
          poster: ''
        });
      }

      this.handleFilterPosts({
        postUserId: this.state.posterId
      });

      this.handleUpdateActiveFilters();
    });
  };

  handleKeyPress = event => {
    event = event || window.event;

    if((event.which === 13) && !event.shiftKey) {
      this.handleSubmit(event);
    }
  };

  handleKeyDown = event => {
    event = event || window.event;

    if(event.which === 27) {
      this.handleClearSearch(false, true);
    }
  };

  handleSubmit = event => {
    if(event) {
      event.preventDefault();
    }

    const {filterBy} = this.state;

    if(filterBy === 'query') {
      this.props.onSearchFeed(this.state.query.trim());
    }
    else if(filterBy === 'source') {
      this.handleFilterPosts({
        postSource: this.state.source ? this.state.source.trim() : ''
      });
    }
    else if((filterBy === 'poster') && (this.state.posterId <= 0)) {
      // non-existent poster
      this.setState({
        posterId: -1
      }, () => {
        this.handleFilterPosts({
          postUserId: this.state.posterId
        });
      });
    }

    this.handleUpdateActiveFilters();
  };

  handleClearSearch = (clearAll, doFilter, event) => {
    if(event) {
      event.preventDefault();
    }

    const {filterBy} = this.state;
    const updatedState = {};

    if(clearAll) {
      _.extend(updatedState, {
        query: '',
        poster: '',
        source: '',
        startDate: null,
        endDate: null
      });

      if(doFilter) {
        this.handleFilterPosts({
          postUserId: 0,
          postSource: ''
        });
      }

      this.props.onSearchFeed('');
    }
    else {
      switch(filterBy) {
        case 'query':
        default:
          updatedState.query = '';

          this.props.onSearchFeed('');

          break;
        case 'poster':
          updatedState.poster = '';
          updatedState.posterId = 0;

          if(doFilter) {
            this.handleFilterPosts({
              postUserId: 0
            });
          }

          break;
        case 'source':
          updatedState.source = '';

          if(doFilter) {
            this.handleFilterPosts({
              postSource: ''
            });
          }

          break;
      }
    }

    this.setState(updatedState, () => {
      this.handleUpdateActiveFilters();
    });
  };

  handleUpdateActiveFilters = () => {
    const updatedFilters = ['query', 'poster', 'source', 'startDate', 'endDate'].reduce((memo, key) => {
      const filter = this.state[key];

      if(filter) {
        memo.push(key);
      }

      return memo;
    }, []);

    this.setState({
      activeFilters: updatedFilters
    });
  };

  setFilterFocus = (focused, event) => {
    if(!focused) {
      const target = event.currentTarget;

      // component blur trick, reference: https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
      setTimeout(() => {
        if(!target.contains(document.activeElement)) {
          this.setState({
            focused
          });
        }
      }, 0);
    }
    else {
      this.setState({
        focused
      });
    }
  };

  // required DateRangePicker handlers
  handleDatesChange = (dates, skipFilterUpdate) => {
    const {startDate} = dates;
    const {endDate} = dates;

    this.setState({
      startDate,
      endDate
    }, () => {
      // match ruby API's ISO8601 format: http://stackoverflow.com/a/25725031/226756
      // (preserves original timezone, use moment().toISOString() for UTC)
      const filterOptions = {
        postDateStart: startDate ? startDate.startOf('day').format() : moment(0).format(),   // fall back to unix epoch
        postDateEnd: endDate ? endDate.endOf('day').format() : moment().format()             // fall back to today
      };

      this.handleFilterPosts(filterOptions);

      if(!skipFilterUpdate) {
        this.handleUpdateActiveFilters();
      }
    });
  };

  handleFocusChange = focusedInput => {
    this.setState({
      focusedInput
    });
  };

  isOutsideRange = day => {
    // only allow selection of past dates
    return !isInclusivelyBeforeDay(day, moment());
  };

  initialVisibleMonth = () => {
    // open with selected start date visible, then fall back to end date, then finally the start of the previous month
    return this.state.startDate || this.state.endDate || moment().subtract(1, 'months');
  };

  handleClearFilter = key => {
    if(this.state.hasOwnProperty(key)) {
      if(['startDate', 'endDate'].indexOf(key) >= 0) {
        this.handleDatesChange({
          startDate: (key === 'startDate') ? null : this.state.startDate,
          endDate: (key === 'endDate') ? null : this.state.endDate
        }, true);
      }
      else {
        const filterState = {};

        filterState[key] = '';

        if(key === 'poster') {
          filterState.posterId = 0;
        }

        this.setState(filterState, () => {
          this.handleFilterPosts();
          this.handleUpdateActiveFilters();
        });
      }
    }
  };

  onSuggestionsClearRequested = () => this.setState({suggestions: []});

  onSuggestionsFetchRequested = _.debounce(args => this.getUserSuggestions(args.value), 400);

  renderUserAutoSuggest = () => {
    const inputProps = {
      id: 'posterName',
      type: 'search',
      className: 'feed-filters_main_search_input',
      placeholder: 'Find posts by user...',
      onFocus: e => this.setFilterFocus(true, e),
      onKeyPress: this.handleKeyPress,
      onKeyDown: this.handleKeyDown,
      onChange: this.handleChangePoster,
      value: this.state.poster
    };

    return (
      <Autosuggest
        getSuggestionValue={this.getUserSuggestionValue}
        inputProps={inputProps}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.handleSelectUserSuggestion}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        renderSuggestion={this.renderUserSuggestion}
        suggestions={this.state.suggestions} />
    );
  };

  renderSearchForm = () => {
    const activeFilters = this.state.activeFilters.slice();
    let searchInput;
    let searchButton;
    let searchIconClass;
    let searchPlaceholder;
    let searchInputValue = '';
    const extendedFormEvents = {};

    switch(this.state.filterBy) {
      case 'query':
      default:
        searchIconClass = 'newspaper-o';
        searchPlaceholder = 'Search feed...';
        searchInputValue = this.state.query;

        if(this.state.query && (activeFilters.indexOf('query') >= 0)) {
          searchButton = (
            <i className="fa fa-times-circle feed-filters_main_search_icon" onClick={e => this.handleClearSearch(false, true, e)} />
          );
        }
        else {
          searchButton = (
            <i className="fa fa-search feed-filters_main_search_icon" onClick={this.handleSubmit} />
          );
        }

        break;
      case 'poster':
        searchIconClass = 'user';
        searchInput = this.renderUserAutoSuggest();

        if(this.state.poster && this.state.posterId) {
          searchButton = (
            <i className="fa fa-times-circle feed-filters_main_search_icon" onClick={e => this.handleClearSearch(false, true, e)} />
          );
        }
        else {
          searchButton = (
            <i className="fa fa-search feed-filters_main_search_icon" onClick={this.handleSubmit} />
          );
        }

        break;
      case 'source':
        searchIconClass = 'globe';
        searchPlaceholder = 'Find posts by source site...';
        searchInputValue = this.state.source;

        if(this.state.source && (activeFilters.indexOf('source') >= 0)) {
          searchButton = (
            <i className="fa fa-times-circle feed-filters_main_search_icon" onClick={e => this.handleClearSearch(false, true, e)} />
          );
        }
        else {
          searchButton = (
            <i className="fa fa-search feed-filters_main_search_icon" onClick={this.handleSubmit} />
          );
        }

        break;
    }

    // only wire up key events for query/source filters (poster search handles it separately)
    if(this.state.filterBy !== 'poster') {
      Object.assign(extendedFormEvents, {
        onKeyPress: this.handleKeyPress,
        onKeyDown: this.handleKeyDown
      });
    }

    return (
      <form role="search" className="feed-filters_main_search" acceptCharset="utf-8" {...extendedFormEvents}>
        {searchInput || (
          <input
            type="search"
            className="feed-filters_main_search_input"
            placeholder={searchPlaceholder}
            onChange={this.handleChange}
            value={searchInputValue}
            name="q"
            maxLength="255"
            onFocus={e => this.setFilterFocus(true, e)} />
        )}
        <i className={'fa fa-' + searchIconClass + ' feed-filters_main_filter_icon'} />
        {searchButton}
      </form>
    );
  };

  renderFilter = (key, label, value) => {
    return (
      <div key={key} className="feed-filters_active_filter" onClick={() => this.handleClearFilter(key)}>
        <span className="feed-filters_active_filter-label">{label ? label + ': ' : ''}</span>{value}
        <i className="feed-filters_active_filter-icon" />
      </div>
    );
  };

  renderActiveFilters = () => {
    const activeFilters = this.state.activeFilters.slice();

    if(!activeFilters) {
      return;
    }

    let filtersActiveClass = 'feed-filters_active';
    let filtersActiveLabel;

    const filtersActive = activeFilters.reduce((memo, key) => {
      const filter = this.state[key];
      let label = '';
      let value = '';

      if(filter) {
        switch(key) {
          case 'query':
          default:
            label = key;
            value = filter;
            break;
          case 'poster':
            label = 'By';
            value = '@' + filter;
            break;
          case 'source':
            label = 'Site';
            value = filter;
            break;
          case 'startDate':
            label = 'After';
            value = filter.format('L');
            break;
          case 'endDate':
            label = 'Before';
            value = filter.format('L');
            break;
        }

        memo.push(this.renderFilter(key, label, value));
      }

      return memo;
    }, []);

    if(filtersActive.length) {
      filtersActiveClass += ' feed-filters_active--visible';
      filtersActiveLabel = (
        <span key="filtersLabel" className="feed_filters_active_label">Filters:</span>
      );
    }

    return (
      <div className={filtersActiveClass}>
        {filtersActiveLabel}{filtersActive}
      </div>
    );
  };

  render() {
    const feedFiltersClasses = classNames('feed-filters', {
      'feed-filters--collapsed': this.context.utils.rival && !this.props.expanded,
      'feed-filters--expanded': this.context.utils.rival && this.props.expanded,
      'feed-filters--focused': this.state.focused,
      'feed-filters--active': this.state.activeFilters.length
    });
    const feedFiltersDateClasses = classNames('feed-filters_main_filter-date', {
      'feed-filters_main_filter-date--active': this.state.calendarMode
    });
    const calendarIcon = (
      <i className="fa fa-calendar feed-filters_main_filter-date-icon" />
    );
    const sortIcon = (
      <i className="feed-filters_main_sort-icon" />
    );
    const filterOptions = [
      {value: 'query', label: 'All posts', icon: 'fa-newspaper-o'},
      {value: 'poster', label: 'By user', icon: 'fa-user'},
      {value: 'source', label: 'Source site', icon: 'fa-globe'}
    ];
    const sortOptions = [
      {value: 'timestamp_desc', label: (<span className="feed-filters-label">Post date <small>(newest first)</small></span>)},
      {value: 'timestamp_asc', label: (<span className="feed-filters-label">Post date <small>(oldest first)</small></span>)},
      {value: 'published_desc', label: (<span className="feed-filters-label">Publication date <small>(newest first)</small></span>)},
      {value: 'published_asc', label: (<span className="feed-filters-label">Publication date <small>(oldest first)</small></span>)}
    ];
    const {filterBy, focusedInput, startDate, endDate, sortBy} = this.state;

    return (
      <div className={feedFiltersClasses} tabIndex="1" onBlur={e => this.setFilterFocus(false, e)}>
        <div className="feed-filters_main">
          {this.renderSearchForm()}
          <div className="feed-filters_main_filter-primary">
            <Dropdown
              label=""
              options={filterOptions}
              selectedValue={filterBy}
              keyPrefix="filter"
              className="feed-filters_main_filter-primary_dropdown"
              onOptionClick={this.handleFilterClick} />
          </div>
          <div className={feedFiltersDateClasses} onClick={this.toggleCalendarMode}>
            <DateRangePicker
              orientation="vertical"
              anchorDirection="right"
              focusedInput={focusedInput}
              startDate={startDate}
              startDateId="startDate"
              endDate={endDate}
              endDateId="endDate"
              onDatesChange={this.handleDatesChange}
              onFocusChange={this.handleFocusChange}
              isOutsideRange={this.isOutsideRange}
              initialVisibleMonth={this.initialVisibleMonth}
              customInputIcon={calendarIcon}
              numberOfMonths={1} />
          </div>
          <div className="feed-filters_main_sort">
            <Dropdown
              options={sortOptions}
              condensed={true}
              labelIcon={sortIcon}
              selectedValue={sortBy}
              keyPrefix="sort"
              containerClass="ui-dropdown-flush"
              className="feed-filters_main_sort-link"
              onOptionClick={this.handleSortClick} />
          </div>
        </div>
        {this.renderActiveFilters()}
      </div>
    );
  }

}

export default FeedFilters;
