import classNames from 'classnames';
import {pluralize} from '../modules/text_utils';

import Dropdown from './_dropdown';
import DropdownMenu from './_dropdown_menu';
import Icon from './_icon';
import Spinner from './_spinner';
import {useMemo} from 'react';

const selectDropdownOptions = [
  {value: 'all', label: 'Select All Consumers', dataTrackingId: 'select-all'},
  {value: 'none', label: 'None', dataTrackingId: 'select-none'}
];

const ManageUsersBulkEditControls = ({
  visible,
  disabled,
  applyingBulkEdit,
  selectedCount,
  status,
  totalCount,
  visibilityGroups,
  onSelectAll,
  onDeselectAll,
  onVisibilityGroupSelected
}) => {
  const handleSelectOption = id => ((id === 'all') ? onSelectAll() : onDeselectAll());

  const handleSelect = e => {
    e?.stopPropagation();
    e?.preventDefault();

    if(selectedCount === totalCount) {
      return onDeselectAll();
    }

    onSelectAll();
  };

  const visibilityGroupValues = useMemo(() => [
    ...visibilityGroups.map(group => [group.id, `${group.name}`]),
    [0, 'Full Access', true]
  ], [visibilityGroups]);

  return (
    <div className={classNames('mange-users-bulk-edit-controls', {'hidden-bulk-edit': !visible})} data-testid="mange-users-bulk-edit-controls">
      <div className={classNames('selector-dropdown', {disabled: disabled || applyingBulkEdit})} data-testid="mange-users-bulk-edit-controls-selector-dropdown">
        <Dropdown
          labelIcon={<div className="icon-container">
            <Icon
              onClick={handleSelect}
              icon={(totalCount && (selectedCount === totalCount)) ? 'check_box_checked' : 'check_box_outline_blank'}
              width="20"
              height="20" />
            <Icon icon="arrow-down" width="20" height="20" />
          </div>}
          label=""
          condensed={true}
          options={selectDropdownOptions}
          keyPrefix="select"
          className="btn secondary"
          containerClass="ui-dropdown--left"
          alignMenuLeft={true}
          onOptionClick={handleSelectOption} />
      </div>
      <div className="count-and-commands" data-testid="mange-users-bulk-edit-controls-count-and-commands">
        {selectedCount ? <div className="item-count">
          {`${selectedCount} ${pluralize('user', selectedCount)}`}
        </div> : null}
        <div className={classNames('dropdowns', {disabled: disabled || !selectedCount || applyingBulkEdit})} data-testid="count-and-commands-dropdowns">
          <DropdownMenu
            id="viewVisibilityGroupDropdown"
            values={visibilityGroupValues}
            label="Visibility Group"
            displayDropdownTitle={false}
            showSelectedValueWhenCollapsed={false}
            allowEmptySelection={false}
            alwaysRenderLabel={true}
            selectedValue={null}
            selectCallback={onVisibilityGroupSelected} />
        </div>
      </div>
      {applyingBulkEdit ? <Spinner /> : null}
      {status}
    </div>
  );
};

ManageUsersBulkEditControls.propTypes = {
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  applyingBulkEdit: PropTypes.bool,
  totalCount: PropTypes.number,
  selectedCount: PropTypes.number,
  status: PropTypes.node,
  visibilityGroups: PropTypes.arrayOf(PropTypes.object),
  onSelectAll: PropTypes.func,
  onDeselectAll: PropTypes.func,
  onVisibilityGroupSelected: PropTypes.func
};

ManageUsersBulkEditControls.defaultProps = {
  visible: false,
  disabled: false,
  applyingBulkEdit: false,
  totalCount: 0,
  selectedCount: 0,
  status: null,
  visibilityGroups: [],
  onSelectAll() {},
  onDeselectAll() {},
  onVisibilityGroupSelected() {}
};

export default ManageUsersBulkEditControls;
