import CommentList from './_comment_list';
import CommentForm from './_comment_form';
import CommentStat from './_comment_stat';

import {commentsGet, commentCreateOrUpdate, commentDelete} from '../modules/api/comments';
import {postDelete} from '../modules/api/posts';
import {isValidId} from '../modules/utils';
import {capitalize, paragraphs} from '../modules/text_utils';
import {analyticsTrack} from '../modules/analytics_utils';
import {containerTypes} from '../modules/constants/comments';
import {sendMessageToExtensionContent} from '../modules/ext_utils';
import classNames from 'classnames';
import {connect} from 'react-redux';

class CommentBox extends React.Component {

  static contextTypes = {
    api: PropTypes.object,                // not required, ExtensionBox doesn't have access
    utils: PropTypes.object.isRequired,
    onScratchpadRefresh: PropTypes.func   // not required, conditionally used inside the app (not in extension)
  };

  static propTypes = {
    allowCommentsToggle: PropTypes.bool,
    canTakeScreenshot: PropTypes.bool,
    commentCount: PropTypes.number,
    containerId: PropTypes.number.isRequired,
    containerType: PropTypes.oneOf(containerTypes),
    digestMode: PropTypes.bool,
    extensionMode: PropTypes.bool,
    favorites: PropTypes.arrayOf(PropTypes.object),
    hideComments: PropTypes.bool,
    hideFavorites: PropTypes.bool,
    hideStat: PropTypes.bool,
    onDeleteFavorite: PropTypes.func,
    onRivalCreate: PropTypes.func,
    onRivalSelect: PropTypes.func,
    onUpdateCanDeletePost: PropTypes.func,
    onUpdateFavorite: PropTypes.func,
    handlePinClick: PropTypes.func,
    updatePinnedComment: PropTypes.func,
    postHasAttachment: PropTypes.bool,
    replaceCommentsForm: PropTypes.node,
    rivalMatches: PropTypes.arrayOf(PropTypes.object),
    rivals: PropTypes.object,
    showNewestFirst: PropTypes.bool,
    suggestion: PropTypes.string,
    user: PropTypes.object.isRequired,
    pinnedComment: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    visible: PropTypes.bool,
    isMakingPinRequest: PropTypes.bool
  };

  static defaultProps = {
    allowCommentsToggle: false,
    canTakeScreenshot: false,
    commentCount: 0,
    containerId: 0,
    containerType: 'post',
    digestMode: false,
    extensionMode: false,
    favorites: null,
    hideComments: false,
    hideFavorites: false,
    hideStat: false,
    onDeleteFavorite: null,
    onRivalCreate: null,
    onRivalSelect: null,
    onUpdateCanDeletePost() {},
    handlePinClick() {},
    updatePinnedComment() {},
    onUpdateFavorite: null,
    postHasAttachment: true,
    replaceCommentsForm: null,
    rivalMatches: [],
    rivals: {},
    pinnedComment: {},
    showNewestFirst: false,
    suggestion: '',
    user: null,
    users: {},
    visible: false,
    isMakingPinRequest: false
  };

  state = {
    visible: this.props.visible,      // set on initial load only
    data: [],
    commentsLoaded: false,
    commentsSynced: false,
    showFeedLink: false,
    listStyle: {}
  };

  componentDidMount() {
    console.log('CommentBox.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    klueMediator.subscribe(this._syncChannel(), () => this.loadComments());

    if(this.state.visible || !this.props.allowCommentsToggle) {
      this.loadComments();
    }
  }

  componentWillUnmount() {
    klueMediator.remove(this._syncChannel());
    klueMediator.remove(this._syncChannel('digest:post'));
    klueMediator.remove(`klue:highlight:sync:${this.props.containerId}`);
    klueMediator.remove('klue:commentForm:setText');

    this._isMounted = false;
  }

  _syncChannel = component => {
    const useComponent = component || 'commentbox';

    return `klue:${useComponent}:${this.props.containerType}:sync:${this.props.containerId}`;
  };

  _showFavorites = () => {
    const {favorites, hideFavorites, extensionMode} = this.props;

    return Boolean(favorites) && !hideFavorites && !extensionMode;
  };

  _isPost = () => this.props.containerType === 'post';

  setCommentBoxRef = el => (this.commentBox = el);

  setCommentFormRef = el => (this.commentForm = el);

  scrollToPosition = posY => {
    // TODO: wire up smooth scrolling here
    // potentially use this: https://github.com/fisshy/react-scroll
    const commentBox = ReactDOM.findDOMNode(this);

    // jump to top of selected comment
    commentBox.scrollTop = posY - (posY >= 50 ? 50 : 0);
  };

  getHasCommented = () => {
    const comments = this.state.data ? this.state.data.slice() : [];

    for(let i = 0; i < comments.length; i++) {
      if(comments[i].userId === this.props.user.id) {
        return true;
      }
    }

    return false;
  };

  // --- data helpers
  // local comment for latency comp display
  localComment = comment => {
    const now = new Date().toISOString();
    const {attachments = [], body, createdAt, highlight, id, title} = comment;
    const {user: {id: userId, name: userName, imageMed: userImage} = {}} = this.props;
    const useComment = {
      title,
      body: paragraphs(body),
      userId,
      userName,
      userImage,
      createdAt: createdAt || now,
      highlight,
      attachments
    };

    if(id) {
      Object.assign(useComment, {
        id,
        lastEdit: {
          editorId: userId,
          editorName: userName,
          editorImage: userImage,
          editedAt: now
        }
      });
    }

    return useComment;
  };

  // remote comment for server submit
  // note root comment key for rails
  remoteComment = (comment, oldComment) => {
    const {isExtensionMode} = this.props;
    const data = {
      comment: {
        authenticity_token: this.props.user.csrf   // eslint-disable-line camelcase
      }
    };

    if(isExtensionMode) {
      data.comment.body = comment.body;
    }
    else {
      data.comment.bodyHtml = comment.body;
    }

    if(comment.id) {
      data.comment.id = comment.id;
    }

    if(this.props.showNewestFirst) {
      data.reverse = 1;
    }

    if(comment.highlight) {
      data.highlights = [{
        url: comment.highlight.url,
        body: comment.highlight.quote,
        ranges: comment.highlight.ranges
      }];
    }

    // Existing comments that are being updated.
    if(!_.isEmpty(comment.attachments) || (oldComment && !_.isEmpty(oldComment.attachments))) {
      if(comment.id) {
        const changedAttachments = comment.attachments && comment.attachments.filter(a => !oldComment.attachments.find(oa => {
          return (oa.id && a.id && (oa.id === a.id)) && (oa.fileName === a.fileName) && (oa.assetUrl === a.assetUrl);
        }));

        data.attachments = {
          add: _.intersection(changedAttachments, comment.attachments).map(a => {
            return {
              name: a.fileName,
              remote_url: a.assetUrl,             // eslint-disable-line camelcase
              content_type: a.mimeType            // eslint-disable-line camelcase
            };
          }),
          remove: {
            images: _.difference(oldComment.attachments, changedAttachments).map(a => {
              if(a.type === 'image') {
                return a.id;
              }
            }),
            documents: _.difference(oldComment.attachments, changedAttachments).map(a => {
              if(a.type === 'document') {
                return a.id;
              }
            })
          }
        };
      }
      else {
        data.attachments = comment.attachments.map(a => {
          return {
            name: a.fileName,
            remote_url: a.assetUrl,     // eslint-disable-line camelcase
            content_type: a.mimeType    // eslint-disable-line camelcase
          };
        });
      }
    }

    return data;
  };

  loadComments = () => {
    const {containerId, containerType, extensionMode, showNewestFirst: reverse, onRivalSelect, onUpdateCanDeletePost} = this.props;
    const commentOptions = {
      containerId,
      containerType,
      reverse
    };

    commentsGet({commentOptions})
      .then(({comments = []}) => {
        console.log('CommentBox.loadComments: %o', comments);

        const {utils: {userCanCurate}} = this.context;

        if(this._isMounted) {
          this.setState({
            data: comments,
            commentsLoaded: true,
            commentsSynced: true,
            showFeedLink: this._isPost() && !comments.length
          });

          if(extensionMode) {
            const highlights = comments.reduce((hl, c) => {
              c.highlight && hl.push(c.highlight);

              return hl;
            }, []);

            sendMessageToExtensionContent({
              type: 'klue:ext:comments:loaded',
              body: {
                containerId,
                isCurator: userCanCurate(),
                highlights
              }
            });
          }

          // a pretty convoluted way of determining if a user can delete a post
          // ideally we would like to extract the ajax call to a high order component (extension_box),
          // however comment box is also used in the feed.
          const canDeletePost = this._isPost() && ((this.state.data[0].userId === this.props.user.id) || userCanCurate());

          onUpdateCanDeletePost(canDeletePost);
        }

        if(onRivalSelect) {
          // add any pre-selected rivals on highlights to our matches list
          const rivals = _.chain(comments)
            .filter(comments, c => c.boards.length > 0)
            .map(c => c.boards.map(r => r.rival))
            .flatten()
            .uniq(false, r => r.name);

          rivals.reverse().forEach(r => onRivalSelect(r), this);
        }
      })
      .catch(error => console.error('CommentBox.loadComments: error loading comments: %o', error));
  };

  useLocalImages = comments => {
    const localComments = this.state.data ? this.state.data.slice() : [];

    // use pre-existing images (local) if server did not yet process uploaded file
    localComments.forEach((localComment, i) => {
      if(comments[i]) {
        comments[i].attachments = localComment.attachments;
      }
    });
  };

  handleCommentSubmit = comment => {
    // latency comp
    const {user = {}, containerId, containerType, extensionMode, showNewestFirst, updatePinnedComment, pinnedComment} = this.props;
    const {data: comments} = this.state;
    const {onScratchpadRefresh} = this.context;
    const isNew = !comment.id;
    const localComment = this.localComment(comment);
    let oldComment = null;
    let commentIndex = null;

    if(isNew) {
      // create
      if(showNewestFirst || ['profile', 'battlecard'].indexOf(containerType) >= 0) {
        comments.unshift(localComment);
        commentIndex = 0;
      }
      else {
        comments.push(localComment);
        commentIndex = comments.length - 1;
      }
    }
    else {
      // update
      commentIndex = comments.findIndex(c => c.id === comment.id);

      oldComment = comments[commentIndex];
      comments[commentIndex] = localComment;
    }

    return new Promise((resolve, reject) => {
      this.setState({
        data: comments,
        commentsLoaded: false
      });

      const commentOptions = {
        commentData: this.remoteComment(comment, oldComment),
        containerId,
        containerType,
        extensionMode
      };

      commentCreateOrUpdate({commentOptions}).then(updatedComment => {
        if(_.isEmpty(updatedComment)) {
          reject(commentOptions);
        }

        comments[commentIndex] = updatedComment;

        this.useLocalImages(comments);

        if(!_.isEmpty(oldComment)) {
          // update pinned comment
          if(comment.id === pinnedComment?.id) {
            updatePinnedComment(updatedComment);
          }

          // server created a job to delete attachments, but has not deleted them yet
          if(_.isEmpty(localComment.attachments) && !_.isEmpty(oldComment.attachments)) {
            updatedComment.attachments = [];
          }
        }

        this.setState({
          data: comments,
          commentsLoaded: true
        }, () => {
          console.log('CommentBox.handleCommentSubmit: comment: %o, updated comments: %o', comment, comments);
        });

        if(this._isPost() && updatedComment.highlight && extensionMode) {
          window.parent.postMessage(
            {
              type: 'klue:ext:highlight:setId',
              body: updatedComment.highlight.id
            },
            '*'
          );
        }

        if(!isNew && !_.isEmpty(updatedComment.boards) && onScratchpadRefresh) {
          // TODO: remove once comments state is centralized in AppBase
          onScratchpadRefresh({comment: updatedComment});
        }

        const type = containerType || 'undefined';

        analyticsTrack({
          type: 'event',
          category: `${capitalize(type)}`,
          action: `${isNew ? 'create' : 'update'} comment`,
          label: `id:${containerId}, user:${user.id}`
        });

        this.refreshCommentsData();

        resolve(updatedComment);
      });
    });
  };

  refreshCommentsData = () => {
    const {containerType = null} = this.props;
    const {rival} = this.context.utils;

    if(_.isEmpty(rival)) {
      return;
    }

    const {id: profileId = 0} = rival.profile;

    if(!containerType || !isValidId(profileId)) {
      return;
    }

    this.context.api.rivalGet({profileId});
  };

  _deleteHighlights = () => {
    const {data = []} = this.state;

    data.filter(comment => !_.isEmpty(comment.highlight))
      .map(c => c.highlight.id)
      .forEach(highlightId => klueMediator.publish('klue:highlight:delete', highlightId));
  };

  // `deletePost` should be treated like an exported method, since it is called from
  // ExtensionBox when the user selects `REMOVE FROM KLUE`
  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  deletePost = () => {
    this._deleteHighlights();

    const {containerId: postId, containerType, extensionMode, user = {}} = this.props;
    const type = containerType?.toLowerCase();

    if(type !== 'post') {
      return;
    }

    const postOptions = {
      postId,
      extensionMode
    };

    postDelete(postOptions).then(() => {
      analyticsTrack({
        type: 'event',
        category: `${capitalize(type)}`,
        action: 'delete post',
        label: `id:${postId}, user:${user.id}`
      });

      window.location.reload();
    });

    sendMessageToExtensionContent({type: 'klue:ext:reload'});
  };

  handleCommentDelete = commentId => {
    // latency comp
    const {user = {}, containerId, containerType, extensionMode, showNewestFirst, updatePinnedComment, pinnedComment} = this.props;
    const {data} = this.state;
    const {onScratchpadRefresh} = this.context;
    const type = containerType || 'undefined';
    const comments = data.filter(c => c.id !== commentId);
    const commentData = {
      data: comments,
      commentsLoaded: false
    };

    if(showNewestFirst) {
      commentData.reverse = 1;
    }

    this.setState(commentData);

    const commentOptions = {
      id: commentId,
      containerId,
      containerType,
      extensionMode
    };

    commentDelete({commentOptions}).then(updatedComments => {
      this.useLocalImages(updatedComments);
      this.setState({
        data: updatedComments,
        showFeedLink: this._isPost() && !updatedComments.length
      }, () => {
        if(commentId === pinnedComment?.id) {
          updatePinnedComment(null);
        }
      });

      // trigger refresh if deleted prime comment & in post details modal
      if(!updatedComments.length) {
        window.location.reload();
      }

      analyticsTrack({
        type: 'event',
        category: `${capitalize(type)}`,
        action: 'delete comment',
        label: `id:${containerId}, user:${user.id}`
      });

      // remove once updatedComments state is centralized in AppBase
      onScratchpadRefresh && onScratchpadRefresh({removeCommentId: commentId});

      this.refreshCommentsData();
    });
  };

  refreshComment = (comment = {}) => {
    if(_.isEmpty(comment)) {
      return;
    }

    const commentIndex = this.state.data.findIndex(c => c.id === comment.id);

    console.log('CommentBox.refreshComment: comment: %o, commentIndex: %o', comment, commentIndex);

    if(commentIndex >= 0) {
      const state = {};

      state[commentIndex] = {$set: comment};

      this.setState({
        data: ReactUpdate(this.state.data, state)
      }, () => console.log('CommentBox.refreshComment: updated data: %o', this.state.data));
    }
  };

  handleToggleComments = event => {
    if(event) {
      event.preventDefault();
    }

    this.setState(prevState => {
      const {visible} = prevState;

      if(!visible) {
        this.loadComments();
      }

      return {
        visible: !visible
      };
    });
  };

  shouldShowComments = () => !this.props.hideComments || (this.props.allowCommentsToggle && this.state.visible);

  isAutoScroll = () => this._isPost() && (!this.props.allowCommentsToggle);

  _getCommentCount = () => {
    const {commentCount} = this.props;
    const {commentsSynced, data} = this.state;

    if(commentsSynced) {
      return Math.max(0, data.length - 1);
    }

    return Math.max(0, commentCount - 1);
  };

  renderCommentStats = hideIfEmpty => {
    const {hideStat, allowCommentsToggle, extensionMode} = this.props;
    const commentCount = this._getCommentCount();

    if(hideStat || (hideIfEmpty && !commentCount) || extensionMode) {
      return;
    }

    const extraCommentStatProps = {};

    if(allowCommentsToggle) {
      extraCommentStatProps.onToggleCommentsClick = this.handleToggleComments;
    }

    return (
      <CommentStat
        hasCommented={this.getHasCommented()}
        commentCount={commentCount}
        {...extraCommentStatProps} />
    );
  };

  renderCommentList = () => {
    const {pinnedComment, isMakingPinRequest} = this.props;

    if(!this.shouldShowComments()) {
      return;
    }

    const commentListProps = {};

    if(this.isAutoScroll()) {
      commentListProps.onRivalSelect = this.props.onRivalSelect;
    }

    if(this._showFavorites()) {
      _.extend(commentListProps, {
        favorites: this.props.favorites
      });
    }

    return (
      <CommentList
        user={this.props.user}
        users={this.props.users}
        data={this.state.data}
        containerId={this.props.containerId}
        containerType={this.props.containerType}
        showNewestFirst={this.props.showNewestFirst}
        suggestion={this.props.suggestion}
        onCommentDelete={this.handleCommentDelete}
        onCommentSubmit={this.handleCommentSubmit}
        showFeedLink={this.state.showFeedLink}
        listStyle={this.state.listStyle}
        postHasAttachment={this.props.postHasAttachment}
        handlePinClick={this.props.handlePinClick}
        rivals={this.props.rivals}
        rivalMatches={this.props.rivalMatches}
        onRivalCreate={this.props.onRivalCreate}
        onCommentUpdate={this.refreshComment}
        onHighlightScroll={this.scrollToPosition}
        digestMode={this.props.digestMode}
        canTakeScreenshot={this.props.canTakeScreenshot}
        extensionMode={this.props.extensionMode}
        pinnedComment={pinnedComment}
        isMakingPinRequest={isMakingPinRequest}
        {...commentListProps} />
    );
  };

  renderCommentForm = () => {
    const {canTakeScreenshot, containerId, containerType, replaceCommentsForm, suggestion, user, rivals, extensionMode} = this.props;
    const {commentsLoaded, showFeedLink} = this.state;

    if(!this.shouldShowComments()) {
      return;
    }

    return replaceCommentsForm || (!showFeedLink && (
      <CommentForm
        ref={this.setCommentFormRef}
        user={user}
        containerId={containerId}
        containerType={containerType}
        suggestion={suggestion}
        autoScroll={this.isAutoScroll()}
        onCommentSubmit={this.handleCommentSubmit}
        canTakeScreenshot={canTakeScreenshot}
        commentsLoaded={commentsLoaded}
        rivals={rivals}
        isExtensionMode={extensionMode}
        commentCount={this._getCommentCount()} />
    ));
  };

  render() {
    const {data, visible: open, commentsLoaded} = this.state;
    const {containerType, showNewestFirst} = this.props;
    const commentBoxClasses = classNames('comment-box comment-inline', {
      'comment-box--has-comments': commentsLoaded && (data.length > 1),
      open
    });

    if(showNewestFirst || ['profile', 'battlecard'].includes(containerType)) {
      // form @ top, no header, comments descending
      return (
        <div ref={this.setCommentBoxRef} className={commentBoxClasses}>
          {this.renderCommentForm()}
          {this.renderCommentStats(true)}
          {this.renderCommentList()}
        </div>
      );
    }

    // form @ bottom, comments ascending
    return (
      <div ref={this.setCommentBoxRef} className={commentBoxClasses}>
        {this.renderCommentStats()}
        {this.renderCommentList()}
        {this.renderCommentForm()}
      </div>
    );
  }

}

const mapStateToProps = ({rivals}) => ({rivals: rivals.items});

export default connect(mapStateToProps, null, null, {withRef: true})(CommentBox);
