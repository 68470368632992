export const getItemTypes = notification => {
  if(!notification || !notification.comment) {
    return [];
  }

  const {containers = []} = notification.comment;

  if(!containers || !containers.length) {
    // handle comments with no container set (edge case; deleted/inactive parent, emailed-in reply)
    return [{
      id: 0,
      type: 'Other',
      link: null
    }];
  }

  // NOTE: we ignore any "board" containerType references from parent notification (as they would be coming from scratchpad)
  // TODO: if board comments are enabled in the future, this will need reworking to specifically filter out the scratchpad board
  return containers.map(container => {
    const {containerId: id, parentId, containerType} = container;

    switch(containerType.toLowerCase()) {
      case 'post':
      default:
        return {
          id,
          type: 'Feed Post',
          link: `/posts/${id}`
        };
      case 'profile':
        return {
          id,
          type: 'Board',
          link: `/profile/${id}`
        };
      case 'battlecard':
        return {
          id,
          type: 'Battlecard',
          link: `/profile/${parentId}/battlecard/${id}`
        };
      case 'card':
        // TODO: show & link to actual card name on profile (card comments NYI)
        return {
          id,
          type: 'Card',
          link: `/profile/${parentId}`
        };
    }
  });
};
