const DigestMenuGroup = ({children}) => {
  return (
    <div className="digest-menu-group">
      {children}
    </div>
  );
};

DigestMenuGroup.propTypes = {
  children: PropTypes.node
};

DigestMenuGroup.defaultProps = {
  children: null
};

export default DigestMenuGroup;
