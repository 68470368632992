import styled from 'styled-components';
import tokens from '@kluein/design-tokens';
import {desktopLayout, tabletLayout} from '../_base_styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Title = styled.span`
  font-family: 'Barlow',system-ui,sans-serif;
  overflow-wrap: break-word;
  margin-top: 0em;
  max-width: unset;
  color: ${tokens.color.neutral[1000]};
  margin-bottom: 0;
  font-size: 32px !important;
  line-height: 40px !important;
  font-weight: ${tokens.fontWeight.semibold};

  ${tabletLayout`
    font-size: 24px !important;
    line-height: 30px !important;
  `}

  ${desktopLayout`
    font-size: 32px !important;
    line-height: 34px !important;
  `}
`;

export const Description = styled(Title)`
  color: ${tokens.color.neutral[900]};
  font-size: 24px !important;
  line-height: 32px !important;
  font-weight: ${tokens.fontWeight.regular};

  ${tabletLayout`
    font-size: 20px !important;
    line-height: 28px !important;
  `}

  ${desktopLayout`
    font-size: 24px !important;
    line-height: 32px !important;
  `}
`;
