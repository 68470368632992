
const StorageKeysSupported = {COMMENT: 'comment', DIGEST: 'digest', CARD: 'card', MOBILE: 'mobile'};
const EntityType = {EXISTING: 'c', NEW: 'p'};
const CommentableTypes = ['post', 'profile', 'board', 'card', 'battlecard'];
const rivalUrlsKey = 'rival-logo-urls';
const deleteAlertsKey = 'deleted-alerts';
const editorBoundsKey = 'card-editor-bounds';

const ExpiryKey = 'e';
const tenMinutes = 10 * 60 * 1000;
const twoDays = 2 * 24 * 60 * 60 * 1000;
const ExpirationPeriod = {COMMENT: twoDays, DIGEST: twoDays, CARD: twoDays};

const findEntityInRecords = (data, storedRecords, recordType) => {
  let entity = null;

  if(data && storedRecords && _.isObjectLike(data) && _.isObjectLike(storedRecords)) {
    const {id, parentId} = data;

    if(id || parentId) {
      let trueCondition = true;

      if(recordType === StorageKeysSupported.COMMENT) {
        const {containerType} = data;

        trueCondition = containerType && CommentableTypes.includes(containerType);
        storedRecords = storedRecords[containerType];
      }

      if(trueCondition) {
        const expectedKey = id ? EntityType.EXISTING + id : EntityType.NEW + parentId;
        const records = storedRecords || [];

        for(const key in records) {
          if(records.hasOwnProperty(key)) {
            // comments or card keys can be of values c12356 (existing item with known ID) or p589345 (new item attached to a parent with ID)
            if(key === expectedKey) {
              entity = records[key];
              break;
            }
          }
        }
      }
    }
  }

  return entity;
};

const getRecordsByTypeFromLS = key => {
  let arrayObj = null;

  if(key) {
    try {
      arrayObj = localStorage.getItem(key);

      if(arrayObj) {
        arrayObj = JSON.parse(arrayObj);
      }
    }
    catch(err) {
      console.error('LocalStorageUtils: Couldn\'t recover object from LS with key %o: %o', key, err);
    }
  }

  return arrayObj;
};

const getObjectFromLS = (data, recordType) => {
  let entity = null;

  if(data && _.isObjectLike(data)) {
    const storedRecords = getRecordsByTypeFromLS(recordType);

    if(storedRecords && _.isObjectLike(storedRecords)) {
      entity = findEntityInRecords(data, storedRecords, recordType);
    }
  }

  return entity;
};

const saveRecordToLS = (data, recordType, storedRecords = null) => {
  if(data && _.isObjectLike(data) && (data.val1 || data.val2)) {
    try {
      const recordRef = data.id ? EntityType.EXISTING + data.id : EntityType.NEW + data.parentId;
      let recordToSave = null;
      let expiryDate = 0;

      switch(recordType) {
        case StorageKeysSupported.COMMENT:
          expiryDate = new Date().getTime() + ExpirationPeriod.COMMENT;

          const commentDetails = {v: data.val1, a: data.val2, e: expiryDate}; // v for Value, a for Attachments, e for Expiry

          recordToSave = {[data.containerType]: {[recordRef]: commentDetails}};

          break;
        case StorageKeysSupported.CARD:
          expiryDate = new Date().getTime() + ExpirationPeriod.CARD;

          const cardDetails = {t: data.val1, h: data.val2, m: data.template, e: expiryDate}; // text, header, template

          recordToSave = {[recordRef]: cardDetails};

          break;
        case StorageKeysSupported.MOBILE:
          const mobileDetails = {m: data.val1}; // message

          recordToSave = {[recordRef]: mobileDetails};

          break;
        default:
      }

      if(recordToSave) {
        const newObj = _.merge(storedRecords, recordToSave);
        const serializedObj = JSON.stringify(newObj);

        localStorage.setItem(recordType, serializedObj);
      }
    }
    catch(err) {
      console.error('LocalStorageUtils: Couldn\'t save %o: %o', data, err);
    }
  }
};

const deleteRecordFromLS = (data, recordType) => {
  if(data && _.isObjectLike(data)) {
    const storedRecords = getRecordsByTypeFromLS(recordType);

    if(storedRecords && _.isObjectLike(storedRecords)) {
      const entity = findEntityInRecords(data, storedRecords, recordType);

      if(entity) {
        const entityRef = data.id ? EntityType.EXISTING + data.id : EntityType.NEW + data.parentId;

        if(recordType === StorageKeysSupported.COMMENT) {
          delete storedRecords[data.containerType][entityRef];
        }
        else {
          delete storedRecords[entityRef];
        }

        const serializedObj = JSON.stringify(storedRecords);

        localStorage.setItem(recordType, serializedObj);
      }
    }
  }
};

const putObjectToLS = (data, recordType) => {
  if(data && _.isObjectLike(data)) {
    const storedRecords = getRecordsByTypeFromLS(recordType);

    if(storedRecords && _.isObjectLike(storedRecords)) {
      if(!data.val1 && !data.val2) {
        if(findEntityInRecords(data, storedRecords, recordType)) {
          deleteRecordFromLS(data);
        }
      }
      else {
        saveRecordToLS(data, recordType, storedRecords);
      }
    }
    else if(data.val1 || data.val2) {
      saveRecordToLS(data, recordType);
    }
  }
};

const checkExpiryDate = entry => {
  let isExpired = false;

  if(entry && _.isObjectLike(entry) && entry.hasOwnProperty(ExpiryKey)) {
    const expiryDate = new Date(entry[ExpiryKey]);
    const remainingSeconds = (expiryDate.getTime() - new Date().getTime()) / 1000;

    isExpired = remainingSeconds <= 0;
  }

  return isExpired;
};

/*
 Invoked on App start to remove the records from the Local Storage which expiry date has passed
*/
export const clearStaleLSRecords = () => {
  // iterate through all the top level records that are identified by keys from StorageKeysSupported
  for(const nextKey in StorageKeysSupported) {
    if(StorageKeysSupported[nextKey]) {
      const nextType = StorageKeysSupported[nextKey];
      const nextRecords = getRecordsByTypeFromLS(nextType);
      let isNeedUpdate = false;

      // if key is not found in LS move to checking the next key
      if(!nextRecords || !_.isObjectLike(nextRecords)) {
        continue;
      }

      // distinguish between Comment, Card, etc
      switch(nextType) {
        case StorageKeysSupported.COMMENT:
          // iterate through all comments entries that are identified by the keys from CommentableTypes
          for(const nextCommentableType in CommentableTypes) {
            if(CommentableTypes[nextCommentableType]) {
              const commentableType = CommentableTypes[nextCommentableType];

              if(nextRecords[commentableType] && _.isObjectLike(nextRecords[commentableType])) {
                const commentsByType = nextRecords[commentableType];

                // iterate through all bottom level records, exact comment entries
                for(const commentRef in commentsByType) {
                  if(commentsByType[commentRef] && _.isObjectLike(commentsByType[commentRef])) {
                    const isExpired = checkExpiryDate(commentsByType[commentRef]);

                    if(isExpired) {
                      isNeedUpdate = true;
                      delete nextRecords[commentableType][commentRef];
                    }
                  }
                }
              }
            }
          }
          break;

        case StorageKeysSupported.CARD:
          // iterate through all bottom level records, exact card entries
          for(const cardRef in nextRecords) {
            if(nextRecords[cardRef] && _.isObjectLike(nextRecords[cardRef])) {
              const isExpired = checkExpiryDate(nextRecords[cardRef]);

              if(isExpired) {
                isNeedUpdate = true;
                delete nextRecords[cardRef];
              }
            }
          }
          break;

        default:
      }

      // optional LS item reset in case expired items were removed during walkthrough
      if(isNeedUpdate) {
        try {
          const updatedSerializedObj = JSON.stringify(nextRecords);

          localStorage.setItem(StorageKeysSupported[nextKey], updatedSerializedObj);
        }
        catch(err) {
          console.error('LocalStorageUtils: Couldn\'t remove stale items: %o', err);
        }
      }
    }
  }
};

export const getCommentFromLS = data => {
  const comment = getObjectFromLS(data, StorageKeysSupported.COMMENT);

  if(comment) {
    // rename the object fields for further convenience
    if(comment.hasOwnProperty('v')) { // v for Value
      comment.text = comment.v;
      delete comment.v;
    }

    if(comment.hasOwnProperty('a')) { // a for Attachments
      comment.attachments = comment.a;
      delete comment.a;
    }

    if(comment.hasOwnProperty(ExpiryKey)) { // e for ExpiryKey, no need to pass it back
      delete comment[ExpiryKey];
    }
  }

  return comment;
};

export const getCardFromLS = data => {
  const card = getObjectFromLS(data, StorageKeysSupported.CARD);

  if(card) {
    // rename the object fields for further convenience
    if(card.hasOwnProperty('t')) { // t for Text
      card.text = card.t;
      delete card.t;
    }

    if(card.hasOwnProperty('h')) { // h for Title (*H*eader)
      card.title = card.h;
      delete card.h;
    }

    if(card.hasOwnProperty('m')) { // m for TeMplate
      card.template = card.m;
      delete card.m;
    }

    if(card.hasOwnProperty(ExpiryKey)) { // e for ExpiryKey, no need to pass it back
      delete card[ExpiryKey];
    }
  }

  return card;
};

export const getMobileBannerDismissedFromLS = (data = {id: 1}) => getObjectFromLS(data, StorageKeysSupported.MOBILE);

export const putCommentToLS = data => {
  putObjectToLS(data, StorageKeysSupported.COMMENT);
};

export const putCardToLS = data => {
  putObjectToLS(data, StorageKeysSupported.CARD);
};

export const deleteCommentFromLS = data => {
  deleteRecordFromLS(data, StorageKeysSupported.COMMENT);
};

export const deleteCardFromLS = data => {
  deleteRecordFromLS(data, StorageKeysSupported.CARD);
};

export const putMobileBannerDismissedToLS = (data = {id: 1, val1: 'user did dismiss mobile download banner'}) => {
  putObjectToLS(data, StorageKeysSupported.MOBILE);
};

export const setLocalRivalLogoUrl = (rival, url) => {
  if(!rival) {
    return;
  }

  const {id} = rival;

  const rivalUrls = JSON.parse(localStorage.getItem(rivalUrlsKey) || '{}');

  if(!url) {
    delete rivalUrls[id];

    return localStorage.setItem(rivalUrlsKey, JSON.stringify(rivalUrls));
  }

  const expiry = new Date().getTime() + tenMinutes;

  localStorage.setItem(rivalUrlsKey, JSON.stringify({...rivalUrls, [id]: {url, expiry}}));
};

export const localRivalLogoUrl = rival => {
  const {id, iconUrl} = rival || {};

  if(!id) {
    return;
  }

  const rivalUrls = JSON.parse(localStorage.getItem(rivalUrlsKey) || '{}');
  const {url, expiry} = rivalUrls[id] || {};

  if(url && expiry) {
    const expiryDate = new Date(expiry);
    const isExpired = (expiryDate.getTime() - new Date().getTime()) <= 0;

    if(!isExpired) {
      return url;
    }

    setLocalRivalLogoUrl(rival);
  }

  return iconUrl;
};

export const setDeletedAlerts = alerts => {
  const data = JSON.stringify(alerts || {});

  localStorage.setItem(deleteAlertsKey, data);
};

export const getDeletedAlerts = () => {
  const data = localStorage.getItem(deleteAlertsKey);
  const alerts = JSON.parse(data || '{}');

  return alerts;
};

export const setEditorBounds = ({top, left, width, height}) => {
  const data = JSON.stringify({top, left, width, height});

  localStorage.setItem(editorBoundsKey, data);
};

export const getEditorBounds = () => {
  const data = localStorage.getItem(editorBoundsKey);
  const bounds = JSON.parse(data || '{}');

  return bounds;
};

