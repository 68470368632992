import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchPdlData} from './data_providers';

export const jobCategories = company => {
  if(!KlueTypes.Company.validate(company)) {
    console.log('Unexpected parameter type.', company);
    throw new Error('Parameter was unexpected type.');
  }

  return fetchPdlData(company.info).then(savedData => {
    const categories = savedData.jobCategories.map(({category, percentage}) => ({label: category, amount: percentage}));

    if(!categories.length) {
      throw new Error('jobCategories data not available.');
    }

    return KlueTypes.Distribution.create({
      portions: Types.arrayOf(KlueTypes.DistributionPortion).create(categories)
    });
  });
};
