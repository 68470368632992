import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ChartistGraph from 'react-chartist';

import ChartLegend from './_chart_legend';

class DistributionGraph extends Component {

  static propTypes = {
    portions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    })).isRequired
  };

  static defaultProps = {
    portions: []
  };

  render() {
    const sorted = [...this.props.portions];

    sorted.sort((a, b) => b.amount - a.amount);

    const chartData = {
      series: sorted.map(p => p.amount)
    };

    const chartOptions = {
      height: 260,
      chartPadding: 0,
      showLabel: true,
      labelInterpolationFnc(value) {
        return (value / chartData.series.reduce((a, b) => a + b) * 100).toFixed(2) + '%';
      }
    };

    //options={chartOptions} />
    return (
      <div className="distribution-graph">
        <ChartistGraph data={chartData} type="Pie" options={chartOptions} />
        <ChartLegend entries={sorted.map(p => p.label)} />
      </div>
    );
  }

}

export default DistributionGraph;
