const RadioCheckboxList = props => {
  const renderOptions = () => {
    if(!props.options.length) {
      return;
    }

    return props.options.map(opt => {
      let isSelected;
      const {selectedValue, alertStyleCheck, multiSelect, onOptionClick} = props;
      const {value, label, dataTestId} = opt;
      const attributes = {};

      if(dataTestId) {
        attributes['data-testid'] = dataTestId;
      }

      if(multiSelect && Array.isArray(selectedValue)) {
        isSelected = (selectedValue.includes(value));
      }
      else {
        isSelected = (value === selectedValue);
      }

      if(alertStyleCheck) {
        const iconOn = 'fa fa-check';
        const iconOff = 'fa fa-square-o';
        const iconStyle = isSelected ? {color: '#44bc90'} : {color: '#575265'};
        const labelStyle = isSelected ? {color: '#44bc90'} : {color: '#31abe3'};

        return (
          <li className="radio-checkbox-alt radio-checkbox--inline"
            data-option-value={value}
            key={value}
            onClick={onOptionClick.bind(this, value)}
            {...attributes}>
            <i
              className={`radio-checkbox-alt-icon ${isSelected ? iconOn : iconOff}`}
              style={iconStyle}
              aria-hidden="true" />
            <span
              className="radio-checkbox-alt-label"
              style={labelStyle}
              title={label.toUpperCase()}>
              {label}
            </span>
          </li>
        );
      }

      return (
        <li
          key={`${props.keyPrefix}-${value}`}
          data-option-value={value}
          className={`radio-checkbox radio-checkbox--inline${isSelected ? ' radio-checkbox--selected' : ''}`}
          onClick={props.onOptionClick.bind(this, value)}
          {...attributes}>
          <span className="radio-checkbox_label" title={label}>{label}</span>
        </li>
      );
    });
  };

  return (
    <ul className="radio-checkbox-list">
      {renderOptions()}
    </ul>
  );
};

RadioCheckboxList.propTypes = {
  alertStyleCheck: PropTypes.bool,
  multiSelect: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  onOptionClick: PropTypes.func,
  keyPrefix: PropTypes.string
};

RadioCheckboxList.defaultProps = {
  alertStyleCheck: false,
  multiSelect: false,
  options: [],
  selectedValue: '',
  onOptionClick: null,
  keyPrefix: 'rcl'
};

export default RadioCheckboxList;
