const BoardTypeInfo = ({boardType, name, url}) => {
  if(!boardType.match('company|product')) {
    return null;
  }

  return (
    <div className="board-type-info">
      <div className="board-type-info_type">
        {`${boardType} board`}
      </div>
      {name && <div className="board-type-info_company">
        {name}
      </div>}
      {url && <div className="board-type-info_url">
        <a href={url} target="#">{url}</a>
      </div>}
    </div>
  );
};

BoardTypeInfo.propTypes = {
  boardType: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string
};

BoardTypeInfo.defaultProps = {
  boardType: 'company',
  name: '',
  url: ''
};

export default BoardTypeInfo;
