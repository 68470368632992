import React from 'react';
import PropTypes from 'prop-types';

import CompareTimeseriesBuilder from './_compare_timeseries_builder';

const CompareRevenueGrowthBuilder = ({onChange}) => {
  CompareRevenueGrowthBuilder.propTypes = {
    onChange: PropTypes.func.isRequired
  };

  CompareRevenueGrowthBuilder.defaultProps = {
    onChange() {}
  };

  return (<CompareTimeseriesBuilder onChange={onChange} funcName="REVENUES" />);
};

export default CompareRevenueGrowthBuilder;
