/* eslint-disable react/no-multi-comp */
import {useState, useMemo, useEffect, useCallback} from 'react';
import classNames from 'classnames';
import Icon from '../_icon';
import TagsInput from '../_tags_input';
import useIsMounted from './_use_is_mounted';
import OptionsDropdown from './_options_dropdown';

const handleQueryTermsValidaiton = str => {
  if(/^.*?(?=[/()"'!]).*$/.test(str)) {
    return '/ ) ( "  \' ! are not allowed';
  }

  if(/^.*[*].+$/.test(str) || str === '*') {
    return '\'*\' is a wildcard character. only allowed at the end of your phrase';
  }

  //reset the validation error
  return undefined;
};

const QueryInput = ({name, value, onChange, editing, customValue, autoFocus, isActive}) => {
  const showTagsInput = Boolean(value.length) || editing;

  return (
    <div className={classNames('query', {custom: Boolean(customValue), disabled: !editing && !isActive})}>
      {
        customValue
          ? <p className="query-text">{customValue}</p>
          : showTagsInput
            ? <TagsInput
                className="query-input"
                name={name}
                onChange={onChange}
                validate={handleQueryTermsValidaiton}
                tags={value}
                warning={name === 'not'}
                secondary={name === 'should'}
                readonly={!editing}
                autoFocus={autoFocus} />
            : <p className="query-text">-</p>
      }
    </div>
  );
};

QueryInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  editing: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  customValue: PropTypes.string,
  isActive: PropTypes.bool
};

QueryInput.defaultProps = {
  value: [],
  editing: false,
  autoFocus: false,
  customValue: undefined,
  isActive: true
};

// eslint-disable-next-line react/prop-types
const DefaultButtons = ({isActive, onDelete, onToggleState}) => (
  <span className="buttons-container">

    <a href="#" className="action-button" onClick={onToggleState}>
      <Icon icon={isActive ? 'pause' : 'play'} />
    </a>
    <a href="#" className="action-button action-button-delete" onClick={onDelete}>
      <Icon icon="deleteX" height="22" width="22" />
    </a>

  </span>
);

/**
 * renders Query Boxes
 * needs to have the structure of query by queryStructure param, then it will be create boxes based on that.
 * queryStructure Example:
 *
 * [{id: 'must', description: 'Include All of these'},
 *  {id: 'not', description: 'Not Include these'}]
 *
 * It will look for the values in queryValues based on IDs you provided in the structure.
 *
 * @param root0
 * @param root0.queryId
 * @param root0.queryStructure
 * @param root0.queryValues
 * @param root0.customQueryValue
 * @param root0.isActive
 * @param root0.isHighVolumeAlerts
 * @param root0.isCustom
 * @param root0.editMode
 * @param root0.newQuery
 * @param root0.showLabel
 * @param root0.onSubmit
 * @param root0.onDelete
 * @param root0.onToggleState
 * @param root0.onDiscard
 * @param root0.onToggleHasUnsavedData
 * @returns {Element} the Query Container
 */
const QueryContainer = ({
  queryId,
  queryStructure,
  queryValues = {},
  customQueryValue,
  isActive,
  isHighVolumeAlerts,
  isCustom,
  editMode,
  newQuery,
  showLabel,
  onSubmit,
  onDelete,
  onToggleState,
  onDiscard,
  onToggleHasUnsavedData
}) => {
  const isMounted = useIsMounted();
  const [userQuery, setUserQuery] = useState(queryValues);
  const [editing, setEditing] = useState(editMode);
  const [showOptions, setShowOptions] = useState(false);

  // close editing when the query is updated
  useEffect(() => { setEditing(newQuery || false); }, [newQuery, queryValues]);

  const calculateDisableSubmit = useCallback(() => {
    if(!editing) {
      return false;
    }

    return queryStructure.reduce((acc, item) => {
      const isEmpty = !(userQuery[item.id] && userQuery[item.id].length);

      return acc && isEmpty;
    }, true);
  }, [editing, queryStructure, userQuery]);

  const handleInputChange = (value, name) => {
    setUserQuery({...userQuery, [name]: value});
  };

  const handleOptionsChange = values => {
    if(values && values.hasOwnProperty('caseInsensitive')) {
      setUserQuery({...userQuery, case_sensitive: !values.caseInsensitive});
    }
  };

  const handleDiscard = event => {
    if(event) {
      event.stopPropagation();
    }

    if(onDiscard) {
      onDiscard();
    }

    setUserQuery(queryValues);
    setEditing(false);
    onToggleHasUnsavedData && onToggleHasUnsavedData(queryId, false);
  };

  const handleSubmit = event => {
    if(event) {
      event.stopPropagation();
    }

    const query = {userQuery};

    // query id is needed for the update request
    if(!newQuery) {
      query.id = queryId;
    }

    onSubmit(query, event);
  };

  const handleEdit = event => {
    if(event) {
      event.preventDefault();
    }

    if(!isCustom) {setEditing(true);}
  };

  const handleDelete = event => {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onDelete(event);
  };

  const handleToggleState = event => {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onToggleState(event);
  };

  const disableSubmit = useMemo(() => calculateDisableSubmit(), [calculateDisableSubmit]);

  useEffect(() => {
    if(isMounted && editing && onToggleHasUnsavedData) {
      onToggleHasUnsavedData(queryId, !disableSubmit);
    }
  }, [disableSubmit, onToggleHasUnsavedData, queryId, isMounted, editing]);

  const handleKeyUp = event => {
    const {key} = event;

    if(key === 'Enter') {
      handleEdit(event);
    }
    else if(key === 'Escape') {
      handleDiscard(event);
    }
  };

  const debounceToggleShowOptions = _.debounce(() => setShowOptions(prev => !prev), 150);

  const handleToggleShowOptions = event => {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    debounceToggleShowOptions();
  };

  const pausedMessage = () => {
    return isHighVolumeAlerts ? 'paused due to high volume alerts' : 'paused';
  };

  return (
    <div
      className={classNames('query-container', {'editable-query': !editing && !isCustom, 'disabled-query': !isActive, 'edit-mode': editing})}
      data-testid="query-container"
      tabIndex="0"
      data-tracking-id="query-container"
      onKeyUp={handleKeyUp}
      onClick={handleEdit}>
      {showLabel && <p className={classNames('query-label', {'query-label-paused': !isActive}, {'query-label-or': isActive})}>
        {!isActive ? pausedMessage() : 'or'}
      </p>}
      {
        isCustom
          ? <QueryInput name="customQuery" key={queryId} customValue={customQueryValue} onChange={handleInputChange} editing={editing} />
          : queryStructure.map(query => {
            //focus on first input for new queries
            const autoFocus = query.autoFocus && newQuery;

            // name attribute will be used to detect which box has changed.
            return (<QueryInput
              name={query.id}
              key={query.id}
              value={userQuery[query.id]}
              onChange={handleInputChange}
              editing={editing}
              isActive={isActive}
              autoFocus={autoFocus} />);
          })
      }
      {
        editing
          ? <span className="buttons-container edit-mode">
            <div>
              <button
                type="button"
                className="btn button button--small button-save"
                onClick={handleSubmit}
                disabled={disableSubmit}>
                Save
              </button>
              <span>
                <a href="#"
                  className={classNames('action-button action-button-settings', {'action-button-settings-active': showOptions})}
                  onClick={handleToggleShowOptions}
                  data-testid="settings-action-button">
                  <Icon icon="settings" height="22" width="22" />
                </a>
                {showOptions &&
                <OptionsDropdown
                  onClose={handleToggleShowOptions}
                  onChange={handleOptionsChange}
                  options={[{key: 'caseInsensitive', description: 'Ignore Case ("My Word" = "my word")', value: !userQuery.case_sensitive}]} />}
              </span>
            </div>
            <button type="button" className="button button--small button-cancel" onClick={handleDiscard}>cancel</button>
          </span>
          : <DefaultButtons isActive={isActive} isCustom={isCustom} onEdit={handleEdit} onDelete={handleDelete} onToggleState={handleToggleState} />
      }

    </div>
  );
};

QueryContainer.propTypes = {
  queryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  queryStructure: PropTypes.array,
  queryValues: PropTypes.object,
  customQueryValue: PropTypes.string,
  isActive: PropTypes.bool,
  isHighVolumeAlerts: PropTypes.bool,
  isCustom: PropTypes.bool,
  editMode: PropTypes.bool,
  newQuery: PropTypes.bool,
  showLabel: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onToggleState: PropTypes.func,
  onDiscard: PropTypes.func,
  onToggleHasUnsavedData: PropTypes.func
};

QueryContainer.defaultProps = {
  queryId: undefined,
  queryStructure: [],
  queryValues: {},
  customQueryValue: '',
  isActive: true,
  isHighVolumeAlerts: true,
  isCustom: false,
  editMode: false,
  newQuery: false,
  showLabel: false,
  onSubmit() {},
  onDelete() {},
  onToggleState() {},
  onToggleHasUnsavedData() {},
  onDiscard: undefined
};

export default QueryContainer;
