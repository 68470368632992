import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SummaryTableRow from './_summary_table_row';

const Table = styled.table`
  font-size: 12px;
  font-weight: 600;
  color: #938b9b;
  width: 100%;
  max-width: 100%;
`;

const SummaryTable = ({facts}) => {
  const rows = facts.map(fact => (<SummaryTableRow key={fact.label} summaryFact={fact} />));

  return (
    <Table>
      <tbody>
        {rows}
      </tbody>
    </Table>
  );
};

SummaryTable.propTypes = {
  facts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired
  })).isRequired
};

SummaryTable.defaultProps = {
  facts: []
};

export default SummaryTable;
