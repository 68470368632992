import {useState, useEffect} from 'react';

const getDeviceType = (responsivenessObject, defaultDevice) => {
  const width = window.innerWidth;
  const responsiveEntries = Object.entries(responsivenessObject);

  for(const [deviceType, maxWidth] of responsiveEntries) {
    if(width <= maxWidth) {
      return deviceType;
    }
  }

  return defaultDevice;
};

const useResponsive = (responsivenessObject, defaultDevice) => {
  const [deviceType, setDeviceType] = useState(getDeviceType(
    responsivenessObject,
    defaultDevice));

  useEffect(() => {
    const handleResize = () => {
      setDeviceType(getDeviceType(
        responsivenessObject,
        defaultDevice));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [defaultDevice, responsivenessObject]);

  return deviceType;
};

export default useResponsive;
