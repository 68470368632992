import Icon from './_icon';

const FormLabelWithField = ({label, onSettingsClick, settingsVisible, children, titleDataTrackingId}) => {
  return (
    <div className="form-label-with-field">
      <div className="form-label-with-field-title" data-tracking-id={titleDataTrackingId}>
        {label}
        {onSettingsClick && <a onClick={onSettingsClick}><Icon icon={`visibility-${settingsVisible ? 'off' : 'on'}`} width="16" height="16" /></a>}
      </div>
      {children}
    </div>
  );
};

FormLabelWithField.propTypes = {
  label: PropTypes.string.isRequired,
  settingsVisible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onSettingsClick: PropTypes.func,
  titleDataTrackingId: PropTypes.string
};

FormLabelWithField.defaultProps = {
  settingsVisible: false,
  onSettingsClick: null,
  titleDataTrackingId: null
};

export default FormLabelWithField;
