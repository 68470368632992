import moment from 'moment';

import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchCraftData} from './data_providers';

function hasRevenue(statement) {
  return statement !== undefined && statement !== null;
}

export function revenues(company, fetchData = fetchCraftData) {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);
    throw new Error('Parameter was unexpected type.');
  }

  return fetchData(company.info).then(({displayName, incomeStatements}) => {
    let annualStatements = incomeStatements.filter(statement => statement.period.periodType === 'fiscal_year').filter(hasRevenue);

    if(annualStatements.length === 0) {
      annualStatements = incomeStatements.filter(statement => statement.period.periodType === 'year').filter(hasRevenue);
    }

    const events = annualStatements.map(statement => {
      const {revenue, period: {endDate}} = statement;
      const date = KlueTypes.Date.create(moment(endDate));

      return KlueTypes.TimeseriesEvent.create({date, value: revenue});
    });

    return KlueTypes.Timeseries.create({
      label: displayName,
      events: Types.arrayOf(KlueTypes.TimeseriesEvent).create(events)
    });
  });
}
