import {redirectToV2} from '../../modules/route_utils';

const SEARCH_PATHNAME = '/search';

const CardTagSearchLink = ({id, children}, context) => {
  const {appData: {v2Host}} = context;

  // doing an explicit redirect here because the Link component causes
  // a back button hit to stay in the v2 app
  const handleLinkClick = event => {
    event.preventDefault();
    event.stopPropagation();

    redirectToV2({
      v2Host,
      v2Path: SEARCH_PATHNAME,
      v2Search: `?tags=${id}`
    });
  };

  const dest = new URL(window.location.href);

  dest.host = v2Host;
  dest.pathname = SEARCH_PATHNAME;
  dest.search = `?tags=${id}`;

  const href = dest.toString();

  return (
    <a
      href={href}
      target="_self"
      onClick={handleLinkClick}>
      {children}
    </a>
  );
};

CardTagSearchLink.contextTypes = {
  appData: PropTypes.object.isRequired
};

CardTagSearchLink.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default CardTagSearchLink;
