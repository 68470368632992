const DigestStats = ({digest, showStats}) => {
  if(!digest || !showStats) {
    return null;
  }

  const {
    emailsOpenCount = 0, emailsUniqueOpenCount = 0, emailsUniqueClickCount = 0, emailsSentCount = 0,
    emailsDeliverCount = 0, useNewStats = false
  } = digest;

  // Show if AT LEAST ONE of the values is greater than zero
  const showBasedOnValues = (emailsSentCount || (useNewStats && emailsDeliverCount)) &&
    Math.max(emailsOpenCount, emailsUniqueOpenCount, emailsUniqueClickCount);

  if(!showBasedOnValues) {
    return null;
  }

  const showZero = useNewStats ? emailsDeliverCount === 0 : false;
  const total = useNewStats ? emailsDeliverCount : emailsSentCount;
  const getRate = count => `${showZero ? 0 : Math.round(((count ?? 0) / total) * 100)}%`;

  return (
    <div className="digest-stats">
      <div className="digest-stats-unique-opens">
        <h5>Opens</h5>
        <p className="digest-stat">
          <span className="opens">{showZero ? 0 : emailsOpenCount}</span>
        </p>
      </div>
      <div className="digest-stats-unique-opens">
        <h5>Unique Opens</h5>
        <p className="digest-stat">
          <span className="opens">{showZero ? 0 : emailsUniqueOpenCount}</span>
          <span>&nbsp;|&nbsp;</span>
          <span className="opens-percent">{getRate(emailsUniqueOpenCount)}</span>
        </p>
      </div>
      <div className="digest-stats-click-thrus">
        <h5>Click-Thrus</h5>
        <p className="digest-stat">
          <span className="click-thrus">{showZero ? 0 : emailsUniqueClickCount}</span>
          <span>&nbsp;|&nbsp;</span>
          <span className="click-thrus-percent">{getRate(emailsUniqueClickCount)}</span>
        </p>
      </div>
    </div>
  );
};

DigestStats.propTypes = {
  digest: PropTypes.object,
  showStats: PropTypes.bool
};

DigestStats.defaultProps = {
  digest: {},
  showStats: false
};

export default DigestStats;
