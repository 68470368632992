import {useState} from 'react';

const DigestName = ({name, onNameChange, onNameBlur, error}) => {
  const [didFocusSelect, setDidFocusSelect] = useState(false);

  const handleNameChange = e => {
    onNameChange(e.target.value);
  };

  const handleOnFocus = () => {
    if(didFocusSelect) { return; }

    setDidFocusSelect(true);
  };

  const setInputRef = input => {
    if(didFocusSelect || !input) { return; }

    input.focus();
    input.select();
  };

  return (
    <div className="digest-name-setting">
      <h4 className="u-pt-m"><strong>Intel Digest Name</strong></h4>
      <h6 className="u-pt-m">Curator eyes only</h6>
      <input type="text"
        data-tracking-id="digest-name-setting-input"
        data-testid="digest-name-setting-input"
        ref={setInputRef}
        className="form-control"
        onChange={handleNameChange}
        onBlur={onNameBlur}
        onFocus={handleOnFocus}
        value={name} />
      {error}
    </div>
  );
};

DigestName.propTypes = {
  name: PropTypes.string,
  onNameChange: PropTypes.func,
  onNameBlur: PropTypes.func,
  error: PropTypes.node
};

DigestName.defaultProps = {
  name: '',
  onNameChange() {},
  onNameBlur() {},
  error: null
};

export default DigestName;
