import DigestArchivesItem from './_digest_archives_item';
import DigestCompetitors from './_digest_competitors';
import Icon from './_icon';

import {
  sanitizeDigestHtml,
  digestHeaderInputs,
  getDefaultOverviewCopy,
  getCompetitorTags,
  getRivalsForTags,
  filterPublishedRivals
} from '../modules/digest_utils';
import {userCanCurate} from '../modules/roles_utils';
import {processLinks, wrapHtml} from '../modules/html_utils';
import {DIGESTS_PER_PAGE} from '../modules/constants/api';

import ReactPaginate from 'react-paginate';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux';
import classNames from 'classnames';

const DigestArchives = props => {
  const {
    user,
    draftDigest,
    activeDigestType,
    subject,
    summary,
    digestByline,
    archivedDigests,
    rivals,
    nextDigestSendTime,
    canDeleteDigest,
    page,
    totalDigests,
    onDeleteDigestClick,
    onPaginationClick
  } = props;
  const isCurator = userCanCurate({user});

  let digestLockedRegion;
  let digestArchivesRegion;
  let digestRuleRegion;
  let upcomingDigestRegion;
  let summaryRegion;
  let digestBodyEl;
  let digestArchivesEl;

  const filteredRivals = filterPublishedRivals(rivals);

  const handlePaginationClick = pageData => {
    digestBodyEl.scrollTop = digestArchivesEl.offsetTop - 12;   // add spacer height above heading

    onPaginationClick(pageData);
  };

  const digestArchivesHeading = isCurator
    ? (
      <span className="digest-archives_heading-container">
        <h4 ref={node => digestArchivesEl = node} className="digest-archives">
          <div className="digest-archives_heading">Past Digests</div>
        </h4>
        <header className="dialogue dialogue--header">
          <h6>Digest stats will show up within 24 hours.</h6>
        </header>
      </span>
    )
    : (
      <h2 ref={node => digestArchivesEl = node} className="digest-archives">
        <div className="digest-archives_heading">Digest Archives</div>
      </h2>
    );

  if(archivedDigests.length) {
    digestArchivesRegion = archivedDigests.reduce((digests, digest) => {
      // non-curators only see "sent" digests (archived & not custom)
      if(isCurator || (!digest.manual && Boolean(digest.archivedAt))) {
        const archiveItemProps = {};

        if(canDeleteDigest(digest)) {
          archiveItemProps.onDelete = onDeleteDigestClick;
        }

        return [...digests, (
          <DigestArchivesItem
            key={`digest_${digest.id}`}
            digest={digest}
            rivalsForItem={getRivalsForTags(getCompetitorTags(digest), filteredRivals, {publishedOnly: true})}
            showStats={isCurator}
            {...archiveItemProps} />
        )];
      }

      return digests;
    }, []);
  }
  else if((totalDigests < 0) || (totalDigests > DIGESTS_PER_PAGE)) {
    const useTotalDigests = totalDigests >= 0 ? totalDigests : DIGESTS_PER_PAGE;
    const isLastPage = parseInt(useTotalDigests / (page + 1), 10) < DIGESTS_PER_PAGE;
    const digestCountForPage = isLastPage ? (useTotalDigests % DIGESTS_PER_PAGE) || DIGESTS_PER_PAGE : DIGESTS_PER_PAGE;

    digestArchivesRegion = [...Array(digestCountForPage).keys()].map(i => {
      const key = `placeholder_${i}`;

      return (
        <div key={key} className="digest-archives--placeholder">
          <div className="digest-archives--placeholder_body" />
          <div className="digest-archives--placeholder_footer" />
        </div>
      );
    });
  }
  else if(!totalDigests && _.isEmpty(archivedDigests)) {
    digestArchivesRegion = (
      <div className="dialogue compact digest-item">
        <p>There are currently no digests in the archive.</p>
      </div>
    );
  }

  if(isCurator && draftDigest) {
    if(Boolean(draftDigest.favorites.length) || digestByline) {
      digestRuleRegion = (
        <hr className="digest-rule" />
      );
    }

    if(!_.isEmpty(draftDigest) && draftDigest.locked) {
      digestLockedRegion = (
        <Icon icon="lock" className="digest-body_preview_subject_icon" width="24" height="24" />
      );
    }

    const overviewCopyArgs = {digest: draftDigest, filteredRivals, plainText: false};
    const subjectPlaceholder = getDefaultOverviewCopy({...overviewCopyArgs, inputType: digestHeaderInputs.SUBJECT});
    const summaryPlaceholder = getDefaultOverviewCopy({...overviewCopyArgs, inputType: digestHeaderInputs.SUMMARY});

    if(summary) {
      summaryRegion = (
        <div className="digest-body_preview_summary" dangerouslySetInnerHTML={wrapHtml(sanitizeDigestHtml(summary))} />
      );
    }
    else {
      summaryRegion = (
        <div className="digest-body_preview_summary">{summaryPlaceholder}</div>
      );
    }

    upcomingDigestRegion = !activeDigestType?.archivedAt ? [
      (
        <header key="header">
          <h2>
            <strong>Next Intel Digest &mdash; {nextDigestSendTime}</strong>
          </h2>
        </header>
      ),
      (
        <div key="preview" id="digestPreview" className="dialogue">
          <h3 className="digest-body_preview_subject">
            {digestLockedRegion}
            <Link to="/digest">{subject || subjectPlaceholder}</Link>
          </h3>
          {summaryRegion}
          {digestRuleRegion}
          <DigestCompetitors
            storyCount={draftDigest.favorites.length}
            rivals={getRivalsForTags(getCompetitorTags(draftDigest), filteredRivals)}
            className="digest-tags" />
          {digestByline && (
            <footer className="digest-form_byline">
              {digestByline}
            </footer>
          )}
        </div>
      )
    ] : null;
  }

  // digest archives pagination
  const pageCount = Math.ceil(totalDigests / DIGESTS_PER_PAGE);
  const iconPrevious = (
    <Icon icon="arrow-left" />
  );
  const iconNext = (
    <Icon icon="arrow-right" />
  );

  const paginator = (pageCount <= 1) ? null : (
    <div className="digest-pagination">
      <ReactPaginate
        previousLabel={(page === 0) ? null : iconPrevious}
        nextLabel={(page === pageCount) ? null : iconNext}
        breakLabel={<span>...</span>}
        breakClassName={'break-me'}
        forcePage={page}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePaginationClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'paginator_active'} />
    </div>
  );

  return (
    <div ref={node => digestBodyEl = node} className={classNames('digest-body digest-body--overview', {'no-padding': true})}>
      {upcomingDigestRegion}
      {digestArchivesHeading}
      {digestArchivesRegion}
      {paginator}
    </div>
  );
};

DigestArchives.propTypes = {
  user: PropTypes.object,
  draftDigest: PropTypes.object,
  activeDigestType: PropTypes.object,
  subject: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  summary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  digestByline: PropTypes.node,
  archivedDigests: PropTypes.arrayOf(PropTypes.object),
  rivals: PropTypes.object.isRequired,
  nextDigestSendTime: PropTypes.node,
  page: PropTypes.number,
  totalDigests: PropTypes.number,
  onPaginationClick: PropTypes.func,
  onDeleteDigestClick: PropTypes.func,
  canDeleteDigest: PropTypes.func
};

DigestArchives.defaultProps = {
  user: null,
  draftDigest: null,
  activeDigestType: null,
  subject: '',
  summary: '',
  digestByline: null,
  archivedDigests: [],
  rivals: {},
  nextDigestSendTime: null,
  page: 1,
  totalDigests: 0,
  onPaginationClick() {},
  onDeleteDigestClick() {},
  canDeleteDigest() {}
};

const mapStateToProps = ({rivals}) => ({rivals: rivals.items});

export default connect(mapStateToProps)(DigestArchives);
