import classNames from 'classnames';

import CompanyLogo from '../components/_company_logo';
import Icon from '../components/_icon';

const CardVitalsSettingsFormHeader = ({expanded, rival, name, disableSubmit, buttonTitle, onClose, onSubmit, values, loading}) => {
  return (
    <div className={classNames('form-header', {expanded})}>
      <div className="logo-and-name">
        <CompanyLogo className="logo" rival={rival} />
        <h2 className="name">{name}</h2>
      </div>
      {loading && (<div className="loader" />)}
      {!loading && (<div className="controls">
        <button
          className={classNames('button button--xsmall', {
            'button--alt': !expanded,
            'button--disabled': disableSubmit
          })}
          type="button"
          onClick={() => onSubmit(values)}
          disabled={disableSubmit}>
          {buttonTitle}
        </button>
        {expanded &&
        <div
          className="cancel"
          onClick={onClose}>
          <Icon icon="close" width="18" height="18" />
        </div>}
      </div>)}
    </div>

  );
};

CardVitalsSettingsFormHeader.propTypes = {
  expanded: PropTypes.bool,
  rival: PropTypes.object,
  name: PropTypes.string,
  disableSubmit: PropTypes.bool,
  buttonTitle: PropTypes.string,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

CardVitalsSettingsFormHeader.defaultProps = {
  expanded: false,
  rival: null,
  name: '',
  disableSubmit: false,
  buttonTitle: '',
  values: {},
  loading: false,
  onClose() {},
  onSubmit() {}
};

export default CardVitalsSettingsFormHeader;
