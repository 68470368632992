// should align with models/document.rb
export const DOCUMENT_CONTENT_TYPES = Object.freeze([
  'text/plain',
  'application/pdf',
  'application/zip',
  'text/rtf',
  'application/rtf',
  'application/msword',
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]);

export const IMAGE_CONTENT_TYPES = Object.freeze([
  'image/*'
]);
