import {rivalsList} from '../modules/api/rivals';
import {profileUpdate} from '../modules/api/profiles';
import {userIsAdmin} from '../modules/roles_utils';
import {pluralize} from '../modules/text_utils';
import {sortUsers} from '../modules/user_utils';

import classNames from 'classnames';
import {Link} from 'react-router-dom';

class CompanySettingsBoardCurators extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    section: PropTypes.string
  };

  static defaultProps = {
    section: ''
  };

  state = {
    allCurators: null,
    rivals: null,
    toggledContainers: []
  };

  componentDidMount() {
    console.log('CompanySettingsBoardCurators.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    // TODO: consider refactoring to use the paginated users API on scroll/visibility here -- this could still potentially fetch many curators
    this.context.api.usersGet({kluebot: true, typeFilter: 'curators'}, users => {
      this.setState({allCurators: sortUsers({users: Object.values(users)})}, () => {
        console.log('CompanySettingsBoardCurators.componentDidMount: loaded all curators: %o', users);
      });
    });

    this.loadRivalsList().then(() => this.scrollToRival());
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // load full rivals listing for profile cloning (w/minimal additional info and no nested profile data)
  loadRivalsList = () => {
    return new Promise(async (resolve, reject) => {
      if(this._isMounted) {
        const rivalOptions = {
          curators: true
        };

        this.setState({
          rivals: await rivalsList({rivalOptions, code: 'CompanySettingsBoardCurators.loadRivalsList'})
        }, () => resolve(this.state.rivals));
      }
      else {
        reject();
      }
    });
  };

  scrollToRival = () => {
    const {section} = this.props;

    if(!section) {
      return;
    }

    setTimeout(() => {
      const rivalSection = this.refs[`r_${section.toLowerCase()}`];

      if(rivalSection) {
        rivalSection.scrollIntoView();
      }
    });
  };

  toggleProfileCurator = (rival, user) => {
    const addCurators = [];
    const removeCurators = [];

    if(rival.curators.includes(user.id)) {
      removeCurators.push(user.id);
    }
    else {
      addCurators.push(user.id);
    }

    const profileOptions = {
      id: rival.profileId,
      curators: {
        add: addCurators,
        remove: removeCurators
      }
    };

    profileUpdate({profileOptions, code: 'CompanySettingsBoardCurators.toggleProfileCurator'}).then(profile => {
      console.log(
        'CompanySettingsBoardCurators.toggleProfileCurator: %o, state: %o, updated profile: %o', user, (addCurators.length ? 'on' : 'off'), profile
      );

      if(user.id === this.context.utils.user.id) {
        // refresh current user if profile ownership was changed
        this.context.api.userGet({userId: user.id}).then(refreshedUser => {
          console.log('CompanySettingsBoardCurators.toggleProfileCurator: refreshed current user: %o', refreshedUser);
        });
      }

      this.refreshRivalCurators(rival, profile.curators);
    });
  };

  clearProfileCurators = rival => {
    if(!rival.curators.length) {
      // nothing to do
      return;
    }

    const profileOptions = {
      id: rival.profileId,
      curators: {
        remove: rival.curators
      }
    };

    profileUpdate({profileOptions, code: 'CompanySettingsBoardCurators.clearProfileCurators'}).then(profile => {
      console.log('CompanySettingsBoardCurators.clearProfileCurators: for rival: %o, updated profile', rival, profile);

      this.refreshRivalCurators(rival, profile.curators);
    });
  };

  refreshRivalCurators = (rival, curators) => {
    this.setState(prevState => {
      const rivalsState = {};
      const {rivals} = prevState;

      rival.curators = curators;

      // update curators for specified rival
      if(!_.isEmpty(rivals)) {
        rivalsState[rival.id] = {$set: rival};

        return ReactUpdate(prevState.rivals, rivalsState);
      }
    }, console.log('CompanySettingsBoardCurators.refreshRivalCurators: updated rivals: %o', this.state.rivals));
  };

  toggleRivalProfile = rivalId => {
    const toggledContainers = this.state.toggledContainers.slice();
    const containerIndex = toggledContainers.indexOf(rivalId);

    if(containerIndex >= 0) {
      toggledContainers.splice(containerIndex, 1);
    }
    else {
      toggledContainers.push(rivalId);
    }

    this.setState({toggledContainers}, () => {
      console.log('CompanySettingsBoardCurators.toggleRivalProfile %o for rivalId #%o', (containerIndex >= 0 ? 'closed' : 'open'), rivalId);
    });
  };

  renderCuratorRow = (rival, user, isSelected) => {
    const curatorClasses = classNames({
      'profile-curators_row': true,
      'profile-curators_row--selected': isSelected
    });
    const userClasses = classNames({
      fa: true,
      'fa-check-circle': isSelected,
      'fa-circle-o': !isSelected
    });

    return (
      <tr key={`curator_${user.id}`} className={curatorClasses}>
        <td onClick={() => this.toggleProfileCurator(rival, user)}>
          <span>
            <i className={userClasses} style={{width: '1em'}} /> {user.name}
          </span> <small className="u-text-muted">({user.email})</small>
        </td>
      </tr>
    );
  };

  renderProfileCurators = rival => {
    const allCurators = (this.state.allCurators || []).slice();
    const {curators} = rival;

    if(_.isEmpty(rival) || _.isEmpty(allCurators)) {
      return;
    }

    const allCuratorsSelected = !curators.length;
    const uiProfileCurators = allCurators.map(user => {
      const isSelected = curators.includes(user.id);

      return this.renderCuratorRow(rival, user, isSelected);
    });

    return (
      <div className="max-height-scroll-container max-height-scroll-container--m u-mb-m">
        <table className="profile-curators table table-bordered table-grey-background" style={{marginBottom: 0}}>
          <tbody>
            <tr>
              <td style={{cursor: 'pointer'}} onClick={() => this.clearProfileCurators(rival)}>
                <i className={'fa fa-' + (allCuratorsSelected ? 'check-circle' : 'circle-o')} style={{width: '1em', color: '#44bc8f'}} />
                {' '}All <strong>admins</strong> will receive notifications from this profile
              </td>
            </tr>
            {uiProfileCurators}
          </tbody>
        </table>
      </div>
    );
  };

  renderRivalProfiles = (rivals = []) => {
    if(_.isEmpty(rivals)) {
      return;
    }

    const allCurators = (this.state.allCurators || []);

    return rivals.map(rival => {
      const {curators} = rival;

      // NOTE: there is the potential here for curator ids to exist on rivals that have been demoted/deactivated, so check if valid first
      const curatorsCount = curators.filter(c => Boolean(allCurators.find(u => u.id === c))).length;
      const isToggled = (this.state.toggledContainers || []).slice().includes(rival.id);
      const curatorsLabelText = `(${curatorsCount ? `${curatorsCount} ${pluralize('curator', curatorsCount)}` : 'all admins'})`;
      const curatorsLabelClass = classNames({
        'text-muted': !curatorsCount
      });
      let curatorsList;

      if(isToggled) {
        curatorsList = this.renderProfileCurators(rival);
      }

      return (
        <div id={rival.slug} ref={`r_${rival.slug}`} key={`rival_${rival.id}`} className="settings-profiles_profile">
          <div className="u-mb-s" style={{cursor: 'pointer'}} onClick={() => this.toggleRivalProfile(rival.id)}>
            <span>
              <i className={`fa fa-caret-${isToggled ? 'down' : 'right'}`} style={{width: '0.75em', textAlign: 'center'}} /> <strong>{rival.name}</strong>
            </span> <span className={curatorsLabelClass}>{curatorsLabelText}
            </span>
          </div>
          {curatorsList}
          <hr />
        </div>
      );
    });
  };

  render() {
    const {user, company} = this.context.utils;
    const rivals = (this.state.rivals || []).slice();
    let adminMessage;

    if(userIsAdmin({user})) {
      adminMessage = (
        <div className="help-block u-mb-l">
          <strong>{company.name} Administrators</strong> will receive email notifications
          about comments on Boards that have no assigned curators.
        </div>
      );
    }

    return (
      <div className="company-settings company-settings-profiles">
        <h3 className="u-m0">Curator Board Assignments</h3>
        <div className={'help-block ' + (adminMessage ? 'u-mb-m' : 'u-mb-l')}>
          Curators assigned to specific Boards will receive both email and <Link to="/notifications"><strong>Dashboard notifications</strong></Link> of
          new Board and Battlecards comments.<br />
        </div>
        {adminMessage}
        {this.renderRivalProfiles(rivals)}
        <div className="help-block u-pt-s">
          <em><strong>Note:</strong> Any new boards will default to notify all curators</em>
        </div>
      </div>
    );
  }

}

export default CompanySettingsBoardCurators;
