import Tokenizer, {T_IDENTIFIER, T_SYMBOL, T_STRING, T_NUMBER} from './tokenizer';
import ASTNode from './ast_node';

export const BLOCK = 'BLOCK';
export const FUNC_CALL = 'FUNC_CALL';
export const FUNC_NAME = 'FUNC_NAME';
export const PARAM_LIST = 'PARAM_LIST';
export const PARAM = 'PARAM';

function parseParam(tokens) {
  const funcCall = parseFuncCall(tokens);     // eslint-disable-line no-use-before-define

  if(funcCall !== null) {
    return new ASTNode(PARAM, [funcCall]);
  }

  if(tokens[0].type === T_STRING) {
    return new ASTNode(PARAM, [tokens[0]]);
  }

  if(tokens[0].type === T_NUMBER) {
    return new ASTNode(PARAM, [tokens[0]]);
  }

  return null;
}

function parseParamList(tokens) {
  const param = parseParam(tokens);

  if(param === null) {
    return null;
  }

  let idx = param.len();

  if(tokens[idx].type !== T_SYMBOL || tokens[idx].value !== ',') {
    return new ASTNode(PARAM_LIST, [param]);
  }

  const sep = tokens[idx];
  const children = [param, sep];

  idx++;

  const tail = parseParamList(tokens.slice(idx));

  if(tail !== null) {
    children.push(...tail.children);
  }

  return new ASTNode(PARAM_LIST, children);
}

function parseFuncCall(tokens) {
  const children = [];

  if(!(tokens || []).length || (tokens.length === 1)) {
    return null;
  }

  if(tokens[0].type !== T_IDENTIFIER) {
    return null;
  }

  const funcName = new ASTNode(FUNC_NAME, [tokens[0]]);

  children.push(funcName);

  if(tokens[1].type !== T_SYMBOL || tokens[1].value !== '(') {
    return null;
  }

  children.push(tokens[1]);

  let idx = 2;
  const params = parseParamList(tokens.slice(2));

  if(params !== null) {
    children.push(params);
    idx += params.len();
  }

  if(idx >= tokens.length) {
    return null;
  }

  if(tokens[idx].type !== T_SYMBOL || tokens[idx].value !== ')') {
    return null;
  }

  children.push(tokens[idx]);

  return new ASTNode(FUNC_CALL, children);
}

function parseBlock(tokens) {
  const funcCall = parseFuncCall(tokens);

  if(funcCall === null) {
    return null;
  }

  return new ASTNode(BLOCK, [funcCall]);
}

class Parser {

  static parse(code) {
    const tokenizer = new Tokenizer(code);
    const tokens = tokenizer.tokenize();

    const block = parseBlock(tokens);

    if(block === null) {
      throw new Error('Block invalid.', code);
    }

    return block;
  }

}

export default Parser;
