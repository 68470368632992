// rich text card display
import CardStaticHtml from './card_templates/_card_static_html';

// NOTE: using PureComponent here to prevent (many) unnecessary card re-renders in battlecard view
class CardDisplay extends React.PureComponent {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    card: PropTypes.object.isRequired,
    rival: PropTypes.object.isRequired,
    previewMode: PropTypes.bool,
    diffIdentifier: PropTypes.string,
    showTitle: PropTypes.bool
  };

  static defaultProps = {
    card: {},
    rival: {},
    previewMode: false,
    diffIdentifier: '',
    showTitle: false
  };

  componentDidMount() {
    console.log('CardDisplay.componentDidMount: props: %o', this.props);
  }

  render() {
    const {card, rival, previewMode, diffIdentifier} = this.props;
    const {id: cardId, templateName, data, board, createdAt, isDraft} = card || {};

    // friendly fallback
    if(_.isEmpty(card) || !templateName || _.isEmpty(data)) {
      return (
        <div className="search-result-card-content_empty">Sorry, this card can&apos;t be displayed. 😧</div>
      );
    }

    return (
      <CardStaticHtml {...data}
        id={cardId}
        createdAt={createdAt}
        isDraft={isDraft}
        rival={rival}
        board={board}
        previewMode={previewMode}
        diffIdentifier={diffIdentifier} />
    );
  }

}

export default CardDisplay;
