export const userIsAdmin = ({user, roles}) => {
  roles = roles || (user ? user.roles : []);

  return roles.indexOf('admin') >= 0;
};

export const userIsKluebot = ({userId, company}) => {
  if(!userId || !company) {
    return false;
  }

  return userId === company.kluebotId;
};

export const userCanCurate = ({user, roles}) => {
  const rolesToTest = roles || (user ? user.roles : []);

  return /(admin|curator)/.test(rolesToTest);
};

export const userCanDeleteComment = ({comment, loggedInUser, company}) => {
  if(!comment || !loggedInUser || !company) {
    return false;
  }

  // Deleting own item or is a curator
  const canDelete = comment.userId === loggedInUser.id ||
    comment.viaUserId === loggedInUser.id ||
    userCanCurate({user: loggedInUser});

  return canDelete;
};

export function userHasCuratedProfiles({user = {}}) {
  if(_.isEmpty(user)) {
    return false;
  }

  return userCanCurate({user}) && (user.curatingProfiles && user.curatingProfiles.length);
}
