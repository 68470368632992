import {isMSIE} from '../modules/browser_utils';

class CopyButton extends React.Component {

  static contextTypes = {
    // NB: this can't be required since this component is used in standalone pages
    utils: PropTypes.object
  };

  static propTypes = {
    label: PropTypes.string,
    labelClicked: PropTypes.string,
    buttonClass: PropTypes.string,
    iconClass: PropTypes.string,
    labelClass: PropTypes.string,
    tooltip: PropTypes.string,
    dataAction: PropTypes.string,
    targetUrl: PropTypes.string,
    onCopyText: PropTypes.func,
    callback: PropTypes.func
  };

  static defaultProps = {
    label: '',
    labelClicked: '',
    buttonClass: 'copy-link',
    iconClass: 'fa fa-link',
    labelClass: '',
    tooltip: '',
    dataAction: '',
    targetUrl: '',
    onCopyText: null,
    callback: null
  };

  state = {
    displayLabel: this.props.label     // for initial population only
  };

  _selectElementText = element => {
    let range;

    if(document.selection) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    }
    else if(window.getSelection) {
      range = document.createRange();
      range.selectNode(element);

      const selection = window.getSelection();

      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  copyTextToClipboard = () => {
    const copyNode = document.createElement('div');

    copyNode.className = 'clipboard-copy';
    copyNode.textContent = this.props.onCopyText ? this.props.onCopyText() : this.props.targetUrl;
    document.body.appendChild(copyNode);

    if(isMSIE() && isMSIE() <= 11) {
      if(window.clipboardData) {
        window.clipboardData.setData('Text', copyNode.innerText);
      }

      return;
    }

    this._selectElementText(copyNode);

    try {
      console.log('CopyButton.handleCopyClick: copying text to clipboard: %o', copyNode.innerText);

      const copied = document.execCommand('copy');

      if(!copied) {
        const msg = 'Sorry, your browser doesn\'t support this function.';

        if(this.context.utils && this.context.utils.dialog) {
          this.context.utils.dialog.alert(msg);
        }
        else {
          alert(msg);     // eslint-disable-line no-alert
        }
      }
    }
    catch(e) {
      // avoid error nastiness in Firefox (unsupported)
      console.error('CopyButton.handleCopyClick: error: unable to copy text to clipboard: %o', e);
    }

    copyNode.remove();
  };

  handleCopyClick = event => {
    if(event) {
      if(event.shiftKey || event.altKey || event.metaKey) {
        // allow user to shift, alt/option, or cmd/ctrl+click to open link & bypass copy handler
        return;
      }

      event.preventDefault();
    }

    this.copyTextToClipboard();

    if(this.props.label && this.props.labelClicked) {
      // TODO: after we replace the bootstrap dropdown, keep popup open during "copied" text delay below
      this.setState({
        displayLabel: this.props.labelClicked
      }, () => {
        setTimeout(() => {
          this.setState({
            displayLabel: this.props.label
          });
        }, 1000);
        typeof this.props.callback === 'function' && this.props.callback();
      });
    }
  };

  render() {
    const {iconClass, labelClass, targetUrl, tooltip, label, buttonClass, dataAction} = this.props;
    const {displayLabel} = this.state;
    let copyIcon;
    let copyLabel;

    if(iconClass) {
      copyIcon = (
        <i className={iconClass} />
      );
    }

    if(labelClass || displayLabel) {
      copyLabel = (
        <span className={labelClass}>{displayLabel}</span>
      );
    }

    return (
      <a
        href={targetUrl || '#'}
        data-title={tooltip || label}
        className={buttonClass}
        data-action={dataAction}
        onClick={this.handleCopyClick}>
        {copyIcon}
        {copyLabel}
      </a>
    );
  }

}

export default CopyButton;
