import PropTypes from 'prop-types';
import React, {Component} from 'react';

export default class SearchCheckbox extends Component {

  static propTypes = {
    value: PropTypes.object.isRequired,
    dataTrackingId: PropTypes.string,
    handleCheckboxChange: PropTypes.func.isRequired,
    iconOn: PropTypes.string,
    iconOff: PropTypes.string,
    iconColorOn: PropTypes.string,
    iconColorOff: PropTypes.string,
    labelColorOn: PropTypes.string,
    labelColorOff: PropTypes.string,
    backgroundOn: PropTypes.string,
    backgroundOff: PropTypes.string,
    switchMode: PropTypes.string,
    count: PropTypes.number,
    checked: PropTypes.bool,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    value: '',
    dataTrackingId: null,
    handleCheckboxChange() {},
    iconOn: '',
    iconOff: 'none',
    iconColorOn: 'none',
    iconColorOff: 'none',
    labelColorOn: 'none',
    labelColorOff: 'none',
    backgroundOn: 'none',
    backgroundOff: 'none',
    switchMode: 'switch',
    count: null,
    checked: false,
    disabled: false
  };

  state = {
    isChecked: this.props.checked,
    iconOn: this.props.iconOn,
    iconOff: this.props.iconOff,
    iconColorOn: this.props.iconColorOn,
    iconColorOff: this.props.iconColorOff,
    labelColorOn: this.props.labelColorOn,
    labelColorOff: this.props.labelColorOff,
    backgroundOn: this.props.backgroundOn,
    backgroundOff: this.props.backgroundOff,
    switchMode: this.props.switchMode,  // can be 'switch' or 'set'
    count: this.props.count
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isChecked, count} = this.state;
    const {checked: nextChecked, count: nextCount} = nextProps;

    if(isChecked !== nextChecked) {
      this.setState({isChecked: nextChecked});
    }

    if(count !== nextCount) {
      this.setState({count: nextCount});
    }
  }

  toggleCheckboxChange = () => {
    const {handleCheckboxChange, value, disabled} = this.props;

    if(disabled) {
      return;
    }

    if(this.state.switchMode === 'switch') {
      this.setState(({isChecked}) => ({isChecked: !isChecked}));
      handleCheckboxChange(value.value, value.label);
    }
    else if(this.state.switchMode === 'set') {
      if(!this.state.isChecked) {
        this.setState({isChecked: true});
        handleCheckboxChange(value.value, value.label);
      }
    }
  };

  renderCheckbox = () => {
    const {value, dataTrackingId} = this.props;
    const {
      count, isChecked, backgroundOn, backgroundOff, iconOn, iconOff, iconColorOn, iconColorOff, labelColorOn, labelColorOff
    } = this.state;
    const background = isChecked ? backgroundOn : backgroundOff;
    const iconStyle = isChecked ? {color: iconColorOn} : {color: iconColorOff};
    const labelStyle = isChecked ? {color: labelColorOn} : {color: labelColorOff};

    return (
      <div className="search-checkbox">
        <button
          className="search-checkbox-filter"
          data-tracking-id={dataTrackingId}
          style={{background}}
          onClick={this.toggleCheckboxChange}>
          <i
            className={`search-checkbox-icon ${isChecked ? iconOn : iconOff}`}
            style={iconStyle}
            aria-hidden="true" />
          <span
            className="search-checkbox-label"
            style={labelStyle}>
            {value.label}
          </span>
          {isChecked &&
            <span
              className="search-checkbox-count"
              style={labelStyle}>
              {(count !== null) && count.toLocaleString()}
            </span>
          }
        </button>
      </div>
    );
  };

  render() {
    return this.renderCheckbox();
  }

}
