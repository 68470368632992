import classNames from 'classnames';

const DigestVisibilityGroups = ({visibilityGroups, selectedGroups, digestType, onGroupsChanged}) => {
  return (
    <div className="digest-visibility-group_checkbox-container" data-testid="digest-visibility-groups">
      {visibilityGroups.map(({id, name}) => {
        const isSelected = selectedGroups.includes(id);
        const classes = classNames({
          fa: true,
          'fa-check-square': isSelected,
          'fa-square-o': !isSelected
        });

        const key = id || 'full-access-user-key';

        return (
          <div className="digest-checkbox" key={key} title={name}>
            <i className={classes} style={{marginRight: '10px'}} onClick={() => onGroupsChanged([id], !isSelected, digestType)} />
            <label htmlFor={key} onClick={() => onGroupsChanged([id], !isSelected, digestType)}>{name}</label>
          </div>
        );
      })}
    </div>
  );
};

DigestVisibilityGroups.propTypes = {
  visibilityGroups: PropTypes.arrayOf(PropTypes.object),
  selectedGroups: PropTypes.arrayOf(PropTypes.number),
  digestType: PropTypes.object,
  onGroupsChanged: PropTypes.func
};

DigestVisibilityGroups.defaultProps = {
  visibilityGroups: [],
  selectedGroups: [],
  digestType: {},
  onGroupsChanged() {}
};

export default DigestVisibilityGroups;
