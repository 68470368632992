import {fetch} from '../api_utils';

export const jobStatus = (jobStatusOptions = {statusId: ''}, code = 'ApiUtils.jobStatus') => {
  // options format:
  //   jobStatusOptions: {
  //     statusId: string,
  //   }
  const {statusId} = jobStatusOptions;

  return new Promise((resolve, reject) => {
    if(typeof statusId !== 'string' || !statusId.length) {
      console.warn(`${code}: invalid options specified: jobStatusOptions: %o`, jobStatusOptions);

      return reject(jobStatusOptions);
    }

    fetch(`/api/job_statuses/${statusId}.json`, {code})
      .then(({data: status}) => {
        if(_.isEmpty(status)) {
          console.error(`${code}: invalid status found for statusId ${statusId}`);

          return reject(null);
        }

        console.log(`${code}: status ${statusId} found: ${status}`);

        resolve(status);
      })
      .catch(error => {
        console.error(`${code}: no status found for statusId: ${statusId}`);

        reject(error);
      });
  });
};

