import {spring, Motion, StaggeredMotion, presets} from 'react-motion';
import cx from 'classnames';
const smooth = presets.noWobble;
const stiff  = presets.stiff;

import onboardingData from '../_onboarding_data';
const newsContent = onboardingData.news;

class RealNewsFeature extends React.Component {

  render() {
    return (
      <div className="layout-news-grid--feature">
        <div className="news-story--feature" style={{backgroundImage: 'url(' + this.props.els[0].img + ')'}}>
          <div>
            <h2 className="news-story--feature_text--title u-m0">Tech Leaders Weigh in On {this.props.company} Using Klue</h2>
            <p>It&apos;s shaping up to be a hot year for competitive intelligence.</p>
            <p className="news-story_read-more"><a href="#">Read Story</a></p>
          </div>
        </div>
      </div>
    );
  }

}

class RealNewsHorizontalBig extends React.Component {

  render() {
    const els = this.props.els;

    return (
      <div className="layout-news-grid">
        <div className="layout-news-grid_item layout-news-grid_item--big">
          <RealNewsFeature els={els.main} company={this.props.company} />
          <RealNewsVertical els={els.vertical} />
        </div>
        {els.big.map((el, i) => {
          return (
            <div key={i} className="layout-news-grid_item layout-news-grid_item--big">
              <RealNewsHeader text={el.headerText} img={el.headerImg} />
              <RealNewsBody text={el.bodyText} />
            </div>
          );
        })}
      </div>
    );
  }

}

class RealNewsVertical extends React.Component {

  render() {
    const els = this.props.els;

    return (
      <div className="layout-news-grid layout-news-grid--vertical layout-news-grid--hidden-sm">
        {els.map(function(el, i) {
          return (
            <div key={i} className="layout-news-grid_item layout-news-grid_item--vertical">
              <RealNewsStoryVertical title={el.title} img={el.img} author={el.author} />
            </div>
          );
        })}
      </div>
    );
  }

}

class RealNewsHorizontal extends React.Component {

  render() {
    return (
      <div className="layout-news-grid layout-news-grid--small">
        {this.props.els.map(function(el, i) {
          return (
            <div key={i} className="layout-news-grid_item">
              <RealNewsHeader text={el.headerText} img={el.headerImg} author={el.author} />
              <RealNewsBody text={el.bodyText} />
            </div>
          );
        })}
      </div>
    );
  }

}

class RealNewsHeader extends React.Component {

  render() {
    return (
      <div className="news-story_header">
        <img className="news-story_img" src={this.props.img} />
        <div>
          <h2 className="news-story_title u-m0">{this.props.text}</h2>
          <div className="news-story_author">{this.props.author}</div>
        </div>
      </div>
    );
  }

}

class RealNewsBody extends React.Component {

  render() {
    return (
      <div>
        <p>{this.props.text}</p>
        <p className="layout-news-story_read-more">
          <span className="news-story_read-more">
            <a href="#">Read Story</a>
          </span>
        </p>
      </div>
    );
  }

}

class RealNewsStoryVertical extends React.Component {

  render() {
    return (
      <div className="news-story--vertical">
        <img className="news-story--vertical_img" src={this.props.img} />
        <div>
          <div>
            <h2 className="u-m0 news-story--vertical_title">{this.props.title}</h2>
          </div>
          <a href="" className="news-story_author">{this.props.author}</a>
        </div>
      </div>
    );
  }

}

class KlueCrunchLogo extends React.Component {

  render() {
    return (
      <div className="layout-news-kc-logo">
        <img src="/onboarding/kc-logo.png" alt="Klue Crunch" />
        <input type="text" placeholder="Search Klue Crunch" />
      </div>
    );
  }

}

const news = {
  horizReal: RealNewsHorizontal,
  horizRealBig: RealNewsHorizontalBig,
  logo: KlueCrunchLogo
};

export default news;
