// NOTE: browser UA/vendor tests below are pretty fragile
// TODO: these will need to be kept up-to-date over time or replaced with an external lib
export const isEdge = () => /edge/i.test(navigator.userAgent);

// NOTE: should represent version of edge built on chromium that supports klue extension
// see: https://stackoverflow.com/a/56361977/226756
export const isChromiumEdge = () => !isEdge() && /edg/i.test(navigator.userAgent) && Boolean(window.chrome);

export const isChrome = () => !isChromiumEdge() && /chrome/i.test(navigator.userAgent) && Boolean(window.chrome);

export const isMSIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');
  const edge = ua.indexOf('Edge/');

  if(msie >= 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  else if(trident >= 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');

    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  else if(edge > 0) {
    // IE 12 => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
};

export const isMobileBrowser = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent);

export const isTestBrowser = () => /jsdom/i.test(navigator.userAgent);
