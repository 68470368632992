import {fetch, post, destroy} from '../api_utils';
import {isValidId} from '../utils';

export const postLikesGet = (postId, code = 'ApiUtils.postLikesGet') => {
  /* options format:
   {postOptions: {
      postId: required
   }} */
  return new Promise((resolve, reject) => {
    if(!isValidId(postId)) {
      reject('Invalid postId.');
    }

    fetch(`/api/posts/${postId}/likes`, {code})
      .then(({data: likes}) => resolve(likes))
      .catch(error => console.error(code, error));
  });
};

export const postGet = (postId, code = 'ApiUtils.postGet') => {
  /* options format:
   {postOptions: {
      postId: required
   }} */
  return new Promise((resolve, reject) => {
    if(!isValidId(postId)) {
      reject('Invalid postId.');
    }

    fetch(`/api/posts/${postId}.json`, {code})
      .then(({data: posts}) => resolve(posts))
      .catch(error => console.error(code, error));
  });
};

export const postsLikesByIds = (postIds = [], code = 'ApiUtils.postsLikesByIds') => {
  if(!postIds.length) {
    return false;
  }

  const postIdsQuery = postIds.reduce((ac, curr) => {
    const postId = encodeURIComponent(curr);

    ac = !ac ? `postIds[]=${postId}` : `${ac}&postIds[]=${postId}`;

    return ac;
  }, '');

  return new Promise((resolve, reject) => {
    fetch(`/api/posts/likes?${postIdsQuery}`, {code})
      .then(({data: likes}) => resolve(likes))
      .catch(error => console.error(code, error));
  });
};

export const postLikesToggle = (postOptions = {}, code = 'ApiUtils.postLikesToggle') => {
  /* options format:
   {postOptions: {
      postId: required,
      action: 'like' (default) |'unlike'
   }} */
  return new Promise((resolve, reject) => {
    const {postId, action = 'like'} = postOptions;

    if(!isValidId(postId)) {
      reject('Invalid postId.');
    }

    post(`/api/posts/${postId}/${action}`, {}, {code})
      .then(({data: likes}) => resolve(likes))
      .catch(error => console.error(code, error));
  });
};

export const postDelete = (postOptions = {}, code = 'ApiUtils.postDelete') => {
  /* options format:
   {postOptions: {
      postId: required,
      extensionMode: bool
   }} */

  return new Promise((resolve, reject) => {
    const {postId, extensionMode = false} = postOptions;

    if(_.isEmpty(postOptions) || !isValidId(postId)) {
      return reject('Invalid postId.');
    }

    destroy(`/api/posts/${postId}?alertAction=delete${extensionMode ? '&ext=1' : ''}`, {code})
      .then(() => resolve())
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

export const postUnpublish = (postOptions = {}, code = 'ApiUtils.postUnpublish') => {
  /* options format:
   {postOptions: {
      postId: required,
      extensionMode: bool
   }} */

  return new Promise((resolve, reject) => {
    const {postId, extensionMode = false} = postOptions;

    if(_.isEmpty(postOptions) || !isValidId(postId)) {
      return reject('Invalid postId.');
    }

    destroy(`/api/posts/${postId}${extensionMode ? '?ext=1' : ''}`, {code})
      .then(() => resolve())
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};
