/* eslint-disable react/no-multi-comp */
export const formulaTypes = Object.freeze({
  DYNAMIC: 'dynamic',
  STATIC: 'static'
});

export const SFDC_OPPORTUNITIES_PER_PAGE = 3;
export const sfdcFormulaTypes = Object.freeze(['sfdcLatestOpportunities', 'sfdcWins']);

export const SFDC_ERROR_MESSAGES = Object.freeze({
  UNFINISHED: () => (<span>Switch to <strong>Edit Mode</strong> to finish setting up this Salesforce data card.</span>),
  NO_INSTANCE: url => (
    <span>
      You must navigate from Salesforce to Klue to view this content.
      {url ? (<span> <a href={url}>Log in</a> and click the <strong>Klue Battlecards</strong> button in any Opportunity.</span>) : ''}
    </span>
  ),
  API: error => (<span>{`${error}`}</span>)
});
