import CommentTextInput from './_comment_text_input';
import AttachmentButton from './_attachment_button';
import AttachmentPreview from './_attachment_preview';

import {
  putCommentToLS,
  deleteCommentFromLS,
  getCommentFromLS
} from '../modules/local_storage_utils';
import {analyticsTrack} from '../modules/analytics_utils';
import {capitalize} from '../modules/text_utils';
import {sanitizeInput} from '../modules/html_utils';
import {isValidId} from '../modules/utils';
import {DOCUMENT_CONTENT_TYPES, IMAGE_CONTENT_TYPES} from '../modules/constants/content_types';
import {normalizeAttachment} from '../modules/attachment_utils';
import {resizeExtension} from '../modules/ext_utils';

import classNames from 'classnames';

class CommentForm extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    containerId: PropTypes.number.isRequired,
    containerType: PropTypes.string,
    commentId: PropTypes.number,
    primeCommentId: PropTypes.number,
    commentTitle: PropTypes.string,
    commentBody: PropTypes.string,
    commentTimestamp: PropTypes.string,
    user: PropTypes.object,
    editMode: PropTypes.bool,
    canTakeScreenshot: PropTypes.bool,
    autoScroll: PropTypes.bool,
    attachments: PropTypes.arrayOf(PropTypes.object),
    suggestion: PropTypes.string,
    canUseForm: PropTypes.bool,
    prime: PropTypes.bool,
    commentsLoaded: PropTypes.bool,
    commentCount: PropTypes.number,
    onCommentSubmit: PropTypes.func,
    onCommentCancel: PropTypes.func,
    onCommentDelete: PropTypes.func,
    onCommentChange: PropTypes.func,
    toggleParentContainer: PropTypes.func,
    isExtensionMode: PropTypes.bool,
    rivals: PropTypes.object
  };

  static defaultProps = {
    containerId: 0,
    containerType: 'post',
    commentId: 0,
    primeCommentId: 0,
    commentTitle: '',
    commentBody: '',
    commentTimestamp: '',
    user: null,
    editMode: false,
    canTakeScreenshot: false,
    autoScroll: false,
    attachments: [],
    suggestion: '',
    canUseForm: true,
    prime: false,
    commentsLoaded: false,
    commentCount: 0,
    onCommentSubmit() {},
    onCommentCancel() {},
    onCommentDelete() {},
    onCommentChange() {},
    toggleParentContainer() {},
    isExtensionMode: false,
    rivals: null
  };

  state = {
    highlight: null,
    commentText: this.props.commentBody, // get comment body from props for initialization only
    attachments: this.props.attachments,
    allowSubmit: true,
    dropping: false
  };

  UNSAFE_componentWillMount() {
    this._highlightUnsaved = false;
    this.possiblyRecoverUnsavedContent();
  }

  componentDidMount() {
    // DEBUG
    console.log('CommentForm.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    // event subscriptions
    klueMediator.subscribe(`klue:highlight:sync:${this.props.containerId}`, highlight => {
      // note: highlight sync only needed for posts
      console.log('CommentForm: klue:highlight:sync %o', highlight);

      // prepend any existing comment text & strip trailing whitespace
      const quote = (this.state.commentText ? `${this.state.commentText.replace(/\s+$/g, '')}\n\n` : '') +
        highlight.quote
          .replace(/[\t ]*?[\r\n][\t ]*/g, '\n')          // normalise newlines and returns and remove surrounding whitespace
          .replace(/([^\s])\n([^\s])/g, '$1 $2')          // convert single new lines to a space
          .replace(/\n+/g, '\n\n')                        // merge and make any remaining new lines at least two newlines
          .replace(/^\s\s*/, '').replace(/\s\s*$/, '');   // trim any surrounding whitespace and newlines

      this._isMounted && this.setState({
        highlight,
        commentText: quote
      }, this.handleResize);
    });

    klueMediator.subscribe('klue:commentForm:setText', text => {
      this._isMounted && this.setState({
        commentText: text
      }, () => {
        this.handleResize();
        console.log('CommentForm: klue:commentForm:setText %o', text);
      });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _syncChannel = action => {
    return 'klue:comment:textarea:' + (this.props.commentId || '0') + (action ? ':' + action : '');
  };

  handleResize = () => {
    if(this.refs.textInput) {
      // on new highlight, focus text input
      this.refs.textInput.focusTextArea();
    }

    resizeExtension();
  };

  handleKeyPress = event => {
    const keyEvent = event || window.event;

    if((keyEvent.which === 13) && !keyEvent.shiftKey) {
      this.handleSubmit(keyEvent);
    }
  };

  handleKeyDown = event => {
    const keyEvent = event || window.event;

    if((keyEvent.which === 27) && (this.props.editMode || this.state.highlight)) {
      if(this.props.editMode) {
        this.handleCommentCancel();
      }
      else if(!_.isEmpty(this.state.highlight)) {
        this.handleHighlightCancel(keyEvent);
      }

      // reset comment text back to original body
      this.setState({
        commentText: this.props.commentBody
      });
    }
  };

  handleBeforeUpload = () => {
    this.setState({
      allowSubmit: false
    });
  };

  handleUpload = file => {
    const {textInput} = this.refs;

    this._highlightUnsaved = false;// update before state changing

    // need to get current text, otherwise resets text area after upload. Code smell here,
    // state of commentText should not be managed like this here
    this.setState({
      attachments: [file],
      commentText: textInput ? textInput.getValue() : '',
      allowSubmit: true
    }, () => {
      this.saveDraftTextAttachments();

      const type = this.props.containerType || 'undefined';

      analyticsTrack({
        type: 'event',
        category: `${capitalize(type)}`,
        action: 'comment: attach file',
        label: `id:${this.props.containerId}, user:${this.props.user.id}`
      });
    });
  };

  handleSubmit = event => {
    if(event) {
      event.preventDefault();
    }

    if(!this.state.allowSubmit) {
      return;
    }

    const {textInput} = this.refs;
    const body = textInput ? textInput.getValue().trim() : '';
    const bodyHtml = (this.props.isExtensionMode || !textInput) ? '' : textInput.getValue().trim();

    if(body.length || (this.state.attachments.length && !_.isEmpty(this.state.attachments[0]))) {
      const comment = {
        title: this.props.commentTitle,
        body,
        bodyHtml,
        createdAt: this.props.commentTimestamp
      };

      if(this.props.commentId) {
        comment.id = this.props.commentId;
      }

      if(this.state.highlight) {
        comment.highlight = this.state.highlight;
      }

      if(!_.isEmpty(this.state.attachments)) {
        comment.attachments = this.state.attachments;
      }

      // Stop any delayed jobs that perform saving this profile's edits in LS
      // TODO: think what happens if the response from server is unsuccessful / no internet
      if(this.timeOutHandle) {
        window.clearTimeout(this.timeOutHandle);
      }

      this.handleCommentSubmit(comment);

      if(this.props.editMode) {
        // toggle out of edit mode
        this.handleCommentCancel();
      }
      else {
        this.setState({
          highlight: null,
          commentText: ''
        });
      }

      // trigger resize back down to default minRows
      klueMediator.publish(this._syncChannel('reset'));
    }
    else if(this.props.commentId && this.props.prime && (this.props.commentId !== this.props.primeCommentId)) {
      this.removeCommentBitsFromStorage();

      // clearing content from subbed-in prime comment, so delete it instead
      this.props.onCommentDelete(this.props.commentId);
    }
    else if(this.props.isExtensionMode) {
      // failed submit, refocus on textarea
      textInput.focusTextArea();

      // clear attachments before next upload
      this.refs.photoAttachmentButton.resetState();

      this.setState({
        attachments: []
      });
    }
  };

  handleHighlightCancel = event => {
    if(event) {
      event.stopPropagation();
    }

    const {highlight = {}} = this.state;

    // remove highlight from embedding page (via ext)
    klueMediator.publish('klue:highlight:delete', highlight.id);

    this.setState({
      highlight: null,
      commentText: ''
    }, this.handleResize);
  };

  handleDropAccepted = files => {
    this.setState({
      dropping: false
    });

    if(!files || !files.length) {
      return;
    }

    this.refs.photoAttachmentButton.uploadFile(files[0]);
  };

  handleDragOver = () => {
    this.setState({
      dropping: true
    });
  };

  handleDragLeave = () => {
    this.setState({
      dropping: false
    });
  };

  handleDropRejected = (files = []) => {
    const file = files[0];

    this.setState({
      dropping: false
    }, () => {
      this.context.utils.dialog.alert('We do not currently support files of this type (' + file.type + ')');
    });
  };

  handleCommentTextChange = event => {
    if(event && event.target) {
      const commentText = event.target.value;

      // update before state changing
      this._highlightUnsaved = false;

      this.setState({commentText}, () => this.saveDraftTextAttachments);
    }
    else {
      this.setState({commentText: event.value}, () => this.saveDraftTextAttachments);
    }
  };

  saveDraftTextAttachments = () => {
    // don't need to keep the signedUrl in LS
    const attach = this.state.attachments[0];
    const {signedUrl, ...reducedAttachmentData} = attach ? attach : {};

    const data = {
      containerType: this.props.containerType,
      id: this.props.commentId,
      parentId: this.props.containerId,
      val1: this.state.commentText,
      val2: reducedAttachmentData
    };

    // debounce
    if(this.timeOutHandle) {
      window.clearTimeout(this.timeOutHandle);
    }

    this.timeOutHandle = window.setTimeout(() => {
      putCommentToLS(data);
    }, 500);
  };

  handleCommentCancel = () => {
    this.removeCommentBitsFromStorage();
    this.props.onCommentCancel();
    this.handleResize();
  };

  handleCommentSubmit = comment => {
    const regex = /@#(\w+)#/g;
    const sanitized = sanitizeInput(comment.body);

    comment.body = sanitized.replace(regex, '@$1'); // convert @#userName# to @userName. See Issue #2951 for details, re: bug in @mentions library.
    this._highlightUnsaved = false;
    this.removeCommentBitsFromStorage();
    this.props.onCommentSubmit(comment);
  };

  removeCommentBitsFromStorage = () => {
    const {commentId: id, containerId: parentId, containerType} = this.props;

    deleteCommentFromLS({containerType, id, parentId});
  };

  possiblyRecoverUnsavedContent = () => {
    const {commentId: id, containerId: parentId, containerType, editMode, toggleParentContainer} = this.props;

    if(isValidId(id) || !this.state.commentText) {
      const {text: commentText, attachments} = getCommentFromLS({containerType, id, parentId}) || {};

      if(commentText || !_.isEmpty(attachments)) {
        this._highlightUnsaved = true;
        this.setState({commentText, attachments});

        // Only toggle edit mode if comment has already existed. For new comments editMode is turned on by design.
        if(id && !editMode) {
          toggleParentContainer();
        }
      }
    }
  };

  removeAttachment = event => {
    if(event) {
      event.preventDefault();
    }

    this._highlightUnsaved = false;// update before state changing

    this.setState({
      attachments: []
    }, () => {
      this.saveDraftTextAttachments();
    });
  };

  renderDropzone = () => {
    if(!this.state.dropping) {
      return;
    }

    return (
      <ReactDropzone
        accept={IMAGE_CONTENT_TYPES.concat(DOCUMENT_CONTENT_TYPES).join(', ')}
        multiple={false}
        disableClick={true}
        disablePreview={true}
        onDragLeave={this.handleDragLeave}
        onDropAccepted={this.handleDropAccepted}
        onDropRejected={this.handleDropRejected}
        className="dropzone">
        <h1>Drop your file to upload!</h1>
      </ReactDropzone>
    );
  };

  renderAttachmentButtons = () => {
    return (
      <AttachmentButton
        ref="photoAttachmentButton"
        onDidUpload={this.handleUpload}
        onWillUpload={this.handleBeforeUpload}
        canTakeScreenshot={this.props.canTakeScreenshot} />
    );
  };

  renderPreview = () => {
    const {attachments = []} = this.state;

    if(!attachments.length) {
      return;
    }

    const attachment = normalizeAttachment(attachments[0]);

    return (
      <AttachmentPreview
        attachment={attachment}
        onRemove={this.removeAttachment} />
    );
  };

  render() {
    const tabIndex = this.props.editMode ? [20, 21] : [10, 11];
    const commentBoxClasses = classNames({
      'comment-form': true,
      'comment-form-with-editor': !this.props.isExtensionMode,
      'comment-isEditing': !this.props.isExtensionMode && this.props.editMode,
      'has-attachment': Boolean(this.state.attachments.length)
    });
    const extraCommentTextInputProps = {};
    let submitLabel;
    let highlightContainer;
    let cancelButton;
    let cancelButtonRegion;

    if(this.props.editMode) {
      submitLabel = 'Save';
      cancelButton = (
        <button type="button" className="btn btn-link btn-cancel" onClick={this.handleCommentCancel} title="Cancel" tabIndex={tabIndex[1]}>
          Cancel
        </button>
      );
    }
    else if(this.state.highlight) {
      submitLabel = 'Save Highlight';
      cancelButton = (
        <button type="button" className="btn btn-link btn-cancel" onClick={this.handleHighlightCancel} title="Cancel highlight" tabIndex={tabIndex[1]}>
          Cancel
        </button>
      );
    }
    else {
      submitLabel = 'Submit';
    }

    if(cancelButton) {
      cancelButtonRegion = (
        <span className="comment-buttons-cancel">
          <label>esc to</label>
          {cancelButton}
        </span>
      );
    }

    if(this.props.autoScroll) {
      extraCommentTextInputProps.handleResize = this.handleResize;
    }

    return (
      <div ref="form" className={commentBoxClasses}>
        <form onKeyPress={this.handleKeyPress} onKeyDown={this.handleKeyDown}>
          {highlightContainer}
          <div className="dropzone-wrapper" onDragOver={this.handleDragOver}>
            <CommentTextInput
              ref="textInput"
              containerId={this.props.containerId}
              commentText={this.state.commentText}
              onChange={this.handleCommentTextChange}
              canUseForm={this.props.canUseForm}
              editMode={this.props.editMode}
              commentCount={this.props.commentCount}
              highlightUnsaved={this._highlightUnsaved}
              containerType={this.props.containerType}
              {...extraCommentTextInputProps}
              rivals={this.props.rivals}
              isExtensionMode={this.props.isExtensionMode} />
            {this.renderDropzone()}
          </div>
          {this.props.isExtensionMode ? <div className="comment-buttons">
            {this.renderAttachmentButtons()}
            {this.renderPreview()}
            {cancelButtonRegion}
            <span className="comment-buttons-submit">
              <label>enter to</label>
              <button type="submit" className="btn btn-link" onClick={this.handleSubmit} tabIndex={tabIndex[0]}>{submitLabel}</button>
            </span>
          </div> : <div className="comment-buttons">
            {cancelButton}
            <button type="submit" className="btn btn-link" disabled={!this.state.commentText} onClick={this.handleSubmit} tabIndex={tabIndex[0]}>{submitLabel}</button>
          </div>}
        </form>
      </div>
    );
  }

}

export default CommentForm;
