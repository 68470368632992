import {useRef, useState, useEffect} from 'react';
import className from 'classnames';

import Spinner from '../_spinner';

export const testIds = {
  editInput: 'editInput',
  editInputButton: 'editInputButton'
};

const EditInput = ({title, value, buttonTitle, onSubmit, onTextDidChange, processing, disableButton}) => {
  const inputRef = useRef();
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  const trimmedEditName = text.trim();
  let buttonDisabled = (processing || trimmedEditName === value || !trimmedEditName.length);

  if(disableButton) {
    buttonDisabled = disableButton(trimmedEditName, buttonDisabled);
  }

  const handleTextDidChange = event => {
    const newValue = event?.target?.value || '';

    setText(newValue);
    onTextDidChange(newValue);
  };

  const handleSubmitText = () => onSubmit(text.trim());

  const handleKeyUp = event => {
    event.preventDefault();
    event.stopPropagation();

    const {key} = event;

    if(key === 'Enter' && !buttonDisabled && inputRef?.current && document.activeElement === inputRef?.current) {
      handleSubmitText(event);
    }
  };

  return (
    <div className="edit-input_container" onKeyUp={handleKeyUp}>
      <span className="edit-input_title">{title}</span>
      <input
        ref={inputRef}
        type="text"
        className="form-control edit-input_input"
        value={text}
        onChange={handleTextDidChange}
        data-testid={`${testIds.editInput}-${buttonTitle}`} />
      <a
        title={buttonTitle}
        className={className('edit-tag-button edit-input_link-button', {'edit-tag-button--disabled': buttonDisabled})}
        onClick={handleSubmitText}
        data-testid={`${testIds.editInputButton}-${buttonTitle}`}>
        {buttonTitle}{processing && <Spinner className="spinner--small" />}
      </a>
    </div>
  );
};

export default EditInput;

EditInput.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  buttonTitle: PropTypes.string,
  processing: PropTypes.bool,
  onSubmit: PropTypes.func,
  onTextDidChange: PropTypes.func,
  disableButton: PropTypes.func
};

EditInput.defaultProps = {
  title: '',
  value: '',
  buttonTitle: '',
  processing: false,
  onSubmit() {},
  onTextDidChange() {},
  disableButton: null
};

