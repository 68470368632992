/* eslint-disable react/no-multi-comp */
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import {Portal} from 'react-portal';

import Icon from './_icon';

// TODO: replace third react-portal lib by react portals API when we upgrade react 16

class Modal extends React.Component {

  static propTypes = {
    children: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
    basic: PropTypes.bool,
    padded: PropTypes.bool,
    showResize: PropTypes.bool,
    extraBodyClass: PropTypes.string,
    extraModalClass: PropTypes.string,
    extraContentClass: PropTypes.string,
    onClose: PropTypes.func,
    hideCloseButton: PropTypes.bool,
    closeOnOutsideClick: PropTypes.bool,
    header: PropTypes.any,
    footer: PropTypes.any,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    isFullScreen: PropTypes.bool
  };

  static defaultProps = {
    onClose() {},
    onCancel() {},
    onConfirm() {},
    basic: false,
    padded: true,
    showResize: false,
    extraBodyClass: '',
    extraModalClass: '',
    extraContentClass: '',
    hideCloseButton: false,
    closeOnOutsideClick: true,
    title: '',
    header: null,
    footer: null,
    isFullScreen: false
  };

  constructor(props) {
    super(props);

    const {extraModalClass} = props;

    this.el = document.createElement('div');
    this.el.setAttribute('class', `klue-ui-modal${extraModalClass ? ` ${extraModalClass}` : ''}`);

    this.backdrop = document.createElement('div');
    this.backdrop.setAttribute('class', 'klue-ui-modal-backdrop');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
    document.body.appendChild(this.backdrop);
    document.body.classList.add('body--noscroll');
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
    document.body.removeChild(this.backdrop);
    document.body.classList.remove('body--noscroll');
  }

  handleClickOutside = () => {
    const {closeOnOutsideClick, onClose} = this.props;

    if(closeOnOutsideClick) {
      onClose();
    }
  };

  render() {
    const {
      children,
      hideCloseButton,
      closeOnOutsideClick,
      header,
      footer,
      title,
      basic,
      padded,
      showResize,
      extraBodyClass,
      extraContentClass,
      onClose,
      onCancel,
      onConfirm,
      isFullScreen
    } = this.props;

    const hasFooter = onCancel || onConfirm || footer;
    const hasHeader = header || title;

    return (
      <Portal node={this.el}>
        <div className={classNames('klue-ui-modal-content', extraContentClass, {padded, 'full-screen': isFullScreen})}>

          {!basic && hasHeader && (
            <div className="klue-ui-modal-header">
              <div className="klue-ui-modal-header-content">
                {header || title}
              </div>
            </div>
          )}

          {!hideCloseButton && (
            <button className="klue-ui-modal-close-button" onClick={onClose} data-testid="close-modal-button">
              <Icon icon="close" width={24} height={24} color="grey" />
            </button>
          )}

          <div className={classNames('klue-ui-modal-body scrollbar-fancy', extraBodyClass, {padded})}>
            {children}
            {showResize && <div className="klue-ui-modal-resize" />}
          </div>

          {!basic && hasFooter && (
            <div className={classNames('klue-ui-modal-footer', {'custom-content': footer})}>
              {footer || (
                <div className="klue-ui-modal-footer-content">
                  <div className="footer-item left-content">
                    {onCancel && <button onClick={onCancel} className="button cancel">Cancel</button>}
                  </div>
                  <div className="footer-item right-content">
                    {onConfirm && <button onClick={onConfirm} className="button confirm">Confirm</button>}
                  </div>
                </div>
              )}
            </div>
          )}

        </div>
      </Portal>
    );
  }

}

export default onClickOutside(Modal);
