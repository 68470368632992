import {useState, useRef, useEffect, useCallback} from 'react';
import ListItemCreate from './_list_item_create';
import Icon from './_icon';
import classNames from 'classnames';

const ListItemsManage = ({
  modalId,
  title,
  createItemTitle,
  createButton,
  placeholder,
  defaultKey,
  listItems,
  infoContent,
  onAdd,
  onUpdateName,
  onDelete,
  onChangedDefault,
  onClosedModal,
  hideCreate,
  onWillEditItem,
  onDidEditItem
}, context) => {
  const [editingItemId, setEditingItemId] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [addNewError, setAddErrorMessage] = useState();
  const [items, setItems] = useState(listItems);
  const inputRef = useRef();

  const getErrorMessage = id => {
    const {id: itemId, message} = (errorMessage || {});

    return id === itemId && message ? message : '';
  };

  const isNameUnique = useCallback((excludingId, name) => {
    const lowercaseName = name.toLowerCase();

    return !items.find(item => item.id !== excludingId && lowercaseName === item.name.toLowerCase());
  }, [items]);

  const clearErrors = () => {
    setErrorMessage();
    setAddErrorMessage();
  };

  const handleListItemCreate = ({name}, callback) => {
    clearErrors();

    if(!isNameUnique(null, name)) {
      return setAddErrorMessage('Please enter a unique name.');
    }

    onAdd(name).then(updatedListItems => {
      setEditingItemId();
      setItems(updatedListItems);

      typeof callback === 'function' && callback(updatedListItems);
    }).catch(error => {
      const {message} = error || {};

      setAddErrorMessage(message || 'The digest creation failed. Please try again.');
    });
  };

  const handleCloseModal = () => {
    const {utils: {dialog}} = context;

    dialog.remove(modalId);

    onClosedModal && onClosedModal();
  };

  const handleSave = useCallback(id => {
    const getItemById = itemId => (items || []).find(i => i.id === itemId);

    clearErrors();

    const updatedName = inputRef?.current?.value?.trim();

    if(!updatedName) {
      return;
    }

    if(updatedName === getItemById(id).name) {
      return setEditingItemId();
    }

    if(!isNameUnique(id, updatedName)) {
      return setErrorMessage({id, message: 'Please enter a unique name.'});
    }

    onUpdateName(id, updatedName).then(updatedListItems => {
      setEditingItemId();
      setItems(updatedListItems);
    }).catch(error => {
      const {message} = error || {};

      setErrorMessage({id, message: message || 'The name update failed. Please try again.'});
    });
  }, [isNameUnique, onUpdateName, items]);

  const handleDelete = id => {
    clearErrors();
    onDelete(id).then(updatedListItems => {
      setEditingItemId();
      setItems(updatedListItems);
    }).catch(error => {
      const {message} = error || {};

      setErrorMessage({id, message: message || 'The delete failed. Please try again.'});
    });
  };

  const handleCancel = useCallback(() => {
    clearErrors();
    setEditingItemId();
  }, []);

  const handleOnEdit = id => {
    clearErrors();
    setEditingItemId(id);
  };

  const handleOnFocus = () => {
    setTimeout(() => inputRef?.current?.select(), 0);
  };

  const handleSetDefault = id => {
    clearErrors();
    onChangedDefault(id).then(updatedListItems => {
      if(updatedListItems) {
        setItems(updatedListItems);
      }
    }).catch(error => {
      const {message} = error || {};

      setErrorMessage({id, message: message || 'Updating the default failed. Please try again.'});
    });
  };

  const handleModalCancel = () => {
    if(!editingItemId) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if(editingItemId) {
      return onWillEditItem();
    }

    onDidEditItem();
  }, [editingItemId, onWillEditItem, onDidEditItem]);

  useEffect(() => {
    setItems(listItems);
  }, [listItems]);

  useEffect(() => {
    const keyCheck = event => {
      if(!editingItemId) {
        return;
      }

      if(event?.key === 'Escape') {
        return handleCancel();
      }

      if(event?.key === 'Enter') {
        return handleSave(editingItemId);
      }
    };

    window.addEventListener('keydown', keyCheck);

    return () => window.removeEventListener('keydown', keyCheck);
  }, [handleCancel, handleSave, editingItemId]);

  return (
    <div className="manage-list-items-modal">
      <h3 className="heading-dialog">{title}</h3>
      <div className="managed-list">
        {items.map(({id, name, [defaultKey]: isDefault}) => {
          const isEditing = editingItemId === id;
          const error = getErrorMessage(id);
          const nameBlock = isEditing
            ? (
              <input
                type="text"
                ref={inputRef}
                placeholder="Enter a digest name..."
                maxLength={255}
                defaultValue={name}
                autoFocus={true}
                onFocus={handleOnFocus} />
            )
            : (
              <h4>
                <span>{name}</span>
              </h4>
            );
          const controls = isEditing
            ? (
              <div className="list-item_edit">
                <button
                  type="button"
                  className="button button--small button--save"
                  onClick={() => handleSave(id)}
                  data-testid={`list-item_edit_save_${id}`}>
                  Save
                </button>
                <button
                  type="button"
                  className={classNames('button button--small button--alert',
                    {'button--inactive': isDefault})}
                  onClick={() => handleDelete(id)}
                  data-testid={`list-item_edit_delete_${id}`}
                  disabled={isDefault}>
                  Delete
                </button>
                <button
                  type="button"
                  className="button button--small button--disabled"
                  onClick={handleCancel}
                  data-testid={`list-item_edit_cancel_${id}`}>
                  Cancel
                </button>
              </div>
            )
            : (
              <div className="list-item_edit">
                {onChangedDefault
                  ? (
                    <div data-testid={`list-items-manage_change-default_${id}`} onClick={() => handleSetDefault(id)}>
                      <Icon
                        key={`user-default-group_${id}`}
                        icon={isDefault ? 'star' : 'star_border'}
                        width="20"
                        height="20"
                        className="visibility-group_icon visibility-group_icon--user-default" />
                    </div>
                  )
                  : null}
                <div data-testid={`list-items-manage_settings_${id}`} onClick={() => handleOnEdit(id)}>
                  <Icon className="visibility-group_icon" icon="settings" width="20" height="20" />
                </div>
              </div>
            );

          return (
            <div data-testid="managed-list-item" key={id}>
              <div className={classNames('managed-list-item', {'managed-list-item--editing': isEditing})}>
                {nameBlock}
                {controls}
              </div>
              {error ? <div className="manage-list-error">{error}</div> : null}
            </div>
          );
        })}
      </div>
      {!hideCreate && <ListItemCreate
        listItemCreate={handleListItemCreate}
        listItems={items}
        manageMode={true}
        infoContent={infoContent}
        title={createItemTitle}
        createButton={createButton}
        placeholder={placeholder}
        onCancel={handleModalCancel} />}
      {addNewError ? <div className="manage-list-error">{addNewError}</div> : null}
      {modalId && <div className="dialog-toolbar">
        <div className="button button--disabled dialog-cancel-button" onClick={handleCloseModal}>Close</div>
      </div>}
    </div>
  );
};

ListItemsManage.contextTypes = {
  utils: PropTypes.object.isRequired
};

ListItemsManage.propTypes = {
  modalId: PropTypes.string,
  title: PropTypes.string,
  createItemTitle: PropTypes.string,
  createButton: PropTypes.string,
  placeholder: PropTypes.string,
  defaultKey: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  infoContent: PropTypes.node,
  onAdd: PropTypes.func,
  onUpdateName: PropTypes.func,
  onDelete: PropTypes.func,
  onChangedDefault: PropTypes.func,
  onClosedModal: PropTypes.func,
  hideCreate: PropTypes.bool,
  onWillEditItem: PropTypes.func,
  onDidEditItem: PropTypes.func
};

ListItemsManage.defaultProps = {
  modalId: null,
  title: 'Manage List Items',
  createItemTitle: 'Create Item',
  createButton: 'Create List Item',
  placeholder: 'New list item name',
  defaultKey: 'default',
  listItems: [],
  infoContent: null,
  onAdd() {},
  onUpdateName() {},
  onDelete() {},
  onChangedDefault: null,
  onClosedModal: null,
  hideCreate: false,
  onWillEditItem() {},
  onDidEditItem() {}
};

export default ListItemsManage;
