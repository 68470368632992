import {useMemo, useEffect, useCallback, useState} from 'react';

import CompanyLogo from './_company_logo';
import Icon from './_icon';
import Dropdown from './_dropdown';

import classNames from 'classnames';

const DigestCompetitors = ({
  id: componentId,
  rivals,
  selectedRivalId,
  defaultRival,
  storyCount,
  className,
  showPrefix,
  allRivals,
  onClickRival,
  onAddBoard,
  onDeleteBoard
}) => {
  const [alignMenuLeft, setAlignMenuLeft] = useState(false);

  const getAddPositioning = useCallback(() => {
    const addIcon = document.getElementById(`add-tag_icon_${componentId}`);
    const parent = addIcon?.parentElement;

    if(!addIcon || !parent) {
      return;
    }

    const parentWidth = parent.offsetWidth;
    const addLeft = addIcon.offsetLeft;

    if(addLeft < parentWidth / 2) {
      setAlignMenuLeft(true);
    }
    else {
      setAlignMenuLeft(false);
    }
  }, [componentId]);

  useEffect(() => {
    getAddPositioning();
  });

  const handleOnAddBoard = useCallback((e, {id}) => {
    const rival = allRivals[id] || {};

    onAddBoard(rival);
  }, [onAddBoard, allRivals]);

  const handleOnDeleteBoard = useCallback((e, board) => {
    e?.stopPropagation();
    e?.preventDefault();
    onDeleteBoard(board);
  }, [onDeleteBoard]);

  const rivalIds = useMemo(() => new Set(rivals.map(({id}) => id)), [rivals]);
  const viewOptions = useMemo(() => (onAddBoard ? (Object.values(allRivals) || []).filter(({id}) => !rivalIds.has(id)).reduce((acc, {id, name}) => {
    return acc.concat({value: id, label: name, onOptionClick: e => handleOnAddBoard(e, {id, name})});
  }, []).sort((a, b) => a.label.localeCompare(b.label)) : null), [allRivals, rivalIds, onAddBoard, handleOnAddBoard]);

  if(_.isEmpty(rivals) && !onAddBoard) {
    return null;
  }

  const rivalIsHighlighted = rival => {
    const {id} = rival;

    return (selectedRivalId === id) || (!selectedRivalId && defaultRival && (defaultRival.id === id));
  };

  const competitorNodes = rivals.map(rival => {
    if(!_.isEmpty(rival)) {
      const {id, name, profile, isDraft} = rival;
      const {isDraft: profileIsDraft} = profile || {};
      const isHighlighted = rivalIsHighlighted(rival);

      return (
        <span
          key={`competitor_${id}`}
          onClick={e => !isHighlighted && onClickRival && onClickRival(e, id)}
          data-testid={`digest-competitor_${id}`}
          className={
            classNames('tag',
              {
                'tag--highlighted': isHighlighted,
                'tag--noHover': Boolean(!onClickRival),
                'tag--draft': Boolean(isDraft || profileIsDraft)
              }
            )
          }>
          <CompanyLogo
            rival={rival}
            className="avatar logo tag-image" />
          <span className="tag-text">{name}</span>
          {onDeleteBoard && (
          <span className="tag-delete" onClick={e => handleOnDeleteBoard(e, rival)}>
            <Icon icon="close" />
          </span>)}
        </span>
      );
    }
  });

  const digestItemClass = classNames('tags', {
    [className]: Boolean(className)
  });

  return (
    <div key="digestTags" className={digestItemClass}>
      {showPrefix &&
        <span className="digest-competitors_text">
          {`${storyCount} stor${storyCount === 1 ? 'y' : 'ies'}${rivals.length ? ' featuring:' : ''}`}
        </span>
      }
      {competitorNodes}
      {onAddBoard && (
      <span
        id={`add-tag_icon_${componentId}`}
        data-testid="digest-item-add-board"
        className="tag--add-board">
        <Dropdown
          containerClass="tag--add-board-dropdown"
          id="viewUsersDropdown"
          label=""
          showFilter={true}
          alignMenuLeft={alignMenuLeft}
          button={<Icon icon="add_circle" width={20} height={20} />}
          condensed={true}
          onOptionClick={handleOnAddBoard}
          options={viewOptions} />
      </span>
      )}
    </div>
  );
};

DigestCompetitors.contextTypes = {
  utils: PropTypes.object.isRequired
};

DigestCompetitors.propTypes = {
  id: PropTypes.number,
  rivals: PropTypes.arrayOf(PropTypes.object),
  selectedRivalId: PropTypes.number,
  defaultRival: PropTypes.object,
  storyCount: PropTypes.number,
  className: PropTypes.string,
  showPrefix: PropTypes.bool,
  allRivals: PropTypes.object,
  onClickRival: PropTypes.func,
  onAddBoard: PropTypes.func,
  onDeleteBoard: PropTypes.func
};

DigestCompetitors.defaultProps = {
  id: null,
  rivals: [],
  selectedRivalId: null,
  defaultRival: null,
  storyCount: 0,
  className: '',
  showPrefix: true,
  allRivals: {},
  onClickRival: null,
  onAddBoard: null,
  onDeleteBoard: null
};

export default DigestCompetitors;
