// See also: app/models/review_source.rb
export const availableSources = Object.freeze({
  Capterra: new Set(['capterra.com', 'capterra.co.uk', 'capterra.ca']),
  'G2 Crowd': new Set(['g2.com', 'g2crowd.com']),
  'Gartner Peer Insights': new Set(['gartner.com']),
  Glassdoor: new Set(['glassdoor.com', 'glassdoor.ca', 'glassdoor.co.uk']),
  Indeed: new Set(['indeed.com', 'indeed.ca', 'indeed.co.uk']),
  PeerSpot: new Set(['peerspot.com']),
  ProductHunt: new Set(['producthunt.com']),
  SoftwareAdvice: new Set(['softwareadvice.com']),
  TrustPilot: new Set(['trustpilot.com', 'uk.trustpilot.com']),
  TrustRadius: new Set(['trustradius.com'])
});

export const environment = Object.freeze({
  DEV: 'development',
  STAGING: 'staging',
  PROD: 'production'
});

export const klueInstanceIds = Object.freeze([4]);

export const isKlueInstance = company => klueInstanceIds.includes(company.id);

export const isProduction = env => env === environment.PROD;
