/* eslint-disable react/no-multi-comp */
import useSFDC from '../../../../../../hooks/useSFDC';

import {ComponentSFDC, SalesforceInfoContent} from '../../../../sfdc_cards/_card_sfdc_content';

const SalesforceBuilder = (props, context) => {
  const {funcName, formula, currentFormula, onChange, rival, displayOnly} = props;
  const {utils} = context || {};
  const {company, rival: currentRival} = utils || {};
  const SFDCComponent = ComponentSFDC[funcName];
  const {El, Options} = SFDCComponent;
  const sfdc = useSFDC({company, formula: currentFormula || formula, funcName});
  const {instance, errorMessage, errorStatus} = sfdc || {};

  const handleOptionChanged = option => {
    onChange(option);
  };

  if(errorStatus) {
    return (<div className="klue-formula-sfdc klue-formula-sfdc--error">{errorMessage}</div>);
  }

  if(!sfdc || !company || !instance) {
    return null;
  }

  return (
    <div className="klue-formula-sfdc">
      {instance && !displayOnly && (
        <SalesforceInfoContent instance={instance} />
      )}

      {!displayOnly && instance && Options && (
      <Options
        rival={currentRival}
        sfdc={sfdc}
        onOptionChanged={handleOptionChanged} />
      )}

      {instance && El && (
      <El
        rival={rival}
        sfdc={sfdc}
        displayOnly={displayOnly}
        {...props} />
      )}

    </div>
  );
};

SalesforceBuilder.contextTypes = {
  utils: PropTypes.object.isRequired
};

SalesforceBuilder.propTypes = {
  funcName: PropTypes.string,
  formula: PropTypes.string,
  currentFormula: PropTypes.string,
  onChange: PropTypes.func,
  rival: PropTypes.object,
  displayOnly: PropTypes.bool
};

SalesforceBuilder.defaultProps = {
  funcName: '',
  formula: '',
  currentFormula: '',
  onChange() {},
  rival: null,
  displayOnly: false
};

export default SalesforceBuilder;

