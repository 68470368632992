export const COMPANY_NAME_PLACEHOLDER = '[company_name]';

export const SURVEY_RESPONSE_API_PATH = '/api/winloss/survey/response';

export const labels = {
  title: 'Buyer Survey',
  respondedSurveyPage: {
    title: 'Thank you!',
    description: 'You have already responded to this survey.'
  },
  successfulSubmitPage: {
    title: 'Thank you!',
    description: 'We have stored your answers. You may close this window.'
  }
};

export const breakpoints = {
  smartphone: {
    min: 0,
    max: 480
  },
  tablet: {
    min: 481,
    max: 880
  },
  desktop: {
    min: 881
  }
};
