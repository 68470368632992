import {Fragment} from 'react';
import className from 'classnames';
import onClickOutside from 'react-onclickoutside';

import Icon from './_icon';
import LabsTag from './_labs_tag';

class InputSelectorDropdown extends React.Component {

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const {groups} = nextProps;
    const {collapsedGroups} = prevState;

    if(collapsedGroups) {
      return ({});
    }

    const collapsedState = {};

    (groups || []).forEach(group => {
      const {collapsed, title} = group; // assuming group titles are unique

      if(collapsed) {
        collapsedState[title] = true;
      }
    });

    return ({collapsedGroups: collapsedState});
  }

  handleEnterKeyPress = event => {
    if(event && (event.key === 'Enter')) {
      return this.handleOnAddNewItem(event);
    }
  };

  handleClickOutside = () => {
    const {onOutsideClick} = this.props;

    onOutsideClick();
  };

  handleGroupClick = group => {
    const {title = ''} = group;
    const {collapsedGroups} = Object.assign({}, this.state);
    const collapsed = Boolean(collapsedGroups[title]);

    collapsedGroups[title] = !collapsed;
    this.setState({collapsedGroups});
  };

  getNewGroupName = () => {
    const {newGroupName} = this.refs || {};

    if(!newGroupName) {
      return null;
    }

    const {value} = newGroupName;

    if(!value || !(typeof value === 'string')) {
      return null;
    }

    return value.trim();
  };

  handleOnAddNewItem = event => {
    if(event) {
      event.preventDefault();
    }

    const newGroupName = this.getNewGroupName();

    if(!newGroupName) {
      return;
    }

    const {onAddNewItem} = this.props;

    this.refs.newGroupName.value = '';
    onAddNewItem(newGroupName);
  };

  handleAddNewItemChange = () => {
    const newItemName = this.getNewGroupName();

    this.setState({newItemName});
  };

  render() {
    const {
      groups,
      selected,
      onItemClick,
      defaultValue,
      showTitleForSingleGroup,
      onAddNewItem,
      addNewPlaceholder
    } = this.props;
    const groupCount = (groups || []).length;
    const {collapsedGroups, newItemName} = this.state;
    let selectedId;

    return (
      <ul>
        {defaultValue && (
          <li
            key="default_item"
            className={className('item', {
              on: !selected.length,
              'off disabled': selected.length
            })}
            onClick={() => onItemClick(undefined)}>
            {defaultValue}
          </li>)}
        {groups.map(group => {
          const {title, items} = group;
          const collapsed = Boolean(collapsedGroups[title]);

          return (
            <Fragment key={title}>
              {(groupCount > 1 || showTitleForSingleGroup) && (
                <li
                  className={className('group', {collapsed, expanded: !collapsed})}
                  data-test-id="group_title"
                  key={`group_${title}`}
                  onClick={() => this.handleGroupClick(group)}>
                  {title}
                  {collapsed && <Icon icon="arrow-down" width={18} height={18} />}
                  {!collapsed && <Icon icon="arrow-up" width={18} height={18} />}
                </li>
              )}
              {!collapsed && items.map(item => {
                const name = typeof item === 'string' ? item : item.name;
                const label = typeof item === 'string' ? item : item.label;
                const id = typeof item === 'string' ? item : item.id;

                if(!selectedId) {
                  selectedId = selected.map(i => {
                    if(typeof i === 'string') {
                      return i;
                    }

                    return i.id;
                  });
                }

                return (
                  <li
                    key={`group_${title}_item_${id}`}
                    className={className('item', {
                      on: selectedId.includes(id),
                      off: !selectedId.includes(id)
                    })}
                    onClick={() => onItemClick(item)}>
                    {name || label}
                    {//pass items with beta arrtibute to make the beta icon visible
                      item?.beta ? <LabsTag className="labs-tag" /> : null
                    }
                  </li>
                );
              })}
            </Fragment>
          );
        })}
        {onAddNewItem && (
          <li
            className="group_add_item"
            key="group_add_item">
            <input
              type="text"
              ref="newGroupName"
              onKeyDown={this.handleEnterKeyPress}
              onChange={this.handleAddNewItemChange}
              placeholder={addNewPlaceholder} />

            <button
              type="button"
              className={className('button button--small', {'button--disabled': !newItemName})}
              disabled={!newItemName}
              onClick={this.handleOnAddNewItem}>Add</button>
          </li>
        )}
      </ul>
    );
  }

}

InputSelectorDropdown.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }), PropTypes.string])).isRequired,
  defaultValue: PropTypes.string,
  onItemClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    collapsed: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }), PropTypes.string]))
  })).isRequired,
  showTitleForSingleGroup: PropTypes.bool,
  onAddNewItem: PropTypes.func,
  addNewPlaceholder: PropTypes.string
};

InputSelectorDropdown.defaultProps = {
  defaultValue: null,
  onItemClick() {},
  onOutsideClick() {},
  showTitleForSingleGroup: false,
  onAddNewItem: null,
  addNewPlaceholder: ''
};

export {InputSelectorDropdown as WrappedInputSelectorDropdown};
export default onClickOutside(InputSelectorDropdown);
