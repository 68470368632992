import moment from 'moment';

import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchCraftData} from './data_providers';

export function facebookLikes(company) {
  if(!KlueTypes.Company.validate(company)) {
    console.log('Unexpected parameter type.', company);
    throw new Error('Parameter was unexpected type.');
  }

  return fetchCraftData(company.info).then(data => {
    const events = data.facebookLikes.map(entry => {
      const mnt = moment(entry.date);
      const date = KlueTypes.Date.create(mnt);

      return KlueTypes.TimeseriesEvent.create({date, value: entry.amount});
    });

    return KlueTypes.Timeseries.create({
      label: data.displayName,
      events: Types.arrayOf(KlueTypes.TimeseriesEvent).create(events)
    });
  });
}
