import {useState, useEffect} from 'react';
import Icon from '../../_icon';
import DisplayCompetitors from './utils/salesforce_display_competitors';
import DisplayNotes from './utils/salesforce_display_notes';
import SFDCQuery from './utils/salesforce_query.js';
import SFDCSvg from './styles/salesforce_svg.js';
import {dig} from '../../../modules/utils.js';
import moment from 'moment';
import styled from 'styled-components';
import {SFDC_OPPORTUNITIES_PER_PAGE} from '../../../modules/constants/formulas';

const AccountName = styled.h2`
  color: #31AAE3;
  font-weight: 'bold';
`;

const CardSFDCLatestOpp = ({rival, sfdc, displayOnly}) => {
  const [displayed, setDisplayed] = useState([]);
  const [paginate, setPaginate] = useState(0);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const {name = ''} = rival || {};
  const {competitorsField, notesField, opportunityValue, notesTitle, options: {selectedOption, defaultOptionName, getTitle} = {}} = sfdc || {};
  const loading = !data || !displayed || !sfdc;

  useEffect(() => {
    if(!selectedOption) {
      return;
    }

    const shapeData = async () => {
      try {
        const oppsData = await SFDCQuery.getSFDCOpps(name, false, selectedOption === defaultOptionName ? '' : selectedOption);

        setData(oppsData);
      }
      catch(error) {
        console.log(`CardSFDCLatestOpp.SFDCQuery.getSFDCOpps error: ${error}`);
        setErrorMessage('An error occurred fetching the Opportunities');
      }
    };

    shapeData();
  }, [name, selectedOption, defaultOptionName]);

  useEffect(() => {
    if(data && data.length > SFDC_OPPORTUNITIES_PER_PAGE) {
      setDisplayed(data.slice(0, SFDC_OPPORTUNITIES_PER_PAGE));

      return setPaginate(SFDC_OPPORTUNITIES_PER_PAGE);
    }

    setDisplayed(data);
  }, [data]);

  const push3More = () => {
    if(_.isEmpty(data) || (data.length < SFDC_OPPORTUNITIES_PER_PAGE)) {
      return;
    }

    const index = paginate + SFDC_OPPORTUNITIES_PER_PAGE;
    const frag = data.slice(paginate, index);
    const _displayed = displayed.concat(frag);

    setDisplayed(_displayed);
    setPaginate(index);
  };

  const renderMoreButton = () => {
    if(!displayOnly || _.isEmpty(data) || (displayed === null) || (displayed.length === data.length)) {
      return;
    }

    return (
      <button
        onClick={push3More}>
        See {paginate ? SFDC_OPPORTUNITIES_PER_PAGE : 0} More
      </button>
    );
  };

  const renderOpportunity = (opp, i, displayedOpps) => {
    const notes = dig(opp, notesField);
    const oppLabel = !opp.IsWon && !opp.IsClosed
      ? (
        <th className="status-label status-label--open">
          <Icon className="status-icon" icon="sentiment_satisfied" />Open
        </th>
      )
      : (
        opp.IsWon
          ? (
            <th className="status-label status-label--won">
              <Icon className="status-icon" icon="sentiment_satisfied" />Won
            </th>
          )
          : (
            <th className="status-label status-label--lost">
              <Icon className="status-icon" icon="sentiment_dissatisfied" />Lost
            </th>
          )
      );
    const oppDate = opp.IsClosed
      ? (
        (<time>{moment(opp.CloseDate).format('LL')}</time>)
      )
      : (
        (<time>{moment(opp.CreatedDate).format('LL')}</time>)
      );
    const dateLabel = opp.IsClosed
      ? (
        'Closed'
      )
      : (
        'Created'
      );

    const dealLabel = opp.IsClosed
      ? (
        'Involved'
      )
      : (
        'Involves'
      );

    const competitors = [];

    competitorsField.forEach((v, ix, src) => {
      if(ix % 2 === 1) {
        return;
      }

      if(src[ix + 1] === 'subquery') {
        const [relation, field] = v.split('.');
        const relatedItems = (dig(opp, relation) || []).map(item => dig(item, field));

        competitors.push(...relatedItems);
      }
      else {
        competitors.push(dig(opp, v));
      }
    });

    // TODO: consider making table a nested component?
    return (
      <div key={i.toString()}>

        {opp.AccountId ?
          <AccountName>
            <a target="_blank" rel="noopener noreferrer" href={`${sfdc.instance}/${opp.AccountId}`}>{opp.Account.Name}</a>
          </AccountName> :
          <p className="sfdc-no-opps">No Account entered for this Opportunity</p>
          }

        <table className="klue-table klue-table--sfdc">
          <tbody>
            <tr>
              {oppLabel}
              <td>{dateLabel} {oppDate}</td>
            </tr>
            <tr>
              <th>Opportunity</th>
              <td><a target="_blank" rel="noopener noreferrer" href={`${sfdc.instance}/${opp.Id}`}>{opp.Name}</a></td>
            </tr>
            <tr>
              <th>Stage</th>
              <td><a target="_blank" rel="noopener noreferrer" href={`${sfdc.instance}/${opp.Id}`}>{opp.StageName}</a></td>
            </tr>
            <tr>
              <th>Sales Rep</th>
              <td><a target="_blank" rel="noopener noreferrer" href={`${sfdc.instance}/${opp.OwnerId}`}>{opp.Owner.Name}</a></td>
            </tr>
            {opportunityValue.fieldName === null ?
              null :
              <tr>
                <th>{opportunityValue.title}</th>
                <td>${(opp[opportunityValue.fieldName || 'Amount'] || 0).toLocaleString()}</td>
              </tr>
            }
          </tbody>
        </table>
        <p className="sfdc-competitors-list"><small>Deal {dealLabel}:</small> <DisplayCompetitors competitors={competitors.filter(Boolean).join(';')} /></p>
        <DisplayNotes notes={notes} title={notesTitle} />
        {(i + 1 < displayedOpps.length) && <hr />}
      </div>
    );
  };

  if(errorMessage) {
    return <div>{errorMessage}</div>;
  }

  if(loading) {
    return <div className="loading">Loading...</div>;
  }

  if(!displayed.length) {
    return (<div>
      <header>
        <SFDCSvg />
        <h4>{getTitle && getTitle()}<br />
          <small>Updates on page reload</small>
        </h4>
      </header>
      {name && (<p className="sfdc-no-opps">No Opportunities found for <strong>{name}</strong>.</p>)}
    </div>);
  }

  return (
    <div className="sfdc-latest-opp">
      <header>
        <SFDCSvg />
        <h4>{getTitle && getTitle()}<br />
          <small>Updates on page reload</small>
        </h4>
      </header>
      {displayOnly ? (<div>
        {displayed.map(renderOpportunity)}
        <hr />
        {renderMoreButton()}
      </div>) : null}
    </div>
  );
};

CardSFDCLatestOpp.propTypes = {
  instance: PropTypes.string.isRequired,
  rival: PropTypes.object,
  sfdc: PropTypes.object,
  displayOnly: PropTypes.bool
};

CardSFDCLatestOpp.defaultProps = {
  instance: '',
  rival: null,
  sfdc: {},
  displayOnly: false
};

export default CardSFDCLatestOpp;
