import BoardCuratorsHeader from './_board_curators_header';
import BoardCuratorsList from './_board_curators_list';

const BoardCurators = ({curators, expanded, onToggleExpanded}) => {
  const suffix = curators.length ? ` (${curators.length})` : '';
  const title = `Board Curators${suffix}`;

  return (
    <div className="board-curators">
      <BoardCuratorsHeader
        title={title}
        expanded={expanded}
        onToggleExpanded={onToggleExpanded} />
      <BoardCuratorsList
        curators={curators}
        expanded={expanded} />
    </div>
  );
};

BoardCurators.propTypes = {
  curators: PropTypes.arrayOf(PropTypes.object),
  expanded: PropTypes.bool,
  onToggleExpanded: PropTypes.func
};

BoardCurators.defaultProps = {
  curators: [],
  expanded: true,
  onToggleExpanded() {}
};

export default BoardCurators;
