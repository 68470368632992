import React from 'react';
import PropTypes from 'prop-types';
import ChartistGraph from 'react-chartist';

const ChartistLineGraph = ({data, options}) => {
  ChartistLineGraph.propTypes = {
    data: PropTypes.shape({
      series: PropTypes.array.isRequired
    }).isRequired,
    options: PropTypes.object
  };

  ChartistLineGraph.defaultProps = {
    data: {series: []},
    options: {}
  };

  return (<ChartistGraph
    className="graph"
    type="Line"
    data={data}
    options={options} />);
};

export default ChartistLineGraph;
