import {wait, isValidId} from './utils';
import {analyticsTrack, SNOWPLOW_SCHEMAS} from './analytics_utils';

import klueHighLight from './klue_highlight';

import {
  CARD_BODY_CLASSNAME,
  FORMULA_REGEX
} from './constants/editor';

export const youTubeUrlRegex = /"?(https?:\/\/)?(www\.)?youtu\.?be(.com)?\/(v\/|u\/\w\/|embed\/|watch\?v=|&v=)?([^#&?]{11})(&[\w\-&=;]+)?/gi;

const urlRegex = /["']?(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?["']?/gi;

// TODO: remove isNewEditorContent flag
export const parseHtmlToMediaEmbeds = (html, isNewEditorContent) => {
  if(!html || (typeof html !== 'string')) {
    return html;
  }

  // convert Youtube URLs to inline video
  return html.replace(youTubeUrlRegex, (...args) => {
    const ytid = args[5];

    // if leads with a quote we'll ignore it as it's probably a href in a link
    if(args[0].slice(0, 1) === '"' || !ytid) {
      return args[0];
    }

    return isNewEditorContent
      ? `<span class="fr-video fr-deletable fr-rv fr-fvc fr-dvb">
          <iframe width="640" height="360"
            src="https://www.youtube-nocookie.com/embed/${ytid}?modestbranding=1&showinfo=0&rel=0&controls=2&color=white&wmode=opaque"
            loading="lazy"
            allowtransparency="true"
            frameborder="0"
            allowfullscreen></iframe>
        </span>`
      : `<div class="card-inline-video-frame">
            <iframe class="card-inline-video-frame_media"
            src="//www.youtube-nocookie.com/embed/${ytid}?modestbranding=1&showinfo=0&rel=0&controls=2&color=white&wmode=opaque"
            loading="lazy"
            allowtransparency="true"
            frameborder="0"
            allowfullscreen></iframe>'
        </div>`;
  });
};

export const parseHtmlToLinks = html => {
  if(!html || (typeof html !== 'string')) {
    return html;
  }

  // convert text URL's to <a/>'s
  return html.replace(urlRegex, url => {
    if(!url.startsWith('"')) {
      return `<a href="${url}" target="_blank" rel="nofollow noopener">${url}</a>`;
    }

    return url;
  });
};

export const getCardHtml = (card = null) => {
  if(_.isEmpty(card)) {
    return '';
  }

  return card.data?.textHtml;
};

export const refreshDynamicContent = async () => {
  // HACK: Chartist component listens for window resize events to adjust graph widths (see: chartist.js:1806)
  await wait(150);

  const event = document.createEvent('Event');

  event.initEvent('resize', true, true);
  window.dispatchEvent(event);

  document.dispatchEvent(new Event('refreshDynamicContent'));
};

/**
 * Highlight mentions from a HTML content
 *
 * @param {string} search Query term
 * @param {string} html
 * @returns {string} html with highlighted tags
 */

export const highlightHTMLWithSearchWords = (search, html) => {
  if(typeof search !== 'string' || typeof html !== 'string') {
    return null;
  }

  const formulas = {};

  return klueHighLight({
    initialValue: html,
    query: search,
    onMatchFound: text =>
      `<mark class="search-term-highlight">${text}</mark>`,
    beforeParse: htmlString =>
      htmlString.replace(FORMULA_REGEX, (m, content, index) => {
        formulas[index] = content;

        return `{{${index}}}`;
      }),
    afterParse: highlighted =>
      highlighted.replace(FORMULA_REGEX, (m, content) => {
        const formulaIndex = parseInt(content, 10);

        return `{{${formulas[formulaIndex]}}}`;
      }),
    shouldHighLightNode: ({ancestors}) =>
      ancestors.every(it => {
        if(it.type === 'root') {return true;}

        return it.hasOwnProperty('tagName') ? it.tagName !== 'mark' : false;
      }),
    shouldHighLightValue: text => !text.includes('<mark')
  });
};

/**
 * Track links clicked on within cards
 *
 * @param {object} target
 * @param cardClass
 * @param opts
 * @param location
 * @returns void
 */
export const trackLinkClickFromTarget = (target, opts = {}, location = '') => {
  const {cardClass = CARD_BODY_CLASSNAME, cardData} = opts;

  if(!target) {
    return;
  }

  const link = target.closest('a') || target.closest('table');
  let category = 'Curator added links';

  if(!link) {
    return;
  }

  let href = link.getAttribute('href');

  const {href: clickedLink = href} = target;

  // find content-zoom reference, if any
  const refEl = link.closest('.contentzoom-img-wrap') || link.closest('.contentzoom-action-zoom-in');
  const originalContentZoomId = refEl ? refEl.getAttribute('data-contentzoom-id') : null;
  const contentZoomId = originalContentZoomId ? originalContentZoomId.replace(/^contentzoom\d+-/i, '') : null;

  if(!href && !contentZoomId) {
    console.warn('CardUtils.trackLinkClickFromTarget: unable to find click target for link', link.outerHTML);

    return;
  }

  if(!href) {
    href = contentZoomId;
    category = 'Content zoom';
  }

  const cardWrapper = link.closest(`.${cardClass}`);

  if(!cardWrapper && !cardData) {
    return;
  }

  let {rivalName, laneName, cardTitle, cardId, boardId, battlecardId, rivalId} = cardData ?? {};

  if(cardWrapper) {
    rivalName = cardWrapper.getAttribute('data-ga-rival');
    rivalId = parseInt(cardWrapper.getAttribute('data-ga-rival-id'), 10);
    laneName = cardWrapper.getAttribute('data-ga-lane');
    cardTitle = cardWrapper.getAttribute('data-ga-card');
    cardId = parseInt(cardWrapper.getAttribute('data-ga-card-id'), 10);
    boardId = parseInt(cardWrapper.getAttribute('data-ga-board-id'), 10);
    battlecardId = parseInt(cardWrapper.getAttribute('data-ga-battlecard-id'), 10);
  }

  const currentPath = location || document.location.pathname;

  const isBattlecardView = currentPath.includes('/battlecard') && !currentPath.includes('/edit');
  const isProfileView = currentPath.startsWith('/profile') && !isBattlecardView;
  const clickViewSource = isProfileView ? 'board' : isBattlecardView ? 'battlecard' : 'search';

  const isContentZoomImg = contentZoomId && target.closest('.contentzoom-img-wrap');
  const isContentZoomTable = contentZoomId && target.closest('table');

  const trackingEventLabel = (isContentZoomImg || isContentZoomTable) ? contentZoomId : clickedLink;
  const trackingEventType = isContentZoomImg ? 'image' : (isContentZoomTable ? 'table' : 'link');

  const trackingDataSP = {
    schema: SNOWPLOW_SCHEMAS.cardInteraction,
    data: {
      label: trackingEventLabel,
      view: clickViewSource,
      type: trackingEventType,
      cardType: 'klue_card'
    },
    context: []
  };

  if(cardId) {
    trackingDataSP.context.push({
      schema: SNOWPLOW_SCHEMAS.card,
      data: {id: cardId}
    });
  }

  if(rivalId) {
    trackingDataSP.context.push({
      schema: SNOWPLOW_SCHEMAS.board,
      data: {id: rivalId}
    });
  }

  if(battlecardId) {
    trackingDataSP.context.push({
      schema: SNOWPLOW_SCHEMAS.battleCard,
      data: {id: battlecardId}
    });
  }

  analyticsTrack({
    type: 'event',
    category,
    action: 'View',
    // TODO: should laneName identify parent container instead: lane, battlecard, search result, single card modal view?
    label: `${rivalName} : ${laneName} : ${cardTitle} : ${href}`
  }, trackingDataSP);

  return;
};

/**
 * Detect valid card links from a click event, and launch modal card view if appropriate.
 *
 * @param {object} target
 * @param event
 * @param history
 * @returns A valid cardId or null
 */
export const maybeLaunchModalCardView = (event, history) => {
  const {target} = event;
  const link = target.closest('a');

  if(!link) {
    return null;
  }

  const targetLinkHref = link.getAttribute('href') || '';
  const cardLinkRegex = /\/card\/(\d+)/i;

  if(targetLinkHref && cardLinkRegex.test(targetLinkHref)) {
    const cardId = Number(targetLinkHref.match(cardLinkRegex)[1]);

    if(isValidId(cardId)) {
      event.stopPropagation();
      event.preventDefault();

      // add a search param to open the single view modal in the current board
      history.push({search: `?viewCard=${cardId}`});

      trackLinkClickFromTarget(target);

      return cardId;
    }
  }

  return null;
};

/**
 * Generate an iframe to embed the card
 *
 * @param {string} rootUrl Base root url of the application
 * @param {number} cardId ID of the card to be embedded
 *
 * @returns {string} Embed iframe code
 */
export const generateCardEmbedCode = (rootUrl, cardId) => {
  return `<iframe width="600" height="500" src="${rootUrl || ''}card/embed/${cardId}"></iframe>`;
};

export const formatLocation = ({city, state, country}) => {
  return country ? `${city}, ${country === 'US' ? state : country}` : city;
};

export const getNewCardViewOrder = ({cards, index}) => {
  const card = cards[index];
  let nextCardViewOrder = null;
  let viewOrder = 0.0;

  if(index < (cards.length - 1)) {
    const nextCard = cards[index + 1];

    if(nextCard) {
      nextCardViewOrder = parseFloat(nextCard.viewOrder);
    }
  }

  if(nextCardViewOrder === null) {
    viewOrder = parseFloat(card.viewOrder) + 1;
  }
  else {
    viewOrder = (parseFloat(card.viewOrder) + nextCardViewOrder) / 2;
  }

  return viewOrder;
};
