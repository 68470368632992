import SynonymList from './_synonym_list';
import EditInput from './_edit_input';

const EditSynonyms = ({synonyms, value, onAdd, onDelete, onSynonymDidChange, addingSynonym, deletingSynonym}) => {
  const haveSomeSynonyms = Boolean(synonyms.length);

  const disableAddButton = editText => !editText.length || synonyms.map(s => s.toLowerCase()).includes(editText.toLowerCase());

  return (
    <>
      {haveSomeSynonyms && <SynonymList synonyms={synonyms} onDelete={onDelete} deletingSynonym={deletingSynonym} />}
      <EditInput
        value={value}
        title={haveSomeSynonyms ? '' : 'Synonyms:'}
        buttonTitle="ADD"
        onSubmit={onAdd}
        onTextDidChange={onSynonymDidChange}
        disableButton={disableAddButton}
        processing={addingSynonym} />
    </>
  );
};

EditSynonyms.propTypes = {
  synonyms: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onDelete: PropTypes.func,
  onSynonymDidChange: PropTypes.func,
  onAdd: PropTypes.func,
  addingSynonym: PropTypes.bool,
  deletingSynonym: PropTypes.bool
};

EditSynonyms.defaultProps = {
  synonyms: [],
  value: '',
  onDelete() {},
  onSynonymDidChange() {},
  onAdd() {},
  addingSynonym: false,
  deletingSynonym: false
};

export default EditSynonyms;
