ReactUpdate = require('immutability-helper');
PropTypes = require('prop-types');

// shared utilities (jquery, lodash, etc.)
$ = jQuery = require('jquery');
flow = require('lodash/flow');
URI = require('urijs');
moment = require('moment');
axios = require('axios');
require('bootstrap-sass');

// redux & react-redux
combineReducers = require('redux').combineReducers;

// react-dnd & related sub-modules
ReactDnD = require('react-dnd');
DragSource = require('react-dnd').DragSource;
DropTarget = require('react-dnd').DropTarget;
ReactDropzone = require('react-dropzone');

// react-dates
DateRangePicker = require('react-dates').DateRangePicker;
isInclusivelyBeforeDay = require('react-dates').isInclusivelyBeforeDay;

// other vendor modules
ReactS3Uploader = require('@kluein/react-s3-uploader');
S3Upload = require('@kluein/react-s3-uploader/s3upload.js');
onClickOutside = require('react-onclickoutside');

// Onboarding
Joyride = require('react-joyride').default;

// Import Modernizer - It's a Lib
require('../../../vendor/assets/javascripts/custom.modernizr.js');

Mediator = require('mediator-js');
klueMediator = new Mediator();
