import DigestTimelineItem from './_digest_timeline_item';

const DigestsSent = ({digests, rivals, user, canEditDigest, onViewSentDigest, onDeleteSentDigest}) => {
  if(!digests || !digests.length) {
    if(canEditDigest()) {
      return null;
    }

    return (
      <div className="digests-sent digests-sent--empty">
        <div className="timeline-header">Competitive Insights, Delivered Right to Your Mailbox</div>
        <p className="timeline-message">
          You have not received any Digests via Klue.
          If you would like to receive competitive insights and market news via the Digest,
          please reach out to your Klue administrator or check your <b>Digest Settings</b> using
          the button above.
        </p>
      </div>
    );
  }

  return (
    <div className="digests-sent">
      <div className="timeline-header">Previous Digests</div>
      {digests.map(digest => {
        const {id} = digest || {};
        const canEdit = canEditDigest(digest);

        return (
          <DigestTimelineItem
            key={`digest-upcoming-${id}`}
            digest={digest}
            rivals={rivals}
            user={user}
            isDraft={false}
            onDigestClick={onViewSentDigest}
            onDeleteSentDigest={canEdit ? onDeleteSentDigest : null} />
        );
      })}
    </div>
  );
};

DigestsSent.propTypes = {
  digests: PropTypes.array.isRequired,
  rivals: PropTypes.object,
  user: PropTypes.object,
  onViewSentDigest: PropTypes.func,
  onDeleteSentDigest: PropTypes.func.isRequired,
  canEditDigest: PropTypes.func.isRequired
};

DigestsSent.defaultProps = {
  rivals: {},
  user: {},
  onViewSentDigest() {}
};

export default DigestsSent;
