import classNames from 'classnames';
import {Link} from 'react-router-dom';

import Spinner from './_spinner';

const BoardCuratorsList = ({expanded, curators}) => {
  return (
    <div className="board-curators_list_container">
      <div className={classNames('board-curators_list', {
        'board-curators_list--expanded': expanded
      })}>
        <ul>
          {curators
            ? (curators || []).map(c => {
              const {id, imageMed, firstName, lastName} = c;
              const displayName = `${firstName} ${lastName}`;

              return (
                <li key={c.id} className="board-curator" data-test-id="board-curator">
                  <Link className="board-curator_link" to={`/users/${id}`}>
                    <img src={imageMed} alt={displayName} loading="lazy" />
                    <p>{displayName}</p>
                  </Link>
                </li>
              );
            })
            : (
              <div className="board-curators_spinner">
                <Spinner />
              </div>
            )}
        </ul>
      </div>
    </div>
  );
};

BoardCuratorsList.propTypes = {
  expanded: PropTypes.bool,
  curators: PropTypes.arrayOf(PropTypes.object)
};

BoardCuratorsList.defaultProps = {
  expanded: false,
  curators: null
};

export default BoardCuratorsList;
