import classNames from 'classnames';

import CompanySettingsDomains from './_company_settings_domains';
import CompanySettingsBoardCurators from './_company_settings_board_curators';
import CompanySettingsDigest from './_company_settings_digest';
import CompanySettingsAlerts from './_company_settings_alerts';
import CompanySettingsTags from './_company_settings_tags';

import {userIsAdmin, userCanCurate} from '../modules/roles_utils';
import {redirectToV2} from '../modules/route_utils';

import {withRouter} from 'react-router-dom';

class CompanySettings extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired
  };

  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    rivalGroups: PropTypes.array
  };

  static defaultProps = {
    match: {},
    history: null,
    rivalGroups: []
  };

  componentDidMount() {
    // DEBUG
    console.log('CompanySettings.componentDidMount: props: %o', this.props);
  }

  getCurrentPageSlug = () => {
    const {match: {params: {pageSlug = ''} = {}}} = this.props;

    return pageSlug.toLowerCase();
  };

  getCurrentPageSection = () => {
    const {match: {params: {pageSection = ''} = {}}} = this.props;

    return pageSection.toLowerCase();
  };

  followNavItem = (slug = '') => {
    const settingsInV2 = ['monitors', 'company-profile'];
    const {appData: {v2Host}} = this.context;

    if(settingsInV2.includes(slug)) {
      return redirectToV2({v2Host, v2Path: `/settings/${slug}`});
    }

    return this.props.history.push(`/settings/${slug}`);
  };

  renderNavItem = slug => {
    const currentPageSlug = this.getCurrentPageSlug();
    const navClass = classNames('settings-nav_link', {
      'settings-nav_link--active': currentPageSlug === slug
    });
    const navLabels = {
      'profile-curators': 'Curator Assignments',
      digest: 'Intel Digests',
      alerts: 'Alerts',
      monitors: 'Monitors',
      tags: 'Card Tags',
      'company-profile': 'Company Profile'
    };
    const handleClick = () => (slug ? this.followNavItem(slug) : '');

    return (
      <div
        key={`settingsNav-${slug}`}
        className={navClass}
        onClick={handleClick}>
        {navLabels[slug]}
      </div>
    );
  };

  render() {
    const {utils} = this.context;
    const {user, isCompanyProfileEnabled} = utils;
    const companyProfileEnabled = isCompanyProfileEnabled();
    const {rivalGroups} = this.props;
    const currentPageSlug = this.getCurrentPageSlug();
    const currentPageSection = this.getCurrentPageSection();
    const isAdmin = userIsAdmin({user});
    const isCurator = userCanCurate({user});
    const isAdminOrCurator = isAdmin || isCurator;
    const uiNavAdmin = (
      <div className="u-pt-l">
        <div className="settings-section-heading u-mb-s">Administration</div>
        <div className="settings-nav">
          {isAdmin ? [this.renderNavItem('profile-curators'), this.renderNavItem('digest')] : null}
          {isAdminOrCurator ? this.renderNavItem('alerts') : null}
          {isAdminOrCurator ? this.renderNavItem('monitors') : null}
          {isAdmin ? this.renderNavItem('tags') : null}
          {isAdminOrCurator && companyProfileEnabled ? this.renderNavItem('company-profile') : null}
        </div>
      </div>
    );
    let uiPage;

    if(!currentPageSlug) {
      uiPage = <CompanySettingsDomains />;
    }
    else if(currentPageSlug === 'alerts') {
      uiPage = <CompanySettingsAlerts />;
    }
    else if(isAdmin && currentPageSlug === 'tags') {
      uiPage = <CompanySettingsTags />;
    }
    else if(isAdmin && currentPageSlug === 'profile-curators') {
      uiPage =
        <CompanySettingsBoardCurators section={currentPageSection} />;
    }
    else if(isAdmin && currentPageSlug === 'digest') {
      uiPage = <CompanySettingsDigest rivalGroups={rivalGroups} />;
    }
    else if(isAdminOrCurator && (currentPageSlug === 'monitors' || currentPageSlug === 'company-profile')) {
      this.followNavItem(currentPageSlug);
    }
    else {
      // requested page slug not found
      this.followNavItem();
    }

    return (
      <div className="layout-settings">
        <div className="layout-settings_sidebar">
          <div className="settings-section-heading u-mb-s">Company Settings</div>
          <div className="settings-nav">
            <div
              className={'settings-nav_link ' + (!currentPageSlug ? 'settings-nav_link--active' : '')}
              onClick={() => this.followNavItem()}>
              Login Domains
            </div>
          </div>
          {uiNavAdmin}
        </div>
        <div className="layout-settings_content">
          <div className="layout-settings-panel">
            <div className="layout-settings-panel_inset">
              {uiPage}
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default withRouter(CompanySettings);
