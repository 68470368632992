import Icon from './_icon';
import {wrapHtml} from '../modules/html_utils';
import React, {useEffect, useRef} from 'react';

const OverlayLoaderBoardReadyButtons = ({title, onClick, buttonStyle, buttonLabel, subTitle, onClose}) => {
  const cancelButtonRef = useRef(null);
  const mainButtonRef = useRef(null);

  useEffect(() => {
    const keyCheck = event => {
      if(event?.key === 'Escape') {
        event?.preventDefault();

        const action = buttonStyle === 'default' ? onClose : onClick;

        action();
      }
      else if(event?.key === 'Tab') {
        event?.preventDefault();

        if(document.activeElement === cancelButtonRef.current) {
          mainButtonRef.current.focus();
        }
        else if(buttonStyle === 'default') {
          cancelButtonRef.current.focus();
        }
        else {mainButtonRef.current.focus();}
      }
    };

    window.addEventListener('keydown', keyCheck);

    return () => window.removeEventListener('keydown', keyCheck);
  }, []);

  return (
    <div className="overlay-loader_button-container">
      <h1 className="overlay-loader_title">
        {buttonStyle === 'default' ? <Icon className="overlay-loader_title--icon" icon="check_circle_outline" width="24" height="24" /> :
        <Icon className="overlay-loader_title--failure-icon" icon="caution" width="24" height="24" />}
        {title}</h1>
      {subTitle && <span className="overlay-loader_subtitle" dangerouslySetInnerHTML={wrapHtml(subTitle)} />}
      <div style={{display: 'flex', marginTop: '24px'}}>
        {buttonStyle === 'default' && <button
          type="button"
          ref={cancelButtonRef}
          tabIndex="0"
          className={'button overlay-loader_box-content_button overlay-loader_box-content_button--plain'}
          onClick={onClose}>
          {'Cancel'}
        </button>}
        <button
          tabIndex="0"
          type="button"
          ref={mainButtonRef}
          className={`button overlay-loader_box-content_button overlay-loader_box-content_button--${buttonStyle}`}
          onClick={onClick}>
          {buttonLabel}
        </button>
      </div>

    </div>
  );
};

OverlayLoaderBoardReadyButtons.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  buttonStyle: PropTypes.string,
  buttonLabel: PropTypes.string,
  subTitle: PropTypes.string,
  onClose: PropTypes.func
};

OverlayLoaderBoardReadyButtons.defaultProps = {
  onClick() {},
  title: '',
  buttonStyle: 'plain',
  buttonLabel: 'view board',
  subTitle: '',
  onClose() {}
};

export default OverlayLoaderBoardReadyButtons;
