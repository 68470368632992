const BoardPageMonitorSettings = ({onTogglePageMonitors}) => {
  return (
    <div className="board-settings-item">
      <div className="title" data-tracking-id="monitors-track-changes-to-web-pages">
        Monitors - Track Changes to Web Pages
      </div>
      <button type="button" className="button configure-button" onClick={onTogglePageMonitors}>
        Configure Monitors
      </button>
    </div>
  );
};

BoardPageMonitorSettings.propTypes = {
  onTogglePageMonitors: PropTypes.func.isRequired
};

export default BoardPageMonitorSettings;
