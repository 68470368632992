import classNames from 'classnames';
import {useAlertSubscriptions} from '../../contexts/_alertSubscriptions';

import CompanyLogo from '../_company_logo';
import AlertRuleComposerEdit from './_alert_rule_composer_edit';

const AlertRuleComposer = () => {
  const {rivals, isHighVolumeAlertDisabled, areAllAlertsQueriesDisabled} = useAlertSubscriptions();

  const sortedCompanies = _.values(rivals).slice().filter(r => !_.isEmpty(r)).sort((r1, r2) => r1.name.localeCompare(r2.name));
  const helpBlockText = 'Set special Alert rules per Board.';

  return (
    <>
      <h4 className="u-pt-s">
        <strong>Alert Rule Composer</strong>
      </h4>
      <p className="help-block">{helpBlockText}</p>
      <div className="rule-composer">
        {sortedCompanies.map(company => {
          const {name: companyName} = company;

          return (
            <div key={`company_${company.id}`} className="rule-composer-item" data-tracking-id="rule-composer-item">
              <span className={classNames('rule-composer-item-rival', {'rule-composer-item-rival_disabled': areAllAlertsQueriesDisabled(company)})}>
                <span className="nav-sidebar-link_image"><CompanyLogo rival={company} /></span>
                <span>{companyName}</span>
              </span>
              <div className="rule-composer-item-status">
                {isHighVolumeAlertDisabled(company)
                  ? <i className="fa fa-exclamation-circle high-alerts-warning-icon rule-composer-warning-icon"
                      data-tip={true}
                      data-for="disabledArlertIcon"
                      title="High Volume Alert Disabled" />
                  : null}
                <AlertRuleComposerEdit rival={company} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AlertRuleComposer;
