/* eslint-disable max-len */
import Dropdown from './_dropdown';
import DropdownMenu from './_dropdown_menu';
import Icon from './_icon';
import VisibilityGroupCreate from './_visibility_group_create';
import VisibilityGroupsManage from './_visibility_groups_manage';
import ManageUsersToolbar from './_manage_users_toolbar';
import ManageUsersBulkEditControls from './_manage_users_bulk_edit_controls';
import ManageUsersPerPageDropdown from './_manage_users_per_page_dropdown';

import {usersGet, userGroupsCountsGet} from '../modules/api/users';
import {userCanCurate, userIsAdmin} from '../modules/roles_utils';
import {renderSuppressionMessage, sortUsers} from '../modules/user_utils.js';
import {pluralize} from '../modules/text_utils';
import {DropdownMenuConstants} from '../modules/constants/dropdown_menu';
import {USERS_PER_PAGE} from '../modules/constants/api';

import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import DigestTypeCheckbox from './_digest_type_checkbox';

class ManageUsers extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object,
    company: PropTypes.object,
    rivals: PropTypes.arrayOf(PropTypes.object),
    rivalGroups: PropTypes.arrayOf(PropTypes.object),
    userInviteUrl: PropTypes.string
  };

  static defaultProps = {
    user: null,
    company: null,
    rivals: [],
    rivalGroups: [],
    userInviteUrl: ''
  };

  state = {
    activeApiKeys: [],
    admins: [],
    applyingBulkEdit: false,
    bulkEditOn: false,
    countsByViewType: {},
    itemsPerPage: USERS_PER_PAGE,
    page: 1,
    selectedAdminId: '',
    sortOrder: 'alpha',
    uiReviewSettingsEnabled: null,
    uiReviewSettingsGroups: null,
    userChecked: {},
    users: null,
    viewGroup: null,
    viewType: 'consumers', // will be overridden in componentWillMount(), 'consumers' is used here just because it is a shared viewType
    visibilityGroups: []
  };

  UNSAFE_componentWillMount() {
    const {user} = this.props;

    this.setState({
      viewType: userCanCurate({user}) ? 'active' : 'users'
    });
  }

  componentDidMount() {
    const {api, utils} = this.context;

    console.log('ManageUsers.componentDidMount: props: %o', this.props);

    this.getUsersCounts();

    if(utils.visibilityGroups) {
      this.setVisibilityGroups();
    }
    else {
      api.visibilityGroupsGet({code: 'ManageUsers.visibilityGroupsGet'}).then(this.setVisibilityGroups);
    }

    this.loadAdminData();
  }

  loadAdminData = () => {
    const {user} = this.props;

    if(userIsAdmin({user})) {
      const {api: {personalAccessTokensGet, usersGet: apiUsersGet}} = this.context;

      personalAccessTokensGet(tokensContent => {
        const active = tokensContent.tokens.filter(key => !key.expired && key.lastUsedAt);

        this.setState({activeApiKeys: active});
      });

      apiUsersGet({typeFilter: 'admins'}, (allAdmins = {}) => {
        const admins = sortUsers({users: Object.values(allAdmins || {})});

        this.setState({admins});
      });
    }
  };

  handleAdminSelect = (user, selectedAdminId) => {
    this.setState({selectedAdminId}, () => {
      this.renderAdminDeactivateModal(user, selectedAdminId);
    });
  };

  handleCancelDeactivateAdmin = () => this.context.utils.dialog.remove('confirm-deactivate-admin-modal');

  getUsersCounts = () => {
    const {query = '', viewGroup = ''} = this.state;

    const search = {query};

    if(viewGroup) {
      Object.assign(search, {groupFilter: viewGroup});
    }

    userGroupsCountsGet({
      code: 'ManageUsers.userGroupsCountsGet',
      search
    }).then(countsByViewType => {
      this.setState({countsByViewType}, this.loadUsers);
    });
  };

  getPagesCount = () => {
    const {countsByViewType: counters, viewType, viewGroup, itemsPerPage} = this.state;
    let count = 0;

    if(counters) {
      count = counters[viewGroup] ? counters[viewGroup] : counters[viewType] ? counters[viewType] : itemsPerPage;
    }
    else {
      count = itemsPerPage;
    }

    return Math.ceil(count / itemsPerPage);
  };

  setVisibilityGroups = () => {
    const visibilityGroups = [...(this.context.utils.visibilityGroups || [])];

    this.setState({
      visibilityGroups
    });
  };

  handleQueryChange = event => {
    const query = event && event.target ? event.target.value : null;

    this.setState({query}, this.getUsersCounts);
  };

  handleQueryCancel = event => (event && event.key === 'Escape') && this.clearQuery();

  clearQuery = () => {
    if(this.state.query) {
      this.setState({
        query: ''
      }, this.getUsersCounts);
    }
  };

  clearQuery = () => this.state.query && this.setState({
    query: ''
  }, this.getUsersCounts);

  handleRoleChange = (user, addRoles, removeRoles, event) => {
    if(event) {
      event.preventDefault();
    }

    console.log('ManageUsers.handleRoleChange: add=%o: remove=%o for user %o', addRoles, removeRoles, user);

    const userOptions = {
      id: user.id
    };

    if(addRoles) {
      userOptions.addRoles = addRoles;
    }

    if(removeRoles) {
      userOptions.removeRoles = removeRoles;
    }

    this.updateUser(user, userOptions);
  };

  updateUser = (user, userOptions) => {
    if(event) {
      event.preventDefault();
    }

    console.log('ManageUsers.updateUser: user: %o, userOptions: %o', user, userOptions);

    this.context.api.userUpdate(userOptions).then(updatedUser => {
      console.log('ManageUsers.updateUser: updated user: %o', updatedUser);

      // Re-render the current page with updated users data.
      // We need to re-fetch the full page, it's not enough just to update the user's object in current users array
      // E.g. in case of deactivation the user needs to leave the Active users list and another user from down the line needs to be fetched.
      this.loadUsers();
    });
  };

  confirmDeactivateAdmin = user => {
    const {api} = this.context;
    const {selectedAdminId} = this.state;

    const userOptions = {
      id: user.id,
      transferAdminId: selectedAdminId
    };

    api.userDelete(userOptions, updatedUser => {
      if(updatedUser) {
        console.log('ManageUsers.handleDeleteUser: %o user: %o', updatedUser.deletedAt ? 'deactivated' : 're-activated', updatedUser);
      }
      else {
        console.error('ManageUsers.handleDeleteUser: userDelete failed for userOptions: %o', userOptions);
      }

      this.getUsersCounts();
      this.loadAdminData();
      this.handleCancelDeactivateAdmin();
    });
  };

  renderAdminDeactivateModal = (user, adminId) => {
    const {utils} = this.context;
    const {activeApiKeys, admins, selectedAdminId} = this.state;
    const filterApiKeys = activeApiKeys.filter(key => key.createdBy === user.id);
    const activeApiList = [];
    const transferAdmins = admins.filter(u => u.id !== user.id).map(u => [u.id, u.name]);

    transferAdmins.unshift(['noone', 'No one (deactivate all keys)']);

    for(let i = 0; i < filterApiKeys.length; i++) {
      activeApiList.push(
        <li key={i}>{filterApiKeys[i].description}</li>
      );
    }

    const uiModalContent = (
      <div>
        <h4 className="heading-dialog">Deactivate admin</h4>
        <p className="help-block u-mb-m">
          {user.name} has <strong>{`${filterApiKeys.length} active API ${pluralize('key', filterApiKeys.length)}`}</strong> that will be revoked.
        </p>
        <ul>{activeApiList}</ul>
        <DropdownMenu
          id={`selectedAdminId${user.id}`}
          values={transferAdmins}
          selectedValue={selectedAdminId || 'noone'}
          label="Transfer API keys to:"
          title="Active Admin Users"
          defaultValue={adminId}
          selectCallback={value => this.handleAdminSelect(user, value)}
          parentContextId={user.id} />
        <div className="row dialog-toolbar">
          <div className="col-sm-5">
            <div className="button button--disabled" onClick={this.handleCancelDeactivateAdmin} style={{width: '100%'}}>Cancel</div>
          </div>
          <div className="col-sm-7">
            <div className="button" onClick={() => this.confirmDeactivateAdmin(user)} style={{width: '100%'}}>OK</div>
          </div>
        </div>
      </div>
    );

    utils.dialog.create({
      id: 'confirm-deactivate-admin-modal',   // calling with same id with just update the contents
      content: uiModalContent
    });
  };

  handleDeleteUser = (user, undo) => {
    const {activeApiKeys, selectedAdminId} = this.state;
    const isAdmin = userIsAdmin({user});
    const filterApiKeys = activeApiKeys.filter(key => key.createdBy === user.id);

    if(isAdmin && !undo && filterApiKeys && filterApiKeys.length > 0) {
      this.renderAdminDeactivateModal(user, selectedAdminId);
    }
    else {
      const isCurator = userCanCurate({user});
      let confirmMessage = `<strong>${undo ? 'Re-' : 'De'}activate this ${isCurator ? 'curator' : 'user'}?</strong><br /><br />
        You can always undo this later.`;

      if(!undo && isCurator) {
        confirmMessage = `${confirmMessage}<br /><br />Deactivated curators will no longer show up when
          <strong><a href="/settings/profile-curators">assigning profile curators</a></strong>.<br /><br />
          Additionally, if a deactivated curator is reactivated, their original roles and profiles will be re-instated.`;
      }

      const confirmAction = () => {
        const userOptions = {
          id: user.id
        };

        if(undo) {
          userOptions.undo = 'y';
        }

        this.context.api.userDelete(userOptions, updatedUser => {
          if(updatedUser) {
            console.log('ManageUsers.handleDeleteUser: %o user: %o', updatedUser.deletedAt ? 'deactivated' : 're-activated', updatedUser);
          }
          else {
            console.error('ManageUsers.handleDeleteUser: userDelete failed for userOptions: %o', userOptions);
          }

          // Re-render the current page with updated users data.
          // We need to re-fetch the full page, it's not enough just to update the user's object in current users array
          // E.g. in case of deactivation the user needs to leave the Active users list and another user from down the line needs to be fetched.
          this.getUsersCounts();
        });
      };

      this.context.utils.dialog.confirm({
        message: confirmMessage,
        okCallback: confirmAction
      });
    }
  };

  handleResendInvitationClick = user => this.context.api.userReconfirm(user);     // NB: same api as reconfirmation

  handleResendConfirmationClick = user => this.context.api.userReconfirm(user);
  handleUnsuppressClick = user => this.context.api.userUnsuppress(user);

  handleViewTypeClick = viewType => this.setState({
    viewType,
    page: 1     // always start with page 1 on view type change
  }, this.loadUsers);

  handleVisibilityGroupClick = viewGroup => {
    const {query} = this.state;

    const search = {groupFilter: viewGroup};

    if(query) {
      Object.assign(search, {query});
    }

    this.setState({viewGroup}, () => {
      userGroupsCountsGet({
        code: 'ManageUsers.userGroupsCountsGet',
        search
      }).then(countsByViewType => {
        this.setState({
          countsByViewType
        }, this.loadUsers);
      });
    });
  };

  handleSortClick = sortOrder => this.setState({
    sortOrder,
    page: 1     // always start with page 1 on sort type change
  }, this.loadUsers);

  handleEmailReviewSettingsClick = user => this.setState({
    uiReviewSettingsEnabled: Boolean(user.emailDigest),
    uiReviewSettingsGroups: this._getEmailReviewFilteredRivalIds(user)
  }, () => {
    this.renderEmailReviewConfigModal(user);
  });

  _getEmailReviewFilteredRivalIds = user => {
    const weeklyReviewData = user.userData ? user.userData.weeklyReviewData : false;

    if(weeklyReviewData && weeklyReviewData.filteredByRivalGroups && !_.isEmpty(weeklyReviewData.filteredByRivalGroups)) {
      return weeklyReviewData.filteredByRivalGroups;
    }

    return false;
  };

  handleCancelEmailReviewSettingsClick = () => this.context.utils.dialog.remove('modal-email-review');

  handleSaveEmailReviewSettingsClick = user => {
    const {utils} = this.context;
    const {uiReviewSettingsEnabled, uiReviewSettingsGroups} = this.state;
    const featureFlag = [['weeklyReviewData', 'filteredByRivalGroups'], uiReviewSettingsGroups];

    this.updateUser(user, {
      id: user.id,
      emailDigest: uiReviewSettingsEnabled,
      featureFlag
    });

    utils.dialog.remove('modal-email-review');
  };

  handleReviewSettingsInput = (stateName, value, isMultiple, user) => {
    const newState = {};
    let val = value;

    if(isMultiple) {
      const options = (this.state[stateName] || []).slice();
      const existingIndex = options.indexOf(value);

      if(existingIndex > -1) {
        options.splice(existingIndex, 1);
      }
      else {
        options.push(value);
      }

      val = options.length ? options : false;
    }

    newState[stateName] = val;

    this.setState(newState, () => {
      this.renderEmailReviewConfigModal(user);
    });
  };

  handleToggleUserDefaultGroup = group => {
    if(_.isEmpty(group)) {
      return;
    }

    const {visibilityGroups: stateVisibilityGroups} = this.state;
    const visibilityGroups = (stateVisibilityGroups || []).map(g => {
      if(g.id === group.id) {
        g.isUserDefault = group.isUserDefault;
      }
      else {
        g.isUserDefault = false;
      }

      return g;
    });

    this.setState({visibilityGroups});
  };

  handleToggleCardDefaultGroup = group => {
    if(_.isEmpty(group)) {
      return;
    }

    const {visibilityGroups: stateVisibilityGroups} = this.state;
    const visibilityGroups = (stateVisibilityGroups || []).map(g => {
      if(g.id === group.id) {
        g.isCardDefault = group.isCardDefault;
      }

      return g;
    });

    this.setState({visibilityGroups});
  };

  onGroupAdded = ({newGroup, userIdRef}) => {
    const visibilityGroups = [...(this.state.visibilityGroups || []), newGroup];

    this.setState({visibilityGroups}, () => {
      if(userIdRef) {
        this._selectDropdownAndUpdateUser({newGroup, userIdRef});
      }
      else {
        this.renderVisibilityGroupsModal();
      }
    });
  };

  onGroupUpdated = updatedGroup => {
    const visibilityGroups = (this.state.visibilityGroups || []).map(g => ((g.id === updatedGroup.id) ? updatedGroup : g));

    this.setState({visibilityGroups}, () => this.renderVisibilityGroupsModal());
  };

  onGroupDeleted = groupId => {
    const visibilityGroups = (this.state.visibilityGroups || []).filter(g => g.id !== groupId);

    this.setState({visibilityGroups}, () => this.renderVisibilityGroupsModal());
  };

  onGroupMoved = (groupPositions = []) => {
    const visibilityGroups = [];

    groupPositions.forEach((groupId, index) => {
      const group = (this.state.visibilityGroups || []).find(g => g.id === groupId);

      visibilityGroups.push({
        ...group,
        viewOrder: index + 1.0
      });
    });

    this.setState({visibilityGroups}, () => {
      visibilityGroups.forEach(g => {
        const {id, viewOrder} = g;

        this.context.api.visibilityGroupUpdate({
          visibilityGroupOptions: {id, viewOrder}
        });
      });
    });
  };

  _selectDropdownAndUpdateUser = ({newGroup, userIdRef}) => {
    this.updateUserVisibilityGroup({groupId: newGroup.id, userId: userIdRef});
  };

  changeVisibilityGroup = (groupId, options) => {
    const {parentContextId: userId} = options;

    if(groupId === DropdownMenuConstants.CREATE_NEW_ITEM_REF) {
      const VisibilityGroupCreateDialogId = 'create_visibility_group_modal';
      const dialogBodyContent = (
        <VisibilityGroupCreate
          modalId={VisibilityGroupCreateDialogId}
          userIdRef={userId}
          onGroupAddedCallback={this.onGroupAdded}
          visibilityGroups={this.state.visibilityGroups} />
      );

      this.context.utils.dialog.create({
        id: VisibilityGroupCreateDialogId,
        content: dialogBodyContent,
        _wideMode: true,
        closeOnBgClick: true
      });
    }
    else {
      this.updateUserVisibilityGroup({groupId, userId});
    }
  };

  resetVisibilityGroup = ({parentContextId: userId}) => {
    if(userId) {
      const user = this.state.users.find(u => u.id === userId);
      const userVisibilityGroupId = user.visibilityGroupId;

      user.visibilityGroupId = null;
      this.updateUserVisibilityGroup({groupId: userVisibilityGroupId, userId, remove: true});
    }
  };

  resetCheckedUsersToFullAccessUser = ({usersSet}) => {
    const visibilityGroupsUsers = {};

    let someCuratorsSelected = false;
    const {users: stateUsers} = this.state;
    const users = stateUsers.map(u => {
      const user = {...u};

      if(usersSet.has(user.id)) {
        if(!userCanCurate({user})) {
          const groupId = user.visibilityGroupId;

          user.visibilityGroupId = null;

          if(groupId) {
            const groupUserSet = visibilityGroupsUsers[groupId] || new Set([]);

            groupUserSet.add(user.id);

            visibilityGroupsUsers[groupId] = groupUserSet;
          }
        }
        else {
          someCuratorsSelected = true;
        }
      }

      return user;
    });

    const updateBlock = () => {
      this.setState({users, applyingBulkEdit: true});

      const promised = [];
      const {api: {visibilityGroupUpdate}} = this.context;

      Object.entries(visibilityGroupsUsers).forEach(([groupId, groupUserSet]) => {
        promised.push(visibilityGroupUpdate({
          visibilityGroupOptions: {
            id: groupId,
            removeUsers: [...groupUserSet]
          }
        }));
      });

      Promise.allSettled(promised).then(results => {
        const bulkEditStatus = results.some(result => result.status === 'rejected')
          ? 'failue'
          : 'success';
        const {userChecked} = this.state;

        this.loadUsers(userChecked);
        this.setState({bulkEditStatus});
        clearTimeout(this.bulkEditStatusClear);

        if(bulkEditStatus === 'success') {
          this.bulkEditStatusClear = setTimeout(() => {
            this.setState({bulkEditStatus: null});
          }, 6000);
        }
      }).catch(err => {
        this.setState({bulkEditStatus: err});
      }).finally(() => {
        this.setState({applyingBulkEdit: false});
      });
    };

    const {utils: {dialog: {confirm, alert}}} = this.context;

    if(Object.keys(visibilityGroupsUsers).length) {
      if(someCuratorsSelected) {
        confirm({
          message: 'Some of the checked users are admins and/or curators. They will not be assigned the "Full Access" visibility group.<br><br>'
            + 'Do you want to assign the "Full Access" visibility group to all other checked users?<br><br> You cannot undo this action.',
          okCallback: updateBlock
        });
      }
      else {
        confirm({
          message: 'Assign the "Full Access" visibility group to all checked users?<br><br> You cannot undo this action.',
          okCallback: updateBlock
        });
      }
    }
    else if(someCuratorsSelected) {
      alert('Only admins and/or curators are checked or all checked users are assigned to the "Full Access" visibility group so no changes will be applied.');
    }
  };

  updateUsersVisibilityGroup = ({groupId, usersSet, remove = false, viaBulkEdit = false}) => {
    if(!usersSet?.size) {
      return;
    }

    if(groupId === 0) {
      // special case for bulk edit which means to reset to full access user.
      return this.resetCheckedUsersToFullAccessUser({usersSet});
    }

    const {users: stateUsers, visibilityGroups} = this.state;
    const visibilityGroup = visibilityGroups.find(g => g.id === groupId);

    if(!visibilityGroup) {
      return;
    }

    const {name: vgName} = visibilityGroup;

    const usersSubSet = new Set([]);
    let someCuratorsSelected = false;
    const users = stateUsers.map(u => {
      const user = {...u};

      if(usersSet.has(user.id)) {
        // Omit admin/curator users.
        // We can't change their visibility group setting
        if(!userCanCurate({user})) {
          user.visibilityGroupId = groupId;

          usersSubSet.add(user.id);
        }
        else {
          someCuratorsSelected = true;
        }
      }

      return user;
    });

    const updateBlock = () => {
      this.setState({users, applyingBulkEdit: viaBulkEdit});

      const {api: {visibilityGroupUpdate}} = this.context;

      visibilityGroupUpdate({
        visibilityGroupOptions: {
          id: groupId,
          [remove ? 'removeUsers' : 'addUsers']: [...usersSubSet]
        }
      }).then(() => {
        const {userChecked} = this.state;

        this.loadUsers(userChecked);

        if(viaBulkEdit) {
          this.setState({bulkEditStatus: 'success'});
          clearTimeout(this.bulkEditStatusClear);
          this.bulkEditStatusClear = setTimeout(() => {
            this.setState({bulkEditStatus: null});
          }, 6000);
        }
      }).catch(err => {
        if(viaBulkEdit) {
          this.setState({bulkEditStatus: err});
        }
      }).finally(() => {
        this.setState({applyingBulkEdit: false});
      });
    };

    const {utils: {dialog: {confirm, alert}}} = this.context;

    if(viaBulkEdit) {
      if(usersSubSet.size) {
        if(someCuratorsSelected) {
          confirm({
            message: `Some of the checked users are admins and/or curators. They will not be assigned the "${vgName}" visibility group.<br><br>`
            + `Do you want to assign the "${vgName}" visibility group to all other checked users?<br><br> You cannot undo this action.`,
            okCallback: updateBlock
          });
        }
        else {
          confirm({
            message: `Assign the "${vgName}" visibility group to all checked users?<br><br> You cannot undo this action.`,
            okCallback: updateBlock
          });
        }
      }
      else if(someCuratorsSelected) {
        alert('Only admins and/or curators are checked so no changes will be applied.');
      }
    }
    else {
      updateBlock();
    }
  };

  updateUserVisibilityGroup = ({groupId, userId, remove = false}) => {
    this.updateUsersVisibilityGroup({groupId, usersSet: new Set([userId]), remove});
  };

  toggleUserChecked = ({id, checked}) => {
    const {userBulkEditDisabled} = this.state;

    if(userBulkEditDisabled[id]) {
      return;
    }

    this.setState(prevState => {
      const userChecked = checked
        ? ReactUpdate(prevState.userChecked, {[id]: {$set: checked}})
        : ReactUpdate(prevState.userChecked, {$unset: [id]});

      clearTimeout(this.bulkEditStatusClear);

      return {userChecked, bulkEditStatus: null};
    });
  };

  getUserBulkEditDisabled = users => {
    const userBulkEditDisabled = (users || []).reduce((acc, user) => {
      if(userIsAdmin({user}) || userCanCurate({user}) || user.deletedAt || !user.confirmedAt) {
        acc[user.id] = true;
      }

      return acc;
    }, {});

    return {
      userBulkEditDisabled,
      totalSelectableUsers: users?.length - Object.keys(userBulkEditDisabled).length
    };
  };

  loadUsers = (userChecked = {}) => {
    const {user} = this.props;
    const {page, itemsPerPage: limit, sortOrder: order, viewType: typeFilter, viewGroup: groupFilter, query = ''} = this.state;
    const userOptions = {
      page,
      limit,
      order,
      typeFilter,
      groupFilter,
      deleted: userCanCurate({user}),
      query: (query || '').trim()
    };

    usersGet({userOptions, code: 'ManageUsers.loadUsers'}).then(fetchedUsers => {
      this.setState({
        users: fetchedUsers,
        userChecked,
        ...this.getUserBulkEditDisabled(fetchedUsers)
      }, () => {
        const {users} = this.state;

        // if there's no users to show and page !== 1, fall back to showing page 1
        // e.g. the last user on the page was deleted, i.e. deactivated from active or reactivated from inactive.
        if((page !== 1) && _.isEmpty(users)) {
          this.setState({
            page: 1
          }, this.getUsersCounts);
        }
      });
    });
  };

  handleChangeItemsPerPage = itemsPerPage => {
    this.setState({itemsPerPage}, this.loadUsers);
  };

  handlePageClick = data => {
    const page = data.selected + 1;

    this.setState({page}, this.loadUsers);
  };

  handleToggleBulkEdit = () => this.setState(({bulkEditOn}) => ({bulkEditOn: !bulkEditOn, bulkEditStatus: null}));

  handleSelectAll = () => {
    const {users} = this.state;

    users?.forEach(({id}) => this.toggleUserChecked({id, checked: true}));
  };

  handleDeselectAll = () => {
    const {users} = this.state;

    users?.forEach(({id}) => this.toggleUserChecked({id, checked: false}));
  };

  handleVisibilityGroupSelected = vg => {
    const {userChecked} = this.state;

    this.updateUsersVisibilityGroup({groupId: vg, usersSet: new Set(Object.keys(userChecked).map(id => parseInt(id, 10))), viaBulkEdit: true});
  };

  getBulkEditDisabledBasedOnFilter = () => {
    const {viewType} = this.state;

    return ['admins', 'curators', 'pending', 'deactivated', 'suppressed'].includes(viewType);
  };

  renderEmailReviewConfigModal = user => {
    const {uiReviewSettingsEnabled, uiReviewSettingsGroups} = this.state;
    const {utils: {dialog}} = this.context;
    const {
      company: {
        companyData: {customIntelDigestName} = {}
      } = {},
      rivalGroups
    } = this.props;

    const uiRivalGroups = [];
    let uiEmptyRivalGroups;
    let uiHelpBlock = (
      <p className="help-block">
        <strong><em>Tip:</em></strong> Curator users can create new company groups on
        the <Link to="/" onClick={() => dialog.remove('modal-email-review')}><strong>Companies Page</strong></Link>.
      </p>
    );

    for(let i = 0; i < rivalGroups.length; i++) {
      uiRivalGroups.push(
        <div key={i} className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={(uiReviewSettingsGroups || []).includes(rivalGroups[i].id)}
              onChange={e => this.handleReviewSettingsInput('uiReviewSettingsGroups', rivalGroups[i].id, true, user, e)} /> {rivalGroups[i].name}{' '}
            <small style={{opacity: 0.7, whiteSpace: 'nowrap'}}>({rivalGroups[i].rivals.length} Companies)</small>
          </label>
        </div>
      );
    }

    // no companies, empty state message
    if(!rivalGroups || !rivalGroups.length) {
      uiEmptyRivalGroups = (
        <div>
          <div className="help-block">(No company groups exists)</div>
          {uiHelpBlock}
        </div>
      );

      uiHelpBlock = null;
    }

    const uiCompanyFilters = (
      <div className="form-group">
        <label>Company groups to include in {user.firstName}&apos;s Intel Digest:</label>
        {uiHelpBlock}
        <div className="email-review-config-modal--groups">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={!uiReviewSettingsGroups}
                onChange={e => this.handleReviewSettingsInput('uiReviewSettingsGroups', null, false, user, e)} /> <em>All Companies</em>
            </label>
          </div>
          {uiRivalGroups}
          {uiEmptyRivalGroups}
        </div>
      </div>
    );

    const uiModalContent = (
      <div>
        <h4 className="heading-dialog">Customize <strong>{user.firstName}</strong>&apos;s Intel Digest</h4>
        <div className="u-pt-xs">
          <div className="radio-inline">
            <label>
              <input
                type="radio"
                checked={uiReviewSettingsEnabled}
                onChange={() => this.handleReviewSettingsInput('uiReviewSettingsEnabled', true, false, user)} /> Enabled
            </label>
          </div>
          <div className="radio-inline" style={{marginLeft: '20px'}}>
            <label>
              <input
                type="radio"
                checked={!uiReviewSettingsEnabled}
                onChange={() => this.handleReviewSettingsInput('uiReviewSettingsEnabled', false, false, user)} /> Disabled
            </label>
          </div>
        </div>
        <p className="help-block u-mb-m">
          The <strong>{customIntelDigestName || 'Klue Intel Digest'}</strong> email includes selected Feed posts.
        </p>
        {uiReviewSettingsEnabled ? uiCompanyFilters : null}
        <div className="row dialog-toolbar">
          <div className="col-sm-5">
            <div className="button button--disabled" onClick={this.handleCancelEmailReviewSettingsClick} style={{width: '100%'}}>Cancel</div>
          </div>
          <div className="col-sm-7">
            <div className="button" onClick={() => this.handleSaveEmailReviewSettingsClick(user)} style={{width: '100%'}}>Save</div>
          </div>
        </div>
      </div>
    );

    let unsuppressCallback = null;
    const {utils: {user: currentUser}} = this.context;

    if(userIsAdmin({user: currentUser})) {
      const {api: {userUnsuppress}} = this.context;

      unsuppressCallback = () => userUnsuppress(user, success => {
        if(!success) {
          return;
        }

        this.loadUsers();
      });
    }

    dialog.create({
      id: 'modal-email-review',   // calling with same id with just update the contents
      content: renderSuppressionMessage(user, dialog, unsuppressCallback) || uiModalContent
    });
  };

  renderEmailReviewIndicator = user => {
    const {suppression, emailDigest} = user;
    const isEnabled = Boolean(emailDigest);
    const rivalFilters = this._getEmailReviewFilteredRivalIds(user);
    const blockClassName = 'email-review-indicator';
    const divClassName = classNames(`${blockClassName}`, {
      [`${blockClassName}--configured`]: rivalFilters,
      [`${blockClassName}--on`]: isEnabled,
      [`${blockClassName}--off`]: suppression
    });

    return (
      <div className={divClassName} onClick={() => this.handleEmailReviewSettingsClick(user)}>
        <i className="fa fa-envelope" />
      </div>
    );
  };

  renderVisibilityGroupsModal = () => {
    const {utils} = this.context;
    const {visibilityGroups} = this.state;
    const manageVisibilityGroupDialogId = 'manage_visibility_group_modal';
    const dialogBodyContent = (
      <VisibilityGroupsManage
        modalId={manageVisibilityGroupDialogId}
        visibilityGroups={visibilityGroups}
        onGroupAddedCallback={this.onGroupAdded}
        onGroupUpdatedCallback={this.onGroupUpdated}
        onGroupDeletedCallback={this.onGroupDeleted}
        onGroupMovedCallback={this.onGroupMoved}
        onGroupToggleUserDefault={this.handleToggleUserDefaultGroup}
        onGroupToggleCardDefault={this.handleToggleCardDefaultGroup} />
    );

    utils.dialog.create({
      id: manageVisibilityGroupDialogId,
      content: dialogBodyContent,
      _widerMode: true
    });
  };

  // Labels on users: visible to all users
  renderInactiveStatusLabel({title, status}) {
    return (
      <span className="label label-flag" title={title}>
        {status}
      </span>
    );
  }

  renderUserList = () => {
    const {user: currentUser, company} = this.props;
    const {
      bulkEditOn,
      query = '',
      userBulkEditDisabled,
      userChecked,
      users: currUsers,
      viewType,
      visibilityGroups
    } = this.state;
    const {utils: {assignVisibilityGroupViaSSO}} = this.context;
    const users = [...(currUsers || [])];
    const isCurator = userCanCurate({user: currentUser});

    if(currUsers === null) {
      return (<div className="user-list-loading"><i className="fa fa-spin fa-spinner" /></div>);
    }

    if(!users || !users.length) {
      if(query.trim().length) {
        return (
          <h4>Sorry, no users matched your search.</h4>
        );
      }

      const filterLabel = ((viewType === 'all') || (viewType === 'users')) ? '' : viewType;
      const filterRegion = !filterLabel ? null : (
        <strong>{filterLabel.replace('_', '-')}</strong>
      );

      return (
        <h4>Sorry, no {filterRegion} users were found.</h4>
      );
    }

    const userNodes = users.map(user => {
      const userUrl = `/users/${user.id}`;
      const userClassName = classNames('user', {
        'user-deleted': Boolean(user.deletedAt),
        'user-pending': Boolean(!user.deletedAt && !user.confirmedAt)
      });
      const userOptions = [];
      let emailDomainRegion;
      let adminLabel;
      let curatorLabel;
      let inactiveLabel;
      let deleteLabel = 'Deactivate';
      let deleteIcon = 'fa-trash';
      let deleteAction;
      let lastSeenAt;
      let digestRegion;
      let userMenu;
      let visibilityGroupControl;

      if(user.deletedAt) {
        inactiveLabel = this.renderInactiveStatusLabel({title: 'User is deactivated', status: 'Deactivated'});
      }
      else if(user.isPreInvite) {
        inactiveLabel = this.renderInactiveStatusLabel({title: 'User hasn\'t been invited to the platform', status: 'Pre-Invite'});
      }
      else if(!user.confirmedAt) {
        if(user.pendingInvitation) {
          inactiveLabel = this.renderInactiveStatusLabel({title: 'User hasn\'t accepted invite', status: 'Invited'});
        }
        else {
          inactiveLabel = this.renderInactiveStatusLabel({title: 'User hasn\'t confirmed their email', status: 'Unconfirmed'});
        }
      }

      if(userIsAdmin({user})) {
        adminLabel = (
          <span className="label label-success label-alt">Admin</span>
        );
      }
      else if((user.roles || []).includes('curator')) {
        // NOTE: all admins have curator access, so we don't add a curator label for admins
        curatorLabel = (
          <span className="label label-success">Curator</span>
        );
      }

      // If user is in Invited, PreInvite, or Unconfirmed state, lastSeenAt be the state label
      if(user.lastSeenAt) {
        lastSeenAt = (
          <time className="last-seen" dateTime={user.lastSeenAt} title="Last seen">
            {moment(user.lastSeenAt).fromNow()}
          </time>
        );
      }

      // Actions on users: only available to admin users
      if(userIsAdmin({user: currentUser})) {
        digestRegion = (
          <div className="manage-users-row-email-review-column">
            {this.renderEmailReviewIndicator(user)}
          </div>
        );

        if(!user.deletedAt) {
          const toAdminAction = e => this.handleRoleChange(user, ['admin'], ['curator'], e);
          const toCuratorAction = e => this.handleRoleChange(user, ['curator'], ['admin'], e);
          const toConsumerAction = e => this.handleRoleChange(user, null, ['admin', 'curator'], e);

          if(adminLabel) {
            userOptions.push({
              value: 'demoteToCurator',
              label: 'Make Curator',
              icon: 'fa-arrow-circle-down',
              onOptionClick: toCuratorAction
            });
            userOptions.push({
              value: 'demoteToConsumer',
              label: 'Make Consumer',
              icon: 'fa-arrow-circle-down',
              onOptionClick: toConsumerAction
            });
          }
          else if(curatorLabel) {
            userOptions.push({
              value: 'promoteToAdmin',
              label: 'Make Admin',
              icon: 'fa-arrow-circle-up',
              onOptionClick: toAdminAction
            });
            userOptions.push({
              value: 'demoteToConsumer',
              label: 'Make Consumer',
              icon: 'fa-arrow-circle-down',
              onOptionClick: toConsumerAction
            });
          }
          else {
            userOptions.push({
              value: 'promoteToAdmin',
              label: 'Make Admin',
              icon: 'fa-arrow-circle-up',
              onOptionClick: toAdminAction
            });
            userOptions.push({
              value: 'promoteToCurator',
              label: 'Make Curator',
              icon: 'fa-arrow-circle-up',
              onOptionClick: toCuratorAction
            });
          }
        }

        if(!user.deletedAt) {
          deleteAction = () => this.handleDeleteUser(user, false);
        }
        else {
          deleteLabel = 'Re-activate';
          deleteIcon = 'fa-undo';
          deleteAction = () => this.handleDeleteUser(user, true);
        }

        if(!user.deletedAt) {
          userOptions.push({
            value: 'digest',
            label: 'Customize Intel Digest',
            icon: 'fa-envelope',
            onOptionClick: () => this.handleEmailReviewSettingsClick(user)
          });
        }

        if(!user.deletedAt && (company?.passwordAuthEnabled || user.passwordAuthEnabled)) {
          if(user.isPreInvite) {
            userOptions.push({
              value: 'reinvite',
              label: 'Send Invitation Email',
              icon: 'fa-send',
              onOptionClick: () => this.handleResendInvitationClick(user)
            });
          }
          else if(!user.confirmedAt) {
            if(user.pendingInvitation) {
              userOptions.push({
                value: 'reinvite',
                label: 'Resend Invitation Email',
                icon: 'fa-refresh',
                onOptionClick: () => this.handleResendInvitationClick(user)
              });
            }
            else {
              userOptions.push({
                value: 'reconfirm',
                label: 'Resend Confirmation Email',
                icon: 'fa-refresh',
                onOptionClick: () => this.handleResendConfirmationClick(user)
              });
            }
          }
        }
      }

      // if deleteAction is set, add it to the end of the menu after separator
      if(deleteAction) {
        userOptions.push({
          value: 'delete',
          label: deleteLabel,
          icon: deleteIcon,
          separator: true,
          onOptionClick: deleteAction
        });
      }

      if(userOptions.length) {
        userMenu = (
          <div className="ui-dropdown-panel">
            <Dropdown
              options={userOptions}
              keyPrefix="digest-more-options"
              condensed={true} />
          </div>
        );
      }

      if(curatorLabel || adminLabel) {
        visibilityGroupControl = (
          <span className="user-visibility-groups--default">
            <span className="user-labels">
              {adminLabel || curatorLabel}
            </span>
          </span>
        );
      }
      else if(isCurator) {
        // check feature flag and show visibility group labels - only visible to curators / admins
        const visibilityGroupId = (user && user.visibilityGroupId);
        const dropDownValues = [];
        let visibilityGroupName = 'Full Access';

        visibilityGroups.forEach(group => {
          dropDownValues.push([group.id, group.name]);

          if(group.id === visibilityGroupId) {
            visibilityGroupName = group.name;
          }
        });

        dropDownValues.push([
          DropdownMenuConstants.CREATE_NEW_ITEM_REF,
          (<button className="button button--small button--full" key={`createGroup-user${user.id}`}>Create new group</button>),
          true, 'toolbar-dropdown-button--util'
        ]);

        if(visibilityGroupId) {
          dropDownValues.push([
            DropdownMenuConstants.RESET_ITEM_REF,
            (<button className="button button--small button--full button--alert" key={`resetGroup-user${user.id}`}>Reset to Full Access</button>),
            true, 'toolbar-dropdown-button--util'
          ]);
        }

        visibilityGroupControl = (
          <div className="user-visibility-groups">
            {
              assignVisibilityGroupViaSSO() ?
                <span className="current-user-group">{visibilityGroupName}</span> :
                <DropdownMenu
                  defaultValue="Full Access"
                  id={`visibilityGroupUser${user.id}`}
                  label=""
                  parentContextId={user.id}
                  ref={`visibilityGroupUser${user.id}`}
                  resetCallback={this.resetVisibilityGroup}
                  selectCallback={this.changeVisibilityGroup}
                  selectedValue={visibilityGroupId || 'Full Access'}
                  title="Select visibility group"
                  values={dropDownValues} />
            }
          </div>
        );
      }

      if(isCurator) {
        emailDomainRegion = (
          <a href={'mailto:' + user.email} className="user-email" title={`Contact ${user.name} (${user.email})`}>{user.email}</a>
        );
      }
      else {
        const domain = (user.email || '@').split('@')[1];

        emailDomainRegion = (
          <a href={`https://${domain}`} className="user-email" title={`Visit ${domain}`} target="_blank">{domain}</a>
        );
      }

      return (
        <tr key={user.id}
          className={classNames(userClassName, {
            'bulk-edit': bulkEditOn,
            'show-checkbox-options': bulkEditOn
          })}>
          {bulkEditOn && <td>
            <DigestTypeCheckbox
              checked={userChecked[user.id]}
              disabled={userBulkEditDisabled[user.id]}
              id={user.id}
              onClick={this.toggleUserChecked}
              tooltip={userBulkEditDisabled[user.id] ? 'Only active consumers can be checked for bulk edit' : null}
              transparentOnChecked={true} />
          </td>}
          {/* <th colspan="2"> */}
          <td>
            {inactiveLabel && !user.deletedAt ? (
              <img src={user.imageMed} alt={user.name} />
            ) : (
              <Link to={userUrl} className="user-avatar" title={`View ${user.name}'s profile`}>
                <img src={user.imageMed} alt={user.name} />
              </Link>
            )}
          </td>
          <td>
            {inactiveLabel && !user.deletedAt ? user.name : (
              <Link to={userUrl} className="user-name" title={`View ${user.name}'s profile`}>{user.name}</Link>
            )}
          </td>
          <td>
            <span className="user-atname" title={`@${user.username}`}>@{user.username}</span>
          </td>
          <td>
            {emailDomainRegion}
          </td>
          <td>
            {visibilityGroupControl}
          </td>
          {/* <th colspan="3"> */}
          <td>
            {inactiveLabel ? inactiveLabel : lastSeenAt}
          </td>
          <td>
            {digestRegion}
          </td>
          <td>
            {userMenu}
          </td>
        </tr>
      );
    });

    return (
      <table className="users-table">
        <thead>
          <tr className={classNames({'bulk-edit': bulkEditOn})}>
            {bulkEditOn && <th>Select</th>}
            <th colSpan="2">User</th>
            <th>User Name</th>
            {isCurator
              ? <th>Email</th>
              : <th>Domain</th>
            }
            {isCurator
              ? <th>
                <div className="visibility-group-header">
                  <span className="visibility-group-header--label">Visibility Group</span>
                  <button
                    className="button button--xsmall button--alt visibility-group-header--button"
                    onClick={this.renderVisibilityGroupsModal}>Manage</button>
                </div>
              </th>
              : <th>Role</th>
            }
            <th colSpan="3">Last Seen</th>
          </tr>
        </thead>
        <tbody>
          {userNodes}
        </tbody>
      </table>
    );
  };

  renderSearchRegion = () => {
    const {query = ''} = this.state;
    const searchIconClass = classNames('user-search_form_icon', {
      'user-search_form_icon--active': Boolean(query)
    });
    let searchIconProps = {};

    if(query.length) {
      searchIconProps = {
        onClick: this.clearQuery,
        title: 'Clear search'
      };
    }

    return (
      <form className="user-search_form" onChange={this.handleQueryChange} onKeyDown={this.handleQueryCancel} onSubmit={e => e.preventDefault()}>
        <input ref="filter" type="text" className="klue-input" defaultValue={query} placeholder="Find a user..." data-cy="findUserInput" />
        <div className={searchIconClass} {...searchIconProps}>
          <Icon icon={query ? 'close' : 'search'} width="20" height="20" />
        </div>
      </form>
    );
  };

  renderPaginator = () => {
    const {page} = this.state;
    const pageCount = this.getPagesCount();
    const forcePage = page ? page : 1;
    const iconPrevious = (
      <Icon icon="arrow-left" />
    );
    const iconNext = (
      <Icon icon="arrow-right" />
    );

    return pageCount <= 1 ? null : (
      <div className="manage-users-pagination" data-cy="manage-users-pagination">
        <ReactPaginate
          previousLabel={(forcePage !== 1) && iconPrevious}
          nextLabel={(forcePage !== pageCount) && iconNext}
          breakLabel={<span>...</span>}
          breakClassName="break-me"
          forcePage={forcePage - 1}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="paginator_active" />
      </div>
    );
  };

  render() {
    const {company, user, userInviteUrl} = this.props;
    const isCurator = userCanCurate({user});
    const {
      applyingBulkEdit,
      bulkEditOn,
      bulkEditStatus,
      countsByViewType,
      itemsPerPage,
      sortOrder,
      totalSelectableUsers,
      userChecked,
      viewGroup,
      viewType,
      visibilityGroups
    } = this.state;
    const {utils: {assignVisibilityGroupViaSSO}} = this.context;
    const isAssignVisibilityGroupViaSSO = assignVisibilityGroupViaSSO();
    const isBulkEditEnabled = !isAssignVisibilityGroupViaSSO;
    const selectedCount = Object.keys(userChecked).length;
    const disableBulkEdit = this.getBulkEditDisabledBasedOnFilter();
    let inviteRegion;
    let bulkEditButton;
    let bulkEditStatusFeedback;

    if(company && isCurator && (company.passwordAuthEnabled || company.ssoAuthEnabled)) {
      inviteRegion = (
        <div className="user-search_invite">
          <a href={userInviteUrl} className="button">
            Invite New Users
          </a>
        </div>
      );
    }

    if(isCurator && isBulkEditEnabled) {
      bulkEditButton = (
        <button type="button" className={classNames('bulk-edit-users')} onClick={this.handleToggleBulkEdit}>
          Bulk Edit
        </button>
      );

      if(bulkEditStatus) {
        const bulkEditStatusClassName = classNames(
          'manage-users-bulk-edit-status',
          bulkEditStatus === 'success' ? 'success' : 'failure'
        );
        const bulkEditStatusMessage = bulkEditStatus === 'success' ? 'success!' : 'something failed.';

        bulkEditStatusFeedback = (
          <div className={bulkEditStatusClassName}>
            {bulkEditStatusMessage}
          </div>);
      }
    }

    return (
      <div className="user-list">
        <ManageUsersToolbar
          counts={countsByViewType}
          itemsPerPage={itemsPerPage}
          onChangeItemsPerPage={this.handleChangeItemsPerPage}
          onSortClick={this.handleSortClick}
          onViewClick={this.handleViewTypeClick}
          onVisibilityGroupClick={this.handleVisibilityGroupClick}
          sortBy={sortOrder}
          user={user}
          viewBy={viewType}
          viewGroup={viewGroup}
          visibilityGroups={visibilityGroups || []} />
        <div className="main">
          <section>
            <div className="user-list_heading">
              {viewType === 'users' ? 'All' : viewType.replace('_', '-')} {(viewType !== 'users') && viewType.endsWith('s') ? '' : 'Users'}
              <ManageUsersPerPageDropdown
                dataTrackingId="manage-users-per-page-dropdown"
                selectedValue={itemsPerPage}
                onChangeItemsPerPage={this.handleChangeItemsPerPage} />
            </div>
            <div className="user-search">
              {this.renderSearchRegion()}
              {(bulkEditButton || inviteRegion) && <div className="manage-users-buttons">
                  {bulkEditButton}
                  {inviteRegion}
                </div>}
            </div>
            {isBulkEditEnabled && <ManageUsersBulkEditControls
              visible={bulkEditOn}
              disabled={disableBulkEdit}
              status={bulkEditStatusFeedback}
              applyingBulkEdit={applyingBulkEdit}
              selectedCount={selectedCount}
              totalCount={totalSelectableUsers ?? 0}
              visibilityGroups={visibilityGroups}
              onSelectAll={this.handleSelectAll}
              onDeselectAll={this.handleDeselectAll}
              onVisibilityGroupSelected={this.handleVisibilityGroupSelected} />}
            <div className="table--scroller">
              {this.renderUserList()}
            </div>
            {this.renderPaginator()}
          </section>
        </div>
      </div>
    );
  }

}

export default ManageUsers;
