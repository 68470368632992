import {useState, useCallback} from 'react';

import {userCanCurate} from '../modules/roles_utils';
import {allCompaniesID} from '../modules/constants/digest';

import DigestMenuBuildAndEdit from './_digest_menu_build_and_edit';
import DigestMenuEmail from './_digest_menu_email';

const DigestMenu = props => {
  const {
    user, digest, editMode, feedMode, reorderMode, archivesMode,
    canEditDigest, hasUnsavedFields, savingDigest, rivalGroups,
    onSendPreviewsClick,
    onSaveDigestClick, onReorderFavoriteSave, onToggleFeed, onAddCustomContent, onToggleReorder, onToggleLock,
    editorBusy, editorBusyReason, canSendDigest, canAddCustomDigestItems, sendingPreviews, previewsSentAt,
    sentPreviewFailures, sentRivalGroupIds
  } = props;
  const [previewExpanded, showPreviewExpanded] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState({[allCompaniesID]: true});
  const isCurator = userCanCurate({user});

  const togglePreviewExpanded = () => {
    showPreviewExpanded(!previewExpanded);
  };

  const handleSendPreviewsClick = rivalGroupIds => {
    onSendPreviewsClick(rivalGroupIds);
  };

  const handleSetGroupSelected = useCallback(group => {
    if(!group) {
      return setSelectedGroups({[allCompaniesID]: true});
    }

    const {id, checked} = group;

    setSelectedGroups({...selectedGroups, [id]: checked});
  }, [selectedGroups]);

  const handleToogleReorder = (favorites, e) => {
    if(!reorderMode) {
      return onToggleReorder(favorites, e);
    }

    onReorderFavoriteSave(e);
  };

  if(editMode && _.isEmpty(digest)) {
    return null;
  }

  const favorites = digest?.favorites ? digest.favorites.slice() : [];
  const {manual} = digest || {};

  return (
    <div className="digest-sidebar_menu">
      <DigestMenuBuildAndEdit
        editMode={editMode}
        isCurator={isCurator}
        digest={digest}
        favorites={favorites}
        canEditDigest={canEditDigest}
        canAddCustomDigestItems={canAddCustomDigestItems}
        feedMode={feedMode}
        archiveMode={archivesMode}
        reorderMode={reorderMode}
        digestLocked={Boolean(digest?.locked)}
        hasUnsavedFields={hasUnsavedFields}
        editorBusy={editorBusy}
        savingDigest={savingDigest}
        editorBusyReason={editorBusyReason}
        onAddContent={onToggleFeed}
        onAddCustomContent={onAddCustomContent}
        onToggleReorder={favorites.length > 1
          ? e => handleToogleReorder(favorites, e)
          : null}
        onToggleLock={onToggleLock}
        onSaveDigest={onSaveDigestClick} />
      <DigestMenuEmail
        editMode={editMode}
        isCurator={isCurator}
        digest={digest}
        manual={manual}
        user={user}
        rivalGroups={rivalGroups}
        sentPreviewFailures={sentPreviewFailures}
        sentRivalGroupIds={sentRivalGroupIds}
        previewExpanded={previewExpanded}
        selectedGroups={selectedGroups}
        archiveMode={archivesMode}
        reorderMode={reorderMode}
        hasUnsavedFields={hasUnsavedFields}
        editorBusy={editorBusy || savingDigest}
        canSendDigest={canSendDigest}
        canEditDigest={canEditDigest}
        sendingPreviews={sendingPreviews}
        previewsSentAt={previewsSentAt}
        onSetGroupSelected={handleSetGroupSelected}
        onTogglePreview={togglePreviewExpanded}
        onSendPreviews={handleSendPreviewsClick} />
    </div>
  );
};

DigestMenu.propTypes = {
  user: PropTypes.object,
  digest: PropTypes.object,
  editMode: PropTypes.bool,
  feedMode: PropTypes.bool,
  reorderMode: PropTypes.bool,
  archivesMode: PropTypes.bool,
  canEditDigest: PropTypes.bool,
  canSendDigest: PropTypes.bool,
  canAddCustomDigestItems: PropTypes.bool,
  hasUnsavedFields: PropTypes.bool,
  savingDigest: PropTypes.bool,
  editorBusy: PropTypes.bool,
  sendingPreviews: PropTypes.bool,
  previewsSentAt: PropTypes.object,
  editorBusyReason: PropTypes.string,
  rivalGroups: PropTypes.arrayOf(PropTypes.object),
  sentPreviewFailures: PropTypes.arrayOf(PropTypes.string),
  sentRivalGroupIds: PropTypes.arrayOf(PropTypes.number),
  onSendPreviewsClick: PropTypes.func,
  onCreateDigestClick: PropTypes.func,
  onSaveDigestClick: PropTypes.func,
  onReorderFavoriteSave: PropTypes.func,
  onToggleFeed: PropTypes.func,
  onAddCustomContent: PropTypes.func,
  onToggleReorder: PropTypes.func,
  onToggleLock: PropTypes.func
};

DigestMenu.defaultProps = {
  user: null,
  digest: null,
  editMode: false,
  feedMode: false,
  reorderMode: false,
  archivesMode: false,
  canEditDigest: true,
  canSendDigest: false,
  canAddCustomDigestItems: false,
  hasUnsavedFields: false,
  savingDigest: false,
  editorBusy: false,
  sendingPreviews: false,
  previewsSentAt: null,
  editorBusyReason: 'Uploading Image...',
  rivalGroups: [],
  sentPreviewFailures: null,
  sentRivalGroupIds: null,
  onSendPreviewsClick() {},
  onCreateDigestClick() {},
  onSaveDigestClick() {},
  onReorderFavoriteSave() {},
  onToggleFeed() {},
  onAddCustomContent() {},
  onToggleReorder() {},
  onToggleLock() {}
};

export default DigestMenu;
