import {labels} from '../_constants';
import {
  Container,
  Description,
  Title
} from './_styles';

const RespondedSurvey = () => (
  <>
    <Container>
      <Title>{labels.respondedSurveyPage.title}</Title>
      <Description>{labels.respondedSurveyPage.description}</Description>
    </Container>
    <div style={{width: '100%', height: '16px', display: 'flex', flexDirection: 'row', gap: '4px', fontSize: '12px', justifyContent: 'center'}}>
      Powered by <div style={{height: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <img width="27px" height="11px" src="/logo-klue-dark.png" />
      </div>
    </div>
  </>
);

export default RespondedSurvey;
