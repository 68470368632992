import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchCraftData} from './data_providers';

const periodTypeRank = [
  'fiscal_year',
  'year',
  'half',
  'quarter',
  'month'
];

function normalizePeriodType(periodType) {
  switch(periodType) {
    case 'fiscal_year':
      return 'fiscal_year';
    case 'h1':
    case 'h2':
      return 'half';
    case 'q1':
    case 'q2':
    case 'q3':
    case 'q4':
      return 'quarter';
    default:
      return periodType;
  }
}

function selectPreferredPeriodType(periodTypes) {
  periodTypes.sort((a, b) => {
    const aRank = periodTypeRank.indexOf(a);
    const bRank = periodTypeRank.indexOf(b);

    if(aRank === -1) {
      console.error(`Unknown period type: ${a}`);
    }

    if(bRank === -1) {
      console.error(`Unknown period type: ${b}`);
    }

    return aRank - bRank;
  });

  // Best ranking at head of list
  return periodTypes[0];
}

export function operatingMetric(company, metricLabel) {
  if(!KlueTypes.Company.validate(company)) {
    throw new Error('Parameter was unexpected type.');
  }

  return fetchCraftData(company.info).then(data => {
    let label = '';

    // Filter metrics to only the selected series
    const metricSeries = data.operatingMetrics.filter(metric => {
      if(metric.companySpecificKpi.toLowerCase() === metricLabel.toLowerCase()) {
        label = metric.companySpecificKpi; // Use the label we actually received from Craft rather than whatever was passed in.

        return true;
      }
    });

    // Normalize the format (particularly periodType)
    const normalizedSeries = metricSeries.map(metric => ({
      value: metric.value.amount,
      date: moment(metric.timePeriod.endDate),
      periodType: normalizePeriodType(metric.timePeriod.periodType)
    }));

    // Filter periodTypes to only the prefered
    const preferredPeriodType = selectPreferredPeriodType(normalizedSeries.map(metric => metric.periodType));
    const filteredSeries = normalizedSeries.filter(metric => metric.periodType === preferredPeriodType);

    // Sort series by date
    filteredSeries.sort((a, b) => a.date.valueOf() - b.date.valueOf());

    // Map series to proper Timeseries type
    const events = filteredSeries.map(metric => {
      return KlueTypes.TimeseriesEvent.create({date: KlueTypes.Date.create(metric.date), value: metric.value});
    });

    return KlueTypes.Timeseries.create({
      label,
      events: Types.arrayOf(KlueTypes.TimeseriesEvent).create(events)
    });
  });
}
