/* eslint-disable react/no-multi-comp */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {fetch, post, update, destroy} from '../modules/api_utils';

export const DigestTemplatesContext = createContext();

export const useDigestTemplates = () => {
  const digestTemplatesContext = useContext(DigestTemplatesContext);

  if(digestTemplatesContext === undefined) {
    throw new Error('useDigests must be used within <DigestTemplatesProvider />');
  }

  return digestTemplatesContext;
};

const useDigestTemplatesInternal = () => {
  const [templates, setTemplates] = useState([]);

  const fetchDigestTemplates = useCallback(async () => {
    try {
      const {data} = await fetch('/api/email_digest_templates.json');

      setTemplates((data || []).sort((a, b) => a.name.localeCompare(b.name)));
    }
    catch(err) {
      console.log(err);
    }
  }, [setTemplates]);

  useEffect(() => {
    fetchDigestTemplates();
  }, [fetchDigestTemplates]);

  const createTemplate = useCallback(async ({name, title, summary}) => {
    const params = {name};

    const trimmedTitle = (title || '').trim();

    if(trimmedTitle) {
      params.title = trimmedTitle;
    }

    const trimmedSummary = (summary || '').trim();

    if(trimmedSummary) {
      params.summary = trimmedSummary;
    }

    try {
      await post('/api/email_digest_templates.json', params);
      fetchDigestTemplates();

      return true;
    }
    catch(err) {
      console.log(err);

      return false;
    }
  }, [fetchDigestTemplates]);

  const updateTemplate = useCallback(async ({id, name, title, summary}) => {
    try {
      const params = {};

      if(name) {
        params.name = name;
      }

      if(title) {
        params.title = title;
      }

      if(summary) {
        params.summary = summary;
      }

      await update(`/api/email_digest_templates/${id}.json`, params);

      const updated = [...templates].map(t => ((t.id === id) ? {...t, name} : t)).sort((a, b) => a.name.localeCompare(b.name));

      setTemplates(updated);

      return updated;
    }
    catch(err) {
      console.log(err);

      return false;
    }
  }, [templates, setTemplates]);

  const deleteTemplate = useCallback(async id => {
    try {
      await destroy(`/api/email_digest_templates/${id}.json`);

      const updated = templates.filter(t => t.id !== id);

      setTemplates(updated);

      return updated;
    }
    catch(err) {
      console.log(err);

      return false;
    }
  }, [templates, setTemplates]);

  const value = useMemo(() => ({
    templates,
    createTemplate,
    updateTemplate,
    deleteTemplate
  }), [
    templates,
    createTemplate,
    updateTemplate,
    deleteTemplate
  ]);

  return value;
};

export const DigestTemplatesProvider = ({children}, context) => {
  const value = useDigestTemplatesInternal(context);

  return (
    <DigestTemplatesContext.Provider value={value}>
      {children}
    </DigestTemplatesContext.Provider>
  );
};

DigestTemplatesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

DigestTemplatesProvider.contextTypes = {
  utils: PropTypes.shape({
    isDigestTemplatesEnabled: PropTypes.func.isRequired
  }).isRequired
};

export const withDigestTemplates = Component => {
  return props => (
    <DigestTemplatesContext.Consumer>
      {digestTemplatesContext => (
        <Component {...props} digestTemplatesContext={{...digestTemplatesContext}} />
      )}
    </DigestTemplatesContext.Consumer>
  );
};

