import Icon from './_icon';
import classNames from 'classnames';

const BoardCollapsed = ({board, cardsCount, curatorMode, onToggleClick, disable}) => {
  if(((!board || _.isEmpty(board)) && !curatorMode) || !onToggleClick) {
    return null;
  }

  let boardContainerId;
  let boardName;
  let boardClassName;
  let cardCountRegion;

  if(curatorMode) {
    boardContainerId = '_tools';
    boardName = 'Curator Tools';
    boardClassName = 'board--tools';
  }
  else {
    boardContainerId = (board.id || '_new');
    boardName = board.name || '';
    boardClassName = `board-${board.id || 'new'}`;

    if(board.id) {
      // exclude vitals board
      cardCountRegion = (
        <div className="badge board-badge">{cardsCount}</div>
      );
    }
    else {
      boardName = boardName || 'Vitals';
    }
  }

  return (
    <div id={`b${boardContainerId}`} className={classNames('ui-card board board--collapsed', boardClassName, {disable})}>
      <div className="board-header">
        <a
          href="#"
          title="Expand Lane"
          className="btn btn-board-header btn-dropdown-size-30"
          data-tip="Expand Lane"
          data-offset="{'top': 0}"
          onClick={onToggleClick}>
          <Icon icon="expand" width="24" height="24" />
        </a>
      </div>
      <div className="board-body">
        {cardCountRegion}
        <div className="board-name">{boardName}</div>
      </div>
    </div>
  );
};

BoardCollapsed.propTypes = {
  board: PropTypes.object,
  cardsCount: PropTypes.number,
  curatorMode: PropTypes.bool,
  onToggleClick: PropTypes.func.isRequired,
  disable: PropTypes.bool
};

BoardCollapsed.defaultProps = {
  board: null,
  cardsCount: 0,
  curatorMode: false,
  onToggleClick: null,
  disable: false
};

export default BoardCollapsed;
