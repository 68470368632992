import SearchCheckbox from './_search_checkbox';

const CheckboxItemFactory = args => {
  const defaultArgs = {
    iconOn: 'fa fa-check-circle',
    iconOff: 'fa fa-circle-o',
    backgroundOn: '#fffde9',
    backgroundOff: 'transparent',
    switchMode: 'set',
    iconColorOn: '#44bc8f',
    iconColorOff: '#575265',
    labelColorOn: '#44bc8f',
    labelColorOff: '#31aae3',
    count: null
  };

  return (
    <div className="global-search-checkbox">
      <SearchCheckbox {...Object.assign({}, defaultArgs, args)} />
    </div>
  );
};

export default CheckboxItemFactory;
