import onClickOutside from 'react-onclickoutside';
import {useState, useEffect, useReducer, useRef, useCallback} from 'react';

import Dropdown from '../_dropdown';
import CardDisplay from '../_card_display';
import Icon from '../_icon';
import classNames from 'classnames';
import Spinner from '../_spinner';
import EditorToolbarCardLinkDisclosure from './_editor_toolbar_card_link_disclosure';
import {lanesGet, laneGetCards} from '../../modules/api/lanes';
import {isValidLinkUrl, isEmail} from '../../modules/url_utils';

const initialState = {
  linkType: 'cards',
  selectedLaneId: null,
  selectedBoardId: null,
  selectedCardId: null,
  fetchingBattlecards: false,
  fetchingLanes: false,
  fetchingCards: false,
  battlecards: null,
  lanes: null,
  cards: null,
  error: ''
};

export const linkTextPlaceholderText = 'Link Text...';
export const urlTextPlaceholderText = 'Enter URL...';

const reducer = (state, {type, payload}) => {
  const {value} = payload;

  switch(type) {
    case 'setLinkType':
      return {
        ...initialState,
        linkType: value
      };
    case 'setSelectedBoardId':
      return {
        ...initialState,
        linkType: state.linkType,
        selectedBoardId: value
      };
    case 'setSelectedLaneId':
      return {
        ...initialState,
        linkType: state.linkType,
        selectedBoardId: state.selectedBoardId,
        lanes: state.lanes,
        selectedLaneId: value
      };
    case 'setSelectedCardId':
      return {
        ...state,
        selectedCardId: value
      };
    case 'setFetchingBattlecards':
      return {
        ...state,
        fetchingBattlecards: value
      };
    case 'setFetchingLanes':
      return {
        ...state,
        fetchingLanes: value
      };
    case 'setFetchingCards':
      return {
        ...state,
        fetchingCards: value
      };
    case 'setBattlecards':
      return {
        ...state,
        battlecards: value
      };
    case 'setLanes':
      return {
        ...state,
        lanes: value
      };
    case 'setCards':
      return {
        ...state,
        cards: value
      };
    case 'setError':
      return {
        ...state,
        error: value
      };
    default:
      return state;
  }
};

const EditorToolbarCardLinkSelector = ({rivals, top, left, onCloseKlueLink, text, url, targetImage, onInsertLink, tab, editor, allowEmail}, context) => {
  // eslint-disable-next-line no-unused-vars
  EditorToolbarCardLinkSelector.handleClickOutside = () => onCloseKlueLink({editor});

  const {api: {battlecardsGet}} = context;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [boardName, setBoardName] = useState('');
  const [laneName, setLaneName] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [selectedTab, setSelectedTab] = useState(tab);
  const rivalCompare = (r1, r2) => r1.name.localeCompare(r2.name);
  const [sortedRivals, setSortedRivals] = useState(Object.values(rivals || {}).sort(rivalCompare));
  const laneNameLowercase = laneName.toLowerCase();
  const boardNameLowercase = boardName.toLowerCase();
  const mounted = useRef(false);
  const sequenceNumber = useRef(0);
  const didFocus = useRef(false);
  const errorRef = useRef();
  const isUpdate = useRef(Boolean(url));
  const urlRef = useRef();

  const [linkText, setLinkText] = useState(text || '');
  const [urlText, setUrlText] = useState(url || '');

  const {
    linkType,
    selectedLaneId,
    selectedBoardId,
    selectedCardId,
    fetchingBattlecards,
    fetchingLanes,
    fetchingCards,
    battlecards,
    lanes,
    cards,
    error
  } = state;

  const handleOnChangeUrlText = e => setUrlText(e.target.value);
  const handleOnChangeLinkText = e => setLinkText(e.target.value);
  const handleBoardNameChange = e => setBoardName(e.target.value);
  const handleLaneNameChange = e => setLaneName(e.target.value);
  const handleLinkTypeChange = value => {
    sequenceNumber.current += 1;
    dispatch({type: 'setLinkType', payload: {value}});
  };

  const setSelectedBoardId = value => {
    sequenceNumber.current += 1;
    dispatch({type: 'setSelectedBoardId', payload: {value}});
  };

  const setSelectedLaneId = value => {
    sequenceNumber.current += 1;
    dispatch({type: 'setSelectedLaneId', payload: {value}});
  };

  const setSelectedCardId = value => dispatch({type: 'setSelectedCardId', payload: {value}});
  const handleCardSelected = value => setSelectedCardId(value);
  const setFetchingBattlecards = value => dispatch({type: 'setFetchingBattlecards', payload: {value}});
  const setFetchingLanes = value => dispatch({type: 'setFetchingLanes', payload: {value}});
  const setFetchingCards = value => dispatch({type: 'setFetchingCards', payload: {value}});
  const setBattlecards = value => dispatch({type: 'setBattlecards', payload: {value}});
  const setLanes = value => dispatch({type: 'setLanes', payload: {value}});
  const setCards = value => dispatch({type: 'setCards', payload: {value}});

  const handleInsertCardLink = card => onCloseKlueLink({card, linkText: linkText || card?.data?.name || 'Untitled Card', editor, targetImage});
  const handleInsertBattkecardLink = battlecard =>
    onCloseKlueLink({battlecard, linkText: linkText || battlecard?.title || 'Untitled Battlecard', editor, targetImage});
  const handleInsertLink = (needsScheme, href) => {
    const getUrlTextWithSchemeIfNeeded = () => {
      if(needsScheme) {
        return isEmail(urlText) ? `mailto:${urlText}` : `https://${urlText}`;
      }

      return href || urlText;
    };

    return onInsertLink({
      linkText,
      urlText: getUrlTextWithSchemeIfNeeded(),
      targetImage,
      editor
    });
  };

  const showError = useCallback(message => {
    dispatch({type: 'setError', payload: {message}});
    errorRef?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }, [dispatch]);

  useEffect(() => {
    urlRef.current?.focus();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => setSortedRivals(Object.values(rivals || {}).sort(rivalCompare)), [rivals]);

  useEffect(() => {
    const getBattlecards = async () => {
      const sn = sequenceNumber.current;

      try {
        setFetchingBattlecards(true);

        const profileId = rivals[selectedBoardId].profile.id;

        battlecardsGet({profileId, callback(bcs) {
          if(!mounted.current || sn !== sequenceNumber.current) {
            return;
          }

          setFetchingBattlecards(false);
          setBattlecards(bcs);
        }});
      }
      catch(e) {
        console.error(e);

        if(!mounted.current) {
          return;
        }

        showError('An error occurred trying to fetch battlecards.');
        setFetchingBattlecards(false);
      }
    };

    const getLanes = async () => {
      const sn = sequenceNumber.current;

      try {
        setFetchingLanes(true);

        const laneOptions = {profileId: rivals[selectedBoardId].profile.id};
        const boardLanes = await lanesGet({laneOptions, code: 'EditorToolbarCardLinkSelector.getLanes'});

        if(!mounted.current || sn !== sequenceNumber.current) {
          return;
        }

        setLanes(boardLanes);
      }
      catch(e) {
        console.error(e);
        showError('An error occurred trying to fetch lanes.');
      }
      finally{
        if(!mounted.current) {
          return;
        }

        setFetchingLanes(false);
      }
    };

    if(!selectedBoardId) {
      return;
    }

    if(linkType === 'cards') {
      getLanes();
    }
    else if(linkType === 'battlecards') {
      getBattlecards();
    }
  }, [selectedBoardId, rivals, linkType, battlecardsGet, showError]);

  useEffect(() => {
    const getCards = async () => {
      const sn = sequenceNumber.current;

      try {
        setFetchingCards(true);

        const laneOptions = {laneId: selectedLaneId};
        const laneCards = await laneGetCards({laneOptions, code: 'EditorToolbarCardLinkSelector.getCards'});

        if(!mounted.current || sn !== sequenceNumber.current) {
          return;
        }

        setCards(laneCards);
      }
      catch(e) {
        console.error(e);
        showError('An error occurred trying to fetch cards.');
      }
      finally{
        if(!mounted.current) {
          return;
        }

        setFetchingCards(false);
      }
    };

    if(!selectedLaneId) {
      return;
    }

    getCards();
  }, [rivals, selectedLaneId, showError]);

  const renderTypeSelector = () => {
    const uiDropdownOptions = [];

    uiDropdownOptions.push({
      separator: false,
      selected: false,
      value: 'cards',
      label: 'card',
      onOptionClick: () => handleLinkTypeChange('cards')
    });

    uiDropdownOptions.push({
      separator: false,
      selected: true,
      value: 'battlecards',
      label: 'Battlecard',
      onOptionClick: () => handleLinkTypeChange('battlecards')
    });

    return (
      <div className="ui-dropdown-panel card-link-dropdown">
        <label htmlFor="type-dropdown">
          type
        </label>
        <Dropdown
          id="type-dropdown"
          label=""
          options={uiDropdownOptions}
          selectedValue={linkType}
          className="btn secondary"
          containerClass="ui-dropdown--left"
          alignMenuLeft={true} />
      </div>
    );
  };

  const renderCards = laneId => {
    if(fetchingCards) {
      return <Spinner key="cards-spinner" className="loading-spinner" />;
    }

    const key = `cards-for-lane-${laneId}`;

    return (<div className="card-links-container" key={key}>{
      (cards || []).map(card => {
        const {id, data: {name} = {}} = card;
        const selected = selectedCardId === id;

        return (
          <div className="card-link-container" key={id}>
            <div className="card-link">
              <div onClick={() => handleInsertCardLink(card)}>
                {name || 'Untitled Card'}
              </div>
              <div
                className="eye"
                onClick={() => {handleCardSelected(selected ? null : id);}}>
                <Icon icon="visibility-on" width={20} height={20} />
              </div>
            </div>
            {selected && <div className="card-thumb">
              <CardDisplay
                card={card}
                rival={rivals[selectedBoardId]} />
            </div>}
          </div>
        );
      })}
    </div>);
  };

  const renderBattlecardSelector = () => {
    if(fetchingBattlecards) {
      return <Spinner key="bc-spinner" className="loading-spinner" />;
    }

    if(!battlecards || !battlecards.length) {
      return <div key="no-battlecards-found" className="no-battlecards-found">no battlecards found</div>;
    }

    return (<div className="card-links-container" key="battlecard-selector">{battlecards.map(bc => {
      const {id, title} = bc;

      return (
        <div className="card-link-container" key={id}>
          <div className="card-link">
            <div onClick={() => handleInsertBattkecardLink(bc)}>
              {title || 'Untitled Battlecard'}
            </div>
          </div>
        </div>
      );
    })}
    </div>);
  };

  const getFilteredLanes = allLanes => (allLanes || []).sort((l1, l2) => {
    return sortOrder === 'a-z' ? l1.name.toLowerCase().localeCompare(l2.name.toLowerCase()) : l1.viewOrder - l2.viewOrder;
  }).reduce((acc, ln) => {
    const {name, cards: laneCards} = ln;
    const lowercaseName = (name || '').toLowerCase();

    if((laneNameLowercase && !name.toLowerCase().startsWith(laneNameLowercase)) || (lowercaseName === 'scratchpad') || (!laneCards.length)) {
      return acc;
    }

    acc.push(ln);

    return acc;
  }, []);

  const renderLaneSelector = () => {
    if(!selectedBoardId) {
      return null;
    }

    const rival = rivals[selectedBoardId];

    if(!rival) {
      return null;
    }

    if(fetchingLanes) {
      return <Spinner key="lanes-spinner" className="loading-spinner" />;
    }

    const filterdLanes = getFilteredLanes(lanes);

    return (
      <div key="rendered-lanes" className="card-link-board-selector">
        <div className="card-link-boards-header">
          <div className="card-link-lane-filter">
            <input placeholder="Filter Lanes..." type="text" value={laneName} onChange={handleLaneNameChange} data-testid="filter-lane-input" />
          </div>
          {filterdLanes.length ? (<div className="card-link-boards-sort">
            <Icon icon="sortArrows" width={16} height={16} fill="#aaa" />
            <div className={classNames('sort-selector', {selected: sortOrder !== 'a-z'})} onClick={() => setSortOrder('viewOrder')}>default</div>
            <div className={classNames('sort-selector', {selected: sortOrder === 'a-z'})} onClick={() => setSortOrder('a-z')}>a-z</div>
          </div>) : null}

        </div>
        {!filterdLanes.length ? (<div key="no-lanes-found" className="no-lanes-found">no lanes found</div>) : null}
        <div className="card-link-lanes">
          {filterdLanes.reduce((acc, ln) => {
            const {id, name} = ln;
            const selected = selectedLaneId === id;

            acc.push(
              <EditorToolbarCardLinkDisclosure
                dataTestId="card-link-lane-disclosure"
                key={id}
                id={id}
                title={name}
                open={selected}
                onClick={() => setSelectedLaneId(!selected ? id : null)} />
            );

            if(selected) {
              acc.push(renderCards(id));
            }

            return acc;
          }, [])}

        </div>
      </div>
    );
  };

  const renderBoardSelector = () => {
    const linkingCards = linkType === 'cards';

    return (
      <div className="card-link-board-selector">
        <div className="card-link-boards-header">
          <label htmlFor="filter-boards-input">
            boards
          </label>
          <div className="card-link-boards-filter">
            <input
              placeholder="Filter Boards..."
              id="filter-boards-input"
              type="text"
              value={boardName}
              onChange={handleBoardNameChange} />
          </div>
        </div>
        <div className="card-link-boards">
          {sortedRivals.reduce((acc, r) => {
            const {id, name, profile} = r;
            const {visibleBattlecardsCount, cardsCount} = profile;

            if(boardNameLowercase && !name.toLowerCase().startsWith(boardNameLowercase)) {
              return acc;
            }

            if(linkingCards) {
              if(!cardsCount) {
                return acc;
              }
            }
            else if(!visibleBattlecardsCount) {
              return acc;
            }

            const selected = selectedBoardId === r.id;

            acc.push(
              <EditorToolbarCardLinkDisclosure
                dataTestId="card-link-board-disclosure"
                key={id}
                id={id}
                title={name}
                open={selected}
                onClick={() => setSelectedBoardId(!selected ? id : undefined)} />
            );

            if(selected) {
              acc.push(linkingCards ? renderLaneSelector() : renderBattlecardSelector());
            }

            return acc;
          }, [])}
        </div>
      </div>
    );
  };

  const renderLinkTextInput = () => {
    return (
      <>
        <label htmlFor="link-text-input">
          Link Text
        </label>
        <div className="card-link-boards-filter">
          <input
            id="link-text-input"
            type="text"
            placeholder={linkTextPlaceholderText}
            value={linkText}
            onChange={handleOnChangeLinkText} />
        </div>
      </>
    );
  };

  const renderURLInput = () => {
    return (
      <>
        <label htmlFor="url-text-input">
          URL
        </label>
        <div className="card-link-boards-filter">
          <input
            id="url-text-input"
            type="text"
            ref={urlRef}
            placeholder={urlTextPlaceholderText}
            value={urlText}
            onChange={handleOnChangeUrlText} />
        </div>
      </>
    );
  };

  const klueLinkSelected = selectedTab === 'klueLink';
  const {isValid, needsScheme, href} = isValidLinkUrl(urlText, {allowEmail});
  const disableInsert = (!targetImage && !(linkText?.trim().length)) || !(urlText?.trim().length) || !isValid;

  return (
    <div className="modal-editor-card-link-selector" style={{top, left}}>
      <div className="card-link-selector-tabs">
        <div className={classNames('card-link-selector-tab', {selected: !klueLinkSelected})} onClick={() => setSelectedTab('link')}>
          <Icon icon="link" width={40} height={40} />
        </div>
        <div className={classNames('card-link-selector-tab', {selected: klueLinkSelected})} onClick={() => setSelectedTab('klueLink')}>
          <Icon icon="klueLink" width={40} height={40} />
        </div>
      </div>
      {klueLinkSelected
        ? (<div className="card-link-tab-content">
          <h4>Search for Klue Links</h4>
          {error && <div ref={errorRef} className="card-link-selector-error">{error}</div>}
          <div className="card-link-board-selector">
            {!targetImage
              ? (
                <>
                  <div className="card-link-boards-header">
                    {renderLinkTextInput()}
                  </div>
                  <div className="link-text-info">if you leave 👆 blank, the card title will be used.</div>
                </>
              )
              : null}
          </div>
          {renderTypeSelector()}
          {renderBoardSelector()}
        </div>)
        : (
          <div className="card-link-tab-content">
            <h4>Insert Link</h4>
            <div className="card-link-board-selector">
              <div className="card-link-boards-header">
                {renderURLInput()}
              </div>
              {!targetImage ? (<div className="card-link-boards-header">
                {renderLinkTextInput()}
              </div>) : null}
              <div className="card-link-buttons">
                <button
                  className={classNames('card-link-button', {'card-link-button-disabled': disableInsert})}
                  onClick={() => handleInsertLink(needsScheme, href)}>
                  {isUpdate.current ? 'Update' : 'Insert'}
                </button>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

EditorToolbarCardLinkSelector.contextTypes = {
  api: PropTypes.object.isRequired,
  utils: PropTypes.object.isRequired
};

EditorToolbarCardLinkSelector.propTypes = {
  onCloseKlueLink: PropTypes.func,
  onInsertLink: PropTypes.func,
  top: PropTypes.number,
  left: PropTypes.number,
  rivals: PropTypes.object,
  text: PropTypes.string,
  url: PropTypes.string,
  targetImage: PropTypes.object,
  tab: PropTypes.string,
  editor: PropTypes.object,
  allowEmail: PropTypes.bool
};

EditorToolbarCardLinkSelector.defaultProps = {
  onCloseKlueLink() {},
  onInsertLink() {},
  top: 0,
  left: 0,
  rivals: {},
  text: '',
  url: '',
  targetImage: null,
  tab: 'link',
  editor: null,
  allowEmail: true
};

const clickOutsideConfig = {
  handleClickOutside: () => EditorToolbarCardLinkSelector.handleClickOutside
};

// used in the test file, for some reason, enzyme has a problem getting the wrapped component from onClickOutside HOC
export {EditorToolbarCardLinkSelector as WrappedEditorToolbarCardLinkSelector};
export default onClickOutside(EditorToolbarCardLinkSelector, clickOutsideConfig);

