export function wrapBuilder(functionName) {
  return ChildBuilder => {
    return class extends React.Component {

      static propTypes = {
        onChange: PropTypes.func.isRequired,
        defaultDomain: PropTypes.string,
        formula: PropTypes.string,
        currentFormula: PropTypes.string
      };

      static defaultProps = {
        onChange() {},
        defaultDomain: null,
        formula: null,
        currentFormula: null
      };

      handleChildChanged = formula => {
        const {onChange} = this.props;

        onChange(`${functionName}(${formula})`);
      };

      render() {
        const {defaultDomain, formula, currentFormula} = this.props;

        return (<ChildBuilder
          onChange={this.handleChildChanged}
          defaultDomain={defaultDomain}
          funcName={functionName}
          formula={formula}
          currentFormula={currentFormula} />);
      }

    };
  };
}
