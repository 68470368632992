/* eslint-disable react/no-multi-comp */
import {useState, useEffect, useReducer, useRef, useCallback} from 'react';
import classNames from 'classnames';
import Modal from '../_modal';
import QueryContainer from './_query';
import SubscriptionSupport from './_subscription_support';

import {
  metabaseQueryUpdate as metabaseQueryUpdate2,
  metabaseQueryCreate as metabaseQueryCreate2,
  metabaseQueryDelete as metabaseQueryDelete2
} from '../../modules/api/metabaseQueries';
import useHasUnsavedData from './_use_has_unsaved_data';
import {profileUpdate} from '../../modules/api/profiles';

const isCustomQuery = ({userQuery = {}}) => (_.isEmpty(userQuery));

const isUserQuery = ({userQuery = {}}) => (!_.isEmpty(userQuery));

const initialState = {
  loading: true,
  queries: []
};
const reducer = (state, action) => {
  switch(action.type) {
    case 'START':
      return {
        ...state,
        loading: true
      };
    case 'SUCCESS':
      return {
        loading: false,
        queries: action.payload
      };
    case 'ERROR':
      return {
        loading: false,
        queries: []
      };
    default:
      return state;
  }
};

const QueryBuilder = (
  {
    rivalId,
    rival,
    onClose,
    onHighVolumeQueryStatusChange,
    alertsActive,
    onAlertsActiveUpdate,
    onMetabaseSubscriptionDidUpdate
  },
  context) => {
  const queryStructure = [
    {id: 'must', description: 'Alerts... Must Contain', autoFocus: true},
    {id: 'should', description: 'And... Have at least one of'},
    {id: 'not', description: 'And... Not Include'}
  ];
  const {api: {metabaseQueriesGet}, utils: {dialog}} = context;
  const {iconUrl, name, supportedRival} = rival;
  const [userQueries, userQueriesDispatch] = useReducer(reducer, initialState);
  const [customQueries, customQueriesDispatch] = useReducer(reducer, initialState);
  const [addingNew, setAddingNew] = useState(false);
  const [isDisableHighVolumeAction, setHighVolumeDisabled] = useState(false);
  const [error, setError] = useState(undefined);
  const queryListRef = useRef(null);
  const rivalIsHighVolumeAlerts = rival?.supportedRival?.isHighVolumeAlerts;
  const [setItemValue, hasUnsavedData, resetUnsavedList] = useHasUnsavedData();

  const metabaseQueryUpdate = args => {
    return metabaseQueryUpdate2(args).then(result => {
      onMetabaseSubscriptionDidUpdate({rivalId});

      return result;
    });
  };

  const metabaseQueryCreate  = args => {
    return metabaseQueryCreate2(args).then(result => {
      onMetabaseSubscriptionDidUpdate({rivalId});

      return result;
    });
  };

  const metabaseQueryDelete = args => {
    return metabaseQueryDelete2(args).then(result => {
      onMetabaseSubscriptionDidUpdate({rivalId});

      return result;
    });
  };

  const refreshQueriesList = useCallback(({updateUserQueries = true, updateCustomQueries = true} = {}) => {
    updateUserQueries && userQueriesDispatch({type: 'START'});
    updateCustomQueries && customQueriesDispatch({type: 'START'});
    metabaseQueriesGet(rivalId, (queries = []) => {
      if(updateUserQueries) {
        const updatedUserQueries = queries.filter(isUserQuery);

        userQueriesDispatch({type: 'SUCCESS', payload: updatedUserQueries});
      }

      if(updateCustomQueries) {
        customQueriesDispatch({type: 'SUCCESS', payload: queries.filter(isCustomQuery)});
      }
    });
  }, [metabaseQueriesGet, rivalId]);

  useEffect(() => {
    refreshQueriesList();
  }, [refreshQueriesList]);

  useEffect(() => {
    //clear errors after 5 seconds
    error && setTimeout(() => {setError(undefined);}, 5000);
  }, [error]);

  const handleAddNewQuery = ({userQuery: {must, should, not, case_sensitive = true}}, event) => {
    if(event) { event.preventDefault(); }

    const queriesList = userQueries.queries;
    const metabaseQueryOptions = {
      rivalId,
      userQuery: {
        must,
        should,
        not,
        case_sensitive
      }
    };

    metabaseQueryCreate({metabaseQueryOptions, code: 'AlertsQueryBuilder.addNewQuery'}).then(newQuery => {
      setAddingNew(false);
      queriesList.unshift(newQuery);
      userQueriesDispatch({type: 'SUCCESS', payload: queriesList});
      setItemValue('new', false);

      if(rivalIsHighVolumeAlerts && !alertsActive) {
        onHighVolumeQueryStatusChange(queriesList, 'create');
      }
    }).catch(() => {
      setItemValue('new', true);
      refreshQueriesList({updateUserQueries: true, updateCustomQueries: false});
      setError('We couldn\'t create the Rule, please try again!');
    });
  };

  const handleEditQuery = ({id, userQuery: {must, should, not, case_sensitive}}, event) => {
    if(event) { event.preventDefault(); }

    const queriesList = userQueries.queries;
    const metabaseQueryOptions = {
      id,
      userQuery: {
        must,
        should,
        not,
        case_sensitive
      }
    };

    metabaseQueryUpdate({metabaseQueryOptions, code: 'AlertsQueryBuilder.updateExistingQuery'}).then(editedQuery => {
      const updatedQueries = queriesList.map(query => (query.id === id ? editedQuery : query));

      userQueriesDispatch({type: 'SUCCESS', payload: updatedQueries});
      setItemValue(id, false);
    }).catch(() => {
      setItemValue(id, true);
      refreshQueriesList({updateUserQueries: true, updateCustomQueries: false});
      setError('We couldn\'t update the Rule, please try again!');
    });
  };

  const handleDeleteQuery = ({id, isCustom, event}) => {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const queriesList = !isCustom ? userQueries.queries : customQueries.queries;
    const deleteAction = () => {
      const metabaseQueryOptions = {
        id
      };

      const updatedQueries = queriesList.filter(query => query.id !== id);

      !isCustom && userQueriesDispatch({type: 'SUCCESS', payload: updatedQueries});
      isCustom && customQueriesDispatch({type: 'SUCCESS', payload: updatedQueries});
      metabaseQueryDelete({metabaseQueryOptions, code: 'AlertsQueryBuilder.deleteExistingQuery'}).then(() => {
        if(rivalIsHighVolumeAlerts && !updatedQueries.length) {
          onHighVolumeQueryStatusChange(updatedQueries, 'delete');
        }
        //open the add new query instead of empty state when there are some custom queries

        if(!isCustom && updatedQueries && updatedQueries.length === 0 && customQueries.queries && customQueries.queries.length !== 0) {
          setAddingNew(true);
        }
      }).catch(() => {
        refreshQueriesList({updateUserQueries: !isCustom, updateCustomQueries: isCustom});
        setError('We couldn\'t delete the Rule , please try again!');
      });
    };

    context.utils.dialog.confirm({
      message: 'Are you sure you want to delete this rule?',
      okCallback: deleteAction
    });
  };

  const handleToggleState = ({id, active, isCustom, event}) => {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const queriesList = !isCustom ? userQueries.queries : customQueries.queries;
    const metabaseQueryOptions = {
      id,
      state: active ? 'inactive' : 'active'
    };

    metabaseQueryUpdate({metabaseQueryOptions, code: 'AlertsQueryBuilder.updateExistingQuery'}).then(updatedQuery => {
      const updatedQueries = queriesList.map(query => (query.id === id ? updatedQuery : query));

      !isCustom && userQueriesDispatch({type: 'SUCCESS', payload: updatedQueries});
      isCustom && customQueriesDispatch({type: 'SUCCESS', payload: updatedQueries});
    }).catch(() => {
      refreshQueriesList({updateUserQueries: !isCustom, updateCustomQueries: isCustom});
      setError('We couldn\'t update the Rule, please try again!');
    });
  };

  const handleAddNewRule = e => {
    if(e) {
      e.preventDefault();
    }

    setAddingNew(true);
    queryListRef.current.scrollTo({top: 0, behavior: 'smooth'});
  };

  const handleSupportRequest = e => {
    if(e) {
      e.preventDefault();
    }

    dialog.create({
      id: 'modal-rival-settings',   // calling with same id will just update the contents
      content: (<SubscriptionSupport rival={rival} />),
      _wideMode: true
    });
  };

  const handleHasUnSavedData = callbackFn => () => {
    if(hasUnsavedData()) {
      context.utils.dialog.confirm({
        message: 'You have unsaved rules, are you sure you want to discard them?',
        okCallback: callbackFn
      });
    }
    else {
      callbackFn();
    }
  };

  const closeModal = handleHasUnSavedData(onClose);
  const handleToggleQueries = useCallback(params => async () => {
    const {profile: {id}} = rival;
    // eslint-disable-next-line no-shadow
    const {alertsActive} = params;

    if(rivalIsHighVolumeAlerts) {
      setHighVolumeDisabled(!alertsActive);
    }

    const runOnAlertsActiveUpdate = value => onAlertsActiveUpdate && onAlertsActiveUpdate(value);

    try {
      runOnAlertsActiveUpdate(alertsActive);
      resetUnsavedList();

      await profileUpdate({
        profileOptions: {
          id,
          alertsActive
        }
      });
      //make sure we sync enable/disable state with backend
      if(!alertsActive) {refreshQueriesList();}
    }
    catch{
      setError(`We couldn\'t ${alertsActive ? 'enable' : 'disable'} all queries, please try again!`);
      runOnAlertsActiveUpdate(!alertsActive);
    }
  }, [rival, onAlertsActiveUpdate, resetUnsavedList, refreshQueriesList, rivalIsHighVolumeAlerts]);

  const isAddingDisabled = addingNew || userQueries.loading || customQueries.loading;
  const hasCustomQueries = Boolean(customQueries.queries && customQueries.queries.length);
  const isEmptyQueries = !userQueries.queries.length && !hasCustomQueries;
  const shouldShowEmptyMessage = isEmptyQueries && !addingNew;
  const shouldShowEnableMessageForNonemptyHighVolume = !isEmptyQueries && !addingNew && rivalIsHighVolumeAlerts && !alertsActive;

  return (
    <Modal header={false} padded={false} basic={true} onClose={closeModal} closeOnOutsideClick={false}>
      <div data-testid="query-builder" className="query-builder">
        <div className="query-builder_header">
          <img className="header-image" src={iconUrl} />
          <h1 className="header-text">
            <span className="header-text-name">{name}</span>
            <span> - Alerts Rule Composer</span>
          </h1>
          <p className={classNames('header-error', {'header-error-visible': error})}>{error}</p>
        </div>
        {alertsActive && (
          <div className="queries-titles">
            {queryStructure.map(({id, description}) => (
              <p className="queries-titles-text" key={id}> <b>{description}</b> </p>
            ))}

            <button
              className={classNames('btn button button--small queries-titles-button', {invisible: addingNew})}
              data-tracking-id="queries-titles-button-new-rule"
              onClick={handleAddNewRule}
              disabled={isAddingDisabled}>
              new rule
            </button>
          </div>
        )}
        <div className={classNames('queries-list', {'full-height': !hasCustomQueries})} ref={queryListRef}>

          {addingNew && !userQueries.loading && !shouldShowEmptyMessage && <QueryContainer
            queryStructure={queryStructure}
            onSubmit={handleAddNewQuery}
            editMode={true}
            newQuery={true}
            onDiscard={() => {setAddingNew(false);}}
            queryId="new"
            queryValues={{case_sensitive: true}}
            onToggleHasUnsavedData={setItemValue} />}

          {rivalIsHighVolumeAlerts && shouldShowEmptyMessage && !userQueries.loading && (
            <div className="message">
              <h3>
                Alerts have been disabled for {supportedRival?.name}<br />
                as the current Alert Rules generate too much noise.
              </h3>
              <a
                href="#"
                className="link"
                onClick={handleAddNewRule}>
                Refine Alert Rules to enable {supportedRival?.name} Alerts
              </a>
            </div>
          )}

          {shouldShowEmptyMessage && !userQueries.loading && alertsActive && !rivalIsHighVolumeAlerts && (
            <div className="message">
              <h3>
                Set up Alert Rules to receive relevant intel.<br />
                Tip: you can specify case sensitivity using the cog.
              </h3>
              <a
                href="#"
                className="link"
                onClick={handleAddNewRule}>
                Tell Kluebot what's relevant
              </a>
            </div>
          )}

          {(!alertsActive && !rivalIsHighVolumeAlerts || rivalIsHighVolumeAlerts && isDisableHighVolumeAction || shouldShowEnableMessageForNonemptyHighVolume) && (
            <div className="message">
              <h3>
                Alerts are disabled for {name}
              </h3>

              <button className="button button--medium message__button" onClick={handleToggleQueries({alertsActive: true})}>
                Enable Alerts
              </button>
            </div>
          )}

          {alertsActive && (
            <>
              {userQueries.loading
                ? (<div className="upload-indicator upload-indicator-centered" />)
                : userQueries.queries.map(({id, userQuery, deletedAt, isHighVolumeAlerts}, index) => (
                  <QueryContainer
                    key={id}
                    queryId={id}
                    queryStructure={queryStructure}
                    queryValues={userQuery}
                    isActive={!deletedAt}
                    isHighVolumeAlerts={isHighVolumeAlerts}
                    showLabel={addingNew || index !== 0 || Boolean(deletedAt)}
                    onSubmit={handleEditQuery}
                    onToggleState={event => handleToggleState({id, active: !deletedAt, isCustom: false, event})}
                    onDelete={event => handleDeleteQuery({id, isCustom: false, event})}
                    onToggleHasUnsavedData={setItemValue} />
                ))}
            </>
          )}
        </div>
        {hasCustomQueries && alertsActive && <>
          <div className="queries-titles queries-titles-custom">
            <p className="queries-titles-text">Custom Rules</p>
          </div>
          <div className="queries-list queries-list-custom">
            {
              customQueries.loading
                ? (<div className="upload-indicator upload-indicator-centered" />)
                : customQueries.queries.map(({id, query, deletedAt, isHighVolumeAlerts}, index) => (
                  <QueryContainer
                    key={id}
                    queryId={id}
                    queryStructure={queryStructure}
                    customQueryValue={query}
                    isCustom={true}
                    isActive={!deletedAt}
                    isHighVolumeAlerts={isHighVolumeAlerts}
                    showLabel={index !== 0 || Boolean(deletedAt)}
                    onSubmit={handleEditQuery}
                    onToggleState={event => handleToggleState({id, active: !deletedAt, isCustom: true, event})}
                    onDelete={event => handleDeleteQuery({id, isCustom: true, event})} />
                ))
            }
          </div>
        </>}
        <div className="query-builder_footer">
          {alertsActive && (
            <a href="#" className="link link__bold" onClick={handleSupportRequest}>Support Request</a>
          )}
          <div className="query-builder_footer--justifyEnd">
            {alertsActive && (
              <button className="button button--medium  button--disabled"
                onClick={handleToggleQueries({alertsActive: false})}
                data-testid="disable-alerts-button">
                Disable all alerts
              </button>
            )}
            <button className="button button--medium" onClick={closeModal} data-testid="all-done-button">All Done</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

QueryBuilder.propTypes = {
  rivalId: PropTypes.number.isRequired,
  rival: PropTypes.object,
  onClose: PropTypes.func,
  onHighVolumeQueryStatusChange: PropTypes.func,
  onAlertsActiveUpdate: PropTypes.func,
  onMetabaseSubscriptionDidUpdate: PropTypes.func,
  alertsActive: PropTypes.bool
};

QueryBuilder.defaultProps = {
  rival: undefined,
  onClose() {},
  onHighVolumeQueryStatusChange() {},
  onAlertsActiveUpdate() {},
  onMetabaseSubscriptionDidUpdate() {},
  alertsActive: false
};

QueryBuilder.contextTypes = {
  api: PropTypes.object.isRequired,
  utils: PropTypes.object.isRequired
};

export default QueryBuilder;
