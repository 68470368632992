import React, {useState, useEffect} from 'react';

import {Direction} from './constants';
import './styles.css';

const Resizer = ({onResize}) => {
  const [direction, setDirection] = useState('');
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    const handleMouseMove = e => {
      e && e.preventDefault();

      if(!direction) {
        return;
      }

      onResize(direction, e.movementX, e.movementY);
    };

    if(mouseDown) {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseDown, direction, onResize]);

  useEffect(() => {
    const handleMouseUp = () => setMouseDown(false);

    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleMouseDown = dir => () => {
    setDirection(dir);
    setMouseDown(true);
  };

  return (
    <div data-testid="right-bottom" data-tracking-id="card-edit-modal-resize" className="right-bottom" onMouseDown={handleMouseDown(Direction.BottomRight)} />
  );
};

Resizer.propTypes = {
  onResize: PropTypes.func.isRequired
};

export default Resizer;

