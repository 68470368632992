import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

const {default: Icon} = require('./_icon');

import PropTypes from 'prop-types';
import {redirectToV2} from '../modules/route_utils';

const NavigationDropdownCombined = ({groups}, context) => {
  const {
    appData: {v2Host}
  } = context;
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      key="navigation-dropdown-responsive-menu"
      ref={dropdownRef}
      className={classNames('head-dropdown-group', {
        'head-dropdown-group--active': isOpen
      })}>
      <div className="header_action" onClick={toggleDropdown} data-tracking-id="app-nav-bar_responsive-menu">
        <div
          className={classNames(
            'head-settings-trigger head-settings-trigger_responsive-menu',
            {'head-settings-trigger--active': isOpen}
          )}>
          <div data-tracking-id="head-settings-trigger_icon">
            <Icon icon="dotsNine" />
          </div>
        </div>
      </div>
      <div className="nav-dropdown_container">
        <div className="nav-dropdown_group">
          {
        groups.map(({title, items}) => (
          <div className="head-dropdown-grid" key={title}>
            <p className="nav-dropdown_container_header">{title}</p>
            {items.map(({id, label, path, isV2, icon, hasNewBadge, hasBetaBadge, hasUpgradeBadge}) => {
              return (
                <div key={label} className="nav-dropdown_container_item">
                  <a
                    href={isV2 ? `https://${v2Host}${path}` : path}
                    className={classNames(
                      'nav-dropdown_container_item_link head-main-item--truncate',
                      {
                        'nav-dropdown_container_item_link--active':
                        location?.pathname?.startsWith(path)
                      }
                    )}
                    data-tracking-id={`app-nav-bar_${id}`}
                    data-test-id={`app-nav-bar_${id}`}
                    onClick={event => {
                      event && event.preventDefault();

                      if(isV2) {
                        redirectToV2({v2Host, v2Path: path});
                      }
                      else {
                        history.push(path);
                      }

                      setIsOpen(false);
                    }}>
                    <div className="head-main-item_icon head-main-item_icon--left ">
                      <Icon icon={icon} />
                    </div>
                    <span className="head-main-item_label">
                      <span>{label}</span>
                    </span>
                    {hasNewBadge && (
                    <div className="head-main-item_badge">
                      <span>NEW</span>
                    </div>
                    )}
                    {hasUpgradeBadge && (
                    <div className="head-main-item_badge variant-purple">
                      <span>UPGRADE</span>
                    </div>
                    )}
                    {hasBetaBadge && (
                    <div className="head-main-item_badge variant-purple">
                      <span>BETA</span>
                    </div>
                    )}
                  </a>
                </div>
              );
            })}
          </div>
        ))
      }</div>
      </div>
    </div>
  );
};

NavigationDropdownCombined.contextTypes = {
  appData: PropTypes.object.isRequired
};
NavigationDropdownCombined.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
          isV2: PropTypes.bool.isRequired
        })
      ).isRequired
    })
  ).isRequired
};

export default NavigationDropdownCombined;
