import URI from 'urijs';

import {isValidUrl} from '../modules/url_utils';
import {availableSources} from '../modules/constants/company_settings';
import {preferenceTypes} from '../modules/constants/alerts';

const findValidSource = domain => {
  for(const source of Object.keys(availableSources)) {
    if(availableSources[source].has(domain)) {
      return source;
    }
  }
};

export const getUrlSourceName = url => url.replace(/^(?:https?:\/\/)?(?:www-?\d*\.)?/i, '');

export const extractValidSourceFromUrl = url => {
  if(!isValidUrl(url)) {
    return null;
  }

  const domain = URI(url).domain().toLowerCase();

  return findValidSource(domain);
};

export const companyImagesFromRivalAndSrc = (rival, src) => {
  if(_.isEmpty(rival) && !src) {
    return null;
  }

  const fallback = 'https://www.google.com/s2/favicons';
  const imgSrc = [];

  if(src) {
    imgSrc.push(src);
  }
  else if(rival.iconUrl) {
    imgSrc.push(rival.iconUrl);
  }
  else {
    // URI.js expects a scheme (e.g. https://) as part of the image url
    const rivalDomain = rival.url ? URI(rival.url).hostname().toLowerCase() : '';

    if(rival.iconUrl) {
      imgSrc.push(rival.iconUrl);
    }

    if(rivalDomain) {
      imgSrc.push(`https://logo.clearbit.com/${rivalDomain}`);
      imgSrc.push(`${fallback}?domain=${rivalDomain}`);
    }
  }

  imgSrc.push(`${fallback}?domain=null`);

  return imgSrc;
};

const getBlocklistConfirmationMessage = (value, fromAlerts = true) => (<div>
  <p>Adding <strong>{value}</strong> to your blocklist will stop the collection of <em>new</em> alerts from this source.</p>
  <p>This will not remove existing alerts.</p>
  {fromAlerts && (
  <p>
    <small><em>You can change this at any time. Click <strong>Alert Settings</strong> to update your settings.</em></small>
  </p>)}
</div>);

const getFavoritesConfirmationMessage = (value, fromAlerts = true) => (<div>
  <p>Adding <strong>{value}</strong> to your favorites will always choose this source over
    others when we find more than one version of an alert.</p>
  <p>This will not modify existing alerts.</p>
  {fromAlerts && (
  <p>
    <small><em>You can change this at any time. Click <strong>Alert Settings</strong> to update
      your settings.</em></small>
  </p>
  )}
</div>);

export const getAlertPreferenceAlreadyExistMessage = type => (type === preferenceTypes.BLOCKLIST
  ? 'This site is already on the blocklist.'
  : 'This site has already been added to your favorites.');

export const getAlertPreferencesTypeConfirmationMessageBody = (value, type, fromAlerts = true) => ((type === preferenceTypes.BLOCKLIST)
  ? getBlocklistConfirmationMessage(value, fromAlerts)
  : getFavoritesConfirmationMessage(value, fromAlerts));

export const getAlertPreferencesTypeConfirmationMessageTitle = type => (type === preferenceTypes.BLOCKLIST
  ? 'Hide all future alerts from this site?'
  : 'Always prefer this source over others?');

export const getAlertPreferencesTypeConfirmationButtonTitle = type => (type === preferenceTypes.BLOCKLIST
  ? 'Yes - Add to Blocklist'
  : 'Yes - Add to Favorites');
