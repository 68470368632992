import DigestCompetitors from './_digest_competitors';
import MenuWrapper from './_menu_wrapper';
import Icon from './_icon';
import {digestHeaderInputs, getDefaultOverviewCopy} from '../modules/digest_utils';

import classNames from 'classnames';
import {Link} from 'react-router-dom';
import moment from 'moment';

const DigestArchivesItem = ({digest, rivals, rivalsForItem, onDelete, showStats}) => {
  if(_.isEmpty(digest)) {
    return null;
  }

  const subjectPlaceholder = getDefaultOverviewCopy({
    digest,
    rivals,
    rivalsForItem,
    plainText: true,
    inputType: digestHeaderInputs.SUBJECT
  });
  const {
    createdAt, archivedAt, locked, id, manual,
    emailsOpenCount = 0, emailsUniqueOpenCount = 0, emailsUniqueClickCount = 0, emailsSentCount = 0,
    emailsDeliverCount = 0, useNewStats = false, title = '', favoritesLiveCount = 0
  } = digest;
  const digestItemClasses = classNames('dialogue compact digest-item', {
    'digest-item--custom': !archivedAt,
    'digest-item--locked': locked
  });
  const sentDate = moment(archivedAt || createdAt);
  let digestMenuRegion;
  let digestLabelRegion;
  let digestLockedRegion;
  let digestStatsRegion;

  if(onDelete) {
    digestMenuRegion = (
      <div className="ui--toolbar">
        <MenuWrapper disableOnClickOutside={true}>
          <a href="#" className="menu-wrapper_items_item" onClick={e => onDelete({id}, e)}>
            Delete This Digest
          </a>
        </MenuWrapper>
      </div>
    );
  }

  if(!archivedAt && manual) {
    digestLabelRegion = (
      <strong>Custom Digest</strong>
    );
  }
  else if(!archivedAt) {
    // shouldn't show up here, but just in case...
    digestLabelRegion = (
      <strong>Draft</strong>
    );
  }
  else if(useNewStats && emailsDeliverCount > 0) {
    digestLabelRegion = (
      <strong>
        {emailsDeliverCount} Delivered
      </strong>
    );
  }
  else {
    digestLabelRegion = (
      <strong>
        Delivered
      </strong>
    );
  }

  if(digest.locked) {
    digestLockedRegion = (
      <Icon icon="lock" className="digest-item_icon" width="18" height="18" />
    );
  }

  // Do not show if ALL values are zero
  if(showStats && (emailsSentCount || (useNewStats && emailsDeliverCount)) && Math.max(emailsOpenCount, emailsUniqueOpenCount, emailsUniqueClickCount)) {
    const showZero = useNewStats ? emailsDeliverCount === 0 : false;
    const total = useNewStats ? emailsDeliverCount : emailsSentCount;

    const _getRate = count => `${showZero ? 0 : Math.round((count / total) * 100)}%`;

    digestStatsRegion = [(<div key="opens" className="digest-item--stats"><strong>Opens</strong> {showZero ? 0 : emailsOpenCount}</div>),
      (<div key="open-rate" className="digest-item--stats"><strong>Open rate</strong> {_getRate(emailsUniqueOpenCount)}</div>),
      (<div key="click-rate" className="digest-item--stats"><strong>Click-thrus</strong> {_getRate(emailsUniqueClickCount)}</div>)];
  }

  return (
    <div id={`digest_${id}`} className={digestItemClasses}>
      {digestMenuRegion}
      <h3>
        <Link to={`/digest/${id}`}>{title || subjectPlaceholder}</Link>
      </h3>
      <DigestCompetitors
        storyCount={favoritesLiveCount}
        rivals={rivalsForItem}
        showItemCount={true} />
      <footer>
        <div className="digest-item--sent">
          {digestLockedRegion}
          {digestLabelRegion} <time dateTime={sentDate.format()} title={sentDate.format('LLLL')}>
            {sentDate.format('LL')}
          </time>
        </div>
        {digestStatsRegion}
      </footer>
    </div>
  );
};

DigestArchivesItem.propTypes = {
  digest: PropTypes.object,
  rivals: PropTypes.object.isRequired,
  rivalsForItem: PropTypes.arrayOf(PropTypes.object),
  showStats: PropTypes.bool,
  onDelete: PropTypes.func
};

DigestArchivesItem.defaultProps = {
  digest: null,
  rivals: {},
  rivalsForItem: [],
  showStats: false,
  onDelete: null
};

export default DigestArchivesItem;
