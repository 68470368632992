import {trackLinkClickFromTarget} from '../modules/card_utils';
import {CARD_BODY_CLASSNAME} from '../modules/constants/editor';
import Icon from './_icon';

class ContentZoom extends React.Component {

  static propTypes = {
    children: PropTypes.node,
    id: PropTypes.number
  };

  static defaultProps = {
    children: null,
    id: 0
  };

  componentDidMount() {
    console.log('ContentZoom.componentDidMount: props: %o', this.props);

    this.init();
  }

  componentDidUpdate() {
    this.init();
  }

  componentWillUnmount() {
    this.unmountModal();
  }

  setRef = el => this.elementRef = el;

  unmountModal = () => {
    const zoomDOMContainer = document.querySelector('.contentzoom-modal');

    if(zoomDOMContainer) {
      zoomDOMContainer.parentNode.removeChild(zoomDOMContainer);
      document.removeEventListener('keyup', this.handleWindowKeyUp);
    }
  };

  init = () => {
    if(this.elementRef) {
      const {id: wrapperId} = this.props;
      const images = this.elementRef.querySelectorAll(':not(.contentzoom) > img[src]');
      const tables = this.elementRef.querySelectorAll('div > .table-scrollwrap:not(.contentzoom)');

      images.forEach((image, idx) => {
        return image.addEventListener('load', () => this.initImage(image, `contentzoom${wrapperId}-img${idx}`), {once: true});
      });
      tables.forEach((table, idx) => this.initTable(table, `contentzoom${wrapperId}-table${idx}`));
    }
  };

  handleZoomCloseClick = event => {
    const {target} = event;

    if(target) {
      const content = target.closest('.contentzoom-modal-content');
      const clickedCloseIcon = target.matches('.contentzoom-modal-close, .contentzoom-modal-close *');

      if(content && (content.matches('.contentzoom-modal--selector .contentzoom-modal-content')) && !clickedCloseIcon) {
        return;
      }

      this.unmountModal();
    }
  };

  handleWindowKeyUp = event => {
    const {key} = event;

    if(key === 'Escape') {
      return this.unmountModal();
    }
  };

  renderZoomContainer = target => {
    const targetData = {
      image: target.getAttribute('data-contentzoom-imgsrc'),
      selector: target.getAttribute('data-contentzoom-selector')
    };
    const type = [...Object.entries(targetData)].reduce((acc, curr) => (acc || curr[1] && curr[0]), '');
    const cardWrapper = target.closest(`.${CARD_BODY_CLASSNAME}`);
    let zoomDOMContainer = document.querySelector('.contentzoom-modal');

    if(!zoomDOMContainer) {
      zoomDOMContainer = document.createElement('div');
      zoomDOMContainer.classList.add('contentzoom-modal', `contentzoom-modal--${type}`);
      document.body.appendChild(zoomDOMContainer);
    }

    let jsx;
    let el;

    if(type === 'image') {
      const img = target.closest('img');

      // Use/clone existing <img/> if image source is the same
      if(img && (img.src === targetData.image)) {
        el = img;
        el.setAttribute('loading', 'lazy');
      }
      else {
        jsx = (<img src={targetData.image} className="contentzoom-modal-img" loading="lazy" />);
      }
    }
    else {
      el = target.querySelector(targetData.selector);
    }

    const uiContainer = (
      <div className="contentzoom-modal-content card-static-html_body">
        {(type === 'selector') && (<Icon icon="close" className="contentzoom-modal-close" />)}
        {jsx}
      </div>
    );

    ReactDOM.render(uiContainer, zoomDOMContainer, () => {
      el && zoomDOMContainer.querySelector('.contentzoom-modal-content').appendChild(el.cloneNode(true));
      zoomDOMContainer.querySelector('.contentzoom-modal-content').addEventListener('click', event => {
        const targ = event?.target;
        const href = targ?.href;

        if(!targ.matches('a') || !href) {
          return;
        }

        targ.classList.contains('card-external-link') && targ.setAttribute('target', '_blank');

        const rivalName = cardWrapper.getAttribute('data-ga-rival');
        const laneName = cardWrapper.getAttribute('data-ga-lane');
        const cardTitle = cardWrapper.getAttribute('data-ga-card');

        trackLinkClickFromTarget(targ, {cardData: {rivalName, laneName, cardTitle}});
      });
      zoomDOMContainer.classList.add('contentzoom-modal--show', `contentzoom-modal--${type}`);
      zoomDOMContainer.scrollTop = 0;
      zoomDOMContainer.addEventListener('click', this.handleZoomCloseClick);
      document.addEventListener('keyup', this.handleWindowKeyUp);
    });
  };

  initImage = (image, id) => {
    if(!image) {
      return;
    }

    image.setAttribute('loading', 'lazy');

    const {parentNode} = image;
    const isLinked = Boolean(parentNode && parentNode.href);
    const {width = 0, height = 0} = image.getBoundingClientRect();
    const {naturalWidth = 0, naturalHeight = 0} = image;
    const {className = ''} = image;
    const zoomEnabled = !(className.includes('no-zoom')) && (((naturalWidth - 50) > width) && ((naturalHeight - 50) > height));

    if(!(zoomEnabled || isLinked)) {
      return;
    }

    let div;

    if(!parentNode || parentNode.nodeType === Node.DOCUMENT_FRAGMENT_NODE) {
      // For images with no parentNode or when parent is a documetFragment, wrap the image in a <div/> (and later discard) for DOM manipulation
      div = document.createElement('div');

      div.append(image);

      if(parentNode && (parentNode.nodeType === Node.DOCUMENT_FRAGMENT_NODE)) {
        parentNode.appendChild(div);
      }
    }
    else if((parentNode.nodeType === Node.ELEMENT_NODE) && parentNode.matches('span.contentzoom-img-wrap')) {
      return;
    }

    const {href} = image.parentNode;
    const isLinkedToImage = isLinked && /\.(jpg|jpeg|png|gif|png){1}$/i.test(href);
    const isSmall = Math.min(Math.floor(width), Math.floor(height)) <= 44;
    const node = isLinked ? image.parentNode : image;
    const parent = node.parentNode;
    const doc = node.ownerDocument;
    const wrapper = doc.createElement('span');
    const _setLinkAttrs = link => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noreferrer noopener');
    };

    let zoomLink;
    let imgLink;

    wrapper.className = image.className;
    wrapper.classList.add('contentzoom', 'contentzoom-img-wrap');
    isSmall && wrapper.classList.add('contentzoom--no-icon');

    image.removeAttribute('class');
    wrapper.appendChild(image.cloneNode(false));

    if(zoomEnabled) {
      zoomLink = isLinkedToImage ? node.cloneNode(false) : doc.createElement('a');
      wrapper.setAttribute('data-contentzoom-imgsrc', isLinkedToImage ? href : image.src);
      wrapper.setAttribute('data-contentzoom-id', `${id}`);
      zoomLink.removeAttribute('href');
      _setLinkAttrs(zoomLink);
      zoomLink.classList.add('contentzoom-action', 'contentzoom-action-zoom-in');
    }

    if(isLinked && !isLinkedToImage) {
      if(isSmall && zoomEnabled) {
        zoomLink.setAttribute('href', href);
      }

      imgLink = node.cloneNode(false);
      imgLink.setAttribute('href', href);
      imgLink.classList.add('contentzoom-action', 'contentzoom-action-link');
      _setLinkAttrs(imgLink);
    }

    zoomLink && wrapper.appendChild(zoomLink);
    imgLink && wrapper.appendChild(imgLink);
    parent && parent.replaceChild(wrapper, node);

    if(!parentNode) {
      return parent.firstChild;
    }

    if(parentNode && (parentNode.nodeType === Node.DOCUMENT_FRAGMENT_NODE)) {
      parentNode.firstChild.replaceWith(wrapper);
    }
  };

  initTable = (tableWrapper, id) => {
    const table = tableWrapper && tableWrapper.querySelector('table');
    const zoomThreshold = 10;

    if(table && !tableWrapper.querySelector('.zoomwrapper')) {
      const {nextSibling = null} = table;
      const zoomWrapper = document.createElement('div');

      zoomWrapper.classList.add('zoomwrapper');
      zoomWrapper.appendChild(table);
      tableWrapper.insertBefore(zoomWrapper, nextSibling);

      const {width: wrapperWidth} = tableWrapper.getBoundingClientRect();
      const {width: tableWidth} = table.getBoundingClientRect();

      if(tableWidth > (wrapperWidth + zoomThreshold)) {
        tableWrapper.classList.add('contentzoom', 'contentzoom--selector');
        zoomWrapper.classList.add('contentzoom-action-zoom-in');
        tableWrapper.setAttribute('data-contentzoom-selector', 'table');
        zoomWrapper.setAttribute('data-contentzoom-id', `${id}`);
      }
    }
  };

  handleZoomClick = event => {
    const {target} = event;
    const isZoomClick = target && target.closest('.contentzoom-action-zoom-in');

    if(isZoomClick) {
      if(!target.href) {
        event.preventDefault();
        event.stopPropagation();
      }

      this.renderZoomContainer(target.closest('.contentzoom'));
    }
  };

  render() {
    const {children} = this.props;

    return children && (
      <div ref={this.setRef} onClick={this.handleZoomClick}>
        {children}
      </div>
    );
  }

}

export default ContentZoom;
