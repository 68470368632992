import PostAvatar from './_post_avatar';
import PostSummary from './_post_summary';
import AttachmentBox from './_attachment_box';
import CommentBox from './_comment_box';
import LikeBox from './_like_box';
import LikeButton from './_like_button';
import Dropdown from './_dropdown';

import {getRivalMatches, redirectToKlueUrl} from '../modules/post_utils';
import {userCanCurate, userIsAdmin, userIsKluebot} from '../modules/roles_utils';
import {analyticsTrack} from '../modules/analytics_utils';
import {uiMessages} from '../modules/constants/ui';

import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import {Link, withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {postUnpublish} from '../modules/api/posts';

class Post extends React.PureComponent {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    company: PropTypes.object,
    rivals: PropTypes.object,
    showComments: PropTypes.bool,
    profileMode: PropTypes.bool,
    profile: PropTypes.object,
    postId: PropTypes.number,
    alertId: PropTypes.string,
    visibleAt: PropTypes.string,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    posterId: PropTypes.number,
    viaUserId: PropTypes.number,
    viaUserName: PropTypes.string,
    viaUserEmail: PropTypes.string,
    viaEmail: PropTypes.bool,
    viaSlack: PropTypes.bool,
    viaTeams: PropTypes.bool,
    linkToFullPost: PropTypes.bool,
    attachments: PropTypes.arrayOf(PropTypes.object),
    competitors: PropTypes.arrayOf(PropTypes.string),
    posts: PropTypes.object,
    commentCount: PropTypes.number,
    commentId: PropTypes.number,
    commentTitle: PropTypes.string,
    commentBody: PropTypes.string,
    commentTimestamp: PropTypes.string,     // ISO-8601 UTC date
    likes: PropTypes.array.isRequired,
    feedItemToggled: PropTypes.bool,
    digest: PropTypes.object,
    pinnedComment: PropTypes.object,
    onToggleFeedItem: PropTypes.func,
    onPinnedCommentUpdatedForFavorite: PropTypes.func,
    onUpdateFavorite: PropTypes.func,
    onDeleteFavorite: PropTypes.func,
    updatePostLikes: PropTypes.func.isRequired,
    onPostDelete: PropTypes.func,
    digestMode: PropTypes.bool,
    singlePostMode: PropTypes.bool,
    postIsToggled: PropTypes.bool,
    digestTypesData: PropTypes.arrayOf(PropTypes.object),
    onDigestTypeFavoriteUpdate: PropTypes.func
  };

  static defaultProps = {
    history: {},
    match: {},
    user: null,
    users: {},
    company: null,
    rivals: {},
    pinnedComment: {},
    showComments: false,
    profileMode: false,
    profile: null,
    postId: 0,
    alertId: null,
    visibleAt: null,
    userName: '',
    userEmail: '',
    posterId: 0,
    viaUserId: 0,
    viaUserName: '',
    viaUserEmail: '',
    viaEmail: false,
    viaSlack: false,
    viaTeams: false,
    linkToFullPost: false,
    attachments: [],
    competitors: [],
    posts: {},
    commentCount: 0,
    commentId: 0,
    commentTitle: '',
    commentBody: '',
    commentTimestamp: '',
    feedItemToggled: false,
    digest: null,
    onToggleFeedItem: null,
    onPinnedCommentUpdatedForFavorite: null,
    onUpdateFavorite: null,
    onDeleteFavorite: null,
    onPostDelete: null,
    digestMode: false,
    singlePostMode: false,
    postIsToggled: false,
    digestTypesData: [],
    onDigestTypeFavoriteUpdate() {}
  };

  state = {
    attachmentId: null,
    tmpDeletedTags: [],
    competitors: [],
    likes: null,
    newPinnedComment: null,
    isMakingPinRequest: false
  };

  componentDidMount() {
    // DEBUG
    console.log('Post.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    const {posterId, competitors} = this.props;
    const {requestUser} = this.context.utils;
    const favorite = this._getAssociatedFavorite();

    // make sure poster is requested
    requestUser({userId: posterId});

    // request curator for associate favorite, if present
    if(favorite) {
      requestUser({userId: favorite.userId});
    }

    if(competitors.length) {
      ReactTooltip.rebuild();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _getAssociatedFavorite = () => {
    const {user, digest, postId} = this.props;

    if(_.isEmpty(digest) || _.isEmpty(digest.favorites) || !userCanCurate({user})) {
      return null;
    }

    return digest.favorites.find(f => f.postId === postId);
  };

  _canDeletePost = (user = {}, posterId = 0, viaUserId = 0) => {
    if(_.isEmpty(user)) {
      return false;
    }

    if((posterId === user.id) || (viaUserId === user.id) || userCanCurate({user})) {
      return true;
    }

    return false;
  };

  _getIsLiked = () => {
    const {user, likes} = this.props;

    if(_.isEmpty(user)) {
      return false;
    }

    return likes.some(like => like.userId === user.id);
  };

  updateActiveAttachment = attachmentId => this.setState({attachmentId});

  onPostDeleteClick = event => {
    if(event) {
      event.preventDefault();
    }

    const confirmMessage = 'Are you sure you want to delete this feed item?';

    this.context.utils.dialog.confirm({
      message: confirmMessage,
      okCallback: this.handlePostUnpublish
    });
  };

  redirectToUrl = event => {
    const {target} = event;

    if(target && (target.matches('a[href]:not([href^="#"])') || target.closest('.btn-more, .attachment-list .attachment-source, .attachment-list .attachment-media'))) {
      return;
    }

    if(event) {
      event.preventDefault();
    }

    const {commentId, commentTitle: postTitle, postId, attachments, linkToFullPost} = this.props;

    analyticsTrack({
      type: 'event',
      category: 'Post',
      action: 'view',
      label: `${postTitle || 'Untitled'} (${postId})`
    });

    if(linkToFullPost && !(target && target.href)) {
      return redirectToKlueUrl(`/feed/posts/${postId}`, postId);
    }

    const attachment = attachments.find(a => (a.commentId === commentId));
    const url = (attachment && attachment.url) || (target.href || target.parentNode.href) || null;

    return url && redirectToKlueUrl(url, postId);
  };

  handleUpdateLikes = data => {
    const {postId, updatePostLikes} = this.props;

    updatePostLikes({postId, likes: data});
  };

  handlePostUnpublish = () => {
    // animate deletion
    const post = ReactDOM.findDOMNode(this);
    const {commentTitle: postTitle, postId, onPostDelete} = this.props;

    post.classList.toggle('off');

    setTimeout(() => {
      // prune post from feed immediately w/out waiting for server response (see #651)
      onPostDelete && onPostDelete(postId);
    }, 660);

    // update server
    postUnpublish({postId}).then(() => {
      if(this._isMounted) {
        // DEBUG
        console.log('Post.handlePostUnpublish: post #%o unpublished', postId);

        analyticsTrack({
          type: 'event',
          category: 'Post',
          action: 'unpublish post',
          label: `${postTitle || 'Untitled'} (${postId})`
        });
      }
    });
  };

  handleFeedItemToggle = event => {
    if(event) {
      event.preventDefault();
    }

    const {target} = event;

    // skip redirect on URLs embedded in comment summary, but capture all other clicks
    if(target.classList.contains('btn-more')) {
      event.stopPropagation();

      return;
    }

    this.toggleFeedItem();
  };

  handleCompetitorProfileVisitClick = profileId => {
    const {history, user} = this.props;

    return history.push(`/profile/${profileId}/${userCanCurate({user}) ? 'edit' : 'view'}`);
  };

  handleCompetitorTagDeleteClick = rivalName => {
    const {commentTitle: postTitle, postId, competitors} = this.props;
    const targetRival = rivalName.toLowerCase();

    // optimistic update
    this.setState(prevState => ({
      competitors: (competitors || []).filter(el => el.toLowerCase() !== targetRival),
      tmpDeletedTags: prevState.tmpDeletedTags.concat(targetRival)
    }));

    // update server
    this.context.api.postCompetitorTagUpdate(postId, {deleteTags: [rivalName]}, () => {
      if(this._isMounted) {
        // DEBUG
        console.log('Post.handleCompetitorTagDelete: competitor #%o deleted for #%o', rivalName, postId);

        analyticsTrack({
          type: 'event',
          category: 'Post',
          action: 'delete competitor tag',
          label: `${postTitle || 'Untitled'} (${postId})`
        });
      }
    });
  };

  toggleFeedItem = () => {
    const {postId, postIsToggled, commentTitle, commentBody, commentId, commentTimestamp, attachments, onToggleFeedItem, linkToFullPost = false} = this.props;
    const firstAttachmentId = (attachments.find(a => (a.commentId === commentId)) || {}).id;
    const postData = postIsToggled ? null : {
      postId,
      commentTitle,
      commentBody,
      commentId,
      commentTimestamp,
      attachments,
      attachmentId: firstAttachmentId,
      linkToFullPost
    };

    onToggleFeedItem(postData);
  };

  handlePinClick = comment => {
    this.setState({isMakingPinRequest: true});

    const {postId} = this.props;
    const {id} = comment;

    this.context.api.postCompetitorTagUpdate(postId, {pinnedCommentId: id}, () => {
      this.setState({newPinnedComment: comment, isMakingPinRequest: false});
      this.handlePinnedCommentUpdatedForFavorite();
    });
  };

  // API accepts the value 0 when reset the pinned comment to empty
  updatePinnedComment = comment => {
    this.setState({newPinnedComment: comment || {id: 0}});
    this.handlePinnedCommentUpdatedForFavorite();
  };

  handlePinnedCommentUpdatedForFavorite = () => {
    const favorite = this._getAssociatedFavorite();

    if(favorite) {
      const {onPinnedCommentUpdatedForFavorite} = this.props;

      if(typeof onPinnedCommentUpdatedForFavorite === 'function') { onPinnedCommentUpdatedForFavorite(favorite); }
    }
  };

  render() {
    const {
      alertId, postId, commentTitle, commentBody, competitors: propsCompetitors, user, users, company, userName, userEmail, posterId,
      viaUserId, viaUserName, viaUserEmail, linkToFullPost, commentTimestamp, digest, attachments, postIsToggled, commentCount,
      showComments, profile: currentProfile, profileMode, rivals, digestMode, onUpdateFavorite, onDeleteFavorite, singlePostMode,
      likes, viaEmail, viaSlack, viaTeams, pinnedComment, visibleAt, posts, digestTypesData, onDigestTypeFavoriteUpdate
    } = this.props;
    const {competitors: stateCompetitors, tmpDeletedTags, newPinnedComment, isMakingPinRequest} = this.state;
    const competitors = (stateCompetitors && stateCompetitors.length) ? stateCompetitors : propsCompetitors;
    const favorite = this._getAssociatedFavorite();
    const postClasses = classNames('post', {
      bookmarked: Boolean(favorite),
      'post--toggled': postIsToggled,
      'post--single': singlePostMode,
      post_slack: viaSlack, // eslint-disable-line camelcase
      post_teams: viaTeams, // eslint-disable-line camelcase
      post_email: viaEmail  // eslint-disable-line camelcase
    });
    const hasAttachment = attachments && (attachments.length > 0);
    const isLiked = this._getIsLiked();
    const handlePostClick = profileMode
      ? this.handleFeedItemToggle
      : !(singlePostMode && linkToFullPost)
        ? this.redirectToUrl
        : null;
    const allowCommentsToggle = singlePostMode ? false : showComments;
    const hideCommentsList = singlePostMode ? false : true;
    const commentsAreVisible = singlePostMode;
    let replaceCommentsForm = null;

    if(viaSlack) {
      let permalink;

      if(hasAttachment) {
        // TODO: validate that it is in fact a slack url?
        permalink = attachments[0].url;
      }
      else {
        // this should never happen since slack posts should always have a permalink pointing to original slack item.
        // TODO: add airbrake notification
        permalink = '';
      }

      const {title, message, id} = uiMessages({id: 'commentsClosed', messageData: {permalink}}).slack;

      replaceCommentsForm =
        (<div className="comment-form comments-closed" key={`msg-${id}-${postId}`}>
          {title && (<h5>{title}</h5>)}
          {message}
        </div>);
    }

    const extraAttachmentBoxProps = {
      postIsToggled,
      singlePostMode,
      redirectToUrl: handlePostClick
    };

    let extraFavoriteProps = {};
    let postCuratorRegion;
    let poster = (users || {})[posterId];
    const currentPinned = newPinnedComment || pinnedComment;

    if(!poster) {
      // fall back to unknown user if poster isn't found (or is pending/deactivated)
      poster = (Object.values(users || {})).find(u => u.username === 'unknownuser');
    }

    if(digest && user && userCanCurate({user}) && (
      !digest.locked || (
        [digest.userId, digest.lastCuratorId].includes(user.id) || userIsAdmin({user}) || userIsKluebot({userId: user.id, company})
      )
    )) {
      extraFavoriteProps = {
        favorites: digest.favorites,
        onUpdateFavorite,
        onDeleteFavorite
      };
    }

    // When we suppress a company tag we need to filter any competitor that might be suppressed as well
    const notSuppressedCompetitors = competitors.filter(c => !tmpDeletedTags.includes(c));
    const rivalsMatches = getRivalMatches(rivals, notSuppressedCompetitors);

    const commentBox = (
      <CommentBox
        allowCommentsToggle={allowCommentsToggle}
        commentCount={commentCount}
        pinnedComment={currentPinned}
        containerId={postId}
        containerType={'post'}
        digestMode={digestMode}
        hideComments={hideCommentsList}
        replaceCommentsForm={replaceCommentsForm}
        postHasAttachment={hasAttachment}
        rivalMatches={rivalsMatches}
        suggestion="Add a comment, image, and @mention other users..."
        user={user}
        users={users}
        visible={commentsAreVisible}
        handlePinClick={this.handlePinClick}
        updatePinnedComment={this.updatePinnedComment}
        isMakingPinRequest={isMakingPinRequest}
        {...extraFavoriteProps} />
    );

    if(profileMode) {
      Object.assign(extraAttachmentBoxProps, {
        profile: currentProfile
      });
    }

    const competitorsList = notSuppressedCompetitors.map(competitor => {
      // find matching profile id for competitor name (duped from PostAvatar component)

      // TODO: compare by rivalID when backend competitorList field is changed
      const matches = Object.keys(rivals)
        .filter(rivalId => {
          const rivalObj = rivals[rivalId];

          return rivalObj.name.toLowerCase() === competitor.toLowerCase();
        })
        .map(rivalId => rivals[rivalId]);

      const rival = matches.length ? matches[0] : null;

      if(rival && rival.profile) {
        const {profile, iconUrl} = rival;
        const imageClasses = classNames({'competitors-list--draft': profile.isDraft});
        const imageTooltip = `${rival.name}${profile.isDraft ? '<br /><span>(Draft)</span>' : ''}`;
        const isCurator = userCanCurate({user});

        if(isCurator) {
          const uiDropdownOptions = [];
          const uiDropdownButton = (
            <img
              src={iconUrl}
              loading="lazy"
              data-tip={imageTooltip}
              data-offset="{'top': -2, 'left': -1}"
              className={imageClasses}
              onError={event => {
                const {target} = event || {};

                return target && (target.src = '/user-profile-image-backup-circle.svg');
              }} />
          );

          uiDropdownOptions.push({
            value: 'go to',
            label: `Visit ${rival.name} Profile`,
            separator: true,
            icon: 'fa-hand-o-right',
            onOptionClick: () => this.handleCompetitorProfileVisitClick(profile.id)
          });
          uiDropdownOptions.push({
            value: 'del',
            label: 'Delete Company Tag',
            separator: true,
            icon: 'fa-trash-o',
            onOptionClick: () => this.handleCompetitorTagDeleteClick(rival.name)
          });

          return (
            <Dropdown
              key={profile.id}
              options={uiDropdownOptions}
              condensed={true}
              containerClass="ui-dropdown-flush competitor-list--competitor"
              button={uiDropdownButton} />
          );
        }

        return (
          <Link
            key={profile.id}
            to={`/profile/${profile.id}/${isCurator ? 'edit' : 'view'}`}
            className="competitor-list--competitor"
            data-tip={imageTooltip}
            data-offset="{'top': -2, 'left': -1}">
            <img src={iconUrl}
              loading="lazy"
              className={imageClasses}
              onError={event => {
                const {target} = event || {};

                return target && (target.src = '/user-profile-image-backup-circle.svg');
              }} />
          </Link>
        );
      }
    }).filter(Boolean);

    if(favorite) {
      const curator = users[favorite.userId];

      if(!_.isEmpty(curator)) {
        const bylineTime = moment(favorite.createdAt);

        postCuratorRegion = (
          <p className="post-curator">
            <Link to={`/users/${favorite.userId}`} className="digest-avatar post-curator_avatar">
              <img src={curator.imageMed}
                loading="lazy"
                alt={`@${curator.username}`}
                onError={event => {
                  const {target} = event || {};

                  return target && (target.src = '/user-profile-image-backup-circle.svg');
                }} />
            </Link> Saved to <Link to="/digest" className="post-curator_digest-link">
              Intel Digest
            </Link> by <Link to={`/users/${favorite.userId}`} className="post-curator_user-link">
              @{curator && curator.username}
            </Link> on <time
              className="digest-time post-curator_time"
              dateTime={bylineTime.format()}
              title={bylineTime.format('LLLL')}>
              {bylineTime.format('ll')}
            </time>
          </p>
        );
      }
    }

    return (
      <li className={postClasses}>
        <div className="post-container">
          <PostAvatar
            postId={postId}
            posts={posts}
            alertId={alertId}
            visibleAt={visibleAt}
            location={location}
            postTitle={commentTitle}
            poster={poster}
            user={user}
            users={users}
            name={userName}
            email={userEmail}
            digestMode={digestMode}
            posterId={posterId}
            viaUserId={viaUserId}
            viaName={viaUserName}
            viaEmail={viaUserEmail}
            timestamp={commentTimestamp}
            onPostDelete={this.onPostDeleteClick}
            onUpdateFavorite={onUpdateFavorite}
            onDeleteFavorite={onDeleteFavorite}
            isFavorite={Boolean(favorite)}
            digest={digest}
            digestTypesData={digestTypesData}
            onDigestTypeFavoriteUpdate={onDigestTypeFavoriteUpdate}
            canDelete={this._canDeletePost(user, posterId, viaUserId)} />
          <PostSummary
            commentBody={commentBody}
            commentTitle={commentTitle}
            linkToFullPost={linkToFullPost}
            {...extraAttachmentBoxProps} />
          <AttachmentBox
            pinnedComment={currentPinned}
            handlePinClick={this.handlePinClick}
            postTitle={commentTitle}
            postBody={commentBody}
            data={attachments}
            postId={postId}
            onActiveAttachmentUpdate={this.updateActiveAttachment}
            linkToFullPost={linkToFullPost}
            viaEmail={viaEmail}
            viaSlack={viaSlack}
            viaTeams={viaTeams}
            favorite={Boolean(favorite)}
            {...extraAttachmentBoxProps} />
          <div className="board-footer">
            <div className="board-footer-meta">
              {competitorsList.length ? (<div className="competitors-list">
                {competitorsList}
              </div>) : null}
              <LikeBox postId={postId} likes={likes}>
                <LikeButton
                  postId={postId}
                  postTitle={commentTitle}
                  user={user}
                  onUpdate={this.handleUpdateLikes}
                  isLiked={isLiked} />
              </LikeBox>
            </div>
            {commentBox}
          </div>
        </div>
        {postCuratorRegion}
      </li>
    );
  }

}

/**
 * Connect component to specific postId key inside feed.likes object
 *
 * @param feed.feed
 * @param {object} feed redux store object
 * @param {object} props
 *
 * @returns prop map
 */
const mapStateToProps = ({feed}, props) => {
  const postLikes = Object.keys(feed.likes).length && feed.likes[props.postId] || [];

  return {likes: postLikes};
};

const mapActionToProps = dispatch => ({
  updatePostLikes: ({postId, likes}) => dispatch.feed.updatePostLikes({postId, likes})
});

const wrapp = compose(
  withRouter,
  connect(mapStateToProps, mapActionToProps)
);

export default wrapp(Post);
