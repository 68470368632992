import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HierarchicalCheckbox = ({checkboxItems, checkedItems, filter, visible, onDidUpdateCheckedItems}) => {
  const [structuredItems, setStructuredItems] = useState({});
  const [checkedState, setCheckedState] = useState({});
  const [filteredItems, setFilteredItems] = useState({});

  useEffect(() => {
    const structured = Object.keys(checkboxItems).sort().reduce((acc, key) => {
      const [parent, child] = key.split('__');

      if(child) {
        acc[parent] = acc[parent] || {label: '', children: {}};
        acc[parent].children[key] = checkboxItems[key];
      }
      else {
        acc[key] = acc[key] || {label: checkboxItems[key], children: {}};
      }

      return acc;
    }, {});

    setStructuredItems(structured);

    const initialState = {};
    const autoCheck = false; //Array.isArray(checkedItems) && !checkedItems.length;

    Object.keys(structured).forEach(parentKey => {
      const childrenKeys = Object.keys(structured[parentKey].children);

      if(childrenKeys.length) {
        const checkedChildren = childrenKeys.filter(key => autoCheck || checkedItems?.includes(key));

        initialState[parentKey] = checkedChildren.length === childrenKeys.length ? true :
          checkedChildren.length > 0 ? 'indeterminate' : false;
        childrenKeys.forEach(childKey => {
          initialState[childKey] = autoCheck || checkedItems?.includes(childKey);
        });
      }
      else {
        initialState[parentKey] = autoCheck || checkedItems?.includes(parentKey);
      }
    });
    setCheckedState(initialState);
  }, [checkboxItems, checkedItems]);

  useEffect(() => {
    if(filter) {
      const filterLowerCase = filter.toLowerCase();
      const filtered = Object.entries(structuredItems).reduce((acc, [parentKey, {label, children}]) => {
        const parentMatches = label.toLowerCase().includes(filterLowerCase);
        const matchedChildren = Object.entries(children).reduce((childAcc, [childKey, childLabel]) => {
          if(childLabel.toLowerCase().includes(filterLowerCase)) {
            childAcc[childKey] = childLabel;
          }

          return childAcc;
        }, {});

        if(parentMatches || Object.keys(matchedChildren).length > 0) {
          acc[parentKey] = {label, children: parentMatches ? children : matchedChildren};
        }

        return acc;
      }, {});

      setFilteredItems(filtered);
    }
    else {
      setFilteredItems(structuredItems); // No filter, display all
    }
  }, [filter, structuredItems]);

  const handleCheckboxChange = (key, checked) => {
    if(!key.includes('__')) {
      const allChildren = Object.keys(structuredItems[key].children);

      if(!allChildren.length) {
        return onDidUpdateCheckedItems({[key]: checked});
      }

      const childCheckedItems = {};

      allChildren.forEach(childKey => {
        childCheckedItems[childKey] = checked;
      });

      onDidUpdateCheckedItems(childCheckedItems);
    }
    else {
      onDidUpdateCheckedItems({[key]: checked});
    }
  };

  const renderCheckbox = (key, label, isChild = false) => {
    const currentCheckedState = checkedState[key];
    const nextCheckedState = currentCheckedState === 'indeterminate' ? true : !checkedState[key];

    return (
      <div
        className="digest-checkbox"
        key={key}
        title={label}
        style={{marginLeft: isChild ? '20px' : '0px'}}
        onClick={() => handleCheckboxChange(key, nextCheckedState)}>
        <i className={
              classNames('fa digest-checkboxes-checkbox', {
                'fa-solid fa-square-minus': currentCheckedState === 'indeterminate',
                'fa-check-square': currentCheckedState !== 'indeterminate' && currentCheckedState,
                'fa-square-o': !currentCheckedState
              })
            } />
        <label htmlFor={key}>{label}</label>
      </div>
    );
  };

  if(!visible) {
    return null;
  }

  return (
    <div className="digest-checkboxes-container">
      {Object.entries(filteredItems).map(([parentKey, {label, children}]) => (
        <React.Fragment key={parentKey}>
          {renderCheckbox(parentKey, label)}
          {Object.entries(children).map(([childKey, childLabel]) =>
            renderCheckbox(childKey, childLabel, true)
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

HierarchicalCheckbox.propTypes = {
  checkboxItems: PropTypes.object.isRequired,
  checkedItems: PropTypes.array,
  filter: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onDidUpdateCheckedItems: PropTypes.func.isRequired
};

HierarchicalCheckbox.defaultProps = {
  checkedItems: null
};

export default HierarchicalCheckbox;
