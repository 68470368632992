import classNames from 'classnames';

import {cardHealthDays} from './card_templates/_card_freshness_card';

const CardFreshnessTools = ({showBattlecardCardsOnly, onToggleBattlecardCardsOnly, onExitFreshness}) => {
  return (
    <div className="card-freshness-tools">
      <div className="card-freshness-swatches">
        {cardHealthDays.map(({label, health}) => (
          <div className={classNames('freshness-swatch', `${health}`)} key={`${health}`}>
            <div className="freshness-swatch-label">&lt;&nbsp;{label}</div>
          </div>
        ))}
        {cardHealthDays.length ? <div className={classNames('freshness-swatch health-0')} key="health-0">
          <div className="freshness-swatch-label">&gt;&nbsp;{cardHealthDays[cardHealthDays.length - 1].label}</div>
        </div> : null}
      </div>
      <div className="card-freshness-battlecard-cards-checkbox">
        <input
          type="checkbox"
          id="freshness-battlecard-checkbox"
          data-tracking-id="freshness-battlecard-checkbox"
          checked={showBattlecardCardsOnly}
          onChange={onToggleBattlecardCardsOnly} />
        <label
          htmlFor="freshness-battlecard-checkbox"
          data-testid="freshness-battlecard-checkbox"
          data-tracking-id="freshness-battlecard-checkbox-label">
          Show Battlecard Cards Only
        </label>
      </div>
      <div className="toolbar-button toolbar-button--done_freshness" data-tracking-id="card-freshness-exit-button" onClick={onExitFreshness}>
        Exit Freshness
      </div>
    </div>
  );
};

CardFreshnessTools.propTypes = {
  showBattlecardCardsOnly: PropTypes.bool,
  onToggleBattlecardCardsOnly: PropTypes.func,
  onExitFreshness: PropTypes.func
};

CardFreshnessTools.defaultProps = {
  showBattlecardCardsOnly: false,
  onToggleBattlecardCardsOnly() {},
  onExitFreshness() {}
};

export default CardFreshnessTools;
