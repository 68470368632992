const DigestMenuTitle = ({title}) => {
  return (
    <div className="digest-menu-title">
      <span>{title}</span>
    </div>
  );
};

DigestMenuTitle.propTypes = {
  title: PropTypes.string
};

DigestMenuTitle.defaultProps = {
  title: ''
};

export default DigestMenuTitle;
