/* eslint-disable react/no-multi-comp */
import Masonry from 'react-masonry-css';

import SearchResultCard from './_search_result_card';

const getCard = card => {
  const {card_template: template} = card;
  const templateName = template ? template.component_name : null;

  return {...card, ...{templateName}};
};

const breakpointColumnsDefaultValue = {
  default: 5,
  1960: 4,
  1600: 3,
  1300: 2,
  900: 1
};

export const SearchResults = React.memo(props => {
  const {cards, rivals, query, filteredTags, breakpointColumns, user, renderCardMetaLink} = props;

  return (
    <Masonry
      breakpointCols={breakpointColumns}
      className="search-masonry-grid"
      columnClassName="search-masonry-grid--column">
      {cards.map(card => (
        <SearchResultCard
          key={card.id}
          card={getCard(card)}
          query={query}
          filteredTags={filteredTags}
          board={card.board}
          rival={(rivals || {})[card.board.rival_id]}
          renderCardMetaLink={renderCardMetaLink}
          user={user} />
      ))}
    </Masonry>
  );
});

SearchResults.propTypes = {
  rivals: PropTypes.objectOf(PropTypes.object),
  cards: PropTypes.arrayOf(PropTypes.object),
  query: PropTypes.string,
  filteredTags: PropTypes.object,
  breakpointColumns: PropTypes.object,
  user: PropTypes.object,
  renderCardMetaLink: PropTypes.func
};

SearchResults.defaultProps = {
  rivals: {},
  cards: [],
  query: '',
  filteredTags: new Set([]),
  breakpointColumns: breakpointColumnsDefaultValue,
  user: null,
  renderCardMetaLink() {}
};

export default SearchResults;
