import {Link, withRouter} from 'react-router-dom';

const CompanySelectorInfoBox = ({company}) => {
  if(!company) {
    return null;
  }

  const {name, createdAt, profileId = 0} = company;

  if(!profileId) {
    return null;
  }

  const profileLink = profileId ? `/profile/${profileId}/edit` : null;

  return (
    <div className="company-selector-info-box">
      <div>Friendly Reminder ➞ You already created a Board with this Company</div>
      {name && profileLink && (
        <Link to={profileLink} target="_blank">
          <button type="button">{name}</button>
        </Link>
      )}
      {createdAt && <div className="company-selector-info-box_date">{`created ${moment(createdAt).fromNow()}`}</div>}
    </div>
  );
};

CompanySelectorInfoBox.propTypes = {
  company: PropTypes.object
};

CompanySelectorInfoBox.defaultProps = {
  company: null
};

export default withRouter(CompanySelectorInfoBox);
