/*
  Use case example. You can basically copy/paste this into any component.

    handleToolTip: function () {
      var toggleJoyride = this.context.joyride.toggleJoyride;
      var setJoyride = this.context.joyride.setJoyride;
      var steps = [{
          title: 'Foo.',
          text: 'bar',
          selector: '.some-class',
          position: 'bottom',
      }];
      setJoyride({ showOverlay: false, steps: steps });
      toggleJoyride('START', true);
    },

  Note: you may need to stop/reset the Joyride component depending on what
  you are doing.

*/

import {flowRight} from 'lodash';
import {useState} from 'react';

const JoyrideConfig = flowRight(

  // Joyride HOC state
  BaseComponent => props => {
    const [joyride, setJoyride] = useState({});
    const [joyrideFlags, setJoyrideFlags] = useState({});

    return (<BaseComponent {...props}
      joyride={joyride}
      joyrideFlags={joyrideFlags}
      setJoyride={setJoyride}
      setJoyrideFlags={setJoyrideFlags}
      joyAPI={(toggle, v, ref) => {
        switch(toggle) {
          case 'TOOLTIP':
            return ref.addTooltip(v);
          case 'START':
            return ref.start(v);
          case 'STOP':
            return ref.stop(v);
          case 'RESET':
            return ref.reset(v);
          case 'NEXT':
            return ref.next(v);
          case 'BACK':
            return ref.back(v);
          case 'PROGRESS':
            return ref.getProgress(v);
          case 'PARSE_STEPS':
            return ref.parseSteps(v);
          default:
            return;
        }
      }} />);
  }
);

export default JoyrideConfig;

/*

  React Joyride

  run {bool}: Run/stop the tour.
  keyboardNavigation {bool}: Toggle keyboard navigation (esc, space bar, return). Defaults to true
  locale {object}: The strings used in the tooltip. Defaults to { back: 'Back', close: 'Close', last: 'Last', next: 'Next', skip: 'Skip' }
  resizeDebounce {bool}: Delay the reposition of the current step while the window is being resized. Defaults to false
  resizeDebounceDelay {number}: The amount of delay for the resizeDebounce callback. Defaults to 200
  holePadding {number}: The gap around the target inside the hole. Defaults to 5
  scrollOffset {number}: The scrollTop offset used in scrollToSteps. Defaults to 20
  scrollToSteps {bool}: Scroll the page to the next step if needed. Defaults to true
  scrollToFirstStep {bool}: Scroll the page for the first step. Defaults to false
  showBackButton {bool}: Display a back button. Defaults to true
  showOverlay {bool}: Display an overlay with holes above your steps (for tours only). Defaults to true
  showSkipButton {bool}: Display a link to skip the tour. Defaults to false
  showStepsProgress {bool}: Display the tour progress in the next button e.g. 2/5 in continuous tours. Defaults to false
  tooltipOffset {number}: The tooltip offset from the target. Defaults to 30
  type {string}: The type of your presentation. It can be continuous (played sequencially with the Next button) or single. Defaults to single
  disableOverlay {bool}: Don't close the tooltip on clicking the overlay. Defaults to false
  debug {bool}: Console.log Joyride's inner actions. Defaults to false
  callback {func}: callback fired after every Joyride event. Debug = true to see them all.
*/
