import Modal from '../_modal';
import CardDisplay from '../_card_display';
import CompanyLogo from '../_company_logo';
import Icon from '../_icon';

import {cardGet} from '../../modules/api/cards';
import {isValidId} from '../../modules/utils';
import {maybeLaunchModalCardView} from '../../modules/card_utils';
import {customEvents} from '../../modules/constants/ui';
import {userCanCurate} from '../../modules/roles_utils';
import {CARD_WRAPPER_CLASSNAME} from '../../modules/constants/editor';

import URI from 'urijs';
import {withRouter, Link} from 'react-router-dom';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import CardInfo from '../_card_info';
import CardTags from '../card_templates/_card_tags';

class ModalSingleCardView extends React.PureComponent {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  state = {
    rival: {},
    card: {},
    hasError: false
  };

  componentDidMount() {
    const {
      location: {search}
    } = this.props;
    const cardId = this.getCardIdFromQuerySearch(search);

    cardId && this.loadCard(cardId);

    document.addEventListener('click', this.handleCardLinksListener, {
      capture: true
    });

    document.addEventListener(
      customEvents.onEditCard,
      this.handleOnEditCardEvent
    );
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {
      location: {search}
    } = nextProps;
    const currentCardId = this.getCardIdFromQuerySearch(search);

    if(!currentCardId || nextState.card.id === currentCardId) {
      return;
    }

    // do not load a card if it already exists in the state
    if(currentCardId !== this.state.card.id && !this.state.hasError) {
      this.loadCard(currentCardId);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleCardLinksListener, true);
    document.removeEventListener(customEvents.onEditCard, this.handleOnEditCardEvent);
  }

  /**
   * Detect internal card links
   *
   * @param {object} event Mouse Event
   * @memberof ModalSingleCardView
   */
  handleCardLinksListener = event => {
    const {target} = event;

    if(!target.closest('.modal-single-card-view')) {
      return;
    }

    const {history} = this.props;

    maybeLaunchModalCardView(event, history);
  };

  handleOnEditCardEvent = e => {
    if(isEmpty(e.detail)) {return;}

    if(e.detail.card.id === this.state.card.id) {
      this.setState({card: {}, rival: {}});
    }
  };

  loadCard = async cardId => {
    const {rivalGet} = this.context.api;

    try {
      const card = await cardGet({
        cardOptions: {cardId},
        code: 'ModalSingleCardView'
      });

      const rival = await rivalGet({profileId: card.board.profileId});

      this.setState({
        isLoading: false,
        card,
        rival
      });
    }
    catch(error) {
      console.error('ModalSingleCardView: cardGet: %o', error);
      this.setState({
        isLoading: false,
        hasError: true,
        card: {},
        rival: {}
      });
    }
  };

  handleModalClose = () => {
    this.setState(
      {
        hasError: false
      },
      () => {
        this.props.history.push({search: ''});
      }
    );
  };

  /**
   * Check if URL search has viewCard query param
   *
   * @example ?viewCard=123
   * @memberof ModalSingleCardView
   * @returns {(number|null)} A valid cardId or null
   * @param search
   */
  getCardIdFromQuerySearch = (search = '') => {
    const urlQuery = URI.parseQuery(search);
    const cardId = Number(urlQuery.viewCard);

    return isValidId(cardId) ? cardId : null;
  };

  renderContentPlaceholder = () => {
    const placeholder = (
      <section className={CARD_WRAPPER_CLASSNAME}>
        <div className="content-loading">
          <h2 className="placeholder-state">Loading card</h2>
          <br />
          <p className="placeholder-state" style={{width: '75%'}}>
            &hellip;
          </p>
          <p className="placeholder-state" style={{width: '50%'}}>
            &hellip;
          </p>
        </div>
      </section>
    );

    return placeholder;
  };

  render() {
    const {utils: {user, company}} = this.context;
    const {companyData: {showCardInfo = true} = {}} = company;
    const isCurator = userCanCurate({user});
    const {history, location} = this.props;
    const {card, rival, hasError} = this.state;
    const currentCardId = this.getCardIdFromQuerySearch(location.search);
    const baseClassName = 'modal-single-card-view';
    const headerClassName = `${baseClassName}--header`;
    const containerClassName = `${baseClassName}--container`;
    const contentClassName = `${baseClassName}--content`;
    const isLoaded = (card.id && rival.profile && card.id === currentCardId) || hasError;
    const profileAction = isCurator ? 'edit' : 'view';

    if(!currentCardId) {
      return null;
    }

    const {board: {id: boardId, profileId} = {}} = card;
    const {author, createdAt, updatedAt, data: cardData = {}} = card;
    const cardInfo = !hasError && !cardData.hasDynamicBlocks &&
      (isCurator || showCardInfo) && (
      <CardInfo author={author} createdAt={createdAt} updatedAt={updatedAt} />
    );

    return (
      <Modal header={false} hideCloseButton={true} padded={false} basic={true}>
        <div className={baseClassName}>
          <div
            className={classNames(headerClassName, {'has-error': hasError})}>
            {!hasError && (
              <div className={`${headerClassName}--profile`}>
                {isLoaded ? (
                  <Link
                    to={`/profile/${profileId}/${profileAction}`}
                    className={`${headerClassName}--logo`}>
                    <CompanyLogo rival={rival} />
                  </Link>
                ) : (
                  <span
                    className={`${headerClassName}--logo placeholder-state`} />
                )}
                <span
                  title={isLoaded ? rival.profile.name : 'Loading...'}
                  className={classNames(`${headerClassName}--title`, {
                    'placeholder-state': !isLoaded
                  })}>
                  {isLoaded ? rival.profile.name : 'Loading...'}
                </span>
              </div>
            )}
            <div className={`${headerClassName}--buttons right-content`}>
              {isCurator && <button
                onClick={() =>
                  history.push({
                    pathname: `/profile/${profileId}/edit/card/${currentCardId}/edit`,
                    search: null,
                    state: {boardId}
                  })
                }
                className={classNames('btn btn-success', {
                  'placeholder-state': !isLoaded
                })}>
                <div className="icon-with-text"><Icon icon="edit" /></div> EDIT
              </button>
              }

              {!hasError && (
                <button
                  onClick={() =>
                    history.push({
                      pathname: `/profile/${profileId}/${profileAction}/card/${currentCardId}`,
                      search: null,
                      state: {boardId}
                    })
                  }
                  className={classNames('btn btn-success', {
                    'placeholder-state': !isLoaded
                  })}>
                  View In Board
                </button>
              )}

              <button
                onClick={this.handleModalClose}
                className={classNames('btn btn--close', {
                  'placeholder-state': !isLoaded
                })}>
                <div className="icon-with-text"><Icon icon="close" height="16" width="16" /></div> Close
              </button>
            </div>
          </div>
          <div className={containerClassName}>
            {((card.id === currentCardId || hasError) && (
              <div className={contentClassName}>
                <CardDisplay
                  card={!hasError ? card : null}
                  rival={rival}
                  id={currentCardId}
                  showTitle={true} />
                {!hasError && <CardTags card={card} editable={true} />}
                {cardInfo}
              </div>
            )) ||
              this.renderContentPlaceholder()}
          </div>
        </div>
      </Modal>
    );
  }

}

export default withRouter(ModalSingleCardView);
