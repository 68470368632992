import KlueLogo from 'images/klue-icon.png';
import Icon from './_icon';
import {APP_STORE_URL, GET_FROM_APP_STORE_MESSAGE} from '../modules/constants/mobile';

class DownloadMobileBanner extends React.PureComponent {

  static propTypes = {
    getItWhere: PropTypes.string,
    appLink: PropTypes.string,
    onViewPressed: PropTypes.func,
    onClosePressed: PropTypes.func
  };

  static defaultProps = {
    getItWhere: GET_FROM_APP_STORE_MESSAGE,
    appLink: APP_STORE_URL,
    onViewPressed() {},
    onClosePressed() {}
  };

  render() {
    const {getItWhere, appLink, onViewPressed, onClosePressed} = this.props;

    return (
      <div className="download-mobile-banner">
        <div className="download-mobile-banner_close">
          <div onClick={onClosePressed}>
            <Icon icon="close" width="24" height="24" />
          </div>
        </div>
        <img className="download-mobile-banner_logo" src={KlueLogo} />
        <div className="download-mobile-banner_app-info">
          <div className="download-mobile-banner_text-info">
            <h1>Klue App</h1>
            <h2>Klue Labs Ltd.</h2>
            <p>{getItWhere}</p>
          </div>
          <div className="download-mobile-banner_view"><a href={appLink} onClick={onViewPressed}>View</a></div>
        </div>
      </div>
    );
  }

}

export default DownloadMobileBanner;
