import Icon from './_icon';

import {DropdownMenuConstants} from '../modules/constants/dropdown_menu';

import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';

class DropdownMenu extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    dataTrackingId: PropTypes.string,
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
    values: PropTypes.array,
    selectedValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    showSelectedValueWhenCollapsed: PropTypes.bool,
    className: PropTypes.string,
    selectCallback: PropTypes.func,
    resetCallback: PropTypes.func,
    enableOnClickOutside: PropTypes.func,
    disableOnClickOutside: PropTypes.func,
    children: PropTypes.node,
    parentContextId: PropTypes.number,
    justification: PropTypes.string,
    allowEmptySelection: PropTypes.bool,
    displayDropdownTitle: PropTypes.bool,
    alwaysRenderLabel: PropTypes.bool,
    icon: PropTypes.string,
    mobileLabel: PropTypes.node
  };

  static defaultProps = {
    id: '',
    label: '',
    title: '',
    dataTrackingId: null,
    dataTestId: null,
    disabled: false,
    values: [],
    selectedValue: '',
    defaultValue: '',
    showSelectedValueWhenCollapsed: true,
    className: '',
    selectCallback() {},
    resetCallback() {},
    enableOnClickOutside() {},
    disableOnClickOutside() {},
    children: null,
    parentContextId: null,
    justification: 'right',
    allowEmptySelection: false,
    displayDropdownTitle: true,
    alwaysRenderLabel: false,
    icon: null,
    mobileLabel: null
  };

  state = {
    isActive: false,
    currentValue: ''
  };

  componentDidMount() {
    // DEBUG
    console.log('DropdownMenu.componentDidMount: props: %o', this.props);
  }

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  handleClickOutside = () => {
    const {isActive} = this.state;

    isActive && this.handleDropdownToggle();
  };

  handleDropdownToggle = event => {
    if(event) {
      event.preventDefault();
    }

    this.setState(prevState => {
      return {
        isActive: !prevState.isActive
      };
    }, () => {
      const {isActive} = this.state;
      const {enableOnClickOutside, disableOnClickOutside} = this.props;

      return isActive ? enableOnClickOutside() : disableOnClickOutside();
    });
  };

  /**
   * Generic dropdown item click handler.
   *
   * @param  {string} ref The ref attribute of the corresponding dropdown
   * @param  {string} val Selected dropdown item value.
   */
  handleDropdownItemClick = (ref, val) => {
    const {defaultValue, selectCallback, parentContextId, resetCallback} = this.props;

    if(val === DropdownMenuConstants.CREATE_NEW_ITEM_REF) {
      selectCallback(val, {parentContextId, ref});
    }
    else if(val === DropdownMenuConstants.RESET_ITEM_REF) {
      this.setState({
        currentValue: defaultValue,
        isActive: false
      }, () => {
        resetCallback({parentContextId});
      });
    }

    this.setState({
      currentValue: val,
      isActive: false
    }, () => {
      selectCallback(val, {parentContextId});
    });
  };

  renderDropdown = () => {
    const {
      selectedValue,
      defaultValue,
      justification,
      allowEmptySelection,
      disabled,
      displayDropdownTitle,
      title,
      alwaysRenderLabel,
      dataTrackingId,
      dataTestId: menuDataTestId
    } = this.props;
    const {isActive, currentValue} = this.state;

    const ref = this.props.id || null;
    const uiDropdownValues = this.props.values || null;
    const uiDropdownTitle = displayDropdownTitle && title && (
      <div className="toolbar-dropdown-heading">{title}</div>
    );

    if(!ref || !uiDropdownValues) {
      return null;
    }

    let uiDefaultUpdatedValue = !allowEmptySelection && (!_.isEmpty(uiDropdownValues[0]) && uiDropdownValues[0][0]);

    if(selectedValue || selectedValue === 0) {
      uiDefaultUpdatedValue = selectedValue;
    }
    else if(currentValue || currentValue === 0) {
      uiDefaultUpdatedValue = currentValue;
    }

    const filterClasses = classNames(`${this.props.className} toolbar-dropdown-group toolbar-dropdown-group--${justification}`, {
      'toolbar-dropdown-group--active': isActive,
      'toolbar-dropdown-group--disabled': disabled
    });
    const filterDropdownClasses = classNames({
      'toolbar-button': true,
      'toolbar-button--focused': isActive
    });
    const uiDropdownOptions = [];
    let uiCurrentLabel = defaultValue;

    if(!disabled) {
      for(let i = 0; i < uiDropdownValues.length; i++) {
        const [
          itemValue = '',
          itemLabel = '',
          showSeparatorAbove = false,
          extraClasses = '',
          {trackingId, dataTestId = ''} = {},
          customIcon = null
        ] = uiDropdownValues[i];
        // checking against selectedValue prop for occassions where actions outside of the dropdown deselects a selected value
        const isCurrent = (itemValue === uiDefaultUpdatedValue) && (selectedValue || selectedValue === 0);
        const itemClasses = classNames(`toolbar-dropdown-button ${extraClasses}`, {
          'toolbar-dropdown-button--active': isCurrent,
          'toolbar-dropdown-button--child': (String(itemValue).indexOf('|') >= 0),
          'toolbar-dropdown-button--default': itemValue === defaultValue,
          'toolbar-dropdown-button--separator': showSeparatorAbove
        });
        let itemActiveIcon;

        if(isCurrent) {
          uiCurrentLabel = itemLabel;
          itemActiveIcon = (
            <div className="toolbar-dropdown-button_icon">
              <Icon icon="check" />
            </div>
          );
        }

        uiDropdownOptions.push(
          <div
            key={`${ref}-item${i}`}
            className={itemClasses}
            data-tracking-id={trackingId}
            data-testid={dataTestId}
            onClick={() => this.handleDropdownItemClick(ref, itemValue)}>
            {itemActiveIcon}{customIcon}<div className="toolbar-dropdown-button_label">{itemLabel}</div>
          </div>
        );
      }
    }

    // remove the label prefix to save space, if default value is equal to a title (call-to-action phrase)
    const renderLabel = alwaysRenderLabel
      ? this.props.label
      : (typeof uiCurrentLabel === 'string' && uiCurrentLabel.includes(this.props.title) ? '' : this.props.label);

    return (
      <div key={ref} className={filterClasses}>
        <div className="toolbar-dropdown-group_trigger">
          <div
            data-tracking-id={dataTrackingId}
            className={filterDropdownClasses}
            onClick={this.handleDropdownToggle}
            data-testid={menuDataTestId}
            title={renderLabel}>
            {this.props.mobileLabel ? (
              <div className="toolbar-button_label--mobile">
                {this.props.mobileLabel}
              </div>
            ) : null}
            {this.props.icon ? (
              <div className="toolbar-button_icon toolbar-button_icon--left">
                <Icon icon={this.props.icon} width="24" height="24" />
              </div>
            ) : null}
            <div className={classNames('toolbar-button_label', this.props.showSelectedValueWhenCollapsed ? 'prefix' : '')}>{renderLabel}</div>
            {this.props.showSelectedValueWhenCollapsed
              ? (
                <div className="toolbar-button_label">
                  {uiCurrentLabel || (!allowEmptySelection && uiDropdownValues[0][1])}
                </div>
              ) : null
            }
            <div className="toolbar-button_icon toolbar-button_icon--green toolbar-button_icon--right">
              <Icon icon={isActive ? 'close' : 'arrow-down'} width="16" height="16" />
            </div>
          </div>
        </div>
        <div className="toolbar-dropdown-group_dropdown">
          {uiDropdownTitle}
          <div className="toolbar-dropdown-options">
            {uiDropdownOptions}
          </div>
          {this.props.children}
        </div>
      </div>
    );
  };

  render() {
    return this.renderDropdown();
  }

}

export default onClickOutside(DropdownMenu);
