import Dropdown from './_dropdown';
import CopyButton from './_copy_button';
import InputPassword from './_input_password';
import Icon from '../components/_icon';

import {userCanCurate, userIsKluebot, userIsAdmin} from '../modules/roles_utils';
import {getSignedUrl} from '../modules/s3_utils';
import {isValidId} from '../modules/utils';
import {pluralize} from '../modules/text_utils';
import {renderSuppressionMessage} from '../modules/user_utils.js';

import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import classNames from 'classnames';

import {Link, withRouter} from 'react-router-dom';

class User extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
    user: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    rivalGroups: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    history: {},
    match: {},
    user: {},
    users: {},
    rivalGroups: []
  };

  state = {
    user: null,
    uiMode: 'profile-view',
    uiUserFields: {},
    uiAddAlias: '',
    uiAddAliasDomain: null,
    uiAddAliasCompanyDomains: [],
    uiMessage: null,
    uiReviewSettingsEnabled: false,
    uiReviewSettingsGroups: false
  };

  componentDidMount() {
    console.log('User.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    const {params: {userId} = {}} = this.props.match;

    this.loadUser(userId);
    this.loadCompanyDomains();

    ReactTooltip.rebuild();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {userId} = nextProps.match.params;

    if(this.props.match.params.userId !== userId) {
      console.log('User.componentWillReceiveProps: props: %o', nextProps);

      this.loadUser(userId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {uiMode, uiAddAlias} = this.state;

    if((uiMode !== prevState.uiMode) || (uiAddAlias !== prevState.uiAddAlias)) {
      ReactTooltip.rebuild();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _canEditProfile = user => {
    const currentUser = this.props.user;

    return (user.id === currentUser.id) || (currentUser.isStaff && !user.isStaff) ||
      (userIsAdmin({user: this.context.utils.user}) && !userIsAdmin({roles: user.roles}));
  };

  _canEditEmailReviewSettings = user => {
    return this._canEditProfile(user);
  };

  // user management
  loadUser = userId => {
    const {users, history} = this.props;
    const userOptions = {};

    if(isValidId(userId, true)) {
      userOptions.userId = userId;
    }
    else if(_.isString(userId) && userId.length) {
      userOptions.atName = userId;
    }

    const userAction = user => {
      this.setState({
        user,
        uiReviewSettingsEnabled: user.emailDigest,
        uiReviewSettingsGroups: this._getEmailReviewFilteredRivalIds(user)
      }, () => console.log('User.loadUser: loaded user: %o', user));
    };

    if(!_.isEmpty(users) && users[userId]) {
      userAction(users[userId]);
    }

    if(userOptions.userId || userOptions.atName) {
      this.context.api.userGet(userOptions)
        .then(user => userAction(user))
        .catch(() => {
          console.error(
            'User.loadUser: unable to find user matching %s %o', isValidId(userOptions.userId, true) ? 'userId' : 'atName', userId
          );

          // user not found, redirect to users listing
          history.replace('/users');
        });
    }
    else {
      // userId or atName not specified, redirect to users listing
      history.replace('/users');
    }
  };

  handleUpdateUser = (user, userOptions, callback = null) => {
    if(!user || _.isEmpty(userOptions)) {
      return;
    }

    console.log('User.handleUpdateUser: updating user: %o, userOptions: %o', user, userOptions);

    this.context.api.userUpdate(userOptions).then(updatedUser => {
      this.setState({
        user: updatedUser
      }, () => {
        console.log('User.handleUpdateUser: updated user: %o', this.state.user);

        return typeof callback === 'function' && callback(this.state.user);
      });
    }).catch(() => {
      this.setUIEditProfileMessage('Unable to save changes. Please try again, or contact the administrator.', true);
    });
  };

  // company domains
  loadCompanyDomains = () => {
    $.ajax({
      url: '/api/company.json',
      type: 'GET',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      success: data => {
        if(this._isMounted) {
          const domains = data.emailDomains;

          if(domains && domains.length) {
            this.setState({
              uiAddAliasDomain: domains[0],
              uiAddAliasCompanyDomains: domains
            }, () => {
              console.log('User.loadCompanyDomains: loaded company domains: %o, default domain: %o', domains, domains[0]);
            });
          }
        }
      },
      error: (xhr, type) => {
        if(this._isMounted) {
          console.error('User.loadCompanyDomains: error: %o, type: %o', xhr, type);
        }
      }
    });
  };

  handleToggleCuratorClick = (user, curatorState) => {
    const userOptions = {
      id: user.id
    };
    const rolesToToggle = ['curator'];

    if(curatorState) {
      userOptions.addRoles = rolesToToggle;
    }
    else {
      userOptions.removeRoles = rolesToToggle;
    }

    this.handleUpdateUser(user, userOptions);

    // optimistically update the ui
    if(curatorState) {
      user.roles = user.roles.concat(rolesToToggle);
    }
    else {
      user.roles = _.difference(user.roles, rolesToToggle);
    }

    this.setState({
      user
    });
  };

  handleContactClick = event => {
    if(event) {
      event.preventDefault();
    }

    window.open(`mailto:${this.state.user.email}`, '_blank');
  };

  handleToggleEmailNotifyClick = user => {
    const {id, emailNotify, suppression: {reportedAt} = {}} = user;

    if(reportedAt) {
      return this.renderEmailReviewConfigModal();
    }

    this.handleUpdateUser(user, {
      id,
      emailNotify: !emailNotify
    });
  };

  handleToggleEmailDigestClick = () => {
    const {user, user: {id, emailDigest, suppression: {reportedAt} = {}}} = this.state;

    if(reportedAt) {
      return this.renderEmailReviewConfigModal();
    }

    this.handleUpdateUser(user, {
      id,
      emailDigest: !emailDigest
    }, updatedUser => {
      this.setState({
        uiReviewSettingsEnabled: updatedUser.emailDigest
      });
    });
  };

  handleUpdateProfileClick = user => {
    const {uiUserFields: {firstName, lastName, title, bio} = {}} = this.state;

    if(!firstName || !lastName) {
      this.setUIEditProfileMessage('Your full name is required', true);

      return;
    }

    const userOptions = {
      id: user.id,
      firstName,
      lastName,
      title,
      bio
    };

    this.handleUpdateUser(user, userOptions);
    this.setUIStateProfileView();
  };

  handlePasswordFormSubmit = () => {
    // delay view change as chrome requires the submitted form to be in the DOM (#1658)
    setTimeout(() => {
      this.setUIStateProfileView();
    }, 10);
  };

  setFieldState = event => {
    const {uiUserFields = {}} = this.state;

    this.setUIEditProfileMessage(null);

    if(_.isEmpty(uiUserFields)) {
      return;
    }

    Object.assign(uiUserFields, {
      [event.target.name]: event.target.value,
      name: `${uiUserFields.firstName} ${uiUserFields.lastName}`
    });

    this.setState({uiUserFields});
  };

  // TODO: this whole flash messaging logic is ugly and needs refactoring/replacement with toast messages
  // (or extraction to something more generic)
  setUIEditProfileMessage = (message, isError) => {
    if(message) {
      this.setState({
        uiMessage: {
          message,
          error: isError
        }
      });
    }
    else {
      this.setState({
        uiMessage: null
      });
    }
  };

  setUIStateProfileView = () => {
    this.setUIEditProfileMessage(null);
    this.setState({
      uiMode: 'profile-view'
    });
  };

  setUIStateProfileEdit = () => {
    this.setUIEditProfileMessage(null);

    if(!this.state.user) {
      return;
    }

    this.setState({
      uiMode: 'profile-edit',
      uiUserFields: this.state.user
    });
  };

  setUIStatePasswordEdit = () => {
    this.setUIEditProfileMessage(null);
    this.setState({
      uiMode: 'password-edit'
    });
  };

  setUIStateEmailEdit = () => {
    this.setUIEditProfileMessage(null);
    this.setState({
      uiMode: 'email-edit'
    });
  };

  handleSetUIStateProfileViewClick = event => {
    if(event) {
      event.preventDefault();
    }

    this.setUIStateProfileView();
  };

  handleSetUIStateProfileEditClick = event => {
    if(event) {
      event.preventDefault();
    }

    this.setUIStateProfileEdit();
  };

  handleSetUIStatePasswordEditClick = event => {
    if(event) {
      event.preventDefault();
    }

    this.setUIStatePasswordEdit();
  };

  handleSetUIStateEmailEditClick = event => {
    if(event) {
      event.preventDefault();
    }

    this.setUIStateEmailEdit();
  };

  handleInMailClick = event => {
    if(event) {
      event.preventDefault();
    }

    return this.state.user ? this.state.user.inMails[0] : 'Sorry, we were unable to copy your inmail link!';
  };

  handleAliasKeyPress = (user, event) => {
    event = event || window.event;

    if((event.which === 13) && !event.shiftKey) {
      this.handleAddAliasClick(user);
    }
  };

  handleAddAliasClick = user => {
    const aliases = user.emailAliases ? user.emailAliases.slice() : [];
    let alias = this.state.uiAddAlias && this.state.uiAddAlias.replace(/^\s\s*/, '').replace(/\s\s*$/, '');

    // convert to full domain
    alias = alias + '@' + (this.state.uiAddAliasDomain || '');

    if(!alias || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(alias)) {
      this.setUIEditProfileMessage('Invalid email address', true);

      return;
    }

    const matchingEmails = aliases.filter(email => alias === email);

    if(matchingEmails.length || (alias === user.email)) {
      this.setUIEditProfileMessage('Email address already exists', true);

      return;
    }

    aliases.push(alias);

    this.handleUpdateUser(user, {
      id: user.id,
      addEmailAliases: [alias]
    });

    this.setUIEditProfileMessage(null);

    this.setState({
      uiAddAlias: ''
    });
  };

  handleRemoveAliasClick = (user, alias, event) => {
    if(event) {
      event.preventDefault();
    }

    this.handleUpdateUser(user, {
      id: user.id,
      removeEmailAliases: [alias]
    });

    ReactTooltip.hide();
  };

  handleAddAliasChange = event => {
    this.setUIEditProfileMessage(null);

    this.setState({
      uiAddAlias: event.target.value
    });
  };

  handleAddAliasDomainChange = event => {
    this.setUIEditProfileMessage(null);

    this.setState({
      uiAddAliasDomain: event.target.value
    });
  };

  getUIProfileEdit = user => {
    return (
      <div className="user-profile-edit">
        <div className="text-right">
          <a href="#" className="btn btn-outline" onClick={this.handleSetUIStateProfileViewClick}><i className="fa fa-times-circle" />Cancel</a>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <div className="form-group">
              <label className="form-field-label">First Name</label>
              <input className="form-control" type="text" value={this.state.uiUserFields.firstName} name="firstName" onChange={this.setFieldState} />
            </div>
          </div>
          <div className="col-xs-6">
            <div className="form-group">
              <label className="form-field-label">Last Name</label>
              <input className="form-control" type="text" value={this.state.uiUserFields.lastName} name="lastName" onChange={this.setFieldState} />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="form-field-label">Title</label>
          <input className="form-control" type="text" value={this.state.uiUserFields.title} name="title" onChange={this.setFieldState} />
        </div>
        <div className="form-group">
          <label className="form-field-label">Bio</label>
          <textarea className="form-control" rows="4" value={this.state.uiUserFields.bio} name="bio" onChange={this.setFieldState} />
        </div>
        <button className="btn btn-success btn-block" onClick={() => this.handleUpdateProfileClick(user)}>Update Profile</button>
      </div>
    );
  };

  getUIPasswordChange = user => {
    return (
      <div className="user-profile-edit">
        <form action="/account/users" method="post" onSubmit={this.handlePasswordFormSubmit}>
          <input name="utf8" type="hidden" value="&#x2713;" />
          <input type="hidden" name="_method" value="patch" />
          <input type="hidden" name="authenticity_token" value={this.props.user.csrf} />
          <div className="pull-right">
            <a href="#" className="btn btn-outline" onClick={this.handleSetUIStateProfileViewClick}><i className="fa fa-times-circle" />Cancel</a>
          </div>
          <div className="form-group">
            <label className="form-field-label">Email</label>
            <p><strong>{user.email}</strong></p>
          </div>
          <div className="form-group">
            <label className="form-field-label">Current Password</label>
            <InputPassword id="user[current_password]" className="form-control" />
            <div className="help-block"><small>To secure your account, please confirm your current password.</small></div>
          </div>
          <hr />
          <div className="form-group">
            <label className="form-field-label">New Password</label>
            <InputPassword id="user[password]" className="form-control" autoFocus={false} showMeter={true} />
          </div>
          <button type="submit" className="btn btn-success btn-block">Change Password</button>
        </form>
      </div>
    );
  };

  handleInMailValue = inMailEmail => {
    if(this.state.uiInMailShow) {
      return inMailEmail;
    }

    return inMailEmail.replace(/\.(.+?)@/, (a, b) => {
      //return new Array(b.length).join('*') + '@'; // replace all random chars with *
      return '.' + b.slice(0, -6) + '******@'; // replace last six random chars with *
    });
  };

  handleInMailToggle = showState => {
    this.setState({
      uiInMailShow: Boolean(showState)
    }, () => {
      const inMail = ReactDOM.findDOMNode(this.refs.inMail);

      if(showState) {
        inMail.setSelectionRange(0, inMail.value.length);
      }
      else {
        inMail.setSelectionRange(0, 0);
      }
    });
  };

  getUIEmailChange = user => {
    const companyDomains = this.state.uiAddAliasCompanyDomains.slice();
    let uiAliases;
    let uiAddAliasRegion;

    if(user.emailAliases && user.emailAliases.length) {
      uiAliases = (
        <table className="table table-bordered table-grey-background">
          <tbody>
            {user.emailAliases.map(alias => {
              return (
                <tr key={alias}>
                  <td>
                    <strong>{alias}</strong>
                    <a
                      href="#"
                      data-tip="Remove email alias"
                      className="pull-right"
                      onClick={() => this.handleRemoveAliasClick(user, alias)}>
                      <i className="fa fa-remove" />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    else {
      uiAliases = (
        <p className="disabled">No email aliases found.</p>
      );
    }

    if(companyDomains && companyDomains.length) {
      const companyDomainNodes = companyDomains.map(domain => {
        return (
          <option key={domain} value={domain}>@{domain}</option>
        );
      });

      // don't allow any aliases to be added if no company domains are present
      uiAddAliasRegion = (
        <table>
          <tbody>
            <tr>
              <td>
                <input
                  type="email"
                  required="required"
                  className="form-control"
                  value={this.state.uiAddAlias}
                  onChange={this.handleAddAliasChange}
                  onKeyDown={e => this.handleAliasKeyPress(user, e)} />
              </td>
              <td>
                <div className="select-wrapper">
                  <select className="form-control" value={this.state.uiAddAliasDomain} onChange={this.handleAddAliasDomainChange}>
                    {companyDomainNodes}
                  </select>
                </div>
              </td>
              <td>
                <button
                  className="btn btn-success"
                  style={{width: '100%'}}
                  onClick={() => this.handleAddAliasClick(user)}>
                  <i className="fa fa-plus" /> Add Alias
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (
      <div className="user-profile-edit">
        <div className="text-right">
          <a href="#" className="btn btn-outline" onClick={this.handleSetUIStateProfileViewClick}><i className="fa fa-chevron-left" /> Back</a>
        </div>
        <div className="form-group">
          <p><label className="form-field-label">Primary Email Address</label></p>
          <p><small>For security reasons, your primary email address can&apos;t be changed.</small></p>
          <div className="primary-email"><strong data-tip="Your primary account login" data-offset="{'top': -4}">{user.email}</strong></div>
        </div>
        <hr />
        <div className="form-group">
          <p><label className="form-field-label">Manage Email Aliases</label></p>
          <p><small>Aliases can only be from known domains associated with your company.</small></p>
          {uiAliases}
        </div>
        {uiAddAliasRegion}
      </div>
    );
  };

  handleAvatarUploadClick = () => {
    // trigger click to open the browse dialog
    const inputElement = ReactDOM.findDOMNode(this.refs.avatarImageInput);

    if(inputElement) {
      inputElement.click();
    }
  };

  avatarOnUploadError = message => {
    this.setState({
      avatarMode: 'uploaded'
    });

    const msg = 'Sorry, we encountered an error uploading this file. Please try again.';

    setTimeout(() => {
      this.setState({
        avatarMode: null
      });
    }, 5000);

    this.handleUpdateUser(this.state.user, {
      id: this.state.user.id,
      image: null,
      imageUrl: null
    });

    if(typeof message === 'string' && this.context.utils.dialog) {
      this.context.utils.dialog.alert(`${msg}<br /><br /><strong>Error:</strong> ${message}`);
    }
    else {
      alert(msg);     // eslint-disable-line no-alert
    }
  };

  avatarOnUploadFinish = (signResult, file) => {
    if(file.type.indexOf('image/') !== 0) {
      const msg = 'Only images can be uploaded.';

      if(this.context.utils.dialog) {
        this.context.utils.dialog.alert(msg);
      }
      else {
        alert(msg);   // eslint-disable-line no-alert
      }

      this.setState({
        avatarMode: null
      });

      return;
    }

    this.setState({
      avatarMode: 'uploaded',
      newAvatarImageFullSize: signResult.assetUrl
    });

    setTimeout(() => {
      this.setState({
        avatarMode: null
      });
    }, 5000);

    this.handleUpdateUser(this.state.user, {
      id: this.state.user.id,
      image: signResult.assetUrl
    });
  };

  avatarOnUploadStart = (file, next) => {
    this.setState({
      avatarMode: 'uploading'
    });

    // Needs this to progress
    next(file);
  };

  avatarOnUploadProgress = () => {
    // shouldn't be needed, but here just in case
    if(this.state.avatarMode !== 'uploading') {
      this.setState({
        avatarMode: 'uploading'
      });
    }
  };

  _getEmailReviewFilteredRivalIds = user => {
    const weeklyReviewData = user.userData ? user.userData.weeklyReviewData : false;

    if(weeklyReviewData && weeklyReviewData.filteredByRivalGroups && !_.isEmpty(weeklyReviewData.filteredByRivalGroups)) {
      return weeklyReviewData.filteredByRivalGroups;
    }

    return false;
  };

  handleCancelEmailReviewSettingsClick = () => {
    const {user} = this.props;

    this.setState({
      uiReviewSettingsEnabled: user.emailDigest
    });

    this.context.utils.dialog.remove('modal-email-review');
  };

  handleSaveEmailReviewSettingsClick = user => {
    const featureFlag = [['weeklyReviewData', 'filteredByRivalGroups'], this.state.uiReviewSettingsGroups];

    this.handleUpdateUser(user, {
      id: user.id,
      emailDigest: this.state.uiReviewSettingsEnabled,
      featureFlag
    }, updatedUser => {
      console.log('User.handleSaveEmailReviewSettingsClick: updated user review settings: %o', updatedUser);
    });

    this.context.utils.dialog.remove('modal-email-review');
  };

  handleReviewSettingsInput = (stateName, value, isMultiple) => {
    const reviewSettingsState = {};

    if(isMultiple) {
      const options = (this.state[stateName] || []).slice();
      const existingIndex = options.indexOf(value);

      if(existingIndex > -1) {
        options.splice(existingIndex, 1);
      }
      else {
        options.push(value);
      }

      value = options.length ? options : false;
    }

    reviewSettingsState[stateName] = value;

    this.setState(reviewSettingsState, () => {
      this.renderEmailReviewConfigModal();
    });
  };

  // TODO: this code is duplicated in _manage_users.js, need to make it a component!
  renderEmailReviewConfigModal = () => {
    const {rivalGroups} = this.props;
    const {user} = this.state;
    const {utils: {company: {companyData: {customIntelDigestName} = {}}}} = this.context;

    const uiRivalGroups = [];
    let uiEmptyRivalGroups;

    let uiHelpBlock = (
      <p className="help-block">
        <strong>
          <em>Tip:</em>
        </strong>{' '}
        Curator users can create new company groups on the{' '}
        <Link
          to="/"
          onClick={() => this.context.utils.dialog.remove('modal-email-review')}>
          <strong>Companies Page</strong>
        </Link>.
      </p>
    );

    for(let i = 0; i < rivalGroups.length; i++) {
      uiRivalGroups.push(
        <div key={i} className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={(this.state.uiReviewSettingsGroups || []).includes(rivalGroups[i].id)}
              onChange={() => this.handleReviewSettingsInput('uiReviewSettingsGroups', rivalGroups[i].id, true)} /> {rivalGroups[i].name}{' '}
            <small style={{opacity: 0.7, whiteSpace: 'nowrap'}}>({rivalGroups[i].rivals.length} Companies)</small>
          </label>
        </div>
      );
    }

    // No companies, empty state message
    if(!rivalGroups || !rivalGroups.length) {
      uiEmptyRivalGroups = (
        <div>
          <div className="help-block">(No company groups exists)</div>
          {uiHelpBlock}
        </div>
      );

      uiHelpBlock = null;
    }

    const uiCompanyFilters = (
      <div className="form-group">
        <label>Company groups to include in {user.firstName}&apos;s Intel Digest:</label>
        {uiHelpBlock}
        <div className="email-review-config-modal--groups">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={!this.state.uiReviewSettingsGroups}
                onChange={() => this.handleReviewSettingsInput('uiReviewSettingsGroups', null, false)} /> <em>All Companies</em>
            </label>
          </div>
          {uiRivalGroups}
          {uiEmptyRivalGroups}
        </div>
      </div>
    );

    const uiModalContent = (
      <div>
        <h4 className="heading-dialog">Customize <strong>{user.firstName}</strong>&apos;s Intel Digest</h4>
        <div className="u-pt-xs">
          <div className="radio-inline">
            <label>
              <input
                type="radio"
                checked={this.state.uiReviewSettingsEnabled}
                onChange={() => this.handleReviewSettingsInput('uiReviewSettingsEnabled', true, false)} /> Enabled
            </label>
          </div>
          <div className="radio-inline" style={{marginLeft: '20px'}}>
            <label>
              <input
                type="radio"
                checked={!this.state.uiReviewSettingsEnabled}
                onChange={() => this.handleReviewSettingsInput('uiReviewSettingsEnabled', false, false)} /> Disabled
            </label>
          </div>
        </div>
        <p className="help-block u-mb-m">
          The <strong>{customIntelDigestName || 'Klue Intel Digest'}</strong> email includes selected Feed posts.
        </p>
        {this.state.uiReviewSettingsEnabled ? uiCompanyFilters : null}
        <div className="row dialog-toolbar">
          <div className="col-sm-5">
            <div className="button button--disabled" onClick={this.handleCancelEmailReviewSettingsClick} style={{width: '100%'}}>Cancel</div>
          </div>
          <div className="col-sm-7">
            <div className="button" onClick={() => this.handleSaveEmailReviewSettingsClick(user)} style={{width: '100%'}}>Save</div>
          </div>
        </div>
      </div>
    );

    let unsuppressCallback = null;
    const {utils: {user: currentUser}} = this.context;

    if(userIsAdmin({user: currentUser})) {
      const {api: {userUnsuppress}} = this.context;

      unsuppressCallback = () => userUnsuppress(user, success => {
        if(!success) {
          return;
        }

        const {id} = user;

        this.loadUser(id);
      });
    }

    this.context.utils.dialog.create({
      id: 'modal-email-review', // calling with same id with just update the contents
      content: (renderSuppressionMessage(user, this.context.utils.dialog, unsuppressCallback)) || uiModalContent
    });
  };

  render() {
    const {user: currentUser} = this.props;
    const {user = {}, uiMode, uiMessage, newAvatarImageFullSize, avatarMode} = this.state;
    const {utils: {company}} = this.context;

    if(_.isEmpty(user)) {
      return false;
    }

    const userTitle = user.title ? `${user.title}, ` : '';
    const avatarClass = 'img-responsive avatar pull-left' + (user.deletedAt ? ' user-deleted' : '');
    const listItemClass = 'toggle-list_item';
    const listItemSelectableClass = `${listItemClass} toggle-list_item--selectable`;
    const canEditDetails = this._canEditProfile(user) && !userIsKluebot({userId: user.id, company});
    const userUIDefaultMode = (uiMode === 'profile-view');
    const currentUserIsCurator = userCanCurate({user: currentUser});
    const isCurrentUser = (user.id === currentUser.id);
    const {suppression: {reportedAt} = {}} = user;
    const actionTooltipText = 'action required! click for details';

    let bioRegion;
    let contactLink;
    let kluebotLink;
    let lastSeenAt;
    let userFlag;
    let inMail;
    let subscriptions;
    let userUIDisplay;
    let userUIAdminControls;
    let userUIProfileEditLinks;
    let userUIEdit;
    let userUIEditProfile;
    let userUIEditPassword;
    let userUIEditEmails;
    let userUIEditMessage;

    if(user.bio) {
      bioRegion = (
        <p className="bio">{user.bio.trim()}</p>
      );
    }

    if(user.lastSeenAt) {
      const lastSeenMoment = moment(user.lastSeenAt);
      const lastSeenTime = moment().diff(lastSeenMoment, 'seconds') < 1 ? 'just now' : lastSeenMoment.fromNow();

      lastSeenAt = (
        <time dateTime={user.lastSeenAt} title={new Date(user.lastSeenAt)}>{lastSeenTime}</time>
      );
    }
    else {
      lastSeenAt = (
        <span>Never</span>
      );
    }

    if(user.deletedAt) {
      userFlag = (
        <span className="label label-warning">Deactivated</span>
      );
    }

    // email notifications & intel digest
    if(this._canEditProfile(user)) {
      // current user
      const emailNotifyLabel = user.emailNotify ? 'Unsubscribe' : 'Subscribe';
      const emailNotifyStatus = user.emailNotify ? ' toggle-list_item--selected' : '';
      const emailDigestLabel = user.emailDigest ? 'Unsubscribe' : 'Subscribe';
      const emailDigestStatus = user.emailDigest ? ' toggle-list_item--selected' : '';
      const hideEmailSuppressionStyling = Boolean(!reportedAt);

      let digestReviewSettingsRegion;

      if(this._canEditEmailReviewSettings(user)) {
        digestReviewSettingsRegion = (
          <span>
            <span className="toggle-list_item_action" onClick={this.renderEmailReviewConfigModal}><strong>Settings</strong></span>
            <span className="toggle-list_item_separator">|</span>
          </span>
        );
      }

      const digestSettingsRegion = (
        <li
          key="review_status"
          className={classNames(`${listItemSelectableClass}${emailDigestStatus}`, {'un-suppressed': hideEmailSuppressionStyling})}
          data-tip={actionTooltipText}
          data-place="left"
          data-tip-disable={hideEmailSuppressionStyling}>
          {reportedAt && <Icon icon="caution" width={20} height={20} className="suppressed-caution" onClick={this.handleToggleEmailDigestClick} />}
          <div
            className="toggle-list_item_label"
            onClick={this.handleToggleEmailDigestClick}>
            Klue <strong>Intel Digest</strong></div>
          <div className="toggle-list_item_actions">
            {digestReviewSettingsRegion}
            <span className="toggle-list_item_action" onClick={this.handleToggleEmailDigestClick}>{emailDigestLabel}</span>
          </div>
        </li>
      );

      subscriptions = (
        <div id="subs"
          className={classNames('user-preferences user_email-preferences', {'un-suppressed': hideEmailSuppressionStyling})}>
          <h6>Email Preferences:</h6>
          <ul className="toggle-list">
            <li
              className={`${listItemSelectableClass}${emailNotifyStatus}`}
              onClick={() => this.handleToggleEmailNotifyClick(user)}
              data-tip={actionTooltipText}
              data-place="left"
              data-tip-disable={hideEmailSuppressionStyling}>
              {reportedAt && <Icon icon="caution" width={20} height={20} className="suppressed-caution" title="Email suppressed" />}
              <div className="toggle-list_item_label">
                <strong>@mention</strong>, <strong>Intercom</strong> emails
              </div>
              <div className="toggle-list_item_actions">
                <span className="toggle-list_item_action">{emailNotifyLabel}</span>
              </div>
            </li>
            {digestSettingsRegion}
          </ul>
        </div>
      );

      if(user.inMails && user.inMails.length) {
        inMail = (
          <div className="inmail-block card center-block">
            <div className="card-block">
              <h4><i className="fa fa-envelope-o icon-space-after" /> Email Integration with Klue</h4>
              <p>
                <strong>Send website URLs or text to your unique email address, below.</strong><br />
                Emailed content will appear in your feed as posted by you.
              </p>
              <div className="form-group">
                <div className="input-group">
                  <input
                    ref="inMail"
                    className="form-control readonly"
                    type="text"
                    readOnly={true}
                    value={this.handleInMailValue.call(this, user.inMails[0])}
                    onFocus={() => this.handleInMailToggle(true)}
                    onBlur={() => this.handleInMailToggle(false)} />
                  <div className="input-group-btn">
                    <CopyButton
                      buttonClass="btn btn-success"
                      label="Copy"
                      labelClicked="Copied!"
                      iconClass="fa fa-clipboard icon-space-after"
                      tooltip="Copy shareable link"
                      dataAction="copyShareableLink"
                      onCopyText={this.handleInMailClick} />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div><small>Each user has their own unique <strong>Klue InMail</strong> address. Please don&apos;t share it.</small></div>
              </div>
            </div>
          </div>
        );
      }
    }

    if(!isCurrentUser) {
      let userName;

      if(userIsKluebot({userId: user.id, company: this.context.utils.company})) {
        userName = 'Kluebot';

        kluebotLink = (
          <div className="inmail-block card center-block">
            <div className="card-block">
              <h4><i className="fa fa-info-circle icon-space-after" /> Have a Question About Using Klue?</h4>
              <div>You can contact <strong>Kluebot</strong> directly at <a href={'mailto:' + user.email}>{user.email}</a>.</div>
            </div>
          </div>
        );
      }
      else {
        userName = user.firstName;
      }

      contactLink = (
        <h4 className="card-email"><a href="#" onClick={this.handleContactClick}><i className="fa fa-comments-o" />Email {userName}</a></h4>
      );
    }

    // profile editing
    if(canEditDetails) {
      const uiPasswordDropdownEditOption = {
        value: 1,
        label: 'Change Password',
        icon: 'fa-key', onOptionClick: this.handleSetUIStatePasswordEditClick
      };
      const userUIdropdownEditOptions = [
        {value: 0, label: 'Edit Profile', icon: 'fa-pencil', onOptionClick: this.handleSetUIStateProfileEditClick},
        {value: 2, label: 'Email Addresses', icon: 'fa-at', onOptionClick: this.handleSetUIStateEmailEditClick}
      ];
      let userUIAdminCuratorToggle;

      if(user.passwordAuthEnabled) {
        userUIdropdownEditOptions.splice(1, 0, uiPasswordDropdownEditOption);
      }

      if(isCurrentUser) {
        // note: wrapped in h2 to provide consistant UI spacing
        userUIProfileEditLinks = (
          <h2>
            <Dropdown
              options={userUIdropdownEditOptions}
              condensed={true}
              className="btn btn-dropdown-card" />
          </h2>
        );
      }

      if(!isCurrentUser) {
        const adminUISubscriptions = subscriptions;
        let uiKlueStaffControls;

        subscriptions = null;

        const userIsCurator = userCanCurate({user});
        const curatorToggleClasses = classNames(listItemSelectableClass, {
          'toggle-list_item--selected': userIsCurator
        });

        userUIAdminCuratorToggle = (
          <li
            className={curatorToggleClasses}
            onClick={() => this.handleToggleCuratorClick(user, !userIsCurator)}>
            <div className="toggle-list_item_label">
              <strong>Curator Access</strong>
            </div>
            <div className="toggle-list_item_actions">
              <span className="toggle-list_item_action">{userIsCurator ? 'Remove Curator Access' : 'Promote to Curator'}</span>
            </div>
          </li>
        );

        if(this.context.utils.userIsKluebot()) {
          uiKlueStaffControls = (
            <div>
              <div>
                <a
                  href="#"
                  onClick={this.handleSetUIStateEmailEditClick}>
                  <i className="fa fa-pencil" /><strong>Edit {user.firstName}&apos;s Email Domains</strong>
                </a>
              </div>
              {/*
                currently, password editing won't work for another user (because it uses a traditional form which posts
                the changed password to a handler which only applies updates as the current user)
              */}
              {/*
                <div>
                  <a
                    href="#"
                    onClick={this.handleSetUIStatePasswordEditClick}>
                    <i className="fa fa-pencil" /><strong>Edit {user.firstName}&apos;s Password</strong>
                  </a>
                </div>
              */}
            </div>
          );
        }

        userUIAdminControls = (
          <div className="well user-admin">
            <h4>User Administration</h4>
            <div className="u-mb-m">
              <div>
                <a
                  href="#"
                  onClick={this.handleSetUIStateProfileEditClick}>
                  <i className="fa fa-pencil" /><strong>Edit {user.firstName}&apos;s Profile</strong>
                </a>
              </div>
              {uiKlueStaffControls}
            </div>
            <div className="user-preferences">
              <h6>User Access:</h6>
              <ul className="toggle-list">
                {userUIAdminCuratorToggle}
              </ul>
            </div>
            {adminUISubscriptions}
          </div>
        );
      }

      if(!_.isEmpty(uiMessage)) {
        const {error = '', message = ''} = (uiMessage || {});
        const uiMessageClasses = classNames('alert fadein alert-dismissible', {
          'alert-danger': Boolean(error),
          'alert-success': !error
        });

        userUIEditMessage = (
          <div className={uiMessageClasses}>
            {message}<span className="close" onClick={() => this.setUIEditProfileMessage(null)}>&times;</span>
          </div>
        );
      }
    }

    userUIDisplay = (
      <div className="card-block">
        {userUIProfileEditLinks}
        <h2>
          {user.name}
          {userFlag}
        </h2>
        <h4>{userTitle} <Link to="/users" className="profile-company">{user.companyName}</Link></h4>
        {bioRegion}
        {
          (currentUserIsCurator || isCurrentUser) && (
            <p>
              <strong className="text-uppercase">Joined:</strong> <time
                dateTime={user.createdAt}
                title={new Date(user.createdAt)}>{moment(user.createdAt).fromNow()}
              </time>
              <br />
              <strong className="text-uppercase">Last Seen: </strong> {lastSeenAt}
            </p>
          )
        }
        {subscriptions}
        {userUIAdminControls}
      </div>
    );

    // Profile View States
    if(canEditDetails) {
      if(uiMode === 'profile-edit') {
        userUIEditProfile = this.getUIProfileEdit(user);
        userUIDisplay = null;
      }
      else if(uiMode === 'password-edit') {
        userUIEditPassword = this.getUIPasswordChange(user);
        userUIDisplay = null;
      }
      else if(uiMode === 'email-edit') {
        userUIEditEmails = this.getUIEmailChange(user);
        userUIDisplay = null;
      }

      if(!userUIDefaultMode) {
        userUIEdit = (
          <div className="card-block">
            {userUIEditMessage}
            {userUIEditProfile}
            {userUIEditPassword}
            {userUIEditEmails}
          </div>
        );
      }
    }

    const avatarImageURL = newAvatarImageFullSize || user.imageLrg;
    let avatarUploadUI;

    if(this._canEditProfile(user) && !avatarMode) {
      avatarUploadUI = (
        <div className="avatar-edit">
          <div className="avatar-edit-content">
            <div className="avatar-edit-icon"><i className="fa fa-camera" /></div>
            Upload New<br />Picture
          </div>
          <ReactS3Uploader
            ref="avatarImageInput"
            getSignedUrl={(f, cb) => getSignedUrl(f, cb, this.avatarOnUploadError)}
            usePostForm={true}
            accept="image/*"
            preprocess={this.avatarOnUploadStart}
            onProgress={this.avatarOnUploadProgress}
            onError={this.avatarOnUploadError}
            onFinish={this.avatarOnUploadFinish}
            uploadRequestHeaders={{'x-amz-acl': 'public-read'}} />
        </div>
      );
    }
    else if(avatarMode === 'uploading') {
      avatarUploadUI = (
        <div className="avatar-uploading" />
      );
    }
    else if(avatarMode === 'uploaded') {
      avatarUploadUI = (
        <div className="avatar-uploaded">
          <div className="avatar-uploaded-icon"><i className="fa fa-check-circle" /></div>
        </div>
      );
    }

    const {
      name, username, imageLrg,
      postsCount = 0, commentsCount = 0, likesCount = 0, curatingProfilesCount = 0
    } = user;

    return (
      <section className="layout-generic user-profile">
        <ReactTooltip
          class="tooltip"
          html={true}
          effect="solid"
          place="top" />
        <div className="container">
          <div className="card center-block">
            <div className="card-block text-right clearfix card-block--bottom0">
              <div className={avatarClass} style={{backgroundImage: `url("${avatarImageURL}")`}} onClick={this.handleAvatarUploadClick}>
                {avatarUploadUI}
                <div className="avatar-profile-image-container">
                  <img src={imageLrg} alt={name} className="img-responsive" loading="lazy" />
                </div>
              </div>
              <h1 className="card-title">@{username}</h1>
              {currentUserIsCurator && contactLink}
            </div>
            {userUIDisplay}
            {userUIEdit}
            <div className="card-footer">
              <div className="row text-center">
                <div className="col-xs-3">
                  <h3><span className="fa fa-newspaper-o" />{postsCount.toLocaleString()}</h3>
                  <h4>{pluralize('Post', postsCount)}</h4>
                </div>
                <div className="col-xs-3">
                  <h3><span className="fa fa-comment-o" />{commentsCount.toLocaleString()}</h3>
                  <h4>{pluralize('Comment', commentsCount)}</h4>
                </div>
                <div className="col-xs-3">
                  <h3><span className="fa fa-thumbs-o-up" />{likesCount.toLocaleString()}</h3>
                  <h4>{pluralize('Like', likesCount)}</h4>
                </div>
                <div className="col-xs-3">
                  <h3><span className="fa fa-file-text-o" />{curatingProfilesCount.toLocaleString()}</h3>
                  <h4>{pluralize('Board', curatingProfilesCount)}</h4>
                </div>
              </div>
            </div>
          </div>
          <br />
          {inMail}
          {kluebotLink}
        </div>
      </section>
    );
  }

}

export default withRouter(User);
