import {useState, useEffect} from 'react';
import {DropTarget} from 'react-dnd';
import {DragTypes} from '../modules/constants/dnd';
import {useCardDragging} from '../contexts/_cardDragging';
import classNames from 'classnames';

const itemTarget = {
  canDrop() {
    return false;
  }
};

function collectItemTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

const DropTargetScroller = ({position, narrow, connectDropTarget, isOver, onDropTargetScrollerHover}) => {
  const {dragging} = useCardDragging();
  const [scrolling, setScrolling] = useState(false);
  const [on, setOn] = useState(false);

  useEffect(() => {
    if(!dragging) {
      return setOn(false);
    }

    const timeout = setTimeout(() => {
      setOn(dragging);
    }, 500);

    return () => clearTimeout(timeout);
  }, [dragging]);

  useEffect(() => {
    if(!scrolling) {
      return;
    }

    const interval = setInterval(() => {
      onDropTargetScrollerHover();
    }, 10);

    return () => clearInterval(interval);
  }, [scrolling, onDropTargetScrollerHover]);

  useEffect(() => {
    if(!isOver) {
      return setScrolling(false);
    }

    const delay = setTimeout(() => setScrolling(true), 500);

    return () => clearTimeout(delay);
  }, [isOver]);

  if(!on) {
    return null;
  }

  return (
    connectDropTarget(<div className={classNames('drop-target-scroller', {
      right: position === 'right',
      top: position === 'top',
      bottom: position === 'bottom',
      left: position === 'left',
      narrow
    })} />)
  );
};

DropTargetScroller.propTypes = {
  position: PropTypes.string,
  narrow: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  isOver: PropTypes.bool,
  onDropTargetScrollerHover: PropTypes.func
};

DropTargetScroller.defaultProps = {
  position: 'right',
  narrow: false,
  connectDropTarget() {},
  isOver: false,
  onDropTargetScrollerHover() {}
};

export default DropTarget([DragTypes.CARD], itemTarget, collectItemTarget)(DropTargetScroller);

