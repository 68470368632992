import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

const {default: Icon} = require('./_icon');

import PropTypes from 'prop-types';
import {redirectToV2} from '../modules/route_utils';

const NavigationDropdown = ({title, items}, context) => {
  const {
    appData: {v2Host}
  } = context;
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isActive = items?.some(({path}) => location?.pathname?.startsWith(path));

  items.sort((itemA, itemB) => itemA.label.toLowerCase().localeCompare(itemB.label.toLowerCase()));

  return (
    <div
      key={title}
      ref={dropdownRef}
      className={classNames('head-dropdown-group', {
        'head-dropdown-group--active': isOpen
      })}>
      <div
        className={classNames('nav-dropdown', {
          'nav-dropdown--active': isOpen || isActive
        })}>
        <a
          href="#"
          onClick={toggleDropdown}
          data-tracking-id={`app-nav-bar_${title}`}>
          <span>{title}</span>
          <span className="head-main-item_icon head-main-item_icon--right">
            <Icon icon="downArrow" width="16" height="16" />
          </span>
        </a>
      </div>

      <div className="nav-dropdown_container">
        <div className="head-dropdown-grid">
          {items.map(({id, label, path, isV2, icon, hasNewBadge, hasBetaBadge, hasUpgradeBadge}) => {
            return (
              <div key={id} className="nav-dropdown_container_item">
                <a
                  href={isV2 ? `https://${v2Host}${path}` : path}
                  className={classNames(
                    'nav-dropdown_container_item_link head-main-item--truncate',
                    {
                      'nav-dropdown_container_item_link--active':
                        location?.pathname?.startsWith(path)
                    }
                  )}
                  data-tracking-id={`app-nav-bar_${id}`}
                  data-test-id={`app-nav-bar_${id}`}
                  onClick={event => {
                    event && event.preventDefault();

                    if(isV2) {
                      redirectToV2({v2Host, v2Path: path});
                    }
                    else {
                      history.push(path);
                    }

                    setIsOpen(false);
                  }}>
                  <div className="head-main-item_icon head-main-item_icon--left ">
                    <Icon icon={icon} />
                  </div>
                  <span className="head-main-item_label">
                    <span>{label}</span>
                  </span>
                  {hasNewBadge && (
                    <div className="head-main-item_badge">
                      <span>NEW</span>
                    </div>
                  )}
                  {hasUpgradeBadge && (
                    <div className="head-main-item_badge variant-purple">
                      <span>UPGRADE</span>
                    </div>
                  )}
                  {hasBetaBadge && (
                    <div className="head-main-item_badge variant-purple">
                      <span>BETA</span>
                    </div>
                  )}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

NavigationDropdown.contextTypes = {
  appData: PropTypes.object.isRequired
};
NavigationDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  items:
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        isV2: PropTypes.bool.isRequired
      })
    ).isRequired
};

export default NavigationDropdown;
