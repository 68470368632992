import DashboardEditGroupRivals from './_dashboard_edit_group_rivals';
import DashboardGroup from './_dashboard_group';
import Icon from './_icon';

import DraggableItem from './_draggable_item';
const DashboardGroupDraggable = DraggableItem('DASHBOARD-DD-GROUP');

/* eslint-disable react/no-multi-comp */
const DashboardEditGroupsList = props => {
  const {
    className, rivalGroups, unfilteredRivals, activeEditGroupId, editGroupId, groupPositions, highlightedGroups, isLoaded,
    onEditGroupClick, onSaveGroupClick, onDeleteGroupClick,
    onReorderGroupDrag, onReorderGroupDrop, onReorderGroupMove,
    onToggleGroupClick
  } = props;

  if(!rivalGroups || !groupPositions || (rivalGroups.length !== groupPositions.length)) {
    return null;
  }

  const groupNodes = groupPositions.map((groupId, index) => {
    const isActive = (groupId === activeEditGroupId);
    const isEditing = (groupId === editGroupId);
    const isHighlighted = highlightedGroups.slice().includes(groupId);
    const group = rivalGroups.find(g => g.id === groupId);
    const dragHandle = (
      <div className="dashboard-group_icon dashboard-group_icon--reorder">
        <Icon icon="reorder" width="20" height="20" />
      </div>
    );

    return (
      <DashboardGroupDraggable
        key={`group_${group.id}`}
        id={group.id}
        canDrag={true}
        canDrop={true}
        onBeginDrag={onReorderGroupDrag}
        onEndDrag={() => onReorderGroupDrag(false)}
        onHandleHover={onReorderGroupMove}
        onHandleDrop={onReorderGroupDrop}
        dragHandle={dragHandle}
        droppableCardIndex={index}>
        <DashboardGroup
          group={group}
          isHighlighted={isHighlighted}
          isActive={isActive}
          isEditing={isEditing}
          onToggle={onToggleGroupClick}
          onEdit={e => onEditGroupClick(group, e)}
          onSave={(name, e) => onSaveGroupClick(group, name, e)}
          onDelete={e => onDeleteGroupClick(group, e)}
          onCancel={e => onEditGroupClick(null, e)}
          isLoaded={isLoaded}
          modifier={'title reorder'}
          label={group.name || '(Untitled)'}>
          <DashboardEditGroupRivals
            group={group}
            {...props}
            rivals={unfilteredRivals} />
        </DashboardGroup>
      </DashboardGroupDraggable>
    );
  });

  if(groupPositions.length) {
    return (
      <div className={className}>
        {groupNodes}
      </div>
    );
  }

  return (
    <div className="dashboard_empty-message">
      Your organization&apos;s curators haven&apos;t created any company groups in Klue yet. Check back later for more!
    </div>
  );
};

DashboardEditGroupsList.propTypes = {
  className: PropTypes.string,
  rivalGroups: PropTypes.arrayOf(PropTypes.object),
  unfilteredRivals: PropTypes.arrayOf(PropTypes.object),
  activeEditGroupId: PropTypes.number,
  editGroupId: PropTypes.number,
  groupPositions: PropTypes.arrayOf(PropTypes.number),
  highlightedGroups: PropTypes.arrayOf(PropTypes.number),
  isLoaded: PropTypes.bool,
  onEditGroupClick: PropTypes.func.isRequired,
  onSaveGroupClick: PropTypes.func.isRequired,
  onDeleteGroupClick: PropTypes.func.isRequired,
  onReorderGroupDrag: PropTypes.func.isRequired,
  onReorderGroupDrop: PropTypes.func.isRequired,
  onReorderGroupMove: PropTypes.func.isRequired,
  onToggleGroupClick: PropTypes.func.isRequired
};

DashboardEditGroupsList.defaultProps = {
  className: '',
  rivalGroups: [],
  unfilteredRivals: [],
  activeEditGroupId: 0,
  editGroupId: 0,
  groupPositions: [],
  highlightedGroups: [],
  isLoaded: false,
  onEditGroupClick() {},
  onSaveGroupClick() {},
  onDeleteGroupClick() {},
  onReorderGroupDrag() {},
  onReorderGroupDrop() {},
  onReorderGroupMove() {},
  onToggleGroupClick() {}
};

export default DashboardEditGroupsList;
