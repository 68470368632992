const ReportDataInaccuracies = ({rival, lane, cardURL, cardTitle, visible, user}) => {
  if(!visible) {
    return null;
  }

  const submitDataInaccuracy = () => {
    const {name: profileName} = rival || {};
    const laneName = lane?.name || 'unknown';

    let message = 'I\'d like to report an issue with the data in an automated card:\n\n';
    const cardRef = cardTitle ? `${cardTitle}` : 'untitled';
    const userMessage = (user
      ? `${user.firstName}, please`
      : 'Please') + ' click \'Submit\' to send this request.\n';

    message += `Board: ${profileName || 'unknown'}\n`;
    message += `Lane: ${laneName}\n`;
    message += `Card: ${cardRef}\n`;

    if(cardURL) {
      message += `${cardURL}\n`;
    }

    message += '----------------------------------------\n';
    message += userMessage;

    window?.Intercom('showNewMessage', message);
  };

  return (
    <div className="automated-card_data-inaccuracies">
      <div className="data-inaccuracies_title" onClick={submitDataInaccuracy}>
        Report missing / inaccurate data
      </div>
    </div>
  );
};

ReportDataInaccuracies.propTypes = {
  rival: PropTypes.object,
  lane: PropTypes.object,
  cardURL: PropTypes.string,
  cardTitle: PropTypes.string,
  visible: PropTypes.bool,
  user: PropTypes.object
};

ReportDataInaccuracies.defaultProps = {
  rival: null,
  lane: null,
  cardURL: null,
  cardTitle: '',
  visible: false,
  user: null
};

export default ReportDataInaccuracies;
