import {Field} from 'react-final-form';

const Condition = ({when, is, children}) => (
  <Field name={when} subscription={{value: true}}>
    {({input: {value}}) => (is(value) ? children : null)}
  </Field>
);

export default Condition;

Condition.propTypes = {
  when: PropTypes.string,
  is: PropTypes.func,
  children: PropTypes.node
};

Condition.defaultProps = {
  when: [],
  is() {},
  children: null
};
