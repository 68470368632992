import {DEFAULTS} from 'froala-editor';

export const EDITOR_PLACEHOLDER_CLASS = 'attachment-placeholder';
export const EDITOR_IMG_PLACEHOLDER_CLASS = 'editor-img-placeholder';
export const FORMULA_REGEX = /{\s*{([^}]+)}\s*}/gim;
export const COMPLETE_FORMULA_REGEX = /^.+?\(.+\)$/im;   // indicates a complete formula with arguments (not a placeholder)
export const CARD_WRAPPER_CLASSNAME = 'card-static-html';
export const CARD_BODY_CLASSNAME = 'card-static-html_body';
export const CARD_TITLE_CLASSNAME = 'card-static-html_title';
export const INTERNAL_LINK_CLASSNAME = 'card-internal-link';
export const EXTERNAL_LINK_CLASSNAME = 'card-external-link';
export const IMAGE_LINK_CLASSNAME = 'card-image-link';
export const TABLE_WRAP_CLASSNAME = 'table-scrollwrap';
export const TABLE_WRAP_CELL_EXPAND_CLASSNAME = 'nowrap';

export const toolbarButtons = [
  ['paragraphFormat', 'fontSizeLabelled', 'bold', 'italic', 'klueClearFormatting'],
  ['textColor', 'backgroundColor'],
  ['listTypesButton', 'align'],
  ['insertLink', 'insertTable', 'insertHR'],
  ['insertImage', 'insertVideo', 'iframeButton', 'klue-attachments'], ['fullscreen']];

export const imageEditButtons = ['imageRemove', 'imageReplace', '|', 'imageStyle', '|', 'imageDisplayInline', 'imageAlign',
  '|', 'imageLink', 'linkEdit', 'linkRemove', 'contentZoomToggle'];

export const shortcutsEnabled = ['bold', 'italic', 'indent', 'outdent', 'undo', 'redo', 'insertLink'];

export const EDITOR_DEFAULT_CONFIG = Object.freeze({
  attribution: false,
  autofocus: true,
  charCounterCount: false,
  editorClass: 'klue-editor',
  heightMax: 0,
  heightMin: 300,
  htmlAllowedEmptyTags: [...DEFAULTS.htmlAllowedEmptyTags, 'i'],
  pasteAllowedStyleProps: ['font-size', 'color', 'text-align', 'font-weight', 'background-color'],
  pastePlain: false,
  imageDefaultMargin: 0,
  imageDefaultWidth: 0,
  imageEditButtons,
  imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
  imageResize: false,
  imageMaxSize: 1024 * 1024 * 3, // TODO: this is a totally arbitrary 3MB image max size (froala default is 10MB)
  imageMultipleStyles: false,
  imageStyles: {
    'klue-img--thumb': 'Thumbnail',
    'klue-img--sm': 'Small',
    'klue-img--med': 'Medium',
    'klue-img--lg': 'Large',
    'klue-img--fullwidth': 'Full Width'
  },
  imageUploadRemoteUrls: false,
  linkAlwaysBlank: true,
  linkAutoPrefix: 'https://',
  linkEditButtons: ['linkEdit', 'linkRemove'],
  linkInsertButtons: ['linkBack'],
  listAdvancedTypes: false,
  paragraphFormat: {
    H1: 'Heading',
    H2: 'Subheading',
    N: 'Normal'
  },
  pasteDeniedAttrs: ['class', 'id'],
  pasteDeniedTags: ['div', 'article', 'section', 'time', 'header', 'blockquote'],
  quickInsertEnabled: false,
  scrollableContainer: '.modal-editor',
  shortcutsEnabled,
  tableCellMultipleStyles: true,
  tableResizer: true,
  tableResizingLimit: 5,
  tableStyles: {
    'table-borders--none': 'No borders',
    'table-borders--inner': 'Inner borders',
    'table-borders--all': 'All borders'
  },
  tableCellStyles: {
    'table-cell-header': 'Header',
    'table-cell-highlighted': 'Highlighted',
    'table-cell-body': 'Body'
  },
  tableEditButtons: ['tableRemove', '|', 'tableHeader', 'tableRows', 'tableColumns', 'tableStyle',
    '-', 'tableCells', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'tableCellStyle', 'toggleTableWrapping', 'setTableSize'],
  tableInsertHelper: false,
  tableMultipleStyles: false,
  toolbarButtons,
  videoDefaultWidth: 0,
  videoEditButtons: ['videoRemove', 'videoReplace'],
  videoInsertButtons: ['videoByURL'],
  videoMove: false,
  videoResize: false,
  videoResponsive: true,
  videoUpload: false,
  wordPasteModal: false,
  iframeEditButtons: ['iframeRemove', 'iframeReplace'],
  colorsHEXInput: false,
  colorsStep: 4
});

export const editorFontSizes = Object.freeze({
  SMALL: '11px',
  MEDIUM: '15px',
  LARGE: '20px',
  XLARGE: '24px'
});

export const editorStatusMessages = Object.freeze({
  UPLOADING: 'Uploading in progress..'
});

export const notificationMessages = Object.freeze({
  FORM_INVALID: 'Cards must contain a title.',
  LOCAL_COPY: 'You\'re viewing an unsaved version of this card.'
});

export const notificationTypes = Object.freeze({
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success'
});

export const formulaToKeyMappings = Object.freeze({
  KEY_PEOPLE: 'keyPeople',
  BALANCE_SHEET_STATEMENTS: 'balanceSheetStatements',
  BALANCE_SHEET_STATEMENTS_QUARTERLY: 'balanceSheetStatementsQuarterly',
  CASH_FLOW_STATEMENTS: 'cashFlowStatements',
  CASH_FLOW_STATEMENTS_QUARTERLY: 'cashFlowStatementsQuarterly',
  COMPARE_EMPLOYEE_COUNT: 'compareEmployeeCount',
  EMPLOYEE_COUNT: 'employeeCount',
  HOMEPAGE_SCREENSHOT: 'homepageScreenshot',
  OPERATING_METRIC: 'operatingMetric',
  INCOME_STATEMENTS: 'incomeStatements',
  INCOME_STATEMENTS_QUARTERLY: 'incomeStatementsQuarterly',
  JOB_CATEGORIES: 'jobCategories',
  JOB_POSTINGS: 'jobPostings',
  JOB_POSTINGS_BY_REGION: 'jobPostingsByRegion',
  OFFICE_LOCATIONS: 'officeLocations',
  OPERATING_METRICS_SUMMARY: 'operatingMetricsSummary',
  REVENUES: 'revenues',
  COMPARE_REVENUES: 'compareRevenues',
  SIMILAR_WEBSITES: 'similarWebsites',
  ENGAGEMENT_SUMMARY: 'engagementSummary',
  WEBSITE_TRAFFIC_ORGANIC_KEYWORDS: 'websiteTrafficOrganicKeywords',
  TRAFFIC_PAGE_VIEWS: 'trafficPageViews',
  WEBSITE_TRAFFIC_PAID_KEYWORDS: 'websiteTrafficPaidKeywords',
  TRAFFIC_SOURCES: 'trafficSources',
  RECENT_PRESS_RELEASES: 'recentPressReleases',
  RECENT_BLOG_POSTS: 'recentBlogPosts',
  RECENT_SOCIAL_UPDATES: 'recentSocialUpdates',
  RECENT_CASE_STUDIES: 'recentCaseStudies',
  RECENT_PUBLICATIONS: 'recentPublications',
  COMPANY_SUMMARY: 'companyOverview',
  SFDC_WINS: 'sfdcWins',
  SFDC_LATEST_OPPORTUNITIES: 'sfdcLatestOpportunities'
});

export const formulaKeyMappings = Object.freeze({
  officeLocations: {label: 'Employee Locations', type: 'dynamic'},
  compareEmployeeCount: {label: 'Employee Growth', type: 'dynamic'},
  employeeCount: {label: 'Employee Count', type: 'dynamic'},
  jobCategories: {label: 'Job Categories', type: 'dynamic'},
  jobPostings: {label: 'Job Postings', type: 'dynamic'},
  jobPostingsByRegion: {label: 'Job Postings by Region', type: 'dynamic'},
  keyPeople: {label: 'Key People', type: 'dynamic'},
  balanceSheetStatements: {label: 'Balance Sheet - Year', type: 'dynamic'},
  balanceSheetStatementsQuarterly: {label: 'Balance Sheet - Quarter', type: 'dynamic'},
  cashFlowStatements: {label: 'Cash Flow - Year', type: 'dynamic'},
  cashFlowStatementsQuarterly: {label: 'Cash Flow - Quarter', type: 'dynamic'},
  incomeStatements: {label: 'Income Statements - Year', type: 'dynamic'},
  incomeStatementsQuarterly: {label: 'Income Statements - Quarter', type: 'dynamic'},
  operatingMetricsSummary: {label: 'Operating Metrics', type: 'dynamic'},
  revenues: {label: 'Revenue', type: 'dynamic'},
  compareRevenues: {label: 'Revenue Growth', type: 'dynamic'},
  recentCaseStudies: {label: 'Recent Case Studies', type: 'dynamic'},
  recentPublications: {label: 'Recent Publications', type: 'dynamic'},
  recentPressReleases: {label: 'Recent Press Releases', type: 'dynamic'},
  recentlyClosed: {label: 'Recently Closed', type: 'dynamic'},
  winRate: {label: 'Win Rate', type: 'dynamic'},
  recentSocialUpdates: {label: 'Recent Social Updates', type: 'dynamic'},
  engagementSummary: {label: 'Avg. Engagement per Visit', type: 'dynamic'},
  homepageScreenshot: {label: 'Homepage Screenshot', type: 'dynamic'},
  websiteTrafficOrganicKeywords: {label: 'Organic Search Keywords', type: 'dynamic'},
  trafficPageViews: {label: 'Page Views per Visit', type: 'dynamic'},
  websiteTrafficPaidKeywords: {label: 'Paid Search Keywords', type: 'dynamic'},
  recentBlogPosts: {label: 'Recent Blog Posts', type: 'dynamic'},
  trafficSources: {label: 'Traffic Share by Type', type: 'dynamic'},
  similarWebsites: {label: 'Similar Sites', type: 'dynamic'},
  sfdcWins: {label: 'Win Rate', type: 'static'},
  sfdcLatestOpportunities: {label: 'Latest Opportunities', type: 'static'}
});

export const videoProviders = Object.freeze([
  {
    test_regex: /(?:https?:\/\/)?(?:www\.)?(?:players.brightcove.net)\/([0-9a-zA-Z_]+)\/(?:[a-z]+_default\/index.html)(?:\?videoId=)([0-9]+)?/,
    url_regex: /(?:https?:\/\/)?(?:www\.)?(?:players.brightcove.net)\/([0-9a-zA-Z_]+)\/(?:[a-z]+_default\/index.html)(?:\?videoId=)([0-9]+)?/g,
    url_text: 'https://players.brightcove.net/$1/default_default/index.html?videoId=$2',
    html:
      `<iframe width="640" height="360" src="{url}" loading="lazy"
      allowfullscreen
      webkitallowfullscreen
      mozallowfullscreen></iframe>`,
    provider: 'brightcove'
  },
  {
    test_regex: /(?:https?:\/\/)?(?:.+)\.(?:wistia\.com|net)(?:\/[0-9a-zA-Z_]+)+\/(?:\w+)(?:.*)$/,
    url_regex: /(?:https?:\/\/)?(.+)\.(?:wistia\.)(com|net)(?:\/[0-9a-zA-Z_]+)+\/(\w+)(?:.*)$/g,
    url_text: 'https://$1.wistia.$2/embed/iframe/$3?videoFoam=true',
    html:
      `<iframe width="640" height="360" src="{url}" loading="lazy"
      allowfullscreen
      webkitallowfullscreen
      mozallowfullscreen
      msallowfullscreen></iframe>`,
    provider: 'wistia'
  }
]);

export const timeseriesFormulas = Object.freeze([
  'COMPARE_EMPLOYEE_COUNT',
  'COMPARE_REVENUES'
]);

export const editorTextColors = ['#011627', '#BDBDBD', '#FFFFFF', '#620DEC', '#3751FF', '#FFF500', '#EB111C', '#00874A', 'REMOVE'];

export const cardLinkSelectorWidth = 560;
export const cardLinkSelectorPadding = 20;
