import LaneItem from './_lane_item';
import LanesDropTarget from './_lanes_drop_target';
import Icon from './_icon';
import Spinner from './_spinner';
import {withCardDragging} from '../contexts/_cardDragging';

const Lanes = ({boards, onToggleLaneVisibility, onLanesDropHover, updatingBoardDrags, onLaneCardCountsChanged, cardDraggingContext}) => {
  return (
    <div className="board-lanes-list-container">
      <div className="board-lanes-left-region" />
      <div>
        <div className="vitals-toc_container_header board-lanes-items-header" data-tracking-id="board-lanes-items-header">
          <div className="board-lanes-items-header-name-block">
            <Icon icon="view_column" className="vitals-toc_container_header_icon" />
            <div className="vitals-toc_container_header_text">Lanes {`(${boards?.length || 0})`}</div>
          </div>
          {updatingBoardDrags ? <Spinner /> : null}
        </div>
        <div className="board-lanes-items-container">
          {boards?.map(({name, id}) => {
            const count = cardDraggingContext?.getCardCountForBoard(id);

            return (<LaneItem
              key={id}
              name={name}
              id={id}
              count={count}
              onDropSuccess={onLaneCardCountsChanged}
              onToggleLaneVisibility={onToggleLaneVisibility} />);
          })}
        </div>
      </div>
      <LanesDropTarget onLanesDropHover={onLanesDropHover} />
    </div>
  );
};

Lanes.propTypes = {
  boards: PropTypes.arrayOf(PropTypes.object).isRequired,
  onToggleLaneVisibility: PropTypes.func,
  onLanesDropHover: PropTypes.func,
  updatingBoardDrags: PropTypes.bool,
  onLaneCardCountsChanged: PropTypes.func,
  cardDraggingContext: PropTypes.object
};

Lanes.defaultProps = {
  onToggleLaneVisibility() {},
  onLanesDropHover() {},
  updatingBoardDrags: false,
  onLaneCardCountsChanged() {},
  cardDraggingContext: null
};

export default withCardDragging(Lanes);
