import {useState, useRef, useContext, useEffect} from 'react';

import TagItemHeader from './tag_item_header';
import TagError from './_tag_error';
import TagOnItemsLink from './_tag_on_item_link';

import ToggleSwitch from '../_toggle_switch';
import {tagUpdate} from '../../modules/api/tags';
import {TagErrorsContext} from '.';

const testIds = {
  klueTagListItem: 'manage-tag-list-item',
  klueTagViewContainer: 'manage-tag-view-container'
};

const KlueTagItem = ({tag, onUpdateTag}) => {
  const listItemRef = useRef();
  const {name, id, taggingsCount, isActive = false} = tag;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const {highlights} = useContext(TagErrorsContext);
  const [highlightTitle, setHightlightTitle] = useState(false);

  useEffect(() => {
    const conflictingName = highlights && highlights[id]?.conflictingName?.toLowerCase();

    if(!conflictingName) {
      return setHightlightTitle(false);
    }

    const isTitleHighlighted = conflictingName.toLowerCase() === name.toLowerCase();

    setHightlightTitle(isTitleHighlighted && !highlightTitle);

    if(isTitleHighlighted) {
      listItemRef?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [highlights, id, name, highlightTitle]);

  const handleChange = async () => {
    setError(null);
    setIsLoading(true);

    try {
      const {tag: updatedTag} = await tagUpdate({tag: {id: tag.id, isActive: !isActive}});

      onUpdateTag(updatedTag);
    }
    catch(err) {
      setError(err);
    }
    finally{
      setIsLoading(false);
    }
  };

  return (
    <li ref={listItemRef} data-testid={testIds.klueTagListItem} key={id}>
      <div data-testid={testIds.klueTagViewContainer} className="tag-container--view">
        <TagItemHeader title={name} isLoading={isLoading} isConflicted={highlightTitle} />
        <div className="tag-viewing-controls">
          <TagOnItemsLink count={taggingsCount} id={id} />
          <ToggleSwitch
            label={`Enable ${name}`}
            disabled={isLoading}
            checked={isActive}
            onChange={handleChange} />
        </div>
      </div>
      {error && <TagError error={error} />}
    </li>
  );
};

KlueTagItem.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    taggingsCount: PropTypes.number.isRequired,
    isActive: PropTypes.bool
  }).isRequired,
  onUpdateTag: PropTypes.func
};

KlueTagItem.defaultProps = {
  onUpdateTag() {}
};

export default KlueTagItem;
