import {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import Icon from './_icon';

const TagsInput = ({tags: propsTags, name, readonly, autoFocus, onChange, secondary, warning, validate}) => {
  const [tags, setTags] = useState(propsTags);
  const [inputPlaceholder, setInputPlaceholder] = useState('Add');
  const [validatonError, setValidationError] = useState(undefined);
  const inputRef = useRef(null);

  useEffect(() => {
    onChange(tags, name);
  }, [tags]);

  useEffect(() => {
    setTags(propsTags);
  }, [propsTags]);

  const clearInput = () => {
    inputRef.current.value = '';
    setValidationError(undefined);
  };

  const removeTags = indexToRemove => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = ({target: {value}}) => {
    if(value.trim() !== '' && !validatonError) {
      const updatedTags = [...tags, value];

      setTags(updatedTags);
      clearInput();
    }
  };

  const handleContainerClick = () =>
    !readonly && inputRef.current.focus();

  const handleFocus = () => {
    setInputPlaceholder('Press enter to add terms');
  };

  const handleBlur = e => {
    if(validatonError) {clearInput();}

    setInputPlaceholder('Add');
    addTags(e);
  };

  const handleKeyUp = e => {
    const {key} = e;

    if(key === 'Enter') {
      addTags(e);
    }
    else if(key === 'Escape') {
      clearInput();
    }
  };

  const handleValidaion = ({target: {value}}) => {
    const trimmedValue = value ? value.trim() : '';

    const validiationErrorMessage = validate(trimmedValue);

    if(!validiationErrorMessage) {
      setValidationError(undefined);
    }

    setValidationError(validiationErrorMessage);
  };

  const showValidaitonMessage = inputPlaceholder !== 'Add' && Boolean(validatonError);

  return (
    <div className={classNames('tags-input', {secondary, warning, readonly})} onClick={handleContainerClick}>
      <ul className="tags-container">
        {tags.map((tag, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${tag}_${index}`} className={classNames('tag', {'tag-deletable': !readonly})}>
            <span className="tag-title">{tag}</span>
            {
              !readonly && <div className="tag-close-icon-container"
                onClick={() => removeTags(index)}
                onMouseDown={e => {e.preventDefault();}}
                data-testid="delete-tag-button">
                <Icon icon="close"
                  width="14"
                  height="14"
                  className="tag-close-icon" />
              </div>
            }
          </li>
        ))}
      </ul>
      {
        !readonly &&
        (
          <span className={classNames('tags-input-container', {'has-error': showValidaitonMessage})}>
            <input
              autoFocus={autoFocus}
              ref={inputRef}
              name={name}
              type="text"
              onKeyUp={handleKeyUp}
              placeholder={inputPlaceholder}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleValidaion}
              data-testid="add-tag-input" />
            {showValidaitonMessage && <div className="error-message" data-testid="add-tag-error-message">{validatonError}</div>}
          </span>)
      }
    </div>
  );
};

TagsInput.propTypes = {
  tags: PropTypes.array,
  name: PropTypes.string,
  readonly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  secondary: PropTypes.bool,
  warning: PropTypes.bool,
  validate: PropTypes.func,
  onChange: PropTypes.func.isRequired
};

TagsInput.defaultProps = {
  tags: [],
  name: undefined,
  readonly: true,
  autoFocus: false,
  secondary: false,
  warning: false,
  validate() {}
};

export default TagsInput;
