import ContentZoom from './_content_zoom';
import Icon from './_icon';
import styled from 'styled-components';

import {cardGet, cardSnapshotsGet, cardUpdate} from '../modules/api/cards';
import {getCardHtml} from '../modules/card_utils';
import {isValidId} from '../modules/utils';
import {sanitizeInput, allowlistedTags, allowlistedAttributes, allowlistedSchemes} from '../modules/html_utils';
import {userCanCurate, userIsKluebot} from '../modules/roles_utils';

import {Link} from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import HtmlDiff from 'htmldiff-js';
import CardDisplay from './_card_display';

const DiffControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  padding-bottom: 10px;
`;

class Snapshots extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    cardId: PropTypes.number,
    rival: PropTypes.object,
    user: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    leftAdjustPx: PropTypes.number,
    onToggleClick: PropTypes.func.isRequired
  };

  static defaultProps = {
    cardId: 0,
    rival: {},
    user: {},
    users: [],
    leftAdjustPx: 0,
    onToggleClick: null
  };

  state = {
    card: {},
    cards: null,
    versionId: 0,
    totalItems: 0
  };

  componentDidMount() {
    this.loadSnapshots();
    window.addEventListener('keydown', this.handleKeyDown, false);    // IE9+
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown, false); // IE9+
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.cardId !== nextProps.cardId) {
      this.loadSnapshots(nextProps);
    }
  }

  handleKeyDown = event => {
    if(!this.activeDialog) {
      return;
    }

    if(event?.key === 'Escape') {
      event.preventDefault();

      const {utils: {dialog}} = this.context;

      dialog.remove(this.activeDialog);
      this.activeDialog = null;
    }
  };

  loadSnapshots = (props = this.props) => {
    const {cardId} = props;
    const cardOptions = {cardId};

    cardGet({cardOptions}).then(card => {
      console.log('Snapshots.componentDidMount: loaded active card: %o', card);

      this.setState({card});
    });

    cardSnapshotsGet(cardOptions).then(({items: cards, totalItems}) => {
      this.setState({
        cards,
        totalItems
      }, () => {
        console.log('Snapshots.componentDidMount: loaded snapshots: %o, total: %o', this.state.cards, this.state.totalItems);

        // request card editor users from snapshots as needed
        // NOTE: parseInt is required for versionAuthorId as papertrail returns a string id (see PR #4027 comments)
        (this.state.cards || []).forEach(({versionAuthorId: userId}) => this.context.utils.requestUser({userId: parseInt(userId, 10)}));
      });
    });
  };

  handleVersionClick = versionId => {
    this.setState(prevState => {
      console.log('Snapshots.handleVersionClick: selected versionId #%o for cardId #%o', versionId, this.props.cardId);

      return {
        versionId: prevState.versionId === versionId ? 0 : versionId
      };
    });
  };

  renderDiffCard = (card, html, active) => {
    if(!card) {
      return null;
    }

    const {data: {name = ''}, updatedAt} = card;
    const cardClasses = classNames('snapshots_card', {
      'snapshots_card--active': active
    });
    const cardContentClasses = classNames({
      'card-wysiwyg-content_inner': true,
      'card-wysiwyg-content_inner--cols': card.templateName === 'CardTextHtmlCols'
    });

    return (
      <div className={cardClasses}>
        <div className="snapshots_card_header">
          <div className="snapshots_card_title">{name || '(Untitled card)'}</div>
          <div className="snapshots_card_date">
            {moment(updatedAt).fromNow()}
          </div>
        </div>
        <div className="snapshots_card_body card-static-html_body">
          <ContentZoom
            zoomChildLinkedImages={true}
            zoomChildImages={true}
            zoomCursorOnImages={true}>
            <div className="snapshot-text-diff">
              <div className="snapshot-text-diff_inner">
                <div className={cardContentClasses}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeInput(html, {
                      allowedTags: [...allowlistedTags, 'del', 'ins'],
                      allowedAttributes: allowlistedAttributes,
                      allowedSchemes: allowlistedSchemes
                    })
                  }} />
              </div>
            </div>
          </ContentZoom>
        </div>
      </div>
    );
  };

  handleRevertClick = (cardVersion = {}, event) => {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const revertAction = () => {
      const {card = {}} = this.state;
      const {id, data: {name = '', textHtml = '', listRows = [], hasDynamicBlocks}} = cardVersion;

      if(_.isEmpty(card) || _.isEmpty(cardVersion)) {
        return;
      }

      const cardOptions = {
        id,
        data: {
          data: Object.assign({}, card.data, {
            name,
            textHtml,
            listRows,
            hasDynamicBlocks
          })
        }
      };

      cardUpdate(cardOptions).then(updatedCard => {
        console.log('Snapshots.handleRevertClick: reverted to version #%o successfully: %o', cardVersion, updatedCard);

        // TODO: use another notification method (redux) here instead of Mediator pub-sub
        klueMediator.publish(`klue:profile:refreshCard:${id}`, updatedCard.data);
      });
    };

    this.context.utils.dialog.confirm({
      message: 'Revert the content of this card?',
      bodyContent: `This will revert the title and content of this card to your selected snapshot, "${cardVersion.data.name || '(Untitled)'}".`,
      okCallback: revertAction
    });
  };

  toggleDiffHighlighting = card => {
    const {card: originalCard} = this.state;
    const currentHtml = getCardHtml(originalCard);
    const historicalHtml = getCardHtml(card);
    const diffHtml = HtmlDiff.execute(historicalHtml, currentHtml);
    const {utils: {dialog}} = this.context;

    const handleOnCloseDiff = () => {
      dialog.remove('modal-card-snapshot-diff');
      this.activeDialog = null;
    };

    const uiModalContent = (
      <div className="snapshot-with-diffs">
        <div className="snapshot-diff-header">
          <div className="snapshot-diff-close" onClick={handleOnCloseDiff}>
            <Icon icon="close" width="24" height="24" />
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <div className="board-body" style={{width: '50%', marginRight: '12px'}}>
            <h4>Current Card</h4>
            {this.renderDiffCard(originalCard, currentHtml)}
          </div>
          <div className="board-body" style={{width: '50%'}}>
            <h4>Past Version</h4>
            {this.renderDiffCard(card, diffHtml, true)}
          </div>
        </div>
      </div>
    );

    dialog.create({
      id: 'modal-card-snapshot-diff',   // calling with same id with just update the contents
      content: uiModalContent,
      _diffModeWide: true,
      closeOnBgClick: true
    });

    this.activeDialog = 'modal-card-snapshot-diff';
  };

  renderCollapsedCard = (card = {}, index = 0) => {
    const {user, users, rival} = this.props;
    const {data: {name = ''}, createdAt, updatedAt, versionId, versionAuthorId} = card;
    const {versionId: vId} = this.state;
    const isActive = versionId === vId;
    const {utils: {userIsStaff, company, user: {id: userId}}} = this.context;

    // TODO: remove parseInt once API returns an int (see PR #4027 comments)
    const {id: authorId, name: authorName} = (users || {})[parseInt(versionAuthorId, 10)] || {};
    const cardClasses = classNames('snapshots_card', {
      'snapshots_card--collapsed': !isActive,
      'snapshots_card--active': isActive
    });
    let summaryRegion;

    if(isActive) {
      summaryRegion = (
        <CardDisplay card={card} rival={rival} diffIdentifier="historical" />
      );
    }

    const showDiff = isActive && (userIsStaff() || userIsKluebot({userId, company}));
    const revealDiffs = showDiff && (
      <a
        href="#"
        className="snapshot-diff-link"
        data-tracking-id="snapshot-diff-link"
        data-testid="snapshot-diff-link"
        onClick={() => this.toggleDiffHighlighting(card)}
        data-action="showCardMeta"
        data-tip="View Diffs"
        data-offset="{'top': 0}">
        <Icon icon="visibility-on" />
      </a>
    );

    const revertButton = isActive && userCanCurate({user}) &&
      (<button
        className="button button--xsmall snapshot-revert"
        data-tracking-id="revert-card-history"
        data-testid="revert-card-history"
        type="button"
        onClick={e => this.handleRevertClick(card, e)}>Revert</button>);
    const authorRegion = authorId &&
      (<div className="snapshots_card_author">
        {createdAt === updatedAt ? 'Created' : 'Updated'} by <Link to={`/users/${authorId}`} className="snapshots_card_author_link">{authorName}</Link>
      </div>);

    return (
      <div key={`snapshot_${index}`} className={cardClasses}>
        <div onClick={() => this.handleVersionClick(versionId)}>
          {!isActive ? <>
            <div className="snapshots_card_title">{name || '(Untitled card)'}</div>
            {authorRegion}
            <div className="snapshots_card_date">
              {moment(updatedAt).fromNow()}
            </div>
          </> : null}
          {summaryRegion}
        </div>
        <DiffControls className={classNames('diff-controls', {'with-diff': showDiff})}>
          {revealDiffs}
          {revertButton}
        </DiffControls>
      </div>
    );
  };

  render() {
    const {cardId, onToggleClick, leftAdjustPx} = this.props;
    const {cards = []} = this.state;
    let snapshotsRegion;

    if(!isValidId(cardId) || !onToggleClick) {
      return null;
    }

    // null cards represents loading state
    if(!_.isEmpty(cards) && cards.length) {
      snapshotsRegion = cards.map((card, index) => this.renderCollapsedCard(card, index));
    }
    else if(_.isEmpty(cards) && (cards !== null)) {
      snapshotsRegion = (
        <div className="snapshots_card--empty">
          Sorry, this card has no prior versions.
        </div>
      );
    }

    return (
      <div id={`snapshots_${cardId}`} className={`ui-card board snapshots snapshots-card-${cardId}`} style={{left: `${leftAdjustPx}px`}}>
        <div className="board-header">
          <div className="board-header_title">
            <span className="board-name snapshots_board-name">
              <Icon icon="history" width="24" height="24" className="board-header_title_icon" />
              Card History
            </span>
          </div>
          <div className="board-header_actions">
            <i className="fa fa-times board-header_actions_icon" onClick={() => onToggleClick()} />
          </div>
        </div>
        <div className="board-body">
          {snapshotsRegion}
        </div>
      </div>
    );
  }

}

export default Snapshots;
