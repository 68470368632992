import Modal from '../_modal';
import DigestTypeSettings from './_digest_type_settings';

const DigestSettingsModal = ({
  digest,
  rivals,
  rivalGroups,
  activeDigestType,
  digestTypes,
  visibilityGroups,
  isManageDigestTemplates,
  activeDigestTypeIsNew,
  onClose,
  onCancelAdd,
  onCancelledEdit,
  digestGet,
  digestTypeUpdate,
  updateTheDigest,
  confirm
}) => {
  return (
    <Modal
      header={false}
      padded={false}
      extraBodyClass="with-overflow-scroll"
      extraModalClass="digest-settings-modal-container"
      basic={true}
      onClose={onClose}
      hideCloseButton={true}
      closeOnOutsideClick={false}>
      <DigestTypeSettings
        digest={digest}
        rivals={rivals}
        rivalGroups={rivalGroups}
        activeDigestType={activeDigestType}
        activeDigestTypeIsNew={activeDigestTypeIsNew}
        digestTypes={digestTypes}
        visibilityGroups={visibilityGroups}
        isManageDigestTemplates={isManageDigestTemplates}
        onClose={onClose}
        onCancelAdd={onCancelAdd}
        onCancelledEdit={onCancelledEdit}
        digestTypeUpdate={digestTypeUpdate}
        updateTheDigest={updateTheDigest}
        digestGet={digestGet}
        confirm={confirm}
        showDoneButton={true}
        restoreSettingOnCancel={Boolean(!onCancelAdd)}
        showNameSection={true} />
    </Modal>
  );
};

DigestSettingsModal.propTypes = {
  digest: PropTypes.object,
  rivals: PropTypes.object,
  rivalGroups: PropTypes.arrayOf(PropTypes.object),
  activeDigestType: PropTypes.object,
  digestTypes: PropTypes.arrayOf(PropTypes.object),
  visibilityGroups: PropTypes.arrayOf(PropTypes.object),
  isManageDigestTemplates: PropTypes.bool,
  activeDigestTypeIsNew: PropTypes.bool,
  onClose: PropTypes.func,
  onCancelAdd: PropTypes.func,
  onCancelledEdit: PropTypes.func,
  digestTypeUpdate: PropTypes.func,
  updateTheDigest: PropTypes.func,
  digestGet: PropTypes.func,
  confirm: PropTypes.func
};

DigestSettingsModal.defaultProps = {
  digest: null,
  rivals: {},
  rivalGroups: [],
  activeDigestType: null,
  digestTypes: [],
  visibilityGroups: [],
  isManageDigestTemplates: false,
  activeDigestTypeIsNew: false,
  onClose() {},
  onCancelAdd: null,
  onCancelledEdit: null,
  digestTypeUpdate() {},
  updateTheDigest() {},
  digestGet() {},
  confirm() {}
};

export default DigestSettingsModal;
