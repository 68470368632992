import {pluralize} from '../modules/text_utils';
import Icon from './_icon';
import classNames from 'classnames';

const CommentStat = ({commentCount, hasCommented, onToggleCommentsClick}) => {
  const commentStatClass = classNames('comment-stat', {
    active: hasCommented
  });
  const stats = (<>
    <Icon icon="comment" className="comment-stat_icon" /><span className="comment-count">{commentCount} {pluralize('comment', commentCount)}</span>
  </>);
  const toggler = onToggleCommentsClick
    ? (<a href="#" onClick={onToggleCommentsClick}>{stats}</a>)
    : (<a>{stats}</a>);

  return (<div className={commentStatClass}>{toggler}</div>);
};

CommentStat.propTypes = {
  commentCount: PropTypes.number,
  hasCommented: PropTypes.bool,
  onToggleCommentsClick: PropTypes.func
};

CommentStat.defaultProps = {
  commentCount: 0,
  hasCommented: false,
  onToggleCommentsClick: null
};

export default CommentStat;
