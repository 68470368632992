import vitalsImage from 'images/vitals-settings-edit.gif';

export const PREPARING_DEFAULT_MESSAGE = 'Please wait, we\'re almost done.';
export const PREPARING_TEMPLATE_MARKUP = '<p>Well done with the templating. Copying those cards into your new board now.</p>';
export const PREPARING_AUTOMATED_CARDS_MARKUP = '<p>Be patient, young Kluebie, your automated cards will start populating as we find new data.</p>';
export const PREPARING_FOR_ALERTS_MARKUP = '<p>New board, yippee! Don\'t forget to set up your Alerts so Kluebot knows what intel you want us to collect.</p>';
export const PREPARING_FOR_MORE_SETTINGS_MARKUP = `<div><p>Refine your board settings further by taking a trip down the Vitals Lane. There you can assign curators and dashboard groups, and fine-tune alerts.</p><img src="${vitalsImage}" /><div>`; // eslint-disable-line max-len

export const overlayMessages = ({rival, options}) => {
  const messages = [PREPARING_DEFAULT_MESSAGE];

  if(!rival || !options) {
    return messages;
  }

  const {automatedCards, copyFromId} = options;

  if(copyFromId) {
    messages.push(PREPARING_TEMPLATE_MARKUP);
  }

  const haveAutomatedCards = automatedCards && automatedCards.length;

  if(haveAutomatedCards) {
    messages.push(PREPARING_AUTOMATED_CARDS_MARKUP);
  }
  else {
    messages.push(PREPARING_FOR_ALERTS_MARKUP);
  }

  messages.push(PREPARING_FOR_MORE_SETTINGS_MARKUP);

  return messages;
};
