import Attachment from './_attachment';
import Icon from './_icon';

import {stripHtml} from '../modules/html_utils';

import {analyticsTrack} from '../modules/analytics_utils';
import FeedFallbackDataMissing from './_feed_fallback_data_missing';
import {validMediaTypes} from '../modules/constants/comments.js';
import PinnedFeedComment from './_pinned_feed_comment/_pinned_feed_comment';

import classNames from 'classnames';

class AttachmentList extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired
  };

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    onActiveAttachmentUpdate: PropTypes.func,
    postTitle: PropTypes.string,
    postTitleLowercased: PropTypes.string,
    postBody: PropTypes.string,
    profileMode: PropTypes.bool,
    profile: PropTypes.object,
    postId: PropTypes.number,
    redirectToUrl: PropTypes.func,
    handlePinClick: PropTypes.func,
    timestamp: PropTypes.string,     // ISO-8601 UTC date
    singlePostMode: PropTypes.bool,
    viaEmail: PropTypes.bool,
    viaSlack: PropTypes.bool,
    viaTeams: PropTypes.bool,
    linkToFullPost: PropTypes.bool,
    favorite: PropTypes.bool,
    pinnedComment: PropTypes.object
  };

  static defaultProps = {
    data: [],
    onActiveAttachmentUpdate: null,
    postTitle: '',
    postTitleLowercased: '',
    postBody: '',
    profileMode: false,
    profile: null,
    postId: 0,
    redirectToUrl: null,
    timestamp: '',
    singlePostMode: false,
    viaEmail: false,
    viaSlack: false,
    viaTeams: false,
    linkToFullPost: false,
    favorite: false,
    pinnedComment: {},
    handlePinClick() {}
  };

  state = {
    position: 0,
    attachmentPreviews: []
  };

  componentDidMount() {
    const {data} = this.props;
    const attachments = (data || []).slice();
    const attachmentPreviews = attachments.reduce((acc, attachment) => {
      const {type, imageUrl, body} = attachment;
      const isPageWithPreview = (type === 'page' && (body || imageUrl));
      const validMediaTypesWithoutPage = validMediaTypes.filter(mediaType => mediaType !== 'page');
      const isMediaPreview = (validMediaTypesWithoutPage.includes(type) && imageUrl);

      return (isPageWithPreview || isMediaPreview) ? [...acc, attachment] : acc;
    }, []);

    if(attachmentPreviews.length) {
      this.setState({attachmentPreviews});
    }
  }

  handlePrevNextClick = (position = 1, max = 1) => {
    return event => {
      event && event.stopPropagation();
      this.setPosition(position, max - 1);
    };
  };

  setPosition = (movement = 0, max = 0) => {
    if(!movement || !max) {
      return;
    }

    const {postId, onActiveAttachmentUpdate} = this.props;
    const {position: currentPosition} = this.state;
    const clickedPrevAtStart = (movement === -1) && (currentPosition === 0);
    const clickedNextAtEnd = (movement === 1) && (currentPosition === max);
    const position = clickedPrevAtStart ? max : clickedNextAtEnd ? 0 : (currentPosition + movement);
    const {attachmentPreviews} = this.state;
    const activePreviewAttachmentId = (attachmentPreviews[position] || {}).id;

    analyticsTrack({
      type: 'event',
      category: 'Post',
      action: 'navigate attachments',
      label: `(${postId})`
    });

    this.setState({position}, () => (onActiveAttachmentUpdate && onActiveAttachmentUpdate(activePreviewAttachmentId)));
  };

  statusMessage = (max = 0) => {
    const {position} = this.state;

    return max && `${position + 1} / ${max}`;
  };

  handleUnpinClick = comment => {
    const {handlePinClick} = this.props;

    handlePinClick(comment);
  };

  render() {
    const {
      data, postId, postTitle, postTitleLowercased, postBody, redirectToUrl, timestamp,
      profileMode, profile, singlePostMode, linkToFullPost, favorite, viaEmail, viaSlack, viaTeams, pinnedComment
    } = this.props;
    const hasPinnedComment = pinnedComment && Boolean(pinnedComment.id);
    const {position, attachmentPreviews} = this.state;
    const activePreviewAttachmentId = (attachmentPreviews[position] || {}).id;
    const attachments = (data || []).slice();
    const mediaMissingClasses = classNames('attachment-media-missing', favorite, {
      'attachment-media-missing--border': !favorite
    });
    const _attachment = (attachment, index) => {
      const {
        id: attachmentId, commentId: attachmentCommentId, type: attachmentType, url: attachmentUrl, title: attachmentTitle,
        body: attachmentBody, bodyHtml: attachmentBodyHtmlOriginal, blocklisted: attachmentBlocklisted,
        imageUrl: attachmentImageUrl, embedCode: attachmentEmbedCode, sourceName: attachmentSourceName
      } = attachment;
      // remove all html tags to prevent empty html tags that generates a false-positive e.g.: <div><div></div></div>
      const attachmentBodyHtmlRaw = stripHtml(attachmentBodyHtmlOriginal);
      const isValidAttachmentBodyHtml = !_.isEmpty(attachmentBodyHtmlRaw);
      const attachmentBodyHtml = isValidAttachmentBodyHtml ? attachmentBodyHtmlOriginal : attachmentBody;
      let bodyToRender = attachmentBodyHtml || attachmentBody;
      const attachmentTitleToRender = (bodyToRender && ((attachmentTitle || '').toLowerCase() !== postTitleLowercased || profileMode)) ? attachmentTitle : '';
      let imageUrl = attachmentImageUrl;

      if(!bodyToRender && (postBody && (postBody !== attachmentUrl))) {
        bodyToRender = postBody;
      }

      if(!bodyToRender && attachmentUrl) {
        bodyToRender = attachmentUrl;

        if(!imageUrl || attachmentBlocklisted) {
          imageUrl = '/attachment-img-placeholder.png';
        }
      }

      return (
        <Attachment
          active={index === -1 || index === position}
          body={bodyToRender}
          embedCode={attachmentEmbedCode}
          favorite={favorite}
          imageUrl={imageUrl}
          key={`attachment-${attachmentId || attachmentCommentId}`}
          linkToFullPost={linkToFullPost}
          postBody={(index === -1) ? (viaEmail ? null : postBody) : null}
          postId={postId}
          postTitle={(index === -1) ? (viaEmail ? null : postTitle) : null}
          profile={profile}
          profileMode={profileMode}
          redirectToUrl={redirectToUrl}
          singlePostMode={singlePostMode}
          sourceName={attachmentSourceName}
          timestamp={timestamp}
          title={attachmentTitleToRender}
          type={attachmentType}
          url={attachmentUrl}
          viaEmail={viaEmail}
          viaSlack={viaSlack}
          viaTeams={viaTeams} />
      );
    };

    let noPostNode;
    let profileModeAttachment;

    //Display Fallback Image if post has no subject, summary, body, or attachments
    if(_.isEmpty(attachments)) {
      const hasTitle = Boolean(postTitle);

      if(!postBody) {
        noPostNode = (
          <div onClick={redirectToUrl} className={classNames('attachment-main', {'zoom-cursor': !(profileMode || (singlePostMode && linkToFullPost))})}>
            <div className={mediaMissingClasses}>
              <FeedFallbackDataMissing hasTitle={hasTitle} />
            </div>
          </div>
        );
      }
    }

    //Display Fallback Image if post has no subject, summary, body, or attachments
    if(profileMode && !noPostNode) {
      if(_.isEmpty(attachments) && postBody) {
        profileModeAttachment = (
          <Attachment
            active={true}
            body={postBody}
            favorite={favorite}
            imageUrl={null}
            postId={postId}
            postTitle={postTitle}
            profile={profile}
            profileMode={profileMode}
            redirectToUrl={redirectToUrl}
            singlePostMode={singlePostMode}
            timestamp={timestamp}
            type="page"
            url={null} />
        );
      }
      else if(viaEmail) {
        const {embedCode, imageUrl} = (attachments.length === 1 && validMediaTypes.includes(attachments[0].type)) ? attachments.pop() : {};

        profileModeAttachment = (
          <Attachment
            active={true}
            body={postBody}
            embedCode={embedCode}
            favorite={favorite}
            imageUrl={imageUrl}
            linkToFullPost={linkToFullPost}
            postBody={postBody}
            postId={postId}
            postTitle={postTitle}
            profile={profile}
            profileMode={profileMode}
            redirectToUrl={redirectToUrl}
            singlePostMode={singlePostMode}
            sourceName={'via e-mail'}
            timestamp={timestamp}
            title={postTitle}
            viaEmail={viaEmail} />
        );
      }
      else {
        const firstAttachment = attachments[0];

        profileModeAttachment = firstAttachment && _attachment(firstAttachment, -1);
      }
    }

    const attachmentLinks = attachments.reduce((acc, attachment) => {
      const {url} = attachment;

      if(url) {
        const {id, sourceName, title, type} = attachment;
        const icon = {
          page: (<Icon icon="external" className="attachment-icon" />),
          image: (<Icon icon="photo" className="attachment-icon" />),
          document: (<Icon icon="file" className="attachment-icon" />),
          link: (<Icon icon="attachment" className="attachment-icon" />)
        }[type && (type.startsWith('document') ? 'document' : type) || 'link'];

        return id ? [...acc, (
          <li className={classNames('attachment-list-all_item', {'attachment-list-all_item--active': id === activePreviewAttachmentId})}
            key={`attachment-link-${id}`}>
            {icon}
            <a title={title || url} href={url} rel="noreferrer nofollow" target="_blank" className="attachment-link">{sourceName || title || 'Attachment'}</a>
          </li>
        )] : acc;
      }

      return acc;
    }, []);

    const {length: attachmentsWithPreviewsCount} = attachmentPreviews;
    const activePreviewIdx = attachmentPreviews.findIndex(attachment => (attachment.id === activePreviewAttachmentId));
    const onPrevClick = this.handlePrevNextClick(-1, attachmentsWithPreviewsCount);
    const onNextClick = this.handlePrevNextClick(1, attachmentsWithPreviewsCount);
    const attachmentControls = (attachmentsWithPreviewsCount > 1)
      ? (
        <div className="attachment-control">
          <div className="attachment-control-prev" onClick={onPrevClick}>
            <i className="fa fa-angle-left" />
          </div>
          <div className="attachment-control-counter">
            {this.statusMessage(attachmentsWithPreviewsCount)}
          </div>
          <div className="attachment-control-next" onClick={onNextClick}>
            <i className="fa fa-angle-right" />
          </div>
        </div>
      ) : null;
    let activePreview = (activePreviewIdx > -1) && _attachment(attachmentPreviews[position], activePreviewIdx);

    if(activePreview && (position === 0) && !viaEmail) {
      activePreview = (
        <div onClick={redirectToUrl} className={classNames('attachment-main', {'zoom-cursor': !(profileMode || (singlePostMode && linkToFullPost))})}>
          {activePreview}
        </div>
      );
    }

    return (
      <>
        {noPostNode}
        {profileModeAttachment && (<div className="attachment-main">{profileModeAttachment}</div>)}
        {(!profileMode) ? (
          <div className="attachment-list">
            {hasPinnedComment && <PinnedFeedComment onUnpinClick={() => this.handleUnpinClick({id: 0})} pinnedComment={pinnedComment} />}
            {attachmentLinks.length ? (
              <ul className="attachment-list-all" data-testid="attachment-list" aria-label="attachment-list">
                {attachmentLinks}
              </ul>
            ) : null}
            {
              Boolean(attachmentsWithPreviewsCount) &&
              <>
                {attachmentControls}
                {activePreview}
              </>
            }
          </div>
        ) : null}
      </>
    );
  }

}

export default AttachmentList;
