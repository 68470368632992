class VisibilityGroupCreate extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    modalId: PropTypes.string,
    userIdRef: PropTypes.number,
    onGroupAddedCallback: PropTypes.func.isRequired,
    visibilityGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    manageMode: PropTypes.bool
  };

  static defaultProps = {
    modalId: '',
    userIdRef: null,
    onGroupAddedCallback() {},
    visibilityGroups: [],
    manageMode: false
  };

  state = {
    groupName: '',
    errorText: ''
  };

  componentDidMount() {
    const textInput = this.refs.newGroupNameInput;

    if(textInput) {
      textInput.focus();
    }
  }

  handleChange = event => {
    this.setState({
      groupName: event.target.value,
      errorText: ''
    });
  };

  createNewVisibilityGroup = event => {
    if(event) {
      event.preventDefault();
    }

    const name = this.refs.newGroupNameInput.value.trim();
    const visibilityGroups = this.props.visibilityGroups.slice();

    if(_.isEmpty(name)) {
      return;
    }

    const isExisting = visibilityGroups.filter(grp => grp.name.toLowerCase() === name.toLowerCase()).length > 0;

    if(isExisting) {
      this.setState({
        errorText: 'It looks like a group with that name already exists. 😢'
      });
    }
    else {
      const newGroup = {name, id: 0};

      this.context.api.visibilityGroupCreate({
        code: 'VisibilityGroupCreate.createNewVisibilityGroup',
        visibilityGroupOptions: newGroup
      }).then(g => {
        this._callbackAndExit(g);
      });
    }
  };

  _callbackAndExit = newGroup => {
    const {onGroupAddedCallback, userIdRef, manageMode, modalId} = this.props;

    if(!_.isEmpty(newGroup)) {
      const grp = {newGroup};

      if(userIdRef) {
        grp.userIdRef = userIdRef;
      }

      onGroupAddedCallback(grp);
    }

    this.setState({
      groupName: '',
      errorText: ''
    }, () => {
      if(!manageMode) {
        this.context.utils.dialog.remove(modalId);
      }
    });
  };

  render() {
    const {manageMode} = this.props;
    const {errorText, groupName} = this.state;
    const saveGroup = manageMode && (<button className="button save" onClick={this.createNewVisibilityGroup}>Save</button>);
    const toolbar = !manageMode && (
      <div className="dialog-toolbar">
        <div className="button button--disabled dialog-cancel-button" onClick={() => {this.context.utils.dialog.remove(this.props.modalId);}}>Cancel</div>
        <button className="button dialog-ok-button" onClick={this.createNewVisibilityGroup}>Save & Assign User</button>
      </div>
    );
    const errors = errorText && (<div className="manage-visibility-group-error">{errorText}</div>);

    return (
      <form onSubmit={this.createNewVisibilityGroup}>
        <h4 className="heading-dialog">Create New Group</h4>
        <div className="create-visibility-group-inputWrap">
          <input
            type="text"
            ref="newGroupNameInput"
            placeholder="New group name"
            className="klue-input create-visibility-group-input"
            value={groupName}
            onChange={this.handleChange} />
          {saveGroup}
        </div>
        {errors}
        <small>A <i>Visibility Group</i> allows you to set Klue content to only be viewable by users assigned to this group.</small>
        {toolbar}
      </form>
    );
  }

}

export default VisibilityGroupCreate;
