import {useMemo} from 'react';
import Icon from './_icon';
import DropdownMenu from './_dropdown_menu';

const DigestSelector = ({
  isAdmin,
  archivesMode,
  manualDigest,
  archivedAt,
  digestTypes,
  activeDigestType,
  onSelectDigestType,
  onAdd
}) => {
  const dropdownFormattedTypes = useMemo(() => digestTypes
    .map(digestType => ([digestType.id, digestType.name, false, '', {}]))
    .sort((a, b) => a[1].localeCompare(b[1])), [digestTypes]);

  const {id, name} = activeDigestType || {};

  const titleComponent = !manualDigest && !archivedAt && digestTypes?.length > 1
    ? (
      <DropdownMenu
        id="multiple-digests-selector"
        dataTrackingId="digest-settings-multiple-digests-dropdown"
        dataTestId="digest-settings-multiple-digests-dropdown"
        justification="left"
        values={dropdownFormattedTypes}
        selectedValue={id ?? ''}
        selectCallback={onSelectDigestType} />
    )
    : (<strong className="digest-selector-title">{
      manualDigest
        ? 'The Intel Digest - Custom Digest'
        : (archivesMode
          ? 'Digest Archives'
          : name)
        }</strong>);

  return (
    <div className="digest-selector" title={name}>
      {titleComponent}
      {isAdmin && !manualDigest && !archivedAt && onAdd
        ? <div className="add-button" onClick={onAdd} data-tracking-id="add-digest-type-button" data-testid="add-digest-type-button">
          <Icon icon="add_circle" width="24" height="24" />
        </div>
        : null}
    </div>
  );
};

DigestSelector.propTypes = {
  isAdmin: PropTypes.bool,
  archivesMode: PropTypes.bool,
  manualDigest: PropTypes.bool,
  archivedAt: PropTypes.string,
  activeDigestType: PropTypes.object,
  digestTypes: PropTypes.arrayOf(PropTypes.object),
  onSelectDigestType: PropTypes.func,
  onAdd: PropTypes.func
};

DigestSelector.defaultProps = {
  isAdmin: false,
  archivesMode: false,
  manualDigest: false,
  archivedAt: null,
  activeDigestType: {},
  digestTypes: [],
  onSelectDigestType() {},
  onAdd: null
};

export default DigestSelector;
