import styled from 'styled-components';

export const noop = Object.freeze({preventDefault: () => null, target: {value: null}});
const H3 = styled.h3`
  margin-top: 2em;
`;

export const testEl = () => (<H3>This Salesforce data card is <strong>coming soon</strong>.</H3>);

// Jump back x months in time to start of month
export const back = x => {
  const cur = new Date();

  return new Date(cur.getFullYear(), cur.getMonth() - x, 1);
};

export const getUTCTime = date => {
  const millisecondsInAMinute = 60000;

  return date.getTime() + date.getTimezoneOffset() * millisecondsInAMinute;
};

// Compare between two dates
export const between = (date, start, end) => {
  return getUTCTime(date) >= start.getTime() && getUTCTime(date) < end.getTime();
};

// Convert integer months to strings
export const getMonths = (data = [], wideMode = false) => {
  if(!data.length) {
    return console.warn('no data to convert');
  }

  const maxWideModeDataLength = 18;
  const maxNarrowModeDataLength = 11;
  const locale = 'en-us';
  const months = [];
  const needCompressStyle = (wideMode && data.length > maxWideModeDataLength) || (!wideMode && data.length > maxNarrowModeDataLength);
  const monthStyle = needCompressStyle ? 'narrow' : 'short';

  // Exclude the current month as it is not complete and doesn't fit SFDC's definition of last n months
  // For more context, see: https://trailhead.salesforce.com/trailblazer-community/feed/0D54S00000A8lAMSAZ
  data.slice(0, -1).forEach(x => {
    const month = new Intl.DateTimeFormat(locale, {month: monthStyle, timeZone: 'UTC'}).format(x.date);

    months.push(month);
  });

  return months;
};

// Reduce opportunities to accumulative integer values
export const lineGraphShaper = data => {
  return data.reduce((sum, opportunity) => {
    sum.push(opportunity.value);

    return sum;
  }, []);
};

/*
 * Generate the shape that we want.
 * [{date: ..., value: ...}, ...]
 */
export const shape = n =>
  Array(n)
    .fill(0)
    .map((d, i) => {
      return {
        date: back(i),
        value: 0
      };
    })
    .reverse();

// Loop through SFDC data, accumulating the values that occur in each month
const prepare = (months, data) => {
  const prepped = shape(months);

  // compare one month to another as we loop through all the months in `shape()`
  const count = i => {
    data.forEach(record => {
      const start = prepped[i].date;
      const end = prepped[i + 1];
      const date = new Date(record.CloseDate);
      const current = new Date();

      // the last index requires a slight adjustment on this pattern.
      if(!end) {
        if(between(date, start, current)) {
          prepped[i].value++;
        }

        return;
      }

      if(between(date, start, end.date)) {
        prepped[i].value++;
      }
    });
  };

  prepped.forEach((x, i) => {
    count(i);
  });

  return prepped;
};

export default prepare;
