import Icon from './_icon';

import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';

class MenuWrapper extends React.Component {

  static propTypes = {
    children: PropTypes.node,

    // from onClickOutside HoC
    enableOnClickOutside: PropTypes.func,
    disableOnClickOutside: PropTypes.func
  };

  static defaultProps = {
    children: null,

    // from onClickOutside HoC
    enableOnClickOutside() {},
    disableOnClickOutside() {}
  };

  state = {
    isActive: false
  };

  // onClickOutside HoC
  handleClickOutside = () => {
    if(this.state.isActive) {
      this.handleMenuToggle();
    }
  };

  handleMenuToggle = event => {
    if(event) {
      event.preventDefault();
    }

    this.setState(prevState => {
      return {
        isActive: !prevState.isActive
      };
    }, () => {
      if(this.state.isActive) {
        this.props.enableOnClickOutside();
      }
      else {
        this.props.disableOnClickOutside();
      }
    });
  };

  render() {
    const {children} = this.props;
    const {isActive} = this.state;
    const menuClasses = classNames('menu-wrapper_icon', {
      'menu-wrapper_icon--active': isActive
    });

    return (
      <div className="menu-wrapper">
        <a href="#" className={menuClasses} tabIndex="-1" onClick={this.handleMenuToggle}>
          <Icon icon="more-vert" width="16" height="36" />
        </a>
        <div className="menu-wrapper_items" onClick={this.handleMenuToggle}>
          {children}
        </div>
      </div>
    );
  }

}

export default onClickOutside(MenuWrapper);
