import Icon from '../_icon';
import ModalEditorPermissionHeader from './_modal_editor_permission_header';
import {
  cardPermissions,
  CURATORS_ONLY,
  EVERYONE,
  CURATORS_AND_GROUPS,
  FULL_ACCESS_USER_LABEL
} from '../../modules/constants/permissions';
import {getPermissionGroupButtonTitle} from '../../modules/permission_utils';

import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

const initialState = {
  currentPermission: CURATORS_ONLY,
  groupsSelection: {},
  groupsLoaded: false,
  selectionsLoaded: false,
  permissionGroups: [],
  permissionGroupsLookup: {}
};

class ModalEditorPermissionManager extends React.PureComponent {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    cardId: PropTypes.number,
    style: PropTypes.object,
    visible: PropTypes.bool,
    assignedVisibilityGroups: PropTypes.arrayOf(PropTypes.object),
    isDraftCard: PropTypes.bool,
    isEveryoneCard: PropTypes.bool,
    cancelOnClickOutside: PropTypes.bool,
    onClose: PropTypes.func,
    onCancel: PropTypes.func
  };

  static defaultProps = {
    cardId: 0,
    style: {},
    visible: false,
    assignedVisibilityGroups: [],
    isDraftCard: true,
    isEveryoneCard: false,
    cancelOnClickOutside: false,
    onClose() {},
    onCancel() {}
  };

  state = initialState;

  componentDidMount() {
    this.fetchVisibilityGroupsAndSetSelected();
    this._mounted = true;
  }

  componentDidUpdate(prevProps) {
    const {
      cardId: prevCardId,
      assignedVisibilityGroups: prevAssignedVisibilityGroups,
      isDraftCard: prevIsDraftCard,
      isEveryoneCard: prevIsEveryoneCard
    } = prevProps;
    const {
      cardId,
      assignedVisibilityGroups,
      isDraftCard,
      isEveryoneCard
    } = this.props;

    if((prevCardId !== cardId) ||
      (prevAssignedVisibilityGroups !== assignedVisibilityGroups) ||
      (prevIsDraftCard !== isDraftCard) ||
      (prevIsEveryoneCard !== isEveryoneCard)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({selectionsLoaded: false}, () => {
        const {permissionGroups, groupsLoaded} = this.state;

        if(groupsLoaded) {
          this.refreshSelections(permissionGroups);
        }
      });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchVisibilityGroupsAndSetSelected = () => {
    this.context.api
      .visibilityGroupsGet({code: 'ManageUsers.visibilityGroupsGet'})
      .then(response => this.refreshSelections(response));
  };

  refreshSelections = permissionGroups => {
    if(!this._mounted) {
      return;
    }

    const {assignedVisibilityGroups, isDraftCard, isEveryoneCard, cardId} = this.props;
    const selectedIds = assignedVisibilityGroups.reduce((acc, group) => {
      const {id} = group;

      acc.push(id);

      return acc;
    }, []);
    const groupsSelection = Object.keys(selectedIds).length
      ? (permissionGroups || []).reduce((map, group) => {
        const {id} = group;

        if(selectedIds.includes(id)) {
          map[id] = true;
        }

        return map;
      }, {})
      : {};

    let currentPermission;

    if(isDraftCard) {
      currentPermission = CURATORS_ONLY;
    }
    else if(isEveryoneCard) {
      currentPermission = EVERYONE;
    }
    else {
      currentPermission = CURATORS_AND_GROUPS;
    }

    const permissionGroupsLookup = permissionGroups.reduce((map, group) => {
      map[group.id] = group;

      return map;
    }, {});

    this.setState({
      permissionGroups,
      permissionGroupsLookup,
      groupsSelection,
      selectionsLoaded: true,
      groupsLoaded: true,
      currentPermission
    });
  };

  handleSelectOption = event => this.setState({currentPermission: event.target.value});

  handleGroupSelect = event => {
    const {target: {name, checked}} = event;

    this.setState(prevState => ({
      groupsSelection: {
        ...prevState.groupsSelection,
        [name]: checked
      }
    }));
  };

  getPermissionValue = () => {
    const data = {};
    const {currentPermission, groupsSelection, permissionGroupsLookup} = this.state;
    let isDraftFromPermissions = false;
    let allAccessFromPermissions = false;
    const visibilityGroupsFromPermissions = [];

    switch(currentPermission) {
      case CURATORS_ONLY:
        isDraftFromPermissions = true;
        break;
      case EVERYONE:
        allAccessFromPermissions = true;
        break;
      default:
        break;
    }

    Object.keys(groupsSelection).forEach(key => {
      if(key && groupsSelection[key]) {
        visibilityGroupsFromPermissions.push(permissionGroupsLookup[key]);
      }
    });

    return Object.assign(data, {
      isDraftFromPermissions,
      allAccessFromPermissions,
      visibilityGroupsFromPermissions
    });
  };

  handleDone = () => {
    const {onClose} = this.props;

    onClose(this.getPermissionValue());
  };

  isEscKeyPress = event => {
    if(!event) {return false;}

    if(event.type !== 'keydown') {return false;}

    return ['Esc', 'Escape'].includes(event.key);
  };

  handleClickOutside = event => {
    const {cancelOnClickOutside, onCancel} = this.props;

    if(cancelOnClickOutside) {
      if(event && event.type === 'keydown') {
        return this.isEscKeyPress(event) && onCancel();
      }

      return onCancel();
    }

    if(!this.isEscKeyPress(event)) {
      return;
    }

    this.handleDone();
  };

  render() {
    const {visible, style, cancelOnClickOutside} = this.props;

    if(!visible) {
      return null;
    }

    const {
      permissionGroups,
      currentPermission,
      isSaving,
      groupsSelection,
      selectionsLoaded,
      groupsLoaded
    } = this.state;

    if(!selectionsLoaded || !groupsLoaded) {
      return (
        <div className="modal-editor-permission-manager">
          <ModalEditorPermissionHeader loading={true} />
        </div>
      );
    }

    const selectedGroupsId = Object.keys(groupsSelection).filter(id => groupsSelection[id]).map(Number);
    const {isDraftFromPermissions: isDraft, allAccessFromPermissions: allAccess, visibilityGroupsFromPermissions: visibilityGroups} = this.getPermissionValue();
    const {title: permissionsTitle, count, tooltip} = getPermissionGroupButtonTitle({isDraft, allAccess, visibilityGroups});

    return (
      <div
        style={style}
        data-test-id="modal-editor-permission-manager"
        className={classNames('modal-editor-permission-manager')}
        onKeyDown={this.handleClickOutside}>
        <ModalEditorPermissionHeader />
        <div data-test-id="permission-manager-options" className="permission-manager-options">
          {Object.keys(cardPermissions).map(key => {
            const checked = (key === currentPermission);
            const {label} = cardPermissions[key];
            const groupOptions = (key === CURATORS_AND_GROUPS)
              ? (<div className={classNames('permission-manager-options_groups', {enabled: currentPermission === CURATORS_AND_GROUPS})}>
                {permissionGroups.map(({id, name}) => (
                  <div
                    key={id}
                    className="permission-manager-options_option">
                    <input
                      type="checkbox"
                      id={`option_${id}`}
                      name={id}
                      checked={selectedGroupsId.includes(id)}
                      onChange={this.handleGroupSelect}
                      value={id} />
                    <label htmlFor={`option_${id}`}>{name}</label>
                  </div>
                ))}
              </div>)
              : null;

            return (
              <div key={key}>
                <div data-test-id="permission-manager-option"
                  data-test-option={key}
                  className={classNames('permission-manager-options_option', {
                    active: checked
                  })}>
                  <input onChange={this.handleSelectOption} checked={checked} type="radio" id={key} name="group" value={key} />
                  <label htmlFor={key} data-tracking-id={`visible-to-${key}-label`}>
                    Visible to {<span className={`visible-${key}-tag`}>{label}</span>}
                  </label>
                </div>
                {groupOptions}
              </div>
            );
          })}

          <div className="permission-manager-options_full-access permission-manager-options_full-access--disabled">
            <div
              className="permission-manager-options_option">
              <input
                type="checkbox"
                id="full-access-checkbox"
                checked={true}
                readOnly={true} />
              <label htmlFor="full-access-checkbox">{FULL_ACCESS_USER_LABEL}</label>
            </div>
          </div>
        </div>
        <div className="permission-manager-footer">
          <div className="permission-manager-footer_current">
            <Icon className="permission-manager-footer_current_icon" icon="person" width="18" height="18" />
            {permissionsTitle}
            {((currentPermission === CURATORS_AND_GROUPS) && Boolean(count))
              && <span title={tooltip} className="permission-manager-footer_current_groups-label">
                +{count}
              </span>}
          </div>
          <button
            onClick={this.handleDone}
            disabled={isSaving}
            className="permission-manager-button permission-manager-button--editing">
            {isSaving ? 'Saving...' : cancelOnClickOutside ? 'Save' : 'Done'}
          </button>
        </div>
      </div>
    );
  }

}

// used in the test file, for some reason, enzyme has a problem getting the wrapped component from onClickOutside HOC
export {ModalEditorPermissionManager as WrappedModalEditorPermissionManager};
export default onClickOutside(ModalEditorPermissionManager);
