
import DigestHeaderSettings from './_digest_header_settings';
import DigestHeaderSuperscript from './_digest_header_superscript';

const DigestEditHeader = ({
  isCurator,
  isAdmin,
  digest,
  activeDigestType,
  width,
  digestTypes,
  visibilityGroups,
  dirty,
  archivesMode,
  canSendDigest,
  canEditDigest,
  reorderMode,
  title,
  onEditDigestSettings,
  onSelectDigestType,
  onAddDigestType,
  onSendDigest,
  confirm,
  alert
}) => {
  const handleOnSelectDigestType = digestTypeId => {
    const newSelectedType = digestTypes.find(({id}) => id === digestTypeId);

    if(!dirty) {
      return onSelectDigestType(newSelectedType);
    }

    const message = 'You have unsaved changes to your Digest. Are you sure you want to leave this page?';

    confirm({message, okCallback: () => onSelectDigestType(newSelectedType)});
  };

  const handleOnEdit = digestType => {
    if(!dirty) {
      return onEditDigestSettings(digestType);
    }

    alert('You have unsaved changes to your Digest. Please save them before editing your Digest Settings.');
  };

  const handleOnAdd = digestType => {
    if(!dirty) {
      return onAddDigestType(digestType);
    }

    alert('You have unsaved changes to your Digest. Please save them before adding a new Digest.');
  };

  if(!width) {
    return null;
  }

  return (
    <div className="digest-edit-header" style={{width: width ?? 0}} data-testid="digest-edit-header">
      <div className="digest-edit-header-super-header">
        <DigestHeaderSuperscript text={title} />
      </div>
      <DigestHeaderSettings
        isCurator={isCurator}
        isAdmin={isAdmin}
        archivesMode={archivesMode}
        canSendDigest={canSendDigest}
        canEditDigest={canEditDigest}
        reorderMode={reorderMode}
        digest={digest}
        activeDigestType={activeDigestType}
        digestTypes={digestTypes}
        visibilityGroups={visibilityGroups}
        onEdit={handleOnEdit}
        onAdd={handleOnAdd}
        onSelectDigestType={handleOnSelectDigestType}
        onSendDigest={onSendDigest} />
    </div>
  );
};

DigestEditHeader.propTypes = {
  isCurator: PropTypes.bool,
  isAdmin: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  digest: PropTypes.object,
  activeDigestType: PropTypes.object,
  width: PropTypes.string,
  digestTypes: PropTypes.arrayOf(PropTypes.object),
  visibilityGroups: PropTypes.arrayOf(PropTypes.object),
  dirty: PropTypes.bool,
  archivesMode: PropTypes.bool,
  canSendDigest: PropTypes.bool,
  canEditDigest: PropTypes.bool,
  reorderMode: PropTypes.bool,
  onEditDigestSettings: PropTypes.func,
  onSelectDigestType: PropTypes.func,
  onAddDigestType: PropTypes.func,
  onSendDigest: PropTypes.func,
  confirm: PropTypes.func,
  alert: PropTypes.func
};

DigestEditHeader.defaultProps = {
  isCurator: false,
  isAdmin: false,
  title: 'Intel Digest',
  digest: {},
  activeDigestType: {},
  width: null,
  digestTypes: [],
  visibilityGroups: [],
  dirty: false,
  archivesMode: false,
  canSendDigest: false,
  canEditDigest: false,
  reorderMode: false,
  onEditDigestSettings() {},
  onSelectDigestType() {},
  onAddDigestType() {},
  onSendDigest() {},
  confirm() {},
  alert() {}
};

export default DigestEditHeader;
