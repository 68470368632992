
import {Map, Record} from 'immutable';

const initialState = {
  craft: Map(),
  codeResults: Map()
};

const CraftState = Record({loading: false, loaded: false, loadFailed: false, error: null, data: null});

/**
 * @name Craft model
 *
 * @type Redux model
 * @description
 * @todo This code logic has more than 4 years, probably needs optimization
 */
export default {
  state: initialState,
  reducers: {
    handleRequestData(state, {companyId}) {
      if(!companyId) {
        return state;
      }

      const updatedMap = state.map.update(companyId, new CraftState(), craftState => {
        return craftState.set('loading', true);
      });

      return state.set('map', updatedMap);
    },
    handleReceiveData(state, {companyId, data}) {
      if(!companyId) {
        return state;
      }

      const updatedMap = state.map.update(companyId, new CraftState(), craftState => {
        return craftState.merge({loading: false, loaded: true, data});
      });

      return state.set('map', updatedMap);
    },
    handleCatchError(state, {companyId, error}) {
      console.log('handleCatchError: error fetching craft data: %s %o', companyId, error);

      if(!companyId) {
        return state;
      }

      const updatedMap = state.map.update(companyId, new CraftState(), craftState => {
        return craftState.merge({loading: false, loadFailed: true, error});
      });

      return state.set('map', updatedMap);
    },
    didCraftDataLoad(state, companyId) {
      return state.map.has(companyId) && (state.map.get(companyId).loaded);
    },
    didCraftDataLoadFail(state, companyId) {
      return state.map.has(companyId) && (state.map.get(companyId).loadFailed);
    },
    didCraftDataLoadFinish(state, companyId) {
      return state.map.has(companyId) && (state.map.get(companyId).loaded || state.map.get(companyId).loadFailed);
    },
    getCraftData(state, companyId) {
      if(!state.map.has(companyId)) {
        return null;
      }

      return state.map.get(companyId).data;
    },
    didCraftDataLoadStart(state, companyId) {
      if(!state.map.has(companyId)) {
        return false;
      }

      const fetchState = state.map.get(companyId);

      return fetchState.loading || fetchState.loaded || fetchState.loadFailed;
    },
    getCraftDataError(state, companyId) {
      if(!state.map.has(companyId)) {
        return null;
      }

      return state.map.get(companyId).error;
    }
  },
  effects: () => ({
    async fetchCraftData(companyId, rootState) {
      // Avoid duplicate loads
      if(this.didCraftDataLoadStart(companyId)) {
        return;
      }

      this.handleRequestData(companyId);

      try {
        const response = await axios.get('/api/providers/craft.json', {
          params: {
            domain: companyId
          },
          responseType: 'json'
        });

        if(response.status < 200 || response.status >= 400) {
          this.handleCatchError(companyId, `Unexpected status code: ${response.status} ${response.statusText}`);

          return;
        }

        // Extract the actual content
        const responseJson = response.data;

        if(responseJson.error) {
          this.handleCatchError(companyId, `API returned an error: ${responseJson.error}`);

          return;
        }

        const rivalData = responseJson.data.company;

        if(!rivalData) {
          this.handleCatchError(companyId, 'No craft data exists for rival.');

          return;
        }

        this.handleReceiveData({companyId, rivalData});
      }
      catch(err) {
        this.handleCatchError({companyId, err});
      }
    }
  })
};

