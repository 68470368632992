import Dropdown from './_dropdown';

const DigestItemMoreOptions = ({digest, isDraft, editSettings, onDeleteDigestType, onDeleteSentDigest, onMakeDefaultDigest, onToggleDeleteAfterSend}) => {
  if(!editSettings && !onDeleteDigestType && !onDeleteSentDigest) {
    return <div className="digest-item-more-options" />;
  }

  const userOptions = [];
  const isDraftDigestAndDefault = Boolean(isDraft && digest?.digestType?.default);
  const isDraftDigestAndDeleteAfterSend = Boolean(isDraft && digest?.digestType?.deleteAfterSend);

  if(editSettings) {
    userOptions.push({
      value: 'settings',
      label: 'Settings',
      icon: 'fa-gear',
      onOptionClick: () => editSettings(digest)
    });
  }

  if(isDraft && !isDraftDigestAndDefault && onMakeDefaultDigest) {
    userOptions.push({
      value: 'make-default',
      label: 'Set as Default Digest',
      icon: 'fa-edit',
      onOptionClick: () => onMakeDefaultDigest(digest)
    });
  }

  if(isDraft && !isDraftDigestAndDefault && onToggleDeleteAfterSend) {
    if(isDraftDigestAndDeleteAfterSend) {
      userOptions.push({
        value: 'keep-after-send',
        label: 'Convert to Recurring Digest',
        icon: 'fa-repeat',
        onOptionClick: () => onToggleDeleteAfterSend(digest)
      });
    }
    else {
      userOptions.push({
        value: 'delete-after-send',
        label: 'Convert to One-Time Digest',
        icon: 'fa-hourglass-end',
        onOptionClick: () => onToggleDeleteAfterSend(digest)
      });
    }
  }

  if(onDeleteDigestType && isDraft) {
    userOptions.push({
      value: 'delete',
      label: 'Delete Digest and Settings',
      icon: 'fa-trash',
      separator: true,
      tooltip: isDraftDigestAndDefault ? 'Cannot delete default Digest' : '',
      className: isDraftDigestAndDefault ? 'dropdown-options-item disabled' : '',
      onOptionClick: !isDraftDigestAndDefault ? () => onDeleteDigestType(digest) : null
    });
  }

  if(onDeleteSentDigest && !isDraft) {
    userOptions.push({
      value: 'delete',
      label: 'Delete Digest',
      icon: 'fa-trash',
      separator: true,
      onOptionClick: () => onDeleteSentDigest(digest)
    });
  }

  return (
    <div className="digest-item-more-options">
      <Dropdown
        options={userOptions}
        dataTrackingId="digest-item-more-options-dropdown"
        keyPrefix="user"
        condensed={true} />
    </div>
  );
};

DigestItemMoreOptions.propTypes = {
  digest: PropTypes.object,
  isDraft: PropTypes.bool,
  editSettings: PropTypes.func,
  onDeleteDigestType: PropTypes.func,
  onDeleteSentDigest: PropTypes.func,
  onMakeDefaultDigest: PropTypes.func,
  onToggleDeleteAfterSend: PropTypes.func
};

DigestItemMoreOptions.defaultProps = {
  digest: {},
  isDraft: false,
  editSettings: null,
  onDeleteDigestType: null,
  onDeleteSentDigest: null,
  onMakeDefaultDigest: null,
  onToggleDeleteAfterSend: null
};

export default DigestItemMoreOptions;
