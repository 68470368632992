import ProfileOptionsModal from './_profile_options_modal';
import Icon from './_icon';
import {DropdownMenuConstants, BeginningOfTime} from '../modules/constants/dropdown_menu';
import ViewAsDropdown from './_view_as_dropdown';
import CardFreshnessTools from './_card_freshness_tools';

import {userCanCurate} from '../modules/roles_utils';
import {getCardFilterDropdownValues, freshnessDropdownValue} from '../modules/profile_utils';
import {wait} from '../modules/utils';
import {BULK_EDIT_QUERY_PARAMS} from '../modules/constants/bulk_edit';

import throttle from 'lodash/throttle';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {withCardDragging} from '../contexts/_cardDragging';

class ProfileToolbar extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    profileFilters: PropTypes.object
  };

  static propTypes = {
    history: PropTypes.object,
    profileEditMode: PropTypes.bool,
    showBattlecardCardsOnly: PropTypes.bool,
    onViewAs: PropTypes.func,
    previewingVisibilityGroupId: PropTypes.number,
    updateRival: PropTypes.func,
    cardDraggingContext: PropTypes.object,
    onToggleCardDragging: PropTypes.func,
    boards: PropTypes.arrayOf(PropTypes.object),
    onToggleBattlecardCardsOnly: PropTypes.func,
    onFilterDateClick: PropTypes.func,
    onSearchFilterSubmit: PropTypes.func
  };

  static defaultProps = {
    history: {},
    profileEditMode: false,
    showBattlecardCardsOnly: false,
    onViewAs() {},
    previewingVisibilityGroupId: null,
    updateRival() {},
    cardDraggingContext: {},
    onToggleCardDragging() {},
    boards: [],
    onToggleBattlecardCardsOnly() {},
    onFilterDateClick() {},
    onSearchFilterSubmit() {}
  };

  state = {
    showDateFilterDropdown: false,
    filterSearch: '',
    vitalsVisible: true
  };

  componentDidMount() {
    console.log('ProfileToolbar.componentDidMount: props: %o', this.props);

    this._isMounted = true;
    this.vitalsEl = document.querySelector('.board-vitals');

    const swimlanesEl = document.querySelector('.swimlanes-wrapper');

    if(swimlanesEl) {
      swimlanesEl.addEventListener('scroll', this.handleBoardScroll);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.profileEditMode && !this.props.profileEditMode && this.state.filterSearch) {
      // switching into edit mode, clear filter
      this.handleSearchFilterClear();
    }
  }

  componentWillUnmount() {
    const swimlanesEl = document.querySelector('.swimlanes-wrapper');

    if(swimlanesEl) {
      swimlanesEl.removeEventListener('scroll', this.handleBoardScroll);
    }

    this._isMounted = false;
  }

  vitalsEl = null;

  _preventDefault = e => e && e.preventDefault();

  _isEditSection = () => (/(\/\d+\/)(\w*\/)*(edit|new)\b.*$/i).test(this.context.utils.getCurrentLocation());

  _getCurrentProfile = () => {
    const {profile = {}} = this.context.utils.rival || {};

    return profile;
  };

  handleBoardScroll = throttle(e => {
    if(!e.currentTarget || !this.vitalsEl) {
      return;
    }

    const vitalsLeft = this.vitalsEl.getBoundingClientRect().left;
    const vitalsVisible = vitalsLeft > 0;

    if(this.state.vitalsVisible !== vitalsVisible) {
      this.setState({vitalsVisible});
    }
  }, 15);   // need low throttle interval here in case of rapid horizontal scrolling

  handleBackToToCClick = () => {
	  const target = this._isEditSection()
      ? '.swimlanes-boards.editing'
      : '.swimlane.ui-feed';

    const targetEl = document.querySelector(target);

    targetEl.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
  };

  handleCreateCommentClick = () => {
    const currentRival = this.context.utils.rival;

    this.context.utils.handleCreateProfileCommentClick(currentRival.profile.id, 'profile');
  };

  handleDateFilterDropdownOutsideClick = event => {
    // Check to make sure the click is not in the Date Filter dropdown
    if(event.target && this.refs.dateFilterDropdown && this.refs.dateFilterDropdown.contains(event.target)) {
      return false;
    }

    document.body.removeEventListener('mousedown', this.handleDateFilterDropdownOutsideClick, false);
    document.body.removeEventListener('touchstart', this.handleDateFilterDropdownOutsideClick, false);

    this.setState({
      showDateFilterDropdown: false
    });
  };

  handleDateFilterDropdownToggle = () => {
    const {showDateFilterDropdown} = this.state;

    document.body.removeEventListener('mousedown', this.handleDateFilterDropdownOutsideClick, false);
    document.body.removeEventListener('touchstart', this.handleDateFilterDropdownOutsideClick, false);

    if(!showDateFilterDropdown) {
      document.body.addEventListener('mousedown', this.handleDateFilterDropdownOutsideClick, false);
      document.body.addEventListener('touchstart', this.handleDateFilterDropdownOutsideClick, false);
    }

    this.setState({
      showDateFilterDropdown: !showDateFilterDropdown
    });
  };

  handleFilterDateClick = (filterOption, toggleDropdown = true) => {
    const [label = 'any time', updatedAtTimestamp = null] = filterOption || [];
    const validUpdatedAtFilter = updatedAtTimestamp > 0 || updatedAtTimestamp === null || updatedAtTimestamp === BeginningOfTime;

    if(!validUpdatedAtFilter) {
      return;
    }

    const {profileFilters} = this.context;
    const {updatedSince: filterDate} = profileFilters || {};
    const wasFreshness = filterDate === BeginningOfTime;
    const toFreshness = updatedAtTimestamp === BeginningOfTime;

    if(wasFreshness !== toFreshness) {
      const targetEl = document.getElementById('swimlanes-wrapper');

      if(targetEl) {
        targetEl.scrollLeft = 0;
      }
    }

    // hide dropdown
    if(toggleDropdown) {
      this.handleDateFilterDropdownToggle();
    }

    const {onFilterDateClick} = this.props;

    onFilterDateClick({label, updatedAtTimestamp});
  };

  handleKeyDown = event => {
    event = event || window.event;

    if(event.which === 27) {
      this.handleSearchFilterClear();
    }
  };

  handleSearchFilterSubmit = event => {
    this._preventDefault(event);

    const searchValue = this.refs.filterSearchInput.value || '';
    const prevSearchValue = this.state.filterSearch;

    this.setState({
      filterSearch: searchValue
    }, () => {
      const {onSearchFilterSubmit} = this.props;

      onSearchFilterSubmit(prevSearchValue, searchValue);
    });
  };

  handleSearchFilterClear = event => {
    this._preventDefault(event);

    this.refs.filterSearchInput.value = '';
    this.refs.filterSearchInput.focus();

    this.handleSearchFilterSubmit();
  };

  handleNewLaneClick = () => {
    // TODO: remove mediator call when possible
    // push change to listener: scrollProfileToNewLane (functionality currently in BoardList)
    klueMediator.publish('klue:profile:scrollProfileToNewLane');
  };

  handleProfileSettingsToggleDraftProfileClick = (isDraft = false) => {
    const profile = this._getCurrentProfile();

    if(_.isEmpty(profile)) {
      return;
    }

    const profileOptions = {
      id: profile.id,
      isDraft
    };

    this.context.api.profileUpdate(profileOptions).then(updatedProfile => {
      console.log(
        'ProfileToolbar.handleProfileSettingsToggleDraftProfileClick: profile #%o: %o, is draft?: %o', updatedProfile.id, updatedProfile, updatedProfile.isDraft
      );

      this.props.updateRival(updatedProfile);
      this.renderProfileSettingsModal(updatedProfile);
    });
  };

  handleProfileSettingsCloneProfileClick = (rival = {}, isTemplate) => {
    const profile = this._getCurrentProfile();
    const {utils: {dialog} = {}, api} = this.context;

    if(_.isEmpty(rival) || _.isEmpty(profile)) {
      return;
    }

    // DEBUG
    console.log('ProfileToolbar.handleProfileSettingsCloneProfileClick: cloning from board: %o, into: %o', rival, profile);

    const confirmMessage = `Are you sure you want to clone the <strong>${rival.name}</strong> ${isTemplate ? 'template' : 'board'} into this one?<br /><br />
    This action can't be undone.`;

    const confirmAction = () => {
      dialog.remove('modal-profile-settings');

      const profileOptions = {
        id: profile.id,
        fromId: rival.profileId
      };

      api.profileClone(profileOptions, clonedProfile => {
        console.log('ProfileToolbar.handleProfileSettingsCloneProfileClick: cloned from board %o successfully: %o', rival.name, clonedProfile);

        // TODO: remove mediator call when possible
        // push change to listener: force update board list count (functionality currently in BoardList)
        klueMediator.publish('klue:profile:forceRefreshBoardCounts');
      });
    };

    dialog.confirm({
      message: confirmMessage,
      okCallback: confirmAction
    });
  };

  handleProfileSettingsDeleteCompanyClick = () => {
    const {rival, dialog: {alert, confirmRestrictive, remove}} = this.context.utils;

    if(_.isEmpty(rival)) {
      return;
    }

    const confirmMessage = `Are you sure you want to delete the board for <strong>${rival.name}</strong>?<br /><br />
      <strong>WARNING:</strong> This will also permanently delete all associated battlecards, lanes and cards.`;

    const confirmAction = () => {
      remove('modal-profile-settings');

      const rivalOptions = {id: rival.id};

      this.context.api.rivalDelete({rivalOptions}).then(async () => {
        console.log('ProfileToolbar.handleProfileSettingsDeleteCompanyClick: company #%o deleted', rival.id);

        alert(
          `We&apos;ve scheduled the <strong>&quot;${rival.name}&quot;</strong> board for deletion.<br /><br />
          It&apos;ll take a few minutes to complete the process, so hang tight!`
        );

        // fake delay for a moment in case background worker has time to process quickly
        await wait(2500);

        this.props.history.push('/');
      }).catch(() => {
        console.error('ProfileToolbar.handleProfileSettingsDeleteCompanyClick: error deleting company: %o', rival);

        // TODO: response from delete rival comes back VERY slowly, may need to fake it on the front-end or add a spinner while the user waits...
        alert(
          `Uh oh... we ran into some trouble deleting the <strong>&quot;${rival.name}&quot;</strong> board. 😢<br /><br />
          Please let us know by clicking the help button in the bottom right corner of your screen, and we&apos;ll delete the board for you.`
        );
      });
    };

    confirmRestrictive({
      message: confirmMessage,
      okCallback: confirmAction,
      buttonOk: 'Yes - Delete It All Forever'
    });
  };

  handleProfileSettingsBulkEditClick = () => {
    const profile = this._getCurrentProfile() || {};
    const {id} = profile;
    const {history} = this.props;

    history.push(`/profile/${id}?${BULK_EDIT_QUERY_PARAMS}`);
    this.handleProfileSettingsCloseClick();
  };

  handleProfileSettingsClick = () => {
    this.renderProfileSettingsModal();
  };

  handleProfileSettingsCloseClick = () => {
    this.context.utils.dialog.remove('modal-profile-settings');
  };

  handleDonePreviewingClick = () => {
    const {onViewAs} = this.props;

    // Take Query away from route
    onViewAs(DropdownMenuConstants.VIEW_AS_DONE_PREVIEWING_ID);
  };

  handleToggleCardFreshness = () => {
    const {profileFilters} = this.context;
    const {updatedSince: filterDate} = profileFilters || {};

    if(filterDate === BeginningOfTime) {
      return this.handleFilterDateClick(null, false);
    }

    this.handleFilterDateClick(freshnessDropdownValue, false);
  };

  renderProfileSettingsModal = () => {
    const profile = this._getCurrentProfile();
    const content = (
      <div>
        <ProfileOptionsModal
          profile={profile}
          onToggleDraftProfileClick={this.handleProfileSettingsToggleDraftProfileClick}
          onCloneProfileClick={this.handleProfileSettingsCloneProfileClick}
          onDeleteRivalClick={this.handleProfileSettingsDeleteCompanyClick}
          onBulkEditClick={this.handleProfileSettingsBulkEditClick} />
        <div className="text-right u-pt-s">
          <div className="button" onClick={this.handleProfileSettingsCloseClick}>Done</div>
        </div>
      </div>
    );

    this.context.utils.dialog.create({
      id: 'modal-profile-settings', // calling with same id with just update the contents
      content,
      _wideMode: true
    });
  };

  renderDateFilterDropdown = () => {
    const {profileFilters} = this.context;
    const {updatedSince: filterDate} = profileFilters || {};
    const dropdownValues = getCardFilterDropdownValues({freshnessEnabled: true});

    const filterClasses = classNames({
      'toolbar-dropdown-group': true,
      'toolbar-dropdown-group--right': true,
      'toolbar-dropdown-group--active': this.state.showDateFilterDropdown
    });
    const filterDropdownClasses = classNames({
      'toolbar-button': true,
      'toolbar-button--focused': this.state.showDateFilterDropdown
    });
    const dropdownOptions = [];
    let currentLabel;

    for(let i = 0; i < dropdownValues.length; i++) {
      const isCurrent = dropdownValues[i][1] === filterDate;
      const [,, dataTrackingId] = dropdownValues[i];

      const attributes = {};

      if(dataTrackingId) {
        attributes['data-tracking-id'] = dataTrackingId;
      }

      if(isCurrent) {
        currentLabel = dropdownValues[i][0];
      }

      dropdownOptions.push(
        <div
          key={i}
          className={'toolbar-dropdown-button' + (isCurrent ? ' toolbar-dropdown-button--active' : '')}
          onClick={() => this.handleFilterDateClick(dropdownValues[i])}
          {...attributes}>{dropdownValues[i][0]}</div>
      );
    }

    return (
      <div key="filter-date" ref="dateFilterDropdown" className={filterClasses}>
        <div className="toolbar-dropdown-group_trigger">
          <div className={filterDropdownClasses} onClick={this.handleDateFilterDropdownToggle}>
            {currentLabel || '(other)'}
            <div className="toolbar-button_icon toolbar-button_icon--green toolbar-button_icon--right">
              <Icon icon={this.state.showDateFilterDropdown ? 'close' : 'arrow-down'} width="16" height="16" />
            </div>
          </div>
        </div>
        <div className="toolbar-dropdown-group_dropdown">
          <div className="toolbar-dropdown-heading">Filter By Date:</div>
          {dropdownOptions}
        </div>
      </div>
    );
  };

  renderSearchFilter = () => {
    return (
      <form key="filter-search" className="toolbar-input" onClick={this.handleSearchFilterSubmit} onKeyDown={this.handleKeyDown}>
        <input ref="filterSearchInput" className="toolbar-input_input" type="search" placeholder="Search board&hellip;" />
        <input type="submit" className="toolbar-input_faux-submit" />
        <div className="toolbar-input_icon" onClick={this.state.filterSearch ? this.handleSearchFilterClear : this.handleSearchFilterSubmit}>
          <Icon icon={this.state.filterSearch ? 'close' : 'search'} width="16" height="16" />
        </div>
      </form>
    );
  };

  renderToCBackButton = () => {
    const {previewingVisibilityGroupId, cardDraggingContext} = this.props;
    const {vitalsVisible} = this.state;
    const scrollAtZero = document.getElementById('swimlanes-wrapper')?.scrollLeft === 0;

    if(scrollAtZero ||
      (previewingVisibilityGroupId !== null) ||
      !this.vitalsEl ||
      vitalsVisible ||
      cardDraggingContext?.isCompressedCardDraggingOn) {
      return;
    }

    return (
      <div className="toolbar-button toolbar-button--back" onClick={this.handleBackToToCClick}>
        <div className="toolbar-button_icon toolbar-button_icon--left"><Icon icon="arrow-back" width="18" height="18" /></div>
        Back
      </div>
    );
  };

  render() {
    const {utils: {user, isImprovedBulkEditFreshnessVisibilityEnabled} = {}} = this.context;
    const showBulkEditAndFreshnessInToolbar = isImprovedBulkEditFreshnessVisibilityEnabled();
    const {
      previewingVisibilityGroupId,
      cardDraggingContext,
      onViewAs,
      onToggleCardDragging,
      showBattlecardCardsOnly,
      onToggleBattlecardCardsOnly
    } = this.props;
    // const {filterDate} = this.state;
    const {profileFilters} = this.context;
    const {updatedSince: filterDate} = profileFilters || {};
    const showingFreshness = filterDate === BeginningOfTime;
    const previewing = (previewingVisibilityGroupId !== null);
    const isEditSection = this._isEditSection();
    const isCurator = userCanCurate({user});
    const curatorToolsRegion = [];
    const profileFiltersRegion = [];
    const profile = this._getCurrentProfile();
    const commentsCount = profile ? profile.commentsCount : 0;
    const commentsCountBadge = commentsCount ? (
      <div className="toolbar-button_indicator">{commentsCount}</div>
    ) : null;

    // refresh vitals lane DOM reference on render in case of collapse/expand or hide action
    this.vitalsEl = document.querySelector('.board-vitals');

    // edit mode buttons
    if(!previewing && isCurator && isEditSection) {
      curatorToolsRegion.push(
        (
          <div
            key="edit-settings"
            className="toolbar-button"
            data-tracking-id="profile-toolbar-button-actions"
            title="Open Board Actions"
            onClick={this.handleProfileSettingsClick}>
            <div className="toolbar-button_icon toolbar-button_icon--left">
              <Icon icon="build" width="24" height="24" />
            </div>
            <div className="toolbar-button_label">Actions</div>
          </div>
        ),
        (
          <div
            key="edit-board"
            className="toolbar-button"
            onClick={this.handleNewLaneClick}
            data-cy="newLaneToolbarButton"
            title="Create a new Lane on this Board">
            <div className="toolbar-button_icon toolbar-button_icon--left">
              <Icon icon="board" width="24" height="24" />
            </div>
            <div className="toolbar-button_label">New Lane</div>
          </div>
        )
      );
    }

    if(isCurator) {
      if(showBulkEditAndFreshnessInToolbar && !showingFreshness) {
        curatorToolsRegion.push(
          <div
            key="edit-bulk"
            className="toolbar-button"
            data-tracking-id="profile-toolbar-bulk-edit"
            onClick={this.handleProfileSettingsBulkEditClick}>
            <div className="toolbar-button_icon toolbar-button_icon--left">
              <Icon icon="edit" width="24" height="24" />
            </div>
            <div className="toolbar-button_label">Bulk Card Actions</div>
          </div>
        );

        curatorToolsRegion.push(
          <div
            key="edit-freshness"
            className="toolbar-button"
            data-tracking-id="profile-toolbar-freshness"
            onClick={this.handleToggleCardFreshness}>
            <div className="toolbar-button_icon toolbar-button_icon--left">
              <Icon
                icon="verified"
                width="24"
                height="24"
                stroke="#575265"
                fill="rgb(255, 255, 255, 0)" />
            </div>
            <div className="toolbar-button_label">Card Freshness</div>
          </div>
        );
      }

      curatorToolsRegion.push(
        profile.isDraft
          ? (
            <div key="profile-view-as-dropdown" data-tip="Publish to preview" data-offset="{'top': 0}" data-testid="profile-view-as-dropdown_disabled">
              <ViewAsDropdown disabled={true} trackingId="profile-toolbar" onViewAs={onViewAs} />
            </div>)
          : <ViewAsDropdown key="profile-view-as-dropdown" trackingId="profile-toolbar" onViewAs={onViewAs} />
      );

      if(previewing) {
        curatorToolsRegion.push(
          <div key="done-previewing" className="toolbar-button toolbar-button--green" onClick={this.handleDonePreviewingClick}>DONE PREVIEWING</div>
        );
      }
    }

    if(!previewing) {
      profileFiltersRegion.push(
        this.renderDateFilterDropdown(),
        this.renderSearchFilter()
      );
    }

    const freshnessTools = showingFreshness
      ? (<CardFreshnessTools
          key="profile-card-freshness-tools"
          showBattlecardCardsOnly={showBattlecardCardsOnly}
          onToggleBattlecardCardsOnly={onToggleBattlecardCardsOnly}
          onExitFreshness={this.handleToggleCardFreshness} />)
      : null;

    const cardsReorder = (isCurator && !previewing && !showingFreshness) ?
      cardDraggingContext?.isCompressedCardDraggingOn
        ? (
          <div className="toolbar-button toolbar-button--done_reorder" data-tracking-id="reorder-cards-done-button" onClick={onToggleCardDragging}>
            Done Reordering
          </div>
        ) : (
          <div className="toolbar-button" data-tracking-id="reorder-cards-button" onClick={onToggleCardDragging} title="Reorder Cards">
            <div className="toolbar-button_icon">
              <Icon icon="reorder" width="24" height="24" />
            </div>
          </div>
        )
      : null;

    return (
      <div className="toolbar">
        <div className={classNames('toolbar_left', {'improved-bulk-visibility': showBulkEditAndFreshnessInToolbar})}>
          {this.renderToCBackButton()}
          {curatorToolsRegion}
        </div>
        <div className="toolbar_right">
          {cardsReorder}
          {freshnessTools}
          {!freshnessTools && <div className="toolbar-button" data-tracking-id="board-comments-button" onClick={this.handleCreateCommentClick}>
            <div className="toolbar-button_icon">
              <Icon icon="comment" width="24" height="24" />
            </div>
            {commentsCountBadge}
          </div>}
          {cardDraggingContext?.isCompressedCardDraggingOn ? null : profileFiltersRegion}
        </div>
      </div>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  updateRival: profile => dispatch.rivals.updateRivalWithProfile(profile)
});

export {ProfileToolbar as WrappedProfileToolbar};
export default withCardDragging(withRouter(connect(null, mapDispatchToProps)(ProfileToolbar)));

