import {labels} from '../_constants';
import {
  Container,
  Description,
  Title
} from './_styles';

const SurveySuccessfullySubmitted = () => (
  <Container>
    <Title>{labels.successfulSubmitPage.title}</Title>
    <Description>{labels.successfulSubmitPage.description}</Description>
  </Container>
);

export default SurveySuccessfullySubmitted;
