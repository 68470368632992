import Icon from './_icon';

import {wait} from '../modules/utils';
import {analyticsTrack} from '../modules/analytics_utils';
import {postLikesToggle} from '../modules/api/posts';

import classNames from 'classnames';

class LikeButton extends React.Component {

  static propTypes = {
    postId: PropTypes.number,
    postTitle: PropTypes.string,
    onUpdate: PropTypes.func,
    isLiked: PropTypes.bool,
    user: PropTypes.object
  };

  static defaultProps = {
    postId: 0,
    postTitle: '',
    onUpdate() {},
    isLiked: null,
    user: null
  };

  state = {
    isLoading: false
  };

  handleLikeToggle = event => {
    event && event.preventDefault();

    const {postId, postTitle, onUpdate = () => {}, isLiked} = this.props;
    let {target} = event;

    target = target.closest('.like-button');
    target.classList.add('like-button--loading');
    target.classList.add('like-button--load');

    this.setState({isLoading: true}, () => {
      postLikesToggle({postId, action: isLiked ? 'unlike' : 'like'}).then(async likes => {
        target.classList.remove('like-button--loading');

        analyticsTrack({
          type: 'event',
          category: 'Post',
          action: 'like',
          label: `${postTitle || 'Untitled'} (${postId})`
        });

        await wait(500);
        this.setState({isLoading: false});
        onUpdate(likes);
      }).catch(() => console.error(`LikeBox.handleLike failed for postId: ${postId}`));
    });
  };

  handleMouseOver = event => {
    const {isLiked} = this.props;
    let {target} = event;

    target = target.closest('.like-button');

    if(!isLiked) {
      target.classList.add('like-button_action-like');
    }
    else {
      target.classList.add('like-button_action-unlike');
    }

    target.closest('.board-footer').classList.add('board-footer--like-hover');
  };

  handleMouseOut = event => {
    event && event.preventDefault();

    let {target} = event;

    target = target.closest('.like-button');
    target.classList.remove('like-button_action-like', 'like-button_action-unlike');
    target.closest('.board-footer').classList.remove('board-footer--like-hover');
  };

  render() {
    const {isLiked, user: {firstName}} = this.props;
    const {isLoading} = this.state;
    const label = isLiked
      ? 'You found this relevant.'
      : (isLiked !== null)
        ? `${firstName}, is this relevant?`
        : 'Loading...';
    const buttonClasses = classNames('like-button', {
      'like-button--liked': isLiked,
      'like-button--loading': (isLoading || isLiked === null),
      'like-button--load': isLoading
    });

    return (
      <button
        className={buttonClasses}
        onClick={this.handleLikeToggle}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}>
        <span className="like-button-label">{label}</span>
        <Icon icon="check" className="like-button-icon" />
      </button>
    );
  }

}

export default LikeButton;
