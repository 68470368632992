import {useState, useEffect} from 'react';
import LineChart from '../charts/_jesse_line_graph.js';
import SFDCSvg from './styles/salesforce_svg.js';
import SFDCQuery from './utils/salesforce_query.js';
import {lineGraphShaper, getMonths} from './utils/salesforce_helpers.js';

const CardSFCardSFDCWins6Months = ({rival, sfdc, displayOnly}) => {
  const {name = '', profile = {}} = rival || {};
  const [percentage, setPercentage] = useState(0);
  const [months, setMonths] = useState();
  const [graphableData, setGraphableData] = useState();
  const {options: {selectedOption, defaultOptionName, getTitle} = {}} = sfdc || {};
  const loading = !graphableData || !sfdc;
  const {wideMode = false} = profile;

  useEffect(() => {
    if(!selectedOption) {
      return;
    }

    const shapeData = async () => {
      try {
        const result = await SFDCQuery.getSFDCOpps(name, true, selectedOption === defaultOptionName ? '' : selectedOption);

        if(!result) {
          return setGraphableData([]);
        }

        const {preparedWons, preparedTotal, amountWon, amountTotal} = result;
        const graphableWons = lineGraphShaper(preparedWons);
        const graphableTotal = lineGraphShaper(preparedTotal);

        // Get percentage
        const percentageTotalData = graphableTotal.reduce((sum, val) => sum + val);

        if(!percentageTotalData) {
          return setGraphableData([]);
        }

        const percentageWonsData = graphableWons.reduce((sum, val) => sum + val);

        setMonths(getMonths(preparedWons, wideMode));
        setPercentage(Math.floor((percentageWonsData / percentageTotalData) * 100));
        setGraphableData([
          {label: `Deals Closed: ${amountTotal}`, set: graphableTotal},
          {label: `Deals Won: ${amountWon}`, set: graphableWons}
        ]);
      }
      catch(error) {
        console.log(error);

        return setGraphableData([]);
      }
    };

    shapeData();
  }, [selectedOption, defaultOptionName, name, wideMode]);

  const findMax = sets => {
    let _max = 0;

    for(let v = 0; v < sets.length; v++) {
      const _setMax = Math.max(...sets[v].set);

      if(_setMax > _max) {
        _max = _setMax;
      }
    }

    return _max;
  };

  if(loading) {
    return <div className="loading">Loading...</div>;
  }

  if(_.isEmpty(graphableData)) {
    return (<div className="klue-formula-sfdc-wins">
      <header>
        <div className="branding">
          <SFDCSvg />
          <h4>{getTitle()}<br />
            <small>Updates on page reload</small>
          </h4>
        </div>
      </header>
      {name && (<p className="sfdc-no-opps">No closed Opportunities found for <strong>{name}</strong>.</p>)}
    </div>);
  }

  return (
    <div className="klue-formula-sfdc-wins">
      <header>
        <div className="branding">
          <SFDCSvg />
          <h4>{getTitle()}<br />
            <small>Updates on page reload</small>
          </h4>
        </div>
        {displayOnly ? <h1 className="stat">{isNaN(percentage) ? 0 : percentage}<span className="stat-percent">%</span></h1> : null}
      </header>
      {displayOnly ? (<div>
        <LineChart
          data={graphableData}
          labels={months}
          max={findMax(graphableData)}
          containerWidth={'274px'} />
      </div>) : null}
    </div>
  );
};

CardSFCardSFDCWins6Months.propTypes = {
  rival: PropTypes.object,
  sfdc: PropTypes.object,
  displayOnly: PropTypes.bool
};

CardSFCardSFDCWins6Months.defaultProps = {
  rival: null,
  sfdc: null,
  displayOnly: false
};

export default CardSFCardSFDCWins6Months;
