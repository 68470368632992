/* eslint-disable react/no-multi-comp */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {fetch} from '../modules/api_utils';

export const DigestsContext = createContext();

export const useDigests = () => {
  const digestsContext = useContext(DigestsContext);

  if(digestsContext === undefined) {
    throw new Error('useDigests must be used within <DigestsProvider />');
  }

  return digestsContext;
};

const sentDigestsPerPage = 10;

const useDigestsInternal = () => {
  const [overviewPageCount, setOverviewPageCount] = useState(0);
  const [overviewPage, setOverviewPage] = useState(0);
  const [upcomingDigests, setUpcomingDigests] = useState([]);
  const [sentDigests, setSentDigests] = useState([]);
  const [loadingUpcomingDigests, setLoadingUpcomingSentDigests] = useState(false);
  const [loadingSentDigests, setLoadingSentDigests] = useState(false);
  const [error, setError] = useState();
  const upcoming = useRef([]);
  const sent = useRef([]);

  const fetchUpcomingDigests = useCallback(() => {
    const sortDigests = (a, b) => {
      if(!a?.willSendAt && b?.willSendAt) {
        return 1;
      }

      if(a?.willSendAt && !b?.willSendAt) {
        return -1;
      }

      if(!a?.willSendAt && !b?.willSendAt) {
        return a?.digestType?.name?.localeCompare(b?.digestType?.name);
      }

      const sendAtCompareResult = a?.willSendAt?.localeCompare(b?.willSendAt);

      if(!sendAtCompareResult) {
        return a?.digestType?.name?.localeCompare(b?.digestType?.name);
      }

      return sendAtCompareResult;
    };

    setLoadingUpcomingSentDigests(true);
    fetch('/api/email_digests.json?limit=1000&filter=draft&typeId=all')
      .then(({data}) => {
        const {items} = data || {};

        upcoming.current = items.sort(sortDigests);
        setUpcomingDigests(upcoming.current);
      }).catch(e => {
        setError(e);
      }).finally(() => {
        setLoadingUpcomingSentDigests(false);
      });
  }, [setUpcomingDigests, setLoadingUpcomingSentDigests, setError]);

  const fetchSentDigests = useCallback(() => {
    setLoadingSentDigests(true);

    fetch(`/api/email_digests.json?page=${overviewPage + 1}&limit=${sentDigestsPerPage}&filter=not_draft&typeId=all`)
      .then(({data}) => {
        const {items, totalItems} = data || {};

        sent.current = items;
        setOverviewPageCount(Math.ceil(totalItems / sentDigestsPerPage));
        setSentDigests(sent.current);
      }).catch(e => {
        setError(e);
      }).finally(() => {
        setLoadingSentDigests(false);
      });
  }, [overviewPage, setOverviewPageCount, setSentDigests, setLoadingSentDigests, setError]);

  const reloadDigests = useCallback(() => {
    setOverviewPage(0);
    fetchUpcomingDigests();
    fetchSentDigests();
  }, [fetchSentDigests, fetchUpcomingDigests, setOverviewPage]);

  const didDeleteDigestType = useCallback(digestType => {
    const updated = upcoming.current.filter(d => d.digestType.id !== digestType.id);

    upcoming.current = updated;
    setUpcomingDigests(upcoming.current);
    fetchSentDigests();
  }, [setUpcomingDigests, fetchSentDigests]);

  const didDeleteSentDigest = useCallback(id => {
    const updated = sent.current.filter(d => d.id !== id);

    if(!updated.length) {
      setOverviewPage(Math.max(overviewPage - 1, 0));
      fetchSentDigests();
    }
    else {
      sent.current = updated;
      setSentDigests(sent.current);
    }
  }, [fetchSentDigests, overviewPage, setOverviewPage, setSentDigests]);

  const didUpdateDigestType = useCallback(({digestType, isNew}) => {
    if(!digestType) {
      return;
    }

    const {id: digestTypeId} = digestType;

    fetch(`/api/email_digests/0.json?typeId=${digestTypeId}`)
      .then(({data: updatedDigest}) => {
        let updated;

        if(isNew) {
          updated = upcoming.current.slice();
          updated.unshift(updatedDigest);
        }
        else {
          updated = upcoming.current.map(d => {
            if(d?.digestType.id === digestTypeId) {
              return {...updatedDigest};
            }

            return d;
          });
        }

        upcoming.current = updated;
        setUpcomingDigests(upcoming.current);
        fetchSentDigests();
      }).catch(e => {
        setError(e);
      });
  }, [setUpcomingDigests, setError, fetchSentDigests]);

  const getDigestFromDigestType = useCallback((digestType, fallbackNewDigest) => {
    const {id: digestTypeId} = digestType || {};

    if(!digestTypeId) {
      return null;
    }

    const match = upcoming.current.find(d => d?.digestType.id === digestTypeId);

    return match || fallbackNewDigest;
  }, []);

  const refreshDigestsRef = useCallback((id, digestRef, setter) => {
    if(!id) {
      return;
    }

    fetch(`/api/email_digests/${id}.json`)
      .then(({data: updatedDigest}) => {
        const updated = digestRef.current.map(digest => {
          if(digest?.id === id) {
            return {...updatedDigest};
          }

          return digest;
        });

        digestRef.current = updated;
        setter(digestRef.current);
      }).catch(e => {
        setError(e);
      });
  }, []);

  const didSetAsDefault = useCallback(id => {
    const currentDefault = upcoming.current.find(d => d?.digestType?.default);

    refreshDigestsRef(currentDefault?.id, upcoming, setUpcomingDigests);
    refreshDigestsRef(id, upcoming, setUpcomingDigests);
    fetchSentDigests();
  }, [refreshDigestsRef, fetchSentDigests]);

  const refreshDigest = useCallback(id => {
    if(!id) {
      return;
    }

    if(upcoming.current.find(d => d?.id === id)) {
      refreshDigestsRef(id, upcoming, setUpcomingDigests);

      return fetchSentDigests();
    }

    refreshDigestsRef(id, sent, setSentDigests);
  }, [refreshDigestsRef, fetchSentDigests]);

  const didUpdateDigest = useCallback(({digest}) => {
    if(!digest) {
      return;
    }

    refreshDigest(digest?.id);
  }, [refreshDigest]);

  useEffect(() => {
    fetchUpcomingDigests();
  }, [fetchUpcomingDigests]);

  useEffect(() => {
    fetchSentDigests();
  }, [fetchSentDigests]);

  const value = useMemo(() => ({
    upcomingDigests,
    sentDigests,
    overviewPage,
    setOverviewPage,
    overviewPageCount,
    loadingSentDigests,
    loadingUpcomingDigests,
    didDeleteDigestType,
    didDeleteSentDigest,
    didUpdateDigestType,
    didUpdateDigest,
    refreshDigest,
    didSetAsDefault,
    reloadDigests,
    getDigestFromDigestType,
    error
  }), [
    upcomingDigests,
    sentDigests,
    overviewPage,
    setOverviewPage,
    overviewPageCount,
    loadingSentDigests,
    loadingUpcomingDigests,
    didDeleteDigestType,
    didDeleteSentDigest,
    didUpdateDigestType,
    didUpdateDigest,
    refreshDigest,
    didSetAsDefault,
    reloadDigests,
    getDigestFromDigestType,
    error
  ]);

  return value;
};

export const DigestsProvider = ({children}, context) => {
  const value = useDigestsInternal(context);

  return (
    <DigestsContext.Provider value={value}>
      {children}
    </DigestsContext.Provider>
  );
};

DigestsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const withDigests = Component => {
  return props => (
    <DigestsContext.Consumer>
      {cardDraggingContext => (
        <Component {...props} digestsContext={{...cardDraggingContext}} />
      )}
    </DigestsContext.Consumer>
  );
};

