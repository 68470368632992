import FeedAdder from './_feed_adder';

import {redirectToKlueUrl} from '../modules/post_utils';
import {boardUpdate} from '../modules/api/boards';
import {commentCreateOrUpdate} from '../modules/api/comments';
import {wrapHtml, cleanedAndMaybeTruncated} from '../modules/html_utils';
import {truncateLimits} from '../modules/constants/ui';

class AttachmentBody extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    onScratchpadRefresh: PropTypes.func   // not required, conditionally used
  };

  static propTypes = {
    singlePostMode: PropTypes.bool,
    profileMode: PropTypes.bool,
    profile: PropTypes.object,
    postId: PropTypes.number,
    title: PropTypes.string,
    body: PropTypes.string,
    url: PropTypes.string,
    linkToFullPost: PropTypes.bool,
    viaEmail: PropTypes.bool,
    viaSlack: PropTypes.bool,
    viaTeams: PropTypes.bool,
    type: PropTypes.string
  };

  static defaultProps = {
    singlePostMode: false,
    profileMode: false,
    profile: null,
    postId: null,
    title: '',
    body: '',
    url: '',
    linkToFullPost: false,
    viaEmail: false,
    viaSlack: false,
    viaTeams: false,
    type: ''
  };

  state = {
    showAdder: false,
    adderMode: 'DEFAULT',    // options: DEFAULT, SAVING, SAVED
    adderStyle: {
      top: 0,
      left: 0
    },
    selectedText: '',
    isExpanded: this.props.profileMode || this.props.singlePostMode
  };

  componentDidMount() {
    // DEBUG
    console.log('AttachmentBody.componentDidMount: props: %o', this.props);

    const {profileMode, singlePostMode} = this.props;

    if(profileMode || singlePostMode) {
      this.toggleLinkClickHandlers(true);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.profileMode && !this.props.profileMode) {
      this.toggleLinkClickHandlers(true);
    }
  }

  componentWillUnmount() {
    const {profileMode, singlePostMode} = this.props;

    if(profileMode || singlePostMode) {
      this.toggleLinkClickHandlers(false);
    }
  }

  toggleLinkClickHandlers = bindMode => {
    const attachmentBody = document.querySelector('.feed-item-expanded .attachment-body-summary');
    const action = bindMode ? 'addEventListener' : 'removeEventListener';

    if(attachmentBody) {
      attachmentBody[action]('click', this.handleLinkClick);
    }
  };

  clearSelection = () => {
    if(window.getSelection) {
      if(window.getSelection().empty) {                 // chrome
        window.getSelection().empty();
      }
      else if(window.getSelection().removeAllRanges) {  // firefox
        window.getSelection().removeAllRanges();
      }
    }
    else if(document.selection) {                       // msie?
      document.selection.empty();
    }
  };

  resetAdder = callback => {
    this.setState({
      showAdder: false,
      adderMode: 'DEFAULT',
      adderStyle: {},
      selectedText: ''
    }, () => {
      typeof callback === 'function' && callback();
    });
  };

  createScratchpadItem = body => {
    const {postId, title, url, profile: {scratchpadBoardId = 0} = {}} = this.props;

    // create new post comment w/nested highlight & associate with scratchpad board for active profile
    const commentData = {
      comment: {
        title,
        body
      },
      highlights: [{
        body,
        url,
        ranges: [{
          start: '/html'    // treat as full-page highlight since we don't have actual ranges to use
        }]
      }],
      reverse: true
    };

    const commentOptions = {
      commentData,
      containerId: postId,
      containerType: 'post'
    };

    commentCreateOrUpdate({commentOptions}).then(comment => {
      if(!_.isEmpty(comment)) {
        // associate comment with scratchpad board
        if(scratchpadBoardId && comment.id) {
          const boardOptions = {
            id: scratchpadBoardId,
            comments: {
              add: [comment.id]
            }
          };

          boardUpdate({boardOptions, code: 'AttachmentBody.createScratchpadItem'})
            .then(() => {
              // set transient "newly added" state on inbound comment & wipe it out on any previous comments
              this.context.onScratchpadRefresh({comment: {...comment, isNew: true}});
            })
            .catch(error => console.error('AttachmentBody.createScratchpadItem: boardUpdate error: %o', error));
        }
      }

      this.handleAdderSaved();
    });
  };

  handleAdderSaved = () => {
    this.setState({
      adderMode: 'SAVED'
    }, () => {
      setTimeout(() => {
        // clear selection & remove adder button after delay
        this.clearSelection();
        this.resetAdder();
      }, 2000);
    });
  };

  handleAdderSaveClick = event => {
    if(event) {
      event.preventDefault();
    }

    const text = this.state.selectedText.trim();

    if(!text) {
      return;
    }

    this.setState({
      adderMode: 'SAVING'
    }, () => {
      setTimeout(() => {
        this.createScratchpadItem(text);
      }, 500);
    });
  };

  handleTextSelection = event => {
    if(event) {
      // prevent event from propagating outside of selection container
      event.stopPropagation();
    }

    const selection = document.all ? document.selection.createRange().text : document.getSelection();

    if(event.target && (event.target.className.indexOf('feed-adder')) >= 0) {
      return;
    }
    else if(!selection) {
      this.resetAdder();

      return;
    }

    const text = selection.toString().trim();
    const range = (selection.rangeCount > 0) && selection.getRangeAt(0);
    const summary = document.querySelector('.feed-item-expanded .attachment-body-summary');

    if(!range || !summary) {
      // unable to get selection range or summary not found
      this.resetAdder();

      return;
    }

    const {top: rangeTop, left: rangeLeft, width: rangeWidth} = range.getBoundingClientRect();
    const {top: parentTop, left: parentLeft} = summary.getBoundingClientRect();
    const rTop = rangeTop - parentTop;
    const rLeft = rangeLeft - parentLeft;

    this.resetAdder(() => {
      if(text) {
        this.setState({
          showAdder: true,
          adderMode: 'DEFAULT',
          adderStyle: {
            top: rTop - 64,
            left: rLeft + ((rangeWidth - rLeft) / 2) - 24
          },
          selectedText: text
        });
      }
    });
  };

  handleLinkClick = event => {
    // make all links in readability view open in a new window (#873)
    let target = event ? event.target : null;
    const parentLink = target && target.closest('a');

    if(parentLink) {
      target = parentLink;
    }

    if(target && (target.nodeName === 'A') && target.hasAttribute('href') && !target.hasAttribute('data-action')) {
      event.preventDefault();
      event.stopPropagation();

      window.open(target.getAttribute('href'), '_blank');
    }
  };

  handleViewMoreClick = event => {
    event && event.preventDefault();

    const {isExpanded} = this.state;

    this.setState({isExpanded: !isExpanded});
  };

  redirectToUrl = event => {
    const {postId, url, linkToFullPost} = this.props;

    if(event) {
      event.preventDefault();
    }

    if(linkToFullPost) {
      return redirectToKlueUrl(`/feed/posts/${postId}`, postId);
    }

    redirectToKlueUrl(url, postId);
  };

  render = () => {
    const {profileMode, singlePostMode, title, url, body: bodyHtml, linkToFullPost, type, viaEmail, viaSlack, viaTeams} = this.props;
    const {showAdder, adderStyle, adderMode, isExpanded} = this.state;
    let titleRegion = title ? (<h5 className="attachment-title">{title}</h5>) : null;
    let feedAdder;

    if(showAdder) {
      feedAdder = (
        <FeedAdder
          {...adderStyle}
          mode={adderMode}
          onAdderClick={this.handleAdderSaveClick} />
      );
    }

    if(title && (profileMode || singlePostMode)) {
      titleRegion = (
        <h5 className="attachment-title">
          <a href={url} title="View original article" target="_blank" onClick={this.redirectToUrl}>{title}</a>
        </h5>
      );
    }

    let summaryRegion;

    // NOTE: "Slack" posts/prime comment attachments are also of type 'page'
    if((type === 'page') && (bodyHtml !== url)) {
      const {attachmentSummary: limit} = truncateLimits;
      const buffer = Math.round(limit / 2);
      const {html, truncatable} = cleanedAndMaybeTruncated({dirtyHtml: bodyHtml, limit, buffer, isExpanded, processAtMentions: true});

      if(html) {
        summaryRegion = (
          <>
            <div
              data-testid="feed-post-summary"
              className="summary"
              dangerouslySetInnerHTML={wrapHtml(html)} />
            {
              truncatable
                && (<button className="btn-more button button--alt button--small" onClick={this.handleViewMoreClick}>{
                  isExpanded ? 'Less' : 'More'
                }</button>)}
          </>
        );
      }
    }

    if(!titleRegion && !summaryRegion && url) {
      titleRegion = (
        <h5 className="attachment-title attachment-title--no-body">
          <a href={url} rel="noreferrer nofollow" target="_blank">{url.replace(/(www\.)|(\/$)/gi, '')}</a>
        </h5>
      );
    }

    return (
      <div className="attachment-body" onMouseUpCapture={this.handleTextSelection}>
        <div className="attachment-body-summary">
          {titleRegion}
          {summaryRegion}
          {feedAdder}
        </div>
      </div>
    );
  };

}

export default AttachmentBody;
