import classNames from 'classnames';
import Icon from '../_icon';

const ModalEditorFooter = ({
  onCancelClick,
  onSaveClick,
  statusMessage,
  loading,
  onPermissionsClick,
  permissionTitle,
  permissionCount,
  tooltip,
  isFullScreen
}) => (
  <div className={classNames('modal-editor-footer', {'full-screen-footer': isFullScreen})}>
    <div className="footer-item left-content">
      <button
        className="permission-manager-button"
        onClick={onPermissionsClick}
        data-test-id="permission-manager-button"
        title={tooltip}>
        <Icon icon="person" className="permission-manager-button--icon" />
        {permissionTitle}
        {Boolean(permissionCount) && <span className="permission-manager-button--groups-label">+{permissionCount}</span>}
      </button>
    </div>
    <div className="footer-item right-content">
      <button onClick={onCancelClick} className="button cancel"data-testid="card-modal-cancel-button">
        Cancel & Exit
      </button>
      <button disabled={loading} onClick={onSaveClick} className="button confirm">
        {statusMessage || 'Save & Exit'}
      </button>
    </div>
  </div>
);

ModalEditorFooter.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onPermissionsClick: PropTypes.func.isRequired,
  statusMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  permissionTitle: PropTypes.string,
  permissionCount: PropTypes.number,
  tooltip: PropTypes.string,
  isFullScreen: PropTypes.bool
};

ModalEditorFooter.defaultProps = {
  onCancelClick() {},
  onSaveClick() {},
  onPermissionsClick() {},
  loading: false,
  statusMessage: '',
  permissionTitle: '',
  permissionCount: null,
  tooltip: '',
  isFullScreen: false
};

export default ModalEditorFooter;
