import {userCanCurate} from '../modules/roles_utils';

class BoardAddPanel extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    boardEditor: PropTypes.object,
    editMode: PropTypes.bool,
    onSetEditMode: PropTypes.func
  };

  static defaultProps = {
    user: {},
    boardEditor: null,
    editMode: false,
    onSetEditMode: null
  };

  componentDidMount() {
    // DEBUG
    console.log('BoardAddPanel.componentDidMount: props: %o', this.props);
  }

  toggleEditMode = () => {
    const editMode = !this.props.editMode;

    this.props.onSetEditMode(editMode, editMode ? -1 : 0);
  };

  render() {
    const {user, editMode, boardEditor} = this.props;
    let boardTitleRegion;

    if(!userCanCurate({user})) {
      return null;
    }

    if(editMode) {
      const boardEditorRegion = React.cloneElement(boardEditor, {onToggleEditMode: this.toggleEditMode});

      boardTitleRegion = (
        <div className="board-header editing">
          {boardEditorRegion}
        </div>
      );
    }
    else {
      boardTitleRegion = (
        <div className="board-header" onClick={this.toggleEditMode}>
          <span className="board-name">New lane title...</span>
          <span className="pull-right">
            <i className="fa fa-plus-circle board-header_icon-add" />
          </span>
        </div>
      );
    }

    // id="b0" is used for new lane navigation in ToC
    return (
      <div id="b0" className="ui-card board">
        {boardTitleRegion}
      </div>
    );
  }

}

export default BoardAddPanel;
