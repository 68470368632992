import styled from 'styled-components';
import tokens from '@kluein/design-tokens';

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 'none';
`;

export const Label = styled.label`
  margin: ${tokens.spacing.xxxsmall} 0;
  font-size: ${tokens.fontSize.bodyFont.small};
  color: ${tokens.color.primitives.purple[700]};
  user-select: none;
`;
