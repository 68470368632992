import {getFileType} from '../modules/attachment_utils';

import ContentZoom from './_content_zoom';

class AttachmentPreview extends React.Component {

  static propTypes = {
    attachment: PropTypes.object.isRequired,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    attachment: null,
    onRemove: null
  };

  handleRemove = event => {
    if(event) {
      event.preventDefault();
    }

    if(this.props.onRemove) {
      this.props.onRemove(event);
    }
  };

  renderRemove = () => {
    if(!this.props.onRemove) {
      return;
    }

    return (
      <a href="#" className="btn-remove" onClick={this.handleRemove}>remove</a>
    );
  };

  renderDocumentType = () => {
    const {mimeType} = this.props.attachment;
    const className = `svg-icon icon-attachment-${getFileType(mimeType)}`;

    return (
      <div>
        <i className={className} />
      </div>
    );
  };

  renderPreview = () => {
    const {attachment: {type, thumbnailUrl} = {}} = this.props;

    if((type === 'image') && thumbnailUrl) {
      return <img src={thumbnailUrl} loading="lazy" />;
    }

    // type is 'document'
    return this.renderDocumentType();
  };

  render() {
    const {attachment: {id: attachmentId, url, fileName} = {}} = this.props;

    return url ? (
      <div className="attachment-preview">
        <ContentZoom id={attachmentId}>
          <a href={url} title={fileName || ''} target="_blank">
            {this.renderPreview()}
          </a>
        </ContentZoom>
        {this.renderRemove()}
      </div>
    ) : null;
  }

}

export default AttachmentPreview;
