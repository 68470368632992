import AttachmentBody from './_attachment_body';
import AttachmentMedia from './_attachment_media';
import AttachmentSource from './_attachment_source';

import {validMediaTypes} from '../modules/constants/comments.js';
import classNames from 'classnames';
import URI from 'urijs';

const Attachment = props => {
  const {
    postId, postTitle, postBody, title, type, body, sourceName, url, timestamp, linkToFullPost,
    active, profileMode, profile, imageUrl, embedCode, singlePostMode, redirectToUrl, viaEmail, viaSlack, viaTeams
  } = props;
  const titleToRender = postTitle || title || '';
  const bodyToRender = (postBody && (postBody !== url)) ? postBody : body || '';
  const attachmentClasses = classNames(`attachment attachment-type-${type}`, {
    'attachment--active': active,
    'attachment--empty': !bodyToRender
  });
  const attachmentBody = (type !== 'page' || viaSlack || viaTeams)
    ? (null)
    : (
      <AttachmentBody
        postId={postId}
        title={titleToRender}
        body={bodyToRender}
        url={url}
        profileMode={profileMode}
        profile={profile}
        singlePostMode={singlePostMode}
        linkToFullPost={linkToFullPost}
        type={type}
        viaEmail={viaEmail}
        viaSlack={viaSlack}
        viaTeams={viaTeams} />
    );
  let attachmentMedia;

  if(!profileMode && url && validMediaTypes.includes(type) && (embedCode || imageUrl)) {
    attachmentMedia = (
      <AttachmentMedia
        linkUrl={url}
        imageUrl={imageUrl}
        sourceName={sourceName}
        embedCode={embedCode}>
        <AttachmentSource
          url={url}
          name={sourceName || URI(url).domain().toLowerCase()}
          timestamp={timestamp}
          profileMode={profileMode}
          onAttachmentClick={redirectToUrl} />
      </AttachmentMedia>
    );
  }
  else if(url) {
    attachmentMedia = (
      <div className="attachment-media">
        <AttachmentSource
          url={url}
          name={sourceName || URI(url).domain().toLowerCase()}
          timestamp={timestamp}
          profileMode={profileMode}
          onAttachmentClick={redirectToUrl} />
      </div>);
  }

  const attachmentContent = (
    <div className="attachment-content">
      {attachmentMedia}
      {attachmentBody}
    </div>
  );

  return (
    <div className={attachmentClasses}>
      {attachmentContent}
    </div>
  );
};

Attachment.propTypes = {
  profileMode: PropTypes.bool,
  postTitle: PropTypes.string,
  postBody: PropTypes.string,
  profile: PropTypes.object,
  postId: PropTypes.number,
  type: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  url: PropTypes.string,
  sourceName: PropTypes.string,
  imageUrl: PropTypes.string,
  embedCode: PropTypes.string,
  timestamp: PropTypes.string,     // ISO-8601 UTC date
  singlePostMode: PropTypes.bool,
  active: PropTypes.bool,
  redirectToUrl: PropTypes.func,
  linkToFullPost: PropTypes.bool,
  viaEmail: PropTypes.bool,
  viaSlack: PropTypes.bool,
  viaTeams: PropTypes.bool
};

Attachment.defaultProps = {
  profileMode: false,
  postTitle: '',
  postBody: '',
  profile: null,
  postId: 0,
  type: 'page',
  title: '',
  body: '',
  url: '',
  sourceName: '',
  imageUrl: '',
  embedCode: '',
  timestamp: '',
  singlePostMode: false,
  active: false,
  redirectToUrl: null,
  linkToFullPost: false,
  viaEmail: false,
  viaSlack: false,
  viaTeams: false
};

export default Attachment;
