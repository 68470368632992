import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchSimilarWebData} from './data_providers';

function parseKeywords(similarWebKeywordData) {
  if(similarWebKeywordData.length === 0) {
    throw new Error('parseKeywords: Keyword data unavailable.');
  }

  const rows = similarWebKeywordData.map(({search_term: searchTerm, share, change}) =>
    KlueTypes.KeywordStats.create({
      keyword: searchTerm,
      share,
      change: change === null || change === -1 ? null : change // SimilarWeb seems to use -1 to indicate no data. But also null at other times... undocumented.
    })
  );

  return Types.arrayOf(KlueTypes.KeywordStats).create(rows);
}

export const websiteTrafficOrganicKeywords = async company => {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('websiteTrafficOrganicKeywords: Unexpected parameter type.', company);
    throw new Error('websiteTrafficOrganicKeywords: Parameter was unexpected type.');
  }

  const similarWebData = await fetchSimilarWebData(company.info);

  return parseKeywords(similarWebData.Sources.OrganicSearchKeywords);
};

export const websiteTrafficPaidKeywords = async company => {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('websiteTrafficPaidKeywords: Unexpected parameter type.', company);
    throw new Error('websiteTrafficPaidKeywords: Parameter was unexpected type.');
  }

  const similarWebData = await fetchSimilarWebData(company.info);

  return parseKeywords(similarWebData.Sources.PaidSearchKeywords);
};
