import {useState, useEffect} from 'react';
import {DropTarget} from 'react-dnd';
import {DragTypes} from '../modules/constants/dnd';

const itemTarget = {
  canDrop() {
    return false;
  }
};

function collectItemTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

const LanesDropTarget = ({connectDropTarget, isOver, onLanesDropHover}) => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    if(!scrolling) {
      return;
    }

    const interval = setInterval(() => {
      onLanesDropHover();
    }, 10);

    return () => clearInterval(interval);
  }, [scrolling, onLanesDropHover]);

  useEffect(() => {
    if(!isOver) {
      return setScrolling(false);
    }

    const delay = setTimeout(() => setScrolling(true), 500);

    return () => clearTimeout(delay);
  }, [isOver]);

  return (
    connectDropTarget(<div className="board-lanes-drop-region" />)
  );
};

LanesDropTarget.propTypes = {
  connectDropTarget: PropTypes.func,
  isOver: PropTypes.bool,
  onLanesDropHover: PropTypes.func
};

LanesDropTarget.defaultProps = {
  connectDropTarget() {},
  isOver: false,
  onLanesDropHover() {}
};

export default DropTarget([DragTypes.CARD], itemTarget, collectItemTarget)(LanesDropTarget);

