import DigestTimelineItem from './_digest_timeline_item';
import {userCanCurate, userIsAdmin} from '../modules/roles_utils';

const DigestsUpcoming =  ({
  digests,
  rivals,
  user,
  onEditDigest,
  onEditDigestSettings,
  onDeleteDigestType,
  onMakeDefaultDigest,
  onToggleDeleteAfterSend,
  canEditDigest
}) => {
  const isAdmin = userIsAdmin({user});
  const isCurator = userCanCurate({user});

  if(!digests || !digests.length || (!isAdmin && !isCurator)) {
    return null;
  }

  return (
    <div className="digests-upcoming">
      <div className="timeline-header">Sending Next</div>
      <div>
        {digests.map(digest => {
          const {id} = digest;
          const canEdit = canEditDigest(digest);

          return (
            <DigestTimelineItem
              key={`digest-upcoming-${id}`}
              digest={digest}
              rivals={rivals}
              user={user}
              isDraft={true}
              onDigestClick={onEditDigest}
              onEditDigestSettings={onEditDigestSettings}
              onDeleteDigestType={canEdit ? onDeleteDigestType : null}
              onMakeDefaultDigest={onMakeDefaultDigest}
              onToggleDeleteAfterSend={onToggleDeleteAfterSend} />
          );
        })}
      </div>
    </div>
  );
};

DigestsUpcoming.propTypes = {
  digests: PropTypes.array.isRequired,
  rivals: PropTypes.object,
  user: PropTypes.object,
  onEditDigest: PropTypes.func.isRequired,
  onEditDigestSettings: PropTypes.func.isRequired,
  onDeleteDigestType: PropTypes.func.isRequired,
  onMakeDefaultDigest: PropTypes.func,
  onToggleDeleteAfterSend: PropTypes.func,
  canEditDigest: PropTypes.func.isRequired
};

DigestsUpcoming.defaultProps = {
  rivals: {},
  user: {},
  onMakeDefaultDigest: null,
  onToggleDeleteAfterSend: null
};

export default DigestsUpcoming;
