import React from 'react';
import PropTypes from 'prop-types';
import {EXTERNAL_LINK_CLASSNAME} from '../../../modules/constants/editor';

const WebsiteList = ({domains}) => {
  const listItems = domains.slice(0, 5).map(domain =>
    (<li key={domain}><a href={`https://${domain}`} className={EXTERNAL_LINK_CLASSNAME} target="_blank">{domain}</a></li>)
  );

  return (<ul className="link-list">{listItems}</ul>);
};

WebsiteList.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.string).isRequired
};

WebsiteList.defaultProps = {
  domains: []
};

export default WebsiteList;
