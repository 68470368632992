import {fetch, update} from '../api_utils';
import {mergeExtendedUserProperties} from '../user_utils';
import {isValidId, serializeQueryString} from '../utils';
import {USERS_PER_PAGE} from '../constants/api';

// user management API

// Gets numbers of users per specific filtered groups (All, Active, Pending, etc) to be used on Manage Users page for:
//   1) Show these counts in Show dropdown menu in the toolbar
//   2) Use to initialize the amount of pages for Pagination component
export const userGroupsCountsGet = ({search = {}, code = 'ApiUtils.userGroupsCountsGet'}) => {
  const queryString = serializeQueryString(search);

  return new Promise(resolve => {
    const url = `/api/users/counts.json?${queryString}`;

    fetch(url, {code}).then(({data}) => resolve(data));
  });
};

// TODO: unify this with AppBase.apiUsersGet
// This function is similar to `AppBase.apiUsersGet`, except that it:
//   1) doesn't override appBase's state.users
//   2) is only fetching `full` list with specified offset&count
// Its purpose is to provide segmented data for Manage Users page.
export const usersGet = ({userOptions = {kluebot: false, deleted: false, page: 1, limit: USERS_PER_PAGE}, code = 'ApiUtils.usersGet'}) => {
  // options format:
  //   userOptions: {
  //     kluebot: bool,
  //     deleted: bool,
  //     page: number,       // 1-based
  //     limit: number,      // page size
  //     order: string,      // alpha, alpha-rev, etc
  //     typeFilter: string  // active, deactivated, curators, etc
  //     groupFilter: number // visibilityGroupID
  //     query: string       // optional, restricts results by searching first_name, last_name, email fields
  //   }
  return new Promise((resolve, reject) => {
    if(_.isEmpty(userOptions)) {
      console.warn(`${code}: invalid options specified: %o`, userOptions);

      return reject(userOptions);
    }

    const {page, limit, order, typeFilter, groupFilter, query, ...keepOptions} = userOptions;
    const keep = Object.keys(keepOptions).reduce((acc, key) => {
      if(userOptions[key]) {
        acc.push(key);
      }

      return acc;
    }, []);
    const urlParams = serializeQueryString({
      keep: keep.join(','),
      full: '1',
      page,
      limit,
      order,
      typeFilter,
      groupFilter,
      query
    });

    fetch(`/api/users.json${urlParams ? `?${urlParams}` : ''}`, {code})
      .then(({data}) => {
        // remap users array to object + add name, prettyName & image variants on client-side
        const users = data.reduce((arr, u) => {
          arr.push(mergeExtendedUserProperties(u));

          return arr;
        }, []);

        console.log(`${code}: loaded users: %o`, users);

        resolve(users);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

// TODO: note gotcha: userGet() needs userOptions.userId while userUpdate() below expects userOptions.id
export const userGet = async (userOptions = {userId: 0, atName: ''}, code = 'ApiUtils.userGet') => {
  // options format:
  //   userOptions: {
  //     userId: int,        // TODO: change this to id to match userUpdate() below
  //     atName: string      // optional; required if userId not specified
  //   }
  const {userId, atName} = userOptions;

  return new Promise((resolve, reject) => {
    // NOTE: get userId: 0 for current user
    if(_.isEmpty(userOptions) || (!isValidId(userId, true) && !atName)) {
      console.warn(`${code}: invalid userId or atName specified: userId #%o, atName: %o`, userId, atName);

      return reject(userOptions);
    }

    fetch(`/api/users/${isValidId(userId, true) ? userId : atName}.json`, {code})
      .then(async ({data: user}) => {
        if(_.isEmpty(user)) {
          console.error(`${code}: no user found for userId #%o, atName: %o`, userId, atName);

          return reject(null);
        }

        console.log(`${code}: loaded userId #%o: %o`, user.id, user);

        resolve(user);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

export const userUpdate = ({userOptions = {id: 0}, code = 'ApiUtils.userUpdate'}) => {
  // options format:
  //   userOptions: {
  //     id: int               // required
  //     firstName: string,
  //     lastName: string,
  //     title: string,
  //     bio: string,
  //     featureFlag: [string | number | (string | number)[], object | array | string | number], // path, value
  //     emailNotify: bool,
  //     emailDigest: bool,
  //     image: file,
  //     addEmailAliases: string[],
  //     removeEmailAliases: string[],
  //     addRoles: string[],
  //     removeRoles: string[],
  //     addCuratingProfiles: profile[],
  //     removeCuratingProfiles: profile[]
  //   }
  const {id} = userOptions;

  return new Promise((resolve, reject) => {
    if(_.isEmpty(userOptions) || !isValidId(id, true)) {
      console.warn(`${code}: invalid options specified: %o`, userOptions);

      return reject(userOptions);
    }

    update(`/api/users/${id}.json`, JSON.stringify(userOptions), {code})
      .then(({data: updatedUser}) => {
        if(_.isEmpty(updatedUser)) {
          console.warn(`${code}: no data returned for user id: ${id}`);

          return reject();
        }

        console.log(`${code}: user with id #%o updated: %o`, updatedUser.id, updatedUser);

        // updated image may not have fully completed upload yet, fall back to uploaded image path
        if((updatedUser.image && updatedUser.image.includes('/missing/')) && userOptions.image) {
          updatedUser.image = userOptions.image;
        }

        resolve(updatedUser);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

