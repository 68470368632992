import CardStaticHtml from './_card_static_html';
import CardVisibilityGroups from './_card_visibility_groups';
import CardSelectLanePopup from './_card_select_lane_popup';
import CardMetaLink from './_card_meta_link';

// data-backed cards & dependencies
import CardInfo from '../_card_info';
import Dropdown from '../_dropdown';
import Icon from '../_icon';
import CopyButton from '../_copy_button';

import {cardGet, cardUpdate} from '../../modules/api/cards';
import {userCanCurate, userIsKluebot} from '../../modules/roles_utils';
import {analyticsTrack, addTrackingParams, trackingParamSources, SNOWPLOW_SCHEMAS} from '../../modules/analytics_utils';
import {highlightHTMLWithSearchWords, generateCardEmbedCode} from '../../modules/card_utils';
import {fetchCardSources} from '../../modules/api/sources';
import {DragTypes} from '../../modules/constants/dnd';
import {
  getCardFromLS,
  deleteCardFromLS
} from '../../modules/local_storage_utils';
import {stripHtml} from '../../modules/html_utils';
import {BeginningOfTime} from '../../modules/constants/dropdown_menu';

import classNames from 'classnames';
import {DragSource, DropTarget} from 'react-dnd';
import {flow, isEmpty} from 'lodash';
import CardTags from './_card_tags';
import FreshnessCard from './_card_freshness_card';
import CopyToBoardModal from './_card_copy_to_board_modal';

const cardSource = {
  beginDrag({id, boardId, onFindCard, cardDraggingContext}) {
    if(cardDraggingContext) {
      cardDraggingContext.onBeginDrag({cardId: id, boardId, index: onFindCard(id).index});
    }
    else {
      document.getElementById(`c${id}`).classList.add('drag-fix');
    }

    return {
      id,
      boardId,
      originalIndex: onFindCard(id).index,
      originalBoardId: boardId
    };
  },

  endDrag({id, boardId, onMoveCard, cardDraggingContext}, monitor) {
    const card = monitor.getItem();
    const didDrop = monitor.didDrop();

    if(!didDrop) {
      if(!cardDraggingContext && card.boardId === boardId) {
        onMoveCard(card.id, card.originalIndex);
      }
    }

    if(cardDraggingContext && cardDraggingContext.onEndDrag) {
      cardDraggingContext.onEndDrag();
    }
    else {
      document.getElementById(`c${id}`).classList.remove('drag-fix');
    }
  }
};

const cardTarget = {
  drop(props, monitor) {
    const {id: targetCardId, boardId: targetBoardId, cardDraggingContext, onLaneCountRefresh: onDropSuccess} = props;
    const item = monitor.getItem();
    const itemType = monitor.getItemType();

    if(monitor.didDrop()) {
      return;
    }

    if(itemType === DragTypes.SCRATCHPAD_ITEM) {
      props.onMergeCard(props.id, item, false);

      return {
        cardId: props.id,
        merged: true
      };
    }

    if(cardDraggingContext) {
      cardDraggingContext.onDrop({targetCardId, targetBoardId, onDropSuccess});
    }
  },

  canDrop(props, monitor) {
    const {cardDraggingContext} = props;
    const item = monitor.getItem();
    const itemType = monitor.getItemType();

    if(itemType === DragTypes.SCRATCHPAD_ITEM) {
      return true;
    }

    // reordering cards within a board
    return cardDraggingContext ? true : item.boardId === props.boardId;
  }
};

const collectCardTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({
      shallow: true
    }),
    didDrop: monitor.didDrop(),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
    dropResult: monitor.getDropResult()
  };
};

const collectCardSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};

class Card extends React.Component {

  static contextTypes = {
    appData: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    location: PropTypes.object
  };

  static propTypes = {
    cardDraggingContext: PropTypes.object,
    id: PropTypes.number.isRequired,
    reviewer: PropTypes.shape({
      id: PropTypes.number,
      reviewedAt: PropTypes.string
    }),
    boardId: PropTypes.number,
    data: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object)
    ]),
    lastCardUpdated: PropTypes.object,
    isVisible: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    user: PropTypes.object,
    rivals: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    dupeCardPosition: PropTypes.number,
    onMergeCard: PropTypes.func,
    onMoveCard: PropTypes.func,
    onMoveCardLane: PropTypes.func,
    onFindCard: PropTypes.func,
    onUpdateCardRefreshTitles: PropTypes.func,
    onUpdateCardDismissScratchpad: PropTypes.func,
    onCardLoaded: PropTypes.func,
    onDeleteCard: PropTypes.func,
    onSetEditMode: PropTypes.func,
    onToggleFreshCardExpanded: PropTypes.func,
    freshCardExpanded: PropTypes.object,
    profileEditMode: PropTypes.bool,
    battlecardCardsOnly: PropTypes.bool,
    battlecardCardIds: PropTypes.object,
    builderMode: PropTypes.bool,
    onToggleCardOnBattlecard: PropTypes.func,
    maxBattlecardCards: PropTypes.number,
    mergeContent: PropTypes.object,
    getSourceObject: PropTypes.func,
    onShowCardMeta: PropTypes.func,
    onToggleSnapshots: PropTypes.func,
    cardSnapshotsId: PropTypes.number,
    filtersToShowCard: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]), // (empty for no filter) or format: {q: string, updatedSince: timestamp}
    onBoardRefresh: PropTypes.func.isRequired,
    onLaneCountRefresh: PropTypes.func,
    onCardRefresh: PropTypes.func,
    showCardPermissions: PropTypes.bool,
    onChange: PropTypes.func,
    onRef: PropTypes.func,
    moveCardToLane: PropTypes.func,
    boards: PropTypes.array,
    previewingId: PropTypes.number,

    // react-dnd
    isDragging: PropTypes.bool.isRequired,
    isOver: PropTypes.bool.isRequired,
    didDrop: PropTypes.bool.isRequired,
    itemType: PropTypes.string,
    dropResult: PropTypes.object,
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired
  };

  static defaultProps = {
    cardDraggingContext: null,
    id: 0,
    reviewer: null,
    boardId: 0,
    data: {},
    isVisible: false,
    isCollapsed: false,
    user: {},
    rivals: [],
    className: '',
    dupeCardPosition: 0,
    lastCardUpdated: {},
    onMergeCard() {},
    onMoveCard() {},
    onMoveCardLane() {},
    onFindCard() {},
    onUpdateCardRefreshTitles() {},
    onUpdateCardDismissScratchpad() {},
    onSetEditMode() {},
    onToggleFreshCardExpanded() {},
    freshCardExpanded: {},
    profileEditMode: false,
    battlecardCardsOnly: false,
    battlecardCardIds: null,
    builderMode: false,
    onChange() {},
    onRef() {},
    onCardLoaded() {},
    onDeleteCard() {},
    onToggleCardOnBattlecard() {},
    maxBattlecardCards: 0,
    mergeContent: null,
    getSourceObject() {},
    onShowCardMeta() {},
    onToggleSnapshots() {},
    cardSnapshotsId: 0,
    filtersToShowCard: {},
    onBoardRefresh() {},
    onCardRefresh() {},
    onLaneCountRefresh() {},
    showCardPermissions: false,
    moveCardToLane() {},
    boards: [],
    previewingId: null,

    // react-dnd
    isDragging: false,
    isOver: false,
    didDrop: false,
    itemType: '',
    dropResult: null,
    connectDragSource() {},
    connectDragPreview() {},
    connectDropTarget() {}
  };

  state = {
    card: {},
    sources: null, // null sources indicate needs loading
    editing: false,
    showCardMeta: false,
    allowCardEdit: true,
    visible: true,
    isLoadingCard: false,
    showCopyToBoardModal: false
  };

  componentDidMount() {
    console.log('Card.componentDidMount: this.props: %o', this.props);

    const {id, user, onRef, profileEditMode, isVisible} = this.props;

    onRef(this);
    this._isMounted = true;
    this._recoveredText = '';
    this._recoveredTitle = '';

    if(profileEditMode) {
      this.possiblyRecoverUnsavedContent();
    }

    if(isVisible) {
      this.handleSync();
    }

    if(userCanCurate({user})) {
      // TODO: use another notification method (redux) here instead of Mediator pub-sub
      klueMediator.subscribe(`klue:profile:refreshCard:${id}`, this.refreshCard);   // triggered by Snapshots component
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isStateChanged = !_.isEqual(this.state, nextState);
    const isPropsChanged = !_.isEqual(this.props, nextProps);

    if(!isStateChanged && !isPropsChanged) {
      return false;
    }

    return true;
  }

  /**
   * This function should be used as a temp solution to update
   * cards content from "outside", basically it listens for an object
   * which stores the last updated card (after saving from editor modal)
   *
   * TODO: We need to refactor the entire cards build system to use a single data source (maybe redux)
   * so we can make any changes from any point of the app
   *
   * @memberof Card
   */
  componentDidUpdate() {
    const {lastCardUpdated, id} = this.props;
    const reviewer = this.getReviewer() || {};
    const {reviewedAt} = reviewer || {};
    const {card} = this.state;
    let updatedCard = {...card};
    let updateCardState = false;

    if(lastCardUpdated && lastCardUpdated.id === id) {
      // avoid infinite loop by comparing the last updated time
      if(
        lastCardUpdated.updatedAt !== card.updatedAt ||
        lastCardUpdated.isDraft !== card.isDraft
      ) {
        updateCardState = true;
        updatedCard = {...updatedCard, ...lastCardUpdated};
      }
    }

    if(!isEmpty(card) && reviewedAt && (!card.reviewer || card.reviewer.reviewedAt !== reviewedAt)) {
      updateCardState = true;
      updatedCard = {
        ...updatedCard,
        reviewer: {
          ...reviewer
        }
      };

      console.log('Card.componentDidUpdate - reviewedAt: cardId #%o updated: %o', id, updatedCard);
    }

    if(updateCardState) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({card: updatedCard}, () => console.log(`Card.componentDidUpdate: cardId #${id} updated: %o`, this.state.card));
    }
  }

  componentWillUnmount() {
    const {id, user, onRef} = this.props;

    onRef(undefined);

    this._isMounted = false;

    if(userCanCurate({user})) {
      klueMediator.remove(`klue:profile:refreshCard:${id}`);
    }
  }

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      id,
      isVisible,
      mergeContent,
      builderMode,
      profileEditMode,
      onMergeCard,
      lastCardUpdated,
      onCardRefresh
    } = this.props;
    const {card} = this.state;

    if(
      mergeContent &&
      nextProps.mergeContent &&
      mergeContent.mergedAt === nextProps.mergeContent.mergedAt
    ) {
      // reset merged content prop on card after appending to card body
      onMergeCard(id, null, true);
    }

    // Switching to builderMode means we're going to a new page so end editing mode for cards.
    if(builderMode && !nextProps.builderMode) {
      this.handleCancelChanges(false);
    }

    if(!profileEditMode && nextProps.profileEditMode) {
      this.possiblyRecoverUnsavedContent();
    }

    if(!isVisible && nextProps.isVisible) {
      if(isEmpty(card)) {
        this.handleSync();
      }
      else if(lastCardUpdated && lastCardUpdated.id === id) {
        onCardRefresh({card});
      }
    }
  }

  getReviewer = () => {
    const {reviewer} = this.props;
    const {reviewedAt} = reviewer || {};
    const {card} = this.state;
    const {reviewer: cardReviewer} = card || {};
    const {reviewedAt: cardReviewedAt} = cardReviewer || {};

    if(!reviewer || !reviewedAt) {
      return cardReviewer || null;
    }

    if(!cardReviewer || !cardReviewedAt) {
      return reviewer;
    }

    return cardReviewedAt > reviewedAt ? cardReviewer : reviewer;
  };

  handleSync = () => {
    const {id: cardId} = this.props;
    const {onCardLoaded} = this.props;
    const cardOptions = {cardId};

    this.setState({isLoadingCard: true}, () => {
      cardGet({cardOptions, code: 'Card.handleSync'}).then(async card => {
        if(!this._isMounted || isEmpty(card)) {
          return;
        }

        console.log('Card.handleSync: loaded card #%o: %o', cardId, card);

        // if card is updated via get, reset the sources.
        this.setState({card, sources: null}, onCardLoaded(card));
      }).catch(err => console.warn('Card.handleSync: unable to load card', err)).finally(() => {
        if(!this._isMounted) {
          return;
        }

        this.setState({isLoadingCard: false});
      });
    });
  };

  _preventDefault = e => e && e.preventDefault();

  _getCardUrl = () => {
    const {id} = this.props;

    return `${this.context.appData.rootUrl || ''}card/${id}`;
  };

  _finishSwitchingEditMode = () => {
    let prevEditing = false;

    this.setState(prevState => {
      prevEditing = prevState.editing;

      return {
        recoveredText: !prevEditing ? this._recoveredText : '',
        recoveredTitle: !prevEditing ? this._recoveredTitle : '',
        editing: !prevEditing
      };
    });
  };

  refreshCard = card => {
    this.setState({card}, console.warn('>>> Card.refreshCard: refreshed card: %o', card));
  };

  possiblyRecoverUnsavedContent = () => {
    const {boardId, id: cardId} = this.props;

    if(cardId || boardId) {
      const data = {parentId: boardId, id: cardId};
      const recoveredCard = getCardFromLS(data);

      if(recoveredCard) {
        if(!this.state.editing) {
          this._recoveredText = recoveredCard.text;
          this._recoveredTitle = recoveredCard.title;
          this._finishSwitchingEditMode();

          // editing state flip no longer required.
          // This is for the case when "recover => toggle" workflow is initiated automatically on card render.
          return false;
        }
      }
    }

    // editing state flip is required.
    // Regular card's click-to-edit scenario.
    return true;
  };

  handleCancelChanges = (removeCardFromStorage = true) => {
    this.setState(
      {
        editing: false,
        allowCardEdit: false // do a little toggle to reset rich text editor in card
      },
      () => {
        this.setState({
          allowCardEdit: true
        });

        return removeCardFromStorage && this._removeCardFromStorage();
      }
    );
  };

  sendUpdateRequest = card => {
    console.log('Card.sendUpdateRequest: %o', card);

    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'PUT',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        url: `/api/cards/${card.id}.json`,
        data: JSON.stringify({
          v: '3',
          boardId: card.board.id,
          templateName: card.templateName,
          data: card.data,
          viewOrder: card.viewOrder,
          isDraft: card.isDraft,
          author: this.props.user.id
        }),
        success: updatedCard => {
          if(this._isMounted) {
            console.log(
              'Card.sendUpdateRequest: updated card: %o',
              updatedCard
            );

            this.props.onUpdateCardRefreshTitles(card);
            this.setState({card});
            resolve();
          }
        },
        error: (xhr, type) => {
          if(this._isMounted) {
            console.error(
              'Card.sendUpdateRequest: error: %o, type: %o',
              xhr,
              type
            );
            reject();
          }
        }
      });
    });
  };

  handleUpdateCard = (data, options) => {
    const {
      user: {id, name},
      boardId,
      onCardRefresh
    } = this.props;
    const card = _.extend(this.state.card, {data});
    const {board: {id: destinationBoardId = 0} = {}} = options || {};

    if(!isEmpty(options)) {
      Object.assign(card, options);
    }

    this.sendUpdateRequest(card).then(() => {
      this._removeCardFromStorage();
      this._finishSwitchingEditMode();
      onCardRefresh({card});
    });

    // Stop any delayed jobs that perform saving this profile's edits in LS
    // TODO: think what happens if the response from server is unsuccessful / no internet
    if(this.timeOutHandle) {
      window.clearTimeout(this.timeOutHandle);
    }

    // update updatedAt timestamp (needed in case of filtering by time)
    this.setState(prevState => ({
      card: Object.assign(prevState.card, {
        updatedAt: new Date().toISOString(),
        author: {id, name}
      }),
      isMoved: destinationBoardId !== boardId
    }));
  };

  _removeCardFromStorage = () => {
    const {boardId, id: cardId} = this.props;

    if(cardId || boardId) {
      const data = {parentId: boardId, id: cardId};

      deleteCardFromLS(data);
    }

    this._recoveredText = '';
    this._recoveredTitle = '';
  };

  handleDeleteCard = event => {
    if(event) {
      event.preventDefault();
    }

    const confirmMessage = 'Are you sure you want to delete this card?';

    const confirmAction = () => {
      $.ajax({
        type: 'DELETE',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        url: `/api/cards/${this.state.card.id}.json`,
        success: () => {
          this._removeCardFromStorage();

          if(this._isMounted) {
            console.log(
              'Card.handleDeleteCardClick: card #%o deleted',
              this.state.card.id
            );

            // remove card from battlecard if present
            if(
              this.context.utils.isOnBattlecard(
                this.context.utils.battlecard,
                this.state.card.id
              )
            ) {
              this.toggleBattlecardStatus();
            }

            this.props.onDeleteCard(this.state.card.id);

            if(this.state.card.board.id) {
              this.props.onBoardRefresh(this.state.card.board.id);
            }
          }
        },
        error: (xhr, type) => {
          if(this._isMounted) {
            console.error(
              'Card.handleDeleteCard: error: %o, type: %o',
              xhr,
              type
            );
          }
        }
      });
    };

    this.context.utils.dialog.confirm({
      message: confirmMessage,
      okCallback: confirmAction
    });
  };

  handleCopyCard = event => {
    if(event) {
      event.preventDefault();
    }

    if(!this.state.card) {
      return;
    }

    const confirmMessage = 'Duplicate this card?';

    const confirmAction = () => {
      console.log('Card.handleCopyCard: duplicating card: %o', this.state.card);

      const {card: {board: {id: boardId}, templateName, data, tags}} = this.state;

      $.ajax({
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        url: '/api/cards.json',
        data: JSON.stringify({
          v: '3',
          boardId,
          templateName,
          data,
          viewOrder: this.props.dupeCardPosition,
          author: this.props.user.id,
          tags: (tags || []).map(t => t.id)
        }),
        success: data => {
          if(this._isMounted) {
            console.log(
              'Card.handleCopyCard: created new duplicate card: %o',
              data
            );

            this.props.onBoardRefresh(this.state.card.board.id);
          }
        },
        error: (xhr, type) => {
          if(this._isMounted) {
            console.error(
              'Card.handleCopyCard: error: %o, type: %o',
              xhr,
              type
            );
          }
        }
      });
    };

    this.context.utils.dialog.confirm({
      message: confirmMessage,
      okCallback: confirmAction
    });
  };

  handleCopyCardToBoard = () => {
    this.setState({showCopyToBoardModal: true});
  };

  toggleBattlecardStatus = event => {
    if(event) {
      event.preventDefault();
    }

    const {id, onToggleCardOnBattlecard} = this.props;
    const {card} = this.state;

    const currentBattlecard = {...this.context.utils.battlecard, card}; // NOTE: ..could be null

    onToggleCardOnBattlecard(currentBattlecard, id);
  };

  disableClick = event => {
    // prevent reorder click from triggering RTE
    if(event) {
      event.preventDefault();
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  appendMergedContent = (data, mergeContent) => {
    const {comment} = mergeContent;
    const mergeBody = comment.body;
    const source = this.props.getSourceObject(comment);

    // let the card update method know to dismiss this item from the scratchpad on save
    source.merged = true;

    if(data.sources && data.sources.length) {
      // de-dupe identical source comments
      if(
        !data.sources.some(s => s.id === source.id && s.type === source.type)
      ) {
        data.sources.push(source);
      }
    }
    else {
      data.sources = [source];
    }

    console.log(
      'Card.appendMergedContent: updated card sources: %o',
      data.sources
    );

    if(mergeBody) {
      data.textHtml += mergeBody;
    }

    console.log('Card.appendMergedContent: listRows: %o', data.listRows);

    return data;
  };

  updateSourceList = sources => {
    if(!sources || !sources.length) {
      const {card = {}} = this.state;

      return this.setState({card: {...card, sourcesCount: 0}, sources: null});
    }

    this.setState({sources});
  };

  showCardMeta = card => {
    const {onShowCardMeta} = this.props;
    const {sourcesCount} = card || {};
    const {sources} = this.state;

    if(!sources) {
      if(sourcesCount > 0) {
        return this.setState({loadingSources: true}, () => {
          fetchCardSources(card)
            .then(({sources: fetchedSources}) => {
              if(!this._isMounted) {
                return;
              }

              this.setState({loadingSources: false, sources: fetchedSources}, () => onShowCardMeta({card, sources: fetchedSources}, this.updateSourceList));
            })
            .catch(error => {
              if(!this._isMounted) {
                return;
              }

              console.error('Cards.showCardMeta', error);

              this.setState({loadingSources: false});
            });
        });
      }

      return this.setState({sources: []}, () => onShowCardMeta({card, sources: []}, this.updateSourceList));
    }

    onShowCardMeta({card, sources}, this.updateSourceList);
  };

  toggleSnapshotsLane = event => {
    this._preventDefault(event);

    const {id, onToggleSnapshots, cardSnapshotsId} = this.props;

    if(!cardSnapshotsId || (cardSnapshotsId !== id)) {
      onToggleSnapshots(id);
    }
    else {
      onToggleSnapshots();
    }
  };

  _doFiltersMatchCardData = (cardData, cardLastModified) => {
    const {filtersToShowCard = {}} = this.props;
    let stringsToSearch = [];

    // filter by search query
    if(filtersToShowCard.q) {
      stringsToSearch.push(cardData.textHtml || '');
      stringsToSearch.push(cardData.name || '');

      if(cardData.source) {
        stringsToSearch.push(cardData.source.pageTitle || '');
        stringsToSearch.push(cardData.source.userName || '');
      }

      // flatten + join listRows data
      stringsToSearch.push(
        (cardData.listRows || [])
          .reduce((acc, cur) => acc.concat(cur), [])
          .join('|')
      );
      stringsToSearch = stripHtml(stringsToSearch.join('|')); // separate by pipe to avoid search overlap

      const matcher = new RegExp(
        _.escapeRegExp(filtersToShowCard.q).replace(/\s+/g, '|'),
        'i'
      );

      if(stringsToSearch.search(matcher) < 0) {
        return false;
      }
    }

    // filter by updatedSince time
    if(filtersToShowCard.updatedSince) {
      const lastModifiedDate = Number(new Date(cardLastModified));
      const filterFromDate = Number(new Date(filtersToShowCard.updatedSince));

      if(filterFromDate && filterFromDate > lastModifiedDate) {
        return false;
      }
    }

    return true; // default to true if no filtering or filters passed
  };

  renderSnapshotsLink = () => {
    const {id, cardSnapshotsId} = this.props;
    const {card = {}} = this.state;

    if(_.isEmpty(card) || !card.snapshotsCount) {
      return;
    }

    const cardMetaClass = `card-meta-link card-meta-link--visible card-meta-link--${card.id}${(id === cardSnapshotsId) ? ' card-meta-link--active' : ''}`;

    return (
      <a
        href="#"
        className={cardMetaClass}
        onClick={this.toggleSnapshotsLane}
        data-action="showCardMeta"
        data-tip="View Card History"
        data-tracking-id="view-card-history"
        data-testid="view-card-history"
        data-offset="{'top': 0}">
        <Icon icon="history" />
      </a>
    );
  };

  handleMoveCardToLane = targetBoardId => {
    const {card} = this.state;
    const {moveCardToLane} = this.props;

    // this function is handled by profile component
    // after updating the boards state, we need to make sure the card data
    // is also update in the backend

    moveCardToLane(card, targetBoardId);
  };

  handleTagsRefresh = tags => {
    const {card = {}, isLoadingCard} = this.state;

    if(isEmpty(card) || isLoadingCard) {
      return;
    }

    this.setState({card: {...card, tags}});
  };

  trackLinkShare = (card, id, linkType) => {
    const action = linkType === 'share' ? 'copy shareable link' : 'copy embed link';
    const {location = {}, utils: {battlecard, rival: {id: rivalId = ''} = {}} = {}} = this.context;
    const isBattlecardView = location.pathname.includes('battlecard/view');
    const battlecardId = battlecard?.id;
    const {id: cardId} = card;

    const trackingDataSP = {
      schema: SNOWPLOW_SCHEMAS.cardInteraction,
      data: {
        label: '',
        view: 'board',
        type: `copy_${linkType}`,
        cardType: 'klue_card'
      },
      context: []
    };

    if(cardId) {
      trackingDataSP.context.push({
        schema: SNOWPLOW_SCHEMAS.card,
        data: {id: cardId}
      });
    }

    if(rivalId) {
      trackingDataSP.context.push({
        schema: SNOWPLOW_SCHEMAS.board,
        data: {id: rivalId}
      });
    }

    if(isBattlecardView && battlecardId) {
      trackingDataSP.context.push({
        schema: SNOWPLOW_SCHEMAS.battleCard,
        data: {id: battlecardId}
      });
    }

    analyticsTrack({
      type: 'event',
      category: 'Card',
      action,
      label: `${card.name || 'Untitled'} (${id})`
    }, trackingDataSP);
  };

  handleOnShowCardMeta = e => {
    e && e.preventDefault();

    const {card = {}} = this.state;

    this.showCardMeta(card);
  };

  isFreshnessMode = () => {
    const {filtersToShowCard = {}} = this.props;

    if(filtersToShowCard.updatedSince && (typeof filtersToShowCard.updatedSince === 'string') && filtersToShowCard.updatedSince === BeginningOfTime) {
      return true;
    }

    return false;
  };

  _embedCode = generateCardEmbedCode(`https://${this?.context.appData.v2Host}/`, this.props.id);
  _isCurator = userCanCurate({user: this.props.user});

  render() {
    const {card = {}, visible, isLoadingCard, loadingSources, sources, showCopyToBoardModal} = this.state;
    const {
      boardId,
      boards,
      builderMode,
      connectDragPreview,
      connectDragSource,
      connectDropTarget,
      didDrop,
      dropResult,
      filtersToShowCard = {},
      id,
      isDragging,
      isOver,
      itemType,
      mergeContent,
      onMoveCardLane,
      onSetEditMode,
      onToggleFreshCardExpanded,
      freshCardExpanded,
      previewingId,
      profileEditMode,
      user,
      isCollapsed,
      cardDraggingContext,
      battlecardCardsOnly,
      battlecardCardIds,
      onBoardRefresh
    } = this.props;
    const {
      utils: {
        battlecard,
        rival,
        company,
        isOnBattlecard: cardIsOnBattlecard,
        canAddToBattlecard,
        user: {id: userId},
        isQuickChangeVisibilityGroupEnabled
      }
    } = this.context;
    const {
      companyData: {showCardInfo = true}
    } = company;
    const showCardPermissions = true;
    const isOnBattlecard = cardIsOnBattlecard(battlecard, id);
    const cardLevelPermissionsEnabled = this._isCurator;
    const isProfileEditMode = this._isCurator && profileEditMode;
    const quickChangeEnabled = cardLevelPermissionsEnabled && isQuickChangeVisibilityGroupEnabled();

    const dndClassNames = {
      dragging: isDragging,
      over: false,
      dropped: false,
      'scratchpad-drop': false
    };
    const currentBoard = boards.find(b => b.id === boardId);

    // detect if this card was moved to a new board
    const isMoved = Boolean(
      currentBoard && currentBoard.isUpdated && !parseFloat(card.viewOrder)
    );

    const {author, createdAt, updatedAt, sourcesCount = 0, sources_count = 0, snapshotsCount, isDraft} = card;
    // sources array takes priority over sourcesCount
    const numberOfSources = sources ? sources.length : (sourcesCount || sources_count);
    let cardData;
    let reorderHandle;
    let cardMenu;

    if(isEmpty(card) || isLoadingCard) {
      // still loading, or in case of improperly populated data
      return <div id={`c${id}`} className={classNames('card--placeholder', {short: isCollapsed})} />;
    }

    cardData = card.data || {};

    if(itemType === DragTypes.SCRATCHPAD_ITEM) {
      dndClassNames['scratchpad-drop'] = true;

      if(isOver) {
        // scratchpad card hovering over card
        dndClassNames.over = true;
      }

      if(didDrop && dropResult && dropResult.cardId === id) {
        dndClassNames.dropped = true;
      }

      // append merged content from dropped scratchpad card
      if(mergeContent) {
        cardData = this.appendMergedContent(
          card.data,
          mergeContent
        );
      }
    }

    const getCardName = ({name}) => (name ? (
      <span>
        &quot;<strong>{name}</strong>&quot;
      </span>
    ) : (
      ''
    ));

    if(isCollapsed) {
      reorderHandle = connectDragSource(
        <div
          className="card-dragging_reorder-handle"
          onClick={this.disableClick}>
          <Icon icon="reorder" />
        </div>
      );

      const cardClass = classNames('cards-collapsed', {
        ...dndClassNames,
        dragging: cardDraggingContext?.isCardDragging(id),
        'is-over': isOver,
        untitled: !cardData.name
      });

      return connectDropTarget(connectDragPreview((
        <div id={`c${id}`} className={cardClass}>
          {cardData.name || 'Untitled Card'}
          {this._isCurator ? reorderHandle : null}
        </div>
      )));
    }

    const handleMarkCardAsReviewed = () => {
      const {
        user: {id: reviewerId}
      } = this.props;
      const {card: {id: cardId}} = this.state || {};

      if(!cardId) {
        return;
      }

      cardUpdate({
        id: cardId,
        data: {
          reviewerId,
          keepUpdatedAt: true
        }}).then(({data: c}) => {
        this.refreshCard(c);
      }).catch(err => console.warn('Card.handleMarkCardAsReviewed: unable to mark card as reviewed', err));
    };

    const handleCardPermissionsUpdated = ({card: updatedCard}) => {
      const {onCardRefresh} = this.props;

      this.setState({card: updatedCard}, () => onCardRefresh({card: updatedCard}));
    };

    const isFreshMode = this.isFreshnessMode();
    const {card: {id: cardId}} = this.state || {};

    if(isFreshMode && !freshCardExpanded[id]) {
      const name = getCardName(this._originalName ? {name: this._originalName} : cardData);

      if(battlecardCardsOnly) {
        if(!battlecardCardIds.has(cardId)) {
          return null;
        }
      }

      return (
        <FreshnessCard
          card={card}
          name={name}
          user={user}
          onSetEditMode={onSetEditMode}
          onToggleFreshCardExpanded={onToggleFreshCardExpanded}
          onMarkCardAsReviewed={handleMarkCardAsReviewed} />
      );
    }
    else if(isFreshMode && freshCardExpanded[id] && battlecardCardsOnly && !battlecardCardIds.has(cardId)) {
      return null;
    }

    // hide card if it doesn't match filters
    if(!isFreshMode && !this._doFiltersMatchCardData(cardData, card.updatedAt)) {
      // TODO: #2876 "Card, that no longer matches filter after save, gets collapsed and is in weird state"
      const name = getCardName(this._originalName ? {name: this._originalName} : cardData);

      return (
        <div className="card-hidden-by-filters ">
          Card {name} hidden by filters.
        </div>
      );
    }

    if(filtersToShowCard.q) {
      // add search highlighting to select data fields
      if(cardData.textHtml) {
        // make a copy of the original content (as cardData is cached/referenced)
        this._originalTextHtml = cardData.textHtml;
        cardData.textHtml = highlightHTMLWithSearchWords(
          filtersToShowCard.q,
          this._originalTextHtml
        );
      }

      if(cardData.name && !this._originalName) {
        // make a copy of the original content (as cardData is cached/referenced)
        this._originalName = cardData.name;
        cardData.name = highlightHTMLWithSearchWords(
          filtersToShowCard.q,
          this._originalName
        );
      }

      if(cardData.source && cardData.source.pageTitle) {
        const pattern = new RegExp(
          '(' + _.escapeRegExp(filtersToShowCard.q).replace(/\s+/g, '|') + ')',
          'gi'
        );

        // make a copy of the original content (as cardData is cached/referenced)
        cardData.source.originalPageTitle =
          cardData.source.originalPageTitle || cardData.source.pageTitle;
        cardData.source.pageTitle = cardData.source.originalPageTitle.replace(
          pattern,
          '<mark>$1</mark>'
        );
      }
    }
    else {
      // remove any highlights from cardData (as cardData is cached/referenced) by reverting to the original copy
      if(
        this._originalTextHtml &&
        this._originalTextHtml !== cardData.textHtml
      ) {
        cardData.textHtml = this._originalTextHtml;
        this._originalTextHtml = null;
      }

      if(
        this._originalName &&
        this._originalName !== cardData.name
      ) {
        cardData.name = this._originalName;
        this._originalName = null;
      }

      if(
        cardData.source &&
        cardData.source.originalPageTitle &&
        cardData.source.originalPageTitle !== cardData.source.pageTitle
      ) {
        cardData.source.pageTitle = cardData.source.originalPageTitle;
        cardData.source.originalPageTitle = null;
      }
    }

    if(isProfileEditMode && previewingId === null) {
      const uiDropdownCardOptions = [];

      reorderHandle = connectDragSource(
        <div
          className="card-static-html_drag-handle"
          onClick={this.disableClick}>
          <Icon icon="unfold_more" />
        </div>
      );

      uiDropdownCardOptions.push({
        value: 1,
        label: 'Edit',
        icon: 'fa-edit',
        onOptionClick: () => onSetEditMode(id)
      });

      const sourcesDropdownLabel = numberOfSources ? 'Edit Sources' : 'Add Sources';

      if(isFreshMode) {
        uiDropdownCardOptions.push({
          value: 'fresh-expand',
          label: freshCardExpanded[id] ? 'Collapse' : 'Expand',
          icon: 'fa-expand',
          onOptionClick: () => onToggleFreshCardExpanded(id)
        });
      }

      uiDropdownCardOptions.push({
        divider: true
      },
      {
        value: 'addSources',
        content: (
          <a
            href="#"
            key={`add-sources-${card.id}`}
            onClick={this.handleOnShowCardMeta}
            data-action="showCardMeta"
            data-testid="cardSources-showBox"
            data-tracking-id="cardSources-showBox-menubar"
            data-offset="{'top': 0}">
            <Icon width="18" height="18" icon={`${!numberOfSources ? 'info-outline' : 'info'}`} className="ui-dropdown-option_icon fa fa-link" />
            <span className="ui-dropdown-option_label">{sourcesDropdownLabel}</span>
          </a>
        )
      });

      if(isOnBattlecard) {
        uiDropdownCardOptions.push({
          value: 2,
          label: 'Remove from battlecard',
          icon: 'fa-star',
          onOptionClick: this.toggleBattlecardStatus
        });
      }
      else if(canAddToBattlecard(battlecard, id)) {
        uiDropdownCardOptions.push({
          value: 2,
          label: 'Add to battlecard',
          icon: 'fa-star-o',
          onOptionClick: this.toggleBattlecardStatus
        });
      }
      else {
        uiDropdownCardOptions.push({
          value: 2,
          label: 'Battlecard full',
          icon: 'fa-ban'
        });
      }

      if(!card.isDraft) {
        uiDropdownCardOptions.push({
          value: 3,
          label: cardLevelPermissionsEnabled
            ? 'Show to Curators Only'
            : 'Set to Draft',
          icon: 'fa-eye-slash',
          onOptionClick: () =>
            this.handleUpdateCard(card.data, {isDraft: true})
        });
      }
      else {
        uiDropdownCardOptions.push({
          value: 3,
          label: cardLevelPermissionsEnabled
            ? 'Set Permissions'
            : 'Publish Card',
          icon: 'fa-eye',
          onOptionClick: cardLevelPermissionsEnabled
            ? () => onSetEditMode(id, showCardPermissions)
            : () => this.handleUpdateCard(card.data, {isDraft: false})
        });
      }

      const uiCopyButton = (
        <CopyButton
          key={`card-${card.id}_copy-link`}
          label="Copy shareable link"
          labelClicked="Copied!"
          buttonClass="dropdown-options-item"
          iconClass="ui-dropdown-option_icon fa fa-link"
          labelClass="ui-dropdown-option_label"
          tooltip="Copy shareable link"
          dataAction="copyShareableLink"
          targetUrl={addTrackingParams(this._getCardUrl(), {
            source: trackingParamSources.card,
            content: 'card-body'
          })}
          callback={() => this.trackLinkShare(card, id, 'share')} />
      );

      uiDropdownCardOptions.push({
        divider: true
      },
      {
        linkElement: uiCopyButton
      });

      const uiCopyButtonEmbed = (
        <CopyButton
          key={`card-${card.id}_copy-embed-code`}
          label="Copy Embed Code"
          labelClicked="Copied!"
          buttonClass="dropdown-options-item"
          iconClass="ui-dropdown-option_icon fa fa-code"
          labelClass="ui-dropdown-option_label"
          tooltip="Copy embed code"
          dataAction="copyEmbedCode"
          targetUrl={this._embedCode}
          callback={() => this.trackLinkShare(card, id, 'embed')} />
      );

      uiDropdownCardOptions.push({
        linkElement: uiCopyButtonEmbed
      });

      uiDropdownCardOptions.push({
        divider: true
      },
      {
        value: 'moveLane',
        content: (
          <CardSelectLanePopup
            key="moveLane_option"
            boardId={boardId}
            moveCardToLane={this.handleMoveCardToLane}
            boards={boards} />
        ),
        icon: 'fa-arrows-h',
        onOptionClick: () => onMoveCardLane({cardId: card.id})
      });

      uiDropdownCardOptions.push({
        value: 'copy',
        label: 'Duplicate Card',
        icon: 'fa-files-o',
        onOptionClick: this.handleCopyCard
      });

      uiDropdownCardOptions.push({
        value: 'copyToBoard',
        label: 'Duplicate To Other Board',
        icon: 'fa-sign-out',
        onOptionClick: this.handleCopyCardToBoard
      });

      if(this.handleDeleteCard) {
        uiDropdownCardOptions.push({
          divider: true
        });
        uiDropdownCardOptions.push({
          value: 6,
          label: 'Delete card',
          icon: 'fa-trash',
          onOptionClick: this.handleDeleteCard
        });
      }

      cardMenu = (
        <div
          key={`card-${card.id}_menu`}
          data-testid="card-menu"
          className={'btn-group card-static-html_actions'}>
          <Dropdown
            options={uiDropdownCardOptions}
            condensed={true}
            containerClass="ui-dropdown-flush ui-dropdown-no-border ui-dropdown-card-style"
            className="btn btn-dropdown-card btn-dropdown-size-38 with-centered-flex" />
        </div>
      );
    }

    let cardClass = classNames('card-block', {
      editable: isProfileEditMode,
      editing: false,
      hidden: !visible,
      'is-draft': card && card.isDraft,
      'hide-draft-icon': cardLevelPermissionsEnabled,
      'on-battlecard': isProfileEditMode && builderMode && isOnBattlecard
    });

    if(isProfileEditMode) {
      cardClass = classNames(cardClass, {
        ...dndClassNames,
        'is-moved': isMoved,
        'is-over': isOver
      });
    }

    const horizontalScroll = document.getElementById('swimlanes-wrapper');
    const containerScroll = document.getElementById(`board_body_${boardId}`);

    const visibilityGroups = cardLevelPermissionsEnabled &&
      previewingId === null && (
      <CardVisibilityGroups
        card={card}
        previewingId={previewingId}
        isOnBattlecard={isOnBattlecard}
        onCardPermissionsUpdated={handleCardPermissionsUpdated}
        quickChangeEnabled={quickChangeEnabled}
        disableOnClickOutside={!quickChangeEnabled}
        containerScroll={containerScroll}
        horizontalScroll={horizontalScroll} />
    );

    let cardInfoAuthor;
    let cardInfoCreatedAt;
    let cardInfoUpdatedAt;
    const previousCardHistory = this._isCurator && snapshotsCount
      ? {
        onToggleCardHistory: this.toggleSnapshotsLane,
        cardHistoryCount: snapshotsCount
      }
      : null;

    if(!cardData.hasDynamicBlocks) {
      cardInfoAuthor = author;
      cardInfoCreatedAt = createdAt;
      cardInfoUpdatedAt = updatedAt;
    }

    const handleToggleFreshCardExpanded = () => {
      onToggleFreshCardExpanded(id);
    };

    const cardInfo = (cardInfoAuthor || previousCardHistory || (cardInfoCreatedAt && cardInfoUpdatedAt)) &&
      (this._isCurator || showCardInfo) && (
      <CardInfo author={cardInfoAuthor} createdAt={cardInfoCreatedAt} updatedAt={cardInfoUpdatedAt} {...previousCardHistory} />
    );
    const showSnapshots = userIsKluebot({userId, company}) || this._isCurator;
    const cardTools = (
      <div className="card-static-html_tools">
        {this._isCurator && reorderHandle}
        {showSnapshots && this.renderSnapshotsLink()}
        <CardMetaLink
          card={card}
          sources={sources}
          user={user}
          previewingId={previewingId}
          loading={loadingSources}
          showSnapshots={showSnapshots}
          onSetEditMode={onSetEditMode} />
        {isFreshMode && (<button
          type="button"
          className="card-freshness-button on-card"
          onClick={handleToggleFreshCardExpanded}
          data-tracking-id="cardFresh-expandLink"
          data-tip="Collapse Card"
          data-offset="{'top': 0}">
          <i className="fa fa-expand" />
          </button>
        )}
        {this._isCurator && cardMenu}

        {showCopyToBoardModal && <CopyToBoardModal onBoardRefresh={onBoardRefresh} card={card} onClose={() => this.setState({showCopyToBoardModal: false})} />}
      </div>
    );
    const cardRegion = (
      <div id={`c${id}`} className={cardClass}>
        <CardStaticHtml
          {...cardData}
          id={card.id}
          createdAt={createdAt}
          isDraft={isDraft}
          rival={rival}
          board={card.board}>{cardTools}</CardStaticHtml>
        <CardTags card={card} editable={true} onTagsRefresh={this.handleTagsRefresh} />
        {cardInfo}
        {visibilityGroups}
      </div>
    );

    if(isProfileEditMode) {
      return connectDropTarget(connectDragPreview(cardRegion));
    }

    return cardRegion;
  }

}

export default flow(
  DragSource(DragTypes.CARD, cardSource, collectCardSource),
  DropTarget([DragTypes.CARD, DragTypes.SCRATCHPAD_ITEM], cardTarget, collectCardTarget)
)(Card);
