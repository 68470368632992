import Icon from './_icon';
import classNames from 'classnames';

const DigestTypeCheckbox = ({id, name, checked, onClick, disabled, tooltip, dataTestId, transparentOnChecked}) => {
  const handleToggleChecked = e => {
    e?.stopPropagation();

    if(disabled) {
      return;
    }

    onClick({id, checked: !checked});
  };

  return (
    <div className={classNames('digest-type-checkbox', {
      checked,
      disabled,
      'transparent-on-checked': transparentOnChecked
    })}
      title={tooltip}
      onClick={handleToggleChecked}
      data-testid={dataTestId}>
      <div>
        <Icon icon={checked ? 'check_box_checked' : 'check_box_outline_blank'} width="20" height="20" />
      </div>
      <span>{name}</span>
    </div>
  );
};

DigestTypeCheckbox.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  transparentOnChecked: PropTypes.bool
};

DigestTypeCheckbox.defaultProps = {
  id: 0,
  name: '',
  tooltip: '',
  checked: false,
  disabled: false,
  dataTestId: null,
  onClick() {},
  transparentOnChecked: false
};

export default DigestTypeCheckbox;
