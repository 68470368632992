import Types from '../../../modules/types';
import * as KlueTypes from '../../../modules/klue_types';

import {abbreviate, percentage} from '../../../modules/number_utils';
import {EXTERNAL_LINK_CLASSNAME} from '../../../modules/constants/editor';

import styled from 'styled-components';

const Cell = styled.td`
  padding: 8px 0;
  border-top: 1px solid #ddd;
`;

const SummaryTableRow = ({summaryFact}) => {
  const {label, value} = summaryFact;

  let valueDisplay = null;

  if(Types.string.validate(value)) {
    valueDisplay = value;
  }
  else if(Types.number.validate(value)) {
    valueDisplay = abbreviate(value);
  }
  else if(KlueTypes.Measurement.validate(value)) {
    valueDisplay = `${abbreviate(value.amount)} ${value.unit}`;
  }
  else if(KlueTypes.Percentage.validate(value)) {
    valueDisplay = percentage(value.percentage);
  }
  else if(KlueTypes.Link.validate(value)) {
    const {url, title} = value;

    valueDisplay = (
      <a href={url} target="_blank" className={EXTERNAL_LINK_CLASSNAME}>{title}</a>
    );
  }
  else {
    console.warn('SummaryTableRow: unexpected value:', value);

    return null;
  }

  return (
    <tr key={label}>
      <Cell>{label}</Cell>
      <Cell>{valueDisplay}</Cell>
    </tr>
  );
};

SummaryTableRow.propTypes = {
  summaryFact: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  }).isRequired
};

export default SummaryTableRow;
