import parse5 from 'parse5';
import fromParse5 from 'hast-util-from-parse5';
import visit from 'unist-util-visit-parents';
import toHtml from 'hast-util-to-html';
import highlightWords from 'highlight-words';
import decode from 'parse-entities';
import flowRight from 'lodash/flowRight';

const removeMainTags = html => {
  return html.replace('<html><head></head><body>', '').replace('</body></html>', '');
};

const klueHighLight = ({
  initialValue,
  query,
  beforeParse = html => html,
  afterParse = html => html,
  onMatchFound = text => text,
  shouldHighLightNode = ({node, ancestors}) => true,
  shouldHighLightValue = value => true
}) => {
  const value = beforeParse(initialValue.slice());
  const parse5Ast = parse5.parse(String(value));
  const hastAst = fromParse5(parse5Ast, value);

  visit(hastAst, 'text', (node, ancestors) => {
    if(!shouldHighLightNode({node, ancestors})) {return;}

    if(!shouldHighLightValue(node.value)) {
      return;
    }

    const chunks = highlightWords({
      text: node.value,
      query
    });

    node.value = chunks
      .map(chunk => (chunk.match ? onMatchFound(chunk.text) : chunk.text))
      .join('');
  });

  return flowRight([afterParse, removeMainTags, decode, toHtml])(hastAst);
};

export default klueHighLight;
