import classNames from 'classnames';
import EmbedHtmlBuilder from './formula_builders/_embed_html_builder';
import FormulaAttachment from '../../attachments/formulae/_formula_attachment';

import {functionBuilder} from './_function_builder';
import {refreshDynamicContent} from '../../../../../modules/card_utils';
import {formulaTypes} from '../../../../../modules/constants/formulas';

import {Record} from 'immutable';

const WizardState = Record({
  selectedBuilderKey: ''
});

class KlueFormulaWizard extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    formula: PropTypes.string,
    formulaKey: PropTypes.string,
    type: PropTypes.string,
    isNewBlock: PropTypes.bool,
    onUpdateClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onSetCurrentFormula: PropTypes.func
  };

  static defaultProps = {
    formula: '',
    formulaKey: '',
    type: formulaTypes.DYNAMIC,
    isNewBlock: false,
    onUpdateClick() {},
    onCancelClick: null,
    onDeleteClick: null,
    onSetCurrentFormula() {}
  };

  state = {
    wizardState: new WizardState(),
    currentFormula: ''
  };

  componentDidMount() {
    console.log('KlueFormulaWizard.componentDidMount: props: %o', this.props);

    // froala cards will pass in the currently selected formula
    const {formula, formulaKey} = this.props;
    const {utils: {embedHtmlDynamicCardsEnabled}} = this.context;

    // check feature flags
    if(embedHtmlDynamicCardsEnabled()) {
      // TODO: avoid mutating this imported object
      functionBuilder.embedHtml = {
        displayName: 'Embed HTML',
        builderComponent: EmbedHtmlBuilder
      };
    }

    if(!formulaKey) {
      return;
    }

    this.handleBuilderChanged(formula);
    this.handleBuilderSelectionChanged(formulaKey);
  }

  componentDidUpdate() {
    refreshDynamicContent();
  }

  handleBuilderSelectionChanged = selectedKey => this.setState(prevState => ({
    wizardState: prevState.wizardState.set('selectedBuilderKey', selectedKey)
  }), () => {
    refreshDynamicContent();
  });

  handleBuilderChanged = formula => {
    const {onSetCurrentFormula} = this.props;

    onSetCurrentFormula(formula);

    // TODO: legacy local currentFormula state for old dynamic cards; update when draftjs is removed
    this.setState({currentFormula: formula});
  };

  renderPreview = currentFormula => {
    const {utils: {rival} = {}} = this.context;

    return currentFormula
      ? (<FormulaAttachment code={currentFormula} rival={rival} />)
      : (<p>Preview unavailable.</p>);
  };

  render() {
    const {wizardState, currentFormula} = this.state;
    // NOTE: most of these props will only be specified for froala cards
    const {formulaKey, formula, type, isNewBlock, onUpdateClick, onCancelClick, onDeleteClick} = this.props;
    const {selectedBuilderKey} = wizardState;
    const currentBuilderDef = functionBuilder[selectedBuilderKey];

    if(!currentBuilderDef) {
      return null;
    }

    const BuilderComponent = currentBuilderDef.builderComponent;
    const addDisabled = !currentFormula;
    const {utils: {rival}} = this.context;
    const builderProps = {rival, formula, currentFormula};

    return (
      <div className="klue-formula-wizard">
        <BuilderComponent onChange={this.handleBuilderChanged} {...builderProps} />
        <p className="klue-formula-wizard_commitment-button-group">
          {onCancelClick && (
            <button className="button button--xsmall button--disabled button--cancel" onClick={onCancelClick}>Cancel</button>
          )}
          {onDeleteClick && (
            <button className="button button--xsmall button--disabled button--delete" onClick={onDeleteClick}>Delete</button>
          )}
          <button
            className={classNames({'button button--xsmall button--add': Boolean(formulaKey)})}
            disabled={addDisabled}
            onClick={() => onUpdateClick(currentFormula)}>
            {isNewBlock ? 'Add' : 'Save'}
          </button>
        </p>
        {(type === formulaTypes.DYNAMIC) && (
          <div className="klue-formula-wizard_preview">
            <p className="klue-formula-wizard_section-header">Preview</p>
            <hr className="klue-formula-wizard_section-separator" />
            {this.renderPreview(currentFormula)}
          </div>
        )}
      </div>
    );
  }

}

export default KlueFormulaWizard;
