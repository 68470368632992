const DigestScheduleInfo = () => (
  <div className="help-block u-mb-xs">
    Set the default <strong>Intel Digest</strong> delivery frequency and timing.
    Scheduling is always based on <a href="https://www.timeanddate.com/worldclock/timezone/utc">Universal Time</a> to
    avoid time-zone dependent behaviors.<br />
    <br />
    While email will be <strong>sent</strong> based on this schedule, <strong>receiving</strong> is
    subject to delivery delays that can be up to a few hours.<br />
  </div>
);

export default DigestScheduleInfo;
