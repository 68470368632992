import classNames from 'classnames';
import {getSendDigestTooltip} from '../modules/digest_utils';

import {
  getNextDigestUTCDateTime,
  renderNextDigestSendTime,
  renderDigestScheduleString,
  getDigestAudienceStrings
} from '../modules/digest_utils';
import Icon from './_icon';

const DigestScheduleAndAudience = ({
  digest,
  canSendDigest,
  canEditDigest,
  reorderMode,
  visibilityGroups,
  onSendDigest
}) => {
  if(!digest) {
    return null;
  }

  const {manual, archivedAt} = digest || {};

  if(manual || archivedAt) {
    return null;
  }

  const handleSendDigest = e => {
    if(!canSendDigest) {
      return;
    }

    onSendDigest(e);
  };

  const nextDigestUTCDateTime = getNextDigestUTCDateTime(digest);
  const {audience, visibilityGroupIds} = getDigestAudienceStrings(digest);
  const hasFullAccessUsers = visibilityGroups?.includes(0);
  let tooltip = visibilityGroupIds
    ? visibilityGroupIds.length
      ? visibilityGroupIds.reduce((acc, id) => {
        const vg = visibilityGroups.find(group => group.id === id);

        if(vg) {
          return acc + (acc.length ? '\n' : '') + vg.name;
        }

        return acc;
      }, '')
      : 'None Selected'
    : audience;

  if(hasFullAccessUsers) {
    tooltip += (tooltip.length ? '\n' : '') + 'Full Access Users';
  }

  const sendToolTip = getSendDigestTooltip(canSendDigest, canEditDigest);

  return (
    <div className="digest-schedule-and-audience">
      <div className="schedule">
        <Icon icon="event" width={20} height={20} />
        {!nextDigestUTCDateTime ? (
          <div className="digest-time">
            <span>{renderNextDigestSendTime(nextDigestUTCDateTime, true)}</span>
          </div>
        ) : (
          <div>
            {renderNextDigestSendTime(nextDigestUTCDateTime, true)}
            <div className="digest-date">
              {nextDigestUTCDateTime.format('llll')} {Intl.DateTimeFormat().resolvedOptions().timeZone}
            </div>
          </div>)}
        <span title={sendToolTip} data-tracking-id="send-digest-now-button">
          <button
            data-test-id="send-now-button"
            className={classNames('send-now-button', {disabled: !canSendDigest || reorderMode})}
            onClick={handleSendDigest}>
            send now
          </button>
        </span>
      </div>
      <div className="audience">
        <div className="audience-when">
          <span>{renderDigestScheduleString(digest || {})}</span>
        </div>
        <div className="audience-who" title={tooltip}>
          <Icon icon="group" width={20} height={20} />
          <span>{audience}</span>
        </div>
      </div>
    </div>
  );
};

DigestScheduleAndAudience.propTypes = {
  digest: PropTypes.object,
  canSendDigest: PropTypes.bool,
  canEditDigest: PropTypes.bool,
  reorderMode: PropTypes.bool,
  visibilityGroups: PropTypes.arrayOf(PropTypes.object),
  onSendDigest: PropTypes.func
};

DigestScheduleAndAudience.defaultProps = {
  digest: null,
  canSendDigest: false,
  canEditDigest: false,
  reorderMode: false,
  visibilityGroups: [],
  onSendDigest() {}
};

export default DigestScheduleAndAudience;
