import DropdownMenu from './_dropdown_menu';
import {useEffect, useState} from 'react';
import {userCanCurate} from '../modules/roles_utils';
import {FULL_ACCESS_USER_LABEL} from '../modules/constants/permissions';

const TRACKING_IDS = {
  viewAs: {
    drop: '--view-as_drop',
    fullAccessUsers: '--view-as_full-access-users',
    visibilityGroup: '--view-as_visibility-group'
  }
};

function ViewAsDropdown({trackingId, onViewAs, disabled}, context = {}) {
  const [groupsState, setGroupsState] = useState();
  const [visibilityGroups, setVisibilityGroups] = useState([]);
  const {utils: {user, visibilityGroups: globalVisibilityGroups}, api: {visibilityGroupsGet}} = context;

  const getTrackingId = type => {
    return trackingId + TRACKING_IDS.viewAs[type];
  };

  useEffect(() => {
    setGroupsState((visibilityGroups || []).map(vg => {
      const {id, name} = vg;

      return [id, name, undefined, undefined, {trackingId: getTrackingId('visibilityGroup')}];
    }, []).concat([[0, FULL_ACCESS_USER_LABEL, undefined, undefined, {trackingId: getTrackingId('fullAccessUsers')}]]));
  }, [visibilityGroups]);

  useEffect(() => {
    if(globalVisibilityGroups) {
      return setVisibilityGroups(globalVisibilityGroups);
    }

    // the theory here is I don't have to call .then() on the promise because the useEffect will be
    // called again when the globalVisibilityGroups changes
    visibilityGroupsGet({code: 'Toolbar.visibilityGroupsGet'});
  }, [globalVisibilityGroups, visibilityGroupsGet, user]);

  if(!userCanCurate({user})) {
    return null;
  }

  return (
    <DropdownMenu
      key="view-as-menu"
      id="viewAsDropdown"
      className="toolbar-button-group--wide"
      disabled={disabled}
      values={groupsState}
      label="View as"
      selectCallback={onViewAs}
      justification="left"
      allowEmptySelection={true}
      displayDropdownTitle={false}
      alwaysRenderLabel={true}
      showSelectedValueWhenCollapsed={false}
      icon="visibility-on"
      dataTrackingId={getTrackingId('drop')} />
  );
}

ViewAsDropdown.contextTypes = {
  api: PropTypes.object.isRequired,
  utils: PropTypes.object.isRequired
};

ViewAsDropdown.propTypes = {
  trackingId: PropTypes.string,
  onViewAs: PropTypes.func,
  disabled: PropTypes.bool
};

ViewAsDropdown.defaultProps = {
  trackingId: '',
  onViewAs() {},
  disabled: false
};

export default ViewAsDropdown;
