import PostBox, {PostBox as PostBoxUnwrapped} from './_post_box';
import FeedFilters from './_feed_filters';
import {wait} from '../modules/utils';
import {withCardDragging} from '../contexts/_cardDragging';

class FeedPostBox extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    postsUrl: PropTypes.string,
    query: PropTypes.string,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    postUserId: PropTypes.number,
    postSource: PropTypes.string,
    postDateStart: PropTypes.string,
    postDateEnd: PropTypes.string,
    postRivals: PropTypes.array,
    digest: PropTypes.object,
    onPinnedCommentUpdatedForFavorite: PropTypes.func,
    onUpdateFavorite: PropTypes.func,
    onDeleteFavorite: PropTypes.func,
    digestMode: PropTypes.bool,
    onFindPost: PropTypes.func,
    findPostId: PropTypes.number,
    selectedPostData: PropTypes.object,
    onToggleFeedItem: PropTypes.func,
    dim: PropTypes.bool,
    feedStartCollapsed: PropTypes.bool,
    onToggleFeed: PropTypes.func,
    updatePosts: PropTypes.func,
    digestTypesData: PropTypes.arrayOf(PropTypes.object),
    onDigestTypeFavoriteUpdate: PropTypes.func,
    cardDraggingContext: PropTypes.object
  };

  static defaultProps = {
    user: null,
    users: {},
    postsUrl: '',
    query: '',
    sortBy: '',
    sortDirection: '',
    postUserId: 0,
    postSource: '',
    postDateStart: null,
    postDateEnd: null,
    postRivals: null,
    digest: null,
    onPinnedCommentUpdatedForFavorite: null,
    onUpdateFavorite: null,
    onDeleteFavorite: null,
    digestMode: false,
    onFindPost: null,
    findPostId: null,
    selectedPostData: null,
    onToggleFeedItem() {},
    dim: false,
    feedStartCollapsed: false,
    onToggleFeed() {},
    updatePosts() {},
    digestTypesData: [],
    onDigestTypeFavoriteUpdate() {},
    cardDraggingContext: {}
  };

  state = {
    query: this.props.query,
    sortBy: this.props.sortBy,
    sortDirection: this.props.sortDirection,
    postUserId: this.props.postUserId,
    postSource: this.props.postSource,
    postDateStart: this.props.postDateStart,
    postDateEnd: this.props.postDateEnd,
    showFeedFilters: false
  };

  componentDidMount() {
    console.log('FeedPostBox.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    const rival = this.context.utils.rival || {};

    if(!_.isEmpty(rival)) {
      this.handleFilterUpdate({
        name: rival.name,
        rivalId: rival.id
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {rival} = this.context.utils;
    const nextRival = nextContext.utils.rival;

    if((rival || nextRival) && rival !== nextRival) {
      console.log(
        'FeedPostBox.componentWillReceiveProps: rival: %o, nextRival: %o',
        rival,
        nextRival
      );

      this.resetFeedPosition();

      if(!nextRival) {
        klueMediator.publish('klue:box:clearSearch', true);
      }
    }

    if(
      nextProps.sortBy !== this.props.sortBy ||
      nextProps.sortDirection !== this.props.sortDirection ||
      nextProps.postUserId !== this.props.postUserId ||
      nextProps.postSource !== this.props.postSource ||
      nextProps.postDateStart !== this.props.postDateStart ||
      nextProps.postDateEnd !== this.props.postDateEnd
    ) {
      console.log(
        'FeedPostBox.componentWillReceiveProps: nextProps: %o',
        nextProps
      );

      this.setState({
        sortBy: nextProps.sortBy,
        sortDirection: nextProps.sortDirection,
        postUserId: nextProps.postUserId,
        postSource: nextProps.postSource,
        postDateStart: nextProps.postDateStart,
        postDateEnd: nextProps.postDateEnd
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _setScrollableContainer = node => {
    this.scrollableContainer = node;
  };

  resetFeedPosition = () =>
    this.scrollableContainer &&
    this.scrollableContainer.scrollTo({
      top: 0,
      left: 0
    });

  handleFilterUpdate = (options = {}) => {
    console.log('FeedPostBox.handleFilterUpdate: options: %o', options);

    const changedProfile = false;
    const filterState = {
      query: ''
    };

    if(changedProfile) {
      // changing profiles, clear all filters beyond selected company
      Object.assign(filterState, {
        query: '',
        postUserId: 0,
        postSource: '',
        postDateStart: null,
        postDateEnd: null
      });

      // clear existing search
      klueMediator.publish('klue:box:clearSearch', true);
    }
    else if(options.hasOwnProperty('postsFilterOptions')) {
      Object.assign(filterState, options.postsFilterOptions);
    }

    this.resetFeedPosition();
    this.setState(filterState);
  };

  handleSortUpdate = sortOptions => {
    if(_.isEmpty(sortOptions) || !_.isObject(sortOptions)) {
      return;
    }

    this.setState(
      {
        sortBy: sortOptions.sortBy,
        sortDirection: sortOptions.sortDirection
      },
      () => {
        this.resetFeedPosition();
        this.forceUpdate();
      }
    );
  };

  handleSearch = query => {
    this.setState(
      {
        query
      },
      () => {
        this.resetFeedPosition();
        this.forceUpdate();
      }
    );
  };

  handleToggleFeedFilters = async event => {
    const {target} = event;
    const {showFeedFilters} = this.state;
    const filters =
      target &&
      target.closest('.swimlanes-boards').querySelector('.feed-filters');

    filters &&
      filters.classList.toggle('feed-filters--collapsed', showFeedFilters);
    showFeedFilters && (await wait(60));
    this.setState({
      showFeedFilters: !showFeedFilters
    });
  };

  renderPostBoxRegion = () => {
    const {
      postsUrl,
      digestMode,
      findPostId,
      onFindPost,
      user,
      users,
      digest,
      onPinnedCommentUpdatedForFavorite,
      onUpdateFavorite,
      onDeleteFavorite,
      selectedPostData,
      onToggleFeedItem,
      feedStartCollapsed,
      onToggleFeed,
      updatePosts,
      digestTypesData,
      onDigestTypeFavoriteUpdate,
      dim,
      postRivals,
      cardDraggingContext
    } = this.props;
    const {
      query,
      sortBy,
      sortDirection,
      postUserId,
      postSource,
      postDateStart,
      postDateEnd,
      showFeedFilters
    } = this.state;

    const reorderingCards = Boolean(cardDraggingContext?.isCompressedCardDraggingOn);

    return (
      <PostBox
        scrollableContainer={this.scrollableContainer}
        url={postsUrl}
        query={query}
        sortBy={sortBy}
        sortDirection={sortDirection}
        digestMode={digestMode}
        onToggleFeedFilters={this.handleToggleFeedFilters}
        postUserId={postUserId}
        postSource={postSource}
        postDateStart={postDateStart}
        postDateEnd={postDateEnd}
        postRivals={postRivals}
        findPostId={findPostId}
        onFindPost={onFindPost}
        user={user}
        users={users}
        showComments={true}
        digest={digest}
        onPinnedCommentUpdatedForFavorite={onPinnedCommentUpdatedForFavorite}
        onUpdateFavorite={onUpdateFavorite}
        onDeleteFavorite={onDeleteFavorite}
        selectedPostData={selectedPostData}
        onToggleFeedItem={onToggleFeedItem}
        showFeedFilters={showFeedFilters}
        dim={dim}
        isFeedCollapsed={feedStartCollapsed || reorderingCards}
        onToggleFeed={onToggleFeed}
        updatePosts={updatePosts}
        digestTypesData={digestTypesData}
        onDigestTypeFavoriteUpdate={onDigestTypeFavoriteUpdate}
        reorderingCards={reorderingCards} />
    );
  };

  render() {
    const {utils: {rival, getCollapsedLanes} = {}} = ({} = this.context);
    const {digestMode, findPostId} = this.props;
    const {showFeedFilters} = this.state;
    const profile = rival ? rival.profile : null;
    const isFeedCollapsed =
      profile &&
      getCollapsedLanes(profile.id).includes(PostBoxUnwrapped.regionTypes.FEED);
    const feedFilters =
      (!profile && !findPostId) || (!isFeedCollapsed && showFeedFilters) ? (
        <FeedFilters
          onSearchFeed={this.handleSearch}
          onSortFeed={this.handleSortUpdate}
          onFilterFeed={this.handleFilterUpdate}
          expanded={showFeedFilters} />
      ) : null;

    if(!profile && !digestMode) {
      return (
        <>
          {feedFilters}
          <div
            ref={this._setScrollableContainer}
            className={`ui-layout endless-container${
              showFeedFilters ? ' filters-enabled' : ''
            }`}>
            {this.renderPostBoxRegion()}
          </div>
        </>
      );
    }

    return (
      <>
        {feedFilters}
        {this.renderPostBoxRegion()}
      </>
    );
  }

}

export default withCardDragging(FeedPostBox);
