import Types from '../types';
import * as KlueTypes from '../klue_types';

import {company} from './lib/company';
import {companyName} from './lib/company_name';
import {companySummary} from './lib/company_summary';
import {compareTimeseries} from './lib/compare_timeseries';
import {employeeCount} from './lib/employee_count';
import {facebookLikes} from './lib/facebook_likes';
import * as financialStatements from './lib/financial_statements';
import {homepageScreenshot} from './lib/homepage_screenshot';
import {jobCategories} from './lib/job_categories';
import {jobPostings} from './lib/job_postings';
import {jobPostingsByRegion} from './lib/job_postings_by_region';
import {keyPeople} from './lib/key_people';
import {normalizeTimeseries} from './lib/normalize_timeseries';
import {officeLocations} from './lib/office_locations';
import {operatingMetric} from './lib/operating_metric';
import {operatingMetricsSummary} from './lib/operating_metrics_summary';
import {website} from './lib/website';
import {websiteTrafficOrganicKeywords, websiteTrafficPaidKeywords} from './lib/website_traffic_keywords';
import {websiteTrafficPageViews, websiteTrafficSources, websiteEngagementSummary, similarWebsites} from './lib/website_traffic_stats';
import {revenues} from './lib/revenues';
import {embedHtml} from './lib/embed_html';

import * as recentPosts from './lib/recent_posts';

/**
 * This is the main spec for the Klue Function language. These functions are used within cards on customer profiles.
 * DO NOT MAKE BACKWARD-INCOMPATIBLE CHANGES to these function signatures. You will break cards.
 */
export const FUNCTION_LIBRARY_SPEC = Object.freeze({
  BALANCE_SHEET_STATEMENTS: {
    description: 'Returns a table of information from up to four years of the company\'s balance sheets on record.',
    example: 'BALANCE_SHEET_STATEMENTS(COMPANY("klue.com"))',
    handler: financialStatements.balanceSheetStatements,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Table
    }
  },
  BALANCE_SHEET_STATEMENTS_QUARTERLY: {
    description: 'Returns a table of information from up to four quarters of the company\'s balance sheets on record.',
    example: 'BALANCE_SHEET_STATEMENTS_QUARTERLY(COMPANY("klue.com"))',
    handler: financialStatements.balanceSheetStatementsQuarterly,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Table
    }
  },
  CASH_FLOW_STATEMENTS: {
    description: 'Returns a table of information from up to four years of the company\'s cash flow statements on record.',
    example: 'CASH_FLOW_STATEMENTS(COMPANY("klue.com"))',
    handler: financialStatements.cashFlowStatements,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Table
    }
  },
  CASH_FLOW_STATEMENTS_QUARTERLY: {
    description: 'Returns a table of information from up to four quarters of the company\'s cash flow statements on record.',
    example: 'CASH_FLOW_STATEMENTS_QUARTERLY(COMPANY("klue.com"))',
    handler: financialStatements.cashFlowStatementsQuarterly,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Table
    }
  },
  COMPANY: {
    description: 'Get a Company instance from a domain name.',
    example: 'COMPANY("klue.com")',
    handler: company,
    parameters: [
      {
        name: 'companyInfo',
        type: Types.string
      }
    ],
    returns: {
      type: KlueTypes.Company
    }
  },
  COMPANY_NAME: {
    description: 'Get the name of the given company',
    example: 'COMPANY_NAME(COMPANY("klue.com"))',
    handler: companyName,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      }
    ],
    returns: {
      type: Types.string
    }
  },
  COMPANY_SUMMARY: {
    description: 'Get a quick summary of company stats',
    example: 'COMPANY_SUMMARY(COMPANY("klue.com"))',
    handler: companySummary,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.SummaryFact())
    }
  },
  COMPARE_TIMESERIES: {
    description: 'Returns all the provided timeseries as a collection which can be compared.',
    example: 'COMPARE_TIMESERIES(EMPLOYEE_COUNT(COMPANY("amazon.com")), EMPLOYEE_COUNT(COMPANY("boeing.com")))',
    handler: compareTimeseries,
    parameters: [
      {
        type: KlueTypes.Timeseries,
        name: 'series',
        variadic: true
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.Timeseries)
    }
  },
  EMPLOYEE_COUNT: {
    description: 'Returns a time-series of estimated employee count for the given company.',
    example: 'EMPLOYEE_COUNT(COMPANY("klue.com"))',
    handler: employeeCount,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Timeseries
    }
  },
  ENGAGEMENT_SUMMARY: {
    description: 'Returns a summary overview of recent traffic engagement with the given website.',
    example: 'ENGAGEMENT_SUMMARY(COMPANY("klue.com"))',
    handler: websiteEngagementSummary,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.SummaryFact())
    }
  },
  FACEBOOK_LIKES: { // TODO: The option to create new 'Facebook Likes' dynamic card is removed. We have to remove this function when the time comes
    description: 'Returns a time-series of the number of likes on the given company\'s Facebook page.',
    example: 'FACEBOOK_LIKES(COMPANY("klue.com"))',
    handler: facebookLikes,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Timeseries
    }
  },
  HOMEPAGE_SCREENSHOT: {
    description: 'Returns a screenshot of the homepage for the given company.',
    example: 'HOMEPAGE_SCREENSHOT(COMPANY("klue.com"))',
    handler: homepageScreenshot,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Image
    }
  },
  INCOME_STATEMENTS: {
    description: 'Returns a table of information from up to four years of the company\'s income statements on record.',
    example: 'INCOME_STATEMENTS(COMPANY("klue.com"))',
    handler: financialStatements.incomeStatements,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Table
    }
  },
  INCOME_STATEMENTS_QUARTERLY: {
    description: 'Returns a table of information from up to four quarters of the company\'s income statements on record.',
    example: 'INCOME_STATEMENTS_QUARTERLY(COMPANY("klue.com"))',
    handler: financialStatements.incomeStatementsQuarterly,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Table
    }
  },
  JOB_CATEGORIES: {
    description: 'Returns a distribution of job categories for the given company.',
    example: 'JOB_CATEGORIES(COMPANY("klue.com"))',
    handler: jobCategories,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Distribution
    }
  },
  JOB_POSTINGS: {
    description: 'Returns a list of active job postings for the company.',
    example: 'JOB_POSTINGS(COMPANY("klue.com"))',
    handler: jobPostings,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.JobPosting)
    }
  },
  JOB_POSTINGS_BY_REGION: {
    description: 'Returns a list of active job postings for the company sorted by region.',
    example: 'JOB_POSTINGS_BY_REGION(COMPANY("klue.com"))',
    handler: jobPostingsByRegion,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: Types.mapOf(KlueTypes.City, Types.arrayOf(KlueTypes.JobPosting))
    }
  },
  KEY_PEOPLE: {
    description: 'Returns a list of key people at the company.',
    example: 'KEY_PEOPLE(COMPANY("klue.com"))',
    handler: keyPeople,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.Person)
    }
  },
  NORMALIZE_TIMESERIES: {
    description: 'Transforms the given timeseries so that all values are relative to the first value (normalized to 1.00).',
    example: 'NORMALIZE_TIMESERIES(EMPLOYEE_COUNT(COMPANY("klue.com")))',
    handler: normalizeTimeseries,
    parameters: [
      {
        type: KlueTypes.Timeseries,
        name: 'timeseries'
      }
    ],
    returns: {
      type: KlueTypes.Timeseries
    }
  },
  OFFICE_LOCATIONS: {
    description: 'Returns a list of places where the company has offices.',
    example: 'OFFICE_LOCATIONS(COMPANY("klue.com"))',
    handler: officeLocations,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.GeoPlace)
    }
  },
  OPERATING_METRIC: {
    description: 'Returns a timeseries for a company-specific operating metric for the given company.',
    example: 'OPERATING_METRIC(COMPANY("amazon.com"), "Robots Employed")',
    handler: operatingMetric,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      },
      {
        name: 'metric',
        type: Types.string
      }
    ],
    returns: {
      type: KlueTypes.Timeseries
    }
  },
  OPERATING_METRICS_SUMMARY: {
    description: 'Returns a table summarizing all known company-specific operating metrics for the given company.',
    example: 'OPERATING_METRICS_SUMMARY(COMPANY("klue.com"))',
    handler: operatingMetricsSummary,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.SummaryFact())
    }
  },
  RECENT_BLOG_POSTS: {
    description: 'Returns a list of recent blog posts from the company.',
    example: 'RECENT_BLOG_POSTS(COMPANY("klue.com"))',
    handler: recentPosts.recentBlogPosts,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.OnlineArticle)
    }
  },
  RECENT_CASE_STUDIES: {
    description: 'Returns a list of recent case studies and success stories from the company.',
    example: 'RECENT_CASE_STUDIES(COMPANY("klue.com"))',
    handler: recentPosts.recentCaseStudies,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.OnlineArticle)
    }
  },
  RECENT_PRESS_RELEASES: {
    description: 'Returns a list of recent press releases from the company.',
    example: 'RECENT_PRESS_RELEASES(COMPANY("klue.com"))',
    handler: recentPosts.recentPressReleases,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.OnlineArticle)
    }
  },
  RECENT_PUBLICATIONS: {
    description: 'Returns a list of recent published assets from the company.',
    example: 'RECENT_PUBLICATIONS(COMPANY("klue.com"))',
    handler: recentPosts.recentPublications,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.OnlineArticle)
    }
  },
  RECENT_REGULATORY_FILINGS: {
    // TODO: The option to create new 'Regulatory Filings' dynamic card is removed. We have to remove this function when the time comes
    description: 'Returns a list of recent regulatory filings from the company.',
    example: 'RECENT_REGULATORY_FILINGS(COMPANY("klue.com"))',
    handler: recentPosts.recentRegulatoryFilings,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.OnlineArticle)
    }
  },
  RECENT_SOCIAL_UPDATES: {
    description: 'Returns a list of recent social updates from the company.',
    example: 'RECENT_SOCIAL_UPDATES(COMPANY("klue.com"))',
    handler: recentPosts.recentSocialUpdates,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.OnlineArticle)
    }
  },
  REVENUES: {
    description: 'Returns a time-series of the given company\'s reported revenues.',
    example: 'REVENUES(COMPANY("klue.com"))',
    handler: revenues,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: KlueTypes.Timeseries
    }
  },
  SIMILAR_WEBSITES: {
    description: 'Returns a list of websites similar to the one provided.',
    example: 'SIMILAR_WEBSITES(COMPANY("klue.com"))',
    handler: similarWebsites,
    parameters: [
      {
        type: KlueTypes.Company,
        name: 'company'
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.Website)
    }
  },

  TRAFFIC_PAGE_VIEWS: {
    description: 'Returns a timeseries of average page views per visit to the given website.',
    example: 'TRAFFIC_PAGE_VIEWS(COMPANY("klue.com"))',
    handler: websiteTrafficPageViews,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      }
    ],
    returns: {
      type: KlueTypes.Timeseries
    }

  },
  TRAFFIC_SOURCES: {
    description: 'Returns a distribution of sources which drive traffic to the given website.',
    example: 'TRAFFIC_SOURCES(COMPANY("klue.com"))',
    handler: websiteTrafficSources,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      }
    ],
    returns: {
      type: KlueTypes.GenericSeries
    }
  },
  WEBSITE: {
    description: 'Returns the primary website of the given company.',
    example: 'WEBSITE(COMPANY("klue.com"))',
    handler: website,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      }
    ],
    returns: {
      type: KlueTypes.Website
    }
  },
  WEBSITE_TRAFFIC_ORGANIC_KEYWORDS: {
    description: 'Returns a list of popular keywords which drive organic search traffic to the company website.',
    example: 'WEBSITE_TRAFFIC_ORGANIC_KEYWORDS(COMPANY("klue.com"))',
    handler: websiteTrafficOrganicKeywords,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.KeywordStats)
    }

  },
  WEBSITE_TRAFFIC_PAID_KEYWORDS: {
    description: 'Returns a list of popular keywords which drive paid search traffic to the company website.',
    example: 'WEBSITE_TRAFFIC_PAID_KEYWORDS(COMPANY("klue.com"))',
    handler: websiteTrafficPaidKeywords,
    parameters: [
      {
        name: 'company',
        type: KlueTypes.Company
      }
    ],
    returns: {
      type: Types.arrayOf(KlueTypes.KeywordStats)
    }
  },
  EMBED_HTML: {
    description: 'Returns html content to render third party widgets.',
    example: 'EMBED_HTML("<iframe width=\\"560\\" height=\\"315\\" src=\\"https://www.youtube.com/embed/-kBhum7f4rI\\" loading=\\"lazy\\" allowfullscreen></iframe>")',
    handler: embedHtml,
    parameters: [
      {
        name: 'htmlContent',
        type: Types.string
      }
    ],
    returns: {
      type: KlueTypes.HtmlContent
    }
  }
});

class KlueFunctionLibrary {

  find(identifier) {
    return FUNCTION_LIBRARY_SPEC[identifier.toUpperCase()];
  }

}

export default KlueFunctionLibrary;
