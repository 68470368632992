import classNames from 'classnames';

import Icon from './_icon';

const BoardCuratorsHeader = ({expanded, title, onToggleExpanded}) => {
  return (
    <div className={classNames('', {'vitals-toc_container--open': expanded})}>
      <div
        className="vitals-toc_container_header"
        onClick={onToggleExpanded}>
        <Icon icon="people" className="vitals-toc_container_header_icon" />
        <a data-test-id="board-curators_header_title" href="#" className="vitals-toc_container_header_text">{title}</a>
        <div className="vitals-toc_container_header_right-container">
          <Icon icon={expanded ? 'arrow-up' : 'arrow-down'} width="16" height="16" className="vitals-toc_container_header_toggle-icon" />
        </div>
      </div>
    </div>
  );
};

BoardCuratorsHeader.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.string,
  onToggleExpanded: PropTypes.func
};

BoardCuratorsHeader.defaultProps = {
  expanded: false,
  title: 'Board Curators',
  onToggleExpanded() {}
};

export default BoardCuratorsHeader;
