import URI from 'urijs';

/* eslint-disable max-len */
export const isValidUrl = (url = '') => {
  // url validation regex via @diegoperini, see:
  // - https://gist.github.com/dperini/729294
  // - https://mathiasbynens.be/demo/url-regex
  return /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url
  );
};

/* eslint-disable max-len */
export const isValidCompleteDomain = (domain = '') => {
  // Check if the input starts with a protocol
  if (/^[a-zA-Z]+:\/\//.test(domain)) {
    return false;
  }
  
  // This regex is adapted from the original isValidUrl regex,
  // focusing only on the domain part (including subdomains)
  const completeDomainRegex = /^(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))$/i;
  return completeDomainRegex.test(domain);
};

export const parseHostname = (url = '') => {
  const parser = document.createElement('a');

  parser.href = url;

  return parser.hostname;
};

export const getHostAndProtocol = (url = '') => {
  if(url.match(/^https?:\/\//)) {
    const uri = URI(url);

    url = URI.build({
      protocol: uri.protocol(),
      hostname: uri.host()
    });
  }

  return url;
};

export const removeHttp = (url = '') => {
  if(url.match(/^https?:\/\//)) {
    url = URI(url).host().replace(/^www./, '');
  }

  return url;
};

export const parseDomain = (url = 'about:blank') => {
  const parts = (new URL(url)).hostname.toLowerCase().split('.');

  return parts.slice(parts.length - 2).slice(0, 1).pop();
};

const emailRegExString = '(?:\\w[-\\w.]{0,63}?)@[-\\w]{1,63}(?:\\.[-\\w]{1,63}){1,4}\\b';

// Looking at the mail string to see if it is somewhat reasonable w.r.t. format.
// Very basic since this can get overcomplicated and is non-trival w.r.t a truly valid check.
const isReasonableEmailPattern = href => {
  const regEx = new RegExp(`^mailto:${emailRegExString}`, 'i');

  return href?.match(regEx);
};

const isValidUrlButMissingScheme = url => {
  // We decided to assume https if no scheme is specified.
  // The user can correct by editing the link if need be.
  return isValidUrl(`https://${url}`);
};

export const isEmail = url => {
  const regEx = new RegExp(emailRegExString, 'i');

  return url?.match(regEx);
};

export const isValidLinkUrl = (url, options = {}) => {
  // this will cover valid http(s) urls... like the old days.
  const isValid = isValidUrl(url);

  if(isValid) {
    return {isValid};
  }

  const {allowEmail = false} = options;

  try {
    const {protocol, href} = new URL(url);

    if(allowEmail && protocol === 'mailto:' && isReasonableEmailPattern(href)) {
      // I pass back the href since that appears to correct some format issues.
      // I'll utilize href if passed back.
      return {isValid: true, href};
    }

    // some other protocol we don't support
    return {isValid: false};
  }
  catch{
    // will get here if protocal is not specified.

    if(isValidUrlButMissingScheme(url)) {
      return {isValid: true, needsScheme: true};
    }

    return {isValid: false};
  }
};
