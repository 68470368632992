const TRACKING_IDS = {
  winLoss: {
    buyerPulseSurvey: {
      nextQuestionButton: 'winloss_survey--next-button',
      previousQuestionButton: 'winloss_survey--previous-button',
      submitButton: 'winloss_survey--submit-button',
      footer: {
        poweredBy: 'winloss_survey--footer-klue'
      }
    }
  }
};

export default TRACKING_IDS;
