import {FormSpy} from 'react-final-form';

const WizardStep = props => {
  const {children, title, showError} = props;

  return (
    <div className="wizard_step input-settings">
      <h1 className="wizard_step-header">{title}</h1>
      <div className="wizard_step-content">{children}</div>

      {// showing API submission errors here
        showError &&
        <FormSpy
          subscribe={{submitError: true}}
          render={({submitError}) =>
            (submitError ? <p className="wizard_step-error">{submitError}</p> : null)
          } />}
    </div>
  );
};

WizardStep.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  showError: PropTypes.bool

};

WizardStep.defaultProps = {
  showError: false
};

export default WizardStep;
