import {T_IDENTIFIER, T_STRING, T_NUMBER} from './tokenizer';
import {FUNC_CALL, FUNC_NAME, PARAM_LIST, PARAM} from './parser';

function getFunctionName(funcCallNode) {
  const nameNodes = funcCallNode.findChildren(FUNC_NAME);
  const nameNode = nameNodes.length === 1 ? nameNodes[0] : null;

  if(nameNode === null) {
    throw new Error('Expected FUNC_NAME.');
  }

  const identifiers = nameNode.findChildren(T_IDENTIFIER);
  const identifier = identifiers.length === 1 ? identifiers[0] : null;

  if(identifier === null) {
    throw new Error('Expected T_IDENTIFIER.');
  }

  return identifier.value;
}

function getFunctionParams(funcCallNode) {
  const paramListNodes = funcCallNode.findChildren(PARAM_LIST);
  const paramListNode = paramListNodes.length === 1 ? paramListNodes[0] : null;

  if(paramListNode === null) {
    return [];
  }

  const paramNodes = paramListNode.findChildren(PARAM);
  const params = paramNodes.map(paramNode => {
    const funcCallNodes = paramNode.findChildren(FUNC_CALL);

    if(funcCallNodes.length === 1) {
      const fc = FunctionCall.fromNode(funcCallNodes[0]);   // eslint-disable-line no-use-before-define

      return fc;
    }

    const strings = paramNode.findChildren(T_STRING);

    if(strings.length === 1) {
      const res = strings[0].value.match(/"(.*)"/);

      return res[1];
    }

    const numbers = paramNode.findChildren(T_NUMBER);

    if(numbers.length === 1) {
      return Number.parseFloat(numbers[0].value);
    }

    throw new Error('Unexpected param type.');
  });

  return params;
}

class FunctionCall {

  static fromNode(funcCallNode) {
    const name = getFunctionName(funcCallNode);

    const params = getFunctionParams(funcCallNode);

    return new FunctionCall(name, params);
  }

  constructor(name, params) {
    this.name = name;
    this.params = params;
  }

}

export default FunctionCall;
