import {fetch, destroy} from '../api_utils';
import {isValidId, serializeQueryString} from '../utils';

// at-notifications management
export function notificationsCountsGet(notificationOptions = {}, code = 'ApiUtils.notificationsCountsGet') {
  // options format:
  //   notificationOptions: {
  //     ownProfiles: non-null  // only include notifications on profiles/battlecards which the user is curating
  //     dismissed: non-null    // include dismissed notifications
  //   }
  const urlParams = serializeQueryString(notificationOptions);

  return new Promise((resolve, reject) => {
    fetch(`/api/notifications/counts.json${urlParams ? `?${urlParams}` : ''}`, {code})
      .then(({data}) => {
        console.log(`${code}: notifications count loaded: %o`, data);

        resolve(data);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
}

export function notificationsGet(notificationOptions = {page: 1, limit: 15}, code = 'ApiUtils.notificationsGet') {
  // options format:
  //   notificationOptions: {
  //     ownProfiles: non-null, // only include notifications on profiles/battlecards which the user is curating
  //     dismissed: non-null,   // include dismissed notifications
  //     page: number,          // 1-based
  //     limit: number          // page size; default limit is 15
  //   }
  const urlParams = serializeQueryString(notificationOptions);

  return new Promise((resolve, reject) => {
    fetch(`/api/notifications.json${urlParams ? `?${urlParams}` : ''}`, {code})
      .then(({data: {totalItems, items}}) => {
        console.log(`${code}: notifications loaded: %o, total notifications: %o`, items, totalItems);

        resolve({notifications: items, totalNotifications: totalItems});
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
}

export function notificationDelete({notificationOptions = {}, undo = false, code = 'ApiUtils.notificationDelete'}) {
  // options format:
  //   notificationOptions: {
  //     id: int               // required, notificationId to toggle
  //   },
  //   undo: bool              // required, true to undelete notification
  return new Promise((resolve, reject) => {
    if(_.isEmpty(notificationOptions) || !isValidId(notificationOptions.id)) {
      console.warn('ApiUtils.notificationDelete: invalid options specified: %o', notificationOptions);

      return reject(notificationOptions);
    }

    // limit=0 needs to be included to retrieve all notifications (instead of trimmed listing)
    destroy(`/api/notifications/${notificationOptions.id}.json?limit=0${undo ? '&undo=y' : ''}`, {code})
      .then(({data: updatedNotification}) => {
        console.log(
          `${code}: notification #%o %o, updated notification: %o`,
          notificationOptions.id, (undo ? 'un-' : '') + 'dismissed', updatedNotification
        );

        resolve(updatedNotification);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
}
