import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ChartLegend extends Component {

  static propTypes = {
    entries: PropTypes.arrayOf(PropTypes.string).isRequired
  };

  static defaultProps = {
    entries: []
  };

  render() {
    let i = 0;
    const items = this.props.entries.map(e => {
      const seriesLabelChar = String.fromCharCode(97 + i++);

      return (
        <li className="chart-legend_entry" key={seriesLabelChar}>
          <i className={`chart-legend_symbol chart-legend_symbol--series-${seriesLabelChar}`} /> {e}
        </li>
      );
    });

    return (
      <ul className="chart-legend">
        {items}
      </ul>
    );
  }

}

export default ChartLegend;
