import {useState, useEffect, useCallback} from 'react';
import SFDCQuery from '../components/card_templates/sfdc_cards/utils/salesforce_query';
import {SFDC_ERROR_MESSAGES} from '../modules/constants/formulas';
import {getFormulaTerms} from '../modules/editor_utils';
import {dig} from '../modules/utils';

const defaultLatestOpportunitiesOptionFormula = 'SFDC_LATEST_OPPORTUNITIES';
const defaultLatestOpportunitiesOptionName = 'Opportunities Last 6 Months';
const defaultWinRateOptionFormula = 'SFDC_WINS';
const defaultWinRateOptionName = 'Closed Opportunities Last 6 Months';

const getFormulaKeyFromName = name => {
  if(name === defaultLatestOpportunitiesOptionName) {
    return defaultLatestOpportunitiesOptionFormula;
  }

  if(name === defaultWinRateOptionName) {
    return defaultWinRateOptionFormula;
  }

  return (name || '').replace(/[^a-zA-Z\d]/g, c => `_${c.charCodeAt(0).toString(16)}`);
};

// this is how to get the name from the formulaKey that is encoded using the method in `getFormulaKeyFromName`
/*
const getNameFromFormulaKey = formulaKey => {
  if(formulaKey === defaultLatestOpportunitiesOptionFormula) {
    return defaultLatestOpportunitiesOptionName;
  }

  if(formulaKey === defaultWinRateOptionFormula) {
    return defaultWinRateOptionName;
  }

  return (formulaKey || '').replace(/_[a-fA-F\d]{2}/g, match => String.fromCharCode(parseInt(match.slice(1), 16)));
};
*/

const getDefaultsFunctionName = name =>
  (name === defaultLatestOpportunitiesOptionFormula ? {
    defaultOptionName: defaultLatestOpportunitiesOptionName,
    defaultTitle: 'Latest Opportunities'
  } : {
    defaultOptionName: defaultWinRateOptionName,
    defaultTitle: 'Win Rate (Last 6 Months)'

  });

const getSFDCOptions = ({company, instance, defaultOptionName}) => {
  if(!company || !instance) {
    return [];
  }

  const sfdc = dig(company, 'companyData.sfdc', {});
  const {opportunityCondition} = sfdc[instance] || {};

  if(!opportunityCondition) {
    return [{name: defaultOptionName}];
  }

  if(typeof opportunityCondition === 'string') {
    return [];
  }

  const options = Object.keys(opportunityCondition).reduce((acc, key) => {
    const {title} = opportunityCondition[key];

    acc.push({
      name: key,
      formulaKey: getFormulaKeyFromName(key),
      title
    });

    return acc;
  }, []);

  options.unshift({name: defaultOptionName});

  return options.sort((a, b) => a.name.localeCompare(b.name));
};

const useSFDCOptions = ({instance, company, formula, defaultOptionName, defaultTitle}) => {
  const options = getSFDCOptions({instance, company, defaultOptionName});
  const [formulaTerms, setFormulaTerms] = useState();
  const [selectedOption, setSelectedOption] = useState();

  const getTitle = useCallback(() => {
    if(!options || !selectedOption) {
      return defaultTitle;
    }

    const match = options.find(option => (option.name === selectedOption) && option.title);

    return match ? match.title : defaultTitle;
  }, [options, selectedOption, defaultTitle]);

  useEffect(() => {
    if(!formulaTerms || !instance) {
      return;
    }

    const option = options.filter(({name}) => name !== defaultOptionName).find(({name, formulaKey}) => {
      if(formulaTerms.includes(formulaKey)) {
        return true;
      }

      // this check, based on the original encoding formula, allows us to forego adding migration code to
      // update any SFDC formulas created with an opportunityData option but not affected by
      // https://github.com/kluein/klue/pull/9606. Essentially we use the original encoding function
      // to see if the option is specified under the old format.
      const originalFormulaKey = (name || '').toUpperCase().replace(/ /g, '_');

      return formulaTerms.includes(originalFormulaKey);
    });

    setSelectedOption(option ? option.name : defaultOptionName);
  }, [options, formulaTerms, instance, defaultOptionName]);

  useEffect(() => {
    setFormulaTerms(getFormulaTerms(formula));
  }, [formula]);

  if(!instance || !company || !formula) {
    return null;
  }

  return {options, selectedOption, setSelectedOption, defaultOptionName, getTitle};
};

export const useSFDC = ({company, formula, funcName}) => {
  const [instance, setInstance] = useState();
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Could not connect to Salesforce');

  useEffect(() => {
    const getInstance = async () => {
      try {
        const {
          instance: sInstance
        } = await SFDCQuery.getInstance() || {};

        if(!sInstance || !sInstance.length) {
          setErrorStatus(true);

          return setErrorMessage(SFDC_ERROR_MESSAGES.NO_INSTANCE());
        }

        setInstance(sInstance);
      }
      catch(error) {
        setErrorStatus(true);
        setErrorMessage(SFDC_ERROR_MESSAGES.API(error));
      }
    };

    getInstance();
  }, []);

  const options = useSFDCOptions({instance, company, formula, ...getDefaultsFunctionName(funcName)});

  if(!instance || !company || !formula) {
    return errorStatus ? {errorStatus, errorMessage} : null;
  }

  const sfdcData = dig(company, ['companyData', 'sfdc']);
  const opportunityValue = dig(sfdcData, [instance, 'opportunityValue'], {title: 'Value', fieldName: 'Amount'});

  if(opportunityValue.title === undefined) {
    opportunityValue.title = 'Value';
  }

  if(opportunityValue.fieldName === undefined) {
    opportunityValue.fieldName = 'Amount';
  }

  const notesField = dig(sfdcData, [instance, 'notesField'], '');
  const notesTitle = dig(sfdcData, [instance, 'notesTitle']);
  const competitorsField = dig(sfdcData, [instance, 'competitorsField'], ['MainCompetitors__c', 'text']);

  return {
    instance,
    competitorsField,
    notesField,
    opportunityValue,
    notesTitle,
    errorStatus,
    errorMessage,
    options
  };
};

export default useSFDC;
