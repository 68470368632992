import Like from './_like';

import ReactTooltip from 'react-tooltip';

const LikeList = ({likes}) => {
  if(_.isEmpty(likes)) {
    return null;
  }

  const likeCount = Math.min(likes.length, 7);
  const likeNodes = likes.slice(likes.length - likeCount).map((like, index) => {
    const extraLikeProps = {};

    if(index === (likeCount - 1)) {
      extraLikeProps.onLikesLoaded = function() {
        ReactTooltip.rebuild();
      };
    }

    return (
      <Like
        key={like.id}
        userId={like.userId}
        userName={like.userName}
        userImage={like.userImage}
        {...extraLikeProps} />
    );
  });

  return (
    <div className={`like-list${(likeCount < likes.length) ? ' like-list--full' : ''}`}>
      <div className="like-list-users">
        {likeNodes}
      </div>
    </div>
  );
};

LikeList.propTypes = {
  likes: PropTypes.arrayOf(PropTypes.object)
};

LikeList.defaultProps = {
  likes: []
};

export default LikeList;
