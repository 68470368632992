import Dropdown from './_dropdown';
import CardVitals from './_card_vitals';
import BoardCollapsed from './_board_collapsed';
import Icon from './_icon';
import onClickOutside from 'react-onclickoutside';
import {withCardDragging} from '../contexts/_cardDragging';

import {userCanCurate} from '../modules/roles_utils';

import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

class BoardVitals extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object,
    boards: PropTypes.arrayOf(PropTypes.object),
    profileEditMode: PropTypes.bool,
    builderMode: PropTypes.bool,
    maxBattlecardCards: PropTypes.number,
    collapsed: PropTypes.bool,
    adjustLeftPos: PropTypes.number,
    onToggleCardOnBattlecard: PropTypes.func,
    onToggleLaneVisibility: PropTypes.func,
    onToggleCuratorTools: PropTypes.func,
    onBoardUpdate: PropTypes.func,
    onProfileUpdated: PropTypes.func,
    onErrorMessage: PropTypes.func,
    onSettingsExpanded: PropTypes.func,
    showSettingsOpen: PropTypes.bool,
    onClickOutside: PropTypes.func,
    cardDraggingContext: PropTypes.object
  };

  static defaultProps = {
    user: {},
    boards: [],
    profileEditMode: false,
    builderMode: false,
    maxBattlecardCards: 0,
    collapsed: false,
    adjustLeftPos: 0,
    onToggleCardOnBattlecard: null,
    onToggleLaneVisibility() {},
    onToggleCuratorTools() {},
    onBoardUpdate() {},
    onProfileUpdated() {},
    onErrorMessage() {},
    onSettingsExpanded() {},
    showSettingsOpen: false,
    onClickOutside() {},
    cardDraggingContext: {}
  };

  componentDidMount() {
    console.log('BoardVitals.componentDidMount: props: %o', this.props);
  }

  _preventDefault = e => e && e.preventDefault();

  toggleVitalsVisibility = event => {
    this._preventDefault(event);

    ReactTooltip.rebuild();

    this.props.onToggleLaneVisibility({id: 0, name: 'Company Vitals'});
  };

  toggleBattlecardStatus = event => {
    this._preventDefault(event);

    this.props.onToggleCardOnBattlecard(this.context.utils.battlecard, 0);
  };

  // onClickOutside HoC
  handleClickOutside = () => {
    const {onClickOutside} = this.props;

    onClickOutside && onClickOutside();
  };

  renderCardMenu = () => {
    const {user, profileEditMode} = this.props;
    const {battlecard, isOnBattlecard: vitalsIsOnBattlecard, canAddToBattlecard} = this.context.utils;

    if(!profileEditMode || !userCanCurate({user})) {
      return;
    }

    const uiDropdownCardOptions = [];
    const isOnBattlecard = vitalsIsOnBattlecard(battlecard, 0);

    if(isOnBattlecard || canAddToBattlecard(battlecard, 0)) {
      uiDropdownCardOptions.push({
        value: 3,
        label: isOnBattlecard ? 'Remove from battlecard' : 'Add to battlecard',
        icon: isOnBattlecard ? 'fa-star' : 'fa-star-o',
        onOptionClick: this.toggleBattlecardStatus
      });
    }
    else {
      uiDropdownCardOptions.push({
        value: 3,
        label: 'Battlecard full',
        icon: 'fa-ban'
      });
    }

    return (
      <div className="btn-group pull-right card-menu">
        <Dropdown
          options={uiDropdownCardOptions}
          condensed={true}
          containerClass="ui-dropdown-flush ui-dropdown-no-border ui-dropdown-card-style"
          className="btn btn-dropdown-card btn-dropdown-size-38" />
      </div>
    );
  };

  // Prevents the scroll from propagating up to the parent and disabling the dimmed state when vitals lane has focus.
  // The parent <Profile /> has a handleEmphasizedSettingsScroll to remove the dimmed state during a scroll.
  handleOnScroll = e => (e && e.stopPropagation());

  render() {
    const {
      user,
      boards,
      profileEditMode,
      builderMode,
      collapsed,
      adjustLeftPos,
      onToggleLaneVisibility,
      onToggleCuratorTools,
      onProfileUpdated,
      onErrorMessage,
      onBoardUpdate,
      onSettingsExpanded,
      showSettingsOpen,
      cardDraggingContext
    } = this.props;
    const {utils} = this.context;
    const {battlecard, isOnBattlecard} = utils;
    const boardBodyClass = classNames('board-body', {
      'on-battlecard': builderMode && isOnBattlecard(battlecard, 0)
    });

    if(collapsed) {
      const extraProps = {};

      if(adjustLeftPos !== 0) {
        extraProps.style = {
          left: `${adjustLeftPos}px`
        };
      }

      return (
        <div className="swimlane ui-boards vitals" {...extraProps}>
          <BoardCollapsed
            board={{id: 0}}
            disable={cardDraggingContext?.isCompressedCardDraggingOn}
            onToggleClick={this.toggleVitalsVisibility} />
        </div>
      );
    }

    return (
      <div className={classNames('swimlane ui-boards vitals')} onScroll={this.handleOnScroll}>
        <div className="ui-card board board-vitals">
          <div className="board-header">
            <div className="board-header_title board-name">
              <i className="fa fa-tachometer board-header_title_icon" />
              <span id="onboardBCStep4Target2">Vitals</span>
            </div>
            <div className="board-header_actions">
              <div
                className="btn btn-board-header btn-board-header--collapse btn-dropdown-size-38"
                data-tip={'Collapse Lane'}
                data-offset="{'top': 0}"
                onClick={this.toggleVitalsVisibility}>
                <Icon icon="collapse" width="24" height="24" />
              </div>
            </div>
          </div>
          <div className={boardBodyClass}>
            <CardVitals
              user={user}
              editMode={profileEditMode}
              showSettingsOpen={showSettingsOpen}
              lanes={boards}
              onBoardUpdate={onBoardUpdate}
              onToggleLaneVisibility={onToggleLaneVisibility}
              onToggleCuratorTools={onToggleCuratorTools}
              onProfileUpdated={onProfileUpdated}
              onErrorMessage={onErrorMessage}
              onSettingsExpanded={onSettingsExpanded}>
              {this.renderCardMenu()}
            </CardVitals>
          </div>
        </div>
      </div>
    );
  }

}

export default withCardDragging(onClickOutside(BoardVitals));
