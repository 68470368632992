import moment from 'moment';

import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchContifyData} from './data_providers';

function contifyPostToKlueArticle(contifyPost) {
  const {name: sourceName} = contifyPost.source;
  const {url: sourceUrl, title, summary, pub_date: publishedDate} = contifyPost;

  return KlueTypes.OnlineArticle.create({
    link: KlueTypes.Link.create({url: sourceUrl, title: sourceName}),
    headline: title,
    lead: summary,
    published: KlueTypes.Date.create(moment(publishedDate))
  });
}

async function requestTopicsFromContify(contifyFetcher, company, topics) {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('[filteredContifyPosts] Unexpected parameter type.', company);
    throw new Error('Parameter was unexpected type.');
  }

  const posts = await contifyFetcher(company.info, topics);
  const articles = posts.map(contifyPostToKlueArticle);

  return Types.arrayOf(KlueTypes.OnlineArticle).create(articles);
}

export function recentPressReleases(company, contifyFetcher = fetchContifyData) {
  return requestTopicsFromContify(contifyFetcher, company, ['press_release']);
}

export function recentBlogPosts(company, contifyFetcher = fetchContifyData) {
  return requestTopicsFromContify(contifyFetcher, company, ['blog']);
}

export function recentSocialUpdates(company, contifyFetcher = fetchContifyData) {
  return requestTopicsFromContify(contifyFetcher, company, ['social_update']);
}

export function recentPublications(company, contifyFetcher = fetchContifyData) {
  return requestTopicsFromContify(contifyFetcher, company, ['white_paper', 'data_sheet', 'ebook', 'marketing_resource']);
}

export function recentCaseStudies(company, contifyFetcher = fetchContifyData) {
  return requestTopicsFromContify(contifyFetcher, company, ['case_study']);
}

export function recentRegulatoryFilings(company, contifyFetcher = fetchContifyData) {
  return requestTopicsFromContify(contifyFetcher, company, ['regulatory_filing']);
}
