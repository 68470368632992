/* eslint-disable react/no-multi-comp */
import {useState, useEffect, useRef} from 'react';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';

import {fetch} from '../modules/api_utils';
import {removeHttp} from '../modules/url_utils';
import CompanySelectorInfoBox from '../components/_company_selector_info_box';
import Icon from './_icon';

const CompanySelector = props => {
  const {selectedName, selectedUrl, rivals, onCompanySelection, isFocused} = props;
  const [selectedCompany, setSelectedCompany] = useState({});
  const [companyName, setCompanyName] = useState(selectedName || '');
  const [suggestions, setSuggestions] = useState([]);
  const companySelectorRef = useRef(null);
  const sequenceNumber = useRef(0);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getSuggestionValue = suggestion => suggestion.competitorName;
  const getNextSequenceNumber = () => ++sequenceNumber.current;

  const getSectionSuggestions = section => {
    return section.companies;
  };

  const handleChange = (event, {newValue}) => setCompanyName(newValue);

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const updateCompany = company => {
    if(!company) {
      return;
    }

    setSelectedCompany(company);
    onCompanySelection(company);
    setSuggestions([]);
  };

  const handleSelectSuggestion = (event, {suggestion}) => {
    event.preventDefault();

    if(_.isEmpty(suggestion)) {
      setCompanyName('');
      setSuggestions([]);

      return false;
    }

    const {competitorName: name, displayLink: url, logo: logoUrl, id, profileId, createdAt, isAddNewCompany} = suggestion;
    const company = {
      name,
      url,
      logoUrl,
      id,
      createdAt,
      profileId,
      isAddNewCompany
    };

    if((name && url) || (name && isAddNewCompany)) {
      updateCompany(company);
    }
  };

  const processCompaniesResult = (matches = [], query = '') => {
    const companies = [];

    if(matches) {
      for(let i = 0; i < matches.length; i++) {
        const {domain = '', name = '', logo} = matches[i];
        const url = `https://${domain}`;     // TODO: this can mess up crawling if protocol is wrong
        const existedProfiles = rivals.filter(r => removeHttp((r.url || '').toLowerCase()) === domain.toLowerCase());

        let toPush = {
          competitorName: name || domain,
          domain,
          displayLink: url,
          logo,
          isAddNewCompany: false
        };

        if(existedProfiles && existedProfiles.length) {
          const {profileId, createdAt, id} = existedProfiles.pop();

          toPush = {...toPush, profileId, createdAt, id};
        }

        companies.push(toPush);
      }
    }

    // always show the option to add a custom new company
    companies.push({
      competitorName: query,
      domain: undefined,
      displayLink: undefined,
      logo: '/klue-logo-128x128.png',
      isAddNewCompany: true
    });

    setSuggestions(companies);
  };

  const getSuggestions = input => {
    const sn = getNextSequenceNumber();

    const escapedInput = encodeURIComponent(input.trim());

    if(escapedInput) {
      if(escapedInput.length <= 1) {
        return setSuggestions([]);
      }

      fetch('/api/discover/companies.json', {
        params: {name: escapedInput}
      }).then(({data: {items: matches = []} = {}}) => {
        if(isMounted.current && sn === sequenceNumber.current) {
          processCompaniesResult(matches, input.trim());
        }
      })
        .catch(error => {
          console.error('CompanySelector.getSuggestions', error);

          // If there's an error, follow the `no company match` worflow.
          // Fix for https://github.com/kluein/klue/issues/11356
          if(isMounted.current && sn === sequenceNumber.current) {
            processCompaniesResult([], input.trim());
          }
        });
    }
  };

  const onSuggestionsFetchRequested = useRef(_.debounce(args => getSuggestions(args.value), 400)).current;

  useEffect(() => {
    return () => {
      onSuggestionsFetchRequested.cancel();
    };
  }, []);

  const renderSuggestion = ({competitorName = '', domain = '', displayLink, isAddNewCompany}) => {
    const competitorDomain = domain.toLowerCase().replace(/\\/g, '').replace(' ', '');

    if(isAddNewCompany) {
      return (
        <span title={competitorName} className="add">
          <Icon icon="add_circle" width="24" height="24" /> Not listed? Add a new Company
        </span>
      );
    }

    const isSelected = selectedName === competitorName && selectedUrl === displayLink;

    return (
      <span
        title={competitorName}
        className={classNames('item', {'item--on': isSelected})}>
        {competitorName} - {competitorDomain}
      </span>
    );
  };

  const inputProps = {
    autoFocus: true,
    id: 'companyUrl',
    type: 'search',
    placeholder: 'Search for Company...',
    'data-testid': 'company-selector-text-input',
    onChange: handleChange,
    value: companyName
  };

  return (
    <div className="company-selector">
      {
        // if we don't have the company url, we should not show the link to the website
        (selectedName && !selectedCompany.isAddNewCompany) ?
          <label className={classNames({focus: isFocused})}>You have selected the company <a href={selectedUrl} target="_blank">{selectedName}</a>.</label>
          :
          <label className={classNames({focus: isFocused})} data-tracking-id="about-which-compay-label">Which company is this Board about?</label>
      }

      <Autosuggest
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={handleSelectSuggestion}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        ref={companySelectorRef}
        renderSuggestion={renderSuggestion}
        getSectionSuggestions={getSectionSuggestions}
        suggestions={suggestions} />
      <div className={classNames('company-selector-footer', {on: selectedName})}>
        <CompanySelectorInfoBox company={selectedCompany} />
      </div>
    </div>

  );
};

CompanySelector.propTypes = {
  onCompanySelection: PropTypes.func.isRequired,
  selectedName: PropTypes.string,
  selectedUrl: PropTypes.string,
  rivals: PropTypes.arrayOf(PropTypes.object),
  isFocused: PropTypes.bool
};
CompanySelector.defaultProps = {
  selectedName: '',
  selectedUrl: '',
  rivals: [],
  isFocused: false
};

export default CompanySelector;
