import {Tweet} from 'react-twitter-widgets';

const isTwitterContent = source => source === 'twitter.com';

// TODO: refactor this to pass this in from metaurl remote_id to avoid parsing it here
const getTweetId = (source, url) => {
  if(!isTwitterContent(source)) {
    return '';
  }

  const regex = /status\/(\d+)/i;
  const matches = regex.exec(url);

  return matches ? matches[1] : '';
};

class AttachmentMedia extends React.Component {

  static propTypes = {
    imageUrl: PropTypes.string,
    sourceName: PropTypes.string,
    linkUrl: PropTypes.string,
    embedCode: PropTypes.string,
    children: PropTypes.node
  };

  static defaultProps = {
    imageUrl: '',
    linkUrl: '',
    sourceName: '',
    embedCode: '',
    children: null
  };

  state = {
    imageLoaded: false
  };

  componentDidMount() {
    // DEBUG
    console.log('AttachmentMedia.componentDidMount: props: %o', this.props);
  }

  handleImageLoad = () => {
    this.setState({
      imageLoaded: true
    });
  };

  handleImageError = event => {
    const {target} = event;
    const postWrapper = target.closest('.attachment-main .attachment-media-image');
    const attachmentWrapper = target.closest('.attachment-list');

    if(target) {
      if(attachmentWrapper) {
        target.src = '/attachment-img-placeholder.png';
      }
      else if(postWrapper) {
        postWrapper.remove();
      }

      this.setState({
        imageLoaded: true
      });
    }
  };

  renderAttachmentMediaEmbed = () => {
    const {embedCode, children, linkUrl, sourceName} = this.props;
    const tweedId = getTweetId(sourceName, linkUrl);

    if(tweedId) {
      return (
        <div className="attachment-media attachment-media-tweet">
          <Tweet tweetId={`${tweedId}`} options={{conversation: 'none', dnt: true}} />
        </div>
      );
    }

    const regex = /(?:src=\\?['"]{1})([^\\"']*)/gi;
    const matches = regex.exec(embedCode);
    const src = (matches && (matches.length)) ? matches[1] : '';

    return (
      <div className="attachment-media">
        {src ? (<div className="attachment-media-embed">
          <iframe src={src.trim().replace(/^https?:/i, '')} frameBorder="0" allowFullScreen={true} loading="lazy" />
        </div>) : null}
        {children}
      </div>
    );
  };

  renderAttachmentMediaImage = () => {
    const classString = this.state.imageLoaded ? 'media-object loaded' : 'media-object';
    const {imageUrl, linkUrl, children} = this.props;
    const mediaImg = <img className={classString} onLoad={this.handleImageLoad} onError={this.handleImageError} src={imageUrl} loading="lazy" />;

    return (
      <div className="attachment-media">
        <div className={'attachment-media-image'}>
          {linkUrl
            ? (<a href={linkUrl} target="_blank" rel="noreferrer nofollow">{mediaImg}</a>)
            : mediaImg}
        </div>
        {children}
      </div>
    );
  };

  render() {
    const {embedCode, imageUrl} = this.props;

    if(embedCode) {
      return this.renderAttachmentMediaEmbed();
    }
    else if(imageUrl) {
      return this.renderAttachmentMediaImage();
    }

    return null;
  }

}
export default AttachmentMedia;
