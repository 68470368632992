import {dataUrlToBlob} from './attachment_utils';

export const getSignedUrl = (file, callback, onError) => {
  $.ajax({
    url: `/api/uploads/post_url.json?objectName=${encodeURIComponent(file.name)}&contentType=${encodeURIComponent(file.type)}&contentLengthLimit=0`,
    type: 'GET',
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    success(data) {
      callback(data);
    },
    error(xhr, type) {
      console.warn('klue:S3Upload:getSignedUrl', xhr, type);

      return typeof onError === 'function' && onError(xhr, type);
    }
  });
};

export const uploadFileToS3 = dataBlob => {
  return new Promise((resolve, reject) => {
    return new S3Upload({
      fileElement: {files: [dataBlob]},
      uploadRequestHeaders: {'x-amz-acl': 'public-read'},
      getSignedUrl: (file, callback) => getSignedUrl(file, callback, (xhr, _type) => reject(xhr.status, file)),
      usePostForm: true,
      clear() {},
      //preprocess: (file, next)
      //onProgress: (percent, status, file)
      onFinishS3Put(signingObject, file) {
        resolve({
          signingObject,
          file
        });
      },
      onError(status, file) {
        console.warn('klue:richTextEditor:S3Upload:onError: status: %o, file: %o', status, file);

        reject(status);
      }
    });
  });
};

export const editorS3Upload = (el, reportFilesAmountOnUploadStart = () => {}, reportOnProcessingSingle = () => {}, immediatelyOnUploadToS3Completed) => {
  const blobImages = Array.from(el.querySelectorAll('img[src^="data:image"]'));
  let counter = 0;

  // Promise.all(iterable) bundles all the promises before starting executing them.
  // .map() allows for running these promises in parallel (.reduce() in sequence)
  Promise.all(blobImages.map(element => {
    const {src} = element;
    const dataBlob = dataUrlToBlob(src);

    counter++;
    dataBlob.name = 'card-image'; // s3 meta-data name, can be any string

    // create a element to wrap around our image to show loading spinner
    const originalHTML = element.outerHTML;
    const replacementElement = document.createElement('div');

    replacementElement.className = 'card-wysiwyg-img-loading';
    replacementElement.innerHTML = originalHTML;

    // add it to the DOM and remove the old one
    element.parentNode.insertBefore(replacementElement, element);
    element.outerHTML = '';

    // Return promise here
    return uploadFileToS3(dataBlob)
      .then(({signingObject}) => {
        if(immediatelyOnUploadToS3Completed) {
          // Replace the base64 img binary value with URL to S3.
          // This needs to be done immediately - without waiting for the image to be downloaded to the page.
          // Source code of the card needs to have the final verision of HTML ready for saving to server.
          replacementElement.outerHTML = `<img src="${signingObject.assetUrl}" loading="lazy" />`;
          reportOnProcessingSingle();
        }
        else {
          const preLoadImage = new Image();

          // On successfull load of image from s3 to page, update the editor DOM element with the new URL to make for smooth transition
          preLoadImage.onload = () => {
            replacementElement.outerHTML = `<img src="${signingObject.assetUrl}" loading="lazy" />`;
            reportOnProcessingSingle(); // report to parent to indicate how many more to wait until, for example, enabling Save button
          };

          preLoadImage.onerror = () => {
            console.warn('klue:richTextEditor:S3Upload:imageLoadError');
            reportOnProcessingSingle(); // report to parent to indicate how many more to wait until, for example, enabling Save button
            replacementElement.outerHTML = originalHTML;
          };

          preLoadImage.src = signingObject.assetUrl;
        }
      })
      .catch(() => {
        reportOnProcessingSingle(); // report to parent to indicate how many more to wait until, for example, enabling Save button
        replacementElement.outerHTML = originalHTML;
      });
  })).then(reportFilesAmountOnUploadStart(counter));
};
