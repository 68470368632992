import classNames from 'classnames';

const DigestTimelineItemCompetitor = ({rival}) => {
  if(!rival) {
    return null;
  }

  const {id, name, iconUrl, profile, isDraft} = rival || {};
  const {isDraft: profileIsDraft} = profile || {};

  return (
    <div
      className={classNames('digest-timeline-item-competitor', {draft: isDraft || profileIsDraft})}
      data-testid="digest-timeline-item-competitor"
      key={`digest-timeline-item-competitor-${id}`}>
      <img src={iconUrl} alt={name} />
      <div className="digest-timeline-item-competitor-name">{name}</div>
    </div>
  );
};

DigestTimelineItemCompetitor.propTypes = {
  rival: PropTypes.object
};

DigestTimelineItemCompetitor.defaultProps = {
  rival: null
};

export default DigestTimelineItemCompetitor;
