export const filterRivals = (rivals = [], filter = '', activeGroup = {}) => {
  if(!(filter || '').trim() && !activeGroup) {
    return rivals;
  }

  return rivals.filter(r => {
    const escapedFilter = _.escapeRegExp(filter.trim());
    const rivalSearch = r.name;
    const matcher = new RegExp(escapedFilter, 'i');
    const firstMatchIndex = rivalSearch ? rivalSearch.search(matcher) : -1;

    return firstMatchIndex >= 0;
  });
};

// assumes rivals are sorted by what's being compared via compare fn
// compare takes in (rival, query), returns 0 if equal, 1 if
export const binarySearch = (rivals = [], query = '', key = '', compare = () => {return false;}) => {
  if(!rivals || !rivals.length) {
    return null;
  }

  const mid = Math.floor(rivals.length / 2);
  const rival = rivals[mid];
  const value = rival[key];
  const compareValue = compare(value, query);

  if(compareValue === 0) {
    return rival;
  }
  else if((compareValue < 0) && rivals.length > 1) {
    return binarySearch(rivals.slice().splice(mid, Number.MAX_VALUE), query, key, compare);
  }
  else if(rivals.length > 1) {
    return binarySearch(rivals.slice().splice(0, mid), query, key, compare);
  }

  return null;
};

export const isDraftRival = rival => {
  const {profile, isDraft} = rival;

  return isDraft || profile?.isDraft;
};

export const mergeProfileIntoRival = (rival, profile) => {
  // Only copies the already existing profile properties into the rival object's profile.
  return {...rival, profile: Object.keys(rival.profile || {}).reduce((acc, key) => {
    acc[key] = profile[key];

    return acc;
  }, {})};
};
