const FlashMessage = ({type, icon, onDismissClick, message}) => {
  let messageClass = `flash-message flash-message--${type}`;
  let messageDismissRegion;
  let messageBodyRegion;
  let messageIcon;

  // TODO: replace this with Icon component after new nav is merged
  if(icon) {
    const messageIconClass = `fa fa-${icon} flash-message_icon`;

    messageIcon = (
      <i className={messageIconClass} />
    );
  }

  if(onDismissClick) {
    messageClass += ' flash-message--dismissible';

    messageDismissRegion = (
      <div className="flash-message_dismiss" onClick={onDismissClick}><span>&times;</span></div>
    );
  }

  if(React.isValidElement(message)) {
    messageBodyRegion = React.cloneElement(message, {className: 'flash-message_body'});
  }
  else {
    messageBodyRegion = (
      <span className="flash-message_body">{message}</span>
    );
  }

  return (
    <div className={messageClass}>
      {messageDismissRegion}
      {messageIcon}
      {messageBodyRegion}
    </div>
  );
};

FlashMessage.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.element,   // fragment
    PropTypes.string     // will be rendered as-is in a <span />
  ]).isRequired,
  type: PropTypes.oneOf([
    'success',
    'info',
    'warning',
    'danger'
  ]).isRequired,
  icon: PropTypes.string,
  onDismissClick: PropTypes.func
};

FlashMessage.defaultProps = {
  message: '',
  type: 'success',
  icon: 'check',
  onDismissClick: null
};

export default FlashMessage;
