import InputPlaceholder from './inputs/_input_placeholder';

import {parseHostname} from '../../../../../../modules/url_utils';
import {normalizedCompanyFormulaParam} from '../../../../../../modules/editor_utils';

import {connect} from 'react-redux';

const determineDefaultCompanyId = (companyIdFromProps, currentRival) => {
  // Handle the special case where a default is specified in props
  if(companyIdFromProps === 'currentCompany') {
    return 0;
  }

  if(companyIdFromProps) {
    return companyIdFromProps;
  }

  // Otherwise, fallback to current rival id
  return currentRival ? currentRival.id : 0;
};

class CompanyBuilder extends React.Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    label: PropTypes.string,
    defaultCompanyId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    rivals: PropTypes.object.isRequired
  };

  static defaultProps = {
    label: 'Company',
    defaultCompanyId: null,
    onChange() {},
    rivals: {}
  };

  state = {
    companyId: determineDefaultCompanyId(this.props.defaultCompanyId, this.context.utils.rival)
  };

  componentDidMount = () => {
    console.log('CompanyBuilder.componentDidMount: props: %o', this.props);

    this._fireChanged();
  };

  _generateFunction = () => {
    const {companyId} = this.state;
    const {utils: {rival: {id: currentRivalId} = {}}} = this.context;

    return `COMPANY("${normalizedCompanyFormulaParam(companyId, currentRivalId)}")`;
  };

  _fireChanged = () => {
    const formula = this._generateFunction();

    this.props.onChange(formula, {companyId: this.state.companyId});
  };

  _handleCompanyIdChanged = companyId => {
    this.setState({companyId}, () => {
      this._fireChanged();
    });
  };

  _renderCompanyIdSelect = () => {
    const {rivals} = this.props;
    const {companyId: selectedCompany} = this.state;
    const {company} = this.context.utils;

    // Reduce rival info to just display name and domain
    const rivalsInfo = Object.keys(rivals).map(rivalId => {
      const {id, name, url} = rivals[rivalId];

      return {id, name, domain: parseHostname(url)};
    });

    // Append the current company to the list (but filter it out if also exists as a rival)
    const instanceDomain = parseHostname(company.url);
    const allRivals = [...rivalsInfo.filter(({name}) => name !== company.name), {id: 0, name: company.name, domain: instanceDomain}];

    // Sort list alphabetically by display name
    const sortedRivalList = allRivals.sort((a, b) => a.name.localeCompare(b.name));
    const options = sortedRivalList.map(({id, name, domain}) => (<option key={`${id}_${domain}`} value={id}>{name}</option>));

    return (
      <select
        className="klue-formula-wizard_control-input klue-formula-wizard_dropdown-menu"
        defaultValue={selectedCompany}
        onChange={e => this._handleCompanyIdChanged(e.target.value)}>
        {options}
      </select>
    );
  };

  render() {
    const {label, rivals} = this.props;
    const input = Object.keys(rivals).length ? this._renderCompanyIdSelect() : (<InputPlaceholder />);

    return (
      <div className="klue-formula-wizard_control-group">
        <label className="klue-formula-wizard_control-label">{label}</label>
        {input}
      </div>
    );
  }

}

const mapStateToProps = ({rivals}) => ({rivals: rivals.items});

export default connect(mapStateToProps)(CompanyBuilder);
