import axios from 'axios';
import axiosRetry from 'axios-retry';

import {wait} from './utils';
import {NOTIFICATIONS_FETCH_INTERVAL} from './constants/api';
/**
 * Partly based on https://github.com/pawelt/caxios/, integrated with axios-retry lib, enhanced to provide app specific feedback on generic failures handling.
 */

let enqueueToastMessage;
let clearToastMessages;
let toastMessageWithIdentifierExists;
let csrfToken;

/**
 * Caxios - consistent Axios.
 *
 * General purpose HTTP request module with consistent responses.
 * This module does NOT modify the global Axios instance.
 *
 * Resolved response objects always have the following fields (Axios compatible):
 *   - status
 *   - statusText
 *   - headers
 *   - config
 *   - request
 *   - data
 *
 * Rejected request error objects are extended with the following additional methods:
 *   - isFormat()     - true if the client expected JSON response, but server returned malformed JSON
 *   - isCancel()     - true if the request was cancelled by the client
 *   - isNetwork()    - true for any network error (timeout, server unavailable, CORS etc.)
 *   - isValidation() - true if response status in 4** (e.g., 422 - Unprocessable Entity)
 * These error types are mutually exclusive, so for any error only one of those methods returns true.
 */

const expiredSessionAlert = () => {
  if(enqueueToastMessage && clearToastMessages && toastMessageWithIdentifierExists) {
    if(toastMessageWithIdentifierExists('session-expired')) {
      return;
    }

    clearToastMessages('ApiUtils', async () => {
      enqueueToastMessage({
        title: 'Your session has expired',
        message: (
          <div>
            <p>Please reload the page to sign in again.</p>
            <div className="toast-message_buttons">
              <a className="button button--small" onClick={() => window.location.reload()}>Sign in again</a>
            </div>
          </div>),
        isInfo: true,
        duration: 0,    // infinite
        source: 'ApiUtils',
        onDismissClick: () => window.location.reload(),    // force full reload even on toast dismissal 😈
        identifier: 'session-expired'
      });

      // force refresh after the notifications fetch interval, even if user hasn't seen the toast message
      await wait(NOTIFICATIONS_FETCH_INTERVAL);
      console.warn(`AppBase.refreshNotificationsCount: waited ${NOTIFICATIONS_FETCH_INTERVAL}ms, force-reloading the app`);
      window.location.reload();
    });
  }
};

const timeoutAlert = () => {
  if(enqueueToastMessage && clearToastMessages && toastMessageWithIdentifierExists) {
    if(toastMessageWithIdentifierExists('timeout-alert')) {
      return;
    }

    clearToastMessages('ApiUtils', () =>
      enqueueToastMessage({
        title: 'Connection timed out',
        message: (
          <div>
            <p>Your changes may not have been saved.</p>
            <div className="toast-message_buttons">
              <a className="button button--small" onClick={() => window.location.reload()}>Reload</a>
            </div>
          </div>),
        isInfo: true,
        duration: 0,    // infinite
        source: 'ApiUtils',
        identifier: 'timeout-alert'
      }));
  }
};

const networkAlert = () => {
  if(enqueueToastMessage && clearToastMessages && toastMessageWithIdentifierExists) {
    if(toastMessageWithIdentifierExists('network-alert')) {
      return;
    }

    clearToastMessages('ApiUtils', () =>
      enqueueToastMessage({
        title: 'Network issues',
        message: (
          <div>
            <p>We&apos;ve failed to send data after several attempts. Please check your network connection.</p>
            <div className="toast-message_buttons">
              <a className="button button--small" onClick={() => window.location.reload()}>Reload</a>
            </div>
          </div>),
        isInfo: true,
        duration: 0,    // infinite
        source: 'ApiUtils',
        identifier: 'network-alert'
      }));
  }
};

// Default rejected response interceptor.
// Extends `ex` object with additional methods for special error types.
const rejectedResponseInterceptor = ex => {
  const theCode = ex.config && ex.config.code ? ex.config.code : 'no code';
  const exitWithNoAlert = Boolean(ex?.config?.exitWithNoAlert);

  // This helps to break out of the existing retry iteration if var's value was assigned to true.
  // Axios-retry has its own set of errors that, when catched, will not result in retrying the requests.
  // See axiosRetry's `retryCondition` config parameter for more details.
  let exitCondition = exitWithNoAlert;

  if(!exitCondition) {
    if(ex.code && ex.code === 'ECONNABORTED') {
      // timeout error
      timeoutAlert();
    }
    else {
      ex.isFormat = ex.isCancel = ex.isNetwork = ex.isValidation = () => false; // for further use in client code

      if(ex instanceof SyntaxError) {
        // A SyntaxError is thrown when the JavaScript engine encounters tokens or token order
        // that does not conform to the syntax of the language when parsing code.
        ex.isFormat = () => true; // for further use in client code
        exitCondition = true;
        networkAlert(); // show an alert, not necessarily the best matching.
      }
      else if(axios.isCancel(ex)) {
        // request was cancelled by user
        ex.isCancel = () => true; // for further use in client code
        exitCondition = true;
      }
      else if(!ex.response) {
        // response is not available, let's show the warning. Expecting Network to recover.
        networkAlert();
        ex.isNetwork = () => true; // for further use in client code
      }
      else if(ex.response.status === 401 || ex.response.status === 422) {
        expiredSessionAlert();
        ex.isValidation = () => true; // for further use in client code <-- usually, will not be accessible, if user relogins quickly
        exitCondition = true;
      }
      else {
        // e.g. error 404 for REST - non existing resource requested
        ex.isValidation = () => true;
        exitCondition = true;
      }
    }
  }

  // propagate error if no further interception & handling is expected
  if(ex.config && !ex.config['axios-retry']
    || exitCondition) {
    throw ex;
  }
};

// Default fulfilled response interceptor.
// Makes sure the resolved result has consistent format.
const fulfilledResponseInterceptor = response => {
  const accept = response.config.headers.Accept;
  const theResponseIsFine =
      // we did not expect JSON, so return whatever came back from the server
      accept.indexOf('application/json') < 0
      // we expected more than one content type, so it may be something else than valid JSON
      || accept.indexOf(',') > 0
      // ignore no-content responses
      || response.status === 204
      // Axios successfully JSON.parsed the response
      || typeof response.data === 'object';

  if(theResponseIsFine) {
    clearToastMessages && clearToastMessages('ApiUtils');

    return response;
  }

  // If we got here, it means Axios failed to JSON.parse(data) the response,
  // but the client expected a JSON response. Capture the parsing error and reject.
  try {
    JSON.parse(response.data);

    return response;
  }
  catch(ex) {
    // ex is an instance of SyntaxError
    ex.response = response;
    ex.config = response.config;

    return rejectedResponseInterceptor(ex);
  }
};

const setCaxiosDefaults = (csrfT = null, toastMessageControl = {}) => {
  csrfToken = csrfT;

  if(!_.isEmpty(toastMessageControl)) {
    ({clearToastMessages, enqueueToastMessage, toastMessageWithIdentifierExists} = toastMessageControl);
  }
};

const getAxios = withRetry => {
  const caxios = axios.create();

  if(withRetry) {
    axiosRetry(caxios, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition(error) {
        // Possibly, provide some UI feedback (between the fail and following retry)
        rejectedResponseInterceptor(error);

        // Returns true to trigger a retry, if conditions of "reattempting legibility" are met. This can be customized.
        return axiosRetry.isNetworkOrIdempotentRequestError(error)
          || error.code === 'ECONNABORTED';
      }
    });
    caxios.interceptors.response.use(fulfilledResponseInterceptor);
  }
  else {
    caxios.interceptors.response.use(fulfilledResponseInterceptor, rejectedResponseInterceptor);
  }

  // By default, Axios sends request payload as application/x-www-form-urlencoded
  ['post', 'put', 'patch', 'delete', 'patch'].forEach(m => {
    caxios.defaults.headers[m]['X-CSRF-Token'] = csrfToken;
    caxios.defaults.headers[m]['Content-Type'] = 'application/json; charset=utf-8';
  });

  return caxios;
};

// GET
const fetch = (url, config = {}) => getAxios(true).get(url, config);
const fetchNoRetry = (url, config = {}) => getAxios().get(url, config);

// POST
const post = (url, data = {}, config = {}) => getAxios(true).post(url, data, config);
const postNoRetry = (url, data = {}, config = {}) => getAxios().post(url, data, config);

// PUT
const update = (url, data = {}, config = {}) => getAxios(true).put(url, data, config);
const updateNoRetry = (url, data = {}, config = {}) => getAxios().put(url, data, config);

// DELETE
const destroy = (url, config = {}) => getAxios(true).delete(url, config);
const destroyNoRetry = (url, config = {}) => getAxios().delete(url, config);

// PATCH
const patch = (url, data = {}, config = {}) => getAxios(true).patch(url, data, config);
const patchNoRetry = (url, data = {}, config = {}) => getAxios().patch(url, data, config);

export {
  fetch,
  post,
  update,
  destroy,
  patch,
  fetchNoRetry,
  postNoRetry,
  updateNoRetry,
  destroyNoRetry,
  patchNoRetry,
  fulfilledResponseInterceptor,
  rejectedResponseInterceptor,
  setCaxiosDefaults,
  /**
   * Unmodified Axios instance
   */
  axios
};
