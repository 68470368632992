/* eslint-disable react/no-multi-comp */
import FormulaAttachment from '../card_templates/dynamic_cards/attachments/formulae/_formula_attachment';
import ContentZoom from '../_content_zoom';

import {extractHtmlFormulasToArray, replaceFormulasByDomElements} from '../../modules/editor_utils';
import {isValidId} from '../../modules/utils';
import {sanitizeInput, allowlistedAttributes, allowlistedTags, wrapHtml} from '../../modules/html_utils';
import {parseHtmlToMediaEmbeds, trackLinkClickFromTarget, youTubeUrlRegex} from '../../modules/card_utils';
import {CARD_BODY_CLASSNAME, CARD_TITLE_CLASSNAME, CARD_WRAPPER_CLASSNAME} from '../../modules/constants/editor';

import classNames from 'classnames';
import {withAnalyticsEvent} from '../../contexts/_analyticsEvent';

class CardStaticHtml extends React.PureComponent {

  static contextTypes = {
    utils: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  static propTypes = {
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.string,
    isDraft: PropTypes.bool,
    textHtml: PropTypes.string,
    name: PropTypes.string,
    nameWithoutHighlight: PropTypes.string,
    hasDynamicBlocks: PropTypes.bool,
    previewMode: PropTypes.bool,
    diffIdentifier: PropTypes.string,
    rival: PropTypes.object.isRequired,
    board: PropTypes.object.isRequired,
    oldData: PropTypes.object,
    children: PropTypes.node,
    analyticsEventContext: PropTypes.object
  };

  static defaultProps = {
    id: '',
    createdAt: '',
    isDraft: false,
    name: '',
    nameWithoutHighlight: '',
    textHtml: '',
    hasDynamicBlocks: false,
    previewMode: false,
    diffIdentifier: '',
    rival: {},
    oldData: {},
    children: null,
    analyticsEventContext: {}
  };

  componentDidMount() {
    console.log('CardStaticHtml.componentDidMount: props: %o', this.props);

    const {hasDynamicBlocks, id: cardId} = this.props;

    if(hasDynamicBlocks) {
      this.renderAttachments(cardId);
    }
  }

  componentDidUpdate() {
    const {hasDynamicBlocks, id: cardId} = this.props;

    if(hasDynamicBlocks) {
      this.renderAttachments(cardId);
    }
  }

  handleContentClick = ({target}, cardClass) => {
    const {location: {pathname}} = this.context;

    trackLinkClickFromTarget(target, {cardClass}, pathname);
  };

  componentRef = null;

  renderAttachments = (cardId = 0) => {
    if(!isValidId(cardId)) {
      return;
    }

    const {rival, textHtml, nameWithoutHighlight, name, board, createdAt, analyticsEventContext, isDraft} = this.props;
    const formulas = extractHtmlFormulasToArray(textHtml);
    const element = ReactDOM.findDOMNode(this.componentRef);

    formulas.forEach((formula, index) => {
      const cardEl = element.querySelector(`.inline-formula[data-formula-index="${index}"]`);

      if(cardEl) {
        ReactDOM.unstable_renderSubtreeIntoContainer(
          this,
          <FormulaAttachment
            rival={rival}
            board={board}
            cardId={cardId}
            cardTitle={nameWithoutHighlight || name}
            code={formula}
            editing={false}
            createdAt={createdAt}
            isDraft={isDraft}
            analyticsEventContext={analyticsEventContext} />,
          cardEl
        );
      }
    });
  };

  setRef = node => this.componentRef = node;

  render() {
    const {
      name,
      nameWithoutHighlight,
      textHtml,
      hasDynamicBlocks,
      id: cardId,
      previewMode,
      diffIdentifier,
      oldData,
      board: {name: laneName = '', profileId = '', id: boardId} = {},
      children
    } = this.props;
    let {rival: {name: rivalName = '', id: rivalId = ''} = {}} = this.props;
    const {location, utils: {battlecard} = {}} = this.context;
    const battlecardId = battlecard?.id;
    const isBattlecardView = location.pathname.includes('battlecard/view');
    const cardType = diffIdentifier ? diffIdentifier : previewMode ? 'preview' : 'static';
    const baseClass = CARD_BODY_CLASSNAME;
    const cardBodyClass = classNames(baseClass, {
      'has-dynamic-blocks': hasDynamicBlocks,
      'card-legacy-list': oldData.list,
      'card-legacy-cols': oldData.cols
    });
    let html = hasDynamicBlocks
      ? replaceFormulasByDomElements(textHtml, extractHtmlFormulasToArray(textHtml))
      : textHtml;

    if(!rivalName) {
      const {utils: {rival}} = this.context;

      rivalName = (rival || {}).name || `profileId=${profileId}`;
    }

    // Handle YouTube embeds
    if(youTubeUrlRegex.test(html)) {
      html = parseHtmlToMediaEmbeds(html, true);
    }

    return (
      <section ref={this.setRef} id={`card-${cardType}-${cardId}`} className={classNames(CARD_WRAPPER_CLASSNAME, {diff: Boolean(diffIdentifier)})}>
        {<div className="card-static-html_heading_wrapper">
          {!previewMode && (
          <h1 className={CARD_TITLE_CLASSNAME} contentEditable={false} dangerouslySetInnerHTML={wrapHtml(name)} />
          )}{children}</div>}
        <ContentZoom id={cardId}>
          <div
            className={cardBodyClass}
            data-test-id="card-static-html-content"
            data-ga-rival={rivalName}
            data-ga-rival-id={rivalId}
            data-ga-lane={laneName}
            data-ga-card={nameWithoutHighlight || name}
            data-ga-card-id={cardId}
            data-ga-board-id={boardId}
            data-ga-battlecard-id={isBattlecardView ? battlecardId : null}
            contentEditable={false}
            onClick={e => this.handleContentClick(e, baseClass)}
            dangerouslySetInnerHTML={wrapHtml(sanitizeInput(html, {
              allowedTags: ['iframe', ...allowlistedTags],
              allowedAttributes: {
                iframe: ['src', 'width', 'height', 'frameborder', 'allowfullscreen', 'allowtransparency', 'name', 'referrerpolicy', 'sandbox', 'loading'],
                ...allowlistedAttributes
              }
            }), false)} />
        </ContentZoom>
      </section>
    );
  }

}

// we use withAnalyticsEvent HOC to inject analyticsEventContext prop here since
// unstable_renderSubtreeIntoContainer doesn't pass context down to sub components
export {CardStaticHtml as WrappedCardStaticHtml};

export default withAnalyticsEvent(CardStaticHtml);
