import classNames from 'classnames';

class DashboardCard extends React.PureComponent {

  static propTypes = {
    id: PropTypes.string.isRequired,
    profileId: PropTypes.number.isRequired,
    isVisible: PropTypes.bool,
    isDraft: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isCompact: PropTypes.bool.isRequired,
    isRestricted: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    children: PropTypes.node,
    cardWidth: PropTypes.number,
    cardHeight: PropTypes.number,
    onVisible: PropTypes.func,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
  };

  static defaultProps = {
    id: '',
    profileId: 0,
    isVisible: true,    // NOTE: default to true here as dashboard edit mode doesn't use Observer
    isDraft: false,
    isNew: false,
    isCompact: false,
    isRestricted: false,
    isActive: false,
    children: null,
    cardWidth: 300,
    cardHeight: 100,
    onVisible() {},
    onClick() {},
    onMouseEnter() {},
    onMouseLeave() {}
  };

  componentDidMount() {
    console.log('DashboardCard.componentDidMount: props: %o', this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!this.props.isVisible && nextProps.isVisible) {
      nextProps.onVisible();
    }
  }

  render() {
    const {
      id, isVisible, isDraft, profileId, isNew, isCompact, isRestricted, isActive, children,
      cardWidth, cardHeight, onClick, onMouseEnter, onMouseLeave
    } = this.props;

    if(!isVisible || _.isEmpty(children)) {
      // still loading, or in case of improperly populated data
      return (
        <div style={{height: cardHeight, width: cardWidth}} className="dashboard-card dashboard-card--placeholder">
          <div className="dashboard-card--placeholder_body" />
          <div className="dashboard-card--placeholder_footer" />
        </div>
      );
    }

    const extraAttributes = {};
    const cardClasses = classNames({
      'dashboard-card': true,
      'dashboard-card--draft': isDraft,
      'dashboard-card--new': isNew,
      'dashboard-card--compact': isCompact,
      'dashboard-card--restricted': isRestricted,
      'dashboard-card--active': isActive
    });

    if(id) {
      extraAttributes.id = id;
    }

    return (
      <div
        className={cardClasses}
        tabIndex="0"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...extraAttributes}
        data-cy={profileId}>
        {children}
      </div>
    );
  }

}

export default DashboardCard;

