import Synonym from './_synonym';
import Spinner from '../_spinner';

const SynonymList = ({synonyms, onDelete, deletingSynonym}) => {
  return (
    <div className="synonym-list_container">
      <div className="synonym-list_header">Synonyms:</div>
      <div className="synonym-list">
        {synonyms.map(s => {
          return (<Synonym key={s} name={s} onDelete={() => onDelete(s)} />);
        })}
        {deletingSynonym && <Spinner className="spinner--small" />}
      </div>
    </div>
  );
};

export default SynonymList;

SynonymList.propTypes = {
  synonyms: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
  deletingSynonym: PropTypes.bool
};

SynonymList.defaultProps = {
  synonyms: [],
  onDelete() {},
  deletingSynonym: false
};

