/* eslint-disable camelcase */
import classNames from 'classnames';

import Spinner from '../_spinner';
import Icon from '../_icon';
import {userCanCurate} from '../../modules/roles_utils';
import {pluralize} from '../../modules/text_utils';

const CardMetaLink = ({card, sources, user, previewingId, loading, hasNotifications, isBattlecard, onSetEditMode, showSnapshots, onShowCardMeta}) => {
  // card object will contain sourcesCount (from db) or sources_count (if card object from elastic_search)
  const {sourcesCount = 0, sources_count = 0, id} = card || {};
  // sources array takes priority over sourcesCount
  const numberOfSources = sources ? sources.length : (sourcesCount || sources_count);
  const isCurator = userCanCurate({user: user || {}});
  const shouldNotRenderCardMetaLink = !id || (!isCurator && !numberOfSources) || (previewingId !== null && !numberOfSources); // eslint-disable-line camelcase

  const handleOnShowCardMeta = e => {
    e && e.preventDefault();

    onShowCardMeta(card);
  };

  const handleOnSetEditMode = e => {
    e && e.preventDefault();

    onSetEditMode(id);
  };

  if(shouldNotRenderCardMetaLink) {
    return null;
  }

  if(loading) {
    return (
      <div className="card-meta-link">
        <div className="card-meta-link_loading">
          <Spinner />
        </div>
      </div>
    );
  }

  const cardMetaClass = classNames(
    `card-meta-link card-meta-link--${id}`, {
      'card-meta-link--visible': isCurator || Boolean(numberOfSources),
      'card-meta-link--notify': hasNotifications
    }
  );
  const editTooltip = 'Edit Card';
  const sourcesTooltip = numberOfSources ? `Show ${numberOfSources} ${pluralize('source', numberOfSources)}` : 'Add Sources';
  const cardMetaStyles = showSnapshots && card.snapshotsCount ? {style: {right: '44px'}} : {};
  const editButton = (
    <a
      href="#"
      className={cardMetaClass}
      onClick={handleOnSetEditMode}
      data-action="showCardMeta"
      data-tracking-id="cardEdit-metaLink"
      data-tip={editTooltip}
      data-offset="{'top': 0}"
      {...cardMetaStyles}>
      <i className="fa fa-edit" fontSize={{font: '18px'}} />
    </a>
  );
  const addSourcesButton = (
    <a
      href="#"
      className={cardMetaClass}
      onClick={handleOnShowCardMeta}
      data-action="showCardMeta"
      data-testid="cardSources-showBox"
      data-tracking-id="cardSources-showBox-metaLink"
      data-tip={sourcesTooltip}
      data-offset="{'top': 0}"
      {...cardMetaStyles}>
      <Icon width="18" height="18" icon={`${!numberOfSources ? 'info-outline' : 'info'}`} />
    </a>
  );

  if(isBattlecard) {
    return isCurator ? addSourcesButton : null;
  }

  return editButton;
};

CardMetaLink.propTypes = {
  card: PropTypes.object,
  sources: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
  previewingId: PropTypes.number,
  loading: PropTypes.bool,
  hasNotifications: PropTypes.bool,
  isBattlecard: PropTypes.bool,
  onSetEditMode: PropTypes.func,
  onShowCardMeta: PropTypes.func,
  showSnapshots: PropTypes.bool
};

CardMetaLink.defaultProps = {
  card: null,
  sources: null,
  user: null,
  previewingId: null,
  loading: false,
  hasNotifications: false,
  isBattlecard: false,
  onSetEditMode() {},
  onShowCardMeta() {},
  showSnapshots: false
};

export default CardMetaLink;
