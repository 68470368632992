import {
  paragraphs as htmlParagraphs,
  truncate as htmlTruncate
} from './html_utils';

// yes, I was just messing around here, and it was fun 😏
// source: https://textfac.es
export const textFaces = Object.freeze([
  '¯\\_(ツ)_/¯',
  '(ಥ﹏ಥ)',
  '( ͡° ͜ʖ ͡°)',
  '༼ つ ◕_◕ ༽つ',
  'ಠ╭╮ಠ',
  'ಥ_ಥ',
  '( ಠ ͜ʖರೃ)',
  '╚(ಠ_ಠ)=┐',
  '༼ つ ಥ_ಥ ༽つ',
  'ಠ_ಥ',
  'ʕ•ᴥ•ʔ',
  '(╯°□°）╯︵ ┻━┻',
  '(¬‿¬)',
  '(◕‿◕✿)',
  '(ᵔᴥᵔ)',
  '( ͡ᵔ ͜ʖ ͡ᵔ )',
  'ᕙ(⇀‸↼‶)ᕗ',
  '⚆ _ ⚆',
  '(✿◠‿◠)'
]);

// get a random text face
export const getTextFace = () => textFaces[Math.floor(Math.random() * textFaces.length)];

// credit: http://www.oct4th.com/2013/03/15/titleize-for-javascript/
export const titleize = (str = '') => {
  if(!str || !(typeof str === 'string')) {
    return '';
  }

  const words = str.split(' ');
  const array = [];

  for(let i = 0; i < words.length; ++i) {
    array.push(`${words[i].charAt(0).toUpperCase()}${words[i].toLowerCase().slice(1)}`);
  }

  return array.join(' ');
};

export const capitalize = (str = '') => (str || '').charAt(0).toUpperCase() + str.slice(1);

export const pluralize = (str = '', count = 0) => `${str}${count !== 1 ? 's' : ''}`;

export const stripBreaks = (str = '') => (str || '').replace(/(\r\n|\n\r|\r|\n)/gm, ' ');

export const stripExtraWhitespace = (str = '') => (str || '').replace(/\s+/g, ' ');

export const cleanupHtmlString = html => (html
  .replace(/\s{2,}/g, ' ') // strip double spaces/lines
  .replace(/>(\s)+</g, '>$1<') // strip space between tags
  .trim());

export const truncate = (str, options) => htmlTruncate(str, options);

export const paragraphs = (str, options) => htmlParagraphs(str, options);

export function intersperse(arr = [], sep = '') {
  if(arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce((accumulator, currentValue) => {
    return accumulator.concat([sep, currentValue]);
  }, [arr[0]]);
}

export const camelToSnake = string =>
  string.replace(/[\w]([A-Z])/g, m => `${m[0]}_${m[1]}`).toLowerCase();
