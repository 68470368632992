import {useCallback, useEffect, useRef, useState} from 'react';
import RespondedSurvey from './win_loss/_responded_survey/_responded_survey';
import SurveyProgressActions from './win_loss/survey_progress_actions/_survey_progress_actions';
import SurveyProgressBar from './win_loss/survey_progress_bar/_survey_progress_bar';
import {
  ProgressButton,
  ProgressButtonContainer,
  QuestionBlock,
  SurveyForm,
  SurveyPage,
  SurveyPageContainer,
  SurveyQuestionHeader,
  SurveyQuestionInput,
  SurveyQuestionInputHelperText,
  SurveyTitle
} from './win_loss/_styles';
import {fetch, post} from '../modules/api_utils';
import isEmpty from 'lodash/isEmpty';
import Spinner from './_spinner';
import {COMPANY_NAME_PLACEHOLDER, labels, breakpoints, SURVEY_RESPONSE_API_PATH} from './win_loss/_constants';
import useResponsive from '../hooks/useResponsive';
import {Label} from './win_loss/survey_progress_actions/_styles';
import TRACKING_IDS from '../tracking-ids';

const WinLossSurvey = ({token}) => {
  const [surveyHasResponse, setSurveyHasResponse] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [companyName, setCompanyName] = useState(COMPANY_NAME_PLACEHOLDER);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [redirectUrl, setRedirectURL] = useState('/winloss/survey/success');

  const formRef = useRef();

  const deviceType = useResponsive({
    smartphone: breakpoints.tablet.min,
    tablet: breakpoints.desktop.min
  }, 'desktop');

  useEffect(() => {
    if(!questions?.length > 0) {
      return;
    }

    const nextQuestion = document.getElementById(`answer${currentQuestion + 1}`);

    const onKeyDownInTextArea = e => {
      if(e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        setCurrentQuestion(prev => (prev < questions.length - 1 ? prev + 1 : prev));
      }
    };

    const onKeyUp = e => {
      if(e.target.nodeName.toUpperCase() === 'TEXTAREA') {
        return;
      }

      if(e.keyCode === 13) {
        setCurrentQuestion(prev => (prev < questions.length - 1 ? prev + 1 : prev));

        return;
      }

      switch(e.key) {
        case 'ArrowDown':
        case 'ArrowRight':
          setCurrentQuestion(prev => (prev < questions.length - 1 ? prev + 1 : prev));
          break;
        case 'ArrowUp':
        case 'ArrowLeft':
          setCurrentQuestion(prev => (prev > 0 ? prev - 1 : prev));
          break;
        default:
          break;
      }
    };

    document.addEventListener('keyup', onKeyUp);
    nextQuestion?.addEventListener('keydown', onKeyDownInTextArea);

    return () => {
      document.removeEventListener('keyup', onKeyUp);
      nextQuestion?.removeEventListener('keydown', onKeyDownInTextArea);
    };
  }, [currentQuestion, questions, isPageLoading]);

  useEffect(() => {
    const getResponses = async () => {
      try {
        const response = await fetch(SURVEY_RESPONSE_API_PATH, {
          headers: {
            'Content-Type': 'application/json',
            'x-klue-survey-access-token': token
          }
        });

        if(!isEmpty(response.data?.responses) && !surveyHasResponse) {
          setSurveyHasResponse(true);
        }

        setCompanyName(response.data?.companyName || COMPANY_NAME_PLACEHOLDER);
        setQuestions(response.data?.template.questions);
        setRedirectURL(prevState => response.data?.template.redirectUrl ?? prevState);
      }
      catch(error) {
        console.error('Error encountered while getting survey response: ', {error});
      }
      finally{
        setIsPageLoading(false);
      }
    };

    if(!surveyHasResponse) {
      getResponses();
    }
  }, [surveyHasResponse, token]);

  useEffect(() => {
    if(!formRef?.current) {
      return;
    }

    const nextQuestion = formRef.current.querySelector(`#answer${currentQuestion + 1}`);

    if(!nextQuestion) {
      return;
    }

    const scrollAnimationTimer = setTimeout(() => {
      nextQuestion.scrollIntoView({behavior: 'smooth'});
    }, 300);

    const focusAnimationTimer = setTimeout(() => {
      const input = nextQuestion.querySelector('textarea');

      if(input) {
        input.focus();
      }
    }, 900);

    return () => {
      clearTimeout(scrollAnimationTimer);
      clearTimeout(focusAnimationTimer);
    };
  }, [currentQuestion]);

  const updateSubmitButton = useCallback(() => {
    if(!formRef?.current) {
      return;
    }

    setIsSubmitDisabled(!formRef.current.checkValidity());
  }, []);

  const handleSubmitForm = useCallback(() => {
    if(!formRef?.current) {
      return;
    }

    setIsSendingForm(true);

    const answersFields = formRef.current.querySelectorAll('textarea');

    const responses = {
      items: Array.from(answersFields || []).map((answerField, index) => ({
        order: index,
        question: answerField.name,
        answer: answerField.value
      }))
    };

    post(SURVEY_RESPONSE_API_PATH, {responses}, {
      headers: {
        'Content-Type': 'application/json',
        'x-klue-survey-access-token': token
      }
    }).then(data => {
      console.log('received from post api', data);
      setIsSendingForm(false);
      setSurveyHasResponse(true);
      window.location.href = redirectUrl;
    }).catch(err => {
      setIsSendingForm(false);
      console.error(
        err,
        'an error happened when trying to submit the form',
        responses
      );
    });
  }, [redirectUrl, token]);

  if(isPageLoading) {
    return (
      <SurveyPage>
        <Spinner />
      </SurveyPage>
    );
  }

  if(surveyHasResponse) {
    return (
      <SurveyPage>
        <SurveyPageContainer>
          <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}>
            <img width="100px" src="/logo-klue-white.png" />
            <SurveyTitle aria-level={1} textLength={labels.title.length}>
              {labels.title}
            </SurveyTitle>
          </div>
          <RespondedSurvey />
        </SurveyPageContainer>
      </SurveyPage>
    );
  }

  const handleShowPreviousQuestion = () => setCurrentQuestion(prev => (prev > 0 ? prev - 1 : prev));
  const handleShowNextQuestion = () => setCurrentQuestion(prev => (prev < questions.length - 1 ? prev + 1 : prev));

  const adjustHeight = e => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <SurveyPage>
      <SurveyPageContainer>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}>
          <SurveyTitle aria-level={1} textLength={labels.title.length}>
            {labels.title}
          </SurveyTitle>
          <SurveyProgressBar currentStep={currentQuestion} totalSteps={questions.length - 1} />
        </div>

        <SurveyForm
          novalidate={true}
          ref={formRef}
          name="win-loss-survey"
          autoComplete="off">
          {questions
            .map(({question, ...rest}) => ({question: question.replaceAll('${}', companyName), ...rest}))
            .map(({question, required}, i) => (
              <QuestionBlock key={`answer${i + 1}`} id={`answer${i + 1}`}>
                <SurveyQuestionHeader>{question}</SurveyQuestionHeader>
                <div>
                  <SurveyQuestionInput
                    id={`answer${i + 1}`}
                    className="answer"
                    name={question}
                    required={required}
                    placeholder="Type your answer here..."
                    onChange={updateSubmitButton}
                    onInput={adjustHeight} />
                  <SurveyQuestionInputHelperText>
                    Hit <b>ENTER</b> to go to the next question and <b>SHIFT + ENTER</b> to jump a line.
                  </SurveyQuestionInputHelperText>
                </div>
                {deviceType !== 'smartphone' && i === questions.length - 1 ? (
                  <ProgressButtonContainer>
                    <ProgressButton
                      isSubmit={true}
                      onClick={handleSubmitForm}
                      disabled={isSubmitDisabled || isSendingForm}
                      data-tracking-id={TRACKING_IDS.winLoss.buyerPulseSurvey.submitButton}
                      isLoading={isSendingForm}>
                      {isSendingForm ? <Spinner /> : 'Submit'}
                    </ProgressButton>
                  </ProgressButtonContainer>
                ) : null}
              </QuestionBlock>
            ))}
        </SurveyForm>
        {deviceType === 'smartphone' ? (
          <ProgressButtonContainer>
            <Label>
              {currentQuestion + 1}/{questions.length}
            </Label>
            {currentQuestion > 0
              ? <ProgressButton data-tracking-id={TRACKING_IDS.winLoss.buyerPulseSurvey.previousQuestionButton} onClick={handleShowPreviousQuestion}>
                Previous
              </ProgressButton>
              : null
            }
            {currentQuestion < questions.length - 1 ? (
              <ProgressButton
                data-tracking-id={TRACKING_IDS.winLoss.buyerPulseSurvey.nextQuestionButton}
                isNext={true}
                onClick={handleShowNextQuestion}>Next</ProgressButton>
            ) : null}
            {currentQuestion === questions.length - 1 ? (
              <ProgressButton
                isSubmit={true}
                onClick={handleSubmitForm}
                data-tracking-id={TRACKING_IDS.winLoss.buyerPulseSurvey.submitButton}
                disabled={isSubmitDisabled || isSendingForm}
                isLoading={isSendingForm}>
                {isSendingForm ? <Spinner /> : 'Submit'}
              </ProgressButton>
            ) : null}
          </ProgressButtonContainer>
        ) : null}
        <div
          style={{
            width: '100%',
            height: '16px',
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            fontSize: '12px',
            justifyContent: 'center'
          }}>
          Powered by
          <a
            href="https://klue.com/win-loss"
            data-tracking-id={TRACKING_IDS.winLoss.buyerPulseSurvey.footer.poweredBy}
            style={{
              height: '15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
            target="_blank">
            <img width="27px" height="11px" src="/logo-klue-dark.png" />
          </a>
        </div>
      </SurveyPageContainer>
      {deviceType !== 'smartphone' ? <SurveyProgressActions
        currentStep={currentQuestion + 1}
        onNextStep={handleShowNextQuestion}
        onPreviousStep={handleShowPreviousQuestion}
        totalSteps={questions.length} /> : null}
    </SurveyPage>
  );
};

WinLossSurvey.propTypes = {
  token: PropTypes.string.isRequired
};

export default WinLossSurvey;
