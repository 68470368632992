import {useState} from 'react';
import className from 'classnames';

import InputSelectorDropdown from './_input_selector_dropdown';

const InputSelector = ({
  selected,
  type,
  label,
  value,
  groups,
  defaultRadioValue,
  tooltip,
  unassignedLabel,
  addNewPlaceholder,
  onAddNewItem,
  onItemClick,
  dataTrackingId
}) => {
  const [open, setOpen] = useState(false);

  const handleOutsideClick = () => setOpen(false);

  const handleItemClick = item => {
    onItemClick(item);

    if(type === 'radio') {
      setOpen(false);
    }
  };

  const handleAddNewItem = (item, val) => {
    setOpen(false);
    onAddNewItem(item, val);
  };

  return (
    <div data-tracking-id={dataTrackingId} className={className('input_selector', {off: !selected.length})} data-type={type}>
      <input
        className={open ? 'on' : 'off'}
        onClick={() => setOpen(!open)}
        autoComplete="off"
        type="search"
        title={tooltip}
        value={`${label}: ${value ? value : unassignedLabel}`}
        readOnly={true}
        placeholder={`Search for ${label}...`}
        data-test-id="input_selector_input" />
      <InputSelectorDropdown
        groups={groups}
        selected={selected}
        defaultValue={type === 'radio' ? defaultRadioValue : null}
        onItemClick={handleItemClick}
        onOutsideClick={handleOutsideClick}
        onAddNewItem={onAddNewItem ? handleAddNewItem : null}
        addNewPlaceholder={addNewPlaceholder} />
    </div>
  );
};

InputSelector.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }), PropTypes.string])).isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  tooltip: PropTypes.string,
  defaultRadioValue: PropTypes.string,
  unassignedLabel: PropTypes.string,
  onItemClick: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    collapsed: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }), PropTypes.string]))
  })).isRequired,
  onAddNewItem: PropTypes.func,
  addNewPlaceholder: PropTypes.string,
  dataTrackingId: PropTypes.string
};

InputSelector.defaultProps = {
  type: 'radio',
  label: '',
  value: '',
  tooltip: '',
  defaultRadioValue: 'none',
  unassignedLabel: 'Unassigned',
  onItemClick() {},
  onAddNewItem: null,
  addNewPlaceholder: '',
  dataTrackingId: null
};

export default InputSelector;
