/* eslint-disable react/no-multi-comp */
import {wrapHtml} from '../modules/html_utils';

import classNames from 'classnames';
import {Link} from 'react-router-dom';

const ToastMessage = props => {
  const {title, message, duration, transitionDuration, isInfo, isError, onDismissClick} = props;
  let {users} = props;
  let messageTimer;

  const handleDismissClick = () => {
    if(messageTimer) {
      clearTimeout(messageTimer);
    }

    onDismissClick && onDismissClick();
  };

  const renderDismissRegion = () => {
    if(!onDismissClick) {
      return;
    }

    return (
      <div className="toast-message_dismiss" onClick={handleDismissClick}><span>&times;</span></div>
    );
  };

  const renderTitleRegion = () => {
    if(!title) {
      return;
    }

    return (
      <h4 className="toast-message_heading">{title}</h4>
    );
  };

  const renderBylineRegion = () => {
    users = users.filter(c => Boolean(c));     // filter out any potential deactivated/null users

    if(!users.length) {
      return;
    }

    const avatars = [];
    const featuredUser = users[0];
    const avatarCount = Math.min(users.length, 3);
    let bylineCount;

    if(!featuredUser || _.isEmpty(featuredUser)) {
      return;
    }

    for(let i = 0; i < avatarCount; i++) {
      const user = users[i];

      if(!user || _.isEmpty(user) || !user.id || user.deletedAt) {
        continue;
      }

      const avatarStyle = {
        zIndex: avatarCount - i      // maintain left-to-right stacking order
      };

      avatars.push(
        <Link key={`avatar_${i}`} to={`/users/${user.id}`} className="toast-message_byline_avatar" style={avatarStyle}>
          <img className="toast-message_byline_avatar_img" src={user.imageMed} />
        </Link>
      );
    }

    if(users.length > 1) {
      bylineCount = (
        <span className="toast-message_byline_count">{`+${users.length - 1}`}</span>
      );
    }

    return (
      <div className="toast-message_byline">
        {avatars}
        <Link to={`/users/${featuredUser.id}`} className="toast-message_byline_link">
          {featuredUser.name}
          {bylineCount}
        </Link>
      </div>
    );
  };

  const renderBodyRegion = () => {
    if(React.isValidElement(message)) {
      return React.cloneElement(message, {className: 'toast-message_body'});
    }

    return (
      <p className="toast-message_body" dangerouslySetInnerHTML={wrapHtml(message)} />
    );
  };

  if(!title && !message && _.isEmpty(users)) {
    return null;
  }

  // DEBUG
  console.log('ToastMessage: props: %o', props);

  if(onDismissClick && (duration > 0)) {
    // set auto-dismiss timeout if requested
    messageTimer = setTimeout(() => {
      onDismissClick();
    }, duration + Math.max(transitionDuration, 0));
  }

  const toastClasses = classNames('toast-message', {
    'toast-message--left': !isError,
    'toast-message--bottom': isInfo,
    'toast-message--right toast-message--error': isError,
    'toast-message--dismissible': Boolean(onDismissClick)
  });

  return (
    <div className={toastClasses}>
      <div className="toast-message_container">
        {renderDismissRegion()}
        {renderTitleRegion()}
        {renderBylineRegion()}
        {renderBodyRegion()}
      </div>
    </div>
  );
};

ToastMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  duration: PropTypes.number.isRequired,
  transitionDuration: PropTypes.number.isRequired,
  isInfo: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  onDismissClick: PropTypes.func
};

ToastMessage.defaultProps = {
  title: '',
  message: '',
  users: [],                  // hidden if empty; if populated, will show byline with user avatar(s) & profile link to first user
  duration: 5000,             // ms, 0 for no auto-dismiss, requires onDismissClick
  transitionDuration: 260,    // ms, transition duration added to props.duration before auto-dismiss
  isInfo: false,              // if true, will show regular toast message in bottom-left corner of the screen
  isError: false,             // if true, will show a red toast message on the right-top corner of the screen
  onDismissClick: null        // if null, duration & transitionDuration will be ignored
};

export default ToastMessage;
