import {fetch, post, update} from '../api_utils';
import {isValidId, serializeQueryString} from '../utils';
import {CARD_SNAPSHOTS_PER_PAGE} from '../constants/api';

// card management API

// TODO: note gotcha: cardGet() needs cardOptions.cardId while cardUpdate() below expects cardOptions.id
export const cardGet = ({cardOptions = {cardId: 0}, code = 'ApiUtils.cardGet'}) => {
  return new Promise((resolve, reject) => {
    if(_.isEmpty(cardOptions) || !isValidId(cardOptions.cardId)) {
      console.warn(`${code}: invalid options specified: %o`, cardOptions);

      return reject(cardOptions);
    }

    const {cardId, visibilityGroupId} = cardOptions;  // TODO: change this to id to match cardUpdate() below
    const urlParams = serializeQueryString({visibilityGroupId});

    fetch(`/api/cards/${cardId}.json?v=3${urlParams ? `&${urlParams}` : ''}`, {code})
      .then(async ({data: card}) => {
        if(_.isEmpty(card)) {
          console.error(`${code}: no card found for cardId #%o`, cardId);

          return reject(null);
        }

        console.log(`${code}: loaded cardId #%o: %o`, card.id, card);

        resolve(card);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

export const cardCreate = (card = {}, code = 'ApiUtils.cardCreate') => {
  /* options format:
   {card: {
      boardId: required,
      templateName: required,
      data: required if template has validation,
      comments: optional, array of comment IDs for this card,
      viewOrder: optional,
      author: optional, user ID
   }} */
  return new Promise((resolve, reject) => {
    const {boardId, templateName, data} = card;

    if(_.isEmpty(card) || !isValidId(boardId) || !templateName || _.isEmpty(data)) {
      console.warn(`${code}: invalid options specified: %o`, card);

      return reject(card);
    }

    post('/api/cards.json?v=3', card, {code})
      .then(newCard => {
        if(_.isEmpty(newCard)) {
          console.error(`${code}: failed to create card %o`, card);

          return reject(null);
        }

        console.log(`${code}: created new card #%o: %o`, newCard.id, newCard);

        resolve(newCard);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

export const cardSnapshotsGet = (cardOptions = {cardId: 0, page: 1, limit: CARD_SNAPSHOTS_PER_PAGE}, code = 'ApiUtils.cardSnapshotsGet') => {
  // options format:
  //   cardOptions: {
  //     cardId: int,
  //     page: number,       // optional (also needs limit), retrieve N-th page (1-based), defaults to 1
  //     limit: number       // optional (also needs page), paginate by limit cards at a time, defaults to 20
  //   }
  const {cardId} = cardOptions;

  return new Promise((resolve, reject) => {
    if(_.isEmpty(cardOptions) || !isValidId(cardId)) {
      console.warn(`${code}: invalid options specified: %o`, cardOptions);

      return reject(cardOptions);
    }

    fetch(`/api/cards/${encodeURIComponent(cardId)}/snapshots.json`, {code})
      .then(({data: card}) => {
        if(_.isEmpty(card)) {
          console.error(`${code}: no card found for cardId #%o`, cardId);

          return reject(null);
        }

        console.log(`${code}: loaded cardId #%o: %o`, card.id, card);

        resolve(card);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

export const cardUpdate = (card = {}, code = 'ApiUtils.cardUpdate') => {
  return new Promise((resolve, reject) => {
    if(_.isEmpty(card) || !isValidId(card.id)) {
      console.warn(`${code}: invalid options specified: %o`, card);

      return reject(card);
    }

    const {data, id: cardId} = card;

    update(`/api/cards/${cardId}.json?v=3`, data, {code})
      .then(updatedCard => {
        console.log(`${code}: updated cardId #%o: %o`, cardId, updatedCard);
        resolve(updatedCard);
      })
      .catch(error => {
        console.error('AppBase.apiPostCompetitorTagUpdate', error);
        reject(error);
      });
  });
};

export const cardAddTag = (card, tagId) =>
  cardUpdate({...card, data: {addTags: [tagId]}}, 'ApiUtils.cardAddTag');

export const cardRemoveTag = (card, tagId) =>
  cardUpdate({...card, data: {removeTags: [tagId]}}, 'ApiUtils.cardRemoveTag');
