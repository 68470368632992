import {useCallback, useReducer, useEffect, useRef, useState} from 'react';
import {
  getSettingsFromDigestType,
  getNextDigestUTCDateTime
} from '../../modules/digest_utils';
import {
  defaultDigestDay,
  defaultDigestFrequency,
  defaultDigestHour,
  defaultDigestSendOnceHours
} from '../../modules/constants/digest';
import DigestAudienceSettings from './_digest_audience_settings';
import DigestTemplateSettings from './_digest_template_settings';
import DigestFrequencySettings from './_digest_frequency_settings';
import DigestArticleSettings from './_digest_articles_settings';
import DigestName from './_digest_name';
import DigestSettingError from './_digest_setting_error';
import classNames from 'classnames';
import {isEqual} from 'lodash';
import {userIsAdmin, userCanCurate} from '../../modules/roles_utils';
import {useDigestTemplates} from '../../contexts/_digestTemplates';

const reducer = (state, {payload: {
  digestType
}, type}) => {
  switch(type) {
    case 'UPDATE':
      return {
        ...state,
        ...getSettingsFromDigestType(digestType)
      };
    default: return state;
  }
};

const calcReviewSendOnceAt = reviewSendOnceAt => {
  return reviewSendOnceAt ? Math.ceil(moment.duration(moment(reviewSendOnceAt).diff(moment())).asHours()) : defaultDigestSendOnceHours;
};

function getSettings(settings) {
  return {
    ...settings,
    originalSettings: settings
  };
}

const DigestTypeSettings = ({
  digest,
  rivals,
  rivalGroups,
  activeDigestType,
  activeDigestTypeIsNew,
  digestTypes,
  visibilityGroups,
  isManageDigestTemplates,
  showDoneButton,
  restoreSettingOnCancel,
  showNameSection,
  onClose,
  onCancelAdd,
  onCancelledEdit,
  digestGet,
  digestTypeUpdate,
  updateTheDigest,
  confirm
}, context) => {
  const [{
    name,
    id,
    reviewFrequency,
    reviewDay,
    reviewHour,
    reviewRole,
    reviewSendOnceAt,
    reviewItemsPerRival,
    reviewTopics,
    reviewRivalIds,
    enableAutoSuggestion,
    deleteAfterSend,
    emailDigestTemplate,
    originalSettings
  }, dispatch] = useReducer(reducer, getSettingsFromDigestType(activeDigestType), getSettings);
  const isMounted = useRef(false);
  const getDigestSequenceNumber = useRef(0);
  const [digestType, setDigestType] = useState(activeDigestType);
  const [dirtyName, setDirtyName] = useState();
  const [didSaveSettings, setDidSaveSettings] = useState(false);
  const [saved, setSaved] = useState(false);
  const [genericError, setGenericError] = useState();
  const [nameError, setNameError] = useState();
  const [sendTime, setSendTime] = useState(getNextDigestUTCDateTime(digest || {}));
  const [sendOnceHours, setSendOnceHours] = useState(calcReviewSendOnceAt(reviewSendOnceAt));
  const {templates, updateTemplate, deleteTemplate} = useDigestTemplates();
  const [updatedTemplates, setUpdatedTemplates] = useState([]);
  const [deleteTemplates, setDeleteTemplates] = useState([]);
  const [workingTemplates, setWorkingTemplates] = useState(templates);
  const [editingTemplateItem, setEditingTemplateItem] = useState(false);
  const [processingTopics, setProcessingTopics] = useState(false);
  const [processingRivals, setProcessingRivals] = useState(false);
  const [, setSaving] = useState(false);

  const handleUpdateError = xhr => {
    setGenericError(<DigestSettingError message={xhr?.responseJSON?.message || 'An unknown error occurred while updating the Digest Settings.'} />);
  };

  const refreshDigestSendTime = useCallback(callback => {
    const sn = ++getDigestSequenceNumber.current;

    digestGet(0, id, updatedDigest => {
      if(!isMounted.current || (sn !== getDigestSequenceNumber.current)) {
        return;
      }

      setSendTime(getNextDigestUTCDateTime(updatedDigest));
      typeof callback === 'function' && callback();
    });
  }, [digestGet, id]);

  const updateDigestType = useCallback((options, refreshSendTime = false, callback) => {
    setDidSaveSettings(true);
    digestTypeUpdate(options, (updated, xhr, type) => {
      if(!isMounted.current) {
        return;
      }

      if(!updated) {
        return handleUpdateError(xhr, type);
      }

      setSaved(true);
      setDigestType(updated);

      if(refreshSendTime) {
        return refreshDigestSendTime(callback);
      }

      typeof callback === 'function' && callback(updated);
    });
  }, [digestTypeUpdate, refreshDigestSendTime]);

  const handleCancelAdd = useCallback(() => {
    onCancelAdd(digestType);
  }, [onCancelAdd, digestType]);

  const updateActiveDigestWithTemplate = useCallback((templateId, callback = null) => {
    const {emailDigestTemplate: originalSettingsTemplate = null} = originalSettings || {};
    const isRecentlySetToNone = templateId === null && originalSettingsTemplate !== null;

    if(templateId || isRecentlySetToNone) {
      const {utils: {user}} = context;

      const {id: lastCuratorId = null} = user || {};
      const {id: digestId} = digest || {};

      const selectedTemplate = templates.find(template => template?.id === templateId);
      const {summary = null, title = null} = selectedTemplate || {};

      const digestOptions = {
        title: (title || '').trim() || null,
        summary: (summary || '').trim() || null,
        banner: null,
        lastCuratorId,
        id: digestId
      };

      updateTheDigest({digestOptions, callback(err) {
        if(typeof callback === 'function') {
          return callback(err);
        }
      }});
    }
    else if(typeof callback === 'function') {
      return callback();
    }
  }, [context, digest, originalSettings, templates, updateTheDigest]);

  const handleUpdateActiveDigestWithTemplate = useCallback(templateId => {
    updateActiveDigestWithTemplate(templateId, error => {
      setSaving(false);

      if(error) {
        return setGenericError(<DigestSettingError message="There was an error setting a default template. Please try again." />);
      }

      onClose((saved || activeDigestTypeIsNew) ? digestType : null);
    });
  }, [activeDigestTypeIsNew, digestType, saved, updateActiveDigestWithTemplate, onClose]);

  const handleSaveAndClose = useCallback(async () => {
    const {emailDigestTemplate: templateId = null} = digestType;

    setGenericError();

    if(nameError) {
      return;
    }

    setSaving(true);

    let updateError = false;
    let deleteError = false;

    for(const template of updatedTemplates) {
      const {id: updateTemplateId} = template;

      if(!deleteTemplates.includes(updateTemplateId)) {
        const updated = await updateTemplate(template);

        updateError ||= Boolean(!updated);
      }
    }

    if(updateError) {
      setGenericError(<DigestSettingError message="There was an error updating the templates. Please try again." />);

      return setSaving(false);
    }

    for(const deleteTemplatesId of deleteTemplates) {
      const deleted = await deleteTemplate(deleteTemplatesId);

      deleteError ||= Boolean(!deleted);
    }

    if(deleteError) {
      setGenericError(<DigestSettingError message="There was an error deleting the templates. Please try again." />);

      return setSaving(false);
    }

    if(typeof dirtyName !== 'string' || dirtyName === digestType?.name) {
      if(activeDigestTypeIsNew) {
        return handleUpdateActiveDigestWithTemplate(templateId);
      }

      setSaving(false);

      return onClose((saved || activeDigestTypeIsNew) ? digestType : null);
    }

    updateDigestType({
      id,
      name: dirtyName
    }, false, updated => {
      if(!updated) {
        setSaving(false);

        return setNameError(<DigestSettingError message="There was an error updating the name. Please try again." />);
      }

      if(activeDigestTypeIsNew) {
        return handleUpdateActiveDigestWithTemplate(templateId);
      }

      setSaving(false);

      onClose(updated);
    });
  }, [
    nameError,
    dirtyName,
    digestType,
    updateDigestType,
    id,
    updatedTemplates,
    deleteTemplates,
    updateTemplate,
    deleteTemplate,
    onClose,
    saved,
    activeDigestTypeIsNew,
    handleUpdateActiveDigestWithTemplate
  ]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if(!isManageDigestTemplates) {
      return;
    }

    document.getElementById('digest-template-settings')?.scrollIntoView({behavior: 'smooth'});
  }, [isManageDigestTemplates]);

  useEffect(() => {
    dispatch({type: 'UPDATE', payload: {digestType}});
  }, [digestType]);

  useEffect(() => {
    setWorkingTemplates([...templates]);
  }, [templates]);

  useEffect(() => {
    setSendTime(getNextDigestUTCDateTime(digest));
  }, [digest]);

  useEffect(() => {
    setSendOnceHours(calcReviewSendOnceAt(reviewSendOnceAt));
  }, [reviewSendOnceAt]);

  const handleVisibilityGroupSelected = (ids, addingRole) => {
    const newReviewRole = [...reviewRole];

    if(addingRole) {
      newReviewRole.push(...ids);
    }
    else {
      // Don't run update if we are trying to remove Full Access Users and it's the only role left
      if(ids.length === 1 && ids[0] === 0 && isEqual(ids, newReviewRole)) {
        return;
      }

      ids.forEach(id => {
        const index = newReviewRole.indexOf(id);

        newReviewRole.splice(index, 1);
      });

      // If we've removed all roles, add Full Access Users back
      if(!newReviewRole.length) {
        newReviewRole.push(0);
      }
    }

    updateDigestType({
      id,
      reviewRole: newReviewRole
    });
  };

  const handleToggleReviewRole = useCallback(role => {
    // Don't trigger update if we are clicking an already-selected role
    if(!role || (role === reviewRole)) {
      return;
    }

    // When picking `visibilityGroups` as role, default to Full Access Users
    const newReviewRole = (role === 'visibilityGroups') ? [0] : (role || 'consumer');

    updateDigestType({
      id,
      reviewRole: newReviewRole
    });
  }, [reviewRole, updateDigestType, id]);

  const handleSetSendOnceHours = hours => {
    const sendOnceAt = moment().add(hours, 'h').toISOString();

    const digestTypeOptions = {
      id,
      reviewSendOnceAt: sendOnceAt
    };

    updateDigestType(digestTypeOptions, true);
  };

  const handleSetReviewFrequency = (frequency, callback) => {
    const frequencyOptions = {
      id,
      reviewFrequency: frequency
    };

    if(frequency === 'once') {
      if(!reviewSendOnceAt) {
        frequencyOptions.reviewSendOnceAt = moment().add(defaultDigestSendOnceHours, 'h').toISOString();
      }
    }

    updateDigestType(frequencyOptions, true, callback);
  };

  const handleSetReviewDay = day => {
    updateDigestType({
      id,
      reviewDay: day
    }, true);
  };

  const handleSetReviewHour = hour => {
    updateDigestType({
      id,
      reviewHour: hour
    }, true);
  };

  const handleSetDeleteAfterSend = delAfterSend => {
    updateDigestType({
      id,
      deleteAfterSend: delAfterSend
    }, true);
  };

  const handleResetToDefaults = () => {
    const resetToDefaults = () => {
      updateDigestType({
        id,
        reviewFrequency: defaultDigestFrequency,
        reviewDay: defaultDigestDay,
        reviewHour: defaultDigestHour,
        reviewSendOnceAt: null
      }, true);
    };

    confirm({
      message: 'Do you want to reset this Digest Schedule to the default schedule?',
      okCallback: resetToDefaults,
      buttonOk: 'Reset to Defaults'
    });
  };

  const handleSetReviewItemsPerRival = itemsPerRival => {
    if(itemsPerRival < 0 || itemsPerRival > 10 || itemsPerRival === reviewItemsPerRival) {
      return;
    }

    updateDigestType({
      id,
      reviewItemsPerRival: itemsPerRival
    });
  };

  const handleDidCheckTopics = checkedTopics => {
    const updatedTopics = new Set(reviewTopics);

    Object.entries(checkedTopics).forEach(([topic, checked]) => {
      if(checked) {
        updatedTopics.add(topic);
      }
      else {
        updatedTopics.delete(topic);
      }
    });

    setProcessingTopics(true);
    updateDigestType({
      id,
      reviewTopics: [...updatedTopics]
    }, false, () => {
      setProcessingTopics(false);
    });
  };

  const handleDidCheckRivals = checkedRivals => {
    const updatedRivals = new Set(reviewRivalIds || []);

    Object.entries(checkedRivals || {}).forEach(([rivalId, checked]) => {
      const rId = parseInt(rivalId, 10);

      if(checked) {
        updatedRivals.add(rId);
      }
      else {
        updatedRivals.delete(rId);
      }
    });

    setProcessingRivals(true);
    updateDigestType({
      id,
      reviewRivalIds: [...updatedRivals]
    }, false, () => {
      setProcessingRivals(false);
    });
  };

  const handleEnableDigestSuggestions = enable => {
    updateDigestType({
      id,
      enableAutoSuggestion: enable
    });
  };

  const handleClearAllTopics = () => {
    setProcessingTopics(true);
    updateDigestType({
      id,
      reviewTopics: []
    }, false, () => {
      setProcessingTopics(false);
    });
  };

  const handleNameChange = updatedName => {
    setDirtyName(updatedName);
  };

  const nameIsUnique = nameToCheck => {
    const lowerCaseName = nameToCheck.toLowerCase();

    return !digestTypes.some(dt => (dt.id !== id) && !dt.archivedAt && (dt.name.toLowerCase() === lowerCaseName));
  };

  const handleNameBlur = () => {
    // we can check the name here for uniqueness
    if(typeof dirtyName === 'string') {
      const trimmedName = dirtyName.trim();

      if(!nameIsUnique(trimmedName)) {
        setNameError(<DigestSettingError message="That name is already in use." />);
      }
      else if(!trimmedName.length) {
        setNameError(<DigestSettingError message="Names cannot be blank." />);
      }
      else if(trimmedName.length > 60) {
        setNameError(<DigestSettingError message="Maximum name length is 60 characters." />);
      }
      else {
        setNameError(null);
      }
    }
  };

  const handleSetDigestTempate = templateId => {
    updateDigestType({
      id,
      emailDigestTemplateId: templateId || null
    });
  };

  if(!digestType) {
    return null;
  }

  const showFrequencySettings = Boolean(!deleteAfterSend || !activeDigestTypeIsNew);
  const {utils: {user, isDigestTemplatesEnabled, isImprovedDigestSuggestionsEnabled}} = context;
  const showDigestTemplates = isDigestTemplatesEnabled();
  const improvedDigestSuggestionsEnabled = isImprovedDigestSuggestionsEnabled();
  const isAdmin = userIsAdmin({user});
  const isCurator = userCanCurate({user});

  const handleCancelEdit = () => {
    const {emailDigestTemplate: emailDigestTemplateId} = originalSettings;

    if(didSaveSettings) {
      // restore to original settings if some were saved
      digestTypeUpdate({
        ...originalSettings,
        emailDigestTemplateId
      }, updated => {
        if(!updated) {
          return setGenericError(<DigestSettingError message="An error occurred while trying to restore changed settings. Please try again." />);
        }

        onCancelledEdit && onCancelledEdit();
      });
    }
    else {
      // otherwise, just close the modal
      onCancelledEdit && onCancelledEdit();
    }
  };

  const handleDidDeleteTemplate = id => {
    const index = deleteTemplates.findIndex(({id: tId}) => tId === id);

    if(index >= 0) {
      return;
    }

    setDeleteTemplates([...deleteTemplates, id]);
  };

  const handleDidUpdateTemplate = template => {
    const index = updatedTemplates.findIndex(({id}) => id === template.id);

    if(index < 0) {
      return setUpdatedTemplates([...updatedTemplates, template]);
    }

    const newUpdatedTemplates = [...updatedTemplates];

    newUpdatedTemplates.splice(index, 1, template);

    setUpdatedTemplates(newUpdatedTemplates);
  };

  const handleWillEditTemplateItem = () => {
    setEditingTemplateItem(true);
  };

  const handleDidEditTemplateItem = () => {
    setEditingTemplateItem(false);
  };

  return (
    <div className="digest-type-settings multiples">
      <h3 className="u-mt-m">Manage this Intel Digest</h3>
      {!isAdmin && showDigestTemplates && isCurator &&
        <div>
          <p>
            To change the digest audience and schedule, you must have administrative privileges.
          </p>
        </div>}
      {genericError}
      {isAdmin && showNameSection && <DigestName
        name={typeof dirtyName === 'string' ? dirtyName : name}
        onNameChange={handleNameChange}
        onNameBlur={handleNameBlur}
        error={nameError} />}
      {isAdmin && <DigestAudienceSettings
        reviewRole={reviewRole}
        visibilityGroups={visibilityGroups}
        onToggleReviewRole={handleToggleReviewRole}
        onVisibilityGroupSelected={handleVisibilityGroupSelected} />}
      {isAdmin && showFrequencySettings && <DigestFrequencySettings
        reviewFrequency={reviewFrequency}
        reviewDay={reviewDay}
        reviewHour={reviewHour}
        sendOnceHours={sendOnceHours}
        deleteAfterSend={deleteAfterSend}
        showDeleteAfterSend={!activeDigestType?.default}
        sendTime={sendTime}
        onSetSendOnceHours={handleSetSendOnceHours}
        onSetReviewFrequency={handleSetReviewFrequency}
        onSetReviewDay={handleSetReviewDay}
        onSetReviewHour={handleSetReviewHour}
        onResetToDefaults={handleResetToDefaults}
        onSetDeleteAfterSend={handleSetDeleteAfterSend} />}
      {isAdmin && <DigestArticleSettings
        improvedDigestSuggestionsEnabled={improvedDigestSuggestionsEnabled}
        reviewItemsPerRival={reviewItemsPerRival}
        reviewTopics={reviewTopics}
        reviewRivalIds={reviewRivalIds}
        enableAutoSuggestion={enableAutoSuggestion}
        rivals={rivals || {}}
        rivalGroups={rivalGroups || []}
        onSetReviewItemsPerRival={handleSetReviewItemsPerRival}
        onClearAllTopics={handleClearAllTopics}
        onDidCheckTopics={handleDidCheckTopics}
        onDidCheckRivals={handleDidCheckRivals}
        processingTopics={processingTopics}
        processingRivals={processingRivals}
        onToggleDigestSuggestionsEnabled={handleEnableDigestSuggestions}
        alwaysShowInfo={true} />}
      {showDigestTemplates && isCurator && <DigestTemplateSettings
        templates={workingTemplates}
        emailDigestTemplate={emailDigestTemplate}
        onSetDigestTemplate={handleSetDigestTempate}
        onDidDeleteTemplate={handleDidDeleteTemplate}
        onDidUpdateTemplate={handleDidUpdateTemplate}
        onWillEditTemplateItem={handleWillEditTemplateItem}
        onDidEditTemplateItem={handleDidEditTemplateItem} />}
      {(showDoneButton || onCancelAdd) && <div className="digest-settings-modal__footer">
        {onCancelAdd && <button
          data-testid="digest-type-settings-cancel-button"
          data-tracking-id="digest-type-settings-cancel-button"
          className="button button--alt"
          onClick={handleCancelAdd}>
          Cancel
        </button>}
        {restoreSettingOnCancel && !onCancelAdd && <button
          data-testid="digest-type-settings-revert-button"
          data-tracking-id="digest-type-settings-revert-button"
          className={classNames('button button--alt', {disabled: editingTemplateItem})}
          onClick={handleCancelEdit}>
          Cancel
        </button>}
        {showDoneButton && <button
          data-testid="digest-type-settings-done-button"
          data-tracking-id="digest-type-settings-done-button"
          className="button button--alt"
          onClick={handleSaveAndClose}>
          Done
        </button>}
      </div>}
    </div>
  );
};

DigestTypeSettings.contextTypes = {
  utils: PropTypes.object.isRequired
};

DigestTypeSettings.propTypes = {
  digest: PropTypes.object,
  rivals: PropTypes.object,
  rivalGroups: PropTypes.array,
  activeDigestType: PropTypes.object,
  activeDigestTypeIsNew: PropTypes.bool,
  digestTypes: PropTypes.arrayOf(PropTypes.object),
  visibilityGroups: PropTypes.arrayOf(PropTypes.object),
  isManageDigestTemplates: PropTypes.bool,
  showDoneButton: PropTypes.bool,
  restoreSettingOnCancel: PropTypes.bool,
  showNameSection: PropTypes.bool,
  onClose: PropTypes.func,
  onCancelAdd: PropTypes.func,
  onCancelledEdit: PropTypes.func,
  digestTypeUpdate: PropTypes.func,
  updateTheDigest: PropTypes.func,
  digestGet: PropTypes.func,
  confirm: PropTypes.func
};

DigestTypeSettings.defaultProps = {
  digest: null,
  rivals: {},
  rivalGroups: [],
  activeDigestType: null,
  activeDigestTypeIsNew: false,
  digestTypes: [],
  visibilityGroups: [],
  isManageDigestTemplates: false,
  showDoneButton: false,
  restoreSettingOnCancel: false,
  showNameSection: false,
  onClose() {},
  onCancelAdd: null,
  onCancelledEdit: null,
  digestTypeUpdate() {},
  updateTheDigest() {},
  digestGet() {},
  confirm() {}
};

export default DigestTypeSettings;
