import axios from 'axios';
import prepare, {getUTCTime} from './salesforce_helpers';

/*
 * TODO: move this to `sfdc_cards.js`, as we can expose it to the UI there better.
 *
 * The following class handles the API logic for all the SFDC card components.
 * (We want those card components to remain fairly agnostic to the data they consume
 * so they are easily transitioned over to Toby's Klue functions.)
 */
class SFDCQuery {

  static getSFDCOpps(query = '', format = true, name = '') {
    const params = {
      query
    };

    if(name) {
      params.conditionName = name;
    }

    return axios
      .get('/api/providers/sfdc/opportunities', {
        params
      })
      .then(response => {
        if(!format) {
          return response.data;
        }

        const {data} = response;

        let minDate = new Date();

        data.forEach(record => {
          const recordDate = new Date(record.CloseDate);

          minDate = getUTCTime(recordDate) < getUTCTime(minDate) ? recordDate : minDate;
        });

        const millisecondsInAMonth = 2592000000;
        const months = Math.ceil((getUTCTime(new Date()) - minDate.valueOf()) / millisecondsInAMonth) + 1;
        const winMonths = months > 7 ? months : 7;
        // Get total closed deals vs only the won ones
        const won = data.filter(x => (x.IsWon ? true : false));

        const preparedWons = prepare(winMonths, won);
        const preparedTotal = prepare(winMonths, data);

        return {preparedWons, preparedTotal, amountWon: won.length, amountTotal: data.length};
      });
  }

  static getInstance() {
    return axios
      .get('/api/providers/sfdc/instance')
      .then(response => response.data)
      .catch(error => {
        console.log(error);
      });
  }

}

export default SFDCQuery;
