import Icon from '../../components/_icon';

import {pluralize} from '../text_utils';
import {NOTIFICATIONS_FETCH_INTERVAL} from './api';

import moment from 'moment';

export const dimensions = Object.freeze({
  navHeight: 44,
  tocItemHeight: 45,                    // 44px + 1px bottom border
  tocEditHeight: 30,
  dashboardCardWidthInner: 212,
  dashboardCardMobileWidthInner: 380,
  dashboardCardHeight: 221,             // average height, not specific to any resolution
  dashboardCardHeightInner: 166,
  dashboardCardMobileHeight: 55,        // collapsed card view <=520px
  dashboardCardMobileHeightInner: 44,
  dashboardCardTopPaddingBorders: 22
});

export const breakpoints = Object.freeze({
  dashboardMobileMax: 520
});

export const uiDefaultTypes = Object.freeze({
  DASHBOARD: 'dashboard',
  TOC: 'toc'
});

export const uiViewports = Object.freeze({
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile'
});

export const uiColours = Object.freeze({
  // placeholder defaults
  placeholderPrimary: '#f3f3f3',
  placeholderSecondary: '#e9e9e9'
});

export const uiMessages = ({id, messageData = {}}) => {
  // NOTE: assumes that NOTIFICATIONS_FETCH_INTERVAL is < 60 minutes
  const notificationsInterval = moment.duration(NOTIFICATIONS_FETCH_INTERVAL);
  const refreshInterval = NOTIFICATIONS_FETCH_INTERVAL >= 60000 ?
  `${notificationsInterval.minutes()} ${pluralize('minute', notificationsInterval.minutes())}` :
  `${notificationsInterval.seconds()} ${pluralize('second', notificationsInterval.seconds())}`;
  const messages = {
    userIsRestricted: {
      title: 'Whoops! 😬',
      message: 'You\'re trying to view a Klue URL that isn\'t available. Please contact your Klue admin if you need help.',
      isError: true
    },
    boardNotFound: {
      title: 'Whoops! 😬',
      message: 'You\'re trying to view a board that doesn\'t exist. Please contact your Klue admin if you need help.',
      isError: true
    },
    battlecardNotFound: {
      title: 'Whoops! 😬',
      message: 'You\'re trying to view a battlecard that doesn\'t exist. Please contact your Klue admin if you need help.',
      isError: true
    },
    newVersion: {
      title: 'Klue update available! 🙌',
      message: (
        <div>
          <p>
            Please save your changes and click <b>Reload</b> below to update the app now. Otherwise, we&apos;ll auto-reload it
            for you in {refreshInterval}.
          </p>
          <p>Enjoy! 👌</p>
          <div className="toast-message_buttons">
            <a className="button button--small" onClick={() => window.location.reload()}>Reload Klue</a>
          </div>
        </div>
      )
    },
    signedIn: {
      title: 'Welcome back! 👋',
      message: (
        <div>
          <p>Been a while?</p>
          <p>Check out our <a href={`https://${messageData.helpDocsHost || 'help.app.klue.com'}`}
            target="_blank">FAQs and guides</a>.</p>
        </div>
      )
    },
    compromisedPassword: {
      title: 'Compromised password ️😬',
      message: (
        <div>
          <p>Global experts publish “bad” passwords that are known to hackers. <b>Your
            Klue password happens to be on these lists</b>. We recommend <a href="/users/me" target="_blank">updating
              your password</a> immediately to maintain your online safety.
          </p>
          <p><a href={`https://${messageData.helpDocsHost || 'help.app.klue.com'}/article/shcijj9t5g-how-do-i-set-up-a-secure-password`}
            target="_blank"
            className="learn-more">Learn more</a></p>
        </div>
      ),
      isError: true
    },
    commentsClosed: {
      slack: {
        message: (<><Icon icon="block" className="comments-closed-icon" /><p><b>Adding comments in Klue is disabled. <a href={messageData.permalink} rel="noreferrer nofollow" target="_blank">
          Continue the conversation in Slack</a>.</b> Resend the thread to Klue to add additional comments.
        </p></>)
      }
    }
  };

  return {id, ...messages[id]} || {id};
};

export const uiMessageTypes = Object.freeze({
  NOTICE: 'Notice',
  ALERT: 'Alert'
});

export const truncateLimits = Object.freeze({
  searchResultsMobileMenu: 33,
  alerts: 255,
  alertTitle: 65,
  attachmentSummary: 333,
  commentBody: 333,
  digestSummary: 600,
  postLikes: 7,
  postCompetitors: 8
});

export const uiDelays = Object.freeze({
  toastError: 10000,
  profileLaneToggle: 160,
  dashboardScrollOnNewCompany: 500,
  alertsBulkDeleteModalDismiss: 10000
});

export const uiMaxResults = Object.freeze({
  MAX_NAV_RESULTS: 10,
  MAX_RECENTLY_VIEWED_PROFILES: 10
});

// dashboard column breakpoints strictly for placing tiles in IE11
export const uiDashboardColsMSIE = Object.freeze({
  BREAKPOINT_1280: 6,
  BREAKPOINT_1024: 5,
  BREAKPOINT_680: 4,
  BREAKPOINT_520: 3,
  BREAKPOINT_400: 1
});

export const BATTLE_CARD_LAYOUT = Object.freeze({
  single: 'single',
  stacker: 'stacker',
  hero: 'hero',
  masonry: 'masonry'
});

export const customEvents = Object.freeze({
  onEditCard: 'onEditCard'
});

export const rivalsCategory = Object.freeze({
  company: 'company',
  product: 'product',
  other: 'other'
});
