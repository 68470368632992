import {useContext, useMemo} from 'react';
import className from 'classnames';

import Icon from '../_icon';
import {TagErrorsContext} from '.';

export const testIds = {
  deleteLink: 'delete-link'
};

const Synonym = ({name, onDelete}) => {
  const lowercaseName = name?.toLowerCase();
  const {highlights} = useContext(TagErrorsContext);
  const isHighlighted = useMemo(() => {
    return Object.values(highlights).some(highlight => highlight?.conflictingName?.toLowerCase() === lowercaseName);
  }, [highlights, name]);

  return (
    <div className="synonym-container">
      <span className={className('synonym-text', {highlighted: isHighlighted})}>{name}</span>
      <a title="Delete" className="item-list-icon" data-testid={`${testIds.deleteLink}-${name}`} onClick={onDelete}>
        <Icon icon="close" width="16" height="16" />
      </a>
    </div>
  );
};

export default Synonym;

Synonym.propTypes = {
  name: PropTypes.string,
  onDelete: PropTypes.func
};

Synonym.defaultProps = {
  name: '',
  onDelete() {}
};
