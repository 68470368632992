import {update} from '../modules/api_utils';

export const alertMessageForPublishedBattlecard = (title, published) => {
  const pStyle = 'font-style: normal; font-weight: 400; font-size: 18px;';

  return published ?
    `<strong>"${title}"</strong> is now available to your Consumer users.<br /><br />
    <i style="${pStyle}"}>Users whose visibility permissions match those of the Cards in this Battlecard
    will be able to see this Battlecard.</i>` :
      `<strong>"${title}"</strong> is now hidden from your Consumer users.<br /><br />
      <i style="${pStyle}"}>Card visibility permissions haven't changed, those that were
      visible in other Battlecards and this Board remain visible.</i>`;
};

export const getBulkEditBattlecardAlertMessage = () => {
  return `<strong>Are you sure you want to mark all stale battlecards on the boards you curate as reviewed?</strong><br />
  <br />This can't be undone.`;
};

export const getSingleBattlecardAlertMessage = battlecard => {
  return `<strong>Marked "${battlecard.title || '(Untitled Battlecard)'}" as reviewed!</strong><br /><br />
  Your team members can now see that this battlecard is up-to-date.`;
};

export const battlecardReviewedAlert = ({
  battlecards,
  context,
  isBulkEdit = false,
  onMarkBattlecardsAsReviewed,
  onWillUpdateBattlecardCards,
  onDidUpdateBattlecardCards
}) => {
  const {utils: {dialog, user} = {}, api: {userUpdate} = {}} = context || {};
  const {userData, id: reviewerId} = user || {};

  if(!battlecards || !battlecards.length || !reviewerId) {
    return;
  }

  const {markBattlecardCardsAsReviewed = false} = userData || {};
  const checkRef = React.createRef();
  const hideCancel = !isBulkEdit;
  const message = isBulkEdit
    ? getBulkEditBattlecardAlertMessage()
    : getSingleBattlecardAlertMessage(battlecards[0]);
  const checkboxLabel = `Mark all Cards on ${isBulkEdit ? 'the Battlecards' : 'this Battlecard'} as reviewed too`;

  const bodyContent = (
    <div className="mark-cards-as-reviewed-checkbox-container" style={{display: 'flex', alignItems: 'center'}}>
      <input
        ref={checkRef}
        className="mark-cards-as-reviewed-checkbox-input"
        type="checkbox"
        defaultChecked={markBattlecardCardsAsReviewed}
        id="mark-cards-as-reviewed-checkbox"
        style={{margin: 0, padding: 0}} />
      <label
        className="mark-cards-as-reviewed-checkbox-label"
        htmlFor="mark-cards-as-reviewed-checkbox"
        style={{margin: 0, padding: 0, fontSize: 14, fontWeight: 'normal', marginLeft: 5}}>{checkboxLabel}</label>
    </div>
  );

  dialog.confirm({
    message,
    hideCancel,
    bodyContent,
    okCallback() {
      if(typeof onMarkBattlecardsAsReviewed === 'function') {
        onMarkBattlecardsAsReviewed();
      }

      const checked = checkRef?.current?.checked;
      const updateBattlecardCards = ({userUpdateError} = {}) => {
        if(checked) {
          if(typeof onWillUpdateBattlecardCards === 'function') {
            onWillUpdateBattlecardCards();
          }

          const ids = new Set();

          battlecards.forEach(battlecard => {
            const cards = battlecard?.cards?.desktop || [];

            cards.forEach(cardId => {
              if(!cardId) {
                return;
              }

              ids.add(cardId);
            });
          });

          const cardIds = [...ids];

          update('/api/cards.json', {
            cardIds,
            reviewerId,
            keepUpdatedAt: true
          }, {code: 'BattlecardUtils.battlecardReviewedAlert'}).then(({data: updatedCards}) => {
            if(typeof onDidUpdateBattlecardCards === 'function') {
              onDidUpdateBattlecardCards({userUpdateError, cardIds, updatedCards});
            }
          }).catch(error => {
            if(typeof onDidUpdateBattlecardCards === 'function') {
              onDidUpdateBattlecardCards({error, userUpdateError, cardIds});
            }
          });
        }
      };

      if(checked !== markBattlecardCardsAsReviewed) {
        const userOptions = {
          id: reviewerId,
          featureFlag: ['markBattlecardCardsAsReviewed', checked]
        };

        userUpdate(userOptions).then(() => {
          updateBattlecardCards();
        }).catch(err => {
          // even if there's an error updating the user checkbox state, we'll try to update the cards
          console.log(err);
          updateBattlecardCards({userUpdateError: err});
        });
      }
      else {
        updateBattlecardCards();
      }
    }
  });
};

export const battlecardReviewedAlertCardErrorMessage = `<strong>Card Update Error!</strong><br /><br />
An error occurred while trying to updated the reviewed status of cards. Please try again.`;

