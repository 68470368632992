import {useEffect} from 'react';
import AppBase from './_app_base';
import CardRedirector from './_card_redirector';
import LoginEmbedRedirect from './_login_embed_redirect';

import history from '../modules/routing/history';
import {klueIsInIframe, getLastKnownLocation, setLastKnownLocation} from '../modules/route_utils';
import createStore from '../redux/createStore';

// react-router
import {Router, Route, Switch} from 'react-router-dom';

// redux
import {Provider} from 'react-redux';
import {QueryParamProvider} from 'use-query-params';

const store = createStore();

const AppRouter = ({appData}) => {
  let isRedirecting = false;

  useEffect(() => {
    const {src} = appData;

    if(klueIsInIframe(window) && src) {
      const lastKnownLocation = getLastKnownLocation(src);

      history.listen(() => setLastKnownLocation(src));

      if(lastKnownLocation && lastKnownLocation !== window.location.href) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isRedirecting = true;

        return window.location.replace(lastKnownLocation);
      }
    }
  }, [appData]);

  return !isRedirecting ? (
    <Provider store={store}>
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route
              path="/embed-signin-complete"
              render={props => (<LoginEmbedRedirect appData={appData} {...props} />)} />
            <Route
              path="/card/:cardId"
              exact={true}
              render={props => (<CardRedirector appData={appData} {...props} />)} />
            <Route
              path="*"
              render={props => (<AppBase appData={appData} {...props} />)} />
          </Switch>
        </QueryParamProvider>
      </Router>
    </Provider>
  ) : null;
};

AppRouter.propTypes = {
  appData: PropTypes.object
};

AppRouter.defaultProps = {
  appData: {}
};

export default AppRouter;
