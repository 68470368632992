class TextareaAutoSize extends React.Component {

  static propTypes = {
    commentId: PropTypes.number,
    commentText: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    handleResize: PropTypes.func,
    canUseForm: PropTypes.bool
  };

  static defaultProps = {
    commentId: null,
    commentText: '',
    placeholder: '',
    className: '',
    onChange() {},
    handleResize: null,
    canUseForm: true
  };

  componentDidMount() {
    // autofocus textarea
    this.refs.textarea.focus();

    // event subscriptions
    klueMediator.subscribe(this._syncChannel('reset'), this.handleChange);

    if(this.props.commentText !== '') {
      this.handleChange();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.commentText !== this.refs.textarea.value) {
      this.refs.textarea.value = nextProps.commentText;

      this.handleChange();
    }
  }

  componentWillUnmount() {
    klueMediator.remove(this._syncChannel('reset'));
  }

  _syncChannel = action => {
    return 'klue:comment:textarea:' + (this.props.commentId || 0) + (action ? ':' + action : '');
  };

  getText = () => {
    return this.refs.textarea.value;
  };

  handleChange = () => {
    const textarea = this.refs.textarea;

    textarea.style.height = '5px';
    textarea.style.height = (textarea.scrollHeight + 10) + 'px';

    if(this.props.handleResize) {
      this.props.handleResize();
    }
  };

  render() {
    const isRenderDisabled = !this.props.canUseForm ? 'disabled' : null;

    return (
      <textarea
        ref="textarea"
        onKeyUp={this.handleChange}
        defaultValue={this.props.commentText}
        onChange={this.props.onChange}
        className={this.props.className}
        autoFocus={true}
        placeholder={this.props.placeholder}
        disabled={isRenderDisabled} />
    );
  }

}

export default TextareaAutoSize;
