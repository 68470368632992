import {Fragment} from 'react';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';

import {SUBMISSION_FAILED_MESSAGE} from '../../modules/constants/new_board_wizard';

class Wizard extends React.PureComponent {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    nextValues: PropTypes.object || PropTypes.null,
    onClose: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    children: PropTypes.node,
    isBoardReady: PropTypes.bool.isRequired,
    onCreateAutomatedCards: PropTypes.func.isRequired,
    selectedAutomatedCards: PropTypes.array
  };

  static defaultProps = {
    nextValues: {},
    initialValues: {},
    children: null,
    isBoardReady: false,
    onCreateAutomatedCards() {},
    selectedAutomatedCards: []
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {}
    };
  }
  
  componentDidMount() {
    // Check if this.props has 'nextValues' key
    const hasNextValues = 'nextValues' in this.props;

    if(hasNextValues && this.props.isBoardReady && this.props.nextValues !== null) {
      this.updatePageAndValues(this.props);
    }
  }

  updatePageAndValues = props => {
    this.setState(state => ({
      page: Math.min(state.page + 1, props.children.length - 1),
      values: {...props.nextValues}
    }));
  };

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = values => {
    const {onSubmit, onCreateAutomatedCards, isBoardReady} = this.props;
    const {boardName, companyUrl, boardType, logoUrl, template, automatedCards: cards, groups, profileId} = values;

    const {profileId: copyFromId} = template || {};
    const rivalGroupsIds = Array.isArray(groups) ? groups.map(group => group.id) : null;
    const automatedCardIds = ((boardType?.id.toLowerCase() !== 'other') && Array.isArray(cards)) ? cards.map(card => card.id) : [];

    const rivalOptions = {
      name: boardName,
      url: companyUrl,
      category: boardType?.id.toLowerCase(),
      withMetabaseSubscription: true,
      imageUrl: logoUrl
    };

    if(rivalGroupsIds) {
      rivalOptions.rivalGroups = rivalGroupsIds;
    }

    if(copyFromId) {
      rivalOptions.copyFromId = copyFromId;
    }

    if(automatedCardIds.length > 0) {
      return onCreateAutomatedCards(automatedCardIds, profileId).catch(() => {
        return {[FORM_ERROR]: SUBMISSION_FAILED_MESSAGE};
      });
    }

    if(!isBoardReady) {
      return onSubmit(rivalOptions)
        .catch(() => {
        //TODO: we could parse the BE error here and show a more accurate error
          return {[FORM_ERROR]: SUBMISSION_FAILED_MESSAGE};
        });
    }
  };

  render() {
    const {children, onClose, isBoardReady, selectedAutomatedCards} = this.props;
    const {page, values} = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = isBoardReady;

    return (

      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}>
        {({handleSubmit, values: formValues, errors, submitting, form}) => {
          const hasError = Object.keys(errors).length !== 0;

          return (
            <form onSubmit={handleSubmit} className="wizard">
              {activePage}
              <div className="wizard-navigation">
                {
                  !isLastPage ?
                    <Fragment>
                      {((formValues.boardType && formValues.boardName) || formValues.boardType?.id === 'other') && <button
                        className="btn wizard-navigation_button wizard-navigation_button--secondary"
                        type="button"
                        disabled={hasError || submitting}
                        onClick={() => form.submit()}>
                        <b>Create new board</b>
                      </button>}
                      <button
                        className="btn wizard-navigation_button wizard-navigation_button--cancel"
                        type="button"
                        onClick={event => {
                          event.preventDefault();
                          event.persist();
                          onClose();
                        }}>
                        <b>CANCEL</b>
                      </button>
                    </Fragment>
                    : <Fragment>
                      <button
                        className="btn btn-success wizard-navigation_button"
                        type="submit"
                        disabled={selectedAutomatedCards?.length === 0 || hasError || submitting}>
                        Add Cards Now
                      </button>
                      <button
                        className="btn wizard-navigation_button wizard-navigation_button--cancel"
                        type="button"
                        onClick={event => {
                          event.preventDefault();
                          event.persist();
                          onClose();
                        }}>
                        <b>All Done - Exit</b>
                      </button>
                    </Fragment>
                }
              </div>
            </form>
          );
        }}
      </Form>
    );
  }

}
export default Wizard;
