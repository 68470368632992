/* We'd hope to use the prop-types module for this but it's very
 * react-oriented and gets disabled in production. We want these
 * validators to work all the time. */

const string = {
  name: 't_string',
  validate: v => typeof v === 'string'
};

const number = {
  name: 't_number',
  validate: v => typeof v === 'number'
};

const bool = {
  name: 't_boolean',
  validate: v => typeof v === 'boolean'
};

// optionOf can be either an instance of the specified type or null
const optionOf = elementType => ({
  name: `t_option<${elementType.name}>`,
  validate(a) {
    if(a === null) {
      return true;
    }

    return elementType.validate(a);
  }
});

// Any value will satisfy an any constraint. Yup, that simple.
const any = {
  name: 't_any',
  validate: () => true
};

const arrayOf = elementType => ({
  name: `t_array<${elementType.name}>`,
  validate(a) {
    if(!Array.isArray(a)) {
      return false;
    }

    if(a.elementType === undefined) {
      console.warn('Array was not created with Types.arrayOf.create');
    }

    if(elementType === any) {
      return true;
    }

    if(a.elementType !== elementType.name) {
      return false;
    }

    return a.every(e => elementType.validate(e));
  },
  create(elems) {
    const out = [...elems];

    out.forEach(e => {
      if(!elementType.validate(e)) {
        throw new Error(`Element does not match array type. ${e}`);
      }
    });

    out.elementType = elementType.name;

    return out;
  }
});

const mapOf = (keyType, valueType) => ({
  name: `t_map<${keyType.name},${valueType.name}>`,
  validate(a) {
    if((keyType !== any && a.keyType !== keyType.name)
      || (valueType !== any && a.valueType !== valueType.name)) {
      return false;
    }

    if(!a.pairs) {
      return false;
    }

    return a.pairs.every(pair => keyType.validate(pair.key) && valueType.validate(pair.value));
  },
  create(pairs) {
    return {
      keyType: keyType.name,
      valueType: valueType.name,
      pairs: pairs.map(pair => {
        if(!keyType.validate(pair.key)) {
          throw new Error(`Key does not match map type. ${JSON.stringify(pair.key)}`);
        }

        if(!valueType.validate(pair.value)) {
          throw new Error(`Value does not match map type. ${JSON.stringify(pair.value)}`);
        }

        return {
          key: pair.key,
          value: pair.value
        };
      })
    };
  }
});

function generateShapeName(obj) {
  const props = Object.keys(obj);

  props.sort();

  let first = true;
  let propString = '';

  props.forEach(p => {
    if(!obj[p] || !obj[p].name) {
      throw new Error(`Invalid type for shape property "${p}". Found type: ${typeof obj[p]}`);
    }

    if(first) {
      first = false;
    }
    else {
      propString += ',';
    }

    propString += `${p}:${obj[p].name}`;
  });

  return `t_shape<${propString}>`;
}

const shape = obj => ({
  name: generateShapeName(obj),
  validate(o) {
    if(o === undefined || o === null) {
      return false;
    }

    const props = Object.keys(obj);

    return props.every(p => obj[p].validate(o[p]));
  },
  create(value) {
    let result = {};

    Object.keys(obj).forEach(propName => {
      const propValue = value[propName];

      if(!obj[propName].validate(propValue)) {
        throw new Error(`Value does not satisfy shape for property ${propName} of type ${obj[propName].name}`);
      }

      result = Object.assign(result, {[propName]: propValue});
    });

    return result;
  }
});

export const typesEqual = (t1, t2) => t1 && t2 && t1.name === t2.name;

export default {
  string,
  number,
  bool,
  any,
  optionOf,
  arrayOf,
  mapOf,
  shape
};
