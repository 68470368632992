import * as KlueTypes from '../../klue_types';
import {fetchKlueData} from './data_providers';

export const homepageScreenshot = async (company, klueDataFetcher = fetchKlueData) => {
  if(!KlueTypes.Company.validate(company)) {
    console.log('Unexpected parameter type.', company);
    throw new Error('Parameter was unexpected type.');
  }

  const data = await klueDataFetcher(company.info);

  return KlueTypes.Image.create({
    imageSrcUrl: data.screenshotUrl,
    alt: `Screenshot of ${company.info}`
  });
};
