import classNames from 'classnames';

import BoardNameAndLogoFormFields from './_board_name_and_logo_form_fields';
import InputSelectorGroup from './_input_selector_group';
import FormToggleSwitch from './_form_toggle_switch';

const CardVitalsSettingsFormBody = ({
  rival,
  rivals,
  curatorsGroup,
  dashboardGroups,
  expanded,
  onAddNewGroup,
  onUploadingStatusChange,
  values
}) => {
  const {logoUrl, useAsTemplate, wideLanes} = values;

  return (
    <div className={classNames('form-body', {expanded})}>
      <div className={classNames('form-settings', {expanded})}>
        <BoardNameAndLogoFormFields
          type={rival.category}
          rival={rival}
          logoUrl={logoUrl}
          rivals={rivals}
          showBoardTypeInfo={true}
          onUploadingStatusChange={onUploadingStatusChange} />
        <InputSelectorGroup
          title="Curators"
          titleDataTrackingId="card-vital-settings-curator-input-selector-group"
          name="curators"
          label="Curators"
          type="checkbox"
          unassignedLabel="All Admins"
          groups={curatorsGroup} />
        <InputSelectorGroup
          title="Groups (e.g. Dashboard views)"
          titleDataTrackingId="card-vital-settings-groups-input-selector-group"
          name="groups"
          label="Groups"
          type="checkbox"
          groups={dashboardGroups}
          onAddNewItem={onAddNewGroup}
          addNewPlaceholder="New group name..." />
        <FormToggleSwitch
          name="useAsTemplate"
          label="Use as a template for new boards?"
          titleDataTrackingId="card-vitals-use-as-template-title"
          initialValue={useAsTemplate} />
        <FormToggleSwitch
          name="wideLanes"
          label="Use wide lanes?"
          titleDataTrackingId="card-vitals-use-wide-lanes-title"
          initialValue={wideLanes} />
      </div>
    </div>

  );
};

CardVitalsSettingsFormBody.propTypes = {
  rival: PropTypes.object,
  rivals: PropTypes.arrayOf(PropTypes.object),
  curatorsGroup: PropTypes.arrayOf(PropTypes.object),
  dashboardGroups: PropTypes.arrayOf(PropTypes.object),
  expanded: PropTypes.bool,
  onAddNewGroup: PropTypes.func,
  onUploadingStatusChange: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

CardVitalsSettingsFormBody.defaultProps = {
  rival: null,
  rivals: [],
  curatorsGroup: null,
  dashboardGroups: null,
  expanded: false,
  onAddNewGroup() {},
  onUploadingStatusChange() {},
  values: {}
};

export default CardVitalsSettingsFormBody;
