import ScratchpadItem from './_scratchpad_item';

import {Link} from 'react-router-dom';

class Scratchpad extends React.Component {

  static contextTypes = {
    appData: PropTypes.object.isRequired
  };

  static propTypes = {
    profileName: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.object),
    onUpdateComment: PropTypes.func.isRequired,
    onDismissComment: PropTypes.func.isRequired,
    onGetCommentSourceLink: PropTypes.func
  };

  static defaultProps = {
    profileName: '',
    comments: [],
    onUpdateComment() {},
    onDismissComment() {},
    onGetCommentSourceLink() {}
  };

  componentDidMount() {
    // DEBUG
    console.log('Scratchpad.componentDidMount: props: %o', this.props);
  }

  render() {
    const {v2Host} = this.context.appData;
    const {comments, onDismissComment, onGetCommentSourceLink, onUpdateComment, profileName} = this.props;
    let commentNodes = comments.map((comment, index) => {
      const {sourceData} = comment || {};
      const {name: sourceName, permalink} = sourceData || {};

      return (
        <ScratchpadItem
          key={`item_${comment.id}`}
          comment={comment}
          isNew={comment.isNew && !index}
          onUpdateComment={onUpdateComment}
          onDismissComment={onDismissComment}
          onGetCommentSourceLink={permalink ? () => ({label: `${sourceName} post`, link: permalink}) : onGetCommentSourceLink} />
      );
    });

    if(!comments.length) {
      commentNodes = (
        <div className="card-block">
          <h5 className="card-subtitle">This is your <strong>Scratchpad</strong>: a holding zone for your cards.</h5>
          <h5 className="card-subtitle">
            Comments made in the&nbsp;
            <Link to="/feed"><strong>Feed</strong></Link> or&nbsp;
            <a href={`//${v2Host}/integrations`}><strong>Klue extension</strong></a> and sent to the&nbsp;
            <strong>{profileName}</strong> board will appear here.
          </h5>
        </div>
      );
    }
    else {
      commentNodes = (
        <div className="scratchpad-item-list">
          {commentNodes}
        </div>
      );
    }

    return commentNodes;
  }

}

export default Scratchpad;
