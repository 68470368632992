import {combineReducers} from 'redux';

import cache, * as fromCache from './cache';

const providersApp = combineReducers({
  cache
});

export default providersApp;
export const getCacheValue = (state, key) => fromCache.getCacheValue(state.cache, key);
