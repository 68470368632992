import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {OrderedSet} from 'immutable';

import OperatingMetricDropdown from './_operating_metric_dropdown';

class OperatingMetricDropdownContainer extends React.Component {

  static propTypes = {
    companyId: PropTypes.string.isRequired,
    selectedMetric: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    didCraftDataLoadFinish: PropTypes.func.isRequired,
    getCraftData: PropTypes.func.isRequired,
    onCraftDataNeeded: PropTypes.func.isRequired
  };

  static defaultProps = {
    companyId: '0',
    selectedMetric: '',
    onChange() {},
    didCraftDataLoadFinish() {},
    getCraftData() {},
    onCraftDataNeeded() {}
  };

  state = {
    selectedMetric: this.props.selectedMetric
  };

  componentDidMount = () => {
    this.selectDefaultMetric();
  };

  componentDidUpdate = prevProps => {
    // Handle the case where a new prefered metric is pushed from above
    if(prevProps.selectedMetric !== this.props.selectedMetric) {
      this.setState({selectedMetric: this.props.selectedMetric});

      return;
    }

    this.selectDefaultMetric();
  };

  selectDefaultMetric = () => {
    if(this.state.selectedMetric !== '') {
      return;
    }

    const metrics = this._getOperatingMetrics();

    if(metrics === null) {
      this._fetchCraftData();

      return;
    }

    // Select the first metric by default when they are first loaded
    if(metrics.length > 0) {
      this.setState({selectedMetric: metrics[0]}, () => {
        this._fireOnChange();
      });
    }
  };

  _fetchCraftData = () => {
    const {
      companyId,
      onCraftDataNeeded
    } = this.props;

    onCraftDataNeeded(companyId);
  };

  _getOperatingMetrics = () => {
    const {companyId, didCraftDataLoadFinish, getCraftData} = this.props;

    if(!didCraftDataLoadFinish(companyId)) {
      return null;
    }

    const craftData = getCraftData(companyId);

    if(!craftData || !craftData.has('operatingMetrics')) {
      return [];
    }

    return craftData.get('operatingMetrics').reduce((prevSet, curMetricData) => {
      const metricLabel = curMetricData.get('companySpecificKpi');

      return prevSet.add(metricLabel);
    }, OrderedSet()).sort().toArray();
  };

  _handleSelectChanged = metric => {
    this.setState({selectedMetric: metric}, () => {
      this._fireOnChange();
    });
  };

  _fireOnChange = () => {
    this.props.onChange(this.state.selectedMetric);
  };

  render() {
    const metrics = this._getOperatingMetrics();

    if(metrics === null) {
      return (
        <OperatingMetricDropdown loading={true} />
      );
    }

    return (
      <OperatingMetricDropdown
        onChange={this._handleSelectChanged}
        metrics={metrics}
        selectedMetric={this.state.selectedMetric} />
    );
  }

}

const mapStateToProps = ({craft}) => ({
  didCraftDataLoadFinish: companyId => craft.didCraftDataLoadFinish(companyId),
  getCraftData: companyId => craft.getCraftData(companyId)
});

const mapDispatchToProps = dispatch => ({
  onCraftDataNeeded: companyId => dispatch.craft.fetchCraftData(companyId)
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatingMetricDropdownContainer);
