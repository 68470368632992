import Dropdown from './_dropdown';
import CheckboxItemFactory from './_checkbox_item_factory';
import SearchResults from './_search_results';
import SearchComparison from './_search_comparison';
import CardMetaLink from './card_templates/_card_meta_link';
import {rivalsGet} from '../modules/api/rivals';
import {tagsGet} from '../modules/api/tags';
import {analyticsTrack, SNOWPLOW_SCHEMAS} from '../modules/analytics_utils';
import {maybeLaunchModalCardView} from '../modules/card_utils';
import {fetchCardSources} from '../modules/api/sources';
import {pluralize} from '../modules/text_utils';
import {userCanCurate} from '../modules/roles_utils';
import {redirectConsumersToV2} from '../modules/route_utils';
import CardMeta from './card_templates/_card_meta';

import classNames from 'classnames';
import update from 'immutability-helper';
import {Link, withRouter} from 'react-router-dom';
import {getSearchPathFromQuery} from '../utils/_search_utils';
import {isEqual} from 'lodash';
import queryString from 'query-string';

class SearchQuery extends React.Component {

  static contextTypes = {
    appData: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object,   // {query: 'THE_QUERY'}
    user: PropTypes.object,
    rivals: PropTypes.objectOf(PropTypes.object)
  };

  static defaultProps = {
    history: {},
    location: {},
    match: {},
    user: null,
    rivals: {}
  };

  constructor(props) {
    super(props);

    this.searchSequenceNumber = 0;

    const {
      rivals,
      user
    } = this.props;

    const query = this.getSearchQueryFromProps();

    this.state = {
      ...SearchQuery.baseState,
      currentRivals: rivals,
      query
    };

    this.isCurator = userCanCurate({user});
  }

  getNextSearchSequenceNumber = () => ++this.searchSequenceNumber;

  componentDidMount() {
    this._isMounted = true;
    this.registerLinksListenerEvent();

    let v2Search = '';

    const {location: {pathname, state, search}} = this.props;
    const {appData: {v2Host}, utils: {user, company}} = this.context;

    if(state?.tagClickedEvent?.id) {
      const params = new URLSearchParams(search);

      params.set('tags', state.tagClickedEvent.id);

      v2Search = `?${params.toString()}`;
    }

    redirectConsumersToV2({v2Host, v2Path: pathname, v2Search, user, company});

    if(!this.cardTagIsVisible()) {
      return this.startNewSearchGroup();
    }

    this.getTags(() => this.setSelectedTagsFromTagClickedEventAndSearch());

    // Disabled for now! -> See https://github.com/kluein/klue/issues/4927
    // suggestedQueriesGet(this.state.query).then(suggestedQueries => this.setState({suggestedQueries}));
  }

  componentDidUpdate() {
    this.registerLinksListenerEvent();

    const {query} = this.state;
    const nextQuery = this.getSearchQueryFromProps();
    const tagClickedEvent = this.getTagClickedEventFromProps();

    if(query !== nextQuery || (tagClickedEvent && !tagClickedEvent.fromQueryParam)) {
      this.searchAgain(query, nextQuery, tagClickedEvent);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    const {searchQueryRef: element} = this;

    element && element.removeEventListener('click', this.handleCardLinksListener, true);
  }

  cardTagIsVisible = () => Boolean(this.context?.utils?.cardTagIsVisible());

  registerLinksListenerEvent = () => {
    const {searchQueryRef: element} = this;

    element && element.addEventListener('click', this.handleCardLinksListener, {capture: true});
  };

  setSelectedTagsFromTagClickedEventAndSearch = () => {
    const {tags} = this.state;

    if(tags) {
      const tagClickedEvent = this.getTagClickedEventFromProps();

      if(tagClickedEvent) {
        this.setTrackTagClickedEvent();

        this.removeTagClickedEventFromLocationState();

        // eslint-disable-next-line eqeqeq
        const selectedTag = tags.find(({id}) => id == tagClickedEvent.id);

        if(selectedTag) {
          this.startNewSearchGroup({
            showTagFilter: true,
            selectedTags: {[selectedTag.name]: selectedTag}
          });

          return;
        }
      }
    }

    this.startNewSearchGroup();
  };

  getTagClickedEventFromProps = () => {
    const {location} = this.props;
    const {state: {tagClickedEvent} = {}} = location;
    const query = queryString.parse(location.search);

    if(query.tag) {
      return {
        id: query.tag,
        fromQueryParam: true
      };
    }

    return tagClickedEvent;
  };

  removeTagClickedEventFromLocationState = () => {
    const {history, location: {state = {}}} = this.props;

    if(state.tagClickedEvent) {
      delete state.tagClickedEvent;
      history.replace(undefined, state);
    }
  };

  getSearchQueryFromProps = () => {
    const {match: {params: {query = ''}}} = this.props;

    return decodeURIComponent(query).trim();
  };

  /**
   * Detect internal card links
   * Redirect to the target card profile page
   *
   * @param {object} event Mouse click
   * @memberof BattlecardView
   * @returns {undefined}
   */
  handleCardLinksListener = event => {
    const {target} = event;

    if(!target.closest('.search-result-card-content')) {
      return;
    }

    const {history} = this.props;

    maybeLaunchModalCardView(event, history);
  };

  static updatedAtFilters = [
    {label: 'TODAY', value: 'now-1d'},
    {label: 'PAST WEEK', value: 'now-1w'},
    {label: 'PAST MONTH', value: 'now-1M'},
    {label: 'PAST 6 MONTHS', value: 'now-6M'}
  ];

  static baseState = {
    query1: '',                  // the first query
    query2: '',                  // the second query
    cards1: null,                // loaded cards for query 1
    cards2: null,                // loaded cards for query 2
    cardsCount1: 0,              // total nr of matching cards for query 1
    cardsCount2: 0,              // total nr of matching cards for query 2
    offset: 0,                   // offset for pagination
    showingMore: false,          // set to true when SHOW MORE was clicked to prevent multiple SHOW MORES
    matchingRivals: {},          // rivals associated with matching cards
    matchingTags: {},            // Tags associated with matching cards
    selectedRivals: {},          // rivals selected for filtering
    selectedTags: {},            // tags selected for filtering
    loaded: false,               // true if search has been finished
    showCompanyFilter: false,    // if true, rival filters are rendered
    showTagFilter: false,        // if true, tag filters are rendered
    updatedAtFilter: null,       // selected updatedAt filter ['TODAY', 'PAST WEEK', 'PAST MONTH', 'PAST 6 MONTHS']
    showUpdatedAtFilter: false,  // if true, date ranges for updatedAt filter are rendered
    compare: false,              // if true, comparison/split view is active
    suggestedQueries: [],        // suggested search queries
    rivalFilter: true,           // if true, rival filters are auto-extracted from query
    showMoreLabel: {value: 'SHOW MORE', label: 'Show more', loadingLabel: 'Loading...'},
    sources: new Map(),
    loadingSources: new Map(),
    cardSourceId: null
  };

  setTrackTagClickedEvent = () => {
    const {location: {state = {}}} = this.props;
    const {tagClickedEvent} = this.state;
    const tag = state?.tagClickedEvent;

    if(!tag?.id) {return;}

    if(tagClickedEvent?.id === tag.id) {return;}

    this.setState({trackTagClickedEvent: tag});
  };

  retriveTrackTagClickedEvent = () => {
    const {trackTagClickedEvent} = this.state;

    if(!trackTagClickedEvent) {return null;}

    this.setState({trackTagClickedEvent: null});

    return trackTagClickedEvent;
  };

  getTrackSearchEventType = () => {
    const {selectedRivals, selectedTags, updatedAtFilter} = this.state;

    const tagClickedEvent = this.retriveTrackTagClickedEvent();

    const isRivalsSelected = Object.keys(selectedRivals).length > 0;
    const isTagsSelected = Object.keys(selectedTags).length > 0;
    const isUpdateAtFilterSelected = Boolean(updatedAtFilter);

    if(tagClickedEvent?.id) {
      return tagClickedEvent?.origin === 'search_suggestion' ? 'search_bar' : 'tag';
    }

    if(isRivalsSelected || isTagsSelected || isUpdateAtFilterSelected) {return 'filter';}

    return 'search_bar';
  };

  getTrackSearchTerm = () => {
    const {query1, query2, compare} = this.state;

    const type = this.getTrackSearchEventType();

    if(type === 'tag') {return null;}

    const query = compare ? `${query1} vs. ${query2}` : query1;

    if(query === '*') {return null;}

    return query;
  };

  getTrackSearchUpdatedAtFilter = () => {
    const {updatedAtFilter} = this.state;
    const FILTER_TYPES = {
      'now-1d': 'today',
      'now-1w': 'pastWeek',
      'now-1M': 'pastMonth',
      'now-6M': 'pastSixMonths'
    };

    const filter = FILTER_TYPES[updatedAtFilter];

    return filter ?? 'allTime';
  };

  analyticsTrack = () => {
    const {query1, query2, compare, cardsCount1, cardsCount2, selectedRivals, selectedTags} = this.state;

    if(query1) {
      let searchType = compare ? 'comparison search' : 'search';
      let label = compare ? `${query1} vs. ${query2}` : query1;

      if(!cardsCount1 && !cardsCount2) {
        searchType += ' (no results)';
      }

      if(!compare && selectedRivals.size) {
        label += ` (${Array.from(selectedRivals).join(', ')})`;
      }

      analyticsTrack({
        type: 'event',
        category: 'Global Search',
        action: searchType,
        label,
        value: cardsCount1 + cardsCount2
      }, {
        schema: SNOWPLOW_SCHEMAS.searchAction,
        data: {
          type: this.getTrackSearchEventType(),
          term: this.getTrackSearchTerm(),
          resultCount: cardsCount1 + cardsCount2
        },
        context: [
          {
            schema: SNOWPLOW_SCHEMAS.searchfilter,
            data: {
              lastUpdated: this.getTrackSearchUpdatedAtFilter(),
              tags: Object.keys(selectedTags).map(item => parseInt(selectedTags[item].id, 10)),
              rivalIds: Object.keys(selectedRivals).map(item => parseInt(selectedRivals[item].id, 10))
            }
          }
        ]
      });
    }
  };

  renderLoading = () => (
    !this.state.loaded ?
      (<div className="alerts-loading-spinner">
        <i className="fa fa-spin fa-spinner" />
      </div>) : null
  );

  /**
   * starts new search
   * (issues request to global search endpoint)
   *
   * @param sequenceNumber
   * @returns {Promise} search results
   */
  search = async sequenceNumber => {
    const {query = '', selectedRivals, selectedTags, offset, rivalFilter, updatedAtFilter, showTags} = this.state;

    let url = [
      '/api/search.json?',
      query ? `query=${encodeURIComponent(query)}&` : '',
      'index=cards&',
      'fields=board.name^3,profile.name^3,data.name^3,data.textHtml,data.content,data.listRows,tags.name^3&',
      `offset=${encodeURIComponent(offset)}&`,
      `profileFilter=${rivalFilter}`
    ].join('');

    if(updatedAtFilter) {
      url += `&updated_start=${updatedAtFilter}`;
    }

    // add rival filters if selected
    if(Object.keys(selectedRivals).length) {
      const rivals = Object
        .values(selectedRivals)
        .map(r => r.name)
        .join(',');

      url += `&profiles=${encodeURIComponent(rivals)}`;
    }

    // Add tags if selected
    if(showTags && Object.keys(selectedTags).length) {
      const allTags = Object
        .values(selectedTags)
        .map(t => t.id)
        .join(',');

      url += `&allTags=${encodeURIComponent(allTags)}`;
    }

    url += showTags ? '&countersFor[]=profile.name.untouched&countersFor[]=tags.id' : '&countersFor[]=profile.name.untouched';

    return new Promise(resolve => {
      this.context.api.search(url, results => {
        // only process the results of the search if a new search was not initiated.
        if(sequenceNumber !== this.searchSequenceNumber) {
          return resolve();
        }

        this.searchCallback(results, sequenceNumber).then(newState => resolve(newState));
      });
    });
  };

  startNewSearchGroup = (newState = {}) => this.setState({
    ...newState,
    loaded: false,
    cards1: null,
    offset: 0,
    showingMore: false}, () => this.search(this.getNextSearchSequenceNumber()));

  searchAgain = async (prevQuery, query) => {
    this.setState({
      ...SearchQuery.baseState,
      prevQuery,
      query
    }, () => {
      this.setSelectedTagsFromTagClickedEventAndSearch();

      // Disabled for now! -> See https://github.com/kluein/klue/issues/4927
      // suggestedQueriesGet(this.state.query).then(suggestedQueries => this.setState({suggestedQueries}));
    });
  };

  // used to update the current list of rivals based on prev search results
  refreshRivalsIds = async ids => {
    const newRivals = await rivalsGet({rivalOptions: {rivalIds: ids}});

    const currentRivals = {...this.state.currentRivals};

    for(const rival of newRivals.rivals) {
      currentRivals[rival.id] = rival;
    }

    this.setState({currentRivals});
  };

  getTags = async cb => {
    let tags;

    try {
      ({tags} = await tagsGet());
    }
    catch(err) {
      tags = [];
    }
    finally{
      this.setState({tags, showTags: Boolean(tags.length)}, cb);
    }
  };

  normalizeSearchResponse = (results, type) => {
    if(type === 'tags.id') {
      const {tags} = this.state;

      if(!tags.length) {
        return [];
      }

      const countsByTag = results.field_counters[type].reduce(
        (acc, {key, doc_count: docCount}) => {
          acc.set(key, docCount);

          return acc;
        }, new Map());

      return tags.reduce((ac, {id, name}) => {
        const count = countsByTag.get(id);

        if(count !== undefined) {
          ac.push({
            id,
            count,
            name
          });
        }

        return ac;
      }, []);
    }

    if(type === 'profile.name.untouched') {
      return results.field_counters[type].reduce((ac, curr) => {
        const id = curr.profile.hits.hits[0]._id;

        ac.push({
          id,
          count: curr.doc_count,
          name: curr.key
        });

        return ac;
      }, []);
    }
  };

  /**
   * handles response from search request
   *
   * @param data
   * @param {object} data, the search request response
   * @param sequenceNumber
   * @returns {Promise} updated state object
   */
  searchCallback = async (data, sequenceNumber) => {
    const {offset} = this.state;
    const {results = {}, results2 = {}} = data;
    // extract cards data from response object
    const cards1 = (results && results.results) || [];
    const cards2 = (results2 && results2.results) || [];
    const cardsCount1 = (results && results.nr_results) || 0;
    const cardsCount2 = (results2 && results2.nr_results) || 0;

    const newState = {
      loaded: true,
      showSidebar: true,
      compare: data.hasOwnProperty('results2') ? true : false,
      offset: offset + 10,
      query1: data.query1 || '',
      query2: data.query2 || '',
      cardsCount1,
      cardsCount2,
      sources: new Map(),
      loadingSources: new Map()
    };

    const cardsRivalIds = new Set();

    if(cards1.length) {
      // if matches: merge new cards into state object
      newState.cards1 = update(this.state.cards1 || [], {$push: cards1});

      for(const card of cards1) {
        cardsRivalIds.add(card.board.rival_id);
      }
    }

    if(cards2.length) {
      // ..comparison search
      newState.cards2 = update(this.state.cards2 || [], {$push: cards2});

      for(const card of cards2) {
        cardsRivalIds.add(card.board.rival_id);
      }
    }

    await this.refreshRivalsIds(Array.from(cardsRivalIds));

    // check sequence number again here after async call... in case another search was started in the meantime:
    if(sequenceNumber !== this.searchSequenceNumber) {
      return Promise.resolve();
    }

    const {matchingRivals: currentMatchingRivals, query, updatedAtFilter, selectedTags, selectedRivals} = this.state;
    const isNewSearch = this.isNewSearch();
    const hasAggregateProfileFields = results.field_counters && results.field_counters['profile.name.untouched'];
    const hasAggregateTagFields = results.field_counters && results.field_counters['tags.id'];

    // We need to cache the matchingRivals data to avoid it changing when filtering by rival
    if(hasAggregateProfileFields && (!currentMatchingRivals.length || isNewSearch)) {
      newState.matchingRivals = this.normalizeSearchResponse(results, 'profile.name.untouched');
    }

    if(hasAggregateTagFields) {
      newState.matchingTags = this.normalizeSearchResponse(results, 'tags.id');
    }

    newState.prevQuery = query;
    newState.prevUpdatedAtFilter = updatedAtFilter;
    newState.prevSelectedTags = {...selectedTags};

    // extract selected rivals and merge into state
    if(data.profiles) {
      const profiles = {};

      // renaming...
      Object.values(data.profiles).forEach(profile => {
        const {id, name, profile_id: profileId} = profile;

        profiles[profile.id] = {id, name, profileId};
      });
      newState.selectedRivals = {
        ...selectedRivals,
        ...profiles
      };
    }

    return new Promise(resolve => {
      this.setState(newState, () => {
        this.analyticsTrack();

        resolve(newState);
      });
    });
  };

  /**
   * groups cards by rival
   *
   * @param cards
   * @param {Array} cards, the cards
   * @returns {object} the cards grouped by rival
   */
  groupByRival = cards => {
    if(!cards) {
      return {};
    }

    return cards.reduce((result, card) => {
      const {name, id: profileId} = card.profile;
      const {rival_id: id} = card.board;

      if(!result.hasOwnProperty(name)) {
        result[id] = {id, name, profileId};
      }

      return result;
    }, {});
  };

  /**
   * selects rival + triggers search with filters (desktop)
   *
   * @param rival
   * @param {string} rival, the selected rival
   */
  selectRivalDesktop = rival => {
    const selectedRivals = {...this.state.selectedRivals};

    if(rival.id in selectedRivals) {
      delete selectedRivals[rival.id];
    }
    else {
      selectedRivals[rival.id] = rival;
    }

    this.startNewSearchGroup({
      selectedRivals,
      rivalFilter: true
    });
  };

  /**
   * selects rival + triggers search with filters (mobile)
   *
   * @param rivalId
   * @param {string} rivalId, the selected rival
   */
  selectRivalMobile = rivalId => {
    if(rivalId === 'ALL') {
      this.selectAllRivals();

      return;
    }

    const rival = this.state.matchingRivals.find(rivalItem => rivalItem.id === rivalId);

    const selectedRivals = {};

    selectedRivals[rivalId] = rival;

    this.startNewSearchGroup({
      selectedRivalMobile: rival.name, // todo rival.id & fix mobile styles
      selectedRivals
    });
  };

  trackFilterByTag = name => {
    if(!name) {
      return;
    }

    analyticsTrack({
      type: 'event',
      category: 'Global Search',
      action: 'Filter by Tag',
      label: name
    });
  };

    /**
     * selects tag + triggers search with filters (mobile)
     *
     * @param tagId
     * @param {string} tagId, the selected rival
     */
    selectTagMobile = tagId => {
      if(tagId === 'ALL') {
        this.selectAllTags();

        return;
      }

      const tag = this.state.matchingTags.find(tagItem => tagItem.id === tagId);
      const {name} = tag || {};
      const selectedTags = {};

      selectedTags[tagId] = tag;

      this.trackFilterByTag(name);

      this.startNewSearchGroup({
        selectedTagMobile: name, // todo tag.id & fix mobile styles
        selectedTags
      });
    };

    /**
     * selects tag + triggers search with filters (desktop)
     *
     * @param tag
     * @param {string} tag, the selected tag
     * @param tag
     */
    selectTagDesktop = tag => {
      const selectedTags = {...this.state.selectedTags};
      const {name} = tag;

      if(name in selectedTags) {
        delete selectedTags[name];
      }
      else {
        selectedTags[name] = tag;

        this.trackFilterByTag(name);
      }

      this.startNewSearchGroup({
        selectedTags,
        tagFilter: true
      });
    };

  /**
   * selects all rivals + triggers search
   *
   * @returns {Void}
   */
  selectAllRivals = () => this.startNewSearchGroup({
    matchingRivals: {},
    selectedRivals: {},
    showCompanyFilter: false,
    selectedRivalMobile: 'ALL',
    rivalFilter: false
  });

  /**
   * selects rival + triggers search
   *
   * @returns {Void}
   */
  selectByRival = () => this.startNewSearchGroup({
    matchingRivals: {},
    selectedRivals: {},
    showCompanyFilter: true,
    rivalFilter: false
  });

  /**
   * selects all tags + triggers search
   *
   * @returns {Void}
   */
  selectAllTags = () => {
    this.startNewSearchGroup({
      selectedTags: {},
      showTagFilter: false,
      selectedTagMobile: 'ALL'
    });
  };

  /**
   * selects Tag + triggers search
   *
   * @returns {Void}
   */
  selectByTag = async () => {
    this.startNewSearchGroup({
      selectedTags: {},
      showTagFilter: true
    });
  };

  handleNotifyOnNoResults = event => {
    if(event) {
      event.preventDefault();
    }

    if(window.Intercom) {
      window.Intercom('showNewMessage', `Can you track down some info on "${this.state.query}" for us? Thanks! `);
    }
  };

  handleMoreButtonClick = () => {
    const {loaded, showMoreLabel: {label, loadingLabel}, showingMore} = this.state;

    if(!loaded || showingMore) {
      return;
    }

    this.refs.moreButton.textContent = loadingLabel;

    this.setState({showingMore: true}, () => {
      const sn = this.searchSequenceNumber;

      this.search(sn).then(newState => {
        if(!newState || sn !== this.searchSequenceNumber) {
          return;
        }

        this.setState({showingMore: false});

        const {moreButton: more} = this.refs;

        if(loaded && more) {
          more.textContent = label;
        }
      });
    });
  };

  toggleUpdatedAtFilter = () => {
    const {showUpdatedAtFilter} = this.state;

    if(showUpdatedAtFilter) {
      this.startNewSearchGroup({
        cards2: null,
        showUpdatedAtFilter: false,
        updatedAtFilter: null
      });
    }
    else {
      this.setState({showUpdatedAtFilter: true});
    }
  };

  selectUpdatedAtFilter = updatedAtFilter => {
    this.startNewSearchGroup({
      updatedAtFilter
    });
  };

  /**
   * renders filter sidebar for desktop view
   *
   * @returns {Element} the filter sidebar
   */
  renderDesktopSidebar = () => {
    const {
      compare,
      selectedRivals,
      selectedTags,
      showCompanyFilter,
      showTagFilter,
      showUpdatedAtFilter,
      updatedAtFilter,
      cardsCount1,
      showSidebar,
      showTags,
      matchingRivals: matchingRivalsObj,
      matchingTags: matchingTagsObj
    } = this.state;
    const matchingRivals = Object.values(matchingRivalsObj);
    const matchingTags = Object.values(matchingTagsObj);

    if(!showSidebar) {
      return null;
    }

    return (
      <div className="global-search-left-sidebar-container">
        <div className="global-search-left-sidebar">
          {!compare &&
            <div>
              <div className="global-search-sidebar-header">Last Updated</div>

              <CheckboxItemFactory
                value={{value: 'ALL TIME', label: 'ALL TIME'}}
                checked={!showUpdatedAtFilter}
                handleCheckboxChange={this.toggleUpdatedAtFilter}
                count={cardsCount1}
                key="ALL TIME" />

              <CheckboxItemFactory
                value={{value: 'BY RANGE', label: 'BY RANGE'}}
                checked={showUpdatedAtFilter}
                handleCheckboxChange={this.toggleUpdatedAtFilter}
                count={cardsCount1}
                key="BY RANGE" />

              {showUpdatedAtFilter &&
                <div>
                  {SearchQuery.updatedAtFilters.map(filter => (
                    <CheckboxItemFactory
                      value={{value: filter.value, label: filter.label}}
                      checked={updatedAtFilter === filter.value}
                      handleCheckboxChange={this.selectUpdatedAtFilter}
                      iconOn="fa fa-check"
                      iconOff="fa fa-square-o"
                      key={`rivalCheckbox_${filter.label}`} />
                  ))}
                </div>
              }

              <div className="global-search-sidebar-header">Companies</div>

              <CheckboxItemFactory
                value={{value: 'ALL', label: 'ALL'}}
                checked={!showCompanyFilter}
                handleCheckboxChange={this.selectAllRivals}
                count={cardsCount1}
                key="ALL" />

              <CheckboxItemFactory
                value={{value: 'BY COMPANY', label: 'BY COMPANY'}}
                dataTrackingId="search-checkbox-by-company"
                checked={showCompanyFilter}
                handleCheckboxChange={this.selectByRival}
                count={cardsCount1}
                key="BY COMPANY" />

              {showCompanyFilter && matchingRivals.map(rival => (
                <CheckboxItemFactory
                  value={{value: rival, label: `${rival.name} (${rival.count})`}}
                  checked={selectedRivals.hasOwnProperty(rival.id)}
                  handleCheckboxChange={this.selectRivalDesktop}
                  iconOn="fa fa-check"
                  iconOff="fa fa-square-o"
                  switchMode="switch"
                  key={`rivalCheckbox_${rival.id}`} />
              ))}

              {showTags &&
                <>
                  <div className="global-search-sidebar-header">Tags</div>

                  <CheckboxItemFactory
                    value={{value: 'ALL', label: 'ALL'}}
                    checked={!showTagFilter}
                    handleCheckboxChange={this.selectAllTags}
                    count={cardsCount1}
                    key="ALL TAGS" />

                  <CheckboxItemFactory
                    value={{value: 'BY TAG', label: 'BY TAG'}}
                    dataTrackingId="search-checkbox-by-tag"
                    checked={showTagFilter}
                    handleCheckboxChange={this.selectByTag}
                    count={cardsCount1}
                    key="BY TAG" />

                  {showTagFilter && matchingTags.map(tag => (
                    <CheckboxItemFactory
                      value={{value: tag, label: `${tag.name} (${tag.count})`}}
                      checked={selectedTags.hasOwnProperty(tag.name)}
                      handleCheckboxChange={this.selectTagDesktop}
                      iconOn="fa fa-check"
                      iconOff="fa fa-square-o"
                      switchMode="switch"
                      key={`tagCheckbox_${tag.name}`} />
                  ))}
                </>
              }

            </div>
          }
        </div>
      </div>
    );
  };

  /**
   * renders filter dropdown for mobile view
   *
   * @returns {Element} the filter dropdown
   */
  renderMobileSidebar = () => this.state.showSidebar && (
    <div className="global-search-mobile-filters">
      <Dropdown
        label="Tags"
        displayLabel="Select..."
        placeholderMode={false}
        options={this.getTagOptions()}
        selectedValue={this.state.selectedTagMobile}
        className="global-search-mobile-filters-button"
        onOptionClick={this.selectTagMobile} />
      <Dropdown
        label="Companies"
        displayLabel="Select..."
        placeholderMode={false}
        options={this.getRivalOptions()}
        selectedValue={this.state.selectedRivalMobile}
        className="global-search-mobile-filters-button"
        onOptionClick={this.selectRivalMobile} />
      <Dropdown
        label="Updated At"
        displayLabel="Select..."
        placeholderMode={false}
        options={SearchQuery.updatedAtFilters}
        selectedValue={this.state.updatedAtFilter}
        className="global-search-mobile-filters-button"
        onOptionClick={this.selectUpdatedAtFilter} />
    </div>
  );

  /**
   * collects rival options for filters
   *
   * @returns {Array} the rival options
   */
  getRivalOptions = () => (
    [{value: 'ALL', label: 'ALL'}].concat(
      Object.values(this.state.matchingRivals).map(rival => (
        {value: rival.id, label: rival.name})
      )
    )
  );

    /**
     * collects tag options for filters
     *
     * @returns {Array} the tag options
     */
    getTagOptions = () => (
      [{value: 'ALL', label: 'ALL'}].concat(
        Object.values(this.state.matchingTags).map(tag => (
          {value: tag.id, label: tag.name})
        )
      )
    );

  /**
   * renders no cards/results message
   *
   * @param query
   * @param {string} query, the search query
   * @returns {Element} the no results message
   */
  renderNoCardsMsg = query => (
    <p className="global-search-no-results-msg" data-tracking-id="global-search-no-results-msg">
      Sorry, Klue did not find any cards for <Link to={getSearchPathFromQuery(query)}>“{query}”</Link>. ¯\_(ツ)_/¯
    </p>
  );

  renderNoCards = query => (
    <div className="global-search-no-results search-result-card">
      <div className="search-result-card-header">
        <h4>Request Help from your Klue administrator</h4>
      </div>
      <div className="search-result-card-content">
        <div className="global-search-no-results-notify">
          <h5>&quot;Can you track down some info on &apos;{`${query}`}&apos; for us? Thanks!&quot;</h5>
          <a href="#" className="button button--full" onClick={this.handleNotifyOnNoResults}>Start Request</a>
          <p><small>This will open a chat message that will notify your Klue administrator within 2-4 hrs.</small></p>
        </div>
      </div>
    </div>
  );

  /**
   * renders suggested board
   *
   * @param rival
   * @param {object} rival, the rival object
   * @returns {Element} the suggested board
   */
  renderSuggestedBoard = (rival = {}) => {
    if(_.isEmpty(rival) || !rival.profile) {
      return;
    }

    const {id, name, profile = {}} = rival;
    const suggestedBoardClasses = classNames('search-suggestion', {
      'search-suggestion--draft': profile.isDraft
    });

    return (
      <li key={id}>
        <a className={suggestedBoardClasses} href={`/profile/${profile.id}/battlecard`}>{name}</a>
      </li>
    );
  };

  /**
   * renders suggested boards
   *
   * @returns {Element} the suggested boards
   */
  renderSuggestedBoards = () => {
    const {user, rivals = {}} = this.props;
    const selectedRivals = Object.values(this.state.selectedRivals || {});
    const filteredRivalNodes = selectedRivals.reduce((rivalsToDisplay, r) => {
      const rival = rivals[r.id] || {};

      if(!_.isEmpty(rival)) {
        return [...rivalsToDisplay, this.renderSuggestedBoard(rival)];
      }

      return rivalsToDisplay;
    }, []);

    if(!filteredRivalNodes.length) {
      return;
    }

    return (
      <div className="search-result-card search-suggestions">
        <div className="search-result-card-header">
          <h4>{pluralize('Suggested Board', filteredRivalNodes.length)}</h4>
        </div>

        <div className="search-result-card-content">
          <ul className="search-suggestion-list">
            {filteredRivalNodes.length ? filteredRivalNodes : (<li><i className="fa fa-spinner fa-pulse" /></li>)}
          </ul>
        </div>
      </div>
    );
  };

  renderCardMetaLink = card => {
    const {id} = card || {};
    const {user} = this.props;

    if(!id || !user) {
      return null;
    }

    const {loadingSources, sources} = this.state;

    return (
      <CardMetaLink
        card={card}
        sources={sources.get(id)}
        user={user}
        loading={Boolean(loadingSources.get(id))} />
    );
  };

  showCardMeta = (card = null) => {
    if(!card) {
      return this.setState({cardSourceId: null});
    }

    const {id, sources_count} = card; // eslint-disable-line camelcase
    const {sources, loadingSources} = this.state;
    const cardSources = sources?.get(id);

    if(!cardSources) {
      if(sources_count === 0) { // eslint-disable-line camelcase
        return this.setState({
          sources: new Map(sources.set(id, [])),
          cardSourceId: id
        });
      }

      return this.setState({loadingSources: loadingSources.set(id, true)}, () => {
        fetchCardSources(card)
          .then(({sources: fetchedSources}) => {
            if(!this._isMounted) {
              return;
            }

            this.setState({
              loadingSources: new Map(loadingSources.set(id, false)),
              sources: new Map(sources.set(id, fetchedSources)),
              cardSourceId: id
            });
          })
          .catch(error => {
            if(!this._isMounted) {
              return;
            }

            console.error('BattlecardView.showCardMeta', error);

            this.setState({loadingSources: new Map(loadingSources.set(id, false))});
          });
      });
    }

    this.setState({cardSourceId: id});
  };

  /**
   * renders suggested boards
   *
   * @returns {Element} the suggested boards
   */
  renderSuggestedQueries = () => {
    const {suggestedQueries = []} = this.state;

    return suggestedQueries.length ? (
      <div className="search-result-card search-suggestions">
        <div className="search-result-card-header">
          <h4>Suggested Searches</h4>
        </div>

        <div className="search-result-card-content">
          <ul className="search-suggestion-list">
            {suggestedQueries.map((query = '') => (
              <li key={query}>
                <a className="search-suggestion" href={getSearchPathFromQuery(query.trim())}>{query}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    ) : null;
  };

  /**
   * renders cards for comparison view search
   *
   * @returns {Element} filters + matching cards
   */
  renderComparison = () => {
    const {user} = this.props;
    const {
      cards1 = [],
      cards2 = [],
      query1 = '',
      query2 = '',
      currentRivals
    } = this.state;

    return (
      <div>
        <SearchComparison
          renderCardMetaLink={card => this.renderCardMetaLink(card)}
          rivals={currentRivals}
          cards1={cards1}
          cards2={cards2}
          query1={query1}
          query2={query2}
          user={user} />
      </div>
    );
  };

  updateSourceList = updatedSources => {
    const {sources, cardSourceId} = this.state;

    const updateCardState = stateCards => {
      const index = (stateCards || []).findIndex(c => c.id === cardSourceId);

      if(index >= 0) {
        const cardsState = {};

        // sources_count is field name in elastic search card data
        cardsState[index] = {$set: {...stateCards[index], sources_count: 0}}; // eslint-disable-line camelcase

        return ReactUpdate(stateCards, cardsState);
      }

      return null;
    };

    if(!updatedSources || !updatedSources.length) {
      const sourcesCopy = new Map(sources);
      const {cards1, cards2} = this.state;

      sourcesCopy.delete(cardSourceId);

      const newState = {sources: sourcesCopy};
      let cardStateUpdate = updateCardState(cards1);

      if(cardStateUpdate) {
        newState.cards1 = cardStateUpdate;
      }

      cardStateUpdate = updateCardState(cards2);

      if(cardStateUpdate) {
        newState.cards2 = cardStateUpdate;
      }

      return this.setState(newState);
    }

    this.setState({
      sources: new Map(sources.set(cardSourceId, updatedSources))
    });
  };

  /**
   * renders filters menu + boards/cards for single view search
   *
   * @returns {Element} filters + matching boards/cards
   */
  renderSingle = () => {
    const {user} = this.props;
    const {cards1: cards = [], query = '', currentRivals, loaded, selectedTags} = this.state;
    const desktopSidebar = this.renderDesktopSidebar();
    const mobileSidebar = this.renderMobileSidebar();
    const suggestedQueries = this.renderSuggestedQueries();
    const noCardsMsg = (_.isEmpty(cards) && loaded) && this.renderNoCardsMsg(query);
    const filteredTags = new Set(Object.values(selectedTags).map(({id}) => id));

    const renderBody = () => (!_.isEmpty(cards)
      ? (<SearchResults
          rivals={currentRivals}
          cards={cards}
          query={query}
          user={user}
          filteredTags={filteredTags}
          renderCardMetaLink={card => this.renderCardMetaLink(card)} />)
      : this.renderNoCards(query));

    return (
      <div>
        {mobileSidebar}
        <div className="global-search-body">
          {desktopSidebar}
          <div className="global-search-results-container">
            {noCardsMsg}
            <div className="global-search-results">
              {suggestedQueries}
              {loaded && renderBody()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  setRef = el => this.searchQueryRef = el;

  isNewSearch() {
    const {
      query,
      prevQuery,
      prevUpdatedAtFilter,
      updatedAtFilter,
      prevSelectedTags,
      selectedTags
    } = this.state;

    return !isEqual(
      {query: prevQuery, updatedAtFilter: prevUpdatedAtFilter, selectedTags: prevSelectedTags},
      {query, updatedAtFilter, selectedTags}
    );
  }

  render() {
    const {compare, cards1, cards2, cardsCount1, cardsCount2, showMoreLabel: {label}, cardSourceId, sources} = this.state;
    const showMore = (cards1 && cards1.length !== 0 && (cards1.length < cardsCount1)) ||
      (cards2 && cards2.length !== 0 && (cards2.length < cardsCount2));
    const moreButton = showMore && (
      <div className="global-search-more-button u-pt-m">
        <button className="button"
          ref="moreButton"
          onClick={this.handleMoreButtonClick}>
          {label}
        </button>
      </div>
    );

    return (
      <>
        {Boolean(cardSourceId) &&
        <CardMeta
          updateSourceList={this.updateSourceList}
          sources={sources.get(cardSourceId) || []}
          onDismiss={() => this.showCardMeta(null)}
          isCurator={this.isCurator}
          cardId={cardSourceId} />
        }
        <div className="global-search-container" ref={this.setRef}>
          {compare ? this.renderComparison() : this.renderSingle()}
          {moreButton}
        </div>
        {this.renderLoading()}
      </>
    );
  }

}

export default withRouter(SearchQuery);
