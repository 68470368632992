import ArrowIcon from './partials/_arrow_icon/_arrow_icon';
import {Label, NavigationContainer} from './_styles';
import TRACKING_IDS from '../../../tracking-ids';

const SurveyProgressActions = ({currentStep, onNextStep, onPreviousStep, totalSteps}) => (
  <NavigationContainer>
    <ArrowIcon
      disabled={currentStep === 1}
      onClick={onPreviousStep}
      size="24"
      trackingId={TRACKING_IDS.winLoss.buyerPulseSurvey.previousQuestionButton} />
    <Label>
      {currentStep}/{totalSteps}
    </Label>
    <ArrowIcon
      disabled={currentStep === totalSteps}
      onClick={onNextStep}
      direction="down"
      size="24"
      trackingId={TRACKING_IDS.winLoss.buyerPulseSurvey.nextQuestionButton} />
  </NavigationContainer>
);

SurveyProgressActions.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  totalSteps: PropTypes.number.isRequired
};

export default SurveyProgressActions;
