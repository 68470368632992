import Post from './_post';

import classNames from 'classnames';

const PostList = props => {
  const {
    data, digest, digestMode, feedItemToggled, onDeleteFavorite, onPostDelete, onToggleFeedItem, onUpdateFavorite,
    onPinnedCommentUpdatedForFavorite, profile, profileMode, rivals, showComments, singlePostMode, toggledPostId, user, users,
    digestTypesData, onDigestTypeFavoriteUpdate, posts
  } = props;

  const postItems = (data || [])
    .map(post => {
      const {
        alertId, attachments, commentBodyHtml, commentBodySummary, commentId, commentTimestamp, commentTitle, emailId,
        postCommentsCount, postCompetitors, postId, userEmail, userId, userName, viaUserEmail, viaUserId,
        viaUserName, sourceData, pinnedComment = {}, visibleAt
      } = post;
      const commentBody = commentBodyHtml || commentBodySummary || '';
      const handlePostUnpublish = postIdToDelete => {
        if(onDeleteFavorite) {
          // remove from favorites if exists
          onDeleteFavorite(postIdToDelete);
        }

        onPostDelete(postId);
      };

      const viaEmail = Number.isInteger(emailId);
      const viaSlack = /slack/i.test((sourceData || {}).name || '');
      const viaTeams = /teams/i.test((sourceData || {}).name || '');

      return (
        <Post
          key={postId}
          posts={posts}
          rivals={rivals}
          user={user}
          users={users}
          postId={postId}
          alertId={alertId}
          visibleAt={visibleAt}
          commentTimestamp={commentTimestamp}
          commentTitle={commentTitle}
          commentBody={commentBody}
          commentId={commentId}
          commentCount={postCommentsCount}
          posterId={userId}
          userName={userName}
          userEmail={userEmail}
          viaUserId={viaUserId}
          viaUserName={viaUserName}
          viaUserEmail={viaUserEmail}
          linkToFullPost={viaEmail || viaSlack || viaTeams}
          viaEmail={viaEmail}
          viaSlack={viaSlack}
          viaTeams={viaTeams}
          attachments={attachments}
          competitors={postCompetitors}
          onPostDelete={handlePostUnpublish}
          showComments={showComments}
          profileMode={profileMode}
          profile={profile}
          feedItemToggled={feedItemToggled}
          postIsToggled={feedItemToggled && (toggledPostId === postId)}
          onToggleFeedItem={onToggleFeedItem}
          digest={digest}
          digestMode={digestMode}
          onPinnedCommentUpdatedForFavorite={onPinnedCommentUpdatedForFavorite}
          onUpdateFavorite={onUpdateFavorite}
          onDeleteFavorite={onDeleteFavorite}
          singlePostMode={singlePostMode}
          pinnedComment={pinnedComment}
          digestTypesData={digestTypesData}
          onDigestTypeFavoriteUpdate={onDigestTypeFavoriteUpdate} />
      );
    });

  return (
    <ul className={classNames('post-list', {'post-list--toggled-post': toggledPostId})}>
      {postItems}
    </ul>
  );
};

PostList.propTypes = {
  onPostDelete: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  posts: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.objectOf(PropTypes.object),
  rivals: PropTypes.object,
  showComments: PropTypes.bool,
  onToggleFeedItem: PropTypes.func,
  digest: PropTypes.object,
  digestMode: PropTypes.bool,
  onPinnedCommentUpdatedForFavorite: PropTypes.func,
  onUpdateFavorite: PropTypes.func,
  onDeleteFavorite: PropTypes.func,
  profileMode: PropTypes.bool,
  profile: PropTypes.object,
  feedItemToggled: PropTypes.bool,
  singlePostMode: PropTypes.bool,
  toggledPostId: PropTypes.number,
  digestTypesData: PropTypes.arrayOf(PropTypes.object),
  onDigestTypeFavoriteUpdate: PropTypes.func
};

PostList.defaultProps = {
  onPostDelete: null,
  data: [],
  posts: {},
  user: null,
  users: {},
  rivals: {},
  showComments: false,
  onToggleFeedItem: null,
  digest: null,
  digestMode: false,
  onPinnedCommentUpdatedForFavorite: null,
  onUpdateFavorite: null,
  onDeleteFavorite: null,
  profileMode: false,
  profile: null,
  feedItemToggled: false,
  singlePostMode: false,
  toggledPostId: 0,
  digestTypesData: [],
  onDigestTypeFavoriteUpdate() {}
};

export default PostList;
