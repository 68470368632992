/* eslint-disable wrap-iife */
// IE doesn't support classList for SVG elements!
// https://github.com/Pomax/react-onclickoutside#ie-does-not-support-classlist-for-svg-elements
if(!('classList' in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, 'classList', {
    get() {
      return {
        contains: className => {
          return this.className.baseVal.split(' ').indexOf(className) !== -1;
        }
      };
    }
  });
}

// Element.closest polyfill (unsupported in IE11, not included in corejs/stable)
// see: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
if(!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
                              Element.prototype.webkitMatchesSelector;
}

if(!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    let el = this;    // eslint-disable-line consistent-this

    do {
      if(el.matches(s)) {
        return el;
      }

      el = el.parentElement || el.parentNode;
    }
    while(el !== null && el.nodeType === 1);

    return null;
  };
}

// Element.replaceWith polyfill (unsupported in IE10+, not included in corejs/stable)
/* eslint-disable babel/no-invalid-this */
function replaceWithPolyfill() {
  // For safari, and IE10+
  // 'use-strict';

  const parent = this.parentNode;
  let i = arguments.length;
  let currentNode;

  if(!parent) {
    return;
  }

  // if there are no arguments
  if(!i) {
    parent.removeChild(this);
  }

  // i-- decrements i and returns the value of i before the decrement
  while(i--) {
    currentNode = arguments[i];

    if(typeof currentNode !== 'object') {
      currentNode = this.ownerDocument.createTextNode(currentNode);
    }
    else if(currentNode.parentNode) {
      currentNode.parentNode.removeChild(currentNode);
    }

    // the value of "i" below is after the decrement
    if(!i) {  // if currentNode is the first argument (currentNode === arguments[0])
      parent.replaceChild(currentNode, this);
    }
    else {    // if currentNode isn't the first
      parent.insertBefore(currentNode, this.previousSibling);
    }
  }
}
/* eslint-enable babel/no-invalid-this */

if(!Element.prototype.replaceWith) {
  Element.prototype.replaceWith = replaceWithPolyfill;
}

if(!CharacterData.prototype.replaceWith) {
  CharacterData.prototype.replaceWith = replaceWithPolyfill;
}

if(!DocumentType.prototype.replaceWith) {
  DocumentType.prototype.replaceWith = replaceWithPolyfill;
}

// request idle callback polyfill
// https://developers.google.com/web/updates/2015/08/using-requestidlecallback
window.requestIdleCallback = window.requestIdleCallback || (
  cb => {
    const start = Date.now();

    return setTimeout(() => {
      cb({
        didTimeout: false,
        timeRemaining: () => Math.max(0, 50 - (Date.now() - start))
      });
    }, 1);
  }
);

window.cancelIdleCallback = window.cancelIdleCallback || (id => clearTimeout(id));

// wrapper to filter out console.log messages in development
// set cookie to bypass: klue_console_debugs=true
window.console.log = (function() {
  const log = console.log;

  return function() {
    const isKlueLog = /^[A-Z]\w+\.[\w.*]+:/.test(arguments[0]);

    if(!isKlueLog || (document.cookie.indexOf('klue_console_debugs=true') >= 0)) {
      log && log.apply(console, arguments);
    }
  };
}());

if(window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function(arr) {
  arr.forEach(function(item) {
    if(item.hasOwnProperty('remove')) {
      return;
    }

    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if(this.parentNode === null) {
          return;
        }

        this.parentNode.removeChild(this);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
