import {fetch, update} from '../api_utils';
import {isValidId} from '../utils';
import {serializeQueryString} from '../utils';

// rival groups management API

export const rivalGroupsGet = ({code = 'ApiUtils.rivalGroupsGet', params}) => {
  return new Promise((resolve, reject) => {
    fetch(`/api/rival_groups.json${params ? `?${serializeQueryString(params)}` : ''}`, {code})
      .then(({data: rivalGroups}) => {
        console.log(`${code}: rival groups loaded: %o`, rivalGroups);

        resolve(rivalGroups);
      })
      .catch(error => {
        console.error(code, error);

        reject([]);
      });
  });
};

export const rivalGroupGet = ({rivalGroupOptions = {}, code = 'ApiUtils.rivalGroupGet'}) => {
  const {rivalGroupId = 0} = rivalGroupOptions;

  return new Promise((resolve, reject) => {
    if(_.isEmpty(rivalGroupOptions) || !isValidId(rivalGroupId, true)) {
      console.warn(`${code}: invalid options specified: %o`, rivalGroupOptions);

      return reject(rivalGroupOptions);
    }

    fetch(`/api/rival_groups/${rivalGroupId}.json`, {code})
      .then(({data: rivalGroup}) => {
        console.log(`${code}: loaded rival group: id #%o: %o`, rivalGroupId, rivalGroup);

        resolve(rivalGroup);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};

export const rivalGroupUpdate = ({rivalGroupOptions = {}, code = 'ApiUtils.rivalGroupUpdate'}) => {
  const {id: rivalGroupId = 0} = rivalGroupOptions;

  return new Promise((resolve, reject) => {
    if(_.isEmpty(rivalGroupOptions) || !isValidId(rivalGroupId, true)) {
      console.warn(`${code}: invalid options specified: %o`, rivalGroupOptions);

      return reject(rivalGroupOptions);
    }

    update(`/api/rival_groups/${rivalGroupId}.json`, JSON.stringify(rivalGroupOptions), {code})
      .then(({data: rivalGroup}) => {
        console.log(`${code}: loaded rival group: id #%o: %o`, rivalGroupId, rivalGroup);

        resolve(rivalGroup);
      })
      .catch(error => {
        console.error(code, error);

        reject(error);
      });
  });
};
