import ToggleSwitch from './_toggle_switch';

const DigestNewInKlueToggle = ({digestType, digestTypeUpdate, onUpdatedDigestType}) => {
  const {id, disableNewInKlue = false} = digestType || {};

  if(!id) {
    return null;
  }

  const onToggleChanged = event => {
    const {checked} = event.target;

    digestTypeUpdate({id, disableNewInKlue: !checked}, updatedDigestType => {
      if(updatedDigestType) {
        onUpdatedDigestType && onUpdatedDigestType(updatedDigestType);
      }
    });
  };

  return (
    <div className="digest-new-in-klue-toggle">
      <h3 className="digest-new-in-klue-toggle__title">New in Klue</h3>
      <div className="digest-new-in-klue-toggle__info">
        Highlight Boards and Battlecards that have been recently updated.
      </div>
      <div className="digest-new-in-klue-toggle__toggle">
        <ToggleSwitch
          label="Enable New In Klue"
          checked={!disableNewInKlue}
          onChange={onToggleChanged} />
      </div>
    </div>
  );
};

DigestNewInKlueToggle.propTypes = {
  digestType: PropTypes.object,
  digestTypeUpdate: PropTypes.func,
  onUpdatedDigestType: PropTypes.func
};

DigestNewInKlueToggle.defaultProps = {
  digestType: {},
  digestTypeUpdate() {},
  onUpdatedDigestType() {}
};

export default DigestNewInKlueToggle;

