import {useMemo} from 'react';

const buttonStates = Object.freeze({
  ENABLE: 0,
  DISABLE: 1,
  REFINE: 2
});
const findState = value => {
  if(!value) {
    return buttonStates.ENABLE;
  }

  return buttonStates.REFINE;
};

const BoardAlertSettings = ({alertsActive, onToggleQueryBuilder, onToggleAlerts}) => {
  const buttonState = useMemo(() => findState(alertsActive), [alertsActive]);

  const handleConfigureAlerts = event => {
    event.preventDefault();
    event.persist();

    if(buttonState === buttonStates.REFINE) {
      onToggleQueryBuilder();
    }
    else {
      onToggleAlerts(!alertsActive);
    }
  };

  const textByValue = {
    [buttonStates.ENABLE]: 'Enable Alerts',
    [buttonStates.DISABLE]: 'Disable Alerts',
    [buttonStates.REFINE]: 'Refine Alerts'
  }[buttonState];

  const classNameByValue = {
    [buttonStates.ENABLE]: 'configure-button',
    [buttonStates.DISABLE]: 'button--disabled',
    [buttonStates.REFINE]: 'configure-button'
  }[buttonState];

  return (
    <div className="board-settings-item">
      <div className="title" data-tracking-id="alerts-collect-news-for-board-title">
        Alerts - Collect News for this Board
      </div>

      <button
        type="button"
        className={`button ${classNameByValue}`}
        onClick={handleConfigureAlerts}>
        {textByValue}
      </button>
    </div>
  );
};

BoardAlertSettings.propTypes = {
  alertsActive: PropTypes.bool,
  onToggleQueryBuilder: PropTypes.func,
  onToggleAlerts: PropTypes.func
};

BoardAlertSettings.defaultProps = {
  alertsActive: false,
  onToggleQueryBuilder() {},
  onToggleAlerts() {}
};

export default BoardAlertSettings;
