import {css} from 'styled-components';
import {breakpoints} from './_constants';

export const mediaQuery = (minWidth, maxWidth) => (...args) => {
  const minWidthMediaQuery = `(min-width: ${minWidth}px)`;
  const maxWidthMediaQuery = maxWidth ? `and (max-width: ${maxWidth}px)` : '';
  const mediaInterval = `${minWidthMediaQuery} ${maxWidthMediaQuery}`;

  return css`
    @media only screen and ${mediaInterval} {
      ${css(...args)}
    }
  `;
};

export const smartphoneLayout = mediaQuery(breakpoints.smartphone.min, breakpoints.smartphone.max);

export const tabletLayout = mediaQuery(breakpoints.tablet.min, breakpoints.tablet.max);

export const desktopLayout = mediaQuery(breakpoints.desktop.min);
