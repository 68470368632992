import Icon from '../_icon';
const ENTER_KEY = 'Enter';

export const RemoveButton = ({
  tagId,
  onRemoveTag
}) => {
  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    onRemoveTag(tagId);
  };

  const handleKeyDown = event => {
    if(event.key === ENTER_KEY) {
      event.preventDefault();
      event.stopPropagation();
      onRemoveTag(tagId);
    }
  };

  return (
    <button
      className="card-tag-remove-button"
      title="Remove tag"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}>
      <Icon className="card-tag-remove-button-icon" icon="closeTag" style={{verticalAlign: 'initial'}} />
    </button>
  );
};

RemoveButton.propTypes = {
  tagId: PropTypes.number.isRequired,
  onRemoveTag: PropTypes.func.isRequired
};

export default RemoveButton;
