import moment from 'moment';
import Dropdown from './_dropdown';
import CopyButton from './_copy_button';

import {canEditDigest} from '../modules/digest_utils';
import {userIsKluebot} from '../modules/roles_utils';
import {userGet} from '../modules/api/users';
import {analyticsTrack, addTrackingParams, trackingParamSources} from '../modules/analytics_utils';

import {Link} from 'react-router-dom';
import PostDigestTypes from './_post_digest_types';

class PostAvatar extends React.Component {

  static contextTypes = {
    appData: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object,
    poster: PropTypes.object,
    posts: PropTypes.object,
    postId: PropTypes.number,
    alertId: PropTypes.string,
    visibleAt: PropTypes.string,
    postTitle: PropTypes.string,
    posterId: PropTypes.number,
    name: PropTypes.string,
    viaName: PropTypes.string,
    viaUserId: PropTypes.number,
    timestamp: PropTypes.string,     // ISO-8601 UTC date
    onPostDelete: PropTypes.func,
    digest: PropTypes.object,
    onUpdateFavorite: PropTypes.func,
    onDeleteFavorite: PropTypes.func,
    canDelete: PropTypes.bool,
    isFavorite: PropTypes.bool,
    digestMode: PropTypes.bool,
    digestTypesData: PropTypes.arrayOf(PropTypes.object),
    onDigestTypeFavoriteUpdate: PropTypes.func
  };

  static defaultProps = {
    user: null,
    posts: {},
    poster: null,
    digestMode: false,
    postId: null,
    alertId: null,
    visibleAt: null,
    postTitle: '',
    posterId: 0,
    name: '',
    viaName: '',
    viaUserId: null,
    timestamp: '',
    onPostDelete() {},
    digest: null,
    onUpdateFavorite: null,
    onDeleteFavorite: null,
    canDelete: false,
    isFavorite: false,
    digestTypesData: [],
    onDigestTypeFavoriteUpdate() {}
  };

  async componentDidMount() {
    const {viaUserId} = this.props;

    if(viaUserId) {
      const {image: viaUserImage} = await userGet({userId: viaUserId});

      this.viaUserImage = viaUserImage;
    }
  }

  getPostUrl = () => {
    const {appData: {rootUrl}} = this.context;
    const {postId} = this.props;

    return `${rootUrl}posts/${postId}`;
  };

  //  save/unsave action
  handleToggleFavorite = (event, isQuickAdd) => {
    if(event) {
      event.preventDefault();
    }

    const {postId, alertId, postTitle, isFavorite, onDeleteFavorite, onUpdateFavorite, visibleAt, digest} = this.props;

    // DEBUG
    console.log('PostAvatar.handleToggleFavorite: %o post #%o', (isFavorite ? 'un-favoriting' : 'favoriting'), postId);

    if(isFavorite) {
      onDeleteFavorite(postId);
    }
    else {
      const {manual} = digest || {};
      const digestOption = (manual || isQuickAdd) ? {digest} : {};

      onUpdateFavorite({postId, ...(visibleAt ? {} : {needsPromote: true, alertId}), ...digestOption});
    }

    analyticsTrack({
      type: 'event',
      category: 'Post',
      action: `${isQuickAdd ? 'quick-' : ''}${isFavorite ? 'remove from' : 'add to'} intel digest`,
      label: `${postTitle || 'Untitled'} (${postId})`
    });
  };

  handleOpenDigestTypesModal = () => {
    const {postId, alertId, visibleAt, digestTypesData = [], user, onDigestTypeFavoriteUpdate} = this.props;
    const {utils: {dialog, company}} = this.context;
    const modalId = `modalDigestTypes${postId}`;

    const handleConfirm = data => {
      onDigestTypeFavoriteUpdate(data, {...(visibleAt ? {} : {needsPromote: true, alertId})});

      dialog.remove(modalId);
    };

    const handleCancel = () => dialog.remove(modalId);

    dialog.create({
      id: modalId,
      content: (
        <PostDigestTypes
          postId={postId}
          digestTypesData={digestTypesData}
          user={user}
          company={company}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          onDigestTypeFavoriteUpdate={onDigestTypeFavoriteUpdate} />
      ),
      _widerMode: false,
      closeOnBgClick: true
    });
  };

  getDropdownOptions = ({usedInDigest = false}) => {
    const {
      user, digest, isFavorite, postId, postTitle,
      canDelete, onPostDelete, visibleAt, digestMode, digestTypesData = []
    } = this.props;
    const {utils: {userCanCurate, rival, company} = {}} = this.context;

    const userUIDropdownEditOptions = [];

    const isNotAProfile = digest && user && userCanCurate() && !rival;

    // don't show add/remove from digest option when in a profile
    if(isNotAProfile) {
      const {manual} = digest || {};

      if(!manual && digestTypesData.length > 1) {
        const isDisabled = _.isEmpty(digestTypesData);
        const className = isDisabled ? 'ui-dropdown-menu--disabled' : null;
        const onOptionClick = isDisabled ? null : this.handleOpenDigestTypesModal;

        if(digestMode) {
          userUIDropdownEditOptions.push({
            value: 1,
            label: isFavorite ? 'Remove from this digest' : `${visibleAt ? '' : 'Promote & '}Add to this digest`,
            icon: isFavorite ? 'fa-minus' : 'fa-plus',
            onOptionClick: e => this.handleToggleFavorite(e, true),
            dataTrackingId: `${isFavorite ? 'quick-multi-digest-remove-from' : 'quick-multi-digest-add-to'}-post-intel-digest-option`
          });
        }

        userUIDropdownEditOptions.push(
          {
            value: 0,
            label: 'Choose Assigned Digests',
            icon: 'fa-share-alt',
            name: 'Intel Digests',
            className,
            onOptionClick,
            dataTrackingId:
            'add-or-remove-post-intel-digest-option'
          },
          {value: 0, divider: true}
        );
      }
      else if(canEditDigest(digest, user, company)) {
        const digestName = manual ? 'Custom Digest' : 'Intel Digest';

        userUIDropdownEditOptions.push({
          value: 1,
          label: isFavorite ? `Remove from ${digestName}` : `${visibleAt ? '' : 'Promote & '}Add to ${digestName}`,
          icon: 'fa-share-alt',
          onOptionClick: this.handleToggleFavorite,
          dataTrackingId: `${isFavorite ? 'remove-from' : 'add-to'}-post-intel-digest-option`
        });
      }
      else {
        userUIDropdownEditOptions.push({
          value: 1,
          label: 'Intel Digest is locked',
          icon: 'fa-lock'
        });
      }
    }

    const uiCopyButton = (
      <CopyButton
        key={`avatar-${postId}_2`}
        label="Copy shareable link"
        labelClicked="Copied!"
        iconClass="ui-dropdown-option_icon fa fa-link"
        labelClass="ui-dropdown-option_label"
        tooltip="Copy shareable link"
        dataAction="copyShareableLink"
        targetUrl={addTrackingParams(this.getPostUrl(), {
          source: trackingParamSources.feed,
          content: user.id
        })}
        callback={() => {
          analyticsTrack({
            type: 'event',
            category: 'Post',
            action: 'copy shareable link',
            label: `${postTitle || 'Untitled'} (${postId})`
          });
        }} />
    );

    userUIDropdownEditOptions.push({
      linkElement: uiCopyButton
    });

    if(canDelete) {
      const className = usedInDigest ? 'ui-dropdown-menu--disabled' : null;
      const onOptionClick = usedInDigest ? null : onPostDelete;
      const tooltip = usedInDigest ? 'Posts attached to an Intel Digest cannot be deleted.' : '';

      userUIDropdownEditOptions.push(
        {value: 4, divider: true},
        {value: 5, label: 'Delete', icon: 'fa-trash-o', onOptionClick, className, tooltip}
      );
    }

    return userUIDropdownEditOptions;
  };

  render() {
    const {postId, poster, posterId, name, viaName, viaUserId, timestamp, posts} = this.props;
    const {utils: {company} = {}} = this.context;
    const isKluebot = userIsKluebot({userId: posterId, company});
    const {imageSml} = poster || {};
    let userAvatar;
    let userProfile;
    let viaUserProfile;

    // filter out unknown user from linked profiles
    if(!_.isEmpty(poster) && (!poster.isInternal || isKluebot)) {
      const posterName = isKluebot ? 'Kluebot' : name;

      userAvatar = (
        <Link
          to={`/users/${posterId}`}
          title={posterName}
          className="ui-post-avatar">
          <img src={imageSml || '/user-profile-image-backup-circle.svg'} alt={posterName} title={posterName} loading="lazy" />
        </Link>
      );

      userProfile = (
        <Link
          to={`/users/${posterId}`}
          title={posterName}>
          {posterName}
        </Link>
      );
    }
    else {
      if(!_.isEmpty(poster)) {
        userAvatar = (
          <Link
            to={`/users/${viaUserId}`}
            title={viaName}
            className="ui-post-avatar">
            <img
              src={(poster.username !== 'unknownuser') && poster.imageSml || this.viaUserImage || '/user-profile-image-backup-circle.svg'}
              alt={viaName}
              title={viaName}
              loading="lazy" />
          </Link>
        );
      }
      else {
        userAvatar = (
          <span className="ui-post-avatar">
            <img
              src={imageSml || '/user-profile-image-backup-circle.svg'}
              alt={name}
              title={name}
              loading="lazy" />
          </span>
        );
      }

      userProfile = (
        <span title={name}>{name}</span>
      );
    }

    if(viaName && viaUserId !== posterId) {
      viaUserProfile = (
        <span>
          {' '}
          via
          {' '}
          <Link to={`/users/${viaUserId}`} title={viaName}>
            {viaName}
          </Link>
        </span>
      );
    }

    return (
      <div className="board-header">
        <div className="post-header">
          {userAvatar}
          <div className="poster-details">
            <span className="card-poster">
              {userProfile}
              {viaUserProfile}
            </span>
            <small>
              <time dateTime={timestamp} title={new Date(timestamp)}>{moment(timestamp).fromNow()}</time>
            </small>
          </div>
        </div>

        <div className="card-toolbar">
          <Dropdown
            keyPrefix={'avatar-' + postId}
            options={this.getDropdownOptions(posts[postId] || {})}
            condensed={true}
            containerClass="ui-dropdown-flush"
            className="btn btn-dropdown-card btn-dropdown-size-38" />
        </div>
      </div>
    );
  }

}

export default PostAvatar;
