import React, {useState, useEffect} from 'react';

import ToggleSwitch from '../_toggle_switch';

const CompanySettingsTagVisibility = ({}, {utils}) => {
  const {featureFlagUpdate, company} = utils;
  const isCardVisibleToConsumersFlag = company.companyData?.cardTagIsConsumerVisible ?? false;
  const [isCardVisibleToConsumers, setIsCardVisibleToConsumers] = useState(isCardVisibleToConsumersFlag);

  const toggleConsumerTagIsVisible = () => {
    setIsCardVisibleToConsumers(!isCardVisibleToConsumers);
  };

  useEffect(() => {
    featureFlagUpdate('cardTagIsConsumerVisible', isCardVisibleToConsumers);
  }, [isCardVisibleToConsumers]);

  return (
    <div>
      <h4 className="u-pt-s"><strong>Tag Visibility</strong></h4>
      <div className="tags-visibility-toggle">
        <span>Make tags visible to all consumers</span>
        <ToggleSwitch
          label="Tags Visibility for consumers"
          defaultChecked={isCardVisibleToConsumers}
          onClick={toggleConsumerTagIsVisible}
          className="tags-visibility-toggle--button" />
      </div>
    </div>
  );
};

CompanySettingsTagVisibility.contextTypes = {
  utils: PropTypes.object.isRequired
};

export default CompanySettingsTagVisibility;

