import * as KlueTypes from '../../klue_types';

export function website(company) {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('validateCompany: Unexpected parameter type.', company);
    throw new Error('validateCompany: Parameter was unexpected type.');
  }

  return KlueTypes.Website.create({websiteDomain: company.domain});
}
