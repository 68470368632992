import {createContext, useContext, useEffect, useState, useCallback} from 'react';
import {profileUpdate} from '../modules/api/profiles';

export const AlertSubscriptionsContext = createContext();

export const useAlertSubscriptions = () => {
  const alertSubscriptionsContext = useContext(AlertSubscriptionsContext);

  if(alertSubscriptionsContext === undefined) {
    throw new Error('useAlertSubscriptions must be used within <AlertSubscriptionsProvider />');
  }

  return alertSubscriptionsContext;
};

const useAlertSubscriptionsInternal = (rivals, dispatch, context) => {
  const {api: {metabaseQueriesGet}} = context;
  const [queries, setQueries] = useState({});

  const getMebaseQueries = useCallback(() => {
    metabaseQueriesGet(0, metabaseQueries => {
      setQueries((metabaseQueries || []).reduce((acc, query) => {
        const {rivalId} = query;

        if(rivalId) {
          const rivalQueries = acc[rivalId] ?? [];

          rivalQueries.push(query);

          acc[rivalId] = rivalQueries;
        }

        return acc;
      }, {}));
    });
  }, [metabaseQueriesGet]);

  useEffect(() => {
    dispatch.rivals.loadOrFetchRivals();
    getMebaseQueries();
  }, [getMebaseQueries, dispatch.rivals]);

  const updateRivalAndMetabaseQueries = rival => {
    const {id} = rival;

    if(!id) {
      return;
    }

    metabaseQueriesGet(id, metabaseQueries => {
      dispatch.rivals.updateRivalWithRival(rival);
      setQueries({...queries, [id]: metabaseQueries});
    });
  };

  const refreshMetabaseSubscription = rivalId => {
    metabaseQueriesGet(rivalId, metabaseQueries => {
      setQueries({...queries, [rivalId]: metabaseQueries});
    });
  };

  const updateAlertsActive = ({rival, alertsActive, updateProfile}) => {
    const updatedRival = {...rival, alertsActive};

    if(updateProfile) {
      const id = rival.profile?.id ?? null;

      if(!id) {
        return Promise.reject();
      }

      return profileUpdate({
        profileOptions: {
          id,
          alertsActive
        }
      }).then(() => updateRivalAndMetabaseQueries(updatedRival));
    }

    return updateRivalAndMetabaseQueries(updatedRival);
  };

  const areAllAlertsQueriesDisabled = rival => {
    if(!rival) {
      return false;
    }

    const {id: rivalId} = rival;
    const rivalQueries = queries[rivalId] ?? [];

    return !(rival?.alertsActive ?? false) || rivalQueries.every(query => query.deletedAt);
  };

  const isHighVolumeAlertDisabled = rival => {
    const {supportedRival, id: rivalId} = rival;
    const rivalQueries = queries[rivalId] ?? [];

    return Boolean(!rivalQueries.length
      ? (supportedRival || {}).isHighVolumeAlerts
      : rivalQueries.some(query => query.isHighVolumeAlerts));
  };

  return {rivals, isHighVolumeAlertDisabled, areAllAlertsQueriesDisabled, updateAlertsActive, refreshMetabaseSubscription};
};

export const AlertSubscriptionsProvider = ({rivals, dispatch, children}, context) => {
  const value = useAlertSubscriptionsInternal(rivals, dispatch, context);

  return (
    <AlertSubscriptionsContext.Provider value={value}>
      {children}
    </AlertSubscriptionsContext.Provider>
  );
};

AlertSubscriptionsProvider.propTypes = {
  rivals: PropTypes.object.isRequired,
  dispatch: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired
};

AlertSubscriptionsProvider.contextTypes = {
  api: PropTypes.object.isRequired
};
