/*
  The basic idea behind the logic in this component is that the end of the animations
  trigger the the Joyride tour to advance/continue. This is because the Joyride component
  needs to do a quick calculation of the DOM, so we can't have anything still animating
  when it, in-turn, begins its animation. DOM manipulation has no reliable
  time-to-completion, so callbacks work well in lieu of a GSAP-style timeline.

  Logic:
    At any time, the user can:
    Click on the extension      - move to screen 3, start in-extension mode
    Cancel the tour             - move back to onboarding guide
    From within ext, close it   - end tour, prompt to replay

  Mobile:
    There is an event listener to move between mobile and desktop. On mobile, you
    cannot really trigger the extension, so we just show them how to do it and
    prompt the user to come back later when they are on desktop. For performance
    reasons, there is only the one state on mobile.

  Screens/states:
    -1: Caching & mobile
     0: Initial Kluebot welcome
     1: Abstract
     2: Real-world
     3: Call to action

    Notes:
      There is an unused stagger effect in the BrowserFrameTop component.
      I left this as-is because of future plans.
*/

import onboardingData from './_onboarding_data';
import BrowserFrame from './elements/_browser_frame';
import KlueBot from './elements/_kluebot';

import {handleMessageFromExtension} from '../../modules/ext_utils';
import {userCanCurate} from '../../modules/roles_utils';
import {uiViewports} from '../../modules/constants/ui';

import classNames from 'classnames';
import {withRouter} from 'react-router-dom';

class ExtensionOnboarding extends React.Component {

  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.object,
    company: PropTypes.object
  };

  static defaultProps = {
    history: {},
    user: {},
    company: {}
  };

  state = {
    isOnboarding: false,
    screen: -1,
    steps: [],
    authed: true,
    overlay: false,
    to: [],
    inExt: false,
    layout: uiViewports.DESKTOP
  };

  componentDidMount() {
    console.log('OnboardingExtension.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    // Watch for window resizes
    window.addEventListener('resize', this.setLayout);
    this.setLayout();

    // Listen to window for the extension to be loaded
    handleMessageFromExtension(msg => {
      if(msg.type === 'klue:ext:mounted') {
        // When extension has mounted,, send message to advance onboarding state
        klueMediator.publish('klue:onboarding:inExt', '');
      }

      if(msg.type === 'klue:ext:sidebar:closed') {
        this.finishTour();
      }
    });

    klueMediator.subscribe('klue:onboarding:inExt', () => {
      // When in the extension part of the tour, we pause the outside
      this.stopTour();
    });

    klueMediator.subscribe('klue:onboarding:complete', () => {
      this.finishTour();
    });

    // Start things off
    this.startLayout(this.state.layout);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.layout !== this.state.layout) {
      this.startLayout(this.state.layout);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setLayout = () => {
    if(window.matchMedia('(min-width: 730px)').matches) {
      this.setState({
        layout: uiViewports.DESKTOP
      });
    }
    else {
      this.setState({
        layout: uiViewports.MOBILE
      });
    }
  };

  startLayout = screenSize => {
    if(screenSize === uiViewports.DESKTOP) {
      this.refs.joyride.stop();
      this.stepsDesktop();
      this.setState({
        screen: 0
      });
    }
    else if(screenSize === uiViewports.MOBILE) {
      this.refs.joyride.stop();
      this.refs.joyride.reset();
      this.stepMobile();

      this.setState({
        screen: -1,
        overlay: false
      }, function() {
        this.refs.joyride.start(true);
      }.bind(this));
    }
    else {
      return;
    }
  };

  stopTour = () => {
    const progress = this.refs.joyride.getProgress();

    if(progress.step) {
      this.refs.joyride.stop();
      this.setState({
        inExt: true,
        screen: 2
      });
    }
    else {
      return;
    }
  };

  finishTour = () => {
    this.refs.joyride.stop();
    this.refs.joyride.reset();
    this.stepFinish();

    if(this.state.screen === 3) {
      this.setState({
        inExt: false,
        overlay: false
      }, function() {
        this.refs.joyride.start(true);
      });
    }
    else {
      this.setState({
        screen: 3,
        inExt: false,
        overlay: false
      });
    }
  };

  // Fired with every 'next/back' on a Joyride
  joyrideCallback = event => {
    // Skip - go back to guide
    if((event.action === 'skip') && (event.type === 'finished')) {
      // On screen three the skip becomes 'reset'
      if(this.state.screen === 3) {
        this.refresh();
      }
      else {
        this.props.history.goBack();
      }
    }

    // If we're moving forward
    if((event.action === 'next') && (event.type === 'step:after')) {
      if(this.state.screen === -1) { return; }

      // Stop Joyride
      this.refs.joyride.stop();

      // Move to Previous Screen
      const incr = this.state.screen + 1;
      const newState = {screen: incr};

      // Change overlay as needed
      newState.overlay = (incr === 1) ? true : (incr === 3) ? false : undefined;
      this.setState(newState);
    }

    // If we're moving Back
    if((event.action === 'back') && (event.type === 'step:after')) {
      this.refs.joyride.stop();

      const decr = this.state.screen - 1;
      const newState = {screen: decr};

      // Change overlay as needed
      newState.overlay = (decr === 2) ? true : (decr === 0) ? false : undefined;
      this.setState(newState);
    }

    // Last state
    if((event.action === 'next') && (event.type === 'finished')) {
      // On mobile? Not complete b/c no extension experience
      if(this.state.screen === -1) {
        this.proceedToGuide();
      }

      this.toggleCompleted();
    }
  };

  proceedToGuide = event => {
    if(event) { event.preventDefault(); }

    const newLocation = '/dashboard';

    window.location = newLocation;
  };

  refresh = () => { window.location.reload(true); };

  animatingChildCallback = () => {
    if(this.state.inExt) { return; }

    this.refs.joyride.start(true);
  };

  addSteps = steps => {
    if(!Array.isArray(steps)) { steps = [steps]; }
    if(!steps.length) { return false; }

    this.setState({steps});
  };

  stepFinish = () => {
    this.addSteps([
      {
        title: 'Nice Work.',
        text: 'You can <strong style=\'color: #49BB90\'>replay</strong> this tutorial or head back to your <a href="/guides/welcome">Klue guide</a>.',
        selector: '.onboarding_kluebot--one',
        position: 'bottom'
      }
    ]);
  };

  stepMobile = () => {
    this.addSteps([
      {
        title: 'Fire Up the Engines.',
        text: `<strong style="color: #49BB90">In your browser extensions toolbar,</strong>
          click the <img src="/klue-logo-128x128.png" style="width: 18px; margin: 0 4px 3px 4px;" />
          to activate your Klue button.`,
        textAlign: 'center',
        selector: '.browser-frame_klue-button',
        position: ''
      }
    ]);
  };

  stepsDesktop = () => {
    this.addSteps([
      {
        title: 'Activate your Klue Button.',
        text: `Hey, I'm Kluebot - I'm going to show you how to activate your Klue Button.
          <br /><br />Press <strong style="color: #49BB90">Next</strong> below to get started.`,
        selector: '.onboarding_kluebot--one',
        position: 'bottom'
      },
      {
        title: 'Look to your toolbar. 👉',
        text: `Your Klue Button is found in your <strong style="color: #49BB90">browser extensions toolbar</strong>
          and it looks like this: <img src="/klue-logo-128x128.png" style="width: 18px; margin: 0 4px 3px 4px;" />.`,
        textAlign: 'center',
        selector: '.browser-frame_klue-button',
        position: 'left'
      },
      {
        title: 'Let\'s save this page. 👆',
        text: `Practice time. <strong style="color: #49BB90">Click your Klue Button</strong>${userCanCurate({user: this.props.user}) ? ' to save this page' : ' now'}.`,
        selector: '.browser-frame_klue-button',
        position: 'left'
      },
      {
        title: 'That\'s all, folks. 👋',
        text: `Didn't activate your Klue Button? Head <strong style="color: #49BB90">back</strong>
          and try again. You can <span onClick={this.refresh}>replay</span> this tutorial or return to
          your <a href="/guides/welcome">Klue guide</a>.`,
        selector: '.onboarding_kluebot--one',
        position: 'bottom'
      }
    ]);
  };

  toggleCompleted = () => {
    const {user} = this.props;

    // Update the user object
    const featureFlag = [['onboarding', 'extGuide'], true];

    // Sync with server
    this.updateUser(user, {featureFlag});
  };

  // TODO: migrate this to use this.context.api.userUpdate instead
  updateUser = (user, userParams) => {
    console.log('ExtensionOnboarding.updateUser: %o, userParams: %o', user, userParams);

    const updateComplete = new Promise(
      function(resolve, reject) {
        resolve(
          $.ajax({
            url: '/api/users/' + user.id + '.json',
            type: 'PUT',
            dataType: 'json',
            data: JSON.stringify(userParams),
            contentType: 'application/json; charset=utf-8',
            success: function(userObj) {
              if(this._isMounted) {
                console.log('ExtensionOnboarding.updateUser: updated user: %o', userObj);
              }
            }.bind(this),
            error: function(xhr) {
              if(this._isMounted) {
                console.error('ExtensionOnboarding.updateUser: error: %o', xhr);
              }
            }.bind(this)
          })
        );
        reject(null);
      }.bind(this)
    );

    updateComplete.then(
      function() { return this.proceedToGuide(); }.bind(this),
      function() { return false; }
    );
  };

  render() {
    const {screen} = this.state;

    // Joyride Steps - are we on the right screen or not?
    const step0 = screen === -1 ? true : false;
    const step1 = screen === 0 ? true : false;
    const step2 = screen === 1 ? true : false;
    const step3 = screen === 2 ? true : false;
    const step4 = screen === 3 ? true : false;

    const flexStyles = {
      visibility: step2 ? 'visible' : 'hidden',
      opacity: step2 ? 1 : 0,
      pointerEvents: step2 ? 'all' : 'none'
    };

    const containerClasses = classNames({
      overlay: this.state.inExt,
      onboarding: true,
      'layout-news-flex': true
    });

    const joyrideLocale = {
      back: (<span>Back</span>),
      close: (<span>Close</span>),
      last: (<span>Finish</span>),
      next: (<span>Next</span>),
      skip: (step4) ? (<span>Replay tutorial.</span>) : (<span>Skip tutorial?</span>)
    };

    return (
      <div className={containerClasses}>
        <KlueBot
          motion={onboardingData.xy.bot}
          toggle={step1 || step4}
          cb={this.animatingChildCallback} />

        <div className="layout-news-flex_desktop" style={flexStyles}>
          <div className="layout-news-flex_web" />
          <div className="layout-news-flex_fill" />
          <div className="layout-news-flex_feed" />
        </div>

        <div className="layout-news-flex_bottom">
          <BrowserFrame
            cache={step0}
            motion={onboardingData}
            toggle={step2 || step3}
            advanceJoyride={step2}
            company={this.props.company.name}
            cb={this.animatingChildCallback} />
        </div>
        <Joyride
          ref="joyride"
          type="continuous"
          showSkipButton={true}
          disableOverlay={true}
          keyboardNavigation={false}
          steps={this.state.steps}
          showOverlay={this.state.overlay}
          callback={this.joyrideCallback}
          locale={joyrideLocale} />
      </div>
    );
  }

}

export default withRouter(ExtensionOnboarding);
