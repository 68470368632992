import React from 'react';
import PropTypes from 'prop-types';

import InputPlaceholder from './_input_placeholder';

const OperatingMetricDropdown = ({onChange, loading, metrics, selectedMetric}) => {
  if(loading) {
    return (<InputPlaceholder />);
  }

  if(metrics.length === 0) {
    return (<InputPlaceholder text="None available" />);
  }

  const options = metrics.map(metricLabel => {
    return (<option key={metricLabel} value={metricLabel}>{metricLabel}</option>);
  });

  return (
    <select
      onChange={e => onChange(e.target.value)}
      value={selectedMetric}
      className="klue-formula-wizard_control-input klue-formula-wizard_dropdown-menu">
      {options}
    </select>
  );
};

OperatingMetricDropdown.propTypes = {
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  metrics: PropTypes.arrayOf(PropTypes.string),
  selectedMetric: PropTypes.string
};

OperatingMetricDropdown.defaultProps = {
  onChange() {},
  loading: false,
  metrics: [],
  selectedMetric: ''
};

export default OperatingMetricDropdown;
