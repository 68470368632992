import React, {Component} from 'react';
import PropTypes from 'prop-types';

import CompanyBuilder from './_company_builder';

import {normalizedCompanyFormulaParam, timeseriesFormula} from '../../../../../../modules/editor_utils';

class CompareTimeseriesBuilder extends Component {

  static contextTypes = {
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    funcName: PropTypes.string
  };

  static defaultProps = {
    onChange() {},
    funcName: ''
  };

  state = {
    companyAValue: '',
    companyBValue: ''
  };

  _generateFunction = () => {
    const {funcName} = this.props;
    const {companyAValue, companyBValue} = this.state;
    const {utils: {rival: {id: currentRivalId} = {}}} = this.context;

    return timeseriesFormula(funcName,
      normalizedCompanyFormulaParam(companyAValue, currentRivalId),
      normalizedCompanyFormulaParam(companyBValue, currentRivalId));
  };

  // eslint-disable-next-line react/destructuring-assignment
  _fireOnChange = () => this.props.onChange(this._generateFunction());

  _handleCompanyAChanged = companyA => this.setState({companyAValue: companyA}, () => this._fireOnChange());

  _handleCompanyBChanged = companyB => this.setState({companyBValue: companyB}, () => this._fireOnChange());

  render() {
    const {utils} = this.context;
    const {company: {id: companyId} = {}} = (utils || {});

    return (
      <div>
        <CompanyBuilder label="Company A" onChange={f => this._handleCompanyAChanged(f)} />
        <CompanyBuilder label="Company B" defaultCompanyId="currentCompany" onChange={f => this._handleCompanyBChanged(f)} />
      </div>
    );
  }

}

export default CompareTimeseriesBuilder;
