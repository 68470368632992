import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {formatLocation} from '../../../modules/card_utils';
import {EXTERNAL_LINK_CLASSNAME} from '../../../modules/constants/editor';

const JobPosting = props => {
  const {city, state, country, url, position, postedDate, source} = props;
  const loc = formatLocation({city, state, country});

  return (
    <div className="job-posting">
      <p className="job-posting_title">
        <a href={url} target="_blank" title="View this posting" className={EXTERNAL_LINK_CLASSNAME}>
          {position}{loc && (<span className="job-posting_location">, {loc}</span>)}
        </a>
      </p>
      <p className="job-posting_meta-line">
        <span className="job-posting_posted-date">
          {`Posted ${moment(postedDate).fromNow()}`}
        </span>
        <span className="job-posting_source">
          {source}
        </span>
      </p>
    </div>
  );
};

JobPosting.propTypes = {
  position: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  postedDate: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired
};

JobPosting.defaultProps = {
  position: '',
  city: '',
  state: '',
  country: '',
  postedDate: '',
  url: '',
  source: ''
};

export default JobPosting;
