/* eslint-disable no-unused-react-component-methods/no-unused-react-component-methods */
import {Field} from 'react-final-form';

import InputSelector from '../components/_input_selector';
import {getTooltip} from '../modules/new_board_wizard_utils';
import classNames from 'classnames';

class InputSelectorGroup extends React.PureComponent {

  handleItemClicked = (item, input, value) => {
    const {type, onItemClick} = this.props;

    if(type === 'radio') {
      onItemClick(item);

      return input.onChange(item);
    }

    // handle checkbox
    const selected = (value || []).slice();

    const selectedIds = selected.map(i => i.id);
    const foundIndex = selectedIds.indexOf(item.id);

    if(foundIndex >= 0) {
      selected.splice(foundIndex, 1);
    }
    else {
      selected.push(item);
    }

    onItemClick(selected);
    input.onChange(selected);
  };

  getSelected = value => {
    const {type} = this.props;

    if(type === 'radio') {
      return value ? [value] : [];
    }

    // handle checkbox
    return value ? value : [];
  };

  getValue = value => {
    const {type} = this.props;

    if(type === 'radio') {
      return value ? typeof value === 'string' ? value : value.name : null;
    }

    // handle checkbox
    return (value && value.length)
      ? ((value.length === 1)
        ? typeof value[0] === 'string'
          ? value[0]
          : value[0].name
        : `${value.length} Selected`)
      : null;
  };

  handleAddNewItem = (groupName, input, value) => {
    const {type, onAddNewItem} = this.props;

    onAddNewItem(groupName)
      .then(({group}) => {
        if(type === 'radio') {
          return input.onChange(group);
        }

        const selected = (value || []).slice();
        const foundIndex = selected.indexOf(group);

        if(foundIndex < 0) {
          selected.push(group);
          input.onChange(selected);
        }
      }).catch(({error}) => {console.log(error);});
  };

  render() {
    const {
      title,
      name,
      label,
      type,
      groups,
      unassignedLabel,
      onAddNewItem,
      addNewPlaceholder,
      onSelectAll,
      onClearAll,
      isFocused,
      titleDataTrackingId,
      selectorDataTrackingId
    } = this.props;
    const {getSelected, getValue, handleItemClicked, handleAddNewItem} = this;

    return (
      <div className="input-selector-group">
        <div className="input-selector-group_header" data-tracking-id={titleDataTrackingId}>
          <label className={classNames({focus: isFocused})}>{title}</label>
          {(onSelectAll || onClearAll) && (
            <div className="selector-buttons">
              {onSelectAll && (
                <button type="button" onClick={onSelectAll} data-test-id="selector-buttons_select-all">select all</button>
              )}
              {onClearAll && (
                <button type="button" onClick={onClearAll} data-test-id="selector-buttons_clear-all">clear all</button>
              )}
            </div>
          )}
        </div>
        <Field
          name={name}
          component={({input}) => {
            const {value} = input;

            return (
              <InputSelector
                type={type}
                label={label}
                groups={groups}
                selected={getSelected(value)}
                value={getValue(value)}
                tooltip={getTooltip(value, type)}
                unassignedLabel={unassignedLabel}
                dataTrackingId={selectorDataTrackingId}
                onItemClick={item => handleItemClicked(item, input, value)}
                onAddNewItem={onAddNewItem ? groupName => handleAddNewItem(groupName, input, value, onAddNewItem) : null}
                addNewPlaceholder={addNewPlaceholder} />
            );
          }} />
      </div>
    );
  }

}

InputSelectorGroup.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  titleDataTrackingId: PropTypes.string,
  selectorDataTrackingId: PropTypes.string,
  unassignedLabel: PropTypes.string,
  onAddNewItem: PropTypes.func,
  onItemClick: PropTypes.func,
  addNewPlaceholder: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.object),
  onSelectAll: PropTypes.func,
  onClearAll: PropTypes.func,
  isFocused: PropTypes.bool
};

InputSelectorGroup.defaultProps = {
  title: '',
  name: '',
  label: '',
  type: 'radio',
  titleDataTrackingId: null,
  selectorDataTrackingId: null,
  unassignedLabel: 'Unassigned',
  onAddNewItem: null,
  onItemClick() {},
  addNewPlaceholder: '',
  groups: [],
  onSelectAll: null,
  onClearAll: null,
  isFocused: false
};

export default InputSelectorGroup;
