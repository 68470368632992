export const CURATORS_ONLY = 'curators_only';
export const EVERYONE = 'everyone';
export const CURATORS_AND_GROUPS = 'curatorsandgroups';
export const FULL_ACCESS_USER_LABEL = 'Full Access Users';
export const FULL_ACCESS_USER_LABEL_V2 = 'Full Access Users (IMPROVED!)';

export const cardPermissions = Object.freeze({
  [CURATORS_ONLY]: {
    label: 'Curators only'
  },
  [EVERYONE]: {
    label: 'Everyone'
  },
  [CURATORS_AND_GROUPS]: {
    label: 'Curators and...'
  }
});
