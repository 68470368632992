// Motion
import {spring, Motion, StaggeredMotion, presets} from 'react-motion';
import cx from 'classnames';
const smooth = presets.noWobble;
const stiff  = presets.stiff;

import placeholders from './_placeholders';
import onboardingData from '../_onboarding_data';
import news from './_real_news';

const PlaceholdersHorizontal = placeholders._PlaceholdersHorizontal;
const RealNewsHorizontal = news.horizReal;
const RealNewsHorizontalBig = news.horizRealBig;
const KlueCrunchLogo = news.logo;
const data = onboardingData;

class BrowserFrame extends React.Component {

  static propTypes = {
    cb: PropTypes.func.isRequired,
    motion: PropTypes.object.isRequired,
    toggle: PropTypes.bool.isRequired
  };

  static defaultProps = {
    cb() {},
    motion: null,
    toggle: false,
    cache: true
  };

  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.toggle) { return; }

    if(prevProps.advanceJoyride !== this.props.advanceJoyride) {
      setTimeout(() => this.cb(), 300);
    }
  }

  cb = () => {
    if(!this.props.toggle) { return; }
    if(this.props.toggle) { this.props.cb(); }
  };

  render() {
    const motion = this.props.motion.xy.frame;
    const cache = this.props.cache;
    const styles = {
      y: spring(this.props.toggle ? motion.to : motion.from, stiff)
    };

    return (
      <Motion style={styles} onRest={this.cb}>
        {s => {
          const moveTo = {transform: 'translateY(' + s.y + 'vh)'};
          const clx = cx({
            'layout-browser-frame': true,
            'layout-browser-frame--cache': cache
          });

          return (
            <div className={clx}>
              <div className="browser-frame" style={moveTo}>
                <div className="browser-frame_top-bar">
                  <div className="browser-frame_button-wrapper">
                    <div className="browser-frame_button" />
                    <div className="browser-frame_button" />
                    <div className="browser-frame_button" />
                  </div>
                  <div className="browser-frame_address" />
                  <div className="browser-frame_klue-button">
                    <img src="/klue-logo-128x128.png" alt="Klue Button" />
                  </div>
                  <div className="browser-frame_extensions">
                    <div className="browser-frame_extensions_dots" />
                    <div className="browser-frame_extensions_dots" />
                    <div className="browser-frame_extensions_dots" />
                  </div>
                </div>
                <div className="browser-frame_main">
                  <BrowserFrameTop
                    els={this.props.motion.news}
                    toggle={this.props.toggle}
                    company={this.props.company} />
                  {!this.props.toggle && <BrowserFrameBottom />}
                </div>
              </div>
            </div>
          );
        }}
      </Motion>
    );
  }

}

class BrowserFrameTop extends React.Component {

  render() {
    // Three Rows
    const els = this.props.els;
    // Basically, create an array of objects with values to animate
    const isolateStyles = _.range(_.keys(els).length).map(_.constant({h: 10}));
    // Staggered animation function
    const jazzhands = function(prev) {
      return (
        prev.map((z, i) => {
          return i === 0
            ? {h: spring(0, stiff)}
            : {h: spring((prev[i - 1].h), stiff)};
        })
      );
    };

    return (
      <StaggeredMotion
        defaultStyles={isolateStyles}
        styles={jazzhands}>
        {function(prev) {
          return (
            <div className="browser-frame_main--bottom">
              {prev.map((p, i) => {
                const newsItem = _.values(els);

                return (
                  <div key={i} style={{transform: 'translateY(' + p.h + 'em)'}}>
                    {i === 0 && <KlueCrunchLogo els={newsItem[0]} />}
                    {i === 1 && <RealNewsHorizontalBig els={newsItem[1]} company={this.props.company} />}
                    {i === 2 && <RealNewsHorizontal els={newsItem[2]} />}
                  </div>
                );
              })}
            </div>
          );
        }.bind(this)}
      </StaggeredMotion>
    );
  }

}

class BrowserFrameBottom extends React.Component {

  static propTypes = {
    toggle: PropTypes.bool.isRequired
  };

  static defaultProps = {
    toggle: false
  };

  render() {
    const toggle = this.props.toggle;
    const styles = {
      x: spring(toggle ? -15 : 0, stiff),
      o: spring(toggle ? 0 : 1, stiff)
    };

    return (
      <Motion style={styles}>
        {s => {
          const moveTo = {
            opacity: s.o,
            transform: 'translateX(' + s.x + 'vh)'
          };

          return (
            <div className="browser-frame_main--top" style={moveTo}>
              <PlaceholdersHorizontal />
              <PlaceholdersHorizontal />
              <PlaceholdersHorizontal />
            </div>
          );
        }}
      </Motion>
    );
  }

}

export default BrowserFrame;
