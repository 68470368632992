import classNames from 'classnames';

import DigestMenuIconButton from './_digest_menu_icon_button';

const DigestBodyFooter = ({
  onCopyToClipboardDisabled,
  onCopyToClipboard
}) => {
  return (
    <div className="digest-body_footer">
      <DigestMenuIconButton
        icon="copy_content"
        title="copy to clipboard"
        dataTrackingId="copy-digest-to-clipboard-button"
        onClick={onCopyToClipboard}
        className={classNames({disabled: onCopyToClipboardDisabled})} />
    </div>
  );
};

DigestBodyFooter.propTypes = {
  onCopyToClipboardDisabled: PropTypes.bool,
  onCopyToClipboard: PropTypes.func
};

DigestBodyFooter.defaultProps = {
  onCopyToClipboardDisabled: false,
  onCopyToClipboard() {}
};

export default DigestBodyFooter;
