export const showIntercomMessage = message => {
  if(!message) {
    return;
  }

  window.Intercom('showNewMessage', message);
};

export const dig = (object, keys, defaultVal) => {
  if(_.isEmpty(object) || !keys) {
    return defaultVal;
  }

  keys = Array.isArray(keys) ? keys : keys.split('.');
  object = object[keys[0]];

  if(object && keys.length > 1) {
    return dig(object, keys.slice(1), defaultVal);
  }

  return object === undefined ? defaultVal : object;
};

// validate that an id is >= or > 0 depending on argument value
export const isValidId = (id, isZeroIdValid = false) =>
  ['string', 'number'].some(type => (typeof id) === type)
  && Boolean(String(id).length)
  && Number(id) >= (isZeroIdValid ? 0 : 1);

export const getRandomIntInRange = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1) + min);

// promise wrapper for setTimeout
export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

// deduplicate an array of objects based on a specific property name (use for int/string props)
export const unique = (array = [], prop = '') => {
  if(!prop) {
    return array;
  }

  return array.filter((r, index, arr) => arr.findIndex(a => a[prop] === r[prop]) === index);
};

// formatter helper for serializeQueryString below, params with null/NaN values are skipped
const formatQueryStringPair = (k, v) => {
  return (!_.isNil(v) && !_.isNaN(v)) ? `${encodeURIComponent(k)}=${encodeURIComponent(v)}` : '';
};

// expects params to be an hash of key/value pairs of query parameters
// source: https://stackoverflow.com/a/43105324/226756 (modified to support rails-style array params)
export const serializeQueryString = (params = {}) => {
  if(_.isEmpty(params) || !_.isObject(params)) {
    return '';
  }

  return [].concat(
    ...Object.entries(params).map(([k, v]) => {
      return Array.isArray(v)
        ? v.map(arr => serializeQueryString({[`${k}[]`]: arr}))
        : formatQueryStringPair(k, v);
    }))
    .filter(Boolean)
    .join('&');
};

// helper fn to detect supported transition event property name
// credit: http://davidwalsh.name/css-animation-callback
export const whichTransitionEvent = () => {
  const el = document.createElement('transitionTestEl');
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd'
  };

  for(const t in transitions) {
    if(el.style[t] !== undefined) {
      return transitions[t];
    }
  }
};

export const redirectToFeed = (event, doRefresh = false, targetUrl = '') => {
  if(event) {
    event.preventDefault();
  }

  const {parent} = window || {};
  const {target: {href} = {}} = event || {};

  if(parent.opener) {
    const {opener} = parent;

    // focus opener and close current tab/window
    if(doRefresh) {
      opener.location.reload();
    }

    parent.blur();
    opener.focus();
    parent.close();
  }
  else {
    // navigate to original link
    return Boolean(href || targetUrl) && window.open(href || targetUrl, 'klue-feed');
  }
};

export const arrayIsEqual = (array1, array2) => {
  if(array1.length !== array2.length) {
    return false;
  }

  const sortedA = array1.slice().sort();
  const sortedB = array2.slice().sort();

  return sortedA.every((value, index) => value === sortedB[index]);
};

// borrowed from:
// https://github.com/react-dnd/react-dnd/commit/4c1c940cfd833f5d5d1437a10ffdada9beffc2eb
export const isClassComponent = Component => Boolean(
  Component && Component.prototype && (typeof Component.prototype.render === 'function')
);

export const subtractDaysFromToday = days => moment().subtract(days, 'days').startOf('day');
