import {useEffect} from 'react';
import {useMicrosoftTeams} from '../hooks/useMicrosoftTeams';
import {setAuthSuccessful} from '../modules/user_utils';
import {analyticsInit, analyticsTrack} from '../modules/analytics_utils';
import KlueLogo from '../../images/klue-logo-black.svg';
import {sendMessageToExtensionBackground} from '../modules/ext_utils';

const LoginEmbedRedirect = ({appData}) => {
  const {teams} = useMicrosoftTeams({
    onSuccess() {
      console.log('MS Teams SDK: initialization successful');
    },
    onFailure(error) {
      console.error('MS Teams SDK: initialization failed', error);
    }
  });

  useEffect(() => {
    const {company, klueAnalyticsId, spAnalyticsId, spAnalyticsLegacy, spAnalyticsPath, spAnalyticsUrl, src, user} = appData;
    const accounts = [];

    if(klueAnalyticsId) {
      accounts.push({
        name: 'klue',
        provider: 'ga',
        id: klueAnalyticsId
      });
    }

    if(spAnalyticsId) {
      accounts.push({
        name: 'instanceAccount',
        provider: 'sp',
        appId: spAnalyticsId,
        collectorUrl: spAnalyticsUrl,
        postPath: spAnalyticsPath
      });
    }

    if(spAnalyticsLegacy) {
      accounts.push({
        name: 'LegacyInstanceAccount',
        provider: 'sp',
        appId: spAnalyticsId,
        collectorUrl: spAnalyticsUrl,
        postPath: spAnalyticsPath
      });
    }

    analyticsInit({user, company, accounts, spAnalyticsLegacy});
    analyticsTrack({
      type: 'event',
      category: 'Embed',
      action: 'login-success',
      label: src
    });
    // authSuccessful flag is only used when app is embedded in iframe,
    // to force sibling iframes to reload upon successful auth.
    setAuthSuccessful();

    const {companyId, id, isImpersonating, isInternal, isStaff, roles} = user;

    sendMessageToExtensionBackground({type: 'klue:app:login', body: {
      companyId,
      id,
      isImpersonating,
      isInternal,
      isStaff,
      roles
    }});

    if(teams.isInitialized) {
      teams.authentication.notifySuccess();
    }
  }, [appData, teams]);

  return (
    <div className="layout-login layout-login-redirect-centered">
      <div className="layout-login-contain layout-login-contain--logo">
        <img alt="Klue" src={KlueLogo} />
      </div>
      <div className="layout-login-contain layout-login-contain--form">
        <p><strong>You have successfully logged in.</strong> You can close this window now.</p>
        {Boolean(window.opener) && (<button
          className="button button--next"
          style={{marginTop: '1rem'}}
          onClick={() => window.close()}>
          Close window
        </button>)}
      </div>
    </div>
  );
};

LoginEmbedRedirect.propTypes = {
  appData: PropTypes.object
};

LoginEmbedRedirect.defaultProps = {
  appData: {}
};

export default LoginEmbedRedirect;
