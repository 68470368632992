import React, {Component} from 'react';
import 'intersection-observer'; // polyfill
import Observer from '@researchgate/react-intersection-observer';

export default class WindowRoot extends Component {

  static propTypes = {
    rootMargin: PropTypes.string,
    children: PropTypes.any.isRequired,
    root: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  };

  static defaultProps = {
    rootMargin: '0px',
    root: null
  };

  state = {
    isVisible: false
  };

  handleChange = (event, unobserve) => {
    if(event.isIntersecting) {
      unobserve();
    }

    this.setState({
      isVisible: event.isIntersecting
    });
  };

  render() {
    const {root, rootMargin, children} = this.props;
    const {isVisible} = this.state;

    return (
      <Observer onChange={this.handleChange}
        root={root || null}
        rootMargin={rootMargin}>
        {children(isVisible)}
      </Observer>
    );
  }

}

