import React, {Component} from 'react';

const landColor = '#8e9992';
const waterColor = '#ffffff';
const markerColor = '#0B9360';

function color(feature, element, code) {
  return {
    featureType: feature,
    elementType: element,
    stylers: [
      {
        color: code
      }
    ]
  };
}

function disable(feature, element) {
  return {
    featureType: feature,
    elementType: element,
    stylers: [
      {
        visibility: 'off'
      }
    ]
  };
}

const mapStyles = [
  color('administrative.country', 'geometry.stroke', landColor),
  color('administrative.province', 'geometry.stroke', landColor),
  color('water', 'geometry', waterColor),
  disable('administrative', 'labels'),
  disable('administrative', 'geometry'),
  disable('landscape', 'geometry'),
  disable('landscape.natural', 'labels'),
  disable('poi', 'all'),
  disable('road', 'all'),
  disable('road', 'labels'),
  disable('transit', 'labels.icon'),
  disable('transit.line', 'geometry'),
  disable('transit.line', 'labels.text'),
  disable('transit.station.airport', 'geometry'),
  disable('transit.station.airport', 'labels'),
  disable('water', 'labels')
];

class GoogleMap extends Component {

  static contextTypes = {
    appData: PropTypes.object.isRequired
  };

  state = {
    mapHolder: undefined
  };

  renderMap = async () => {
    const {mapHolder} = this.state;
    const {markers} = this.props;

    if(!mapHolder || !window.google || !window.google.maps) {
      return;
    }

    const map = new window.google.maps.Map(mapHolder, {
      styles: mapStyles,
      disableDefaultUI: true,
      zoom: 1,
      maxZoom: 1
    });

    map.fitBounds({
      east: 110,
      north: 40,
      south: -25,
      west: -90
    });

    for(const marker of markers) {
      const markOptions = {
        position: {lat: marker.lat, lng: marker.lng},
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          radius: 3,
          fillColor: markerColor,
          fillOpacity: 0.8,
          strokeColor: markerColor,
          strokeWeight: 3
        },
        map
      };

      // eslint-disable-next-line no-new
      new window.google.maps.Marker(markOptions);
    }
  };

  handleGoogleMapRef = async ref => {
    if(ref === null) {
      return;
    }

    this.setState({mapHolder: ref}, () => {
      this.renderMap();
    });
  };

  render() {
    return (
      <div className="card-map_container">
        <div className="card-map_map" ref={this.handleGoogleMapRef} />
      </div>
    );
  }

}

GoogleMap.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  })).isRequired
};

GoogleMap.defaultProps = {
  markers: []
};

export default GoogleMap;
