import {withRouter} from 'react-router-dom';
import {tagsGet} from '../modules/api/tags';
import {redirectConsumersToV2, redirectToV2} from '../modules/route_utils';
import {userCanCurate} from '../modules/roles_utils';
import {getSearchPathFromQuery} from '../utils/_search_utils';
import {AnalyticsEventProvider} from '../contexts/_analyticsEvent';
import CardTag from './card_templates/_card_tag';
import Icon from './_icon';

class SearchSuggester extends React.Component {

  constructor(props) {
    super(props);

    this.isCurator = null;
    this.showSearchContexts = null;

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    const {history: {location: {pathname = ''} = {}} = {}} = props;

    this.state = {
      value: pathname?.startsWith('/search') ? this.getSearchValueFromPathname(pathname) : '',  // current search box input
      suggestions: [],                // current suggestions
      hideSuggestions: false,
      mobileActive: false,
      searchContext: 'cards',
      isSuggesterOpen: false
    };
  }

  static contextTypes = {
    appData: PropTypes.object.isRequired,
    utils: PropTypes.shape({
      cardTagIsVisible: PropTypes.func.isRequired
    }).isRequired
  };

  static propTypes = {
    history: PropTypes.object
  };

  static defaultProps = {
    history: {}
  };

  componentDidMount() {
    this._isMounted = true;

    const {utils: {user}} = this.context;
    const {history} = this.props;

    this.isCurator = userCanCurate({user});
    this.showSearchContexts = this.isCurator;

    this.unlisten = history?.listen(location => {
      const {pathname} = location || {};

      if(!pathname) {
        return;
      }

      if(!pathname.startsWith('/search')) {
        // reset suggester when going to non-search page
        return this.clearSearch();
      }

      this.setState({value: this.getSearchValueFromPathname(pathname) || ''});
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.onSuggestionsFetchRequested?.cancel();
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.unlisten && this.unlisten();
  }

  getSearchValueFromPathname = pathname => decodeURIComponent(pathname.substr('/search/'.length));

  clearSearch = () => {
    this.setState({
      value: '',
      suggestions: [],
      hideSuggestions: false,
      mobileActive: false
    });
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if(this.state.hideSuggestions && this.state.value !== nextState.value) {
      this.setState({hideSuggestions: false});
    }

    const shouldFetchSuggestions = Boolean(nextState.value.length)
    && nextState.value !== this.state.value
     && nextState.searchContext === 'cards';

    if(shouldFetchSuggestions) {
      this.onSuggestionsClearRequested();
      this.onSuggestionsFetchRequested({value: nextState.value});
    }

    document[nextState.isSuggesterOpen ? 'addEventListener' : 'removeEventListener']('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const {isSuggesterOpen} = this.state;

    if(!isSuggesterOpen) {return;}

    const shouldHideSearch = this.wrapperRef && !this.wrapperRef.current.contains(event.target);

    if(shouldHideSearch) {
      this.setState({mobileActive: false});
      this.toggleSuggester(false);
      this.onSuggestionsClearRequested();
    }
  }

  showTags = () => Boolean(this.context?.utils?.cardTagIsVisible());

  /**
   * Issues new search
   * (routes to _search_query.js component)
   *
   * @param query
   * @param {string} query, the search query
   */
  search = (query = this.state.value) => {
    const path = getSearchPathFromQuery(query);
    const {appData: {v2Host}, utils: {user, company}} = this.context;

    if(!redirectConsumersToV2({v2Host, v2Path: path, user, company})) {
      this.setState({
        suggestions: [],
        hideSuggestions: true,
        mobileActive: false
      }, this.props.history.push(path)); // route to _search_query.js
    }
  };

  searchTag = tag => {
    const path = getSearchPathFromQuery('');
    const {appData: {v2Host}, utils: {user, company}} = this.context;

    if(!redirectConsumersToV2({v2Host, v2Path: path, user, company, v2Search: `?tags=${tag.id}`})) {
      this.setState({
        suggestions: [],
        hideSuggestions: true,
        mobileActive: false

      }, this.props.history.push(path, {tagClickedEvent: {id: tag.id}}));
    }
  };

  /**
   * Handles search request
   *
   * @param {object} event
   */
  handleSearch = event => {
    const {searchContext, value} = this.state;
    const {appData: {v2Host}, utils: {company}} = this.context;
    const encodedValue = encodeURIComponent(value);

    this.toggleSuggester(false);

    if(event) {
      event.preventDefault();
    }

    if(searchContext === 'alerts') {
      return redirectToV2({v2Host, v2Path: '/alerts', v2Search: `?q=${encodedValue}`});
    }

    if(searchContext === 'cards' && this.isCurator) {
      return redirectToV2({v2Host, v2Path: `/search/${encodedValue}`});
    }

    this.search();
  };

  // debounces suggestion requests to prevent large number of requests during typing
  onSuggestionsFetchRequested = _.debounce(args => this.doSuggestRequest(args.value), 400);

  /**
   * Executes suggestions API request
   *
   * @param {string} query, the query to find suggestions for
   * @param query
   * @param args
   * @param {object} args, the request options
   */
  doSuggestRequest = (query, args = {
    querySuggestions: true,                                   // if true, get query suggestions
    faqSuggestions: true,                                     // if true, get FAQ suggestions
    tagSuggestions: this.showTags(),                          // if tags are enabled, get tag suggestions
    querySuggestionsCallback: this.querySuggestionsCallback,  // query suggestions callback
    faqSuggestionsCallback: this.faqSuggestionsCallback,       // FAQ suggestions callback
    tagsSuggestionsCallback: this.tagsSuggestionsCallback
  }) => {
    const encodedQuery = encodeURIComponent(query);

    // get query suggestions
    args.querySuggestions && jQuery.ajax({
      url: `/api/suggest.json?mode=completions&query=${encodedQuery}`,
      method: 'GET',
      dataType: 'json',
      success(data) {
        args.querySuggestionsCallback(data);
      },
      error(xhr, type, error) {
        if(xhr.status !== 200) {
          console.error('Search Suggester: error [%s]: %o (%s)', type, xhr, error);
        }
      }
    });

    // get FAQ suggestions
    args.faqSuggestions && jQuery.ajax({
      url: `/api/suggest.json?mode=faq&query=${encodedQuery}`,
      method: 'GET',
      dataType: 'json',
      success(data) {
        args.faqSuggestionsCallback(data, query);
      },
      error(xhr, type, error) {
        if(xhr.status !== 200) {
          console.error('Search Suggester: error [%s]: %o (%s)', type, xhr, error);
        }
      }
    });

    args.tagSuggestions && tagsGet({filter: 'assigned,active', search: query})
      .then(({tags}) => {
        args.tagsSuggestionsCallback(tags);
      })
      .catch(err => {
        const message = err?.error?.message || 'unknown error';

        console.error(`Search Suggester: tags error - ${message}`);
      });
  };

  /**
   * Handles response from query suggestion API request
   *
   * @param data
   * @param {object} data, the suggestions request response
   */
  querySuggestionsCallback = data => {
    if(!this._isMounted) {
      return;
    }

    const querySuggestions = data.suggestions.map((completion, i) => {
      return {
        type: 'query',
        value: completion,
        id: completion.value || i
      };
    });

    this.updateSuggestions({querySuggestions});
  };

  /**
   * Handles response from FAQ suggestion API request
   *
   * @param data
   * @param {object} data, the suggestions request response
   */
  faqSuggestionsCallback = data => {
    if(!this._isMounted) {
      return;
    }

    const faqSuggestions = data.suggestions.map(faq => {
      return {
        type: 'faq',
        question: faq.question,
        answer: faq.answer,
        rival: faq.rival,
        id: faq.id
      };
    });

    this.updateSuggestions({faqSuggestions});
  };

  tagsSuggestionsCallback = tagsAndSynonyms => {
    if(!this._isMounted) {
      return;
    }

    const tagSuggestions = [];

    for(const tagOrSynonyms of tagsAndSynonyms) {
      if(tagOrSynonyms.synonyms?.length) {
        tagSuggestions.push(...tagOrSynonyms.synonyms.map(name => ({name, tag: tagOrSynonyms, type: 'synonym'})));
        continue;
      }

      tagSuggestions.push({tag: tagOrSynonyms, type: 'tag'});
    }

    this.updateSuggestions({tagSuggestions});
  };

  updateSuggestions = newSuggestions => this.setState(state => {
    const [
      querySuggestions,
      faqSuggestions,
      tagSuggestions
    ] = state.suggestions.reduce(
      (acc, suggestion) => {
        switch(suggestion.type) {
          case 'query':
            acc[0].push(suggestion);
            break;
          case 'faq':
            acc[1].push(suggestion);
            break;
          case 'tag':
          case 'synonym':
            acc[2].push(suggestion);
            break;
          default:
            break;
        }

        return acc;
      },
      [[], [], []]
    );

    const suggestionsObj = {
      querySuggestions,
      faqSuggestions,
      tagSuggestions,
      ...newSuggestions
    };

    const suggestions = suggestionsObj.querySuggestions
      .concat(
        suggestionsObj.faqSuggestions
      )
      .concat(
        suggestionsObj.tagSuggestions
      );

    // Don't update suggestions if the user has already deleted their query
    if(state.value.length === 0) {
      return state;
    }

    return {
      ...state,
      suggestions
    };
  });

  /**
   * Chooses render function for suggestion
   *
   * @param suggestion
   * @param {object} suggestion, the suggestion object
   * @returns {Element}
   */
  renderSuggestion = suggestion => {
    switch(suggestion.type) {
      case 'query':
        return this.renderQuery(suggestion);
      case 'faq':
        return this.renderFaq(suggestion);
      case 'tag':
        return this.renderTag(suggestion);
      case 'synonym':
        return this.renderSynonym(suggestion);
      default: return;
    }
  };

  /**
   * Render function for query suggestion
   *
   * @param query
   * @param {object} query, the query suggestion
   * @returns {Element}
   */
  renderQuery = query => (
    <a
      className="suggestion-body"
      onClick={() => {
        this.onSuggestionSelected(query);
      }}>
      <span className="suggestion-label">
        {query.value}
      </span>
    </a>
  );

  /**
   * Render function for FAQ suggestion
   *
   * @param faq
   * @param {object} faq, the FAQ suggestion
   * @returns {Element}
   */
  renderFaq = faq => (
    <a className="suggestion-body"
      onClick={() => {
        this.onSuggestionSelected(faq);
      }}>
      <span className="suggestion-label">
        {faq.question}
      </span>
    </a>
  );

  renderTag = ({tag}) => (
    <div className="suggestion-body card-tags">
      <CardTag
        key={tag.id.toString()}
        id={tag.id}
        name={tag.name}
        handleOnSelect={() => this.toggleSuggester(false)}
        origin="search_suggestion" />
      <Icon className="tag-icon" icon="tag" />
    </div>
  );

  renderSynonym = ({name, tag}) => (
    <div className="suggestion-body card-tags">
      <span className="suggestion-label">
        {name}
      </span>
      <Icon icon="arrow-right" />
      <CardTag
        id={tag.id}
        name={tag.name}
        handleOnSelect={() => this.toggleSuggester(false)}
        origin="search_suggestion" />
      <Icon className="tag-icon" icon="tag" />
    </div>);

    toggleSuggester = value => {
      this.setState({
        isSuggesterOpen: value
      });
    };

  /**
   * Handle search box query input change
   *
   * @param {object} event
   */
  onChange = event => {
    const {value} = event.target;

    // Clear all suggestions if search input has just been emptied
    if(!value) {
      this.onSuggestionsClearRequested();
    }

    this.setState({value});
  };

  onFocus = () => {
    this.toggleSuggester(true);
  };

  /**
   * Handle search box key press
   *
   * @param {object} event
   */
  handleKeyPress = event => {
    const {value, isSuggesterOpen} = this.state;

    if(Boolean(value) && !isSuggesterOpen) {
      this.toggleSuggester(true);
    }

    if(event.key === 'Enter') {
      event.preventDefault();
      this.handleSearch();
    }
  };

  /**
   * Clears all suggestions from component state
   */
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  /**
   * Handles suggestion selection
   *
   * @param suggestion,.suggestion
   */

  onSuggestionSelected = suggestion => {
    this.toggleSuggester(false);

    if(suggestion) {
      if(suggestion.type === 'faq') {
        // route to _search_answer.js component
        this.props.history.push(`/answer/${suggestion.id}/${encodeURIComponent(suggestion.rival)}`);
      }
      else if(suggestion.type === 'query') {
        if(suggestion.value && suggestion.value !== '') {
          this.search(suggestion.value); // issue new search
        }
      }
      else if(suggestion.type === 'tag' || suggestion.type === 'synonym') {
        this.searchTag(suggestion.tag);
      }
    }
  };

  handleToggleContext = context => {
    this.searchSuggester?.focus();

    const newState = {
      searchContext: context,
      isSuggesterOpen: true
    };

    if(context === 'alerts') {
      Object.assign(newState, {
        suggestions: []
      });
    }

    this.setState(newState);
  };

  render() {
    const {value, suggestions, searchContext, isSuggesterOpen, mobileActive, hideSuggestions} = this.state;
    const hasSuggestions = suggestions.length > 0;

    return (
      <>
        <div
          className={`navigation-search--wrapper ${mobileActive ? 'navigation-search--wrapper-mobile' : ''}`}
          data-tracking-id="navigation-search--wrapper">
          <div className={mobileActive ? 'navigation-search navigation-search--open' : 'navigation-search'} ref={this.wrapperRef}>
            <div className={hideSuggestions ? 'search-suggester hide-suggestions' : 'search-suggester'}>
              <input
                ref={input => { this.searchSuggester = input; }}
                id="searchSuggester"
                data-testid="search-suggester-input"
                className="search-suggester__input"
                type="text"
                autoComplete="off"
                placeholder="Search Klue"
                required={true}
                value={value}
                onChange={this.onChange}
                onKeyPress={this.handleKeyPress}
                onFocus={this.onFocus} />

              {isSuggesterOpen && (value.length > 0 || this.showSearchContexts) &&
              <AnalyticsEventProvider tagClickEvent={{category: 'Global Search', action: 'Search by Tag'}}>
                <div data-suggestions={hasSuggestions} className="search-suggester-layer">
                  {this.showSearchContexts && (<div
                    className="search-suggester-layer__content">
                    <span className="search-suggester-layer__label">Searching</span>
                    <div className="search-suggester-context" data-testid="search-contexts">
                      <button
                        data-testid="search-context-cards"
                        className={`search-suggester-context__button${searchContext === 'cards' ? '--active' : ''}`}
                        onClick={() => this.handleToggleContext('cards')}>All Cards</button>
                      <button
                        data-testid="search-context-alerts"
                        className={`search-suggester-context__button${searchContext === 'alerts' ? '--active' : ''}`}
                        onClick={() => this.handleToggleContext('alerts')}>All Alerts</button>
                    </div>
                  </div>
                  )}

                  {hasSuggestions &&
                  <ul className="search-suggester-list">
                    {suggestions.map((suggestion, index) =>
                      (<li
                        className="search-suggester-list__suggestion"
                          // eslint-disable-next-line react/no-array-index-key
                        key={`suggestion-${index}`}>
                        {this.renderSuggestion(suggestion)}
                      </li>)
                    )}
                  </ul>
                }
                </div>
              </AnalyticsEventProvider>
            }

              <button className="search-suggester-submit" data-testid="search-suggester-button" onClick={this.handleSearch}>
                <i className="fa fa-search" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

}

export default withRouter(SearchSuggester);
