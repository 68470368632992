import CardDisplay from './_card_display';
import CompanyLogo from './_company_logo';
import CardVitals from './_card_vitals';
import CardInfo from './_card_info';
import CardMeta from './card_templates/_card_meta';
import CardEditModal from '../components/editor/_card_edit_modal';
import CardMetaLink from './card_templates/_card_meta_link';

import {cardGet} from '../modules/api/cards';
import {refreshDynamicContent, maybeLaunchModalCardView} from '../modules/card_utils';
import {fetchCardSources} from '../modules/api/sources';
import {userCanCurate, userIsKluebot} from '../modules/roles_utils';
import {isValidId} from '../modules/utils';
import {uiViewports, BATTLE_CARD_LAYOUT} from '../modules/constants/ui';
import {AnalyticsEventProvider} from '../contexts/_analyticsEvent';

import ReactUpdate from 'immutability-helper';
import classNames from 'classnames';
import {Link, withRouter} from 'react-router-dom';
import CardTags from './card_templates/_card_tags';
import CardVisibilityGroups from './card_templates/_card_visibility_groups';

class BattlecardView extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    companyLastUpdated: PropTypes.string,
    maxBattlecardCards: PropTypes.number,
    showPreviewLinkOnCards: PropTypes.bool,
    layout: PropTypes.string,
    focusedCard: PropTypes.object,
    onFocusCard: PropTypes.func,
    updateBattlecardCards: PropTypes.func
  };

  static defaultProps = {
    history: {},
    location: {},
    companyLastUpdated: '',
    maxBattlecardCards: 8,
    showPreviewLinkOnCards: false,
    layout: BATTLE_CARD_LAYOUT.single,
    focusedCard: null,
    onFocusCard() {},
    updateBattlecardCards() {}
  };

  state = {
    cardSourceId: null,
    view: uiViewports.DESKTOP,
    cards: [],
    sources: new Map(),
    loadingSources: new Map(),
    cardsSorted: false
  };

  componentDidMount() {
    console.log('BattlecardView.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    // TODO: convert this to reference profileId/battlecardId directly from params (see Profile)
    const {rival, battlecard} = this.context.utils;

    if(battlecard) {
      this.loadBattlecardCards(battlecard);
    }

    if(rival) {
      // load extended rival/profile data including stats
      this.context.api.rivalGet({rivalId: rival.id}, 'BattlecardView.componentDidMount').then(rivalData => {
        console.log('BattlecardView.componentDidMount: loaded extended rival data: %o', rivalData);
      });
    }

    this.registerLinksListenerEvent();
  }

  componentWillUnmount() {
    this._isMounted = false;

    const {battlecardViewRef: element} = this;

    element && element.removeEventListener('click', this.handleCardLinksListener, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    // TODO: convert this to reference profileId/battlecardId directly from params (see Profile)
    const {rival} = this.context.utils;
    const nextRival = nextContext.utils.rival;

    if(nextRival && (!rival || (rival.id !== nextRival.id))) {
      // load extended rival/profile data including stats
      this.context.api.rivalGet({rivalId: nextRival.id}, 'BattlecardView.componentWillReceiveProps').then(rivalData => {
        console.log('BattlecardView.componentWillReceiveProps: loaded extended rival data: %o', rivalData);
      });
    }

    const {battlecard} = this.context.utils;
    const nextBattlecard = nextContext.utils.battlecard;

    if(nextBattlecard && !battlecard) {
      console.log('BattlecardView.componentWillReceiveProps: load battlecardId #%o: %o', nextBattlecard.id, nextBattlecard);

      // battlecard may not be loaded on initial mount
      this.loadBattlecardCards(nextBattlecard);
    }
    else if(nextBattlecard && battlecard && (nextBattlecard.id !== battlecard.id)) {
      console.log('BattlecardView.componentWillReceiveProps: switching to battlecardId #%o: %o', nextBattlecard.id, nextBattlecard);

      // switching between battlecards, clear sorted cards & reload
      this.setState({
        cardsSorted: false
      }, () => {
        // chain cards clearing so sorted flag is set to false first to prevent unsorted cards from showing
        this.setState({
          cards: []
        }, () => {
          this.loadBattlecardCards(nextBattlecard);
        });
      });
    }
    else if(nextProps.companyLastUpdated !== this.props.companyLastUpdated) {
      console.log('BattlecardView.componentWillReceiveProps: refreshing battlecard: %o, updated: %o', nextBattlecard, nextProps.companyLastUpdated);

      // clear out cards in state before reloading battlecard contents
      this.setState({
        cardsSorted: false
      }, () => {
        // chain cards clearing so sorted flag is set to false first to prevent unsorted cards from showing
        this.setState({
          cards: []
        }, () => {
          this.loadBattlecardCards(nextBattlecard);
        });
      });
    }
  }

  componentDidUpdate() {
    this.registerLinksListenerEvent();
  }

  registerLinksListenerEvent = () => {
    const {battlecardViewRef: element} = this;

    element && element.addEventListener('click', this.handleCardLinksListener, {capture: true});
  };

  /**
   * Detect internal card links
   * Redirect to the target card profile page
   *
   * @param {object} event Mouse click
   * @memberof BattlecardView
   * @returns {undefined}
   */
  handleCardLinksListener = event => {
    const {history} = this.props;

    maybeLaunchModalCardView(event, history);
  };

  _getProfile = () => {
    const {profile = {}} = this.context.utils.rival || {};

    return profile;
  };

  getPreviewingID = () => {
    const {location} = this.props;
    const queryParams = new URLSearchParams(location.search);
    const previewGroup = queryParams.get('previewing');
    const visibilityGroup = parseInt(previewGroup, 10);

    return isValidId(visibilityGroup, true) ? visibilityGroup : null;
  };

  handleCardClick = (card, event) => {
    const {history} = this.props;
    const {user} = this.context.utils;
    const isCurator = userCanCurate({user});

    if(event) {
      event.preventDefault();
    }

    const profile = this._getProfile();

    if(!card || !profile) {
      return;
    }

    const previewID = this.getPreviewingID();

    if(previewID !== null) {
      return history.push(`/profile/${profile.id}/${isCurator ? 'edit' : 'view'}?previewing=${previewID}#${card.board.id}/${card.id}`);
    }

    history.push(`/profile/${profile.id}/${isCurator ? 'edit' : 'view'}#${card.board.id}/${card.id}`);
  };

  handleCardEditClick = card => {
    const {board, id: cardId} = card;
    const {profileId, id: boardId} = board || {};
    const {utils} = this.context;
    const {user} = utils;

    if(!cardId || !boardId || !profileId) {
      return;
    }

    this.setState({editCard: {
      cardId,
      boardId,
      profileId,
      authorId: user.id
    }});
  };

  handleCardModalClose = () => {
    this.setState({editCard: {}});
  };

  refreshBattlecardCard = card => {
    const {id: cardId} = card || {};
    const cardOptions = {cardId};

    cardGet({cardOptions, code: 'BattlecardView.cardGet'})
      .then(refreshedCard => {
        if(this._isMounted && refreshedCard) {
          const {cards: stateCards, loadingSources, sources} = this.state;
          const cards = stateCards.map(c => ((c.id === card.id) ? refreshedCard : Object.assign({}, c)));
          const loadingSourcesCopy = new Map(loadingSources);
          const sourcesCopy = new Map(sources);

          loadingSourcesCopy.delete(cardId);
          sourcesCopy.delete(cardId);

          this.setState({
            cards,
            loadingSources: loadingSourcesCopy,
            sources: sourcesCopy
          });
        }
      }).catch(err => console.warn('BattlecardView.refreshBattlecardCard: unable to load card', err));
  };

  handleCardModalAfterUpdate = cardData => {
    this.refreshBattlecardCard(cardData);
    this.handleCardModalClose();
  };

  loadBattlecardCards = battlecard => {
    const {view} = this.state;

    if(!battlecard || _.isEmpty(battlecard.cards) || !battlecard.cards[view]) {
      return;
    }

    console.log('BattlecardView.loadBattlecardCards: battlecard: %o', battlecard);

    const cards = battlecard.cards[view].slice();
    const deferreds = [];

    if(!cards || !cards.length) {
      this.setState({
        cardsSorted: true
      });

      return;
    }

    for(let i = 0; i < cards.length; i++) {
      const cardId = cards[i];

      if(cardId === 0) {
        console.log('BattlecardView.loadBattlecardCards: fetching vitals card');

        deferreds.push(
          this.getVitalsCard()
        );
      }
      else if(cardId) {
        console.log('BattlecardView.loadBattlecardCards: fetching cardId #%o', cardId);

        try {
          deferreds.push(this.getCard(cardId));
        }
        catch(e) {
          // invalid cardId
          console.warn('BattlecardView.loadBattlecardCards: unable to load card', e);
        }
      }
    }

    Promise.all(deferreds).then(() => {
      console.log('BattlecardView.loadBattlecardCards: loaded all battlecard cards, sorting...');

      this.sortCards();
      refreshDynamicContent();
    });
  };

  getVitalsCard = () => {
    // vitals card
    const vitalsCard = {
      id: 0
    };

    return new Promise(resolve => {
      this.setState({
        cards: ReactUpdate(this.state.cards, {$push: [vitalsCard]})
      }, () => {
        console.log('BattlecardView.getVitalsCard: loaded vitals card for cardId #0: %o', vitalsCard);

        resolve();
      });
    });
  };

  getCard = cardId => {
    return new Promise((resolve, reject) => {
      if(!isValidId(cardId)) {
        return reject(cardId);
      }

      const visibilityGroupId = this.getPreviewingID();
      const cardOptions = {cardId, visibilityGroupId};

      cardGet({cardOptions, code: 'BattlecardView.getCard'})
        .then(card => {
          if(this._isMounted) {
            console.log('BattlecardView.getCard: got cardId #%o: %o', cardId, card);

            if(card) {
              const {cards} = this.state;

              this.setState({
                cards: ReactUpdate(cards, {$push: [card]})
              });
            }

            resolve(card);
          }
        });
    });
  };

  sortCards = () => {
    const {battlecard} = this.context.utils;

    if(!battlecard || _.isEmpty(battlecard.cards) || !battlecard.cards[this.state.view]) {
      return;
    }

    const cards = this.state.cards.slice() || [];
    const cardPositions = battlecard.cards[this.state.view].slice() || [];
    const sortedCards = [];

    for(let i = 0; i < cardPositions.length; i++) {
      sortedCards[i] = cards.find(c => c && (c.id === cardPositions[i]));
    }

    this.setState({
      cards: sortedCards,
      cardsSorted: true
    }, () => {
      console.log('BattlecardView.sortCards: sorted cards: %o, cardPositions: %o', this.state.cards, cardPositions);

      if(!this.props.focusedCard) {
        this.props.onFocusCard(sortedCards[sortedCards.findIndex(card => card !== null)]);
      }

      this.props.updateBattlecardCards(sortedCards);
    });
  };

  renderVitalsCard = () => {
    return (
      <div className="battlecard-slot-card">
        <CardVitals
          showToc={false}
          showCurators={false} />
      </div>
    );
  };

  showCardMeta = (card = null) => {
    if(!card) {
      return this.setState({cardSourceId: null});
    }

    const {id, sourcesCount} = card;
    const {sources, loadingSources} = this.state;
    const cardSources = sources?.get(id);

    if(!cardSources) {
      if(sourcesCount === 0) {
        return this.setState({
          sources: new Map(sources.set(id, [])),
          cardSourceId: id
        });
      }

      return this.setState({loadingSources: loadingSources.set(id, true)}, () => {
        fetchCardSources(card)
          .then(({sources: fetchedSources}) => {
            if(!this._isMounted) {
              return;
            }

            this.setState({
              loadingSources: new Map(loadingSources.set(id, false)),
              sources: new Map(sources.set(id, fetchedSources)),
              cardSourceId: id
            });
          }).catch(error => {
            if(!this._isMounted) {
              return;
            }

            console.error('BattlecardView.showCardMeta', error);

            this.setState({loadingSources: new Map(loadingSources.set(id, false))});
          });
      });
    }

    this.setState({cardSourceId: id});
  };

  updateSourceList = updatedSources => {
    const {sources, cardSourceId} = this.state;

    if(!updatedSources || !updatedSources.length) {
      const sourcesCopy = new Map(sources);
      const {cards} = this.state;
      const cardsState = {};
      const index = cards.findIndex(c => c.id === cardSourceId);

      cardsState[index] = {$set: {...cards[index], sourcesCount: 0}};

      sourcesCopy.delete(cardSourceId);

      return this.setState({
        cards: ReactUpdate(cards, cardsState),
        sources: sourcesCopy
      });
    }

    this.setState({
      sources: new Map(sources.set(cardSourceId, updatedSources))
    });
  };

  getClassForVisibilityGroup = ({isDraft = false, allAccess = false}) => {
    return {
      'is-draft': isDraft,
      'all-access': !isDraft && allAccess,
      'visibility-grouped': !isDraft && !allAccess,
      'with-visibility': true
    };
  };

  renderNormalCard = (card, index) => {
    const {data, board, author, createdAt, updatedAt, id, allAccess, isDraft} = card;
    const {loadingSources, sources} = this.state;
    const {utils: {user, company, rival, isVisibilityGroupsOnBattlecardsEnabled}} = this.context;
    const {companyData: {showCardInfo = true}} = company;
    const isCurator = userCanCurate({user});
    // card titles
    const cardName = data.name
      || (<div className="board-name" title={board.name}><i className="fa fa-inbox board-header_title_icon" /> {board.name}</div>)
      || 'Untitled';
    const uiCardTitle = (
      <h1 className="card-title" title={cardName}>
        {cardName}
      </h1>
    );
    let uiCardMoreLink;

    if(this.props.showPreviewLinkOnCards) {
      uiCardMoreLink = (
        <a href="#" className="card-preview-more" onClick={e => this.handleCardClick(card, e)}>More</a>
      );
    }

    const uiCardEditLink = (
      <a href="#" className="card-preview-edit" onClick={() => this.handleCardEditClick(card)} data-testid="card-preview-edit">
        <i className="fa fa-edit" style={{position: 'relative', top: 2, fontSize: 16}} />
      </a>
    );

    const cardInfo = (isCurator || showCardInfo) && (
      <CardInfo
        author={author}
        createdAt={createdAt}
        updatedAt={updatedAt} />
    );

    const uiCardContents = (
      <div className={classNames('battlecard-slot-card', isVisibilityGroupsOnBattlecardsEnabled() ?
        this.getClassForVisibilityGroup({allAccess, isDraft}) : {})}>
        <AnalyticsEventProvider tagClickEvent={{category: 'Battlecard'}} viewContext="battlecard">
          <div className="card-preview card-block">
            <div className="card-preview-header">
              {uiCardTitle}<div data-tracking-id={`card-preview-controls_${index}_${id}`} className="card-preview-controls">
                <CardMetaLink
                  card={card}
                  sources={sources.get(id)}
                  user={user}
                  isBattlecard={true}
                  loading={Boolean(loadingSources.get(id))}
                  onShowCardMeta={() => this.showCardMeta(card)} />
                {isCurator && uiCardEditLink}
                {uiCardMoreLink}
              </div></div>
            <CardDisplay card={card} rival={rival} previewMode={true} />
          </div>
          <CardTags card={card} editable={true} />
        </AnalyticsEventProvider>
        {cardInfo}
      </div>
    );

    return uiCardContents;
  };

  getCards = () => {
    const profile = this._getProfile();
    let cardNodes;

    if(profile && this.state.cards) {
      cardNodes = this.state.cards.slice();
    }
    else {
      cardNodes = [];
    }

    // trim cards to the max battle card limit
    if(cardNodes.length > this.props.maxBattlecardCards) {
      cardNodes = cardNodes.slice(0, this.props.maxBattlecardCards);
    }

    if(this.props.layout !== BATTLE_CARD_LAYOUT.single) {
      // strip empty slots
      cardNodes = cardNodes.filter(card => Boolean(card && (card.data || card.id === 0)));
    }

    if(this.props.layout === BATTLE_CARD_LAYOUT.single) {
      const cardCount = cardNodes.length || 0;

      cardNodes.length = this.props.maxBattlecardCards;
      cardNodes.fill(undefined, cardCount);
    }

    return cardNodes;
  };

  handleCardPermissionsUpdated = ({card: updatedCard}) => {
    const {cards} = this.state;
    const cardsState = {};

    const index = cards.findIndex(c => c.id === updatedCard.id);

    cardsState[index] = {$set: {...updatedCard}};

    return this.setState({
      cards: ReactUpdate(cards, cardsState)
    });
  };

  renderCards = () => {
    const {utils: {isQuickChangeVisibilityGroupEnabled, isVisibilityGroupsOnBattlecardsEnabled}} = this.context;

    const cardNodes = this.getCards();
    const {focusedCard, layout} = this.props;
    const quickChangeEnabled = isQuickChangeVisibilityGroupEnabled();
    const isStackerLayout = layout === BATTLE_CARD_LAYOUT.stacker;

    // turn each data item in the card array into the corresponding JSX
    const uiCards = cardNodes.map((cardNode, index) => {
      const emptyCardKey = `card-empty_${index}`;
      const isCurrent = (cardNode && focusedCard && focusedCard.id === cardNode.id) || (isStackerLayout && !focusedCard && index === 0);

      const battlecardSlotClass = classNames('layout-battlecard-slots_slot', {
        'layout-battlecard-slots_slot--empty': !cardNode,
        'layout-battlecard-slots_slot--vitals': cardNode && !cardNode.id,
        'layout-battlecard-slots_slot--fadeout': layout.match(/^(single)$/g),
        'layout-battlecard-slots_slot--current': isCurrent,
        'layout-battlecard-slots_slot--hidden': isStackerLayout && !isCurrent,
        ...this.getClassForVisibilityGroup(cardNode || {})
      });

      // The following overrides static position for battlecard slots. Needed to position the
      // quick change visibility group editor in the correct position.
      const style = quickChangeEnabled ? (isStackerLayout ? {} : {position: 'relative'}) : {};

      // no card means it is a gap
      if(!cardNode) {
        return (
          <div key={emptyCardKey} className={battlecardSlotClass} />
        );
      }

      // if vitals card (id == 0)
      if(cardNode.id === 0) {
        return (
          <div key="card_0" className={battlecardSlotClass}>
            {this.renderVitalsCard(cardNode)}
          </div>
        );
      }

      const containerScroll = document.getElementsByClassName('battlecard-section_cards')[0];

      return (
        <div key={`card_${cardNode.id}`} className={battlecardSlotClass} data-card-id={cardNode.id} style={style}>
          {this.renderNormalCard(cardNode, index)}
          {layout !== BATTLE_CARD_LAYOUT.single && isVisibilityGroupsOnBattlecardsEnabled()
            ? <CardVisibilityGroups card={cardNode}
                onCardPermissionsUpdated={this.handleCardPermissionsUpdated}
                quickChangeEnabled={quickChangeEnabled}
                disableOnClickOutside={!quickChangeEnabled}
                containerScroll={containerScroll} />
            : null
          }
        </div>
      );
    });

    return uiCards;
  };

  renderNav = () => {
    const cardNodes = this.getCards();
    const {focusedCard} = this.props;

    const uiNavItems = cardNodes.map((card, index) => {
      if(!card) {
        return null;
      }

      let cardName;

      if(card.id === 0) {
        cardName = 'Vitals';
      }
      else {
        cardName = card.data.name || (<div className="board-name"><i className="fa fa-inbox board-header_title_icon" /> {card.board.name}</div>) || 'Untitled';
      }

      return (
        <div key={`battlecard-nav-item${card.id}`}
          className={classNames('battlecard-nav-item', {
            'battlecard-nav-item--current': focusedCard ? focusedCard.id === card.id : index === 0
          })}
          onClick={() => {
            this.props.onFocusCard(card);
          }}>
          <label>{cardName}</label>
        </div>
      );
    });

    return (
      <div key="battlecard-nav" className="battlecard-nav">
        <CompanyLogo rival={this.context.utils.rival} className="battlecard-logo" title={this.context.utils.rival.name} />
        {uiNavItems}
      </div>
    );
  };

  renderEmptyStateMessages = () => {
    const profile = this._getProfile();

    if(!profile || _.isEmpty(profile)) {
      return;
    }

    const {battlecard, company, rival, user} = this.context.utils;
    const uiProfileName = profile.name || 'This profile';
    const isCurator = userCanCurate({user});
    const isImpersonating = userIsKluebot({userId: user.id, company});
    const isComposerEnabled = isCurator && (
      company?.companyData?.isComposerEnabled ||
      (company?.companyData?.isComposerEnabledForStaff && isImpersonating)
    );
    const profilePath = `/profile/${profile.id}/${isCurator ? 'edit' : 'view'}`;
    const battlecardEditPath = `/profile/${profile.id}/battlecard/edit/${battlecard ? battlecard.id : ''}`;
    const composerPath = '/composer';
    let battlecardCardsCount = 0;
    let uiBattlecardName;
    let uiEmptyMessageTitle;
    let uiEmptyMessageSubTitle;

    if(battlecard) {
      const matchedBattlecard = (profile.battlecards || []).find(b => b.id === battlecard.id);

      if(matchedBattlecard) {
        battlecardCardsCount = matchedBattlecard.cards[this.state.view].length;
      }
    }

    if(profile.battlecardsCount && battlecard && !battlecardCardsCount) {
      uiBattlecardName = (
        <strong>{battlecard.title || '(Untitled Battlecard)'}</strong>
      );

      uiEmptyMessageTitle = (
        <strong>{uiProfileName}&apos;s &quot;{uiBattlecardName}&quot; battlecard is empty!</strong>
      );

      if(isCurator) {
        uiEmptyMessageSubTitle = (
          <span>
            You can add cards individually when <Link to={profilePath}>editing this profile</Link>,<br />
            or by <Link to={battlecardEditPath}>editing this battlecard</Link> in the builder tool.
          </span>
        );
      }
    }
    else if(!profile.battlecardsCount) {
      uiEmptyMessageTitle = (
        <strong data-tracking-id="no-battlecard-yet-message">{uiProfileName} doesn&rsquo;t have a battlecard yet. 😢</strong>
      );

      if(isCurator) {
        uiEmptyMessageSubTitle = (
          <div>
            Want to add it?<br />
          </div>
        );
      }
      else {
        uiEmptyMessageSubTitle = (
          <div>
            Want to see the information we&apos;ve gathered so far?
          </div>
        );
      }
    }

    if(uiEmptyMessageTitle) {
      const dashboardLink = !isCurator && (
        <Link to="/" className="button button--alt">Back to Dashboard</Link>
      );
      const battlecardBuilderLink = isCurator && (
        <Link to={battlecardEditPath} className="button">Build This Battlecard</Link>
      );
      const profileLinkClass = classNames('button', {
        'button--alt': isCurator
      });
      const profileLink = (
        <Link to={profilePath} className={profileLinkClass}>{uiProfileName}&apos;s Board</Link>
      );
      const composerLink = isComposerEnabled ? (
        <><br /><Link to={composerPath} className="button button--purple">🧙 Build Battlecard with AI</Link></>
      ) : null;

      return (
        <div className="layout-battlecard-slots_error">
          <CompanyLogo
            className="logo"
            rival={rival} />
          <div className="u-mb-s u-pt-m">{uiEmptyMessageTitle}</div>
          {uiEmptyMessageSubTitle}
          {profile.name && (
            <div className="u-pt-m">
              {profileLink}
              {battlecardBuilderLink}
              {dashboardLink}
              {composerLink}
            </div>
          )}
        </div>
      );
    }
  };

  setRef = el => this.battlecardViewRef = el;

  render() {
    const {cardSourceId, sources, cardsSorted, editCard: {cardId, boardId, profileId, authorId} = {}} = this.state;
    const {user} = this.context.utils;
    let {layout} = this.props;

    let uiBody = this.renderEmptyStateMessages();
    let uiNav = null;

    if(!uiBody && !cardsSorted) {
      // no errors, card data still loading
      return null;
    }
    else if(!uiBody) {
      // no errors, output the cards
      uiBody = this.renderCards();
    }
    else {
      // always use 'single' layout for empty state messages
      layout = BATTLE_CARD_LAYOUT.single;
    }

    if(layout === BATTLE_CARD_LAYOUT.stacker) {
      uiNav = this.renderNav();
    }

    const profile = this._getProfile();
    const cardModal = (<CardEditModal
      visible={Boolean(cardId && boardId && profileId && profile)}
      profileId={Number(profileId)}
      onCreate={this.handleCardModalAfterUpdate}
      onUpdate={this.handleCardModalAfterUpdate}
      onClose={this.handleCardModalClose}
      cardId={Number(cardId)}
      boardId={Number(boardId)}
      authorId={authorId}
      wideMode={profile.wideMode} />
    );

    return (
      <React.Fragment>
        {Boolean(cardSourceId) &&
        <CardMeta
          updateSourceList={this.updateSourceList}
          sources={sources.get(cardSourceId) || []}
          onDismiss={() => this.showCardMeta(null)}
          isCurator={userCanCurate({user})}
          cardId={cardSourceId} />
        }
        <div ref={this.setRef} data-cardcount={uiBody.length} className={`layout-battlecard--${layout} layout-battlecard-slots`}>
          {cardModal}
          {uiNav}
          {uiBody}

        </div>
      </React.Fragment>
    );
  }

}

export default withRouter(BattlecardView);
