import {useCallback, useState} from 'react';

function useHasUnsavedData() {
  const [map, setMap] = useState(new Map());

  const setItemValue = useCallback((id, value) => {
    setMap(prev => prev.set(id, value));
  }, [setMap]);

  const hasUnsavedData = useCallback(() => {
    if(map.size > 0) {
      return Array.from(map).some(([_, value]) => Boolean(value));
    }

    return false;
  }, [map]);

  const resetUnsavedList = useCallback(() => {
    setMap(new Map());
  }, [setMap]);

  return [setItemValue, hasUnsavedData, resetUnsavedList];
}

export default useHasUnsavedData;
