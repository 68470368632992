import ProfileToolbar from './_profile_toolbar';
import Scratchpad from './_scratchpad';
import FeedPostBox from './_feed_post_box';
import FeedPostBoxExpanded from './_feed_post_box_expanded';
import BoardList from './_board_list';
import BoardCollapsed from './_board_collapsed';
import BattlecardsEditor from './_battlecards_editor';
import CardMeta from './card_templates/_card_meta';
import CardEditModal from './editor/_card_edit_modal';
import Icon from './_icon';
import {matchPath} from 'react-router';

import {profileGet} from '../modules/api/profiles';
import {cardGet} from '../modules/api/cards';
import {lanesGet, laneGet, laneCreate, laneUpdate, laneDelete} from '../modules/api/lanes';
import {commentsGet} from '../modules/api/comments';
import {userCanCurate} from '../modules/roles_utils';
import {isValidId, dig} from '../modules/utils';
import {getCollapsedLanes} from '../modules/user_utils';
import {analyticsTrack, SNOWPLOW_SCHEMAS, pageTrackingTypes} from '../modules/analytics_utils';
import {uiDelays, uiMaxResults} from '../modules/constants/ui';
import {DropdownMenuConstants} from '../modules/constants/dropdown_menu';
import {setLocalRivalLogoUrl} from '../modules/local_storage_utils';
import {getCardFilterParamFromLabel, getCardFilterTimestamp} from '../modules/profile_utils';

import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import ReactUpdate from 'immutability-helper';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {redirectToV2} from '../modules/route_utils';
import {CardDraggingProvider, CardDraggingContext} from '../contexts/_cardDragging';
import DropTargetScroller from './_drop_target_scroller';

class Profile extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired
  };

  static childContextTypes = {
    onScratchpadRefresh: PropTypes.func.isRequired,
    profileFilters: PropTypes.object
  };

  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
    users: PropTypes.objectOf(PropTypes.object),
    company: PropTypes.object,
    rivals: PropTypes.arrayOf(PropTypes.object),
    postsUrl: PropTypes.string,
    editMode: PropTypes.bool,
    builderMode: PropTypes.bool,
    newBattlecardMode: PropTypes.bool,
    onRemoveCardsFromBattlecards: PropTypes.func,
    onToggleCardOnBattlecard: PropTypes.func,
    maxBattlecardCards: PropTypes.number,
    onToggleLane: PropTypes.func.isRequired,
    onEnqueueToastMessage: PropTypes.func.isRequired,   // eslint-disable-line react/no-unused-prop-types
    onClearToastMessages: PropTypes.func.isRequired,
    loadOrFetchRivals: PropTypes.func.isRequired,
    showFeedPostBox: PropTypes.bool
  };

  static defaultProps = {
    match: {},
    history: {},
    location: {},
    user: null,
    users: {},
    company: null,
    rivals: [],
    postsUrl: '',
    editMode: false,
    builderMode: false,
    newBattlecardMode: false,
    onRemoveCardsFromBattlecards: null,
    onToggleCardOnBattlecard: null,
    maxBattlecardCards: 0,
    onToggleLane() {},
    onEnqueueToastMessage() {},
    onClearToastMessages() {},
    loadOrFetchRivals() {},
    showFeedPostBox: true
  };

  state = {
    rival: null,
    boards: [],
    boardsRefreshCount: {},
    boardsCount: null,
    scratchpadComments: null,   // default state is null to differentiate from empty array loading state
    selectedCard: {
      card: null,
      sources: []
    },
    battlecardCardsOnly: false,
    onAddSourceUpdateCard() {},
    selectedPostData: null,
    lastCardUpdated: {},
    commentProfiles: [],
    previewVisibilityGroup: null,
    lanesLoaded: false,
    lanesLoading: false,
    emphasizeVitalSettings: Boolean(this.props.history?.location?.state?.fromViewInBoard),
    feedStartCollapsed: true,
    profileFilterObj: {
      q: '',
      updatedSince: null
    },
    freshCardExpanded: {}
  };

  getChildContext() {
    const {profileFilterObj: profileFilters} = this.state;

    return {
      onScratchpadRefresh: this.handleScratchpadRefresh,
      profileFilters
    };
  }

  async componentDidMount() {
    console.log('Profile.componentDidMount: props: %o', this.props, this.state);
    this._isMounted = true;

    const {history, user, editMode, match: {params = {}}, newBattlecardMode, location, loadOrFetchRivals} = this.props;
    const {pathname = ''} = location || {};

    loadOrFetchRivals();

    if(params.profileId) {
      const profileId = parseInt(params.profileId, 10) || 0;
      const {state: {boardId} = {}} = location;
      const cardId = parseInt(params.cardId, 10) || null;
      const rival = await this.loadRival(profileId);
      const profile = (params.profileId && rival) ? rival.profile : null;

      if(!profile) {
        return;
      }

      if(location.search) {
        const queryParams = new URLSearchParams(location.search);
        const previewGroup = queryParams.get('previewing');
        const cardFilter = queryParams.get('cardFilter');
        const visibilityGroup = parseInt(previewGroup, 10);

        this.loadLanes({rival, visibilityGroupId: visibilityGroup});

        if(cardFilter) {
          this.refreshFreshnessData(cardFilter);
        }

        return this.analyticsTrack(rival);
      }

      this.loadLanes({rival});
      this.analyticsTrack(rival);

      const isCuratorToolsCollapsed = getCollapsedLanes(user, profile.id).includes(Profile.regionTypes.CURATOR_TOOLS);

      if(newBattlecardMode) {
        // if we're in new battlecard mode and collapsing the curator tools, go back to the regular edit mode url
        // in order to prevent conflicting with auto-expanding on click of "add new battlecard" from ToC
        history.replace(`/profile/${profile.id}/battlecard/edit`);

        if(isCuratorToolsCollapsed) {
          // if "add new battlecard" is clicked from the ToC, make sure curator tools are visible
          this.toggleRegionVisibility(Profile.regionTypes.CURATOR_TOOLS);
        }
      }

      if(cardId && !boardId) {
        const isCurator = userCanCurate({user});
        const isEditCardURL = matchPath(pathname, {path: '/profile/:profileId/edit/card/:cardId/edit'});
        const shouldEditCard = isCurator && isEditCardURL;

        if(shouldEditCard) {
          const code = 'Profile.componentDidMount';
          const card = await cardGet({cardOptions: {cardId}, code});
          const {board: {id: bId} = {}} = card || {};

          history.replace({
            pathname,
            state: {boardId: bId}
          });
        }
        else {
          history.replace(`/card/${cardId}`);
        }
      }

      this.loadScratchpadComments(profile.scratchpadBoardId).catch(console.error);
    }
  }

  async componentDidUpdate(prevProps) {
    const {location} = this.props;
    const {utils} = this.context;
    const {rival} = utils;

    const {pathname: currPath, search: currSearch} = location;
    const {pathname: prevPath, search: prevSearch} = prevProps.location;
    const currentUrl = `${currPath}${currSearch}`;
    const prevUrl = `${prevPath}${prevSearch}`;

    if(currentUrl !== prevUrl) {
      const queryParams = new URLSearchParams(currSearch);
      const previewGroup = queryParams.get('previewing');
      const visibilityGroupId = parseInt(previewGroup, 10);

      this.loadLanes({rival, visibilityGroupId});

      const prevQueryParams = new URLSearchParams(prevSearch);
      const prevCardFilter = prevQueryParams.get('cardFilter');
      const cardFilter = queryParams.get('cardFilter');

      if(cardFilter !== prevCardFilter) {
        this.refreshFreshnessData(cardFilter);
      }
    }
  }

  componentWillUnmount() {
    this.turnOffCardDraggingRefreshBoardsAndSaveChangesIfNecessary({unmounting: true});
    this.props.onClearToastMessages('Profile');

    this._isMounted = false;
  }

  // eslint-disable-next-line no-unused-react-component-methods/no-unused-react-component-methods
  async UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {match: {params = {}}, history, user, editMode, newBattlecardMode} = this.props;
    const {match: {params: nextParams = {}}, user: nextUser, editMode: nextEditMode, newBattlecardMode: nextNewBattlecardMode} = nextProps;
    const profileId = parseInt(params.profileId, 10) || 0;
    const nextProfileId = parseInt(nextParams.profileId, 10) || 0;
    const {battlecard} = nextContext.utils;
    const editDidToggle = editMode !== nextEditMode;

    if(!editMode && nextEditMode && battlecard) {
      const battlecardId = parseInt(nextParams.battlecardId, 10) || 0;

      if(nextParams.battlecardId && (battlecardId !== battlecard.id)) {
        // invalid battlecardId requested in edit path -- default battlecard shown
        console.warn('Profile.componentWillReceiveProps: invalid battlecardId #%o requested, redirecting to default battlecard editor', battlecardId);

        return history.replace(`/profile/${nextProfileId}/battlecard/edit`);
      }
    }

    if(nextParams.profileId && ((profileId !== nextProfileId) || editDidToggle)) {
      if(profileId !== nextProfileId) {
        this.turnOffCardDraggingRefreshBoardsAndSaveChangesIfNecessary({switchingProfiles: true});
      }

      const rival = await this.loadRival(nextProfileId, nextProps, nextContext);
      const profile = rival ? rival.profile : null;

      if(profile) {
        // switching into edit mode for current rival, or switching between rivals
        this.loadLanes({rival});
        this.loadScratchpadComments(profile.scratchpadBoardId);
        this.analyticsTrack(rival);
      }
    }

    if(nextParams.profileId && (profileId === nextProfileId) && nextNewBattlecardMode) {
      const wasCuratorToolsCollapsed = getCollapsedLanes(user, profileId).includes(Profile.regionTypes.CURATOR_TOOLS);
      const isCuratorToolsCollapsed = getCollapsedLanes(nextUser, nextProfileId).includes(Profile.regionTypes.CURATOR_TOOLS);
      const isCollapsing = !wasCuratorToolsCollapsed && isCuratorToolsCollapsed;

      // make sure we're not interfering with someone actively toggling curator tools
      if(!isCollapsing && (!newBattlecardMode || (newBattlecardMode && isCuratorToolsCollapsed))) {
        if(nextNewBattlecardMode !== newBattlecardMode) {
          history.replace(`/profile/${nextProfileId}/battlecard/edit`);
        }

        if(isCuratorToolsCollapsed) {
          this.toggleRegionVisibility(Profile.regionTypes.CURATOR_TOOLS);
        }
      }
    }
  }

  handleClearFreshCardsExpanded = () => {
    this.setState({freshCardExpanded: {}});
  };

  handleToggleFreshCardExpanded = cardId => {
    if(cardId === null) {
      return this.handleClearFreshCardsExpanded();
    }

    const {freshCardExpanded} = this.state;
    const newState = {...freshCardExpanded};

    newState[cardId] = !newState[cardId];

    this.setState({freshCardExpanded: newState});
  };

  refreshFreshnessData = cardFilter => {
    const freshnessMode = cardFilter === 'freshness';
    const {profileFilterObj: {q} = {}} = this.state;
    const updatedAtTimestamp = getCardFilterTimestamp(cardFilter);
    const filterObj = {
      q: q || '',
      updatedSince: updatedAtTimestamp || null
    };

    this.setState({
      profileFilterObj: filterObj,
      freshnessMode,
      freshCardExpanded: {},
      ...this.getBattlecardCardLookup({freshnessMode})
    });
  };

  static regionTypes = {
    NONE: 0,
    FEED: 'feed',
    CURATOR_TOOLS: 'tools'
  };

  _swimlanesRef = null;
  _cardDraggingContext = null;

  _setSwimlanesRef = node => this._swimlanesRef = node;

  _getPreviewingId = () => {
    const {location} = this.props;
    const queryParams = new URLSearchParams(location.search);
    const previewGroup = queryParams.get('previewing');
    const visibilityGroup = parseInt(previewGroup, 10);

    return visibilityGroup >= 0 ? visibilityGroup : null; // 0 visibilityGroup indicates full access user.
  };

  _getProfile = () => {
    const {profile = {}} = this.context.utils.rival || {};

    return profile;
  };

  // TODO: replace with context.utils.getBattlecardTitle()?
  _getBattlecardTitle = battlecardId => {
    const {commentProfiles} = this.state;

    if(_.isEmpty(commentProfiles) || !isValidId(battlecardId)) {
      return '';
    }

    let matchedBattlecard = {};

    commentProfiles.forEach(p => {
      const battlecards = (p.battlecards || []).filter(b => b.id === battlecardId);

      if(!battlecards.length) {
        return;
      }

      matchedBattlecard = battlecards[0];
    });

    return matchedBattlecard.title || 'Untitled';
  };

  _getBattlecardCardTitles = () => {
    const profile = this._getProfile();
    const battlecards = profile.battlecards ? profile.battlecards : [];

    if(_.isEmpty(battlecards)) {
      return [];
    }

    // merge all cardTitles arrays
    const cardTitles = [].concat(...battlecards.map(b => { return b.cardTitles || []; }));

    // get only unique cardTitles (by id)
    return cardTitles.filter((ct, index, self) => self.findIndex(t => t.id === ct.id) === index);
  };

  _findBoardIndex = board => (this.state.boards || []).slice().findIndex(b => b.id === board.id);

  _isScratchpad = board => board && (board.name.toLowerCase() === 'scratchpad');

  _getValidCommentContainer = (containers = [], validContainerTypes = []) => {
    const profile = this._getProfile();
    const validContainers = (containers || []).filter(c => {
      const type = c.containerType.toLowerCase();

      // exclude current profile's scratchpad from valid boardIds
      return validContainerTypes.includes(type) && ((type !== 'board') || (c.containerId !== profile.scratchpadBoardId));
    });

    // use first available valid container (only showing one source label)
    return validContainers.length ? validContainers[0] : null;
  };

  analyticsTrack = (rival = {}) => {
    if(!rival || _.isEmpty(rival)) {
      return;
    }

    const {name, id: rivalId, profile: {isDraft}} = rival;
    const {location: pathname} = this.props;
    let event;

    const trackingData = {
      context: [{
        schema: SNOWPLOW_SCHEMAS.board,
        data: {
          id: rivalId,
          status: isDraft ? 'draft' : 'published'
        }
      }]
    };

    pageTrackingTypes.forEach(type => {
      if(type === 'event') {
        event = {
          type: 'event',
          category: 'Profile',
          action: 'view',
          label: `${name}`
        };
      }
      else {
        event = {
          path: `${pathname}`,
          type
        };
      }

      analyticsTrack(event, trackingData);
    });
  };

  trackRecentProfileView = (profileId = 0, props = this.props, context = this.context) => {
    const {user: {id, userData = {}}} = props;

    if(!isValidId(id) || !isValidId(profileId)) {
      return [];
    }

    const recentlyViewedProfiles = (userData.recentlyViewedProfiles || []).slice();
    const profileIndex = recentlyViewedProfiles.findIndex(p => p === profileId);

    if(profileIndex >= 0) {
      // profile was previously visited, drop previous reference
      recentlyViewedProfiles.splice(profileIndex, 1);
    }

    // add profile as most recently viewed
    recentlyViewedProfiles.unshift(profileId);

    // save updated userData
    const userOptions = {
      id,
      featureFlag: [
        'recentlyViewedProfiles',
        // trim list to last 10 items
        recentlyViewedProfiles.slice(0, uiMaxResults.MAX_NAV_RESULTS)
      ]
    };

    context.api.userUpdate(userOptions).then(updatedUser => {
      console.log('Profile.trackRecentProfileView: viewed profileId #%o, updated user: %o', profileId, updatedUser);

      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  };

  loadProfile = async (profileId = 0) => {
    if(!isValidId(profileId)) {
      return;
    }

    const {commentProfiles} = this.state;
    const activeProfile = this._getProfile() || {};

    if((commentProfiles || []).find(p => p.id === profileId)) {
      // profile is already loaded
      console.log('Profile.loadProfile: profileId #%o already loaded: %o', profileId, commentProfiles);

      return;
    }

    const isActiveProfile = activeProfile.id === profileId;
    const profile = isActiveProfile ? activeProfile : await profileGet({profileId, version: 2});

    if(!_.isEmpty(profile)) {
      commentProfiles.push(profile);

      this.setState({commentProfiles}, () => {
        console.log(
          'Profile.loadProfile: %s profileId #%o, updated commentProfiles: %o',
          isActiveProfile ? 'added active' : 'fetched', profileId, this.state.commentProfiles
        );
      });
    }
  };

  loadRival = (profileId = 0, props = this.props, context = this.context) => {
    return new Promise((resolve, reject) => {
      if(!isValidId(profileId)) {
        console.warn('Profile.loadRival: invalid profileId specified: %o', profileId);

        reject(profileId);

        // invalid profileId - redirect user to dashboard
        return props.history.replace({
          pathname: '/',
          state: {
            redirectCode: 'boardNotFound'
          }
        });
      }

      // load extended rival/profile data including stats
      context.api.rivalGet({profileId})
        .then(rival => {
          console.log('Profile.loadRival: loaded extended rival data for profileId #%o: %o', profileId, rival);

          // track recent profile view
          this.trackRecentProfileView(profileId, props, context);

          this.setState({rival}, () => resolve(rival));
        }).catch(error => {
          console.warn('Profile.loadRival: error loading rival by profileId #%o, redirecting...: %o', profileId, error);

          reject(profileId);

          // invalid/non-existent rival - redirect user to dashboard
          return props.history.replace({
            pathname: '/',
            state: {
              redirectCode: 'boardNotFound'
            }
          });
        });
    });
  };

  loadLanes = ({rival = this.context.utils.rival, visibilityGroupId = null} = {}) => {
    if(!rival || !this._isMounted) {
      return;
    }

    const {profile} = rival || {profile: {}};

    const intVisibilityGroupId = parseInt(visibilityGroupId, 10);

    const previewVisibilityGroup = isValidId(intVisibilityGroupId, true)
      ? (intVisibilityGroupId === DropdownMenuConstants.VIEW_AS_DONE_PREVIEWING_ID)
        ? null
        : intVisibilityGroupId
      : null;

    return new Promise((resolve, reject) => {
      if(_.isEmpty(profile)) {
        return reject();
      }

      const lanesLoadedAction = data => {
        if(!this._isMounted) {
          return;
        }

        this.setState({
          boards: data,
          previewVisibilityGroup,
          lanesLoaded: true,
          lanesLoading: false,
          ...this.getBattlecardCardLookup({boards: data})
        }, () => {
          const {boards} = this.state;

          console.log('Profile.loadLanes: lanes loaded for profileId #%o: %o', profile.id, boards);

          this.handleUpdateBoardsCount(boards);

          return resolve(boards);
        });
      };

      const laneOptions = {
        profileId: profile.id,
        visibilityGroupId: previewVisibilityGroup
      };

      this.setState({lanesLoading: true}, () => {
        lanesGet({laneOptions, code: 'Profile.loadLanes'}).then(lanesLoadedAction).catch(error => console.error(error))
          .finally(() => {
            this.setState({lanesLoading: false});
          });
      });
    });
  };

  handleErrorMessage = ({title = '', message = ''}) => {
    if(!title || !message) {
      return;
    }

    this.props.onEnqueueToastMessage({
      title,
      message,
      duration: uiDelays.toastError,
      isError: true,
      source: 'Profile'
    });
  };

  toggleRegionVisibility = (region = Profile.regionTypes.NONE, event = null) => {
    if(event) {
      event.preventDefault();
    }

    return new Promise((resolve, reject) => {
      const profile = this._getProfile();

      console.log('Profile.toggleRegionVisibility: profile: %o, region: %o', profile, region);

      if(!profile || (region === Profile.regionTypes.NONE)) {
        return reject();
      }

      this.props.onToggleLane({
        profile,
        region
      }).then(resolve);
    });
  };

  handleUpdateBoardsCount = boards => {
    // keep boardsCount null if boards aren't loaded so we can differentiate loading from empty state (#1798)
    const boardsCount = !boards ? null : boards.filter(b => b.name.toLowerCase() !== 'scratchpad').length;

    this.setState({boardsCount});
  };

  updateBoard = (board, forceRefresh = false) => {
    const boards = (this.state.boards || []).slice();
    const {boardsRefreshCount} = this.state;

    const state = {};
    const boardRefreshCountState = {};

    state[this._findBoardIndex(board)] = {$set: board};

    if(forceRefresh) {
      const {id: boardId} = board;

      boardRefreshCountState[boardId] = {$set: (boardsRefreshCount[boardId] ?? 0) + 1};
    }

    this.setState({
      boards: ReactUpdate(boards, state),
      boardsRefreshCount: ReactUpdate(boardsRefreshCount, boardRefreshCountState)
    }, () => {
      this.setState({
        boards: this.state.boards.slice().sort((b1, b2) => parseFloat(b1.viewOrder) - parseFloat(b2.viewOrder))
      }, () => {
        console.log('Profile.updateBoard: boards updated: %o', this.state.boards);
      });
    });
  };

  deleteBoard = board => {
    const boards = this.state.boards ? this.state.boards.slice() : [];

    this.setState({
      boards: ReactUpdate(boards, {$splice: [[this._findBoardIndex(board), 1]]})
    }, () => {
      this.handleUpdateBoardsCount(this.state.boards);
    });
  };

  handleMoveCardLane = (card, targetBoardId) => {
    const {onMoveCardToLane} = this._cardDraggingContext || {};

    onMoveCardToLane({card, targetBoardId});
  };

  handleBoardCreate = (name = '', callback = null) => {
    const {rival} = this.state;
    const laneOptions = {name};

    if(_.isEmpty(rival) || !name) {
      return;
    }

    if(rival.profile) {
      Object.assign(laneOptions, {
        profileId: rival.profile.id
      });
    }
    else {
      Object.assign(laneOptions, {
        rivalId: rival.id
      });
    }

    laneCreate({laneOptions, code: 'Profile.handleBoardCreate'})
      .then(lane => {
        if(!_.isEmpty(lane)) {
          this.setState({
            boards: ReactUpdate(this.state.boards, {$push: [lane]})
          }, () => {
            console.log('Profile.handleBoardCreate: lane #%o created: %o', lane.id, lane);

            this.handleUpdateBoardsCount(this.state.boards);

            return typeof callback === 'function' && callback(lane);
          });
        }
      })
      .catch(({message}) => {
        console.error('Profile.handleBoardCreate: unable to create lane with options %o: %o', laneOptions, message);

        this.handleErrorMessage({
          title: '🚨 Unable to create lane!',
          message: (
            <div>Sorry, the lane name <b>{name}</b> is already in use. Please try a different name and try again.</div>
          )
        });
      });
  };

  handleBoardUpdate = (board, callback) => {
    if(_.isEmpty(board)) {
      return;
    }

    const {id, name, viewOrder} = board;
    const laneOptions = {id, name, viewOrder};

    laneUpdate({laneOptions, code: 'Profile.handleBoardUpdate'})
      .then(lane => {
        if(!_.isEmpty(lane)) {
          console.log('Profile.handleBoardUpdate: board #%o updated: %o', lane.id, lane);

          this.updateBoard(lane);

          return typeof callback === 'function' && callback(lane);
        }
      })
      .catch(({message}) => {
        console.error('Profile.handleBoardUpdate: unable to update lane with options %o: %o', laneOptions, message);

        if(board.prevName) {
          this.updateBoard(
            Object.assign(board, {
              name: board.prevName
            })
          );
        }

        this.handleErrorMessage({
          title: '🚨 Unable to rename lane!',
          message: (
            <div>Sorry, the lane name <b>{laneOptions.name}</b> is already in use. Please try a different name and try again.</div>
          )
        });
      });
  };

  handleBoardDelete = (board, callback) => {
    // latency comp; resync on error if delete fails
    this.deleteBoard(board);

    const laneOptions = {
      id: board.id
    };

    laneDelete({laneOptions, code: 'Profile.handleBoardDelete'})
      .then(() => {
        const profile = this._getProfile();

        console.log('Profile.handleBoardDelete: board #%o deleted', board.id);

        this.props.onRemoveCardsFromBattlecards(profile, board.cards);

        return typeof callback === 'function' && callback();
      })
      .catch(({message}) => {
        console.error('Profile.handleBoardDelete: unable to delete lane with options %o: %o', laneOptions, message);

        // reload all lanes as a failsafe (reverses latency comp above)
        this.loadLanes({rival: this.context.utils.rival});

        this.handleErrorMessage({
          title: '💥 Unable to delete lane!',
          message: `Sorry, we encountered an error when deleting the requested lane.
            Please try again or contact your Klue administrator if the problem persists.`
        });
      });
  };

  getUniqueLaneName = (boards, name) => {
    const boardNames = boards.map(b => b.name.toLowerCase());
    const uniqueName = name.toLowerCase();

    if(!boardNames.includes(uniqueName)) {
      return name;
    }

    let i = 1;

    while(boardNames.includes(`${uniqueName} (${i})`)) {
      i++;
    }

    return `${name} ${i}`;
  };

  handleInsertLaneAfter = (board, callback) => {
    const {rival, boards} = this.state;

    if(_.isEmpty(rival) || !rival.profile) {
      return;
    }

    const findIndex = this._findBoardIndex(board);

    if(findIndex < 0) {
      return;
    }

    let viewOrder;
    const nextIndex = findIndex + 1;

    if(nextIndex < boards.length) {
      viewOrder = (parseFloat(boards[findIndex].viewOrder) + parseFloat(boards[nextIndex].viewOrder)) / 2;
    }
    else {
      viewOrder = parseFloat(boards[findIndex].viewOrder) + 1;
    }

    const name = this.getUniqueLaneName(boards, 'Untitled Lane');
    const laneOptions = {
      profileId: rival.profile.id,
      name,
      viewOrder
    };

    laneCreate({laneOptions, code: 'Profile.handleInsertLaneAfter'})
      .then(lane => {
        if(!_.isEmpty(lane)) {
          this.setState({
            boards: ReactUpdate(boards, {
              $splice: [
                [nextIndex, 0, {...lane}]
              ]
            })
          }, () => {
            console.log('Profile.handleInsertLaneAfter: lane #%o created: %o', lane.id, lane);

            this.handleUpdateBoardsCount(this.state.boards);

            return typeof callback === 'function' && callback(lane);
          });
        }
      })
      .catch(({message}) => {
        console.error('Profile.handleInsertLaneAfter: unable to create lane with options %o: %o', laneOptions, message);

        this.handleErrorMessage({
          title: '🚨 Unable to create lane!',
          message: (
            <div>Sorry, something went wrong. Please try again.</div>
          )
        });
      });
  };

  handleShowCardMeta = (cardData, onAddSourceUpdateCard = null) => {
    const {card = null, sources = []} = cardData ?? {};
    const selectedCard = {card, sources};

    this.setState({
      selectedCard,
      onAddSourceUpdateCard
    }, () => console.log('Profile.handleShowCardMeta: selected card: %o', selectedCard));
  };

  handleUpdateSourceList = ({card, sources}) => {
    const {onAddSourceUpdateCard} = this.state;

    onAddSourceUpdateCard && onAddSourceUpdateCard(sources);
    this.setState({selectedCard: {card, sources}});
  };

  loadScratchpadComments = (containerId = 0) => {
    return new Promise((resolve, reject) => {
      if(containerId <= 0) {
        return reject(containerId);
      }

      const commentOptions = {
        containerId,
        containerType: 'board',
        reverse: true
      };

      commentsGet({commentOptions})
        .then(({comments}) => {
          if(!this._isMounted) {
            resolve(null);
          }

          this.setState({
            scratchpadComments: comments
          }, () => {
            console.log('Profile.loadScratchpadComments: loaded comments for scratchpad boardId #%o: %o', containerId, this.state.scratchpadComments);

            const {scratchpadComments} = this.state;
            const profileIds = [];

            // fetch any associated profiles for scratchpad comments, if necessary
            (scratchpadComments || []).forEach(c => {
              // use first available valid container (only showing one source label)
              const container = this._getValidCommentContainer(c.containers, ['profile', 'battlecard']);

              if(!container) {
                return;
              }

              switch(container.containerType.toLowerCase()) {
                case 'profile':
                  profileIds.push(container.containerId);
                  break;
                case 'battlecard':
                  profileIds.push(container.parentId);
                  break;
                default:
                  break;
              }
            });

            // process unique profileIds
            new Set(profileIds).forEach(id => this.loadProfile(id));

            resolve(this.state.scratchpadComments);
          });
        });
    });
  };

  getCommentSourceLink = ({containers}) => {
    if(_.isEmpty(containers)) {
      return {};
    }

    // add source link back to comment container (battlecard/profile/card/board) if not post-related
    const container = this._getValidCommentContainer(containers, ['profile', 'battlecard', 'board', 'card']);

    if(!container) {
      // invalid container type
      return {};
    }

    const isCurator = userCanCurate({user: this.props.user});
    let itemLabel = '';
    let itemLink = '';
    let profileId = null;
    let battlecardTitle = '';

    switch(container.containerType.toLowerCase()) {
      case 'profile':
        itemLabel = 'Board';    // following new naming convention in UI (sorry)
        itemLink = `/profile/${container.containerId}/${isCurator ? 'edit' : 'view'}#comments`;
        profileId = container.containerId;
        break;
      case 'battlecard':
        battlecardTitle = this._getBattlecardTitle(container.containerId);
        itemLabel = `\u0022${battlecardTitle}\u0022 Battlecard`;
        itemLink = `/profile/${container.parentId}/battlecard/${container.containerId}#comments`;
        profileId = container.parentId;
        break;
      case 'board':
      case 'card':
        // TODO: show & link to actual board/card name on profile (board/card comments NYI)
        itemLabel = container.containerType;
        itemLink = `/profile/${container.parentId}/${isCurator ? 'edit' : 'view'}#comments`;
        profileId = container.parentId;
        break;
      default:
        return {};
    }

    if(profileId) {
      const commentProfiles = this.state.commentProfiles ? this.state.commentProfiles.slice() : [];
      const profile = commentProfiles.find(p => p.id === profileId);

      if(profile) {
        itemLabel = `${profile.name} \u2013 ${itemLabel}`;
      }
    }

    return {
      label: itemLabel,
      link: itemLink
    };
  };

  handleBoardRefresh = laneId => {
    console.log('Profile.handleBoardRefresh: boardId #%o', laneId);

    const laneOptions = {
      laneId,
      visibilityGroupId: this.state.previewVisibilityGroup
    };

    laneGet({laneOptions, code: 'Profile.handleBoardRefresh'})
      .then(board => {
        this.updateBoard(board, true);
      }).catch(error => {
        const {response: {status} = {}} = error;

        if(status === 404) {
          // remove the board locally. An edit during preview can cause the board to no longer have cards
          // resulting in a 404 status which is legitamite.
          const board = (this.state.boards || []).slice().filter(b => b.id === laneId).shift();

          if(board) {
            this.deleteBoard(board);
          }
        }
      });
  };

  handleCardRefresh = ({card}) => {
    this.setState({lastCardUpdated: card}, () => {
      const {board: {id: boardId} = {}} = card;

      this.handleBoardRefresh(boardId);
    });
  };

  handleProfileUpdated = (profile, profileOptions) => {
    const {api} = this.context;

    return new Promise((resolve, reject) => {
      api.rivalGet({profileId: profile.id})
        .then(rival => {
          const {imageUrl} = profileOptions;

          if(imageUrl) {
            setLocalRivalLogoUrl(rival, imageUrl);
          }

          this.setState({rival});
          resolve({rival, profile});
        }).catch(error => reject({error}));
    });
  };

  handleSettingsExpanded = () => this.setState({emphasizeVitalSettings: false});

  handleToggleCardOnBattlecard = (battlecard, cardId, callback) => {
    const {onToggleCardOnBattlecard} = this.props;

    onToggleCardOnBattlecard(battlecard, cardId, async () => {
      const {freshnessMode} = this.state;

      // in freshness mode ensure the battlecards cards lookup is updated
      if(freshnessMode) {
        this.loadLanes();
      }

      callback && callback();
    });
  };

  handleToggleFeedItem = data => {
    const {selectedPostData: postData} = this.state;

    if(data && data.postId && postData) {
      data.attachments = [...postData.attachments];
    }

    this.setState({
      selectedPostData: data
    });
  };

  handleEditorSidebarTabClick = newView => {
    const profile = this._getProfile();
    const profileEditUrl = `/profile/${profile.id}${(newView === 'BATTLECARD_BUILDER') ? '/battlecard' : ''}/edit`;

    if(this.context.location.pathname !== profileEditUrl) {
      this.props.history.push(profileEditUrl);
    }
    else if(newView === 'SCRATCHPAD') {
      // refresh scratchpad contents
      this.loadScratchpadComments(profile.scratchpadBoardId);
    }
  };

  handleScratchpadDismissComment = (comment = null, bypassConfirm = true) => {
    if(_.isEmpty(comment)) {
      return;
    }

    const profile = this._getProfile();

    if(!profile || !profile.scratchpadBoardId) {
      console.error('Profile.handleScratchpadDismissComment: profile missing or no scratchpad found for profile: %o', profile);

      return;
    }

    const laneOptions = {
      id: profile.scratchpadBoardId,
      comments: {
        remove: [comment.id]
      }
    };

    const removeFromScratchpadAction = () => {
      laneUpdate({laneOptions, code: 'Profile.handleScratchpadDismissComment'}).then(lane => {
        console.log('Profile.handleScratchpadDismissComment: commentId #%o dismissed successfully: %o', comment.id, lane);

        const commentIndex = (this.state.scratchpadComments || []).slice().findIndex(c => c.id === comment.id);

        // TODO: this should also disassociate the comment/highlight in question from the related feed item's comments,
        // if the related post currently has its comments expanded (edge case)
        this.setState({
          scratchpadComments: ReactUpdate(this.state.scratchpadComments, {
            $splice: [[commentIndex, 1]]
          })
        }, () => this.handleBoardRefresh(laneOptions.id));
      });
    };

    if(bypassConfirm) {
      removeFromScratchpadAction();
    }
    else {
      const confirmMessage = 'Remove this comment from your scratchpad?';

      this.context.utils.dialog.confirm({
        message: confirmMessage,
        okCallback: removeFromScratchpadAction
      });
    }
  };

  handleScratchpadRefresh = ({comment = null, profileId = 0, boardId = 0, removeCommentId = 0}) => {
    const profile = this._getProfile();

    if(!profile || !profile.scratchpadBoardId) {
      return;
    }

    const scratchpadComments = this.state.scratchpadComments ? this.state.scratchpadComments.slice() : [];

    if(comment && comment.isNew) {
      let commentData;

      if(!this.state.scratchpadComments) {
        // scratchpadComments can be null if in empty state, so $push/$update will blow up in this case; need to set entire array here
        commentData = [comment];
      }
      else {
        commentData = ReactUpdate(this.state.scratchpadComments, {$unshift: [comment]});
      }

      this.setState({
        scratchpadComments: commentData
      }, () => {
        console.log('Profile.handleScratchpadRefresh: new comment: %o, updated comments: %o', comment, this.state.scratchpadComments);

        if(comment.boards.length) {
          this.handleBoardRefresh(comment.boards[0].id);
        }
      });
    }
    else if(comment && comment.boards && comment.boards.length) {
      // only refresh if comment's target board matches scratchpad
      if(comment.boards.find(b => b.id === profile.scratchpadBoardId)) {
        const commentIndex = scratchpadComments.findIndex(c => c.id === comment.id);

        if(commentIndex >= 0) {
          const commentsState = {};

          commentsState[commentIndex] = {$set: comment};

          this.setState({
            scratchpadComments: ReactUpdate(this.state.scratchpadComments, commentsState)
          }, () => {
            console.log('Profile.handleScratchpadRefresh: updated comment: %o, refreshed comments: %o', comment, this.state.scratchpadComments);
          });
        }
      }
    }
    else if(profileId) {
      // only refresh if comment's target profile matches this one
      if(profileId === profile.id) {
        console.log('Profile.handleScratchpadRefresh: all comments (profile match)');

        this.loadScratchpadComments(profile.scratchpadBoardId);
      }
    }
    else if(boardId) {
      // only refresh if comment's target board matches this one
      if(boardId === profile.scratchpadBoardId) {
        this.loadScratchpadComments(profile.scratchpadBoardId).then(comments => {
          console.log('Profile.handleScratchpadRefresh: all comments (board match): %o', comments);
        });
      }
    }
    else if(removeCommentId) {
      // remove comment if it exists in the scratchpad
      const commentIndex = scratchpadComments.findIndex(c => c.id === removeCommentId);

      if(commentIndex >= 0) {
        this.setState({
          scratchpadComments: ReactUpdate(this.state.scratchpadComments, {
            $splice: [[commentIndex, 1]]
          })
        }, () => console.log('Profile.handleScratchpadRefresh: removed comment: %o', removeCommentId));
      }
    }
  };

  handleUpdateScratchpadComment = (commentData, callback) => {
    const profile = this._getProfile();

    if(!profile || !profile.scratchpadBoardId || !commentData || _.isEmpty(commentData)) {
      return;
    }

    $.ajax({
      type: 'PUT',
      url: `/api/boards/${profile.scratchpadBoardId}/comments/${commentData.id}`,
      data: JSON.stringify({
        comment: commentData,
        reverse: true
      }),
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      success: comment => {
        if(this._isMounted) {
          console.log('Profile.handleUpdateScratchpadComment: updated comment for boardId #%o: %o', profile.scratchpadBoardId, comment);

          const commentIndex = this.state.scratchpadComments.findIndex(c => c.id === commentData.id);

          if(commentIndex >= 0) {
            const commentsState = {};

            commentsState[commentIndex] = {$set: comment};

            this.setState({
              scratchpadComments: ReactUpdate(this.state.scratchpadComments, commentsState)
            }, () => {
              console.log('Profile.handleUpdateScratchpadComment: updated comment: %o, refreshed comments: %o', comment, this.state.scratchpadComments);

              return typeof callback === 'function' && callback();
            });
          }
        }
      },
      error: (xhr, type) => {
        if(this._isMounted) {
          console.error('Profile.handleUpdateScratchpadComment: error: %o, type: %o', xhr, type);
        }
      }
    });
  };

  handleBattlecardTileClicked = cardId => {
    if(cardId === 0) {
      const targetEl = document.querySelector('.swimlane.ui-boards.vitals');

      if(targetEl) {
        targetEl.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
      }

      return;
    }

    const {boards} = this.state;

    if(!boards) {
      return;
    }

    const boardId = (boards.find(board => (board.cards || []).find(card => card.id === cardId)) || {}).id;

    if(!boardId) {
      return;
    }

    const profile = this._getProfile();

    if(!profile) {
      return;
    }

    const {id: profileId} = profile;

    if(!profileId) {
      return;
    }

    const {history} = this.props;
    const {location: {pathname} = {}} = history || {};

    if(!pathname) {
      return;
    }

    history.push(`${pathname}#${boardId}/${cardId}`);
  };

  handleDidUpdateBattlecardCards = ({updatedCards}) => {
    this.loadLanes().then(() => {
      const {boardsRefreshCount} = this.state;
      const boardRefreshCountState = {};
      const boardIds = new Set();

      (updatedCards || []).forEach(card => {
        const {board: {id: boardId} = {}} = card;

        if(boardId) {
          boardIds.add(boardId);
        }
      });

      // incrementing the refresh count for each board that was updated
      // causes a refresh of cardPositions for each board thus keeping them in sync
      // with the updated reviewed date
      boardIds.forEach(boardId => {
        boardRefreshCountState[boardId] = {$set: (boardsRefreshCount[boardId] ?? 0) + 1};
      });
      this.setState({
        boardsRefreshCount: ReactUpdate(boardsRefreshCount, boardRefreshCountState)
      });
    });
  };

  renderScratchpadRegion = (scratchpadBoard = null) => {
    const profile = this._getProfile();

    if(!profile || !userCanCurate({user: this.props.user}) || !scratchpadBoard || !this._isScratchpad(scratchpadBoard)) {
      return;
    }

    // If it is scratchpad mode but no scratchpad comments, load them
    if(!this.state.scratchpadComments && profile.scratchpadBoardId) {
      this.loadScratchpadComments(profile.scratchpadBoardId);
    }

    // NOTE: scratchpadBoard is only used for non-comments-related properties;
    // comment stats/details comes from state array (scratchpadComments) which is kept in sync when updated
    const comments = this.state.scratchpadComments ? this.state.scratchpadComments.slice() : [];
    const scratchpadClasses = classNames(`ui-card board board-${scratchpadBoard.id}`, {
      'scratchpad--empty': !comments.length
    });
    let scratchpadRegion;

    if(this.state.scratchpadComments) {
      scratchpadRegion = (
        <Scratchpad
          profileName={profile.name}
          comments={comments}
          onUpdateComment={this.handleUpdateScratchpadComment}
          onDismissComment={comment => this.handleScratchpadDismissComment(comment, false)}
          onGetCommentSourceLink={this.getCommentSourceLink} />
      );
    }
    else {
      scratchpadRegion = (
        <div className="scratchpad-item-list">
          <div className="scratchpad-item scratchpad-item--loading">
            <div className="scratchpad-item_inner">
              <div className="text-center" style={{opacity: 0.3}} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="swimlane ui-boards">
        <div id={`b${scratchpadBoard.id}`} className={scratchpadClasses}>
          <div className="board-body">
            {scratchpadRegion}
          </div>
        </div>
      </div>
    );
  };

  renderCuratorTools = (dim, cardDraggingContext) => {
    const profile = this._getProfile();
    const {user, location, editMode, builderMode, newBattlecardMode} = this.props;

    if(_.isEmpty(profile) || !editMode || !userCanCurate({user})) {
      return;
    }

    const {boards, scratchpadComments} = this.state;
    const {utils: {getBattlecardTemplate}} = this.context;
    const {isCompressedCardDraggingOn = false} = cardDraggingContext || {};
    const reorderingCompressedCards = isCompressedCardDraggingOn;
    const filteredBoards = boards.slice().filter(b => b.name.toLowerCase() === 'scratchpad');
    const scratchpad = filteredBoards.length ? filteredBoards[0] : null;
    const collapsedLanes = getCollapsedLanes(user, profile.id);
    const isCuratorToolsCollapsed = collapsedLanes.includes(Profile.regionTypes.CURATOR_TOOLS);
    let draftsCount = 0;
    let battlecardsBadge;
    let scratchpadBadge;

    if(scratchpad) {
      const battlecardCount = !_.isEmpty(profile.battlecards) ? profile.battlecards.length : '';
      let uiEditModeSidebar;

      if(builderMode) {
        uiEditModeSidebar = (
          <div className="bcbuilder-panel">
            <BattlecardsEditor
              location={location}
              newBattlecardMode={newBattlecardMode}
              cardTitles={this._getBattlecardCardTitles()}
              allBattlecards={profile ? profile.battlecards : null}
              boards={boards}
              newBattlecardSaveObj={getBattlecardTemplate({id: profile.id, name: profile.name})}
              onBattlecardTileClicked={this.handleBattlecardTileClicked}
              onViewAs={this.handleBattlecardViewAs}
              onDidUpdateBattlecardCards={this.handleDidUpdateBattlecardCards} />
          </div>
        );
      }
      else {
        uiEditModeSidebar = (
          <div className="scratchpad-child-overrides">
            {this.renderScratchpadRegion(scratchpad)}
          </div>
        );

        if(!_.isEmpty(scratchpadComments)) {
          draftsCount = scratchpad.comments.length;
        }
      }

      if(isCuratorToolsCollapsed || reorderingCompressedCards) {
        return (
          <div className={classNames('scratchpad scratchpad--collapsed', {'reordering-cards': reorderingCompressedCards})}>
            <BoardCollapsed
              curatorMode={true}
              onToggleClick={e => this.toggleRegionVisibility(Profile.regionTypes.CURATOR_TOOLS, e)} />
            <DropTargetScroller onDropTargetScrollerHover={this.handleScrollRight} />
          </div>
        );
      }

      if(builderMode) {
        battlecardsBadge = (
          <span className="notification-badge notification-badge--grey">{battlecardCount}</span>
        );
      }
      else {
        // NOTE: item count only available when scratchpad component has loaded
        scratchpadBadge = (
          <span className="notification-badge notification-badge--grey">{draftsCount}</span>
        );
      }

      const scratchpadClassNames = classNames('scratchpad', {
        'scratchpad--dim': dim
      });

      return (
        <div className={scratchpadClassNames}>
          <div className="layout-editmode-panel">
            <div className="editmode-panel-tabs">
              <div
                className={'editmode-panel-tabs_tab' + (builderMode ? ' editmode-panel-tabs_tab--active' : '')}
                onClick={() => this.handleEditorSidebarTabClick('BATTLECARD_BUILDER')}
                data-cy="editBattleCardsTabButton">
                <span className="editmode-panel-tabs_tab_title">
                  Edit Battlecards
                </span> {battlecardsBadge}
              </div>
              <div
                className={'editmode-panel-tabs_tab' + (!builderMode ? ' editmode-panel-tabs_tab--active' : '')}
                onClick={() => this.handleEditorSidebarTabClick('SCRATCHPAD')}
                data-cy="scratchPadTabButton">
                <span className="editmode-panel-tabs_tab_title">
                  Scratchpad {scratchpadBadge}
                </span>
              </div>
              <a
                href="#"
                className="editmode-panel-tabs_collapse-link"
                onClick={e => this.toggleRegionVisibility(Profile.regionTypes.CURATOR_TOOLS, e)}
                title="Collapse Curator Tools"
                data-tip={'Collapse Curator Tools'}
                data-offset="{'top': 0}">
                <Icon icon="collapse" width="24" height="24" />
              </a>
            </div>
            <div className="layout-editmode-panel_contents">
              {uiEditModeSidebar}
            </div>
          </div>
          <DropTargetScroller onDropTargetScrollerHover={this.handleScrollRight} />
        </div>
      );
    }
  };

  renderCardMeta = () => {
    const {selectedCard} = this.state;
    const {card, sources} = selectedCard;
    const {user} = this.props;
    const isCurator = userCanCurate({user});

    if(!card) {
      return null;
    }

    return (
      <CardMeta
        sources={sources || []}
        onDismiss={() => this.handleShowCardMeta(null)}
        isCurator={isCurator}
        previewingId={this._getPreviewingId()}
        cardId={card.id}
        updateSourceList={updatedSources => this.handleUpdateSourceList({card: {...card}, sources: updatedSources})} />
    );
  };

  handleModalAfterUpdate = (cardData = {}, comment = null) => this.setState({lastCardUpdated: cardData}, () => {
    const {board: {id: boardId} = {}} = cardData;

    // remove scratchpad item
    comment && this.handleScratchpadDismissComment(comment);
    this.handleBoardRefresh(boardId);
    this.handleModalClose();
  });

  updateViewAsQuery = queryValue => {
    const {history, location, user} = this.props;
    const queryParams = new URLSearchParams(location.search);
    let {pathname} = location;

    if(Number.isInteger(queryValue) && queryValue >= 0) {
      queryParams.set('previewing', queryValue);
    }
    else {
      queryParams.delete('previewing');

      if(userCanCurate({user})) {
        pathname = pathname.replace(/(\/view\b)(?!.*\1)/, '/edit');
      }
    }

    history.push({
      pathname,
      search: queryParams.toString()
    });
  };

  turnOffCardDraggingRefreshBoardsAndSaveChangesIfNecessary = (options = {switchingProfiles: false, unmounting: false}) => {
    if(!this._cardDraggingContext?.isCompressedCardDraggingOn) {
      return;
    }

    const {switchingProfiles, unmounting} = options;

    const affectedBoardIs = new Set(this._cardDraggingContext?.getAffectedBoards() || []);

    if(!switchingProfiles && !unmounting && affectedBoardIs.size) {
      // refresh boards affected by card d&d
      this.handleProfileRefresh([...affectedBoardIs]);
    }

    if(!unmounting) {
      this._cardDraggingContext?.toggleIsCardDraggingOn();
    }
  };

  handleBattlecardViewAs = (previewVisibilityGroup = null) => {
    const {appData: {v2Host}} = this.context;
    const {match: {params = {}}} = this.props;

    if(previewVisibilityGroup !== null) {
      const v2Search = `?previewing=${previewVisibilityGroup}`;
      const {profileId, battlecardId} = params;

      return redirectToV2({v2Host, v2Path: `/profile/${profileId}/battlecard/view/${battlecardId}`, v2Search, newTab: true});
    }
  };

  handleProfileViewAs = (previewVisibilityGroup = null) => {
    this.turnOffCardDraggingRefreshBoardsAndSaveChangesIfNecessary();

    const {appData: {v2Host}} = this.context;
    const {match: {params = {}}} = this.props;

    const {rival} = this.state;

    if(!rival) {
      return;
    }

    if(previewVisibilityGroup === DropdownMenuConstants.VIEW_AS_DONE_PREVIEWING_ID) {
      return this.updateViewAsQuery(null);
    }

    if(previewVisibilityGroup !== null) {
      const v2Search = `?previewing=${previewVisibilityGroup}`;

      return redirectToV2({v2Host, v2Path: `/profile/${params.profileId}`, v2Search, newTab: true});
    }

    return this.updateViewAsQuery(previewVisibilityGroup);
  };

  handleModalClose = () => {
    const {history, location: {state: locationState}, match: {params = {}}} = this.props;

    if(history?.length > 1) {
      return history.goBack();
    }

    const builderMode = (!_.isEmpty(locationState) && locationState.builderMode) || false;
    const battlecardId = (builderMode && locationState.battlecardId) || null;
    const battlecardEditPath = `/${builderMode ? 'battlecard/' : ''}edit${battlecardId ? `/${battlecardId}` : ''}`;

    history.push(`/profile/${params.profileId}${battlecardEditPath}`);
  };

  handleOnClickOutsideOfVitals = () => this.setState({emphasizeVitalSettings: false});

  handleEmphasizedSettingsScroll = () => this.setState({emphasizeVitalSettings: false});

  handleCardDraggingError = ({affectedBoards, message = 'Whoops 😬 an error occurred while saving card positions. Some changes were not saved.'}) => {
    const {utils: {dialog: {alert}}} = this.context;

    alert(message, () => {
      if(affectedBoards) {
        affectedBoards.forEach(laneId => this.handleBoardRefresh(laneId));
      }
    });
  };

  handleScrollRight = () => {
    document.getElementById('swimlanes-wrapper')?.scrollBy(-25, 0);
  };

  handleScrollLeft = () => {
    document.getElementById('swimlanes-wrapper')?.scrollBy(25, 0);
  };

  handleLanesDropHover = () => this.handleScrollRight();

  handleProfileRefresh = boardIds => {
    boardIds.forEach(id => this.handleBoardRefresh(id));
  };

  handleSetDraggingContextRef = context => {
    this._cardDraggingContext = context;
  };

  handleBoardDragged = ({sourceBoardId, targetBoardId}) => {
    if(sourceBoardId === targetBoardId) {
      return;
    }

    const {boards} = this.state;
    let updatedBoards = (boards || []).slice();
    const targetBoardIndex = this._findBoardIndex({id: targetBoardId});
    const sourceBoardIndex = this._findBoardIndex({id: sourceBoardId});
    const sourceBoard = updatedBoards[sourceBoardIndex];
    const deleteIndex = sourceBoardIndex;
    const insertIndex = sourceBoardIndex < targetBoardIndex ? targetBoardIndex - 1 : targetBoardIndex;

    updatedBoards = ReactUpdate(updatedBoards, {$splice: [
      [deleteIndex, 1],
      [insertIndex, 0, {...sourceBoard}]
    ]});

    let viewOrder;

    if(insertIndex === 0) {
      viewOrder = parseFloat(updatedBoards[insertIndex + 1].viewOrder) / 2;
    }
    else {
      viewOrder = (parseFloat(updatedBoards[insertIndex - 1].viewOrder) + parseFloat(updatedBoards[insertIndex + 1].viewOrder)) / 2;
    }

    updatedBoards = ReactUpdate(updatedBoards, {[insertIndex]: {viewOrder: {$set: `${viewOrder}`}}});

    laneUpdate({laneOptions: {id: updatedBoards[insertIndex].id, viewOrder}}).catch(() => {
      const {utils: {dialog: {alert}}} = this.context;

      alert('Whoops 😬 an error occurred while saving lane positions. Changes were not saved.', () => {});
    });

    this.setState({
      boards: updatedBoards
    });
  };

  handleToggleCardDragging = () => {
    if(!this._cardDraggingContext) {
      return;
    }

    if(this._cardDraggingContext.isCompressedCardDraggingOn) {
      return this.turnOffCardDraggingRefreshBoardsAndSaveChangesIfNecessary();
    }

    this._cardDraggingContext.toggleIsCardDraggingOn();
  };

  getBattlecardCardLookup = ({
    battlecardCardsOnly = this.state.battlecardCardsOnly,
    boards = this.state.boards,
    freshnessMode = this.state.freshnessMode
  }) => {
    const battlecardCardIds = new Set();
    const laneIdsWithBattlecardCard = new Set();

    if(!freshnessMode || !battlecardCardsOnly) {
      return {
        battlecardCardIds,
        laneIdsWithBattlecardCard
      };
    }

    const {utils: {rival}} = this.context;

    rival?.profile?.battlecards?.forEach(battlecard => {
      battlecard.cards?.desktop?.forEach(cardId => {
        battlecardCardIds.add(cardId);
      });
    });

    for(let i = 0; i < boards?.length; i++) {
      const board = boards[i];

      for(let j = 0; j < board?.cards?.length; j++) {
        const card = board.cards[j];

        if(battlecardCardIds.has(card.id)) {
          laneIdsWithBattlecardCard.add(board.id);
          break;
        }
      }
    }

    return {
      battlecardCardIds,
      laneIdsWithBattlecardCard
    };
  };

  handleToggleBattlecardCardsOnly = () => {
    const {battlecardCardsOnly} = this.state;

    this.setState({
      battlecardCardsOnly: !battlecardCardsOnly,
      ...this.getBattlecardCardLookup({battlecardCardsOnly: !battlecardCardsOnly})
    });
  };

  handleFilterDateClick = ({label, updatedAtTimestamp: date}) => {
    const {history} = this.props;

    history.push({
      search: date ? `?cardFilter=${getCardFilterParamFromLabel(label)}` : '',
      state: {}
    });
  };

  handleSearchFilterSubmit = (prevSearchValue, searchValue) => {
    const {profileFilterObj} = this.state;
    const {updatedSince} = profileFilterObj || {};
    const filterObj = {
      q: searchValue || '',
      updatedSince: updatedSince || null
    };

    this.setState({
      profileFilterObj: filterObj
    }, () => {
      const profile = this._getProfile();

      if(profile && searchValue && (searchValue !== prevSearchValue)) {
        analyticsTrack({
          type: 'event',
          category: 'Profile',
          action: 'search',
          label: `${profile.name}: query:${searchValue}`,
          value: 1
        });
      }
    });
  };

  render() {
    const profile = this._getProfile();

    if(!profile) {
      return null;
    }

    const {
      user, users, company, rivals, editMode, builderMode, postsUrl, maxBattlecardCards, location,
      onToggleLane, match: {params: {profileId = '', cardId = ''} = {}}, showFeedPostBox
    } = this.props;

    const {
      lanesLoaded, lanesLoading, boards: allBoards, boardsCount, emphasizeVitalSettings, feedStartCollapsed,
      selectedPostData, selectedCard, previewVisibilityGroup, lastCardUpdated, boardsRefreshCount,
      battlecardCardsOnly, battlecardCardIds, laneIdsWithBattlecardCard, freshnessMode,
      freshCardExpanded
    } = this.state;
    const isCurator = userCanCurate({user});
    const previewing = previewVisibilityGroup !== null;
    const {state: {boardId, showCardPermissions} = {}, pathname} = location;
    const {id: authorId} = user || {};
    const profileEditMode = isCurator && editMode;
    let boards = (allBoards || []).filter(({name = ''}) => name.toLowerCase() !== 'scratchpad');

    if(freshnessMode && battlecardCardsOnly) {
      boards = boards.filter(({id}) => laneIdsWithBattlecardCard.has(id));
    }

    const profileClass = classNames('ui-profile', {
      'wide-mode': profile.wideMode
    });
    const boardsClass = classNames('swimlanes-boards', {
      editing: profileEditMode,
      'swimlanes-boards--loading': !lanesLoaded,
      'swimlanes-boards--maximize': !lanesLoaded && !feedStartCollapsed
    });
    const isEditingCard = (/\/card\/\d+\/edit\b.*$/i).test(pathname);
    const isNewCard = !cardId && (/(\/\d+)?\/card\/new?$/i).test(pathname);
    const isVisible = Boolean(editMode && authorId && profileId && boardId && (isEditingCard || isNewCard));
    const isCuratorToolsCollapsed = getCollapsedLanes(user, profileId).includes(Profile.regionTypes.CURATOR_TOOLS);

    return (
      <div className="container">
        <CardEditModal
          visible={isVisible}
          profileId={Number(profileId)}
          onCreate={this.handleModalAfterUpdate}
          onUpdate={this.handleModalAfterUpdate}
          onClose={this.handleModalClose}
          cardId={!isNewCard ? Number(cardId) : null}
          boardId={Number(boardId)}
          authorId={authorId}
          wideMode={profile.wideMode}
          showCardPermissions={showCardPermissions}
          location={location} />
        {Boolean(selectedCard.card) && this.renderCardMeta()}
        <ReactTooltip
          class="tooltip"
          html={true}
          effect="solid"
          offset={{top: 15}} />
        <CardDraggingProvider
          onError={this.handleCardDraggingError}
          onBoardDragged={this.handleBoardDragged}
          onSetContextRef={this.handleSetDraggingContextRef}>
          <div className="ui-wrapper on profile-layout">
            <ProfileToolbar
              profileEditMode={profileEditMode}
              showBattlecardCardsOnly={battlecardCardsOnly}
              onToggleCardDragging={this.handleToggleCardDragging}
              onViewAs={this.handleProfileViewAs}
              previewingVisibilityGroupId={previewVisibilityGroup}
              onToggleBattlecardCardsOnly={this.handleToggleBattlecardCardsOnly}
              onFilterDateClick={this.handleFilterDateClick}
              onSearchFilterSubmit={this.handleSearchFilterSubmit}
              boards={boards} />
            <div className="ui-layout">
              <div
                ref={this._setSwimlanesRef}
                id="swimlanes-wrapper"
                className="swimlanes-wrapper scrollbar-fancy"
                onScroll={
                  emphasizeVitalSettings
                    ? this.handleEmphasizedSettingsScroll
                    : null
                }>
                <div ref="swimlanes" className={boardsClass}>
                  {!freshnessMode && lanesLoaded &&
                    !previewing ? (
                      <CardDraggingContext.Consumer>
                        {cardDraggingContext => (
                          this.renderCuratorTools(emphasizeVitalSettings, cardDraggingContext)
                        )}
                      </CardDraggingContext.Consumer>
                    )
                    : null}
                  {!freshnessMode && lanesLoaded && !previewing && showFeedPostBox && (
                    <FeedPostBox
                      user={user}
                      users={users}
                      company={company}
                      postsUrl={postsUrl}
                      profileEditMode={profileEditMode}
                      builderMode={builderMode}
                      rivals={rivals}
                      maxBattlecardCards={maxBattlecardCards}
                      selectedPostData={selectedPostData}
                      onToggleFeedItem={this.handleToggleFeedItem}
                      dim={emphasizeVitalSettings}
                      feedStartCollapsed={feedStartCollapsed}
                      onToggleFeed={() =>
                        this.setState(prev => ({
                          feedStartCollapsed: !prev.feedStartCollapsed
                        }))
                      } />
                  )}
                  {!freshnessMode && lanesLoaded && !previewing && (
                    <FeedPostBoxExpanded
                      postData={selectedPostData}
                      profile={profile}
                      profileEditMode={profileEditMode}
                      onToggleFeedItem={this.handleToggleFeedItem}
                      dim={emphasizeVitalSettings} />
                  )}
                  <div className={profileClass}>
                    <BoardList
                      swimlanesRef={this._swimlanesRef}
                      boards={boards}
                      lanesLoading={lanesLoading}
                      boardsRefreshCount={boardsRefreshCount}
                      wideMode={profile.wideMode}
                      battlecardCardsOnly={battlecardCardsOnly}
                      battlecardCardIds={battlecardCardIds}
                      emphasizeVitalSettings={emphasizeVitalSettings}
                      user={user}
                      users={users}
                      onBoardsLoad={this.loadLanes}
                      onBoardCreate={this.handleBoardCreate}
                      onBoardUpdate={this.handleBoardUpdate}
                      onBoardDelete={this.handleBoardDelete}
                      onInsertLaneAfter={this.handleInsertLaneAfter}
                      onScratchpadDismiss={this.handleScratchpadDismissComment}
                      onBoardRefresh={this.handleBoardRefresh}
                      onCardRefresh={this.handleCardRefresh}
                      onProfileUpdated={this.handleProfileUpdated}
                      onSettingsExpanded={this.handleSettingsExpanded}
                      profileEditMode={profileEditMode}
                      freshnessMode={freshnessMode}
                      freshCardExpanded={freshCardExpanded}
                      rivals={rivals}
                      builderMode={builderMode}
                      onToggleCardOnBattlecard={this.handleToggleCardOnBattlecard}
                      maxBattlecardCards={maxBattlecardCards}
                      onShowCardMeta={this.handleShowCardMeta}
                      onGetCommentSourceLink={this.getCommentSourceLink}
                      boardsCount={boardsCount}
                      onToggleLane={onToggleLane}
                      moveCardToLane={this.handleMoveCardLane}
                      lastCardUpdated={lastCardUpdated}
                      feedCollapsed={feedStartCollapsed}
                      onToggleCuratorTools={() =>
                        this.toggleRegionVisibility(
                          Profile.regionTypes.CURATOR_TOOLS
                        )
                      }
                      onErrorMessage={this.handleErrorMessage}
                      activeBoardId={boardId}
                      previewingId={previewVisibilityGroup}
                      onClickOutsideOfVitals={emphasizeVitalSettings ? this.handleOnClickOutsideOfVitals : null}
                      isCuratorToolsCollapsed={isCuratorToolsCollapsed}
                      onProfileRefresh={this.handleProfileRefresh}
                      onLanesDropHover={this.handleLanesDropHover}
                      onToggleFreshCardExpanded={this.handleToggleFreshCardExpanded} />
                  </div>
                </div>
              </div>
              <DropTargetScroller onDropTargetScrollerHover={this.handleScrollLeft} />
            </div>
          </div>
        </CardDraggingProvider>
      </div>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  loadOrFetchRivals: () => dispatch.rivals.loadOrFetchRivals()
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  withRouter
);

export default enhance(Profile);
