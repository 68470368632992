import {wait} from '../modules/utils';

export const highlightLane = async (lane, laneEl, collapsed, onToggleLaneVisibility) => {
  let el = laneEl;

  const showHighlight = async () => {
    el.classList.add('lane--spotlight');

    // cover spotlight animation duration + a little extra buffer
    await wait(4500);

    el.classList.remove('lane--spotlight');
  };

  if(collapsed) {
    console.log('CardVitalsToc.highlightLane: lane #%o is collapsed, opening...', lane.id);

    await onToggleLaneVisibility(lane);

    // re-query lane element after opening
    el = document.querySelector(`#b${lane.id}`);
  }

  showHighlight();
};

export const handleScrollToLane = (lane, collapsed, onToggleLaneVisibility, scrollOptions = {behavior: 'smooth', block: 'end'}) => {
  const laneEl = document.querySelector(`#b${lane.id}`);

  if(laneEl) {
    // detect if we've completed scrolling to lane, then trigger highlight animation
    // NOTE: using raw IntersectionObserver (or polyfill); doesn't need to use our wrapper Observer component (overkill)
    const intersectionObserver = new IntersectionObserver(async ([entry]) => {
      if(entry.isIntersecting) {
        await wait(100);

        highlightLane(lane, laneEl, collapsed, onToggleLaneVisibility);

        if(!lane.id) {
          // focus board name input if curator clicked "add new lane"
          const headerEl = laneEl.querySelector('.board-header');

          // TODO: HACK: hackery to get around refactoring old BoardAddPanel logic and focus the lane name input
          headerEl && headerEl.click();
        }

        intersectionObserver.disconnect();
      }
    });

    intersectionObserver.observe(laneEl);
    laneEl.scrollIntoView(scrollOptions);
  }
};

