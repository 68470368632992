/* eslint-disable max-len */
export default function KlueLogo() {
  return (<svg style={{width: 40, display: 'block'}} viewBox="0 0 37 15">
    <path
      d="M14.1102 0H12.1034C11.9995 0 11.9153 0.0850094 11.9153 0.189874V14.8101C11.9153 14.915 11.9995 15 12.1034 15H14.1102C14.2141 15 14.2983 14.915 14.2983 14.8101V0.189873C14.2983 0.0850092 14.2141 0 14.1102 0Z"
      fill="currentColor" />
    <path
      d="M2.19492 0H0.188136C0.0842312 0 0 0.0850094 0 0.189874V14.8101C0 14.915 0.0842312 15 0.188136 15H2.19492C2.29882 15 2.38305 14.915 2.38305 14.8101V0.189873C2.38305 0.0850092 2.29882 0 2.19492 0Z"
      fill="currentColor" />
    <path
      d="M23.1714 3.86076C23.0675 3.86076 22.9832 3.94577 22.9832 4.05063V9.64392C22.9832 10.5376 22.9509 12.6647 20.8831 12.6647H20.5068C18.3771 12.6647 18.4295 10.5366 18.4295 9.64392V4.05063C18.4295 3.94577 18.3453 3.86076 18.2414 3.86076H16.1797C16.0758 3.86076 15.9915 3.94577 15.9915 4.05063V9.48559C15.9915 11.0939 16.2424 15 20.3814 15H21.0085C25.1475 15 25.3983 11.0855 25.3983 9.47725V4.05063C25.3983 3.94577 25.3141 3.86076 25.2102 3.86076H23.1714Z"
      fill="currentColor" />
    <path
      d="M2.65356 9.03004C2.58972 8.95277 2.59667 8.83866 2.66942 8.76987L7.80237 3.91625C7.83724 3.88328 7.88323 3.86493 7.931 3.86493H10.3837C10.5529 3.86493 10.6361 4.07271 10.5144 4.19135L5.74128 8.84624C5.67073 8.91504 5.66441 9.027 5.72675 9.10345L10.2823 14.6895C10.3834 14.8133 10.2961 15 10.1371 15H7.49814C7.44065 15 7.38631 14.9735 7.35062 14.928L4.02368 10.6858C4.02343 10.6855 4.02297 10.6855 4.02269 10.6857C4.02241 10.686 4.02196 10.686 4.02171 10.6857L2.65356 9.03004Z"
      fill="currentColor" />
    <path
      d="M26.7819 9.41775C26.7819 8.67089 26.8827 7.96115 27.1358 7.28179C27.3889 6.60243 27.7695 5.98443 28.2546 5.46512C28.7398 4.9458 29.3193 4.53596 29.9583 4.26035C30.5972 3.98473 31.2823 3.84907 31.9722 3.86155C35.023 3.86155 37 6.23184 37 9.70604V10.1295C37 10.2343 36.9158 10.3193 36.8119 10.3193H29.7621C29.6484 10.3193 29.5602 10.4205 29.5803 10.5334C29.6904 11.1538 29.9196 11.6477 30.4022 12.0587C30.939 12.516 31.7031 12.7172 32.4358 12.7172C33.3591 12.7039 34.2517 12.4288 34.9803 11.8402C35.058 11.7774 35.1708 11.7808 35.2409 11.8522L36.4813 13.1146C36.5583 13.1929 36.5532 13.3208 36.4691 13.3912C34.8145 14.7779 33.6136 15 32.1495 14.9907C31.3559 15 30.7345 14.9217 30.0712 14.6586C29.408 14.3956 28.8041 13.9885 28.2985 13.4635C27.7929 12.9386 27.3967 12.3074 27.1356 11.6106C26.8744 10.9138 26.7819 10.1899 26.7819 9.41775ZM31.9722 6.08398C31.9722 6.08343 31.9718 6.08297 31.9712 6.08295C31.3522 6.06428 30.75 6.29816 30.2892 6.73624C29.8774 7.12787 29.6068 7.55483 29.5193 8.10775C29.5021 8.21679 29.5892 8.31097 29.6986 8.31097H34.2429C34.3497 8.31097 34.4365 8.22104 34.4216 8.11431C34.3909 7.8947 34.323 7.72516 34.2201 7.49323C34.0886 7.19699 33.9025 6.93153 33.6728 6.71241C33.443 6.49329 33.1743 6.32493 32.8823 6.21721C32.5906 6.10961 32.2816 6.06467 31.9733 6.08501C31.9727 6.08505 31.9722 6.08458 31.9722 6.08398Z"
      fill="currentColor" />
  </svg>);
}
